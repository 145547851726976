import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { useFormContext } from "react-hook-form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useForceUpdate from "../../hooks/useForceUpdate";
import { defaultLegValue, defaultPilotValue, useCalculatorContext } from "../../CalculatorContext";
import { Control } from "../Control";
import Leg from "./Leg";
import uid from "../../../../utils/uid";
import cs from "../../../../utils/condString";

import "./index.css";
import apiHandler from "../../../../services/api-handler";
import { showNotification } from "../../../../utils/showNotification";
import { connect } from "react-redux";

const LegsList = (props) => {
  const { prefix, disabled, banking } = props;
  const { watch, getValues } = useFormContext();
  const { updateOperatorMargin, updateForm, demoVersion } = useCalculatorContext();
  const forceUpdate = useForceUpdate();
  const legs = getValues(`${prefix}.legs`);

  const updateAircraftTotal = React.useCallback(() => {
    const total_aircraft_costs = legs.reduce((total, leg) => total + +leg.total_cost_leg, 0);

    updateForm([[`${prefix}.total_aircraft_costs`, total_aircraft_costs]]);
    updateOperatorMargin();
  }, [getValues, prefix, updateForm, updateOperatorMargin]);

  const aircraft = watch(prefix);
  const minPilotsLen = Math.max(...aircraft.legs.map((leg) => leg.pilots.length));

  const generateNewLeg = React.useCallback(() => {
    const { crew_size, price_per_hour, cruise_speed } = aircraft;
    const companyVat = banking?.vat_value;
    const currentAircraft = getValues(prefix);
    const aircraftVat = currentAircraft.aircraft_vat || companyVat || "";

    return {
      id: uid().uid,
      crew_size,
      price_per_hour,
      cruise_speed,
      pilots: [{ id: uid().uid, ...defaultPilotValue() }],
      ...defaultLegValue,
      flight_tax: {
        sum: "",
        percent: aircraftVat,
      },
      departure_fee_tax: {
        sum: "",
        percent: companyVat || "",
      },
      landing_fee_tax: {
        sum: "",
        percent: companyVat || "",
      },
      extra_cost_tax: {
        sum: "",
        percent: companyVat || "",
      },
    };
  }, [aircraft]);

  if (!document.getElementById("calculator__legs")) {
    setTimeout(forceUpdate);
    return null;
  }

  const handleRemoveLeg = (legIndex, callback) => {
    const currentLeg = legs[legIndex];

    if (currentLeg.calculation_leg_id) {
      apiHandler
        .setPath("quote", "remove-leg")
        .setProperty("skipErrorAlert", true)
        .delete(currentLeg.calculation_leg_id)
        .then((res) => {
          showNotification(res.message, "success");
          return callback(legIndex);
        })
        .catch((err) => err);
    } else {
      return callback(legIndex);
    }
  };

  return ReactDOM.createPortal(
    <Control.List name={`${prefix}.legs`}>
      {({ fields, add, remove, move }) => {
        const len = fields.length - 1;

        if (demoVersion && disabled) return null;

        return (
          <React.Fragment>
            <div className={cs("legs-list", disabled && "disabled")}>
              <DndProvider backend={HTML5Backend}>
                {fields.map((field, idx) => (
                  <Leg
                    key={field.id}
                    prefix={`${prefix}.legs.${idx}`}
                    index={idx}
                    isLast={len === idx}
                    minPilotsLen={minPilotsLen}
                    legsLen={len + 1}
                    // onRemove={() => remove(idx)}
                    onRemove={() => handleRemoveLeg(idx, remove)}
                    onTotalUpdate={updateAircraftTotal}
                    moveLeg={move}
                  />
                ))}
              </DndProvider>
            </div>

            <button
              type="button"
              disabled={disabled}
              className="calculator-btn calculator-add-btn"
              tabIndex="-1"
              style={{
                alignSelf: "flex-start",
              }}
              onClick={() => {
                add(generateNewLeg());
              }}
            >
              + Add Leg
            </button>
          </React.Fragment>
        );
      }}
    </Control.List>,
    document.getElementById("calculator__legs"),
  );
};

LegsList.propTypes = {
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  banking: PropTypes.object,
};

LegsList.defaultProps = {};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(LegsList);
