import React from "react";
import staticService from "../../../../services/static.service";
import BackLink from "../../../common/backlink";

const RequestNavComponent = () => {
  return (
    <div className="gh-new-request-nav">
      <div className="uk-container">
        <div className="gh-new-request-nav-inner">
          <BackLink back>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("back"),
              }}
            />
          </BackLink>
        </div>
      </div>
    </div>
  );
};

export default RequestNavComponent;
