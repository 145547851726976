import React from "react";
import PropTypes from "prop-types";

export const BlockComponent = ({
  noContaierClass,
  visible = false,
  parentProps = {},
  containerClass,
  children,
}) => {
  let defaultClassName = "uk-container";

  if (noContaierClass) {
    defaultClassName = "";
  }

  return visible ? (
    <div {...parentProps}>
      <div className={containerClass || defaultClassName}>{children}</div>
    </div>
  ) : null;
};

BlockComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  parentProps: PropTypes.object,
  containerClass: PropTypes.string,
};
