import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { parseCoordinates, inArrayWithKey, utcTime } from "../../../../shared";
import { FlightDirection } from "../../../../components/common";
import { config } from "../../../../configs";
import { passengerModel } from "../../../../reducers/models";
import staticService from "../../../../services/static.service";

const gridSize = {
  full: ["uk-width-1-1@m", "uk-width-1-1@m", "uk-width-1-1@m", "uk-width-1-1@m"],
  responsive: [
    "uk-width-expand@l uk-width-1-1@s",
    "uk-width-expand@l uk-width-1-1@s",
    "uk-width-1-4@l uk-width-1-1@s",
    "uk-width-auto@l uk-width-1-1@s",
  ],
};

const PadDetail = ({ requestInfo, leg, index, showHelicopImage = true, isResponsive = true }) => {
  const { from_name: fromAddr, to_name: toAddr, from_coords, to_coords } = leg;

  const fromCoords = parseCoordinates(from_coords);
  const toCoords = parseCoordinates(to_coords);
  const gridDimensions = isResponsive ? gridSize.responsive : gridSize.full;
  const { number_adults, number_children } = requestInfo.legs
    ? requestInfo.legs[index]
    : passengerModel;

  return (
    <div>
      <div data-uk-grid className="gh-leg-info">
        <div className={"uk-width-1-2@l"}>
          <FlightDirection visible={showHelicopImage} />
        </div>
      </div>

      <div
        data-uk-grid
        className={`${index === 0 ? "uk-margin-small-top" : "uk-margin-medium-top"} gh-leg-info`}
      >
        <div className={gridDimensions[0]}>
          <PadInfo addr={fromAddr} lat={fromCoords.lat} lng={fromCoords.lng} />
        </div>
        <div className={gridDimensions[1]}>
          <PadInfo addr={toAddr} lat={toCoords.lat} lng={toCoords.lng} />
        </div>

        <div className={gridDimensions[2]}>
          <div>
            <small>
              {staticService.findByAlias("quoteDetailDepartureDate")}:{" "}
              <span>{moment(leg.departure_time).format("DD MMMM, YYYY")}</span>
            </small>
          </div>
          <div>
            <small>
              <span>
                {staticService.findByAlias("quoteDetailDepartureTime")}:{" "}
                {moment(leg.departure_time).format(config.timeFormat) + utcTime(leg.departure_time)}
              </span>
            </small>
          </div>
          {index === 0 ? (
            <div>
              <div>
                <small>
                  {staticService.findByAlias("AircraftPreferences")}:{" "}
                  <span>
                    {requestInfo.preferences &&
                      inArrayWithKey("id", requestInfo.preferences, config.aircraftPreferences)
                        .title}
                  </span>
                </small>
              </div>
              <div>
                <small>
                  {staticService.findByAlias("CrewRequirements")}:{" "}
                  <span>
                    {requestInfo.crew_size >= 0 &&
                      inArrayWithKey("id", requestInfo.crew_size, config.pilotPreferences).title}
                  </span>
                </small>
              </div>
            </div>
          ) : null}
        </div>

        <div className={gridDimensions[3]}>
          <div>
            <small>
              {staticService.findByAlias("NumberAdults")}: <span>{number_adults}</span>
            </small>
          </div>
          <div>
            <small>
              {staticService.findByAlias("NumberChildren")}: <span>{number_children}</span>
            </small>
          </div>
          <div>
            <small>
              {staticService.findByAlias("CateringRequired")}:{" "}
              <span>{requestInfo.catering ? "Yes" : "No"}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

const PadInfo = ({ addr, lat, lng }) => (
  <div>
    <h3 className="uk-h3 uk-margin-remove">{addr}</h3>
    <div>
      <small>
        <span>
          {lat.toFixed(2)}° N, {lng.toFixed(2)}° W
        </span>
      </small>
    </div>
  </div>
);

PadDetail.defaultProps = {
  requestInfo: {},
  leg: {},
  showHelicopImage: true,
  isResponsive: true,
};

PadDetail.propTypes = {
  requestInfo: PropTypes.object.isRequired,
  leg: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  showHelicopImage: PropTypes.bool,
  isResponsive: PropTypes.bool,
};

export { PadDetail };
