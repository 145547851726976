import React from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { BlockComponent } from "../common";
import { addFailedCssClassToArray, getActiveClassBool, pageTitle, storage } from "../../shared";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../";
import Auth from "../../services/auth";
import staticService from "../../services/static.service";
import filtersIcon from "../../assets/img/svg/filters-icon.svg";
import BackLink from "../common/backlink";

class BrokerBookingLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCss: ["gh-filters-tab"],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filterCss !== nextProps.filterCss) {
      this.setState({
        filterCss: addFailedCssClassToArray(nextProps.filterCss, this.state.filterCss),
      });
    }
  }

  componentWillMount() {
    document.title = pageTitle(this.props.pageTitle);
  }

  get className() {
    return ["broker-booking-layout"].concat(this.props.parentClassname).join(" ");
  }

  get requestButtonProps() {
    storage.delete("request_form");
    return {
      componentProps: {
        isButton: false,
        path: "/new-request",
        actionTitle: staticService.findByAlias("requestPageQuote"),
        classNames: [
          "uk-button",
          "gh-req-button",
          "uk-margin-small-bottom",
          "uk-margin-remove-top",
          "uk-width-1-6",
          "uk-padding-remove",
          "uk-margin-small-top",
        ],
        tooltipMessage: staticService.findByAlias("accountUnverified"),
        verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["broker_admin", "broker_team"],
      component: IsVerifiedAuthorization,
    };
  }

  get buttonProps() {
    return this.requestButtonProps;
  }

  isActive(index, alias, activePath) {
    return getActiveClassBool(index, alias, activePath.path.trim());
  }

  render() {
    const { isFiltersShown = false } = this.props;

    const brokerAllRequestsDetail = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/all/:id",
      strict: true,
      exact: true,
    });

    const brokerRequest = matchPath(window.location.pathname, {
      path: "/flights/requests/:id",
      strict: true,
      exact: true,
    });

    const brokerRequestQuote = matchPath(window.location.pathname, {
      path: "/flights/requests/:id/quote/:quoteId",
      strict: true,
      exact: true,
    });
    const brokerConfirm = matchPath(window.location.pathname, {
      path: "/flights/requests/:id/payment/:quoteID/step/:id",
      strict: true,
      exact: true,
    });
    const brokerReview = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/review/:id",
      strict: true,
      exact: true,
    });
    const brokerOpen = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/open/:id",
      strict: true,
      exact: true,
    });
    const brokerExpired = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/expired/:id",
      strict: true,
      exact: true,
    });
    const brokerWithdrawn = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/withdrawn/:id",
      strict: true,
      exact: true,
    });
    const brokerUrgent = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/urgent/:id",
      strict: true,
      exact: true,
    });
    const brokerPending = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/pending/:id",
      strict: true,
      exact: true,
    });

    const fullNav = () => (
      <div>
        <div className="uk-width-1-1">
          <div className="uk-flex uk-flex-middle">
            <div className="uk-width-1-2">
              <h3>All {this.props.pageTitle}</h3>
            </div>
            <div className="uk-width-1-2 uk-flex uk-flex-right">
              <div>
                <ComponentAuthorization
                  component={this.buttonProps.component}
                  componentProps={this.buttonProps.componentProps}
                  roles={this.buttonProps.roles}
                />
              </div>
            </div>
          </div>
        </div>
        <ul className="uk-tab">
          {/*{navigationTabs}*/}
          <li>
            <BackLink to="/dashboard" className="uk-margin-small-bottom">
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("backToDashboard"),
                }}
              />
            </BackLink>
          </li>
          {this.props.isFilterVisible ? (
            <li className={this.state.filterCss.join(" ")}>
              <a onClick={this.props.onFiltersClick}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(isFiltersShown ? "Close" : "Filters"),
                  }}
                />{" "}
                <span className="uk-margin-small-left">
                  <img src={filtersIcon} alt="filters-icon" />
                </span>
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    );

    const shortNavVisible = () => {
      return (
        (Auth.getRole() === "broker_admin" || Auth.getRole() === "broker_team") &&
        (!!brokerRequest ||
          !!brokerRequestQuote ||
          !!brokerConfirm ||
          !!brokerReview ||
          !!brokerOpen ||
          !!brokerWithdrawn ||
          !!brokerExpired ||
          !!brokerUrgent ||
          !!brokerAllRequestsDetail ||
          !!brokerPending)
      );
    };

    return (
      <section className={this.className}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: !shortNavVisible() ? "uk-margin-medium-top" : "",
          }}
          containerClass={shortNavVisible() ? "uk-container-expand" : ""}
        >
          {!shortNavVisible() && fullNav()}
          {/*{fullNav()}*/}
          {this.props.children}
        </BlockComponent>
      </section>
    );
  }
}

BrokerBookingLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  navigationTabs: PropTypes.array.isRequired,
  activeRoute: PropTypes.object.isRequired,
  parentClassname: PropTypes.array,
  isFilterShown: PropTypes.bool,
  onFiltersClick: PropTypes.func,
  isFilterVisible: PropTypes.bool,
  filterCss: PropTypes.string,
};

BrokerBookingLayout.defaultProps = {
  pageTitle: "Booking",
  navigationTabs: [],
  parentClassname: [],
  isFilterVisible: true,
};

export { BrokerBookingLayout };
