import React from "react";
import { actions, Control, Errors, Form } from "react-redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
// import "core-js/fn/object/assign";
// import "core-js/fn/promise";
import {
  CustomValidateError,
  DateTimeComponent,
  prepareQueryFromModelForFilters,
  prepareQueryStringFromObj,
  RadioComponent,
  setBrowserHistory,
  stringToBoolean,
  updateModel,
} from "../../../../../../shared";
import {
  changeSort,
  getOperatorRequestsByFilter,
  getOperatorRequetsSuccess,
  getRequests,
  getRequestsByFilter,
  getRequestsSuccess,
  resetSearchDefault,
  saveSearchDefault,
  SORT_GRID,
} from "../../../../../../actions";
import {
  config,
  operatorMarketRequestConfig,
  validationMessages,
  validationRules,
} from "../../../../../../configs";
import staticService from "../../../../../../services/static.service";
import {
  ConfirmComponent,
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../../../common";
import BasicRequestFiltersComponent from "../../../filters/basic-request-filters.component";
import moment from "moment/moment";

const modelName = "operatorMarketplaceRequestsFiltersComponent";
const { rangeValidation, rangeValidationEndDate, required } = validationRules;
const modelDefault = {
  sortedBy: "asc",
  orderBy: "departure",
  activity: "own",
  date: null,
  date_end: "",
  date_start: "",
  disableBtn: false,
  fleetCheck: true,
  lat: null,
  lng: null,
  near_by: "",
  notQuotedOnly: false,
  declined: false,
  radius: "",
};

export const defaultFiltersToReset = [
  { key: "activity", value: "own" },
  { key: "fleetCheck", value: 1 },
  { key: "notQuotedOnly", value: 0 },
  { key: "declined", value: 1 },
  { key: "near_by", value: "" },
  { key: "radius", value: "" },
  { key: "disableBtn", value: 0 },
  { key: "date_end", value: "" },
  { key: "date_start", value: "" },
  { key: "refreshCollection", value: 0 },
  { key: "lat", value: "" },
  { key: "lng", value: "" },
];

class OperatorMarketplaceRequestsFiltersComponent extends BasicRequestFiltersComponent {
  constructor(props) {
    super(props, modelName);
  }

  applyFilters(filters, action) {
    const queryParams = action === "reset" ? {} : filters;
    this.props[this.props.getMethodName](
      1,
      prepareQueryStringFromObj(prepareQueryFromModelForFilters(queryParams)),
    ).then((requests) => {
      const _filters = action === "reset" ? super.Model : filters;
      this.props.updateModel(modelName, _filters);
      this.props.updateModel(modelName + ".disableBtn", false);
      this.props.updateModel(modelName + ".refreshCollection", true);
      this.props[this.props.getMethodName + "Success"](requests);
    });
  }

  componentWillMount() {
    this.setState({ submitFirstTime: false });
    this.props.updateModel(
      modelName,
      Object.assign(this.Model, stringToBoolean(this.props.queryParams)),
    );
  }

  componentDidMount() {
    const { defaultFilters, queryParams } = this.props;
    this.props.updateModel(modelName, { ...defaultFilters, ...queryParams });
    this.context.store.dispatch(actions.change(`${modelName}.fleetCheck`, true));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.requests && nextProps.requests.length < 0) {
      this.context.store.dispatch(actions.resetErrors(modelName));
    }
  }

  updateLocation(model, location) {
    if (location === "") {
      this.context.store.dispatch(actions.change(modelName + ".lat", null));
      this.context.store.dispatch(actions.change(modelName + ".long", null));
    }
    this.context.store.dispatch(actions.change(model, location));
  }

  reset() {
    this.context.store.dispatch({
      type: SORT_GRID,
      sort: operatorMarketRequestConfig.orderByOn,
    });
    this.props.resetModel(modelName);
    this.handleFormSubmit({}, "reset");
    this.props.onReset();
    this.forceUpdate();
  }

  handleFormSubmit(model, action = "submit") {
    const { filterModel, defaultFilters } = this.props;

    this.context.store.dispatch(actions.change("isConcat", true));

    const filterSort = {
      field: filterModel.orderBy === undefined ? defaultFilters.orderBy : filterModel.orderBy,
      sortOrder:
        filterModel.sortedBy === undefined ? defaultFilters.sortedBy : filterModel.sortedBy,
    };

    if (!filterModel.radius && !!filterModel.near_by) {
      this.context.store.dispatch(actions.setTouched(`${modelName}.radius`));
      this.context.store.dispatch(
        actions.setErrors(`${modelName}.radius`, {
          required: true,
        }),
      );
    } else if (!filterModel.near_by && !!filterModel.radius) {
      this.context.store.dispatch(actions.setTouched(`${modelName}.near_by`));
      this.context.store.dispatch(
        actions.setErrors(`${modelName}.near_by`, {
          required: true,
        }),
      );
    } else {
      this.props.onSubmit(model);

      if (action !== "reset" && !this.state.submitFirstTime) {
        this.setState({ submitFirstTime: true });
        this.context.store.dispatch({ type: SORT_GRID, sort: filterSort });
      }

      this.props.updateModel(this.state.modelName + ".disableBtn", true);

      if (model.source && model.source === config.locationSource[2]) {
        this.context.store.dispatch({ type: SORT_GRID, sort: filterSort });
        return super.getByCoordonates(model, action);
      } else {
        this.context.store.dispatch({ type: SORT_GRID, sort: filterSort });
        return super.getByOthers(model, action);
      }
    }
  }
  resetProps() {
    this.props.resetSearchDefault([], 1, [], this.onSuccess());
    this.forceUpdate();
    this.props.updateModel(modelName, modelDefault);
    this.context.store.dispatch({
      type: SORT_GRID,
      sort: operatorMarketRequestConfig.orderByOn,
    });
    setBrowserHistory(this.props.history, "/marketplace", modelDefault);
  }

  saveProps() {
    const { filterModel, saveDefaultSearch, history } = this.props;
    saveDefaultSearch(filterModel, 1, [], this.onSuccess());
    this.forceUpdate();
    this.props.updateModel(modelName + ".refreshCollection", true);
    setBrowserHistory(history, "/marketplace", filterModel);
  }

  get updateToDefault() {
    this.setState({ resetLocation: true });
    const { defaultFilters, history } = this.props;
    this.context.store.dispatch(actions.change("isConcat", false));
    const resetSort = {
      field: defaultFilters.orderBy,
      sortOrder: defaultFilters.sortedBy,
    };
    this.props.getRequests(1, defaultFiltersToReset).then((res) => {
      this.setState({ resetLocation: false });
      this.props.updateModel(modelName, res.default_filters);
      this.context.store.dispatch({ type: SORT_GRID, sort: resetSort });
      setBrowserHistory(history, "/marketplace", defaultFilters);
      this.forceUpdate();
    });
  }

  onSuccess() {
    this.successModal.openModal();
  }

  closeConsentDialog() {
    this.successModal.closeModal();
  }

  get info() {
    return (
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("defaultUpdated"),
          }}
        />
        <div className="gh-modal-footer uk-text-center">
          <button
            className={"uk-button uk-margin-small-top uk-button-primary"}
            onClick={this.closeConsentDialog.bind(this)}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("okButton"),
            }}
          />
        </div>
      </div>
    );
  }

  isChecked(type) {
    return this.props.filterModel.sortedBy === type;
  }

  getOption(header) {
    if (header.sortColumn === "status") {
      return "";
    }
    return header.sortColumn ? (
      <option value={header.sortColumn} key={header.sortColumn}>
        {header.title}
      </option>
    ) : (
      ""
    );
  }
  render() {
    const { defaultFilters } = this.props;

    if (!this.props.visible) {
      return null;
    }

    return (
      <div className="uk-card uk-card-default uk-card-body">
        <div className={"uk-margin-left"}>
          <Form
            model={modelName}
            onSubmit={this.handleFormSubmit.bind(this)}
            validateOn="submit"
            validators={{
              "": {
                rangeRequired: rangeValidation,
                endDateValidation: rangeValidationEndDate,
              },
            }}
          >
            <div className="gh-filters-container uk-flex uk-flex-1 uk-flex-row" data-uk-grid>
              {this.props.screenW < 960 && (
                <div className="uk-width-1-1 uk-padding-small">
                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("sortBy"),
                      }}
                    />
                    <div className="uk-form-controls">
                      <Control.select model=".orderBy" className="uk-select">
                        {this.props.headers.map((header) => {
                          return this.getOption(header);
                        })}
                      </Control.select>
                    </div>
                  </div>
                  <div className="gh-mob-radio-row">
                    <div className="uk-inline" style={{ marginRight: 10 }}>
                      <RadioComponent
                        label={staticService.findByAlias("ascendant")}
                        model=".sortedBy"
                        onClick={(isChecked, val, model) => {
                          const _modelName = modelName + model;
                          return this.props.updateModel(_modelName, val);
                        }}
                        value={"asc"}
                        wrapperClassName="gh-radio-wrapper-with-label"
                        checked={this.isChecked("asc")}
                      />
                    </div>

                    <div className="uk-inline gh-mob-radio-margin">
                      <RadioComponent
                        label={staticService.findByAlias("descendant")}
                        model=".sortedBy"
                        onClick={(isChecked, val, model) => {
                          const _modelName = modelName + model;
                          return this.props.updateModel(_modelName, val);
                        }}
                        value={"desc"}
                        wrapperClassName="gh-radio-wrapper-with-label gh-right-radio"
                        checked={this.isChecked("desc")}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-margin-remove-top">
                <label
                  className="uk-form-label"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("NearByTo"),
                  }}
                />
                <div className="uk-form-controls uk-position-relative">
                  <LocationAutocompleteWrapper>
                    {({
                      locations,
                      search,
                      handleClickOutside,
                      loader,
                      onBlur,
                      validate,
                      validationMessage,
                      locationValidationTemplate,
                      clearErrors,
                    }) => {
                      if (this.state.resetLocation) {
                        clearErrors();
                      }

                      return (
                        <div
                          className={
                            "uk-position-relative gh-location-autocomplete-marketplace-filters"
                          }
                        >
                          <LocationAutocompleteInput
                            className={["uk-input"]}
                            placeholder={"London"}
                            readOnly={false}
                            value={this.props.near_by ? this.props.near_by : ""}
                            additionalInfo={this.props.home_info}
                            onSelect={(location) => {
                              this.updateGeocodeModel(location);
                              return search(location);
                            }}
                            onBlur={onBlur}
                            loader={loader}
                            debounce={500}
                            model={`${modelName}.near_by`}
                          />
                          <LocationAutocompleteList
                            onSelect={this.onSelectLocation.bind(this, handleClickOutside)}
                            locations={locations}
                          />
                          {this.props.radius &&
                            locationValidationTemplate(`${modelName}.near_by`, ".near_by")}
                          <ul
                            className={
                              "ul validation-errors disabled-list" + (validate ? " hide" : "")
                            }
                          >
                            <li>{validationMessage}</li>
                          </ul>
                        </div>
                      );
                    }}
                  </LocationAutocompleteWrapper>
                </div>
              </div>

              <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-margin-remove-top">
                <label
                  className="uk-form-label"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("WithinRadius"),
                  }}
                />
                <div className="uk-form-controls">
                  <Control.select
                    defaultValue={defaultFilters.radius ? defaultFilters.radius : ""}
                    value={this.props.radius ? this.props.radius : ""}
                    model={".radius"}
                    className="uk-select"
                    validators={this.props.near_by && { required }}
                  >
                    {config.miles.map((radius, index) => (
                      <option value={radius.id} key={index}>
                        {radius.title}
                      </option>
                    ))}
                  </Control.select>
                  <Errors
                    model=".radius"
                    show="touched"
                    wrapper={CustomValidateError}
                    messages={{
                      required: validationMessages().requiredMessage.bind(
                        null,
                        staticService.findByAlias("WithinRadius"),
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-display-inline-block uk-margin-remove-top">
                <label
                  className="uk-form-label uk-display-block"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("date"),
                  }}
                />
                <div className="uk-form-controls gh-range-hypen gh-range-hypen-filter">
                  <Control
                    model={`.date_start`}
                    className={"uk-input gh-calendar-input"}
                    component={DateTimeComponent}
                    defaultValue={defaultFilters.date_start}
                    controlProps={{
                      dateFormat: "DD MMMM, YYYY",
                      placeholder: "Start date",
                      isValidDate: true,
                      onClear: () => this.props.updateModel(modelName + ".date_start", ""),
                    }}
                    changeAction={(model, value) => {
                      return updateModel(
                        model,
                        moment(value).add({ minute: moment().utcOffset() }).toISOString(),
                      );
                    }}
                    updateOn="change"
                  />
                  <Errors
                    model={modelName}
                    style={{ position: "absolute" }}
                    wrapper={(props) => {
                      const firstRule = props.children.shift();
                      if (firstRule && firstRule.props) {
                        return (
                          <ul className="ul validation-errors disabled-list gh-input-errors uk-position-absolute">
                            <li dangerouslySetInnerHTML={{ __html: firstRule.props.children }} />
                          </ul>
                        );
                      }
                      return null;
                    }}
                    messages={{
                      endDateValidation: staticService.findByAlias("rangeValidationEndDate"),
                      rangeRequired: staticService.findByAlias("rangeValidationRequired"),
                    }}
                  />
                </div>
              </div>

              <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-margin-remove-top uk-padding-remove-top uk-padding-small-top@s uk-display-inline-block">
                <div className="uk-form-controls uk-position-relative">
                  <Control
                    model={`.date_end`}
                    className={"uk-input gh-calendar-input"}
                    component={DateTimeComponent}
                    defaultValue={defaultFilters.date_end ? defaultFilters.date_end : ""}
                    controlProps={{
                      dateFormat: "DD MMMM, YYYY",
                      placeholder: "End date",
                      isValidDate: true,
                      onClear: () => this.props.updateModel(modelName + ".date_end", ""),
                    }}
                    changeAction={(model, value) =>
                      updateModel(
                        model,
                        value
                          ? moment(value).add({ minute: moment().utcOffset() }).toISOString()
                          : value,
                      )
                    }
                    updateOn="change"
                  />
                </div>
              </div>
            </div>
            <div data-uk-grid>
              <div className="uk-width-1-1 gh-appropriate-results-control uk-padding-small uk-margin-remove-top ">
                <div className="uk-grid-item-match uk-flex-middle">
                  <label>
                    <Control.checkbox
                      className={"uk-checkbox uk-margin-small-right"}
                      model={".fleetCheck"}
                      defaultValue={defaultFilters.fleetCheck ? defaultFilters.fleetCheck : false}
                      changeAction={(model, value) => updateModel(model, value)}
                      updateOn="change"
                    />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("showResultForFleet"),
                      }}
                    />
                  </label>
                </div>
                <div className="uk-grid-item-match uk-flex-middle uk-margin-small-top">
                  <label>
                    <Control.checkbox
                      className={"uk-checkbox uk-margin-small-right"}
                      model={".notQuotedOnly"}
                      defaultValue={
                        defaultFilters.notQuotedOnly ? defaultFilters.notQuotedOnly : false
                      }
                      changeAction={(model, value) => updateModel(model, value)}
                      updateOn="change"
                    />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("filterRequestNotQuoted"),
                      }}
                    />
                  </label>
                </div>
                <div className="uk-grid-item-match uk-flex-middle uk-margin-small-top">
                  <label>
                    <Control.checkbox
                      className={"uk-checkbox uk-margin-small-right"}
                      model={".declined"}
                      defaultValue={defaultFilters.declined ? defaultFilters.declined : true}
                      changeAction={(model, value) => updateModel(model, value)}
                      updateOn="change"
                    />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("filterDeclinedRequest"),
                      }}
                    />
                  </label>
                </div>
              </div>

              <div
                style={{ alignSelf: "center" }}
                className="uk-padding-small uk-width-1-1 uk-flex-center uk-padding-remove-left uk-margin-remove-left"
                data-uk-grid
              >
                <div>
                  <Control.button
                    model={modelName}
                    type={"button"}
                    onClick={() => this.updateToDefault}
                    className={"uk-button gh-cloud-blue-btn"}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("resetToOriginal"),
                      }}
                    />
                  </Control.button>
                </div>
                <div>
                  <Control.button
                    model={modelName}
                    className={"uk-button uk-button-primary gh-uk-width"}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("showFilterResult"),
                      }}
                    />
                  </Control.button>
                </div>
              </div>
              <div className="gh-home-marketplace uk-padding-remove-left">
                <div>{this.props.message}</div>
              </div>
            </div>
          </Form>

          <ConfirmComponent
            ref={(successModal) => (this.successModal = successModal)}
            info={this.info}
            buttonsProps={{}}
            onSelect={this.closeConsentDialog.bind(this)}
            onReject={this.closeConsentDialog.bind(this)}
            showCloseBtn={true}
            modalProps={{
              options: { clsPage: "", bgClose: false, escClose: false },
            }}
          />
        </div>
      </div>
    );
  }
}

OperatorMarketplaceRequestsFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorMarketplaceRequestsFiltersComponent.propTypes = {
  sortParams: PropTypes.shape({
    sortOrder: PropTypes.string,
    field: PropTypes.string,
  }),
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  parentFilters: PropTypes.object,
  requests: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    filterModel: state.operatorMarketplaceRequestsFiltersComponent,
    ...state.operatorMarketplaceRequestsFiltersComponent,
    message: state.requests.message,
    screenW: state.screenDimensions.screenInnerW,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      resetModel: (model) => dispatch(actions.reset(model)),
      getRequestsByFilter,
      getRequests: getRequests,
      changeSort: changeSort,
      saveDefaultSearch: saveSearchDefault,
      resetSearchDefault: resetSearchDefault,
      getOperatorRequestsByFilter,
      getRequestsByFilterSuccess: getRequestsSuccess,
      getOperatorRequestsByFilterSuccess: getOperatorRequetsSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorMarketplaceRequestsFiltersComponent);
export { COMPONENT as OperatorMarketplaceRequestsFiltersComponent };
