import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";

const generateDirName = (data) => (dir) => {
  const location = data[`${dir}_location`] || null;

  if (location) {
    return `${location.name}, ${location.type}, ${location.secondary_text}`;
  }

  if (data[`${dir}_info`]) {
    return `${data[dir]}, ${data[`${dir}_info`].replace(" •", ",")}`;
  }

  return data[dir];
};

export const LegRequests = ({ legs = [] }) => (
  <ul className={"disabled-list"}>
    {legs.map((leg, index) => {
      const dirName = generateDirName(leg);

      return (
        <li key={index} className={"uk-margin-small-bottom"}>
          <div className="uk-flex uk-flex-top legs-modal_request">
            <h5 className="uk-margin-small-bottom" style={{ minWidth: 90 }}>
              Leg {index + 1}
            </h5>
            <div className="dir-container">
              <div className="uk-margin-small-bottom dir-item">
                <span
                  className="dir-label"
                  dangerouslySetInnerHTML={{ __html: `${staticService.findByAlias("from")}:` }}
                />
                <span className="dir-name">{dirName("from")}</span>
              </div>
              <div className="dir-item">
                <span
                  className="dir-label"
                  dangerouslySetInnerHTML={{ __html: `${staticService.findByAlias("to")}:` }}
                />
                <span className="dir-name">{dirName("to")}</span>
              </div>
            </div>
          </div>
        </li>
      );
    })}
  </ul>
);

LegRequests.propTypes = {
  legs: PropTypes.array,
};
