import React from "react";
import PropTypes from "prop-types";
import "./ButtonCopyToClipboard.css";
import staticService from "../../../services/static.service";
import { showNotification } from "../../../utils/showNotification";
import clsx from "clsx";

const ButtonCopyToClipboard = ({ textToBeCopied, className, style, disabled }) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  const handleClick = () => {
    navigator.clipboard.writeText(textToBeCopied);
    showNotification(
      staticService.findByAlias("theLinkToTheRequestHasBeenCopiedToClipboard"),
      "success",
      "top-right",
      2000,
      true,
    );
    setCopied(true);
  };

  return (
    <div className={clsx("gh-wrapper-progress", { disabled })}>
      <div className="gh-wrapper-progress-grafic-icon uk-margin-small-right uk-margin-remove-left uk-margin-remove-right">
        <div
          className={clsx(
            { disabled },
            "gh-wrapper-progress-grafic-btn btn-style gh-button-copy-to-clipboard-wrapper",
          )}
        >
          <button
            disabled={disabled}
            onClick={handleClick}
            style={{
              appearance: "none",
              padding: 5,
              border: 0,
              outline: 0,
              cursor: "pointer",
              background: "#10b997",
              borderRadius: 3,
              margin: 0,
              ...style,
            }}
            className={clsx(className, "gh-button-copy-to-clipboard")}
          >
            <div
              style={{
                position: "relative",
                height: 16,
                width: 16,
              }}
            >
              <Clippy
                className={clsx("gh-button-copy-clippy", {
                  "gh-button-copy-clippy-animation": copied,
                })}
              />
              <Check
                isVisible={copied}
                className={clsx("gh-button-copy-check", {
                  "gh-button-copy-check-animation": copied,
                })}
              />
            </div>
          </button>
          <span className="gh-leg-link-customs-text">Copy</span>
        </div>
      </div>
    </div>
  );
};

function Clippy(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2_22)">
        <path d="M11.6 2.7998H2V15.5998H11.6V2.7998Z" stroke="white" strokeWidth="1.5" />
        <path d="M4 0.399902H14V13.5999" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_2_22">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function Check(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
}

ButtonCopyToClipboard.propTypes = {
  textToBeCopied: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
ButtonCopyToClipboard.defaultProps = {};
export default ButtonCopyToClipboard;
