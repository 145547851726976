import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { LocationAutocompleteList, LocationAutocompleteWrapper } from "../index";
import { DebounceInput } from "react-debounce-input";
import getTextWidth from "../../../utils/getTextWidth";
import FlagIcon from "../FlagIcon";
import Tooltip from "../Tooltip";
import staticService from "../../../services/static.service";

import "./autoCompleteInput.css";

const calculateTextWidth = getTextWidth();

const AutocompleteInput = (props) => {
  const {
    placeholder,
    fieldState: { error },
    label,
    debounce,
    showFlagIcon,
    value,
    onChange,
    secondaryInputText,
    inputRef,
    tooltipSide,
    coverageWarning,
    showCoverage,
    place,
  } = props;
  const [inputMaxWidth, setInputMaxWidth] = useState(undefined);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [innerWidth, setInnerWidth] = useState();
  const input = useRef();

  const updateResize = () => setInnerWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateResize);

    return () => {
      return window.removeEventListener("resize", updateResize);
    };
  }, []);

  const generateAdditionalInfoWidth = () => {
    if (input.current) {
      const _input = input.current.querySelector("input");
      const getInputnputWidth = () => {
        const inputStyles = window.getComputedStyle(_input);
        const inputPadding =
          parseInt(inputStyles.getPropertyValue("padding-left")) +
          parseInt(inputStyles.getPropertyValue("padding-right"));

        return _input.offsetWidth - inputPadding;
      };
      const inputWidth = getInputnputWidth();
      const inputValueWidth = calculateTextWidth(value, _input);

      return inputWidth - inputValueWidth - 10;
    }

    return 0;
  };

  useEffect(() => {
    if (value && value.length && secondaryInputText && secondaryInputText.length) {
      const maxWidth = generateAdditionalInfoWidth();

      setInputMaxWidth({ maxWidth });

      if (maxWidth > 16) setShowAdditionalInfo(true);
      else setShowAdditionalInfo(false);
    } else {
      setShowAdditionalInfo(false);
    }
  }, [value, secondaryInputText, innerWidth]);

  const getAdditionalInfo = useCallback(() => {
    if (showAdditionalInfo && secondaryInputText) {
      const data = secondaryInputText.split("|");

      return (
        <div className="gh-autocomplete-info" style={{ ...inputMaxWidth }}>
          <span className="info-item">{data.filter((content) => !!content).join(" • ")}</span>
        </div>
      );
    }

    return null;
  }, [secondaryInputText, showAdditionalInfo, value, inputMaxWidth]);

  return (
    <LocationAutocompleteWrapper place={place}>
      {({ locations, search, handleClickOutside, loader }) => {
        return (
          <div className="uk-position-relative">
            {label ? (
              <label className="uk-form-label leg-form-label">
                {label}
                {showFlagIcon ? (
                  <Tooltip
                    pos={`bottom-${tooltipSide}`}
                    refEl="self"
                    wrapperClassName="uk-margin-small-left"
                    trigger={<FlagIcon />}
                  >
                    {staticService.findByAlias("modifiedFromOriginal")}
                  </Tooltip>
                ) : (
                  ""
                )}
              </label>
            ) : (
              ""
            )}

            <div
              className="gh-wrapper-autocomplete-input gh-location-input-wrapper"
              ref={input}
              style={{ maxWidth: 480 }}
            >
              <DebounceInput
                type="search"
                value={value}
                className={["default-input-style uk-input"]}
                placeholder={placeholder ? placeholder : ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const input = e.nativeEvent.target;
                  onChange(value);
                  if (value.length >= 3) {
                    if (input) input.setAttribute("aria-expanded", "true");
                    return search(value);
                  }
                }}
                debounceTimeout={debounce || 500}
                inputRef={inputRef}
              />
              {showAdditionalInfo && getAdditionalInfo()}
              {coverageWarning && showCoverage && (
                <span
                  className="coverage-warning"
                  title={staticService.findByAlias("noHelicoptersInFleet")}
                >
                  {staticService.findByAlias("noHelicoptersInFleet")}
                </span>
              )}
              {loader ? (
                <div data-uk-spinner="ratio: 0.8" className="input-autocomplete-loader" />
              ) : (
                ""
              )}
            </div>
            <LocationAutocompleteList
              relativeEl={input.current}
              onSelect={(val) => {
                props.onChange(val);
                handleClickOutside();
              }}
              locations={locations}
            />
            {error && error.message ? <div className="gh-message-error">{error.message}</div> : ""}
          </div>
        );
      }}
    </LocationAutocompleteWrapper>
  );
};

AutocompleteInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  debounce: PropTypes.number,
  showFlagIcon: PropTypes.bool,
  fieldState: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  secondaryInputText: PropTypes.string,
  inputRef: PropTypes.func,
  tooltipSide: PropTypes.string.isRequired,
  coverageWarning: PropTypes.bool,
  showCoverage: PropTypes.bool,
  place: PropTypes.string,
};

AutocompleteInput.defaultProps = {
  showCoverage: false,
  place: "",
};

export default AutocompleteInput;
