import React, { useState } from "react";
import PropTypes from "prop-types";

import "./passengerInfoDetail.css";
import staticService from "../../../services/static.service";
import { CustomValidateError } from "../../../shared";

const renderOtherItem = (
  data,
  editing,
  updatePassengersInfo,
  legId,
  category,
  itemKey,
  legIndex,
) => (
  <div className="gh-passengers-info-row-other">
    {editing ? (
      <textarea
        value={data[itemKey]}
        className="uk-textarea other-textarea"
        onChange={(e) => {
          updatePassengersInfo(category, { ...data, [itemKey]: e.target.value }, legIndex, true);
        }}
      />
    ) : !editing && data[itemKey] ? (
      <div>{data[itemKey]}</div>
    ) : (
      "--"
    )}
  </div>
);

const PassengerInfoDetail = (props) => {
  const {
    data,
    items,
    editing,
    updatePassengersInfo,
    legId,
    category,
    legIndex,
    passengerAmountError,
  } = props;
  let count = 0;
  items.forEach((item) => {
    if (data[item.key] && typeof data[item.key] === "number") {
      count += data[item.key];
    }
  });

  if (!count && !editing && items[0].key !== "other") {
    return <div className="gh-empty-info">--</div>;
  }
  return (
    <div className="gh-wrapper-passengers-info">
      {items.map((item, index) => (
        <div key={index} className="gh-wrapper-passengers-info-item">
          {(data[item.key] && item.key !== "other") ||
          (item.key === "total" && item.key !== "other") ||
          (editing && item.key !== "other") ? (
            <div
              ceva={item.totalValue}
              className={`gh-passengers-info-row ${item.key === "total" ? "total-row" : ""} ${
                item.key === "total" && editing ? "editing" : ""
              } ${
                item.key === "total" && passengerAmountError === legIndex
                  ? "gh-quote-form-passengers-error-total"
                  : ""
              }`}
            >
              <div className="gh-passengers-info-row-title">
                {staticService.findByAlias(item.alias) || item.title}
              </div>
              <div className="gh-passengers-info-row-value">
                {editing && item.key !== "total" ? (
                  <div
                    className="btn-counter"
                    onClick={() => {
                      updatePassengersInfo(
                        category,
                        { ...data, [item.key]: data[item.key] === 0 ? 0 : data[item.key] - 1 },
                        legIndex,
                      );
                    }}
                  >
                    -
                  </div>
                ) : (
                  ""
                )}
                <span className="value">
                  {item.key === "total" ? item.totalValue : data[item.key]}
                </span>
                {editing && item.key !== "total" ? (
                  <div
                    className="btn-counter"
                    onClick={() => {
                      updatePassengersInfo(
                        category,
                        { ...data, [item.key]: data[item.key] + 1 },
                        legIndex,
                      );
                    }}
                  >
                    +
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : item.key === "other" ? (
            renderOtherItem(
              data,
              editing,
              updatePassengersInfo,
              legId,
              category,
              item.key,
              legIndex,
            )
          ) : (
            ""
          )}
          {item.key === "total" && passengerAmountError === legIndex && editing ? (
            <CustomValidateError containerClass={["gh-quote-form-passengers-error"]}>
              {[
                <span key={item.key}>
                  {staticService.findByAlias("requestMinPassengersError")}
                </span>,
              ]}
            </CustomValidateError>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

PassengerInfoDetail.propTypes = {
  data: PropTypes.object,
  items: PropTypes.array,
  editing: PropTypes.bool,
  updatePassengersInfo: PropTypes.func,
  legId: PropTypes.number,
  category: PropTypes.string,
  legIndex: PropTypes.number,
  passengerAmountError: PropTypes.number,
};

export default PassengerInfoDetail;
