import AbstractService from "../../services/abstract.service";

class SpinnerService extends AbstractService {
  constructor() {
    super();
    this.type = "default";
    this.forceType = false;
  }

  get Type() {
    return this.type;
  }

  show() {
    this.visible = true;
    this.onChange(true, this.Type);
  }

  hide() {
    this.visible = false;
    this.resetProps();
    this.onChange(false, this.Type);
  }
}

const spinnerInstance = new SpinnerService();

export { spinnerInstance, SpinnerService };
