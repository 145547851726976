import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { operatorBookingTabs, brokerBookingTabs, OPERATOR_MAIN } from "../../../../../configs";
import { BookingLayout } from "../../../../layouts";
import { returnRouteUrl, RouteWithSubRoutes } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import auth from "../../../../../services/auth";
import { BookingGenericListComponent } from "./";
import { loadUserDashboardStatistic } from "../../../../../actions";

class BookingGenericComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersShown: false,
      showFilterIcon: true,
      pageTitle: staticService.findByAlias("brokerBookingSectionTitle"),
    };
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      isFiltersShown: !prevState.isFiltersShown,
    }));
  }

  get layoutParentCssClass() {
    return ["gh-normal"];
  }

  get tabs() {
    return auth.getMainRole() === OPERATOR_MAIN ? operatorBookingTabs : brokerBookingTabs;
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  render() {
    return (
      <BookingLayout
        pageTitle={this.props.pageTitle || this.state.pageTitle}
        navigationTabs={this.tabs}
        isFiltersShown={this.state.isFiltersShown}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        parentClassname={this.layoutParentCssClass}
        activeRoute={this.props.match}
        isFilterVisible={this.state.showFilterIcon}
        filterCss={this.state.filterFailed}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  title={route.title}
                  {...returnRouteUrl(route, this.props.match.url)}
                  onEnter={this.onEnterPage.bind(this)}
                />
              );
            })}
          <Route
            path="/"
            render={(props) => (
              <BookingGenericListComponent
                {...props}
                match={this.props.match}
                isFiltersShown={this.state.isFiltersShown}
              />
            )}
          />
        </Switch>
      </BookingLayout>
    );
  }
}

function mapStateToProps({ pageTitle }) {
  return { pageTitle };
}

const COMPONENT = connect(mapStateToProps, {})(BookingGenericComponent);
export { COMPONENT as BookingGenericComponent };
