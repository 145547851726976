import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";

export default function OptionsItem(props) {
  const { className, children, onClick, danger } = props;

  return (
    <button
      type="button"
      className={cs("calculator-reset-btn options-btn", className, danger && "danger")}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

OptionsItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  danger: PropTypes.bool,
};
