import PropTypes from "prop-types";
import React from "react";

const TabTitle = ({ title, setSelectedTab, index, selectedTab, counter }) => {
  const onClick = React.useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  const isActiveClass = React.useMemo(() => {
    return selectedTab === index ? "active" : "";
  }, [selectedTab, index]);

  return (
    <li className={`gh-tabs-new-design-tab ${isActiveClass}`} onClick={onClick}>
      <Title title={title} />
      {counter ? <span className="gh-tabs-new-design-counter">{counter}</span> : ""}
    </li>
  );
};

TabTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(TabTitle);

const Title = ({ title }) =>
  typeof title === "string" ? title : typeof title === "function" ? title() : "";
