import React from "react";
import { Redirect, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, Form, Errors, actions } from "react-redux-form";
import { login } from "../../actions";
import Auth from "../../services/auth";
import { validationRules, validationMessages } from "../../configs";
import { CustomValidateError } from "../../shared";
import staticService from "../../services/static.service";
import { Helmet } from "react-helmet/es/Helmet";

const { required, validEmail } = validationRules;

class LoginFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      modelName: "userLogin",
      invalidCredentials: "userLogin.invalid_credentials",
    };
    context.store.dispatch(actions.reset(this.state.modelName));
    this.modelUpdate = this.modelUpdate.bind(this);
  }

  modelUpdate(model, value) {
    return (dispatch) => {
      dispatch(actions.resetValidity(model));
      dispatch(actions.resetValidity(this.state.invalidCredentials));
      dispatch(actions.change(model, value));
    };
  }

  render() {
    if (this.props.isVisible && !Auth.isAuthenticated()) {
      return (
        <Form
          model={this.state.modelName}
          className={"uk-form-stacked"}
          validators={{
            email: { required, validEmail },
            password: { required },
          }}
          onSubmit={this.props.actions.login}
        >
          <Helmet>
            <link rel="canonical" href="https://getheli.com/account/login" />
          </Helmet>
          <div className={"uk-width-1-1@s uk-width-1-2@m uk-align-center"}>
            <label
              className={"gh-dark-blue"}
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("yourEmail") }}
            />
            <Control.text
              type={"email"}
              className={"uk-input"}
              model={".email"}
              changeAction={this.modelUpdate}
              updateOn="change"
            />
            <Errors
              model=".email"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("yourEmail"),
                ),
                validEmail: validationMessages().validEmailMessage,
              }}
            />
          </div>

          <div className={"uk-width-1-1@s uk-width-1-2@m uk-align-center"}>
            <label
              className={"gh-dark-blue"}
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("password") }}
            />
            <Control.text
              className={"uk-input"}
              type={"password"}
              model={".password"}
              changeAction={this.modelUpdate}
              updateOn="change"
            />
            <Errors
              wrapper={CustomValidateError}
              model=".password"
              show="touched"
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("password"),
                ),
              }}
            />
          </div>
          <div className={"uk-width-1-1@s uk-width-1-2@m uk-align-center uk-margin-small-top"}>
            <Errors
              model=".invalid_credentials"
              wrapper={(props) =>
                CustomValidateError({ ...props, containerClass: ["gh-input-errors_static"] })
              }
            />
          </div>
          <div className={"uk-width-1-1@s uk-width-1-2@m uk-align-center"}>
            <div className="uk-flex uk-width-1-1@s uk-flex-middle uk-margin-small-top uk-align-left uk-margin-remove-right">
              <label>
                <Control.text
                  className="uk-checkbox uk-margin-small-right"
                  type="checkbox"
                  model={".keep_logged"}
                  changeAction={this.modelUpdate}
                  updateOn="change"
                  value={"checked" ? 1 : 0}
                />
                {staticService.findByAlias("keepLogged")}
              </label>
            </div>
          </div>
          <div className={"uk-width-1-1@s uk-width-1-2@m uk-align-center"}>
            <div>
              <div className="uk-flex uk-flex-middle uk-margin-small-top uk-align-left uk-margin-remove-right">
                <button
                  type="submit"
                  className={"uk-button uk-button-primary uk-align-center"}
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("signIn") }}
                ></button>
                <NavLink
                  to={"/account/password/forgotten"}
                  className={"uk-link-text uk-align-left uk-margin-medium-left"}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("forgotPassword"),
                    }}
                  ></span>
                </NavLink>
              </div>
            </div>
          </div>
        </Form>
      );
    }
    return <Redirect to="/" />;
  }
}

LoginFormComponent.propTypes = {
  openModal: PropTypes.func.isRequired,
  staticContentLoaded: PropTypes.bool,
};

LoginFormComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    errors: state.errorObj,
    isVisible: state.auth.id ? false : true,
    forgottenStatus: state.auth && state.auth.forgot ? true : false,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        login: login,
      },
      dispatch,
    ),
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent);
export { COMPONENT as LoginFormComponent };
