import auth from "../services/auth";

export const marketRequestConfig = {
  headers: [
    { title: "gridHeaderID", filter: true, sortColumn: "id", css: "gh-width-grid-id" },
    {
      title: "gridHeaderFrom",
      filter: true,
      sortColumn: "start_place",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderTo",
      filter: true,
      sortColumn: "end_place",
      css: "gh-min-grid-col",
    },
    { title: "gridHeaderPassengers", filter: false, css: "uk-text-center" },
    { title: "gridHeaderOn", filter: true, sortColumn: "departure" },
  ],
  defaultOrder: { field: "departure", sortOrder: "asc" },
  columns: ["displayID", "from", "to", "passengers", "on"],
  headersApplied: false,
};

export const operatorMarketRequestConfig = {
  headers: [
    { title: "gridHeaderID", filter: true, sortColumn: "id", css: "gh-width-grid-id" },
    {
      title: "gridHeaderPostedOn",
      filter: true,
      sortColumn: "created_at",
      css: "gh-width-grid-col",
    },
    {
      title: "gridHeaderFrom",
      filter: true,
      sortColumn: "start_place",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderTo",
      filter: true,
      sortColumn: "end_place",
      css: "gh-min-grid-col",
    },
    { title: "gridHeaderLegs", filter: false, css: "uk-text-center" },
    { title: "gridHeaderPassengers", filter: false, css: "uk-text-center" },
    { title: "gridHeaderOn", filter: true, sortColumn: "departure" },
    {
      title: "gridHeaderStatus",
      filter: false,
      sortColumn: "status",
      css: "uk-text-center",
    },
  ],
  defaultOrder: { field: "departure", sortOrder: "asc" },
  // orderByID: { field: "id", sortOrder: "desc" },
  orderByOn: { field: "departure", sortOrder: "desc" },
  columns: [
    "displayID",
    "created_at",
    "from",
    "to",
    "totalLegs",
    "passengers",
    "on",
    "quotes_per_company",
  ],
  headersApplied: false,
  filters: ["id", "start_place", "end_place", "departure"],
};

export const requestListConfig = {
  headers: [
    {
      title: "gridHeaderID",
      filter: true,
      sortColumn: "id",
      css: "gh-width-grid-id",
    },
    {
      title: "brokerName",
      filter: true,
      sortColumn: "broker_name",
    },
    {
      title: "gridHeaderStatus",
      filter: true,
      sortColumn: "status",
      css: "gh-width-grid-status",
    },
    { title: "gridHeaderIdentifier", sortColumn: "client_ref", filter: true },
    {
      title: "gridHeaderFrom",
      filter: true,
      sortColumn: "start_place",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderTo",
      filter: true,
      sortColumn: "end_place",
      css: "gh-min-grid-col",
    },
    { title: "gridHeaderLegs", filter: false, css: "uk-text-left" },
    {
      title: "gridHeaderOn",
      filter: true,
      sortColumn: "departure",
      css: "gh-width-grid-on",
    },
    { title: "gridHeaderQuotes", filter: false },
    {
      title: "gridHeaderProgress",
      filter: false,
      css: "gh-width-grid-col-progress",
    },
  ],
  defaultOrder: { field: "departure", sortOrder: "asc" },
  activeOrder: { field: "departure", sortOrder: "asc" },
  columns: [
    "displayID",
    "brokerName",
    "status",
    "identifier",
    "from",
    "to",
    "totalLegs",
    "on",
    "totalQuotes",
    "progress",
  ],
  filters: [
    { field: "radius", type: "string" },
    { field: "lat", type: "string" },
    { field: "lng", type: "string" },
    { field: "source", type: "string" },
    { field: "date", type: "string" },
    { field: "active", type: "bool" },
    { field: "activity", type: "string" },
  ],
  headersApplied: false,
};

export const quotesConfig = {
  headers: [
    {
      title: "gridQoutesHeaderID",
      filter: true,
      sortColumn: "id",
      css: "gh-width-grid-id gh-quote-header",
    },
    {
      title: "gridRequestHeaderID",
      filter: true,
      sortColumn: "booking_request_id",
      css: "gh-width-grid-id",
    },
    { title: "gridHeaderFrom", filter: false, css: "gh-min-grid-col" },
    { title: "gridHeaderTo", filter: false, css: "gh-min-grid-col" },
    {
      title: "gridHeaderPassengers",
      filter: false,
      css: "uk-text-center, gh-small-grid-col",
    },
    {
      title: "gridHeaderOn",
      filter: true,
      sortColumn: "departure_time",
      css: "gh-small-grid-col",
    },
    { title: "gridHeaderQuoteStatus", filter: true, sortColumn: "status", css: "" },
    {
      title: "gridHeaderQuotedOn",
      filter: true,
      sortColumn: "submitted_at",
      css: "gh-small-grid-col gh-col-submitted-at",
    },
    {
      title: "gridHeaderProgress",
      filter: false,
    },
  ],
  defaultOrder: { field: "id", sortOrder: "desc" },
  orderByDate: { field: "departure_time", sortOrder: "asc" },
  orderBySubmittedAt: { field: "submitted_at", sortOrder: "desc" },
  columns: [
    "displayID",
    "displayRequestID",
    "from",
    "to",
    "passengers",
    "on",
    "status",
    "submitted_at",
    "progress",
  ],
  headersApplied: false,
  filters: [
    { field: "status", type: "string" },
    { field: "from", type: "string" },
    { field: "to", type: "string" },
    { field: "aircrafts", type: "array" },
    { field: "selectedAircrafts", type: "array" },
    { field: "active", type: "bool" },
    { field: "activity", type: "string" },
    { field: "departure_time", type: "string" },
  ],
};

export const bookingConfigOperatorParts = {
  headers: [
    {
      title: "broker",
      filter: true,
      sortColumn: "broker_company",
    },
  ],
  columns: ["broker_company"],
};

export const bookingConfigBrokerParts = {
  headers: [
    {
      title: "clientIdentifier",
      filter: true,
      sortColumn: "client_identifier",
    },
  ],
  columns: ["client_identifier"],
};

export const bookingConfig = {
  headers: [
    { title: "gridBookingsHeaderID", filter: true, sortColumn: "id", css: "gh-width-grid-id" },
    { title: "gridHeaderFrom", filter: false, css: "gh-min-grid-col" },
    { title: "gridHeaderTo", filter: false, css: "gh-min-grid-col" },
    { title: "gridHeaderPassengers", filter: false },
    { title: "gridHeaderOn", filter: true, sortColumn: "departure_time" },
    { title: "gridHeaderQuote", filter: false },
    //  here will be added the dynamic header(client/broker) func: addBrokerOrClientColumnToTable
    { title: "gridHeaderStatus", filter: false },
    { title: "gridHeaderPayment", filter: false },
  ],
  defaultOrder: { field: "id", sortOrder: "desc" },
  columns: [
    "displayID",
    "from",
    "to",
    "passengers",
    "on",
    "price",
    //  here will be added the dynamic column func: addBrokerOrClientColumnToTable
    "status",
    "payment_status",
  ],
  filters: ["from", "to", "departure_time", "activity"],
  headersApplied: false,
};

export const emptyLegConfig = {
  headers: [
    {
      title: "gridHeaderPostedOn",
      filter: true,
      sortColumn: "created_at",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderFrom",
      filter: true,
      sortColumn: "from_name",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderTo",
      filter: true,
      sortColumn: "to_name",
      css: "gh-min-grid-col",
    },
    { title: "gridHeaderOn", filter: true, sortColumn: "date" },
    { title: "gridHeaderHelicopter", filter: false },
    { title: "gridHeaderRequested", filter: false },
  ],
  defaultOrder: { field: "created_at", sortOrder: "desc" },
  columns: ["posted_on", "from", "to", "date", "helicopter", "requested"],
  headersApplied: false,
};

export const pipelinesConfig = {
  headers: [
    {
      title: "leadId",
      filter: true,
      sortColumn: "lead_id",
    },
    {
      title: "created",
      filter: true,
      sortColumn: "created_at",
    },
    {
      title: "assignedTo",
      filter: true,
      sortColumn: "assigned_to",
    },
    {
      title: "fullName",
      filter: false,
    },
    {
      title: "gridHeaderFrom",
      filter: false,
      sortColumn: "from_name",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderTo",
      filter: false,
      sortColumn: "to_name",
      css: "gh-min-grid-col",
    },
    { title: "gridHeaderOn", filter: true, sortColumn: "departure_at" },
    {
      title: "status",
      filter: true,
      sortColumn: "status",
      css: "gh-pipeline-status",
    },
    // { title: "gridHeaderHelicopter", filter: false },
    // { title: "gridHeaderRequested", filter: false },
  ],
  defaultOrder: { field: "created_at", sortOrder: "desc" },
  columns: [
    "lead_id",
    "created_at",
    "assigned_to",
    "name",
    "from_clamp",
    "to_clamp",
    "departure",
    "status_label",
  ],
  headersApplied: false,
};

export const operatorEmptyLegConfig = {
  headers: [
    {
      title: "gridHeaderFrom",
      filter: true,
      sortColumn: "from_name",
      css: "gh-min-grid-col",
    },
    {
      title: "gridHeaderTo",
      filter: true,
      sortColumn: "to_name",
      css: "gh-min-grid-col",
    },
    { title: "gridHeaderOn", filter: true, sortColumn: "date" },
    { title: "gridHeaderHelicopter", filter: false },
    { title: "gridHeaderRequests", filter: false, css: "uk-text-center" },
  ],
  defaultOrder: { field: "date", sortOrder: "asc" },
  columns: ["from", "to", "date", "helicopter", "requests"],
  headersApplied: false,
};

export const notificationsConfig = {
  headers: [],
  columns: ["data", "updated_at"],
};

export const paymentsConfig = {
  headers: [
    {
      title: "gridHeaderBookingFlight",
      filter: false,
      sortColumn: "displayID",
    },
    { title: "gridHeaderStatus", filter: false, sortColumn: "status" },
    { title: "gridHeaderTeamMember", filter: false, sortColumn: "email" },
    {
      title: "gridHeaderPaymentMethod",
      filter: false,
      sortColumn: "last_four",
      css: "uk-text-center",
    },
    {
      title: "gridHeaderPrice",
      filter: false,
      sortColumn: "sum",
      css: "uk-text-center",
    },
  ],
  columns: ["displayID", "status", "email", "last_four", "sum"],
  defaultOrder: { field: "sum", sortOrder: "desc" },
  paginationLimit: 10,
  headersApplied: false,
};

export const operatorPaymentConfig = {
  headers: [
    { title: "gridHeaderBooking", filter: false, sortColumn: "displayID" },
    { title: "gridHeaderStatus", filter: false, sortColumn: "status" },
    { title: "gridHeaderTeamMember", filter: false, sortColumn: "email" },
    {
      title: "gridHeaderPaymentMethod",
      filter: false,
      sortColumn: "last_four",
      css: "uk-text-center",
    },
    {
      title: "gridHeaderPrice",
      filter: false,
      sortColumn: "sum",
      css: "uk-text-center",
    },
  ],
  columns: ["displayID", "status", "email", "last_four", "sum"],
  defaultOrder: { field: "sum", sortOrder: "desc" },
  paginationLimit: 10,
  headersApplied: false,
};

export const teamMembersConfig = {
  headers: [
    { title: "fullName", filter: false },
    { title: "gridHeaderEmail", filter: false },
    {
      title: "gridHeaderAdmin",
      filter: false,
      tooltip: { messageAlias: "adminTooltip" },
    },
    { title: "&nbsp;", filter: false, css: "gh-team-remove-icon" },
  ],
  columns: ["full_name", "email", "is_admin", "remove"],
  paginationLimit: 10,
  headersApplied: false,
};

export const invitationConfig = {
  headers: [
    { title: "gridHeaderEmail", filter: false },
    { title: "gridHeaderAdmin", filter: false },
    { title: "", filter: false },
  ],
  columns: ["email", "is_admin", "remove"],
  paginationLimit: 10,
  headersApplied: false,
};

export const BookingFilters = [
  { alias: "allBookingActivityTabBroker", active: true, filter: "all" },
  { alias: "confirmedBookingsFilter", active: false, filter: "confirmed" },
  { alias: "provisionalBooking", active: false, filter: "provisional" },
  { alias: "bookingCancellationsFilter", active: false, filter: "cancelled" },
  { alias: "previousBookingsFilter", active: false, filter: "completed" },
];

export const brokerRequestsFilters = [
  { alias: "allRequestsFilter", active: true, filter: "all" },
  { alias: "openRequestsFilter", active: false, filter: "open" },
  { alias: "quotesToReviewFilter", active: false, filter: "review" },
  { alias: "bookingRequestedFilter", active: false, filter: "pending" },
  { alias: "withdrawnFilter", active: false, filter: "withdrawn" },
  { alias: "expiredFilter", active: false, filter: "expired" },
];

export const operatorEmptyLegsFilters = [
  {
    alias: "emptyLegsOfferedFilter",
    active: true,
    filter: "open",
  },
];

export const operatorQuotesFilters = [
  { alias: "allQuotesFilter", active: true, filter: "all" },
  { alias: "preQuotesFilter", active: true, filter: "pre_quotes" },
  { alias: "openQuotesFilter", active: false, filter: "open" },
  { alias: "bookingRequestedFilter", active: false, filter: "booking_init" },
  { alias: "unsuccessfulQuotesFilter", active: false, filter: "rejected-unsuccessful" },
  // { alias: "amendedQuotesFilter", active: false, filter: "amended" },
  { alias: "withdrawnQuotesFilter", active: false, filter: "withdrawn" },
];

export const clientsListConfig = {
  headers: [
    { title: "Client Identifier", filter: true, sortColumn: "client_identifier" },
    { title: "First Name", filter: true, sortColumn: "first_name" },
    { title: "Last Name", filter: true, sortColumn: "last_name" },
    { title: "Weight", filter: true, sortColumn: "weight" },
    { title: "Phone", filter: true, sortColumn: "phone" },
    { title: "Email", filter: true, sortColumn: "email" },
  ],
  columns: ["client_identifier", "first_name", "last_name", "weight", "phone", "email"],
  paginationLimit: 10,
  headersApplied: true,
};
