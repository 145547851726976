import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import moment from "moment";
import { config } from "../../../configs";
import Tooltip from "../Tooltip";

import "./ExpirationBanner.css";

const ExpirationBanner = (props) => {
  const { date } = props;
  const isQuoteExpired = moment().isAfter(date);

  return (
    <Tooltip
      pos="bottom-center"
      refEl="self"
      show={isQuoteExpired}
      trigger={
        <div className={`gh-quote-expired-wrapper ${isQuoteExpired ? "--expired" : ""}`}>
          {staticService.findByAlias(isQuoteExpired ? "quoteExpiredOn" : "quoteExpiresOn")}
          <span>{moment(date).format(config.dateFormat)}</span>
          <span>at</span>
          <span>{moment(date).format(config.timeFormat)}</span>
          <span>
            (UTC
            {moment(date).format("Z")})
          </span>
        </div>
      }
    >
      {staticService.findByAlias("expiratedQuoteTooltip")}
    </Tooltip>
  );
};

ExpirationBanner.propTypes = {
  date: PropTypes.string,
};

export default ExpirationBanner;
