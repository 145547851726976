import React from "react";
import { actions, Control } from "react-redux-form";
import { IconComponent, returnArray } from "../../../shared";
import { BlockComponent } from "../../common";
import staticService from "../../../services/static.service";

class BaseRegisterFormTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainTitle: "",
      modelName: "",
      subTitle: "",
      showLoader: false,
      step: 1,
    };
    this.skip = this.skip.bind(this);
  }

  skip() {
    this.context.store.dispatch(actions.reset(this.state.modelName));
    this.props.changeView();
  }

  onSubmit(data) {
    this.setState({ showLoader: true });
  }

  getRemoveIcon(index) {
    return (
      <td className="uk-width-1-2">
        <a className="uk-link-text" onClick={this.remove.bind(this, index)}>
          <IconComponent visible={true} className={"close"} icon={"close"} id={"close" + index} />
        </a>
      </td>
    );
  }

  get mainTitle() {
    return (
      <BlockComponent
        visible={true}
        containerClass={"uk-container uk-container-affiliate"}
        parentProps={{ className: "uk-section uk-padding-remove-bottom" }}
      >
        <h3 className={"uk-margin-medium-bottom"}>{this.state.mainTitle}</h3>
      </BlockComponent>
    );
  }

  get fillExtraSpaces() {
    const loop = returnArray(this.props.step);
    return loop.map((li, i) => {
      return (
        <li
          key={i}
          className={`uk-active uk-width-1-${this.props.totalSteps}@s uk-width-1-${this.props.totalSteps}@m`}
        />
      );
    });
  }

  get subTitle() {
    return (
      <ul data-uk-tab className={"disabled-list gh-uk-tab"}>
        <li className="uk-active uk-width-1-4@s uk-width-1-4@m">
          <a href="#" className="uk-text-left">
            {this.state.subTitle}
          </a>
        </li>
      </ul>
    );
  }

  get subTitleMobile() {
    return (
      <ul data-uk-tab className={"disabled-list gh-uk-tab"}>
        <li className="uk-active uk-width-1-1@s uk-width-1-4@m">
          <a href="#" className="uk-text-left">
            {this.state.subTitle}
          </a>
        </li>
      </ul>
    );
  }

  get content() {
    return (
      <BlockComponent
        visible={true}
        containerClass="uk-container gh-container-affiliate"
        parentProps={{
          className: "uk-section uk-padding-remove-top gh-section-statistics",
        }}
      >
        {/*{this.subTitle}*/}
        {this.formContent}
      </BlockComponent>
    );
  }

  get formContent() {
    return null;
  }

  getContinueButtonTemplate(diasbled) {
    return (
      <Control.button
        type={"submit"}
        model={this.state.modelName}
        disabled={{ valid: false } || disabled}
        className={"uk-button uk-button-primary uk-align-center uk-align-right@m"}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("continue"),
          }}
        />
      </Control.button>
    );
  }

  getSkipButtonTemplate() {
    return (
      <Control.button
        type={"button"}
        model={this.state.modelName}
        className="uk-button uk-button-text uk-align-center"
        onClick={this.skip}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("skipStep"),
          }}
        />
      </Control.button>
    );
  }
  getBackButtonTemplate() {
    return (
      <button
        type={"button"}
        onClick={this.back}
        className="uk-button uk-align-center uk-align-left@m gh-register-back-button"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("back"),
          }}
        />
      </button>
    );
  }

  render() {
    if (this.props.visible) {
      return (
        <div>
          {/*{this.mainTitle}*/}
          {this.content}
        </div>
      );
    }
    return null;
  }
}

export { BaseRegisterFormTemplate };
