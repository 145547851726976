import React from "react";
import { Control } from "react-redux-form";
import staticService from "../../../../services/static.service";
import { updateModel } from "../../../../shared";

export const CheckCard = ({ title, model, warning }) => {
  return (
    <div className={`gh-check-card ${warning ? "pastel" : ""}`}>
      <label>
        <span>{staticService.findByAlias(title)}</span>
        <Control.checkbox
          className={`uk-checkbox ${warning ? "gh-yellow-checkbox" : ""}`}
          model={model}
          style={{ transform: "translateY(2px)" }}
          changeAction={updateModel}
          updateOn="change"
        />
      </label>
    </div>
  );
};
