export const pages = [
  // {
  //   path: "/",
  //   title: "HomeTopMenu",
  //   props: {
  //     linkProp: {
  //       className: "",
  //     },
  //     listProp: {
  //       className: "uk-hidden@xl list-link",
  //     },
  //   },
  //   onlineUser: "both",
  //   allPages: true,
  // },
  {
    path: "/events",
    title: "eventsDropDownMenu",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@xl list-link",
      },
    },
    onlineUser: "both",
    allPages: true,
  },
  // {
  //   path: "/pricing",
  //   title: "pricingTopMenu",
  //   props: {
  //     linkProp: {
  //       className: "",
  //     },
  //     listProp: {
  //       className: "uk-hidden@xl list-link",
  //     },
  //   },
  //   onlineUser: "offline",
  //   allPages: false,
  // },
  {
    path: "/destinations",
    title: "destinationsDropDownMenu",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@xl list-link",
      },
    },
    onlineUser: "both",
    allPages: true,
  },
  {
    path: "/contact",
    title: "contactDropDownMenu",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@xl list-link",
      },
    },
    onlineUser: "both",
    allPages: true,
  },
  // {
  //   path: "/",
  //   title: "HomeTopMenu",
  //   props: {
  //     linkProp: {
  //       className: "gh-navlink-title",
  //     },
  //     listProp: {
  //       className: "uk-visible@xl",
  //     },
  //   },
  //   onlineUser: "both",
  //   allPages: true,
  // },
  {
    path: "/events",
    title: "eventsDropDownMenu",
    props: {
      linkProp: {
        className: "gh-navlink-title",
      },
      listProp: {
        className: "uk-visible@xl",
      },
    },
    onlineUser: "both",
    allPages: true,
  },
  {
    path: "/destinations",
    title: "destinationsDropDownMenu",
    props: {
      linkProp: {
        className: "gh-navlink-title",
      },
      listProp: {
        className: "uk-visible@xl",
      },
    },
    onlineUser: "both",
    allPages: true,
  },
  // {
  //   path: "/pricing",
  //   title: "pricingTopMenu",
  //   props: {
  //     linkProp: {
  //       className: "gh-navlink-title",
  //     },
  //     listProp: {
  //       className: "uk-visible@xl",
  //     },
  //   },
  //   onlineUser: "offline",
  //   allPages: false,
  //   hideOnPages: ["pricing"],
  // },
  {
    path: "/contact",
    title: "contactDropDownMenu",
    props: {
      linkProp: {
        className: "gh-navlink-title",
      },
      listProp: {
        className: "uk-visible@xl",
      },
    },
    onlineUser: "both",
    allPages: true,
  },
  // {
  //   path: "/account/register",
  //   title: "signUpMenu",
  //   alias: "signUp",
  //   props: {
  //     linkProp: {
  //       className: "uk-button gh-btn-sign-up gh-auth-btn uk-button-primary",
  //     },
  //     listProp: {
  //       className: "uk-visible@xl",
  //     },
  //   },
  //   onlineUser: "offline",
  //   allPages: true,
  //   hideOnPages: ["register", "signUp", "signUpMenu"],
  // },
  // {
  //   path: "/get-a-demo",
  //   title: "bookADemo",
  //   alias: "bookDemo",
  //   props: {
  //     linkProp: {
  //       className: "uk-button uk-button-primary",
  //     },
  //     listProp: {
  //       className: "uk-visible@xl",
  //     },
  //   },
  //   onlineUser: "both",
  //   allPages: true,
  //   hideOnPages: ["bookDemo", "contact"],
  // },

  // {
  //   path: "/account/register",
  //   title: "signUpMenu",
  //   alias: "signUp",
  //   props: {
  //     linkProp: {
  //       className: "uk-button gh-btn-sign-up gh-auth-btn uk-button-primary gh-bg-transparent",
  //     },
  //     listProp: {
  //       className: "uk-hidden@xl",
  //     },
  //   },
  //   onlineUser: "offline",
  //   allPages: true,
  //   hideOnPages: ["register"],
  // },
  {
    path: "/account/login",
    title: "SignInMenu",
    props: {
      linkProp: {
        className: "uk-button gh-btn-sign-in gh-auth-btn",
      },
      listProp: {
        className: "gh-list-item uk-visible@xl",
      },
    },
    onlineUser: "offline",
    allPages: true,
    hideOnPages: ["login"],
  },
  // {
  //   path: "/get-a-demo",
  //   title: "bookADemo",
  //   alias: "bookDemo",
  //   props: {
  //     linkProp: {
  //       className: "uk-button uk-button-primary gh-mobile-book-btn",
  //     },
  //     listProp: {
  //       className: "uk-hidden@xl",
  //     },
  //   },
  //   onlineUser: "both",
  //   allPages: true,
  // },
  {
    path: "/account/login",
    title: "SignInMenu",
    props: {
      linkProp: {
        className: "uk-button gh-auth-btn uk-button-default gh-bg-transparent",
      },
      listProp: {
        className: "gh-list-item uk-hidden@xl",
      },
    },
    onlineUser: "offline",
    allPages: true,
    hideOnPages: ["login"],
  },

  {
    isDropdown: true,
    path: "/account/logout",
    title: "signOutMenu",
    props: {
      linkProp: {
        className: "uk-margin-small-left uk-margin-medium-right",
      },
      listProp: {
        className: "gh-list-item gh-navlink-title",
      },
    },
    onlineUser: "online",
    hideOnPages: ["register", "login"],
  },
];

export const account = [
  {
    path: "/dashboard",
    title: "DashboardTopmenu",
    props: {
      linkProp: {
        className: "uk-margin-medium-right",
      },
      listProp: {
        className: "uk-visible@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },

  {
    path: "/marketplace",
    paths: {
      broker: "/marketplace/empty-legs",
      operator: "/marketplace",
    },
    title: "MarketPlaceTopmenu",
    props: {
      linkProp: {
        className: "uk-margin-medium-right",
      },
      listProp: {
        className: "uk-visible@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
  {
    path: "/account/fleet",
    title: "fleetMarketplaceTitle",
    props: {
      linkProp: {
        className: "uk-margin-medium-right",
      },
      listProp: {
        className: "uk-visible@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
  {
    path: "/price-calculator",
    title: "priceCalculatorTitle",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
  {
    path: "/dashboard",
    title: "DashboardTopmenu",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
  {
    path: "/marketplace",
    paths: {
      broker: "/marketplace/empty-legs",
      operator: "/marketplace",
    },
    title: "MarketPlaceTopmenu",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
  {
    path: "/marketplace/pipelines",
    title: "PipelinesTopmenu",
    props: {
      linkProp: {
        className: "uk-margin-medium-right",
      },
      listProp: {
        className: "uk-visible@l",
      },
    },
    onlineUser: "online",
    roles: ["broker_admin", "sole_trader", "broker_team"],
  },
  {
    path: "/marketplace/pipelines",
    title: "PipelinesTopmenu",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@l",
      },
    },
    onlineUser: "online",
    roles: ["broker_admin", "sole_trader", "broker_team"],
  },
  {
    path: "/account/fleet",
    title: "fleetMarketplaceTitle",
    props: {
      linkProp: {
        className: "",
      },
      listProp: {
        className: "uk-hidden@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
  {
    path: "/price-calculator",
    title: "priceCalculatorTitle",
    props: {
      linkProp: {
        className: "uk-margin-medium-right",
      },
      listProp: {
        className: "uk-visible@l",
      },
    },
    onlineUser: "online",
    allPages: true,
  },
];

export const tabs = [
  {
    alias: "broker",
    title: "Broker",
    active: true,
  },
  {
    alias: "operator",
    title: "Operator",
    active: false,
  },
];

export const signUpTabs = [
  {
    alias: "accountTab",
    title: "account",
    active: true,
  },
  {
    alias: "companyTab",
    title: "company",
    active: false,
  },
  {
    alias: "invitationTab",
    title: "invitation",
    active: false,
  },
  {
    alias: "fleetTab",
    title: "fleet",
    active: false,
  },
  {
    alias: "uploadTab",
    title: "upload",
    active: false,
  },
  {
    alias: "cocTab",
    title: "coc",
    active: false,
  },
];

export const fleetTabs = [
  {
    alias: "fleet",
    title: "Fleet",
    active: false,
    exact: false,
    permission: "operator_admin",
    path: "/account/fleet",
    roles: ["operator_team", "operator_admin"],
  },
];

export const fleetTabsExtra = [
  {
    alias: "fleet",
    title: "Fleet",
    active: false,
    exact: false,
    permission: "operator_admin",
    path: "/account/fleet",
    roles: ["operator_team", "operator_admin"],
  },
  {
    alias: "platform-fleet",
    title: "Platform fleet",
    active: false,
    exact: false,
    permission: "operator_admin",
    path: "/account/platform-fleet",
    roles: ["operator_team", "operator_admin"],
  },
];

export const accountTabs = [
  {
    alias: "profile",
    title: "My User Profile",
    active: false,
    exact: false,
    permission: "both",
    path: "/account/profile",
  },
  {
    alias: "info",
    title: "Company",
    active: false,
    exact: false,
    permission: "both",
    path: "/account/information",
    roles: ["broker_admin", "operator_admin", "sole_trader", "broker_team", "operator_team"],
  },
  {
    alias: "aoc",
    title: "AOC",
    active: false,
    exact: false,
    permission: "operator_admin",
    path: "/account/aoc",
    roles: ["operator_team", "operator_admin"],
  },
  {
    alias: "fleet",
    title: "Fleet",
    active: false,
    exact: false,
    permission: "operator_admin",
    path: "/account/fleet",
    roles: ["operator_team", "operator_admin"],
  },
  {
    alias: "banking",
    title: "Banking",
    active: false,
    exact: false,
    permission: "both",
    path: "/account/banking",
    roles: ["broker_admin", "operator_admin", "sole_trader"],
  },
  // {
  //   alias: "pricing",
  //   title: "Pricing Plan",
  //   active: false,
  //   exact: false,
  //   permission: "broker_admin",
  //   path: "/account/pricing-plan",
  //   roles: ["broker_admin", "sole_trader"],
  // },
  {
    alias: "widget-configuration",
    title: "Widget configuration",
    active: false,
    exact: false,
    permission: "broker_admin",
    path: "/account/widget-configuration",
    roles: ["broker_admin", "broker_team", "sole_trader"],
  },
  {
    alias: "agreements",
    title: "Legal",
    active: false,
    exact: false,
    permission: "operator_admin",
    roles: ["broker_admin", "broker_team", "sole_trader"],
    path: "/account/agreements",
  },

  {
    alias: "agreements",
    title: "Terms & Conditions",
    active: false,
    exact: false,
    permission: "broker_admin",
    roles: ["broker_admin", "broker_team", "sole_trader"],
    path: "/account/agreements",
  },
];

export const aocTabs = [
  {
    alias: "AOC",
    title: "AOC",
    active: false,
    exact: false,
    permission: "both",
    path: "/account/aoc",
    roles: ["broker_admin", "operator_admin", "sole_trader", "broker_team", "operator_team"],
  },
];

export const companyTabs = [
  {
    alias: "info",
    title: "Information",
    active: false,
    exact: false,
    permission: "both",
    path: "/company/information",
    roles: ["broker_admin", "operator_admin", "sole_trader", "broker_team", "operator_team"],
  },
  {
    alias: "banking",
    title: "Banking",
    active: false,
    exact: false,
    permission: "both",
    path: "/company/banking",
    roles: ["broker_admin", "operator_admin", "sole_trader"],
  },
];

export const teamTabs = [
  {
    alias: "verification",
    title: "Company Information",
    active: true,
    path: "/account/information",
    roles: ["broker_admin", "operator_admin", "broker_team", "operator_team", "sole_trader"],
  },
  {
    alias: "members",
    title: "Manage Team",
    active: false,
    path: "/account/information/team",
    roles: ["broker_admin", "operator_admin", "broker_team", "operator_team", "sole_trader"],
  },
  {
    alias: "favourites",
    title: "Preferred Operators",
    active: false,
    path: "/account/information/preferred",
    roles: ["broker_admin", "broker_team", "sole_trader"],
  },
  {
    alias: "invitebroker",
    title: "Invite a Broker",
    active: false,
    path: "/account/information/refer",
    roles: ["operator_admin", "operator_team"],
    isTabDisabled: true,
  },
];

export const agreementTabs = [
  {
    alias: "CodeofConductMenu",
    title: "Code of Conduct",
    active: true,
    path: "/account/agreements",
    roles: ["operator_admin", "operator_team"],
  },
  {
    alias: "codeOfConduct",
    title: "Code of Conduct",
    active: true,
    path: "/account/agreements",
    roles: ["broker_admin", "broker_team", "sole_trader"],
  },
  {
    alias: "TheGetHeliPlatformMenu",
    title: "The Get Heli Platform",
    active: false,
    path: "/account/agreements/getheli-platform",
    roles: ["operator_admin", "operator_team"],
  },
  {
    alias: "theGetHeliPlatform",
    title: "The Get Heli Platform",
    active: false,
    path: "/account/agreements/getheli-platform",
    roles: ["broker_admin", "broker_team", "sole_trader"],
  },
  {
    alias: "FlightTermsMenu",
    title: "Flight Terms",
    active: false,
    path: "/account/agreements/flight-term",
    roles: ["operator_admin", "operator_team"],
  },
  {
    alias: "paymentPartnerTab",
    title: "Payment Partner",
    active: false,
    path: "/account/agreements/payment-partner",
    roles: ["operator_admin", "operator_team"],
  },
];

export const profileTabs = [
  {
    alias: "myDetails",
    title: "My Details",
    active: true,
    path: "/account/profile",
    roles: ["broker_admin", "operator_admin", "broker_team", "operator_team", "sole_trader"],
  },
  {
    alias: "notificationsAccount",
    title: "Notifications",
    active: false,
    path: "/account/profile/notifications",
    roles: ["broker_admin", "operator_admin", "broker_team", "operator_team", "sole_trader"],
  },
  {
    alias: "changePassword",
    title: "Change password",
    active: false,
    roles: ["broker_admin", "operator_admin", "broker_team", "operator_team", "sole_trader"],
    path: "/account/profile/change_password",
  },
];

export const bankingTabs = [
  {
    alias: "stripe_account",
    title: "Bank Account Details",
    active: false,
    path: "/account/banking/account",
    roles: ["operator_admin"],
  },
  {
    alias: "cards",
    title: "Manage Company Cards",
    active: false,
    path: "/account/banking/cards",
    roles: ["broker_admin", "sole_trader"],
  },
  {
    alias: "transactions",
    title: "Transaction History",
    active: false,
    path: "/account/banking/history",
    roles: ["broker_admin", "operator_admin", "sole_trader"],
  },
];

export const brokerBookingTabs = [
  {
    path: "/flights/my-bookings",
    title: "broker-bookings-nav",
    alias: "my-bookings",
    active: false,
  },
  {
    path: "/flights/requests",
    title: "broker-requests-nav",
    alias: "requests",
    active: true,
  },
];

export const operatorBookingTabs = [
  {
    path: "/flights/my-bookings",
    title: "operator-bookings-nav",
    alias: "my-bookings",
  },
  {
    path: "/flights/quotes",
    title: "operator-quotes-nav",
    alias: "quotes",
  },
  {
    path: "/flights/empty-legs",
    alias: "empty-legs",
    title: "operator-empty-legs-nav",
  },
];

export const operatorMarketplaceTabs = [
  {
    path: "/marketplace/requests",
    alias: "requests",
    title: "operator-requests-tab",
    active: true,
  },
  /*  {
        path: '/marketplace/activity',
        alias: 'activity',
        title: 'activity-tab',
        active: false
      },*/
];

export const brokerMarketplaceTabs = [
  /*  {
        path: '/marketplace',
        title: 'activity-tab',
        alias: 'activity',
        active: true
      },*/
  // {
  //   path: "/marketplace/empty-legs",
  //   alias: "empty-legs",
  //   title: "broker-empty-legs-tab",
  //   active: false,
  // },
];

export const bankAccountTabs = [
  {
    alias: "account_detail",
    title: "Get Heli partners with Stripe for secure financial services",
    active: true,
  },
  {
    alias: "bank_account_detail",
    title: "Bank Account Details",
    active: true,
  },
  // {
  //   alias: 'document',
  //   title: 'Verification documents',
  //   active: false,
  // }
];
export const registerTabs = [
  {
    alias: "account_detail",
    title: "ACCOUNT DETAILS",
    active: true,
  },
  {
    alias: "further_information",
    title: "FURTHER INFORMATION",
    active: false,
  },
];

export const operatorMarketOverview = {
  request: {
    path: "/marketplace/requests",
    alias: "marketplaceRequests",
  },
  booking: {
    path: "/flights/my-bookings/filter/active",
    alias: "marketplaceLiveBooking",
  },
  bookingInception: {
    path: "/flights/my-bookings",
    alias: "marketplaceBookingInception",
  },
};

export const brokerMarketOverview = {
  request: {
    path: "/flights/requests",
    alias: "marketplaceRequests",
  },
  booking: {
    path: "/flights/my-bookings/filter/active",
    alias: "marketplaceLiveBooking",
  },
  bookingInception: {
    path: "/flights/my-bookings",
    alias: "marketplaceBookingInception",
  },
};

export const brokerBooking = {
  aocDocs: {
    path: "/aoc-documents/",
    alias: "aocDocuments",
  },
};
