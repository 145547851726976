import React from "react";
import prev from "../../../../../assets/img/svg/prev.svg";
import PropTypes from "prop-types";

const PrevNextSliderButtons = ({ handlePrev, handleNext }) => {
  return (
    <div className="gh-heli-suggestions-slider-btn-wrapper">
      <button className="gh-heli-suggestions-slider-btn" onClick={handlePrev}>
        <img src={prev} alt="prev" className="gh-helicopter-suggestions-prev-btn" />
      </button>
      <button className="gh-heli-suggestions-slider-btn" onClick={handleNext}>
        <img src={prev} alt="prev" className="gh-helicopter-suggestions-next-btn" />
      </button>
    </div>
  );
};
PrevNextSliderButtons.propTypes = {
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};
export default PrevNextSliderButtons;
