import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { bindActionCreators } from "redux";
import {
  bookingConfig,
  CANCELLED,
  COMPLETED,
  config,
  CONFIRMED,
  CURRENT,
  operatorBookingTabs,
  PROVISIONAL,
  staticText,
} from "../../../../../configs";
import {
  GET_BOOKING_LIST,
  getBookings,
  sendConfirmPaymentReceived,
  sendPaymentInformation,
} from "../../../../../actions";
import {
  ComplexTableComponent,
  filterModel,
  formatID,
  ModalComponent,
} from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import { BookingListComponent } from "../";
import { BookingFiltersComponent } from "./";
import Auth from "../../../../../services/auth";
import auth from "../../../../../services/auth";
import moment from "moment";
import Button from "../../../../common/Button";
import ProvidePaymentModalContent from "../../../../common/ProvidePaymentModalContent";
import ConfirmPaymentModalContent from "./BookingPaymentDetails/components/ConfirmPaymentModalContent";
import { showNotification } from "../../../../../utils/showNotification";

class BookingGenericListComponent extends BookingListComponent {
  constructor(props) {
    super(props);
    const filters = props.match.params.search
      ? [
          filterModel("filterBy", props.match.params.search),
          filterModel("activity", props.filters.activity),
        ]
      : [];
    this.state = {
      bookingStatus: super.getType(props),
      filters: filters,
      sortOrder: super.getSorting(props),
      filterFailed: "",
      filterActive: false,
      navTabs: operatorBookingTabs,
      pageTitle: staticService.findByAlias("ManagePageTitle"),
      customProps: {},
      headers: [],
      columns: [],
      allItems: [],
      staticContentsAliasesForGrid: {
        default: "NoBookings",
        all: "NoBookings",
        current: "NoCurrentConfirmedBooking",
        completed: "NoCompletedBookings",
        active: "NoActiveBookings",
        cancelled: "NoBookingCancellations",
        confirmed: "NoBookingConfirmed",
        provisional: "empty-row-bookings-provisional",
      },
      selectedBooking: null,
      closingModal: false,
      isLoading: false,
    };
    this.reloadGrid = this.reloadGrid.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.handleSendPaymentInfo = this.handleSendPaymentInfo.bind(this);
    this.openPreviewFileModal = this.openPreviewFileModal.bind(this);
    this.resetFilesList = this.resetFilesList.bind(this);
    this.handleConfirmPaymentReceived = this.handleConfirmPaymentReceived.bind(this);
    this.updateTableRows = this.updateTableRows.bind(this);
    this.handleConfirmPayment = this.handleConfirmPayment.bind(this);
    this.handleViewBooking = this.handleViewBooking.bind(this);
  }

  componentDidMount() {
    this.updateTable(this.props.match.params.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.search !== prevProps.match.params.search) {
      this.updateTable(this.props.match.params.search);
    }
  }

  updateTable(filter) {
    const headers =
      filter === "all" || filter === "provisional"
        ? this.paginationService.headers
        : this.paginationService.headers.filter((item) => item.title !== "Payment");

    const columns =
      filter === "all" || filter === "provisional"
        ? this.paginationService.columns
        : this.paginationService.columns.filter((item) => item !== "payment_status");

    this.setState({
      headers: headers,
      columns: columns,
    });
  }

  defaultFilter(props = {}) {
    return Object.assign(
      {
        from: "",
        to: "",
        departure_time: "",
        disableBtn: false,
        refreshCollection: false,
        activity: "all",
        forceUpdate: false,
        date_end: "",
        date_start: "",
      },
      props,
    );
  }

  catchSuccessResponse() {
    super.catchSuccessResponse();
    this.paginationService.setProp("hasLoaded", true);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.paginationService.hasLoaded ||
      JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items)
    ) {
      if (nextProps.filters.refreshCollection) {
        this.setFailed(nextProps.items);
        this.resetFilter("refreshCollection");
        let filters = this.state.filters;
        this.paginationService.setProp("filters", filters);
      }
      if (nextProps.filters.forceUpdate) {
        this.resetFilter("forceUpdate");
      }
      this.paginationService.setProp("hasLoaded", false);

      this.setState({ allItems: nextProps.items });

      this.paginationService.populateItems(
        nextProps.items,
        this.prepareRows.bind(this),
        nextProps.isConcat,
      );
    }

    this.setEmptyRowMessage();
  }

  setEmptyRowMessage() {
    if (Auth.getMainRole() === "operator") {
      const search = this.props.match.params.search;
      let button = { label: "Marketplace", link: "/marketplace" };
      let messageId = "empty-row-bookings-default";
      if (search === CANCELLED) {
        messageId = "empty-row-bookings-cancelled";
        button.label = "Return to Dashboard";
        button.link = "/dashboard";
      } else if (search === CONFIRMED) {
        messageId = "empty-row-bookings-confirmed";
      } else if (search === CURRENT) {
        messageId = "empty-row-bookings-current";
      } else if (search === PROVISIONAL) {
        messageId = "empty-row-bookings-provisional";
        button = {};
      } else if (search === COMPLETED) {
        messageId = "empty-row-bookings-completed";
      }

      this.setState({
        customProps: {
          emptyRowMessage: staticService.findByAlias(messageId),
          emptyRowButton: button,
        },
      });
    } else {
      this.setState({
        customProps: { emptyRowMessage: this.brokerEmptyRowMessage },
      });
    }
  }

  reloadGrid(nextProps) {
    let filters = nextProps.match.params.search
      ? [filterModel("filterBy", nextProps.match.params.search)]
      : [];
    const initSort = this.getSorting(nextProps);
    this.paginationService.setSortingParameters(
      { column: initSort.field, order: initSort.sortOrder },
      "bookingOrder",
      "bookingSorted",
    );
    this.paginationService.reloadItems();
    this.context.store.dispatch(
      getBookings(super.getType(nextProps), 1, filters.concat(this.paginationService.sorting)),
    );
  }

  resetFilter(filter, value = false) {
    this.paginationService.reloadItems();
    this.context.store.dispatch(actions.change("bookingsConfirmedFiltersModel." + filter, value));
  }

  openModal(modal) {
    this[modal].open();
  }

  resetFilesList() {
    this.context.store.dispatch(actions.change("filesExistState", false));
    this.context.store.dispatch(actions.change("provisionalPaymentInformationModel", []));
  }

  closeModal(modal) {
    if (modal === "providePaymentInfo") {
      this.resetFilesList();
      this.setState({ closingModal: true }, () => {
        this.setState({ closingModal: false });
      });
    }

    this.setState({ selectedBooking: null });
    this[modal].hide();
  }

  prepareRows(items) {
    return items.map((item) => {
      const from = item.from;
      const to = item.to;
      const status = staticText.bookingStatuses[item.status];
      const on = moment(item.on).format("DD MMMM, YYYY");
      const currency = Auth.isOperator()
        ? item.operator_currency.currency || config.currencyCode
        : item.user_currency.currency || config.currencyCode;

      const operatorStatus =
        Auth.isOperator() && item.payment_status === 4 ? (
          item.status === CANCELLED ? (
            staticService.findByAlias("cancelled")
          ) : (
            staticService.findByAlias("paid")
          )
        ) : Auth.isOperator() && !item.payment_status ? (
          item.status === CONFIRMED ? (
            staticService.findByAlias("paid")
          ) : item.status === CANCELLED ? (
            staticService.findByAlias("cancelled")
          ) : (
            ""
          )
        ) : item.status === CANCELLED ? (
          staticService.findByAlias("cancelled")
        ) : (
          <span
            className="uk-button gh-req-button gh-payment-table-btn"
            style={{ textTransform: "capitalize", whiteSpace: "nowrap" }}
            onClick={() => {
              this.setState({ selectedBooking: item });
              this.openModal(
                item.payment_status === 1 ? "providePaymentInfo" : "confirmPaymentModal",
              );
            }}
          >
            {staticService.findByAlias(
              item.payment_status === 1 ? "providePaymentInformation" : "confirmPaymentReceived",
            )}
          </span>
        );

      const brokerStatus =
        Auth.isBroker() && item.status === CANCELLED ? (
          staticService.findByAlias("cancelled")
        ) : Auth.isBroker() && (item.status === CONFIRMED || item.status === COMPLETED) ? (
          staticService.findByAlias("paid")
        ) : Auth.isBroker() && item.payment_status === 1 ? (
          <span className="gh-text-warning">
            {staticService.findByAlias("operatorToProvidePaymentInformation")}
          </span>
        ) : Auth.isBroker() && item.payment_status === 4 ? (
          staticService.findByAlias("paid")
        ) : Auth.isBroker() && item.payment_status === 2 ? (
          <span style={{ color: "#e74c3c" }}>Unpaid</span>
        ) : Auth.isBroker() && item.payment_status === 3 ? (
          <span className="gh-text-warning">
            {staticService.findByAlias("operatorToConfirmPayment")}
          </span>
        ) : (
          ""
        );

      return {
        ...item,
        from,
        to,
        status: (
          <span className={item.status === "provisional" ? "gh-text-warning" : "gh-text-black"}>
            {status}
          </span>
        ),
        on,
        payed: `${item.quote_price?.deposit_amount.percent}%`,
        payment_status: Auth.isOperator() ? operatorStatus : brokerStatus,
        displayID: formatID(item.id, config.prefixes.booking),
        catering: item.catering ? "Yes" : "No",
        price: currency + " " + (Auth.isOperator() ? item.price : item.user_total_price),
        client_identifier: item.client_identifier || "---",
        broker_company: item.broker_company_name || "---",
      };
    });
  }

  loadData(status, page) {
    this.setState({ isLoading: true });

    let pf = [...this.paginationService.filters].map((item) =>
      item.key === "activity" && Auth.getRole() === "broker_team"
        ? { key: "activity", value: "own" }
        : item,
    );
    const params = () => {
      if (status) return status;
      if (this.props.match.params.search) return this.props.match.params.search;
      return "all";
    };
    pf.map((item) => {
      if (item.key === "filterBy") {
        item.value = params();
      }
    });

    const onFinish = () => {
      this.setState({ isLoading: false });
    };

    this.context.store.dispatch(
      getBookings(
        params(),
        page ? page : this.paginationService.page,
        pf.concat(this.paginationService.sorting),
        onFinish,
      ),
    );
  }

  handleViewBooking(isAllowed, url) {
    return (event) => {
      event.preventDefault();

      if (auth.getMainRole() === "broker") {
        if (isAllowed) {
          this.props.history.push(url);
        } else {
          showNotification(staticService.findByAlias("restrictAccessForBroker"), "warning");
        }
      } else {
        this.props.history.push(url);
      }
    };
  }

  tableAction(index) {
    const model = this.paginationService.items[index];
    const id = model.id;

    const action = (
      <a
        className={"uk-button uk-button-link"}
        href={`${this.props.match.url}/${id}`}
        onClick={this.handleViewBooking(model.ability_to_view, `${this.props.match.url}/${id}`)}
      >
        View
      </a>
    );
    return {
      desktop: <td style={{ verticalAlign: "middle" }}>{action}</td>,
      mobile: action,
    };
  }

  handleSorting(column, order) {
    this.paginationService.setFilters(this.props.filters, bookingConfig.filters);
    super.handleSorting(column, order);
    this.context.store.dispatch(
      actions.change("bookingsConfirmedFiltersModel.refreshCollection", true),
    );
  }

  get cancelled() {
    return this.props.match.params.search === "cancelled";
  }

  filterToggle(state) {
    this.setState({ filterActive: state });
  }

  get brokerEmptyRowMessage() {
    const alias = this.props.match.params.search ? this.props.match.params.search : "default";
    return staticService.findByAlias(this.state.staticContentsAliasesForGrid[alias]);
  }

  updateTableRows(booking) {
    const updatedList = this.state.allItems.map((item) =>
      item.id === booking.id
        ? {
            ...item,
            payment_status: booking.payment_status,
            status: booking.status,
            highlight: booking.highlight,
          }
        : item,
    );

    this.paginationService.populateItems(updatedList, this.prepareRows.bind(this), false);

    this.paginationService.setProp("loadMoreBtnStatus", updatedList.length % 10 === 0);

    this.setState({ allItems: updatedList });
  }

  openPreviewFileModal(file) {
    this.previewFileOutput.src = URL.createObjectURL(file);
    this.previewFileModal.open();

    setTimeout(() => {
      URL.revokeObjectURL(this.previewFileOutput.src);
    }, 100);
  }

  handleSendPaymentInfo(value) {
    this.props.sendPaymentInformation(this.state.selectedBooking.id, value).then((booking) => {
      this.updateTableRows(booking);
      this.closeModal("providePaymentInfo");
    });
  }

  handleConfirmPaymentReceived(fn = () => {}) {
    fn(true);

    this.props
      .sendConfirmPaymentReceived(this.state.selectedBooking.id)
      .then((booking) => {
        fn(false);
        this.updateTableRows(booking);
        this.closeModal("confirmPaymentModal");
      })
      .catch(() => {
        fn(false);
      });
  }

  handleConfirmPayment() {
    this.props.sendConfirmPaymentReceived(this.state.selectedBooking.id).then((booking) => {
      this.updateTableRows(booking);
      this.closeModal("confirmManualBankTransfer");
    });
  }

  render() {
    const { selectedBooking, isLoading } = this.state;

    return (
      <div>
        <BookingFiltersComponent
          visible={this.props.isFiltersShown}
          onSubmit={this.filterToggle.bind(this, true)}
          onReset={this.filterToggle.bind(this, false)}
          path={this.props.match.params.search || "all"}
          pageFilters={this.state.filters}
          disablePastDate={this.cancelled}
          loadDataFunc={this.loadData}
          history={this.props.history}
          modelFiels={this.props.filters}
          isLoading={isLoading}
        />
        <div className={"uk-overflow-auto uk-margin-bottom"}>
          <ComplexTableComponent
            loadMore={this.paginationService.loadMore.bind(this.paginationService)}
            rows={this.paginationService.items}
            headers={this.state.headers}
            columns={this.state.columns}
            showActionColumn={1}
            showLoader={this.paginationService.showLoaderBtn}
            showMoreText={staticService.findByAlias("showMore")}
            actionElements={this.tableAction}
            selectedOrder={this.state.sortOrder}
            sortChange={this.handleSorting.bind(this)}
            firstTimeLoading={this.paginationService.initialLoadState}
            dispatch={this.context.store.dispatch}
            {...this.state.customProps}
          />
        </div>

        {/*MODALS*/}
        <ModalComponent
          title={staticService.findByAlias("confirmPaymentModalTitle")}
          ref={(confirmPaymentModal) => (this.confirmPaymentModal = confirmPaymentModal)}
          id={"confirmPaymentModal"}
          modalClass="gh-confirm-payment-modal"
          options={{ clsPage: "", bgClose: false, escClose: false }}
        >
          <ConfirmPaymentModalContent
            modalTextAlias={"confirmPaymentModalText"}
            onConfirm={this.handleConfirmPaymentReceived}
            closeModal={() => {
              this.closeModal("confirmPaymentModal");
            }}
          />
        </ModalComponent>

        <ModalComponent
          title={staticService.findByAlias("confirmManualBankTransfer")}
          ref={(confirmManualBankTransfer) =>
            (this.confirmManualBankTransfer = confirmManualBankTransfer)
          }
          id="gh-booking-payment-details-modal"
          options={{ clsPage: "", bgClose: false, escClose: false }}
          onClose={() => {
            this.closeModal("confirmManualBankTransfer");
          }}
        >
          <p className="gh-booking-payment-details-modal-text uk-margin-small-top gh-text-gray">
            {staticService.findByAlias("confirmManualBankTransferModalText")}
          </p>
          <div className="uk-flex uk-flex-center uk-margin-medium-top">
            <Button
              onClick={() => {
                this.closeModal("confirmManualBankTransfer");
              }}
              variant="outlined"
              color="light"
              className="uk-margin-small-right"
            >
              Cancel
            </Button>
            <Button onClick={this.handleConfirmPayment}>Confirm</Button>
          </div>
        </ModalComponent>

        <ModalComponent
          title={staticService.findByAlias("paymentInformationModalTitle")}
          ref={(providePaymentInfo) => (this.providePaymentInfo = providePaymentInfo)}
          id={"providePaymentInfo"}
          modalClass="gh-confirm-payment-modal"
          options={{ clsPage: "", bgClose: false, escClose: false }}
          onClose={() => {
            this.closeModal("providePaymentInfo");
          }}
        >
          <ProvidePaymentModalContent
            closeModal={this.closeModal}
            brokerName={
              selectedBooking && selectedBooking.broker_company
                ? selectedBooking.broker_company.name
                : null
            }
            registeredAddress={
              selectedBooking && selectedBooking.broker_company
                ? selectedBooking.broker_company.address
                : null
            }
            bookingID={selectedBooking ? selectedBooking.id : ""}
            handleSubmit={this.handleSendPaymentInfo}
            openPreviewFileModal={this.openPreviewFileModal}
            closingModal={this.state.closingModal}
          />
        </ModalComponent>

        <ModalComponent
          title=""
          ref={(previewFileModal) => (this.previewFileModal = previewFileModal)}
          id={"previewFileModal"}
          modalClass="gh-confirm-payment-modal"
          options={{ clsPage: "", bgClose: false, escClose: false }}
          onClose={() => {
            this.providePaymentInfo.open();
          }}
        >
          <img
            ref={(previewFileOutput) => (this.previewFileOutput = previewFileOutput)}
            alt="preview"
          />
        </ModalComponent>
      </div>
    );
  }
}

BookingGenericListComponent.contextTypes = {
  store: PropTypes.object,
};

BookingGenericListComponent.propTypes = {
  isFiltersShown: PropTypes.bool,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

BookingGenericListComponent.defaultProps = {
  items: [],
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      refreshBookings: (bookings) => dispatch({ type: GET_BOOKING_LIST, bookings: bookings }),
      sendPaymentInformation: sendPaymentInformation,
      sendConfirmPaymentReceived: sendConfirmPaymentReceived,
    },
    dispatch,
  );
};

function mapStateToProps({
  bookingList,
  screenDimensions,
  bookingsConfirmedFiltersModel,
  pagination,
  isConcat,
}) {
  return {
    items: bookingList,
    filters: bookingsConfirmedFiltersModel,
    ...screenDimensions,
    pagination,
    isConcat,
  };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BookingGenericListComponent);
export { COMPONENT as BookingGenericListComponent };
