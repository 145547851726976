import { config } from "../configs/";
import GetHeliEvents from "../services/event.service";
import staticService from "../services/static.service";

class StripeService {
  constructor(stripe) {
    this.stripe = stripe(config.stripePublicKey);
    this.form = null;
    this.error = null;
    this.valid = true;
    this.errorMessage = null;
    this.replaceStripeErrors = [
      {
        code: "invalid_expiry_year_past",
        message: staticService.findByAlias("carcExpiredNotification"),
      },
      {
        code: "address_line1",
        message: staticService.findByAlias("requiredField").replace("{field}", "address"),
      },
      {
        code: "address_city",
        message: staticService.findByAlias("requiredField").replace("{field}", "city"),
      },
      {
        code: "address_state",
        message: staticService.findByAlias("requiredField").replace("{field}", "county"),
      },
      {
        code: "address_zip",
        message: staticService.findByAlias("requiredField").replace("{field}", "postal code"),
      },
    ];
    // Create an instance of Elements.
    this.elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
        },
      ],
    });
    // Listen for errors from each Element, and show error messages in the UI.
    this.savedErrors = {};
  }

  enableInputs() {
    Array.prototype.forEach.call(
      this.form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
      function (input) {
        input.removeAttribute("disabled");
      },
    );
  }

  disableInputs() {
    Array.prototype.forEach.call(
      this.form.querySelectorAll("input[type='text'], input[type='email'], input[type='tel']"),
      function (input) {
        input.setAttribute("disabled", "true");
      },
    );
    this.savedErrors = {};
  }

  setInputEvents() {
    Array.prototype.forEach.call(this.form.querySelectorAll(".input"), function (input) {
      input.addEventListener("focus", function () {
        input.classList.add("focused");
      });
      input.addEventListener("blur", function () {
        input.classList.remove("focused");
      });
      input.addEventListener("keyup", function (ev) {
        if (input.value && input.value.length === 0) {
          input.classList.add("empty");
          GetHeliEvents.trigger("stripe-form-validation-failed", ev);
        } else {
          ev.target.classList.remove("invalid");
          input.classList.remove("empty");
          GetHeliEvents.trigger("stripe-form-validation-success");
        }
      });
    });
  }

  replaceWithCustomMessages(error) {
    const err = this.replaceStripeErrors.filter((row) => error.code === row.code);
    if (err.length > 0) return err[0].message;
    return error.message;
  }

  setError(_stripeService, idx, error) {
    _stripeService.error.classList.add("visible");
    _stripeService.savedErrors[idx] = _stripeService.replaceWithCustomMessages(error);
    _stripeService.errorMessage.innerText = _stripeService.savedErrors[idx];
    GetHeliEvents.trigger("stripe-form-validation-failed", error);
  }

  validateAdditionalData(elements) {
    let data = {};
    this.valid = true;
    Object.keys(elements).forEach((key, index) => {
      if ((elements[key] === "" || elements[key] === undefined) && this.valid) {
        this.valid = false;
        data = {
          error: {
            code: key,
            message: "",
          },
          idx: index,
        };
      }
    });

    return data;
  }

  setInputErrorsEvents(elements) {
    const _stripeService = this;

    elements.forEach(function (element, idx) {
      element.on("change", function (event) {
        if (event.error) {
          _stripeService.setError(_stripeService, event.elementType, event.error);
        } else {
          _stripeService.savedErrors[event.elementType] = null;

          // Loop over the saved errors and find the first one, if any.
          const nextError = Object.keys(_stripeService.savedErrors)
            .sort()
            .reduce(function (maybeFoundError, key) {
              return maybeFoundError || _stripeService.savedErrors[key];
            }, null);

          if (nextError) {
            // Now that they've fixed the current error, show another one.
            _stripeService.errorMessage.innerText = nextError;
            GetHeliEvents.trigger("stripe-form-validation-failed", nextError);
          } else {
            // The user fixed the last error; no more errors.
            _stripeService.error.classList.remove("visible");
            GetHeliEvents.trigger("stripe-form-validation-success");
          }
        }
      });
    });
  }
}

export { StripeService };
