import React from "react";
import { Control, Fieldset } from "react-redux-form";
import { NumberComponent } from "../../../shared";
import { config } from "../../../configs";
import staticService from "../../../services/static.service";

export const LegNumberFieldset = ({
  model,
  visible,
  updateModel,
  defaultCss = "uk-width-1-1@s uk-width-1-2@m",
  disabled,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Fieldset model={model} className={defaultCss}>
      <div data-uk-grid className="uk-child-width-1-3 uk-margin-remove-left">
        <div className="gh-number-field">
          <Control
            model={model + `.number_adults`}
            className={"uk-input"}
            component={NumberComponent}
            changeAction={updateModel}
            disabled={disabled}
            updateOn="change"
            step={1}
            label={staticService.findByAlias("NumberAdults")}
            required={false}
            max={config.allowed_passengers}
            min={0}
          />
        </div>
        <div className="gh-number-field">
          <Control
            model={model + `.number_children`}
            className={"uk-input"}
            component={NumberComponent}
            changeAction={updateModel}
            disabled={disabled}
            updateOn="change"
            step={1}
            label={staticService.findByAlias("NumberChildren")}
            required={false}
            max={config.allowed_passengers}
            min={0}
          />
        </div>
        <div className="gh-number-field">
          <Control
            model={model + `.number_luggage`}
            className={"uk-input"}
            component={NumberComponent}
            changeAction={updateModel}
            disabled={disabled}
            updateOn="change"
            step={1}
            label={staticService.findByAlias("luggage")}
            required={false}
            max={config.allowed_passengers}
            min={0}
          />
        </div>
      </div>
    </Fieldset>
  );
};
