import Axios from "axios";
import { config, staticText } from "../configs/";
import Auth from "./auth";

class StaticContentService {
  constructor() {
    this.items = [];
  }

  loadItems() {
    const url = `${config.apiDomain}/static`;
    const headers = config.apiHeaders;
    if (Auth.getToken()) {
      headers.Authorization = "Bearer " + Auth.getToken();
    }
    return Axios.get(url, { headers });
  }

  setItems(items = []) {
    this.items = items;
  }

  findByAlias(alias, defaultVal = "") {
    const result = this.items.filter((row) => row.alias === alias);
    return result.length > 0 ? result[0].description : defaultVal;
  }

  setGlobalMessages() {
    //company
    staticText.globalMessages.user.verification.onSuccess.post =
      this.findByAlias("onSuccessCompanyPost");
    staticText.globalMessages.user.verification.onError.post =
      this.findByAlias("onErrorCompanyPost");
    staticText.globalMessages.user.account.onSuccess.put = this.findByAlias(
      "accountUpdateSuccessNotification",
    );
    staticText.globalMessages.user.account.onError.put = this.findByAlias(
      "accountUpdateErrorNotification",
    );

    //passwordRecover
    staticText.globalMessages.user.passwordRecover.onSuccess.post = this.findByAlias(
      "onSuccessPasswordRecoverPost",
    );
    staticText.globalMessages.user.passwordRecover.onError.post = this.findByAlias(
      "onErrorPasswordRecoverPost",
    );
    staticText.globalMessages.user.passwordUpdate.onSuccess.post = this.findByAlias(
      "onSuccessPasswordUpdatePost",
    );
    staticText.globalMessages.user.passwordUpdate.onError.post = this.findByAlias(
      "onErrorPasswordUpdatePost",
    );
    //userRegister
    staticText.globalMessages.user.userRegister.onSuccess.post = this.findByAlias(
      "onSuccessUserRegisterPost",
    );
    staticText.globalMessages.user.userRegister.onError.post =
      this.findByAlias("onErrorUserRegisterPost");
    //notifications
    staticText.globalMessages.user.notifications.onSuccess.post = this.findByAlias(
      "onSuccessNotificationSettingsUpdated",
    );
    //accountDetail
    staticText.globalMessages.user.bankAccount.onSuccess.put =
      this.findByAlias("onSuccessBankAccountPut");
    staticText.globalMessages.user.bankAccount.onError.put =
      this.findByAlias("onErrorBankAccountPut");
    //bankAccount
    staticText.globalMessages.user.accountDetail.onSuccess.put = this.findByAlias(
      "onSuccessAccountDetailPut",
    );
    staticText.globalMessages.user.accountDetail.onError.put =
      this.findByAlias("onErrorAccountDetailPut");
    //cards update
    staticText.globalMessages.user.cards.updated.onSuccess.put =
      this.findByAlias("onSuccessUpdatedCardPut");
    staticText.globalMessages.user.cards.updated.onError.put =
      this.findByAlias("onErrorUpdatedCardPut");
    staticText.globalMessages.user.cards.onSuccess.put = this.findByAlias(
      "onSuccessDefaultCardPost",
    );
    staticText.globalMessages.user.cards.onError.put = this.findByAlias("onErrorDefaultCardPost");
    staticText.globalMessages.user.cards.onSuccess.post = this.findByAlias("onSuccessNewCardPost");
    staticText.globalMessages.user.cards.onError.post = this.findByAlias("onErrorNewCardPost");
    //cards delete
    staticText.globalMessages.user.cards.deleted.onSuccess.put =
      this.findByAlias("onSuccessCardPut");
    staticText.globalMessages.user.cards.deleted.onError.put = this.findByAlias("onErrorCardPut");
    //user role
    staticText.globalMessages.user.role.onSuccess.post = this.findByAlias("onSuccessRolePost");
    staticText.globalMessages.user.role.onError.post = this.findByAlias("onErrorRolePost");
    //aircraft actions
    staticText.globalMessages.team.onSuccess.put = this.findByAlias("onSuccessEditAircraftPut");
    staticText.globalMessages.team.onSuccess.post = this.findByAlias("onSuccessAddAircraftPost");
    staticText.globalMessages.team.onSuccess.delete = this.findByAlias("teamMemberRemoveSuccess");
    staticText.globalMessages.team.onError.put = this.findByAlias("onErrorEditAircraftPut");
    staticText.globalMessages.team.onError.post = this.findByAlias("onErrorAddAircraftPost");
    staticText.globalMessages.team.onError.delete = this.findByAlias("teamMemberRemoveError");
    //booking actions
    staticText.globalMessages.booking.onSuccess.put = this.findByAlias("onSuccessBookingPut");
    staticText.globalMessages.booking.onSuccess.post = this.findByAlias("onSuccessBookingPost");
    staticText.globalMessages.booking.onError.put = this.findByAlias("onErrorBookingPut");
    staticText.globalMessages.booking.onError.post = this.findByAlias("onErrorBookingPost");

    staticText.globalMessages.comment.onSuccess.post = this.findByAlias("onSuccessCommentPost");
    staticText.globalMessages.comment.onError.post = this.findByAlias("onErrorCommentPost");

    //company actions
    staticText.globalMessages.user.upload.onSuccess.post = this.findByAlias("onSuccessUploadPost");
    staticText.globalMessages.user.upload.onError.post = this.findByAlias("onErrorUploadPost");
    staticText.globalMessages.user.upload.onSuccess.delete =
      this.findByAlias("onSuccessUploadDelete");
    staticText.globalMessages.user.upload.onError.delete = this.findByAlias("onErrorUploadDelete");
    staticText.globalMessages.user.verification.onSuccess.put = this.findByAlias(
      "onSuccessVerificationPut",
    );
    staticText.globalMessages.user.verification.onError.put =
      this.findByAlias("onErrorVerificationPut");

    staticText.globalMessages.user.stripe.onSuccess.post =
      this.findByAlias("stripeUploadFileSuccess");
    staticText.globalMessages.user.stripe.onError.post = this.findByAlias("onErrorUploadPost");
    //generic actions
    staticText.globalMessages.conctactForm.onSuccess.post = this.findByAlias(
      "onSuccessConctactFormPost",
    );
    staticText.globalMessages.conctactForm.onError.post =
      this.findByAlias("onErrorConctactFormPost");
    staticText.globalMessages.subscribe.onSuccess.post = this.findByAlias("onSuccessSubscribePost");
    staticText.globalMessages.subscribe.onError.post = this.findByAlias("onErrorSubscribePost");
    //payment actions
    staticText.globalMessages.payment.onSuccess.post = this.findByAlias("onSuccessPaymentPost");
    staticText.globalMessages.payment.onError.post = this.findByAlias("onErrorPaymentPost");
    //quote actions
    staticText.globalMessages.quote.onSuccess.put = this.findByAlias("quoteRejectedNotification");
    staticText.globalMessages.quote.onError.put = this.findByAlias("onErrorQuotePut");
    staticText.globalMessages.quoteWithdrawn.onSuccess.put = this.findByAlias(
      "onSuccessQuoteWithdrawnPut",
    );
    staticText.globalMessages.quoteWithdrawn2.onSuccess.put = this.findByAlias(
      "onSuccessQuoteWithdrawnPut2",
    );
    staticText.globalMessages.quoteWithdrawn.onError.put = this.findByAlias(
      "onErrorQuoteWithdrawnPut",
    );
    staticText.globalMessages.updateNote.onSuccess.post = this.findByAlias("noteSaved");

    staticText.globalMessages.quoteRequestWithdraw = {
      onSuccess: { put: this.findByAlias("confirmationRequestNotification") },
      onError: { put: this.findByAlias("onErrorRequestWithdrawnPut") },
    };
    //request actions
    staticText.globalMessages.request.onSuccess.post = this.findByAlias("onSuccessRequestPost");
    staticText.globalMessages.request.onError.post = this.findByAlias("onErrorRequestPost");
    staticText.globalMessages.request.onSuccess.put = this.findByAlias("onSuccessRequestPut");
    staticText.globalMessages.request.onError.put = this.findByAlias("onErrorRequestPut");
    staticText.globalMessages.request.identifier.onSuccess.post =
      this.findByAlias("onSuccessIdentifierPut");
    staticText.globalMessages.request.identifier.onError.post =
      this.findByAlias("onErrorIdentifierPut");
    //team actions
    staticText.globalMessages.invitation.onSuccess.post =
      this.findByAlias("onSuccessInvitationPost");
    staticText.globalMessages.invitation.onError.post = this.findByAlias("onErrorInvitationPost");
    staticText.globalMessages.invitation.onSuccess.delete = this.findByAlias(
      "removeInvitationSuccessNotification",
    );
    staticText.globalMessages.invitation.onError.delete = this.findByAlias(
      "removeMemberErrorNotification",
    );
    // decline request actions
    staticText.globalMessages.declineRequest.onSuccess.post = this.findByAlias(
      "declineRequestModalSuccess",
    );
    staticText.globalMessages.declineRequest.onError.post = this.findByAlias(
      "declineRequestModalError",
    );

    //favourite actions
    staticText.globalMessages.favorite.delete.onSuccess.post = this.findByAlias(
      "operatorRemovedNotification",
    );
    staticText.globalMessages.favorite.delete.onError.post = this.findByAlias(
      "operatorRemoveFailNotification",
    );
    staticText.globalMessages.favorite.add.onSuccess.post =
      this.findByAlias("operatorAddNotification");
    staticText.globalMessages.favorite.add.onError.post = this.findByAlias(
      "operatorAddFailNotification",
    );
    //empty leg delete notification
    staticText.globalMessages.emptyLeg.onSuccess.delete =
      this.findByAlias("emptyLegRemovedSuccess");
    staticText.globalMessages.emptyLeg.onSuccess.post = this.findByAlias("createdEmptyLeg");

    staticText.globalMessages.emptyLegWithdraw.onSuccess.delete =
      this.findByAlias("emptyLegWithdrawn");

    staticText.globalMessages.emptyLeg.onError.delete = this.findByAlias("emptyLegRemovedError");
    staticText.globalMessages.emptyLeg.onError.post = this.findByAlias("emptyLegAddError");

    staticText.bookingStatuses = {
      completed: this.findByAlias("completedStatus"),
      cancelled: this.findByAlias("cancelledStatus"),
      confirmed: this.findByAlias("confirmedStatus"),
      provisional: this.findByAlias("provisionallyConfirmed"),
    };

    //aircraft delete actions
    staticText.globalMessages.helicopter = {
      onSuccess: {
        delete: this.findByAlias("onSuccessAircraftDelete"),
      },
      onError: {
        delete: this.findByAlias("onErrorAircraftDelete"),
      },
    };

    staticText.quoteStatuses = {
      open: this.findByAlias("openStatus"),
      pending: this.findByAlias("pendingStatus"),
      expired: this.findByAlias("expiredStatus"),
      closed: this.findByAlias("bookingConfirmed"),
      withdrawn: this.findByAlias("withdrawnStatus"),
      on_hold: this.findByAlias("onHoldStatus"),
      unsuccessful: this.findByAlias("unsuccessfulStatus"),
      rejected: this.findByAlias("rejectedStatus"),
      booking_init: this.findByAlias("pendingStatus"),
      amended: this.findByAlias("amendedStatus"),
      pre_quoted: this.findByAlias("preQuoted"),
    };

    staticText.quoteRequestStatuses = staticText.quoteStatuses;

    staticText.globalMessages.user = {
      ...staticText.globalMessages.user,
      agreement: {
        onSuccess: {
          post: this.findByAlias("onSuccessAgreementUpdated"),
        },
        onError: {
          post: this.findByAlias("onErrorAgreementUpdated"),
        },
      },
    };
  }

  setGridHeaders(configuration = {}) {
    const headers = configuration.headers.map((header) => {
      if (!configuration.headersApplied) {
        header.title = this.findByAlias(header.title);
      }
      return header;
    });
    configuration.headersApplied = true;
    return headers;
  }
}

export default new StaticContentService();
