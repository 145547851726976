/**
 * @name transformAdditionalInfo
 *
 * @param {String} str
 */
export default (str) =>
  str
    ? str
        .split("|")
        .filter((content) => !!content)
        .join(" • ")
    : str;
