import * as types from "../../actions/actionTypes";
import { GET_PIPELINES } from "../../actions/actionTypes";
import moment from "moment";
import { staticText } from "../../configs";

const modelName = "operatorMarketplaceRequestsFiltersComponent";

function broker_requests(state = [], action) {
  const getStatus = (status) => staticText.quoteRequestStatuses[status];
  const getDate = (date) => moment(date, "DD/MM/YYYY").format("DD MMMM, YYYY");

  switch (action.type) {
    case types.GET_BROKER_REQUESTS_LIST:
      return action.requests.map((item) => ({
        ...item,
        on: getDate(item.on),
        status: getStatus(item.status),
        statusFromApi: item.status,
      }));
    case types.REMOVE_BROKER_REQUEST:
      return state.filter((req) => req.id !== action.requestId);
    case types.UPDATE_BROKER_REQUEST:
      return state.map((req) =>
        req.id === action.request.id
          ? {
              ...action.request,
              on: getDate(action.request.on),
              status: getStatus(action.request.status),
              statusFromApi: action.request.status,
            }
          : req,
      );
    case types.UPDATE_BROKER_REQUEST_BY_QUOTES:
      return state.map((req) =>
        req.id === action.response.request_id
          ? {
              ...req,
              quotes: action.response.quotes.data.length,
            }
          : req,
      );
    default:
      return state;
  }
}

function operator_requests(state = [], action) {
  switch (action.type) {
    case types.GET_OPERATOR_REQUESTS_LIST:
      return action.requests;
    case types.TOGGLE_REQUEST_FLAG_STATUS: {
      return action.response.requests.map((item) => {
        if (item.id === action.response.id) {
          return action.response.updatedObj;
        }
        return item;
      });
    }
    default:
      return state;
  }
}

function editedRequest(state = {}, action) {
  switch (action.type) {
    case types.EDIT_REQUEST:
      return action.request;
    default:
      return state;
  }
}

function legDistance(state = { distance: [] }, action) {
  switch (action.type) {
    case types.SEND_COORDINATES: {
      localStorage.setItem("distance", JSON.stringify([...state.distance, action.requests]));
      const distance = JSON.parse(localStorage.getItem("distance"));
      return {
        ...state,
        distance: [...state.distance, action.requests],
      };
    }
    default:
      return state;
  }
}

function request_notification(state = { firstTimeLoaded: false, total: 0 }, action) {
  switch (action.type) {
    case types.GET_OPERATOR_REQUESTS_NOTIFICATION:
      return { ...state, total: action.total };
    case types.LOAD_UNREAD_REQUESTS_NOTIFICATION:
      return { firstTimeLoaded: true, total: action.total };
    case types.GET_REQUESTS_LIST:
      return { firstTimeLoaded: true, total: 0 };
    default:
      return state;
  }
}

const initialState = {
  data: [],
  message: "",
  default_filters: {},
};

function requests(state = initialState, action) {
  switch (action.type) {
    case types.GET_REQUESTS_LIST:
      return action.requests;
    case types.GET_DECLINE_REASONS:
      return {
        ...state,
        reasons: action.reasons,
      };
    case types.SEND_DECLINE_REASONS:
      return {
        ...state,
        reasons: action.reasons,
      };
    case types.SAVE_RESET_REQUESTS_LIST: {
      const savedList = action.requests.data.map((item) => {
        return {
          ...item,
          on: moment(item.on, "DD/MM/YYYY").format("DD MMMM, YYYY"),
        };
      });
      return {
        ...state,
        ...action.requests,
        data: savedList,
      };
    }
    case types.LOAD_MORE_REQUEST:
      action.requests.data.map((item) => {
        return {
          ...item,
          on: moment(item.on, "DD/MM/YYYY").format("DD MMMM, YYYY"),
        };
      });
      return {
        ...state,
        ...action.requests,
      };
    case types.TOGGLE_REQUEST_FLAG_STATUS: {
      return {
        ...state,
        data: action.response.requests.map((item) => {
          if (item.id === action.response.id) {
            return action.response.updatedObj;
          }
          return item;
        }),
        empty_leg_requests: action.response.updatedObj.empty_leg_requests,
        requests: action.response.updatedObj.requests,
      };
    }
    case types.SAVE_DEFAULT_SEARCH:
      return {
        ...state,
        default_filters: action.data,
      };
    case types.DEFAULT_FILTERS:
      return {
        ...state,
        default_filters: action.requests,
      };
    case types.RESET_DEFAULT_SEARCH:
      return {
        ...state,
        data: [],
        default_filters: action.data,
      };
    case types.REMOVE_FILTERS:
      return {
        ...state,
        default_filters: action.data,
      };
    default:
      return state;
  }
}
function defaultRequests(state = {}, action) {
  switch (action.type) {
    case types.SET_DEFAULT_REQUESTS_DATA:
      return action.requests;
    default:
      return state;
  }
}

function requestDetail(state = {}, action) {
  switch (action.type) {
    case types.GET_BROKER_REQUEST_DETAIL:
    case types.REQUEST_UPDATED:
      return { ...action.request };
    case types.REJECT_QUOTE:
    case types.QUOTE_UNSUCCESSFULL:
      return { ...state, activeQuotes: action.response };
    case types.SELECT_QUOTE:
      return { ...action.response };
    case types.UPDATE_REQUEST_BY_QUOTES:
      return { test: 1, test2: 2 };
      if (parseInt(action.response.request_id) !== parseInt(state.id)) return state;
      return {
        ...state,
        activeQuotes: action.response.quotes.data.map((itm) => {
          const updatedQuote = state.activeQuotes.find((_itm) => _itm.id === itm.id);

          if (updatedQuote) {
            return {
              ...itm,
              ...updatedQuote,
            };
          }

          return itm;
        }),
      };
    case types.UPDATE_QUOTE_STATUS: {
      const arr = [];
      state.activeQuotes.forEach((item) => {
        if (item.id === action.response.id && item.status !== action.response.status) {
          arr.push(action.response);
        } else {
          arr.push(item);
        }
      });
      return { ...state, activeQuotes: arr };
    }
    case types.UPDATE_QUOTE_AMEND_STATUS: {
      return {
        ...state,
        activeQuotes: state.activeQuotes.map((itm) =>
          itm.id === action.request.id ? { ...itm, is_amend_notified: 0 } : itm,
        ),
      };
    }
    case types.UPDATE_QUOTE_LOCALLY: {
      return {
        ...state,
        activeQuotes:
          state.activeQuotes &&
          state.activeQuotes.map((quote) =>
            quote.id === action.seenQuote.quote_id ? { ...quote, ...action.seenQuote } : quote,
          ),
      };
    }
    case types.GET_QUOTE_ITEM:
      return { ...action.quote.booking_request };
    case types.UPDATE_IDENTIFIER:
      return { ...state, identifier: action.response.identifier };
    case types.UPDATE_PRIVATE_NOTES:
      return { ...state, private_notes: action.response.private_notes };
    case types.RESET_PAYMENT:
    case types.RESET_EMPTY_LEG:
      return {};
    case types.UPDATE_MORE_DETAIL_REQUESTS: {
      const moreDetails = action.payload;
      const updatedMoreDetailRequests = state.more_detail_requests.map((item) =>
        item.id === moreDetails.id ? moreDetails : item,
      );
      return { ...state, more_detail_requests: updatedMoreDetailRequests };
    }
    default:
      return state;
  }
}

function booking_request(state = { operators: [] }, action) {
  switch (action.type) {
    case types.ADD_REQUEST_SUCCESS:
      return action.request;
    case types.RESET_MODEL:
      return [];
    case types.REVIEW_REQUEST_ADD_OPERATORS: {
      return { ...state, operators: action.payload };
    }
    default:
      return state;
  }
}

function pipelines(state = [], action) {
  switch (action.type) {
    case GET_PIPELINES:
      return action.data;
    default:
      return state;
  }
}

function progressInfo(state = {}, action) {
  switch (action.type) {
    case types.ADD_PROGRESS_INFO:
      return action.response;
    case types.DELETE_PROGRESS_INFO:
      return {};
    default:
      return state;
  }
}

export {
  broker_requests,
  legDistance,
  requestDetail,
  booking_request,
  operator_requests,
  requests,
  request_notification,
  editedRequest,
  pipelines,
  progressInfo,
  defaultRequests,
};
