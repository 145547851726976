import React from "react";
import { actions, Control, Errors, Form } from "react-redux-form";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  CustomValidateError,
  DateTimeComponent,
  prepareQueryStringFromObj,
  updateModel,
} from "../../../../shared";
import { BookingFilters, config, validationRules } from "../../../../configs/";
import { getBrokerRequetsByFilter, getBrokerRequetsSuccess } from "../../../../actions/";
import staticService from "../../../../services/static.service";
import { ActivityFilterCriteria, FilterTabsComponent } from "./";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../common";
import BasicRequestFiltersComponent from "./basic-request-filters.component";

const modelName = "brokerBookingRequestsFiltersModel";
const { rangeValidation } = validationRules;

class BrokerBookingQuotesFiltersComponent extends BasicRequestFiltersComponent {
  constructor(props) {
    super(props, modelName);
    this.state = {
      filterTabs: BookingFilters,
    };
    this.updateTabList = this.updateTabList.bind(this);
  }

  reset() {
    this.props.resetModel(modelName);
    this.props.get(1, this.props.filters).then((response) => {
      if (this.props.resetFilters) {
        this.props.resetFilters(this.props.filters, response);
      }
    });
  }

  applyFilters(filters) {
    this.props.get(1, prepareQueryStringFromObj(filters)).then((requests) => {
      this.updateModel(requests, filters);
    });
  }

  updateTabList(list) {}

  render() {
    // if (!this.props.visible) {
    //   return null;
    // }

    return (
      <div>
        <FilterTabsComponent tabs={this.state.filterTabs} changeFunc={this.updateTabList} />
        {!this.props.visible ? null : (
          <div className="uk-card uk-card-default uk-card-body">
            <div>
              <Form
                model={modelName}
                validators={{
                  "": {
                    rangeRequired: rangeValidation,
                  },
                }}
                onSubmit={this.handleFormSubmit.bind(this)}
              >
                <div data-uk-grid className="gh-filters-container">
                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("NearByTo"),
                      }}
                    />
                    <div className="uk-form-controls uk-position-relative">
                      <LocationAutocompleteWrapper>
                        {({
                          locations,
                          search,
                          handleClickOutside,
                          loader,
                          onBlur,
                          validate,
                          validationMessage,
                        }) => {
                          return (
                            <div className={"uk-position-relative"}>
                              <LocationAutocompleteInput
                                className={["uk-input"]}
                                placeholder={"London"}
                                readOnly={false}
                                value={this.props.near_by}
                                additionalInof={this.props.home_info}
                                coverageWarning={this.props.home_coverage_status}
                                onSelect={(location) => {
                                  this.updateGeocodeModel(location);
                                  return search(location);
                                }}
                                onBlur={onBlur}
                                loader={loader}
                                debounce={500}
                                model={`${modelName}.near_by`}
                              />
                              <LocationAutocompleteList
                                onSelect={this.onSelectLocation.bind(this, handleClickOutside)}
                                locations={locations}
                              />
                              <ul
                                className={
                                  "ul validation-errors disabled-list" + (validate ? " hide" : "")
                                }
                              >
                                <li>{validationMessage}</li>
                              </ul>
                            </div>
                          );
                        }}
                      </LocationAutocompleteWrapper>
                    </div>
                  </div>

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("WithinRadius"),
                      }}
                    />
                    <div className="uk-form-controls">
                      <Control.select model={".radius"} className="uk-select">
                        {config.miles.map((radius, index) => (
                          <option value={radius.id} key={index}>
                            {radius.title}
                          </option>
                        ))}
                      </Control.select>
                    </div>
                  </div>

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("date"),
                      }}
                    />
                    <div className="uk-form-controls uk-position-relative">
                      <Control
                        model={`.date_start`}
                        className={"uk-input gh-calendar-input"}
                        component={DateTimeComponent}
                        changeAction={(model, value) =>
                          updateModel(model, value.format(config.filterDateFormat))
                        }
                        updateOn="change"
                        controlProps={{
                          dateFormat: "DD MMMM, YYYY",
                          placeholder: "Start date",
                          onClear: () => this.props.updateModel(modelName + ".date_start", ""),
                          isValidDate: false,
                        }}
                      />
                    </div>
                  </div>

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <div className="uk-form-controls uk-position-relative">
                      <Control
                        model={`.date_end`}
                        className={"uk-input gh-calendar-input"}
                        component={DateTimeComponent}
                        changeAction={(model, value) =>
                          updateModel(model, value.format(config.filterDateFormat))
                        }
                        updateOn="change"
                        controlProps={{
                          dateFormat: "DD MMMM, YYYY",
                          placeholder: "End date",
                          onClear: () => this.props.updateModel(modelName + ".date_end", ""),
                          isValidDate: false,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    "uk-text-right uk-margin-small-top uk-margin-small-bottom uk-visible@m"
                  }
                >
                  <Errors
                    model={modelName}
                    wrapper={CustomValidateError}
                    messages={{
                      rangeRequired: staticService.findByAlias("rangeValidationRequired"),
                    }}
                  />
                </div>

                <div className={"uk-margin-medium-bottom uk-margin-small-top uk-hidden@m"}>
                  <Errors
                    model={modelName}
                    wrapper={CustomValidateError}
                    messages={{
                      rangeRequired: staticService.findByAlias("rangeValidationRequired"),
                    }}
                  />
                </div>
                <div className={"uk-margin-medium-top"} data-uk-grid>
                  <div className="uk-grid-item-match">
                    <label>
                      <Control.checkbox
                        className={"uk-checkbox uk-margin-small-right"}
                        model={".active"}
                        changeAction={updateModel}
                        updateOn="change"
                      />{" "}
                      {staticService.findByAlias("allActive")}
                    </label>
                  </div>
                  <ActivityFilterCriteria
                    updateModel={this.props.updateModel}
                    modelName={modelName}
                    boxLabels={{ label1: "MyRequests", label2: "AllRequests" }}
                    activity={this.props.activity}
                  />
                </div>
                <div className="uk-flex-center" data-uk-grid>
                  <div>
                    <Control.button
                      onClick={this.reset}
                      type={"button"}
                      model={modelName}
                      className={"uk-button gh-cloud-blue-btn"}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("resetFilter"),
                        }}
                      />
                    </Control.button>
                  </div>
                  <div>
                    <Control.button model={modelName} className={"uk-button uk-button-primary"}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("showFilterResult"),
                        }}
                      />
                    </Control.button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.resetModel(modelName);
  }
}

BrokerBookingQuotesFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerBookingQuotesFiltersComponent.propTypes = {
  sortParams: PropTypes.shape({
    sortOrder: PropTypes.string,
    field: PropTypes.string,
  }),
  resetFilters: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  parentFilters: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      resetModel: (model) => dispatch(actions.reset(model)),
      get: getBrokerRequetsByFilter,
      update: getBrokerRequetsSuccess,
    },
    dispatch,
  );
};

const mapProps = (props) => {
  return {
    ...props[modelName],
    filters: props.filters,
  };
};

const COMPONENT = connect(mapProps, mapDispatchToProps)(BrokerBookingQuotesFiltersComponent);
export { COMPONENT as BrokerBookingQuotesFiltersComponent };
