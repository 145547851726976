import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import {
  distanceBetween,
  isObjectEmpty,
  parseCoordinates as prsCrds,
  percentageFormula,
  storage,
} from "../../../shared";
import getNearestPads from "../../../utils/getNearestPads";
import { config, LondonLocation } from "../../../configs";
import MapRoot from "../../../shared/mapHook/Map";
import { returnNormalPlaceType } from "../../../shared/mapHook/mapUtils";
import PadSelectionContext from "../../../context/PadSelectionContext";

const offset = 0.52990619827; // radius in which to display landing pads

function PadSelectionMap(props) {
  const {
    legs,
    screenDimensions,
    showAvailablePads,
    flyDirCoords,
    legIndex,
    handleAssignPad,
    classNames,
    cardActions,
    review,
    legId,
    updateChosenSitesByRole,
  } = props;
  const [pads, setPads] = useState([]);
  const [prevCoords, setPrevCoords] = useState([{ from: "", to: "" }]);
  const [defaultPadsLoaded, setDefaultPadsLoaded] = useState(false);

  useEffect(() => {
    if (showAvailablePads) {
      if (flyDirCoords.length && (flyDirCoords[0].from || flyDirCoords[0].to)) {
        if (
          !pads.length ||
          flyDirCoords[0].from !== prevCoords[0].from ||
          flyDirCoords[0].to !== prevCoords[0].to
        ) {
          getAvailablePadsMarkers(
            loadAvailablePads({ from: flyDirCoords[0].from, to: flyDirCoords[0].to }),
            legs[0].id,
            0,
          );
          setDefaultPadsLoaded(false);
          setPrevCoords(flyDirCoords);
        }
      } else {
        if (!defaultPadsLoaded) {
          getAvailablePadsMarkers(loadAvailablePads(), 0, 0);
        }
        setDefaultPadsLoaded(true);
      }
    }
  }, [flyDirCoords, showAvailablePads]);

  useUpdateEffect(() => {
    if (!showAvailablePads) {
      setPads([]);
      setDefaultPadsLoaded(false);
    }
  }, [showAvailablePads]);

  function loadAvailablePads(coords = { from: "", to: "" }) {
    const sites = JSON.parse(storage.get("landingSites")) || [];
    const loadNearestPads = getNearestPads(sites, offset);

    // if (coords.from && coords.to) {
    //   return [...loadNearestPads(prsCrds(coords.from)), ...loadNearestPads(prsCrds(coords.to))];
    // } else {
    //   return [...loadNearestPads(LondonLocation)];
    // }

    return sites;
  }

  function handleAssignPadFunc(site) {
    const leg = legs.find((i) => site.id === i.id);

    if (!isObjectEmpty(leg)) {
      handleAssignPad(site);
    }
  }

  function getAvailablePadsMarkers(pads = [], legId, markerCoordinates = 0) {
    let padsArray = [...pads];

    padsArray = padsArray
      .map((pad) => {
        const coords = { lat: pad.location.latitude, lng: pad.location.longitude };
        return {
          ...coords,
          handleAssignButton: (legSide) => {
            handleAssignPadFunc({
              location: pad.location,
              type: returnNormalPlaceType(pad.category.display_name),
              source: pad.source || "hellipaddy",
              name: pad.name,
              legId,
              legIndex,
              pad_id: pad.id,
              place_id: pad.id,
              legSide,
            });
          },
          name: pad.name,
          rating: pad.rating,
          image: pad.upload && pad.upload.file_url,
          reviews: pad.comments_count,
          category: returnNormalPlaceType(pad.category.display_name),
          source: pad.source,
          view_link: pad.view_link,
          distance: markerCoordinates ? distanceBetween(markerCoordinates, coords, "N") : 0,
          isSecondary: false,
          start_date: pad.start_date,
          end_date: pad.end_date,
          type: pad.type,
        };
      })
      .filter(
        (pad, index, arr) => index === arr.findIndex((t) => t.lat === pad.lat && t.lng === pad.lng),
      )
      .filter(
        (pad) =>
          !flyDirCoords.every(
            (dir) => dir.from === `${pad.lat}|${pad.lng}` || dir.to === `${pad.lat}|${pad.lng}`,
          ),
      );

    if (padsArray.length) setPads(padsArray);
  }

  const mapHeight = () => {
    const height = screenDimensions.screenW - percentageFormula(screenDimensions.screenW, 50);
    if (config.maxAllowedMapeHeight > height) return height;
    return config.maxAllowedMapeHeight;
  };

  return (
    <PadSelectionContext.Provider
      value={{
        cardActions,
        handleAssignPad,
        review,
        legIndex,
        legId,
        updateChosenSitesByRole,
      }}
    >
      <div className={classNames}>
        <MapRoot
          flyDirCoords={flyDirCoords}
          pads={pads}
          containerStyle={{
            position: "relative",
            width: "100%",
            height: mapHeight(),
          }}
        />
      </div>
    </PadSelectionContext.Provider>
  );
}

PadSelectionMap.propTypes = {
  legs: PropTypes.array,
  handleAssignPad: PropTypes.func,
  screenDimensions: PropTypes.object,
  legIndex: PropTypes.number,
  showAvailablePads: PropTypes.bool,
  cardActions: PropTypes.bool,
  defaultCoords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  flyDirCoords: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      pad: PropTypes.object,
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  review: PropTypes.bool,
  legId: PropTypes.number,
  updateChosenSitesByRole: PropTypes.bool,
};

PadSelectionMap.defaultProps = {
  legs: [],
  flyDirCoords: [],
  cardActions: true,
  updateChosenSitesByRole: false,
};

export default memo(PadSelectionMap);
