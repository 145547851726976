import React, { Component } from "react";
import PropTypes from "prop-types";
import { MarketPlaceLayout } from "../../../../layouts/";
import { ActivityFeedList, MapActivityComponent } from "../";
import { commaSeparatedNumber } from "../../../../../shared/";
import staticService from "../../../../../services/static.service";
import { MarketOverviewItemComponent } from "../";

class MarketPlaceActivityComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      tabSelected: "bookings",
      tabs: [],
      overviewTabs: [],
    };
    this.handleDatePicked = this.handleDatePicked.bind(this);
  }

  componentWillMount() {
    if (this.state.activities.length === 0) {
      this.props.loadActivity();
    }
  }

  handleDatePicked(range) {
    this.props.getActivityFlightsByDate(range);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activityFeed !== nextProps.activityFeed) {
      this.setState({ activities: nextProps.activityFeed.feeds });
    }
  }

  render() {
    const { match, activityFeed, activityFlights = {}, screenDimensions } = this.props;
    const { tabSelected } = this.state;
    return (
      <MarketPlaceLayout
        pageTitle={staticService.findByAlias("marketplacePageTitle")}
        navigationTabs={this.state.tabs}
        activeRoute={match}
        isFilterVisible={false}
      >
        <div data-uk-grid className={"uk-margin"}>
          <div className="uk-width-2-3@m">
            <MapActivityComponent
              onDatePicked={this.handleDatePicked}
              bookings={activityFlights.bookings}
              showTab={true}
              emptyLegs={activityFlights["empty-legs"]}
              flights={activityFlights}
              tabSelected={tabSelected}
              screenDimensions={screenDimensions}
              updateRangeModel={this.props.updateModel}
              onTabSelected={(tabSelected) => this.setState({ tabSelected })}
            />
          </div>
          <div className="uk-width-1-3@m gh-section-statistics uk-margin-bottom uk-margin-top">
            <div className="uk-text-uppercase uk-margin-medium-top uk-margin-small-bottom uk-text">
              <small
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("marketplaceOverview"),
                }}
              />
            </div>
            <div className="uk-background-default uk-padding-small uk-border-rounded">
              <MarketOverviewItemComponent
                visible={true}
                info={this.state.overviewTabs.request}
                total={commaSeparatedNumber(activityFeed.availableRequests)}
              />
            </div>
            <div className="uk-background-default uk-padding-small uk-border-rounded uk-margin-small-top">
              <MarketOverviewItemComponent
                visible={true}
                info={this.state.overviewTabs.booking}
                total={commaSeparatedNumber(activityFeed.confirmedBooking)}
              />
            </div>
            <div className="uk-background-default uk-padding-small uk-border-rounded uk-margin-small-top">
              <MarketOverviewItemComponent
                visible={true}
                info={this.state.overviewTabs.bookingInception}
                total={commaSeparatedNumber(activityFeed.allBookings)}
              />
            </div>
            <div className="uk-text-uppercase uk-margin-medium-top uk-margin-small-bottom uk-text">
              <small
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("marketplaceActivityFeed"),
                }}
              />
            </div>
            <ActivityFeedList activityFeed={this.state.activities} />
          </div>
        </div>
      </MarketPlaceLayout>
    );
  }

  componentWillUnmount() {
    this.setState({ activities: [], overviewTabs: [], tabs: [] });
  }
}

MarketPlaceActivityComponent.contextTypes = {
  store: PropTypes.object,
};

export { MarketPlaceActivityComponent };
