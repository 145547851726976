import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { actions } from "react-redux-form";
import { BlockComponent, ConfirmComponent } from "../../../../common";
import { removeUser, removeInvitation } from "../../../../../actions";
import { AddMembersComponent, TeamTableComponent, InvitationTableComponent } from "./";
import auth from "../../../../../services/auth";
import staticService from "../../../../../services/static.service";

class TeamMembersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { currentUser: null };
    this.closeModal = this.closeModal.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  isAdmin() {
    return (
      auth.getRole() === "operator_admin" ||
      auth.getRole() === "broker_admin" ||
      auth.getRole() === "sole_trader"
    );
  }

  onInvitationsSuccess(invitations) {}

  closeModal() {
    this.setState({ currentUser: null });
    this.confirmComponent.closeModal();
  }

  setCurrentUser(user) {
    this.setState({ currentUser: user });
    this.confirmComponent.openModal();
  }

  onSelect() {
    if (this.state.currentUser.admin !== undefined) {
      this.props.removeInvitation(this.state.currentUser.id).then(() => {
        this.confirmComponent.closeModal();
      });
    } else {
      this.props.removeUser(this.state.currentUser.id, this.state.currentUser.email).then(() => {
        this.confirmComponent.closeModal();
      });
    }
  }

  get info() {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("removeMemberConfirmMessage"),
        }}
      />
    );
  }

  get confirmModal() {
    return (
      <ConfirmComponent
        ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
        info={this.info}
        onSelect={this.onSelect}
        buttonsProps={{
          cancel: { title: staticService.findByAlias("cancel") },
          continue: {
            title: staticService.findByAlias("removeMemberBtn"),
            css: "uk-button-danger",
          },
        }}
        modalProps={{
          id: "remove-member-modal",
          title: staticService.findByAlias("removeMemberConfirmTitle"),
          onClose: this.closeModal,
        }}
        onReject={this.closeModal}
      />
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("userEmailModel"));
  }

  render() {
    return (
      <BlockComponent visible={true}>
        <TeamTableComponent onModalOpen={this.setCurrentUser.bind(this)} />
        <InvitationTableComponent onModalOpen={this.setCurrentUser.bind(this)} />

        {this.isAdmin() ? (
          <div className={"uk-text-right"}>
            <AddMembersComponent onModalClose={this.onInvitationsSuccess.bind(this)} />
          </div>
        ) : null}
        {this.confirmModal}
      </BlockComponent>
    );
  }
}

TeamMembersComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeUser,
      removeInvitation,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(TeamMembersComponent);
export { COMPONENT as TeamMembersComponent };
