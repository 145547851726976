import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateQuoteDetail } from "../../../actions";
import staticService from "../../../services/static.service";
import "./additionalInformation.css";
import newLineStr from "../../../utils/newLineStr";
import { Controller } from "react-hook-form";
import TextareaHookForm from "../TextareaHookForm";

const AdditionalInformation = (props) => {
  const { editingQuote, updateQuoteDetail, readOnly, control, clearErrors, setError } = props;

  const editComment = (value) => {
    const newQuote = { ...editingQuote, description: value };
    updateQuoteDetail(newQuote);
  };

  useEffect(() => {
    const limit = 500;

    if (readOnly) return;

    if (editingQuote.description && editingQuote.description.length > limit) {
      setError(
        "description",
        {
          type: "manual",
          message: `Must be ${limit} or fewer characters`,
        },
        { shouldFocus: true },
      );
    } else {
      clearErrors("description");
    }
  }, [editingQuote.description, readOnly]);

  return (
    <div className="gh-simple-section gh-additional-info-wrapper">
      <div className="gh-additional-info-title" style={{ marginTop: 0 }}>
        {staticService.findByAlias("additionalInformationTitle")}
      </div>
      <div className="gh-additional-info-title">
        {staticService.findByAlias("brokerCommentsTitle")}
      </div>
      <div className="gh-additional-info-content">
        {editingQuote.other_info ? newLineStr(editingQuote.other_info) : "---"}
      </div>
      <div className="gh-additional-info-title">
        {staticService.findByAlias("operatorCommentsTitel")}
      </div>
      <div className="gh-additional-info-content">
        {readOnly ? (
          <span>{editingQuote.description && newLineStr(editingQuote.description)}</span>
        ) : (
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <TextareaHookForm
                field={field}
                fieldState={fieldState}
                value={editingQuote.description}
                onChange={(e) => {
                  let value = e.target.value;
                  field.onChange(value);
                  editComment(value);
                }}
                placeholder={staticService.findByAlias("operatorCommentsPlaceholder")}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

AdditionalInformation.propTypes = {
  editingQuote: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
  readOnly: PropTypes.bool,
  control: PropTypes.object,
  clearErrors: PropTypes.func,
  setError: PropTypes.func,
};

export default connect(null, { updateQuoteDetail })(AdditionalInformation);
