import React from "react";
import { actions } from "react-redux-form";
import PropTypes from "prop-types";
import googleApi from "../../../../services/google.service";
import { config } from "../../../../configs/";
import BaseFilterComponent from "./base-filter.component";
import { allModels } from "../../../../reducers/";
import { connect } from "react-redux";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";

class BasicRequestFiltersComponent extends BaseFilterComponent {
  constructor(props, modelName) {
    super(props, modelName);

    this.getByOthers = this.getByOthers.bind(this);
    this.updateModel = this.updateModel.bind(this);
    this.getByCoordonates = this.getByCoordonates.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.reset = this.reset.bind(this);
  }

  get Model() {
    return {
      near_by: "",
      radius: "",
      date: null,
      fleetCheck: true,
      notQuotedOnly: false,
      activity: "own",
      disableBtn: false,
      refreshCollection: false,
      lat: null,
      lng: null,
      ...allModels.range,
    };
  }

  handleFormSubmit(model, action = "submit") {
    this.props.onSubmit(true);
    this.props.updateModel(this.state.modelName + ".disableBtn", true);
    if (model.source && model.source === config.locationSource[2]) {
      return this.getByCoordonates(model, action);
    } else {
      return this.getByOthers(model, action);
    }
  }

  updateModel(requests, filters) {
    this.props.updateModel(this.state.modelName, filters);
    this.props.updateModel(this.state.modelName + ".disableBtn", false);
    this.props.updateModel(this.state.modelName + ".refreshCollection", true);
    this.props.update(requests);
  }

  getByCoordonates(model, action) {
    googleApi.getCoordinatesByAddress(model.near_by).then((result) => {
      const filters = {
        ...{
          orderBy: this.props.sortParams.field,
          sortedBy: this.props.sortParams.sortOrder,
        },
        ...model,
        ...result,
        ...this.props.parentFilters,
      };
      this.applyFilters(filters, action);
    });
  }

  getByOthers(model, action) {
    const filters = {
      ...{
        orderBy: this.props.sortParams.field,
        sortedBy: this.props.sortParams.sortOrder,
      },
      ...model,
      ...this.props.parentFilters,
    };
    this.applyFilters(filters, action);
  }

  applyFilters(filters, action) {}

  onSelectLocation(handleClickOutside, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(address);
    } else {
      this.updateOnSelect(address);
    }
  }

  updateGeocodeModel(value) {
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
    };
    this.updateOnSelect(address);
  }

  updateOnSelect(address) {
    const {
      disableBtn,
      refreshCollection,
      radius,
      activity,
      date_start,
      date,
      date_end,
      fleetCheck,
      notQuotedOnly,
    } = this.props;
    const near_by = address.location;
    const lat = address.latitude;
    const lng = address.longitude;
    const source = address.source;
    const home_info = generateLocationAdditionalInfo(address);
    const home_coverage_status = address.coverage_status;
    this.context.store.dispatch(
      actions.change(`${this.state.modelName}`, {
        disableBtn,
        refreshCollection,
        radius,
        fleetCheck,
        notQuotedOnly,
        activity,
        near_by,
        home_info,
        lat,
        lng,
        source,
        date_start,
        date,
        date_end,
        home_coverage_status,
      }),
    );
  }

  fillCoordinates(address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(address);
      });
    }
  }

  render() {
    return null;
  }
}

BasicRequestFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

BasicRequestFiltersComponent.propTypes = {
  sortParams: PropTypes.shape({
    sortOrder: PropTypes.string,
    field: PropTypes.string,
  }),
  resetFilters: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  parentFilters: PropTypes.object,
};

export default BasicRequestFiltersComponent;
