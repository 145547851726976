import React from "react";
import PropTypes from "prop-types";
import { staticText, config, brokerBooking, CANCELLED } from "../../../../../configs";
import { formatID, checkObjectProp } from "../../../../../shared/";
import staticService from "../../../../../services/static.service";
import { ClientIdentifierComponent } from "../client-identifier.component";
import { AllowPermission } from "../../../../permissions";
import { NavLink } from "react-router-dom";
import auth from "../../../../../services/auth";
import LiveChatComponent from "../../../../common/LiveChatComponent";

export class BookingStatsComponent extends React.Component {
  get document() {
    if (this.props.bookingDetail && this.props.bookingDetail.documents) {
      if (this.props.bookingDetail.documents.operator !== null) {
        return (
          <a
            href={this.props.bookingDetail.documents.operator}
            rel="noreferrer"
            target={"_blank"}
            className={"uk-button uk-button-small uk-button-primary"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("viewLabel"),
              }}
            />
          </a>
        );
      }
      return (
        <button className={"uk-button uk-button-small uk-button-primary"} disabled={true}>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("paymentPendingStatus"),
            }}
            className={"gh-dark-blue"}
          />
        </button>
      );
    }

    return "";
  }

  get liveChatCommentsCount() {
    const {
      bookingDetail: {
        booking_request: { selectedQuote },
      },
    } = this.props;

    if (auth.getMainRole() === "operator") {
      return selectedQuote.comments_count && !selectedQuote.seen_by_operator
        ? selectedQuote.comments_count
        : 0;
    }

    return selectedQuote.comments_count && !selectedQuote.seen_by_broker
      ? selectedQuote.comments_count
      : 0;
  }

  handleBookingPrice() {
    const { bookingDetail } = this.props;
    return auth.isOperator() ? bookingDetail.price || "" : bookingDetail.user_total_price || "";
  }

  handleBookingCurrency() {
    const { bookingDetail } = this.props;
    return auth.isOperator()
      ? bookingDetail.operator_currency.currency || config.currencyCode
      : bookingDetail.user_currency.currency || config.currencyCode;
  }

  get ChatComponent() {
    const {
      bookingDetail: {
        booking_request: { selectedQuote },
      },
      bookingDetail,
    } = this.props;
    return (
      <div>
        <div
          className="uk-text-uppercase"
          style={{
            color: "transparent",
            opacity: 0,
            visibility: "hidden",
            marginBottom: window.innerWidth > 1200 ? 3 : 0,
          }}
        >
          <small
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("flightTermLabel"),
            }}
          />
        </div>
        <div className="gh-text-secondary gh-text-big gh-agreement gh-text-big-chat">
          <LiveChatComponent
            quoteId={selectedQuote.id}
            userType={auth.getMainRole() === "operator" ? "operator_admin" : "broker_admin"}
            titleAlias="quoteDiscussionBroker"
            additionalContentTitleAlias={
              window.location.href.includes("my-bookings")
                ? "bookingConfirmedPhrase"
                : "addAnyQuestions"
            }
            btnClassName={`booking-confirmed-chat ${this.liveChatCommentsCount ? "new-msgs" : ""}`}
            commentsCount={this.liveChatCommentsCount}
          />
        </div>
      </div>
    );
  }

  render() {
    const { bookingDetail } = this.props;
    return (
      <div
        data-uk-grid
        className={
          "uk-margin-medium-bottom uk-margin-medium-top uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-auto@l gh-booking-summary"
        }
      >
        <div>
          <div className="uk-text-uppercase">
            <small
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("requestPageID"),
              }}
            />
          </div>
          <div className="gh-text-secondary gh-text-big">
            {formatID(bookingDetail.id, config.prefixes.booking) || "No Id"}
          </div>
        </div>
        <AllowPermission role={"broker"}>
          <ClientIdentifierComponent
            identifier={checkObjectProp(this.props.request, "identifier", "")}
            requestId={checkObjectProp(this.props.request, "id", "")}
            requestStatus={checkObjectProp(this.props.request, "status", "")}
            allowEdit={this.props.editClientIdentifier}
          />
        </AllowPermission>
        <div>
          <div className="uk-text-uppercase">
            <small
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("status"),
              }}
            />
          </div>
          <div
            className={`gh-text-primary gh-text-big gh-booking-status-component-status-text ${
              bookingDetail && bookingDetail.status === CANCELLED ? "gh-text-gray" : ""
            }`}
          >
            {staticText.bookingStatuses[bookingDetail.status] || ""}
          </div>
        </div>
        <div>
          <div className="uk-text-uppercase">
            <small
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("price"),
              }}
            />
          </div>
          <div className="gh-text-secondary gh-text-big">
            {this.handleBookingCurrency()} {this.handleBookingPrice()}
          </div>
        </div>
        <div>
          <div className="uk-text-uppercase">
            <small
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("operatorPaymentDocumentLabel"),
              }}
            />
          </div>
          <div className="gh-text-secondary gh-text-big">{this.document}</div>
        </div>
        <div>
          <div className="uk-text-uppercase">
            <small
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("flightTermLabel"),
              }}
            />
          </div>
          <div className="gh-text-secondary gh-text-big gh-agreement">
            <button
              className={"uk-button uk-button-primary uk-button-small"}
              target={"_blank"}
              onClick={this.props.downloadAgreement.bind(this, bookingDetail.booking_request.id)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("viewAgreement"),
                }}
              />
            </button>
          </div>
        </div>
        {auth.getMainRole() !== "operator" ? (
          <div>
            <div className="uk-text-uppercase">
              <small
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("aocDocuments"),
                }}
              />
            </div>
            <div className="gh-text-secondary gh-text-big gh-agreement">
              <NavLink
                className={"uk-button uk-button-primary uk-button-small"}
                target={"_blank"}
                to={brokerBooking.aocDocs.path + bookingDetail.id}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("view"),
                  }}
                />
              </NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.ChatComponent}
      </div>
    );
  }
}

BookingStatsComponent.contextTypes = {
  store: PropTypes.object,
};

BookingStatsComponent.propTypes = {
  bookingDetail: PropTypes.object,
  request: PropTypes.object,
  downloadAgreement: PropTypes.func,
  editClientIdentifier: PropTypes.bool,
};

BookingStatsComponent.defaultProps = {
  editClientIdentifier: true,
};
