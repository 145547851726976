import PropTypes from "prop-types";
import { useFieldArray, useFormContext } from "react-hook-form";

export default function ControlList(props) {
  const { name, children } = props;
  const { control } = useFormContext();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
  });

  return children({
    fields,
    add: append,
    remove,
    move,
  });
}

ControlList.propTypes = {
  name: PropTypes.string,
  children: PropTypes.func,
};
