import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import * as yup from "yup";
import useYupResolver from "../../../../hooks/useYupResolver";
import { useForm, useFormContext } from "react-hook-form";
import { notification } from "uikit";
import { Form } from "../../../../components";
import InputNumber from "../../../../components/Form/components/InputNumber";
import apiHandler from "../../../../services/api-handler";
import { Modal } from "../Modal";
import { useCalculatorContext } from "../../CalculatorContext";
import { inputNumberValueToNumber } from "../Input/Input.utils";
import { connect } from "react-redux";
import Button from "../../../../components/common/Button";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";

const AircraftModal = ({ index, id, onAircraftNameChange, visible, setVisible, banking, legs }) => {
  const { availableAircrafts, extraAircrafts, updateAvailableAircrats, updateForm } =
    useCalculatorContext();
  const { getValues } = useFormContext();

  const { has_access_to_extra_helicopters } = Auth.getCompanyFromLocalStorage();

  const aircraftForm = yup.object().shape({
    price_per_hour: yup.string().required("This field is required"),
    cruise_speed: yup.string().required("This field is required"),
    aircraft_vat: yup
      .string()
      .test("maxPercent", staticService.findByAlias("taxRateAircraftErrorMsg"), (val) => {
        const vatValue = inputNumberValueToNumber(val);
        return vatValue <= 100;
      }),
  });
  const form = useForm({
    resolver: useYupResolver(aircraftForm),
  });

  const [loading, setLoading] = React.useState(false);

  const fieldsToUpdate = (percent) => {
    const fields = [];
    const currentLegs = getValues(`aircrafts.${index}.legs`);

    for (let i = 0; i < currentLegs.length; i++) {
      fields.push([`aircrafts.${index}.legs.${i}.flight_tax`, { sum: "", percent: percent }]);
    }

    return fields;
  };

  React.useEffect(() => {
    if (visible) {
      let aircraftData = availableAircrafts.find((aircraft) => aircraft.id === id);

      if (!aircraftData) {
        aircraftData = getValues(`aircrafts.${index}`);
      }

      form.setValue("cruise_speed", aircraftData.cruise_speed || "");
      form.setValue("price_per_hour", aircraftData.price_per_hour || "");
      form.setValue(
        "aircraft_vat",
        aircraftData.aircraft_vat || aircraftData.aircraft_vat === 0
          ? aircraftData.aircraft_vat.toString()
          : "",
      );
    }
  }, [visible, form, getValues, index, availableAircrafts, id]);

  const onSubmitValues = React.useCallback(
    (values) => {
      setLoading(true);

      const data = Object.fromEntries(
        Object.entries(values).map(([k, v]) =>
          k === "aircraft_vat" && v === "" ? [k, ""] : [k, inputNumberValueToNumber(v)],
        ),
      );

      apiHandler
        .setPath("aircraft", "update")
        .setProperty("skipErrorAlert", true)
        .put(id, data)
        .then((res) => {
          const companyVat = banking?.vat_value;
          const aircraftVat = res.aircraft_vat !== null ? res.aircraft_vat : companyVat || "";

          updateForm([...fieldsToUpdate(aircraftVat)]);
          setLoading(true);
          updateAvailableAircrats(id, res);
          onAircraftNameChange(id, res);
          setVisible(false);
          notification("Aircraft updated with success", {
            status: "calculator",
            pos: "top-right",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, updateAvailableAircrats, onAircraftNameChange, setVisible],
  );

  const aircraftName = React.useMemo(
    () => availableAircrafts.find((aircraft) => aircraft.id === +id),
    [id, availableAircrafts],
  );

  const extraAircraftName = React.useMemo(
    () => extraAircrafts.find((aircraft) => aircraft.id === +id),
    [id, extraAircrafts],
  );

  const isMainAircraft = useMemo(() => {
    return !!availableAircrafts.find((aircraft) => aircraft.id === +id);
  }, [id]);

  if (!visible) return null;

  return ReactDOM.createPortal(
    <Modal
      title={(aircraftName && aircraftName.name) || (extraAircraftName && extraAircraftName.name)}
      visible={visible}
      onVisibilityChange={setVisible}
    >
      <Form form={form} onSubmit={onSubmitValues}>
        <Form.Item
          name="cruise_speed"
          label="Speed of aircraft (Knots)"
          className="uk-margin-bottom"
        >
          <InputNumber className="uk-input" placeholder="[0]" />
        </Form.Item>
        <Form.Item
          name="price_per_hour"
          label={`Cost/h of aircraft (${banking.currency || "GBP"})`}
          className="uk-margin-bottom"
        >
          <InputNumber className="uk-input" placeholder="[0.00]" />
        </Form.Item>
        <Form.Item
          name="aircraft_vat"
          label={staticService.findByAlias("taxRateOfAircraftLabel")}
          className="uk-margin-bottom"
        >
          <InputNumber className="uk-input" placeholder="" />
        </Form.Item>
        {/* Disable button if the aircraft is from platform fleet */}
        {isMainAircraft ? (
          <Button
            loading={loading}
            className="calculator-reset-btn calculator-submit-btn"
            onClick={form.handleSubmit(onSubmitValues)}
          >
            Save as Default
          </Button>
        ) : (
          ""
        )}
      </Form>
    </Modal>,
    document.getElementById("app"),
  );
};

AircraftModal.propTypes = {
  index: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAircraftNameChange: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  banking: PropTypes.object,
  legs: PropTypes.array,
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(AircraftModal);
