import React from "react";
import checkIcon from "../../assets/img/svg/check.svg";
import staticService from "../../services/static.service";

const AfterSubmitComponent = () => {
  return (
    <div className="gh-after-submit">
      <img className="image" src={checkIcon} alt="icon" />
      <h4
        className="title"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("afterSubmitTitle") }}
      />
      <div
        className="message"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("afterSubmitMessage") }}
      />
    </div>
  );
};

export { AfterSubmitComponent };
