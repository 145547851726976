import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import UIkit from "uikit";
import Auth from "../../services/auth";
import staticService from "../../services/static.service";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMenuItems, loadDestinations } from "../../actions";
import uid from "../../utils/uid";
import backArrowGreenIcon from "../../assets/img/svg/backArrowGreen.svg";
import link from "../../assets/img/external-link-green.svg";

const list = [
  "HomeTopMenu",
  "eventsDropDownMenu",
  "destinationsDropDownMenu",
  "contactDropDownMenu",
  "communityNav",
  "pricingTopMenu",
];

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCountry: null,
      activeEvent: null,
      activeEventKey: "",
    };

    this.component = null;
    this.pagesList = this.pagesList.bind(this);
  }

  componentDidMount() {
    const { getMenuItems } = this.props;
    this.component = UIkit.navbar("#" + this.props.id, {});

    if (!Auth.isAuthenticated()) {
      getMenuItems();
    }
  }

  componentDidUpdate() {
    const { activeCountry, activeEvent } = this.state;
    const { menuItems } = this.props;
    const destinations = menuItems?.navbar?.destinations;
    const events = menuItems?.navbar?.events;

    // set default sub menu list
    if (!activeCountry && destinations) {
      this.setState({ activeCountry: destinations[0] });
    }
    if (!activeEvent && events) {
      const firstKey = Object.keys(events)[0];
      this.setState({ activeEvent: events[firstKey], activeEventKey: firstKey });
    }
  }

  componentWillUnmount() {
    this.component.$destroy();
  }

  path(page) {
    if (page.paths) {
      return page.paths[Auth.getMainRole()];
    }
    return page.path;
  }

  static getMenuList(css = "uk-nav uk-navbar-dropdown-nav") {
    return (
      <ul
        className={css}
        style={{ display: window.location.href.includes("events") ? "none" : "block" }}
      >
        {Auth.getMainRole() === "broker" ? (
          <li>
            <NavLink to="/flights/requests/filter/all">
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("allRequestActivity"),
                }}
              />
            </NavLink>
          </li>
        ) : (
          <li>
            <NavLink to="/flights/quotes/filter/all">
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("allQuotesActivity"),
                }}
              />
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/flights/my-bookings/filter/all">
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("allBookingActivity"),
              }}
            />
          </NavLink>
        </li>
        <li>
          <NavLink to={"/account/profile"}>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("accountMenu"),
              }}
            />
          </NavLink>
        </li>
        <li>
          <NavLink to={"/contact"}>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("contactDropDownMenu"),
              }}
            />
          </NavLink>
        </li>
        <li className="uk-nav-divider" />
        <li>
          <NavLink to={"/account/logout"}>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("signOutMenu"),
              }}
            />
          </NavLink>
        </li>
      </ul>
    );
  }

  static getDropdownTemplate(klass = "") {
    return [
      <ul key={"desktop"} className={`uk-visible@l uk-navbar-nav nav-dropdown ${klass}`}>
        <li>
          <div className="uk-navbar-nav">
            <div className="uk-navbar-item">
              <ul
                data-uk-grid
                className="uk-grid-small uk-flex-inline uk-flex-middle uk-flex-nowrap"
              >
                <li>
                  <div className="uk-margin-remove-left uk-padding-remove gh-user-name gh-text-normal">
                    {Auth.fullName}
                  </div>
                </li>
                <li>
                  <span
                    className="uk-margin-remove-left uk-padding-remove gh-dropdown-arrow"
                    data-uk-icon="icon: chevron-down"
                  />
                </li>
              </ul>
            </div>
            <div className="uk-navbar-dropdown">{this.getMenuList()}</div>
          </div>
        </li>
      </ul>,
      <div key={"mobile"} className={"uk-hidden@l uk-margin-medium-bottom  nav-dropdown"}>
        {Auth.isAuthenticated() && !window.location.href.includes("events") ? (
          <div className="uk-navbar-item uk-margin-right">
            <ul
              data-uk-grid
              className="uk-grid-small uk-flex-inline uk-flex-middle uk-flex-nowrap uk-nav gh-user-nav"
            >
              <li className="uk-first-column">
                <div className="gh-user-initials">{Auth.fullNameInitials}</div>
              </li>
              <li>
                <div className="uk-margin-remove-left uk-padding-remove gh-user-name">
                  {Auth.fullName}
                </div>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
        {this.getMenuList("uk-nav uk-nav-sub uk-nav-center uk-padding-remove-left")}
      </div>,
    ];
  }

  pagesList(navList) {
    const { dashboardModel } = this.props;
    const displayPipelinesCounter = Auth.isAuthenticated() && dashboardModel.total_pipelines;
    const displayEmptyLegsCounter = Auth.isAuthenticated() && dashboardModel.total_empty_legs;

    return (
      <ul {...this.props.ulProps}>
        {navList.map((page, index) => {
          return (
            <li key={index} {...page.props.listProp}>
              {!page.isDropdown ? (
                <NavLink {...page.props.linkProp} to={this.path(page)} activeClassName="uk-active">
                  {page.paths &&
                  page.paths[Auth.getMainRole()] === "/marketplace/empty-legs" &&
                  Auth.isAuthenticated() &&
                  Auth.getMainRole() === "broker" ? (
                    <span>
                      <span>{staticService.findByAlias("brokerMarketplaceEmptyLegTab")}</span>
                      {displayEmptyLegsCounter ? (
                        <span className="uk-label uk-label-danger gh-button-counter uk-margin-small-left uk-margin-bottom">
                          {dashboardModel.total_empty_legs}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  ) : page.path === "/contact" ? (
                    ""
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias(page.title),
                      }}
                    />
                  )}

                  {page.title === "PipelinesTopmenu" &&
                  displayPipelinesCounter &&
                  Auth.hasFeature("broker_pipeline_of_leads") ? (
                    <span className="uk-label uk-label-danger gh-button-counter uk-margin-small-left uk-margin-bottom">
                      {dashboardModel.total_pipelines}
                    </span>
                  ) : (
                    ""
                  )}
                </NavLink>
              ) : (
                ""
              )}
            </li>
          );
        })}
      </ul>
    );
  }

  get endNav() {
    const { activePath } = this.props;
    const navList = this.props.pages.filter((page) => !list.includes(page.title));

    return (
      <div
        data-uk-navbar
        className={`${
          Auth.isAuthenticated() ? "uk-visible@l uk-navbar-center" : "uk-visible@m uk-margin-right"
        }`}
        style={{
          zIndex: 2,
        }}
      >
        {Auth.isAuthenticated() ? (
          <div className={`gh-text-normal ${Auth.isAuthenticated() ? "uk-navbar-item " : ""}`}>
            {this.pagesList(navList)}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  get midNav() {
    const pathList = [];
    const { activeCountry, activeEvent, activeEventKey } = this.state;
    const { menuItems } = this.props;
    const navList = this.props.pages.filter((page) => list.includes(page.title));
    const currentPathName = window.location.pathname.split("/")[1];
    const countries = menuItems?.navbar?.destinations;
    const eventsMenuItems = menuItems?.navbar?.events;
    const handleRedirect =
      (url, anchor = "") =>
      () => {
        localStorage.setItem("scroll", anchor);
        window.location.href = url;
      };

    /*Show old header for path list*/
    return pathList.includes(currentPathName) ? (
      <div data-uk-navbar className="uk-visible@m uk-flex-middle gh-main-center-menu">
        <div className="gh-text-normal">{this.pagesList(navList)}</div>
      </div>
    ) : (
      <React.Fragment>
        <ul className="header-desktop-menu uk-visible@m">
          <li className="header-desktop-menu-item">
            <a href="/helicopterhire" className="link">
              {staticService.findByAlias("bespokeMenu")}
            </a>
          </li>

          <li className="header-desktop-menu-item">
            <span className="link">
              {staticService.findByAlias("helicopterCharterInEuropeMenu")}
            </span>

            <div className="header-desktop-submenu">
              <div className="header-desktop-submenu__col left-side">
                <ul className="header-desktop-submenu__col_list">
                  {countries?.map((country) => (
                    <li
                      className={`header-desktop-submenu__col_item ${
                        country.name === activeCountry?.name ? "--active" : ""
                      }`}
                      key={country.name}
                      onMouseEnter={() => {
                        this.setState({ activeCountry: country });
                      }}
                    >
                      {country.name}
                      <img className="gh-arrow-open-menu" src={backArrowGreenIcon} alt="next" />
                    </li>
                  ))}
                  <li className="header-desktop-submenu__col_item --redirect">
                    <a href="/destinations" className="link">
                      {staticService.findByAlias("seeAllButton")}
                      <img className="gh-arrow-open-menu" src={link} alt="next" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="header-desktop-submenu__col right-side">
                <div className="header-desktop-submenu__group-list">
                  {/*<div className="header-desktop-submenu__group-item --title">*/}
                  {/*  {activeCountry?.name}*/}
                  {/*</div>*/}
                  {activeCountry?.types?.map((type) => (
                    <div className="header-desktop-submenu__group-wrapper" key={uid().uid}>
                      <div className="header-desktop-submenu__group-item --group-title">
                        {type.label}
                      </div>
                      {type?.locations?.map((location) => (
                        <div
                          className="header-desktop-submenu__group-item"
                          key={location.location_name}
                        >
                          {location.url ? (
                            <a href={`/destinations/${location.url}`}>{location.location_name}</a>
                          ) : (
                            <NavLink
                              to={`/destinations?country=${location.country_code}&location_name=${location.location_name}&flight_type=${type.type}`}
                            >
                              {location.location_name}
                            </NavLink>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </li>

          <li className="header-desktop-menu-item">
            <span className="link">
              {staticService.findByAlias("hlicopterCharterToEventsMenu")}
            </span>

            <div className="header-desktop-submenu">
              <div className="header-desktop-submenu__col left-side">
                <ul className="header-desktop-submenu__col_list">
                  {eventsMenuItems &&
                    Object.keys(eventsMenuItems)?.map((key) => (
                      <li
                        className={`header-desktop-submenu__col_item ${
                          activeEventKey === key ? "--active" : ""
                        }`}
                        key={key}
                        onMouseEnter={() => {
                          this.setState({ activeEvent: eventsMenuItems[key], activeEventKey: key });
                        }}
                      >
                        {key}
                        <img className="gh-arrow-open-menu" src={backArrowGreenIcon} alt="next" />
                      </li>
                    ))}
                  <li className="header-desktop-submenu__col_item --redirect">
                    <a href="/events" className="link">
                      {staticService.findByAlias("seeAllButton")}
                      <img className="gh-arrow-open-menu" src={link} alt="next" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="header-desktop-submenu__col right-side">
                <div className="header-desktop-submenu__group-list">
                  {/*bestsellers*/}
                  <div className="header-desktop-submenu__group-wrapper">
                    {activeEvent?.bestsellers?.length ? (
                      <div className="header-desktop-submenu__group-item --title">
                        Popular events
                      </div>
                    ) : (
                      ""
                    )}
                    {activeEvent?.bestsellers?.map((event) => (
                      <div className="header-desktop-submenu__group-item" key={event.name}>
                        <a href={`/events/${event.url}`}>{event.name}</a>
                      </div>
                    ))}
                  </div>

                  {/*events*/}
                  {activeEvent?.events &&
                    Object.keys(activeEvent?.events).map((key) => (
                      <div className="header-desktop-submenu__group-wrapper" key={key}>
                        <div className="header-desktop-submenu__group-item --group-title">
                          {key}
                        </div>
                        {activeEvent?.events[key].map((event) => (
                          <div className="header-desktop-submenu__group-item" key={event.name}>
                            <a href={`/events/${event.url}`}>{event.name}</a>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </li>

          <li className="header-desktop-menu-item">
            <a href="/transfers" className="link">
              {staticService.findByAlias("shuttleMenu")}
            </a>
          </li>

          <li className="header-desktop-menu-item">
            <a href="/tours" className="link">
              {staticService.findByAlias("helicopterToursMenu")}
            </a>
          </li>
        </ul>
        <ul className="header-desktop-menu --right-side uk-visible@m">
          <li className="subnav header-desktop-menu-item">
            <button className="subnavbtn gh-text-green">
              {staticService.findByAlias("techSolutionsMenu")}
            </button>
            <div className="subnav-content">
              <li>
                <a
                  onClick={() => {
                    this.props.handleRedirect("/pricingcalculator");
                  }}
                  className="link"
                >
                  {staticService.findByAlias("theGetHeliApiMenu")}
                </a>
              </li>
              <li>
                <a href="/helicoptermarketplace">
                  {staticService.findByAlias("theGetHeliMarketplaceMenu")}
                </a>
              </li>
              <li>
                <NavLink to="/account/login">
                  {staticService.findByAlias("resetPasswordRedirect")}
                </NavLink>
              </li>
            </div>
          </li>
        </ul>
      </React.Fragment>
    );
  }

  get dropdownNav() {
    if (this.props.showNavigation && Auth.isAuthenticated()) {
      return (
        <div data-uk-navbar className="uk-visible@l uk-navbar-right">
          <div className="uk-navbar-item gh-text-normal">
            <ul {...this.props.ulProps}>
              {this.props.pages.map((page, index) => {
                return (
                  <li key={index} {...page.props.listProp}>
                    {page.isDropdown ? Nav.getDropdownTemplate() : ""}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  }

  render() {
    return this.props.visible ? (
      <nav id={this.props.id} {...this.props.navProps} uk-navbar="mode: click; align: right;">
        {this.props.before()}
        {!Auth.isAuthenticated() ? this.midNav : ""}
        {this.endNav}
        {this.dropdownNav}
        {this.props.after()}
      </nav>
    ) : null;
  }
}

Nav.propTypes = {
  navProps: PropTypes.object,
  extraProps: PropTypes.object,
  ulProps: PropTypes.object,
  before: PropTypes.func,
  after: PropTypes.func,
  pages: PropTypes.array,
  visible: PropTypes.bool,
  showNavigation: PropTypes.bool,
  id: PropTypes.string,
  dashboardModel: PropTypes.object,
  handleRedirect: PropTypes.func,
  activePath: PropTypes.string,
  loadDestinations: PropTypes.func,
  getMenuItems: PropTypes.func,
  menuItems: PropTypes.object,
};

Nav.defaultProps = {
  dashboardModel: {},
  pages: [],
  id: "nav",
  visible: true,
  showNavigation: true,
  navProps: {},
  ulProps: {},
  extraProps: {},
  before: () => {},
  after: () => {},
};

const mapStateToProps = (state) => {
  return {
    menuItems: state.menuItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadDestinations,
      getMenuItems,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(Nav);
export { COMPONENT as Nav };
