import PropTypes from "prop-types";
import React from "react";
import { FormProvider } from "react-hook-form";
import FormItem from "./FormItem";
import FormList from "./FormList";

import "./styles/index.css";

export default function Form(props) {
  const { name, className, form, onSubmit, onValuesChange, initialValues, children } = props;
  const [valuesInitiated, setValuesInitiated] = React.useState(false);
  const subscription = React.useRef(null);
  const { handleSubmit } = form;

  const onFormChange = React.useCallback(
    (values, { type, name }) => {
      if (type === "change" && onValuesChange) {
        onValuesChange({ name, value: values[name] }, values);
      }
    },
    [onValuesChange],
  );

  React.useEffect(() => {
    if (form) {
      subscription.current = form.watch(onFormChange);
    }

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [form, onFormChange]);

  React.useEffect(() => {
    if (initialValues && !valuesInitiated) {
      Object.entries(initialValues).forEach(([key, value]) => form.setValue(key, value));

      setValuesInitiated(true);
    }
  }, [initialValues, form, valuesInitiated]);

  return (
    <FormProvider {...form}>
      <form className={className} id={name} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

Form.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  onValuesChange: PropTypes.func,
  initialValues: PropTypes.object,
};

Form.Item = FormItem;
Form.List = FormList;
