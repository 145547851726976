export default () => {
  const errorSection = document.getElementsByClassName("gh-input-errors");
  for (let i = 0; i < errorSection.length; i++) {
    if (errorSection[i].getElementsByTagName("li")[0].innerText) {
      const element = errorSection[i].getElementsByTagName("li")[0].getBoundingClientRect();
      window.scrollTo({
        top: element.top - document.body.getBoundingClientRect().top - 100,
        left: element.x,
        behavior: "smooth",
      });
    }
  }
};
