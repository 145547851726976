import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import groupBy from "../../../../../utils/groupBy";
import staticService from "../../../../../services/static.service";
import QuoteItem from "../../../../common/QuoteItem";
import OperatorCompanyBanner from "../../../../common/OperatorCompanyBanner";
import "./QuotesReceived.css";

const QuotesReceived = (props) => {
  const {
    requestDetail: { id, activeQuotes, ability_to_edit, has_to_expire, status },
    history,
    showPad,
  } = props;

  const [initQuotes, setInitQuotes] = useState([]);
  const [quotesGroupedByOperator, setQuotesGroupedByOperator] = useState({});

  useEffect(() => {
    if (!activeQuotes) return;

    // Filter quotes with status booking_init
    const filteredInitBooks = activeQuotes.filter(
      (item) => item.status === "booking_init" || item.old_status === "booking_init",
    );
    setInitQuotes(filteredInitBooks);

    // Filter quotes with status booking_init
    const otherQuotes = activeQuotes.filter(
      (item) => item.status !== "booking_init" && item.old_status !== "booking_init",
    );
    const groupByOperator = groupBy(otherQuotes, "operator");

    setQuotesGroupedByOperator(groupByOperator);
  }, [activeQuotes]);

  return (
    <div className="uk-margin-bottom" id="br_quotes_container">
      {initQuotes.length ? (
        <div>
          <div>
            <h4
              className="uk-width-1-1@s uk-padding-remove gh-title uk-margin-small-bottom"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("quoteChosenAwaitingLabel"),
              }}
            />
          </div>

          <div className="gh-group-operator">
            <OperatorCompanyBanner index={0} operatorCompany={initQuotes[0].operator_company} />
            <div className="gh-group-quotes">
              {initQuotes.map((quote) => (
                <QuoteItem
                  quote={quote}
                  brokerIsAllowedToEdit={ability_to_edit}
                  key={quote.id}
                  history={history}
                  requestID={id}
                  showPad={showPad}
                  hasQuoteToExpired={has_to_expire}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/*grouped quotes by operator*/}

      {Object.keys(quotesGroupedByOperator).length ? (
        <div>
          <hr className="uk-margin-small-bottom uk-margin-medium-top" />
          <h4
            className="uk-width-1-1@s uk-margin-bottom uk-padding-remove uk-margin-remove-top gh-title"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("quotesReceived"),
            }}
          />
          {Object.keys(quotesGroupedByOperator).map((key, index) => (
            <div key={index} className="gh-group-operator">
              <OperatorCompanyBanner
                index={index}
                operatorCompany={quotesGroupedByOperator[key][0].operator_company}
              />
              <div className="gh-group-quotes">
                {Object.keys(quotesGroupedByOperator[key]).map((quoteKey, index) => (
                  <QuoteItem
                    quote={quotesGroupedByOperator[key][quoteKey]}
                    brokerIsAllowedToEdit={ability_to_edit}
                    key={index}
                    history={history}
                    requestID={id}
                    showPad={showPad}
                    hasQuoteToExpired={has_to_expire}
                    status={status}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

QuotesReceived.propTypes = {
  requestDetail: PropTypes.object,
  history: PropTypes.object,
  showPad: PropTypes.func,
};

export default QuotesReceived;
