import React from "react";
import PropTypes from "prop-types";
import "reactjs-popup/dist/index.css";
import "./Modal.css";
import Popup from "reactjs-popup";
import ConditionalRendering from "../ConditionalRendering";
import clsx from "clsx";

const Modal = React.forwardRef(
  ({ children, className, title, buttonsComponent, openDefault, ...rest }, ref) => {
    const [open, setOpen] = React.useState(openDefault);
    const openModal = React.useCallback(() => setOpen(true), []);
    const closeModal = React.useCallback(() => setOpen(false), []);

    React.useImperativeHandle(ref, () => ({
      openModal,
      closeModal,
      open,
    }));

    React.useEffect(() => {
      return () => {
        setOpen(false);
      };
    }, []);

    return (
      <>
        <Popup
          className={clsx(className, "gh-popup-type-modal")}
          open={open}
          onClose={closeModal}
          {...rest}
        >
          {Boolean(title) && <h3 className="gh-modal-title">{title}</h3>}
          {children}
          <ConditionalRendering showComponent={Boolean(buttonsComponent)}>
            <div className="gh-modal-buttons">{buttonsComponent}</div>
          </ConditionalRendering>
          <div onClick={closeModal} className="gh-modal-close-btn">
            <CloseSVG />
          </div>
        </Popup>
      </>
    );
  },
);

Modal.displayName = "ModalForwardRef";

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonsComponent: PropTypes.element,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  openDefault: PropTypes.bool,
  lockScroll: PropTypes.bool,
  /* eslint-disable */
  // trigger: PropTypes.element,
  // disabled: PropTypes.bool,
  // nested: PropTypes.bool,
  // defaultOpen: PropTypes.bool,
  // on: PropTypes.any,
  // position: PropTypes.oneOf([
  //   "top left",
  //   "top center",
  //   "top right",
  //   "right top",
  //   "right center",
  //   "right bottom",
  //   "bottom left",
  //   "bottom center",
  //   "bottom right",
  //   "left top",
  //   "left center",
  //   "left bottom",
  //   "center center",
  // ]),
  // offsetX: PropTypes.number,
  // offsetY: PropTypes.number,
  // arrow: PropTypes.bool,
  // modal: PropTypes.bool,
  // lockScroll: PropTypes.bool,
  // closeOnDocumentClick: PropTypes.bool,
  // closeOnEscape: PropTypes.bool,
  // repositionOnResize: PropTypes.bool,
  // mouseEnterDelay: PropTypes.number,
  // mouseLeaveDelay: PropTypes.number,
  // contentStyle: PropTypes.object,
  // overlayStyle: PropTypes.object,
  // arrowStyle: PropTypes.object,
  // keepTooltipInside: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /* eslint-enable */
};
Modal.defaultProps = {
  buttonsComponent: null,
  title: null,
  openDefault: false,
  lockScroll: true,
};
export default React.memo(Modal);

const CloseSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    data-svg="close-icon"
  >
    <line fill="none" stroke="#000" strokeWidth="1.1" x1="1" y1="1" x2="13" y2="13"></line>
    <line fill="none" stroke="#000" strokeWidth="1.1" x1="13" y1="1" x2="1" y2="13"></line>
  </svg>
);
