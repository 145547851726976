import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions, Control, Errors, Form } from "react-redux-form";
import { config, validationMessages, validationRules } from "../../../../../configs";
import {
  CustomValidateError,
  dateExpired,
  DateTimeComponent,
  GridComponent,
  range,
  rangeReverse,
  soonExpiresFunc,
  updateModel,
} from "../../../../../shared";
import * as types from "../../../../../actions";
import { validateTailNumber, updateAircraftFleetLocally } from "../../../../../actions";
import staticService from "../../../../../services/static.service";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../../common/location_autocomplete";
import googleApi from "../../../../../services/google.service";
import { CheckCard } from "../../common";
import UploadFiles from "../../../../forms/register/upload-files.component";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";
import Tooltip from "../../../../common/Tooltip";
import ErrorMessageComponent from "../../../../common/ErrorMessageComponent";
import { clearInvalidDate, transformPickMonth } from "../../../../../utils/dateTimePicker";

const modelName = "fleetModel";

const yearsRange = rangeReverse(config.yearRanges.end, config.yearRanges.start);

const maxCrew = range(1, config.max_crew);

const { required, max, min, fieldIsRequiredOnlyNumbers, numeric } = validationRules;

class AccountFleetFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelName: modelName,
      manufacturerModels: [],
      airCraft: null,
      airCraftModel: null,
      isLoaded: false,
      years: [],
      showLoader: false,
      maxPassengers: [],
      home_base: null,
      home_info: null,
      selectedHome: false,
      address: null,
      noFilesError: false,
      helicopterOnAocWarning: !props.fleetModel.is_helicopter_on_aoc /*true*/,
      expireSoonError: false,
      dateExpireError: false,
      noInsuranceEvidenceWarning: false,
      uploadingFile: false,
      filesUploadedCount: 0,
      filesToUploadCount: 0,
    };
    this.setSelectedModel = this.setSelectedModel.bind(this);
    this.validateTailNumber = this.validateTailNumber.bind(this);
    this.updateOnFileRemove = this.updateOnFileRemove.bind(this);
    this.handleUpdateProgressStatus = this.handleUpdateProgressStatus.bind(this);
    this.dateError = this.dateError.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.setSelectedModel(this.props.manufacturers, this.props.selectedModel);
  }

  componentDidMount() {
    const { manufacturers, fleetModel } = this.props;

    this.setSelectedModel(manufacturers, {
      manufacturer: +fleetModel.manufacturer,
      type_id: +fleetModel.type_id,
    });

    if (fleetModel && fleetModel.registration_number && fleetModel.registration_number.length) {
      this.context.store.dispatch(actions.setTouched(`${modelName}.expiration_date`));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.setState({ showLoader: false });
      this.context.store.dispatch(actions.setPending(modelName, false));
    }
    if (this.props.auth !== nextProps.auth && nextProps.auth.invitationResponse) {
      this.setState({ isVisible: false });
      this.props.changeView();
    }

    if (
      (nextProps.selectedModel && nextProps.selectedModel.manufacturer && !this.state.isLoaded) ||
      this.props.selectedModel.id !== nextProps.selectedModel.id
    ) {
      this.setSelectedModel(this.props.manufacturers, nextProps.selectedModel);
    }
    if (this.props.heliInsuranceFilesModel.length !== nextProps.heliInsuranceFilesModel.length) {
      this.context.store.dispatch({ type: types.CLEAR_ERRORS });
      this.context.store.dispatch(actions.resetValidity(`${modelName}.files`));
    }
    if (!nextProps.fleetModel.is_helicopter_on_aoc) {
      this.setState({ helicopterOnAocWarning: true });
    } else {
      this.setState({ helicopterOnAocWarning: false });
    }
    if (
      nextProps.fleetModel.expiration_date &&
      soonExpiresFunc(nextProps.fleetModel.expiration_date)
    ) {
      this.setState({ expireSoonError: true });
    } else {
      this.setState({ expireSoonError: false });
    }
    if (nextProps.fleetModel.expiration_date && dateExpired(nextProps.fleetModel.expiration_date)) {
      this.setState({ dateExpireError: true });
    } else {
      this.setState({ dateExpireError: false });
    }
    if (
      (!!nextProps.fleetModel.expiration_date && !nextProps.heliInsuranceFilesModel.length) ||
      (nextProps.errors && nextProps.errors.files)
    ) {
      this.setState({ noInsuranceEvidenceWarning: true });
    } else {
      this.setState({ noInsuranceEvidenceWarning: false });
    }
  }

  componentDidUpdate() {
    const { filesUploadedCount, filesToUploadCount, uploadingFile } = this.state;
    if (filesToUploadCount !== 0) {
      if (filesToUploadCount !== filesUploadedCount && !uploadingFile) {
        this.setState({ uploadingFile: true });
      }

      if (filesToUploadCount === filesUploadedCount && uploadingFile) {
        this.setState({ uploadingFile: false, filesUploadedCount: 0, filesToUploadCount: 0 });
      }
    }
  }

  handleUpdateProgressStatus(status, count, decreaseCount = 0) {
    if (status === "uploading") {
      this.setState({ filesToUploadCount: this.state.filesToUploadCount + count });
    } else if (status === "uploaded") {
      this.setState({ filesUploadedCount: this.state.filesUploadedCount + 1 });
    } else if (status === "error" && decreaseCount) {
      this.setState({
        uploadingFile: false,
        filesToUploadCount: this.state.filesToUploadCount - decreaseCount,
      });
    } else if (status === "error") {
      this.setState({ uploadingFile: false });
    }
  }

  setSelectedModel(manufacturers, selectedModel) {
    const filteredManufacturers = manufacturers.filter(
      (manufacturer) => manufacturer.id === selectedModel.manufacturer,
    );

    if (filteredManufacturers.length > 0) {
      const filteredManufacturerModels = filteredManufacturers[0].types.filter(
        (model) => model.id == selectedModel.type_id,
      );

      this.setState({
        airCraftModel: filteredManufacturerModels[0],
        years: yearsRange,
        airCraft: filteredManufacturers[0],
        manufacturerModels: filteredManufacturers[0].types,
        isLoaded: true,
        maxPassengers:
          filteredManufacturerModels.length > 0
            ? range(1, filteredManufacturerModels[0].max_passengers)
            : [],
      });
    }
  }

  onSuccessValidation(fleet, onSuccessAction = true) {
    const changedFleet = {
      manufacturer: this.state.airCraft.name,
      type_id: this.state.airCraftModel.model,
    };

    this.props.onUpdateItems(fleet, changedFleet, onSuccessAction);
  }

  onSubmit(fleet) {
    const { heliInsuranceFilesModel, heliPhotosFilesModel, heliEvidenceAOCModel } = this.props;
    // refactor: hot fix for validation(home-base, aoc_document)
    if (this.isAutocompleteHomebaseInvalid(fleet)) return;
    if (this.isAOCInvalid(heliEvidenceAOCModel)) return;
    //----------------
    const docs = [].concat(heliInsuranceFilesModel, heliPhotosFilesModel, heliEvidenceAOCModel);

    let files = [];
    for (let i = 0; i < docs.length; i++) {
      const { file_data, ...file } = docs[i];

      const postFile = {
        file_name: file.name,
        file_type: file.type,
        document_type: file.document_type,
        upload: !file.name,
        ...file,
      };

      files.push(postFile);
    }
    files = files.filter((file) => !!file.file_name);

    let craft = {
      ...fleet,
      files: files,
      date_last_refurbished: clearInvalidDate(transformPickMonth(fleet.date_last_refurbished)),
    };

    if (craft.lat) {
      craft = { ...craft, home_base_coords: `${craft.lat}|${craft.lng}` };

      delete craft.lat;
      delete craft.lng;
    }

    this.context.store.dispatch(
      validateTailNumber(craft, this.onSuccessValidation.bind(this, craft), false),
    );
    this.setState({ selected: false, address: null });
  }

  onPickerChange(model, value) {
    this.context.store.dispatch(actions.resetErrors("fleetModel"));
    this.dateError();
    return updateModel(model, value);
  }

  onModelChange(model, value) {
    const filtered = this.state.manufacturerModels.filter(
      (manufacturer) => manufacturer.id === parseInt(value, 10),
    );
    if (filtered.length > 0) {
      this.setState({
        airCraftModel: filtered[0],
        years: yearsRange,
        maxPassengers: range(1, filtered[0].max_passengers),
      });
    }
    this.resetMaxPassengers();
    return updateModel(model, value);
  }

  onManufactureChange(model, value) {
    const filtered = this.props.manufacturers.filter(
      (manufacturer) => manufacturer.id === parseInt(value, 10),
    );
    if (filtered.length > 0) {
      const { types } = filtered[0];
      this.setState({
        airCraft: filtered[0],
        manufacturerModels: types,
        maxPassengers: [],
      });
    }
    this.resetModel();
    this.resetMaxPassengers();
    return updateModel(model, value);
  }

  get isEdit() {
    return !!(this.props.selectedModel && this.props.selectedModel.id);
  }

  resetModel() {
    this.context.store.dispatch(actions.resetValidity(`${modelName}.type_id`));
    this.context.store.dispatch(actions.change(`${modelName}.type_id`, 0));
  }

  resetMaxPassengers() {
    this.context.store.dispatch(actions.resetValidity(`${modelName}.max_passengers`));
    this.context.store.dispatch(actions.change(`${modelName}.max_passengers`, 0));
  }

  updateGeocodeModel(value) {
    if (this.props.fleetModel.home_base.length === 0) this.setState({ address: null });
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
    };
    this.updateOnSelect(address);
  }

  updateOnSelect(address) {
    const {
      aircraft_vat,
      manufacturer,
      type_id,
      year_manufacture,
      date_last_refurbished,
      registration_number,
      max_passengers,
      id,
      max_crew,
      expiration_date,
      is_helicopter_on_aoc,
      files,
      price_per_hour,
      cruise_speed,
    } = this.props.fleetModel;

    const home_base = address.location;
    const lat = address.latitude;
    const lng = address.longitude;
    const source = address.source;
    const home_info = generateLocationAdditionalInfo(address);
    this.context.store.dispatch(
      actions.change(modelName, {
        aircraft_vat,
        manufacturer,
        type_id,
        id,
        year_manufacture,
        date_last_refurbished,
        registration_number,
        home_base,
        lat,
        lng,
        source,
        home_info,
        max_passengers,
        max_crew,
        expiration_date,
        is_helicopter_on_aoc,
        files,
        price_per_hour,
        cruise_speed,
        home_base_location: address,
      }),
    );
  }

  updateOnFileRemove(fileId) {
    const craft = {
      id: this.props.fleetModel.id,
      files: this.props.fleetModel.files.filter((item) => item.id !== fileId),
    };

    this.context.store.dispatch(updateAircraftFleetLocally(craft));
  }

  get verified() {
    const { status } = this.props.fleetModel;
    return (
      <div className="gh-status-box">
        <label className="gh-dark-blue">
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("aircraftVerified"),
            }}
          />
          {status === 2 ? (
            <span
              className="uk-margin-small-left gh-animated-label gh-yellow-tooltip"
              data-uk-icon="icon: info"
              data-uk-tooltip={`title: ${staticService.findByAlias(
                "verifiedButExpiresSoon",
              )}; pos: top-left`}
            />
          ) : (
            ""
          )}
          {status === 3 ? (
            <span
              className="uk-margin-small-left gh-yellow-tooltip"
              data-uk-icon="icon: info"
              data-uk-tooltip={`title: ${staticService.findByAlias(
                "mustBeVerified",
              )}; pos: top-left`}
            />
          ) : (
            ""
          )}
        </label>
        <div className="gh-verified-checkbox uk-text-center uk-margin-remove">
          <input
            type="checkbox"
            className={`fleet-status-checkbox uk-checkbox ${
              status !== 1 ? "gh-yellow-checkbox" : ""
            }`}
            readOnly={true}
            checked={status !== 3}
          />
        </div>
      </div>
    );
  }

  get unVerified() {
    return (
      <div className="gh-status-box">
        <label className="gh-dark-blue">
          <div className="uk-flex uk-flex-middle">
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("aircraftVerified"),
              }}
            />
          </div>
        </label>
        <div className="gh-verified-checkbox uk-text-center uk-margin-remove">
          <span
            className="uk-margin-small-left gh-yellow-tooltip"
            data-uk-icon="icon: info"
            data-uk-tooltip={`title: ${staticService.findByAlias(
              "notVerifiedMessage",
            )}; pos: top-right`}
          />
        </div>
      </div>
    );
  }

  onSelectLocation(handleClickOutside, address) {
    this.setState({ selected: true, address: address });
    this.context.store.dispatch(actions.resetValidity(`${modelName}.home_base`));
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(address);
    } else {
      this.updateOnSelect(address);
    }
  }

  fillCoordinates(address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(address);
      });
    }
  }

  validateTailNumber(val) {
    if (this.props.skipTailNumberValidation) return false;

    const validate = this.props.fleets.find(function (fleet) {
      return fleet.registration_number === val;
    });
    return !validate;
  }

  dateError() {
    const { errors } = this.props;
    const { expireSoonError, dateExpireError } = this.state;
    if (errors && errors.hasOwnProperty("expiration_date")) {
      return <Errors model=".expiration_date" show="touched" wrapper={CustomValidateError} />;
    }
    return (
      <ul className="ul validation-errors disabled-list gh-input-errors">
        {dateExpireError && (
          <li
            style={{ position: "initial" }}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("velidHeliInsuranceRequired"),
            }}
          />
        )}
        {expireSoonError && (
          <li
            style={{ position: "initial" }}
            className="gh-alert-warning"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("InsuranceWillExpireSoon"),
            }}
          />
        )}
      </ul>
    );
  }

  isAutocompleteHomebaseInvalid(fleet) {
    if (fleet) {
      return !fleet.lat && !fleet.lng && !fleet.home_base_coords;
    }
    const fleetModel = this.props.fleetModel;
    const fleetForm = this.props.fleetForm;
    const showAutoCompleteError =
      !fleetModel.lat &&
      !fleetModel.lng &&
      fleetForm.home_base.touched &&
      fleetForm.home_base.valid &&
      /*[!fleetModel.home_base_coords] for fleet edit form*/
      !fleetModel.home_base_coords;

    return showAutoCompleteError;
  }

  isAOCInvalid(aocFilesArray) {
    const showAutoCompleteError = !aocFilesArray?.length;
    if (showAutoCompleteError) {
      this.context.store.dispatch(
        actions.setErrors(`${modelName}.aoc_document`, {
          required: "Please provide the AOC document.",
        }),
      );
    }
    return showAutoCompleteError;
  }

  render() {
    const { selectedModel } = this.props;
    const disablingFields = Object.keys(this.props.selectedModel).length;
    const { status } = this.props.fleetModel;

    if (this.props.visible) {
      return (
        <Form
          model={modelName}
          onSubmit={this.onSubmit.bind(this)}
          className="gh-aircraft-form"
          validateOn="submit"
          validators={{
            cruise_speed: { required: (val) => val > 0 },
            price_per_hour: { required: (val) => val > 0 },
            aircraft_vat: numeric,
          }}
        >
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-0",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("registerTailNumber"),
                }}
              />
              <div className="uk-form-controls">
                <Control.text
                  model=".registration_number"
                  className={"uk-input"}
                  placeholder={"Tail Number"}
                  disabled={disablingFields}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    required,
                    max: max(10),
                  }}
                />
                <Errors
                  model=".registration_number"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    max: validationMessages().maxMessage(10),
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("TailNumber"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("status"),
                }}
              />
              <div className="uk-form-controls">
                <div
                  className={`gh-status-field ${
                    status !== undefined && status === 1 ? "green" : "pastel"
                  }`}
                >
                  {status !== undefined && status !== 4 ? this.verified : this.unVerified}
                </div>
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-1",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("manufacturer"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".manufacturer"
                  className={"uk-select"}
                  disabled={disablingFields}
                  changeAction={this.onManufactureChange.bind(this)}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select ...</option>
                  {this.props.manufacturers.map((manufacturer, i) => (
                    <option key={i} value={manufacturer.id}>
                      {manufacturer.name}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".manufacturer"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("manufacturer"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("model"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".type_id"
                  className={"uk-select"}
                  disabled={disablingFields}
                  changeAction={this.onModelChange.bind(this)}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select ...</option>
                  {this.state.manufacturerModels.map((type, i) => (
                    <option key={i} value={type.id}>
                      {type.model}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".type_id"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("model"),
                    ),
                  }}
                />
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-2",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("MaxCrewSize"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".max_crew"
                  className={"uk-select"}
                  disabled={disablingFields}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select Crew</option>
                  {maxCrew.map((number, i) => (
                    <option key={i} value={number}>
                      {number}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".max_crew"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("MaxCrewSize"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("maxPassengers"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".max_passengers"
                  disabled={disablingFields}
                  className={"uk-select"}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select passenger</option>
                  {this.state.maxPassengers.map((passenger, i) => (
                    <option key={i} value={passenger}>
                      {passenger}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".max_passengers"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("maxPassengers"),
                    ),
                  }}
                />
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-3",
              className: "uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("manufactureYear"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".year_manufacture"
                  className={"uk-select"}
                  disabled={disablingFields}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select ...</option>
                  {this.state.years.map((year, i) => (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".year_manufacture"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("manufactureYear"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("lastRefurbished"),
                }}
              />
              <div className="uk-form-controls uk-position-relative">
                <Control
                  model=".date_last_refurbished"
                  className={"uk-input gh-calendar-input"}
                  component={DateTimeComponent}
                  readOnly
                  controlProps={{
                    isValidDateBefore: true,
                    showClearIcon: true,
                    dateFormat: config.monthFormat,
                    model: modelName + ".date_last_refurbished",
                    dispatch: this.context.store.dispatch,
                  }}
                  changeAction={this.onPickerChange.bind(this)}
                  updateOn="change"
                />
                <Errors
                  model={".date_last_refurbished"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("lastRefurbished"),
                    ),
                  }}
                />
              </div>
            </div>
          </GridComponent>

          <GridComponent
            visible={true}
            attrProps={{
              id: "row-0",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("cruiseSpeed"),
                }}
              />
              <div className="uk-form-controls">
                <Control.text
                  model=".cruise_speed"
                  className={"uk-input"}
                  placeholder="100"
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    fieldIsRequiredOnlyNumbers: fieldIsRequiredOnlyNumbers,
                  }}
                />
                <Errors
                  model=".cruise_speed"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    fieldIsRequiredOnlyNumbers: "Field is required. \nPlease enter a valid number",
                    required: "Field is required.",
                  }}
                />
              </div>
            </div>
            <div>
              <label className={"uk-form-label required"}>
                {staticService.findByAlias("pricePerHour")}
                <span style={{ paddingLeft: 4 }}>({this.props.banking?.currency || ""})</span>
              </label>
              <div className="uk-form-controls">
                <Control.text
                  model=".price_per_hour"
                  className={"uk-input"}
                  placeholder="3500"
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    fieldIsRequiredOnlyNumbers: fieldIsRequiredOnlyNumbers,
                  }}
                />
                <Errors
                  model=".price_per_hour"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    fieldIsRequiredOnlyNumbers: "Field is required. \nPlease enter a valid number",
                    required: "Field is required.",
                  }}
                />
              </div>
            </div>
          </GridComponent>

          <GridComponent
            visible={true}
            attrProps={{
              id: "row-5",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("taxRateOfAircraftLabel"),
                }}
              />
              <div className="uk-form-controls">
                <Control.text
                  model=".aircraft_vat"
                  className={"uk-input"}
                  placeholder="20"
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    fieldIsRequiredOnlyNumbers: numeric,
                  }}
                />
                <Errors
                  model=".aircraft_vat"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    fieldIsRequiredOnlyNumbers: "Please enter a valid number",
                  }}
                />
              </div>
            </div>
            <div />
          </GridComponent>

          <div className="uk-margin-medium-top">
            {/*<label className='uk-form-label required' dangerouslySetInnerHTML={{__html: staticService.findByAlias('homeBase')}} />*/}
            <div className="uk-grid">
              <label
                className="uk-form-label required"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("homeBase"),
                }}
              />
              <span
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias("iconBaseHome")}`}
              />
            </div>
            <div className="uk-form-controls uk-position-relative">
              <LocationAutocompleteWrapper>
                {({
                  locations,
                  search,
                  handleClickOutside,
                  loader,
                  onBlur,
                  locationValidationTemplate,
                }) => {
                  return (
                    <div className={"uk-position-relative"}>
                      <LocationAutocompleteInput
                        className={["uk-input"]}
                        placeholder={"London"}
                        readOnly={false}
                        value={
                          this.props.fleetModel.home_base === "null"
                            ? ""
                            : this.props.fleetModel.home_base
                        }
                        coverageWarning={this.props.fleetModel.home_coverage_status}
                        additionalInfo={this.props.fleetModel.home_info}
                        onSelect={(location) => {
                          this.updateGeocodeModel(location);
                          return search(location, "home_base");
                        }}
                        onBlur={onBlur}
                        loader={loader}
                        debounce={500}
                        model={`${modelName}.home_base`}
                        onClick={() =>
                          this.context.store.dispatch(actions.setTouched(`${modelName}.home_base`))
                        }
                      />
                      <LocationAutocompleteList
                        onSelect={this.onSelectLocation.bind(this, handleClickOutside)}
                        locations={locations}
                      />
                      {this.isAutocompleteHomebaseInvalid() && (
                        <ErrorMessageComponent>
                          {staticService.findByAlias("pleaseSelect")}
                        </ErrorMessageComponent>
                      )}
                      {locationValidationTemplate(
                        `${modelName}.home_base`,
                        ".home_base",
                        "home_base",
                        "touched",
                        {
                          required,
                          min: min(3),
                        },
                      )}
                    </div>
                  );
                }}
              </LocationAutocompleteWrapper>
            </div>
          </div>

          <div className="uk-margin-medium">
            <div className="uk-margin-small-bottom">
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("aocLabel"),
                }}
              />
              {this.state.helicopterOnAocWarning ? (
                <span
                  className="uk-margin-small-left gh-animated-label"
                  data-uk-icon="icon: info"
                  data-uk-tooltip={`title: ${staticService.findByAlias(
                    "aocCheckboxUntickMessage",
                  )}; pos: top-left`}
                />
              ) : (
                ""
              )}
            </div>
            <CheckCard
              warning={this.state.helicopterOnAocWarning}
              model=".is_helicopter_on_aoc"
              title="helicopterInAoc"
            />
          </div>
          <div className="uk-margin-medium uk-margin-remove-bottom">
            <UploadFiles
              containerClass="uk-margin-remove-bottom"
              model={this.props.heliEvidenceAOCModel}
              modelName="heliEvidenceAOCModel"
              uploadTitle="heliEvidenceAOC"
              openModal={this.props.openPreview}
              entity="aircrafts"
              docType={3}
              docs={this.props.heliEvidenceAOCModel}
              onFileRemove={this.props.updateOnFileRemove}
              handleUpdateProgressStatus={this.handleUpdateProgressStatus}
              withOutGlobalLoading
              required
            />
            <Errors
              model=".aoc_document"
              className="uk-margin-bottom"
              show
              wrapper={CustomValidateError}
            />
          </div>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-4",
              className: "uk-margin-small-bottom uk-margin-top uk-child-width-expand@s",
            }}
          >
            <div key={selectedModel.id}>
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("insuranceHelicopter"),
                }}
              />
              <div className="uk-form-controls uk-position-relative">
                <Control
                  model=".expiration_date"
                  className="uk-input gh-calendar-input"
                  component={DateTimeComponent}
                  readOnly
                  controlProps={{
                    isValidDate: true,
                    showClearIcon: false,
                    dateFormat: config.dobFormat,
                    utc: true,
                  }}
                  changeAction={this.onPickerChange.bind(this)}
                  updateOn="change"
                />
                {this.dateError()}
              </div>
            </div>
            <div className="uk-visible@s" />
          </GridComponent>
          <div className="uk-margin-medium uk-margin-remove-bottom">
            <UploadFiles
              containerClass="uk-margin-bottom"
              model={this.props.heliInsuranceFilesModel}
              modelName="heliInsuranceFilesModel"
              uploadTitle="evidenceOfHelicopter"
              openModal={this.props.openPreview}
              entity="aircrafts"
              docType={1}
              docs={this.props.heliInsuranceFilesModel}
              customErrors={
                this.state.noInsuranceEvidenceWarning && (
                  <ul
                    className="ul validation-errors disabled-list gh-input-errors"
                    style={{ marginTop: 6 }}
                  >
                    <li
                      style={{ position: "unset" }}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("pleaseProvideEvidence"),
                      }}
                    />
                  </ul>
                )
              }
              onFileRemove={this.props.updateOnFileRemove}
              handleUpdateProgressStatus={this.handleUpdateProgressStatus}
              withOutGlobalLoading
            />

            <Errors
              model=".files"
              className="uk-margin-bottom"
              show
              wrapper={CustomValidateError}
            />
          </div>
          <div className="uk-margin-medium">
            <UploadFiles
              model={this.props.heliPhotosFilesModel}
              modelName="heliPhotosFilesModel"
              uploadTitle="helicopterPhotos"
              openModal={this.props.openPreview}
              docType={2}
              entity="aircrafts"
              docs={this.props.heliPhotosFilesModel}
              onFileRemove={this.props.updateOnFileRemove}
              handleUpdateProgressStatus={this.handleUpdateProgressStatus}
              withOutGlobalLoading
            />
          </div>
          <div className="uk-flex uk-flex-between">
            <button
              type="button"
              className="uk-button gh-cancel-btn gh-aircraft-btn"
              onClick={this.props.onClose}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("cancel"),
                }}
              />
            </button>

            {this.state.uploadingFile ? (
              <Tooltip
                pos="top-center"
                wrapperClassName="gh-tooltip-disabled-tab"
                trigger={
                  <Control.button
                    disabled={true}
                    type={"submit"}
                    model={modelName}
                    className="uk-button uk-button-primary"
                  >
                    {selectedModel.id
                      ? staticService.findByAlias("update")
                      : staticService.findByAlias("addAircraft")}
                  </Control.button>
                }
                addSpaceAtTheEnd={false}
              >
                {staticService.findByAlias("buttonLoadingTooltip")}
              </Tooltip>
            ) : (
              <Control.button
                type={"submit"}
                model={modelName}
                className="uk-button uk-button-primary"
              >
                {selectedModel.id
                  ? staticService.findByAlias("update")
                  : staticService.findByAlias("addAircraft")}
              </Control.button>
            )}
          </div>
        </Form>
      );
    }
    return null;
  }
}

AccountFleetFormComponent.defaultProps = {
  updateFileLoadingStatus: () => {},
};

AccountFleetFormComponent.propTypes = {
  onUpdateItems: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  skipTailNumberValidation: PropTypes.bool,
  continueButton: PropTypes.any,
  submitBtn: PropTypes.any,
  fleets: PropTypes.array,
  selectedModel: PropTypes.object,
  fleetModel: PropTypes.object,
  updateOnFileRemove: PropTypes.func,
  updateFileLoadingStatus: PropTypes.func,
  manufacturers: PropTypes.array,
  banking: PropTypes.object,
  fleetForm: PropTypes.object,
};

AccountFleetFormComponent.contextTypes = {
  store: PropTypes.object,
};

AccountFleetFormComponent.defaultProps = {
  manufacturers: [],
  skipTailNumberValidation: false,
};

const mapStateToProps = (state) => {
  return {
    manufacturers: state.manufacturer && state.manufacturer.length ? state.manufacturer : [],
    fleetModel: state.fleetModel,
    fleetForm: state.forms.fleetModel,
    heliInsuranceFilesModel: state.heliInsuranceFilesModel,
    heliPhotosFilesModel: state.heliPhotosFilesModel,
    heliEvidenceAOCModel: state.heliEvidenceAOCModel,
    errors: state.errorObj,
    banking: state.dashboard.banking,
  };
};

const COMPONENT = connect(mapStateToProps)(AccountFleetFormComponent);
export { COMPONENT as AccountFleetFormComponent };
