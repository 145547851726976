import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import InfoIcon from "./icon";
import condStrings from "../../../../utils/condString";

import "./index.css";

const Tooltip = (props) => {
  const { content, position, color, className } = props;
  const [style, setStyle] = useState({ top: 0, left: 0 });
  const trigger = useRef();
  const tooltipNode = useRef();

  const generateStype = () => {
    const triggerRect = trigger.current.getBoundingClientRect();
    const tooltipRect = tooltipNode.current.getBoundingClientRect();

    if (position === "top-left") {
      const top = triggerRect.top - tooltipRect.height - 15;
      const left = triggerRect.left - triggerRect.width / 2 - 7.5;

      setStyle({ top, left });
    }
  };

  useEffect(generateStype, [position]);

  return (
    <div className={condStrings("tooltip-container", className)} onMouseEnter={generateStype}>
      <div className="tooltip-container_icon" ref={trigger}>
        <InfoIcon color={color} />
      </div>
      <div
        className="tooltip-container_content"
        data-position={position}
        style={{ ...style }}
        ref={tooltipNode}
      >
        {content}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string,
  position: PropTypes.oneOf(["top-left", "top-right", "bottom-left", "bottom-right"]),
  color: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export { Tooltip };
