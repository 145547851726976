import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";

import "./index.css";

export function SectionArrow({ closed, onClick }) {
  return (
    <button
      type="button"
      tabIndex="-1"
      onClick={onClick}
      className={cs("section-arrow", closed && "closed")}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    </button>
  );
}

SectionArrow.propTypes = {
  closed: PropTypes.bool,
  onClick: PropTypes.func,
};

SectionArrow.defaultProps = {
  onClick: () => null,
};
