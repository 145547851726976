import * as types from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import Auth from "../services/auth";
import staticService from "../services/static.service";
import { staticText } from "../configs/";
import GetHeliEvents from "../services/event.service";
import { spinnerInstance, storage } from "../shared";
import googleApi from "../services/google.service";
import moment from "moment";

Api.reset();

export function validateUserBetaPasswordSuccess() {
  return { type: types.BETA_VALIDATION_PASSED, status: true };
}

export function defaultFilter(requests) {
  return { type: types.DEFAULT_FILTERS, requests };
}

export function validateUserBetaPassword(model) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userPassword");
    return Api.setProperty("skipErrorAlert", true)
      .setPath("validate", "beta")
      .post(model)
      .then(() => {
        dispatch(validateUserBetaPasswordSuccess());
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => {
        Api.setProperty("skipErrorAlert", false);
        return handlerError(err, dispatch);
      });
  };
}

export function loginUserSuccess(user) {
  Auth.setUserAuthData(user);
  staticService.loadItems().then((response) => {
    staticService.setItems(response.data);
  });

  return { type: types.LOGIN_SUCCESS, user };
}

export function getMapEventsList() {
  return function (dispatch) {
    return Api.setPath("public_events", "map")
      .getAll()
      .then((res) => {
        return Promise.resolve(
          res.map((event) => {
            return {
              ...event,
              category: { display_name: event.category },
              comments_count: 0,
              id: event.place_id,
              location: {
                country: event.secondary_text,
                id: event.place_id,
                latitude: event.latitude,
                longitude: event.longitude,
                name: event.location,
              },
              name: event.location,
              rating: 0,
              view_link: event.url,
            };
          }),
        );
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function login(userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userLogin");
    return Api.setPath("login")
      .setProperty("skipErrorAlert", true)
      .post({ ...userData, offset: moment().utcOffset() / 60 }, true)
      .then((user) => {
        dispatch(loginUserSuccess(user));
        dispatch(getMapEventsList()).then((eventsList) => {
          googleApi
            .getPadsByCoordinatesAndRadius()
            .then((pads) => {
              storage.add("landingSites", JSON.stringify([...eventsList, ...pads.data]));
            })
            .catch((err) => handlerError(err, dispatch));
        });
        Api.setProperty("skipErrorAlert", false);
        return Api.setPath("booking-requests/marketplace-filter")
          .getAll()
          .then((requests) => {
            dispatch(defaultFilter(requests));
            GetHeliEvents.trigger("requests");
          })
          .catch((err) => handlerError(err, dispatch));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export const loginByToken = (user, fn) => {
  return function (dispatch) {
    dispatch(loginUserSuccess(user));
    dispatch(getMapEventsList()).then((eventsList) => {
      googleApi
        .getPadsByCoordinatesAndRadius()
        .then((pads) => {
          storage.add("landingSites", JSON.stringify([...eventsList, ...pads.data]));
        })
        .catch((err) => handlerError(err, dispatch));
    });
    Api.setProperty("skipErrorAlert", false);
    return Api.setPath("booking-requests/marketplace-filter")
      .getAll()
      .then((requests) => {
        dispatch(defaultFilter(requests));
        GetHeliEvents.trigger("requests");
        fn();
      })
      .catch((err) => handlerError(err, dispatch));
  };
};

export function checkInvitationSuccess(response) {
  return { type: types.CHECK_INVITATION_SUCCESS, response };
}

export function checkInvitation(invitation, onError) {
  return function (dispatch) {
    return Api.setPath("register", "check-hash")
      .get(invitation)
      .then((response) => {
        dispatch(checkInvitationSuccess(response));
      })
      .catch((err) => handlerError(err, dispatch, onError, types.CHECK_INVITATION_FAILED));
  };
}

export function getAccountDetailsSuccess(account) {
  Auth.setAccount("account_detail", account);
  return { type: types.GET_ACCOUNT_DETAILS, account };
}

export function getAccountDetails() {
  return function (dispatch) {
    return Api.setPath("user", "bank-account")
      .setProperty("skipErrorAlert", true)
      .get(Auth.getUser().id)
      .then((account) => {
        dispatch(getAccountDetailsSuccess(account));
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function checkHashSuccess(response) {
  return { type: types.CHECK_HASH_SUCCESS, response };
}

export function checkHash(hash) {
  return function (dispatch) {
    return Api.setPath("forgotten", "password")
      .get(hash)
      .then((response) => {
        dispatch(checkHashSuccess(response));
      })
      .catch((err) => handlerError(err, dispatch, null, types.CHECK_HASH_FAILED));
  };
}

export function logoutUserSuccess(user) {
  Auth.logout();
  return { type: types.LOGOUT_SUCCESS, user };
}

export function logout() {
  return function (dispatch) {
    return Api.setPath("logout")
      .setRefreshToken(false)
      .getAll()
      .then((response) => {
        dispatch(logoutUserSuccess(response));
        Api.setRefreshToken(true);
        window.location.href = "/account/login";
      })
      .catch((err) => {
        Api.setRefreshToken(true);
        if (err.status === 402) {
          dispatch(logoutUserSuccess(err));
        } else {
          return handlerError(err, dispatch);
        }
      });
  };
}

export function forgotPasswordSuccess(data) {
  return { type: types.FORGOT_PASSWORD_SUCCESS, data };
}

export function forgotPassword(onSuccess, userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userEmailModel")
      .setProperty("skipErrorAlert", true)
      .setProperty("responseMessages", staticText.globalMessages.user.passwordRecover);
    return Api.setPath("forgotten", "password")
      .post(userData)
      .then((data) => {
        dispatch(forgotPasswordSuccess(data));
        onSuccess(data);
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function recoverPasswordSuccess(response) {
  return { type: types.RECOVER_PASSWORD_SUCCESS, response };
}

export function recoverPassword(hash, userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "passwordConfirmModel");
    return Api.setPath("forgotten", "password/" + hash)
      .post(userData)
      .then((data) => {
        dispatch(recoverPasswordSuccess(data));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateProfileSuccess(token) {
  Auth.setUserAuthData(token);
  return { type: types.UPDATE_PROFILE_SUCCESS, token };
}

export function updateProfile(userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "editProfileModel");
    return Api.setPath("user", "")
      .setProperty("responseMessages", staticText.globalMessages.user.account)
      .put(Auth.getUser().id, userData)
      .then((token) => {
        dispatch(updateProfileSuccess(token));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateLastLogged(onSuccess) {
  return function (dispatch) {
    return Api.setPath("user", "last-logged")
      .setProperty("skipErrorAlert", true)
      .post()
      .then((token) => {
        dispatch(updateProfileSuccess(token));
        if (onSuccess) onSuccess();
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateChangePasswordSuccess(response) {
  return { type: types.UPDATE_PASSWORD_SUCCESS, response };
}

export function updateChangePassword(userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "editChangePasswordModel").setProperty(
      "responseMessages",
      staticText.globalMessages.user.passwordUpdate,
    );
    return Api.setPath("user", "change/password")
      .post(userData)
      .then((data) => {
        dispatch(updateChangePasswordSuccess(data));
        Api.resetFormModel();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function registerUserSuccess(user, action) {
  staticService.loadItems().then((response) => {
    staticService.setItems(response.data);
  });
  return { type: action, user };
}

export function register(userData, files) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userRegisterModel").setProperty(
      "skipErrorAlert",
      true,
    );

    const _files = files.map((file) => {
      return {
        name: file.name,
        upload: file.upload,
        document_type: file.document_type,
      };
    });

    return Api.setPath("register", userData.type)
      .post({ ...userData, files: _files })
      .then((user) => {
        Auth.setUserAuthData(user);
        dispatch(getMapEventsList()).then((eventsList) => {
          googleApi
            .getPadsByCoordinatesAndRadius()
            .then((pads) => {
              storage.add("landingSites", JSON.stringify([...eventsList, ...pads.data]));
            })
            .catch((err) => handlerError(err, dispatch));
        });
        dispatch(registerUserSuccess(user, types.REGISTER_SUCCESS));
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function preRegister(userData, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userModalRegisterModel");
    return Api.setProperty("skipErrorAlert", true)
      .setPath("register", "pre-register")
      .post(userData)
      .then((user) => {
        Api.setProperty("skipErrorAlert", false);
        if (onSuccess) onSuccess(user);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function validateUserSuccess(action, user) {
  return { type: action, user };
}

export function validateFirstStepUserSuccess(action, user) {
  return { type: action, user };
}

export function validatingUser(userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userRegisterModel");
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath("register", userData.type + "/validate")
      .setProperty("skipErrorAlert", true)
      .post(userData)
      .then((user) => {
        dispatch(validateUserSuccess(types.ACCOUNT_REMOTE_VALIDATION, user));
        Api.setProperty("skipErrorAlert", false);
        spinnerInstance.setProp("type", "large").hide();
        return Promise.resolve(user);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function validatingFirstStep(userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userRegisterModel");
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath("register", "first/validate")
      .setProperty("skipErrorAlert", true)
      .post(userData)
      .then((user) => {
        dispatch(validateFirstStepUserSuccess(types.ACCOUNT_REMOTE_FIRST_VALIDATION, user));
        Api.setProperty("skipErrorAlert", true);
        spinnerInstance.setProp("type", "large").hide();
        return Promise.resolve(user);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function hubSpot(userData) {
  return function (dispatch) {
    return Api.setPath("register/hubspot")
      .setProperty("skipErrorAlert", true)
      .post(userData)
      .then(() => {
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function createAccountByInvitation(userData, hash, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "userRegisterModel").setProperty(
      "responseMessages",
      staticText.globalMessages.user.userRegister,
    );

    return Api.setPath("register", "invited/" + hash)
      .post(userData)
      .then((user) => {
        Auth.setUserAuthData(user);
        dispatch(registerUserSuccess(user, types.ACCOUNT_INVITATION_SUCCESS));
        onSuccess(user);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateAccountSuccess(response) {
  Auth.setAccount("account_detail", response);
  return { type: types.UPDATE_ACCOUNT, response };
}

export function updateAccount(userData) {
  return function (dispatch) {
    // Api.setDispatchAndValidationModel(dispatch, 'accountDetail')
    //   .setProperty('responseMessages', staticText.globalMessages.user.accountDetail)
    //   .setProperty('messagePlaceholders', staticText.stripePlaceholders);
    return Api.setPath("user", "account")
      .setProperty("skipErrorAlert", false)
      .put(Auth.getUser().id, userData)
      .then((data) => {
        Api.setProperty("skipErrorAlert", false);
        spinnerInstance.setProp("type", "large").show();
        return (window.location.href = data.stripe_account_link.url);
        // dispatch(updateAccountSuccess(data));
        // if (onSuccess) onSuccess(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateBankAccountSuccess(response) {
  Auth.setAccount("account_detail", response);
  return { type: types.UPDATE_BANK_ACCOUNT, response };
}

export function updateBankAccount(userData, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "bankAccountModel")
      .setProperty("messagePlaceholders", staticText.stripePlaceholders)
      .setProperty("responseMessages", staticText.globalMessages.user.bankAccount);

    return Api.setPath("user", "bank-account")
      .put(Auth.getUser().id, userData)
      .then((data) => {
        dispatch(updateBankAccountSuccess(data));
        Api.resetFormModel();
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateCardSuccess(response, type = types.UPDATE_CARD) {
  return { type: type, response };
}

export function updateCard(userData) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "cardUpdateModel")
      .setProperty("messagePlaceholders", staticText.stripePlaceholders)
      .setProperty("responseMessages", staticText.globalMessages.user.cards.updated);
    return Api.setPath("user", "card")
      .put(Auth.getUser().id, userData)
      .then((data) => {
        dispatch(updateCardSuccess(data));
        Api.resetFormModel();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function setDefaultCard(card) {
  return function (dispatch) {
    return Api.setPath("user", "card/update")
      .setProperty("responseMessages", staticText.globalMessages.user.cards)
      .put(Auth.getUser().id, { ...card })
      .then((data) => {
        dispatch(updateCardSuccess(data, types.SET_DEFAULT_CARD));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function deleteCard(card, onSuccess) {
  return function (dispatch) {
    return Api.setPath("user", "card/delete")
      .setProperty("responseMessages", staticText.globalMessages.user.cards.deleted)
      .put(Auth.getUser().id, { ...card })
      .then((data) => {
        if (onSuccess) onSuccess(data);
        dispatch(updateCardSuccess(data, types.DELETE_CARD));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function newCardSuccess(type, card) {
  return { type: type, card };
}

export function addCard(card, onSuccess, onError, type = types.NEW_CARD) {
  const post = { card_id: card };
  if (type === types.NEW_CARD_AND_DEFAULT) {
    post.set_default = true;
  }
  return function (dispatch) {
    return Api.setPath("user", "card/add")
      .setProperty("responseMessages", staticText.globalMessages.user.cards)
      .post(post)
      .then((data) => {
        if (onSuccess) onSuccess(data);
        dispatch(newCardSuccess(type, data));
        return data;
      })
      .catch((err) => {
        if (onError) onError(err);
        return handlerError(err, dispatch);
      });
  };
}

export function updateRole(user_id, onSuccess) {
  return function (dispatch) {
    return Api.setPath("user", "update/role")
      .setProperty("responseMessages", staticText.globalMessages.user.role)
      .post({ user_id: user_id })
      .then((response) => {
        onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateAgreement(state) {
  return function (dispatch) {
    return Api.setPath("user", "accept-terms")
      .setProperty("responseMessages", staticText.globalMessages.user.agreement)
      .post({ state: state })
      .then((user) => {
        dispatch(loginUserSuccess(user));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getPayments(args, onSuccess) {
  return function (dispatch) {
    return Api.setProperty("showSpinner", false)
      .setPath("user", "transactions")
      .setQueryParams(args)
      .getAll()
      .then((response) => {
        Api.setProperty("showSpinner", true);
        onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function removeUserSuccess(members) {
  return { type: types.USER_REMOVE_SUCCESS, members };
}

export function removeUser(id, email) {
  return function (dispatch) {
    return Api.setPath("user")
      .setProperty("messagePlaceholders", { email: email })
      .setProperty("responseMessages", staticText.globalMessages.team)
      .delete(id)
      .then((response) => {
        dispatch(removeUserSuccess(response));
        return response;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function pushAccountStatus() {
  return function (dispatch) {
    return Api.setPath("account", "status")
      .setProperty("skipErrorAlert", true)
      .getAll()
      .then((token) => {
        Auth.setUserAuthData(token);
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getHelicopterServiceTerms() {
  return function (dispatch) {
    return Api.setPath("get-helicopter-service-terms")
      .setProperty("skipErrorAlert", true)
      .getAll()
      .then((res) => res)
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateHelicopterServiceTerms(files) {
  return function (dispatch) {
    return Api.setPath("upload-helicopter-services-terms")
      .setProperty("skipErrorAlert", true)
      .post({ file: files[0] })
      .then((res) => res)
      .catch((err) => handlerError(err, dispatch));
  };
}

export function deleteHelicopterServiceTerms() {
  return function (dispatch) {
    return Api.setPath("remove-service-terms", "")
      .setProperty("skipErrorAlert", true)
      .delete("")
      .then((res) => res)
      .catch((err) => handlerError(err, dispatch));
  };
}
