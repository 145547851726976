import React from "react";
import staticService from "../../../../../services/static.service";
import PropTypes from "prop-types";

const OperatorComments = ({ children }) => {
  return (
    <div className="gh-quote-details-operator-comments">
      <div className="gh-operator-comments">
        <h4 className="gh-box-title">
          {staticService.findByAlias("operatorsComments").toString().toUpperCase()}:
        </h4>
        <p className="gh-box-p">{children && children.toString().length > 0 ? children : "---"}</p>
      </div>
    </div>
  );
};

OperatorComments.propTypes = {
  children: PropTypes.string,
};

export default OperatorComments;
