import React from "react";
import PropTypes from "prop-types";
import { AccountOnlineLayout } from "../../layouts";
import * as broker from "./broker";
import * as operator from "./operator";
import * as generic from "./common";
import { roles } from "../../../configs";
import auth from "../../../services/auth";
import { capitalizeFirstLetter } from "../../../shared";
import { NotFoundComponent } from "../";
import { RedirectComponent } from "../../redirect.component";

const DispatcherComponent = (WrappedComponent, skipLayout = false, useGenericComponent = false) => {
  return class DispatchComponent extends React.Component {
    constructor(props) {
      super(props);
    }

    get Component() {
      if (useGenericComponent) return generic[WrappedComponent];

      const role = roles[auth.getRole()];
      if (!role) return NotFoundComponent;
      const component = capitalizeFirstLetter(role) + WrappedComponent;
      switch (role) {
        case "broker":
          return broker[component] || false;
        case "operator":
          return operator[component] || false;
      }
    }

    get component() {
      return this.Component ? (
        <this.Component {...this.props}></this.Component>
      ) : (
        <RedirectComponent route={"/not-found"} />
      );
    }

    render() {
      if (skipLayout) {
        return this.component;
      }

      return (
        <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
          {this.component}
        </AccountOnlineLayout>
      );
    }
  };
};

DispatcherComponent.contextTypes = {
  store: PropTypes.object,
};

export { DispatcherComponent };
