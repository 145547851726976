import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { actions } from "react-redux-form";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { IconComponent, fireTracking, formatID, storage } from "../../../../../shared";
import { BlockComponent } from "../../../../common";
import { getNotifications, CLEAR_BROADCAST_EVENT } from "../../../../../actions/";
import { PaginationService } from "../../../../../services";
import auth from "../../../../../services/auth";
import { notificationsConfig, config, staticText } from "../../../../../configs";
import { NotificationsFiltersBar } from "./notifications-filters-bar.component";
import { NotificationItem } from "./notifications-item.component";
import { List } from "../../../../../shared/list/";
import staticService from "../../../../../services/static.service";
import Auth from "../../../../../services/auth";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../../../../../components";
import { ButtonWithBadgeComponent } from "../button-with-badge.component";
import moment from "moment";

class NotificationsListComponent extends Component {
  constructor(props, context) {
    super(props);

    this.toggleFiltersBar = this.toggleFiltersBar.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
    this.selectRoute = this.selectRoute.bind(this);
    this.state = {
      isFilterBarShown: false,
      isFilterSubmit: false,
      isAttentionRequired: false,
      filterFailed: "",
    };

    this.paginationService = new PaginationService(
      context.store.dispatch,
      getNotifications,
      config.paginationLimit,
      [],
    );
    this.paginationService.setProp("columns", notificationsConfig.columns);
    this.paginationService.setProp("headers", notificationsConfig.headers);
    this.paginationService.loadData();
    fireTracking(props.location.pathname);
    this.setFailed = this.setFailed.bind(this);
  }

  prepareRows(items) {
    return items.map((item) => {
      item.departure = moment(item.departure, "DD/MM/YYYY").format("DD MMMM, YYYY");
      return item;
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      if (this.state.isFilterSubmit) {
        this.setFailed(nextProps.items);
      }
      this.paginationService.populateItems(nextProps.items, this.prepareRows.bind(this));
    }

    if (nextProps.newNotification) {
      if (this.props.notification.id !== nextProps.notification.id) {
        this.paginationService.pushOnTop({
          ...nextProps.notification,
          departure: moment(nextProps.notification).format("DD MMMM, YYYY"),
        });
      }
    }
  }

  componentWillMount() {
    this.props.clearBroadcast();
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      ...prevState,
      isFilterBarShown: !prevState.isFilterBarShown,
    }));
  }

  handleFilterInputChange() {
    const filterKeys = Object.keys(this.props.filters);
    const filters = [];
    filterKeys.forEach((key) => {
      if (this.props.filters[key] !== "") {
        filters.push({ key: key, value: this.props.filters[key] });
      }
    });
    this.paginationService.reloadItems();
    this.paginationService.filters = filters;
    this.props.getNotifications(this.paginationService.page, filters);
    this.setState({
      isFilterSubmit: true,
    });
  }

  loadMore() {
    this.paginationService.loadMore();
    this.setState({ isFilterSubmit: false });
  }

  get emptyRow() {
    return (
      <div
        className="uk-background-default uk-padding uk-panel uk-text-center"
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("noNotifications"),
        }}
      />
    );
  }

  setFailed(items) {
    if (items.length === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  selectRoute(notification) {
    switch (notification.custom_type) {
      case "quote":
        return auth.getMainRole() === "broker"
          ? "/flights/requests/" + notification.request
          : "/flights/quotes/" + notification.quote;
      case "empty_leg":
        return "/flights/empty-legs/" + notification.empty_leg;
      case "request":
        return "/flights/requests/" + notification.request;
      case "request_details":
        return auth.getMainRole() === "broker"
          ? "/flights/requests/filter/open/" + notification.request
          : "/marketplace/trip/request/" + notification.request;
      default:
        return config.bookingRoute + "/" + notification.booking;
    }
  }

  get requestButtonProps() {
    storage.delete("request_form");
    return {
      componentProps: {
        isButton: false,
        path: "/new-request",
        actionTitle: staticService.findByAlias("requestPageQuote"),
        classNames: [
          "uk-button",
          "gh-req-button",
          "uk-align-center",
          "uk-margin-remove-top",
          "uk-width-1-6",
          "uk-padding-remove",
          "uk-margin-small-top",
        ],
        tooltipMessage: staticService.findByAlias("accountUnverified"),
        verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["broker_admin", "broker_team", "sole_trader"],
      component: IsVerifiedAuthorization,
    };
  }

  get marketButtonProps() {
    return {
      componentProps: {
        route: "/marketplace",
        label: staticService.findByAlias("operatorMarketPlaceButton"),
        classes: ["btn-pulse"],
        visible: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["operator_admin", "operator_team"],
      component: ButtonWithBadgeComponent,
    };
  }

  get buttonProps() {
    return Auth.getMainRole() === "operator" ? this.marketButtonProps : this.requestButtonProps;
  }

  render() {
    const { isFilterBarShown } = this.state;
    const notificationList = this.paginationService.items.map((item, i) => {
      return (
        <NavLink
          key={i}
          to={{
            pathname: this.selectRoute(item),
            state: { prevPath: this.props.location.pathname },
          }}
          className={item.is_read ? "read" : "unread"}
        >
          <NotificationItem
            isRead={item.is_read}
            notification={{ ...item, request_details: item.request }}
          />
        </NavLink>
      );
    });

    const filterCss = ["uk-align-right", "gh-filters-tab"];
    if (this.state.isFilterSubmit) filterCss.push(this.state.filterFailed);
    return (
      <BlockComponent visible={true} parentProps={{ className: "uk-margin-medium-top" }}>
        <div className="uk-padding-remove-bottom">
          <div className="uk-container">
            <div className="uk-width-1-1" style={{ marginBottom: 0 }}>
              <h3>Notifications</h3>
            </div>
            <div className="uk-width-1-1">
              <ComponentAuthorization
                component={this.buttonProps.component}
                componentProps={this.buttonProps.componentProps}
                roles={this.buttonProps.roles}
              />
            </div>

            <div className={filterCss.join(" ")}>
              <a style={styles.filterLink} className="uk-link-text" onClick={this.toggleFiltersBar}>
                {isFilterBarShown
                  ? staticService.findByAlias("close")
                  : staticService.findByAlias("filters")}{" "}
                {
                  <IconComponent
                    visible={true}
                    icon={"settings"}
                    id={"icon-settings"}
                    className={"uk-icon"}
                  />
                }
              </a>
            </div>
          </div>
        </div>

        <NotificationsFiltersBar
          visible={isFilterBarShown}
          modelName={"notificationFilters"}
          submit={this.handleFilterInputChange}
        />
        {this.paginationService.items.length > 0 ? (
          <List attrProps={{ className: "disabled-list gh-notifications" }}>
            {notificationList}
          </List>
        ) : (
          this.emptyRow
        )}

        {this.paginationService.showLoaderBtn ? (
          <div className={"uk-padding-small uk-text-center"}>
            <button
              type={"button"}
              onClick={this.loadMore}
              className={"uk-button uk-button-default "}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("showMore"),
                }}
              />
            </button>
          </div>
        ) : null}
      </BlockComponent>
    );
  }

  componentWillUnmount() {
    this.props.clearBroadcast();
  }
}

const styles = {
  filterLink: {
    textDecoration: "none",
  },
};

NotificationsListComponent.contextTypes = {
  store: PropTypes.object,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  const response = {
    notification: {},
    newNotification: false,
    items: state.notifications,
    filters: state.notificationFilters,
    company: state.company,
  };
  if (state.broadcast.newNotifications) {
    response.notification = state.broadcast.notification;
    response.newNotification = true;
  }

  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotifications: getNotifications,
      resetModel: () => dispatch(actions.reset("notificationFilters")),
      clearBroadcast: () => dispatch({ type: CLEAR_BROADCAST_EVENT }),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(NotificationsListComponent);
export { COMPONENT as NotificationsListComponent };
