import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  CodeOfConductComponent,
  FleetLayoutComponent,
  InvitationFormComponent,
  RegisterFormComponent,
  RegisterSelectRole,
  RegisterStepTitle,
  RegisterTabs,
  UploadFormComponent,
} from "../../";
import { AccountLayout } from "../../layouts";
import { config, OPERATOR_MAIN, pages, signUpTabs, tabs } from "../../../configs";
import { getRegisterCountries, hubSpot, loadHomeContent } from "../../../actions/";
import { fireTracking, redirectTo, setBrowserHistory, storage } from "../../../shared/";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { actions } from "react-redux-form";
import * as types from "../../../actions/actionTypes";

class RegisterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "role",
      showNav: true,
      article: {},
      tabs: tabs,
      homeRedirect: false,
      signUpTabs: signUpTabs,
      previousView: false,
      currentTab: this.setInitialTab(props.userType),
      previousSelectedRole: null,
    };
    this.filteredPages = this.filteredPages.bind(this);
    this.changeLandingView = this.changeLandingView.bind(this);
    this.clickTab = this.clickTab.bind(this);
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);
    fireTracking(props.location.pathname);
  }

  componentDidMount() {
    this.props.loadHomeContent();
    const _urlQueryObject = queryString.parse(this.props.location.search);
    this.populateForm();
    let role = OPERATOR_MAIN;
    if (_urlQueryObject.type) {
      role = _urlQueryObject.type;
    }

    if (_urlQueryObject.type === OPERATOR_MAIN) {
      this.clickTab(tabs[1]);
    }

    this.context.store.dispatch(actions.reset("userRegisterModel"));
    this.context.store.dispatch(actions.resetValidity("userRegisterModel"));
    this.context.store.dispatch(actions.reset("invitationsModel"));
    storage.delete("userRegisterModel");
    storage.delete("invitationsModel");
    this.context.store.dispatch(actions.reset("certificateFilesModel"));
    this.context.store.dispatch(actions.reset("aocFilesModel"));
    this.context.store.dispatch(actions.reset("evidenceFilesModel"));
    this.context.store.dispatch(actions.reset("fleetModels"));
    this.setSelectedTab(this.setInitialTab(role), false);
    this.context.store.dispatch({
      type: types.ACCOUNT_REMOTE_VALIDATION,
      user: { user_validation: 0 },
    });
    this.context.store.dispatch({
      type: types.ACCOUNT_REMOTE_FIRST_VALIDATION,
      user: { user_validation: 0 },
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.history.location.state && nextProps.history.location.state.role) {
      this.clickTab(tabs.find((tab) => tab.alias === nextProps.history.location.state.role));
      setTimeout(
        () => nextProps.history.replace({ ...nextProps.history.location, state: undefined }),
        1000,
      );
    }
    if (nextProps.items) {
      const signUp = nextProps.items.find((article) => article.alias === "sign_up");
      this.setState({ article: signUp });
    }
    if (nextProps.userType === "broker") {
      const tabs = signUpTabs.filter((tab) => tab.title !== "fleet" && tab.title !== "upload");
      this.setState({ signUpTabs: tabs });
    } else {
      this.setState({ signUpTabs: signUpTabs });
    }
  }
  populateForm() {
    const localStorageUser = storage.get("userRegisterModel");
    if (localStorageUser) {
      const model = JSON.parse(localStorageUser);
      if (model.email !== this.props.userRegisterModel.email) {
        model.tac_accepted = false;
        model.password = "";
        model.password_confirmation = "";
        this.context.store.dispatch(actions.change("userRegisterModel", model));
      }
    }
  }

  setSelectedTab(tab, skipQueryParam = true) {
    this.setState({ currentTab: tab });
    this.populateForm();
    const query =
      this.props.history.location.state && this.props.history.location.state.bundle
        ? {
            type: tab.alias,
            bundle: this.props.history.location.state.bundle,
          }
        : {
            type: tab.alias,
          };
    this.context.store.dispatch(actions.change("userRegisterModel.type", tab.alias));
    if (skipQueryParam) {
      setBrowserHistory(this.props.history, "/account/register", query);
    }
  }

  get invitationRedirectTab() {
    return this.props.userType !== "broker" ? "fleet" : "coc";
  }

  get cocRedirectTab() {
    return this.props.userType !== "broker" ? "upload" : "invitation";
  }

  redirectToHome() {
    this.setState({ homeRedirect: true });
  }
  changeLandingView() {
    const signUp = this.props.items.find((article) => article.alias === "sign_up");
    this.setState({ article: signUp });
  }

  setInitialTab(userType) {
    return tabs.reduce((prev, next) => {
      if (next.alias === userType) {
        return next;
      }
      return prev;
    });
  }

  clickTab(currentTab) {
    const { history } = this.props;
    if (currentTab.alias === "broker" && !history.location.state) {
      return history.push("/pricing");
    }
    if (
      currentTab.alias !== this.state.previousSelectedRole ||
      (!this.props.countries && !this.props.countries.length)
    ) {
      this.props.getRegisterCountries(currentTab.alias);
    }
    this.setSelectedTab(currentTab);
    this.setState(() => ({
      tabs: this.state.tabs.map((tab) => {
        tab.active = tab.title === currentTab.title;
        return tab;
      }),
      view: "account",
      previousSelectedRole: currentTab.alias,
    }));
  }

  get step() {
    switch (this.state.view) {
      case "account":
        return 1;
      case "company":
        return 2;
      case "invitation":
        return 3;
      case "fleet":
        return 4;
      case "upload":
        return 5;
      case "coc":
        return this.props.userType !== "broker" ? 6 : 4;
      // case 'summary':
      //   return this.props.userType !== 'broker' ? 5 : 4;
      default:
        return 0;
    }
  }

  get View() {
    switch (this.state.view) {
      case "role":
        return (
          <RegisterSelectRole
            clickTab={this.clickTab}
            changeLandingView={this.changeLandingView}
            article={this.state.article}
          />
        );
      case "account":
        return (
          <RegisterFormComponent
            tab={this.state.currentTab}
            userHomeType={this.props.userType}
            history={this.props.history}
            step={this.state.view}
            location={this.props.location}
            article={this.state.article}
            changeView={this.changeView.bind(this)}
            back={this.changeView.bind(this, "role", true)}
          />
        );
      case "company":
        return (
          <RegisterFormComponent
            tab={this.state.currentTab}
            userHomeType={this.props.userType}
            history={this.props.history}
            step={this.state.view}
            location={this.props.location}
            article={this.state.article}
            changeView={this.changeView.bind(this)}
            back={this.redirectToHome}
          />
        );
      case "invitation":
        return (
          <InvitationFormComponent
            tab={this.state.currentTab}
            changeView={this.changeView.bind(this, this.invitationRedirectTab)}
            back={this.changeView.bind(this, "company", false)}
            visible={true}
          />
        );
      case "fleet":
        return (
          <FleetLayoutComponent
            changeView={this.changeView.bind(this, "upload")}
            back={this.changeView.bind(this, "invitation", false)}
            visible={true}
          />
        );
      case "upload":
        return (
          <UploadFormComponent
            tab={this.state.currentTab}
            back={this.changeView.bind(this, "fleet", false)}
            changeView={this.changeView.bind(this, "coc")}
            visible={true}
          />
        );
      case "coc":
        return (
          <CodeOfConductComponent
            tab={this.state.currentTab}
            back={this.changeView.bind(this, this.cocRedirectTab, false)}
            visible={true}
          />
        );
      // case 'summary':
      //   return <SummaryFormComponent
      //     visible={true}
      //     step={this.step}
      //     back={this.changeView.bind(this, 'coc', false)}
      //   />;
      default:
        return null;
    }
  }

  changeView(view, isBack = false) {
    const {
      first_name,
      last_name,
      email,
      email_confirmation,
      password,
      password_confirmation,
      hub_spot_privacy_policy,
    } = this.props.userModel;
    this.setState(
      {
        view: view,
        showNav: false,
      },
      () => {
        if (view === "company") {
          this.context.store.dispatch(actions.resetErrors("userRegisterModel"));
          window.scrollTo(0, 0);
          this.props.hubSpot({
            first_name,
            last_name,
            email,
            email_confirmation,
            password,
            password_confirmation,
            hub_spot_privacy_policy,
          });
          this.context.store.dispatch(actions.change("filesExistState", true));
        }
      },
    );

    if (isBack) {
      storage.delete("userRegisterModel");
      this.context.store.dispatch(actions.reset("userRegisterModel"));
      this.context.store.dispatch(actions.reset("certificateFilesModel"));
      this.context.store.dispatch(actions.reset("aocFilesModel"));
      this.context.store.dispatch(actions.reset("evidenceFilesModel"));
    }
  }

  filteredPages() {
    return pages.filter((page) => {
      return !(page.hideOnPages && page.hideOnPages.indexOf("register") !== -1);
    });
  }

  render() {
    const { view, showNav, signUpTabs, homeRedirect } = this.state;
    const { redirect, match, title, userType, screenW } = this.props;

    if (homeRedirect) {
      return redirectTo("/");
    }
    if (redirect) {
      return redirectTo(config.redirectToPreRegister);
    }

    return (
      <AccountLayout
        activeRoute={match}
        pageTitle={title}
        navVisibility={showNav}
        pages={this.filteredPages(pages)}
      >
        <section
          className={`register-page 
      ${view === "role" ? "gh-role-view" : ""} 
      ${(view === "account" || view === "company") && screenW < 576 ? "gh-white-bg" : ""}
      ${this.step > 2 ? "gh-register-ex-padding" : ""}`}
        >
          {view !== "role" && (
            <div>
              <RegisterStepTitle userType={userType} step={this.step} />
              <RegisterTabs view={view} step={this.step} screen={screenW} tabs={signUpTabs} />
            </div>
          )}
          {this.View}
        </section>
      </AccountLayout>
    );
  }
}

RegisterComponent.contextTypes = {
  store: PropTypes.object,
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadHomeContent,
      hubSpot,
      getRegisterCountries,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    items: state.home,
    userType: state.userRegisterModel.type,
    redirect: state.betaFailed,
    screenW: state.screenDimensions.screenInnerW,
    userRegisterModel: state.forms.userRegisterModel,
    userModel: state.userRegisterModel,
    countries: state.countries,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
export { COMPONENT as RegisterComponent };
