import React from "react";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";

export class InfoWindowComponent extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map) {
      this.renderInfoWindow();
    }

    if (this.props.visible) {
      this.updateContent();
    }

    if (this.props.visible !== prevProps.visible || this.props.marker !== prevProps.marker) {
      this.props.visible ? this.openWindow() : this.closeWindow();
    }
  }

  componentDidMount() {
    if (this.props.map) {
      this.renderInfoWindow();
    }
  }

  renderInfoWindow() {
    const iw = (this.infoWindow = new google.maps.InfoWindow({
      content: "",
    }));

    google.maps.event.addListener(iw, "closeclick", this.onClose.bind(this));
    google.maps.event.addListener(iw, "domready", this.onOpen.bind(this));

    google.maps.event.addListener(iw, "click", (e) => {
      console.log(e);
    });
  }

  onOpen() {
    if (this.props.onOpen) this.props.onOpen();
  }

  onClose() {
    if (this.props.onClose) this.props.onClose();
  }

  updateContent() {
    this.infoWindow.setContent(this.props.children);
    this.infoWindow.setPosition(this.props.position);
  }

  renderChildren() {
    const { children } = this.props;
    return ReactDOMServer.renderToStaticMarkup(children);
  }

  openWindow() {
    this.infoWindow.setPosition(this.props.position);
    if (this.props.marker.visible) {
      this.infoWindow.open(this.props.map, this.props.marker);
    }
  }

  closeWindow() {
    this.infoWindow.close();
  }

  render() {
    return null;
  }
}

InfoWindowComponent.propTypes = {
  map: PropTypes.object,
  position: PropTypes.object,
  visible: PropTypes.bool,
  name: PropTypes.string,
};
