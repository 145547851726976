import React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteWithSubRoutes, returnRouteUrl } from "../../../../../shared";
import { WrapperComponent } from "../../../../WrapperComponent";
import { BrokerBookingRequestDetailComponent, BrokerRequestComponent } from "./";

export class BrokerBookingRequestComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <WrapperComponent>
        <Switch>
          {this.props.routes.map((route, i) => {
            return (
              <RouteWithSubRoutes
                key={i}
                title={route.title}
                onEnter={this.props.onEnter}
                parentRoute={this.props.match}
                {...returnRouteUrl(route, this.props.match.url)}
              />
            );
          })}
          {/*<Route path="/" render={(props) => <BrokerBookingRequestDetailComponent {...props}*/}
          {/*										onEnter={this.props.onEnter}*/}
          {/*										match={this.props.match} />}/>*/}
          <Route
            path="/"
            render={(props) => (
              <BrokerRequestComponent
                {...props}
                onEnter={this.props.onEnter}
                match={this.props.match}
              />
            )}
          />
        </Switch>
      </WrapperComponent>
    );
  }
}
