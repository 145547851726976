import React from "react";
import PropTypes from "prop-types";
import { BlockComponent } from "../../../common";
import { AccordionComponent } from "../../../../shared";
import staticService from "../../../../services/static.service";
import auth from "../../../../services/auth";

const BrokerQuoteSummary = ({
  showTitle = true,
  title = staticService.findByAlias("request-details"),
  topMargin = " uk-margin-medium-top",
  quote,
  getAccordionItems,
  setActiveTab,
  sectionClassName,
}) => {
  return (
    <BlockComponent
      visible={true}
      parentProps={{ className: ["uk-padding-remove-top", topMargin].join(" ") }}
    >
      <ul
        data-uk-accordion="collapsible: false"
        className="gh-accordion disabled-list gh-summary-accordion"
      >
        <li className="uk-open uk-primary gh-accordion-section">
          {showTitle ? (
            <span className={`uk-accordion-title gh-accordion-title ${sectionClassName}`}>
              {title}
            </span>
          ) : (
            ""
          )}
          <div className="uk-accordion-content gh-accordion-content uk-margin-remove gh-req-quotes-details">
            {auth.hasFeature("remove_anonymity_for_brokers") && !!quote.operator_company && (
              <div className="uk-flex uk-flex-between quote-summary_operator-company">
                <h4 className="label uk-margin-remove">{staticService.findByAlias("operator")}</h4>
                <h4 className="value uk-margin-remove">{quote.operator_company.name}</h4>
              </div>
            )}
            <AccordionComponent
              accordionId="gh-quote-detail-accordion"
              items={getAccordionItems}
              options={{ collapsible: true, multiple: true }}
              setActiveTab={setActiveTab}
            />
          </div>
        </li>
      </ul>
    </BlockComponent>
  );
};

BrokerQuoteSummary.propTypes = {
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  quote: PropTypes.object,
  sectionClassName: PropTypes.string,
};

BrokerQuoteSummary.defaultProps = {
  sectionClassName: "",
};

export { BrokerQuoteSummary };
