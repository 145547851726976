import React from "react";
import staticService from "../../../../services/static.service";
import moment from "moment";

const HeliMoreInfoComponent = ({
  heliKey,
  info: { manufacturer, engine_type, date_last_refurbished, year_manufacture, cruise_speed, range, payload },
}) => {
  const showElement = (el, date) => {
    return el ? (date ? moment(el, "YYYY-MM-DD").format("MMMM, YYYY") : el) : "";
  };

  const infoList = [manufacturer, engine_type, year_manufacture, date_last_refurbished, cruise_speed, range, payload];
  const infoLabels = [
    { label: "manufacturerLabel", unit: "" },
    { label: "engineType", unit: "" },
    { label: "yom", unit: "" },
    { label: "refurbishmentDate", unit: "" },
    { label: "speed", unit: " Knots" },
    { label: "range", unit: " NM" },
    { label: "payload", unit: " kg" },
  ];
  const heliInfo = () => {
    return infoList.map((item, index) =>
      item ? (
        <div key={index} data-uk-grid className="uk-margin-small-top">
          <div className="uk-width-1-2">
            <span
              className="solid"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias(infoLabels[index].label),
              }}
            />
          </div>
          <div className="uk-width-1-2">
            <span>{infoLabels[index].label === "refurbishmentDate" ? showElement(item, true) : showElement(item)}</span>
            {item ? <span>{infoLabels[index] && infoLabels[index].unit}</span> : ""}
          </div>
        </div>
      ) : (
        ""
      ),
    );
  };
  return (
    <div key={heliKey} className="gh-animated-description gh-heli-info">
      <div>{heliInfo()}</div>
    </div>
  );
};

export { HeliMoreInfoComponent };
