import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MainLayout } from "./main.layout";
import { AccountHeader } from "../common";
import { config, account } from "../../configs";
import { redirectTo, filteredPages, pageTitle, storage } from "../../shared";
import Auth from "../../services/auth";
import staticService from "../../services/static.service";
import { loadUserDashboardStatistic, SET_COMPANY } from "../../actions";
import "../../assets/css/components/loader.css";
import BroadcastService from "../../services/broadcast.service";
import SignupProgress from "../common/SignupProgress";
import { matchPath } from "react-router-dom";
class AccountOnlineLayout extends MainLayout {
  get header() {
    return (
      <div>
        <AccountHeader
          activeRoute={this.props.activeRoute}
          extraProps={{ navClassName: "uk-navbar-container uk-navbar" }}
          className={"gh-dashboard-nav"}
          pages={filteredPages(account).map((page) => {
            if (Auth.getMainRole() === "operator" && page.path === "/flights/requests") {
              return {
                ...page,
                path: "/flights/quotes",
              };
            }
            return { ...page };
          })}
        />
      </div>
    );
  }

  get footer() {
    return null;
  }

  get accountWarning() {
    return this.warningMessage;
  }

  get warningMessage() {
    const { company } = this.props;

    if (company.sign_up_steps) {
      if (!company.sign_up_steps.is_visible && company.genericUnverifiedMessages !== null) {
        const alias = company.genericUnverifiedMessages[Auth.getRole()];
        return (
          <div
            data-uk-alert
            className={"uk-alert-warning gh-account-restricted gh-alert-without-bg"}
          >
            <a className="uk-alert-close" data-uk-close></a>
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }}></span>
          </div>
        );
      }
    }
    return null;
  }

  get provisionalMessage() {
    const { dashboard, company } = this.props;

    if (company.genericMessage) {
      return (
        <div data-uk-alert className="gh-yellow-alert">
          <a className="uk-alert-close" data-uk-close />
          <div
            className="gh-alert-message"
            dangerouslySetInnerHTML={{
              __html: company.genericMessage,
            }}
          />
        </div>
      );
    }

    const message_on_booking_page = dashboard && dashboard.provisional;
    if (dashboard && dashboard.provisional_information) {
      const { message_outside_booking_page } = dashboard.provisional_information;
      return (
        <div data-uk-alert className="gh-yellow-alert">
          <a className="uk-alert-close" data-uk-close />
          <div
            className="gh-alert-message"
            dangerouslySetInnerHTML={{
              __html: message_on_booking_page
                ? message_on_booking_page
                : message_outside_booking_page,
            }}
          />
        </div>
      );
    }
    return null;
  }

  get accoutSigupProgress() {
    const { company, screenDimensions } = this.props;

    if (company.sign_up_steps) {
      if (company.sign_up_steps.is_visible) {
        return (
          <SignupProgress
            screenDimensions={screenDimensions}
            role={Auth.getMainRole()}
            steps={company.sign_up_steps.steps}
            isTeamMember={!!Auth.getRole().match(/_team/gi)}
          />
        );
      }
    }

    return null;
  }

  get main() {
    return (
      <main style={{ position: "relative", zIndex: 1 }}>
        {this.provisionalMessage}
        {this.accoutSigupProgress}
        {this.accountWarning}
        {this.props.children}
      </main>
    );
  }

  componentWillMount() {
    document.title = pageTitle(this.props.pageTitle);
    if (!this.props.company.id) {
      this.context.store.dispatch({ type: SET_COMPANY, company: Auth.company });
    }
  }
  componentDidMount() {
    document.body.classList.add("gh-dashboard");

    if (Auth.getUser() && !BroadcastService.isLoaded) {
      BroadcastService.setProperty("dispatch", this.context.store.dispatch);
      BroadcastService.broadcast();
      BroadcastService.setProperty("isLoaded", true);
    }
    if (this.props.dashboard && !this.props.dashboard.provisional_information) {
      this.props.loadUserDashboardStatistic();
    }
  }

  render() {
    if (this.props.isLogged === false) {
      return redirectTo(config.redirectToLogin);
    }
    return super.render();
  }
}

AccountOnlineLayout.contextTypes = {
  store: PropTypes.object,
};

AccountOnlineLayout.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

AccountOnlineLayout.defaultProps = {
  pageTitle: "Account",
};

function mapStateToProps(state) {
  return {
    isLogged: state.auth && state.auth.logout ? false : true,
    company: state.company,
    screenDimensions: state.screenDimensions,
    dashboard: state.dashboard,
  };
}

const component = connect(mapStateToProps, { loadUserDashboardStatistic })(AccountOnlineLayout);
export { component as AccountOnlineLayout };
