import React from "react";
import { NavLink } from "react-router-dom";
import auth from "../../services/auth";
import { AccountLayout, AccountOnlineLayout } from "../layouts";

export const NotFoundComponent = ({ match, title, attrProps = {} }) => {
  if (auth.getUser()) {
    return (
      <AccountOnlineLayout activeRoute={match} pageTitle={title}>
        <div {...attrProps}>
          <div className={"uk-margin-left uk-margin-xlarge-top"}>
            <div className="uk-container not-found-page">
              <h2>ERROR 404</h2>
              <div>
                Hmm, something went wrong. Click <NavLink to={"/"}>here</NavLink> to return to the
                Homepage.
              </div>
            </div>
          </div>
        </div>
      </AccountOnlineLayout>
    );
  }

  return (
    <AccountLayout activeRoute={match} pageTitle={title}>
      <div {...attrProps}>
        <div className={"uk-margin-left uk-margin-xlarge-top"}>
          <div className="uk-container not-found-page">
            <h2>ERROR 404</h2>
            <div>
              Hmm, something went wrong. Click <NavLink to={"/"}>here</NavLink> to return to the
              Homepage.
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};
