import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CustomValidateError, ModalComponent, TabComponent, updateModel } from "../../../../shared";
import { accountTabs, validationMessages, validationRules } from "../../../../configs";
import { BlockComponent } from "../../../common";
import { ButtonWithBadgeComponent, UserProfileComponent } from "../common";
import Auth from "../../../../services/auth";
import auth from "../../../../services/auth";
import staticService from "../../../../services/static.service";
import { AccountOnlineLayout } from "../../../layouts";
import { actions, Control, Errors, Form } from "react-redux-form";
import UploadFiles from "../../../forms/register/upload-files.component";
import { bindActionCreators } from "redux";
import { downloadDocImage, getCompanyDetails, saveCompanyChanges } from "../../../../actions";
import download from "../../../../assets/img/download.png";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

const { aocValidation, max } = validationRules;

class OperatorAocComponent extends UserProfileComponent {
  constructor(props) {
    super(props);
    const items = accountTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      selectedTab: items[1],
      tabs: items,
      modelName: "aocModel",
      selectedFile: {},
      fileReader: new FileReader(),
      files: [],
      pages: [],
      currentFile: null,
      docsChanged: false,
      documents: [],
    };
    this.successSubmit = this.successSubmit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getActive = this.getActive.bind(this);
    this.verifiedTabs = this.verifiedTabs.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.loadFileReader = this.loadFileReader.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.state.fileReader.onload = this.loadFileReader.bind(this);
  }

  componentDidMount() {
    this.props.getCompanyDetails();
    const { companyDetails } = this.props;
    if (companyDetails.documents && companyDetails.documents.length) {
      this.sortDocs(companyDetails.documents);
    }
  }

  onTabClick(tab, ev) {
    ev.preventDefault();
    this.setState({ selectedTab: tab });
  }

  getActive(loopEl) {
    if (loopEl.alias === this.state.selectedTab.alias) {
      return " uk-active";
    }
    return "";
  }

  componentWillReceiveProps(nextProps) {
    const { companyDetails, aocModel, aocFilesModel } = this.props;
    if (
      Object.keys(nextProps.companyDetails).length &&
      companyDetails.documents === undefined &&
      nextProps.companyDetails.documents.length
    ) {
      this.sortDocs(nextProps.companyDetails.documents);
    }
    if (companyDetails !== nextProps.companyDetails) {
      const copy = { ...nextProps.companyDetails };
      const obj = {
        aoc_shared: copy.aoc_shared.toString(),
        aoc_number: copy.aoc_number,
        aoc_company_name: copy.aoc_company_name,
      };
      this.context.store.dispatch(actions.change("aocModel", obj));
    }
    if (Object.keys(aocModel).length > 1 && aocModel !== nextProps.aocModel) {
      this.setState({ changed: true });
    }
    if (Object.keys(aocFilesModel).length && aocFilesModel !== nextProps.aocFilesModel) {
      this.setState({ changed: true });
    }
  }

  sortDocs(docs) {
    let aocList = [];
    if (docs) {
      docs.map((doc) => {
        if (doc.document_type === null || doc.document_type === 2) {
          aocList.push(doc);
        }
      });
    }
    this.context.store.dispatch(actions.change("aocFilesModel", aocList));
  }

  verifiedTabs(tabs) {
    if (auth.getUser().hasApiKey === false) {
      return tabs.filter((tab) => tab.alias !== "credentials");
    }
    return tabs;
  }
  loadFileReader(e) {
    PDFJS.getDocument(new Uint8Array(e.target.result)).then((pdf) => {
      // Hardcoded to match the current viewport
      let scale = 1.5;

      let viewport, canvas, context, renderContext;

      // This is a good example of why handling DOM directly w/React is a bad idea
      // Ideally we just use data and grab context from canvas using something like
      // <canvas ref={(c) => this.context = c.getContext('2d')} />
      // otherwise you need to manually keep track of DOM manipulations
      const pageContainer = this._pageContainer;
      let { pages } = this.state;
      pages.map((page) => pageContainer.removeChild(page));
      pages = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          viewport = page.getViewport(scale);

          // Prepare canvas using PDF page dimensions.
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = 600;

          // Render PDF page into canvas context.
          renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          pageContainer.appendChild(canvas);
          pages.push(canvas);
        });
      }
      this.setState({ pages });
    });
  }
  openModal(file) {
    this.setState({ selectedFile: file }, () => {
      this.uploadFileHandler(file);
      this.modal.open();
    });
  }
  closeModal() {
    this.modal.hide();
    setTimeout(() => {
      this.setState({
        selectedFile: {},
        files: [],
        pages: [],
        currentFile: null,
      });
    }, 500);
  }
  loadFile(file) {
    // Quick example of short-circuit evaluation
    file !== this.state.currentFile &&
      (this.setState({ currentFile: file }) || this.state.fileReader.readAsArrayBuffer(file));
  }

  uploadFileHandler(file) {
    const { files } = this.state;
    const blob = new Blob([file]);
    // const file = e.target.files[0];
    files.push(file);
    this.setState({ files });
    this.loadFile(blob);
  }

  downloadImg() {
    const url = this.state.selectedFile.file_data.slice(40);
    return this.props.downloadDocImage(this.state.selectedFile, url);
  }

  get modalContent() {
    const { selectedFile } = this.state;
    if (selectedFile && selectedFile.type && selectedFile.type.includes("application/pdf")) {
      return (
        <div className="gh-preview-file">
          {/*<div ref={(c) => (this._pageContainer = c)} />*/}
          <iframe src={selectedFile.file_data} title="preview" className="gh-preview-file-pdf" />
        </div>
      );
    } else {
      return (
        <div className="gh-preview-file gh-image-box">
          <img src={selectedFile.file_data} alt="preview" />
          <a
            className="uk-button uk-button-secondary uk-padding-remove gh-image-box-btn"
            onClick={this.downloadImg.bind(this)}
          >
            <img className="gh-image-box-icon" src={download} alt="download" />
            {staticService.findByAlias("download")}
          </a>
        </div>
      );
    }
  }

  previewFile() {
    return (
      <ModalComponent
        title={this.state.selectedFile.file_name || this.state.selectedFile.name || ""}
        ref={(modal) => (this.modal = modal)}
        id="preview-file-account"
        modalClass="gh-preview-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={this.closeModal.bind(this)}
      >
        {this.modalContent}
      </ModalComponent>
    );
  }
  successSubmit(details) {
    this.uploadedFiles([]);
    this.props.successHandler(details);
    this.setState({ isAocTouched: false });
    this.context.store.dispatch(actions.setUntouched(this.state.modelName));
    this.props.getCompanyDetails();
  }

  submitForm(model) {
    const { aocFilesModel, companyDetails } = this.props;
    const _model = { ...model };
    const companyModel = {
      ...companyDetails,
      aoc_shared: _model.aoc_shared,
      aoc_number: _model.aoc_number,
      aoc_company_name: _model.aoc_company_name,
    };
    this.setState({ changed: false });
    return this.props.submit(companyModel, aocFilesModel, this.successSubmit);
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("aocModel"));
    this.context.store.dispatch(actions.reset("aocFilesModel"));
  }

  render() {
    const { modelName, changed, selectedTab, tabs } = this.state;
    return (
      <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
        <section className={"team-page"}>
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom header-title",
            }}
          >
            {this.previewFile()}
            <div className="uk-width-1-1">
              <h3
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("account"),
                }}
              />
            </div>
            <div className={"uk-margin-bottom"}>
              <ButtonWithBadgeComponent
                route={"/marketplace"}
                classes={["btn-pulse"]}
                visible={true}
                label={staticService.findByAlias("operatorMarketPlaceButton")}
              />
            </div>
          </BlockComponent>
          <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
            <TabComponent
              items={tabs}
              id={"profile-tab"}
              visible={true}
              tabProps={{ className: "uk-tab uk-margin" }}
              onClick={this.onTabClick.bind(this)}
              selected={selectedTab}
              linkType={"nav"}
            />
            <div data-uk-grid>
              <div className="uk-width-1-1@s uk-width-1-3@m uk-visible@m">
                <div className="uk-width-3-4 uk-margin-small-top">
                  {" "}
                  {/*<MenuComponent items={this.verifiedTabs(bankingTabs)} />*/}
                </div>
              </div>
              <div className="uk-margin-top uk-width-3-1@s uk-width-2-3@m">
                <h2
                  className={"gh-text-slim uk-margin-remove-bottom"}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("airOperatorCertificateTitle"),
                  }}
                />
                <Form model={modelName} onSubmit={this.submitForm}>
                  <div className="uk-margin-top">
                    <div data-uk-grid>
                      <div className="uk-width-1-1">
                        <div className="uk-width-auto">
                          <label
                            dangerouslySetInnerHTML={{
                              __html: staticService.findByAlias("aocNumber"),
                            }}
                          />
                        </div>
                        <Control.text
                          className="uk-input"
                          model=".aoc_number"
                          changeAction={(model, value) => updateModel(model, value.toUpperCase())}
                          updateOn="change"
                          validators={{ aocValidation, max: max(20) }}
                        />
                        <Errors
                          model=".aoc_number"
                          show="touched"
                          wrapper={CustomValidateError}
                          messages={{
                            max: validationMessages().maxMessage(20),
                            aocValidation: staticService.findByAlias("aocAlphanumeric"),
                          }}
                        />
                      </div>
                      <div className="uk-width-1-1">
                        <div className="uk-width-auto">
                          <label
                            dangerouslySetInnerHTML={{
                              __html: staticService.findByAlias("sharedAOC"),
                            }}
                          />
                        </div>
                        <Control.text
                          className="uk-input"
                          model=".aoc_company_name"
                          changeAction={updateModel}
                          updateOn="change"
                        />
                      </div>
                      <div className="uk-width-1-1 uk-width-1-2@s">
                        <div className="uk-flex uk-flex-top">
                          <Control.radio
                            className="uk-checkbox gh-margin-top-5"
                            model=".aoc_shared"
                            changeAction={updateModel}
                            updateOn="change"
                            value={"1"}
                          />
                          <div className="uk-width-auto uk-margin-left">
                            <label
                              dangerouslySetInnerHTML={{
                                __html: staticService.findByAlias("licensedThroughCivilLabel"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-1 uk-width-1-2@s">
                        <div className="uk-flex uk-flex-top">
                          <Control.radio
                            className="uk-checkbox gh-margin-top-5"
                            model=".aoc_shared"
                            changeAction={updateModel}
                            updateOn="change"
                            defaultValue={"0"}
                            value={"0"}
                          />
                          <div className="uk-width-auto uk-margin-left">
                            <label
                              dangerouslySetInnerHTML={{
                                __html: staticService.findByAlias("notLicensedCompanyLabel"),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-1">
                        <UploadFiles
                          handleChange={() => this.setState({ changed: true })}
                          model={this.props.aocFilesModel}
                          modelName="aocFilesModel"
                          uploadTitle="evidenceOfAoc"
                          docType={2}
                          openModal={this.openModal.bind(this)}
                          docs={this.props.aocFilesModel}
                          entity="company_document"
                        />
                      </div>
                      <div className="uk-width-1-1 uk-margin-remove-top">
                        {changed ? (
                          <div
                            data-uk-alert
                            className="uk-alert-warning gh-account-restricted gh-without-bg"
                          >
                            <a className="uk-alert-close gh-alert-close" data-uk-close></a>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: staticService.findByAlias("userChangesAOCData"),
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <div className="uk-flex uk-flex-center uk-margin-remove-right">
                            <Control.button
                              model={modelName}
                              disabled={!changed}
                              type="submit"
                              className={"uk-button uk-button-primary uk-align-center"}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: staticService.findByAlias("update"),
                                }}
                              />
                            </Control.button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </BlockComponent>
        </section>
      </AccountOnlineLayout>
    );
  }
}

OperatorAocComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({
  screenDimensions,
  accountUpdated,
  aocFilesModel,
  aocModel,
  companyDetails,
}) {
  return {
    accountUpdated: accountUpdated,
    aocFilesModel: aocFilesModel,
    companyDetails,
    aocModel,
    ...screenDimensions,
  };
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submit: saveCompanyChanges,
      getCompanyDetails,
      downloadDocImage,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(OperatorAocComponent);
export { COMPONENT as OperatorAocComponent };
