import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { staticText, config, QUOTE_REJECTED, QUOTE_CLOSED } from "../../../configs";
import { aircraftTitle, checkObjectProp, formatID } from "../../../shared";
import Auth from "../../../services/auth";
import { LandingItem } from "../../pages/user-online/common";
import staticService from "../../../services/static.service";

export const QuoteActiveComponent = ({
  visible,
  info,
  download,
  showTail = true,
  redirectToRequest,
}) => {
  const landingTitle = () => {
    return Auth.getMainRole() === "broker"
      ? staticService.findByAlias("landigSitesTitleBroker")
      : staticService.findByAlias("landigSitesTitleOperator");
  };
  const displayLink = (id) => {
    return <NavLink to={`/flights/my-bookings/${id}`}>View Booking</NavLink>;
  };

  const rejectedMessage = (message) => {
    return [
      <div
        key="reject_msg_alias"
        className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top"
      >
        <p
          className="uk-margin uk-margin-small-left gh-text-secondary"
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("rejectionMessageLabel"),
          }}
        />
      </div>,
      <div
        key="reject_msg"
        className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top"
      >
        <p className="uk-margin uk-margin-small-left">{message}</p>
      </div>,
    ];
  };

  const reasonMessage = () => {
    return [
      <div
        key="reason_msg"
        className={
          info.booking_request.reason && info.booking_request.status
            ? "gh-outline-line uk-width-1-1"
            : "gh-outline-line disabled uk-width-1-1"
        }
      >
        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          {info.booking_request.status === "withdrawn" ? (
            <p
              className="uk-margin-small-left gh-text-secondary"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("reasonNoColon"),
              }}
            />
          ) : null}
        </div>
        <div className="uk-width-1-1 uk-card uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          {info.booking_request.status === "withdrawn" ? (
            <p className="uk-margin-small-left">
              <span>
                {info.booking_request.reason !== null ? info.booking_request.reason : "Unspecified"}
              </span>
            </p>
          ) : null}
        </div>
      </div>,
    ];
  };

  function getCurrency() {
    if (info.currency && info.currency.currency) {
      return info.currency.currency + " ";
    }
    return config.currency;
  }

  return visible ? (
    <div className="uk-background-default uk-padding-small uk-border-rounded">
      <div data-uk-grid className="gh-margin-bottom-quote">
        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m uk-margin-remove-top">
          <p
            className="uk-margin uk-margin-small-left  gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("Quoted"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m uk-margin-remove-top">
          <p className="uk-margin uk-margin-small-left">{checkObjectProp(info, "owner", "---")}</p>
        </div>
        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          <p
            className="uk-margin uk-margin-small-left  gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("status"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          <p className="uk-margin uk-margin-small-left uk-display-inline">
            {staticText.quoteStatuses[info.status] +
              (info && info.status === "closed" && info.booking ? " - " : "")}
            {info.booking_request.is_broker_verified === false && info.status === "on_hold" ? (
              <span
                className="uk-margin-small-left gh-animated-label gh-yellow-tooltip"
                data-uk-icon="icon: info"
                style={{ transform: "translateY(-2px)" }}
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "operQuoteOnHoldStatusTooltip",
                )}; pos: top-left`}
              />
            ) : (
              ""
            )}
          </p>
          {info && info.status === QUOTE_CLOSED && info.booking ? displayLink(info.booking) : ""}
        </div>
        {info && info.status === QUOTE_REJECTED ? rejectedMessage(info.rejected_message) : ""}

        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          <p
            className="uk-margin uk-margin-small-left gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("price"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          <p className="uk-margin uk-margin-small-left">
            <span className="gh-text-primary">
              <span
                dangerouslySetInnerHTML={{
                  __html: getCurrency(),
                }}
              />
              {info.price}{" "}
            </span>
            incl.{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: getCurrency(),
              }}
            />
            {info.quote_vat} of VAT
          </p>
        </div>

        {info && info.status !== QUOTE_REJECTED ? reasonMessage() : null}

        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          <p
            className="uk-margin uk-margin-small-left gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("helicopters"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          <p className="uk-margin uk-margin-small-left">
            {info.aircrafts && info.aircrafts.length > 0
              ? info.aircrafts.map((aircraft, index) => (
                  <span key={index + aircraft.aircraft_model}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: aircraftTitle(aircraft, showTail),
                      }}
                    />
                    {index !== info.aircrafts.length - 1 ? ", " : ""}
                  </span>
                ))
              : "---"}
          </p>
        </div>

        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          <p
            className="uk-margin uk-margin-small-left gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("comment"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          <p className="uk-margin uk-margin-small-left">
            {checkObjectProp(info, "description", "---")}
          </p>
        </div>
        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          <p
            className="uk-margin uk-margin-small-left gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("charterTerms"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          <p className="uk-margin uk-margin-small-left">
            <a className={"uk-active"} target={"_blank"} onClick={download}>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("viewAgreement"),
                }}
              />
            </a>
          </p>
        </div>
        <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-6@m gh-margin-medium-top">
          <p
            className="uk-margin uk-margin-small-left gh-text-secondary"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("requestPageID"),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-width-3-5@s uk-width-5-6@m gh-margin-medium-top">
          <p className="uk-margin uk-margin-small-left">
            {formatID(info.booking_request.id, config.prefixes.request)}
            {info.status && info.status === "open" && (
              <span>
                {" "}
                -{" "}
                <a
                  className={"uk-active"}
                  target={"_blank"}
                  onClick={() => redirectToRequest(info.booking_request.id)}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("viewRequest"),
                    }}
                  />
                </a>
              </span>
            )}
          </p>
        </div>
      </div>
      <h4 className="uk-margin-small-left gh-margin-medium-top gh-margin-medium-bottom">
        {staticService.findByAlias("routeSummary")}
      </h4>
      <div className="uk-margin-small-left gh-margin-medium-bottom">
        {info.pads && info.pads.length > 0 ? <h4>{landingTitle}</h4> : null}
        {info.pads && info.pads.length
          ? info.pads.map((pad) => <LandingItem pad={pad} key={pad.id + pad.from_name} />)
          : null}
      </div>
      {info.padsDiffer && (
        <div className="uk-margin-small-left">
          <span
            className="uk-text-danger gh-vertical-middle uk-margin-small-right"
            data-uk-icon="icon: warning"
          />
          <span
            className="font-weight-500 gh-vertical-middle"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("pads-differ-operator"),
            }}
          />
        </div>
      )}
    </div>
  ) : null;
};

QuoteActiveComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  info: PropTypes.object.isRequired,
  download: PropTypes.func.isRequired,
  showTail: PropTypes.bool,
  redirectToRequest: PropTypes.func,
};
