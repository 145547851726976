import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEmptyLegsRequests, removeEmpty } from "../../../../../actions";
import { BlockComponent, ConfirmComponent } from "../../../../common";
import { fireTracking, redirectTo } from "../../../../../shared";
import { WrapperComponent } from "../../../../";
import { EmptyLegDetailComponent, EmptyLegRequestComponent } from "../../common/";
import staticService from "../../../../../services/static.service";
import { config } from "../../../../../configs";
import BackLinkStyled from "../../../../common/BackLinkStyled";

class OperatorEmptyLegDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      removeMessage: staticService.findByAlias("withdrawEmptyLegBody"),
      removeMessageWithoutBooking: staticService.findByAlias("withdrawEmptyLegBodyWithoutBooking"),
      info: {},
      existLeg: false,
    };
    fireTracking(props.location.pathname);
    this.onSuccess = this.onSuccess.bind(this);
    this.removeEmptyLeg = this.removeEmptyLeg.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { getEmptyLegsRequests, match } = this.props;

    getEmptyLegsRequests(match.params.id, this.onSuccess);
  }

  componentDidMount() {
    this.setState({ existLeg: true });
    if (this.props.onEnter) this.props.onEnter(false);
  }

  onSuccess(response) {
    this.setState({ info: response, isLoaded: true });
  }

  closeModal() {
    this.confirm.closeModal();
  }

  get requests() {
    return this.state.info.booking_request || [];
  }

  removeEmptyLeg() {
    this.props.remove(this.state.info.id, true).then(() => {
      this.setState({ existLeg: false });
      setTimeout(() => {
        window.location.href = config.redirectToEmptyleg;
      }, 500);
    });
  }

  render() {
    const { removeMessage, removeMessageWithoutBooking, info, isLoaded } = this.state;

    if (isLoaded && info && info.status === "closed") {
      return redirectTo("/forbidden");
    }

    const requests = this.requests;
    return (
      <WrapperComponent>
        <BackLinkStyled
          linkName={staticService.findByAlias("backToEmptyLegsList")}
          to="/flights/empty-legs/filter/requests"
        />
        <BlockComponent visible={true} parentProps={{ className: "uk-margin uk-margin-bottom" }}>
          {this.confirmDialog}
          <ConfirmComponent
            ref={(confirm) => (this.confirm = confirm)}
            info={info?.booking_id ? removeMessage : removeMessageWithoutBooking}
            buttonsProps={{
              cancel: { title: staticService.findByAlias("cancel"), css: "uk-button-default" },
              continue: { title: staticService.findByAlias("confirm"), css: "uk-button-danger" },
            }}
            modalProps={{
              id: "remove-empty-leg-modal",
              title: staticService.findByAlias("withdrawEmptyLegTitle"),
              onClose: this.closeModal,
              options: {
                clsPage: "",
                bgClose: false,
                escClose: false,
              },
            }}
            onSelect={this.removeEmptyLeg}
            onReject={this.closeModal}
          />
          {this.state.existLeg && this.state.info ? (
            <div className="uk-align-center uk-margin-remove">
              <div>
                <EmptyLegDetailComponent leg={this.state.info} visible={this.state.isLoaded} />
              </div>

              {requests.length > 0 ? (
                <div className="uk-margin-top">
                  <h3>Requests</h3>
                  <div className="uk-text-center uk-text-left@m">
                    {requests.map((request, key) => (
                      <EmptyLegRequestComponent key={key} visible={true} info={request} />
                    ))}
                  </div>
                </div>
              ) : null}

              <hr
                className="uk-margin-top uk-margin-remove-bottom uk-visible@m"
                style={{ borderBottom: "1px solid #D5D5D5" }}
              />

              <div className="uk-margin-bottom">
                <button
                  type="button"
                  onClick={() => this.confirm.openModal()}
                  className="uk-button uk-button-text uk-text-danger gh-button-withdraw uk-padding-remove"
                >
                  {staticService.findByAlias("withdrawEmptyLeg")}
                </button>
              </div>
            </div>
          ) : null}
        </BlockComponent>
      </WrapperComponent>
    );
  }

  componentWillUnmount() {
    if (this.props.onEnter) this.props.onEnter(true);
  }
}

OperatorEmptyLegDetailComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorEmptyLegDetailComponent.propTypes = {
  location: PropTypes.object,
  getEmptyLegsRequests: PropTypes.func,
  match: PropTypes.object,
  onEnter: PropTypes.func,
  remove: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      remove: removeEmpty,
      getEmptyLegsRequests,
    },
    dispatch,
  );
};

const COMPONENT = connect(null, mapDispatchToProps)(OperatorEmptyLegDetailComponent);
export { COMPONENT as OperatorEmptyLegDetailComponent };
