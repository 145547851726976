import * as types from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import GetHeliEvents from "../services/event.service";
import { staticText } from "../configs/";
import { getQuote } from "./";
import { downloadImage, spinnerInstance } from "../shared";

const entity = "booking";

export function saveBookingSuccess(booking) {
  return { type: types.NEW_BOOKING, booking };
}

export function getBookingSuccess(bookings) {
  return { type: types.GET_BOOKING_LIST, bookings };
}

export function getAocDocsSuccess(docs) {
  return { type: types.GET_AOC_DOCS, docs };
}

export function getBookingDetailSuccess(bookingDetail) {
  return { type: types.GET_BOOKING_DETAIL, bookingDetail };
}

export function addCommentSuccess({ id, comment }) {
  return { type: types.ADD_COMMENT_TO_BOOKING, id, comment };
}

export function cancelBookingSuccess(booking) {
  return { type: types.CANCEL_BOOKING, booking };
}

export function getEmptyLegDetailsSuccess(emptyLegDetails) {
  return { type: types.GET_EMPTY_LEG_DETAILS, emptyLegDetails };
}

export function saveBooking(data, onSuccess, onError) {
  return function (dispatch) {
    Api.setProperty("responseMessages", staticText.globalMessages.booking).setProperty(
      "skipErrorAlert",
      true,
    );

    spinnerInstance.setProp("type", "large").show();

    return Api.setPath(entity, null)
      .post(data)
      .then((data) => {
        onSuccess(data);
        dispatch(saveBookingSuccess(data));
        Api.setProperty("skipErrorAlert", false);
        spinnerInstance.setProp("type", "large").hide();
      })
      .catch((err) => {
        onError(err);
        if (err.status === 423 && err.data.error.refresh) {
          Api.setProperty("alertModal", true);
          Api.triggerError(err);
          Api.setProperty("alertModal", false);
          dispatch(getQuote(data.quote_id));
        } else {
          Api.triggerError(err);
        }
        return handlerError(err, dispatch);
      });
  };
}

export function getBookings(type, page, filters = [], handler) {
  return function (dispatch) {
    return Api.setPath(entity, type)
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((bookings) => {
        GetHeliEvents.trigger("bookings");
        dispatch(getBookingSuccess(bookings));
      })
      .catch((err) => handlerError(err, dispatch))
      .finally(() => {
        if (handler) {
          handler();
        }
      });
  };
}

export function getAocDocs(id, type = "booking") {
  return function (dispatch) {
    const entries = {
      booking: "bookings/view-aoc-documents",
      quote: "quote/view-documents",
    };
    if (!entries[type]) return;
    return Api.setPath(entries[type], id)
      .getAll()
      .then((docs) => {
        dispatch(getAocDocsSuccess(docs));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function downloadDocImage(file, url) {
  return function (dispatch) {
    return Api.setPath("bookings/download-aoc-document?file=" + url)
      .downloadImage()
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return downloadImage(file, URL.createObjectURL(blob));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getBookingsByFiltering(type, page, filters = []) {
  return function () {
    return Api.setPath(entity, type)
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getBookingInfo(id) {
  return function (dispatch) {
    Api.reset();
    return Api.setPath(`${entity}/${id}`)
      .getAll()
      .then((bookingDetail) => {
        dispatch(getBookingDetailSuccess(bookingDetail));
        return bookingDetail;
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject();
      });
  };
}

export function updateDepositAmount(booking_id, data) {
  return function (dispatch) {
    Api.reset();
    return Api.setPath(`${entity}/upload-booking-deposit-percentage/${booking_id}`)
      .setProperty("skipErrorAlert", true)
      .post(data)
      .then((bookingDetail) => {
        dispatch(getBookingDetailSuccess(bookingDetail));
        return bookingDetail;
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject();
      });
  };
}

export function addCommentToBooking(id, comment) {
  return function (dispatch) {
    Api.setProperty("responseMessages", staticText.globalMessages.comment);
    return Api.setPath(`${entity}/${id}/comment`)
      .post({ body: comment })
      .then((commentAdded) => {
        dispatch(addCommentSuccess({ id, comment: commentAdded }));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function cancelBooking(id, message, onSuccess) {
  return function (dispatch) {
    return Api.setPath(`${entity}/${id}/cancel`)
      .setProperty("skipErrorAlert", true)
      .post({ message })
      .then((booking) => {
        if (onSuccess) onSuccess(booking);
        Api.setProperty("skipErrorAlert", false);
        return booking;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getEmptyLegInfo(id) {
  return function (dispatch) {
    Api.reset();
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath(`empty-leg`, id)
      .getAll()
      .then((emptyLeg) => {
        dispatch(getEmptyLegDetailsSuccess(emptyLeg));
        spinnerInstance.setProp("type", "large").hide();
        return Promise.resolve(emptyLeg);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function addPrivateNoteToBooking(id, note, message = null) {
  if (message) {
    staticText.globalMessages.updateNote.onSuccess.post = message;
  }
  return function (dispatch) {
    Api.reset();
    return Api.setPath("add-private-note/booking", id)
      .setProperty("responseMessages", staticText.globalMessages.updateNote)
      .post({ private_notes: note })
      .then((response) => {
        return response;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function addPrivateNoteToRequest(id, private_notes, message = null) {
  if (message) {
    staticText.globalMessages.updateNote.onSuccess.post = message;
  }
  return function (dispatch) {
    Api.reset();
    return Api.setPath("add-private-note/booking_request", id)
      .setProperty("responseMessages", staticText.globalMessages.updateNote)
      .post({ private_notes })
      .then((response) => response)
      .catch((err) => handlerError(err, dispatch));
  };
}

export function addPrivateNoteOperator(id, private_notes = null, quote) {
  return function (dispatch) {
    return Api.setPath(`add-private-note/quote/${id}`)
      .setProperty("responseMessages", staticText.globalMessages.updateNote)
      .post({ private_notes })
      .then(() => {
        dispatch({ type: types.GET_QUOTE_ITEM, quote: { ...quote, private_notes } });
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function operatorAddQuoteInternalNotes(quote_id, body, message = null) {
  if (message) {
    staticText.globalMessages.updateNote.onSuccess.post = message;
  }
  return function (dispatch) {
    // Dispatch action who will merge the array in quotDetail.internalNotes
    Api.reset();
    return Api.setPath("internal-notes", null)
      .setProperty("responseMessages", staticText.globalMessages.updateNote)
      .post({ quote_id, body })
      .then((res) => {
        dispatch({
          type: types.OPERATOR_QUOTE_INTERNAL_NOTES_ADDED_WITH_SUCCESS,
          payload: res,
        });

        return res;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function operatorAddBookingInternalNotes(quote_id, body, message = null) {
  if (message) {
    staticText.globalMessages.updateNote.onSuccess.post = message;
  }
  return function (dispatch) {
    Api.reset();
    return Api.setPath("internal-notes", null)
      .setProperty("responseMessages", staticText.globalMessages.updateNote)
      .post({ quote_id, body })
      .then((res) => {
        dispatch({
          type: types.OPERATOR_BOOKING_INTERNAL_NOTES_ADDED_WITH_SUCCESS,
          payload: res,
        });

        return res;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function sendPaymentInformation(bookingID, body) {
  return function (dispatch) {
    Api.reset();
    return Api.setPath(`send-payment-information/${bookingID}`)
      .setProperty("skipErrorAlert", true)
      .post(body)
      .then((res) => {
        return res;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function sendConfirmPaymentReceived(bookingID) {
  return function (dispatch) {
    Api.reset();
    return Api.setPath(`confirm-payment/${bookingID}`)
      .setProperty("skipErrorAlert", true)
      .post()
      .then((res) => {
        return res;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getStripFields(bookingID) {
  return function (dispatch) {
    Api.reset();
    return Api.setPath(`get-stripe-fields`)
      .setQueryParams([{ key: "booking_id", value: bookingID }])
      .get("")
      .then((res) => {
        const fields = {
          ...res,
          account_holder: {
            key: "account_holder",
            label: "Account Holder Name",
            example: "",
          },
        };

        dispatch({
          type: types.GET_STRIPE_FIELDS,
          payload: fields,
        });

        return fields;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function uploadOperatorInvoiceFile(bookingID, files) {
  return function (dispatch) {
    Api.reset();
    return Api.setPath(entity, `upload/operator_invoice/${bookingID}`)
      .setProperty("skipErrorAlert", true)
      .post({ file: files[0] })
      .then((booking) => {
        dispatch(getBookingDetailSuccess(booking));
        return booking;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
