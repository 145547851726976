import React from "react";
import Auth from "../../../../../services/auth";
import staticService from "../../../../../services/static.service";

const NewRequestNextStepInfo = () => {
  let text = "";
  const hasBothFeatures =
    Auth.hasFeature("instant_price_estimates") &&
    Auth.hasFeature("send_the_request_to_wished_operators");

  if (hasBothFeatures) {
    text = staticService.findByAlias("seePriceEstimatesAndSelectOperators");
  } else if (Auth.hasFeature("instant_price_estimates")) {
    text = staticService.findByAlias("see_price_estimates_and_submit_request_to_operators");
  } else if (Auth.hasFeature("send_the_request_to_wished_operators")) {
    text = staticService.findByAlias("select_operators_and_submit_request");
  } else {
    text = staticService.findByAlias("review_request_and_submit_to_operators");
  }

  return (
    <div
      className="new-request-next-button-description"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
NewRequestNextStepInfo.propTypes = {};
export default NewRequestNextStepInfo;
