import { isValidNumber, parseNumber } from "libphonenumber-js";
import { convertToNumber, replaceAttributesInString } from "../shared";
import staticService from "../services/static.service";
import moment from "moment";
import auth from "../services/auth";

const getHasRemoveValidationForDomainFeature = () =>
  auth.hasFeature("remove_validation_for_domain_when_inviting_members");

export const validationRules = {
  validEmail: (val) => {
    if (val === "") return true;
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(val);
  },

  validEmailDomain: (domain, email) => {
    const hasRemoveValidationForDomainFeature = getHasRemoveValidationForDomainFeature();
    if (email === "") return true;
    if (hasRemoveValidationForDomainFeature) return true;

    if (!email) return;

    const parts = email.split("@");
    const domainPart = domain.split("@");
    if (parts.length === 2) {
      if (parts[1] === domainPart[1]) {
        return true;
      }
    }
    return false;
  },

  validEmailDomainSignUp: (domain, email) => {
    if (email === "") return true;

    if (!email) return;

    const parts = email.split("@");
    const domainPart = domain.split("@");
    if (parts.length === 2) {
      if (parts[1] === domainPart[1]) {
        return true;
      }
    }
    return false;
  },

  invalidEmailDomainShort: (email) => {
    if (email === "") return true;
    return /^[A-Z0-9._%+-]+@([A-Z0-9.-])+\.[A-Z]{2,9}$/i.test(email);
  },

  invalidEmailDomain: (email) => {
    const hasRemoveValidationForDomainFeature = getHasRemoveValidationForDomainFeature();
    if (email === "") return true;
    if (hasRemoveValidationForDomainFeature) return true;
    return /^[A-Z0-9._%+-]+@((?!(\b(gmail|yahoo|hotmail|live|aol|outlook)\b))[A-Z0-9.-])+\.[A-Z]{2,9}$/i.test(
      email,
    );
  },

  invalidEmailDomainSignUp: (email) => {
    if (email === "") return true;
    return /^[A-Z0-9._%+-]+@((?!(\b(gmail|yahoo|hotmail|live|aol|outlook)\b))[A-Z0-9.-])+\.[A-Z]{2,9}$/i.test(
      email,
    );
  },

  validUrl: (val) => {
    if (val === "" || val === null) return true;
    return /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
      val,
    );
  },

  signupValidUrl: (val) => {
    if (val === "" || val === null) return true;
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
      val,
    );
  },

  alphaNumValidation: (val) => {
    if (val === "") return true;
    return /^[a-zA-Z0-9].*$/.test(val);
  },

  alphaNumStrictValidation: (val) => {
    if (val === "") return true;
    return /^[a-zA-Z0-9]*$/.test(val);
  },

  alphaNumSpaceValidation: (val) => {
    if (val === "") return true;
    const word = val.replace(/[^0-9a-zA-Z]+/g, "");
    return /^([a-zA-Z0-9,() ])+$/.test(word);
  },

  passwordValid: (val) => {
    let mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
    if (val === "") return true;
    return mediumRegex.test(val);
  },

  aocValidation: (val) => {
    return /^[a-zA-Z0-9]*$/.test(val);
  },

  spaceValidation: (val) => {
    if (val === "") return true;
    return /^(?! ).[A-Za-z0-9!@#$%^&*() .,-]*$/.test(val);
  },

  hasWhiteSpace: (val) => {
    return !/\s/g.test(val);
  },

  alpha: (val) => {
    return /^([a-zA-Z-])+$/.test(val);
  },

  required: (val) => val && val.length,

  requiredObject:
    (property, additionalCheck = false) =>
    (object) => {
      if (additionalCheck) {
        if (typeof object !== "object") {
          return object && object.length;
        }
      }

      return object[property] && object[property].length;
    },

  // validation working reverse if val = false - then error is showing up
  soonExpires: (val) => {
    if (typeof val === "string") {
      return moment(val, "DD MMMM, YYYY").diff(moment(), "days") > 30;
    } else {
      return moment(val).diff(moment(), "days") > 30;
    }
  },

  // validation working reverse if val = false - then error is showing up
  dateExpired: (val) => {
    if (typeof val === "string") {
      return moment(val, "DD MMMM, YYYY").diff(moment(), "days") >= 1;
    } else {
      return moment(val).diff(moment(), "days") >= 1;
    }
  },

  requiredBD: (val) => {
    if (val === "") return false;
    return /^(?! ).[a-zA-Z0-9\t\n ./<>?;:"'`~!@#$%^&*()\[\]{}_+=|\\,-]*$/.test(val);
  },

  checkboxRequired: (val) => val,

  max:
    (len, isNumber = false) =>
    (val = "") => {
      if (val === "") return true;
      if (isNumber) {
        return convertToNumber(val) <= convertToNumber(len);
      }
      return val?.length <= len;
    },

  min:
    (len, isNumber = false) =>
    (val = "") => {
      if (val === "") return true;
      if (isNumber) {
        return convertToNumber(val) >= convertToNumber(len);
      }
      return val?.length >= len;
    },

  validateInviteEmail:
    (email) =>
    (val = "") =>
      val !== email,

  lessThan:
    (field) =>
    (val = "") => {
      const _val = val !== "" ? parseFloat(val) : 0;
      return _val < parseFloat(field);
    },

  duplicateEmail: (emails, email) => {
    if (emails.length) {
      const duplicate = emails.find((mail) => mail === email);
      return !!duplicate;
    }
  },

  alreadyExistsComplex: (emails) => (email) => {
    const result = emails.filter((item) => item.email === email);
    if (result.length === 0) return true;
    return false;
  },

  hasDigit: (val) => (val.search(/\d/) === -1 ? false : true),
  hasLetter: (val) => (val.search(/[a-zA-Z]/) === -1 ? false : true),

  isNumber: (val) => !isNaN(Number(val)),

  fieldIsRequiredOnlyNumbers: (val) => {
    if (isNaN(val) || Number(val) === 0 || Number(val).toString()?.length > 10) return false;
    return true;
  },

  numeric: (val) => {
    if (val === "" || val === undefined || val === null) return true;
    return /^[0-9]*(?:[+][0-9]+)*$/.test(val);
  },

  numericPlus: (val) => {
    if (val === "") return true;
    return /^[0-9]*(?:[+][0-9]+)*$/.test(val);
  },

  phoneValidation: (val) => {
    if (val === undefined) return true;
    const validate = parseNumber(val);
    return validate.country || isValidNumber(val, "GB") ? true : false;
  },

  phoneInputValidation: (data) => {
    if (!data) return true;

    if (typeof data === "object") {
      const { value, code, dialCode } = data;

      if (!value) return true;
      if (value === dialCode) return true;

      return value.length > 6 && !!code;
    }

    return data && data.length > 6;
  },

  matchField:
    (confirm = "") =>
    (field = "") => {
      if (confirm.length === 0) {
        return true;
      }

      return confirm === field;
    },

  passwordsMatchOld: (vals) => vals.password !== vals.old_password,

  isPrice: (val) => {
    const price = val.replace(/\,/g, "");
    return /^[0-9,]*\.?[0-9]*$/i.test(price);
  },
  isGreaterThanZero: (val) => val !== null && val > 0,
  isDate: (date) => {
    if (/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})|([0-9]{2})\-([0-9]{2})\-([0-9]{4})$/.test(date)) {
      return moment(date, "DD-MM-YYYY").isValid();
    }
    return false;
  },

  rangeValidation: (form) => {
    return (
      (form.date_end === "" && form.date_start === "") ||
      (form.date_end !== "" && form.date_start !== "")
    );
  },

  rangeValidationEndDate: (form) => {
    if (form.date_end === "" || form.date_start === "") return true;
    const endDate = moment(form.date_end);
    const startDate = moment(form.date_start);
    return endDate.isSameOrAfter(startDate);
  },
};

export const validationMessages = () => {
  return {
    validEmailMessage: (val) =>
      val
        ? replaceAttributesInString(
            { email: val },
            staticService.findByAlias("individualValidEmailMessage"),
          )
        : staticService.findByAlias("genericValidEmailMessage"),
    validUrlMessage: (val) =>
      replaceAttributesInString({ url: val }, staticService.findByAlias("validUrlMessage")),
    requiredMessage: (field) =>
      replaceAttributesInString({ field: field }, staticService.findByAlias("requiredMessage")),
    maxMessage:
      (len, alias = "maxCharactersValidatorMessage", fieldName = "") =>
      (val) => {
        return replaceAttributesInString(
          { length: len, value: val, name: fieldName },
          staticService.findByAlias(alias),
        );
      },
    passwordValidMessage: (val) =>
      replaceAttributesInString({ val: val }, staticService.findByAlias("passwordValidation")),
    minMessage: (len) => (val) =>
      replaceAttributesInString({ length: len }, staticService.findByAlias("minMessage")),
    isNumberMessage: (val) =>
      replaceAttributesInString({ number: val }, staticService.findByAlias("isNumberMessage")),
    numericMessage: (field) =>
      replaceAttributesInString({ number: field }, staticService.findByAlias("numericMessage")),
    matchFieldMessage: (field) =>
      replaceAttributesInString({ field: field }, staticService.findByAlias("matchFieldMessage")),
    passwordsMatchOldMessage: (val) => staticService.findByAlias("passwordsMatchOldMessage"),
    hasDigitMessage: (val) => staticService.findByAlias("hasDigitMessage"),
    hasLetterMessage: (val) => staticService.findByAlias("hasLetterMessage"),
    alphaMessage: (field) =>
      replaceAttributesInString({ field: field }, staticService.findByAlias("alphaMessage")),
    phoneValidationMessage: (field) => staticService.findByAlias("phoneValidationMessage"),
    invalidEmailDomainMessage: (field) => staticService.findByAlias("invalidEmailDomainMessage"),
    invalidEmailDomainShortMessage: (field) => staticService.findByAlias("invalidEmail"),
    isPriceMessage: (val) =>
      replaceAttributesInString({ val: val }, staticService.findByAlias("isPriceMessage")),
    lessThanMessage: (field1, field2) =>
      replaceAttributesInString(
        {
          field1: field1,
          field2: field2,
        },
        staticService.findByAlias("lessThanMessage"),
      ),
    alphaNumValidationMessage: (val) => staticService.findByAlias("alphaNumValidationMessage"),
    alphaNumStrictValidationMessage: (val) => staticService.findByAlias("alphaNumStrictValidation"),
    spaceValidationMessage: (field) =>
      replaceAttributesInString(
        { field: field },
        staticService.findByAlias("spaceValidationMessage"),
      ),
    validEmailMessageDomain: (domain, val) => {
      const domainPart = domain.split("@");
      return replaceAttributesInString(
        { domain: domainPart[1] },
        staticService.findByAlias("validEmailMessageDomain"),
      );
    },
    isDateMessage: (val) =>
      replaceAttributesInString({ val: val }, staticService.findByAlias("isDateMessage")),
  };
};
