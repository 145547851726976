import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ComponentAuthorization } from "../../../ComponentAuthorization";
import { Item, List } from "../../../../shared/list";

const MenuComponent = ({ items = [] }) => {
  return (
    <List attrProps={{ className: "disabled-list uk-nav uk-nav-default gh-account-nav " }}>
      {items.map((nav, index) => {
        if (nav.isTabDisabled) return "";
        return (
          <ComponentAuthorization
            key={index}
            component={Item}
            componentProps={{
              attrProps: { className: "uk-nav " },
            }}
            roles={nav.roles}
          >
            <NavLink
              className={"uk-margin-small uk-margin-small-top"}
              to={nav.path}
              exact={true}
              activeClassName={"uk-active"}
            >
              <span dangerouslySetInnerHTML={{ __html: nav.title }} />
            </NavLink>
          </ComponentAuthorization>
        );
      })}
    </List>
  );
};

MenuComponent.propTypes = {
  items: PropTypes.array.isRequired,
};

export { MenuComponent };
