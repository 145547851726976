import React from "react";
import PropTypes from "prop-types";
import { inputNumberValueToNumber } from "./Input.utils";
import cs from "../../../../utils/condString";

import "./index.css";

const formatter = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  maximumFractionDigits: 2,
});

export const InputNumber = React.forwardRef((props, ref) => {
  const {
    name,
    onChange,
    value: providedValue,
    onFocus,
    onBlur,
    readOnly,
    onKeyDown,
    placeholder,
    prefix,
    suffix,
    invalid,
    disabled,
    max,
  } = props;
  const [focused, setFocused] = React.useState(false);

  const focusHandler = (e) => {
    if (onFocus) onFocus(e);
    setFocused(true);
  };

  const blurHandler = (e) => {
    if (onBlur) onBlur(e);
    setFocused(false);
  };

  const validation = (value) => {
    if (!isNaN(value)) {
      if (max) {
        return value <= max;
      }
    }

    return true;
  };

  const changeHandler = (e) => {
    const currentValue = providedValue;
    const value = e.target.value;

    onChange(value || "");

    if (value) {
      const [left, right] = value
        .toString()
        .replace(/[^0-9.]/g, "")
        .split(".");

      if (right && right.length && value[value.length - 1] === ".") {
        return onChange(value.slice(0, -1));
      }

      if (value[value.length - 1] === ".") {
        if (value[value.length - 2] === ".") {
          return onChange(value.slice(0, -1));
        }
        return onChange(value);
      }

      let end_value = "";

      if (left) end_value = formatter.format(Number(left));
      else end_value = "0";

      if (right) {
        if (right.length > 2) end_value += `.${right.slice(0, -1)}`;
        else end_value += `.${right}`;
      }

      if (validation(inputNumberValueToNumber(end_value))) {
        return onChange(end_value);
      }

      return onChange(currentValue.toString());
    }
  };

  const formatValue = (value) => {
    if (!value && value !== 0) return "";

    if (value[value.length - 1] === ".") {
      return value;
    }

    let end_value = value.toString().replace(/[^0-9.]/g, "");
    end_value = formatter.format(Number(end_value));

    return end_value;
  };

  return (
    <div
      className={cs(
        "pc-input__container",
        prefix && "with-prefix",
        suffix && "with-suffix",
        focused && "focused",
      )}
    >
      {prefix && <div className="pc-input__prefix">{prefix}</div>}
      <input
        disabled={disabled}
        className={cs("pc-input", invalid && "pc-invalid")}
        ref={ref}
        id={name}
        name={name}
        value={formatValue(providedValue)}
        placeholder={placeholder}
        onChange={changeHandler}
        onKeyDown={onKeyDown}
        onBlur={blurHandler}
        onFocus={focusHandler}
        readOnly={readOnly}
        tabIndex={(readOnly && "-1") || ""}
        autoComplete="off"
        type="text"
      />
      {suffix && <div className="pc-input__suffix">{suffix}</div>}
    </div>
  );
});

InputNumber.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeyDown: PropTypes.func,
  prefix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  suffix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.number,
};

InputNumber.defaultProps = {
  value: "",
};

InputNumber.displayName = "InputNumber";
