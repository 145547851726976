import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { staticText, config, REQUEST_WITHDRAWN, EXPIRED } from "../../../../../configs/";
import { formatID, ModalComponent, redirectTo } from "../../../../../shared";
import { NormalPermission } from "../../../../permissions";
import staticService from "../../../../../services/static.service";
import { ClientIdentifierComponent } from "../client-identifier.component";
import { editRequest } from "../../../../../actions";
import RequestEditRights from "./RequestEditRights";

import "./RequestStatus.styles.css";

import expiredCalendarIcon from "../../../../../assets/img/expired-calendar.png";

class RequestStatusComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: props.visible || false,
      editMode: false,
      editable: false,
    };
    this.toggleDialogState = this.toggleDialogState.bind(this);
    this.sendToEdit = this.sendToEdit.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ dialog: nextProps.visible });
  }

  toggleDialogState() {
    this.props.action(REQUEST_WITHDRAWN, null);
  }

  sendToEdit(request) {
    request &&
      request.legs &&
      request.legs.map((leg) => {
        leg.from_is_private = leg.from_is_private !== 0;
        leg.to_is_private = leg.to_is_private !== 0;
      });
    this.context.store.dispatch(editRequest(request));
    this.setState({ editable: true });
  }

  render() {
    const {
      request,
      brokerIsAllowedToEdit,
      match: { path },
    } = this.props;

    const visibleEdit =
      request &&
      request.activeQuotes &&
      request.activeQuotes.length === 0 &&
      request.status === "open" &&
      !request.leg_owner;
    const match = this.props.match && this.props.match.params;
    const expirationReason =
      request.expiration_reason === staticService.findByAlias("bookingMadeWthAnotherOperator")
        ? staticService.findByAlias("expirationReasonBookingRequestMade")
        : request.expiration_reason;

    if (this.state.editable) {
      return redirectTo("/new-request");
    }

    return (
      <div>
        <div className="gh-request-status">
          <div className={match ? "uk-flex uk-flex-middle uk-margin-remove-top" : "uk-width-auto"}>
            {match ? (
              <h4 className="gh-column-label" style={{ whiteSpace: "nowrap" }}>
                {staticService.findByAlias("requestPageID")}
              </h4>
            ) : (
              <div className="uk-text-uppercase">
                <small
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("requestPageID"),
                  }}
                />
              </div>
            )}
            <div
              className={
                match ? "gh-column-text uk-text-capitalize" : "gh-text-secondary gh-text-big"
              }
            >
              {formatID(request.id, config.prefixes.request) || "No Id"}
            </div>
          </div>
          <div className={match ? "uk-flex uk-flex-middle uk-margin-remove-top" : "uk-width-auto"}>
            {match ? (
              <h4 className="gh-column-label">{staticService.findByAlias("status")}</h4>
            ) : (
              <div className="uk-text-uppercase">
                <small
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("status"),
                  }}
                />
              </div>
            )}
            <div className={match ? "gh-column-text" : "gh-text-primary gh-text-big"}>
              {request && request.booking ? (
                <NavLink to={`/flights/my-bookings/${request.booking}`}>
                  {staticText.quoteRequestStatuses[request.status]}
                </NavLink>
              ) : (
                staticText.quoteRequestStatuses[request.status] || ""
              )}

              {request.status === EXPIRED && request.expiration_reason ? (
                <span
                  className="gh-see-reason-btn btn-style"
                  onClick={() => {
                    this.expiredReason.open();
                  }}
                >
                  <img src={expiredCalendarIcon} alt="see reason" />
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="gh-margin-short-top uk-margin-remove-top gh-top-row">
            <ClientIdentifierComponent
              identifier={this.props.request.client_id}
              requestId={this.props.request.id}
              requestStatus={this.props.request.status}
              allowEdit={this.props.editIdentifier}
              match={match}
            />
          </div>
          {!match && (
            <div>
              {visibleEdit ? (
                <div className="uk-margin-small-right uk-flex">
                  <button
                    className="uk-button uk-button-default gh-request-btn "
                    onClick={() => this.sendToEdit(request)}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("editThisRequest"),
                      }}
                    />
                  </button>
                </div>
              ) : null}
            </div>
          )}
          {match ? (
            ""
          ) : (
            <NormalPermission condition={this.props.requestStatus}>
              <div
                className={`uk-margin-small-top ${
                  visibleEdit ? "gh-padding-medium-left-s" : "gh-padding-medium-left"
                }`}
              >
                <button
                  className="uk-button uk-button-danger gh-request-btn"
                  onClick={this.toggleDialogState}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("withdrawRequest"),
                    }}
                  />
                </button>
              </div>
            </NormalPermission>
          )}

          {path.indexOf("step") === -1 ? (
            <RequestEditRights
              brokersList={request?.edit_rights_brokers_list || []}
              assignAction={this.props.action}
              request={this.props.request}
            />
          ) : (
            ""
          )}
        </div>

        <ModalComponent
          title={staticService.findByAlias("expiredReason")}
          ref={(expiredReason) => (this.expiredReason = expiredReason)}
          id={"expiredRequestModal"}
          modalClass="gh-expired-reasons-modal"
        >
          <div>{expirationReason || ""}</div>
        </ModalComponent>
      </div>
    );
  }
}

RequestStatusComponent.contextTypes = {
  store: PropTypes.object,
};

RequestStatusComponent.propTypes = {
  request: PropTypes.object.isRequired,
  action: PropTypes.func,
  requestStatus: PropTypes.bool,
  editIdentifier: PropTypes.bool,
  brokerIsAllowedToEdit: PropTypes.bool,
  match: PropTypes.object,
  path: PropTypes.string,
  visible: PropTypes.bool,
};

RequestStatusComponent.defaultProps = {
  requestStatus: false,
  match: {},
};

export { RequestStatusComponent };
