/**
 * @param str
 *
 * @returns {String}
 */

export default (str) => {
  return str
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase().concat(s.slice(1)))
    .join(" ");
};
