import React, { useContext } from "react";
import calculations from "../services/calculations";
import CalculatorContext from "../CalculatorContext";

export default function useLegCalculation({ getValues, setValue, updateForm, prefix }) {
  const { quote, demoVersion } = useContext(CalculatorContext);
  const onLegFlightChange = React.useCallback(() => {
    const leg = getValues(prefix);
    //todo: handle bugs @eduard
    if (!demoVersion) {
      const aircraftIndex = prefix.split(".")[1];
      const legIndex = prefix.split(".")[3];
      const legFromApiCall = quote.aircrafts[aircraftIndex]?.legs[legIndex];

      if (legFromApiCall && JSON.stringify(legFromApiCall) === JSON.stringify(leg)) return;
    }
    const values = calculations.leg.flight(leg);

    updateForm(Object.entries(values).map(([key, value]) => [`${prefix}.${key}`, value]));
  }, [getValues, prefix, updateForm]);

  const onLegFeesChange = React.useCallback(() => {
    setTimeout(() => {
      const leg = getValues(prefix);
      const values = calculations.leg.fees(leg);

      updateForm(Object.entries(values).map(([key, value]) => [`${prefix}.${key}`, value]));
    }, 0);
  }, [getValues, prefix, updateForm]);

  const onLegExtracostsChange = React.useCallback(() => {
    setTimeout(() => {
      const leg = getValues(prefix);
      const values = calculations.leg.extracost(leg);

      updateForm(Object.entries(values).map(([key, value]) => [`${prefix}.${key}`, value]));
    }, 0);
  }, [getValues, prefix, updateForm]);

  const onPilotChange = React.useCallback(
    (pilotPath) => () => {
      const pilot = getValues(pilotPath);

      if (pilot.is_included === "no" || pilot.is_included === "2") {
        const values = calculations.pilot(pilot);

        updateForm(Object.entries(values).map(([key, value]) => [`${pilotPath}.${key}`, value]));
      }
    },
    [getValues, updateForm],
  );

  const updatePilotsTotal = React.useCallback(() => {
    const pilots = getValues(`${prefix}.pilots`);
    const total_pilots_costs = pilots.reduce((total, pilot) => total + +pilot.total_pilot_costs, 0);

    setValue(`${prefix}.total_pilots_costs`, total_pilots_costs);
  }, [getValues, prefix, setValue]);

  return {
    onLegFlightChange,
    onLegExtracostsChange,
    onLegFeesChange,
    onPilotChange,
    updatePilotsTotal,
  };
}
