import PropTypes from "prop-types";
import React, { useEffect } from "react";
import OptionsItem from "./OptionsItem";

import "./index.css";

import wheelSettingsIcon from "../../../../assets/img/svg/wheelSettings.svg";

export function Options(props) {
  const { children, hideOptions, aircraftOptions } = props;
  const [contentVisible, setContentVisible] = React.useState(false);

  useEffect(() => {
    if (hideOptions) setContentVisible(false);
  }, [hideOptions]);

  return (
    <div className="options">
      <button
        type="button"
        className="calculator-reset-btn options-trigger"
        tabIndex="-1"
        onClick={() => setContentVisible(true)}
      >
        {aircraftOptions ? (
          <img src={wheelSettingsIcon} alt="settings" />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        )}
      </button>
      {contentVisible && (
        <div
          className="options-overlay"
          aria-hidden="true"
          onClick={() => setContentVisible(false)}
        />
      )}
      {contentVisible && <div className="options-content">{children}</div>}
    </div>
  );
}

Options.defaultProps = {
  hideOptions: false,
};

Options.propTypes = {
  children: PropTypes.any,
  hideOptions: PropTypes.bool,
  aircraftOptions: PropTypes.bool,
};

Options.Item = OptionsItem;
