import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../../../Form";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import {
  appFontSizeOptions,
  appFontsOptions,
  appTypesOptions,
  colorList,
  typeOfRules,
} from "../../mockupExternalAppSettingsForm";
import Select from "../../../../../../Form/components/Select";
import Button from "../../../../../../common/Button";
import staticService from "../../../../../../../services/static.service";
import ColorPicker from "../../../../../../Form/components/ColorPicker";
import Checkbox from "../../../../../../Form/components/Checkbox";
import UploadFiles from "../../../../../../forms/register/upload-files.component";
import Input from "../../../../../../Form/components/Input";
import Api from "../../../../../../../services/api-handler";
import { showNotification } from "../../../../../../../utils/showNotification";

import "./ExternalAppSettingsForm.css";
import isAllowForEditing from "../../../../../../../utils/isAllowForEditing";
import { RESTRICT_ERROR_MESSAGE } from "../../../../../../../configs";

const ExternalAppSettingsForm = (props) => {
  const {
    externalAppSettings,
    updateModel,
    formValues,
    handlePreviewFile,
    appRules,
    handleOpenRuleModal,
    apiKey,
    appDomain,
  } = props;

  const [loadingBtn, setLoadingBtn] = useState(false);

  const form = useForm();

  useEffect(() => {
    if (formValues.length) {
      formValues.forEach((item) => {
        if (item.alias === "form_background_img") {
          updateModel("externalAppSettings.background_image", [item.value]);
        }

        form.setValue(item.alias, item.value || "");
      });
    }
  }, [formValues]);

  const fontSizeOptions = useMemo(() => appFontSizeOptions(10, 40, "px"), [appFontSizeOptions]);
  const formBackgroundBlur = useMemo(() => appFontSizeOptions(1, 10, "px"), [appFontSizeOptions]);
  const pulseCountOptions = useMemo(() => appFontSizeOptions(1, 10), [appFontSizeOptions]);
  const selectedAppType = form.watch("app_type");

  const onSubmit = (values) => {
    if (!isAllowForEditing()) {
      return showNotification(RESTRICT_ERROR_MESSAGE, "danger");
    }

    const postData = {
      form_data: {
        ...values,
        form_background_img: externalAppSettings.background_image[0],
      },
      rules: appRules,
    };

    setLoadingBtn(true);
    Api.setPath("save-internal-configuration")
      .setProperty("skipErrorAlert", true)
      .post(postData)
      .then(() => {
        setLoadingBtn(false);
        showNotification("Settings updated with success", "success");
      })
      .catch(() => setLoadingBtn(false));
  };

  const setTransparentBg = (value) => {
    updateModel("externalAppSettings.background_image", []);
    form.resetField("form_background_color");

    return value;
  };

  const handleChangeBackgroundColor = (value) => {
    updateModel("externalAppSettings.background_image", []);
    form.resetField("form_background_transparent");

    return value;
  };

  const handleUploadFile = () => {
    form.resetField("form_background_transparent");
    form.resetField("form_background_color");
  };

  return (
    <Form form={form}>
      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="App type"
          name="app_type"
          options={appTypesOptions}
        >
          <Select className="gh-white-bg" />
        </Form.Item>
      </div>

      <hr className="gh-divider" />

      <label className="uk-form-label" style={{ textTransform: "inherit" }}>
        Tracking code: Paste the following script before the &lt;/head&gt; tag on every page you
        want surveys to appear.
      </label>
      {apiKey && appDomain ? (
        <pre className="uk-margin-remove-top">
          <code>
            &lt;script <br /> data-gh-wg-token={`"${apiKey}"`} <br /> type="text/javascript" <br />{" "}
            src={`"${appDomain}/api/get-js"`}&gt; <br /> &lt;/script&gt;
          </code>
        </pre>
      ) : (
        ""
      )}

      {selectedAppType === "type-iframe" ? (
        <React.Fragment>
          <label className="uk-form-label" style={{ textTransform: "inherit" }}>
            {staticService.findByAlias("iframeDescription")}
          </label>
          {apiKey && appDomain ? (
            <pre className="uk-margin-remove-top">
              <code>&lt;div id="getheli-iframe-base-element"&gt;&lt;/div&gt;</code>
            </pre>
          ) : (
            ""
          )}
        </React.Fragment>
      ) : (
        ""
      )}

      <hr className="gh-divider" />

      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Font family"
          name="--gh-widget-font-family"
          options={appFontsOptions}
        >
          <Select className="gh-white-bg" />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Font size"
          name="--gh-widget-form-font-size"
          options={fontSizeOptions}
        >
          <Select className="gh-white-bg" />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Primary Font Color"
          name="--gh-widget-primary-font-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Secondary Font Color"
          name="--gh-widget-secondary-font-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Light Font Color"
          name="--gh-widget-light-font-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>
      </div>

      <hr className="gh-divider" />

      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Primary Color"
          name="--gh-widget-ui-primary-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Secondary Color"
          name="--gh-widget-ui-secondary-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>
      </div>

      <hr className="gh-divider" />

      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item
          className="uk-width-1-1 gh-settings-checkbox-item"
          label="Form background transparent"
          type="checkbox"
          name="form_background_transparent"
        >
          <Checkbox pattern={setTransparentBg} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s gh-widget-settings-select"
          label="Form background blur"
          name="--gh-widget-form-bg-blur"
          options={formBackgroundBlur}
        >
          <Select className="gh-white-bg" />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Form background color"
          name="form_background_color"
        >
          <ColorPicker defaultColors={colorList} pattern={handleChangeBackgroundColor} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 gh-form-background"
          label="Form background image"
          name="form_background_img"
        >
          <UploadFiles
            allowed=".jpeg, .png, .jpg"
            fileFormatAllowed="File formats allowed: png, jpeg and jpg"
            handleChange={handleUploadFile}
            model={externalAppSettings.background_image}
            modelName="externalAppSettings.background_image"
            uploadTitle=""
            docs={externalAppSettings.background_image}
            entity="background_img"
            customRemoveFileAction={() => {
              updateModel("externalAppSettings.background_image", []);
            }}
            multiple={false}
            onlyOneFile={true}
            openModal={handlePreviewFile}
          />
        </Form.Item>
      </div>

      <hr className="gh-divider" />

      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Button text"
          name="widget_button_label"
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Button background color"
          name="--gh-widget-button-bg-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Button font color"
          name="--gh-widget-button-font-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Button pulse color"
          name="--gh-widget-button-pulse-color"
        >
          <ColorPicker defaultColors={colorList} />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="Button pulse count"
          name="--gh-widget-button-pulse-count"
          options={pulseCountOptions}
        >
          <Select className="gh-white-bg" />
        </Form.Item>
      </div>

      <hr className="gh-divider" />

      {appRules.map((rule, index) => (
        <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom" key={index}>
          <div className="uk-width-expand">
            <input type="text" className="uk-input" disabled={true} value={rule.parameter} />
          </div>
          <div className="uk-width-1-1 uk-width-1-4@s">
            <input
              type="text"
              className="uk-input"
              disabled={true}
              value={typeOfRules[rule.type]}
            />
          </div>
        </div>
      ))}

      {/*BUTTONS*/}
      <div className="btn-style gh-add-rule-btn" onClick={handleOpenRuleModal}>
        Add external rule +
      </div>

      <hr className="gh-divider" />

      <label className="uk-form-label" style={{ textTransform: "inherit" }}>
        General Data Protection Regulation (GDPR)
      </label>
      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="From Company Name"
          name="from_company_name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="uk-width-1-1 uk-width-1-2@s"
          label="To Company Name"
          name="to_company_name"
        >
          <Input />
        </Form.Item>
      </div>

      <div className="uk-flex uk-flex-center uk-margin-top uk-margin-bottom">
        <Button loading={loadingBtn} onClick={form.handleSubmit(onSubmit)}>
          {staticService.findByAlias("save")}
        </Button>
      </div>
    </Form>
  );
};

ExternalAppSettingsForm.propTypes = {
  externalAppSettings: PropTypes.object,
  updateModel: PropTypes.func,
  formValues: PropTypes.array,
  handlePreviewFile: PropTypes.func,
  appRules: PropTypes.array,
  handleOpenRuleModal: PropTypes.func,
  apiKey: PropTypes.string,
  appDomain: PropTypes.string,
};

const mapStateToProps = (store) => ({
  externalAppSettings: store.externalAppSettings,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalAppSettingsForm);
