import React, { memo, useContext, useEffect, useState } from "react";
import MarkerInfoContext from "./MarkerInfoContext";
import link from "../../../assets/img/external-link.svg";
import cardMapMarker from "../../../assets/img/svg/cardMapMarker.svg";
import arrowRight from "../../../assets/img/svg/mapArrowRight.svg";
import { generateChosenSiteText, placeTypeIcon } from "../mapUtils";
import moment from "moment";

function EventMarker() {
  const {
    pin,
    pin: {
      name,
      image,
      category,
      type,
      handleAssignButton,
      view_link,
      start_date,
      end_date,
      direction,
    },
    isSelected,
    updateChosenSitesByRole,
  } = useContext(MarkerInfoContext);
  const [showInfo, setShowInfo] = useState(false);
  const [chosenSiteText, setChosenSiteText] = useState();

  const title = () => {
    return view_link ? (
      <a href={view_link} target="_blank" rel="noreferrer">
        {name} <img className="gh-link-icon" src={link} alt="link" />
      </a>
    ) : (
      name
    );
  };

  useEffect(() => {
    setChosenSiteText(generateChosenSiteText(direction, updateChosenSitesByRole));
  }, [direction]);

  return (
    <div className="legInfoWindow event" id="id-info">
      {!!image && (
        <div
          className="imageBox"
          style={{
            background: `url(${image}) center center / cover no-repeat`,
          }}
        />
      )}
      <div className="content">
        <h4 className="top-head">{title()}</h4>
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width uk-width-1-2">
            <span className="item-icon type">
              <img src={placeTypeIcon(category)} alt="cutleryIcon" />{" "}
              <span className="text">
                {category} • {type}
              </span>
            </span>
          </div>
          <div className="uk-width uk-width-1-2">
            <span className="item-icon">
              <img src={cardMapMarker} alt="cardMapMarker" /> <span className="text">-</span>
            </span>
          </div>
        </div>
        <div className="legInfoWindow-date-range">
          {moment(start_date).format("ddd DD MMM, YYYY")} -{" "}
          {moment(end_date).format("ddd DD MMM, YYYY")}
        </div>
        {isSelected && direction ? (
          <h4 className="bottom-head">{pin.chosenSiteText || chosenSiteText}</h4>
        ) : (
          <div className="legInfoWindowActionLegs">
            {showInfo ? (
              <div>
                <a role="button" onClick={() => handleAssignButton("from")}>
                  FROM
                </a>
                <span style={{ margin: "0 15px", fontWeight: 600, fontSize: 14 }}>or</span>
                <a role="button" onClick={() => handleAssignButton("to")}>
                  TO
                </a>
              </div>
            ) : (
              <a onClick={() => setShowInfo(true)}>
                <h4 className="bottom-head secondary">
                  Use this helipad <img src={arrowRight} alt="arrow" />
                </h4>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(EventMarker);
