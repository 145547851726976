import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  QUOTE_WITHDRAWN,
  QUOTE_SELECTED,
  QUOTE_OPEN,
  QUOTE_REJECTED,
  QUOTE_DISCUSSION,
  QUOTE_AMEND,
} from "../../../../configs";
import { ComponentAuthorization, IsVerifiedAuthorization } from "../../../";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";
import envelope from "../../../../assets/img/svg/comment.svg";

class QuoteActionButtonsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.button = this.button.bind(this);
  }

  get componentProps() {
    return {
      isButton: false,
      objPath: {
        pathname: `/marketplace/trip/request/${this.props.quote.booking_request.id}`,
        state: { originPath: this.props.history.location.pathname },
      },
      actionTitle: staticService.findByAlias("submitAnotherQuote"),
      classNames: [
        "uk-button uk-button-primary uk-margin-small-bottom gh-full-width-btn uk-margin-small-right",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  get withdrawProps() {
    return {
      isButton: true,
      actionTitle: staticService.findByAlias("withdrawQuote"),
      action: this.props.action,
      classNames: [
        "uk-button uk-button-danger uk-margin-small-bottom uk-margin-small-right gh-full-width-btn",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  get discussProps() {
    return {
      isButton: true,
      actionTitle: (
        <span className="uk-flex">
          <div className="gh-discussion-btn_envelope" style={{ width: "unset" }}>
            <img className="gh-envelope-icon-broker" src={envelope} alt="envelope" />
            {!!this.props.quote.comments_count && !this.props.seenQuote && (
              <span className="messages-count">{this.props.quote.comments_count}</span>
            )}
          </div>
          {!!this.props.quote.comments_count && !this.props.seenQuote
            ? staticService.findByAlias("newMessage")
            : staticService.findByAlias("quoteLiveChat")}
        </span>
      ),
      action: this.props.action,
      classNames: [
        "uk-button uk-button-default uk-margin-small-bottom gh-full-width-btn uk-margin-small-right gh-discussion-btn operator",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  get amendProps() {
    return {
      isButton: true,
      actionTitle: staticService.findByAlias("amendQuote"),
      action: this.props.action,
      classNames: ["uk-button uk-button-default uk-margin-small-bottom gh-full-width-btn"],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  get rejectedProps() {
    return {
      isButton: false,
      actionTitle: staticService.findByAlias("quoteAgain"),
      objPath: {
        pathname: `/marketplace/trip/request/${this.props.quote.booking_request.id}`,
        state: { originPath: this.props.history.location.pathname },
      },
      classNames: ["uk-button uk-button-primary uk-margin-small-bottom"],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  get confirmProps() {
    return {
      isButton: true,
      actionTitle: staticService.findByAlias("confirmBooking"),
      action: this.props.action,
      classNames: [
        "uk-button uk-button-primary uk-margin-small-bottom uk-margin-small-right gh-full-width-btn",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  button(alias, className) {
    return this.props.isNavlink ? (
      <NavLink
        to={this.props.path}
        className={className}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }}
      />
    ) : (
      <button onClick={this.props.action} className={className} disabled={this.props.disabled}>
        {this.props.type === "discussion" && !this.props.seenQuote && (
          <img className="gh-envelope-icon" src={envelope} alt="envelope" />
        )}
        {staticService.findByAlias(alias)}
      </button>
    );
  }

  render() {
    const btn = () => {
      switch (this.props.type) {
        case QUOTE_OPEN:
          return (
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.componentProps}
              roles={["operator_admin", "operator_team"]}
            />
          );
        case QUOTE_WITHDRAWN:
          return (
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.withdrawProps}
              roles={["operator_admin", "operator_team"]}
            />
          );
        case QUOTE_DISCUSSION:
          return (
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.discussProps}
              roles={["operator_admin", "operator_team"]}
            />
          );
        case QUOTE_AMEND:
          return (
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.amendProps}
              roles={["operator_admin", "operator_team"]}
            />
          );
        case QUOTE_REJECTED:
          return (
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.rejectedProps}
              roles={["operator_admin", "operator_team"]}
            />
          );
        case QUOTE_SELECTED:
          return (
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.confirmProps}
              roles={["operator_admin", "operator_team"]}
            />
          );
        default:
          return null;
      }
    };
    return this.props.visible ? btn() : null;
  }
}

QuoteActionButtonsComponent.propTypes = {
  quote: PropTypes.object.isRequired,
  type: PropTypes.string,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  isNavlink: PropTypes.bool,
  path: PropTypes.string,
  action: PropTypes.func,
  history: PropTypes.object,
};

export { QuoteActionButtonsComponent };
