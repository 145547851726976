import React from "react";
import PropTypes from "prop-types";
import staticService from "../../services/static.service";
import check from "../../assets/img/svg/white-check.svg";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "./location_autocomplete";
import { Control, Form } from "react-redux-form";
import { LegNumberFieldset } from "../forms/fieldsets";
import { validationRules } from "../../configs";
import { EventSelectPax } from "./event-select-pax";
import Button from "./Button";
import { CSSTransition } from "react-transition-group";
import upDownArrows from "../../assets/img/svg/upDownArrows.svg";
import clsx from "clsx";
import EnquireDate from "./EnquireFormComponents/EnquireDate";
import EnquireTime from "./EnquireFormComponents/EnquireTime";
import WarningMessage from "./WarningMessage";

const { required, rangeValidationEndDate } = validationRules;

export const EnquireForm = (props) => {
  const {
    model,
    form,
    updateGeocodeModel,
    onSelectLocation,
    onPickerChange,
    switchType,
    typeOfTrip,
    updateRange,
    goToOptions,
    updatePax,
    numberOfPax,
    loadingBtn,
    handleFlipLocations,
    swapButtonLoading,
    isToLocationSwapped,
    fromDestinations,
    fromCustomMessage,
    fromLandingFeeCustomMessage,
    toCustomMessage,
    toLandingFeeCustomMessage,
  } = props;

  const areInputsDisabled = !fromDestinations && !form.to_coords && !isToLocationSwapped;
  const isSubmitButtonDisabled =
    !form.to_coords || !form.from_coords || numberOfPax > 8 || loadingBtn;
  return (
    <div className="enquire-form">
      <div
        className="gh-enquire-title uk-visible@l"
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("enquiryFormLabel"),
        }}
      />
      <div className={clsx("enquire-form-content", { disabled: areInputsDisabled })}>
        <Form model={model} onSubmit={goToOptions}>
          <div className="uk-width-1-1">
            <div className="uk-flex">
              <label
                className="uk-form-label uk-margin-small-right gh-height-reduce-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("from"),
                }}
              />
            </div>
            <LocationAutocompleteWrapper>
              {({
                locations,
                search,
                handleClickOutside,
                loader,
                onBlur,
                locationValidationTemplate,
              }) => {
                return (
                  <div className="uk-position-relative uk-margin-small-bottom gh-height-reduce">
                    <LocationAutocompleteInput
                      className={["uk-input"]}
                      placeholder={staticService.findByAlias("fromPlaceholder")}
                      readOnly={(!form.from_info && !!form.to_info && !fromDestinations) || false}
                      value={form.from || ""}
                      additionalInfo={form.from_info}
                      coverageWarning={form.from_coverage_status}
                      onSelect={(location) => {
                        updateGeocodeModel("from", location);
                        return search(location);
                      }}
                      onBlur={onBlur}
                      loader={loader}
                      debounce={500}
                      disabled={areInputsDisabled}
                      model={`${model}.from`}
                    />
                    <LocationAutocompleteList
                      className="enquire-form-location-list"
                      onSelect={(address) => onSelectLocation(handleClickOutside, "from", address)}
                      locations={locations}
                    />
                    {form.to_coords &&
                      locationValidationTemplate(`${model}.from_coords`, ".from_coords")}
                  </div>
                );
              }}
            </LocationAutocompleteWrapper>

            <WarningMessage
              message={
                isToLocationSwapped ? toLandingFeeCustomMessage : fromLandingFeeCustomMessage
              }
            />
            <WarningMessage message={isToLocationSwapped ? toCustomMessage : fromCustomMessage} />
          </div>

          <div className="gh-enquiry-form-swap-wrapper">
            <CSSTransition
              in={swapButtonLoading}
              timeout={300}
              classNames={{
                exitActive: "gh-animation-flip-exit-active",
              }}
            >
              <Button
                variant="text"
                color="light"
                type="button"
                onClick={handleFlipLocations}
                className="gh-enquiry-form-swap-button"
              >
                <img
                  src={upDownArrows}
                  alt="upDownArrows"
                  className="gh-enquire-swap-locations-icon"
                />
              </Button>
            </CSSTransition>
          </div>
          <div className="uk-width-1-1">
            <div className="uk-flex">
              <label
                className="uk-form-label uk-margin-small-right gh-height-reduce-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("to"),
                }}
              />
            </div>
            <LocationAutocompleteWrapper>
              {({ locations, search, handleClickOutside, loader, onBlur }) => {
                return (
                  <div className="uk-position-relative uk-margin-small-bottom gh-height-reduce">
                    <LocationAutocompleteInput
                      className={["uk-input"]}
                      placeholder={staticService.findByAlias("toPlaceholder")}
                      readOnly={
                        (form.from_info &&
                          !form.to_info &&
                          !isToLocationSwapped &&
                          !fromDestinations) ||
                        (!form.from_info &&
                          !form.to_info &&
                          !isToLocationSwapped &&
                          !fromDestinations) ||
                        false
                      }
                      value={form.to || ""}
                      additionalInfo={form.to_info}
                      coverageWarning={form.to_coverage_status}
                      onSelect={(location) => {
                        updateGeocodeModel("to", location);
                        return search(location);
                      }}
                      onBlur={onBlur}
                      loader={loader}
                      debounce={500}
                      disabled={areInputsDisabled}
                      model={`${model}.to`}
                    />
                    <LocationAutocompleteList
                      onSelect={(address) => onSelectLocation(handleClickOutside, "to", address)}
                      locations={locations}
                    />
                  </div>
                );
              }}
            </LocationAutocompleteWrapper>

            <WarningMessage
              message={
                isToLocationSwapped ? fromLandingFeeCustomMessage : toLandingFeeCustomMessage
              }
            />
            <WarningMessage message={isToLocationSwapped ? fromCustomMessage : toCustomMessage} />
          </div>

          <div>
            <LegNumberFieldset
              model={`${model}`}
              visible={true}
              disabled={areInputsDisabled}
              defaultCss="uk-width-1-1 uk-visible@l"
            />
            <EventSelectPax
              defaultCss="uk-width-1-1 uk-hidden@l enquire-select-pax"
              model={`${model}`}
              disabled={areInputsDisabled}
              updatePax={updatePax}
            />
            {numberOfPax > 8 && (
              <div
                className="validation-errors disabled-list gh-input-errors uk-text-center gh-pax-error"
                key={0}
              >
                {staticService.findByAlias("exceededPaxNumber")}
              </div>
            )}
          </div>

          <div className="uk-width-1-1 uk-margin-top">
            <label
              className="uk-form-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("typeOfFlightLabel"),
              }}
            />
            <div className="gh-switcher">
              <button
                className={`uk-button uk-text-center ${
                  typeOfTrip === "round" ? "uk-button-default" : "uk-button-primary"
                }`}
                onClick={(e) => switchType(e, "one")}
                disabled={areInputsDisabled}
              >
                <img className="gh-switcher-checked" src={check} alt="check" />
                {staticService.findByAlias("one-way")}
              </button>

              <button
                className={`uk-button uk-text-center ${
                  typeOfTrip === "round" ? "uk-button-primary" : "uk-button-default"
                }`}
                onClick={(e) => switchType(e, "round")}
                disabled={areInputsDisabled}
              >
                <img className="gh-switcher-checked" src={check} alt="check" />
                {staticService.findByAlias("roundtrip")}
              </button>
            </div>
          </div>

          <div className="uk-width-1-1 uk-margin-top uk-margin-bottom">
            <div className="uk-grid-small" data-uk-grid>
              <div className="uk-width-expand">
                <EnquireDate
                  label="date"
                  model=".date"
                  disabled={areInputsDisabled}
                  validators={{ required }}
                  onChange={onPickerChange}
                />
              </div>

              <div className="uk-width-1-3">
                <EnquireTime
                  label="timeLabel"
                  disabled={areInputsDisabled}
                  validators={{ required: (val) => required(val.toString()) }}
                  onChange={updateRange}
                  model=".departure_time"
                />
              </div>
            </div>
          </div>

          {typeOfTrip === "round" ? (
            <div className="uk-width-1-1 uk-margin-top uk-margin-bottom">
              <div className="uk-grid-small" data-uk-grid>
                <div className="uk-width-expand">
                  <div className="uk-width-expand">
                    <EnquireDate
                      label="returnDateLabel"
                      model=".return_date"
                      disabled={areInputsDisabled}
                      validators={{
                        required,
                        endDateValidation: () => {
                          return rangeValidationEndDate({
                            date_start: form.date,
                            date_end: form.return_date,
                          });
                        },
                      }}
                      onChange={onPickerChange}
                    />
                  </div>
                </div>

                <div className="uk-width-1-3">
                  <EnquireTime
                    label="timeLabel"
                    disabled={areInputsDisabled}
                    validators={{ required: (val) => required(val.toString()) }}
                    onChange={updateRange}
                    model=".return_time"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <Control.button
            type={"submit"}
            model={model}
            disabled={isSubmitButtonDisabled}
            className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
          >
            <span
              dangerouslySetInnerHTML={
                loadingBtn
                  ? { __html: "Loading..." }
                  : { __html: staticService.findByAlias("getEtimateLabel") }
              }
            />
          </Control.button>
        </Form>
      </div>
    </div>
  );
};
EnquireForm.propTypes = {
  form: PropTypes.object,
  model: PropTypes.string,
  updateRange: PropTypes.func,
  switchType: PropTypes.func,
  onPickerChange: PropTypes.func,
  updateGeocodeModel: PropTypes.func,
  updatePax: PropTypes.func,
  numberOfPax: PropTypes.number,
  loadingBtn: PropTypes.bool,
  handleFlipLocations: PropTypes.func,
  swapButtonLoading: PropTypes.bool,
  isToLocationSwapped: PropTypes.bool,
  onSelectLocation: PropTypes.func,
  typeOfTrip: PropTypes.string,
  goToOptions: PropTypes.func,
  fromDestinations: PropTypes.bool,
  fromCustomMessage: PropTypes.string,
  fromLandingFeeCustomMessage: PropTypes.string,
  toCustomMessage: PropTypes.string,
  toLandingFeeCustomMessage: PropTypes.string,
};
