import React from "react";
import PropTypes from "prop-types";
import "./Tabs.css";
import TabTitle from "./TabTitle";
import clsx from "clsx";

const Tabs = ({ children, className, defaultSelectedTab }) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultSelectedTab);

  return (
    <div>
      <ul className={clsx("gh-tabs-new-design", className)}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            counter={item.props.counter}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
  defaultSelectedTab: PropTypes.number,
};
Tabs.defaultProps = { className: "", defaultSelectedTab: 0 };
export default Tabs;
