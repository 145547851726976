import PropTypes from "prop-types";
import React from "react";
import auth from "../../../services/auth";
import { isPadVerified } from "./AvailableHomebases.utils";

const formatEngineType = (type) =>
  type
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join("-");

export default function HomebaseContent(props) {
  const { homebases, onInfoClose } = props;
  const [pagination, setPagination] = React.useState({
    page: 1,
  });

  const handlePaginationChange = (dir) => () => {
    if (dir === "next" && pagination.page < homebases.length) {
      setPagination({
        page: pagination.page + 1,
      });
    }

    if (dir === "prev" && pagination.page > 1) {
      setPagination({
        page: pagination.page - 1,
      });
    }
  };

  const HomebasesInfo = React.useMemo(
    () =>
      homebases
        .sort((a, b) => Number(isPadVerified(b)) - Number(isPadVerified(a)))
        .map((homebase, index) => (
          <div
            key={homebase.id}
            data-homebase-visible={index + 1 === pagination.page}
            className="homebase-info__content"
          >
            {auth.hasFeature("show_operator_name_in_coverage_map") && (
              <div className="homebase-info__line">
                <span className="subtitle">Company</span>
                <span className="title">{homebase?.company?.name}</span>
              </div>
            )}
            <div className="homebase-info__line">
              <span className="subtitle">Manufacturer</span>
              <span className="title">
                {homebase?.type?.manufacturer && `${homebase?.type?.manufacturer?.name} `}
                {homebase.type?.model}
              </span>
            </div>
            <div className="homebase-info__line">
              <span className="subtitle">Engine Type</span>
              <span className="title">{formatEngineType(homebase.type.engine_type)}</span>
            </div>
            <div className="homebase-info__line">
              <span className="subtitle">Crew Size</span>
              <span className="title">{homebase.max_crew === 1 ? "One Pilot" : "Two Pilots"}</span>
            </div>
            <div className="homebase-info__line">
              <span className="subtitle">Max Passengers Size</span>
              <span className="title">
                {homebase.max_passengers === 1
                  ? "One Passenger"
                  : `${homebase.max_passengers} Passengers`}
              </span>
            </div>
            <div className="homebase-info__line">
              <span className="subtitle">Status</span>
              <span className="title">
                {isPadVerified(homebase) ? "Verified" : "Verification in process"}
              </span>
            </div>
          </div>
        )),
    [homebases, pagination.page],
  );

  return (
    <div className="homebase-info__container">
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          onInfoClose();
        }}
        data-uk-close
      />
      <div className="homebase-info__group">{HomebasesInfo}</div>
      {homebases.length > 1 && (
        <div className="homebase-info__pagination">
          <span className="homebase-info__pagination-meta">
            {pagination.page} of {homebases.length}
          </span>
          <button disabled={pagination.page === 1} onClick={handlePaginationChange("prev")}>
            Prev
          </button>
          <button
            disabled={pagination.page === homebases.length}
            onClick={handlePaginationChange("next")}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

HomebaseContent.propTypes = {
  homebases: PropTypes.array,
  onInfoClose: PropTypes.func,
};
