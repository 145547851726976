import React from "react";
import { actions } from "react-redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import {
  ComplexTableComponent,
  fireTracking,
  ModalComponent,
  pageTitle,
  prepareQueryStringFromObj,
  spinnerInstance,
  storage,
} from "../../../../shared";
import { config, pipelinesConfig } from "../../../../configs";
import { PaginationService } from "../../../../services";
import {
  deletePipeline,
  editRequest,
  getPipelines,
  requestSwitchBroker,
} from "../../../../actions";
import staticService from "../../../../services/static.service";
import auth from "../../../../services/auth";
import trashIcon from "../../../../assets/img/svg/trash.svg";
import Api from "../../../../services/api-handler";
import Tooltip from "../../../common/Tooltip";
import BrokerPipelineView from "./BrokerPipelineView";
import { tabsKeys } from "./BrokerPipelineView/BrokerPipelineView";
import { showNotification } from "../../../../utils/showNotification";
import BrokerPipeLinesFilters from "./broker-pipelines-filters.component";
import isAllowForEditing from "../../../../utils/isAllowForEditing";

class BrokerPipelinesComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.updatePipelineNotes = this.updatePipelineNotes.bind(this);
    this.updatePiplineStatus = this.updatePiplineStatus.bind(this);
    this.updateListItem = this.updateListItem.bind(this);
    this.sendToMarketplace = this.sendToMarketplace.bind(this);
    this.getPipelineStatuses = this.getPipelineStatuses.bind(this);
    this.prepareRows = this.prepareRows.bind(this);
    this.closeRequestDetail = this.closeRequestDetail.bind(this);
    this.toggleFlaggedStatus = this.toggleFlaggedStatus.bind(this);
    this.loadDataHandler = this.loadDataHandler.bind(this);
    this.editCustomerInfo = this.editCustomerInfo.bind(this);
    this.onTableFiltersChange = this.onTableFiltersChange.bind(this);
    this.updateTableFilters = this.updateTableFilters.bind(this);
    this.handleAssignPipeline = this.handleAssignPipeline.bind(this);
    this.state = {
      modalVisibility: false,
      isFiltersShown: false,
      redirect: false,
      selectedRowId: null,
      showFilterIcon: true,
      filters: [],
      filterFailed: "",
      sortOrder: pipelinesConfig.defaultOrder,
      request: {},
      requestIdForDelete: null,
      pipelineStatuses: [],
      defaultActiveTab: undefined,
    };

    this.catchSuccessResponse = this.catchSuccessResponse.bind(this);
    this.paginationService = new PaginationService(
      context.store.dispatch,
      getPipelines,
      config.paginationLimit,
      this.state.filters,
    );
    this.paginationService.setProp("columns", pipelinesConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(pipelinesConfig));

    this.tableAction = this.tableAction.bind(this);
    this.changeModalVisibility = this.changeModalVisibility.bind(this);
    this.setFailed = this.setFailed.bind(this);
    fireTracking(props.location.pathname);
    this._mounted = true;
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState && this._mounted) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  componentDidMount() {
    document.title = pageTitle("Pipelines");
    this.getPipelineStatuses();
    storage.delete("request_form");
  }

  async getPipelineStatuses() {
    const response = await Api.setPath("pipeline", "get-statuses").getAll();
    this.setState({ pipelineStatuses: response });
    this.loadDataHandler();
  }

  prepareRows(items) {
    return items.map((item) => {
      const status_name = this.state.pipelineStatuses.find(
        (status) => status.value === +item.status,
      );

      const getAssignedBroker = () => {
        const assignedBroker = item.team_members?.find((broker) => broker.id === item.broker_id);

        if (assignedBroker) return `${assignedBroker.first_name} ${assignedBroker.last_name}`;
        return "---";
      };

      item.lead_id = `GHL${item.lead_id}`;
      item.status_label = status_name ? status_name.name : item.status;
      item.passengers = item.passenger_amount + (item.children || 0);
      item.departure = moment(item.departure_at).format("DD MMMM, YYYY");
      item.created_at = moment(item.created_at).format("DD MMMM, YYYY");
      item.name = item.last_name;
      item.assigned_to = getAssignedBroker();
      item.to_clamp = (
        <span className="line-clamp" title={item.return_at ? item.from : item.to}>
          {item.return_at ? item.from : item.to}
        </span>
      );
      item.from_clamp = (
        <span className="line-clamp" title={item.from}>
          {item.from}
        </span>
      );
      return item;
    });
  }

  changeModalVisibility() {
    this.setState({
      modalVisibility: !this.state.modalVisibility,
    });
  }

  setFailed(items) {
    if (items.length === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items)) {
      if (nextProps.filters.refreshCollection) {
        this.setFailed(nextProps.items);
        this.paginationService.reloadItems();
        this.context.store.dispatch(
          actions.change("emptyLegsBrokerFiltersModel.refreshCollection", false),
        );
        const filters = prepareQueryStringFromObj(nextProps.filters).concat(this.state.filters);
        this.paginationService.setProp("filters", filters);
      }
      this.paginationService.populateItems(nextProps.items, this.prepareRows);
    }
    const pReq = storage.get("pipelineRequest");
    if (pReq) {
      // refactor: hot fix, request is not a valid pipeline, so i replace it with pipeline
      let request = JSON.parse(storage.get("pipelineRequest"));
      const isRequestValidPipeline = !!request?.team_members;
      if (!isRequestValidPipeline) {
        request = this.props.items?.find((item) => item.id === request.pipeline_id) || {};
      }
      //-----------------
      if (
        request &&
        nextProps.history.location.state &&
        nextProps.history.location.state.from_new_request
      ) {
        this.setState(
          {
            request: request,
            piplineStatus: request.status,
            pipelineNotes: request.notes,
            defaultActiveTab: tabsKeys.workspace,
          },
          () => this.requestModal.open(),
        );
        nextProps.history.replace({ state: undefined });
        storage.delete("pipelineRequest");
      }
    }
  }

  async loadDataHandler() {
    await this.paginationService.loadData();
    this.forceUpdate();
  }

  handleSorting(column, order) {
    this.updateTableFilters(this.state.filters);
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.loadDataHandler();
    this.context.store.dispatch(
      actions.change("emptyLegsBrokerFiltersModel.refreshCollection", true),
    );
  }

  openDeleteConfirm(request) {
    this.setState({ requestIdForDelete: request.id });
    this.deletePipelineModal.open();
  }

  closeDeleteConfirm() {
    this.setState({ requestIdForDelete: null });
    this.deletePipelineModal.hide();
  }

  submitDelete() {
    this.props.deletePipeline(this.state.requestIdForDelete).then(() => {
      this.paginationService.filterList(() =>
        this.paginationService.items.filter((itm) => itm.id !== this.state.requestIdForDelete),
      );
      this.closeDeleteConfirm();
    });
  }

  openRequestDetail(request) {
    if (request.highlight && isAllowForEditing()) {
      Api.setProperty("skipErrorAlert", true)
        .setProperty("alertModal", true)
        .setPath("pipeline", `mark-as-seen/${request.id}`)
        .post()
        .then(() => {
          this.setState({
            request: {
              ...this.state.request,
              highlight: false,
            },
          });
          this.paginationService.filterList(() =>
            this.updateListItem(request.id, {
              highlight: false,
            }),
          );
          this.forceUpdate();
        });
    }

    this.requestModal.open();
    this.setState({
      request: request,
      piplineStatus: request.status,
      pipelineNotes: request.notes,
    });
  }

  toggleFlaggedStatus(pipeline) {
    spinnerInstance.setProp("type", "large").show();

    Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", true)
      .setPath("pipeline", `toggle-flag/${pipeline.id}`)
      .post()
      .then(() => {
        this.setState({
          request: {
            ...this.state.request,
            is_flag_set: !pipeline.is_flag_set,
          },
        });
        this.paginationService.filterList(() =>
          this.updateListItem(pipeline.id, {
            is_flag_set: !pipeline.is_flag_set,
            highlight: false,
          }),
        );
        this.forceUpdate();
        spinnerInstance.setProp("type", "large").hide();
      })
      .catch(() => {
        spinnerInstance.setProp("type", "large").hide();
      });
  }

  closeRequestDetail() {
    this.setState({
      request: {},
      defaultActiveTab: undefined,
    });
  }

  sendToMarketplace() {
    if (!isAllowForEditing()) {
      showNotification("restrictErrorMessage", "danger");
      return;
    }

    spinnerInstance.setProp("type", "large").show();
    Api.setProperty("skipErrorAlert", true)
      .setPath("booking-requests", `pipeline/${this.state.request.id}`)
      .getAll()
      .then((req) => {
        spinnerInstance.setProp("type", "large").hide();
        this.context.store.dispatch(editRequest({ ...req, pipeline_id: this.state.request.id }));
        storage.add("pipelineRequest", JSON.stringify(this.state.request));
        this.props.history.push("/new-request");
      });
  }

  tableAction(index) {
    const flag = () => {
      if (this.paginationService.items[index].is_flag_set) {
        return (
          <button
            className="uk-button pipeline-flag-button uk-button-link uk-padding-remove flag-request"
            onClick={() => this.toggleFlaggedStatus(this.paginationService.items[index])}
            data-flagged={this.paginationService.items[index].is_flag_set}
          >
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.68372 12.05C1.68372 12.05 2.64098 11.2 5.51278 11.2C8.38457 11.2 10.2991 12.9 13.1709 12.9C16.0427 12.9 17 12.05 17 12.05V1.85C17 1.85 16.0427 2.7 13.1709 2.7C10.2991 2.7 8.38457 1 5.51278 1C2.64098 1 1.68372 1.85 1.68372 1.85V12.05Z"
                fill="white"
                stroke="#B6B9C0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.68376 18.0001V2.33578"
                stroke="#B6B9C0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        );
      }
      return (
        <Tooltip
          pos="top-center"
          refEl="self"
          contentClass="tooltip-content-fixed-tabs"
          wrapperClassName="gh-marketplace-wrapper-tooltip"
          trigger={
            <button
              className="uk-button uk-button-link uk-padding-remove flag-request"
              onClick={() => this.toggleFlaggedStatus(this.paginationService.items[index])}
              data-flagged={this.paginationService.items[index].is_flag_set}
            >
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.68372 12.05C1.68372 12.05 2.64098 11.2 5.51278 11.2C8.38457 11.2 10.2991 12.9 13.1709 12.9C16.0427 12.9 17 12.05 17 12.05V1.85C17 1.85 16.0427 2.7 13.1709 2.7C10.2991 2.7 8.38457 1 5.51278 1C2.64098 1 1.68372 1.85 1.68372 1.85V12.05Z"
                  fill="white"
                  stroke="#B6B9C0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.68376 18.0001V2.33578"
                  stroke="#B6B9C0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          }
        >
          <div style={{ whiteSpace: "nowrap" }}>{staticService.findByAlias("saveForLater")}</div>
        </Tooltip>
      );
    };
    if (auth.isVerified()) {
      return {
        desktop: (
          <td className="gh-complex-table-td">
            <div className="uk-flex uk-flex-middle">
              <a
                className="uk-button uk-button-link gh-action uk-padding-small-rigth"
                onClick={this.openRequestDetail.bind(this, this.paginationService.items[index])}
              >
                {staticService.findByAlias("view")}
              </a>
              {flag()}
              <button
                className={`uk-button uk-button-link uk-padding-remove gh-min-width-small ${
                  this.paginationService.items[index].quotes_per_company === "Quoted"
                    ? "uk-text-muted"
                    : "uk-text-danger"
                }`}
                onClick={this.openDeleteConfirm.bind(this, this.paginationService.items[index])}
              >
                <img src={trashIcon} alt="icon" />
              </button>
            </div>
          </td>
        ),
        mobile: (
          <div className="uk-flex uk-flex-center uk-flex-middle">
            {/* NEED TO ADD VALID ROUTE*/}
            <a
              className="uk-button uk-button-link gh-action"
              onClick={this.openRequestDetail.bind(this, this.paginationService.items[index])}
            >
              {staticService.findByAlias("view")}
            </a>
            {flag()}
            <button
              className={`uk-button uk-button-link uk-padding-remove gh-min-width-small ${
                this.paginationService.items[index].quotes_per_company === "Quoted"
                  ? "uk-text-muted"
                  : "uk-text-danger"
              }`}
              onClick={this.openDeleteConfirm.bind(this, this.paginationService.items[index])}
            >
              <img src={trashIcon} alt="icon" />
            </button>
          </div>
        ),
      };
    }
    return null;
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  updateListItem(id, updatedData) {
    return this.paginationService.items.map((itm) =>
      itm.id === id
        ? {
            ...itm,
            ...updatedData,
          }
        : itm,
    );
  }

  updatePipelineNotes(value) {
    spinnerInstance.setProp("type", "large").show();
    Api.setProperty("skipErrorAlert", true)
      .setPath("pipeline", `add-notes/${this.state.request.id}`)
      .post({
        notes: value,
      })
      .then((res) => {
        this.setState({
          request: {
            ...this.state.request,
            notes: res.data.notes,
          },
        });
        this.paginationService.filterList(() =>
          this.updateListItem(this.state.request.id, {
            notes: res.data.notes,
          }),
        );
      })
      .finally(() => {
        spinnerInstance.setProp("type", "large").hide();
      });
  }

  updatePiplineStatus(statusId, statusLabel) {
    spinnerInstance.setProp("type", "large").show();
    Api.setProperty("skipErrorAlert", true)
      .setPath("pipeline", `change-status/${this.state.request.id}`)
      .post({
        status: statusId,
      })
      .then(() => {
        this.setState({
          request: {
            ...this.state.request,
            status: statusId,
          },
        });
        this.paginationService.filterList(() =>
          this.updateListItem(this.state.request.id, {
            status: statusId,
            status_label: statusLabel,
          }),
        );
        this.forceUpdate();
      })
      .finally(() => {
        spinnerInstance.setProp("type", "large").hide();
      });
  }

  editCustomerInfo(values, pipelineId) {
    spinnerInstance.setProp("type", "large").show();

    Api.setProperty("skipErrorAlert", true)
      .setPath("pipeline", `edit-client/${pipelineId}`)
      .post(values)
      .then(({ data }) => {
        showNotification(staticService.findByAlias("customerUpdateInfoMessage"), "success");

        const newInfo = {
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          email: data.email || "",
          phone: data.phone || "",
          name: `${data.first_name} ${data.last_name}`,
        };

        this.paginationService.filterList(() => this.updateListItem(pipelineId, newInfo));
        this.setState({ request: { ...this.state.request, ...newInfo } });
        this.forceUpdate();

        spinnerInstance.setProp("type", "large").hide();
      })
      .catch(() => {
        spinnerInstance.setProp("type", "large").hide();
      });
  }

  updateTableFilters(filters) {
    if (filters[0]) {
      let updatedFilters = [...this.paginationService.filters];

      if (updatedFilters.find((f) => f.key === "keyword")) {
        updatedFilters = updatedFilters.map((filter) => {
          if (filter.key === "keyword") {
            filter.value = filters[0].value;
          }

          return filter;
        });
      } else {
        updatedFilters.push(filters[0]);
      }

      this.paginationService.setProp("filters", updatedFilters);
    }
  }

  onTableFiltersChange(filters) {
    this.setState({ filters });
    this.updateTableFilters(filters);
    this.loadDataHandler();
    this.context.store.dispatch(
      actions.change("emptyLegsBrokerFiltersModel.refreshCollection", true),
    );
  }

  handleAssignPipeline(brokerId, id) {
    return this.props
      .requestSwitchBroker(id, { type_of_request: 1, id: id, broker_id: brokerId })
      .then((res) => {
        this.setState({ request: res.data });
        showNotification("Pipeline assigned with success", "success");
        return res;
      });
  }

  render() {
    return (
      <div className="uk-margin-medium-top">
        <div className="uk-container">
          <BrokerPipelineView
            pipeline={this.state.request}
            refClbk={(requestModal) => (this.requestModal = requestModal)}
            statuses={this.state.pipelineStatuses}
            onClose={this.closeRequestDetail}
            updatePiplineStatus={this.updatePiplineStatus}
            updatePipelineNotes={this.updatePipelineNotes}
            sendToMarketplace={this.sendToMarketplace}
            defaultActiveTab={this.state.defaultActiveTab}
            editCustomerInfo={this.editCustomerInfo}
            handleAssignPipeline={this.handleAssignPipeline}
          />
          <ModalComponent
            title="Are you sure you want delete this lead?"
            ref={(deletePipelineModal) => (this.deletePipelineModal = deletePipelineModal)}
            id="deletePipelineModal"
            onClose={this.closeDeleteConfirm.bind(this)}
            modalStyles={{ maxWidth: 500 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 15,
                flexDirection: window.innerWidth < 576 ? "column" : "row",
              }}
            >
              <a
                className="uk-button uk-button-secondary gh-button-flex"
                style={{
                  marginRight: window.innerWidth < 576 ? 0 : 15,
                  marginBottom: window.innerWidth < 576 ? 15 : 0,
                }}
                onClick={this.closeDeleteConfirm.bind(this)}
              >
                {staticService.findByAlias("cancel")}
              </a>
              <a
                className="uk-button uk-button-primary gh-button-flex"
                onClick={this.submitDelete.bind(this)}
              >
                {staticService.findByAlias("confirm")}
              </a>
            </div>
          </ModalComponent>

          <div className="uk-width-1-1">
            <h3
              className="gh-booking-layout-title"
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("pipelines") }}
            />
          </div>

          <BrokerPipeLinesFilters onChange={this.onTableFiltersChange} />

          <ComplexTableComponent
            className="pipelines-table"
            loadMore={this.paginationService.loadMore.bind(this.paginationService)}
            rows={this.paginationService.items}
            headers={this.paginationService.headers}
            columns={this.paginationService.columns}
            showActionColumn={1}
            showLoader={this.paginationService.showLoaderBtn}
            showMoreText={staticService.findByAlias("showMore")}
            actionElements={this.tableAction.bind(this)}
            selectedOrder={this.state.sortOrder}
            sortChange={this.handleSorting.bind(this)}
            firstTimeLoading={this.paginationService.initialLoadState}
            isLoading={this.paginationService.isLoading}
          />
        </div>
      </div>
    );
  }
}

BrokerPipelinesComponent.propTypes = {
  items: PropTypes.array,
  requestSwitchBroker: PropTypes.func,
};

BrokerPipelinesComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({
  emptyLegsBrokerFiltersModel,
  activityFlights,
  screenDimensions,
  pipelines,
  requests,
  editedRequest,
}) {
  return {
    items: pipelines,
    filters: emptyLegsBrokerFiltersModel,
    activityFlights,
    screenDimensions,
    reasons: requests.reasons,
    editedRequest,
  };
}
const COMPONENT = connect(mapStateToProps, { deletePipeline, requestSwitchBroker })(
  BrokerPipelinesComponent,
);
export { COMPONENT as BrokerPipelinesComponent };
