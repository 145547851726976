import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, Form, Errors, actions } from "react-redux-form";
import Auth from "../../services/auth";
import { StripeService } from "../../services";
import { updateCard, addCard } from "../../actions";
import {
  spinnerInstance,
  updateModel,
  CustomValidateError,
  capitalizeFirstLetter,
} from "../../shared";
import { validationRules, validationMessages } from "../../configs";
import GetHeliEvents from "../../services/event.service";
import staticService from "../../services/static.service";
import moment from "moment";
import auth from "../../services/auth";

const { required } = validationRules;

const elementClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid",
};

const elementStyles = {
  focused: {
    background: "#E6F2F0",
    border: "1px solid #D5D5D5",
  },
  base: {
    color: "#32325D",
    fontWeight: 500,
    fontSize: "16px",
    fontSmoothing: "antialiased",

    "::placeholder": {
      color: "#CFD7DF",
    },
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
  invalid: {
    color: "#E25950",
    border: "1px solid #E25950",

    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

const elements = [];

class CardFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.initStripeForm = this.initStripeForm.bind(this);
    this.state = {
      stripeService: new StripeService(Stripe),
      card: props.card,
      disabledState: false,
      fieldsList: ["#address", "#city", "#state", "#zip", "#name"],
    };
  }

  initStripeForm() {
    this.state.stripeService.form = document.getElementById("payment-form");
    this.state.stripeService.error = this.state.stripeService.form.querySelector(".error");
    this.state.stripeService.errorMessage =
      this.state.stripeService.error.querySelector(".message");

    elements[0] = this.state.stripeService.elements.create("cardNumber", {
      style: elementStyles,
      classes: elementClasses,
    });
    elements[0].mount("#card-number");

    elements[1] = this.state.stripeService.elements.create("cardExpiry", {
      style: elementStyles,
      classes: elementClasses,
    });
    elements[1].mount("#card-expiry");

    elements[2] = this.state.stripeService.elements.create("cardCvc", {
      style: elementStyles,
      classes: elementClasses,
    });
    elements[2].mount("#card-cvc");

    this.state.stripeService.setInputErrorsEvents(elements);
    this.state.stripeService.setInputEvents();
    GetHeliEvents.setEvent("stripe-form-validation-failed", () => {
      this.setState({ disabledState: true });
    });
    GetHeliEvents.setEvent("stripe-form-validation-success", () => {
      this.setState({ disabledState: false });
      this.state.stripeService.errorMessage.innerText = "";
    });
  }

  componentDidMount() {
    if (!this.state.card) {
      this.initStripeForm();
    } else {
      const model = {
        card_id: this.state.card.card_id,
        address_line1: this.state.card.line,
        address_city: this.state.card.city,
        address_country: this.state.card.state,
        address_zip: this.state.card.zip,
        // address_country: this.props.cardUpdateModel.address_country,
      };
      this.props.updateModel(model);
    }
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("stripe-form-validation-failed");
    GetHeliEvents.clearEvent("stripe-form-validation-success");
  }

  onError(err) {
    if (err.status !== 423) {
      this.state.stripeService.setError(this.state.stripeService, 0, err.data.error);
    }
    this.state.stripeService.savedErrors = {};
    spinnerInstance.hide();
  }

  onSubmit(e) {
    e.preventDefault();
    const _stripeService = this.state.stripeService;
    // Disable all inputs.
    _stripeService.disableInputs();

    // Gather additional customer data we may have collected in our form.
    const additionalElements = [];
    additionalElements.push(_stripeService.form.querySelector("#address"));
    additionalElements.push(_stripeService.form.querySelector("#city"));
    additionalElements.push(_stripeService.form.querySelector("#state"));
    additionalElements.push(_stripeService.form.querySelector("#zip"));
    additionalElements.push(_stripeService.form.querySelector("#name"));

    const additionalData = {
      address_line1: additionalElements[0] ? additionalElements[0].value : undefined,
      address_city: additionalElements[1] ? additionalElements[1].value : undefined,
      address_country: additionalElements[2] ? additionalElements[2].value : undefined,
      address_zip: additionalElements[3] ? additionalElements[3].value : undefined,
      name: additionalElements[4] ? additionalElements[4].value : undefined,
    };

    const validator = _stripeService.validateAdditionalData(additionalData);

    if (!_stripeService.valid) {
      _stripeService.setError(_stripeService, validator.idx, validator.error);
      _stripeService.enableInputs();
      // this.state.fieldsList[validator.idx].classList.add("invalid");
      additionalElements[validator.idx].classList.add("invalid");
      if (document.querySelector(this.state.fieldsList[validator.idx])) {
        document.querySelector(this.state.fieldsList[validator.idx]).classList.add("invalid");
      }
      return;
    }

    spinnerInstance.setProp("type", "large").show();
    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token. We can also pass
    // in the additional customer data we collected in our form.
    _stripeService.stripe.createToken(elements[2], additionalData).then((result) => {
      if (result.token) {
        this.props
          .addCard(
            result.token.id,
            this.props.onSuccess,
            this.onError.bind(this),
            this.props.newCardAction,
          )
          .then(() => {
            const departureDaysCount = moment(this.props.quoteDetail.departure_time).diff(
              new Date(),
              "days",
            );
            const isEnabled = auth.hasFeature("manual_bank_transfer");
            spinnerInstance.hide();

            if (isEnabled && departureDaysCount <= 9) {
              this.props.updateOnAddNewCard({ card_id: "manual" });
            }
          });
      } else {
        spinnerInstance.hide();

        const field = result.error.code.split("_");
        let elementType = result.error.code;
        if (field.length > 0) {
          elementType = `card${capitalizeFirstLetter(field[field.length - 1])}`;
        }
        _stripeService.setError(_stripeService, elementType, result.error);
      }
      _stripeService.enableInputs();
    });
  }

  removeErrorsOnChange() {
    const { fieldsList } = this.state;
    for (let i = 0; i < fieldsList.length; i++) {
      document.querySelector(fieldsList[i]).classList.remove("invalid");
    }
    this.setState({ disabledState: false });
    this.state.stripeService.errorMessage.innerText = "";
  }

  get addNewCardForm() {
    return (
      <form
        id="payment-form"
        className="gh-payment-form"
        onSubmit={this.onSubmit}
        noValidate
        onChange={this.removeErrorsOnChange.bind(this)}
      >
        <div>
          <button
            className="backBtn"
            onClick={() => {
              this.props.changeCard("cancel");
            }}
          >
            <span>{"< "}</span> BACK
          </button>
          <hr />
        </div>
        <div data-uk-grid>
          <div className="uk-width-1-1 uk-width-1-2@l">
            <h3 className="uk-margin-bottom">{staticService.findByAlias("creditCardDetails")}</h3>
            <div className="field">
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cardName") }} />
              <input id="name" className="uk-input empty" type="text" required />
            </div>
            <div className="field">
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("CardNumber") }} />
              <div id="card-number" className="input empty" />
            </div>
            <div data-uk-grid className="uk-grid-small">
              <div className="uk-width-2-3">
                <div className="field" style={{ marginTop: 0 }}>
                  <span
                    dangerouslySetInnerHTML={{ __html: staticService.findByAlias("Expiration") }}
                  />
                  <div id="card-expiry" className="input empty" />
                </div>
              </div>
              <div className="uk-width-1-3">
                <div className="field" style={{ marginTop: 0 }}>
                  <span
                    dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cardCVC") }}
                  />
                  <div id="card-cvc" className="input empty" />
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-1-2@l">
            <h3 className="uk-margin-bottom">{staticService.findByAlias("billingAddress")}</h3>
            <div className="field">
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("address") }} />
              <input id="address" className="uk-input empty" type="text" required />
            </div>
            <div className="field">
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("city") }} />
              <input
                id="city"
                className="input empty"
                type="text"
                placeholder="San Francisco"
                required
              />
            </div>
            <div className="field">
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("state") }} />
              <input
                id="state"
                className="input empty"
                type="text"
                placeholder="United States"
                required
              />
            </div>
            <div className="field">
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("zipCode") }} />
              <input id="zip" className="input empty" type="text" placeholder="94107" required />
            </div>
          </div>
        </div>
        <div className="error" role="alert">
          <span className="message" />
        </div>
        <hr />
        {window.innerWidth > 576 ? (
          <div>
            <button
              type="submit"
              className="uk-button uk-button-primary"
              disabled={this.state.disabledState}
            >
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("saveCard") }} />
            </button>
            <button
              type="button"
              className="uk-button uk-button-default uk-margin-left"
              style={{ border: 0 }}
              onClick={() => {
                this.props.changeCard("cancel");
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cancel") }} />
            </button>
          </div>
        ) : (
          <div className="gh-payment-form-btns">
            <button type="submit" className="uk-button-primary" disabled={this.state.disabledState}>
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("saveCard") }} />
            </button>
            <button
              type="button"
              className="uk-button-default uk-margin-left"
              onClick={() => {
                this.props.changeCard("cancel");
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cancel") }} />
            </button>
          </div>
        )}
      </form>
    );
  }

  get updateCardForm() {
    return (
      <Form model={"cardUpdateModel"} onSubmit={this.props.updateCard}>
        <div className="uk-grid-item-match">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("address") }}
          />
          <Control.text
            className={"uk-input"}
            model={".address_line1"}
            changeAction={updateModel}
            updateOn="change"
          />
          <Errors
            model=".address_line1"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("address"),
              ),
            }}
          />
        </div>

        <div className="uk-grid-item-match">
          <label
            className={"uk-form-label required"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("city") }}
          />
          <Control.text
            className={"uk-input"}
            model={".address_city"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ required }}
          />
          <Errors
            model=".address_city"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("city"),
              ),
            }}
          />
        </div>

        <div className="uk-grid-item-match">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("zipCode") }}
          />
          <Control.text
            className={"uk-input"}
            model={".address_zip"}
            changeAction={updateModel}
            updateOn="change"
          />
          <Errors
            model=".address_zip"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("zipCode"),
              ),
            }}
          />
        </div>

        <div className="uk-grid-item-match">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("state") }}
          />
          <Control.text
            className={"uk-input"}
            model={".address_state"}
            changeAction={updateModel}
            updateOn="change"
          />
          <Errors
            model=".address_state"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("state"),
              ),
            }}
          />
        </div>

        <div className={"uk-text-center"}>
          <button
            type="button"
            className={"uk-button uk-button-danger uk-margin-small-right uk-margin-small-top"}
            onClick={this.props.changeCard}
          >
            {<span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cancel") }} />}
          </button>
          <Control.button
            model={"cardUpdateModel"}
            type={"submit"}
            disabled={{ valid: false }}
            className={"uk-button uk-button-primary uk-margin-small-top"}
          >
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("update") }} />
          </Control.button>
        </div>
      </Form>
    );
  }

  render() {
    if (this.props.visible && Auth.isAuthenticated()) {
      return this.state.card ? this.updateCardForm : this.addNewCardForm;
    }
    return null;
  }
}

CardFormComponent.contextTypes = {
  store: PropTypes.object,
};

CardFormComponent.propTypes = {
  changeCard: PropTypes.func,
  card: PropTypes.object,
  onSuccess: PropTypes.func,
  newCardAction: PropTypes.string,
};

function mapStateToProps(state) {
  const response = {
    cardNumber: state.paymentModel.card.number,
    existCard: false,
    quoteRequest: state.requestDetail,
    cardUpdateModel: state.cardUpdateModel,
  };
  if (state.auth && state.auth.total) {
    response.existCard = true;
  }
  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model) => dispatch(actions.change("cardUpdateModel", model)),
      updateCard,
      addCard,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(CardFormComponent);
export { COMPONENT as CardFormComponent };
