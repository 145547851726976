import React, { Component } from "react";
import PropTypes from "prop-types";
import { RadioComponent } from "../../../shared";

class RadioGroupComponent extends Component {
  constructor(props) {
    super(props);
    this.isChecked = this.isChecked.bind(this);
  }

  isChecked(value) {
    return this.props.defaultValue === value ? true : false;
  }

  get inline() {
    return this.props.isInline ? "uk-inline" + this.props.className : "uk-block";
  }

  render() {
    return (
      <div>
        <div className={this.inline}>
          {this.props.items.map((radio, key) => (
            <RadioComponent
              key={key}
              label={radio.label}
              model={this.props.model}
              onClick={(isChecked, val, model) => {
                const _modelName = this.props.parentModel
                  ? this.props.parentModel + this.props.model
                  : this.props.model;
                return this.props.updateModelDispatch(_modelName, val);
              }}
              value={radio.value}
              wrapperClassName={"gh-radio-wrapper-with-label"}
              checked={this.isChecked(radio.value)}
            />
          ))}
        </div>
      </div>
    );
  }
}

RadioGroupComponent.propTypes = {
  defaultValue: PropTypes.any,
  items: PropTypes.array.isRequired,
  isInline: PropTypes.bool,
  model: PropTypes.string.isRequired,
  className: PropTypes.string,
  parentModel: PropTypes.string,
  updateModelDispatch: PropTypes.func.isRequired,
};

export { RadioGroupComponent };
