import React, { useState } from "react";
import staticService from "../../../services/static.service";
import AccordionArrow from "../../../assets/img/full-accordion-arrow.png";
import TermsAndConditions from "../TermsAndConditions/termsAndConditions";
import PropTypes from "prop-types";

const TermsAndConditionsAccordion = ({ request, isOpen = false }) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(isOpen);
  const getClasses = (e) => {
    setAccordionIsOpen(e.currentTarget.classList.contains("uk-open"));
  };
  return (
    <ul data-uk-accordion="multiple: true" className="gh-accordion-wrapper">
      <li
        id={`gh-accordion-item-terms-and-conditions`}
        className={`${
          isOpen ? "uk-open" : ""
        } gh-accordion-section gh-simple-section gh-accordion-wrapper-item`}
        onClick={getClasses}
      >
        <a className="uk-accordion-title" href="#">
          <h4 className="gh-request-section-title">
            {staticService.findByAlias("charterTerms").toString().toUpperCase()}
          </h4>
          {!accordionIsOpen ? (
            <h4 className="gh-request-section-title">
              {staticService.findByAlias("cancellationFees").toString().toUpperCase()}
            </h4>
          ) : null}
          <span className="accordion-icon">
            <img src={AccordionArrow} alt="icon" />
          </span>
        </a>
        <div className="uk-accordion-content uk-margin-remove">
          <TermsAndConditions request={request} disableTitle={true} disableWrapperStyles={true} />
        </div>
      </li>
    </ul>
  );
};

TermsAndConditionsAccordion.propTypes = {
  request: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};
export default TermsAndConditionsAccordion;
