import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./QuoteDetails.css";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addPrivateNoteOperator,
  extendExpirationDate,
  getQuote,
  REMOVE_QUOTE_ITEM,
} from "../../../actions";
import QuoteVisualsLayout from "../ViewFullQuoteDetails/components/QuoteVisualsLayout/QuoteVisualsLayout";
import { canWriteCompanyNotes, isObjectEmpty, ModalComponent } from "../../../shared";
import staticService from "../../../services/static.service";

import QuoteDetailsManageButtons from "./components/QuoteDetailsManageButtons";
import QuoteGeneralInfoWithChat from "./components/QuoteGeneralInfoWithChat";
import { BrokerPad, BrokerQuoteComponent } from "../user-online";
import BackLinkStyled from "../../common/BackLinkStyled";
import {
  PENDING,
  QUOTE_OPEN,
  QUOTE_REJECTED,
  QUOTE_SELECTED,
  UNSUCCESSFUL,
} from "../../../configs";
import auth from "../../../services/auth";
import OperatorPrivateNotesComponent from "../../forms/operator-private-notes.component";
import QuoteReason from "./components/QuoteReason";

const QuoteDetails = (props) => {
  const {
    match,
    parentMatch,
    getQuote,
    quoteDetail,
    history,
    requestDetail,
    location,
    removeQuotes,
    addPrivateNoteOperator,
    extendExpirationDate,
  } = props;

  const [padModalProps, setPadModalProps] = useState(null);

  const { pathname: locationPathname, state: locationState } = location || {};
  const quoteID = match.params.id;
  const requestID = requestDetail.id;

  const { url: parentMatchUrl = null } = parentMatch || {};
  const parentUrl = parentMatchUrl || `/flights/quotes`;

  const {
    internalNotes = "",
    alternativeQuotes = null,
    status: quoteStatus,
    departure_time: quoteDepartureTime,
    price: quotePrice,
    is_conversation_open,
  } = quoteDetail || {};
  const alternativeQuotesData = alternativeQuotes ? alternativeQuotes.data : [];
  const showButtons =
    quoteStatus === QUOTE_OPEN || quoteStatus === QUOTE_SELECTED || quoteStatus === PENDING;
  const chatFeatures = auth.hasFeature("operator_to_start_conversation");

  const liveChatVisibility = chatFeatures
    ? showButtons ||
      quoteDetail?.booking_request?.status === QUOTE_OPEN ||
      (quoteDetail?.booking_request?.has_to_expire &&
        quoteDetail?.booking_request?.status === QUOTE_OPEN &&
        quoteStatus === UNSUCCESSFUL)
    : is_conversation_open && showButtons;

  const padModal = useRef(null);

  /*===========Lifecycles===========*/
  useEffect(() => {
    const wrapper = document.querySelector(".gh-block-component-wrapper-with-margin");
    if (!wrapper) return;
    wrapper.classList.remove("uk-margin-medium-top");
    return () => {
      document
        .querySelector(".gh-block-component-wrapper-with-margin")
        ?.classList.add("uk-margin-medium-top");
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getQuote(quoteID).catch(() => {
      history.push(backwardUrl);
    });
    return () => {
      removeQuotes();
    };
  }, [quoteID]);

  useEffect(() => {
    if (padModalProps) {
      padModal.current.open();
    }
  }, [padModalProps]);

  useEffect(() => {
    document.body.classList.add("gh-quote-details-body");

    return function cleanup() {
      document.body.classList.remove("gh-quote-details-body");
    };
  }, []);

  /*===========Memoized===========*/
  const canWriteNotesCallback = useMemo(() => canWriteCompanyNotes(quoteStatus), [quoteStatus]);
  const backwardUrl = useMemo(
    () =>
      locationState?.prevPath
        ? locationState.prevPath
        : parentMatchUrl
        ? parentMatchUrl
        : "/dashboard",
    [locationState, parentMatchUrl],
  );

  const backLinkTitle = useMemo(
    () =>
      backwardUrl === "/dashboard"
        ? staticService.findByAlias("backToDashboard")
        : backwardUrl === "/notifications"
        ? staticService.findByAlias("backToNotifications")
        : staticService.findByAlias("backToAllQuotesList"),
    [backwardUrl],
  );
  const alternativeQuotesBackwardLink = useCallback(
    (quote) =>
      locationState && locationState.prevPath
        ? { pathname: `${parentUrl}/${quote.id}`, state: { prevPath: backwardUrl } }
        : `${parentUrl}/${quote.id}`,
    [locationState, parentUrl, backwardUrl],
  );

  /*===========Functions===========*/

  const handleShowPadModal = (type, title, coords) => {
    setPadModalProps({ type, title, coords, status: true });
  };

  const handleCloseModal = () => {
    setPadModalProps(null);
  };

  if (isObjectEmpty(quoteDetail)) return "";
  return (
    <div className="gh-operator-quote-details-wrapper">
      <BackLinkStyled
        to={backwardUrl}
        linkName={backLinkTitle}
        title={`Quote Details - GHQ${quoteID}`}
      />
      <div className="gh-operator-quote-details uk-container">
        <div className="gh-operator-quote-details-main-details">
          <QuoteGeneralInfoWithChat
            visibility={liveChatVisibility}
            quoteID={quoteID}
            info={quoteDetail}
            originPath={locationPathname}
            requestID={parseInt(requestID)}
            extendExpirationDate={extendExpirationDate}
            history={history}
          />

          <QuoteReason quote={quoteDetail} />

          {canWriteNotesCallback && (
            <div className="gh-operator-quote-details-item-notes">
              <OperatorPrivateNotesComponent
                notes={quoteDetail.private_notes}
                id={quoteID}
                action={addPrivateNoteOperator}
                quote={quoteDetail}
                hasQuoteToExpired={quoteDetail.has_to_expire}
              />
            </div>
          )}
        </div>

        <div className="gh-operator-quote-details-button">
          <QuoteDetailsManageButtons
            quoteID={Number(quoteID)}
            quoteStatus={quoteStatus}
            bookingRequestStatus={requestDetail.status}
            quoteDepartureTime={quoteDepartureTime}
            quotePrice={quotePrice}
            requestID={Number(requestID)}
            parentUrl={parentUrl}
            originPath={locationPathname}
            history={history}
            payment={quoteDetail.payment}
            hasQuoteToExpired={quoteDetail.has_to_expire}
          />
        </div>
        <hr
          className="gh-quote-divider"
          style={
            !showButtons && !canWriteNotesCallback
              ? quoteStatus === QUOTE_REJECTED
                ? { marginTop: "10px" }
                : { marginTop: "30px" }
              : {}
          }
        />
        <div className="gh-operator-quote-details-visuals-section">
          <QuoteVisualsLayout quoteDetail={quoteDetail} showVetting={false} />
        </div>
        {alternativeQuotesData.length > 0 ? (
          <div className="gh-operator-quote-details-other-quotes">
            <hr className="gh-quote-divider" />
            <h4 className="gh-operator-quote-details-title">
              {staticService.findByAlias("otherQuotesForThisRequest")}
            </h4>
            {alternativeQuotesData.map((quote, index) => {
              return (
                <BrokerQuoteComponent
                  key={index}
                  visible={true}
                  showTail={false}
                  viewFullQuoteOnClick={alternativeQuotesBackwardLink(quote)}
                  quote={{
                    ...quote,
                    reason: quoteDetail.reason,
                    user_vat_formatted: quote.quote_vat,
                    user_price_formatted: quote.price,
                    user_currency: quote.currency,
                  }}
                  request={requestDetail}
                  onSelectPath={"/flights/requests/" + requestID}
                  hideChat={true}
                  hideOnHoldOverlay={true}
                  showPad={handleShowPadModal}
                />
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      {/*MODALS*/}
      <ModalComponent
        title={padModalProps ? padModalProps.type : ""}
        ref={padModal}
        id={"QuoteDetailsPadModal"}
        onClose={handleCloseModal}
        onCloseOnBackground={handleCloseModal}
      >
        {padModalProps && padModalProps.status && <BrokerPad pad={padModalProps} />}
      </ModalComponent>
    </div>
  );
};

QuoteDetails.propTypes = {
  getQuote: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  parentMatch: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  quoteDetail: PropTypes.object.isRequired,
  requestDetail: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  removeQuotes: PropTypes.func,
  addPrivateNoteOperator: PropTypes.func,
  extendExpirationDate: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeQuotes: () => dispatch({ type: REMOVE_QUOTE_ITEM }),
      getQuote,
      extendExpirationDate,
      addPrivateNoteOperator,
    },
    dispatch,
  );
};
const mapStateToProps = (state) => ({
  quoteDetail: state.quoteDetail,
  requestDetail: state.requestDetail || {},
});
export default connect(mapStateToProps, mapDispatchToProps)(QuoteDetails);
