import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions } from "react-redux-form";
import {
  saveNotifications,
  loadNotificationsSettings,
  changeNotificationsSettings,
} from "../../../../../actions";
import { fireTracking, RadioComponent } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import auth from "../../../../../services/auth";
import { NavLink } from "react-router-dom";

class NotificationsSettingsOperatorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      booking_changes: 0,
      empty_leg_requests: 0,
      getheli_updates: 0,
      new_quotes: 0,
      new_requests: 0,
      own_changes: 0,
      unsuccessful_quotes: 0,
    };
  }

  componentDidMount() {
    this.props.getNotificationsSettings();
  }

  render() {
    const { getNotifications, changeNotifications, saveChanges } = this.props;
    return (
      <div>
        <div className="">
          {/*<div className="gh-account-not-width">{staticService.findByAlias('notificationsText')}</div>*/}
          <div
            className="gh-account-not-width"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("notificationsText") }}
          />
          <hr className="gh-hr-notifications" />
          <div className="uk-flex uk-flex-column uk-margin-small-top">
            {auth.getRole() === "operator_admin" && (
              <div className="uk-inline gh-mob-radio gh-padding-small-bottom">
                <div className="">{staticService.findByAlias("changesToCompany")}</div>
                <div className="uk-flex gh-margin-left-20 gh-margin-top-5">
                  <RadioComponent
                    disabled
                    label={staticService.findByAlias("always")}
                    model={".activity"}
                    onClick={() => {}}
                    value={"always"}
                    wrapperClassName={"gh-radio-wrapper-with-label"}
                    checked={true}
                  />
                </div>
              </div>
            )}
            <div className="uk-inline gh-mob-radio gh-padding-small-bottom">
              <div data-uk-grid>
                <div className="gh-padding-top-3">
                  {staticService.findByAlias("bookingStatusChanges")}
                </div>
                <div className="uk-width-auto gh-padding-left-10">
                  <span
                    data-uk-icon="icon: info"
                    data-uk-tooltip={`title: ${staticService.findByAlias(
                      "brokerToolTip",
                    )}; pos: top-left`}
                  />
                </div>
              </div>
              <div className="uk-flex gh-margin-left-20 gh-margin-top-5 gh-grid-notification">
                <RadioComponent
                  label={staticService.findByAlias("wholeCompany")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ booking_changes: 1 })}
                  value={"On"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.booking_changes === 1 ? true : false}
                  // new_quotes
                />
                <RadioComponent
                  label={staticService.findByAlias("onlyMine")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ booking_changes: 2 })}
                  value={"Off"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.booking_changes === 1 ? false : true}
                  // new_quotes
                />
              </div>
            </div>
            <div className="uk-inline gh-mob-radio gh-padding-small-bottom">
              <div className="">{staticService.findByAlias("newRequestsMatching")}</div>
              <div className="uk-flex gh-margin-left-20 gh-margin-top-5 gh-grid-notification">
                <RadioComponent
                  label={staticService.findByAlias("onNotificationsRadio")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ new_requests: 1 })}
                  value={"On"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.new_requests === 1 ? true : false}
                />
                <RadioComponent
                  label={staticService.findByAlias("offNottifications")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ new_requests: 2 })}
                  value={"Off"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.new_requests === 1 ? false : true}
                />
              </div>
            </div>
            <div className="uk-inline gh-mob-radio gh-padding-small-bottom">
              <div className="">{staticService.findByAlias("emptyLegRequest")}</div>
              <div className="uk-flex gh-margin-left-20 gh-margin-top-5 gh-grid-notification">
                <RadioComponent
                  label={staticService.findByAlias("wholeCompany")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ empty_leg_requests: 1 })}
                  value={"On"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.empty_leg_requests === 1 ? true : false}
                />
                <RadioComponent
                  label={staticService.findByAlias("onlyMine")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ empty_leg_requests: 2 })}
                  value={"Off"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.empty_leg_requests === 1 ? false : true}
                />
              </div>
            </div>
            <div className="uk-inline gh-mob-radio gh-padding-small-bottom">
              <div className="">{staticService.findByAlias("unsuccessfulQuotes")}</div>
              <div className="uk-flex gh-margin-left-20 gh-margin-top-5 gh-grid-notification">
                <RadioComponent
                  label={staticService.findByAlias("wholeCompany")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ unsuccessful_quotes: 1 })}
                  value={"On"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.unsuccessful_quotes === 1 ? true : false}
                  // getheli_updates
                />
                <RadioComponent
                  label={staticService.findByAlias("onlyMyQuotes")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ unsuccessful_quotes: 2 })}
                  value={"Off"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.unsuccessful_quotes === 2 ? true : false}
                />
                <RadioComponent
                  label={staticService.findByAlias("offNottifications")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ unsuccessful_quotes: 0 })}
                  value={"Off"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.unsuccessful_quotes === 0 ? true : false}
                />
              </div>
            </div>
            <div className="uk-inline gh-mob-radio gh-padding-small-bottom">
              <div className="">{staticService.findByAlias("getHeliUpdates")}</div>
              <div className="uk-flex gh-margin-left-20 gh-margin-top-5 gh-grid-notification">
                <RadioComponent
                  label={staticService.findByAlias("onNotificationsRadio")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ getheli_updates: 1 })}
                  value={"On"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.getheli_updates === 1 ? true : false}
                  // getheli_updates
                />
                <RadioComponent
                  label={staticService.findByAlias("offNottifications")}
                  model={".activity"}
                  onClick={() => this.props.changeNotificationsSettings({ getheli_updates: 0 })}
                  value={"Off"}
                  wrapperClassName={
                    "gh-radio-wrapper-with-label gh-margin-right-50 gh-filters-control gh-margin-bottom-notification"
                  }
                  checked={changeNotifications.getheli_updates === 1 ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="gh-margin-top-20">
            <Control.button
              model={"button"}
              disabled={JSON.stringify(getNotifications) === JSON.stringify(changeNotifications)}
              className={"uk-button uk-button-primary uk-align-center"}
              onClick={() => saveChanges(changeNotifications)}
            >
              <span
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("saveChanges") }}
              ></span>
            </Control.button>
          </div>
        </div>
      </div>
    );
  }
}

NotificationsSettingsOperatorComponent.contextTypes = {
  store: PropTypes.object,
  getNotifications: PropTypes.shape({}),
  changeNotificationsSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    getNotifications: state.getNotificationsSettings,
    changeNotifications: state.changeNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveChanges: saveNotifications,
      getNotificationsSettings: loadNotificationsSettings,
      changeNotificationsSettings: changeNotificationsSettings,
    },
    dispatch,
  );
};

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsSettingsOperatorComponent);
export { COMPONENT as NotificationsSettingsOperatorComponent };
