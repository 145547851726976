import React, { Component } from "react";
import PropTypes from "prop-types";

class LoadingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: "",
    };
    this.onClick = this.onClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.hideLoader !== nextProps.hideLoader &&
      nextProps.hideLoader === "" &&
      nextProps.type === "button"
    ) {
      this.setState({ loaded: "" });
    }

    if (this.props.hideLoader !== nextProps.hideLoader && nextProps.type === "submit") {
      this.setState({ loaded: nextProps.hideLoader });
    }
  }

  onClick() {
    this.setState({ loaded: " show" });
    this.props.action();
  }

  render() {
    const props = {
      className: this.props.classNames.join(" ") + this.state.loaded,
      type: this.props.type,
    };
    if (this.props.type === "button") {
      props.onClick = this.onClick;
    }

    return <div>{this.props.children({ btnProps: props })}</div>;
  }
}

LoadingButton.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string.isRequired,
  action: PropTypes.func,
  hideLoader: PropTypes.string,
};

LoadingButton.defaultTypes = {
  classNames: ["uk-button", "uk-button-primary", "gh-button-loader"],
  type: "button",
  hideLoader: "",
  action: () => {},
};

export { LoadingButton };
