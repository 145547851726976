import React from "react";
import PropTypes from "prop-types";
import { camelize } from "../helpers";

export class CurveBezierComponent extends React.Component {
  constructor(props) {
    super(props);
    this.B1 = this.B1.bind(this);
    this.B2 = this.B2.bind(this);
    this.B3 = this.B3.bind(this);
    this.B4 = this.B4.bind(this);
    this.getBezier = this.getBezier.bind(this);
    this.GmapsCubicBezier = this.GmapsCubicBezier.bind(this);
  }

  B1(t) {
    return t * t * t;
  }

  B2(t) {
    return 3 * t * t * (1 - t);
  }

  B3(t) {
    return 3 * t * (1 - t) * (1 - t);
  }

  B4(t) {
    return (1 - t) * (1 - t) * (1 - t);
  }

  getBezier(C1, C2, C3, C4, percent) {
    const pos = {};
    pos.x =
      C1.x * this.B1(percent) +
      C2.x * this.B2(percent) +
      C3.x * this.B3(percent) +
      C4.x * this.B4(percent);
    pos.y =
      C1.y * this.B1(percent) +
      C2.y * this.B2(percent) +
      C3.y * this.B3(percent) +
      C4.y * this.B4(percent);
    return pos;
  }

  GmapsCubicBezier(latlong1, latlong2, latlong3, latlong4, resolution) {
    const lat1 = latlong1.lat();
    const long1 = latlong1.lng();
    const lat2 = latlong2.lat();
    const long2 = latlong2.lng();
    const lat3 = latlong3.lat();
    const long3 = latlong3.lng();
    const lat4 = latlong4.lat();
    const long4 = latlong4.lng();

    let points = [];
    for (let it = 0; it <= 1.01; it += resolution) {
      const point = this.getBezier(
        {
          x: lat4,
          y: long4,
        },
        {
          x: lat3,
          y: long3,
        },
        {
          x: lat2,
          y: long2,
        },
        {
          x: lat1,
          y: long1,
        },
        it,
      );

      points.push({
        lat: point.x,
        lng: point.y,
      });
    }

    const Line = new google.maps.Polyline({
      path: points,
      clickable: true,
      strokeColor: this.props.strokeColor,
      strokeOpacity: 1,
      /*	strokeWeight: 3,*/
      /*icons: [{
				icon: {
					path: 'M 0,-1 0,1',
					strokeOpacity: 1,
				},
					offset: '0',
					repeat: '20px'
			}]*/
    });

    Line.setMap(this.props.map);
    return Line;
  }

  setLatLng({ lat, lng }) {
    return new google.maps.LatLng(lat, lng);
  }

  renderMarker() {
    let { position } = this.props;
    const P1 = this.setLatLng(position[0]);
    const P2 = this.setLatLng(position[1]);

    const lineLength = google.maps.geometry.spherical.computeDistanceBetween(P1, P2);
    const lineHeading = google.maps.geometry.spherical.computeHeading(P1, P2);
    let lineHeading1,
      lineHeading2 = 0;
    if (lineHeading < 0) {
      lineHeading1 = lineHeading + 60;
      lineHeading2 = lineHeading + 120;
    } else {
      lineHeading1 = lineHeading + -60;
      lineHeading2 = lineHeading + -120;
    }
    const pA = google.maps.geometry.spherical.computeOffset(P1, lineLength / 3, lineHeading1);
    const pB = google.maps.geometry.spherical.computeOffset(P2, lineLength / 3, lineHeading2);

    this.marker = this.GmapsCubicBezier(P1, pA, pB, P2, 0.01);
    const evtNames = ["click", "mouseover", "mouseout"];

    evtNames.forEach((e) => {
      this.marker.addListener(e, this.handleEvent(e));
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.map !== prevProps.map ||
      JSON.stringify(this.props.position) !== JSON.stringify(prevProps.position)
    ) {
      this.renderMarker();
    }
  }

  componentDidMount() {
    if (this.props.map && this.props.position) {
      this.renderMarker();
    }
  }

  handleEvent(evt) {
    return (e) => {
      const evtName = `on${camelize(evt)}`;
      if (this.props[evtName]) {
        this.props[evtName](this.props, this.marker, e, evt);
      }
    };
  }

  render() {
    return null;
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }
}
CurveBezierComponent.propTypes = {
  strokeColor: PropTypes.string,
  map: PropTypes.object,
  templateInfo: PropTypes.object,
  position: PropTypes.array,
};

CurveBezierComponent.defaultProps = {
  strokeColor: "#242F42",
};
