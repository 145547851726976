import * as types from "../../actions/actionTypes";

const initialState = [];

function notifications(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_LIST:
      return action.notifications;
    default:
      return state;
  }
}

function getNotificationsSettings(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SETTINGS:
      return action.respones.data;
    default:
      return state;
  }
}

function changeNotifications(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SETTINGS:
      return action.respones.data;
    case types.CHANGE_NOTIFICATIONS_SETTINGS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

function unreadNotification(state = { unread_notifications: 0 }, action) {
  switch (action.type) {
    case types.GET_UNREAD_NOTIFICATIONS_LIST:
      return action.data;
    case types.GET_NOTIFICATIONS_LIST:
      return { unread_notifications: 0 };
    default:
      return state;
  }
}

function unreadNotificationLoaded(state = false, action) {
  switch (action.type) {
    case types.GET_UNREAD_NOTIFICATIONS_LIST:
      return true;
    default:
      return state;
  }
}

export {
  notifications,
  getNotificationsSettings,
  unreadNotification,
  unreadNotificationLoaded,
  changeNotifications,
};
