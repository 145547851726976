import React from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";
import { SharedComponent } from "../shared.component";
import { TabItem } from "./tabItem.component";
import { NavLink } from "react-router-dom";
import Auth from "../../services/auth";
import { connect } from "react-redux";

class TabComponent extends SharedComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
    this.getElement = this.getElement.bind(this);
  }

  componentDidMount() {
    const { items } = this.props;
    this.setState({ items });
    if (this.props.linkType === "tab") this.component = UIkit.tab("#" + this.props.id, {});
    if (
      Auth.getMainRole() === "broker" &&
      (!this.props.company.hasOwnProperty("bundle") ||
        Object.keys(this.props.company.bundle).length === 0)
    ) {
      return this.setState({
        items: items.filter((tab) => tab.path !== "/account/pricing-plan"),
      });
    }
    if (Auth.getRole() === "operator_team") {
      return this.setState({
        items: items.filter(
          (tab) => tab.path !== "/account/banking" && tab.path !== "/account/fleet",
        ),
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      Auth.getMainRole() === "broker" &&
      (!this.props.company.hasOwnProperty("bundle") ||
        Object.keys(this.props.company.bundle).length === 0)
    ) {
      return this.setState({
        items: nextProps.items.filter((tab) => tab.path !== "/account/pricing-plan"),
      });
    }
  }

  click(selectedTab, ev) {
    ev.preventDefault();
    this.props.onClick(selectedTab);
    this.setState((prevState, props) => ({
      items: this.props.items.map((tab) => {
        tab.active = false;
        if (tab.title === selectedTab.title) {
          tab.active = true;
        }
        return tab;
      }),
    }));
  }

  getElement(item, i) {
    if (this.props.linkType === "tab") {
      return (
        <TabItem
          key={i}
          classNames={item.className}
          onItemClick={this.click.bind(this, item)}
          title={item.title}
          isActive={this.props.selected && this.props.selected.alias === item.alias}
        />
      );
    }

    return (
      <li key={i}>
        <NavLink
          to={item.path}
          activeClassName={"uk-active"}
          exact={item.exact}
          isActive={(m, l) => l.pathname.includes(item.alias)}
        >
          {item.title}
        </NavLink>
      </li>
    );
  }

  render() {
    if (this.props.visible) {
      return (
        <ul id={this.props.id} {...this.props.tabProps}>
          {this.state.items.map((item, i) => {
            return this.getElement(item, i);
          })}
        </ul>
      );
    }
    return null;
  }
}

TabComponent.propTypes = {
  tabProps: PropTypes.object,
  visible: PropTypes.bool,
  items: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.any,
  linkType: PropTypes.string,
};

TabComponent.defaultProps = {
  tabProps: {
    className: "app-tab",
  },
  id: "uk-tab",
  linkType: "tab",
  items: [],
  onClick: () => {},
};

const COMPONENT = connect(({ company }) => ({ company }))(TabComponent);
export { COMPONENT as TabComponent };
