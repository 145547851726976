import React from "react";
import { Control, Fieldset } from "react-redux-form";
import { updateModel } from "../../../shared";
import { config } from "../../../configs";
import staticService from "../../../services/static.service";

const LegExtraFieldset = ({ model, visible, disabled }) => {
  if (visible) {
    return (
      <Fieldset model={model} data-uk-grid>
        <div className="uk-width-1-1@s uk-width-1-2@m">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("helicopterType"),
            }}
          />
          <Control.select
            model=".preferences"
            defaultValue={undefined}
            className={"uk-select"}
            changeAction={updateModel}
            updateOn="change"
            disabled={disabled}
          >
            {config.aircraftPreferences.map((preference, i) => (
              <option key={i} value={preference.id}>
                {preference.title}
              </option>
            ))}
          </Control.select>
        </div>

        <div className="uk-width-1-1@s uk-width-1-2@m">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("CrewRequirements"),
            }}
          />
          <Control.select
            model=".crew_size"
            defaultValue={undefined}
            className={"uk-select"}
            changeAction={updateModel}
            updateOn="change"
            disabled={disabled}
          >
            {config.pilotPreferences.map((req, i) => (
              <option key={i} value={req.id}>
                {req.title}
              </option>
            ))}
          </Control.select>
        </div>
      </Fieldset>
    );
  }
  return null;
};

export { LegExtraFieldset };
