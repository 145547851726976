import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";
import condStrings from "../../../../utils/condString";

const DestinationInformationContent = (props) => {
  const { event, handleOpenModal } = props;
  const [isShowMoreActive, setIsShowMoreActive] = React.useState(null);

  React.useEffect(() => {
    const eventContentHeight = document.getElementById("destinations-content-block")?.scrollHeight;

    if (eventContentHeight > 1070 && isShowMoreActive === null) {
      setIsShowMoreActive(true);
    }
  }, [event]);

  const handleShowMore = (e) => {
    e.preventDefault();
    setIsShowMoreActive(false);
  };

  return (
    <div
      id="destinations-content-block"
      className={condStrings(
        "uk-background-default gh-padding-normal gh-padding-bottom gh-event-info-content",
        isShowMoreActive && "show-more",
      )}
    >
      <div className="gh-padding-small-bottom">
        <h1 className="uk-width-1-1 gh-text-slim uk-margin-remove gh-single-title">{event.name}</h1>
        <div className="uk-width-1-1 gh-city-event-title">{event.locations_string_all}</div>
      </div>
      {event.occurrence !== null ? (
        <div className="uk-margin-small-bottom">
          <div
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(event.occurrence),
            }}
          />
        </div>
      ) : (
        ""
      )}

      <div className="gh-grid-margin-small-top uk-hidden@l">
        <button
          className="uk-width-1-1 uk-width-1-3@s uk-button uk-button-primary gh-open-enquire-btn"
          onClick={handleOpenModal}
        >
          {staticService.findByAlias("priceEstimateBtnLabel")}
        </button>
      </div>

      {event.start_month ? (
        <div>
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-6@s">
              <div className="gh-title-events">Peak Season:</div>
            </div>
            <div className="uk-width-1-1 uk-width-5-6@s gh-grid-small-text-margin">
              <div className="gh-event-dates-inside">
                <span className="gh-event-big-text" style={{ marginRight: 5 }}>
                  {event.start_month || ""}
                </span>
                <span className="gh-event-big-text">
                  {event.end_month ? <span>to {event.end_month}</span> : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <hr />

      <div className="gh-grid-margin-normal-top">
        {event.destination_introduction ? (
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.destination_introduction,
            }}
          />
        ) : (
          ""
        )}
      </div>

      <div className="gh-grid-margin-normal-top">
        <h2
          className="gh-title-events gh-padding-small-bottom"
          dangerouslySetInnerHTML={{
            __html: event.description_heading || staticService.findByAlias("eventDescription"),
          }}
        />
        <div
          className="gh-event-small-text gh-grid-margin-small-bottom"
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
        {event.description && event.btn_label !== null ? (
          <a
            className="gh-button-link uk-button uk-button-default"
            target="_blank"
            rel="noopener noreferrer"
            href={event.btn_url}
          >
            {event.btn_label}
          </a>
        ) : (
          ""
        )}
      </div>

      {event.helicopter_relevance_description !== null ? (
        <div className="gh-grid-margin-normal-top">
          <h2
            className="gh-padding-small-bottom gh-title-events"
            dangerouslySetInnerHTML={{
              __html:
                event.helicopter_relevance_heading || staticService.findByAlias("helicoptersTitle"),
            }}
          />
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.helicopter_relevance_description,
            }}
          />
          {event.helicopter_relevance_btn_label && (
            <a
              className="gh-button-link uk-button uk-button-default"
              target="_blank"
              rel="noopener noreferrer"
              href={event.helicopter_relevance_btn_url}
            >
              {event.helicopter_relevance_btn_label}
            </a>
          )}
        </div>
      ) : (
        ""
      )}
      {event.landing_information_content !== null ? (
        <div className="gh-grid-margin-normal-top">
          <h2
            className="gh-padding-small-bottom gh-title-events"
            dangerouslySetInnerHTML={{
              __html:
                event.landing_information_heading ||
                staticService.findByAlias("landingInformation"),
            }}
          />
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.landing_information_content,
            }}
          />
          {event.landing_information_btn_label && (
            <a
              className="gh-button-link uk-button uk-button-default"
              target="_blank"
              rel="noopener noreferrer"
              href={event.landing_information_btn_url}
            >
              {event.landing_information_btn_label}
            </a>
          )}
        </div>
      ) : (
        ""
      )}
      {event.optional_heading !== null ? (
        <div className="gh-grid-margin-normal-top">
          <h2
            className="gh-padding-small-bottom gh-title-events"
            dangerouslySetInnerHTML={{
              __html: event.optional_heading,
            }}
          />
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.optional_text,
            }}
          />
          {event.optional_text &&
          event.optional_heading &&
          event.optional_text_btn_label !== null ? (
            <a
              className="gh-button-link uk-button uk-button-default"
              target="_blank"
              rel="noopener noreferrer"
              href={event.optional_text_btn_url}
            >
              {event.optional_text_btn_label}
            </a>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div className="gh-grid-margin-small-top uk-hidden@l">
        <button
          className="uk-width-1-1 uk-width-1-3@s uk-button uk-button-primary gh-open-enquire-btn"
          onClick={handleOpenModal}
        >
          {staticService.findByAlias("priceEstimateBtnLabel")}
        </button>
      </div>

      {isShowMoreActive && (
        <a className="gh-event-show-more-btn" onClick={handleShowMore}>
          Show More
        </a>
      )}
    </div>
  );
};

DestinationInformationContent.propTypes = {
  event: PropTypes.object,
  handleOpenModal: PropTypes.func,
};

DestinationInformationContent.defaultProps = {
  event: {},
};

export default DestinationInformationContent;
