import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SubMenuEventItem from "./SubMenuEventItem";
import SubMenuDestinationItem from "./SubMenuDestinationItem";
import uid from "../../../../utils/uid";
import staticService from "../../../../services/static.service";
import { NavLink } from "react-router-dom";
import { DESTINATION_MENU, EVENTS_MENU, GETHELI_BUSINESSES_MENU } from "../PublicMobileMenu";
import link from "../../../../assets/img/external-link-green.svg";

const SecondStep = (props) => {
  const { menuItems, selectedCategory } = props;

  if (!menuItems || !selectedCategory) return <div>Loading ...</div>;
  return (
    <ul className="gh-side-menu second-step">
      {selectedCategory === DESTINATION_MENU
        ? menuItems?.navbar?.destinations.map((item) => (
            <SubMenuDestinationItem item={item} key={item.name} />
          ))
        : ""}

      {selectedCategory === EVENTS_MENU
        ? Object.keys(menuItems?.navbar?.events).map((key) => (
            <SubMenuEventItem item={menuItems?.navbar?.events[key]} key={uid().uid} name={key} />
          ))
        : ""}

      {selectedCategory === GETHELI_BUSINESSES_MENU ? (
        <ul className="gh-subnav-menu --separate">
          <li className="header-desktop-menu-item">
            <a href="/pricingcalculator" className="link">
              {staticService.findByAlias("theGetHeliApiMenu")}
            </a>
          </li>
          <li className="header-desktop-menu-item">
            <a href="/helicoptermarketplace" className="link">
              {staticService.findByAlias("theGetHeliMarketplaceMenu")}
            </a>
          </li>
          <li className="header-desktop-menu-item">
            <NavLink to="/account/login" className="link">
              {staticService.findByAlias("resetPasswordRedirect")}
            </NavLink>
          </li>
        </ul>
      ) : (
        ""
      )}

      {selectedCategory === DESTINATION_MENU ? (
        <li className="header-desktop-menu-item --redirect">
          <a href="/destinations" className="link">
            {staticService.findByAlias("seeAllButton")}
            <img className="gh-arrow-open-menu" src={link} alt="next" />
          </a>
        </li>
      ) : (
        ""
      )}

      {selectedCategory === EVENTS_MENU ? (
        <li className="header-desktop-menu-item --redirect">
          <a href="/events" className="link">
            {staticService.findByAlias("seeAllButton")}
            <img className="gh-arrow-open-menu" src={link} alt="next" />
          </a>
        </li>
      ) : (
        ""
      )}
    </ul>
  );
};

SecondStep.propTypes = {
  menuItems: PropTypes.object,
  selectedCategory: PropTypes.string,
};

const mapStateToProps = (store) => ({
  menuItems: store.menuItems,
});

export default connect(mapStateToProps)(SecondStep);
