import React from "react";
import PropTypes from "prop-types";
import { BlockComponent } from "../../../common";
import { checkObjectProp } from "../../../../shared";
import staticService from "../../../../services/static.service";
import SiteComponent from "../../../common/SiteComponent";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";
import Time from "../../../common/Time";

const EmptyLegDetailComponent = ({ leg, visible = false }) => {
  return (
    <BlockComponent
      visible={visible}
      parentProps={{
        className: "uk-section uk-padding-remove-top uk-padding-remove-bottom empty-leg-detail",
      }}
    >
      {leg && Object.keys(leg).length ? (
        <div className="uk-primary">
          <h3 dangerouslySetInnerHTML={{ __html: staticService.findByAlias("EmptyLegDetail") }} />

          <div className="uk-margin-remove quote-request-detail-wrapper gh-mob-padding">
            <div data-uk-grid className="uk-margin">
              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-text"
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("from") }}
                />
                <SiteComponent
                  icon={leg.from_source}
                  name={leg.from_location && leg.from_location.name}
                  secondaryText={generateLocationAdditionalInfo(leg.from_location)}
                  className="gh-quote-details-flight-site-component gh-payment-leg-row"
                />
              </div>
              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-text"
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("to") }}
                />
                <SiteComponent
                  icon={leg.to_source}
                  name={leg.to_location && leg.to_location.name}
                  secondaryText={generateLocationAdditionalInfo(leg.to_location)}
                  className="gh-quote-details-flight-site-component gh-payment-leg-row"
                />
              </div>
            </div>
            <div data-uk-grid className="uk-margin">
              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-text"
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("date") }}
                />
                <div className="gh-info-text uk-margin-small">
                  <Time timestamp={leg.date} />
                </div>
              </div>
              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-text"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("windowAvailability"),
                  }}
                />
                <div className="gh-info-text uk-margin-small">
                  <Time timestamp={leg.time_interval?.[0]} showType="timeUtc" /> -{" "}
                  <Time timestamp={leg.time_interval?.[1]} showType="timeUtc" />
                </div>
              </div>
              <div className="uk-width-1-1@s uk-width-1-3@m uk-margin-top">
                <label
                  className="uk-form-label"
                  htmlFor="form-stacked-text"
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("helicopter") }}
                />
                <div className="gh-info-text uk-margin-small">
                  {checkObjectProp(leg.aircraft, "registration_number", null)}, {""}
                  {checkObjectProp(leg.aircraft, "manufacturer", null)} {""}
                  {checkObjectProp(leg.aircraft, "aircraft_model", null)}, {""}(
                  {staticService.findByAlias("maxPassengersLabel")}{" "}
                  {checkObjectProp(leg.aircraft, "max_passengers", null)})
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </BlockComponent>
  );
};

EmptyLegDetailComponent.propTypes = {
  leg: PropTypes.object.isRequired,
  visible: PropTypes.bool,
};
export { EmptyLegDetailComponent };
