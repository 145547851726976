import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { AccountLayout } from "../../layouts";
import { filteredPages } from "../../../shared";
import { RecoverPasswordFormComponent } from "../../";
import { pages } from "../../../configs";
import { checkHash } from "../../../actions";
import { BlockComponent } from "../../common/";
import staticService from "../../../services/static.service";

class RecoverPasswordHashComponent extends React.Component {
  constructor(props, context) {
    super(props);
    context.store.dispatch(checkHash(this.props.match.params.hash));
    this.state = {
      loader: true,
      showForm: false,
      redirectPath: "/",
    };
  }

  get alert() {
    if (this.props.updated) {
      return (
        <div className={"gh-recover-pass uk-margin-large-top"}>
          <h3 dangerouslySetInnerHTML={{ __html: staticService.findByAlias("emailSent") }}></h3>
          <p
            className={"uk-margin-large-top"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("emailSentMessage") }}
          ></p>
          <div className={"uk-margin-large-top uk-text-center"}>
            <NavLink className={"uk-button uk-button-primary"} to={"/account/login"}>
              {staticService.findByAlias("resetPasswordRedirect")}
            </NavLink>
          </div>
        </div>
      );
    }

    if (this.props.isHashValid) {
      return (
        <div className="uk-alert-danger" data-uk-alert>
          <a className="uk-alert-close" data-uk-close></a>
          <p dangerouslySetInnerHTML={{ __html: staticService.findByAlias("hashExpired") }}></p>
        </div>
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loader: false });

    if (this.props.showForm !== nextProps.showForm && nextProps.showForm) {
      this.setState({ showForm: true });
    }

    if (this.props.updated !== nextProps.updated) {
      this.setState({
        showForm: false,
      });
    }
  }

  render() {
    return (
      <AccountLayout
        activeRoute={this.props.match}
        pageTitle={this.props.title}
        pages={filteredPages(pages)}
      >
        <section className={"recover-password-page"}>
          <BlockComponent
            visible={true}
            containerClass={"uk-container uk-container-affiliate"}
            parentProps={{ className: "uk-section uk-padding-remove-bottom" }}
          >
            {this.alert}
          </BlockComponent>
          <BlockComponent
            visible={true}
            containerClass={"uk-section uk-padding-remove-top gh-section-statistics"}
            parentProps={{ className: "uk-container uk-container-affiliate" }}
          >
            <RecoverPasswordFormComponent
              requestHash={this.props.match.params.hash}
              visible={this.state.showForm}
            />
          </BlockComponent>
        </section>
      </AccountLayout>
    );
  }
}

RecoverPasswordHashComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  const response = {
    isHashValid: state.errorObj && state.errorObj.failedHash ? true : false,
    loader: state.auth.hashCheck ? true : false,
    showForm: state.auth.hashCheck ? true : false,
    updated: state.auth.updatePassword ? true : false,
  };
  return response;
}

const COMPONENT = connect(mapStateToProps)(RecoverPasswordHashComponent);
export { COMPONENT as RecoverPasswordHashComponent };
