import moment from "moment";

/**
 * transformPickMonth - transform date to first day of month 12:00, return ISO string
 * @param {string} date
 * return {string} - date in iso format
 * */
export const transformPickMonth = (date) => {
  return moment(date).set({ date: 1, hour: 12, minute: 0 }).format();
};

export const clearInvalidDate = (date) => (date === "Invalid date" ? null : date);
