import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextMessage from "../../text-message";
import MediaMessage from "../../media-message";
import staticService from "../../../../services/static.service";
import { connect } from "react-redux";
import { FeedbackFormComponent } from "../../../forms";
import { addDiscussionMessage, markAssSeenQuotesComments } from "../../../../actions";
import { actions } from "react-redux-form";
import { bindActionCreators } from "redux";
import auth from "../../../../services/auth";

const LiveChatContent = (props) => {
  const {
    quoteDiscussion,
    openPreviewFile,
    feedbackModel,
    addDiscussionMessage,
    resetModel,
    feedbackFormError,
    quoteId,
    chatIsOpen,
    markAssSeenQuotesComments,
    userRole,
  } = props;

  const [prevFile, setPrevFile] = useState({});
  const [quoteDiscussionCount, setQuoteDiscussionCount] = useState(0);

  useEffect(() => {
    if (quoteDiscussion && quoteDiscussion.length > quoteDiscussionCount && chatIsOpen) {
      const discussionContainer = document.getElementById(`gh-discussion-wrapper-${quoteId}`);
      if (discussionContainer) {
        setTimeout(() => {
          discussionContainer.scrollTop = discussionContainer.scrollHeight;
        }, 500);
      }

      markAssSeenQuotesComments(quoteId);
      setQuoteDiscussionCount(quoteDiscussion.length);
    }
  }, [quoteDiscussion, chatIsOpen]);

  const atLeastOneSystem = () => {
    return quoteDiscussion && quoteDiscussion.filter((data) => data.is_system === 0).length;
  };

  const sendDiscussionMessage = (model) => {
    addDiscussionMessage(quoteId, model);
    resetModel("feedbackModel");
  };

  const sendMessage = (model) => {
    if (model.message) {
      return sendDiscussionMessage(model);
    }

    if (prevFile.file) {
      const formData = new FormData();
      formData.append("file", prevFile.file);
      setPrevFile({});

      return sendDiscussionMessage({ file: formData });
    }
  };

  const showPrev = (e) => {
    const file = e.target.files[0];
    setPrevFile({ file: file, prev_url: URL.createObjectURL(file) });
  };

  const removePrevFile = () => {
    setPrevFile({});
  };

  const seenBy = useCallback(
    (data) =>
      data.user_type.toLowerCase().includes(userRole) && data.is_seen
        ? "operator" === userRole
          ? "Broker"
          : "Operator"
        : "",
    [userRole],
  );

  const isSecondRole = useCallback(
    (data) => !data.user_type.toLowerCase().includes(userRole),
    [userRole],
  );

  return (
    <div>
      <div
        className={`gh-discussion-container ${
          quoteDiscussion && quoteDiscussion.length === 0 ? "gh-no-messages" : ""
        }`}
        id={`gh-discussion-wrapper-${quoteId}`}
      >
        {quoteDiscussion &&
          quoteDiscussion.map((data) => {
            return (
              <div
                key={data.id}
                className={`gh-message-body ${isSecondRole(data) ? "second-role-body" : ""}`}
              >
                {data.body || (data.file && data.file.file_type === "file") ? (
                  <TextMessage
                    openModal={openPreviewFile}
                    data={data}
                    seenBy={seenBy(data)}
                    isSecondRole={isSecondRole(data)}
                  />
                ) : (
                  <MediaMessage
                    openModal={openPreviewFile}
                    file={data.file}
                    data={data}
                    seenBy={seenBy(data)}
                  />
                )}
              </div>
            );
          })}
        {quoteDiscussion && (quoteDiscussion.length === 0 || !atLeastOneSystem()) ? (
          <div className="gh-bubble-message-container gh-first-message">
            <div className="gh-bubble-message">
              <div className="gh-system-role">
                {staticService.findByAlias("getHeliSystemLabel")}
              </div>
              <div>
                {staticService.findByAlias("brokerFirstMessageToAdd") +
                  `${auth.getMainRole() === "broker" ? " Operator" : " Broker"}`}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="uk-margin-small">
        <FeedbackFormComponent
          className="gh-live-chat-feedback-form"
          modelName="feedbackModel"
          modelField={".message"}
          label={""}
          visible={true}
          action={sendMessage}
          form={feedbackModel}
          fieldError={feedbackFormError}
          discussionStart={false}
          role={auth.getMainRole()}
          sendAttach={showPrev}
          removePrevFile={removePrevFile}
          prevFile={prevFile}
          chatModal={true}
          chatIsOpen={chatIsOpen}
        />
      </div>
    </div>
  );
};

LiveChatContent.propTypes = {
  quoteDiscussion: PropTypes.array,
  openPreviewFile: PropTypes.func,
  feedbackModel: PropTypes.object,
  addDiscussionMessage: PropTypes.func,
  resetModel: PropTypes.func,
  feedbackFormError: PropTypes.string,
  quoteId: PropTypes.any,
  chatIsOpen: PropTypes.bool,
  markAssSeenQuotesComments: PropTypes.func,
  userRole: PropTypes.oneOf(["operator", "broker"]).isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addDiscussionMessage,
      markAssSeenQuotesComments,
      resetModel: (modelName) => dispatch(actions.reset(modelName)),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  quoteDiscussion: state.quoteDiscussion && state.quoteDiscussion.data,
  feedbackModel: state.feedbackModel,
  feedbackFormError:
    state.errorObj &&
    state.errorObj.errors &&
    state.errorObj.errors.body &&
    state.errorObj.errors.body[0],
  ...state.screenDimensions,
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatContent);
