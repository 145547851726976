import React from "react";
import PropTypes from "prop-types";
import { Control, Fieldset } from "react-redux-form";
import { InlineNumberComponent } from "../../../shared";
import staticService from "../../../services/static.service";
import { config } from "../../../configs";
import capitalizeString from "../../../utils/capitalizeString";

export const InlineNumberFieldset = ({
  model: formModel,
  visible,
  modelSection,
  legIndex,
  disabled,
  items,
  capitalizeTitles,
  fillWithTheSameInfoNextLeg,
  maxPassengers,
  total,
}) => {
  const getMaxPassengers = (name) => {
    if (modelSection === "passengers") {
      if (maxPassengers && total) {
        const { men = 0, children = 0, women = 0 } = total;
        switch (name) {
          case "children":
            return maxPassengers - men - women;
          case "men":
            return maxPassengers - children - women;
          case "women":
            return maxPassengers - children - men;
        }
      }
    }

    return config.allowed_passengers;
  };

  if (!visible) {
    return null;
  }

  return (
    <Fieldset model={formModel} className="gh-inline-numbers">
      {items.map((item, index) => (
        <div
          key={formModel + item.key}
          className={`gh-number-field ${items.length - 1 === index ? "uk-margin-remove" : ""}`}
        >
          <Control
            model={formModel + `.${item.key}`}
            className={"uk-input"}
            component={InlineNumberComponent}
            changeAction={(model, value) =>
              fillWithTheSameInfoNextLeg(model, value, legIndex, modelSection)
            }
            disabled={disabled}
            step={1}
            label={
              (capitalizeTitles && capitalizeString(staticService.findByAlias(item.title))) ||
              staticService.findByAlias(item.title)
            }
            required={false}
            max={getMaxPassengers(item.key)}
            min={0}
          />
        </div>
      ))}
    </Fieldset>
  );
};

InlineNumberFieldset.defaultProps = {
  capitalizeTitles: false,
};

InlineNumberFieldset.propTypes = {
  capitalizeTitles: PropTypes.bool,
};
