import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import "./QuotePriceSection.css";
import staticService from "../../../../../services/static.service";
import Tooltip from "../../../../common/Tooltip";
import Auth from "../../../../../services/auth";
import QuotePriceProcessingFees from "../QuotePriceProcessingFees";
import {
  BOOKING_INIT,
  EUROPEAN_FEE,
  INTERNATIONAL_FEE,
  NO_FEE,
  validationRules,
} from "../../../../../configs";
import QuoteDepositDetails from "./QuoteDepositDetails";
import QuotePriceRepositioning from "../../../../common/QuotePrice/quotePriceRepositioning";
import QuotePriceVatRow from "./QuotePriceVatRow";
import { formatNumber } from "../../../../../shared";

const { isPrice } = validationRules;

const returnRefundableFeeType = (key) => {
  const types = {
    0: "No",
    1: "Yes",
    2: "Weather Only",
  };
  return types[key];
};

const QuotePriceSection = (props) => {
  const {
    currency,
    bookingStatus,
    calculatedPrices,
    payment,
    renderBy,
    aliasTitle,
    history,
    feeSelected,
    setFeeSelected,
    bookingDetail,
    isOperatorStripeVerified,
    quotePrice,
  } = props;
  //todo: remove renderBy and replace it with auth role, because renderBy values is based on auth role

  const [isWithoutGetHeliFees, setIsWithoutGetHeliFees] = useState(true);
  const [calculatedPrice, setCalculatedPrice] = useState({});

  const isBroker = useMemo(() => Auth.isBroker(), [Auth.getMainRole()]);

  useEffect(() => {
    if (history && feeSelected) {
      history.replace({
        pathname: history.location.pathname,
        search: `?payment_method=${feeSelected}`,
      });
    }
  }, [history, feeSelected]);

  useEffect(() => {
    if (bookingStatus === BOOKING_INIT || renderBy === "BrokerBookingDetails") {
      if (payment.payment_method === 2) {
        setCalculatedPrice(calculatedPrices[NO_FEE]);
        if (setFeeSelected) setFeeSelected(NO_FEE);
      } else {
        let selectedCard = payment.card_type === 1 ? INTERNATIONAL_FEE : EUROPEAN_FEE;
        if (setFeeSelected) setFeeSelected(selectedCard);
        setCalculatedPrice(calculatedPrices[selectedCard]);
        setIsWithoutGetHeliFees(false);
      }
    } else {
      const prices = calculatedPrices[feeSelected] || calculatedPrices[EUROPEAN_FEE];
      setCalculatedPrice(prices);
      setIsWithoutGetHeliFees(feeSelected === NO_FEE);
    }
  }, [bookingStatus, calculatedPrices[feeSelected]?.deposit_amount, feeSelected]);

  const handleSelectFee = (event) => {
    const value = typeof event === "string" ? event : event.currentTarget.value;

    if (setFeeSelected) setFeeSelected(value);
    setCalculatedPrice(calculatedPrices[value]);
    if (value === NO_FEE) {
      setIsWithoutGetHeliFees(true);
    } else {
      setIsWithoutGetHeliFees(false);
    }
  };

  return (
    <section className="gh-quote-price gh-quote-details-section-card">
      <h4 className="gh-quote-details-title gh-quote-details-title-bold gh-quote-details-quote-price-title">
        {staticService
          .findByAlias(aliasTitle ? aliasTitle : "quotePriceTitle")
          .toString()
          .toUpperCase()}
      </h4>
      <div className="gh-quote-details-details-row gh-quote-details-quote-price-details-row">
        <div className="gh-quote-details-text-large gh-quote-details-total-price-label gh-quote-details-value">
          {staticService.findByAlias("totalBroker")}
        </div>
        <div className="gh-quote-detail-total-price-wrapper gh-quote-details-total-price-value uk-flex-middle gh-quote-details-value">
          <span className="gh-quote-details-price-total gh-quote-details-text-large">
            {currency}{" "}
            {isBroker && renderBy === "QuoteDetails"
              ? calculatedPrice.total_without_vat
              : calculatedPrice.total}
          </span>
          {isBroker && renderBy !== "BrokerBookingDetails" ? (
            <Tooltip
              pos="top-left"
              refEl={"self"}
              wrapperClassName="gh-quote-details-tooltip-margin-left"
            >
              {staticService.findByAlias("tooltipPriceWillVary")}
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      </div>

      {isBroker && (
        <QuotePriceVatRow
          vat={calculatedPrice.vat || ""}
          currency={currency}
          title={staticService.findByAlias(
            renderBy === "QuoteDetails" ? "excludingTitle" : "includingTitle",
          )}
        />
      )}

      <QuotePriceVatRow
        vat={calculatedPrice.vat || ""}
        currency={currency}
        title={staticService.findByAlias("includingTitle")}
        show={!isBroker}
      />
      <div className="gh-quote-details-details-row gh-quote-details-quote-price-details-row">
        <div className="gh-quote-details-text-normal gh-quote-details-value">
          {staticService.findByAlias("includingTitle")}
        </div>
        <div className="gh-quote-details-text-normal gh-quote-details-value">
          <span className="gh-quote-details-title-bold" style={{ marginRight: 5 }}>
            {currency} {formatNumber(calculatedPrice.landing_fees)}
          </span>
          {staticService.findByAlias("ofLandingFeesTitle")}
        </div>
      </div>
      <div className="gh-quote-detail-additional-fees">
        <div className="gh-quote-details-additional-fees-row gh-quote-details-details-row">
          <div className="gh-quote-details-text-normal gh-quote-details-value">
            {staticService.findByAlias("allLandingFees")}
          </div>
          <div className="gh-quote-details-text-normal gh-quote-details-title-bold gh-quote-details-value">
            {calculatedPrice.are_landing_fees_included ? "Yes" : "No"}
          </div>
        </div>
        {!calculatedPrice.are_landing_fees_included && (
          <div className="gh-quote-details-additional-fees-row gh-quote-details-details-row">
            <div className="gh-quote-details-text-normal gh-quote-details-text-with-tooltip gh-quote-details-value">
              {staticService.findByAlias("estimateFeesTitle")}
            </div>
            <div className="gh-quote-details-text-normal gh-quote-details-title-bold gh-quote-details-additional-landing-fees gh-quote-details-value">
              {currency} {calculatedPrice.additional_landing_fees}
              {!isBroker && (
                <Tooltip
                  pos="top-left"
                  refEl={"self"}
                  wrapperClassName="gh-quote-details-tooltip-margin-left"
                >
                  {staticService.findByAlias("onlyForInformationPurposes")}
                </Tooltip>
              )}
            </div>
          </div>
        )}
        <div className="gh-quote-details-additional-fees-row gh-quote-details-details-row">
          <div className="gh-quote-details-text-normal gh-quote-details-value">
            {staticService.findByAlias("landingFeesRefundable")}
          </div>
          <div className="gh-quote-details-text-normal gh-quote-details-title-bold gh-quote-details-value">
            {returnRefundableFeeType(calculatedPrice.are_additional_landing_fees_refundable)}
          </div>
        </div>
      </div>
      {isBroker && (
        <>
          <div className="gh-quote-details-details-row gh-quote-details-quote-price-details-row">
            <div className="gh-quote-details-text-normal gh-quote-details-value">
              {staticService.findByAlias("includingTitle")}
            </div>
            <div className="gh-quote-details-text-normal gh-quote-details-value">
              <span className="gh-quote-details-title-bold" style={{ marginRight: 5 }}>
                {currency} {calculatedPrice.get_heli_fees}
              </span>
              {staticService.findByAlias("ofGetHeliFees")}
            </div>
          </div>
          <QuotePriceProcessingFees
            currency={currency}
            stripeFees={calculatedPrice.stripe_fees}
            setIsWithoutGetHeliFees={setIsWithoutGetHeliFees}
            bookingStatus={bookingStatus}
            calculatedPrice={calculatedPrice}
            handleSelectFee={handleSelectFee}
            feeSelected={feeSelected}
            renderBy={renderBy}
            isOperatorStripeVerified={isOperatorStripeVerified}
          />
        </>
      )}
      <QuoteDepositDetails
        bookingDetail={bookingDetail}
        bookingStatus={bookingStatus}
        calculatedPrice={calculatedPrice}
        currency={currency}
      />
      {/*is_custom_quote_price*/}
      {quotePrice.repositioning_cost && quotePrice.repositioning_cost !== "0.00" ? (
        <QuotePriceRepositioning percentage={quotePrice.repositioning_cost} />
      ) : (
        ""
      )}
    </section>
  );
};

QuotePriceSection.defaultProps = {
  quotePrice: {},
};

QuotePriceSection.propTypes = {
  currency: PropTypes.string,
  bookingStatus: PropTypes.string,
  calculatedPrices: PropTypes.object,
  payment: PropTypes.object,
  renderBy: PropTypes.string,
  aliasTitle: PropTypes.string,
  history: PropTypes.object,
  feeSelected: PropTypes.string,
  setFeeSelected: PropTypes.func,
  bookingDetail: PropTypes.object,
  isOperatorStripeVerified: PropTypes.bool,
  quotePrice: PropTypes.object,
};

export default React.memo(QuotePriceSection);
