import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import staticService from "../../../../../services/static.service";
import DeclineButtonComponent from "../declineButtonComponent";
import LiveChatComponent from "../../../../common/LiveChatComponent";
import Auth from "../../../../../services/auth";
import DownloadPDFModal from "../../../../common/DownloadPDFModal";
import isAllowForEditing from "../../../../../utils/isAllowForEditing";
import { showNotification } from "../../../../../utils/showNotification";
import { RESTRICT_ERROR_MESSAGE } from "../../../../../configs";
import auth from "../../../../../services/auth";

const QuoteManageButtons = (props) => {
  const { quoteDetail, quoteID, requestId, history } = props;
  const downloadPDFModalRef = React.createRef();
  const isManualTransferAvailable = auth.hasFeature("manual_bank_transfer");

  const [isAbilityToEdit, setIsAbilityToEdit] = useState(false);
  useEffect(() => {
    if (quoteDetail.booking_request) {
      setIsAbilityToEdit(quoteDetail.booking_request.ability_to_edit);
    }
  }, [quoteDetail]);

  const handleOpenDownloadPDFModal = () => {
    if (!isAllowForEditing()) {
      showNotification(RESTRICT_ERROR_MESSAGE, "danger");
    } else {
      downloadPDFModalRef.current.open();
    }
  };

  const paymentMethodsAreNotAvailable =
    !isManualTransferAvailable && quoteDetail.operator_stripe_verification_status === false;
  const isBookingButtonDisabled = !isAbilityToEdit || paymentMethodsAreNotAvailable;

  return (
    <div className="gh-quote-details-buttons">
      {quoteDetail.status === "open" ? (
        <div className="gh-quote-details-buttons-col">
          {!isBookingButtonDisabled ? (
            <NavLink
              onClick={(e) => {
                if (!isAllowForEditing()) {
                  e.preventDefault();
                  return showNotification(RESTRICT_ERROR_MESSAGE, "danger");
                }
                quoteDetail && quoteDetail.status === "booking_init" && e.preventDefault();
              }}
              className={`uk-button uk-button-default gh-quote-button gh-full-quote-btn ${
                quoteDetail.status && quoteDetail.status === "booking_init"
                  ? "gh-book-btn-disabled"
                  : "gh-book-btn"
              }`}
              to={`/flights/requests/${requestId}/payment/${quoteDetail.id}/step/2${history.location.search}`}
            >
              {staticService.findByAlias("bookButton")}
            </NavLink>
          ) : (
            <button
              disabled={true}
              className={`uk-button uk-button-danger gh-decline-btn gh-quote-button uk-padding-remove gh-full-quote-btn gh-decline-full`}
              onClick={() => {}}
            >
              {staticService.findByAlias("bookButton")}
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {quoteDetail.status === "open" || quoteDetail.status === "booking_init" ? (
        <div className="gh-quote-details-buttons-col">
          <DeclineButtonComponent
            disabled={!isAbilityToEdit}
            quote={quoteDetail}
            requestId={requestId}
            history={history}
          />
        </div>
      ) : (
        ""
      )}

      <div className="gh-quote-details-buttons-col">
        <LiveChatComponent
          quoteId={quoteID}
          disabled={!isAbilityToEdit}
          commentsCount={quoteDetail.comments_count}
          titleAlias="quoteDiscussionBroker"
        />
      </div>

      <div className="gh-quote-details-buttons-col">
        <button
          disabled={!isAbilityToEdit}
          className="uk-button uk-button-secondary uk-padding-remove gh-discussion-btn gh-full-quote-btn gh-quote-download-btn"
          onClick={handleOpenDownloadPDFModal}
          {...Auth.getFeatureStatus("quote_to_pdf")}
        >
          <svg
            height="512pt"
            viewBox="0 0 512 512"
            width="512pt"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m256 362.667969c-8.832031 0-16-7.167969-16-16v-330.667969c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v330.667969c0 8.832031-7.167969 16-16 16zm0 0" />
            <path d="m256 362.667969c-4.097656 0-8.191406-1.558594-11.308594-4.695313l-85.332031-85.332031c-6.25-6.25-6.25-16.382813 0-22.636719 6.25-6.25 16.382813-6.25 22.636719 0l74.023437 74.027344 74.027344-74.027344c6.25-6.25 16.386719-6.25 22.636719 0 6.25 6.253906 6.25 16.386719 0 22.636719l-85.335938 85.332031c-3.15625 3.136719-7.25 4.695313-11.347656 4.695313zm0 0" />
            <path d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 32.363281-26.304688 58.667969-58.667969 58.667969zm0 0" />
          </svg>
          {staticService.findByAlias("downloadAsPDF")}
        </button>
      </div>
      <DownloadPDFModal
        quoteDetail={quoteDetail}
        modalRef={downloadPDFModalRef}
        quoteId={quoteID}
      />
    </div>
  );
};
QuoteManageButtons.propTypes = {
  quoteDetail: PropTypes.object,
  quoteID: PropTypes.string,
  requestId: PropTypes.string,
  history: PropTypes.object,
  brokerIsAllowedToEdit: PropTypes.bool,
};
export default QuoteManageButtons;
