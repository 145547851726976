import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const NotificationIcon = ({ visible, notifications, path = "/notifications" }) => {
  return visible ? (
    <NavLink className="uk-position-relative" to={path}>
      <span className="gh-notifications-icon">
        <svg
          width="23"
          height="27"
          viewBox="0 0 23 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7143 4.67856C8.12444 4.67856 5.21429 7.58871 5.21429 11.1786V16.5357C5.21429 17.348 4.98449 18.1065 4.58637 18.75H18.8422C18.4441 18.1065 18.2143 17.348 18.2143 16.5357V11.1786C18.2143 7.58871 15.3041 4.67856 11.7143 4.67856ZM22.4286 18.75C21.2057 18.75 20.2143 17.7586 20.2143 16.5357V11.1786C20.2143 6.48414 16.4087 2.67856 11.7143 2.67856C7.01987 2.67856 3.21429 6.48414 3.21429 11.1786V16.5357C3.21429 17.7586 2.22292 18.75 1 18.75C0.447715 18.75 0 19.1977 0 19.75C0 20.3023 0.447715 20.75 1 20.75H22.4286V18.75ZM9.06203 23.7152C9.24053 23.4053 9.57094 23.2143 9.92857 23.2143H13.6357C13.9933 23.2143 14.3238 23.4053 14.5023 23.7152C14.6807 24.0251 14.6802 24.4067 14.5007 24.716C13.9385 25.6853 12.9027 26.2819 11.7821 26.2819C10.6616 26.2819 9.62582 25.6853 9.06357 24.716C8.88412 24.4067 8.88354 24.0251 9.06203 23.7152ZM9.06203 2.57053C9.24053 2.88043 9.57094 3.07142 9.92857 3.07142H13.6357C13.9933 3.07142 14.3238 2.88043 14.5023 2.57053C14.6807 2.26063 14.6802 1.879 14.5007 1.56964C13.9385 0.60038 12.9027 0.00378418 11.7821 0.00378418C10.6616 0.00378418 9.62582 0.60038 9.06357 1.56964C8.88412 1.879 8.88354 2.26063 9.06203 2.57053Z"
            fill="white"
          />
        </svg>
      </span>
      {parseInt(notifications) > 0 ? (
        <span className="uk-label uk-label-danger gh-notifications-label">{notifications}</span>
      ) : null}
    </NavLink>
  ) : null;
};

NotificationIcon.propTypes = {
  visible: PropTypes.bool.isRequired,
  path: PropTypes.string,
  notifications: PropTypes.number,
};
