import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import calendarIcon from "../../../../assets/img/svg/calendar.svg";

import "./DatePicker.css";
import { config } from "../../../../configs";

const DatePicker = (props) => {
  const {
    hideIcon,
    placeholder,
    value,
    onChange,
    disabled,
    timeConstraints,
    closeOnSelect,
    className,
    initialViewMode,
    dateFormat,
    isValidDate,
  } = props;

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <div className="gh-hook-date-picker-wrapper">
      <Datetime
        isValidDate={isValidDate}
        initialViewMode={initialViewMode}
        timeFormat={false}
        inputProps={{
          name,
          placeholder,
          className: className,
          disabled,
        }}
        value={value}
        dateFormat={dateFormat || ""}
        onChange={handleChange}
        timeConstraints={timeConstraints}
        closeOnSelect={closeOnSelect}
        renderInput={(props) => (
          <input
            {...props}
            readOnly={true}
            className="uk-input"
            autoComplete="off"
            value={value.format(dateFormat) || ""}
          />
        )}
      />

      {!hideIcon ? <img src={calendarIcon} alt="Calendar" className="gh-hook-calendar-icon" /> : ""}
    </div>
  );
};

DatePicker.defaultProps = {
  hideIcon: false,
  closeOnSelect: true,
  className: "",
  placeholder: "",
  disabled: false,
  initialViewMode: "days",
  dateFormat: config.dateFormat,
};

DatePicker.propTypes = {
  hideIcon: PropTypes.bool,
  timeConstraints: PropTypes.object,
  closeOnSelect: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  initialViewMode: PropTypes.string,
  dateFormat: PropTypes.string,
  isValidDate: PropTypes.func,
};

export default DatePicker;
