import * as types from "../../actions/actionTypes";

const initialState = { event: false };

function finalizedPayment(state = initialState, action) {
  switch (action.type) {
    case types.ADD_NEW_PAYMENT:
      return { event: true, data: action.response.message };
    case types.RESET_PAYMENT:
      return { event: false, data: null };
    default:
      return state;
  }
}

function cards(state = { total: 0, cards: [] }, action) {
  switch (action.type) {
    case types.CARDS_LOADED:
    case types.SET_DEFAULT_CARD:
      return action.response;
    case types.NEW_CARD:
      const cards = state.cards.map((card) => {
        card.selected = false;
        return card;
      });
      cards.unshift(action.card);
      return {
        total: state.cards.length,
        cards: cards,
      };

    case types.NEW_CARD_AND_DEFAULT:
      state.cards.map((card) => {
        card.selected = false;
        card.default = false;
        return card;
      });
      state.cards.unshift(action.card);
      return {
        total: state.cards.length,
        cards: state.cards,
      };
    case types.DELETE_CARD:
      let deletedCards = state.cards.filter((card) => card.card_id !== action.response.card_id);
      if (action.response.default) {
        deletedCards = deletedCards.map((card, index) => {
          if (index === 0) {
            card.selected = true;
            card.default = true;
          }
          return card;
        });
      }

      let changed = false;
      if (!action.response.default) {
        deletedCards = deletedCards.map((card, index) => {
          if (!card.default && index === 0 && !changed) {
            card.selected = true;
            changed = true;
          }
          if (card.default && !changed) {
            card.selected = true;
          }
          return card;
        });
      }
      return {
        total: deletedCards.length,
        cards: deletedCards,
      };
    default:
      return state;
  }
}

export { finalizedPayment, cards };
