import React from "react";
import { config } from "../../../../configs";
import staticService from "../../../../services/static.service";
import { checkObjectProp } from "../../../../shared";

export const BrokerPriceBlock = ({ quote = {} }) => {
  const _brokerPrice = checkObjectProp(quote, "price", null);
  return (
    <div className="uk-margin-bottom uk-margin-top">
      <label
        className="gh-dark-blue"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("charterPrice") }}
      />
      :
      <b className="gh-padding-left-10 font-weight-500">
        {config.currencyCode} {_brokerPrice}
      </b>
      <span
        className="gh-padding-left-10"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("vatIncludeShort") }}
      />
      <span className="gh-padding-left-10">
        <b className={"font-weight-500"}>
          {config.currencyCode} {quote.quote_vat}
        </b>
      </span>
      <span
        className="gh-padding-left-10"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("vatShortnessOfVat") }}
      />
    </div>
  );
};
