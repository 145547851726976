import React from "react";
import staticService from "../../../services/static.service";
import DocumentTitle from "../../common/DocumentTitle";

const IframeOperatorBrochure = () => {
  const environment = process.env.NODE_ENV;

  const envURL = {
    development: "api-staging",
    demo: "demo-api",
    production: "api",
  };

  const url = `https://${envURL[environment]}.getheli.com/pdf/get-heli-api-operators-fv.pdf`;

  return (
    <div>
      <DocumentTitle title={staticService.findByAlias("operatorPdfPageTitle")} />
      <iframe src={url} title="testPdf" height="100%" width="100%" style={{ height: "100vh" }} />
    </div>
  );
};

export default IframeOperatorBrochure;
