import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import "./QuoteGeneralInfoWithChat.css";
import LiveChatComponent from "../../../../common/LiveChatComponent";
import QuoteGeneralInfo from "./QuoteGeneralInfo";
import auth from "../../../../../services/auth";
import { roles } from "../../../../../configs";

const QuoteGeneralInfoWithChat = (props) => {
  const {
    requestID,
    quoteID,
    info,
    visibility,
    originPath,
    extendExpirationDate,
    history,
    history: { location },
  } = props;
  const { owner, status, booking_request, comments_count, payment } = info;

  const openTheChat = useMemo(() => location.state?.openChat, [location]);

  useEffect(() => {
    if (location.state?.openChat) {
      history.replace({ ...history.location, state: { ...location.state, openChat: false } });
    }
  }, [history]);

  const seenByCurrentUser = useMemo(
    () => info[`seen_by_${roles[auth.getRole()]}`],
    [auth.getRole(), info],
  );

  return (
    <section className="gh-quote-general-info-component">
      {auth.hasFeature("operator_to_start_conversation") &&
      ["open", "booking_init"].includes(status) ? (
        <LiveChatComponent
          quoteId={quoteID}
          commentsCount={comments_count && !seenByCurrentUser ? comments_count : 0}
          titleAlias="quoteDiscussionBroker"
          triggerToOpen={!!openTheChat}
        />
      ) : visibility ? (
        <LiveChatComponent
          quoteId={quoteID}
          commentsCount={comments_count && !seenByCurrentUser ? comments_count : 0}
          titleAlias="quoteDiscussionBroker"
          triggerToOpen={!!openTheChat}
        />
      ) : (
        ""
      )}

      <QuoteGeneralInfo
        info={info}
        owner={owner}
        status={status}
        booking_request={booking_request}
        originPath={originPath}
        requestID={requestID}
        payment={payment}
        expirationDate={info.expiration_time}
        extendExpirationDate={extendExpirationDate}
      />
    </section>
  );
};
QuoteGeneralInfoWithChat.propTypes = {
  quoteID: PropTypes.string,
  requestID: PropTypes.number.isRequired,
  info: PropTypes.shape({
    owner: PropTypes.string,
    seen_by_broker: PropTypes.bool,
    comments_count: PropTypes.number,
    status: PropTypes.string,
    booking_request: PropTypes.object,
    rejected_message: PropTypes.string,
    payment: PropTypes.object,
    expiration_time: PropTypes.string,
  }).isRequired,
  visibility: PropTypes.bool,
  originPath: PropTypes.string.isRequired,
  extendExpirationDate: PropTypes.func,
  history: PropTypes.object,
};
export default QuoteGeneralInfoWithChat;
