import React from "react";
import PropTypes from "prop-types";
import { RadioComponent } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import auth from "../../../../../services/auth";
import { BROKER_ADMIN, SOLE_TRADER } from "../../../../../configs";
import { NormalPermission } from "../../../../permissions";
import moment from "moment";
import checkIcon from "../../../../../assets/img/svg/checkSimple.svg";

const StripeCardComponent = ({
  cardInfo,
  updateModelFunc,
  selectedCard,
  deleteCard,
  setDefaultCard,
  disabled,
}) => {
  const currentYear = parseInt(moment().format("YYYY"));
  const currentMonth = parseInt(moment().format("MM"));
  const expired = !!(cardInfo.exp_year === currentYear && cardInfo.exp_month - currentMonth === 1);

  const USER_ROLE = auth.getRole();

  const isChecked = () => {
    if (selectedCard && selectedCard.card_id) {
      return selectedCard.card_id === cardInfo.card_id;
    }
    return cardInfo.selected;
  };

  return (
    <div
      className={`wallet-row-default ${isChecked() ? "selected-card" : ""}  ${
        expired ? "wallet-row-expired" : ""
      }`}
    >
      <div className="uk-flex uk-flex-middle wallet-card">
        <div className="uk-flex uk-flex-middle">
          {!window.location.pathname.includes("cards") ? (
            <RadioComponent
              label=""
              model="card"
              onClick={updateModelFunc.bind(null, cardInfo)}
              value={cardInfo.card_id}
              wrapperClassName="gh-radio-wrapper wallet-radio"
              checked={isChecked()}
            />
          ) : (
            ""
          )}
          <div className="uk-width-expand uk-flex-center">
            <div>
              <span
                className="wallet-row-label"
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cardName") }}
              />
              <span className="wallet-row-text">{cardInfo.name ? cardInfo.name : "- -"}</span>
            </div>
            <div className="uk-margin-remove-top">
              <span
                className="wallet-row-label"
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("CardNumber") }}
              />
              <span className="wallet-row-text">
                **** **** **** {cardInfo.last || cardInfo.last4}
              </span>
            </div>
            <div className="uk-margin-remove-top">
              <span
                className="wallet-row-label"
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("AccountExpiration") }}
              />

              <span className={`wallet-row-text ${expired ? "wallet-row-expired-text" : ""}`}>
                {moment(cardInfo.exp_month, "MM").format("MMMM")} / {cardInfo.exp_year}
              </span>
            </div>
            <div>
              <span
                className="wallet-row-label"
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cardCVC") }}
              />
              <span className="wallet-row-text">***</span>
            </div>
            {cardInfo.default ? (
              <div className="uk-margin-remove-top">
                <img src={checkIcon} alt="check" style={{ width: 15, marginRight: 10 }} />
                <span
                  className="wallet-row-text"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("defaultPaymentCard"),
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {!cardInfo.default ? (
              <div className="uk-margin-remove-top">
                <button
                  onClick={setDefaultCard}
                  className="wallet-row-text wallet-default-button"
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("makeDefaultCard") }}
                />
              </div>
            ) : (
              ""
            )}
            {disabled && (
              <p className="gh-card-disabled-warn">
                Only available for bookings made more than 11 days before the commencement of the
                programme
              </p>
            )}
          </div>
        </div>
        <NormalPermission condition={USER_ROLE === BROKER_ADMIN || USER_ROLE === SOLE_TRADER}>
          <a
            onClick={deleteCard.bind(null, cardInfo)}
            style={{ color: "#de2525", marginTop: window.innerWidth < 576 ? 20 : 0 }}
          >
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("DeleteCard") }} />
          </a>
        </NormalPermission>
      </div>
    </div>
  );
};

StripeCardComponent.propTypes = {
  cardInfo: PropTypes.object.isRequired,
  deleteCard: PropTypes.func.isRequired,
  updateModelFunc: PropTypes.func.isRequired,
  selectedCard: PropTypes.object,
  setDefaultCard: PropTypes.func,
  disabled: PropTypes.bool,
};

export { StripeCardComponent };
