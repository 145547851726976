import React from "react";
import PropTypes from "prop-types";
import {
  addCommentToBooking,
  addPrivateNoteToBooking,
  operatorAddBookingInternalNotes,
} from "../../../../actions";
import { isObjectEmpty, aircraftTitle } from "../../../../shared";
import {
  config,
  CONFIRMED,
  QUOTE_CLOSED,
  QUOTE_REJECTED,
  QUOTE_UNSUCCESSFULL,
  QUOTE_WITHDRAWN,
} from "../../../../configs";
import { BlockComponent } from "../../../common";
import { RequestBlockComponent, LegDetails } from "./";
import { List } from "../../../../shared/list";
import { FlightItemNoteFormComponent, NewCommentFormComponent } from "../../../../components";
import { AllowPermission } from "../../../permissions/allowPermission";
import staticService from "../../../../services/static.service";
import { CommentRowComponent } from "./comment-row.component";
import Auth from "../../../../services/auth";
import { BrokerPrivateNotesComponent } from "../broker/broker-private-notes.component";

class BookingDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getComments = this.getComments.bind(this);
    this.state = {
      sectionForBroker: true,
    };
  }

  get canWriteCompanyNotes() {
    const {
      booking: { status },
    } = this.props;

    return (
      status !== undefined &&
      status !== QUOTE_REJECTED &&
      status !== QUOTE_WITHDRAWN &&
      status !== QUOTE_CLOSED &&
      status !== QUOTE_UNSUCCESSFULL
    );
  }

  getComments(comments) {
    const {
      booking_request,
      broker_created,
      operator_created,
      status,
      cancelled,
      confirmed_by,
      legs,
      audits,
    } = this.props.booking;
    const lastIndex = this.props.booking.comments.length + 5;
    return (
      <div className={"gh-comment-section"}>
        <RequestBlockComponent title={staticService.findByAlias("audit")}>
          <div>
            {audits.map((item) => (
              <List attrProps={{ className: "disabled-list" }} key={item.created_at}>
                <CommentRowComponent
                  posted={item.created_at}
                  user={item.user.full_name}
                  content={item.action.name}
                  total={audits.length + 1}
                  isBold={false}
                  isBoldLabel={staticService.findByAlias("onRequestComment")}
                  sectionForBroker={this.state.sectionForBroker}
                  legs={legs}
                  index={item.action.order}
                />
              </List>
            ))}
          </div>
        </RequestBlockComponent>
      </div>
    );
  }

  render() {
    const { legs } = this.props.booking;
    const { booking, payment_method } = this.props;
    if (!isObjectEmpty(booking)) {
      const comments = booking.comments.map((comment, index) => {
        const borderWidth = index === 0 ? "3px" : "1px";
        return (
          <CommentRowComponent
            key={index}
            borderWidth={borderWidth}
            posted={comment.posted}
            user={comment.user}
            content={comment.body}
            total={booking.comments.length + 2}
            index={index + 1}
          />
        );
      });

      return (
        <BlockComponent visible={true} parentProps={{ className: "uk-padding-remove-top" }}>
          <ul data-uk-accordion="collapsible: false" className="disabled-list gh-accordion">
            <li className="uk-open uk-primary gh-accordion-section">
              <span
                className="uk-accordion-title"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(
                    payment_method === 1 ? "BookingDetails" : "rovisionalBookingConfirmation",
                  ),
                }}
              />
              <div className="uk-accordion-content uk-margin-remove gh-req-quotes-details">
                <RequestBlockComponent
                  title={
                    Auth.getMainRole() === "broker"
                      ? staticService.findByAlias("operatorCompanyInfo")
                      : staticService.findByAlias("brokerCompanyInfo")
                  }
                  topPosition={"uk-margin"}
                >
                  <ul className={"uk-margin-medium-bottom disabled-list company-summary"}>
                    <li>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("companyName"),
                        }}
                      />
                      : <b className={"font-weight-500"}>{booking.summary.company}</b>
                    </li>
                    <li>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("companyPhone"),
                        }}
                      />
                      : {booking.summary.phone}
                    </li>
                    <AllowPermission role={"broker"}>
                      <li>
                        <label
                          dangerouslySetInnerHTML={{
                            __html: staticService.findByAlias("aocName"),
                          }}
                        />
                        :{" "}
                        <b className={"font-weight-500"}>
                          {booking.summary.company_aoc ? booking.summary.company_aoc : "---"}
                        </b>
                      </li>
                    </AllowPermission>
                    {/*<AllowPermission role={"broker"}>*/}
                    {/*  <li>*/}
                    {/*    <label*/}
                    {/*      dangerouslySetInnerHTML={{*/}
                    {/*        __html: staticService.findByAlias(*/}
                    {/*          "companyAocNumber"*/}
                    {/*        ),*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*    : {booking.summary.number}{" "}*/}
                    {/*    {booking.summary.company_aoc*/}
                    {/*      ? `/ ${booking.summary.company_aoc}`*/}
                    {/*      : null}*/}
                    {/*  </li>*/}
                    {/*</AllowPermission>*/}
                    <li>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("companyEmail"),
                        }}
                      />
                      : {booking.summary.email}
                    </li>
                    <li>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("assignedTo"),
                        }}
                      />
                      :{" "}
                      <b
                        className={
                          booking.summary.suspended
                            ? "suspended font-weight-500"
                            : "active font-weight-500"
                        }
                      >
                        {booking.summary.created_by}
                      </b>
                    </li>
                    <li>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("directContactPhone"),
                        }}
                      />
                      : {booking.summary.private_phone}
                    </li>
                  </ul>
                </RequestBlockComponent>
                <RequestBlockComponent
                  title={staticService.findByAlias("aircraftProvisionallyAlocated")}
                  topPosition={"uk-margin"}
                  tooltipVisible={Auth.getMainRole() === "operator"}
                >
                  <ul className={"uk-margin-medium-bottom disabled-list company-summary"}>
                    {booking.aircrafts.map((aircraft, index) => (
                      <li key={index}>
                        <span
                          className={"uk-label gh-aircraft-label"}
                          dangerouslySetInnerHTML={{
                            __html: aircraftTitle(aircraft, this.props.showTailNumber),
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </RequestBlockComponent>

                {booking.legs &&
                  booking.legs.map((leg, index) => {
                    return (
                      <RequestBlockComponent
                        key={index}
                        title={"Leg" + (index + 1)}
                        topPosition={index === 0 ? "uk-margin-small-top" : "uk-margin-medium-top"}
                      >
                        <LegDetails
                          requestInfo={booking.booking_request}
                          leg={leg}
                          index={index}
                          showHelicopImage={this.props.screenW > config.minDeviceWidth}
                        />
                      </RequestBlockComponent>
                    );
                  })}
                {this.getComments(comments)}

                <p
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("GeneralConditionsofCarriageNotification"),
                  }}
                />

                <AllowPermission role="operator">
                  <FlightItemNoteFormComponent
                    className="gh-mob-clear-padding gh-margin-top-20"
                    title={staticService.findByAlias("internalCompanyNotes")}
                    id={this.props.booking.booking_request.selectedQuote.id}
                    noteList={this.props.booking.internalNotes}
                    action={operatorAddBookingInternalNotes}
                    canWriteNotes={this.canWriteCompanyNotes}
                    company={this.props.company}
                  />
                </AllowPermission>

                <AllowPermission role="broker">
                  <BrokerPrivateNotesComponent
                    className="gh-mob-clear-padding gh-margin-top-20"
                    title={staticService.findByAlias("privateNotes")}
                    action={addPrivateNoteToBooking}
                    note={this.props.booking.private_notes || ""}
                    id={this.props.booking.id}
                  />
                </AllowPermission>
              </div>
            </li>
          </ul>
          {this.props.children}
        </BlockComponent>
      );
    }
    return null;
  }
}

BookingDetailComponent.contextTypes = {
  store: PropTypes.object,
};

BookingDetailComponent.propTypes = {
  reduxResetModel: PropTypes.func,
  showTailNumber: PropTypes.bool,
  internalCompanyNotes: PropTypes.shape({}),
};

export { BookingDetailComponent };
