import React from "react";
import { Route } from "react-router-dom";

const RouteWithSubRoutes = (prop) => {
  const _path = prop.path ? { path: prop.path } : {};
  if (prop.exact) {
    return (
      <Route
        exact
        {..._path}
        render={(props) => {
          return <prop.component {...prop} {...props} title={prop.title} routes={prop.routes} />;
        }}
      />
    );
  }
  return (
    <Route
      path={prop.path}
      render={(props) => {
        return <prop.component {...prop} {...props} title={prop.title} routes={prop.routes} />;
      }}
    />
  );
};

export { RouteWithSubRoutes };
