import React, { memo, useContext, useState, useEffect } from "react";
import MarkerInfoContext from "./MarkerInfoContext";
import arrowRight from "../../../assets/img/svg/mapArrowRight.svg";
import { generateChosenSiteText } from "../mapUtils";

function GoogleMarker() {
  const {
    pin,
    pin: { name, handleAssignButton, direction },
    isSelected,
    updateChosenSitesByRole,
  } = useContext(MarkerInfoContext);
  const [showInfo, setShowInfo] = useState(false);
  const [chosenSiteText, setChosenSiteText] = useState();

  useEffect(() => {
    setChosenSiteText(generateChosenSiteText(direction, updateChosenSitesByRole));
  }, [direction]);

  return (
    <div className="legInfoWindow selected google" id="id-info">
      <div className="content">
        <p className="top-subtitle">pin address</p>
        <h4 className="top-head">{name}</h4>
        {isSelected && direction ? (
          <h4 className="bottom-head">{pin.chosenSiteText || chosenSiteText}</h4>
        ) : (
          <div className="legInfoWindowActionLegs">
            {showInfo ? (
              <div>
                <a role="button" onClick={() => handleAssignButton("from")}>
                  FROM
                </a>
                <span style={{ margin: "0 15px", fontWeight: 600, fontSize: 14 }}>or</span>
                <a role="button" onClick={() => handleAssignButton("to")}>
                  TO
                </a>
              </div>
            ) : (
              <a onClick={() => setShowInfo(true)}>
                <h4 className="bottom-head secondary">
                  Use this landing site <img src={arrowRight} alt="arrow" />
                </h4>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(GoogleMarker);
