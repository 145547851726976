import React from "react";
import Auth from "../../../../services/auth";
import { ConfirmComponent } from "../../../common";
import staticService from "../../../../services/static.service";
import { connect } from "react-redux";

class MainDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.confirmComponent.closeModal();
  }

  get dialogMessage() {
    const content =
      Auth.getMainRole() === "broker"
        ? staticService.findByAlias("dialogBroker")
        : staticService.findByAlias("dialogOperator");
    return <span dangerouslySetInnerHTML={{ __html: content }} />;
  }

  get modalProps() {
    return {
      id: "confirm-modal",
      title: staticService.findByAlias("dashboardModalTItle"),
      onClose: this.closeDialog.bind(this),
    };
  }

  get dialog() {
    return (
      <ConfirmComponent
        ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
        info={this.dialogMessage}
        buttonsProps={this.buttonsProps}
        modalProps={this.modalProps}
      />
    );
  }

  get mainContent() {
    return null;
  }

  render() {
    return this.mainContent;
  }

  componentWillUnmount() {
    this.closeDialog();
  }
}
export { MainDashboard };
