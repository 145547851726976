import React from "react";
import Auth from "../../services/auth";
import { ForbiddenComponent } from "../pages/";

export const IsVerifiedPermission = (props) => {
  if (Auth.isAuthenticated() && Auth.getMainRole() === props.role && Auth.isVerified()) {
    return props.children;
  }
  return <ForbiddenComponent {...props} />;
};
