import React from "react";
import PropTypes from "prop-types";
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { RouteWithSubRoutes, returnRouteUrl, TabComponent } from "../../../../shared";
import { accountTabs, bankingTabs } from "../../../../configs";
import { VerificationComponent } from "../team-components";
import { BlockComponent } from "../../../common";
import { ButtonWithBadgeComponent, MenuComponent, UserProfileComponent } from "../common";
import Auth from "../../../../services/auth";
import auth from "../../../../services/auth";
import staticService from "../../../../services/static.service";
import { AccountOnlineLayout } from "../../../layouts";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class OperatorBankingComponent extends UserProfileComponent {
  constructor(props) {
    super(props);
    const pages = [...accountTabs];
    const operatorTabs = pages.map((p) => {
      if (p.title === "Banking") {
        return { ...p, path: "/account/banking/account" };
      } else {
        return p;
      }
    });
    const items = operatorTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      selectedTab: bankingTabs[0],
      tabs: items,
    };
    this.getActive = this.getActive.bind(this);
    this.verifiedTabs = this.verifiedTabs.bind(this);
  }

  componentDidMount() {
    this.props.history.push(bankingTabs[0].path);
  }

  onTabClick(tab, ev) {
    ev.preventDefault();
    this.setState({ selectedTab: tab });
  }

  getActive(loopEl) {
    if (loopEl.alias === this.state.selectedTab.alias) {
      return " uk-active";
    }
    return "";
  }

  verifiedTabs(tabs) {
    if (auth.getUser().hasApiKey === false) {
      return tabs.filter((tab) => tab.alias !== "credentials");
    }

    return tabs;
  }

  render() {
    return (
      <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
        <section className={"team-page"}>
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom header-title",
            }}
          >
            <div className="uk-width-1-1">
              <h3
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("account"),
                }}
              />
            </div>
            <div className={"uk-margin-bottom"}>
              <ButtonWithBadgeComponent
                route={"/marketplace"}
                classes={["btn-pulse"]}
                visible={true}
                label={staticService.findByAlias("operatorMarketPlaceButton")}
              />
            </div>
          </BlockComponent>
          <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
            <TabComponent
              items={this.state.tabs}
              id={"profile-tab"}
              visible={true}
              tabProps={{ className: "uk-tab uk-margin" }}
              onClick={this.onTabClick.bind(this)}
              selected={this.state.selectedTab}
              linkType={"nav"}
            />
            <div data-uk-grid>
              <div className="uk-width-1-1@s uk-width-1-3@m">
                <div className="uk-width-3-4 uk-margin-small-top">
                  <MenuComponent items={this.verifiedTabs(bankingTabs)} />
                </div>
              </div>
              <div className="uk-width-3-1@s uk-width-2-3@m">
                <Switch>
                  {this.props.routes &&
                    this.props.routes.map((route, i) => {
                      return (
                        <RouteWithSubRoutes
                          key={i}
                          title={route.title}
                          {...returnRouteUrl(route, this.props.match.url)}
                        />
                      );
                    })}
                  <Redirect from="/company/banking" exact to="/company/banking/account" />
                  <Route path="/" component={VerificationComponent} />
                </Switch>
              </div>
            </div>
          </BlockComponent>
        </section>
      </AccountOnlineLayout>
    );
  }
}

OperatorBankingComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ screenDimensions, accountUpdated }) {
  return {
    accountUpdated: accountUpdated,
    ...screenDimensions,
  };
}

const COMPONENT = connect(mapStateToProps)(OperatorBankingComponent);
export { COMPONENT as OperatorBankingComponent };
