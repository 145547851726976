import React from "react";
import { actions, Control, Form } from "react-redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  aircraftTitle,
  DateTimeComponent,
  LoadingButton,
  prepareQueryAttributes,
  setActiveItem,
  updateModel,
} from "../../../../shared";
import {
  getOperatorFilterQuotes,
  getOperatorQuotesSuccess,
  loadAircraftFilter,
} from "../../../../actions";
import { config, operatorQuotesFilters } from "../../../../configs";
import BaseFilterComponent from "./base-filter.component";
import { ActivityFilterCriteria, FilterTabsComponent } from "./";
import staticService from "../../../../services/static.service";
import GetHeliEvents from "../../../../services/event.service";
import googleApi from "../../../../services/google.service";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../common";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";
import { withRouter } from "react-router-dom";

const modelName = "operatorBookingQuotesFiltersModel";

class OperatorBookingQuotesFiltersComponent extends BaseFilterComponent {
  constructor(props) {
    super(props, modelName);
    this.state = {
      modelName: modelName,
      isToggled: false,
      aircrafts: [],
      filterTabs: operatorQuotesFilters,
    };

    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.updateTabList = this.updateTabList.bind(this);
    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.updateOnSelect = this.updateOnSelect.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
  }

  add(id) {
    const refreshedAircrafts = this.state.aircrafts.concat([true]);
    this.setState({ aircrafts: refreshedAircrafts });
    const refreshedAircraftsList = this.props.selectedAircrafts.concat([id]);
    this.props.updateModel(modelName + ".selectedAircrafts", refreshedAircraftsList);
  }

  get Model() {
    return {
      status: "all",
      departure_time: null,
      active: false,
      from: "",
      to: "",
      aircraft: "",
      disableBtn: false,
      refreshCollection: false,
      aircrafts: [],
      selectedAircrafts: [],
      isToggled: false,
      activity: "own",
    };
  }

  remove(id) {
    let refreshedAircraftsStatus = [];
    const refreshedAircrafts = this.props.selectedAircrafts.filter((rowId) => {
      if (rowId !== id) {
        return rowId;
      }
    });
    refreshedAircrafts.forEach(() => {
      refreshedAircraftsStatus.push(true);
    });
    this.setState({
      aircrafts: refreshedAircraftsStatus,
      isToggled: false,
    });
    this.props.updateModel(modelName + ".selectedAircrafts", refreshedAircrafts);
    this.props.updateModel(modelName + ".isToggled", false);
  }

  toggleAll(model, value) {
    if (!value) {
      this.props.updateModel(modelName + ".selectedAircrafts", []);
      this.props.updateModel(modelName + ".aircrafts", []);
      this.setState({
        aircrafts: [],
        isToggled: value,
      });
    } else {
      const selectedAircrafts = [];
      const aircrafts = [];
      this.props.aircrafts.forEach((aircraft) => {
        selectedAircrafts.push(aircraft.id);
        aircrafts.push(true);
      });

      this.props.updateModel(modelName + ".selectedAircrafts", selectedAircrafts);
      this.props.updateModel(modelName + ".aircrafts", aircrafts);
      this.setState({
        aircrafts: aircrafts,
        isToggled: value,
      });
    }
    return updateModel(model, value);
  }

  componentWillMount() {
    this.props.loadAircrafts();
  }

  componentDidMount() {
    if (this.props.pageFilters && this.props.pageFilters[0] && this.props.pageFilters[0].value) {
      const el = this.props.pageFilters[0].value;
      const active = this.state.filterTabs.find((item) => item.filter === el);

      if (active) {
        setActiveItem(this.state.filterTabs, active.filter);
      }
    }

    const parentParams = this.props.mergedParams?.search;
    const currentActiveTab = parentParams || "all";

    this.updateTabList([], currentActiveTab === "quotes" ? "all" : currentActiveTab);
  }

  updateTabList(list, status) {
    const tabExists = this.state.filterTabs.findIndex((item) => item.filter === status);
    const requestIdParam = this.props.mergedParams?.requestID;
    // hardcoded route only for all tab
    const url = requestIdParam
      ? `/flights/quotes/filter/${status}/request/${requestIdParam}`
      : `/flights/quotes/filter/${status}`;

    if (tabExists !== -1) {
      this.props.history.push(url);
      this.context.store.dispatch(actions.change("isConcat", false));
      this.props.loadData(status);
      setActiveItem(this.state.filterTabs, status);
    } else {
      this.props.history.push(`/flights/quotes/filter/all`);
      this.context.store.dispatch(actions.change("isConcat", false));
      this.props.loadData("all");
      setActiveItem(this.state.filterTabs, "all");
    }
  }

  updateAircraftList(id, model, value) {
    if (value) {
      this.add(id);
    } else {
      this.remove(id);
    }
    return updateModel(model, value);
  }

  updateOnSelect(modelKey, address) {
    if (address) {
      this.context.store.dispatch(
        actions.change(`${this.state.modelName}.${modelKey}`, address.location),
      );
      this.context.store.dispatch(
        actions.change(
          `${this.state.modelName}.${modelKey}_coverage_status`,
          address.coverage_status,
        ),
      );
      this.context.store.dispatch(
        actions.change(
          `${this.state.modelName}.${modelKey}_info`,
          generateLocationAdditionalInfo(address),
        ),
      );
    }
  }

  updateGeocodeModel(modelKey, value) {
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
    };
    this.updateOnSelect(modelKey, address);
  }

  fillCoordinates(modelKey, address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(modelKey, address);
      });
    } else {
      this.context.store.dispatch(actions.setTouched(`${this.state.modelName}.${modelKey}`));
    }
  }

  onSelectLocation(handleClickOutside, modelKey, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address);
    } else {
      this.updateOnSelect(modelKey, address);
    }
  }

  submit(type, model) {
    this.props.updateModel(this.state.modelName + ".disableBtn", true);
    const params =
      this.props.pageFilters && this.props.pageFilters[0] && this.props.pageFilters[0].value;
    let filters = prepareQueryAttributes(
      { ...model, status: params || "all" },
      this.props.queryAttributes,
    );

    GetHeliEvents.trigger("filter-triggered", filters);
    let pageFilters = this.props.pageFilters;
    if (this.props.onSubmit) {
      pageFilters = this.props.onSubmit();
    }

    this.action(type, filters.concat(this.props.pageFilters), pageFilters);
  }

  reset() {
    if (this.Model) {
      this.props.resetModel(this.state.modelName);
      this.setState({ reset: " show" });
      let pageFilters = this.props.pageFilters;
      if (this.props.onReset) {
        pageFilters = this.props.onReset();
      }
      this.action("", [], pageFilters);
    }
  }

  get isSubmitEnabled() {
    return !!this.props.disableBtn;
  }

  get aircrafts() {
    return (
      <div className="uk-margin-medium-top">
        <label className="uk-form-label">Aircraft</label>
        <ul className="uk-form-controls uk-margin-small-top uk-list">
          {this.props.aircrafts.map((aircraft, index) => {
            return (
              <li key={index}>
                <label>
                  <Control.checkbox
                    value={aircraft.id}
                    className={"uk-checkbox uk-margin-small-right"}
                    model={".aircrafts[" + index + "]"}
                    changeAction={this.updateAircraftList.bind(this, aircraft.id)}
                    updateOn="change"
                  />{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: aircraftTitle(aircraft, true),
                    }}
                  />
                </label>
              </li>
            );
          })}
        </ul>
        <br />
      </div>
    );
  }

  get form() {
    return (
      <div className="uk-card uk-card-default uk-card-body">
        <div>
          <Form model={modelName} onSubmit={this.handleFormSubmit.bind(this, "")}>
            <div data-uk-grid className="gh-filters-container">
              <div className="uk-width-1-3@m">
                <label
                  className="uk-form-label"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("DepartureFrom"),
                  }}
                />
                <div className="uk-form-controls">
                  <LocationAutocompleteWrapper>
                    {({
                      locations,
                      search,
                      handleClickOutside,
                      loader,
                      onBlur,
                      locationValidationTemplate,
                    }) => {
                      return (
                        <div className="uk-position-relative">
                          <LocationAutocompleteInput
                            className={["uk-input"]}
                            placeholder="London"
                            onSelect={(location) => {
                              this.updateGeocodeModel("from", location);
                              return search(location);
                            }}
                            onBlur={onBlur}
                            loader={loader}
                            debounce={500}
                            value={this.props.from}
                            coverageWarning={this.props.from_coverage_status}
                            additionalInfo={this.props.from_info}
                            model={`${modelName}.from`}
                          />
                          <LocationAutocompleteList
                            onSelect={this.onSelectLocation.bind(this, handleClickOutside, "from")}
                            locations={locations}
                          />
                          <span>{locationValidationTemplate(modelName, ".from")}</span>
                        </div>
                      );
                    }}
                  </LocationAutocompleteWrapper>
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <label
                  className="uk-form-label"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("DepartureTo"),
                  }}
                />
                <div className="uk-form-controls">
                  <LocationAutocompleteWrapper>
                    {({
                      locations,
                      search,
                      handleClickOutside,
                      loader,
                      onBlur,
                      locationValidationTemplate,
                    }) => {
                      return (
                        <div className="uk-position-relative">
                          <LocationAutocompleteInput
                            className={["uk-input"]}
                            placeholder="Paris"
                            onSelect={(location) => {
                              this.updateGeocodeModel("to", location);
                              return search(location);
                            }}
                            onBlur={onBlur}
                            loader={loader}
                            debounce={500}
                            value={this.props.to}
                            coverageWarning={this.props.to_coverage_status}
                            additionalInfo={this.props.to_info}
                            model={`${modelName}.to`}
                          />
                          <LocationAutocompleteList
                            onSelect={this.onSelectLocation.bind(this, handleClickOutside, "to")}
                            locations={locations}
                          />
                          <span>{locationValidationTemplate(modelName, ".to")}</span>
                        </div>
                      );
                    }}
                  </LocationAutocompleteWrapper>
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <label
                  className="uk-form-label"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("date"),
                  }}
                />
                <div className="uk-form-controls  uk-position-relative">
                  <Control
                    model={`.departure_time`}
                    className={"uk-input gh-calendar-input"}
                    controlProps={{
                      dateFormat: "DD MMMM, YYYY",
                      isValidDate: this.props.isValidDate,
                      onClear: () => this.props.updateModel(modelName + ".departure_time", ""),
                    }}
                    component={DateTimeComponent}
                    changeAction={(model, value) => updateModel(model, value.utc().toISOString())}
                    updateOn="change"
                  />
                </div>
              </div>
            </div>
            <div data-uk-grid className="uk-flex-middle">
              <div className="uk-width-1-3@m">
                <ActivityFilterCriteria
                  updateModel={this.props.updateModel}
                  modelName={modelName}
                  activity={this.props.activity}
                />
              </div>
            </div>

            {this.props.aircrafts.length > 0 ? this.aircrafts : null}

            <div className="uk-flex-center" data-uk-grid>
              <div>
                <LoadingButton
                  type={"button"}
                  classNames={["uk-button", "gh-cloud-blue-btn", "gh-button-loader"]}
                  action={this.reset.bind(this)}
                  hideLoader={this.state.reset}
                >
                  {({ btnProps }) => (
                    <button {...btnProps}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("resetFilter"),
                        }}
                      />
                    </button>
                  )}
                </LoadingButton>
              </div>
              <div>
                <LoadingButton
                  type={"submit"}
                  classNames={["uk-button", "uk-button-primary", "gh-button-loader"]}
                  hideLoader={this.state.loading}
                >
                  {({ btnProps }) => (
                    <Control.button
                      model={modelName}
                      disabled={this.isSubmitEnabled}
                      className={btnProps.className}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("showFilterResult"),
                        }}
                      />
                    </Control.button>
                  )}
                </LoadingButton>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div>
        <FilterTabsComponent
          tabs={this.state.filterTabs}
          changeFunc={this.updateTabList}
          isLoading={isLoading}
        />
        {!this.props.visible
          ? null
          : this.props.children({
              template: this.form,
            })}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.resetModel(modelName);
  }
}

OperatorBookingQuotesFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorBookingQuotesFiltersComponent.propTypes = {
  queryAttributes: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    ...state.operatorBookingQuotesFiltersModel,
    aircrafts: state.aircraft,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      resetModel: (model) => dispatch(actions.reset(model)),
      loadAircrafts: loadAircraftFilter,
      get: getOperatorFilterQuotes,
      update: getOperatorQuotesSuccess,
    },
    dispatch,
  );
};

const COMPONENT = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OperatorBookingQuotesFiltersComponent),
);
export { COMPONENT as OperatorBookingQuotesFiltersComponent };
