import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../Form";
import { useForm } from "react-hook-form";
import Select from "../../../../Form/components/Select";
import Button from "../../../../common/Button";
import staticService from "../../../../../services/static.service";

import "./DestinationFilters.css";
import { toNumber } from "uikit/src/js/util";

const DestinationFilters = (props) => {
  const {
    loadFilteredDestinations,
    countries,
    loadDestinations,
    handleUpdateCustomFilters,
    customFilters,
    listOfDestinationsRef,
    eventTypes,
    locationFilters,
    history,
  } = props;

  const locationsMemo = useMemo(() => {
    if (locationFilters) {
      return locationFilters.map((location) => ({
        label: location.label,
        value: location.label,
      }));
    }
  }, [locationFilters]);
  const searchParams = new URLSearchParams(location.search);
  const [locations, setLocations] = useState(locationsMemo);
  const countryValue = searchParams.get("country");
  const locationValue = searchParams.get("location_name");
  const flightTypeValue = searchParams.get("flight_type");

  const form = useForm();
  const countryField = form.watch("country");

  useEffect(() => {
    if (!countryField) return;

    form.setValue("locations", "");

    const countryLocations = countries?.find((el) => el.country_code === countryField)?.locations;

    const newLocations = countryLocations?.map((location) => ({
      label: location.location_name,
      value: location.location_name,
    }));

    setLocations(newLocations);
  }, [countryField]);

  const executeScroll = () =>
    listOfDestinationsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  useEffect(() => {
    if (customFilters.country) {
      form.setValue("country", customFilters.country || "");
      form.setValue("locations", customFilters.locations || "");
      form.setValue("destination_type", customFilters.destination_type || "");
    }
  }, [customFilters]);

  const countryOptions = useMemo(() => {
    if (!countries) return [];
    return countries.map((country) => ({
      label: country.name,
      value: country.country_code,
    }));
  }, [countries]);

  const handleResetFilters = () => {
    if (countryValue || locationValue) {
      history.push("/destinations");
    }

    executeScroll();
    handleUpdateCustomFilters({});
    setLocations(locationsMemo);
    form.reset();

    loadDestinations();

    //get all destinations
  };

  const handleSubmitForm = ({ country, locations, destination_type }) => {
    executeScroll();
    const formValue = [
      { key: "country_code", value: country || "" },
      { key: "location_name", value: locations || "" },
      { key: "destination_type", value: destination_type || "" },
    ];

    handleUpdateCustomFilters({
      country: country || "",
      locations: locations || "",
      destination_type: destination_type || "",
    });

    loadFilteredDestinations(1, formValue);
  };

  useEffect(() => {
    if (countryValue && countryOptions.length) {
      form.setValue("country", countryValue);
    }
  }, [countryOptions, locations]);

  useEffect(() => {
    if (countryValue === countryField && locationValue) {
      setTimeout(() => {
        form.setValue("locations", toNumber(locationValue) || locationValue);
        form.setValue("destination_type", flightTypeValue);
      }, 300);
    }
  }, [countryValue, locationValue, countryField]);

  const handleFormOnChange = () => {
    history.push("/destinations");
  };

  return (
    <div className="uk-card uk-card-default uk-card-body">
      <Form form={form} onValuesChange={handleFormOnChange}>
        <div className="uk-grid">
          <Form.Item
            className="width-1-1@s uk-width-1-4@m uk-margin-top"
            label="Country"
            name="country"
            options={countryOptions}
          >
            <Select placeholder="Select..." />
          </Form.Item>
          <Form.Item
            className="width-1-1@s uk-width-1-4@m uk-margin-top"
            label="Location"
            name="locations"
            options={locations}
          >
            <Select placeholder="Select..." />
          </Form.Item>
          <Form.Item
            className="width-1-1@s uk-width-1-4@m uk-margin-top"
            label="Flight type"
            name="destination_type"
            options={eventTypes}
          >
            <Select placeholder="Select..." />
          </Form.Item>
        </div>

        <div className="gh-filters-buttons uk-flex uk-flex-center">
          <Button color="transparentBlue" onClick={handleResetFilters}>
            {staticService.findByAlias("resetFilter")}
          </Button>
          <Button onClick={form.handleSubmit(handleSubmitForm)}>
            {staticService.findByAlias("showFilterResults")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

DestinationFilters.propTypes = {
  loadFilteredDestinations: PropTypes.func,
  countries: PropTypes.array,
  loadDestinations: PropTypes.func,
  handleUpdateCustomFilters: PropTypes.func,
  customFilters: PropTypes.object,
  listOfDestinationsRef: PropTypes.object,
  eventTypes: PropTypes.array,
  locationFilters: PropTypes.array,
  history: PropTypes.object,
};

export default DestinationFilters;
