import React from "react";
import auth from "../../../../../services/auth";
import staticService from "../../../../../services/static.service";
import Button from "../../../../common/Button";
import { RequestDetailsFooterContext } from "./RequestDetailsFooter";
import clockIcon from "../../../../../assets/img/svg/clock.svg";
import { Dropdown } from "../../../..";
import clsx from "clsx";

export default function RequestLaterAction() {
  const {
    originalQuoteDetail,
    toggleRequestFlagStatus,
    setShowPrompt,
    history,
    showAdditionalButtons,
  } = React.useContext(RequestDetailsFooterContext);

  const [actionsVisible, setActionsVisible] = React.useState(false);

  const toggleFlagStatusHandler = (key) => {
    setShowPrompt(false);
    let requestId;
    if (originalQuoteDetail.booking_request) {
      requestId = originalQuoteDetail.booking_request.id;
    } else {
      requestId = originalQuoteDetail.id;
    }

    const data = { [key]: true };

    toggleRequestFlagStatus(requestId, data).then(() => {
      history.push("/marketplace");
    });
  };

  const handleActionsClick = React.useCallback((cb, key) => () => {
    setActionsVisible(false);
    cb(key);
  });

  if (showAdditionalButtons) {
    return (
      <div className="request-details-later-action">
        <Dropdown
          visibile={actionsVisible}
          onVisibleChange={setActionsVisible}
          placement="topLeft"
          overlay={
            <ul className="disabled-list later-action__options">
              <li>
                <button
                  className={clsx({
                    "--selected": originalQuoteDetail.is_flag_set,
                  })}
                  onClick={
                    !originalQuoteDetail.is_flag_set &&
                    handleActionsClick(toggleFlagStatusHandler, "is_flag_set")
                  }
                >
                  {staticService.findByAlias("willQuoteSoon")}
                </button>
              </li>

              <li>
                <button
                  className={clsx({
                    "--selected": originalQuoteDetail.is_quote_uncertain_set,
                  })}
                  onClick={
                    !originalQuoteDetail.is_quote_uncertain_set &&
                    handleActionsClick(toggleFlagStatusHandler, "is_quote_uncertain_set")
                  }
                >
                  {staticService.findByAlias("quotingUncertain")}
                </button>
              </li>
            </ul>
          }
        >
          <Button
            variant="outlined"
            color="gray"
            disabled={!auth.isVerified()}
            data-uk-tooltip={(!auth.isVerified() && "Your account is not verified") || ""}
          >
            <span style={{ marginRight: 5 }}>
              <img src={clockIcon} />
            </span>
            <span>{staticService.findByAlias("buttonLater")}</span>
          </Button>
        </Dropdown>
      </div>
    );
  }

  return null;
}
