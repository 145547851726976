import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseRegisterFormTemplate } from "./base-form.component";
import staticService from "../../../services/static.service";
import { explode, storage } from "../../../shared";
import { actions } from "react-redux-form";
import { register } from "../../../actions";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as types from "../../../actions";

class CodeOfConductComponent extends BaseRegisterFormTemplate {
  constructor(props) {
    super(props);
    this.state = {
      mainTitle: staticService.findByAlias("codeOfConductMain"),
      subTitle: staticService.findByAlias("codeOfConduct"),
      continueDisabled: true,
      redirectPath: false,
      files: [],
    };

    this.back = this.back.bind(this);
    this.isActive = this.isActive.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.continue = this.continue.bind(this);
    this.backClicked = false;
  }

  componentDidMount() {
    document.body.classList.add("gh-dashboard");
    const { certificateFilesModel, aocFilesModel, evidenceFilesModel } = this.props;
    const list = [];
    const docs = list.concat(certificateFilesModel, aocFilesModel, evidenceFilesModel);
    this.setState({ files: docs });
  }

  continue() {
    this.onSubmit(this.props.userModel);
  }

  checkboxClicked() {
    this.setState((prevState) => ({
      continueDisabled: !prevState.continueDisabled,
    }));
  }

  getCodeConductAlias() {
    return `${this.props.userModel.type.toUpperCase()}_CODE_CONDUCT`;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.context.store.dispatch(actions.setPending("userRegisterModel", false));
    }

    if (this.props.isRegistered !== nextProps.isRegistered) {
      this.setState({ redirectPath: "/dashboard" });
    }
  }

  filterEmails(emails) {
    return emails.reduce((prev, next) => {
      if (next && next !== "") {
        prev.push(next);
      }
      return prev;
    }, []);
  }

  isActive(prop) {
    return this.props && (this.props[prop] || this.props[prop] > 0) ? "checked" : "not-checked";
  }

  onSubmit(user) {
    this.props
      .register(
        {
          ...user,
          helicopters: this.props.helicopters.map((heli) => {
            return {
              ...heli,
              date_last_refurbished: explode(heli.date_last_refurbished),
            };
          }),
        },
        this.state.files,
      )
      .then(() => {
        storage.clearByKeys([
          "userRegisterModel",
          "invitationsModel",
          "certificateFilesModel",
          "aocFilesModel",
          "evidenceFilesModel",
          "itemsForGrid",
          "itemsForServer",
        ]);
        this.context.store.dispatch(actions.reset("userRegisterModel"));
        this.context.store.dispatch(actions.reset("invitationsModel"));
        this.context.store.dispatch(actions.reset("certificateFilesModel"));
        this.context.store.dispatch(actions.reset("aocFilesModel"));
        this.context.store.dispatch(actions.reset("evidenceFilesModel"));
        this.context.store.dispatch(actions.reset("fleetModels"));
        this.context.store.dispatch(actions.change("filesExistState", false));
        this.context.store.dispatch({
          type: types.LOAD_CONDITION_SUCCESS,
          data: [],
        });
        this.backClicked = false;
      });
  }

  back() {
    this.context.store.dispatch({
      type: types.ACCOUNT_REMOTE_VALIDATION,
      user: { user_validation: 0 },
    });
    return this.props.back();
  }

  get subTitleDesktop() {
    return (
      <ul data-uk-tab className={"disabled-list gh-uk-tab"}>
        {this.fillExtraSpaces}
        <li className="uk-active uk-width-1-4@s uk-width-1-4@m">
          <a href="#" className="uk-text-left">
            {this.state.subTitle}
          </a>
        </li>
      </ul>
    );
  }

  get subTitle() {
    return (
      <div>
        <div className={"uk-hidden@m"}>{this.subTitleMobile}</div>
        <div className={"uk-visible@m"}>{this.subTitleDesktop}</div>
      </div>
    );
  }

  get operatorExplanationParagraph() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("documentUploadOperator"),
        }}
        className={["gh-alert-without-bg", this.operatorCss].join(" ")}
      />
    );
  }

  get brokerExplanationParagraph() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("documentUploadBroker"),
        }}
        className={["gh-alert-without-bg", this.css].join(" ")}
      />
    );
  }

  get formContent() {
    return (
      <div>
        <div
          className={"gh-conduct-container"}
          dangerouslySetInnerHTML={{
            __html: this.props.conditions.find(
              (condition) => condition.type === this.getCodeConductAlias(),
            ).description,
          }}
        />
        <hr />
        <div className={"uk-margin-medium-top uk-margin-medium-bottom uk-flex"}>
          <input
            type="checkbox"
            onChange={() => {
              this.checkboxClicked();
            }}
            className={"uk-checkbox uk-margin-small-right uk-margin-remove-top"}
            checked={!this.state.continueDisabled}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("agreeCodeOfConduct"),
            }}
          />
        </div>
        <hr className="gh-divider" />
        <div data-uk-grid className="uk-flex uk-flex-between gh-register-btns">
          <div>{this.getBackButtonTemplate()}</div>
          <button
            onClick={() => {
              this.continue();
            }}
            disabled={this.state.continueDisabled}
            className="uk-button uk-button-primary"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("finishLabel"),
              }}
            />
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.redirectPath) {
      return super.render();
    }
    return <Redirect to={this.state.redirectPath} />;
  }
}

CodeOfConductComponent.propTypes = {
  visible: PropTypes.bool,
};

CodeOfConductComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    conditions: state.termsCondition,
    userModel: state.userRegisterModel,
    certificateFilesModel: state.certificateFilesModel,
    aocFilesModel: state.aocFilesModel,
    evidenceFilesModel: state.evidenceFilesModel,
    userRegisterModel: state.forms.userRegisterModel,
    helicopters: state.fleetModels,
    fleet: state.fleetModels.length,
    invitations: state.invitationsModel,
    invitation: state.invitationsModel.length,
    account: state.userRegisterModel.email !== "" || storage.get("userRegisterModel") !== undefined,
    isRegistered: !!state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      register,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(CodeOfConductComponent);
export { COMPONENT as CodeOfConductComponent };
