import React from "react";
import PropTypes from "prop-types";

export const MiniTabComponent = ({ visible, selectedView, onClick, tabs = [], itemsCount }) => {
  return visible ? (
    <div className="uk-flex uk-flex-center uk-margin-remove-bottom">
      <div className="gh-flex-group gh-button-group mini-tab">
        {tabs.length &&
          tabs.map((tab, index) => {
            return (
              <a
                key={index}
                className={
                  selectedView === tab.view
                    ? "uk-button gh-mini-tab-btn uk-padding-remove uk-active"
                    : "uk-button gh-mini-tab-btn uk-padding-remove"
                }
                onClick={onClick.bind(null, tab.view)}
              >
                <span>{tab.title}</span>
                {itemsCount && itemsCount[index].count && itemsCount[index].count !== 0 ? (
                  <span className={`gh-item-count ${selectedView === tab.view ? "active" : ""}`}>
                    {itemsCount[index].count}
                  </span>
                ) : (
                  ""
                )}
              </a>
            );
          })}
      </div>
    </div>
  ) : null;
};

MiniTabComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  tabs: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedView: PropTypes.string,
};
