import PropTypes from "prop-types";
import React from "react";

const Tab = ({ children }) => {
  return <div>{children}</div>;
};

Tab.propTypes = {
  children: PropTypes.object,
};
export default React.memo(Tab);
