import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { config } from "../../../configs";
import Auth from "../../../services/auth";
import { logout } from "../../../actions";

class LogoutComponent extends React.Component {
  componentDidMount() {
    this.context.store.dispatch(logout());
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error === "token_invalid") {
      Auth.logout();
    }
  }

  render() {
    const { error, isLogout } = this.props;

    if (error === "token_invalid") return <Redirect to={config.redirectToLogin} />;
    if (isLogout) return <Redirect to={config.redirectToLogin} />;

    return <div data-uk-spinner="ratio: 3" className={"uk-position-medium uk-position-center"} />;
  }
}

LogoutComponent.contextTypes = {
  store: PropTypes.object,
};

LogoutComponent.propTypes = {
  isLogout: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLogout: !!state.auth.logout,
    error: state.errorObj && state.errorObj.error,
  };
}

const COMPONENT = connect(mapStateToProps)(LogoutComponent);
export { COMPONENT as LogoutComponent };
