import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";
import clsx from "clsx";
import Button from "../../Button";

const EventInformationContent = (props) => {
  const { event, handleOpenModal, hideEnquireForm } = props;
  const [isShowMoreActive, setIsShowMoreActive] = React.useState(null);
  const environment = process.env.NODE_ENV;

  const envURL = {
    development: "/tours/get-a-helicopter-to-glastonbury-festival",
    demo: "/tours/london-glastonbury-helicoptertransfer",
    production: "/tours/london-glastonbury-helicoptertransfer",
  };

  React.useEffect(() => {
    const eventContentHeight = document.getElementById("event-content-block")?.scrollHeight;

    if (eventContentHeight > 1070 && isShowMoreActive === null) {
      setIsShowMoreActive(true);
    }
  }, [event]);

  const handleShowMore = (e) => {
    e.preventDefault();
    setIsShowMoreActive(false);
  };

  return (
    <div
      id="event-content-block"
      className={clsx(
        "uk-background-default gh-padding-normal gh-padding-bottom gh-event-info-content",
        {
          "show-more": isShowMoreActive,
          "gh-event-without-form": hideEnquireForm,
        },
      )}
    >
      <div className="gh-padding-small-bottom">
        <h1 className="uk-width-1-1 gh-text-slim uk-margin-remove gh-single-title">{event.name}</h1>
        <div className="uk-width-1-1 gh-city-event-title">
          {event.town}, {event.country}
        </div>
      </div>
      {event.occurrence !== null ? (
        <div className="gh-margin-normal-bottom">
          <div
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(event.occurrence),
            }}
          />
        </div>
      ) : (
        ""
      )}

      <div className="gh-custom-event-header">
        <div className="gh-custom-event-header-info">
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-6@s">
              <div
                className="gh-title-events"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("eventDatesLabel"),
                }}
              />
            </div>
            <div className="uk-width-1-1 uk-width-5-6@s gh-grid-small-text-margin">
              <div className="gh-event-dates-inside">
                <span
                  className={`gh-event-big-text ${event.is_outdated ? "gh-outdated-event" : ""}`}
                >
                  {event.dates}
                </span>
                {event.is_outdated ? (
                  <div
                    className="gh-future-dates gh-future-dates-inside"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("futureDatesReleased"),
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div data-uk-grid className="gh-grid-margin-small-top">
            <div className="uk-width-1-1 uk-width-1-6@s">
              <div
                className="gh-title-events"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("eventVenueLabel"),
                }}
              />
            </div>
            {event.venue !== null ? (
              <div className="uk-width-1-1 uk-width-5-6@s gh-grid-small-text-margin">
                {event.venue_url !== null ? (
                  <a
                    className="gh-event-big-text gh-text-color-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={event.venue_url}
                  >
                    {event.venue}
                  </a>
                ) : (
                  <div className="gh-event-big-text">{event.venue}</div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div data-uk-grid className="gh-grid-margin-small-top">
            <div className="uk-width-1-1 uk-width-1-6@s">
              <div
                className="gh-title-events"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("eventTypeLabel"),
                }}
              />
            </div>
            {event.type !== null ? (
              <div className="uk-width-1-1 uk-width-5-6@s gh-margin-last">
                <span className="gh-event-big-text">{event.type}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {!hideEnquireForm ? (
            <div className="gh-grid-margin-small-top uk-hidden@l">
              <button
                className="uk-width-1-1 uk-width-1-3@s uk-button uk-button-primary gh-open-enquire-btn"
                onClick={handleOpenModal}
              >
                {staticService.findByAlias("priceEstimateBtnLabel")}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        {hideEnquireForm ? (
          <div className="gh-custom-event-header-button">
            <Button color="success" onClick={() => (window.location.href = envURL[environment])}>
              {staticService.findByAlias("reserveHeliShuttle")}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>

      <hr />

      <div className="gh-grid-margin-normal-top">
        {event.event_introduction ? (
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.event_introduction,
            }}
          />
        ) : (
          ""
        )}
      </div>

      <div className="gh-grid-margin-normal-top">
        <h2
          className="gh-title-events gh-padding-small-bottom"
          dangerouslySetInnerHTML={{
            __html: event.description_heading || staticService.findByAlias("eventDescription"),
          }}
        />
        <div
          className="gh-event-small-text gh-grid-margin-small-bottom"
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
        {event.description && event.btn_label !== null ? (
          <a
            className="gh-button-link uk-button uk-button-default"
            target="_blank"
            rel="noopener noreferrer"
            href={event.btn_url}
          >
            {event.btn_label}
          </a>
        ) : (
          ""
        )}
      </div>

      {hideEnquireForm ? (
        <div className="uk-flex uk-flex-center">
          <Button color="success" onClick={() => (window.location.href = envURL[environment])}>
            {staticService.findByAlias("reserveHeliShuttle")}
          </Button>
        </div>
      ) : (
        ""
      )}

      {event.helicopter_relevance_description !== null ? (
        <div className="gh-grid-margin-normal-top">
          <h2
            className="gh-padding-small-bottom gh-title-events"
            dangerouslySetInnerHTML={{
              __html:
                event.helicopter_relevance_heading || staticService.findByAlias("helicoptersTitle"),
            }}
          />
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.helicopter_relevance_description,
            }}
          />
          {event.helicopter_relevance_btn_label && (
            <a
              className="gh-button-link uk-button uk-button-default"
              target="_blank"
              rel="noopener noreferrer"
              href={event.helicopter_relevance_btn_url}
            >
              {event.helicopter_relevance_btn_label}
            </a>
          )}
        </div>
      ) : (
        ""
      )}

      {event.landing_information_content !== null ? (
        <div className="gh-grid-margin-normal-top">
          <h2
            className="gh-padding-small-bottom gh-title-events"
            dangerouslySetInnerHTML={{
              __html:
                event.landing_information_heading ||
                staticService.findByAlias("landingInformation"),
            }}
          />
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.landing_information_content,
            }}
          />
          {event.landing_information_btn_label && (
            <a
              className="gh-button-link uk-button uk-button-default"
              target="_blank"
              rel="noopener noreferrer"
              href={event.landing_information_btn_url}
            >
              {event.landing_information_btn_label}
            </a>
          )}
        </div>
      ) : (
        ""
      )}
      {event.optional_heading !== null ? (
        <div className="gh-grid-margin-normal-top">
          <h2
            className="gh-padding-small-bottom gh-title-events"
            dangerouslySetInnerHTML={{
              __html: event.optional_heading,
            }}
          />
          <div
            className="gh-event-small-text gh-grid-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: event.optional_text,
            }}
          />
          {event.optional_text &&
          event.optional_heading &&
          event.optional_text_btn_label !== null ? (
            <a
              className="gh-button-link uk-button uk-button-default"
              target="_blank"
              rel="noopener noreferrer"
              href={event.optional_text_btn_url}
            >
              {event.optional_text_btn_label}
            </a>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {!hideEnquireForm ? (
        <div className="gh-grid-margin-small-top uk-hidden@l">
          <button
            className="uk-width-1-1 uk-width-1-3@s uk-button uk-button-primary gh-open-enquire-btn"
            onClick={handleOpenModal}
          >
            {staticService.findByAlias("priceEstimateBtnLabel")}
          </button>
        </div>
      ) : (
        ""
      )}

      {hideEnquireForm ? (
        <div className="uk-flex uk-flex-center">
          <Button color="success" onClick={() => (window.location.href = envURL[environment])}>
            {staticService.findByAlias("reserveHeliShuttle")}
          </Button>
        </div>
      ) : (
        ""
      )}

      {isShowMoreActive && (
        <a className="gh-event-show-more-btn" onClick={handleShowMore}>
          Show More
        </a>
      )}
    </div>
  );
};

EventInformationContent.propTypes = {
  event: PropTypes.object,
  handleOpenModal: PropTypes.func,
  hideEnquireForm: PropTypes.bool,
};

EventInformationContent.defaultProps = {
  event: {},
};

export default EventInformationContent;
