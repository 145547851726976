export default class AbstractService {
  constructor() {
    this.visible = false;
    this.disabled = false;
    this.onChange = (info) => {};
  }

  show() {
    this.visible = true;
    this.onChange();
  }

  hide() {
    this.visible = false;
    this.resetProps();
    this.onChange();
  }

  setProp(prop, value) {
    this[prop] = value;
    return this;
  }

  success(info = {}) {
    this.hide();
  }

  resetProps() {}
}
