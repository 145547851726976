import React from "react";
import PropTypes from "prop-types";
import { OverlayPopup } from "../overlay/overlay-popup";
import { config } from "../../configs/app-config";
import auth from "../../services/auth";

class FilePreviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTimeOpen: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.firstTimeOpen;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible && nextProps.file) {
      document.body.style.overflowY = "hidden";
      this.setState({ firstTimeOpen: nextProps.visible });
    }
  }

  componentWillUnmount() {
    if (this.props.onDestroy) this.props.onDestroy();
  }

  onClose() {
    document.body.style.overflowY = "auto";
    this.props.onClose();
  }

  get previewComponent() {
    if (this.props.file) {
      const src = `${config.apiDomain}/document/preview/${
        this.props.file.id
      }?token=${auth.getToken()}`;

      switch (this.props.file.mime) {
        case "application/pdf":
          return <iframe src={src}></iframe>;
        default:
          return (
            <div className={"uk-text-center gh-preview-img"}>
              <img src={src} alt={this.props.file.name} data-uk-img />
            </div>
          );
      }
    }
    return null;
  }

  get toggleClass() {
    return this.props.visible ? "full shown" : "";
  }

  render() {
    return (
      <OverlayPopup
        isOpen={this.props.visible}
        classNames={["overlay", this.toggleClass].join(" ")}
      >
        <div className={"gh-file-preview uk-position-relative"}>
          <div data-uk-body className={"bodyContainer"}>
            {this.previewComponent}
          </div>
          <div className={"footer uk-text-center"}>
            <button
              type={"button"}
              onClick={this.onClose.bind(this)}
              className={"uk-button uk-button-default"}
              dangerouslySetInnerHTML={{ __html: "Close" }}
            ></button>
          </div>
        </div>
      </OverlayPopup>
    );
  }
}

FilePreviewComponent.propTypes = {
  classNames: PropTypes.string,
  file: PropTypes.object,
  options: PropTypes.object,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export { FilePreviewComponent };
