import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addQuote,
  clearQuoteDetail,
  getPurposes,
  getQuoteById,
  resetQuoteDetail,
  updateQuoteDetail,
} from "../../../actions";
import staticService from "../../../services/static.service";
import { ModalComponent } from "../../../shared";
import { ReviewLegsSummaryComponent } from "../user-online/broker/review";
import AccordionComponent from "../../common/AccordionComponent";
import LocationInfo from "../../common/LocationInfo";
import QuoteDepartureInfo from "../../common/QuoteDepartureInfo";
import QuotePassengersInfo from "../../common/QuotePassengersInfo";
import QuoteEstimate from "../../common/QuoteEstimate";
import QuoteAircrafts from "../../common/QuoteAircrafts";
import QuotePrice from "../../common/QuotePrice/quotePrice";
import AdditionalInformation from "../../common/AdditionalInformation";
import TermsAndConditions from "../../common/TermsAndConditions/termsAndConditions";
import CheckboxFuncComponent from "../../common/CheckboxFuncComponent";
import objectValuesToNumber from "../../../utils/objectValuesToNumber";

import "./previewQuote.css";
import BackLink from "../../common/backlink";
import auth from "../../../services/auth";
import ViewDateTime from "../../common/ViewDateTime";
import moment from "moment";
import { config } from "../../../configs";
import UploadFiles from "../../forms/register/upload-files.component";
import ReviewGeneralInformationComponent from "../user-online/broker/review/review-general-information.component";

const PreviewQuote = (props) => {
  const {
    editingQuote,
    getPurposes,
    screenDimensions,
    history,
    updateQuoteDetail,
    addQuote,
    clearQuoteDetail,
    location,
  } = props;
  const historyState = history.location.state;
  const confirmQuoteModal = useRef();

  useEffect(() => {
    if (
      !editingQuote ||
      (!Object.keys(editingQuote).length && historyState && historyState.editingQuote)
    ) {
      updateQuoteDetail(historyState.editingQuote);
    }
  }, [historyState]);

  const [showConfirmError, setShowConfirmError] = useState(false);
  const [flightPurposes, setFlightPurposes] = useState([]);

  useEffect(() => {
    getPurposes().then((res) => setFlightPurposes(res));
    window.scrollTo(0, 0);
  }, []);

  const submitQuote = () => {
    if (editingQuote.is_agree && editingQuote.is_information_confirmed) {
      const onSuccess = () => {
        history.push("/flights/quotes/filter/all");
      };

      addQuote(
        {
          ...editingQuote,
          quote_price: objectValuesToNumber(editingQuote.quote_price),
          booking_request_id: editingQuote.booking_request_id || editingQuote.id,
          booking_request: editingQuote.booking_request,
        },
        onSuccess,
      );
    } else {
      setShowConfirmError(true);
    }
  };

  const updateQuote = (key, value) => {
    updateQuoteDetail({ ...editingQuote, [key]: value });
  };

  useEffect(() => {
    return function cleanup() {
      clearQuoteDetail();
    };
  }, []);

  const backObj = {
    pathname:
      location.state.formPath.pathname ||
      `/marketplace/trip/quote/${editingQuote.booking_request_id}`,
    search: location.state.formPath.search,
    state: { editingQuote: editingQuote, originPath: location.state.originPath },
  };

  const handleOpenModal = () => {
    confirmQuoteModal.current.open();
  };

  if (!editingQuote || !Object.keys(editingQuote).length) return <div>Loading...</div>;

  const legAddresses = editingQuote.legs.filter((l) => l.from_coords && l.to_coords);

  return (
    <div className="gh-review-quote">
      <div className="gh-short-nav">
        <div className="uk-container">
          <div className="uk-flex uk-flex-between">
            <BackLink to={backObj}>Back</BackLink>
          </div>
        </div>
      </div>

      <section>
        <div className="uk-container">
          <div>
            <h3>{staticService.findByAlias("quoteReview")}</h3>
          </div>

          <ReviewGeneralInformationComponent
            requestForm={editingQuote}
            purposes={flightPurposes}
            brokerCompany={
              editingQuote.broker_company || history.location.state.originalQuote.broker_company
            }
          />
          <AccordionComponent
            items={editingQuote.legs}
            isOpen={true}
            flightType={editingQuote.flight_type}
          >
            {editingQuote.legs.map((item, index) => (
              <div key={index}>
                {/*LOCATION*/}
                <div className="gh-legs-location-wrapper">
                  <div className="gh-legs-location-wrapper-item">
                    <LocationInfo
                      leg={item}
                      legIcon={item.from_source}
                      keyDirection="from"
                      direction="from_location"
                      additionalDetails={false}
                      showOperatorProvideOptions={false}
                    />
                  </div>
                  <div className="gh-legs-location-wrapper-item">
                    <LocationInfo
                      leg={item}
                      legIcon={item.to_source}
                      keyDirection="to"
                      direction="to_location"
                      additionalDetails={false}
                      showOperatorProvideOptions={false}
                    />
                  </div>
                </div>

                {/*ESTIMATE ROW*/}
                <div className="gh-separator-line" />
                <QuoteEstimate leg={item} readOnly={true} />

                {/*DEPARTURE INFO*/}
                <div className="gh-separator-line" />
                <QuoteDepartureInfo
                  editingQuote={editingQuote}
                  leg={item}
                  legIndex={index}
                  readOnly={true}
                />

                {/*PASSENGERS INFO*/}
                <div className="gh-separator-line" />
                <QuotePassengersInfo
                  leg={item}
                  legIndex={index}
                  {...props}
                  readOnly={true}
                  isWillProvideLaterDisplayed={false}
                />

                {/*AIRCRAFT */}
                <div className="gh-separator-line" />
                <QuoteAircrafts
                  {...props}
                  leg={item}
                  index={index}
                  readOnly={true}
                  editingQuote={editingQuote}
                  aircraftKey="selected_aircrafts"
                />

                {item.selected_additional_aircrafts[0] &&
                Object.keys(item.selected_additional_aircrafts[0]).length > 1 ? (
                  <QuoteAircrafts
                    {...props}
                    leg={item}
                    index={index}
                    readOnly={true}
                    editingQuote={editingQuote}
                    aircraftKey="selected_additional_aircrafts"
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
          </AccordionComponent>

          {legAddresses ? (
            <ReviewLegsSummaryComponent
              // handleAssignPad={handleAssignPad}
              screenDimensions={screenDimensions}
              requestForm={editingQuote}
              legAddresses={legAddresses}
              review={true}
              returnFlight={editingQuote.flight_type === "2"}
            />
          ) : (
            ""
          )}

          {/*QUOTE PRICE*/}
          <QuotePrice editingQuote={editingQuote} flightPurposes={flightPurposes} readOnly={true} />

          {/*VIEW EXPIRATION QUOTE*/}

          {editingQuote.expiration_date ? (
            <div className="gh-simple-section gh-additional-info-wrapper">
              <div className="gh-additional-info-title" style={{ marginTop: 0 }}>
                {staticService.findByAlias("quoteExpirationTitle")}
              </div>

              <div className="gh-quote-view-expiration-date">
                <ViewDateTime
                  view="date"
                  label="expirationDate"
                  dateTime={moment(editingQuote.expiration_date).format(config.dateFormat)}
                />

                <ViewDateTime
                  view="time"
                  label="expirationTime"
                  dateTime={moment(editingQuote.expiration_date).format(config.timeFormat)}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {/*ADDITIONAL INFORMATION*/}
          <AdditionalInformation editingQuote={editingQuote} readOnly={true} />

          {/*TERMS AND CONDITIONS*/}
          {auth.getHeliServiceTerms() ? (
            <TermsAndConditions request={editingQuote} />
          ) : editingQuote.helicopter_services_terms.length ? (
            <div className="uk-margin-top">
              <UploadFiles
                model={editingQuote.helicopter_services_terms}
                modelName="quoteFormModel.helicopterServicesTerms"
                uploadTitle="flightContract"
                docType={1}
                docs={editingQuote.helicopter_services_terms}
                entity="company_document"
                multiple={false}
                onlyOneFile={true}
                hiddenUpload={true}
                hideRemove={true}
              />
            </div>
          ) : (
            ""
          )}

          <div className="gh-request-details-buttons">
            <button
              type="button"
              style={{ backgroundColor: "#fff" }}
              className="uk-button gh-request-gen-btns"
              onClick={() => {
                history.push(backObj);
              }}
            >
              <span>Back</span>
            </button>

            <button
              type="button"
              className="uk-button uk-button-primary gh-request-gen-btns"
              onClick={handleOpenModal}
            >
              <span>Submit Quote</span>
            </button>
          </div>
        </div>
      </section>

      <ModalComponent
        title={staticService.findByAlias("previewQuoteModalTitle")}
        ref={confirmQuoteModal}
        id={"confirmQuoteModalId"}
        // modalClass="gh-decline-modal"
      >
        <div>
          <div className="uk-flex uk-flex-middle">
            <CheckboxFuncComponent
              alias={
                auth.getHeliServiceTerms()
                  ? "bookingConfirmModalContent"
                  : "bookingConfirmModalContentShort"
              }
              checked={editingQuote.is_agree}
              onClick={() => {
                updateQuote("is_agree", !editingQuote.is_agree);
              }}
            />
          </div>
          <div className="uk-margin-top uk-flex uk-flex-middle">
            <CheckboxFuncComponent
              alias="tickOnConfirmOpsBooking"
              checked={editingQuote.is_information_confirmed}
              onClick={() => {
                updateQuote("is_information_confirmed", !editingQuote.is_information_confirmed);
              }}
            />
          </div>
          <div className="uk-margin-medium-top uk-flex uk-flex-middle">
            {/*<span dangerouslySetInnerHTML={staticService.findByAlias("operatorUponConfirmation")} />*/}
          </div>
          {showConfirmError ? <div className="gh-message-error">Pleas confirm!</div> : ""}
          <div className="uk-modal-footer gh-modal-footer uk-text-center gh-modal-grid">
            <button
              style={{ minHeight: 50, marginBottom: window.innerWidth < 769 ? 15 : 0 }}
              type="button"
              className="uk-button uk-modal-close uk-button-default"
              onClick={() => {
                confirmQuoteModal.current.hide();
              }}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ minHeight: 50 }}
              type="button"
              className="uk-button uk-button-primary gh-request-gen-btns"
              onClick={submitQuote}
              disabled={!editingQuote.is_agree || !editingQuote.is_information_confirmed}
            >
              <span>Submit Quote</span>
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

PreviewQuote.propTypes = {
  editingQuote: PropTypes.object,
  getPurposes: PropTypes.func,
  screenDimensions: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
  history: PropTypes.object,
  addQuote: PropTypes.func,
  clearQuoteDetail: PropTypes.func,
  location: PropTypes.object,
};

const mapStateToProps = (store) => ({
  editingQuote: store.setQuoteDetail.editingQuoteDetail,
  screenDimensions: store.screenDimensions,
});

export default connect(mapStateToProps, {
  getQuoteById,
  resetQuoteDetail,
  getPurposes,
  updateQuoteDetail,
  addQuote,
  clearQuoteDetail,
})(PreviewQuote);
