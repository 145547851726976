import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./companyInformation.css";
import staticService from "../../../../../services/static.service";
import LiveChatComponent from "../../../../common/LiveChatComponent/liveChatComponent";
import auth from "../../../../../services/auth";
import { Link } from "react-router-dom";

const CompanyInformation = (props) => {
  const {
    quoteId,
    commentsCount,
    seenBy,
    companyName,
    aocHolder,
    aocNumber,
    address,
    website,
    operatorChatLabelAlias,
    brokerChatLabelAlias,
    isBrokerAllowedToInteract,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="gh-quote-price gh-quote-details-section-card">
      <h4 className="gh-booking-details-title">
        {staticService.findByAlias(auth.isOperator() ? "brokerCompanyInfo" : "operatorCompanyInfo")}
      </h4>

      <div className="gh-company-information-content">
        <div className="gh-company-information-content-col">
          <div className="gh-company-information-content-key">
            {staticService.findByAlias("companyName")}
          </div>
          <div className="gh-company-information-content-value">{companyName || "---"}</div>
        </div>

        {auth.isOperator() ? (
          <div className="gh-company-information-content-col">
            <div className="gh-company-information-content-key">
              {staticService.findByAlias("website")}
            </div>
            {/*<div className="gh-company-information-content-value">{website <Link/ || "---"}</div>*/}
            <div className="gh-company-information-content-value">
              {website ? (
                <Link
                  to={website}
                  type="link"
                  target="_blank"
                  variant="text"
                  className="uk-company-link"
                >
                  {website}
                </Link>
              ) : (
                "---"
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        {auth.isOperator() ? (
          <div className="gh-company-information-content-col">
            <div className="gh-company-information-content-key">
              {staticService.findByAlias("address")}
            </div>
            <div className="gh-company-information-content-value">{address || "---"}</div>
          </div>
        ) : (
          ""
        )}

        {auth.isBroker() ? (
          <div className="gh-company-information-content-col">
            <div className="gh-company-information-content-key">
              {staticService.findByAlias("aocHolder")}
            </div>
            <div className="gh-company-information-content-value">{aocHolder || "---"}</div>
          </div>
        ) : (
          ""
        )}

        {auth.isBroker() ? (
          <div className="gh-company-information-content-col">
            <div className="gh-company-information-content-key">
              {staticService.findByAlias("aocNumber2")}
            </div>
            <div className="gh-company-information-content-value">{aocNumber || "---"}</div>
          </div>
        ) : (
          ""
        )}

        {quoteId ? (
          <div className="gh-company-information-content-col">
            <div className="gh-company-information-wrapper-chat">
              <LiveChatComponent
                quoteId={quoteId}
                commentsCount={commentsCount && !seenBy ? commentsCount : 0}
                userType="broker_admin"
                titleAlias="quoteDiscussionBroker"
                additionalContentTitleAlias="addAnyQuestions"
                operatorChatLabelAlias={operatorChatLabelAlias}
                brokerChatLabelAlias={brokerChatLabelAlias}
                disabled={!isBrokerAllowedToInteract}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

CompanyInformation.propTypes = {
  quoteId: PropTypes.number,
  commentsCount: PropTypes.number,
  seenBy: PropTypes.bool,
  companyName: PropTypes.string,
  aocHolder: PropTypes.string,
  aocNumber: PropTypes.string,
  address: PropTypes.string,
  website: PropTypes.string,
  operatorChatLabelAlias: PropTypes.string,
  brokerChatLabelAlias: PropTypes.string,
  isBrokerAllowedToInteract: PropTypes.bool,
};

export default CompanyInformation;
