import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form } from "react-redux-form";
import { updateProfile } from "../../../actions";
import { UserProfileFieldset } from "../fieldsets";
import staticService from "../../../services/static.service";
import auth from "../../../services/auth";
import { profileTabs } from "../../../configs";
import { fireTracking } from "../../../shared";
import { MenuComponent } from "../../pages/user-online/common";

const modelName = "editProfileModel";

class EditProfileFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    context.store.dispatch(actions.reset(modelName));
    const items = profileTabs.filter((tab) => tab.roles.indexOf(auth.getRole()) !== -1);
    this.state = {
      selectedTab: items[0],
      tabs: items.map((menu) => {
        menu.title = staticService.findByAlias(menu.alias);
        return menu;
      }),
      disabledBtn: true,
    };
    fireTracking(props.location.pathname);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const user = auth.getUser();
    this.context.store.dispatch(
      actions.change(modelName, {
        email: user.email,
        last_name: user.last_name,
        first_name: user.first_name,
        phone: user.phone,
      }),
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.editProfileModel.first_name &&
      this.props.editProfileModel !== nextProps.editProfileModel
    ) {
      this.setState({ disabledBtn: false });
    } else {
      this.setState({ disabledBtn: true });
    }
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("editProfileModel"));
  }

  submit(form) {
    this.setState({ disabledBtn: true });
    return this.props.updateProfile(form);
  }
  render() {
    const { disabledBtn } = this.state;
    return (
      <div data-uk-grid>
        <div className={"uk-width-1-4@m uk-width-1-1@s"}>
          <MenuComponent items={this.state.tabs} />
        </div>
        <div className="uk-width-1-2@s uk-width-1-3@m gh-account-settings-margin gh-modal-promo-small uk-width-1-2@m uk-width-1-1@s">
          <Form model={modelName} onSubmit={this.submit}>
            <UserProfileFieldset modelName={modelName} />
            <div className="uk-margin-small-top">
              <Control.button
                model={modelName}
                disabled={disabledBtn}
                className={"uk-button uk-button-primary uk-align-center"}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("update"),
                  }}
                />
              </Control.button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

EditProfileFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = ({ editProfileModel }) => {
  return { editProfileModel };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProfile: updateProfile,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(EditProfileFormComponent);
export { COMPONENT as EditProfileFormComponent };
