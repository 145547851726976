import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessageComponent.css";

const ErrorMessageComponent = (props) => {
  const { children, visibility, className } = props;
  if (!visibility) return "";

  return (
    <div className={`validation-errors disabled-list gh-input-errors ${className}`}>{children}</div>
  );
};
ErrorMessageComponent.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  visibility: PropTypes.bool,
};
ErrorMessageComponent.defaultProps = {
  visibility: true,
};
export default ErrorMessageComponent;
