import * as types from "../../actions/actionTypes";
import { GET_MAP_EMPTY_LEGS } from "../../actions/actionTypes";

function emptyLeg(state = [], action) {
  switch (action.type) {
    case types.ADD_EMPTY_LEG:
      return [...state, action.emptyLeg];
    case types.RESET_EMPTY_LEG:
      return [];
    case types.REMOVE_EMPTY_LEG:
      return { event: true };
    default:
      return state;
  }
}

function emptyLegs(state = [], action) {
  switch (action.type) {
    case types.GET_EMPTY_LEGS:
      return [...action.emptyLegs];
    case types.ADD_EMPTY_LEG:
      return [...state, action.emptyLeg];
    default:
      return state;
  }
}

function mapEmptyLegs(state = [], action) {
  switch (action.type) {
    case types.GET_MAP_EMPTY_LEGS:
      return [...action.emptyLegs];
    default:
      return state;
  }
}

function emptyLegDetails(state = {}, action) {
  switch (action.type) {
    case types.GET_EMPTY_LEG_DETAILS:
      return action.emptyLegDetails;
    default:
      return state;
  }
}

export { emptyLeg, emptyLegs, emptyLegDetails, mapEmptyLegs };
