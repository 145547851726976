export const apiDomain = 'https://demo.getheli.com/api';
export const landingSitesDomain = 'https://api.helipaddy.com/api/external/pads';
export const landingSitesApiKey = '$2y$10$w5A90rfLTtywnt4pD.gl5OGiK';
export const websocketPath = 'https://socket-demo.getheli.com';
export const stripePublicKey =  'pk_test_CL3pi5975POi9fakL3Ikex8s';
export const ga = 'UA-20908921-15';
export const environment = 'demo';
export const forum_link = "https://demo.getheli.com/";
export const deployment_version = 4;
export const landingSitesApi = "https://api.helipaddy.com/api/external";
export const staticFilesVersion = 8;
export const iframeToken = "OisytwWGVanOO2xdFpt9";
export const url = "https://external-demo.getheli.com";
export const pdfUri = "https://pdf-demo.getheli.com/";
export const REACT_APP_URL = "https://demo.getheli.com/";
export const IFRAME_APP_URL = "https://external-demo.getheli.com/";
