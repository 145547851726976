import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import moment from "moment";

import { operatorEmptyLegConfig, config, quotesConfig } from "../../../../../configs";
import {
  getEmptyLegs,
  removeEmpty,
  removeEmptyById,
  RESET_EMPTY_LEG,
} from "../../../../../actions";
import {
  ComplexTableComponent,
  replaceAttributesInString,
  prepareQueryStringFromObj,
  prepareQueryAttributes,
} from "../../../../../shared/";
import { PaginationService } from "../../../../../services";
import { ConfirmComponent } from "../../../../common";
import { OperatorBookingEmptyLegsFiltersComponent } from "../../";
import Auth from "../../../../../services/auth";
import GetHeliEvents from "../../../../../services/event.service";
import staticService from "../../../../../services/static.service";

import trashIcon from "../../../../../assets/img/svg/trash.svg";

class OperatorBookingEmptyLegListComponent extends React.Component {
  constructor(props, context) {
    super(props);
    const filters = [];
    this.state = {
      redirect: false,
      selectedRowId: null,
      removeMessage: staticService.findByAlias("removeLegConfirm"),
      filters: filters,
      showFilterIcon: true,
      sortOrder: operatorEmptyLegConfig.defaultOrder,
      isFilterTriggerd: false,
      requestsCount: null,
    };

    GetHeliEvents.setEvent("legs", this.catchSuccessResponse.bind(this));
    GetHeliEvents.setEvent("filter-triggered", () => {
      this.setState({ isFilterTriggerd: true });
    });

    this.paginationService = new PaginationService(
      context.store.dispatch,
      getEmptyLegs,
      config.paginationLimit,
      this.state.filters,
    );
    this.paginationService.setProp("columns", operatorEmptyLegConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(operatorEmptyLegConfig));
    this.paginationService.loadData();
    this.setFailed = this.setFailed.bind(this);
    this._mounted = true;
    this.tableAction = this.tableAction.bind(this);
    this.removeLeg = this.removeLeg.bind(this);
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState && this._mounted) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  setFailed(items) {
    if (items) {
      if (items.length === 0) {
        this.setState({ filterFailed: "failed" });
      } else {
        this.setState({ filterFailed: "" });
      }
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  prepareRows(items) {
    return items.map((item) => {
      item.requests = (
        <Link to={"/flights/empty-legs/filter/requests/" + item.id}>
          <span className={"uk-label uk-label-success"}>{item.requests}</span>
        </Link>
      );
      item.date = moment(item.date).format("DD MMMM, YYYY");
      item.helicopter =
        item.aircraft.registration_number +
        ", " +
        item.aircraft.manufacturer +
        " " +
        item.aircraft.aircraft_model +
        " " +
        `(${staticService.findByAlias("maxPassengersLabel")} ${item.aircraft.max_passengers})`;
      return item;
    });
  }

  removeRow(index, e) {
    if (!!Auth.isVerified() === false) {
      e.preventDefault();
    } else {
      const model = this.paginationService.items[index];
      this.setState({
        selectedRowId: model.id,
        rowIndex: index,
      });
      this.confirm.openModal();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { requestsCount } = this.state;
    const { items } = nextProps;

    if (
      this.paginationService.hasLoaded ||
      JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items)
    ) {
      if (nextProps.filters.refreshCollection) {
        this.paginationService.reloadItems();
        this.setFailed(nextProps.items);
        this.context.store.dispatch(
          actions.change("emptyLegsFiltersModel.refreshCollection", false),
        );
        let filters = this.state.filters;
        if (this.state.isFilterTriggerd) {
          filters = prepareQueryStringFromObj(nextProps.filters);
        }
        this.paginationService.setProp("filters", filters);
      }

      this.paginationService.populateItems(
        nextProps.items,
        this.prepareRows.bind(this),
        nextProps.isConcat,
      );
    }

    if (!requestsCount && items.length) {
      const count = nextProps.items.map((item) => ({
        count: item.requests.props ? item.requests.props.children : 0,
        id: item.id,
      }));
      this.setState({ requestsCount: count });
    }

    this.setEmptyRowMessage();
  }

  setEmptyRowMessage() {
    if (Auth.getMainRole() === "operator") {
      const button = { label: "My bookings", link: "/flights/my-bookings" };
      let messageId = "empty-row-legs-default";
      this.setState({
        customProps: {
          emptyRowMessage: staticService.findByAlias(messageId),
          emptyRowButton: button,
        },
      });
    }
  }
  tableAction(index) {
    const handleActionClick = (e) => {
      if (!!Auth.isVerified() === false) {
        return e.preventDefault();
      }
    };

    const action = (
      <div>
        <Link
          onClick={handleActionClick}
          style={{
            marginRight: "20px",
            opacity: !!Auth.isVerified() === false ? 0.4 : 1,
          }}
          to={this.props.parentMatch.url + "/" + this.paginationService.items[index].id}
        >
          View
        </Link>

        <div
          style={{ padding: 10, display: "inline-block" }}
          className={`uk-remove btn-style ${
            !!Auth.isVerified() === false ? "gh-disabled-link" : ""
          }`}
          onClick={this.removeRow.bind(this, index)}
        >
          <img src={trashIcon} alt="Remove" />
        </div>
      </div>
    );
    return {
      desktop: <td style={{ verticalAlign: "middle" }}>{action}</td>,
      mobile: action,
    };
  }

  get removeMessage() {
    const { requestsCount, selectedRowId } = this.state;

    if (requestsCount) {
      const request = requestsCount.find((row) => row.id === selectedRowId);
      if (request && request.count > 0) {
        const question = staticService.findByAlias("emtyLegRemoveNotification");
        return replaceAttributesInString(
          {
            N: request.count,
            R: request.count === 1 ? "request" : "requests",
            V: request.count === 1 ? "is" : "are",
          },
          question,
        );
      }
    }

    return this.state.removeMessage;
  }

  handleSorting(column, order) {
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.paginationService.loadData();
    this.context.store.dispatch(actions.change("emptyLegsFiltersModel.refreshCollection", true));
  }

  closeModal() {
    this.confirm.closeModal();
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  removeLeg() {
    const filteredItems = this.paginationService.items.filter(
      (item) => item.id !== this.state.selectedRowId,
    );

    this.props
      .removeEmptyById(this.state.selectedRowId)
      .then(() => {
        this.paginationService.populateItems(filteredItems, null, false);
        this.forceUpdate();
        this.setState({ selectedRowId: null });
        this.closeModal(this.bind);
      })
      .catch(() => {
        this.setState({ selectedRowId: null });
      });
  }

  render() {
    const { items, headers, columns } = this.paginationService;

    return (
      <div>
        <ConfirmComponent
          ref={(confirm) => (this.confirm = confirm)}
          info={this.removeMessage}
          buttonsProps={{
            cancel: {
              title: staticService.findByAlias("cancel"),
              css: "uk-button-default",
            },
            continue: {
              title: staticService.findByAlias("delete"),
              css: "uk-button-danger",
            },
          }}
          modalProps={{
            id: "remove-empty-leg-modal",
            title: staticService.findByAlias("confirmRemoveLegModalTitle"),
            onClose: this.closeModal.bind(this),
            options: {
              clsPage: "",
              bgClose: false,
              escClose: false,
            },
          }}
          onSelect={this.removeLeg}
          onReject={this.closeModal.bind(this)}
        />
        <OperatorBookingEmptyLegsFiltersComponent
          visible={this.props.isFiltersShown}
          pageFilters={this.state.filters}
        />
        <div className={"uk-overflow-auto uk-margin-bottom"}>
          <ComplexTableComponent
            loadMore={this.paginationService.loadMore.bind(this.paginationService)}
            rows={items}
            headers={headers}
            columns={columns}
            showActionColumn={1}
            showLoader={this.paginationService.showLoaderBtn}
            showMoreText={staticService.findByAlias("showMore")}
            actionElements={this.tableAction.bind(this)}
            sortChange={this.handleSorting.bind(this)}
            selectedOrder={this.state.sortOrder}
            firstTimeLoading={this.paginationService.initialLoadState}
            isLoading={true}
            {...this.state.customProps}
          />
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("legs");
    GetHeliEvents.clearEvent("filter-triggered");
    this._mounted = false;
  }
}

OperatorBookingEmptyLegListComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorBookingEmptyLegListComponent.propTypes = {
  isFiltersShown: PropTypes.bool,
  removeEmptyById: PropTypes.func,
  parentMatch: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  items: PropTypes.array,
  filters: PropTypes.object,
  removed: PropTypes.object,
};

function mapStateToProps({ emptyLegs, emptyLeg, emptyLegsFiltersModel, isConcat }) {
  return {
    items: emptyLegs,
    removed: emptyLeg,
    filters: emptyLegsFiltersModel,
    isConcat,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeEmptyById,
      toggleConfirmDialog: (type, visible) => dispatch({ type: type, visible: visible }),
    },
    dispatch,
  );
};

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorBookingEmptyLegListComponent);
export { COMPONENT as OperatorBookingEmptyLegListComponent };
