import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { BlockComponent } from "../../../common";
import { TabComponent, fireTracking, returnRouteUrl, RouteWithSubRoutes } from "../../../../shared";
import { accountTabs, companyTabs } from "../../../../configs";
import auth from "../../../../services/auth";
import staticService from "../../../../services/static.service";
import { ButtonWithBadgeComponent } from "../common";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class OperatorCompanyComponent extends React.Component {
  constructor(props) {
    super(props);
    const items = accountTabs.filter((tab) => tab.roles.indexOf(auth.getRole()) !== -1);

    this.state = {
      selectedTab: items[0],
      tabs: items,
    };
    fireTracking(props.location.pathname);
  }

  onTabClick(tab) {
    this.setState({ selectedTab: tab });
  }

  render() {
    return (
      <section className="profile-page">
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-bottom gh-align-left",
          }}
        >
          <div className="uk-align-left">
            <h3
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("company"),
              }}
            />
          </div>
        </BlockComponent>
        <div className={"uk-margin-bottom"}>
          <ButtonWithBadgeComponent
            route={"/marketplace"}
            classes={["btn-pulse"]}
            visible={true}
            label={staticService.findByAlias("operatorMarketPlaceButton")}
          />
        </div>
        <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
          <TabComponent
            items={this.state.tabs}
            id={"profile-tab"}
            visible={true}
            tabProps={{ className: "uk-tab uk-margin" }}
            onClick={this.onTabClick.bind(this)}
            selected={this.state.selectedTab}
            linkType={"nav"}
          />
          <Switch>
            {this.props.routes &&
              this.props.routes.map((route, i) => {
                return (
                  <RouteWithSubRoutes
                    key={i}
                    title={route.title}
                    {...returnRouteUrl(route, this.props.match.url)}
                  />
                );
              })}
            <Redirect from="/company" exact to="/company/information" />
          </Switch>
        </BlockComponent>
      </section>
    );
  }
}

OperatorCompanyComponent.contextTypes = {
  store: PropTypes.object,
};

const COMPONENT = connect(() => {
  return {};
}, {})(OperatorCompanyComponent);
export { COMPONENT as OperatorCompanyComponent };
