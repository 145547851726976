import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { UserProfileComponent, ButtonWithBadgeComponent } from "../common/";
import staticService from "../../../../services/static.service";

class OperatorProfileComponent extends UserProfileComponent {
  get requestBtn() {
    return (
      <div className={"uk-margin-bottom"}>
        <ButtonWithBadgeComponent
          route={"/marketplace"}
          classes={["btn-pulse"]}
          visible={true}
          label={staticService.findByAlias("operatorMarketPlaceButton")}
        ></ButtonWithBadgeComponent>
      </div>
    );
  }
}

OperatorProfileComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps() {
  return {};
}

const COMPONENT = connect(mapStateToProps)(OperatorProfileComponent);
export { COMPONENT as OperatorProfileComponent };
