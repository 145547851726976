import React, { useEffect } from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import BackLink from "../../common/backlink/backlink.component";
import BookingPaymentDetails from "../user-online/common/booking/BookingPaymentDetails/BookingPaymentDetails";
import QuotePriceSection from "../ViewFullQuoteDetails/components/QuotePriceSection/QuotePriceSection";
import CustomLoader from "../../common/CustomLoader/customLoader";
import CompanyInformation from "./components/CompanyInformation";
import AccordionComponent from "../../common/AccordionComponent";
import BookingAuditItem from "./components/BookingAuditItem";
import QuotePads from "../ViewFullQuoteDetails/components/QuotePads/QuotePads";
import TermsAndConditions from "../../common/TermsAndConditions/termsAndConditions";
import BookingInfoBar from "./components/BookingInfoBar/bookingInfoBar";
import BookingButtons from "./components/BookingButtons";
import auth from "../../../services/auth";
import { connect } from "react-redux";
import { loadUserDashboardStatisticSuccess, updateIdentifier } from "../../../actions";
import "./viewFullBookingDetails.css";

const ViewFullBookingDetails = (props) => {
  const {
    history,
    bookingDetail,
    bookingDetail: {
      booking_request,
      summary,
      audits,
      european_quote_price,
      international_quote_price,
      manual_bank_transfer_quote_price,
      payment,
      payment_method,
      payment_status,
      message_on_booking_page,
      quote_price,
    },
    dashboard,
    loadUserDashboardStatisticSuccess,
    updateIdentifier,
  } = props;

  const isBrokerAllowedToInteract = React.useMemo(() => {
    if (!auth.isOperator()) {
      return bookingDetail.booking_request?.ability_to_edit ?? true;
    }

    return true;
  }, [bookingDetail?.booking_request]);

  /*===========Start Hooks===========*/

  useEffect(() => {
    if (message_on_booking_page) {
      loadUserDashboardStatisticSuccess({ ...dashboard, provisional: message_on_booking_page });
    }

    return () => loadUserDashboardStatisticSuccess({ ...dashboard, provisional: "" });
  }, [message_on_booking_page]);

  /*===========End Hooks===========*/

  /*===========Start Update Identifier===========*/
  const handleUpdateIdentifier = (value) => {
    return updateIdentifier({
      id: bookingDetail.booking_request.id,
      identifier: value,
      status: bookingDetail.booking_request.status,
    });
  };
  /*===========End Update Identifier===========*/

  const handleBack = () => {
    history.goBack();
  };

  if (!Object.keys(bookingDetail).length || !booking_request) return <CustomLoader />;

  const displayTerms =
    auth.getMainRole() === "operator"
      ? auth.getHeliServiceTerms()
      : bookingDetail.terms_status &&
        bookingDetail.terms_status.country_terms_list.helicopter_services_terms;

  return (
    <div className="gh-broker-booking-details">
      <div className="gh-short-nav">
        <div className="uk-container">
          <div className="uk-flex uk-flex-between">
            <BackLink onClick={handleBack}>
              {staticService.findByAlias("backToConfirmedBookingsList")}
            </BackLink>
          </div>
        </div>
      </div>

      <div className="uk-container">
        <div className="gh-booking-title-line">
          <h3 className="gh-booking-layout-title">
            {staticService.findByAlias(
              payment_method === 1 || payment_status === 4
                ? "bookingConfirmation"
                : "provisionalBookingConfirmation",
            )}
          </h3>
        </div>

        {/*BOOKING INFO TOP BAR*/}
        <BookingInfoBar
          bookingId={bookingDetail.id}
          clientId={bookingDetail.booking_request ? bookingDetail.booking_request.identifier : ""}
          status={bookingDetail.status}
          quotePrice={bookingDetail.quote_price}
          handleUpdateIdentifier={handleUpdateIdentifier}
          privateNotes={bookingDetail.private_notes}
          bookingRequestId={booking_request ? booking_request.id : null}
          operatorInvoice={bookingDetail.operator_invoice}
          getHeliInvoice={bookingDetail.get_heli_invoice}
          documents={bookingDetail.documents}
          isBrokerAllowedToInteract={isBrokerAllowedToInteract}
          bookingTerms={
            bookingDetail.terms_status &&
            bookingDetail.terms_status.country_terms_list.helicopter_services_terms
          }
          individualHelicopterServicesTerms={bookingDetail.helicopter_services_terms || []}
        />

        {/*CONTENT*/}
        <div className="gh-broker-booking-details-wrapper">
          <div className="gh-broker-booking-details-col">
            <div className="gh-broker-booking-details-col-content">
              {/*PAYMENT DETAILS*/}
              {bookingDetail.payment_method && bookingDetail.payment_method === 2 ? (
                <section className="gh-quote-price gh-quote-details-section-card">
                  <h4 className="gh-booking-details-title">
                    {staticService.findByAlias("PaymentDetailsPageTitle")}
                  </h4>
                  <BookingPaymentDetails
                    bookingStatus={bookingDetail.status}
                    paymentStatus={bookingDetail.payment_status}
                    bookingID={bookingDetail.id}
                    brokerCompany={bookingDetail.broker_company}
                    paymentInfo={bookingDetail.payment_information}
                    isBrokerAllowedToInteract={isBrokerAllowedToInteract}
                    history={history}
                  />
                </section>
              ) : (
                ""
              )}

              {/*PRICE SECTION*/}
              <QuotePriceSection
                aliasTitle="price"
                renderBy="BrokerBookingDetails"
                bookingDetail={bookingDetail}
                currency={
                  bookingDetail.broker_currency ? bookingDetail.broker_currency.currency : ""
                }
                calculatedPrices={{
                  european_quote_price,
                  international_quote_price,
                  manual_bank_transfer_quote_price,
                }}
                payment={payment}
                history={history}
                isOperatorStripeVerified={bookingDetail?.operator_stripe_verification_status}
                quotePrice={quote_price}
              />

              {/*COMPANY INFORMATION*/}
              <CompanyInformation
                quoteId={booking_request.selectedQuote.id}
                id={bookingDetail.id}
                commentsCount={booking_request.selectedQuote.comments_count}
                seenBy={
                  auth.isBroker()
                    ? booking_request.selectedQuote.seen_by_broker
                    : booking_request.selectedQuote.seen_by_operator
                }
                companyName={summary.company}
                aocHolder={summary.company_aoc}
                aocNumber={summary.number}
                address={summary.address}
                website={summary.website}
                brokerChatLabelAlias="brokerBookingChatModalLabel"
                operatorChatLabelAlias="operatorBookingChatModalLabel"
                isBrokerAllowedToInteract={isBrokerAllowedToInteract}
              />

              {/*BOOKING AUDITS*/}
              <AccordionComponent isOpen={true} title={staticService.findByAlias("audit")}>
                <React.Fragment>
                  {audits?.map((item, index) => (
                    <BookingAuditItem
                      name={item?.user?.full_name}
                      action={item?.action?.name}
                      date={item?.created_at}
                      indexKey={index}
                      key={index}
                    />
                  ))}
                </React.Fragment>
              </AccordionComponent>
            </div>
          </div>

          <div className="gh-broker-booking-details-col">
            <div className="gh-broker-booking-details-col-content">
              {/*LEGS SECTION*/}
              <QuotePads
                pads={bookingDetail.legs}
                flight_type={bookingDetail.booking_request.flight_type}
                readOnly
              />
            </div>
          </div>
        </div>

        {/*TERMS AND CONDITIONS*/}
        {displayTerms ? (
          <TermsAndConditions request={bookingDetail.booking_request} withOutBtn />
        ) : (
          ""
        )}
      </div>

      {/*BUTTONS*/}
      <BookingButtons isBrokerAllowedToInteract={isBrokerAllowedToInteract} {...props} />
    </div>
  );
};

ViewFullBookingDetails.propTypes = {
  history: PropTypes.object,
  bookingDetail: PropTypes.object,
  updateIdentifier: PropTypes.func,
  dashboard: PropTypes.object,
  loadUserDashboardStatisticSuccess: PropTypes.func,
};

const mapStateToProps = (store) => ({
  bookingDetail: store.bookingDetail,
  dashboard: store.dashboard,
});

export default connect(mapStateToProps, { updateIdentifier, loadUserDashboardStatisticSuccess })(
  ViewFullBookingDetails,
);
