import React, { useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";
import timeIcon from "../../../../assets/img/svg/clock.svg";
import { config } from "../../../../configs";
import "./TimePicker.css";

const TimePicker = (props) => {
  const {
    hideIcon,
    placeholder,
    value,
    onChange,
    disabled,
    timeConstraints,
    closeOnSelect,
    className,
    initialViewMode,
    timeFormat,
  } = props;
  const inputRef = useRef();

  const handleChange = (e) => {
    const { hours } = timeConstraints;

    if (hours) {
      if (hours.min && typeof hours.min === "object" && e.isBefore(hours.min)) {
        return onChange(hours.min);
      }

      if (
        (hours.max && typeof hours.max === "object" && e.isSame(hours.max)) ||
        (hours.max && typeof hours.max === "object" && e.isAfter(hours.max)) ||
        (hours.max && typeof hours.max === "object" && e.diff(hours.max, "hours") === 0)
      ) {
        return onChange(moment(hours.max).subtract(1, "h"));
      }
    }

    onChange(e);
  };

  const handleClickOnInput = () => {
    inputRef.current.click();
  };

  return (
    <div className="gh-hook-time-picker-wrapper">
      <Datetime
        initialViewMode={initialViewMode}
        dateFormat={false}
        timeFormat={timeFormat}
        inputProps={{
          name,
          placeholder,
          className: className,
          disabled,
        }}
        value={value || ""}
        onChange={handleChange}
        closeOnSelect={closeOnSelect}
        timeConstraints={{ minutes: timeConstraints.minutes }}
        renderInput={(props) => (
          <input
            {...props}
            readOnly={true}
            className="uk-input"
            autoComplete="off"
            value={value.format(config.timeFormat) || ""}
            ref={inputRef}
          />
        )}
      />

      {value ? (
        <div className="gh-hook-utc" onClick={handleClickOnInput}>
          (UTC{moment().format("Z")})
        </div>
      ) : (
        ""
      )}

      {!hideIcon ? (
        <img
          src={timeIcon}
          alt="Calendar"
          className="gh-hook-clock-icon"
          onClick={handleClickOnInput}
        />
      ) : (
        ""
      )}
    </div>
  );
};

TimePicker.defaultProps = {
  hideIcon: false,
  closeOnSelect: false,
  className: "",
  placeholder: "",
  disabled: false,
  initialViewMode: "time",
  timeFormat: config.timeFormat,
  timeConstraints: {},
};

TimePicker.propTypes = {
  hideIcon: PropTypes.bool,
  timeConstraints: PropTypes.object,
  closeOnSelect: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  initialViewMode: PropTypes.string,
  timeFormat: PropTypes.string,
};

export default TimePicker;
