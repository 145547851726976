import React from "react";
import PropTypes from "prop-types";
import "./MarketAxisContent.css";
import clsx from "clsx";
import BrokerLastSeen from "../../../../../../common/BrokerLastSeen";

const MarketAxisContent = ({ actionsList }) => {
  return actionsList.map((action) => (
    <AxisRow key={action.id} highlighted={action.highlighted}>
      <AxisCol title="Operator" className="gh-axis-col-operator">
        {action.operator}
      </AxisCol>

      <AxisCol title={action.statusSeenTitle} className="gh-axis-col-status">
        <AxisStatus
          value={action.statusSeenValue}
          isUserActionsOperator={action.isUserActionsOperator}
        />
      </AxisCol>

      <AxisCol title="Helicopter(s)" className="gh-axis-col-helicopter">
        <AxisHelicopters helicopters={action.helicopter?.data} />
      </AxisCol>

      <AxisCol title="Quote Price" className="gh-axis-col-line">
        <AxisLine
          disabled={action.price_percentage_disabled}
          percentage={action.price_percentage}
        />
      </AxisCol>

      <AxisCol title="Response Time" className="gh-axis-col-response-time">
        <AxisTag
          colorClassName={action.response_time_className}
          value={action.response_time_converted}
        />
      </AxisCol>
    </AxisRow>
  ));
};

MarketAxisContent.propTypes = { actionsList: PropTypes.array.isRequired };
MarketAxisContent.defaultProps = {};
export default MarketAxisContent;

/*eslint-disable react/prop-types*/
const AxisRow = ({ children, highlighted }) => (
  <div className={clsx("gh-axis-row", { "gh-axis-row-highlighted": highlighted })}>{children}</div>
);

const AxisCol = ({ title, children, className }) => (
  <div className={clsx("gh-axis-col", className)}>
    <div className="gh-axis-title">{title}</div>
    <div className="gh-axis-value">{children}</div>
  </div>
);

const AxisLine = ({ percentage, disabled = false }) => (
  <div className="gh-axis-line-container">
    {!disabled && <div className="gh-axis-line-label">LOW</div>}

    <div className={clsx("gh-axis-line", { "gh-axis-line-disabled": disabled })}>
      <div className="gh-axis-line-point" style={{ left: percentage }} />
    </div>

    {!disabled && <div className="gh-axis-line-label">HIGH</div>}
  </div>
);

const AxisTag = ({ value, colorClassName }) => (
  <div className={clsx("gh-axis-tag", colorClassName)}>{value}</div>
);

const AxisHelicopter = ({ name }) => <div className="gh-axis-helicopter">{name}</div>;

const AxisHelicopters = ({ helicopters }) => (
  <div className="gh-axis-helicopters">
    {helicopters && helicopters.length
      ? helicopters.map((heli) => <AxisHelicopter key={heli.name} name={heli.name} />)
      : "---"}
  </div>
);

const AxisStatus = ({ isUserActionsOperator, value }) => {
  return isUserActionsOperator ? <BrokerLastSeen dateAndTime={value} /> : value;
};
