import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import UIkit from "uikit";
import { actions } from "react-redux-form";
import { Backlink } from "../../../../common";
import { EventDetailComponent } from "../../common";
import { FlightFormComponent } from "./forms";
import { QuoteDetails } from "./quote-details.component";
import {
  ModalComponent,
  objectOmit,
  AccordionComponent,
  floatNumberPrecision,
  getVAT,
  getRoundedPrice,
  removeCommas,
  distanceBetween,
  parseCoordinates,
  redirectTo,
  alertInstance,
  spinnerInstance,
} from "../../../../../shared";
import { addQuote, downloadAgreement, QUOTE_TO_AMEND } from "../../../../../actions";
import staticService from "../../../../../services/static.service";
import { TacComponent } from "../../../../forms";
import { config, staticText, validationRules } from "../../../../../configs";
import TermsAndConditions from "../../../../common/TermsAndConditions";
const { isPrice } = validationRules;
import history from "../../../../../utils/history";

class RequestTabComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMapVisible: false,
      legs: props.request.legs,
      submitConfirm: false,
      submitDocs: false,
      submit: false,
      eventDayBox: false,
      agreementVisibility: false,
      modalProps: this.onSuccessModalProps,
      activeTab: 2,
      redirectPath: config.redirectToMarketPlace,
      redirectPathOpenQuote: "",
      loading: false,
    };

    this.submitFlightForm = this.submitFlightForm.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.getAccordionItems = this.getAccordionItems.bind(this);
    this.resetStates = this.resetStates.bind(this);
    this.setEventDayCheckbox = this.setEventDayCheckbox.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.requestModel = this.requestModel.bind(this);
    this.getActive = this.getActive.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.request !== newProps.request) {
      this.requestModel(newProps.request);
      const _state = { isMapVisible: true };
      if (newProps.request.empty_leg) {
        _state.activeTab = 1;
        _state.isMapVisible = false;
        _state.redirectPath = config.redirectToEmptyleg + newProps.request.empty_leg;
      }
      this.setState(_state);
    }
  }

  updatePriceModel(model, value) {
    const { vat_value } = this.props.banking;
    const price = value.replace(/\,/g, "");
    const view_price = price !== "" ? parseInt(price, 10).toLocaleString() : price;
    const vat = price !== "" ? floatNumberPrecision((price / 100) * Number(vat_value), 2) : "";
    this.context.store.dispatch(actions.resetValidity(model));
    this.context.store.dispatch(actions.setTouched(model));
    const updateModel = {
      ...this.props.quoteModel,
      view_price: isPrice(price) ? view_price : price,
      price: price,
      final_price: floatNumberPrecision(getRoundedPrice(price), 2),
      quote_vat: vat !== "" ? removeCommas(vat) : "",
      view_quote_vat: vat,
    };
    this.context.store.dispatch(actions.change("quoteModel", updateModel));
  }

  requestModel(requestModel) {
    const { quoteToAmend } = this.props;
    this.props.actions.updateModel("quoteModel.legs", requestModel.legs);
    this.props.actions.updateModel("quoteModel.booking_request_id", requestModel.id);
    if (requestModel.emptyLegAircraft.id) {
      this.props.actions.updateModel("quoteModel.helicopters", [requestModel.emptyLegAircraft.id]);
    }

    if (quoteToAmend && Object.values(quoteToAmend).length) {
      const legs = quoteToAmend.booking_request.legs.map((leg, index) => {
        return {
          ...leg,
          from_coords: quoteToAmend.pads[index].from_coords,
          from: quoteToAmend.pads[index].from_name,
          from_place_id: quoteToAmend.pads[index].from_place_id,
          to_coords: quoteToAmend.pads[index].to_coords,
          to: quoteToAmend.pads[index].to_name,
          to_place_id: quoteToAmend.pads[index].to_place_id,
        };
      });
      const price = Math.round(parseInt(quoteToAmend.price.replace(/\,/g, ""), 10)).toString();
      this.updatePriceModel("quoteModel.view_price", price);
      this.context.store.dispatch(
        actions.xor("quoteModel.helicopters", quoteToAmend.aircrafts[0].aircraft_id),
      );
      this.props.actions.updateModel(
        "quoteModel.booking_request_id",
        quoteToAmend.booking_request.id,
      );
      this.props.actions.updateModel(
        "quoteModel.helicopters",
        quoteToAmend.aircrafts.map((ac) => ac.aircraft_id),
      );
      this.props.actions.updateModel(
        "quoteModel.description",
        quoteToAmend.description === null ? "" : quoteToAmend.description,
      );
      this.props.actions.updateModel("quoteModel.event_day", quoteToAmend.event_day);
      this.props.actions.updateModel("quoteModel.legs", [...legs]);
      this.props.actions.updateModel("quoteModel.old_quote_id", quoteToAmend.id);
    }
  }

  resetStates() {
    this.setState({
      formVisibility: false,
      submitConfirm: false,
      submitDocs: false,
      submit: false,
      eventDayBox: false,
      agreementVisibility: false,
    });
    this.toggleAccordion(1);
    this.props.actions.resetModel("quoteModel");
    this.requestModel(this.props.request);
    this.context.store.dispatch(
      actions.change("quoteModel.currency", this.props.banking.currency_data.currency),
    );
    this.closeModal();
  }

  setEventDayCheckbox() {
    const newState = !this.state.eventDayBox;
    this.props.actions.updateModel("quoteModel.event_day", newState ? 1 : 0);
    this.setState((prev) => ({ eventDayBox: newState }));
  }

  toggleAccordion(index, element = "#just") {
    UIkit.accordion(element).toggle(index, true);
  }

  submitFlightForm() {
    this.context.store.dispatch(actions.submit("quoteModel"));
    this.scroll();
  }

  handleFormSubmit() {
    this.setState({ loading: true });
    spinnerInstance.setProp("size", "large").show();
    this.context.store.dispatch(actions.submit("quoteModel"));
    if (!this.props.formQuoteModel.$form.valid) {
      this.toggleAccordion(1);
      this.scroll();
    } else {
      this.toggleAccordion(2);
      if (this.state.submitConfirm) {
        this.props.actions.addQuote(this.props.quoteModel, ({ id, content }) => {
          this.setState({
            redirectPathOpenQuote: `${config.redirectToOpenQuotes}`,
            loading: false,
          });
          this.context.store.dispatch({ type: QUOTE_TO_AMEND, payload: {} });
          this.props.actions.resetModel("quoteModel");
          alertInstance.eventInstance.trigger("global", {
            visible: true,
            message: content,
            type: alertInstance.successProp,
          });
        });
      } else {
        this.setState({ showConfirm: true });
      }
    }
  }

  openModal() {
    this.successModal.open();
  }

  closeModal() {
    this.successModal.hide();
  }

  addTimeMax(leg, index) {
    let currentDistance = parseInt(
      distanceBetween(parseCoordinates(leg.from_coords), parseCoordinates(leg.to_coords)).toFixed(
        0,
      ),
    );
    const timeCounted = Math.floor((currentDistance / 130) * 60 + 15);
    if (currentDistance === 0) {
      return this.context.store.dispatch(
        actions.change(`quoteModel.legs.[${index}].time_max`, currentDistance),
      );
    }
    if (timeCounted % 10 === 0) {
      return this.context.store.dispatch(
        actions.change(`quoteModel.legs.[${index}].time_max`, timeCounted * 60),
      );
    } else {
      const rounded =
        timeCounted % 10 !== 0 ? (timeCounted + (10 - (timeCounted % 10))) * 60 : timeCounted * 60;
      return this.context.store.dispatch(
        actions.change(`quoteModel.legs.[${index}].time_max`, rounded),
      );
    }
  }

  scroll() {
    let invalid = false;
    setTimeout(() => {
      let offsetTop = parseInt(this.nextBtn.offsetTop);
      this.props.quoteModel.legs.forEach((leg, i) => {
        this.addTimeMax(leg, i);
        if (!this.props.formQuoteModel.legs[i].$form.valid && !invalid) {
          invalid = true;
          const element = document.getElementById("leg-container-" + i);
          offsetTop = parseInt(element.offsetTop);
        }
      });

      window.scrollTo(0, offsetTop);
    }, 200);
  }

  redirectToPriceCalculation(id) {
    history.push(`/price-calculator/${id}`);
  }

  getAccordionItems(quoteDetails, requestInfo, legs) {
    const id = this.props.request.id;
    const { quoteToAmend } = this.props;
    return [
      {
        title: staticService.findByAlias("request-details"),
        active: this.getActive(1),
        content: (
          <div>
            {quoteDetails}
            <div className="uk-margin-medium-top">
              <div
                className="gh-leg-title uk-text-uppercase"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("criticalInfoLabel"),
                }}
              />
            </div>

            <p>
              {<span dangerouslySetInnerHTML={{ __html: requestInfo.other_info }} /> ||
                staticText.none}
            </p>
            <EventDetailComponent
              visible={this.hasEvent(requestInfo)}
              event={this.getEvent(requestInfo)}
            />
            <button
              className="uk-button uk-button-primary uk-margin-top uk-margin-bottom"
              onClick={this.toggleAccordion.bind(this, 1, "#just")}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("QuoteNow"),
                }}
              />
            </button>
          </div>
        ),
      },
      {
        title: staticService.findByAlias("quote-details"),
        active: this.getActive(2),
        onAccordionShow: () => this.setState({ isMapVisible: true }),
        content: (
          <div ref={(ref) => (this.formSection = ref)}>
            <FlightFormComponent
              isMapVisible={this.state.isMapVisible}
              onFormSubmit={(model) => this.toggleAccordion(2)}
              initialLegs={legs}
              legItems={legs}
              loading={this.state.loading}
              amendedHeli={
                quoteToAmend &&
                Object.values(quoteToAmend).length &&
                quoteToAmend.aircrafts.map((ac) => ac.aircraft_id)
              }
            />
            <button
              ref={(ref) => (this.nextBtn = ref)}
              id="nextBtn"
              className="uk-button uk-button-primary uk-margin-remove-top uk-margin-bottom"
              onClick={this.submitFlightForm}
            >
              Next step
            </button>
            <button
              className="uk-margin-medium-left uk-button uk-button-primary uk-margin-remove-top uk-margin-bottom"
              style={{ backgroundColor: "red" }}
              onClick={() => this.redirectToPriceCalculation(id)}
            >
              Price Calculator
            </button>
          </div>
        ),
      },
      {
        title: staticService.findByAlias("flightTermsAndDocConfirmTitle"),
        active: this.getActive(3),
        content: (
          <div>
            {!this.state.submitConfirm && this.state.submit ? (
              <div className="uk-alert-danger gh-alert-without-bg uk-margin-small-bottom">
                {staticService.findByAlias("quoteAgreementNotification")}
              </div>
            ) : null}
            <div data-uk-grid className="uk-grid-small">
              <div className="uk-width-1-1">
                <input
                  type="checkbox"
                  onChange={() => {
                    this.setState((prev) => ({
                      submitConfirm: !prev.submitConfirm,
                    }));
                  }}
                  className={"uk-checkbox uk-margin-small-right"}
                  value={1}
                  checked={this.state.submitConfirm}
                />
                <label>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("quoteAgreement"),
                    }}
                  />
                </label>
                <a
                  onClick={() => this.setState({ agreementVisibility: true })}
                  style={{ marginLeft: 10 }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("readAgreement"),
                    }}
                  />
                </a>
              </div>
              {!this.state.submitDocs && this.state.submit ? (
                <div className="uk-alert-danger gh-alert-without-bg uk-margin-left">
                  {staticService.findByAlias("quoteDocsNotification")}
                </div>
              ) : (
                ""
              )}
              <div className="uk-width-1-1 uk-margin-small-top">
                <input
                  type="checkbox"
                  name="quoteModel.is_information_confirmed"
                  onChange={(e) => {
                    this.setState((prev) => ({
                      submitDocs: !prev.submitDocs,
                    }));
                    this.props.actions.updateModel(
                      "quoteModel.is_information_confirmed",
                      e.target.checked,
                    );
                  }}
                  value={1}
                  className={"uk-checkbox uk-margin-small-right"}
                  checked={this.state.submitDocs}
                />
                <label>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("documentConfirmation"),
                    }}
                  />
                </label>
              </div>
            </div>

            <div className={"uk-margin-top"}>
              <a
                id="submitBtn"
                onClick={this.handleFormSubmit}
                className="uk-button uk-button-primary uk-margin-top uk-margin-bottom gh-full-width-btn"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("SubmitQuote"),
                  }}
                />
              </a>
            </div>
            <TermsAndConditions />
          </div>
        ),
      },
    ];
  }

  getActive(tab) {
    return this.state.activeTab === tab;
  }

  get agreement() {
    return this.props.request.agreement
      ? this.props.request.agreement
      : { title: "", description: "" };
  }

  getEvent(request) {
    return this.hasEvent(request) ? request.events[0] : {};
  }

  hasEvent(request) {
    return request.events && request.events.length > 0;
  }

  render() {
    const { redirectPathOpenQuote } = this.state;
    if (!this.props.visible) {
      return null;
    }
    const { legs } = this.props.request;
    const requestInfo = objectOmit(this.props.request, "quotes");
    const quoteDetails = legs.map((leg, index) => {
      const _clone = { ...leg };
      _clone.from = leg.original_from;
      _clone.to = leg.original_to;
      return (
        <QuoteDetails
          key={index}
          title={`Leg ${++index}`}
          requestInfo={requestInfo}
          leg={_clone}
          data={{ screenW: this.props.screenW }}
        />
      );
    });

    if (redirectPathOpenQuote) {
      return redirectTo(redirectPathOpenQuote);
    }

    return (
      <div>
        <Backlink
          title={staticService.findByAlias("backToRequestList")}
          path={this.state.redirectPath}
        />
        <div className="uk-padding-large gh-mob-padding">
          <AccordionComponent
            accordionId={"just"}
            items={this.getAccordionItems(quoteDetails, requestInfo, legs)}
            options={{ collapsible: true }}
          />
        </div>
        <ModalComponent
          title={this.state.modalProps.title}
          ref={(successModal) => (this.successModal = successModal)}
          id={this.state.modalProps.id}
          options={{ clsPage: "", bgClose: false, escClose: false }}
          onClose={this.closeModal}
          closeBtnTemplate={this.state.modalProps.closeBtnTemplate}
        >
          {this.state.modalProps.content}
        </ModalComponent>
        <TacComponent
          html={this.agreement.description}
          agreeCallback={this.onChangeCondition}
          visible={this.state.agreementVisibility}
          classNames="full"
          isFull={true}
          flightTerms={staticService.findByAlias("flightTermsTitle")}
          downloadCallback={this.props.actions.downloadAgreement.bind(this, this.props.request.id)}
        />
      </div>
    );
  }

  onChangeCondition(state) {
    this.setState({
      agreementVisibility: !this.state.agreementVisibility,
      submitConfirm: state > 0,
      icon: state === 0 ? "copy" : "check",
      iconClass: state === 0 ? "uk-margin-small-right" : "uk-margin-small-right uk-icon-button",
      termsConditions:
        state === 0 ? "uk-width-auto uk-padding-remove" : "gh-button-terms gh-button-terms-text",
    });
  }

  get onSuccessModalProps() {
    return {
      id: "success-confirm",
      title: staticService.findByAlias("quoteCreatedModalTitle"),
      onClose: this.closeModal,
      closeBtnTemplate: (
        <NavLink className={"gh-modal-close"} to={config.redirectToMarketPlace}>
          <span data-uk-icon={"close"} />
        </NavLink>
      ),
      content: (
        <div>
          <p
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("quoteCreatedModalContent"),
            }}
          />

          <div data-uk-grid className="uk-flex-center">
            <div className="uk-margin-small-top uk-flex">
              <button
                onClick={this.resetStates.bind(this)}
                className={"uk-button uk-button-primary"}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("submitQuote"),
                  }}
                />
              </button>
            </div>

            <div className="uk-margin-small-top uk-flex">
              <NavLink
                className={"uk-button uk-button-default uk-link-text"}
                to={config.redirectToMarketPlace}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("oneIsEnough"),
                  }}
                />
              </NavLink>
            </div>
          </div>
        </div>
      ),
    };
  }

  componentWillUnmount() {
    this.props.actions.resetModel("quoteModel");
    this.setState({
      formVisibility: false,
      submitConfirm: false,
      eventDayBox: false,
      agreementVisibility: false,
    });
  }
}

RequestTabComponent.contextTypes = {
  store: PropTypes.object,
};

RequestTabComponent.propTypes = {
  request: PropTypes.object.isRequired,
  history: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        addQuote,
        resetModel: (modelName) => dispatch(actions.reset(modelName)),
        updateModel: (modelName, value) => dispatch(actions.change(modelName, value)),
        downloadAgreement,
      },
      dispatch,
    ),
  };
};

const mapStateToProps = ({
  newQuote,
  termsCondition,
  assignAircraft,
  forms,
  quoteModel,
  quoteToAmend,
  dashboard,
}) => {
  return {
    formQuoteModel: forms.quoteModel,
    quoteToAmend: quoteToAmend,
    quoteModel: quoteModel,
    termsCondition,
    assignAircraft,
    newQuote,
    banking: dashboard.banking,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RequestTabComponent);
export { COMPONENT as RequestTabComponent };
