import React from "react";
import PropTypes from "prop-types";
import { Control, Form, Errors } from "react-redux-form";
import { validationRules, validationMessages } from "../../configs";
import { CustomValidateError, updateModel } from "../../shared";
import staticService from "../../services/static.service";

const { required, validEmail } = validationRules;
const { requiredMessage, validEmailMessage } = validationMessages;

export class GrantAccessFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: props.visible,
      isSubmit: false,
    };
  }

  render() {
    return (
      <Form
        model="userEmailModel"
        className={"gh-recover-pass"}
        validators={{
          email: { required, validEmail },
        }}
        onSubmit={this.props.submit}
      >
        <h3 dangerouslySetInnerHTML={{ __html: staticService.findByAlias("grantAccessTitle") }} />
        <p dangerouslySetInnerHTML={{ __html: staticService.findByAlias("grantAccessDesc") }} />
        <div className={"uk-margin"}>
          <Control.text
            type={"email"}
            className={"uk-input"}
            model={".email"}
            updateOn={"change"}
            changeAction={updateModel}
            placeholder={""}
          />
          <Errors
            model=".email"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: requiredMessage.bind(null, "Email"),
              validEmail: validEmailMessage,
            }}
          />
        </div>
        <button
          type="submit"
          className={"uk-button uk-button-primary uk-align-center"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("submit") }}
        ></button>
      </Form>
    );
  }
}

GrantAccessFormComponent.propsType = {
  submit: PropTypes.func,
};
