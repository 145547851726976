import React from "react";
import PropTypes from "prop-types";
import useTime from "../../../hooks/useTime";

const Time = ({ timestamp, showType }) => {
  const { time, date, utc, timeUtc } = useTime(timestamp);
  const showVariants = {
    date,
    time,
    utc,
    timeUtc,
  };

  return <>{showVariants[showType]}</>;
};

Time.propTypes = {
  timestamp: PropTypes.string.isRequired,
  showType: PropTypes.oneOf(["date", "time", "utc", "timeUtc"]),
};
Time.defaultProps = { showType: "date" };
export default Time;
