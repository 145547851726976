import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ModalComponent } from "../../../../../../../../shared";
import ProvidePaymentModalContent from "../../../../../../../common/ProvidePaymentModalContent";
import { connect } from "react-redux";
import {
  getBookingDetailSuccess,
  sendConfirmPaymentReceived,
  sendPaymentInformation,
} from "../../../../../../../../actions";
import staticService from "../../../../../../../../services/static.service";
import auth from "../../../../../../../../services/auth";
import ConfirmPaymentModalContent from "../ConfirmPaymentModalContent";

const BookingPaymentModals = (props) => {
  const {
    bookingID,
    brokerCompany,
    paymentInfo,
    paymentStatus,
    history,
    sendPaymentInformation,
    getBookingDetailSuccess,
    providePaymentInfoRef,
    confirmFullPayment,
    sendConfirmPaymentReceived,
  } = props;
  const previewFileModalRef = useRef(null);
  const previewImgOutput = useRef(null);
  const previewFileOutput = useRef(null);

  useEffect(() => {
    if (!history) return;
    const {
      location: { hash },
    } = history;

    //type === "providePaymentInformation" &&
    if (hash === "#payment_information") {
      handleOpenModal(providePaymentInfoRef);
    }
  }, []);

  const handleOpenModal = (modalRef) => {
    modalRef.current.open();
  };

  const handleCloseModal = (modalRef) => {
    modalRef.current.hide();
  };

  // START PROVIDE PAYMENT INFORMATION
  const handleSubmitPayment = (value, fn) => {
    sendPaymentInformation(bookingID, value).then((booking) => {
      getBookingDetailSuccess(booking);
      handleCloseModal(providePaymentInfoRef);
      fn(false);
    });
  };

  // CONFIRM THE PAYMENT
  const handleConfirmPayment = (cb) => {
    sendConfirmPaymentReceived(bookingID).then((booking) => {
      getBookingDetailSuccess(booking);
      cb(false);
      handleCloseModal(confirmFullPayment);
    });

    setTimeout(() => {
      cb(false);
    }, 2000);
  };

  // START PROVIDE PAYMENT INFORMATION
  const openPreviewFileModal = (file) => {
    const srcFile = file.file_url ? file.file_url : URL.createObjectURL(file);

    if (file.type.includes("pdf")) {
      previewFileOutput.current.src = srcFile;
      previewFileOutput.current.classList.remove("hide");
    } else {
      previewImgOutput.current.src = srcFile;
      previewImgOutput.current.classList.remove("hide");
    }

    setTimeout(() => {
      handleOpenModal(previewFileModalRef);
    }, 200);

    setTimeout(() => {
      URL.revokeObjectURL(previewFileOutput.current.src);
    }, 100);
  };

  const handleCLosePreviewFile = () => {
    handleOpenModal(providePaymentInfoRef);
  };

  return (
    <div>
      {/*START PROVIDE PAYMENT INFORMATION MODAL*/}
      <ModalComponent
        title={staticService.findByAlias("viewPaymentInformationModalTitle")}
        ref={providePaymentInfoRef}
        id="providePaymentInfoId"
        options={{ clsPage: "", bgClose: false, escClose: false }}
      >
        <ProvidePaymentModalContent
          handleSubmit={handleSubmitPayment}
          closeModal={() => {
            handleCloseModal(providePaymentInfoRef);
          }}
          bookingID={bookingID}
          openPreviewFileModal={openPreviewFileModal}
          brokerName={brokerCompany ? brokerCompany.name : null}
          registeredAddress={brokerCompany ? brokerCompany.address : null}
          // closingModal={closingModal}
          paymentInfo={paymentInfo}
          readOnly={paymentStatus !== 1}
        />
      </ModalComponent>
      {/*END PROVIDE PAYMENT INFORMATION MODAL*/}

      {/*START CONFIRM FULL PAYMENT MODAL*/}
      <ModalComponent
        title={staticService.findByAlias(
          auth.isOperator() ? "confirmPaymentModalTitle" : "confirmManualBankTransfer",
        )}
        ref={confirmFullPayment}
        id="confirmFullPaymentModalId"
        options={{ clsPage: "", bgClose: false, escClose: false }}
      >
        <ConfirmPaymentModalContent
          modalTextAlias={
            auth.isOperator() ? "confirmPaymentModalText" : "confirmManualBankTransferModalText"
          }
          onConfirm={handleConfirmPayment}
          closeModal={() => {
            handleCloseModal(confirmFullPayment);
          }}
        />
      </ModalComponent>
      {/*END CONFIRM FULL PAYMENT MODAL*/}

      {/*START PREVIEW FILE MODAL*/}
      <ModalComponent
        title=""
        ref={previewFileModalRef}
        id="previewFileModalId"
        modalClass="gh-preview-file-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={handleCLosePreviewFile}
      >
        <img ref={previewImgOutput} alt="preview" className="hide" />
        <div className="gh-preview-file gh-padding-10">
          <iframe ref={previewFileOutput} title="preview" className="gh-preview-file-pdf hide" />
        </div>
      </ModalComponent>
      {/*END PREVIEW FILE MODAL*/}
    </div>
  );
};

BookingPaymentModals.propTypes = {
  bookingID: PropTypes.number,
  brokerCompany: PropTypes.object,
  paymentInfo: PropTypes.object,
  paymentStatus: PropTypes.number,
  history: PropTypes.object,
  providePaymentInfoRef: PropTypes.object,
  sendPaymentInformation: PropTypes.func,
  getBookingDetailSuccess: PropTypes.func,
  confirmFullPayment: PropTypes.object,
  sendConfirmPaymentReceived: PropTypes.func,
};

export default connect(null, {
  sendPaymentInformation,
  getBookingDetailSuccess,
  sendConfirmPaymentReceived,
})(BookingPaymentModals);
