import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import UIkit from "uikit";
import { NavWrapper } from "../nav-wrapper.component";
import Auth from "../../../services/auth";
import { loadUnreadNotifications } from "../../../actions";
import { AccountDropDown } from "./account-dropdown.component";
import staticService from "../../../services/static.service";
import { NotificationIcon } from "../notification-icon.component";
import UserInitials from "../UserInitials";
import closeIcon from "../../../assets/img/home/closeMenu.svg";

class AccountNavWrapper extends NavWrapper {
  constructor(props) {
    super(props);

    this.state = {
      extraProps: props.extraProps,
      extraOptions: {
        position: "uk-navbar-center uk-visible@l",
      },
      logoPath: "/dashboard",
      showDropDown: false,
    };
    this.filteredNav = this.filteredNav.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  componentDidMount() {
    UIkit.toggle("#offcanvas");
    if (!this.props.unreadNotificationLoaded) {
      this.props.getUnreadTotal();
    }
  }

  componentProps() {
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: ["gh-req-quotes"],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  path(page) {
    if (page.paths) {
      return page.paths[Auth.getMainRole()];
    }
    return page.path;
  }

  toggleMobileNavigationHandler(status) {
    return () => {
      if (status) {
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("html").style.overflowY = "unset";
      }
      this.setState({ mobileNavVisible: status });
    };
  }

  toggleDropDown() {
    this.setState((prevState) => ({ showDropDown: !prevState.showDropDown }));
  }

  afterNav() {
    const { dashboardModel, notifications } = this.props;
    const displayPipelinesCounter =
      Auth.isAuthenticated() &&
      dashboardModel &&
      dashboardModel.total_pipelines &&
      dashboardModel.total_pipelines > 0;

    return [
      <div key={0} className="uk-navbar-right gh-navbar-right uk-visible@l">
        <ul className="uk-navbar-nav ul-disabled-ul">
          <li>
            <NotificationIcon visible={true} notifications={notifications} />
          </li>
          <li>
            <div
              className="uk-navbar-item"
              style={{ cursor: "pointer" }}
              onClick={this.toggleDropDown}
            >
              <ul
                data-uk-grid
                className="uk-grid-small uk-flex-inline uk-flex-middle uk-flex-nowrap gh-user-nav"
              >
                <li className="uk-first-column">
                  <UserInitials />
                </li>
                <li>
                  <div className="uk-margin-remove-left uk-padding-remove gh-user-name gh-text-normal">
                    {Auth.fullName}
                  </div>
                </li>
                <li>
                  <span
                    className="uk-margin-remove-left uk-padding-remove gh-dropdown-arrow"
                    data-uk-icon="icon: chevron-down"
                  />
                </li>
              </ul>
            </div>
            <div
              className={`uk-navbar-dropdown ${
                this.state.showDropDown ? "gh-visible" : "gh-hidden"
              }`}
            >
              <AccountDropDown toggleVisible={this.toggleDropDown} />
            </div>
          </li>
        </ul>
      </div>,
      <div key={1} className="uk-navbar-right uk-hidden@l">
        <div className="">
          <NotificationIcon visible={true} notifications={notifications} />
        </div>
        <div className="uk-nav gh-user-nav gh-user-nav-mobile">
          <UserInitials />
        </div>
        <button
          href="#"
          className="uk-navbar-toggle"
          data-uk-icon
          onClick={this.toggleMobileNavigationHandler(true)}
        >
          <svg
            width="28"
            height="20"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 9.99993H26.7143"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1.42852H26.7143"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 18.5713H26.7143"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>,
      <div key="mobile-nav">
        <div
          role="banner"
          className="gh-side-menu-box"
          style={{
            display: this.state.mobileNavVisible ? "block" : "none",
          }}
          onClick={this.toggleMobileNavigationHandler(false)}
        />
        <div
          className={`gh-side-menu-inner ${
            Auth.isAuthenticated() ? "gh-offcanvas-bar-logged" : "gh-offcanvas-bar"
          }`}
          style={{
            transform: `translateX(${this.state.mobileNavVisible ? 0 : "100%"})translateY(86px)`,
          }}
        >
          <button
            className="uk-offcanvas-close"
            type="button"
            onClick={this.toggleMobileNavigationHandler(false)}
          >
            <img src={closeIcon} alt="close" />
          </button>
          <div>
            <AccountDropDown
              toggleVisible={this.toggleMobileNavigationHandler(false)}
              tagProps={{
                className: "uk-nav uk-nav-sub uk-nav-center uk-padding-remove-left",
              }}
            />
          </div>

          <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-medium-top">
            {this.filteredNav().map((page, index) => {
              return (
                <li key={index} {...page.props.listProp}>
                  <NavLink
                    {...page.props.linkProp}
                    to={this.path(page)}
                    activeClassName="uk-active"
                  >
                    {page.paths &&
                    page.paths[Auth.getMainRole()] === "/marketplace/empty-legs" &&
                    Auth.getMainRole() === "broker" ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("brokerMarketplaceEmptyLegTab"),
                        }}
                      />
                    ) : (
                      <div className="uk-position-relative">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: staticService.findByAlias(page.title),
                          }}
                        />
                        {page.title === "PipelinesTopmenu" &&
                        displayPipelinesCounter &&
                        Auth.hasFeature("broker_pipeline_of_leads") ? (
                          <span
                            className="uk-label uk-label-danger gh-button-counter uk-margin-small-left uk-margin-bottom uk-position-absolute"
                            style={{ top: "initial", right: "initial" }}
                          >
                            {dashboardModel.total_pipelines}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>,
    ];
  }

  render() {
    return super.render();
  }
}

AccountNavWrapper.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  extraProps: PropTypes.object,
  notifications: PropTypes.number,
};

AccountNavWrapper.defaultProps = {
  extraProps: {
    navClassName: "uk-navbar",
  },
};

AccountNavWrapper.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  const response = {
    notifications: state.unreadNotification.unread_notifications,
    unreadNotificationLoaded: state.unreadNotificationLoaded,
    dashboardModel: state.dashboard,
  };

  if (state.broadcast.newNotifications) {
    response.notifications = state.broadcast.newNotifications;
  }
  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUnreadTotal: loadUnreadNotifications,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(AccountNavWrapper);
export { COMPONENT as AccountNavWrapper };
