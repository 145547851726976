import React from "react";
import PropTypes from "prop-types";
import { Control, Fieldset } from "react-redux-form";
import { DateTimeComponent } from "../../../shared";
import { ActivityFilterCriteria } from "../../pages";
import staticService from "../../../services/static.service";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../common";
import auth from "../../../services/auth";

const FilterBookingFieldset = (props) => {
  const {
    modelName,
    isSubmitEnabled,
    activity,
    updateGeocodeModel,
    onSelectLocation,
    updateModelFn,
    disablePastDate = false,
    resetModel,
    radioLabels = {},
    showActivity = true,
    disableFutureDate = false,
    from,
    from_info,
    to,
    to_info,
    from_coverage_status,
    to_coverage_status,
    updateDepartureTime,
  } = props;

  return (
    <Fieldset model={modelName}>
      <div data-uk-grid className="gh-filters-container">
        <div className="uk-width-1-1@s uk-width-expand@m">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("from"),
            }}
          />
          <div className="uk-form-controls">
            <LocationAutocompleteWrapper>
              {({
                locations,
                search,
                handleClickOutside,
                loader,
                onBlur,
                locationValidationTemplate,
              }) => {
                return (
                  <div className="uk-position-relative">
                    <LocationAutocompleteInput
                      className={["uk-input"]}
                      placeholder="London"
                      onSelect={(location) => {
                        updateGeocodeModel("from", location);
                        return search(location, "notRequired");
                      }}
                      onBlur={onBlur}
                      loader={loader}
                      debounce={500}
                      value={from}
                      coverageWarning={from_coverage_status}
                      additionalInfo={from_info}
                      model={`${modelName}.from`}
                    />
                    <LocationAutocompleteList
                      onSelect={onSelectLocation.bind(this, handleClickOutside, "from")}
                      locations={locations}
                    />
                    <span>{locationValidationTemplate(modelName, ".from")}</span>
                  </div>
                );
              }}
            </LocationAutocompleteWrapper>
          </div>
        </div>

        <div className="uk-width-1-1@s uk-width-expand@m">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("to"),
            }}
          />
          <div className="uk-form-controls">
            <LocationAutocompleteWrapper>
              {({
                locations,
                search,
                handleClickOutside,
                loader,
                onBlur,
                locationValidationTemplate,
              }) => {
                return (
                  <div className="uk-position-relative">
                    <LocationAutocompleteInput
                      className={["uk-input"]}
                      placeholder="Paris"
                      onSelect={(location) => {
                        updateGeocodeModel("to", location);
                        return search(location, "notRequired");
                      }}
                      onBlur={onBlur}
                      loader={loader}
                      debounce={500}
                      value={to}
                      coverageWarning={to_coverage_status}
                      additionalInfo={to_info}
                      model={`${modelName}.to`}
                    />
                    <LocationAutocompleteList
                      onSelect={onSelectLocation.bind(this, handleClickOutside, "to")}
                      locations={locations}
                    />
                    <span>{locationValidationTemplate(modelName, ".to")}</span>
                  </div>
                );
              }}
            </LocationAutocompleteWrapper>
          </div>
        </div>

        <div className="uk-width-1-1@s uk-width-expand@m">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("date"),
            }}
          />
          <div className="uk-form-controls uk-position-relative">
            <Control
              model={`${modelName}.departure_time`}
              className={"uk-input gh-calendar-input"}
              component={DateTimeComponent}
              changeAction={(model, value) => updateDepartureTime(model, value.utc().toISOString())}
              updateOn="change"
              controlProps={{
                dateFormat: "DD MMMM, YYYY",
                isValidDate: disablePastDate,
                isValidDateBefore: disableFutureDate,
                onClear: () => updateModelFn(modelName + ".departure_time", ""),
              }}
            />
          </div>
        </div>
      </div>

      <div data-uk-grid className="gh-filters-container uk-margin-top">
        {auth.getMainRole() === "broker" ? (
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <label className={"uk-form-label"}>Client</label>
            <div className="uk-form-controls uk-position-relative">
              <Control.text
                model={`.client_identifier`}
                placeholder="Type the client"
                className="uk-input"
                updateOn="change"
              />
            </div>
          </div>
        ) : (
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <label className={"uk-form-label"}>Broker Name</label>
            <div className="uk-form-controls uk-position-relative">
              <Control.text
                model={`.broker_name`}
                placeholder="Enter a broker name"
                className="uk-input"
                updateOn="change"
              />
            </div>
          </div>
        )}
      </div>

      {showActivity ? (
        <div data-uk-grid className="uk-margin-top">
          <ActivityFilterCriteria
            updateModel={updateModelFn}
            boxLabels={radioLabels}
            modelName={modelName}
            activity={activity}
          />
        </div>
      ) : null}
      <div className="uk-flex-center" data-uk-grid>
        <div>
          <Control.button
            onClick={resetModel}
            type={"button"}
            model={modelName}
            className={"uk-button gh-cloud-blue-btn"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("resetFilter"),
              }}
            />
          </Control.button>
        </div>
        <div>
          <Control.button
            model={modelName}
            disabled={isSubmitEnabled}
            className={"uk-button uk-button-primary"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("showFilterResult"),
              }}
            />
          </Control.button>
        </div>
      </div>
    </Fieldset>
  );
};

FilterBookingFieldset.propTypes = {
  from: PropTypes.string,
  from_info: PropTypes.string,
  to: PropTypes.string,
  to_info: PropTypes.string,
  activity: PropTypes.string,
  disableFutureDate: PropTypes.bool,
  disablePastDate: PropTypes.bool,
  isSubmitEnabled: PropTypes.bool,
  modelName: PropTypes.string,
  onSelectLocation: PropTypes.func,
  radioLabels: PropTypes.object,
  resetModel: PropTypes.func,
  showActivity: PropTypes.bool,
  updateGeocodeModel: PropTypes.func,
  updateModelFn: PropTypes.func,
  from_coverage_status: PropTypes.bool,
  to_coverage_status: PropTypes.bool,
  updateDepartureTime: PropTypes.func,
};

export { FilterBookingFieldset };
