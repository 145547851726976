import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { config } from "../../../configs";
import staticService from "../../../services/static.service";
import Auth from "../../../services/auth";

export const AccountDropDown = ({
  tagProps = { className: "uk-nav uk-navbar-dropdown-nav" },
  toggleVisible,
}) => {
  return (
    <ul {...tagProps} onClick={toggleVisible}>
      {Auth.getMainRole() === "broker" ? (
        <li>
          <NavLink to="/flights/requests/filter/all">
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("allRequestActivity"),
              }}
            />
          </NavLink>
        </li>
      ) : (
        <li>
          <NavLink to="/flights/quotes/filter/all">
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("allQuotesActivity"),
              }}
            />
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to="/flights/my-bookings/filter/all">
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("allBookingActivity"),
            }}
          />
        </NavLink>
      </li>
      <li>
        <NavLink to={"/account/profile"}>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("accountMenu"),
            }}
          />
        </NavLink>
      </li>

      {Auth.getMainRole() === "broker" ? (
        <li>
          <NavLink to={"/clients"}>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("clientsMenu"),
              }}
            />
          </NavLink>
        </li>
      ) : (
        ""
      )}

      <li>
        <NavLink to={"/contact"}>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("contactDropDownMenu"),
            }}
          />
        </NavLink>
      </li>
      <li className="uk-nav-divider" />
      <li>
        <NavLink to={"/account/logout"}>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("signOutMenu"),
            }}
          />
        </NavLink>
      </li>
    </ul>
  );
};

AccountDropDown.propTypes = {
  tagProps: PropTypes.object,
  toggleVisible: PropTypes.func,
};
