import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BlockComponent } from "../../../../common";
import { getPayments } from "../../../../../actions";
import { PaginationService } from "../../../../../services";
import { paymentsConfig, config, operatorPaymentConfig } from "../../../../../configs";
import { ComplexTableComponent, formatID, spinnerInstance } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import auth from "../../../../../services/auth";
import { Link } from "react-router-dom";

class PaymentsListComponent extends Component {
  constructor(props, context) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.loadData = this.loadData.bind(this);
    const gridConfig = auth.getMainRole() === "broker" ? paymentsConfig : operatorPaymentConfig;
    this.state = {
      hasLoaded: false,
      clearList: false,
      filters: [{ key: "page", value: 1 }],
      sortOrder: gridConfig.defaultOrder,
    };

    this.paginationService = new PaginationService(
      context.store.dispatch,
      getPayments,
      config.paginationLimit,
      this.state.filters,
    );
    this.paginationService.setProp("columns", gridConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(gridConfig));
    this._mount = true;
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    spinnerInstance.setProp("type", "large");
    spinnerInstance.show();
    this.context.store.dispatch(
      getPayments(
        this.paginationService.filters.concat(this.paginationService.sorting),
        this.handleOnSuccess.bind(this),
      ),
    );
  }

  handleOnSuccess(payments) {
    spinnerInstance.setProp("type", "default");
    spinnerInstance.hide();
    if (!this._mount) return false;
    if (this.state.clearList) {
      this.paginationService.reloadItems();
      this.setState({ clearList: false });
    }
    this.paginationService.populateItems(payments, this.prepareRows.bind(this));
    if (!this.paginationService.initialLoadState) {
      this.paginationService.setProp("initialLoadState", true);
    }
    this.setState({ hasLoaded: true });
    this.forceUpdate();
  }

  loadMore() {
    this.paginationService.page += 1;
    this.paginationService.setProp("filters", [
      { key: "page", value: this.paginationService.page },
    ]);
    this.loadData();
  }

  prepareRows(items) {
    return items.map((item) => {
      item.displayID =
        item.booking === ""
          ? formatID(item.request, config.prefixes.request)
          : formatID(item.booking, config.prefixes.booking);
      item.date = moment(item.date).fromNow();
      item.sum =
        item.add_minus === true ? (
          <span className={item.is_blue ? "" : "gh-warn"}>
            {"-"}
            {item.currency.currency || config.currencyCode} {item.price}
          </span>
        ) : (
          <span className={item.is_blue ? "" : "gh-warn"}>
            {item.currency.currency || config.currencyCode} {item.price}
          </span>
        );
      return item;
    });
  }

  handleSorting(column, order) {
    this.setState({
      sortOrder: { field: column, sortOrder: order },
    });
    this.paginationService.setProp("sorting", [
      { key: "orderBy", value: column },
      { key: "sortedBy", value: order },
    ]);
    this.paginationService.page = 1;
    this.paginationService.setProp("filters", [
      { key: "page", value: this.paginationService.page },
    ]);
    this.loadData();
    this.setState({ clearList: true });
  }

  tableAction() {
    return {
      desktop: <td>{""}</td>,
      mobile: "",
    };
  }

  render() {
    return (
      <BlockComponent visible={true}>
        <h1 className={"gh-text-slim"}>
          {staticService.findByAlias(auth.isOperator() ? "paymentsFromBrokers" : "paymentsLabel")}
        </h1>
        <div className="uk-overflow-auto">
          <ComplexTableComponent
            payments
            loadMore={this.loadMore}
            rows={this.paginationService.items}
            headers={this.paginationService.headers}
            columns={this.paginationService.columns}
            showActionColumn={1}
            showLoader={this.paginationService.showLoaderBtn}
            showMoreText={staticService.findByAlias("showMore")}
            actionElements={this.tableAction.bind(this)}
            selectedOrder={this.state.sortOrder}
            sortChange={this.handleSorting.bind(this)}
            firstTimeLoading={this.paginationService.initialLoadState}
          />
        </div>
      </BlockComponent>
    );
  }

  componentWillUnmount() {
    this._mount = false;
  }
}

PaymentsListComponent.contextTypes = {
  store: PropTypes.object,
};

export { PaymentsListComponent };
