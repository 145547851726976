import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { brokerMarketplaceTabs, brokerMarketOverview, config } from "../../../../configs";
import { getActivityFlightsByDate, loadActivityFeed } from "../../../../actions/";
import { redirectTo } from "../../../../shared/";
import { MarketPlaceActivityComponent } from "../common/";

class BrokerMarketPlaceActivityComponent extends MarketPlaceActivityComponent {
  componentWillMount() {
    super.componentWillMount();
    this.setState({
      tabs: brokerMarketplaceTabs,
      overviewTabs: brokerMarketOverview,
    });
  }

  render() {
    return redirectTo(config.redirectToEmptyMarketPlace);
  }
}

BrokerMarketPlaceActivityComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadActivity: loadActivityFeed,
      getActivityFlightsByDate,
    },
    dispatch,
  );
};

function mapStateToProps({ activityFeed, activityFlights, screenDimensions }) {
  return { activityFeed, activityFlights, screenDimensions };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerMarketPlaceActivityComponent);
export { COMPONENT as BrokerMarketPlaceActivityComponent };
