import PropTypes, { string } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip";
import condStrings from "../../../utils/condString";

function QuoteTabItem(props) {
  const { title, path, tooltipMessage, disabled, active, onClick, tabName } = props;

  const onClickHandler = (ev) => {
    ev.preventDefault();
    if (!disabled) onClick();
  };

  return (
    <Link
      to={path}
      className={condStrings(
        "gh-quote-tabs-tab btn-style",
        disabled && "disabled",
        active && "active",
      )}
      onClick={onClickHandler}
    >
      {title}
      {tooltipMessage && (
        <Tooltip
          pos="top-center"
          mobilePos={tabName === "calculator" ? "top-left" : "top-center"}
          wrapperClassName="gh-tooltip-disabled-tab"
          contentClass={
            tabName === "calculator" ? "tab-tooltip-price-content" : "tab-tooltip-content"
          }
          trigger={<span className="gh-quote-tabs-tab__tooltip" />}
          addSpaceAtTheEnd={false}
        >
          {tooltipMessage}
        </Tooltip>
      )}
    </Link>
  );
}

QuoteTabItem.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  tooltipMessage: string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  tabName: PropTypes.string,
};

export default React.memo(QuoteTabItem);
