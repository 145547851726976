import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-redux-form";
import { Item, List } from "../../../../../../shared/list";
import staticService from "../../../../../../services/static.service";
import { aircraftTitle } from "../../../../../../shared/";

export const AssignHeliControlComponent = ({
  departure,
  currentSelectedHelicopters,
  helicopters,
  onItemChange,
  onModalClose,
  onSubmit,
}) => {
  const checked = (id) => currentSelectedHelicopters.indexOf(id) !== -1;
  const _html = (heli) => {
    return heli.status == 3 || heli.status == 4 || heli.status == null ? (
      <span
        dangerouslySetInnerHTML={{ __html: aircraftTitle(heli) }}
        className={"disabled"}
        data-uk-tooltip={`title: ${staticService.findByAlias("unverifiedAircraftTooltip")};`}
      />
    ) : (
      <span dangerouslySetInnerHTML={{ __html: aircraftTitle(heli) }} />
    );
  };

  const manageHeliWarning = (heli) => {
    let isHeliInsured = true;

    if (heli.expiration_date !== null) {
      const departureDate = new Date(departure.split(" ")[0]).setHours(0, 0, 0);
      const heliInsuranceExpirationDate = new Date(heli.expiration_date).setHours(0, 0, 0);

      isHeliInsured = departureDate < heliInsuranceExpirationDate;
    }

    let warning = null;

    if (heli.busy_state === "by_booking" && !isHeliInsured) {
      warning = (
        <span
          className={"uk-margin-small-left gh-text-bottom gh-red"}
          data-uk-icon="icon: warning"
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "HeliUnassuredAndBooked",
          )}; pos: top-left`}
        />
      );
    } else if (heli.busy_state === "by_quote" && !isHeliInsured) {
      warning = (
        <span
          className={"uk-margin-small-left gh-text-bottom gh-yellow"}
          data-uk-icon="icon: warning"
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "HeliUnassuredAndQuoted",
          )}; pos: top-left`}
        />
      );
    } else if (heli.busy_state !== null) {
      warning = (
        <span
          className={
            "uk-margin-small-left gh-text-bottom " +
            (heli.busy_state === "by_booking" ? "gh-red" : "gh-yellow")
          }
          data-uk-icon="icon: warning"
          data-uk-tooltip={`title: ${staticService.findByAlias(
            heli.busy_state === "by_booking" ? "heliAssignedToBooking" : "heliAssignedToQuote",
          )}; pos: top-left`}
        />
      );
    } else if (!isHeliInsured) {
      warning = (
        <span
          className={"uk-margin-small-left gh-text-bottom gh-red"}
          data-uk-icon="icon: warning"
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "HelicopterInsuranceWillExpireBeforeFlight",
          )}; pos: top-left`}
        />
      );
    }

    return warning;
  };

  const helis = helicopters.map((heli, index) => {
    return (
      <Item key={index}>
        <label className={"gh-helicopter-label"}>
          <input
            type={"checkbox"}
            className={"uk-checkbox uk-margin-small-right"}
            value={heli.id}
            disabled={heli.status == 3 || heli.status == 4 || heli.status == null}
            onChange={onItemChange.bind(this, heli.id)}
            checked={checked(heli.id)}
          />
          {_html(heli)}
          {heli.status != 3 && heli.status != 4 && heli.status != null
            ? manageHeliWarning(heli)
            : null}
        </label>
      </Item>
    );
  });

  return (
    <div>
      <div className="uk-flex">
        <h3
          className="uk-h3"
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("selectAircraft"),
          }}
        />
        <span
          className="uk-margin-small-left gh-text-bottom gh-yellow"
          style={{ marginTop: 5, minWidth: 20 }}
          data-uk-icon="icon: warning"
          data-uk-tooltip={`title: ${staticService.findByAlias("selectAircraftTitleTooltip")};`}
        />
      </div>
      <Form model="quoteModel">
        <List attrProps={{ className: "disabled-list uk-list" }}>{helis}</List>
        <div className="uk-margin-medium-top uk-text-center uk-text-left@m">
          <button
            type="button"
            onClick={onSubmit.bind(this, currentSelectedHelicopters)}
            className={"uk-button uk-button-primary uk-inline"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("addAircraft"),
              }}
            />
          </button>
          <a
            className={"uk-button uk-inline"}
            onClick={onModalClose}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("cancel"),
            }}
          />
        </div>
      </Form>
    </div>
  );
};

AssignHeliControlComponent.propTypes = {
  departure: PropTypes.string,
  helicopters: PropTypes.array,
  selectedHelicopters: PropTypes.array,
  currentSelectedHelicopters: PropTypes.array,
  onSubmit: PropTypes.func,
  onItemChange: PropTypes.func,
};
