import React from "react";
import { Route, Switch } from "react-router-dom";
import { brokerMarketplaceTabs } from "../../../../configs";
import { BookingLayout } from "../../../layouts";
import { redirectTo, returnRouteUrl, RouteWithSubRoutes } from "../../../../shared";
import staticService from "../../../../services/static.service";
import { BrokerEmptyLegListComponent } from "./";
import auth from "../../../../services/auth";

export class BrokerMarketPlaceEmptyLegsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersShown: false,
      showFilterIcon: true,
      filterFailed: "",
    };
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      isFiltersShown: !prevState.isFiltersShown,
    }));
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  render() {
    if (!auth.hasFeature("empty_leg_markeplace")) return redirectTo("/forbidden");

    return (
      <BookingLayout
        pageTitle={staticService.findByAlias("brokerMarketplaceEmptyLegTab")}
        isFiltersShown={this.state.isFiltersShown}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        navigationTabs={brokerMarketplaceTabs}
        activeRoute={this.props.match}
        isFilterVisible={this.state.showFilterIcon}
        filterCss={this.state.filterFailed}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  onEnter={this.onEnterPage.bind(this)}
                  title={route.title}
                  {...returnRouteUrl(route, this.props.match.url)}
                />
              );
            })}
          <Route
            path="/"
            render={(props) => (
              <BrokerEmptyLegListComponent
                {...props}
                isFiltersShown={this.state.isFiltersShown}
                parentMatch={this.props.match}
              />
            )}
          />
        </Switch>
      </BookingLayout>
    );
  }
}
