import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UIkit from "uikit";
import { bindActionCreators } from "redux";
import {
  addPrivateNoteToBooking,
  downloadAgreement,
  downloadFileByUrl,
  uploadOperatorInvoiceFile,
} from "../../../../../actions";
import staticService from "../../../../../services/static.service";
import { getLastItemFromArray, ModalComponent } from "../../../../../shared";
import auth from "../../../../../services/auth";
import Button from "../../../../common/Button";
import BookingPrivateNotes from "../BookingPrivateNotes/bookingPrivateNotes";
import UploadFiles from "../../../../forms/register/upload-files.component";

import "./bookingInfoBar.css";

import uploadIcon from "../../../../../assets/img/svg/uploadIcon.svg";
import downloadIcon from "../../../../../assets/img/svg/downloadIcon.svg";
import {
  CONFIRMED,
  PROVISIONAL,
  RESTRICT_ERROR_MESSAGE,
  IS_PREV_PAGE_BOOKING_DETAILS,
} from "../../../../../configs";
import { actions } from "react-redux-form";
import { useLocalStorage } from "react-use";
import isAllowForEditing from "../../../../../utils/isAllowForEditing";
import { showNotification } from "../../../../../utils/showNotification";
import InfoPaidDeposit from "./components/InfoPaidDeposit";

const inputCharactersLimit = 30;
const invoiceModelName = "operatorInvoiceModel";

const BookingInfoBar = (props) => {
  const {
    bookingId,
    clientId,
    status,
    handleUpdateIdentifier,
    privateNotes,
    addPrivateNoteToBooking,
    downloadAgreement,
    bookingRequestId,
    operatorInvoice,
    getHeliInvoice,
    operatorInvoiceModel,
    uploadOperatorInvoiceFile,
    documents,
    downloadFileByUrl,
    updateModel,
    bookingTerms,
    quotePrice,
    isBrokerAllowedToInteract,
    individualHelicopterServicesTerms,
  } = props;

  /*===========Global Hooks===========*/
  // eslint-disable-next-line no-unused-vars
  const [initialValue, setValue, removeLocalStorageValue] = useLocalStorage(
    IS_PREV_PAGE_BOOKING_DETAILS,
    true,
  );
  /*===========Start States===========*/
  const [showIdentifierForm, setShowIdentifierForm] = useState(false);
  const [identifierInputValue, setIdentifierInputValue] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState("");
  const [docsErr, setDocsErr] = useState(false);

  const uploadOperatorInvoiceRef = useRef();
  const previewImgOutput = useRef(null);
  const previewFileOutput = useRef(null);
  const previewFileModalRef = useRef(null);

  /*===========End States===========*/

  /*===========Start Hooks===========*/
  useEffect(() => {
    setIdentifierInputValue(clientId || "");
  }, [clientId]);

  useEffect(() => {
    document.body.classList.add("gh-view-full-booking-details-body");

    /*===========Remove AOC access when the window is closed===========*/
    window.onbeforeunload = () => removeLocalStorageValue();
    return function cleanup() {
      document.body.classList.remove("gh-view-full-booking-details-body");
      removeLocalStorageValue();
    };
  }, []);

  /*===========Start Hooks===========*/

  /*===========Start Functions===========*/
  const handleChangeInput = (e) => {
    const val = e.target.value;
    if (val.length <= inputCharactersLimit + 1) {
      setIdentifierInputValue(e.target.value);
    }
  };

  const handleChangeEdit = () => setShowIdentifierForm(!showIdentifierForm);

  const handleClose = () => {
    setShowIdentifierForm(!showIdentifierForm);
    setIdentifierInputValue(clientId);
  };

  const handleSave = () => {
    handleUpdateIdentifier(identifierInputValue).then(() => {
      setShowIdentifierForm(!showIdentifierForm);
    });
  };

  const handleSavePrivateNotes = (value) => {
    if (value !== privateNotes) {
      addPrivateNoteToBooking(bookingId, value);
    }
  };

  const handleUploadOperatorInvoice = () => {
    if (!operatorInvoiceModel.length) {
      return setDocsErr(true);
    }

    uploadOperatorInvoiceFile(bookingId, operatorInvoiceModel).then(() => {
      updateModel(invoiceModelName, []);
      uploadOperatorInvoiceRef.current.hide();
    });
  };

  const openPreviewFileModal = (file) => {
    if (file.type.includes("pdf")) {
      previewFileOutput.current.src = file.file_data;
      previewFileOutput.current.classList.remove("hide");
    } else {
      previewImgOutput.current.src = file.file_data;
      previewImgOutput.current.classList.remove("hide");
    }

    previewFileModalRef.current.open();

    setTimeout(() => {
      // URL.revokeObjectURL(previewFileOutput.current.src);
    }, 100);
  };

  const handleCLosePreviewFile = () => {
    previewFileOutput.current.classList.add("hide");
    previewImgOutput.current.classList.add("hide");
    // uploadOperatorInvoiceRef.current.open();
  };

  /*===========End Functions===========*/
  const buttons = [
    {
      alias: "uploadOperatorInvoice",
      func: () => {
        uploadOperatorInvoiceRef.current.open();
      },
      upload: true,
      hide: auth.isBroker() || (auth.isOperator() && operatorInvoice) || status === PROVISIONAL,
      className: `uk-upload-btn ${status !== PROVISIONAL ? "gh-pulse-animation" : ""}`,
      disabled: !isAllowForEditing(),
    },
    {
      alias: "aocDocumentsTitle",
      link: `/aoc-documents/${bookingId}`,
      target: "_blank",
      hide: auth.isOperator(),
      linkAdditionalOnClick: () => setValue(true),
      disabled: !isAllowForEditing() || !isBrokerAllowedToInteract,
    },
    {
      alias: "charterTerms",
      func: () => {
        if (!isAllowForEditing()) {
          showNotification(RESTRICT_ERROR_MESSAGE, "danger");
        } else {
          setIsBtnLoading("charterTerms");
          downloadAgreement(bookingRequestId).then(() => {
            setIsBtnLoading("");
          });
        }
      },
      hide: auth.getMainRole() === "operator" ? !auth.getHeliServiceTerms() : !bookingTerms,
      disabled: !isAllowForEditing() || !isBrokerAllowedToInteract,
    },
    {
      alias: "flightContract",
      func: () => {
        const file = individualHelicopterServicesTerms[0];
        const fileType = getLastItemFromArray(file.type.split("/"));
        setIsBtnLoading("charterTerms");

        downloadFileByUrl(file.file_url, file.name || "file_name", fileType)
          .then(() => {
            setIsBtnLoading("");
          })
          .catch(() => {
            setIsBtnLoading("");
          });
      },
      hide: !individualHelicopterServicesTerms.length,
    },
    {
      alias: operatorInvoice ? "operatorInvoice" : "waitingOperatorInvoice",
      func: () => {
        if (!isAllowForEditing()) {
          showNotification(RESTRICT_ERROR_MESSAGE, "danger");
        } else {
          setIsBtnLoading("operatorInvoice");
          downloadFileByUrl(
            operatorInvoice?.file_url + "?download=1" || operatorInvoice?.file_data + "?download=1",
            operatorInvoice?.name || "operator_invoice",
          ).then(() => {
            setIsBtnLoading("");
          });
        }
      },
      disabled: !operatorInvoice || !isAllowForEditing() || !isBrokerAllowedToInteract,
      hide: auth.getMainRole() === "operator" && !operatorInvoice,
    },
    {
      alias: "getHeliInvoice",
      func: () => {
        if (!isAllowForEditing()) {
          showNotification(RESTRICT_ERROR_MESSAGE, "danger");
        } else {
          setIsBtnLoading("getHeliInvoice");
          downloadFileByUrl(getHeliInvoice, "get_heli_invoice").then(() => {
            setIsBtnLoading("");
          });
        }
      },
      disabled: !isAllowForEditing() || !isBrokerAllowedToInteract,
      hide: !getHeliInvoice,
    },
    {
      alias: "operatorPaymentDocumentLabel",
      func: () => {
        if (!isAllowForEditing()) {
          showNotification(RESTRICT_ERROR_MESSAGE, "danger");
        } else {
          setIsBtnLoading("operatorPaymentDocumentLabel");
          downloadFileByUrl(
            auth.isOperator() ? documents.operator : documents.broker,
            "payment_remittance",
          ).then(() => {
            setIsBtnLoading("");
          });
        }
      },
      hide: (auth.isOperator() && !documents.operator) || (auth.isBroker() && !documents.broker),
      disabled: !isAllowForEditing() || !isBrokerAllowedToInteract,
    },
    {
      alias: "downloadAsPDF",
      func: () => {
        if (!isAllowForEditing()) {
          showNotification(RESTRICT_ERROR_MESSAGE, "danger");
        } else {
          try {
            document.querySelectorAll(".uk-accordion").forEach((accordion) => {
              Array.from(accordion.children).forEach((accordionItem, index) => {
                if (!accordionItem.classList.contains("uk-open")) {
                  UIkit.accordion(accordion).toggle(index, true);
                }
              });
            });
          } catch (error) {
            console.error(error);
          }
          window.print();
        }
      },
      disabled: !isAllowForEditing() || !isBrokerAllowedToInteract,
      hide: status === PROVISIONAL,
    },
  ];

  return (
    <div className="gh-booking-info-bar">
      <div className="gh-booking-info-bar-wrapper uk-flex">
        <div className="gh-booking-info-bar-col">
          <div className="gh-booking-info-bar-key">
            {staticService.findByAlias("gridBookingsHeaderID")}
          </div>
          <div className="gh-booking-info-bar-value">GHB{bookingId}</div>
        </div>

        {auth.isBroker() && !isBrokerAllowedToInteract ? (
          <div className="gh-booking-info-bar-col">
            <div className="gh-booking-info-bar-key">
              {staticService.findByAlias("gridColumnClientId")}
            </div>
            {!showIdentifierForm ? (
              <div className="gh-booking-info-bar-value">
                {clientId || "---"}
                {!auth.isBroker() ? (
                  <div
                    className="gh-booking-info-bar-edit-btn btn-style"
                    onClick={handleChangeEdit}
                  >
                    Edit
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {showIdentifierForm ? (
              <div className="gh-booking-info-bar-identifier-form">
                <input
                  type="text"
                  className="gh-booking-info-bar-identifier-input"
                  value={identifierInputValue}
                  onChange={handleChangeInput}
                />
                {identifierInputValue.length >= inputCharactersLimit + 1 ? (
                  <span className="gh-message-error">{`Must be ${inputCharactersLimit} or fewer characters`}</span>
                ) : (
                  ""
                )}

                <button
                  disabled={identifierInputValue.length >= inputCharactersLimit + 1}
                  uk-icon="icon: check"
                  className="gh-booking-info-bar-identifier-btn btn-style"
                  style={{ color: "#1abc9c" }}
                  onClick={handleSave}
                />

                <button
                  uk-icon="icon: close"
                  className="gh-booking-info-bar-identifier-btn btn-style"
                  style={{ color: "#e74c3c" }}
                  onClick={handleClose}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div className="gh-booking-info-bar-col">
          <div className="gh-booking-info-bar-key">
            {staticService.findByAlias("gridHeaderStatus")}
          </div>
          <div className="gh-booking-info-bar-value status">
            {status === PROVISIONAL ? (
              staticService.findByAlias("provisionallyConfirmed")
            ) : status === CONFIRMED ? (
              <span style={{ color: "#10b997" }}>{status}</span>
            ) : (
              status
            )}
          </div>
        </div>

        <InfoPaidDeposit quotePrice={quotePrice} bookingId={bookingId} status={status} />
      </div>

      <div className="gh-booking-info-bar-buttons">
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            {!button.hide ? (
              <Button
                className={button.className || ""}
                variant="outlined"
                color="primaryBorder"
                type={button.link ? "link" : "button"}
                onClick={button.func ? button.func : button.link}
                linkAdditionalOnClick={button?.linkAdditionalOnClick}
                target={button.target || ""}
                loading={button.alias === isBtnLoading}
                disabled={button.disabled}
              >
                <img src={button.upload ? uploadIcon : downloadIcon} alt="Icon" />
                <span>{staticService.findByAlias(button.alias)}</span>
              </Button>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
      </div>

      {/*MODALS*/}
      <ModalComponent
        title={staticService.findByAlias("uploadOperatorInvoice")}
        ref={uploadOperatorInvoiceRef}
        id="createEmptyLegFromThisBookingRef"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onModalClose={() => {
          setDocsErr(false);
        }}
      >
        <UploadFiles
          model={operatorInvoiceModel}
          modelName={invoiceModelName}
          uploadTitle=""
          docs={operatorInvoiceModel}
          withOutGlobalLoading
          multiple={false}
          onlyOneFile={true}
          openModal={(file) => {
            openPreviewFileModal(file);
          }}
        />

        <div className="gh-message-error">
          {docsErr && !operatorInvoiceModel.length ? "Docs is required." : ""}
        </div>

        <div className="uk-flex uk-flex-center uk uk-margin-top">
          <Button onClick={handleUploadOperatorInvoice}>
            {staticService.findByAlias("submit")}
          </Button>
        </div>
      </ModalComponent>

      <ModalComponent
        title=""
        ref={previewFileModalRef}
        id={"previewFileModal"}
        modalClass="gh-confirm-payment-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={handleCLosePreviewFile}
      >
        <img ref={previewImgOutput} alt="preview" className="hide" />
        <div className="gh-preview-file gh-padding-10">
          <iframe ref={previewFileOutput} title="preview" className="gh-preview-file-pdf hide" />
        </div>
      </ModalComponent>

      <BookingPrivateNotes
        privateNotes={privateNotes}
        handleSavePrivateNotes={handleSavePrivateNotes}
      />
    </div>
  );
};

BookingInfoBar.defaultProps = {
  individualHelicopterServicesTerms: [],
};

BookingInfoBar.propTypes = {
  bookingId: PropTypes.number,
  clientId: PropTypes.string,
  status: PropTypes.string,
  handleUpdateIdentifier: PropTypes.func,
  privateNotes: PropTypes.string,
  addPrivateNoteToBooking: PropTypes.func,
  downloadAgreement: PropTypes.func,
  bookingRequestId: PropTypes.number,
  operatorInvoiceModel: PropTypes.array,
  uploadOperatorInvoiceFile: PropTypes.func,
  documents: PropTypes.object,
  downloadFileByUrl: PropTypes.func,
  operatorInvoice: PropTypes.object,
  getHeliInvoice: PropTypes.object,
  isBrokerAllowedToInteract: PropTypes.bool,
  quotePrice: PropTypes.object,
  individualHelicopterServicesTerms: PropTypes.array,
  updateModel: PropTypes.func,
  bookingTerms: PropTypes.any,
};

const mapStateToProps = (state) => ({
  operatorInvoiceModel: state.operatorInvoiceModel,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addPrivateNoteToBooking,
      downloadAgreement,
      uploadOperatorInvoiceFile,
      downloadFileByUrl,
      updateModel: (model, value) => dispatch(actions.change(model, value)),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfoBar);
