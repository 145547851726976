import React from "react";
import PropTypes from "prop-types";

const loaderStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const CustomLoader = (props) => {
  const { ratio } = props;

  return (
    <div style={{ height: "100vh" }}>
      <div data-uk-spinner={`ratio: ${ratio}`} style={loaderStyle} />;
    </div>
  );
};

CustomLoader.defaultProps = {
  ratio: 3,
};

CustomLoader.propTypes = {
  ratio: PropTypes.number,
};

export default CustomLoader;
