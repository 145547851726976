import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Control, Errors } from "react-redux-form";
import {
  CustomValidateError,
  fireTracking,
  isObjectEmpty,
  ModalComponent,
  replaceAttributesInString,
  setBrowserHistory,
  storage,
  time,
  updateModel,
} from "../../../../../shared";
import {
  ADD_DISCUSSION_MESSAGE,
  assignRequest,
  CLEAR_ERRORS,
  downloadDocImage,
  getBrokerRequestDetail,
  getDiscussionMessages,
  getQuote,
  getQuotePDF,
  loadHelicopterGallery,
  markAssSeenQuotesComments,
  PREVIOUS_PATH,
  quoteWithdrawByBroker,
  rejectQuote,
  REQUEST_UPDATED,
  requestSwitchBroker,
  RESET_QUOTE_UPDATE_MODEL,
  SEEN_QUOTE,
  sendDeclineReasons,
  UPDATE_MORE_DETAIL_REQUESTS,
  updateIdentifier,
  updateQuoteStatus,
  updateRequest,
} from "../../../../../actions";
import { BlockComponent, ConfirmComponent } from "../../../../common";
import {
  ASSIGN_REQUEST,
  config,
  QUOTE_DECLINED,
  QUOTE_REJECTED,
  QUOTE_UNSUCCESSFULL,
  REQUEST_WITHDRAWN,
  validationMessages,
  validationRules,
  WITHDRAWN,
} from "../../../../../configs";
import { GalleryModalComponent, RequestDetailComponent } from "../../common";
import staticService from "../../../../../services/static.service";
import { FeedbackFormComponent } from "../../../../forms";
import { BrokerQuoteComponent } from "./broker-quote.component";
import { BrokerRequestNav } from "../broker-request-nav.component";
import { BrokerPad } from "../broker-pad.component";
import marker from "../../../../../assets/img/marker.svg";
import moment from "moment";
import LearMoreVetting from "../../../../common/LearnMoreVetting";
import * as types from "../../../../../actions/actionTypes";
import { showNotification } from "../../../../../utils/showNotification";
import { generateAccountLocalization } from "../../../../../context";
import Tabs from "../../../../common/Tabs";
import Tab from "../../../../common/Tabs/Tab";
import RequestFeedback from "../RequestFeedback";
import QuotesReceived from "../QuotesReceived";

const model = "feedbackModel";
const { max, required, min } = validationRules;

class BrokerRequestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.accountLocalization = generateAccountLocalization();
    this.state = {
      view: "quotes",
      action: null,
      quote: null,
      galleryState: false,
      aircraftModel: {},
      form: {},
      request: {},
      seenQuote: {},
      selectedQuote: {},
      selectedHeli: 0,
      selectedFile: {},
      currentFile: null,
      isSendToEdit: false,
      brokerIsAllowedToEdit: false,
      teamMembersList: [],
      padModalProps: {
        type: "",
        title: "",
        coords: "",
      },
      tabs: [
        {
          title: staticService.findByAlias("flightDetails"),
          view: "request-details",
        },
        { title: staticService.findByAlias("quotes"), view: "quotes" },
      ],
      closeRequestDialog: false,
      dialogProps: this.defaultDialogProps(),
    };
    this.loadData = this.loadData.bind(this);
    this.loadView = this.loadView.bind(this);
    this.selectedView = this.selectedView.bind(this);
    this.triggerAction = this.triggerAction.bind(this);
    this.quoteCancel = this.quoteCancel.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
    this.openGallery = this.openGallery.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.defaultDialogProps = this.defaultDialogProps.bind(this);
    this.withdrawDialogProps = this.withdrawDialogProps.bind(this);
    this.onErrorCloseModal = this.onErrorCloseModal.bind(this);
    this.feedbackForm = this.feedbackForm.bind(this);
    this.showPad = this.showPad.bind(this);
    this.editRequestDetailsCounter = this.editRequestDetailsCounter.bind(this);
    fireTracking(props.location.pathname);
  }

  componentDidMount() {
    storage.delete("request_form");

    if (!this.props.requestDetail.id || !this.props.requestDetail.activeQuotes) {
      this.loadData(this.props.match.params.id);
    }
    this.props.onEnter(false);
  }

  emptyModel(data) {
    return Object.assign(
      {
        message: "",
        status: "",
        id: "",
      },
      data,
    );
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { requestDetail } = this.props;

    if (
      requestDetail.activeQuotes &&
      requestDetail.activeQuotes.length &&
      nextProps.requestDetail.activeQuotes &&
      !nextProps.requestDetail.activeQuotes.length
    ) {
      setTimeout(() => {
        // this.withdrawRequestModal.open();
      }, 500);
    }

    if (requestDetail.activeQuotes !== nextProps.requestDetail.activeQuotes) {
      this.setState({ closeRequestDialog: false });
    }
    this.setState({ form: nextProps.form });

    if (requestDetail !== nextProps.requestDetail) {
      const request = {
        id: nextProps.requestDetail.id,
        status: nextProps.requestDetail.status,
        identifier:
          nextProps.requestDetail.identifier !== null ? nextProps.requestDetail.identifier : "",
      };
      this.context.store.dispatch(actions.change("identifierModel", request));
    }

    if (nextProps.quoteUpdate.isRejected) {
      this.props.resetModel(model);
      this.setState({
        quote: null,
        action: null,
        dialogProps: this.defaultDialogProps(),
      });
      this.context.store.dispatch({ type: RESET_QUOTE_UPDATE_MODEL });
      this.confirmComponent.closeModal();
    }

    if (Object.keys(nextProps.seenQuote).length !== 0) {
      this.setState({ seenQuote: nextProps.seenQuote });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { requestDetail } = this.props;

    if (prevState.brokerIsAllowedToEdit !== requestDetail.ability_to_edit) {
      this.setState({ brokerIsAllowedToEdit: requestDetail.ability_to_edit });
    }

    if (
      !isObjectEmpty(requestDetail) &&
      prevState.teamMembersList &&
      requestDetail.team_members &&
      prevState.teamMembersList.length !== requestDetail.team_members.length
    ) {
      this.setState({ teamMembersList: requestDetail.team_members });
    }
  }

  loadData(id) {
    this.context.store.dispatch(getBrokerRequestDetail(id));
  }

  openGallery(aircraftModel) {
    this.setState({ galleryState: true, aircraftModel: aircraftModel });
    this.props.loadHelicopterGallery(aircraftModel.id, aircraftModel.manufacturer_id);
    this.gallery.modal.open();
  }

  closeGallery() {
    this.setState({ galleryState: false, aircraftModel: {} });
  }

  closeModal() {
    this.setState({ request: {} });
    this.confirmComponent.closeModal();
  }

  onErrorCloseModal() {
    this.props.resetModel(model);
    this.confirmComponent.closeModal();
  }

  triggerAction(
    action,
    quote,
    openModal = true,
    options = { successText: "Request assigned with success" },
  ) {
    const { updateModel, requestSwitchBroker, updateRequestSuccess } = this.props;

    switch (action) {
      case QUOTE_DECLINED:
      case QUOTE_UNSUCCESSFULL:
        this.setState({
          quote: quote,
          action: action,
          dialogProps: this.defaultDialogProps(quote),
        });
        updateModel("quoteSelectedStatus", quote.status);
        updateModel("declineModel", this.emptyModel({ id: quote.id, status: action }));
        break;
      case REQUEST_WITHDRAWN:
        this.setState({
          quote: quote,
          action: action,
          dialogProps: this.withdrawDialogProps(),
        });
        break;
      case ASSIGN_REQUEST:
        return requestSwitchBroker(quote.id, quote).then((request) => {
          updateRequestSuccess(request);
          showNotification(options.successText, "success");
          return request;
        });
    }

    if (openModal) {
      this.confirmComponent.openModal();
    }
  }

  get quotes() {
    const { activeQuotes } = this.props.requestDetail;
    // console.log("activeQuotes", activeQuotes);
    const bookingQuote =
      activeQuotes && activeQuotes.find((quote) => quote.old_status === "booking_init");
    const quotesList = () => {
      if (bookingQuote) {
        return [
          bookingQuote,
          ...activeQuotes.filter((quote) => quote.old_status !== "booking_init"),
        ];
      } else {
        return activeQuotes;
      }
    };

    // console.log("get quote list", quotesList());

    const opers = (id) => {
      const x = {};
      activeQuotes &&
        activeQuotes.map((q) => {
          x[q.operator] = q.operator;
        });
      return Object.keys(x).indexOf(id.toString()) + 1;
    };

    const ruleToDisplayBanner = (index, quote) => {
      if (
        index === 0 ||
        (index !== 0 &&
          (activeQuotes[index - 1].operator !== quote.operator ||
            activeQuotes[index - 1].status === "booking_init"))
      )
        return true;
    };

    const ruleToDisplayConnectedBorders = (index, quote) => {
      if (
        index !== 0 &&
        activeQuotes[index - 1].operator === quote.operator &&
        activeQuotes[index - 1].status !== "booking_init"
      )
        return true;
    };

    const ruleToHideBottomBorder = (index, quote) => {
      if (
        index !== activeQuotes.length - 1 &&
        quote.status !== "booking_init" &&
        activeQuotes[index + 1].operator === quote.operator
      )
        return true;
    };

    return (
      <div className="uk-margin-bottom" id="br_quotes_container">
        {activeQuotes && activeQuotes.some((quote) => quote.status === "booking_init") ? (
          ""
        ) : (
          <div>
            <hr className="uk-margin-small-bottom uk-margin-medium-top" />
            <h4
              className="uk-width-1-1@s uk-margin-remove uk-padding-remove gh-title"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("quotesReceived"),
              }}
            />
          </div>
        )}

        {(activeQuotes &&
          quotesList().map((quote, index) => (
            <div key={index}>
              {quote.status === "booking_init" && activeQuotes.length >= 1 ? (
                <div>
                  <hr className="uk-margin-small-bottom uk-margin-medium-top" />
                  <h4
                    className="uk-width-1-1@s uk-margin-remove uk-padding-remove gh-title"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("quoteChosenAwaitingLabel"),
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <BrokerQuoteComponent
                key={index}
                visible={true}
                showTail={false}
                quote={quote}
                quoteIndex={index}
                request={this.props.requestDetail}
                onSelectPath={"/flights/requests/" + this.props.requestDetail.id}
                action={this.triggerAction}
                gallery={this.openGallery}
                onReject={this.props.reject}
                showPad={this.showPad}
                brokerIsAllowedToEdit={this.state.brokerIsAllowedToEdit}
                displayBanner={ruleToDisplayBanner(index, quote)}
                displayConnectBorders={ruleToDisplayConnectedBorders(index, quote)}
                hideBorderBottom={ruleToHideBottomBorder(index, quote)}
                operIndex={opers}
              />
              {quote.status === "booking_init" && activeQuotes.length > 1 ? (
                <div>
                  <hr className="uk-margin-small-bottom uk-margin-medium-top" />
                  <h4
                    className="uk-width-1-1@s uk-margin-remove gh-padding-left-10 gh-title"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("otherQuotesReceived"),
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ))) ||
          ""}
        {this.noQuotes}
      </div>
    );
  }

  get noQuotes() {
    if (
      this.props.requestDetail.activeQuotes &&
      this.props.requestDetail.activeQuotes.length === 0
    ) {
      return (
        <div>
          <span
            className="uk-width-1-1@s uk-display-block uk-margin-small-top uk-padding-remove"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("noQuotesInRequestMessage"),
            }}
          />
        </div>
      );
    }

    return null;
  }

  loadView(view) {
    switch (view) {
      case "quotes":
        return this.quotes;
      default:
        return (
          <RequestDetailComponent
            request={this.props.requestDetail}
            showHelicopImage={this.props.screenW > config.minDeviceWidth}
          />
        );
    }
  }

  selectedView(view, ev) {
    ev.preventDefault();
    this.setState({ view: view });
  }

  quoteCancel(model) {
    this.context.store.dispatch(
      quoteWithdrawByBroker(
        {
          status: QUOTE_UNSUCCESSFULL,
          id: model.id,
          request: this.props.requestDetail.id,
          reason: model.message,
        },
        { action: "changed to unsuccessfull", entityName: "quote" },
        this.onErrorCloseModal,
      ),
    );
  }

  onDialogSelect() {
    if (this.props.withdrawRequestReason.length > 3) {
      this.props.updateRequest(
        {
          status: WITHDRAWN,
          id: this.props.requestDetail.id,
          reason: this.props.withdrawRequestReason,
        },
        () => setBrowserHistory(this.props.history, "/flights/requests/filter/open"),
      );

      this.context.store.dispatch(actions.reset("withdrawRequestReason"));
    }
  }

  quoteRejected(model) {
    this.context.store.dispatch(
      updateQuoteStatus(
        {
          status: QUOTE_REJECTED,
          message: model.message,
          id: model.id,
        },
        { action: "rejected" },
      ),
    );
  }

  submitFeedback(model) {
    if (model.status === "declined" && model.message.length) {
      this.context.store.dispatch(actions.reset("declineModel"));
      return this.state.quote.status === "open"
        ? this.quoteRejected(model)
        : this.quoteCancel(model);
    }
  }

  get isAllowed() {
    return !!(
      this.props.requestDetail &&
      this.props.requestDetail.status !== "expired" &&
      this.props.requestDetail.status !== "closed" &&
      this.props.requestDetail.status !== "withdrawn"
    );
  }

  feedbackForm() {
    return (
      <FeedbackFormComponent
        label=""
        feedback={true}
        modelName="declineModel"
        modelField={".message"}
        form={this.props.feedbackModel}
        visible={true}
        action={this.submitFeedback.bind(this)}
      />
    );
  }

  defaultDialogProps(quote) {
    return {
      content: this.feedbackForm(),
      className: "uk-alert uk-alert-default",
      buttonsProps: {},
      onSelect: () => {},
      onReject: () => {
        this.props.resetModel("declineModel");
      },
      showCloseBtn: true,
      modalProps: {
        id: "decline-booking-modal",
        options: {
          clsPage: "",
          bgClose: false,
          escClose: false,
        },
        title:
          quote && quote.status === "open"
            ? staticService.findByAlias("rejectQuoteTitle")
            : staticService.findByAlias("CancelRequestBooking"),
        className: "gh-margin-bottom-0",
      },
    };
  }

  enterHandler(e) {
    if (e.keyCode === 13 && this.props.withdrawRequestReason.length > 3) {
      e.preventDefault();
      return this.onDialogSelect();
    }
  }

  withdrawDialogProps() {
    return {
      content: (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("confirmRequestWithdraw"),
            }}
          />
          <div className="uk-margin">
            <Control.textarea
              className={"uk-textarea"}
              model="withdrawRequestReason"
              validators={{
                max: max(config.quoteDescriptionMaxLength, false),
                required,
                min: min(4),
              }}
              changeAction={updateModel}
              onKeyDown={this.enterHandler.bind(this)}
            />
            <Errors
              model="withdrawRequestReason"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(null, "Reason"),
                min: validationMessages().minMessage.bind(null, "4")(),
              }}
            />
            {this.props.fieldError && (
              <ul className="ul validation-errors disabled-list gh-input-errors">
                <li dangerouslySetInnerHTML={{ __html: this.props.fieldError }} />
              </ul>
            )}
            <Errors
              model="withdrawRequestReason"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                max: validationMessages().maxMessage(
                  config.quoteDescriptionMaxLength,
                  "characterLimitExceeded",
                ),
              }}
            />
          </div>
        </div>
      ),
      className: "uk-alert uk-alert-warning gh-alert-without-bg",
      buttonsProps: {
        cancel: { title: staticService.findByAlias("cancel") },
        continue: {
          title: staticService.findByAlias("withdrawButton"),
          css: "uk-button-danger withdraw-request-modal_btn",
          closeOnClick: false,
        },
      },
      showCloseBtn: false,
      onSelect: () => {
        if (this.props.withdrawRequestReason.length > 3) {
          this.onDialogSelect.bind(this)();
        } else {
          this.context.store.dispatch(actions.setTouched("withdrawRequestReason"));
        }
      },
      onReject: () => {
        this.closeModal();
        this.context.store.dispatch(actions.reset("withdrawRequestReason"));
      },
      modalProps: {
        id: "withdraw-request-modal",
        title: staticService.findByAlias("confirmRequestWithdrawModalTitle"),
        options: {
          clsPage: "",
          bgClose: false,
          escClose: false,
        },
      },
    };
  }

  showPad(type, title, coords) {
    this.setState({ padModalProps: { type, title, coords, status: true } }, () =>
      this.padModal.open(),
    );
  }

  showVettingMore() {
    this.vettingModal.open();
  }

  quoteLeg(quote, leg, index) {
    const { selectedHeli } = this.state;
    return (
      <div data-uk-grid key={index}>
        <div className="uk-width-1-1 gh-margin-ex-small-top">
          <div className="gh-pdf-leg-title">LEG {index + 1}</div>
        </div>
        <div className="uk-width-1-1 uk-margin-remove-top">
          <div data-uk-grid>
            <div className="uk-width-1-3">
              <span className="gh-quote-label">{staticService.findByAlias("helicopter")}</span>
            </div>
            <div className="uk-width-2-3">
              <span className="gh-quote-text">
                {quote.aircrafts.length === 1 && (
                  <span>{quote.aircrafts && quote.aircrafts[0].aircraft_model}</span>
                )}
                {quote.aircrafts.length !== 1 &&
                  quote.aircrafts.map((heli, heliIndex) => (
                    <span key={heliIndex} className="gh-heli-label">
                      {heliIndex === 0 || heliIndex === quote.aircrafts.length ? (
                        ""
                      ) : (
                        <span className="gh-text-green"> + </span>
                      )}
                      <span className={selectedHeli === heliIndex ? "gh-heli-active" : ""}>
                        {heli.aircraft_model}
                      </span>
                    </span>
                  ))}
              </span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 gh-margin-ex-small-top">
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-3@s">
              <span className="gh-quote-label">
                {staticService.findByAlias("proposedTakeOffSite")}
              </span>
            </div>
            <div className="uk-width-1-1 uk-width-2-3@s">
              <div className="gh-quote-pad">
                <img className="gh-quote-pad-marker" src={marker} alt="marker" />
                <span className="gh-quote-text">{leg.from_name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 gh-margin-ex-small-top">
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-3@s">
              <span className="gh-quote-label">
                {staticService.findByAlias("proposedLandingSite")}
              </span>
            </div>
            <div className="uk-width-1-1 uk-width-2-3@s">
              <div className="gh-quote-pad">
                <img className="gh-quote-pad-marker" src={marker} alt="marker" />
                <span className="gh-quote-text">{leg.to_name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 gh-margin-ex-small-top">
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-3@s">
              <span className="gh-quote-label">{staticService.findByAlias("dateOfDeparture")}</span>
            </div>
            <div className="uk-width-1-1 uk-width-2-3@s">
              <span className="gh-quote-text">
                {moment(leg.departure_time).format("DD MMMM, YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 gh-margin-ex-small-top">
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-3@s">
              <span className="gh-quote-label">{staticService.findByAlias("timeOfDeparture")}</span>
            </div>
            <div className="uk-width-1-1 uk-width-2-3@s">
              <span className="gh-quote-text">
                {moment(leg.departure_time).format(this.accountLocalization.timeFormat)} (UTC
                {moment(leg.departure_time).format("Z")})
              </span>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 gh-margin-ex-small-top">
          <div data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-3@s">
              <span className="gh-quote-label">
                {staticService.findByAlias("estimateFlightDuration")}
              </span>
            </div>
            <div className="uk-width-1-1 uk-width-2-3@s">
              <span className="gh-quote-text">{leg.time_max && time(leg.time_max)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  requestDetailsCounter() {
    const counter = this.props.requestDetail?.more_detail_requests?.filter(
      (req) => !req.broker_response,
    ).length;
    return counter || null;
  }

  editRequestDetailsCounter(moreDetailRequestsUpdated) {
    this.context.store.dispatch({
      type: UPDATE_MORE_DETAIL_REQUESTS,
      payload: moreDetailRequestsUpdated,
    });
  }

  render() {
    const { match, requestDetail, screenInnerW, history, heliImages } = this.props;

    const {
      selectedQuote,
      padModalProps,
      isSendToEdit,
      teamMembersList,
      brokerIsAllowedToEdit,
      dialogProps,
    } = this.state;

    return (
      <div>
        <BrokerRequestNav
          backPath={match.path.replace("/:id", "")}
          closeRequestDialog={this.state.closeRequestDialog}
          isAllowed={this.isAllowed}
          requestDetail={requestDetail}
          triggerAction={this.triggerAction}
          backButton={staticService.findByAlias(screenInnerW < 380 ? "back" : "backToMyRequests")}
          match={match}
          visibleWithdraw={true}
          isSendToEdit={isSendToEdit}
          history={history}
          teamMembersList={teamMembersList}
          brokerIsAllowedToEdit={brokerIsAllowedToEdit}
        />

        {!isObjectEmpty(requestDetail) ? (
          <div className="uk-container">
            <Tabs
              className="gh-request-tabs"
              defaultSelectedTab={
                requestDetail.more_detail_requests && requestDetail.more_detail_requests.length
                  ? 0
                  : 1
              }
            >
              <Tab title={PreQuoteTitle} counter={this.requestDetailsCounter()}>
                <div className="gh-request-feedback">
                  <h3 className="gh-request-feedback-title">
                    {staticService.findByAlias("requestFeedbackTitle")}
                  </h3>

                  {requestDetail.more_detail_requests &&
                    requestDetail.more_detail_requests.map((moreDetailsRequest, index) => (
                      <RequestFeedback
                        request={requestDetail}
                        isAllowToEditRequest={
                          !requestDetail?.activeQuotes?.length && requestDetail.status === "open"
                        }
                        moreDetailsRequest={moreDetailsRequest}
                        index={index}
                        key={index}
                        history={history}
                        editCounter
                        onSubmit={this.editRequestDetailsCounter}
                      />
                    ))}
                </div>
              </Tab>

              <Tab title={() => <QuotesTitle />}>
                <QuotesReceived
                  requestDetail={requestDetail}
                  history={history}
                  showPad={this.showPad}
                />
              </Tab>
            </Tabs>
          </div>
        ) : (
          ""
        )}

        {/*===================== RENDER MODALS ========================*/}

        <BlockComponent visible={true} parentProps={{ className: "" }}>
          <div>
            {padModalProps.type.length ? (
              <ModalComponent
                title={padModalProps.type}
                options={{
                  clsPage: "",
                  bgClose: false,
                  escClose: false,
                }}
                onClose={() =>
                  this.setState({ padModalProps: { ...padModalProps, status: false } })
                }
                modalClass="gh-pad-modal"
                id="gh-pad-modal"
                ref={(padModal) => (this.padModal = padModal)}
              >
                {padModalProps.status && <BrokerPad pad={padModalProps} />}
              </ModalComponent>
            ) : (
              ""
            )}

            <ModalComponent
              title={staticService.findByAlias("ghVettingProcess")}
              ref={(vettingModal) => (this.vettingModal = vettingModal)}
              modalClass="gh-vetting-modal"
              id="learn-more-vetting"
              options={{ clsPage: "", bgClose: false, escClose: false }}
              onClose={this.closeModal.bind(this)}
            >
              <LearMoreVetting vetting={selectedQuote && selectedQuote.vetting_process} />
            </ModalComponent>

            <ConfirmComponent
              ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
              info={dialogProps.content}
              rejectRequest
              alertType={dialogProps.className}
              buttonsProps={dialogProps.buttonsProps}
              modalProps={dialogProps.modalProps}
              onReject={dialogProps.onReject}
              onSelect={dialogProps.onSelect}
              showCloseBtn={dialogProps.showCloseBtn}
            />
            {/*{this.loadView(this.state.view)}*/}

            <GalleryModalComponent
              onClose={this.closeGallery}
              ref={(gallery) => (this.gallery = gallery)}
              items={heliImages}
              modalTitle={replaceAttributesInString(
                {
                  manufacturer: this.state.aircraftModel.manufacturer,
                  model: this.state.aircraftModel.aircraft_model,
                },
                staticService.findByAlias("premiumGalleryModalTitle"),
              )}
              modalContent={replaceAttributesInString(
                {
                  manufacturer: this.state.aircraftModel.manufacturer,
                  model: this.state.aircraftModel.aircraft_model,
                },
                staticService.findByAlias("premiumGalleryModalContent"),
              )}
            />
          </div>
        </BlockComponent>
      </div>
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: RESET_QUOTE_UPDATE_MODEL });
    this.context.store.dispatch({ type: REQUEST_UPDATED, request: {} });
    this.props.resetModel(model);
    this.props.onEnter(true);
    this.context.store.dispatch({
      type: PREVIOUS_PATH,
      path: this.props.match.url,
    });
    this.context.store.dispatch({ type: ADD_DISCUSSION_MESSAGE, messages: [] });
    this.context.store.dispatch({ type: SEEN_QUOTE, seenQuote: {} });
    this.context.store.dispatch({ type: CLEAR_ERRORS });
  }
}

BrokerRequestComponent.contextTypes = {
  store: PropTypes.object,
  activeQuotes: PropTypes.array,
};

BrokerRequestComponent.propTypes = {
  requestDetail: PropTypes.object,
  requestSwitchBroker: PropTypes.func,
  updateRequestSuccess: PropTypes.func,
  match: PropTypes.object,
  screenInnerW: PropTypes.number,
  history: PropTypes.object,
  resetModel: PropTypes.func,
  onEnter: PropTypes.func,
  heliImages: PropTypes.array,
  location: PropTypes.object,
  quoteUpdate: PropTypes.object,
  seenQuote: PropTypes.object,
  loadHelicopterGallery: PropTypes.func,
  form: PropTypes.object,
  updateModel: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reject: rejectQuote,
      updateRequest: updateRequest,
      assignRequest: assignRequest,
      getQuote: getQuote,
      getQuotePDF: getQuotePDF,
      sendDeclineReasons: sendDeclineReasons,
      getDiscussionMessages: getDiscussionMessages,
      updateIdentifier: updateIdentifier,
      updateModel: (modelName, value) => dispatch(actions.change(modelName, value)),
      resetModel: (modelName) => dispatch(actions.reset(modelName)),
      updateRequestSuccess: (request) => dispatch({ type: types.REQUEST_UPDATED, request }),
      loadHelicopterGallery,
      downloadDocImage,
      markAssSeenQuotesComments,
      requestSwitchBroker,
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    requestDetail: state.requestDetail || {},
    quoteUpdate: state.quoteUpdate,
    heliImages: state.helicopterGallery,
    form: state.forms.declineRequestModel,
    reasons: state.requests.reasons,
    quoteDiscussion: state.quoteDiscussion && state.quoteDiscussion.data,
    quoteIdDiscussion: state.quoteDiscussion && state.quoteDiscussion.quote_id,
    feedbackModel: state.feedbackModel,
    declineModel: state.feedbackModel,
    seenQuote: state.seenQuote,
    withdrawRequestReason: state.withdrawRequestReason,
    feedbackFormError:
      state.errorObj &&
      state.errorObj.errors &&
      state.errorObj.errors.body &&
      state.errorObj.errors.body[0],
    ...state.screenDimensions,
  };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerRequestComponent);
export { COMPONENT as BrokerRequestComponent };

//todo: refactor this component

const PreQuoteTitle = () => (
  <>
    <div className="uk-visible@s">{staticService.findByAlias("brokerPreQuoteFeedback")}</div>
    <div className="uk-hidden@s">{staticService.findByAlias("preQuoteFeedbackMobile")}</div>
  </>
);

const QuotesTitle = () => (
  <>
    <div className="uk-visible@s">{staticService.findByAlias("quotesReceived")}</div>
    <div className="uk-hidden@s">{staticService.findByAlias("quotes")}</div>
  </>
);
