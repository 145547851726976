import PropTypes from "prop-types";
import React from "react";
import useDebounce from "../../../hooks/useDebounce";
import condStrings from "../../../utils/condString";

import "./index.css";

export default function Autocomplete(props) {
  const {
    endpoint,
    onSelect,
    label,
    placeholder,
    name,
    className,
    inputClassName,
    optionsClassName,
    optionsPosition,
    value,
    setValue,
    config,
    autocompleteRef,
    manualHideOptions,
  } = props;
  const [options, setOptions] = React.useState([]);
  const [optionsVisible, setOptionsVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const optionsRef = React.useRef();
  const inputWrapperRef = React.useRef();

  const autocompleteValidation = (cb) => (query) => {
    if (!query || query.trim().length > 2) {
      cb(query);
    }
  };

  const autocompleteHandler = React.useCallback(async (query) => {
    setLoading(true);

    const res = await endpoint(query);
    if (res && Array.isArray(res) && res.length) {
      setOptionsVisible(true);
      setOptions(res);
    } else if (!manualHideOptions) {
      setOptionsVisible(false);
    }

    setOptions(res || []);
    setLoading(false);
  });

  const onFocus = (e) => {
    if (!e.target.value.length) {
      autocompleteHandler("");
    }

    if (options.length) {
      setOptionsVisible(true);
    }
  };

  const onSelectHandler = (option) => () => {
    setOptionsVisible(false);
    onSelect(option);
  };

  const debouncedAutocomplete = React.useRef(
    useDebounce(autocompleteValidation(autocompleteHandler), 300),
  );

  const onChange = (e) => {
    const query = e.target.value;

    setValue(query);

    debouncedAutocomplete.current(query || "");
  };

  const validateAdditionalRender = (opt) => {
    const renderedValue = config.additionalRender(opt);

    if (renderedValue) {
      return <span className="secondary">{renderedValue}</span>;
    }
  };

  React.useEffect(() => {
    const hideOptions = (e) => {
      if (optionsRef.current && inputWrapperRef.current) {
        if (!optionsRef.current.contains(e.target) && !inputWrapperRef.current.contains(e.target)) {
          setOptionsVisible(false);
        }
      }
    };

    window.addEventListener("click", hideOptions);
    return () => window.removeEventListener("click", hideOptions);
  });

  React.useImperativeHandle(autocompleteRef, () => ({
    setOptionsVisible,
    optionsVisible,
  }));

  return (
    <div className={condStrings("autcomplete-wrapper", className)}>
      {optionsVisible && <div className="autocomplete-backdrop" />}
      {label && label}
      <div className="autocomplete-input" ref={inputWrapperRef}>
        <input
          type="text"
          placeholder={placeholder}
          name={name}
          id={name}
          value={value}
          autoComplete="off"
          onChange={onChange}
          className={condStrings("uk-input", inputClassName)}
          onFocus={onFocus}
        />

        {loading && <div className="autocomplete-input-loader" uk-spinner="ratio: 0.7" />}

        {optionsVisible && (
          <ul
            className={condStrings(
              "autocomplete-options disabled-list",
              optionsClassName,
              optionsPosition,
            )}
            ref={optionsRef}
          >
            {(options &&
              options.length &&
              options.map((opt) => (
                <li key={opt.id} className="autocomplete-suggestion">
                  <button
                    type="button"
                    className="autocomplete-suggestion-btn"
                    onClick={onSelectHandler(opt)}
                  >
                    <span className="autocomplete-suggestion-text">{opt[config.propName]}</span>
                    {config.additionalRender && validateAdditionalRender(opt)}
                  </button>
                </li>
              ))) ||
              ""}
            {config.additionalLastItem}
          </ul>
        )}
      </div>
    </div>
  );
}

Autocomplete.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endpoint: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  optionsPosition: PropTypes.oneOf(["top", "bottom"]),
  value: PropTypes.string,
  setValue: PropTypes.func,
  config: PropTypes.shape({
    propName: PropTypes.string.isRequired,
    additionalRender: PropTypes.func,
    additionalLastItem: PropTypes.element,
  }),
  autocompleteRef: PropTypes.object,
  manualHideOptions: PropTypes.bool,
};

Autocomplete.defaultProps = {
  optionsPosition: "bottom",
  config: { additionalLastItem: null },
  manualHideOptions: false,
};
