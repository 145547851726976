import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../../services/static.service";
import "./BookingPaymentDetails.css";
import BookingPaymentModals from "./components/BookingPaymentModals";
import Button from "../../../../../common/Button";
import auth from "../../../../../../services/auth";

const BookingPaymentDetails = (props) => {
  const { paymentStatus, bookingStatus } = props;
  const [selectedStatus, setSelectedStatus] = useState("unpaid");
  const confirmFullPayment = useRef(null);

  const providePaymentInfoRef = useRef(null);

  const handleChangeSelect = (e) => {
    const { value } = e.target;

    if (value === "paid") {
      confirmFullPayment.current.open();
    }
    setSelectedStatus(value);
  };

  const handleOpenModal = (modalRef) => {
    modalRef.current.open();
  };

  // const handleCloseModal = (modalRef) => {
  //   modalRef.current.close();
  // };

  const operatorPaymentDetailsView = (status) => {
    switch (status) {
      case "provisional":
        return (
          <Button
            onClick={() => handleOpenModal(providePaymentInfoRef)}
            className="gh-booking-payment-details-confirm-payment-btn"
            color="warning"
          >
            {staticService.findByAlias("providePaymentInformationLabel")}
          </Button>
        );
      case "provisionalReadOnly":
        return (
          <div className="gh-booking-payment-details-status">
            <span style={{ paddingRight: 10 }}>{staticService.findByAlias("providedLabel")}</span>
            <Button
              onClick={() => {
                handleOpenModal(providePaymentInfoRef);
              }}
              variant="text"
            >
              ({staticService.findByAlias("view")})
            </Button>
          </div>
        );
      case "confirmedReadOnly":
        return (
          <div className="gh-booking-payment-details-status">
            <span style={{ paddingRight: 10 }}>{staticService.findByAlias("providedLabel")}</span>
            <Button
              onClick={() => {
                handleOpenModal(providePaymentInfoRef);
              }}
              variant="text"
            >
              ({staticService.findByAlias("view")})
            </Button>
          </div>
        );
      default:
        return "";
    }
  };

  const brokerPaymentDetailsView = (status) => {
    switch (status) {
      case "provisional":
        return (
          <span className="gh-text-warning">
            {staticService.findByAlias("operatorToProvidePaymentInformation")}
          </span>
        );
      case "provisionalReadOnly":
        return (
          <div className="gh-booking-payment-details-status">
            <span style={{ paddingRight: 10 }}>{staticService.findByAlias("providedLabel")}</span>
            <Button
              onClick={() => {
                handleOpenModal(providePaymentInfoRef);
              }}
              variant="text"
            >
              ({staticService.findByAlias("view")})
            </Button>
          </div>
        );
      case "confirmedReadOnly":
        return (
          <div className="gh-booking-payment-details-status">
            <span style={{ paddingRight: 10 }}>{staticService.findByAlias("providedLabel")}</span>
            <Button
              onClick={() => {
                handleOpenModal(providePaymentInfoRef);
              }}
              variant="text"
            >
              ({staticService.findByAlias("view")})
            </Button>
          </div>
        );
      default:
        return "";
    }
  };

  const brokerPaymentDetailsStatusView = (status) => {
    switch (status) {
      case 1:
        return (
          <span>{staticService.findByAlias("unpaidOperatorProvidePaymentInformationStatus")}</span>
        );
      case 2:
        return (
          <select
            className={`uk-select gh-white-bg gh-custom-select ${
              selectedStatus === "paid" ? "paid" : "unpaid"
            }`}
            value={selectedStatus}
            onChange={handleChangeSelect}
          >
            <option value="unpaid" className="gh-unpaid">
              Unpaid
            </option>
            <option value="paid" className="gh-paid">
              Paid
            </option>
          </select>
        );
      case 3:
        return <span>{staticService.findByAlias("paidToBeConfirmedByOperator")}</span>;
      case 4:
        return <span>{staticService.findByAlias("paidReceivedByOperator")}</span>;
      default:
        return "";
    }
  };

  const operatorPaymentDetailsStatusView = (status) => {
    switch (status) {
      case 1:
        return (
          <span>{staticService.findByAlias("unpaidPleaseProvidePaymentInformationStatus")}</span>
        );
      case 2:
        return (
          <div className="uk-flex" style={{ alignItems: "center" }}>
            <span className="gh-paid-label">Unpaid</span>
            <Button
              onClick={() => handleOpenModal(confirmFullPayment)}
              className="gh-booking-payment-details-confirm-payment-btn"
              color="warning"
            >
              {staticService.findByAlias("confirmPaymentReceivedLabel")}
            </Button>
          </div>
        );
      case 3:
        return (
          <div className="uk-flex" style={{ alignItems: "center" }}>
            <span className="gh-paid-label gh-paid-label-green">Paid</span>
            <Button
              onClick={() => handleOpenModal(confirmFullPayment)}
              className="gh-booking-payment-details-confirm-payment-btn"
              color="warning"
            >
              {staticService.findByAlias("confirmPaymentReceivedLabel")}
            </Button>
          </div>
        );
      case 4:
        return <span>{staticService.findByAlias("paidAndReceived")}</span>;
      default:
        return "";
    }
  };

  return (
    <div className="gh-booking-payment-details">
      <div className="gh-booking-payment-details-row">
        <span className="gh-booking-payment-details-label">
          {staticService.findByAlias("paymentMethodLabel")}
        </span>
        <span className="gh-booking-payment-details-value">
          {staticService.findByAlias("manualBankTransferLabel")}
        </span>
      </div>

      {bookingStatus !== "cancelled" ? (
        <div className="gh-booking-payment-details-row">
          <span className="gh-booking-payment-details-label">
            {staticService.findByAlias("paymentInformationLabel")}
          </span>
          <span className="gh-booking-payment-details-value">
            {auth.isBroker()
              ? brokerPaymentDetailsView(
                  paymentStatus !== 1 ? `${bookingStatus}ReadOnly` : bookingStatus,
                )
              : operatorPaymentDetailsView(
                  paymentStatus !== 1 ? `${bookingStatus}ReadOnly` : bookingStatus,
                )}
          </span>
        </div>
      ) : (
        ""
      )}

      <div className="gh-booking-payment-details-row" style={{ marginBottom: "0" }}>
        <span className="gh-booking-payment-details-label">
          {staticService.findByAlias("paymentStatusLabel")}
        </span>
        <span className="gh-booking-payment-details-value">
          {auth.isBroker()
            ? brokerPaymentDetailsStatusView(paymentStatus)
            : operatorPaymentDetailsStatusView(paymentStatus)}
        </span>
      </div>

      <BookingPaymentModals
        providePaymentInfoRef={providePaymentInfoRef}
        setSelectedStatus={setSelectedStatus}
        confirmFullPayment={confirmFullPayment}
        {...props}
      />
    </div>
  );
};
BookingPaymentDetails.propTypes = {
  paymentStatus: PropTypes.number.isRequired,
  brokerCompany: PropTypes.object,
  bookingID: PropTypes.number.isRequired,
  bookingStatus: PropTypes.string,
  paymentInfo: PropTypes.object,
  history: PropTypes.object,
  isBrokerAllowedToInteract: PropTypes.bool,
};
BookingPaymentDetails.defaultProps = {
  bookingStatus: null,
};
export default memo(BookingPaymentDetails);
