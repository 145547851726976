import PropTypes from "prop-types";
import React from "react";
import { InputNumber } from "./InputNumber";
import usePrevious from "../../../../hooks/usePrevious";
import { n, precision } from "../../services/calculations/formulas";
import cs from "../../../../utils/condString";

const TaxInput = React.forwardRef((props, ref) => {
  const {
    value,
    onBlur,
    onChange,
    readOnly,
    total,
    onValueChange,
    onFocus,
    confirmOnBlur,
    hideActions,
    currency,
    isSticky,
  } = props;

  const [focused, setFocused] = React.useState(false);
  const confirmRef = React.useRef();
  const cancelRef = React.useRef();

  const previousTotal = usePrevious(total);

  const [percent, setPercent] = React.useState("");
  const [sum, setSum] = React.useState("");

  React.useEffect(() => {
    let percent = typeof value.percent === "number" ? value.percent.toString() : value.percent;

    onChangeHandler("percent")(percent);
  }, [value.percent]);

  React.useEffect(() => {
    const newPercent = props.percent || percent;
    if (isSticky !== undefined && newPercent && typeof newPercent === "string") {
      onChangeHandler("percent")(newPercent);
    }
  }, [isSticky]);

  React.useEffect(() => {
    if (value) {
      props.setPercent ? props.setPercent(value.percent) : setPercent(value.percent);
      props.setSum ? props.setSum(value.sum) : setSum(value.sum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPercent, setSum]);

  React.useEffect(() => {
    if (total !== previousTotal) {
      if (!total || total === "0") {
        // props.setPercent ? props.setPercent("") : setPercent("");
        // props.setSum ? props.setSum("") : setSum("");
        // return onChange({ percent: "", sum: "" });
      }

      if (value.percent) {
        const newValue = {};

        newValue.percent = value.percent;
        newValue.sum = precision((n(total) / 100) * value.percent);

        props.setPercent ? props.setPercent(newValue.percent) : setPercent(newValue.percent);
        props.setSum ? props.setSum(newValue.sum) : setSum(newValue.sum);

        onChange(newValue);
      }
    }
  }, [total]);

  const onChangeHandler = React.useCallback(
    (type) => (event) => {
      const value = event?.replace(",", "");

      const newValue = {};

      if (type === "percent") {
        newValue.percent = value || props.value.percent;
        newValue.sum = precision((n(total) / 100) * value);
      } else if (type === "sum") {
        newValue.sum = value || props.value.sum;
        newValue.percent = precision((value / n(total)) * 100);
      }

      props.setPercent ? props.setPercent(newValue.percent) : setPercent(newValue.percent);
      props.setSum ? props.setSum(newValue.sum) : setSum(newValue.sum);
    },
    [total],
  );

  const confirmHandler = () => {
    const newPercent = props.percent ? props.percent : percent;
    const newSum = props.sum ? props.sum : sum;

    onChange({ percent: newPercent, sum: newSum });
    if (onValueChange) onValueChange();
  };

  const cancelHandler = () => {
    onChange({ sum: "", percent: "" });
    if (onValueChange) onValueChange();
  };

  const focusHandler = (e) => {
    if (onFocus) onFocus(e);
    setFocused(true);
  };

  const blurHandler = (e) => {
    if (onBlur) onBlur(e);

    if (e.relatedTarget === confirmRef.current || confirmOnBlur) {
      confirmHandler();
    } else if (e.relatedTarget === cancelRef.current) {
      cancelHandler();
    } else {
      props.setPercent ? setPercent(value.percent) : setPercent(value.percent);
      props.setSum ? props.setSum(value.sum) : setPercent(value.percent);
    }

    setFocused(false);
  };

  return (
    <div className="tax-input" ref={ref}>
      <div className="tax-input__part">
        <InputNumber
          placeholder="[0.00]"
          value={props.percent ? props.percent : percent}
          onChange={onChangeHandler("percent")}
          readOnly={readOnly || !total}
          onBlur={blurHandler}
          onFocus={focusHandler}
          max={100}
          suffix={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
            </svg>
          }
        />
      </div>
      <div className="tax-input__part">
        <InputNumber
          placeholder="[0.00]"
          value={props.sum ? props.sum : sum}
          onChange={onChangeHandler("sum")}
          readOnly={readOnly || !total}
          onBlur={blurHandler}
          onFocus={focusHandler}
          suffix={currency}
        />
      </div>
      {!hideActions && (
        <div className={cs("calculator__input-actions", focused && !readOnly && "visible")}>
          <button
            type="button"
            tabIndex="-1"
            className="calculator-reset-btn calculator__input-btn"
            ref={confirmRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
          </button>
          <button
            type="button"
            className="calculator-reset-btn calculator__input-btn"
            tabIndex="-1"
            ref={cancelRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
});

TaxInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func,
  onFocus: PropTypes.func,
  confirmOnBlur: PropTypes.bool,
  hideActions: PropTypes.bool,
  currency: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setPercent: PropTypes.func,
  sum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSum: PropTypes.func,
  isSticky: PropTypes.bool,
  // defaultTax: PropTypes.object,
};

TaxInput.defaultProps = {
  value: { sum: "", percent: "" },
  confirmOnBlur: false,
};

TaxInput.displayName = "TaxInput";

export { TaxInput };
