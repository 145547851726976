import * as types from "../../actions/actionTypes";

const appScreenDimensions = {
  screenW: window.outerWidth,
  screenH: window.outerHeight,
  screenInnerW: window.innerWidth,
  screenInnerH: window.innerHeight * 0.01,
};

function metaData(state = {}, action) {
  if (action.type === types.GET_META) {
    return action;
  } else {
    return state;
  }
}

function globalObj(state = { data: {} }, action) {
  switch (action.type) {
    case types.LOAD_SUCCESS:
      return action.data;
    case types.ACTIVE_ROUTE:
      return { activeRoute: action.route };
    case types.CLOSE_MODAL:
      return { modalClose: action.modal };
    default:
      return state;
  }
}

function termsCondition(state = [], action) {
  switch (action.type) {
    case types.LOAD_CONDITION_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function home(state = [], action) {
  switch (action.type) {
    case types.LOAD_HOME_DATA:
      return action.data;
    default:
      return state;
  }
}

function countries(state = [], action) {
  switch (action.type) {
    case types.GET_REGISTER_COUNTRIES:
      return action.data;
    default:
      return state;
  }
}

function currencies(state = [], action) {
  switch (action.type) {
    case types.GET_CURRENCIES:
      return action.data;
    default:
      return state;
  }
}

const initialState = {
  data: [],
  meta: {},
};

function events(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_EVENT_DATA:
      return action.data;
    case types.RESET_EVENT_DATA:
      return initialState;
    case types.SHOW_MORE_EVENTS:
      return {
        data: [...state.data, ...action.data.data],
        meta: { ...state.data.meta, ...action.data.meta },
        types: action.data.types,
        countries: action.data.countries,
      };
    default:
      return state;
  }
}

function destinations(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_DESTINATION_DATA:
      return action.data;
    case types.RESET_EVENT_DATA:
      return initialState;
    case types.SHOW_MORE_DESTINATIONS:
      return {
        data: [...state.data, ...action.data.data],
        meta: { ...state.data.meta, ...action.data.meta },
        locationsFilters: action.data.locations,
        types: action.data.types,
        countries: action.data.countries,
      };
    default:
      return state;
  }
}

function eventsInformation(state = [], action) {
  switch (action.type) {
    case types.LOAD_EVENT_INFORMATION_DATA:
      return action.data;
    default:
      return state;
  }
}

function destinationInformation(state = [], action) {
  switch (action.type) {
    case types.LOAD_DESTINATION_INFORMATION_DATA:
      return action.data;
    default:
      return state;
  }
}

function heliOptions(state = [], action) {
  switch (action.type) {
    case types.GET_HELI_OPTIONS:
      return action.data;
    default:
      return state;
  }
}

function storeRequest(state = [], action) {
  switch (action.type) {
    case types.STORE_REQUEST:
      return action.data;
    default:
      return state;
  }
}

function validateInvitations(state = { invitations_validation: 0 }, action) {
  switch (action.type) {
    case types.ACCOUNT_INVITATIONS_VALIDATION:
      return action.data;
    case types.RESET_MODEL:
      return { invitations_validation: 0 };
    default:
      return state;
  }
}

function validateDocuments(state = { file_validation: 0 }, action) {
  switch (action.type) {
    case types.ACCOUNT_DOCUMENTS_VALIDATION:
      return action.data;
    case types.RESET_MODEL:
      return { file_validation: 0 };
    default:
      return state;
  }
}

function uploadDocuments(state = { event: false }, action) {
  switch (action.type) {
    case types.UPLOAD_DOCUMENTS_SUCCESS:
      return { event: true, data: action.data };
    default:
      return state;
  }
}

function validateAircrafts(state = { aircraft_validation: 0 }, action) {
  switch (action.type) {
    case types.ACCOUNT_FLEET_VALIDATION:
      return action.data;
    case types.RESET_MODEL:
      return { aircraft_validation: 0 };
    default:
      return state;
  }
}

function broadcast(state = {}, action) {
  switch (action.type) {
    case types.BROADCAST_EVENT:
      return action.response;
    case types.CLEAR_BROADCAST_EVENT:
      return {};
    default:
      return state;
  }
}

const _dashboard = {
  bookings: { current_week: 0, confirmed: 0, canceled: 0 },
  last: { total_quotes: null, bookings: 0 },
  current_week: { quotes: 0, booking: 0 },
  first: { pending: 0, cancelled: 0, requests_pending: 0 },
  total: 0,
};

function aircrafts(state = [], action) {
  switch (action.type) {
    case types.GET_DASHBOARD_AIRCRAFTS:
      return action.data;
    default:
      return state;
  }
}

function dashboard(state = _dashboard, action) {
  switch (action.type) {
    case types.GET_DASHBOARD_STATISTIC:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function screenDimensions(state = appScreenDimensions, action) {
  switch (action.type) {
    case types.WINDOW_RESIZE:
      return { ...action.data };
    default:
      return state;
  }
}

function contactForm(state = {}, action) {
  switch (action.type) {
    case types.SUBMIT_CONTACT_FORM:
      return { ...action.data };
    default:
      return state;
  }
}

function post(state = null, action) {
  switch (action.type) {
    case types.PRE_REGISTER_SUCCESS:
      return action;
    case types.RESET_PRE_REGISTER:
      return null;
    default:
      return state;
  }
}

function pageTitle(state = null, action) {
  switch (action.type) {
    case types.SET_PAGE_TITLE:
      return action.title;
    default:
      return state;
  }
}

function prevPath(state = null, action) {
  switch (action.type) {
    case types.PREVIOUS_PATH:
      return action.path;
    default:
      return state;
  }
}

function filters(state = [], action) {
  switch (action.type) {
    case types.SET_FILTERS:
      return action.filters;
    default:
      return state;
  }
}

function gridSort(state = {}, action) {
  switch (action.type) {
    case types.SORT_GRID:
      return action.sort;
    default:
      return state;
  }
}

function activeStep(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_STEPS_ENQUIRE_FORMS:
      return action.payload;
    default:
      return state;
  }
}

function menuItems(state = {}, action) {
  switch (action.type) {
    case types.MENU_ITEMS:
      return action.data;
    default:
      return state;
  }
}

export {
  globalObj,
  termsCondition,
  validateInvitations,
  validateDocuments,
  validateAircrafts,
  uploadDocuments,
  broadcast,
  events,
  eventsInformation,
  dashboard,
  post,
  heliOptions,
  storeRequest,
  screenDimensions,
  contactForm,
  pageTitle,
  home,
  countries,
  prevPath,
  filters,
  gridSort,
  metaData,
  activeStep,
  currencies,
  aircrafts,
  destinations,
  destinationInformation,
  menuItems,
};
