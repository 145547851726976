import PropTypes from "prop-types";
import React from "react";
import { useRadioGroupContext } from "./RadioGroupContext";

const Radio = React.forwardRef((props, ref) => {
  const { value, label } = props;
  const { contextName, contextValue, onChange } = useRadioGroupContext();

  return (
    <label htmlFor={`${contextName}-${value}`} className="calculator__radio">
      <input
        id={`${contextName}-${value}`}
        name={`${contextName}-${value}`}
        value={value}
        checked={value === contextValue}
        ref={ref}
        className="calculator__radio-input"
        onChange={onChange}
        type="radio"
      />
      {label}
    </label>
  );
});

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

Radio.defaultProps = {
  value: "",
};

Radio.displayName = "Radio";

export default Radio;
