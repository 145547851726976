import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { config } from "../../../configs";
import { updateQuoteDetail, updateQuoteLegLocation } from "../../../actions";
import AccordionComponent from "../AccordionComponent";
import QuoteLegLocation from "../QuoteLegLocation";
import QuoteEstimate from "../QuoteEstimate";
import QuoteDepartureInfo from "../QuoteDepartureInfo";
import QuotePassengersInfo from "../QuotePassengersInfo";
import QuoteAircrafts from "../QuoteAircrafts";
import staticService from "../../../services/static.service";
import { distanceBetween, parseCoordinates } from "../../../shared";

import "./quoteLegs.css";
import PadSelectionMap from "../PadSelectionMap/PadSelectionMap";
import generateLocationAdditionalInfo, {
  generateSecondaryText,
} from "../../../utils/generateLocationAdditionalInfo";
import Api from "../../../services/api-handler";
import CheckboxFuncComponent from "../CheckboxFuncComponent";

const QuoteLegs = (props) => {
  const {
    originalQuote,
    editingQuote,
    editingQuote: { legs, flight_type },
    updateQuoteDetail,
    updateQuoteLegLocation,
    screenDimensions,
    isEmptyLeg,
    setValue,
    resetQuoteVal,
    unregister,
    setPassengerAmountError,
    control,
  } = props;

  const updateDetailQuote = (key, id, editKey, locationData = {}, index = null) => {
    const isLocData = Object.keys(locationData).length;
    const newQuoteData = {
      ...editingQuote,
      legs: editingQuote.legs.map((item, currentIndex) => {
        if (item.id === id) {
          const originalCurrentLeg = originalQuote.legs[currentIndex];
          const originalData = resetQuoteVal("leg", "location", { id, dir: key, leg: item });
          const locData = isLocData ? locationData : originalData.location;
          const distance = parseInt(
            distanceBetween(
              parseCoordinates(
                key === "from" ? `${locData.latitude}|${locData.longitude}` : item.from_coords,
              ),
              parseCoordinates(
                key === "to" ? `${locData.latitude}|${locData.longitude}` : item.to_coords,
              ),
            ).toFixed(0),
          );

          return {
            ...item,
            estimated_distance: distance,
            estimated_duration: null,
            [key]: isLocData ? locationData.location : originalCurrentLeg[key],
            // [key + "_info"]: "",
            [key + "_is_private"]: isLocData ? "" : originalCurrentLeg[key + "_is_private"],
            [key + "_coords"]: `${locData.latitude}|${locData.longitude}`,
            [key + "_location"]: isLocData
              ? { ...locationData, name: locationData.location }
              : originalCurrentLeg[`${key}_location`],
            [key + "_source"]: locData.source,
            [editKey]: !!isLocData,
          };
        } else {
          return item;
        }
      }),
    };

    updateQuoteDetail(newQuoteData, true);

    if (!isLocData) {
      unregister(`${key}${index}`);
    }
  };

  const clearLocation = (key, id, editingKey) => {
    const newQuoteData = {
      ...editingQuote,
      legs: editingQuote.legs.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [key]: "",
            [key + "_coords"]: "",
            [key + "_details"]: "",
            [key + "_info"]: "",
            [key + "_is_private"]: 0,
            [key + "_location"]: {},
            [key + "_source"]: "",
            [editingKey]: true,
          };
        } else {
          return item;
        }
      }),
    };
    updateQuoteDetail(newQuoteData);
  };

  const updateLeg = (fieldKey, valueObj) => {
    const newQuoteData = {
      ...editingQuote,
      legs: editingQuote.legs.map((item) => {
        if (valueObj[item.id] || valueObj[item.id] === false) {
          return { ...item, [fieldKey]: valueObj[item.id] };
        } else {
          return item;
        }
      }),
    };
    updateQuoteDetail(newQuoteData);
  };

  const updatePassengersInfo = (fieldKey, value, legIndex, otherComment = false) => {
    if (
      fieldKey === "passengers" ||
      (fieldKey === "special_items" && !otherComment) ||
      fieldKey === "luggage"
    ) {
      const passengersLimit = Object.values(value)
        .map((passengers) => passengers <= 15 || typeof passengers === "string")
        .includes(false);
      if (passengersLimit) return;
    }
    let skip = false;

    const updatedLegs = legs.map((item, index) => {
      if (index < legIndex) {
        return item;
      } else if (legIndex === index) {
        return { ...item, [fieldKey]: value, same_as_previous: false };
      } else if (legIndex < index && item.same_as_previous) {
        if (skip) {
          return item;
        } else {
          return { ...item, [fieldKey]: value, same_as_previous: true };
        }
      } else {
        skip = true;
        return item;
      }
    });

    updateQuoteDetail({ ...editingQuote, legs: updatedLegs });
    if (fieldKey === "passengers") {
      const sum = Object.entries(value).reduce(
        (acc, [key, val]) => (key === "passengers_amount_type" ? acc : acc + val),
        0,
      );
      if (sum > 0) setPassengerAmountError(null);
      else setPassengerAmountError(legIndex);
    }
  };

  const updateTheSamePassengers = (value, legIndex) => {
    let prevPassagers = {};
    let skip = false;

    const updatedLegs = legs.map((item, index) => {
      if (index < legIndex) {
        return item;
      } else if (legIndex === index) {
        prevPassagers = {
          maximum_weight_allowed: value
            ? editingQuote.legs[index - 1].maximum_weight_allowed
            : originalQuote.legs[index].maximum_weight_allowed || "0",
          passengers: value
            ? editingQuote.legs[index - 1].passengers
            : originalQuote.legs[index].passengers,
          luggage: value ? editingQuote.legs[index - 1].luggage : originalQuote.legs[index].luggage,
          special_items: value
            ? editingQuote.legs[index - 1].special_items
            : originalQuote.legs[index].special_items,
        };

        return { ...item, ...prevPassagers, same_as_previous: value };
      } else if (legIndex < index && item.same_as_previous) {
        if (skip) {
          return item;
        } else {
          return { ...item, ...prevPassagers, same_as_previous: true };
        }
      } else {
        skip = true;
        return item;
      }
    });

    updateQuoteDetail({ ...editingQuote, legs: updatedLegs });
  };

  const handleAssignPad = async (site) => {
    const source = site.source || config.locationSource[1];
    const body = {
      location: [site.location.latitude, site.location.longitude],
      place_id: site.place_id,
      source,
      secondary_text: generateSecondaryText(site.location),
    };

    let response = {};
    if (!site.hasOwnProperty("coverage_status")) {
      const res = await Api.setProperty("skipErrorAlert", true)
        .setProperty("alertModal", false)
        .setPath("check-location-coverage", "")
        .post(body);
      response = res;
    } else response.message = site.coverage_status;

    const updateFormClbks = [];
    const side = site.legSide;
    const coords = `${site.location.latitude}|${site.location.longitude}`;
    const coverage_status = response.message;
    const generatedData = {
      [`${side}`]: site.name,
      [`${side}_source`]: source,
      [`${side}_place_id`]: site.place_id,
      [`${side}_type`]: site.type,
      [`${side}_coords`]: coords,
      [`${side}_coverage_status`]: coverage_status,
      [`${side}_info`]: generateLocationAdditionalInfo({
        ...site.location,
        type: site.type,
        source,
      }),
    };

    const estimatedDistance = (side, secondSideCoords) => {
      if (side === "from") {
        return distanceBetween(
          parseCoordinates(coords),
          parseCoordinates(secondSideCoords),
        ).toFixed(0);
      } else {
        return distanceBetween(
          parseCoordinates(secondSideCoords),
          parseCoordinates(coords),
        ).toFixed(0);
      }
    };

    const updatedLeg = {};

    editingQuote.legs.forEach((item, idx) => {
      const secondSideCoords = side === "from" ? item.to_coords : item.from_coords;

      if (item.id === site.legId) {
        updateFormClbks.push(() => setValue(`${side}${idx}`, site.name));
        updatedLeg.estimated_distance = estimatedDistance(coords, secondSideCoords);
        updatedLeg[`${side}_location`] = {
          ...item[`${side}_location`],
          ...site.location,
          type: site.type,
          source,
          place_id: site.place_id,
          name: site.name,
          secondary_text: generateSecondaryText({
            ...site.location,
            type: site.type,
            source,
          }),
        };
        updatedLeg[`is_${side}_edited`] = true;
      }
    });

    updateFormClbks.forEach((f) => f());
    updateQuoteLegLocation({ ...updatedLeg, ...generatedData, id: site.legId });
  };

  useEffect(() => {
    const newQuoteData = {
      ...editingQuote,
      legs: editingQuote.legs.map((item) => {
        return { ...item, local_points_of_interest: false };
      }),
    };
    updateQuoteDetail(newQuoteData);
  }, []);

  return (
    <AccordionComponent items={legs} isOpen={true} flightType={editingQuote.flight_type}>
      {legs &&
        legs.map((leg, index) => {
          const flyDirCoords = {
            from: leg.from_coords,
            to: leg.to_coords,
            source: leg.to_source,
            key: leg.id,
            pad: leg,
          };

          return (
            <div key={index}>
              {/*AUTOCOMPLETE LOCATION*/}
              <QuoteLegLocation
                leg={leg}
                legIndex={index}
                updateDetailQuote={updateDetailQuote}
                clearLocation={clearLocation}
                {...props}
              />

              {/*ESTIMATE ROW*/}
              <div className="gh-separator-line" />
              <QuoteEstimate leg={leg} updateLeg={updateLeg} control={control} />

              <div className="gh-separator-line" />
              <div className="gh-quote-map-header">
                <h4 className="title-item">map</h4>
                <CheckboxFuncComponent
                  label={staticService.findByAlias("localPointsOfInterest")}
                  checked={leg.local_points_of_interest}
                  onClick={() => {
                    updateLeg("local_points_of_interest", {
                      [leg.id]: !leg.local_points_of_interest,
                    });
                  }}
                />
              </div>

              {/*MAP*/}

              <PadSelectionMap
                classNames="uk-margin gh-pad-selection"
                flyDirCoords={[flyDirCoords]}
                legs={[leg]}
                screenDimensions={screenDimensions}
                handleAssignPad={handleAssignPad}
                legIndex={index}
                legId={leg.id}
                flightType={flight_type}
                showAvailablePads={leg.local_points_of_interest}
              />

              <div
                className="uk-flex uk-flex-middle"
                style={{
                  background: "#F2F4F7",
                  padding: 15,
                  border: "1px solid #E3E7ED",
                  color: "#242F42",
                }}
              >
                <span
                  className="gh-vertical-middle uk-margin-small-right uk-position-relative"
                  style={window.innerWidth < 576 ? { width: 80 } : {}}
                >
                  <svg
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <ellipse cx="15.0268" cy="15" rx="15.0134" ry="15" fill="#FFFCE8" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3569 7.71567C16.9966 8.01967 16.5122 8.17167 15.9036 8.17167C15.3431 8.17167 14.8787 8.01967 14.5104 7.71567C14.1421 7.41167 13.9579 7.00367 13.9579 6.49167C13.9579 5.94767 14.1421 5.52367 14.5104 5.21967C14.8787 4.91567 15.3431 4.76367 15.9036 4.76367C16.5122 4.76367 16.9966 4.91567 17.3569 5.21967C17.7173 5.52367 17.8974 5.94767 17.8974 6.49167C17.8974 7.00367 17.7173 7.41167 17.3569 7.71567ZM14.054 22.6317C13.7337 22.6877 13.4294 22.7157 13.1412 22.7157C12.5326 22.7157 12.1123 22.5997 11.88 22.3677C11.6478 22.1357 11.5317 21.8837 11.5317 21.6117C11.5317 21.2917 11.5558 20.9797 11.6038 20.6757C11.6518 20.3717 11.7159 20.0357 11.796 19.6677L13.4294 12.2277L11.9401 11.8917V11.0757C12.1803 10.9797 12.5006 10.8797 12.901 10.7757C13.3013 10.6717 13.7257 10.5797 14.1741 10.4997C14.6225 10.4197 15.0669 10.3517 15.5073 10.2957C15.9477 10.2397 16.336 10.2117 16.6723 10.2117L17.1047 10.4997L14.9428 20.8437H16.6243V21.6597C16.4161 21.8037 16.1719 21.9397 15.8916 22.0677C15.6114 22.1957 15.3151 22.3077 15.0028 22.4037C14.6906 22.4997 14.3743 22.5757 14.054 22.6317Z"
                      fill="#242F42"
                    />
                  </svg>
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("pinsOnMapAreOnlySuggestions"),
                  }}
                />
              </div>

              {/*DEPARTURE INFO*/}
              <div className="gh-separator-line" />
              <QuoteDepartureInfo
                editingQuote={editingQuote}
                leg={leg}
                originalLeg={originalQuote.legs[index]}
                originalQuote={originalQuote}
                updateLeg={updateLeg}
                legIndex={index}
                readOnly={isEmptyLeg || editingQuote.empty_leg_id}
                updateQuoteDetail={updateQuoteDetail}
                {...props}
              />

              {/*PASSENGERS INFO*/}
              <div className="gh-separator-line" />
              <QuotePassengersInfo
                leg={leg}
                originalLeg={originalQuote.legs[index]}
                updatePassengersInfo={updatePassengersInfo}
                updateTheSamePassengers={updateTheSamePassengers}
                editingPassengersDetail={false}
                legIndex={index}
                readOnly={isEmptyLeg || editingQuote.empty_leg_id}
                isEmptyLeg={isEmptyLeg}
                updateLeg={updateLeg}
                setPassengerAmountError={setPassengerAmountError}
                {...props}
              />

              {/*AIRCRAFT */}
              <div className="gh-separator-line" />
              <QuoteAircrafts
                {...props}
                leg={leg}
                legIndex={index}
                readOnly={isEmptyLeg || editingQuote.empty_leg_id}
                updateQuoteDetail={updateQuoteDetail}
                aircraftKey="selected_aircrafts"
              />

              {/*ALTERNATIVE AIRCRAFT */}
              {(leg.selected_additional_aircrafts[0] &&
                Object.keys(leg.selected_additional_aircrafts[0]).length > 1 &&
                editingQuote.empty_leg_id) ||
              !editingQuote.empty_leg_id ? (
                <QuoteAircrafts
                  {...props}
                  leg={leg}
                  legIndex={index}
                  readOnly={isEmptyLeg || editingQuote.empty_leg_id}
                  updateQuoteDetail={updateQuoteDetail}
                  aircraftKey="selected_additional_aircrafts"
                />
              ) : (
                ""
              )}
            </div>
          );
        })}
    </AccordionComponent>
  );
};

const mapStateToProps = ({ setQuoteDetail, screenDimensions }) => ({
  editingQuote: setQuoteDetail.editingQuoteDetail,
  originalQuote: setQuoteDetail.originalQuoteDetail,
  screenDimensions: screenDimensions,
});

QuoteLegs.propTypes = {
  editingQuote: PropTypes.object,
  originalQuote: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
  screenDimensions: PropTypes.object,
  unregister: PropTypes.func,
  isEmptyLeg: PropTypes.bool,
  setValue: PropTypes.func,
  resetQuoteVal: PropTypes.func,
  setPassengerAmountError: PropTypes.func,
  updateQuoteLegLocation: PropTypes.func,
  control: PropTypes.object,
};

export default connect(mapStateToProps, { updateQuoteLegLocation })(QuoteLegs);
