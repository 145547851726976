import PropTypes from "prop-types";
import React from "react";
import RadioGroupContext from "./RadioGroupContext";
import Radio from "./Radio";
import cs from "../../../../utils/condString";

import "./index.css";

const RadioGroup = React.forwardRef((props, ref) => {
  const { className, name, value, onChange, onAfterChange, children, invalid } = props;

  const onChangeHander = (event) => {
    const value = event.target.value;
    onChange(value);

    if (onAfterChange) onAfterChange();
  };

  return (
    <RadioGroupContext.Provider
      value={{ contextName: name, contextValue: value, onChange: onChangeHander }}
    >
      <div className={cs("calculator__radio-group", invalid && "pc-invalid", className)} ref={ref}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
});

RadioGroup.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
  invalid: PropTypes.bool,
};

RadioGroup.defaultProps = {
  value: "",
};

RadioGroup.displayName = "RadioGroup";

export { RadioGroup, Radio };
