import React from "react";
import PropTypes from "prop-types";
import "./Radio.css";
import uid from "../../../utils/uid";

const Radio = (props) => {
  const { className, onChange, value, name, id, label, children, selected, color, disabled } =
    props;

  const radioId = id ? id : name + "_" + uid().uid;
  return (
    <div className={`gh-radio-button-wrapper ${className}`}>
      <input
        id={radioId}
        type="radio"
        name={name}
        onChange={onChange}
        value={value}
        checked={selected}
        disabled={disabled}
      />
      <label
        className={`gh-radio-button-label gh-radio-label ${color ? "gh-radio-" + color : ""} ${
          disabled ? "gh-radio-button-label-disabled" : ""
        }`}
        htmlFor={radioId}
      >
        {children ? children : label}
      </label>
    </div>
  );
};
Radio.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  selected: PropTypes.bool,
  color: PropTypes.oneOf(["purple"]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
};
Radio.defaultProps = {
  className: "",
  label: "",
  onChange: () => {},
  color: "",
  id: null,
  disabled: false,
};
export default Radio;
