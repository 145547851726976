import React from "react";
import PropTypes from "prop-types";
import { RadioComponent } from "../../../../../shared";
import staticService from "../../../../../services/static.service";

import "./styles/index.css";

const ManualBankTransfer = (props) => {
  const { updateModelFunc, selectedCard } = props;
  const isChecked = () => {
    if (selectedCard) {
      return selectedCard.card_id === "manual";
    }
  };
  return (
    <div style={{ marginTop: 40 }}>
      <h4>{staticService.findByAlias("paymentByBankTransfer")}</h4>
      <div
        className={`wallet-row-default wallet-row-default_manual ${
          isChecked() ? "selected-card" : ""
        }`}
      >
        <div className="uk-flex uk-flex-middle wallet-card">
          <div className="uk-flex uk-flex-middle">
            <RadioComponent
              label=""
              model="card"
              onClick={() => updateModelFunc({ card_id: "manual" })}
              value="manual"
              wrapperClassName="gh-radio-wrapper wallet-radio"
              checked={isChecked()}
            />
            <div className="uk-width-expand uk-flex-center">
              <div className="wallet-row-subtitle" style={{ fontSize: 14 }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("paymentByBankTransferText"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ManualBankTransfer.propTypes = {
  updateModelFunc: PropTypes.func.isRequired,
  selectedCard: PropTypes.object,
};

export { ManualBankTransfer };
