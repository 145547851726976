import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { utcTime } from "../../shared";
import { useAccountLocalization } from "../../context";

const MediaMessage = ({ openModal, file, data, seenBy }) => {
  const accountLocalization = useAccountLocalization();

  return (
    <div className="wrapper-media-message">
      <div
        onClick={() => {
          openModal(file);
        }}
        className="wrapper-bg"
        style={{ backgroundImage: `url(${file.file_url})` }}
      />

      <div className="gh-bottom-line-media">
        <span className="gh-message-header-posted">
          <span>{moment(data.posted).format("DD MMM, YYYY")}</span>
          <span style={{ padding: "0 3px" }}>|</span>
          <span>{moment(data.posted).format(accountLocalization.timeFormat) + utcTime()}</span>
        </span>

        {seenBy && data.is_system !== 1 ? (
          <div className="gh-seen-message">
            Seen by <span style={{ marginLeft: 3 }}>{seenBy}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

MediaMessage.propTypes = {
  openModal: PropTypes.func,
  file: PropTypes.object,
  data: PropTypes.object,
  seenBy: PropTypes.string,
};

export default MediaMessage;
