import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { returnRouteUrl, RouteWithSubRoutes } from "../../../../shared";
import QuoteTabs from "../../../common/QuoteTabs";
import staticService from "../../../../services/static.service";
import {
  clearQuoteDetail,
  getDeclineReasons,
  getPurposes,
  getQuoteById,
  removeToAmendQuote,
  updateBothQuoteObjects,
  updateEditedQuote,
} from "../../../../actions";
import BackLink from "../../../common/backlink";
import uid from "../../../../utils/uid";
import LoadingComponent from "../../../common/LoadingComponent";
import CustomLoader from "../../../common/CustomLoader/customLoader";

const OperatorQuoteTripComponent = (props) => {
  const {
    routes,
    match,
    history,
    quoteDetail,
    getQuoteById,
    getPurposes,
    editingQuote,
    getDeclineReasons,
    updateBothQuoteObjects,
    clearQuoteDetail,
    updateEditedQuote,
  } = props;

  const [flightPurposes, setFlightPurposes] = useState([]);
  const [URLParams, setURLParams] = useState({
    requestId: null,
    quoteId: null,
  });
  const [getDataTrigger, setGetDataTrigger] = useState(false);
  const location = window.location.search;
  const historyState = history.location.state;

  useEffect(() => {
    if (editingQuote && editingQuote.has_to_expire) {
      history.push("/dashboard");
    }
  }, [editingQuote]);

  const handleChangeTab = (currentTabName, pathname) => {
    setTimeout(() => {
      if (currentTabName !== pathname) {
        let prevTabName = history.location.pathname.split("/")[3];

        history.push({
          pathname: history.location.pathname.replace(prevTabName, currentTabName),
          search: history.location.search,
          state: history.location.state,
        });
      }
    }, 100);
  };

  const backHandler = () => {
    const {
      location: { pathname, search, state },
    } = history;

    if (state?.from === "pre_quotes") {
      return history.push(state.prevPath);
    }

    if (state.prevPath && state.prevPath === "/notifications") {
      return history.push(state.prevPath);
    }

    if (pathname.indexOf("/request/") !== -1) {
      let path = state && state.originPath ? state.originPath : "/marketplace";
      history.push({
        pathname: path,
        state: {
          anchorId: "ghr" + pathname.split("/").pop(),
          emptyLeg: !!editingQuote.empty_leg_id,
        },
      });
    }

    if (pathname.indexOf("/quote/") !== -1) {
      history.push({
        pathname: pathname.replace("quote", "request"),
        search: search || "",
        state: state || {},
      });
    }

    if (history.location.pathname.indexOf("/calculator/") !== -1) {
      history.push({
        pathname: pathname.replace("calculator", "quote"),
        search: search || "",
        state: state || {},
      });
    }
  };

  const setQuoteDetailNew = (data) => {
    const quoteObjects = {
      original: {
        ...data,
        ...data.booking_request,
        expiration_date: null,
        is_quote_started: data.is_quote_started,
        calculation_id: data?.quote?.calculation_id,
        legs: data.booking_request.legs.map((leg) => ({
          ...leg,
          selected_aircrafts: [uid()],
        })),
      },
      editing: {
        ...data,
        ...data.booking_request,
        booking_request_id: data.booking_request.id,
        is_quote_started: data.is_quote_started,
        helicopters: data.helicopters,
        expiration_date: null,
      },
    };

    if (data.quote?.pads?.length) {
      quoteObjects.editing.legs = data.quote.pads.map((leg) => ({
        ...leg,
        selected_aircrafts: leg.aircrafts.length ? leg.aircrafts : [uid()],
        selected_additional_aircrafts: leg.additional_aircrafts.length
          ? leg.additional_aircrafts
          : [uid()],
      }));
      quoteObjects.editing.quote_price = data.quote.quote_price;
    } else {
      quoteObjects.editing.legs = data.booking_request.legs.map((leg) => ({
        ...leg,
        selected_aircrafts: [uid()],
      }));
    }

    updateBothQuoteObjects(quoteObjects);
  };

  const setQuoteDetailAmend = (data) => {
    const updatedQuoteKeys = {
      ...data.quote,
      extra_helicopters: [...data.extra_helicopters],
      expiration_date: null,
      quote: data.quote,
      is_quote_started: data.is_quote_started,
      calculation_id: data?.quote?.calculation_id,
      more_detail_requests: data.booking_request.more_detail_requests,
      empty_leg_id: data.booking_request.empty_leg_id,
      crew_size: data.booking_request.crew_size,
      preferences: data.booking_request.preferences,
      legs: data.quote.pads.map((pad) => {
        const _pad = {
          ...pad,
          selected_aircrafts: pad.aircrafts.map((arc) => ({ ...arc, ...uid() })),
          selected_additional_aircrafts: pad.additional_aircrafts.length
            ? pad.additional_aircrafts.map((arc) => ({ ...arc, ...uid() }))
            : [uid()],
        };
        delete _pad.aircrafts;
        delete _pad.additional_aircrafts;

        return _pad;
      }),
    };

    delete updatedQuoteKeys.pads;

    updateBothQuoteObjects({
      original: data.booking_request,
      editing: { ...updatedQuoteKeys, helicopters: data.helicopters },
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location);
    const splittedPathname = history.location.pathname.split("/");

    setURLParams({
      quoteId: params.get("quote_id"),
      requestId: splittedPathname[splittedPathname.length - 1],
    });
    setGetDataTrigger(true);
  }, []);

  useEffect(() => {
    if (!flightPurposes.length) {
      getPurposes().then((res) => setFlightPurposes(res));
    }
  }, [flightPurposes]);

  useEffect(() => {
    if (historyState && historyState.editingQuote && (URLParams.quoteId || URLParams.requestId)) {
      getQuoteById(URLParams.requestId, URLParams.quoteId).then((res) => {
        const original = {
          helicopters: { ...res.helicopters },
          ...res.booking_request,
          ...res.is_crew_size_edited,
          ...res.is_selected_helicopter_edited,
          ...res.quote,
        };

        updateEditedQuote({
          originalQuote: original,
          editingQuote: history.location.state.editingQuote,
        });
      });
    }
  }, [getDataTrigger]);

  useEffect(() => {
    if (!editingQuote || !Object.keys(editingQuote).length) {
      if (URLParams.requestId) {
        if (!historyState || !historyState.editingQuote) {
          getDeclineReasons("booking_request");
          getQuoteById(URLParams.requestId, URLParams.quoteId)
            .then((res) => {
              if (!URLParams.quoteId) {
                return setQuoteDetailNew(res);
              }
              if (res.quote) {
                return setQuoteDetailAmend(res);
              }
              setQuoteDetailNew(res);
            })
            .catch((error) => {
              if ((error && error.status === 404) || error.status === 403) {
                history.push("/dashboard");
              }
            });
        }
      }
    }
  }, [getDataTrigger]);

  useEffect(() => {
    return function cleanup() {
      clearQuoteDetail();
    };
  }, []);

  if (
    (editingQuote &&
      editingQuote.booking_request_id !== +URLParams.requestId &&
      editingQuote.id !== +URLParams.quoteId) ||
    editingQuote?.has_to_expire
  ) {
    return <CustomLoader />;
  }

  return (
    <div>
      <div className="gh-short-nav">
        <div className="uk-container">
          <div className="uk-flex uk-flex-between">
            <BackLink onClick={backHandler}>{staticService.findByAlias("back")}</BackLink>
          </div>
        </div>
      </div>
      <section>
        <div className="uk-container">
          <div>
            <h3>{staticService.findByAlias("quoteForTrip")}</h3>
          </div>

          {editingQuote ? (
            <QuoteTabs
              {...props}
              handleChangeTab={handleChangeTab}
              quoteId={+URLParams.requestId}
            />
          ) : (
            ""
          )}

          {/*CONTENT*/}
          <LoadingComponent show={!!Object.keys(quoteDetail).length}>
            <Switch>
              {routes &&
                routes.map((route, i) => {
                  return (
                    <RouteWithSubRoutes
                      key={i}
                      backHandler={backHandler}
                      title={route.title}
                      {...returnRouteUrl(route, match.url)}
                      handleChangeTab={handleChangeTab}
                      originalQuote={quoteDetail.originalQuoteDetail}
                      editingQuote={quoteDetail.editingQuoteDetail}
                      flightPurposes={flightPurposes}
                      setQuoteDetailNew={setQuoteDetailNew}
                      setQuoteDetailAmend={setQuoteDetailAmend}
                    />
                  );
                })}
              <Redirect to="/marketplace" />
            </Switch>
          </LoadingComponent>
        </div>
      </section>
    </div>
  );
};

OperatorQuoteTripComponent.propTypes = {
  routes: PropTypes.array,
  match: PropTypes.object,
  quoteDetail: PropTypes.object,
  getQuoteById: PropTypes.func,
  resetQuoteDetail: PropTypes.func,
  history: PropTypes.object,
  getPurposes: PropTypes.func,
  editingQuote: PropTypes.object,
  getDeclineReasons: PropTypes.func,
  removeToAmendQuote: PropTypes.func,
  updateBothQuoteObjects: PropTypes.func,
  quoteToAmend: PropTypes.object,
  clearQuoteDetail: PropTypes.func,
  updateEditedQuote: PropTypes.func,
};

const mapStateToProps = (store) => ({
  quoteDetail: store.setQuoteDetail,
  editingQuote: store.setQuoteDetail.editingQuoteDetail,
  quoteToAmend: store.quoteToAmend,
});

export default connect(mapStateToProps, {
  getQuoteById,
  getPurposes,
  getDeclineReasons,
  removeToAmendQuote,
  updateBothQuoteObjects,
  clearQuoteDetail,
  updateEditedQuote,
})(OperatorQuoteTripComponent);
