import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import { Control, Errors } from "react-redux-form";
import calendar from "../../../assets/img/svg/calendar.svg";
import { CustomValidateError, DateTimeComponent } from "../../../shared";
import { validationMessages } from "../../../configs";

const EnquireDate = (props) => {
  const { label, model, disabled, validators, onChange } = props;

  return (
    <div className="gh-enquire-calendar --enquire-form">
      <label className="uk-form-label">{staticService.findByAlias(label)}</label>
      <div className="uk-form-controls uk-position-relative gh-enquire-calendar-box">
        <Control
          model={model}
          className={"uk-input gh-calendar-input"}
          icon={calendar}
          component={DateTimeComponent}
          disabled={disabled}
          validators={validators}
          readOnly
          controlProps={{
            isValidDate: true,
            isValidDateBefore: true,
            dateFormat: "DD MMMM, YYYY",
            showClearIcon: false,
          }}
          attrProps={{ className: "uk-width-1-1" }}
          changeAction={onChange}
          updateOn="change"
        />
        <Errors
          model={model}
          show="touched"
          wrapper={CustomValidateError}
          messages={{
            required: validationMessages().requiredMessage.bind(null, "Date"),
            endDateValidation: staticService.findByAlias("rangeValidationEndDateError"),
          }}
        />
      </div>
    </div>
  );
};

EnquireDate.propTypes = {
  label: PropTypes.string,
  model: PropTypes.string,
  disabled: PropTypes.bool,
  validators: PropTypes.any,
  onChange: PropTypes.any,
};

export default EnquireDate;
