import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { actions } from "react-redux-form";
import { connect } from "react-redux";
import {
  getCompanyDetails,
  REMOVED_DOCUMENT,
  getCompanyDetailsSuccess,
} from "../../../../../actions";
import { CompanyComponent } from "../../../../forms";
import Auth from "../../../../../services/auth";
import staticService from "../../../../../services/static.service";
import { BlockComponent } from "../../../../common/block.component";
import { BROKER_MAIN } from "../../../../../configs/constants";

class VerificationComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.companyModel !== nextProps.companyModel) {
      this.props.updateModel("companyModel", nextProps.companyModel);
    }
  }

  onSuccessHandler(response) {
    this.context.store.dispatch({ type: REMOVED_DOCUMENT, data: [] });
    this.props.updateCompanyDetails(response);
  }

  componentWillMount() {
    this.props.get();
  }

  get verified() {
    const response = {
      alertClass: "uk-alert-warning gh-alert-without-bg",
    };

    if (this.props.verified) {
      response.alertClass = "uk-alert-success";
    }
    return response;
  }

  get status() {
    if (this.props.company.id) {
      const alias = this.props.company.companyUnverifiedMessages[Auth.getRole()];
      return <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }} />;
    }
    return null;
  }

  get operatorStatus() {
    if (this.props.company.id) {
      const alias = this.props.company.companyUnverifiedMessages[Auth.getRole()];
      return <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }} />;
    }
    return null;
  }

  get brockerVerificationStatus() {
    return !this.props.verified ? (
      <div
        className={[
          "uk-margin-small-bottom",
          "uk-alert gh-alert-without-bg",
          this.verified.alertClass,
        ].join(" ")}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("accountStatus"),
          }}
        />
        : {this.status}
      </div>
    ) : null;
  }

  get operatorVerificationStatus() {
    return (
      <div
        className={[
          "uk-margin-small-bottom",
          "uk-alert gh-alert-without-bg",
          this.verified.alertClass,
        ].join(" ")}
      >
        {this.operatorStatus}
      </div>
    );
  }

  render() {
    return (
      <BlockComponent visible={true}>
        <h2
          className={"gh-text-slim uk-margin-remove-bottom"}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("companyInfo"),
          }}
        />
        {Auth.getMainRole() === BROKER_MAIN
          ? this.brockerVerificationStatus
          : this.operatorVerificationStatus}
        <CompanyComponent successHandler={this.onSuccessHandler.bind(this)} />
      </BlockComponent>
    );
  }
}

VerificationComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ companyDetails, company }) {
  return {
    companyModel: companyDetails,
    verified: company.verified,
    company,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      get: getCompanyDetails,
      updateCompanyDetails: getCompanyDetailsSuccess,
      updateModel: (model, value) => dispatch(actions.change(model, { ...value })),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(VerificationComponent);
export { COMPONENT as VerificationComponent };
