import React, { Component } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import PropTypes from "prop-types";

class InputGeocodeComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {
      value: props.value || "",
    };
  }

  componentWillReceiveProps(next) {
    if (this.props.value !== next.value) {
      this.setState({ value: next.value });
    }
  }

  showSuggestions(suggestions) {
    this.places.setState({
      suggestions: suggestions.map((suggestion, index) => {
        suggestion.description = suggestion.suggestion;
        suggestion.id = index;
        suggestion.terms = {};
        suggestion.matchedSubstrings = {};
        suggestion.type = ["locality", "political", "geocode"];
        return suggestion;
      }),
    });
  }

  onChange(value) {
    if (typeof this.props.onInputChange === "function") {
      this.props.onInputChange(value);
    }
  }

  onInputFocus(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.readonly) {
      return false;
    }
    if (this.props.onInputFocus) {
      this.props.onInputFocus(this.showSuggestions.bind(this));
    }
  }

  onSelect(value, placeId) {
    this.onChange(value);
    if (this.props.onOptionSelect) {
      this.props.onOptionSelect(value, placeId);
    }
  }

  onInputBlur(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onBlur(event);
    const activeItem = this.places.getActiveSuggestion();
    if (activeItem && activeItem.suggestion !== "undefined" && activeItem.placeId !== "undefined") {
      this.onSelect(activeItem.suggestion, activeItem.placeId);
    }
  }

  render() {
    const inputProps = {
      name: this.props.name,
      readOnly: this.props.readonly,
      onFocus: this.onInputFocus.bind(this),
      onBlur: this.onInputBlur.bind(this),
      placeholder: this.props.placeholder,
      className: "uk-input",
    };
    const styles = Object.assign({}, defaultStyles, this.props.styles);
    const options = {
      componentRestrictions: { country: this.props.filters },
    };

    return (
      <PlacesAutocomplete
        ref={(ref) => (this.places = ref)}
        value={this.props.value}
        onChange={this.onChange.bind(this)}
        onSelect={this.onSelect.bind(this)}
        searchOptions={options}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const items = suggestions.filter((suggestion) => {
            if (suggestion.suggestion) return true;
            return true;
          });
          const containerClass = ["autocomplete-dropdown-container", "disabled-list"];
          if (items.length === 0) containerClass.push("hide");
          return (
            <div style={styles}>
              <input {...getInputProps(inputProps)} />
              <ul className={containerClass.join(" ")}>
                {items.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <li {...getSuggestionItemProps(suggestion, { className, style })}>
                      <span>{suggestion.description}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

InputGeocodeComponent.propTypes = {
  onLocationSelected: PropTypes.func,
  onInputFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  styles: PropTypes.object,
  classNames: PropTypes.object,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  filters: PropTypes.array,
};

InputGeocodeComponent.defaultTypes = {
  value: "",
  styles: {},
  classNames: {},
  placeholder: "",
  filters: [],
  readonly: false,
};

const defaultStyles = {
  root: {
    position: "relative",
    paddingBottom: "0px",
  },
  input: {
    display: "inline-block",
    width: "100%",
    padding: "10px",
  },
  autocompleteContainer: {
    zIndex: 999,
    maxHeight: "500px",
    overflowY: "auto",
  },
  autocompleteItem: {
    backgroundColor: "#ffffff",
    padding: "10px",
    color: "#555555",
    cursor: "pointer",
  },
  autocompleteItemActive: {
    backgroundColor: "#fafafa",
  },
};

export { InputGeocodeComponent };
