import React from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";
import { BlockComponent } from "../common";
import { addFailedCssClassToArray, getActiveClassBool, pageTitle, storage } from "../../shared";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../";
import Auth from "../../services/auth";
import staticService from "../../services/static.service";
import { ButtonWithBadgeComponent } from "../pages/user-online/common/button-with-badge.component";
import filtersIcon from "../../assets/img/svg/filters-icon.svg";
import BackLink from "../common/backlink";

class BookingLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCss: ["gh-filters-tab"],
    };
    this.alternativeBackLink = props.history?.location.state?.goBackObj;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filterCss !== nextProps.filterCss) {
      this.setState({
        filterCss: addFailedCssClassToArray(nextProps.filterCss, this.state.filterCss),
      });
    }
  }

  componentWillMount() {
    document.title = pageTitle(this.props.pageTitle);
  }

  get className() {
    return ["booking-layout"].concat(this.props.parentClassname).join(" ");
  }

  get requestButtonProps() {
    return {
      componentProps: {
        isButton: false,
        path: "/new-request",
        onClick: () => storage.delete("request_form"),
        actionTitle: staticService.findByAlias("requestPageQuote"),
        classNames: [
          "uk-button",
          "gh-req-button",
          "uk-margin-small-bottom",
          "uk-margin-remove-top",
          "uk-width-1-6",
          "uk-padding-remove",
          "uk-margin-small-top",
        ],
        tooltipMessage: staticService.findByAlias("accountUnverified"),
        verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["broker_admin", "broker_team"],
      component: IsVerifiedAuthorization,
    };
  }

  get marketButtonProps() {
    return {
      componentProps: {
        route: "/marketplace",
        label: staticService.findByAlias("operatorMarketPlaceButton"),
        classes: ["btn-pulse"],
        visible: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["operator_admin", "operator_team"],
      component: ButtonWithBadgeComponent,
    };
  }

  get buttonProps() {
    return Auth.getMainRole() === "operator" ? this.marketButtonProps : this.requestButtonProps;
  }

  isActive(index, alias, activePath) {
    return getActiveClassBool(index, alias, activePath.path.trim());
  }

  render() {
    const { isFiltersShown = false } = this.props;

    const brokerAllRequestsDetail = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/all/:id",
      strict: true,
      exact: true,
    });

    const brokerAllQuotesDetail = matchPath(window.location.pathname, {
      path: "/flights/quotes/filter/all/:id",
      strict: true,
      exact: true,
    });

    const brokerAllBookingDetail = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/filter/all/:id",
      strict: true,
      exact: true,
    });

    const brokerBookingDetail = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/:id",
      strict: true,
      exact: true,
    });
    const brokerBookingDetailOpen = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/filter/open/:id",
      strict: true,
      exact: true,
    });
    const brokerRequest = matchPath(window.location.pathname, {
      path: "/flights/requests/:id",
      strict: true,
      exact: true,
    });
    const brokerConfirm = matchPath(window.location.pathname, {
      path: "/flights/requests/:id/payment/:quoteID/step/:id",
      strict: true,
      exact: true,
    });
    const brokerReview = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/review/:id",
      strict: true,
      exact: true,
    });
    const brokerActive = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/open/:id",
      strict: true,
      exact: true,
    });
    const brokerUrgent = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/urgent/:id",
      strict: true,
      exact: true,
    });
    const brokerPending = matchPath(window.location.pathname, {
      path: "/flights/requests/filter/pending/:id",
      strict: true,
      exact: true,
    });

    const operOpen = matchPath(window.location.pathname, {
      path: "/flights/quotes/filter/open/:id",
      strict: true,
      exact: true,
    });
    const operBookingInit = matchPath(window.location.pathname, {
      path: "/flights/quotes/filter/booking_init/:id",
      strict: true,
      exact: true,
    });
    const operUnsuccessful = matchPath(window.location.pathname, {
      path: "/flights/quotes/filter/unsuccessful/:id",
      strict: true,
      exact: true,
    });
    const operWithdrawn = matchPath(window.location.pathname, {
      path: "/flights/quotes/filter/withdrawn/:id",
      strict: true,
      exact: true,
    });
    const operBookings = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/:id",
      strict: true,
      exact: true,
    });
    const operQuotes = matchPath(window.location.pathname, {
      path: "/flights/quotes/:id",
      strict: true,
      exact: true,
    });
    const bookingConfirmed = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/filter/confirmed/:id",
      strict: true,
      exact: true,
    });
    const bookingCancelled = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/filter/cancelled/:id",
      strict: true,
      exact: true,
    });
    const bookingCompleted = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/filter/completed/:id",
      strict: true,
      exact: true,
    });
    const bookingProvisional = matchPath(window.location.pathname, {
      path: "/flights/my-bookings/filter/provisional/:id",
      strict: true,
      exact: true,
    });
    const brokerMarketplaceEmptyLeg = matchPath(window.location.pathname, {
      path: "/marketplace/empty-legs/:id",
      strict: true,
      exact: true,
    });
    const operMarketplaceEmptyLeg = matchPath(window.location.pathname, {
      path: "/flights/empty-legs/filter/requests/:id",
      strict: true,
      exact: true,
    });
    const operFlightsEmptyLeg = matchPath(window.location.pathname, {
      path: "/flights/empty-legs/:id",
      strict: true,
      exact: true,
    });
    const quoteDetails = matchPath(window.location.pathname, {
      path: "/flights/quotes/filter/:type/:id",
      strict: true,
      exact: true,
    });
    const quoteDetailsOtherUrl = matchPath(window.location.pathname, {
      path: "/flights/quotes/:id",
      strict: true,
      exact: true,
    });
    const quoteEmptyLegDetails = matchPath(window.location.pathname, {
      path: "/flights/empty-legs/filter/requests/:id",
      strict: true,
      exact: true,
    });
    const quoteEmptyLegDetailsNotify = matchPath(window.location.pathname, {
      path: "/flights/empty-legs/:id",
      strict: true,
      exact: true,
    });

    const showShortNav =
      !!quoteDetailsOtherUrl ||
      !!quoteDetails ||
      !!quoteEmptyLegDetails ||
      !!quoteEmptyLegDetailsNotify;

    const hideOnOperQuoteDetail = () => {
      return (
        !!brokerAllRequestsDetail ||
        !!brokerAllQuotesDetail ||
        !!brokerAllBookingDetail ||
        !!operOpen ||
        !!operBookingInit ||
        !!operUnsuccessful ||
        !!operWithdrawn ||
        !!operQuotes ||
        !!bookingConfirmed ||
        !!bookingCancelled ||
        !!bookingCompleted ||
        !!bookingProvisional ||
        !!brokerMarketplaceEmptyLeg ||
        !!operMarketplaceEmptyLeg ||
        !!operBookings ||
        !!operFlightsEmptyLeg
      );
    };

    const backLinkUrl = this.alternativeBackLink?.pathname || "/dashboard";
    const backLinkAlias = this.alternativeBackLink?.alias || "backToDashboard";

    const fullNav = () => (
      <div>
        <div className={`uk-width-1-1 ${Auth.getMainRole() === "broker" ? "uk-flex" : ""}`}>
          <div className={Auth.getMainRole() === "broker" ? "uk-width-1-2" : "uk-width-1-1"}>
            <h3
              className="gh-booking-layout-title"
              dangerouslySetInnerHTML={{ __html: "All " + this.props.pageTitle }}
            />
          </div>
          <div
            className={
              Auth.getMainRole() === "broker"
                ? "uk-width-1-2 uk-flex uk-flex-right"
                : "uk-width-1-1"
            }
          >
            <ComponentAuthorization
              component={this.buttonProps.component}
              componentProps={this.buttonProps.componentProps}
              roles={this.buttonProps.roles}
            />
          </div>
        </div>
        {hideOnOperQuoteDetail() ? (
          ""
        ) : (
          <ul className="uk-tab">
            <li>
              <BackLink
                to={backLinkUrl}
                className="uk-margin-small-bottom"
                toUpdateModel={backLinkUrl === "/marketplace"}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(backLinkAlias),
                  }}
                />
              </BackLink>
            </li>
            {this.props.isFilterVisible ? (
              <li className={this.state.filterCss.join(" ")}>
                <a onClick={this.props.onFiltersClick}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias(isFiltersShown ? "Close" : "Filters"),
                    }}
                  />{" "}
                  <span className="uk-margin-small-left">
                    <img src={filtersIcon} alt="filters-icon" />
                  </span>
                </a>
              </li>
            ) : null}
          </ul>
        )}
      </div>
    );
    const shortNavVisible = () => {
      return (
        (Auth.getRole() === "broker_admin" ||
          Auth.getRole() === "broker_team" ||
          Auth.getRole() === "operator_admin" ||
          showShortNav) &&
        (!!brokerRequest ||
          !!brokerConfirm ||
          !!brokerBookingDetailOpen ||
          !!brokerBookingDetail ||
          !!brokerReview ||
          !!brokerActive ||
          !!brokerUrgent ||
          !!quoteDetails ||
          !!brokerAllBookingDetail ||
          !!bookingConfirmed ||
          !!bookingCancelled ||
          !!bookingCompleted ||
          !!bookingProvisional ||
          showShortNav ||
          !!brokerPending)
      );
    };

    return (
      <section className={this.className}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: !shortNavVisible() ? "uk-margin-medium-top" : "",
          }}
          containerClass={
            shortNavVisible() ? "uk-container-expand gh-block-component-wrapper-with-margin" : ""
          }
        >
          {!shortNavVisible() && fullNav()}
          {/*{fullNav()}*/}
          {this.props.children}
        </BlockComponent>
      </section>
    );
  }
}

BookingLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  parentClassname: PropTypes.array,
  onFiltersClick: PropTypes.func,
  isFilterVisible: PropTypes.bool,
  filterCss: PropTypes.string,
  history: PropTypes.object,
};

BookingLayout.defaultProps = {
  pageTitle: "Booking",
  parentClassname: [],
  isFilterVisible: true,
};

export { BookingLayout };
