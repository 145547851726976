import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import UploadFiles from "../../../../../../forms/register/upload-files.component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import CustomLoader from "../../../../../../common/CustomLoader/customLoader";
import {
  deleteHelicopterServiceTerms,
  getHelicopterServiceTerms,
  updateHelicopterServiceTerms,
} from "../../../../../../../actions";
import { showNotification } from "../../../../../../../utils/showNotification";

const UploadTerms = (props) => {
  const {
    updateModel,
    helicopterServicesTermsFiles,
    getHelicopterServiceTerms,
    updateHelicopterServiceTerms,
    deleteHelicopterServiceTerms,
  } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHelicopterServiceTerms()
      .then((res) => {
        updateModel("helicopterServicesTermsFiles", res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleUpdateTerms = (progressStatus) => {
    if (progressStatus === "uploaded") {
      updateHelicopterServiceTerms(helicopterServicesTermsFiles).then(() => {
        showNotification("Uploaded with success", "success");
      });
    }
  };

  const handleDeleteTerms = (file) => {
    if (file.id || file.upload) {
      return deleteHelicopterServiceTerms()
        .then(() => {
          updateModel("helicopterServicesTermsFiles", []);
          showNotification("Deleted with success", "success");
        })
        .catch(() => {})
        .finally(() => {});
    } else {
      return updateModel("helicopterServicesTermsFiles", []);
    }
  };

  if (loading) return <CustomLoader />;
  return (
    <div className="uk-margin-top">
      <UploadFiles
        model={helicopterServicesTermsFiles}
        modelName="helicopterServicesTermsFiles"
        uploadTitle="flightContract"
        docType={1}
        docs={helicopterServicesTermsFiles}
        handleUpdateProgressStatus={handleUpdateTerms}
        entity="company_document"
        customRemoveFileAction={handleDeleteTerms}
        multiple={false}
        onlyOneFile={true}
        withOutGlobalLoading={true}
      />
    </div>
  );
};

UploadTerms.propTypes = {
  updateModel: PropTypes.func.isRequired,
  helicopterServicesTermsFiles: PropTypes.array.isRequired,
  getHelicopterServiceTerms: PropTypes.func.isRequired,
  updateHelicopterServiceTerms: PropTypes.func.isRequired,
  deleteHelicopterServiceTerms: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  helicopterServicesTermsFiles: store.helicopterServicesTermsFiles,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      getHelicopterServiceTerms,
      updateHelicopterServiceTerms,
      deleteHelicopterServiceTerms,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadTerms);
