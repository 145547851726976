import React from "react";
import { Route, Switch, Link } from "react-router-dom";
import {
  config,
  bookingConfig,
  bookingConfigOperatorParts,
  bookingConfigBrokerParts,
} from "../../../../configs";
import { BookingLayout } from "../../../layouts";
import {
  ComplexTableComponent,
  returnRouteUrl,
  RouteWithSubRoutes,
  fireTracking,
  inArray,
} from "../../../../shared";
import { PaginationService } from "../../../../services";
import GetHeliEvents from "../../../../services/event.service";
import staticService from "../../../../services/static.service";
import auth from "../../../../services/auth";

export class BookingListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.catchSuccessResponse = this.catchSuccessResponse.bind(this);
    this.setFailed = this.setFailed.bind(this);
    this.tableAction = this.tableAction.bind(this);
    this.loadData = this.loadData.bind(this);
    this._mounted = true;
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState && this._mounted) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  catchCancelBookingResponse(event, params) {
    const items = this.props.items.filter((booking) => booking.id !== params.id);
    this.props.refreshBookings(items);
    this.paginationService.reloadItems();
  }

  componentWillMount() {
    GetHeliEvents.setEvent("bookings", this.catchSuccessResponse);
    GetHeliEvents.setEvent("booking-canceled", this.catchCancelBookingResponse.bind(this));
    GetHeliEvents.setEvent("filter-triggered", () => {
      this.setState({ isFilterTriggerd: true });
    });

    GetHeliEvents.setEvent("update-booking-title-page", (ev, data) => {
      this.setState({ pageTitle: staticService.findByAlias(data[0]) });
    });

    const initSort = this.getSorting(this.props);
    this.paginationService = new PaginationService(
      this.context.store.dispatch,
      this.loadData,
      config.paginationLimit,
      this.state.filters,
    );
    const newConfig = addBrokerOrClientColumnToTable(bookingConfig, auth.getMainRole());

    this.paginationService.setProp("columns", newConfig.columns);
    this.paginationService.setSortingParameters(
      {
        column: initSort.field,
        order: initSort.sortOrder,
      },
      "bookingOrder",
      "bookingSorted",
    );
    this.paginationService.setProp("headers", staticService.setGridHeaders(newConfig));
    this.paginationService.setProp("skipDispatch", true);
    this.paginationService.loadData();
    fireTracking(this.props.location.pathname);
  }

  setFailed(items) {
    if (items.length === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  prepareRows(items) {}

  loadData() {}

  getType(props) {
    if (props.match.params.search) {
      return props.match.params.search;
    } else {
      return "all";
    }
  }

  getSorting(props) {
    if (props.match.params.search && props.match.params.search === "completed") {
      return { field: "departure_time", sortOrder: "desc" };
    } else if (
      props.match.params.search &&
      inArray(props.match.params.search, ["current", "active"])
    ) {
      return { field: "departure_time", sortOrder: "asc" };
    }
    return { field: "id", sortOrder: "desc" };
  }

  tableAction(index) {
    const id = this.paginationService.items[index].id;
    const template = (
      <Link className={"uk-button uk-button-link"} to={`${config.bookingRoute}/${id}`}>
        View
      </Link>
    );
    return {
      desktop: <td style={{ verticalAlign: "middle" }}>{template}</td>,
      mobile: template,
    };
  }

  get layoutParentCssClass() {
    return this.props.screenW < config.nexusDeviceWidth &&
      this.props.screenW > config.minDeviceWidth
      ? ["gh-mob"]
      : ["gh-normal"];
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      isFiltersShown: !prevState.isFiltersShown,
    }));
  }

  handleSorting(column, order) {
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters(
      { column: column, order: order },
      "bookingOrder",
      "bookingSorted",
    );
    this.paginationService.loadData();
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  get filters() {}

  render() {
    return (
      <BookingLayout
        pageTitle={this.state.pageTitle}
        navigationTabs={this.state.navTabs}
        isFiltersShown={this.state.isFiltersShown}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        parentClassname={this.layoutParentCssClass}
        activeRoute={this.props.match}
        location={this.props.location}
        isFilterVisible={this.state.showFilterIcon}
        filterCss={this.state.filterFailed}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  title={route.title}
                  onEnter={this.onEnterPage.bind(this)}
                  {...returnRouteUrl(route, this.props.match.url)}
                />
              );
            })}
          <Route
            path="/"
            render={() => {
              return (
                <div>
                  {this.filters}
                  <div className={"uk-overflow-auto uk-margin-bottom"}>
                    <ComplexTableComponent
                      loadMore={this.paginationService.loadMore.bind(this.paginationService)}
                      rows={this.paginationService.items}
                      headers={this.paginationService.headers}
                      columns={this.paginationService.columns}
                      showActionColumn={1}
                      showLoader={this.paginationService.showLoaderBtn}
                      showMoreText={staticService.findByAlias("showMore")}
                      actionElements={this.tableAction}
                      selectedOrder={this.state.sortOrder}
                      sortChange={this.handleSorting.bind(this)}
                      firstTimeLoading={this.paginationService.initialLoadState}
                    />
                  </div>
                </div>
              );
            }}
          />
        </Switch>
      </BookingLayout>
    );
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("bookings");
    GetHeliEvents.clearEvent("booking-canceled");
    GetHeliEvents.clearEvent("filter-triggered");
    GetHeliEvents.clearEvent("update-booking-title-page");
    this._mounted = false;
  }
}

const addBrokerOrClientColumnToTable = (config, role) => {
  const newConfig = JSON.parse(JSON.stringify(config));
  const isOperator = role === "operator";
  const headers = isOperator
    ? bookingConfigOperatorParts.headers[0]
    : bookingConfigBrokerParts.headers[0];
  const columns = isOperator
    ? bookingConfigOperatorParts.columns[0]
    : bookingConfigBrokerParts.columns[0];
  const headersOrder = newConfig.headers?.length - 2;
  const columnsOrder = newConfig.columns?.length - 2;

  newConfig.headers.splice(headersOrder, 0, { ...headers });
  newConfig.columns.splice(columnsOrder, 0, columns);
  return newConfig;
};
