import React from "react";
import PropTypes from "prop-types";
import { account, pages, staticText } from "../../configs";
import { appendScript, filteredPages } from "../../shared";
import { MainLayout } from "./main.layout";
import { AccountHeader, Header } from "../common";
import staticService from "../../services/static.service";
import Auth from "../../services/auth";

class HomeLayout extends MainLayout {
  componentWillMount() {
    super.componentWillMount();
    this.setState({ pages: this.props.pages });
  }

  get header() {
    if (Auth.isAuthenticated()) {
      return (
        <AccountHeader
          activeRoute={this.props.activeRoute}
          extraProps={{ navClassName: "uk-navbar-container uk-navbar" }}
          className={"gh-dashboard-nav"}
          pages={filteredPages(account).map((page) => {
            if (Auth.getMainRole() === "operator" && page.path === "/flights/requests") {
              return {
                ...page,
                path: "/flights/quotes",
              };
            }
            return { ...page };
          })}
        />
      );
    }
    return (
      <Header
        activeRoute={this.props.activeRoute}
        extraProps={{ navClassName: "uk-navbar-container uk-navbar gh-dashboard-logout-container-nav" }}
        className={"gh-dashboard-nav gh-dashboard-logout-header-nav"}
        pages={this.props.pages}
        navVisibility={this.props.navVisibility}
      />
    );
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = staticService.findByAlias("homePageTitle");
    //temporary fix
    document.body.classList.add("gh-dashboard");
    this.timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    appendScript("//cdn.iubenda.com/cs/iubenda_cs.js", true, "iubenda");
    this.setState({
      pages: this.updatePagesRoutes(this.props.pages, this.props.registerPath),
    });
    if (window.location.href.includes("pricing")) {
      const header = document.getElementsByClassName("gh-dashboard-nav")[0];
      if (header) {
        header.style.minWidth = "1500px";
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.registerPath !== nextProps.registerPath) {
      this.setState({
        pages: this.updatePagesRoutes(this.props.pages, nextProps.registerPath),
      });
    }
  }

  updatePagesRoutes(pages = [], signUpPath) {
    return pages.map((page, i) => {
      if (page.alias && page.alias === "signUp") {
        page.path = signUpPath;
      }
      return page;
    });
  }

  render() {
    return (
      <div className={"page-wrapper"}>
        {this.header}
        {this.props.children}
        {this.footer}
      </div>
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const iubendaScript = document.getElementById("iubenda");
    if (iubendaScript && iubendaScript.remove) {
      iubendaScript.remove();
    }
  }
}

HomeLayout.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  registerPath: PropTypes.string.isRequired,
  pages: PropTypes.array,
};

HomeLayout.defaultProps = {
  pages: pages,
  pageTitle: "Home",
};

export { HomeLayout };
