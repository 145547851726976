import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../../CalculatorContext";
import { Control } from "../Control";
import { Description } from "../Description";
import { Select } from "../Select";

function AircraftOptions({ prefix, onFocus, onSelect, selectedAircrafts: unavailable }) {
  const { availableAircrafts, extraAircrafts } = useCalculatorContext();
  const { getValues } = useFormContext();

  const emptyLegId = getValues("empty_leg_id");

  const options = React.useMemo(() => {
    return [...availableAircrafts].map((aircraft) => ({
      value: aircraft.id,
      label: aircraft.name,
      disabled: unavailable.includes(aircraft.id),
    }));
  }, [availableAircrafts, unavailable]);

  const extraOptions = React.useMemo(() => {
    return [...extraAircrafts].map((aircraft) => ({
      value: aircraft.id,
      label: aircraft.name,
      disabled: unavailable.includes(aircraft.id),
    }));
  }, [extraAircrafts, unavailable]);

  return (
    <Description.Item>
      <Control name={`${prefix}.name`} required disabled={!!emptyLegId}>
        <Select
          placeholder="[Tail number and type]"
          options={options}
          extraOptions={extraOptions}
          onFocus={onFocus}
          onSelect={onSelect}
          disabled={!!emptyLegId}
        />
      </Control>
    </Description.Item>
  );
}

AircraftOptions.propTypes = {
  prefix: PropTypes.string,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  selectedAircrafts: PropTypes.any,
};

export default React.memo(AircraftOptions);
