import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../../CalculatorContext";
import AircraftOptions from "./AircraftOptions";
import AircraftModal from "./AircraftModal";
import LegsList from "../Leg/LegsList";
import useAircraftName from "../../hooks/useAircraftName";
import { Control } from "../Control";
import { Description } from "../Description";
import { Options } from "../Options";
import staticService from "../../../../services/static.service";
import cs from "../../../../utils/condString";

import "./index.css";
import { formatNumber } from "../../../../shared";

export function Aircraft(props) {
  const {
    aircraftsLen,
    index,
    onRemove,
    prefix,
    selectedAircrafts,
    aircraft,
    banking: bankingDemo,
  } = props;

  const {
    availableAircrafts,
    extraAircrafts,
    activeAircraftIndex,
    setAircraftActiveIndex,
    updateForm,
    demoVersion,
    banking,
  } = useCalculatorContext();
  const { watch, getValues } = useFormContext();
  const [visible, setVisible] = React.useState(false);
  const [hideOptions, setHideOptions] = React.useState(false);
  const legs = getValues(`${prefix}.legs`);

  React.useEffect(() => {
    if (visible) {
      setHideOptions(true);
    }
    return () => {
      setHideOptions(false);
    };
  }, [visible]);

  const aircraftId = watch(`${prefix}.name`, "");
  const aircraftUid = getValues(`${prefix}.id`);
  const aircraftName = useAircraftName({
    updateForm,
    availableAircrafts: [...availableAircrafts, ...extraAircrafts],
    prefix,
    getValues,
    companyVat: demoVersion ? bankingDemo?.vat_value : banking?.vat_value,
  });

  React.useEffect(() => {
    if (aircraftId) {
      selectedAircrafts.set(aircraftUid, aircraftId);
    }

    return () => {
      selectedAircrafts.delete(aircraftUid);
    };
  }, [aircraftId, selectedAircrafts, index, aircraftUid]);

  const isActive = React.useMemo(() => index === activeAircraftIndex, [activeAircraftIndex, index]);

  return (
    <div
      className={cs(
        "calculator__aircraft",
        isActive && "active",
        index < activeAircraftIndex && "with-border-b",
      )}
    >
      <Description onClick={() => setAircraftActiveIndex(index)}>
        <Description.Item
          type="header"
          as="text"
          className="aircraft-header"
          suffix={
            <Options hideOptions={hideOptions} aircraftOptions>
              <Options.Item onClick={() => setVisible(true)}>
                {staticService.findByAlias("defaultSettings")}
              </Options.Item>
              {aircraftsLen > 1 && (
                <Options.Item onClick={onRemove} danger>
                  Remove
                </Options.Item>
              )}
            </Options>
          }
        >
          <div className="aircraft-header__title">
            <span>Aircraft #{index + 1}</span>
          </div>
        </Description.Item>

        <AircraftOptions
          prefix={prefix}
          onFocus={() => setAircraftActiveIndex(index)}
          onSelect={aircraftName.onChange}
          selectedAircrafts={[...selectedAircrafts.values()].map(Number)}
        />
        <Control name={`${prefix}.calculation_aircraft_id`} notAComponent>
          <input type="hidden" />
        </Control>
      </Description>

      <Description>
        <Description.Item as="text">
          <span style={{ fontWeight: "400" }}>
            {formatNumber(legs.reduce((total, leg) => total + +leg.total_cost_leg, 0))}
          </span>
        </Description.Item>
      </Description>

      {isActive && <LegsList disabled={!aircraftId} prefix={prefix} />}

      <AircraftModal
        visible={visible}
        setVisible={setVisible}
        index={index}
        id={aircraftId}
        onAircraftNameChange={aircraftName.onChange}
        legs={aircraft.legs}
        banking={banking}
      />
    </div>
  );
}

Aircraft.propTypes = {
  prefix: PropTypes.string,
  index: PropTypes.number,
  aircraftsLen: PropTypes.number,
  onRemove: PropTypes.func,
  selectedAircrafts: PropTypes.any,
  aircraft: PropTypes.object,
  banking: PropTypes.object,
};
