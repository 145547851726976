import auth from "../../../services/auth";
import staticService from "../../../services/static.service";

export const getTabDisabledStatus = (quote) => (tabName) => {
  if (!auth.isVerified()) return true;

  let verifications = [];

  if (tabName === "quote") {
    verifications = [quote.quote];
  }

  if (tabName === "calculator") {
    verifications = [quote.quote, quote.quote?.is_calculator_enabled];
  }

  return verifications.some((v) => !v);
};

export const getTabTooltipMessage = (quote) => (tabName) => {
  if (tabName === "quote") {
    if (!quote.quote) return staticService.findByAlias("startTheQuoteFirst");
  }

  if (tabName === "calculator") {
    if (!quote.quote) return staticService.findByAlias("startTheQuoteFirst");
    if (!quote.quote?.is_calculator_enabled) return quote.quote?.calculator_disable_message || "";
  }

  return "";
};
