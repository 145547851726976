import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import info from "../../../assets/img/svg/info.svg";
import getTextWidth from "../../../utils/getTextWidth";

import "./tooltip.css";

const calculateTextW = getTextWidth();

function Tooltip(props) {
  const {
    children,
    pos,
    wrapperClassName,
    mobilePos,
    refEl,
    sign,
    trigger,
    icon,
    adjustPos,
    className,
    disabled,
    onTrigger,
    show,
    styles,
  } = props;
  const [currentPos, setCurrentPos] = useState(pos);
  const [contentW, setContentW] = useState();
  const content = useRef();
  const wrapper = useRef();

  const adjustVerticalPos = () => {
    if (wrapper.current) {
      let ref = null;

      if (refEl && refEl.current) ref = refEl.current;
      else if (refEl === "self") ref = wrapper.current;

      if (ref) {
        const { top } = ref.getBoundingClientRect();
        const { height } = content.current.getBoundingClientRect();

        const update = () => {
          if (currentPos.indexOf("top") !== -1) setCurrentPos(currentPos.replace("top", "bottom"));
          else setCurrentPos(currentPos.replace("bottom", "top"));
        };

        if (sign) {
          if (sign === ">") {
            if (adjustPos && top > height + 100) update();
            else setCurrentPos(window.innerWidth < 540 ? (mobilePos ? mobilePos : pos) : pos);
          }
        } else {
          if (adjustPos && top < height + 100) update();
          else setCurrentPos(window.innerWidth < 540 ? (mobilePos ? mobilePos : pos) : pos);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", adjustVerticalPos);

    return () => window.removeEventListener("scroll", adjustVerticalPos);
  }, []);

  useEffect(() => {
    if (mobilePos) {
      if (window.innerWidth < 540) setCurrentPos(mobilePos);
      else setCurrentPos(pos);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (content.current) {
      setContentW(calculateTextW(children, content.current));
    }
  }, [content.current]);

  if (disabled) {
    return trigger || null;
  }

  return (
    <div
      className={`custom-tooltip-wrapper ${show ? "show" : ""} ${wrapperClassName}`}
      ref={wrapper}
      onMouseOver={() => onTrigger(wrapper.current)}
      style={styles}
    >
      <div className="custom-tooltip-icon">
        {trigger ? trigger : <img src={icon || info} alt="Info" />}
      </div>
      {typeof children === "string" ? (
        <div
          className={`custom-tooltip-content ${currentPos} ${className}`}
          ref={content}
          style={{
            width: contentW ? contentW : "auto",
          }}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      ) : (
        <div
          className={`custom-tooltip-content ${currentPos} ${className}`}
          ref={content}
          style={{
            width: contentW ? contentW : "auto",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  trigger: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pos: PropTypes.oneOf([
    "top-left",
    "top-right",
    "top-center",
    "bottom-center",
    "bottom-right",
    "bottom-left",
  ]),
  mobilePos: PropTypes.oneOf([
    "top-left",
    "top-right",
    "top-center",
    "bottom-center",
    "bottom-right",
    "bottom-left",
  ]),
  wrapperClassName: PropTypes.string,
  refEl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sign: PropTypes.string,
  icon: PropTypes.node,
  adjustPos: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onTrigger: PropTypes.func,
  show: PropTypes.bool,
  styles: PropTypes.object,
};

Tooltip.defaultProps = {
  wrapperClassName: "",
  className: "",
  adjustPos: true,
  onTrigger: () => null,
  show: true,
};

export default Tooltip;
