import React from "react";
import { Description } from "../Description";

export function PilotPlaceholder() {
  return (
    <Description>
      <Description.Item />
      <Description.Item />
      <Description.Item />
      <Description.Item />
      <Description.Item />
      <Description.Item />
      <Description.Item />
    </Description>
  );
}
