import React from "react";
import staticService from "../../../../../services/static.service";
import fleetCheck from "../../../../../assets/img/svg/fleet-check.svg";
import fleetWarning from "../../../../../assets/img/svg/fleet-warning.svg";

const FleetHeliComponent = (props) => {
  const {
    detailsCallback,
    status,
    registration_number,
    manufacturer,
    aircraft_model,
    aircraft_images,
    home_base,
  } = props;

  const statusInfo = () => {
    if (status === 1) {
      return (
        <span className="status-bar">
          <img src={fleetCheck} alt="verified" />
          <span
            style={{ color: "#10B997" }}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("aircraftVerified") }}
          />
        </span>
      );
    }
    if (status === 2) {
      return (
        <span
          className="status-bar"
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "verifiedButExpiresSoon",
          )}; pos: top-left`}
        >
          <img src={fleetWarning} alt="fleetWarning" />
          <span
            style={{ color: "#FFAB00" }}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("aircraftVerified") }}
          />
        </span>
      );
    }
    if (status === 3) {
      return (
        <span
          className="status-bar"
          data-uk-tooltip={`title: ${staticService.findByAlias("mustBeVerified")}; pos: top-left`}
        >
          <img src={fleetWarning} alt="fleetWarning" />
          <span
            style={{ color: "#FFAB00" }}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("unverified") }}
          />
        </span>
      );
    }
    return (
      <span
        className="status-bar"
        data-uk-tooltip={`title: ${staticService.findByAlias("notVerifiedMessage")}; pos: top-left`}
      >
        <img src={fleetWarning} alt="fleetWarning" />
        <span
          style={{ color: "#FFAB00" }}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("unverified") }}
        />
      </span>
    );
  };

  return (
    <li className="fleet-item">
      <div className="status">{statusInfo()}</div>
      <div
        className="heli-img"
        style={{
          background: `url('${aircraft_images[0].file_url}') center center / cover no-repeat`,
        }}
      />
      <div className="info-list">
        <div className="info-row">
          <h5>{staticService.findByAlias("registrationHeliLabel")}</h5>
          <span className="info-row-description-registration">{registration_number}</span>
        </div>
        <div className="info-row">
          <h5>{staticService.findByAlias("manufacturer")}</h5>
          <span className="info-row-description">{manufacturer}</span>
        </div>
        <div className="info-row">
          <h5>{staticService.findByAlias("model")}</h5>
          <span className="info-row-description">{aircraft_model}</span>
        </div>
        <div className="info-row">
          <h5>{staticService.findByAlias("homeBase")}</h5>
          <span className="info-row-description">{home_base}</span>
        </div>
      </div>
      <div className="details-btn">
        <button
          className="uk-button uk-button-primary uk-button-small"
          onClick={(ev) => detailsCallback(props, ev)}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("aircraftDetailsButton"),
          }}
        />
      </div>
    </li>
  );
};

export default FleetHeliComponent;
