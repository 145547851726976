import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useMount, useUnmount, useUpdateEffect } from "react-use";
import { parseCoordinates } from "../../..";
import useDebounce from "../../../../hooks/useDebounce";
import generateCurvedLine from "../../../../utils/generateCurvedLine";

const curvature = 0.2;

export default function Line(props) {
  const { range, google, map, zoom, projection } = props;
  const [prevRange, setPrevRange] = useState(range);
  const [prevStableRange, setPrevStableRange] = useState(range);
  const getCurvedLine = useRef();
  const line = useRef(
    new google.maps.Marker({
      clickable: false,
      map,
      projection,
      zoom,
    }),
  );
  const lineArrow = useRef(
    new google.maps.Marker({
      clickable: false,
      map,
      projection,
      zoom,
    }),
  );

  const removeLine = () => {
    if (lineArrow.current) lineArrow.current.setMap(null);
    if (line.current) line.current.setMap(null);
  };

  const setLine = () => {
    if (!getCurvedLine.current) return;

    const curvedLine = getCurvedLine.current(
      {
        from: parseCoordinates(range.from),
        to: parseCoordinates(range.to),
      },
      projection,
      zoom,
    );

    if (!curvedLine) return;

    line.current = new google.maps.Marker({
      clickable: false,
      map,
      ...curvedLine,
    });

    lineArrow.current = new google.maps.Marker({
      clickable: false,
      map,
      position: curvedLine.midLine,
      options: {
        zIndex: 1,
        icon: {
          path: 1,
          fillColor: "#242f42",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "white",
          scale: 7,
          rotation: curvedLine.rotation,
        },
      },
    });
  };

  const updateLineDebounce = useRef(useDebounce(setLine, 300));

  const updateLine = () => {
    removeLine();
    updateLineDebounce.current();
  };

  const onRangeUpdated = () => {
    removeLine();

    if (JSON.stringify(range) === JSON.stringify(prevStableRange)) {
      updateLine();
    }
  };

  useEffect(() => {
    getCurvedLine.current = generateCurvedLine(google, curvature);
  }, [google]);

  useUpdateEffect(() => {
    if (range.to && range.from) {
      updateLine();
    }
  }, [zoom]);

  useEffect(() => {
    if (JSON.stringify(range) !== JSON.stringify(prevRange)) {
      onRangeUpdated();
    }

    if (range.from && range.to) {
      if (range.from !== prevStableRange.from || range.to !== prevStableRange.to) {
        updateLine();
      }
      setPrevStableRange(range);
    }

    setPrevRange(range);
  }, [range]);

  useMount(() => {
    removeLine();
    updateLine();
  });

  useUnmount(removeLine);

  return null;
}

Line.propTypes = {
  google: PropTypes.object,
  zoom: PropTypes.number,
  map: PropTypes.object,
  projection: PropTypes.object,
  range: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

Line.defaultProps = {
  range: {
    from: "",
    to: "",
  },
};
