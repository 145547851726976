import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LoginFormComponent } from "../../";
import { AccountLayout } from "../../layouts";
import { config, pages } from "../../../configs";
import auth from "../../../services/auth";
import { BlockComponent } from "../../common";
import { alert, filteredPages, fireTracking, storage } from "../../../shared";
import { bindActionCreators } from "redux";
import { getMeta } from "../../../actions";
import { Helmet } from "react-helmet/es/Helmet";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.state = {
      modalVisibility: false,
      isFormVisible: false,
      redirectPath: config.redirectUserDashboard,
    };
    fireTracking(props.location.pathname);
  }

  openModal(ev) {
    ev.preventDefault();
    this.changeModalVisibility(true);
  }

  componentDidMount() {
    this.props.getMeta(window.location.pathname.substring(1));
  }

  changeModalVisibility(state) {
    this.setState({ modalVisibility: state, isFormVisible: state });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLogged !== prevProps.isLogged) {
      const prevUrl = storage.get("prevUrl");
      storage.delete("prevUrl");
      if (prevUrl && prevUrl !== "") {
        this.setState({ redirectPath: prevUrl });
      }
    }
  }

  get Redirect() {
    return auth.getUser().notify_terms_accept && auth.getUser().active_sole_trader === 0
      ? config.redirectToReviewAgreement
      : this.state.redirectPath;
  }

  render() {
    const { metaData, title, match, isLogged } = this.props;

    if (!isLogged) {
      return (
        <AccountLayout activeRoute={match} pageTitle={title} pages={filteredPages(pages, "login")}>
          <Helmet>
            <link rel="canonical" href="https://getheli.com" />
            {metaData?.meta?.message?.map((meta) => (
              <meta key={meta.name} name={meta.name} content={meta.content} />
            ))}
          </Helmet>
          <section className={"login-page"}>
            <BlockComponent
              visible={true}
              containerClass={"uk-container uk-container-affiliate"}
              parentProps={{ className: "uk-section uk-padding-remove-bottom" }}
            >
              <div className="uk-width-1-1@s uk-width-1-2@m uk-align-center header-title">
                <h1 className="uk-margin-small-bottom section-heading">{this.props.title}</h1>
              </div>
            </BlockComponent>
            <BlockComponent
              visible={true}
              containerClass={"uk-section uk-padding-remove-top gh-section-statistics"}
              parentProps={{ className: "uk-container uk-container-affiliate" }}
            >
              <LoginFormComponent isVisible={true} openModal={this.openModal} />
            </BlockComponent>
          </section>
        </AccountLayout>
      );
    }

    return <Redirect to={this.Redirect} />;
  }
}

LoginComponent.contextTypes = {
  store: PropTypes.object,
};

LoginComponent.propTypes = {
  metaData: PropTypes.object,
  title: PropTypes.string,
  match: PropTypes.object,
  isLogged: PropTypes.bool,
  getMeta: PropTypes.func,
  location: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMeta,
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    isLogged: !!(state.auth && state.auth.access_token),
    forgottenStatus: !!(state.auth && state.auth.forgot),
    inactiveUser:
      state.errorObj && state.errorObj.inactive_user ? alert(state.errorObj.inactive_user) : false,
    metaData: state.metaData,
  };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
export { COMPONENT as LoginComponent };
