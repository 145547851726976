/**
 * Source: https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
 *
 * @description Check if the element is visible to the user
 *
 * @param {Node} el        Element itself.
 * @param {Node} container Checking if an element is visible inside this container.
 *
 * @returns {Boolean}
 */
export default function (el, container = null) {
  const rect = el.getBoundingClientRect();

  if (container) {
    const eleTop = el.offsetTop;
    const eleBottom = eleTop + el.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    return eleTop >= containerTop && eleBottom <= containerBottom;
  }

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
