import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import ImageLoader from "../../shared/image-loader/image-loader.component";
import staticService from "../../services/static.service";

class InfoBlockComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
    this.showMore = this.showMore.bind(this);
    this.formatContent = this.formatContent.bind(this);
  }

  showMore() {
    this.setState((prev) => ({ showMore: !prev.showMore }));
  }

  formatContent(txt) {
    if (window.innerWidth < 768 && !this.state.showMore) {
      return txt.split(".")[0] + ".";
    } else {
      return txt;
    }
  }

  get slideContent() {
    if (this.props.reverseColumn) {
      return (
        <div
          id="gh-info-block-box"
          className="uk-flex-middle uk-flex-left uk-child-width-1-1 uk-child-width-1-2@s uk-grid gh-info-block-box"
        >
          <div>
            <LazyLoad debounce={false}>
              <div className="uk-scrollspy-inview uk-animation-slide-right-medium uk-text-right">
                <NavLink to={this.props.navPath} className="el-link">
                  <ImageLoader src={this.props.image} className="el-image gh_inf_image" />
                </NavLink>
              </div>
            </LazyLoad>
          </div>
          <div className="uk-first-column gh-inf-block-desc">
            {!!this.props.showTitle && (
              <h3 className="uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-right-medium gh_inf_title">
                {this.props.title}
              </h3>
            )}
            <div className="uk-margin-medium uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-right-medium">
              <div
                className="gh_inf_content"
                dangerouslySetInnerHTML={{ __html: this.formatContent(this.props.content) }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        id="gh-info-block-box"
        className="uk-flex-middle uk-flex-right uk-child-width-1-1 uk-child-width-1-2@s uk-grid gh_ib_box gh-info-block-box"
      >
        <a className="gh_ib_show_more" onClick={this.showMore}>
          {this.state.showMore ? (
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("showLess") }} />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("showMore") }} />
          )}
        </a>

        <div className="uk-first-column gh-inf-block-desc">
          {!!this.props.showTitle && (
            <h3
              id="gh-info-block-title"
              className="uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-right-medium gh_inf_title"
            >
              {this.props.title}
            </h3>
          )}
          <div className="uk-margin-medium uk-width-1-1@s uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-right-medium">
            <div
              className="gh_inf_content"
              dangerouslySetInnerHTML={{ __html: this.formatContent(this.props.content) }}
            />
          </div>
        </div>

        <LazyLoad debounce={false}>
          <div className="uk-margin uk-scrollspy-inview uk-animation-slide-right-medium uk-text-left">
            <NavLink to={this.props.navPath} className="el-link">
              <ImageLoader src={this.props.image} className="gh_inf_image" />
            </NavLink>
          </div>
        </LazyLoad>
      </div>
    );
  }

  render() {
    return this.props.visible ? (
      <div className="info-block">
        <div>{this.slideContent}</div>
      </div>
    ) : null;
  }
}

InfoBlockComponent.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  navPath: PropTypes.string.isRequired,
  reverseColumn: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  showTitle: PropTypes.bool,
};

export { InfoBlockComponent };
