import React from "react";
import { MarketPlaceActivityComponent } from "../common/";
import { config } from "../../../../configs/";
import { redirectTo } from "../../../../shared/";

export class OperatorMarketPlaceActivityComponent extends MarketPlaceActivityComponent {
  componentWillMount() {}

  render() {
    return redirectTo(config.redirectToMarketPlace);
  }
}
