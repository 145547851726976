import staticService from "../services/static.service";

export const staticText = {
  appName: "Get Heli",
  forbiddenMessage: "Your access is restricted to this page!",
  registerTabTitle: {
    account: "Sign Up",
    accountByInvitation: " has invited you to join the {company} {attribute} team",
    underHeading: {
      first:
        "We are currently available for UK businesses and will be expanding to new markets soon.",
      second: "to find out what the plan is for launching in your region.",
    },
    invitation: {
      mainTitle: "Send Invitation",
      subTitle: "Team Invitations",
      note:
        "If you would prefer to invite members of your team at a later stage you can do so once logged in under\n" +
        "            your Account",
    },
    fleet: {
      mainTitle: "Make Helicopter Assignment Easy",
      subTitle: "Fleet",
    },
    summary: {
      mainTitle: "Summary and Account Setup",
      statuses: [
        {
          title: "Company and Account Setup",
          prop: "account",
          only: "both",
          alias: "companyAndAccountSetup",
        },
        {
          title: "Team Invitations",
          prop: "invitation",
          only: "both",
          alias: "invitationTeamInvitation",
        },
        {
          title: "Fleet",
          prop: "fleet",
          only: "operator",
          alias: "wizardFleetSubTitle",
        },
        {
          title: "Documentation",
          prop: "documentation",
          only: "both",
          alias: "uploadAccountDocumentation",
        },
      ],
    },

    upload: {
      mainTitle: "Account Verification",
      subTitle: "Documentation",
      uploadedTitle: "Uploaded Documents",
      noFileUploadMessage:
        "Please upload at least one file if you want to continue or just skip this step!!",
      noFileUploadMessage1: "At least one file is required!!",
    },
  },
  dashboard: {
    block_requests: {
      title: "liveQuoteRequests",
      blocksTitles: [
        [
          {
            blTitle: "quoteRequests",
            blNavTitle: "View",
            qty: "open_requests",
            parentKey: "requests",
            css: "gh-dark-blue",
            blNav: "/flights/requests/filter/open",
          },
          {
            blTitle: "quotesToReview",
            blNavTitle: "View",
            qty: "review",
            parentKey: "requests",
            css: "gh-count-primary",
            blNav: "/flights/requests/filter/review",
          },
          {
            blTitle: "bookingPending",
            blNavTitle: "View",
            qty: "pending",
            parentKey: "requests",
            css: "gh-count-warning",
            blNav: "/flights/requests/filter/pending",
          },
        ],
      ],
    },
    block_bookings: {
      title: "currentBookings",
      blocksTitles: [
        [
          {
            blTitle: "blockBookingAwaitingPayment",
            blNavTitle: "View",
            parentKey: "bookings",
            qty: "provisional_bookings",
            css: "gh-count-warning",
            blNav: "/flights/my-bookings/filter/provisional",
          },
          {
            blTitle: "brokerConfirmedBookings",
            blNavTitle: "View",
            parentKey: "bookings",
            qty: "confirmed",
            css: "gh-count-primary",
            blNav: "/flights/my-bookings/filter/confirmed",
          },
          {
            blTitle: "cancellations",
            blNavTitle: "View",
            parentKey: "bookings",
            qty: "canceled",
            css: "gh-count-danger",
            blNav: "/flights/my-bookings/filter/cancelled",
          },
        ],
      ],
    },

    operator_first: {
      title: "charterFlights",
      blocksTitles: [
        [
          {
            blTitle: "dashboardQuotesTitle",
            blNavTitle: "View",
            qty: "open_quotes",
            css: "gh-count-default",
            blNav: "/flights/quotes/filter/all",
          },
          {
            blTitle: "bookingsPendingConfirmation",
            blNavTitle: "View",
            qty: "bookings_to_confirm",
            css: "gh-count-warning",
            blNav: "/flights/quotes/filter/booking_init",
          },
          {
            blTitle: "blockBookingAwaitingPayment",
            blNavTitle: "View",
            qty: "provisional_bookings",
            css: "gh-count-warning",
            blNav: "/flights/my-bookings/filter/provisional",
          },
          {
            blTitle: "blockQuotesTitle1",
            blNavTitle: "View",
            qty: "active_bookings",
            css: "gh-count-primary",
            blNav: "/flights/my-bookings/filter/confirmed",
          },
          {
            blTitle: "cancelledBookings",
            blNavTitle: "View",
            qty: "canceled_bookings",
            css: "gh-count-danger",
            blNav: "/flights/my-bookings/filter/cancelled",
          },
        ],
      ],
    },

    block_current_week: {
      title: "bookingActivityForWeek",
      blocksTitles: [
        [
          {
            blTitle: "yourFlights",
            blNavTitle: "View",
            parentKey: "bookings",
            qty: "current_week",
            css: "gh-count-primary",
            blNav: "/flights/my-bookings/filter/current",
          },
          {
            blTitle: "urgentQuoteRequest",
            blNavTitle: "View",
            qty: "urgent",
            parentKey: "requests",
            css: "gh-count-danger",
            blNav: "/flights/requests/filter/urgent",
          },
        ],
      ],
    },

    operator_second: {
      title: "bookingActivityForWeek",
      blocksTitles: [
        [
          {
            blTitle: "operatorActiveBookingsDashboard",
            blNavTitle: "View",
            qty: "booking",
            css: "gh-count-primary",
            blNav: "/flights/my-bookings/filter/current",
          },
          {
            blTitle: "blockQuotesTitle2",
            blNavTitle: "View",
            qty: "quotes",
            css: "gh-count-default",
            blNav: "/flights/quotes/filter/current",
          },
        ],
      ],
    },

    block_quotes: {
      title: "operatorDashboardEmptyLegsTile",
      blocksTitles: [
        [
          {
            blTitle: "emptyLegRequests",
            blNavTitle: "View",
            qty: "empty_legs_offered",
            css: "gh-count-primary",
            blNav: "/flights/empty-legs/filter/requests",
          },
        ],
      ],
    },
  },

  payment: {
    card: {
      number: "Number",
      cvc: "CVC",
      exp_month: "Expire month",
      exp_year: "Expire year",
    },
    steps: ["Confirm Details", "Review Terms & Conditions", "Make Payment"],
  },

  globalMessages: {
    onSuccess: {
      post: "New item created with success",
      put: "{entityName} updated with success",
      delete: "{entityName} deleted with success",
    },

    onError: {
      post: "Ups, it seems something wrong went with your saving",
      put: "Ups, {entityName} could not be updated",
      delete: "Ups {entityName} could not be deleted",
    },
    updateNote: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },
    declineRequest: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },
    user: {
      accountDetail: {
        onSuccess: {
          put: "",
        },
        onError: {
          put: "",
        },
      },
      account: {
        onSuccess: {
          put: "",
        },
        onError: {
          put: "",
        },
      },
      notifications: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      verification: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      role: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      upload: {
        onSuccess: {
          post: "",
          delete: "",
        },
        onError: {
          post: "",
          delete: "",
        },
      },
      stripe: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      cards: {
        deleted: {
          onSuccess: {
            put: "",
          },
          onError: {
            put: "",
          },
        },
        updated: {
          onSuccess: {
            put: "",
          },
          onError: {
            put: "",
          },
        },
        onSuccess: {
          post: "",
          put: "",
        },
        onError: {
          post: "",
          put: "",
        },
      },
      bankAccount: {
        onSuccess: {
          put: "",
        },
        onError: {
          put: "",
        },
      },
      passwordUpdate: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      userRegister: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      passwordRecover: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      company: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
    },
    team: {
      onSuccess: {
        post: "",
        put: "",
        delete: "",
      },
      onError: {
        post: "",
        put: ".",
        delete: "",
      },
    },
    invitation: {
      onSuccess: {
        post: "",
        delete: "",
      },
      onError: {
        post: "",
        delete: "",
      },
    },

    favorite: {
      delete: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
      add: {
        onSuccess: {
          post: "",
        },
        onError: {
          post: "",
        },
      },
    },

    member: {
      onSuccess: {
        delete: "Member {email} removed",
      },
      onError: {
        delete: "",
      },
    },
    pipeline: {
      onSuccess: {
        delete: "Lead deleted successfully",
      },
      onError: {
        delete: "",
      },
    },
    conctactForm: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },
    subscribe: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },

    quote: {
      onSuccess: {
        put: "",
      },
      onError: {
        put: "",
      },
    },
    quoteWithdrawn: {
      onSuccess: {
        put: "",
      },
      onError: {
        put: "",
      },
    },
    quoteWithdrawn2: {
      onSuccess: {
        put: "",
      },
      onError: {
        put: "",
      },
    },
    request: {
      onSuccess: {
        post: "",
        put: "",
      },
      onError: {
        post: "",
        put: "",
      },
      identifier: {
        onSuccess: {
          put: "",
        },
        onError: {
          put: "",
        },
      },
    },
    booking: {
      onSuccess: {
        post: "",
        put: "",
      },
      onError: {
        post: "",
        put: "",
      },
    },
    payment: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },
    emptyLeg: {
      onSuccess: {
        delete: "",
        post: "",
      },
      onError: {
        delete: "",
        post: "",
      },
    },
    emptyLegWithdraw: {
      onSuccess: {
        delete: "",
        post: "",
      },
      onError: {
        delete: "",
        post: "",
      },
    },
    comment: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },
    premium: {
      onSuccess: {
        post: "",
      },
      onError: {
        post: "",
      },
    },
  },

  accountDropdown: {
    signUp: "Sign Out",
    profile: "Account",
    company: "Company",
    support: "Contact",
  },
  stripeErrors: [
    "id",
    "legal_entity[last_name]",
    "legal_entity[first_name]",
    "legal_entity[dob]",
    "exp_month",
    "card_declined",
    "expired_card",
    "risk_level",
    "incorrect_number",
    "incorrect_cvc",
  ],
  stripePlaceholders: {
    "legal_entity.verification.document": "Verification document",
    tos_acceptance: "Stripe Agreement",
    "legal_entity[dob][day]": "Please provide a corect day number",
    "legal_entity[dob][month]": "Please provide a corect month",
    "legal_entity[dob][year]": "Please provide a corect year",
    "legal_entity[address][city]": "Please provide a corect city based by the selectedcountry",
    "legal_entity[address][country]": "Please select a country",
    "legal_entity[address][line1]": "Please provide a corect address line 1",
    "legal_entity[address][line2]": "Please provide a corect address line 2",
    "legal_entity[address][state]": "Please provide a corect state",
    "legal_entity[address][postal_code]": "Please provide a corect postal code",
    "legal_entity[last_name]": "You can not change your last name in case your account is verified",
    "legal_entity[first_name]":
      "You can not change your first name in case your account is verified",
    "legal_entity[dob]": "You can not change your DOB in case your account is verified",
    id: "No such customer",
    exp_month: "Your card has expired.",
    external_account: "Bank account",
  },
  quotedPrice: "Quoted price",

  none: "None",

  loadingText: "Loading...",
};
