import React from "react";
import PropTypes from "prop-types";

class DragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draggingOverTarget: false,
    };
    this.handleFileDrop = this.handleFileDrop.bind(this);
  }

  handleFileDrop(files) {
    if (typeof this.props.onDragFinish === "function") {
      this.props.onDragFinish(files);
    }
  }

  onDrop(event) {
    event.preventDefault();
    this.setState({ draggingOverTarget: false });
    this.handleFileDrop(event.dataTransfer.files);
  }

  onDragOverLeave(state, event) {
    event.preventDefault();
    this.setState({ draggingOverTarget: state });
  }

  onWindowDrag(event) {
    event.preventDefault();
  }

  componentDidMount() {
    window.addEventListener("dragover", this.onWindowDrag);
    window.addEventListener("drop", this.onWindowDrag);
  }

  render() {
    let fileDropTargetClassName = "file-drop";
    fileDropTargetClassName += this.state.draggingOverTarget
      ? " file-drop-target file-drop-dragging-over-target file-drop-dragging-over-frame"
      : "";

    return (
      <div {...this.props.mapProps}>
        {this.props.children}
        <div
          className={fileDropTargetClassName}
          onDrop={this.onDrop.bind(this)}
          onDragOver={this.onDragOverLeave.bind(this, true)}
          onDragLeave={this.onDragOverLeave.bind(this, false)}
        />
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener("dragover", this.onWindowDrag);
    window.removeEventListener("drop", this.onWindowDrag);
  }
}

DragAndDrop.propTypes = {
  onDragFinish: PropTypes.func.isRequired,
  mapProps: PropTypes.object,
};

export { DragAndDrop };
