export const mock = {
  bundles: [
    {
      title: "Commission",
      bg: "#F8F8F8",
    },
    {
      title: "Lite",
      bg: "#dde8ff",
    },
    {
      title: "Essentials",
      bg: "#FDEDEC",
    },
    {
      title: "Pro",
      bg: "#DFE2E8",
    },
    {
      title: "Unlimited",
      bg: "#FFF7E6",
    },
  ],
  pricing: {
    list: {
      options: [
        {
          title: "Subscription Cost",
          key: "subscription_cost",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: "£49",
            },
            {
              Essentials: "£199",
            },
            {
              Pro: "£499",
            },
            {
              Unlimited: "£799",
            },
          ],
        },
        {
          title: "Annual Bookings",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: 3,
            },
            {
              Essentials: 12,
            },
            {
              Pro: 36,
            },
            {
              Unlimited: "Unlimited",
            },
          ],
        },
        {
          title: "Annual Requests",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: 15,
            },
            {
              Essentials: 60,
            },
            {
              Pro: 180,
            },
            {
              Unlimited: "Unlimited",
            },
          ],
        },
        {
          title: "Get Heli Booking Fee",
          values: [
            {
              Commission: "Up to 10%",
            },
            {
              Lite: "0%",
            },
            {
              Essentials: "0%",
            },
            {
              Pro: "0%",
            },
            {
              Unlimited: "0%",
            },
          ],
        },
        {
          title: "Coverage",
          values: [
            {
              Commission: "Europe",
            },
            {
              Lite: "Europe",
            },
            {
              Essentials: "Europe",
            },
            {
              Pro: "Europe",
            },
            {
              Unlimited: "Europe",
            },
          ],
        },
        {
          title: "Operator Details Displayed",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: 1,
            },
            {
              Essentials: 1,
            },
            {
              Pro: 1,
            },
            {
              Unlimited: 1,
            },
          ],
        },
        {
          title: "Bank Transfer Payments",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: 1,
            },
            {
              Essentials: 1,
            },
            {
              Pro: 1,
            },
            {
              Unlimited: 1,
            },
          ],
        },
        {
          title: "Card Payments",
          stripe: true,
          values: [
            {
              Commission: 1,
            },
            {
              Lite: 1,
            },
            {
              Essentials: 1,
            },
            {
              Pro: 1,
            },
            {
              Unlimited: 1,
            },
          ],
        },
        {
          title: "Preferred Broker",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: 0,
            },
            {
              Essentials: 0,
            },
            {
              Pro: 0,
            },
            {
              Unlimited: 1,
            },
          ],
        },
      ],
      main: [
        {
          title: "Extra User(s)",
          type: "extra_user",
          values: [
            {
              Commission: 0,
            },
            {
              Lite: 0,
            },
            {
              Essentials: "£39",
            },
            {
              Pro: "£39",
            },
            {
              Unlimited: "£39",
            },
          ],
        },
      ],
    },
  },
  features: {
    list: [
      {
        options: [
          {
            title: "Real-time marketplace",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Curated & moderated platform",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Real-time dashboard",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Real-time notifications and emails",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Compatible with mobile devices",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Bespoke request & quoting system",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Empty leg marketplace",
            items: { Commission: 1, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "iFrame",
            tooltip: "iFrameWidgetPricing",
            items: { Commission: 0, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Widget",
            tooltip: "widgetPricingTooltip",
            items: { Commission: 0, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "API",
            tooltip: "additionalChargePricing",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
        ],
      },
      {
        options: [
          {
            title: "Multi-leg/aircraft",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Precise location inputs (incl. long/lat)",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "One-to-many request system",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Relevant operators receive request",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Detailed quote breakdown",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Live chat system with operators pre/post-booking",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Add attachments to live chat",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Rich content map (incl. events, hotels, restaurants with helipads)",
            items: { Commission: 1, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Instant estimated travel times before submitting requests to Operators",
            items: { Commission: 1, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Instant price estimates before submitting requests to Operators",
            items: { Commission: 1, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "List of high-end events accessible by helicopter on API",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Advanced quoting progress bar",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
        ],
      },
      {
        options: [
          {
            title: "Details of landing fees",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Downloadable quotes (PDF)",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Aircraft YOM and refurbishment dates",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Operator details displayed on quotes",
            items: { Commission: 0, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Pictures of specific aircraft on quotes",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Broker's commission added to quotes PDF",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Ready-to-send PDF quotes with Broker's brand",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Downloadable Operator's AOC documents pre-booking",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Downloadable helicopter insurance certificate pre-booking",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
        ],
      },
      {
        options: [
          {
            title: "Standardised flight terms for selected countries",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "e-Signature of flight contract",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Operator code of conduct",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Transaction audit trail",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
        ],
      },
      {
        options: [
          {
            title: "Downloadable Operator invoice",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Payment transaction history",
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "PCI DSS-compliant card payments",
            stripe: true,
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Non-European payment cards accepted",
            sup: 3,
            items: { Commission: 1, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Bank transfer payments",
            items: { Commission: 0, Lite: 1, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Store payment cards",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Store multiple cards securely",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
        ],
      },
      {
        sup: 4,
        subInfo:
          "This includes all countries excluded from the list of European countries defined by Stripe. Please visit: https://stripe.com/docs/currencies#european-credit-cards",
        options: [],
      },
      {
        options: [
          {
            title: "Request additional bespoke features",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
        ],
      },
      {
        title: "Upcoming Features",
        options: [
          {
            title: "Details of repositioning costs",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Online chat system with Get Heli Team",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Internal sales leads management system",
            items: { Commission: 0, Lite: 0, Essentials: 0, Pro: 1, Unlimited: 1 },
          },
          {
            title: "Empty legs marketplace available through API",
            items: { Commission: 0, Lite: 0, Essentials: 1, Pro: 1, Unlimited: 1 },
          },
        ],
      },
    ],
  },
};
