import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ModalComponent } from "../../../shared";
import { Prompt } from "react-router-dom";
import {
  getQuoteById,
  sendDeclineReasons,
  setDefaultRequestsData,
  startQuote,
  toggleRequestFlagStatus,
  updateEditedQuote,
} from "../../../actions";
import {
  ReviewAdditionalInfoComponent,
  ReviewLegsSummaryComponent,
  ReviewPreferenceComponent,
} from "../user-online/broker/review";
import AccordionComponent from "../../common/AccordionComponent";
import LocationInfo from "../../common/LocationInfo";
import QuoteDepartureInfo from "../../common/QuoteDepartureInfo";
import QuotePassengersInfo from "../../common/QuotePassengersInfo";
import staticService from "../../../services/static.service";
import RadioButtonComponent from "../../common/RadioButtonComponent";
import auth from "../../../services/auth";

import "./requestDetails.css";
import RequestDetailsFooter from "./components/RequestDetailsFooter/RequestDetailsFooter";
import ReviewGeneralInformationComponent from "../user-online/broker/review/review-general-information.component";

const RequestDetails = (props) => {
  const {
    history,
    editingQuote,
    originalQuote,
    originalQuote: { legs, flight_type },
    flightPurposes,
    screenDimensions,
    reasons,
    sendDeclineReasons,
    setQuoteDetailNew,
  } = props;

  const declineQuoteModal = useRef(null);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [reasonsList, setReasonsList] = useState([]);
  const [showPrompt, setShowPrompt] = useState(true);

  useEffect(() => {
    if (!editingQuote) return;

    const {
      is_declined,
      is_flag_set,
      is_need_more_information_set,
      is_quote_uncertain_set,
      quotes_per_company,
      empty_leg_id,
      is_quote_started,
      quote,
    } = editingQuote;

    if (
      !is_declined &&
      !is_flag_set &&
      !is_need_more_information_set &&
      !is_quote_uncertain_set &&
      !quotes_per_company &&
      !empty_leg_id &&
      !quote &&
      !is_quote_started &&
      auth.isVerified()
    ) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  }, [editingQuote]);

  useEffect(() => {
    if (!reasons) return;
    const res = reasons.map((item) => ({ value: item, label: item }));
    setReasonsList(res);
  }, [reasons]);

  const handleDeclineRequest = () => {
    setShowPrompt(false);
    sendDeclineReasons(originalQuote.id, {
      reason: selectedReason === "Other info" ? otherReason : selectedReason,
    }).then(() => {
      history.push("/marketplace");
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!legs) return <div>Loading...</div>;

  // const legAddresses = legs && legs.filter((i) => i.from && i.from_coords && i.to && i.to_coords);

  const legAddresses = useMemo(() => {
    const addresses = [];

    if (legs && legs.length > 0) {
      legs
        .filter((leg) => leg.from && leg.from_coords && leg.to && leg.to_coords)
        .forEach((leg) => {
          addresses.push(leg);
        });
    }

    if (+originalQuote?.booking_request?.flight_type === 2) {
      return [addresses[1], addresses[0]];
    }

    return addresses;
  }, [legs, originalQuote]);

  return (
    <div className="gh-request-details">
      <Prompt when={showPrompt} message={staticService.findByAlias("quotePromptModalText")} />

      <ReviewGeneralInformationComponent
        requestForm={originalQuote}
        purposes={flightPurposes}
        brokerCompany={originalQuote.broker_company}
      />

      <AccordionComponent items={legs} isOpen={true} flightType={flight_type}>
        {legs.map((item, index) => (
          <div key={index}>
            {/*LOCATION*/}
            <div className="gh-legs-location-wrapper">
              <div className="gh-legs-location-wrapper-item">
                <LocationInfo
                  leg={item}
                  legIcon={item.from_source}
                  keyDirection="from"
                  direction="from_location"
                  additionalDetails={item.from_location.details}
                />
              </div>
              <div className="gh-legs-location-wrapper-item">
                <LocationInfo
                  leg={item}
                  legIcon={item.to_source}
                  keyDirection="to"
                  direction="to_location"
                  additionalDetails={item.to_location.details}
                />
              </div>
            </div>

            {/*DEPARTURE INFO*/}
            <div className="gh-separator-line" />
            <QuoteDepartureInfo
              editingQuote={originalQuote}
              leg={item}
              legIndex={index}
              readOnly={true}
            />

            {/*PASSENGERS INFO*/}
            <div className="gh-separator-line" />
            <QuotePassengersInfo
              leg={item}
              legIndex={index}
              {...props}
              readOnly={true}
              hideCheckbox={true}
            />
          </div>
        ))}
      </AccordionComponent>

      {/*<ReviewLegsSummaryComponent {...props} />*/}
      {legAddresses ? (
        <ReviewLegsSummaryComponent
          // handleAssignPad={handleAssignPad}
          screenDimensions={screenDimensions}
          requestForm={originalQuote}
          legAddresses={legAddresses}
          review={true}
          returnFlight={flight_type === "2"}
        />
      ) : (
        ""
      )}

      <ReviewPreferenceComponent requestForm={originalQuote} />
      <ReviewAdditionalInfoComponent requestForm={originalQuote} />

      <RequestDetailsFooter
        setShowPrompt={setShowPrompt}
        history={history}
        setQuoteDetailNew={setQuoteDetailNew}
      />

      <ModalComponent
        title={staticService.findByAlias("operatorDeclineModalTitle")}
        ref={declineQuoteModal}
        id={"declineRequest"}
        modalClass="gh-decline-modal"
      >
        <div>
          <RadioButtonComponent
            selected={selectedReason}
            items={[...reasonsList, { label: "Other info", value: "Other info" }]}
            handleChange={(reason) => {
              setSelectedReason(reason);
            }}
            id="included-fees"
          />
          {selectedReason === "Other info" ? (
            <div>
              <textarea
                value={otherReason}
                className="uk-textarea other-textarea"
                placeholder="Insert other info"
                minLength={4}
                onChange={(e) => setOtherReason(e.target.value)}
              />
              {otherReason.length > 0 && otherReason.length < 4 ? (
                <div className="gh-message-error">Must be 4 or more characters!</div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
            <button
              type="button"
              className="uk-button uk-button-primary gh-request-gen-btns"
              onClick={handleDeclineRequest}
              disabled={
                selectedReason === "" || (selectedReason === "Other info" && otherReason.length < 4)
              }
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

RequestDetails.propTypes = {
  history: PropTypes.object,
  editingQuote: PropTypes.object,
  originalQuote: PropTypes.object,
  flightPurposes: PropTypes.array,
  screenDimensions: PropTypes.object,
  reasons: PropTypes.array,
  sendDeclineReasons: PropTypes.func,
  startQuote: PropTypes.func,
  toggleRequestFlagStatus: PropTypes.func,
  getQuoteById: PropTypes.func,
  updateEditedQuote: PropTypes.func,
  setDefaultRequestsData: PropTypes.func,
  defaultRequests: PropTypes.object,
  setQuoteDetailNew: PropTypes.func,
};

const mapStateToProps = (store) => ({
  screenDimensions: store.screenDimensions,
  reasons: store.requests.reasons,
  defaultRequests: store.defaultRequests,
});

export default connect(mapStateToProps, {
  sendDeclineReasons,
  startQuote,
  toggleRequestFlagStatus,
  getQuoteById,
  updateEditedQuote,
  setDefaultRequestsData,
})(RequestDetails);
