import React from "react";
import PropTypes from "prop-types";
import { Control, Fieldset, Errors } from "react-redux-form";
import { validationRules, validationMessages } from "../../../configs";
import {
  CustomValidateError,
  updateModel,
  GridComponent,
  prependHttpWithModelUpdate,
} from "../../../shared";
import staticService from "../../../services/static.service";
import { TermsConditionsComponent } from "../../common";
import PhoneInput from "../../common/phoneInput";

const {
  required,
  requiredObject,
  signupValidUrl,
  max,
  aocValidation,
  alphaNumValidation,
  alphaNumStrictValidation,
  phoneInputValidation,
} = validationRules;

const OperatorCompanyFieldset = ({
  company,
  changeIcon,
  changeIconClass,
  termsAction,
  termsConditionsClass,
  countriesList,
  currenciesList,
  changeCountry,
}) => {
  return (
    <Fieldset model={"userRegisterModel"}>
      <GridComponent
        visible={true}
        attrProps={{
          id: "row-0",
          className: "uk-margin-small-bottom uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className="uk-form-label required-contact uk-width-expand"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("companyCountry"),
              }}
            />
            <div className="uk-width-auto">
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "countrySelectTooltip",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.select
            className="uk-select"
            model=".company.country_code"
            changeAction={changeCountry}
            updateOn="change"
            validators={{ required, alphaNumValidation }}
          >
            <option value={""} key="optional">
              Select country
            </option>
            {countriesList.countries &&
              countriesList.countries.map((country, index) => (
                <option key={country.name + index} value={country.code}>
                  {country.name}
                </option>
              ))}
          </Control.select>

          <Errors
            model=".company.country_code"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyCountry"),
              ),
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
            }}
          />
        </div>
        <div className="validation-wrapper">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className="uk-form-label required-contact uk-width-expand"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("companyCurrency"),
              }}
            />
            <div className="uk-width-auto">
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "countrySelectTooltip",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.select
            className="uk-select"
            model=".company.currency"
            changeAction={updateModel}
            updateOn="change"
            validators={{ required, alphaNumStrictValidation }}
          >
            <option value={""} key="optional">
              Select currency
            </option>
            {currenciesList &&
              currenciesList.map((currency, index) => (
                <option key={currency + index} value={currency}>
                  {currency}
                </option>
              ))}
          </Control.select>
          <Errors
            model=".company.currency"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyCurrency"),
              ),
              alphaNumStrictValidation: validationMessages().alphaNumStrictValidationMessage,
            }}
          />
        </div>
      </GridComponent>
      <GridComponent
        visible={true}
        attrProps={{
          id: "row-1",
          className: "uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper">
          <label
            className="required-contact uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyName"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".company.name"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ required, alphaNumValidation }}
          />

          <Errors
            model={".company.name"}
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyName"),
              ),
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
            }}
          />
        </div>
        <div className={"validation-wrapper  "}>
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className="uk-form-label uk-width-expand"
              style={{ marginTop: 9 }}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("companyNumber"),
              }}
            />
          </div>
          <Control.text
            className={"uk-input"}
            model={".company.number"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ alphaNumStrictValidation, max: max(14) }}
          />
          <Errors
            model=".company.number"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              alphaNumStrictValidation: validationMessages().alphaNumStrictValidationMessage,
              max: validationMessages().maxMessage(14),
            }}
          />
        </div>
      </GridComponent>
      <GridComponent
        visible={true}
        attrProps={{
          id: "row-2",
          className: "uk-margin-medium-bottom uk-child-width-expand@s",
        }}
      >
        <div className={"validation-wrapper  "}>
          <label
            className={"uk-form-label  required-contact"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyPhoneNumber"),
            }}
          />
          <Control.text
            model={".company.phone"}
            changeAction={updateModel}
            placeholder={"+1 213 373 4253"}
            validators={{ required: requiredObject("value", true), phoneInputValidation }}
            selectedCountry={company.country_code}
            component={PhoneInput}
          />
          <Errors
            model=".company.phone"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyPhoneNumber"),
              ),
              phoneInputValidation: validationMessages().phoneValidationMessage,
            }}
          />
        </div>
        <div className="validation-wrapper  ">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className={"required-contact uk-form-label uk-width-expand"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("directPhoneNumber"),
              }}
            />
            <div className={"uk-width-auto"}>
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "directPhoneTooltipMessage",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.text
            model={".phone"}
            placeholder={"+1 213 373 4253"}
            changeAction={updateModel}
            validators={{ required: requiredObject("value", true), phoneInputValidation }}
            selectedCountry={company.country_code}
            component={PhoneInput}
          />
          <Errors
            model=".phone"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("directPhoneNumber"),
              ),
              phoneInputValidation: validationMessages().phoneValidationMessage,
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-3",
          className: "uk-margin-medium-bottom uk-child-width-expand@s",
        }}
      >
        <div className={"validation-wrapper"}>
          <div className="uk-width-1-1">
            <div data-uk-grid className="uk-flex uk-flex-bottom">
              <div className={"uk-width-expand"}>
                <label
                  className={"uk-form-label required-contact"}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("companyAocNumber"),
                  }}
                />
              </div>
            </div>
            <Control.text
              className="uk-input"
              model={".company.aoc_number"}
              changeAction={updateModel}
              updateOn="change"
              validators={{ required, aocValidation, max: max(20) }}
            />
            <Errors
              model=".company.aoc_number"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("companyAocNumber"),
                ),
                aocValidation: staticService.findByAlias("aocAlphanumeric"),
                max: validationMessages().maxMessage(20),
              }}
            />
          </div>
        </div>

        <div className="validation-wrapper">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className="uk-form-label uk-width-expand"
              style={{ marginTop: 4 }}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("sharedAOC"),
              }}
            />
          </div>
          <Control.text
            className={"uk-input"}
            model={".company.aoc_company_name"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ alphaNumValidation }}
          />
          <Errors
            model=".company.aoc_company_name"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-4",
          className: "uk-margin-medium-bottom uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper gh-padding-additional-top">
          <label
            className="uk-form-label"
            style={{ marginTop: -1 }}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("website"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".company.website"}
            mapProps={{ value: company.website }}
            changeAction={prependHttpWithModelUpdate}
            validators={{ signupValidUrl }}
          />
          <Errors
            model=".company.website"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              signupValidUrl: validationMessages().validUrlMessage,
            }}
          />
        </div>

        <div className="validation-wrapper  ">
          <div className="uk-padding-remove">
            <TermsConditionsComponent
              changeIcon={changeIcon}
              changeIconClass={changeIconClass}
              action={termsAction}
              termsConditionsClass={termsConditionsClass}
              condition={true}
              operator={true}
            />
          </div>
        </div>
      </GridComponent>
    </Fieldset>
  );
};

OperatorCompanyFieldset.propTypes = {
  company: PropTypes.object,
  changeIcon: PropTypes.string,
  changeIconClass: PropTypes.string,
  termsAction: PropTypes.func,
  termsConditionsClass: PropTypes.string,
  countriesList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currenciesList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  changeCountry: PropTypes.func,
};

export { OperatorCompanyFieldset };
