import React, { useState } from "react";
import PropTypes from "prop-types";
import leftArrow from "../../../../assets/img/arrow-left.svg";
import { NavLink } from "react-router-dom";
import uid from "../../../../utils/uid";

const SubMenuDestinationItem = (props) => {
  const { item } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleItemMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <li className={`header-desktop-menu-item ${isOpen ? "--open" : ""}`} key={uid().uid}>
      <span className="link" onClick={toggleItemMenu}>
        {item.name}
        <img src={leftArrow} alt="down" className="gh-dropdown-arrow" />
      </span>

      <ul className="gh-subnav-menu">
        {item?.types?.map((type) => (
          <React.Fragment key={uid().uid}>
            <span className="link" style={{ fontWeight: 400 }}>
              {type.label}
            </span>

            {type.locations.map((location) => (
              <li className="header-desktop-menu-item" key={uid().uid}>
                {location.url ? (
                  <a className="link" href={`/destinations/${location.url}`}>
                    {location.location_name}
                  </a>
                ) : (
                  <NavLink
                    className="link"
                    to={`/destinations?country=${location.country_code}&location_name=${location.location_name}&flight_type=${type.type}`}
                  >
                    {location.location_name}
                  </NavLink>
                )}
              </li>
            ))}
          </React.Fragment>
        ))}
      </ul>
    </li>
  );
};

SubMenuDestinationItem.propTypes = {
  item: PropTypes.object,
};

export default SubMenuDestinationItem;
