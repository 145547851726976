import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { loadTermCondition, createAccountByInvitation, download } from "../../../actions";
import { UserAccountFieldset } from "../fieldsets";
import { validationMessages, tabs, config, OPERATOR_MAIN } from "../../../configs";
import { CustomValidateError, replaceAttributesInString } from "../../../shared";
import { TacComponent } from "../tac.component";
import { CLEAR_ERRORS } from "../../../actions";
import { BaseRegisterFormTemplate } from "./base-form.component";
import { BlockComponent, TermsConditionsComponent } from "../../common";
import staticService from "../../../services/static.service";

class RegisterByInvitationFormComponent extends BaseRegisterFormTemplate {
  constructor(props) {
    super(props);
    this.state = {
      termsConditions: "uk-width-auto uk-padding-remove",
      iconClass: "uk-margin-small-right",
      modalVisibility: false,
      icon: "copy",
      currentTab: tabs[0],
      showLoader: false,
      redirectPath: config.redirectUserDashboard,
      modelName: "userRegisterModel",
    };
    this.changeModalVisibility = this.changeModalVisibility.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    this.context.store.dispatch(loadTermCondition("BROKER_AGREEMENT,OPERATOR_AGREEMENT"));
  }

  changeModalVisibility(state) {
    this.setState({ modalVisibility: state });
  }

  openModal(ev) {
    ev.preventDefault();
    this.changeModalVisibility(true);
  }

  onChangeCondition(state) {
    if (state === 0) {
      this.setState({
        modalVisibility: false,
        icon: "copy",
        iconClass: "uk-margin-small-right",
        termsConditions: "uk-width-auto uk-padding-remove",
      });
      this.context.store.dispatch(actions.change("userRegisterModel.tac_accepted", false));
    } else {
      this.setState({
        modalVisibility: false,
        icon: "check",
        iconClass: "uk-margin-small-right uk-icon-button",
        termsConditions: "gh-button-terms gh-button-terms-text",
      });
      this.context.store.dispatch(actions.change("userRegisterModel.tac_accepted", true));
    }
  }

  get modal() {
    const alias = this.props.invitation.user.inviter_type.toUpperCase() + "_AGREEMENT";
    return (
      <TacComponent
        html={this.agreement}
        visible={this.state.modalVisibility}
        agreeCallback={this.onChangeCondition}
        downloadCallback={this.props.download.bind(this, alias)}
      />
    );
  }

  onSubmit(user) {
    this.setState({ showLoader: true });
    this.context.store.dispatch({ type: CLEAR_ERRORS });
    this.context.store.dispatch(
      createAccountByInvitation(user, this.props.invitationHash, this.props.onSuccess),
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ showLoader: false });
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.context.store.dispatch(actions.setPending(this.state.modelName, false));
    }

    if (nextProps.invitation.invitationValidity) {
      this.context.store.dispatch(
        actions.change(this.state.modelName + ".email", nextProps.invitation.user.inviter_email),
      );
    }
  }

  get formContent() {
    if (!this.state.showLoader) {
      return (
        <div className={"gh-sign-by-invitation uk-padding uk-position-relative "}>
          {/*<div className={"Rectangle-6"}></div>*/}
          {this.modal}
          <div className={"uk-margin-small-bottom"}>
            {this.User}
            {replaceAttributesInString(
              {
                attribute: this.props.invitation.user.inviter_type || "Operator",
                company: this.props.invitation.user.company || "Helicopter",
              },
              staticService.findByAlias("accountByInvitation"),
            )}
          </div>
          <Form
            model={this.state.modelName}
            onSubmit={this.onSubmit}
            validators={{
              tac_accepted: (val) => val > 0,
            }}
          >
            <UserAccountFieldset
              showEmailsInputs={false}
              userModel={this.props.userRegisterModel}
            />
            <Errors
              model={this.state.modelName}
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                passwordsMatch: validationMessages().passwordsMatchOldMessage,
              }}
            />
            <TermsConditionsComponent
              changeIcon={this.state.icon}
              changeIconClass={this.state.iconClass}
              action={this.openModal}
              termsConditionsClass={this.state.termsConditions}
            />

            <div className="uk-margin-medium-top">
              <Control.button
                model={this.state.modelName}
                disabled={{ valid: false }}
                className={"uk-button uk-button-primary uk-align-center"}
              >
                {staticService.findByAlias("createAccount")}
              </Control.button>
            </div>
          </Form>
        </div>
      );
    }
    return null;
  }

  get agreement() {
    const alias = this.props.invitation.user.inviter_type.toUpperCase() + "_AGREEMENT";
    const conditions = this.props.conditions.filter((condition) => condition.type === alias);
    return conditions.length > 0 ? conditions[0].description : "";
  }

  get User() {
    if (this.props.invitation.user && this.props.invitation.user.inviter_name) {
      return <span>{this.props.invitation.user.inviter_name} </span>;
    }
    return null;
  }

  get subTitle() {
    return null;
  }

  get mainTitle() {
    return (
      <BlockComponent
        visible={true}
        containerClass={"uk-container uk-container-affiliate"}
        parentProps={{
          className:
            "uk-section uk-padding-remove-bottom uk-padding-remove-top uk-margin-medium-top",
        }}
      >
        <h3
          className="uk-margin-medium-bottom"
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("registerSignUp"),
          }}
        />
      </BlockComponent>
    );
  }
}

RegisterByInvitationFormComponent.propTypes = {
  visible: PropTypes.bool,
  invitationHash: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

RegisterByInvitationFormComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    conditions: state.termsCondition,
    errors: state.errorObj,
    invitation: state.auth.invitation ? state.auth.invitation : {},
    userRegisterModel: state.userRegisterModel,
  };
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      download,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RegisterByInvitationFormComponent);
export { COMPONENT as RegisterByInvitationFormComponent };
