import handlerError from "./error";
import Api from "../services/api-handler";
import * as types from "./actionTypes";
import { staticText } from "../configs/";
import Auth from "../services/auth";
import { StripeService } from "../services";
import { alertInstance } from "../shared";

const entity = "payment";

const stripePlaceholders = {
  id: "No such customer",
};

export function paymentOnSuccess(response) {
  return { type: types.ADD_NEW_PAYMENT, response };
}

export function paymentDetailsSuccess(response) {
  return { type: types.PAYMENT_DETAILS, response };
}

export function GetCardsSuccess(response) {
  return { type: types.CARDS_LOADED, response };
}

export function cardDeletedSuccess(response) {
  return { type: types.DELETE_CARD, response };
}

export function getCards(onSuccess, { params = {} }) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("showSpinner", false)
      .setPath("user", "account")
      .setQueryParams(
        { ...params, place: window.location.href.includes("payment") ? "quote" : "account" },
        true,
      )
      .get(Auth.getUser().id)
      .then((account) => {
        dispatch(GetCardsSuccess(account));
        Api.setProperty("skipErrorAlert", true).setProperty("showSpinner", true);
        if (onSuccess) onSuccess(account);
        return account;
      });
  };
}

export function doPayment(paymentData, onSuccess, onError) {
  return function (dispatch) {
    return Api.setPath(entity)
      .setProperty("responseMessages", staticText.globalMessages.payment)
      .setProperty("messagePlaceholders", stripePlaceholders)
      .setProperty("skipErrorAlert", true)
      .setProperty("alertModal", true)
      .post(paymentData)
      .then((response) => {
        onSuccess(response);
        Api.setProperty("skipErrorAlert", false).setProperty("alertModal", false);
        return dispatch(paymentOnSuccess(response));
      })
      .catch((err) => {
        alertInstance.eventInstance.trigger("global", {
          visible: true,
          message: err.data.message,
          type: alertInstance.errorProp,
        });
        if (err.data.error.requires_action) {
          let stripeService = new StripeService(Stripe);
          stripeService.stripe
            .handleCardAction(err.data.error.payment_intent_client_secret)
            .then((result) => {
              if (result.error) {
                onError(result.error.message);
                return handlerError(result.error, dispatch);
              } else {
                paymentData.payment_intent_id = result.paymentIntent.id;
                Api.setPath(entity + "?XDEBUG_SESSION_START=19537")
                  .setProperty("responseMessages", staticText.globalMessages.payment)
                  .setProperty("messagePlaceholders", stripePlaceholders)
                  .setProperty("skipErrorAlert", true)
                  .setProperty("alertModal", true)
                  .post(paymentData)
                  .then((response) => {
                    onSuccess(response);
                    Api.setProperty("alertModal", false).setProperty("skipErrorAlert", false);
                    return dispatch(paymentOnSuccess(response));
                  })
                  .catch((err) => {
                    onError(err.data.error.message);
                    return handlerError(err.data.error, dispatch);
                  });
              }
            });
        } else {
          Api.triggerError(err);
          Api.setProperty("alertModal", false).setProperty("skipErrorAlert", false);
          return handlerError(err, dispatch);
        }
      });
  };
}

export function getPaymentQuoteDetails(quoteId, cardCountry) {
  return function (dispatch) {
    return Api.setPath("quote", "payment/details")
      .setProperty("skipErrorAlert", true)
      .post({ quoteId: quoteId, cardCountry: cardCountry })
      .then((details) => {
        Api.setProperty("skipErrorAlert", true);
        dispatch(paymentDetailsSuccess(details));
        return Promise.resolve(details);
      })
      .catch((err) => {
        Api.triggerError(err);
        Api.setProperty("skipErrorAlert", false);
        return handlerError(err, dispatch);
      });
  };
}

export const confirmManualBankTransfer = (bookingID) => (dispatch) => {
  return Api.setPath("confirm-payment", bookingID)
    .post(null)
    .then((response) => response)
    .catch((error) => error);
};
