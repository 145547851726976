import React from "react";
import staticService from "../../../../../services/static.service";
import Upload from "../../../../Form/components/Upload";

const ReviewAdditionalInfoComponent = ({ requestForm }) => {
  return (
    <React.Fragment>
      <div className="gh-simple-section review-additional">
        <h4
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("additionalInformationLabel"),
          }}
        />
        <div data-uk-grid>
          <div className="uk-width-1-1">
            <div className="gh-section">
              <div className="gh-info-text">
                {requestForm.other_info ? (
                  <span dangerouslySetInnerHTML={{ __html: requestForm.other_info }} />
                ) : (
                  "---"
                )}
              </div>
            </div>
          </div>
        </div>
        {!!requestForm.other_info_attachments?.length && (
          <Upload
            className="uk-margin-top"
            readOnly
            onChange={() => null}
            defaultFileList={requestForm.other_info_attachments}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export { ReviewAdditionalInfoComponent };
