import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form } from "react-redux-form";
import {
  loadTermCondition,
  updateBankAccount,
  getAccountDetailsSuccess,
  download,
} from "../../../actions";
import { StripeService } from "../../../services";
import { BankAccountFieldset } from "../fieldsets";
import { spinnerInstance } from "../../../shared";
import { TermsConditionsComponent } from "../../common";
import { TacComponent } from "../tac.component";
import { BankAccountInfoComponent, AccountStatusComponent } from "./";
import staticService from "../../../services/static.service";
import StripeLogoWhite from "../../../assets/img/svg/stripe_logo_white.svg";
import Tooltip from "../../common/Tooltip";

const modelName = "bankAccountModel";

class BankAccountFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    context.store.dispatch(actions.reset(modelName));
    this.state = {
      agreement: "",
      stripeService: new StripeService(Stripe),
      submitConfirm: false,
      termsConditions: "uk-width-expand uk-padding-remove",
      iconClass: "uk-margin-small-right",
      modalVisibility: false,
      icon: "copy",
      editState: false,
    };
    this.changeModalVisibility = this.changeModalVisibility.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.loadForm = this.loadForm.bind(this);
    this.enableEditMode = this.enableEditMode.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillMount() {
    if (this.props.agreement.length === 0) {
      this.props.loadTerm("STRIPE_AGREEMENT");
    }

    if (this.props.agreement.length > 0) {
      const _exists = this.props.agreement.filter(
        (agreement) => agreement.type === "STRIPE_AGREEMENT",
      );
      if (_exists.length === 0) this.props.loadTerm("STRIPE_AGREEMENT");
    }

    if (this.props.bankAccount !== null && this.props.bankAccount !== undefined) {
      this.setState({ editState: true });
    }
  }

  componentDidUpdate() {
    this.state.stripeService.form = document.getElementById("bank-account");
    if (this.state.stripeService.form) {
      this.state.stripeService.error = this.state.stripeService.form.querySelector(".error");
      this.state.stripeService.errorMessage =
        this.state.stripeService.error.querySelector(".message");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.bankAccount !== null &&
      nextProps.bankAccount !== undefined &&
      this.props.bankAccount !== nextProps.bankAccount
    ) {
      this.setState({ editState: true });
    }

    if (this.props.isBankAccountUpdated !== nextProps.isBankAccountUpdated) {
      this.setState({ editState: true });
    }
  }

  enableEditMode() {
    const state = !this.state.editState;
    this.setState({ editState: state });

    const bankAccountModel = {
      country: this.props.dashboard.banking.country,
      currency: this.props.dashboard.banking.currency,
      routing_number: this.props.bankAccount.routing_number,
      account_number: "",
      account_holder_name: this.props.bankAccount.account_holder,
      account_holder_type: this.props.bankAccount.account_type,
    };
    this.props.updateBankModel(bankAccountModel);
  }

  changeModalVisibility(state) {
    this.setState({ modalVisibility: state });
  }

  onSubmitSuccess(data) {
    this.props.updateAccount(data);
  }

  onSubmit() {
    const $this = this;
    spinnerInstance.setProp("type", "large");
    spinnerInstance.show();
    const { banking } = this.props.dashboard;
    const bankAccountData = {
      ...this.props.bankAccountModel.bank_account,
      currency: banking.currency,
      country: banking.country,
    };

    if (!Object.keys(this.props.bankAccountFields).includes("routing_number")) {
      delete bankAccountData.routing_number;
    } else {
      bankAccountData.routing_number = bankAccountData.routing_number.replace(/-/g, "");
    }

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token. We can also pass
    // in the additional customer data we collected in our form.
    this.state.stripeService.stripe
      .createToken("bank_account", bankAccountData)
      .then(function (result) {
        if (result.token) {
          const request = {
            bank_account_token: result.token.id,
          };
          $this.props.updateBankAccount(request, $this.onSubmitSuccess.bind($this)).then(() => {
            $this.onChangeCondition(0);
          });
        } else {
          spinnerInstance.hide();
          $this.state.stripeService.setError($this.state.stripeService, 0, result.error);
        }
      });
  }

  onChangeCondition(state) {
    const isAccepted = this.state.modalVisibility;
    this.setState({
      // modalVisibility: !isAccepted,
      modalVisibility: false,
      icon: !state ? "copy" : "check",
      iconClass: !state ? "uk-margin-small-right" : "uk-margin-small-right uk-icon-button",
      termsConditions: !state
        ? "uk-width-auto uk-padding-remove"
        : "gh-button-terms gh-button-terms-text",
    });
    this.context.store.dispatch(actions.change("bankAccountModel.agreement", state));
  }

  onCancel() {
    this.setState({ editState: true });
    this.onChangeCondition(0);
  }

  get Agreement() {
    return this.props.agreement && this.props.agreement.length
      ? this.props.agreement[0].description
      : "";
  }

  get agreementAlias() {
    return "STRIPE_AGREEMENT";
  }

  loadForm() {
    return (
      <div className="uk-align-center">
        <TacComponent
          html={this.Agreement}
          agreeCallback={this.onChangeCondition}
          visible={this.state.modalVisibility}
          downloadCallback={this.props.download.bind(this, this.agreementAlias)}
        />

        <Form
          model={modelName}
          id={"bank-account"}
          onSubmit={this.onSubmit.bind(this)}
          validators={{
            agreement: (val) => val,
          }}
        >
          <BankAccountFieldset
            modelName=".bank_account"
            dynamicFields={this.props.accountStatus.bank_account_fields}
          />
          <div className="error" role="alert">
            <span className="message" />
          </div>

          <TermsConditionsComponent
            changeIcon={this.state.icon}
            changeIconClass={this.state.iconClass}
            agreementSlug={"stripeAgreementBtn"}
            helperSlug={"stripeTermsConditions"}
            action={this.changeModalVisibility.bind(this, true)}
            termsConditionsClass={this.state.termsConditions}
            bankClass="gh-button-terms-bank"
          />
          <div className="gh-terms-btns-bank">
            {this.props.accountStatus.bank_account_details ? (
              <button
                type="button"
                // onClick={() => this.setState({ editState: true })}
                onClick={this.onCancel}
                className="uk-button uk-button-default"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("cancel"),
                  }}
                />
              </button>
            ) : (
              ""
            )}
            <Control.button
              model={modelName}
              disabled={{ valid: false }}
              className="uk-button uk-button-primary "
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("updateStripeAccount"),
                }}
              />
            </Control.button>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    const { visible, accountDetail, accountStatus, bankAccount, bankAccountFields } = this.props;
    if (visible) {
      if (accountDetail) {
        return (
          <div>
            {accountStatus &&
            accountStatus.account_details_message !== "operatorAccountFailVerification" ? (
              <AccountStatusComponent accountStatus={accountStatus} /> && this.state.editState ? (
                <BankAccountInfoComponent
                  accountInfo={bankAccount}
                  bankAccountFields={bankAccountFields}
                  changeAccount={this.enableEditMode}
                />
              ) : (
                this.loadForm()
              )
            ) : (
              <div
                className={"uk-alert uk-text-center uk-alert-warning gh-alert-without-bg"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("bankAccountDetailAlert"),
                }}
              />
            )}
            <div className="just-accordion_footer">
              <span>
                <span>Powered by</span>
                <span>
                  <img src={StripeLogoWhite} alt="Stripe" />
                </span>
              </span>
              <Tooltip
                pos="top-left"
                refEl="self"
                contentClass="stripe-tooltip-content"
                trigger={<span className="icon" uk-icon="info" />}
              >
                {staticService.findByAlias("stripeTooltipText")}
              </Tooltip>
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            className={"uk-alert uk-text-center uk-alert-warning gh-alert-without-bg"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("bankAccountDetailAlert"),
            }}
          />
          <div className="just-accordion_footer">
            <span>
              <span>Powered by</span>
              <span>
                <img src={StripeLogoWhite} alt="Stripe" />
              </span>
            </span>
            <Tooltip
              pos="top-left"
              refEl="self"
              contentClass="stripe-tooltip-content"
              trigger={<span className="icon" uk-icon="info" />}
            >
              {staticService.findByAlias("stripeTooltipText")}
            </Tooltip>
          </div>
        </div>
      );
    }
    return null;
  }
}

BankAccountFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  const response = {
    accountDetail: state.auth.first_name || state.accountUpdated.first_name,
    agreement: state.termsCondition,
    dashboard: state.dashboard,
    bankAccount: state.accountDetail.bank_account_details,
    bankAccountModel: state.bankAccountModel,
    userRegisterModel: state.userRegisterModel,
    isBankAccountUpdated: state.bankAccount.event,
    accountStatus: state.auth,
    bankAccountFields: state.auth.bank_account_fields,
  };
  if (state.bankAccount.event) {
    response.bankAccount = state.bankAccount.data.bank_account_details;
  }

  return response;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      download,
      loadTerm: loadTermCondition,
      updateBankModel: (model) => dispatch(actions.change(modelName + ".bank_account", model)),
      updateBankAccount: updateBankAccount,
      updateAccount: (data) => dispatch(getAccountDetailsSuccess(data)),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BankAccountFormComponent);
export { COMPONENT as BankAccountFormComponent };
