import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { config, BROKER_MAIN } from "../../../../../configs";
import {
  toArray,
  toUppercaseFirst,
  FilePreviewComponent,
  CustomValidateError,
  replaceAttributesInString,
  fileSize,
} from "../../../../../shared";
import { REMOVED_DOCUMENT, GET_COMPANY_DETAILS } from "../../../../../actions";
import { FileBlockComponent } from "./file-block.component";
import staticService from "../../../../../services/static.service";
import Auth from "../../../../../services/auth";

class UploadDocumentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverFiles: [],
      serverQty: 0,
      currentFile: null,
      showError: false,
      preview: false,
      previewClassname: "",
      filePReview: null,
      errorAlias: "wrongFileType",
    };
    this.uploadDocument = this.uploadDocument.bind(this);
    this.remove = this.remove.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleOnSuccessUpload = this.handleOnSuccessUpload.bind(this);
    this.handleOnSuccessRemove = this.handleOnSuccessRemove.bind(this);
  }

  handleDrop(files) {
    this.setState({ showError: false, serverErrors: [] });
    if (!this.validateFile(files)) {
      return false;
    }
    this.setState({ showError: false });
    const oldFiles = this.props.files.map((file) => file);
    const _files = oldFiles.concat(toArray(files));
    this.props.uploadedFiles(_files);
  }

  validateFile(files = []) {
    let validateStatus = true;

    for (let i = 0; i < files.length; i++) {
      if (config.allowedFiles.indexOf(files.item(i).type) === -1) {
        validateStatus = false;
        this.setState({ showError: true, errorAlias: "wrongFileType" });
        break;
      }
      if (fileSize(files.item(i).size) > config.max_file_upload) {
        validateStatus = false;
        this.setState({ showError: true, errorAlias: "maxUploadValidation" });
        break;
      }
    }
    return validateStatus;
  }

  uploadDocument(index) {}

  handleOnSuccessUpload(files) {
    this.remove(this.state.currentFile, false);
    const _files = this.state.serverFiles.slice(0);
    _files.push(files[0]);
    this.setState({ serverFiles: _files });
  }

  handleOnSuccessRemove() {
    const files = this.state.serverFiles.slice(0);
    files.splice(this.state.currentFile, 1);
  }

  remove(index, removeFromServer) {
    if (removeFromServer) {
      const file = this.props.companyDetails.documents[index];

      const _files = this.props.companyDetails.documents.filter((row) => row.id !== file.id);
      const model = { ...this.props.companyDetails };
      model.documents = _files;
      this.setState({ currentFile: index });
      const files = this.props.removedDocuments.map((id) => id);
      files.push(file.id);

      this.context.store.dispatch({ type: GET_COMPANY_DETAILS, details: model });
      this.context.store.dispatch({ type: REMOVED_DOCUMENT, data: files });
    } else {
      const files = this.props.files.filter((file, i) => index !== i);
      this.props.uploadedFiles(files);
    }
  }

  componentWillReceiveProps(nextProps) {}

  preview(file) {
    this.setState({
      preview: true,
      filePreview: file,
      previewClassname: "full shown",
    });
  }

  closePreview() {
    this.setState({
      preview: false,
      filePreview: null,
      previewClassname: "",
    });
  }

  get alias() {
    if (this.props.documents.length === 0)
      return `documentUpload${toUppercaseFirst(Auth.getMainRole())}`;

    if (this.props.documents.length > 0 && !Auth.isVerified())
      return "thankYouMessageDocumentUpload";

    return "brokerCompanyVerified";
  }

  get operatorAlias() {
    return this.props.documents.length > 1
      ? "operatorSuccessUploadCompanyDocuments"
      : `documentUpload${toUppercaseFirst(Auth.getMainRole())}`;
  }

  get css() {
    return this.props.documents.length > 0 ? "uk-alert-success" : "uk-alert-warning";
  }

  get operatorCss() {
    return this.props.documents.length > 1 ? "uk-alert-success" : "uk-alert-warning";
  }

  get operatorExplanationParagraph() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias(this.operatorAlias) }}
        className={["gh-alert-without-bg", this.operatorCss].join(" ")}
      />
    );
  }

  get brokerExplanationParagraph() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias(this.alias) }}
        className={["gh-alert-without-bg", this.css].join(" ")}
      />
    );
  }

  get formContent() {
    return (
      <div className={"uk-margin-medium-top"}>
        <h2 className={"gh-text-slim uk-margin-remove-bottom"}>Documents</h2>
        {Auth.getMainRole() === BROKER_MAIN
          ? this.brokerExplanationParagraph
          : this.operatorExplanationParagraph}
        <ul className={"disabled-list uploaded-zone uk-margin"}>
          {this.props.companyDetails.documents &&
            this.props.companyDetails.documents.map((file, index) => {
              return (
                <FileBlockComponent
                  key={index}
                  fileName={file.name}
                  index={index}
                  noPreview={false}
                  preview={this.preview.bind(this, file)}
                  uploadFile={this.uploadDocument.bind(this, index)}
                  removeFile={this.remove.bind(this, index, true)}
                  isUploaded={true}
                />
              );
            })}
          {this.props.files.map((file, index) => {
            return (
              <FileBlockComponent
                key={index + this.props.companyDetails.documents.length}
                fileName={file.name}
                isUploaded={true}
                index={index + this.props.companyDetails.documents.length}
                uploadFile={this.uploadDocument.bind(this, index)}
                removeFile={this.remove.bind(this, index, false)}
              />
            );
          })}
        </ul>
        {this.state.showError ? (
          <CustomValidateError>
            {[
              <p className={"uk-text-center"} key={0}>
                {staticService.findByAlias(this.state.errorAlias)}
              </p>,
            ]}
          </CustomValidateError>
        ) : null}
        <div className="gh-upload-btn-wrapper gh-upload-btn-relative">
          <button
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("uploadDocumentBtnLabel"),
            }}
          ></button>
          <p
            className="uk-text-small uk-margin-small-top"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("fileFormatsAllowed") }}
          ></p>
          <input
            type="file"
            name="files"
            accept=".jpeg,.pdf,.png"
            multiple={true}
            ref={(fileRef) => (this.fileRef = fileRef)}
            onChange={(ev) => this.handleDrop(this.fileRef.files)}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: replaceAttributesInString(
                { size: config.max_file_upload },
                staticService.findByAlias("maxUploadAllowed"),
              ),
            }}
          />
        </div>
        <FilePreviewComponent
          visible={this.state.preview}
          file={this.state.filePreview}
          classNames={this.state.previewClassname}
          onClose={this.closePreview.bind(this)}
        />
      </div>
    );
  }

  render() {
    return this.formContent;
  }
}

UploadDocumentComponent.propTypes = {
  visible: PropTypes.bool,
  uploadedFiles: PropTypes.func,
  files: PropTypes.array,
};

UploadDocumentComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ companyDetails, removedDocuments }) {
  return {
    companyDetails,
    removedDocuments,
    documents: companyDetails.documents || [],
  };
}

const COMPONENT = connect(mapStateToProps)(UploadDocumentComponent);
export { COMPONENT as UploadDocumentComponent };
