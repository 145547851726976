import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import "./HelicopterSuggestions.css";
import staticService from "../../../../../services/static.service";
import EventHelicopterItem from "../../../../common/eventHelicopters/components/EventHelicopterItem";
import SliderWithChildren from "../../../../common/ImageSlider/SliderWithChildren";
import InfoBox from "../../../../common/InfoBox";
import PrevNextSliderButtons from "./PrevNextSliderButtons";
import Tooltip from "../../../../common/Tooltip";

const HelicopterSuggestions = (props) => {
  const { editingQuote } = props;
  const heliOptions = editingQuote.helicopters;
  if (!heliOptions) return "";
  const isHeliExists = heliOptions.length > 0;

  const sliderRef = useRef(null);

  const handleNext = useCallback(
    () => sliderRef.current && sliderRef.current.scrollTo(sliderRef.current.getIndex() + 1),
    [sliderRef],
  );
  const handlePrev = useCallback(
    () => sliderRef.current && sliderRef.current.scrollTo(sliderRef.current.getIndex() - 1),
    [sliderRef],
  );
  return (
    <div className="gh-simple-section gh-helicopter-suggestions">
      <div className="gh-quote-price-wrapper uk-flex uk-flex-column">
        <div className="uk-flex uk-flex-between">
          <h4 className="title-item">
            {staticService.findByAlias("priceEstimateBtnLabel")}{" "}
            <Tooltip
              mobilePos="bottom-center"
              pos="bottom-right"
              wrapperClassName="heli-item-label-tooltip uk-display-inline-block"
            >
              {staticService.findByAlias("requestReviewHelicopterSuggestTooltip")}
            </Tooltip>
          </h4>
          {isHeliExists ? (
            <PrevNextSliderButtons handleNext={handleNext} handlePrev={handlePrev} />
          ) : (
            ""
          )}
        </div>
        <div className="gh-heli-suggestions-slider-wrapper">
          {isHeliExists ? (
            <SliderWithChildren
              ref={sliderRef}
              options={{ containScroll: "trimSnaps", loop: true }}
              autoPlay={true}
            >
              {heliOptions.map((heli, index) => {
                const {
                  estimated_price,
                  id,
                  model,
                  tooltip_message,
                  images,
                  max_passengers,
                  duration,
                  description,
                } = heli;
                return (
                  <EventHelicopterItem
                    estimated_price={estimated_price}
                    helicopterId={id}
                    model={model}
                    tooltipMessage={tooltip_message}
                    description={description}
                    duration={duration}
                    images={images}
                    max_passengers={max_passengers}
                    key={index}
                    hideTooltips={true}
                    estimatedPriceRangeAlias="quoteFormEstimatedPriceRange"
                    hideParenthesis={true}
                  />
                );
              })}
            </SliderWithChildren>
          ) : (
            <InfoBox alias="canNotEstimatePrice" />
          )}
        </div>
      </div>
    </div>
  );
};

HelicopterSuggestions.propTypes = {
  editingQuote: PropTypes.object,
};

export default HelicopterSuggestions;
