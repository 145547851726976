/**
 * @param {Object[]} sites
 * @param {Number} offset
 * @returns {Function}
 */
const getNearestPads =
  (sites, offset) =>
  /**
   * @param {Object} coords
   * @param {Number} coords.lat
   * @param {Number} coords.lng
   * @returns {Object[]}
   */
  (coords) =>
    sites.filter(
      ({ location: { latitude, longitude } }) =>
        latitude >= coords.lat - offset &&
        latitude <= coords.lat + offset &&
        longitude >= coords.lng - offset &&
        longitude <= coords.lng + offset,
    );

export default getNearestPads;
