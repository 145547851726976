import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";
import staticService from "../../../services/static.service";
import { dateFormat, validDatetimeFormat } from "../../../shared";
import { config, dateType, timeType } from "../../../configs";
import clockIcon from "../../../assets/img/svg/clock.svg";
import AlternativeBtn from "../AlternativeBtn";
import FlagIcon from "../FlagIcon";
import ResetBtn from "../ResetBtn";
import calendar from "../../../assets/img/svg/calendar.svg";
import clock from "../../../assets/img/svg/clock.svg";
import Tooltip from "../Tooltip";
import { useAccountLocalization } from "../../../context";
import { renderTime } from "./quoteDeparture.utils";
import Time from "../Time";

import "./quoteDepartureInfo.css";

const difference = function (a, b) {
  return Math.abs(a - b);
};

const QuoteDepartureInfo = (props) => {
  const {
    leg,
    originalLeg,
    updateLeg,
    editingQuote,
    legIndex,
    readOnly,
    updateQuoteDetail,
    originalQuote,
    departureTimeError,
    setDepartureTimeError,
  } = props;
  const accountLocalization = useAccountLocalization();

  const [isEdited, setIsEdited] = useState(false);
  const [isAlternativeTimePickerClosed, setIsAlternativeTimePickerClosed] = useState(true);

  useEffect(() => {
    if (readOnly) return;
    if (
      renderTime(leg.departure_time, { showOffset: false }) !==
      moment(originalLeg.departure_time).format("HH:mm")
    ) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [leg, originalLeg, readOnly]);

  const updateDepartureTime = (e) => {
    const currentLegHour = parseInt(moment(e).format(accountLocalization.timeFormat));
    const nextLeg = editingQuote.legs[legIndex + 1];
    const legs = editingQuote.legs;

    setDepartureTimeError(null);

    if (nextLeg && moment(leg.date) < moment(nextLeg.date)) {
      // update departure time current leg if date of nex leg is bigger
      return updateLeg("departure_time", {
        [leg.id]: moment(e).format(accountLocalization.timeFormat),
      });
    }

    if (legIndex > 0 && moment(leg.date) > moment(legs[legIndex - 1].date)) {
      // update departure time current leg if date of prev leg is smaller
      return updateLeg("departure_time", {
        [leg.id]: moment(e).format(accountLocalization.timeFormat),
      });
    }

    if (
      (nextLeg && difference(currentLegHour, parseInt(nextLeg.departure_time)) === 1) ||
      (nextLeg && currentLegHour > parseInt(nextLeg.departure_time))
    ) {
      const updatedLeg = editingQuote.legs.map((item, index) => {
        if (index < legIndex) {
          return item;
        }

        if (index === legIndex) {
          return { ...item, departure_time: moment(e).format(accountLocalization.timeFormat) };
        }

        if (index > legIndex && moment(legs[index].date).isSame(moment(legs[legIndex].date))) {
          const prevLegTime = legs[index - 1].departure_time;
          return {
            ...item,
            departure_time: moment(prevLegTime, accountLocalization.timeFormat)
              .add(3, "hours")
              .format(accountLocalization.timeFormat),
          };
        } else {
          return item;
        }
      });
      return updateQuoteDetail({ ...editingQuote, legs: updatedLeg });
    }

    updateLeg("departure_time", { [leg.id]: moment(e).format(accountLocalization.timeFormat) });
  };

  const handleResetDepartureTime = () => {
    const originalDepartTime = originalQuote.legs[legIndex].departure_time;
    const prevLeg = editingQuote.legs[legIndex - 1];

    if (legIndex === 0) {
      return updateLeg("departure_time", { [originalLeg.id]: originalLeg.departure_time });
    }

    if (
      difference(parseInt(originalDepartTime), parseInt(prevLeg.departure_time)) < 2 ||
      parseInt(originalDepartTime) < parseInt(prevLeg.departure_time)
    ) {
      setDepartureTimeError(null);
      updateLeg("departure_time", { [originalLeg.id]: originalLeg.departure_time });
    } else {
      setDepartureTimeError(null);
      updateLeg("departure_time", { [originalLeg.id]: originalLeg.departure_time });
    }
  };

  const minDepartureTime = useMemo(() => {
    const prevLeg = editingQuote.legs[legIndex - 1];
    if (prevLeg) {
      const prevDepartureTime =
        moment(prevLeg.departure_time).format("HH") !== "Invalid date"
          ? moment(prevLeg.departure_time).format("HH")
          : prevLeg.departure_time.split(":")[0];
      return parseInt(prevDepartureTime) + 2;
    } else {
      return 0;
    }
  }, [editingQuote]);

  const setIsAlternativeTimePickerClosedTrue = () => setIsAlternativeTimePickerClosed(true);
  const setIsAlternativeTimePickerClosedFalse = () => setIsAlternativeTimePickerClosed(false);

  return (
    <div className={`gh-departure-wrapper ${readOnly ? "read-only" : ""}`}>
      <div className="gh-departure-wrapper-item">
        <div className="gh-departure-info">
          <div className="gh-departure-info-line">
            <div className="gh-departure-info-line-icon">
              <img src={calendar} alt="iconKey" />
            </div>
            <div className="gh-departure-info-line-details">
              <div className="gh-departure-info-title">{staticService.findByAlias("date")}</div>
              <div className="gh-departure-info-period">
                <div className="gh-departure-info-text">
                  <Time timestamp={leg.date} />
                </div>
                <div className="gh-departure-info-secondary-text">
                  {staticService.findByAlias(dateType[Number(leg.date_type) - 1].name)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gh-departure-wrapper-item">
        <div className="gh-departure-info">
          <div className="gh-departure-info-line">
            <div className="gh-departure-info-line-icon">
              <img src={clock} alt="iconKey" />
            </div>
            <div className="gh-departure-info-line-details">
              <div className="gh-departure-info-title">
                {staticService.findByAlias("departureTime")}
              </div>
              <div className="gh-departure-info-period">
                <div className="gh-departure-info-text">{renderTime(leg.departure_time)}</div>
                <div>
                  {isEdited ? (
                    <Tooltip
                      pos={`bottom-left`}
                      mobilePos="bottom-left"
                      refEl="self"
                      sign=">"
                      wrapperClassName="uk-margin-small-left"
                      trigger={<FlagIcon />}
                    >
                      {staticService.findByAlias("modifiedFromOriginal")}
                    </Tooltip>
                  ) : (
                    <div className="gh-departure-info-secondary-text">
                      {staticService.findByAlias(timeType[Number(leg.time_type) - 1].name)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {departureTimeError && departureTimeError === legIndex ? (
            <div className="gh-time-picker-content-error" id={`departure-time-error-${legIndex}`}>
              {staticService.findByAlias("requestDepartureTimeErrorMessage")}
            </div>
          ) : (
            ""
          )}
          {isEdited && isAlternativeTimePickerClosed ? (
            <div style={{ height: 35 }}>
              <ResetBtn handleReset={handleResetDepartureTime} />
            </div>
          ) : (
            ""
          )}
        </div>
        <Datetime
          dateFormat={false}
          timeFormat={config.timeFormat}
          timeConstraints={{
            hours: {
              min: minDepartureTime,
              step: 1,
            },
            minutes: {
              step: config.minuteStep,
            },
          }}
          initialViewMode="time"
          onChange={updateDepartureTime}
          value={validDatetimeFormat(leg.departure_time, {
            dateFormat: null,
            timeFormat: config.timeFormat,
          })}
          onClose={setIsAlternativeTimePickerClosedTrue}
          onOpen={setIsAlternativeTimePickerClosedFalse}
          renderView={(viewMode, renderDefault) => (
            <div>
              <div className="gh-time-picker-content">
                <span>{renderTime(leg.departure_time)}</span>
                <img src={clockIcon} alt="icon" />
              </div>
              {renderDefault()}
            </div>
          )}
          renderInput={(props) =>
            !readOnly && !isEdited ? (
              <div {...props}>
                <AlternativeBtn />
              </div>
            ) : (
              ""
            )
          }
        />
      </div>
    </div>
  );
};

QuoteDepartureInfo.propTypes = {
  leg: PropTypes.object,
  updateLeg: PropTypes.func,
  originalLeg: PropTypes.object,
  editingQuote: PropTypes.object,
  legIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  updateQuoteDetail: PropTypes.func,
  originalQuote: PropTypes.object,
  departureTimeError: PropTypes.any,
  setDepartureTimeError: PropTypes.func,
};

export default QuoteDepartureInfo;
