import { combineReducers } from "redux";
import { createForms } from "react-redux-form";
import * as reducers from "./reducers";
import { allModels } from "../reducers";
import { LOGOUT_SUCCESS } from "../actions/actionTypes";

const appReducer = combineReducers({
  ...reducers,
  ...createForms(allModels),
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    const { home } = state;
    state = { home };
  }
  return appReducer(state, action);
};

export default rootReducer;
