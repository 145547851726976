import React, { Component } from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";

class AccordionItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: "list" + props.index,
    };
    this.currentActiveTab = this.currentActiveTab.bind(this);
  }

  componentDidMount() {
    UIkit.util.on(`#${this.state.itemId}`, "hide", () => {
      this.props.onAccordionHide(this.props.index);
    });

    UIkit.util.on(`#${this.state.itemId}`, "show", () => {
      this.props.onAccordionShow(this.props.index);
    });
  }

  currentActiveTab() {
    this.props.setActiveTab(parseInt(this.state.itemId.substring(4), 10));
  }

  render() {
    const { title, children, classNames, isOpen } = this.props;
    let className = {
      section: `gh-accordion-section ${isOpen ? "uk-open" : ""} ${classNames.section || ""}`,
      linkTitle: `uk-accordion-title gh-accordion-title ${classNames.linkTitle || ""}`,
      content: `uk-accordion-content uk-margin-remove ${classNames.content || ""}`,
    };

    return (
      <li className={className.section} id={this.state.itemId}>
        <a
          onClick={this.currentActiveTab}
          href="javascript: void(0)"
          className={className.linkTitle}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={className.content}>{children}</div>
      </li>
    );
  }
}

AccordionItemComponent.propTypes = {
  title: PropTypes.string.isRequired,
  classNames: PropTypes.object,
  isOpen: PropTypes.bool,
  onAccordionShow: PropTypes.func,
  onAccordionHide: PropTypes.func,
  isDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export { AccordionItemComponent };
