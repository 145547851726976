import React from "react";
import staticService from "../../../../services/static.service";

export const BrokerOperatorComments = ({ quote, comments }) => {
  return (
    <div>
      {quote && quote.padsDiffer && (
        <div className="uk-margin-medium-bottom">
          <span
            className="uk-text-danger gh-vertical-middle uk-margin-small-right"
            data-uk-icon="icon: warning"
          />
          <span
            className="font-weight-500 gh-vertical-middle"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("pads-differ") }}
          />
        </div>
      )}
      <div className="gh-operator-comments">
        <h4 className="gh-box-title">{staticService.findByAlias("operatorsComments")}</h4>
        <p className="gh-box-p">{comments}</p>
      </div>
    </div>
  );
};
