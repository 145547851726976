import React, { useEffect, useRef, useState } from "react";
import "./EventHelicopterItem.css";
import removeFractionalPart from "../../../../../utils/removeFractionalPart";
import staticService from "../../../../../services/static.service";
import { formattedTime } from "../../../../../shared";
import PropTypes from "prop-types";
import getTextWidth from "../../../../../utils/getTextWidth";
import { GhTooltip } from "../../../gh-tooltip";
import ImageSlider from "../../../ImageSlider";
import Tooltip from "../../../Tooltip";
import WarningMessage from "../../../WarningMessage";

const calculateTextWidth = getTextWidth();
const renderModel = (model, hideParenthesis) =>
  hideParenthesis ? model.replaceAll("(", "").replaceAll(")", "") : model;

const EventHelicopterItem = (props) => {
  const {
    estimated_price,
    helicopterId,
    model,
    tooltipMessage,
    images,
    max_passengers,
    duration,
    children,
    hideTooltips,
    estimatedPriceRangeAlias,
    hideParenthesis,
    typeOfTrip,
    isFromEventPage,
    description,
    warningMessages,
    warningMessage,
  } = props;
  const { max: maxP, min: minP, average: averageP } = estimated_price;

  const [averagePos, setAveragePos] = useState(null);

  const itemText = useRef();
  const rangeLine = useRef();

  const getAveragePos = (average, total, minW, maxW, averageW) => {
    if (rangeLine.current) {
      const lineWidth = rangeLine.current.offsetWidth;

      const averagePos = (average / total) * lineWidth - 15;
      const calcMin = minW + 20;
      const calcMax = lineWidth - maxW - averageW + 10;

      if (!isNaN(averagePos)) {
        if (averagePos < calcMax && averagePos > calcMin) return averagePos;
        else if (averagePos < calcMin) {
          if (calcMin < 100) return calcMin + 4;
          return calcMin;
        } else if (averagePos > calcMax) return calcMax;
      }
    }
  };
  const { minW, maxW, averageW } = {
    minW: calculateTextWidth(`£${removeFractionalPart(minP)}`, itemText.current),
    maxW: calculateTextWidth(`£${removeFractionalPart(maxP)}`, itemText.current),
    averageW: Math.max(
      calculateTextWidth(staticService.findByAlias("average"), itemText.current),
      calculateTextWidth(`£${removeFractionalPart(averageP)}`, itemText.current),
    ),
  };

  useEffect(() => {
    setAveragePos(
      getAveragePos(
        Number(averageP.replace(/,/g, "")),
        Number(maxP.replace(/,/g, "")),
        minW,
        maxW,
        averageW,
      ),
    );
  }, [rangeLine]);

  return (
    <div key={helicopterId} className="gh-heli-card gh-padding-small">
      <div className="gh-heli-card-image">
        <h3 className="gh-heli-card-title uk-margin-small-bottom">
          {renderModel(model, hideParenthesis)}
          {!hideTooltips ? (
            <GhTooltip
              tKey={`enquire-tooltip-title-${helicopterId}`}
              side="bottom-left"
              secondary="enquire-tooltip"
              message={tooltipMessage}
              adjustPos={false}
            />
          ) : (
            <Tooltip
              pos="bottom-left"
              refEl="self"
              adjustPos={false}
              wrapperClassName="gh-quote-details-tooltip-margin-left"
            >
              {tooltipMessage}
            </Tooltip>
          )}
        </h3>
        {isFromEventPage ? (
          <React.Fragment>
            <div className="gh-event-helicopter-carousel">
              <ImageSlider
                imagesUrl={images.map((el) => el.file_url)}
                autoPlay={true}
                options={{ loop: true }}
              />
            </div>

            <div className="heli-item-label uk-margin-small-top">
              <div
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("enquireTitleDescription"),
                }}
              />
            </div>

            <div
              className="gh-event-helicopter-desc"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </React.Fragment>
        ) : (
          <div
            className="gh-heli-card-box"
            style={{
              background: `url(${
                images && images[0] && images[0].file_url
              }) center center / cover no-repeat`,
            }}
          />
        )}
      </div>
      <div className="gh-heli-card-content">
        <div data-uk-grid className="uk-grid-small">
          <div className="gh-heli-card-subtitle">
            <div className="heli-item-label">
              <div
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(estimatedPriceRangeAlias),
                }}
              />
            </div>
            {!hideTooltips && (
              <span className="heli-item-label-tooltip">
                <GhTooltip
                  tKey={`enquire-tooltip-sub-title-${helicopterId}`}
                  side="bottom-left"
                  secondary="enquire-tooltip"
                  alias={
                    typeOfTrip === "one" ? "estimatedPriceTooltip" : "estimatedPriceTooltipReturn"
                  }
                  adjustPos={false}
                />
              </span>
            )}
          </div>
          <div className="heli-item-range">
            <div className="heli-item-range_item from">
              <span className="heli-item-label">{staticService.findByAlias("from")}</span>
              <span className="heli-item-text" ref={itemText}>
                {`£${removeFractionalPart(minP)}`}
              </span>
            </div>
            <div
              className="heli-item-range_item average"
              style={{
                left: averagePos - 13 + "px",
              }}
            >
              <span
                className="heli-item-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("average"),
                }}
              />
              <span className="heli-item-text">{`£${removeFractionalPart(averageP)}`}</span>
            </div>
            <div className="heli-item-range_item to">
              <span
                className="heli-item-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("to"),
                }}
              />
              <span className="heli-item-text">{`£${removeFractionalPart(maxP)}`}</span>
            </div>
            <div className="heli-item-range_line uk-margin-small-bottom" ref={rangeLine}>
              <div
                className="heli-item-range_marker"
                style={{
                  left: averagePos - 30 + "px",
                  width: averageW,
                }}
              />
            </div>

            <WarningMessage message={warningMessages?.static_content} />
            <WarningMessage message={warningMessages?.from_location} />
            <WarningMessage message={warningMessages?.to_location} />
            <WarningMessage message={warningMessage} />
          </div>

          <div className="heli-info-wrapper uk-margin-small-top">
            <div className="heli-info-item">
              <div className="heli-item-label">
                {staticService.findByAlias("estimateFlightDuration")}
              </div>
              <div className="heli-item-text">
                {duration < 15 ? "Less than 15 minutes" : formattedTime(duration)}
              </div>
            </div>
            <div className="heli-info-item">
              <div
                className="heli-item-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("maximumPassengers"),
                }}
              />
              <div className="heli-item-text">{max_passengers}</div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

EventHelicopterItem.propTypes = {
  estimated_price: PropTypes.object.isRequired,
  helicopterId: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  max_passengers: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.element,
  hideTooltips: PropTypes.bool,
  estimatedPriceRangeAlias: PropTypes.string,
  hideParenthesis: PropTypes.bool,
  typeOfTrip: PropTypes.string,
  isFromEventPage: PropTypes.bool,
  warningMessages: PropTypes.object,
  warningMessage: PropTypes.string,
};

EventHelicopterItem.defaultProps = {
  children: null,
  hideTooltips: false,
  estimatedPriceRangeAlias: "estimatedPriceRange",
  hideParenthesis: false,
};

export default EventHelicopterItem;
