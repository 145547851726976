import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { parseCoordinates, inArrayWithKey, utcTime } from "../../../../../shared";
import { FlightDirection } from "../../../../common";
import { config } from "../../../../../configs";
import staticService from "../../../../../services/static.service";
import checkIcon from "../../../../../assets/img/svg/checkSimple.svg";
import capitalizeString from "../../../../../utils/capitalizeString";

const showOtherInfo = (key, value, index) => {
  if (key === "luggage_will_provide_later") {
    return (
      <div key={index}>
        <small>
          <span>{value ? "Luggage information will be provided later" : ""}</span>
        </small>
      </div>
    );
  }
  return (
    <div key={index}>
      <small>
        <span>{capitalizeString(staticService.findByAlias(key))}</span>: <span>{value}</span>
      </small>
    </div>
  );
};

const QuoteDetails = ({ title, requestInfo, leg, data }) => {
  const {
    from: fromAddr,
    to: toAddr,
    from_coords,
    to_coords,
    from_details,
    to_details,
    from_is_private,
    to_is_private,
  } = leg;
  const fromCoords = parseCoordinates(from_coords);
  const toCoords = parseCoordinates(to_coords);
  return (
    <div className="uk-position-relative gh-req-quotes-details uk-margin-medium-bottom">
      <div>
        <div className="gh-leg-title uk-text-uppercase">{title}</div>
      </div>

      <FlightDirection visible={data.screenW > config.minDeviceWidth} />

      <div data-uk-grid className="uk-margin-medium-top gh-leg-info">
        <div className="uk-width-1-4@m">
          <PadInfo
            addr={fromAddr}
            lat={fromCoords.lat}
            lng={fromCoords.lng}
            details={from_details}
            isPrivate={from_is_private}
            side="from"
          />
        </div>
        <div className="uk-width-1-4@m">
          <PadInfo
            addr={toAddr}
            lat={toCoords.lat}
            lng={toCoords.lng}
            details={to_details}
            isPrivate={to_is_private}
            side="to"
          />
        </div>

        <div className="uk-width-1-4@m">
          <div>
            <small>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("quoteDetailDepartureDate"),
                }}
              />
              : <span>{moment(leg.date).format("DD MMMM, YYYY")}</span>
            </small>
          </div>
          <div>
            <small>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("quoteDetailDepartureTime"),
                }}
              />
              :{" "}
              <span>
                {moment(leg.date + " " + leg.departure_time).format(config.timeFormat) + utcTime()}
              </span>
            </small>
          </div>
          <div>
            <small>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("AircraftPreferences"),
                }}
              />
              :{" "}
              <span>
                {requestInfo.preferences &&
                  inArrayWithKey("id", requestInfo.preferences, config.aircraftPreferences).title}
              </span>
            </small>
          </div>
          <div>
            <small>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("CrewRequirements"),
                }}
              />
              :{" "}
              <span>
                {requestInfo.crew_size >= 0 &&
                  inArrayWithKey("id", requestInfo.crew_size, config.pilotPreferences).title}
              </span>
            </small>
          </div>
        </div>

        <div className="uk-width-1-4@m">
          <div>
            <small>
              <span>{staticService.findByAlias("MenCount")}</span>:{" "}
              <span>{leg.passengers.men}</span>
            </small>
          </div>
          <div>
            <small>
              <span>{staticService.findByAlias("WomenCount")}</span>:{" "}
              <span>{leg.passengers.women}</span>
            </small>
          </div>
          <div>
            <small>
              <span>{staticService.findByAlias("ChildrenCount")}</span>:{" "}
              <span>{leg.passengers.children}</span>
            </small>
          </div>
          <div>
            <small>
              <span>
                {leg.passengers.passengers_amount_type === 1 ? (
                  <span>Exact total number of passengers</span>
                ) : leg.passengers.passengers_amount_type === 2 ? (
                  <span>Possibility of change (+ 1 or 2 passengers)</span>
                ) : (
                  ""
                )}
              </span>
            </small>
          </div>
          <br />
          {Object.keys(leg.luggage).map((item, idx) =>
            leg.luggage[item] ? showOtherInfo(item, leg.luggage[item], idx) : "",
          )}
          <br />
          {Object.keys(leg.special_items).map((item, idx) =>
            leg.special_items[item] ? showOtherInfo(item, leg.special_items[item], idx) : "",
          )}
        </div>
      </div>
    </div>
  );
};

const PadInfo = ({ addr, lat, lng, details, isPrivate, side }) => (
  <div>
    <h3 className="uk-h3 uk-margin-remove">{addr}</h3>
    <div>
      <small>
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("address"),
          }}
        />
        : <span>{addr}</span>
      </small>
    </div>
    <div>
      <small>
        <span>
          {lat.toFixed(2)}° N, {lng.toFixed(2)}° W
        </span>
      </small>
    </div>
    {details && window.location.pathname.search("/flights/my-bookings/") === -1 ? (
      <div className="uk-margin-top">
        {isPrivate ? (
          <div className="uk-flex uk-flex-middle">
            <img style={{ marginRight: 10 }} src={checkIcon} alt="check" />
            {side === "from" ? (
              <h6
                className="gh-take-off"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("exactTakeOffLocation"),
                }}
              />
            ) : (
              <h6
                className="gh-take-off"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("exactLandingLocation"),
                }}
              />
            )}
          </div>
        ) : (
          ""
        )}

        <div
          className="gh-additional-info"
          style={{ margin: "10px 0 0 0", background: "none", padding: "15px 0" }}
        >
          <h6
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("additionalDetailsAboutLocation"),
            }}
          />
          <span>{details}</span>
        </div>
      </div>
    ) : null}
  </div>
);

QuoteDetails.defaultProps = {
  data: {},
  quote: {},
};

QuoteDetails.propTypes = {
  data: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
};

export { QuoteDetails };
