import Api from "../services/api-handler";
import handlerError from "./error";
import * as types from "./actionTypes";
import { spinnerInstance } from "../shared";
import objectNullValuesToString from "../utils/objectNullValuesToString";
import { config } from "../configs";
import auth from "../services/auth";
import { deployment_version } from "../../env/env";

const entity = "booking-requests";

export function getRequestDraftSuccess(companies) {
  return { type: types.GET_REQUEST_DRAFT, companies };
}

export function updateRequestDraftSuccess(companies) {
  return { type: types.UPDATE_REQUEST_DRAFT, companies };
}

export function getRequestDraft(pipeline_id = null) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();

    let queryParams = {};
    if (pipeline_id) {
      queryParams.key = "pipeline_id";
      queryParams.value = pipeline_id;
    }

    return Api.setPath(entity, "get-draft")
      .setQueryParams([queryParams])
      .getAll()
      .then((draft) => {
        dispatch(getRequestDraftSuccess(draft.message));
        if (draft.message) {
          return Promise.resolve(objectNullValuesToString(draft.message));
        }
      })
      .catch((err) => handlerError(err, dispatch))
      .finally(() => spinnerInstance.setProp("type", "large").hide());
  };
}

export function updateRequestDraft(updatedDraft) {
  const URL = `${config.apiDomain}/booking-requests/post-draft`;
  const headers = {
    authorization: `Bearer ${auth.getFromLocalStorage("access_token")}`,
    Accept: "application/json, */*",
    "Content-Type": "application/json",
    version: deployment_version || 1,
  };
  // hotfix for draft, if there is not pipeline_id, we shouldn't save draft, because it will create a new pipeline with wrong data
  if (!updatedDraft?.pipeline_id) return;

  return function (dispatch) {
    // eslint-disable-next-line no-undef
    return fetch(URL, {
      method: "POST",
      headers,
      body: JSON.stringify(updatedDraft),
    })
      .then((draft) => {
        dispatch(updateRequestDraftSuccess(draft));
        return Promise.resolve(draft);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
