import React from "react";
import PropTypes from "prop-types";
import { Control, Form, Errors, actions } from "react-redux-form";
import { validationRules, validationMessages } from "../../configs";
import { CustomValidateError, updateModel } from "../../shared";
import staticService from "../../services/static.service";
import Auth from "../../services/auth";
import auth from "../../services/auth";

const { required } = validationRules;

export class NewCommentFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addComment = this.addComment.bind(this);
  }

  addComment(model) {
    const value = model.body.trim();
    if (value) {
      this.context.store.dispatch(this.props.action(this.props.id, value));
      this.context.store.dispatch(actions.reset(this.props.modelName));
    }
  }

  render() {
    return (
      <Form onSubmit={this.addComment} model={this.props.modelName}>
        <Control.textarea
          className={"uk-textarea"}
          model={".body"}
          validators={{ required }}
          changeAction={updateModel}
          disabled={!auth.isVerified()}
        />
        <Errors
          model={".body"}
          show="touched"
          wrapper={CustomValidateError}
          messages={{
            required: validationMessages().requiredMessage.bind(null, "Comment"),
          }}
        />
        <div className={"uk-margin-small-top"}>
          <Control.button
            model={"commentModel"}
            disabled={{ valid: false }}
            className={"uk-button uk-button-primary"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("send"),
              }}
            />
          </Control.button>
        </div>
      </Form>
    );
  }
}

NewCommentFormComponent.contextTypes = {
  store: PropTypes.object,
};

NewCommentFormComponent.propTypes = {
  action: PropTypes.func,
  modelName: PropTypes.string,
  id: PropTypes.number,
};
