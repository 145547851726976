import Api from "../services/api-handler";
import handlerError from "./error";
import * as types from "./actionTypes";
import { staticText } from "../configs/static-text.config";

const entity = "company";

export function favouritesSuccess(companies) {
  return { type: types.LOAD_FAVORITES, companies };
}

export function addSuccess(companies) {
  return { type: types.ADD_FAVORITE, companies };
}

export function deleteSuccess(companies) {
  return { type: types.DELETE_FAVORITE, companies };
}

export function favourites(id) {
  return function (dispatch) {
    return Api.setPath(entity, "favourites")
      .get(id)
      .then((list) => {
        dispatch(favouritesSuccess(list));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function addFavourite(id, model, onSuccess) {
  return function (dispatch) {
    return Api.setPath(entity, "add_favourite/" + id)
      .setProperty("responseMessages", staticText.globalMessages.favorite.add)
      .post(model)
      .then((list) => {
        dispatch(addSuccess(list));
        if (onSuccess) onSuccess(list);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function deleteFavourite(id, model) {
  return function (dispatch) {
    return Api.setPath(entity, "remove_favourite/" + id)
      .setProperty("responseMessages", staticText.globalMessages.favorite.delete)
      .post(model)
      .then((list) => {
        dispatch(deleteSuccess(list));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function searchFavouriteSuccess(items) {
  return { type: types.POPULATE_AUTOCOMPLETE_LIST, items };
}

export function searchFavourite(id, search, onSuccess) {
  return function (dispatch) {
    return Api.setPath(entity, "search/" + id)
      .setProperty("skipErrorAlert", true)
      .setProperty("showSpinner", false)
      .post({ search: search })
      .then((list) => {
        dispatch(searchFavouriteSuccess(list));
        Api.setProperty("skipErrorAlert", true).setProperty("showSpinner", true);
        onSuccess(list);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
