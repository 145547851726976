import React, { useState } from "react";
import { Form, Control, actions, Errors } from "react-redux-form";
import {
  dateFormat,
  DateTimeComponent,
  updateModel,
  CustomValidateError,
} from "../../../../shared";
import staticService from "../../../../services/static.service";
import moment from "moment";
import { validationRules } from "../../../../configs";

const updateStart = (model, value) => {
  if (typeof value !== "string" && value !== null) {
    return (dispatch) => {
      dispatch(actions.change("eventsFiltersModel.date_end", ""));
      dispatch(actions.change(model, value.format("DD MMMM, YYYY")));
    };
  }
  return updateModel(model, value);
};

const updateEnd = (model, value, filters) => {
  if (typeof value !== "string" && value !== null) {
    if (filters && value.isBefore(moment(filters.date_start, "DD-MM-YYYY"))) {
      return updateModel(model, dateFormat(filters.date_start));
    }
    return updateModel(model, value.format("DD MMMM, YYYY"));
  }
  return updateModel(model, value);
};

const updateFeaturedEvent = (model, value) => updateModel(model, value ? 1 : 0);

const EventFilters = (props) => {
  const { submit, resetFilters } = props;

  const { rangeValidationEndDate, rangeValidation } = validationRules;

  const handleSubmit = (formValue) => {
    submit(formValue);
  };

  const handleReset = () => {
    resetFilters();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      model={props.modelName}
      validators={{
        "": {
          endDateValidation: rangeValidationEndDate,
          rangeRequired: rangeValidation,
        },
      }}
    >
      <div className="uk-card uk-card-default uk-card-body">
        <div data-uk-grid className="uk-flex uk-flex-middle uk-text-left">
          <div className="uk-width-1-1 uk-width-1-4@m">
            <label
              className="uk-form-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("eventLocation"),
              }}
            />
            <Control.select
              model=".location"
              defaultValue={undefined}
              className="uk-select"
              changeAction={updateModel}
              updateOn="change"
            >
              <option value="">Select ...</option>
              {props.countries?.map((country, i) => (
                <option key={i} value={country}>
                  {country}
                </option>
              ))}
            </Control.select>
          </div>
          <div className="uk-width-1-1 uk-width-1-4@m">
            <label
              className="uk-form-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("eventName"),
              }}
            />
            <Control.text
              model=".name"
              defaultValue={undefined}
              className="uk-input"
              changeAction={updateModel}
              updateOn="change"
            />
          </div>
          <div className="uk-width-1-1 uk-width-1-4@m gh-padding-micro-top">
            <label
              className="uk-form-label uk-display-block"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("eventDate"),
              }}
            />
            <div className="uk-form-controls gh-range-hypen gh-range-hypen-filter uk-position-relative">
              <Control
                model=".date_start"
                className={"uk-input gh-calendar-input"}
                component={DateTimeComponent}
                defaultValue={props.filters && props.filters.date_start}
                controlProps={{
                  dateFormat: "DD MMMM, YYYY",
                  placeholder: "Start date",
                  onClear: () => props.updateModel(props.modelName + ".date_start", ""),
                }}
                changeAction={(model, value) =>
                  updateStart(
                    model,
                    value ? moment(value).utc().toISOString() : value,
                    props.filters,
                  )
                }
                updateOn="change"
              />
              <Errors
                model={props.modelName}
                wrapper={CustomValidateError}
                messages={{
                  endDateValidation: staticService.findByAlias("rangeValidationEndDate"),
                  rangeRequired: staticService.findByAlias("rangeValidationRequired"),
                }}
              />
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-4@m gh-medium-padding-left gh-padding-plus-top">
            <div className="uk-form-controls uk-position-relative">
              <Control
                model=".date_end"
                className={"uk-input gh-calendar-input"}
                component={DateTimeComponent}
                controlProps={{
                  dateFormat: "DD MMMM, YYYY",
                  placeholder: "End date",
                  onClear: () => props.updateModel(props.modelName + ".date_end", ""),
                  isValidDate: true,
                }}
                changeAction={(model, value) =>
                  updateEnd(model, value ? value.utc().toISOString() : value, props.filters)
                }
                updateOn="change"
              />
            </div>
          </div>

          <div className="uk-width-1-1 uk-width-1-4@m">
            <label
              className="uk-form-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("eventType"),
              }}
            />
            <Control.select
              model=".type"
              defaultValue={undefined}
              className="uk-select"
              changeAction={updateModel}
              updateOn="change"
            >
              <option value="">Select ...</option>
              {props.eventTypes?.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </Control.select>
          </div>
          <div className="uk-width-1-1 uk-width-1-4@m">
            <Control.checkbox
              className={"uk-checkbox uk-margin-small-right"}
              model={".is_featured_event"}
              changeAction={updateFeaturedEvent}
              updateOn="change"
            />
            <label
              className="uk-form-label uk-margin-small-right"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("featuredEventsType"),
              }}
            />
          </div>

          <div
            className="uk-width-1-1 uk-flex uk-flex-middle uk-flex-center gh-margin-remove"
            data-uk-grid
          >
            <div className="uk-width-1-1 uk-width-1-2@s uk-flex uk-flex-right@s gh-padding-none gh-margin-top-medium">
              <Control.button
                onClick={handleReset}
                type="button"
                model={props.modelName}
                className="uk-button gh-cloud-blue-btn gh-with-btn-full"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("resetFilter"),
                  }}
                />
              </Control.button>
            </div>
            <div className="uk-width-1-1 uk-width-1-2@s uk-flex uk-flex-left@s gh-padding-none">
              <Control.button
                model={props.modelName}
                className="uk-button uk-button-primary gh-with-btn-full"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("showFilterResult"),
                  }}
                />
              </Control.button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default EventFilters;
