import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";
import { DescriptionItem } from "./DescriptionItem";

import "./index.css";

function Description(props) {
  const { className, onClick, children } = props;

  return (
    <div
      className={cs("calculator__description", className, onClick && "clickable")}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

Description.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

Description.Item = DescriptionItem;

export { Description, DescriptionItem };
