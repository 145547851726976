import { removeTemporaryDocument } from "../../../../actions";
import { isObject } from "../../../../shared";
import getApiErrorMessages from "../../../../utils/getApiErrorMessages";

export async function removeFileHanlder(file) {
  if (file.id) {
    return Promise.resolve();
  }

  try {
    await removeTemporaryDocument(file)();
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(getApiErrorMessages(error));
  }
}

export function parseUploadErrors(errors) {
  if (errors && isObject(errors)) {
    return Object.values(errors)
      .map((error) => {
        if (Array.isArray(error)) {
          return error.join("<br />");
        }

        return error;
      })
      .join("<br />");
  }

  if (errors?.message) {
    return errors.message;
  }

  return "Unknown error";
}
