import PropTypes from "prop-types";
import React from "react";
import apiHandler from "../../../services/api-handler";

export default function ProgressInfoRenotify(props) {
  const { requestId, companyId, waitTime } = props;
  const [loadingContent, setLoadingContent] = React.useState(null);
  const [renotified, setRenotified] = React.useState(false);

  const renotifyHandler = React.useCallback(
    async (event) => {
      event.preventDefault();

      apiHandler.setProperty("skipErrorAlert", true);

      setLoadingContent("Sending...");

      await apiHandler
        .setPath("booking-requests", `renotify-operators/${requestId}/${companyId}`)
        .getAll();

      setLoadingContent("Sent");

      setTimeout(() => {
        setRenotified(true);
        setLoadingContent(null);
      }, 1000);
    },
    [requestId, companyId],
  );

  if (loadingContent) {
    return <span>{loadingContent}</span>;
  }

  if (waitTime) {
    return <span className="renotify-btn">Renotify in {waitTime} mins</span>;
  }

  if (renotified) {
    return <span className="renotify-btn">Sent</span>; //@todo enhance when renotify once
  }

  return (
    <a className="renotify-btn" onClick={renotifyHandler}>
      Renotify
    </a>
  );
}

ProgressInfoRenotify.propTypes = {
  requestId: PropTypes.any,
  companyId: PropTypes.any,
  waitTime: PropTypes.number,
};
