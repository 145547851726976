import { isValidPhoneNumber } from "libphonenumber-js";

export const isValidPhone = (phone) => {
  if (typeof phone === "string" && phone.length) {
    return isValidPhoneNumber(phone);
  }

  if (typeof phone === "object") {
    if (!phone.value) return true;

    return isValidPhoneNumber(phone.value, phone.code);
  }

  return true;
};

export const parsePhoneInputValue = (value) => {
  if (value) {
    if (typeof value === "object") {
      return value.value || "";
    }
  }

  return value || "";
};
