import React from "react";
import PropTypes from "prop-types";
import { Control } from "react-redux-form";
import { GridComponent, IconComponent } from "../../shared";
import staticService from "../../services/static.service";
import { matchPath } from "react-router-dom";

class TermsConditionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsConditions: props.termsConditionsClass,
      changeIcon: props.changeIcon,
      changeIconClass: props.changeIconClass,
      invitationPath: false,
    };
  }

  componentDidMount() {
    const path = matchPath(window.location.pathname, {
      path: "/account/invitation/:id",
      strict: true,
      exact: true,
    });
    if (path) {
      this.setState({ invitationPath: true });
    } else {
      this.setState({ invitationPath: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.changeIcon !== nextProps.changeIcon) {
      this.setState(() => {
        return {
          changeIcon: nextProps.changeIcon,
          changeIconClass: `${nextProps.changeIconClass}`,
          termsConditions: `${nextProps.termsConditionsClass}`,
        };
      });
    }
  }

  render() {
    const { invitationPath } = this.state;
    return (
      <GridComponent
        visible={true}
        style={this.props.extraStyle}
        attrProps={{
          id: "terms-grid",
          className:
            this.props.termsType || this.props.condition
              ? "uk-margin-remove-top"
              : "uk-margin-small-top uk-flex uk-flex-center",
        }}
      >
        <div
          className={`gh-term-condition-button-wrapper ${
            invitationPath ? "gh-invitation-wrapper" : ""
          }`}
          style={{
            margin: this.props.condition ? (this.props.broker ? "0" : "6px 0") : "15px auto",
          }}
        >
          <div
            className={
              this.props.operator ? "uk-text-left gh-condition-notification" : "uk-text-center"
            }
          >
            {this.props.termsType ? (
              <small dangerouslySetInnerHTML={{ __html: this.props.agreementTitle }} />
            ) : (
              <small
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(this.props.helperSlug),
                }}
              />
            )}
          </div>
          <div className="uk-flex uk-flex-center">
            <Control.button
              type={"button"}
              disabled={this.props.disabled}
              model="userRegisterModel.tac_accepted"
              onClick={this.props.action}
              className={`uk-button uk-button-default uk-width-1-1 uk-flex gh-button-terms uk-flex-center uk-flex-middle 
              ${this.props.bankClass ? this.props.bankClass : ""} 
              ${this.props.condition ? "gh-button-terms-register" : ""}
              ${invitationPath ? "gh-terms-invitation-btn" : ""}
              `}
            >
              <div className="uk-width-auto gh-padding-small-left">
                {
                  <IconComponent
                    visible={true}
                    icon={this.state.changeIcon || "copy"}
                    id={"icon-" + this.state.changeIcon || "copy"}
                    ratio={1.5}
                    className={this.state.changeIconClass}
                  />
                }
              </div>
              <div className={this.state.termsConditions || "uk-width-expand uk-padding-remove"}>
                {this.props.termsType ? (
                  <div
                    className={"gh-term-condition-txt"}
                    dangerouslySetInnerHTML={{
                      __html: this.props.agreementBtnText,
                    }}
                  />
                ) : (
                  <div
                    className={"gh-term-condition-txt"}
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias(this.props.agreementSlug),
                    }}
                  />
                )}
              </div>
              <div className="uk-width-auto">
                <div className="uk-text-right gh-term-arrow">
                  {
                    <IconComponent
                      visible={true}
                      icon={"arrow-right"}
                      id={"icon-arrow-right"}
                      ratio={1.5}
                      className={"uk-margin-small-right  gh-icon-terms"}
                    />
                  }
                </div>
              </div>
            </Control.button>
          </div>
        </div>
      </GridComponent>
    );
  }
}

TermsConditionsComponent.propTypes = {
  termsConditionsClass: PropTypes.string,
  action: PropTypes.func,
  changeIcon: PropTypes.string,
  changeIconClass: PropTypes.string,
  agreementSlug: PropTypes.string,
  helperSlug: PropTypes.string,
};

TermsConditionsComponent.defaultProps = {
  agreementSlug: "agreement",
  helperSlug: "termsConditions",
};

export { TermsConditionsComponent };
