import * as types from "../../actions/actionTypes";

export function clientsList(state = [], action) {
  switch (action.type) {
    case types.GET_CLIENTS_LIST:
      return [...action.payload];
    case types.CLIENTS_LIST_REMOVE:
      return state.filter((c) => c.id !== action.payload);
    case types.CLIENTS_LIST_ADD: {
      const newState = [...state];
      state.unshift(action.payload);
      return newState;
    }
    default:
      return state;
  }
}
