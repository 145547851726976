import React from "react";
import PropTypes from "prop-types";
import uid from "../../../utils/uid";

import AccordionArrow from "../../../assets/img/full-accordion-arrow.png";
import { legTitle } from "../../../shared";

import "./accordionComponent.css";

const AccordionComponent = (props) => {
  const { children, items, isOpen, flightType, title } = props;

  return (
    <ul data-uk-accordion="multiple: true" className="gh-accordion-wrapper">
      {items &&
        items.map((_, index) => (
          <li
            id={`gh-accordion-item${uid().uid}`}
            className={`${
              isOpen ? "uk-open" : ""
            } gh-accordion-section gh-simple-section gh-accordion-wrapper-item`}
            key={index}
          >
            <a className={`uk-accordion-title ${isOpen}`} href="#">
              <h4 className="gh-request-section-title">
                {legTitle(flightType, index) || title || "Legs"}
              </h4>
              <span className="accordion-icon">
                <img src={AccordionArrow} alt="icon" />
              </span>
            </a>
            <div className="uk-accordion-content">
              {children.length ? children[index] : children}
            </div>
          </li>
        ))}
    </ul>
  );
};

AccordionComponent.defaultProps = {
  items: [{}],
};

AccordionComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isOpen: PropTypes.bool,
  flightType: PropTypes.number,
  title: PropTypes.string,
};

export default AccordionComponent;
