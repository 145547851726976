import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ModalComponent } from "../../../shared";
import Button from "../Button";

const CustomPromptModal = (props) => {
  const { confirmOpen, handleCancel, value } = props;
  const modalRef = useRef(null);

  useEffect(() => {
    if (confirmOpen) {
      modalRef.current.open();
    }
  }, []);

  const handleAction = () => {
    modalRef.current.hide();
  };

  return (
    <div>
      <ModalComponent
        options={{ clsPage: "", bgClose: false, escClose: false }}
        modalClass="gh-broker-nav-request-modal gh-restrict-info-modal"
        id="restrict-info-modal"
        ref={modalRef}
        showCloseBtn={false}
        onModalClose={() => {
          window.scroll({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
          handleCancel();
        }}
      >
        <div className="uk-margin-bottom" dangerouslySetInnerHTML={{ __html: value }} />
        <div className="uk-flex uk-flex-center">
          <Button onClick={handleAction}>Choose</Button>
        </div>
      </ModalComponent>
    </div>
  );
};

CustomPromptModal.propTypes = {
  confirmOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  value: PropTypes.string,
};

export default CustomPromptModal;
