import React, { Component } from "react";
import { HomeLayout } from "../layouts";
import { BlockComponent } from "../common";
import { ContactFormComponent } from "../forms";
import { fireTracking } from "../../shared";
import { pages, config } from "../../configs";
import staticService from "../../services/static.service";
import { Helmet } from "react-helmet/es/Helmet";
import { getMeta } from "../../actions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import auth from "../../services/auth";

class AboutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props,
    };
    this.filteredPages = this.filteredPages.bind(this);
  }

  componentDidMount() {
    this.props.getMeta(window.location.pathname.substring(1));
  }

  filteredPages() {
    if (auth.isAuthenticated()) {
      return pages.filter(
        (page) =>
          page.path &&
          page.path !== "/contact" &&
          page.path !== "/events" &&
          page.path !== "/get-a-demo" &&
          page.path !== "/",
      );
    } else {
      return pages.filter((page) => page.path);
    }
  }

  render() {
    const { data } = this.state;
    fireTracking(data.location.pathname);
    const { metaData } = this.props;

    return (
      <HomeLayout
        activeRoute={data.match}
        pageTitle={data.title}
        pages={this.filteredPages()}
        registerPath={config.redirectToRegister}
      >
        <Helmet>
          <link rel="canonical" href="https://getheli.com/contact" />
          {metaData &&
            metaData.meta &&
            metaData.meta.message &&
            metaData.meta.message.map((meta) => (
              <meta key={meta.name} name={meta.name} content={meta.content} />
            ))}
        </Helmet>
        <div className={"hc-main-page uk-section-default uk-position-relative"}>
          <div className={"uk-background-norepeat uk-background-cover gh-contact-bg"}>
            <div className="hc-subtitle-box">
              <h3
                className={"gh-text-slim uk-text-center gh-contact-title"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("contactPage"),
                }}
              />
            </div>
          </div>
        </div>
        <BlockComponent visible={true}>
          <div
            data-uk-grid
            className={"uk-padding uk-padding-remove-horizontal uk-child-width-1-2@s gh-padding-20"}
            data-uk-height-match="target: > div > .uk-card; row: false"
          >
            <div className="uk-first-column">
              <div className="">
                <div className={"gh-reduce-paragraph-pad"}>
                  <h1
                    className={"gh-text-slim"}
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("getInTouch"),
                    }}
                  />
                  <p
                    className={"gh-warn"}
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("getInTouchQuestion"),
                    }}
                  />
                  <p
                    className={"gh-warn"}
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("getInTouch48"),
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="uk-visible@s">
                <div className="gh-reduce-paragraph-pad">
                  <div className="uk-flex uk-flex-row">
                    <div style={{ flex: 1 }}>
                      <h2
                        className={"gh-text-slim"}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("FindUs"),
                        }}
                      />
                      <address
                        className={"gh-address"}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("FindUsAddress"),
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <h2
                        className={"gh-text-slim"}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("FollowUs"),
                        }}
                      />
                      <a href="https://www.instagram.com/getheli/" target="_blank">
                        <span
                          className="gh-social-icon"
                          style={{ cursor: "pointer", color: "#1abc9c" }}
                          data-uk-icon={"icon: instagram; ratio: 1.75"}
                        />
                      </a>
                      <a href="https://twitter.com/gethelico" target="_blank">
                        <span
                          className="gh-social-icon"
                          style={{
                            cursor: "pointer",
                            color: "#1abc9c",
                            marginLeft: "20px",
                          }}
                          data-uk-icon={"icon: twitter; ratio: 0.75"}
                        />
                      </a>
                      <a href="https://www.linkedin.com/company/getheli/" target="_blank">
                        <span
                          className="gh-social-icon"
                          style={{
                            cursor: "pointer",
                            color: "#1abc9c",
                            marginLeft: "20px",
                          }}
                          data-uk-icon={"icon: linkedin; ratio: 1.75"}
                        />
                      </a>
                      <a href="https://www.facebook.com/gethelico" target="_blank">
                        <span
                          className="gh-social-icon"
                          style={{
                            cursor: "pointer",
                            color: "#1abc9c",
                            marginLeft: "20px",
                          }}
                          data-uk-icon={"icon: facebook; ratio: 0.62"}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactFormComponent model={"contactFormModel"} visible={true} />
        </BlockComponent>
      </HomeLayout>
    );
  }
}

AboutComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMeta,
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    metaData: state.metaData,
  };
}

const ABOUT = connect(mapStateToProps, mapDispatchToProps)(AboutComponent);
export { ABOUT as AboutComponent };
