import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../../CalculatorContext";
import OperatorMarginContent from "./OperatorMarginContent";

import "./index.css";
import { useSessionStorage } from "react-use";

export const OperatorMarginContext = React.createContext();

export function OperatorMargin({ isFormSubmitting, aircrafts }) {
  const { updateOperatorMargin, currency } = useCalculatorContext();
  const { watch } = useFormContext();

  const [percent, setPercent] = React.useState("");
  const [sum, setSum] = React.useState("");

  const [isSticky, setIsSticky] = React.useState(false);
  const [loadingCompanyDefaultsSettings, setLoadingCompanyDefaultsSettings] = React.useState(false);
  const [companyDefaultSettings, setCompanyDefaultSettings] = useSessionStorage(
    "company-quote-defaults",
    null,
  );

  React.useEffect(() => {
    const trigger = document.getElementById("quote-trip-tabs");
    const nav = document.getElementById("nav");
    const onScroll = () => {
      if (trigger) {
        const triggerRect = trigger.getBoundingClientRect();
        setIsSticky(triggerRect.top < -230);
      } else {
        const triggerRect = nav.getBoundingClientRect();
        setIsSticky(triggerRect.top < -230);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const quote_costs = watch("quote_costs");
  const quote_costs_total = watch("quote_costs_total");

  return (
    <OperatorMarginContext.Provider
      value={{ companyDefaultSettings, setCompanyDefaultSettings, loadingCompanyDefaultsSettings }}
    >
      <React.Fragment>
        {isSticky && (
          <OperatorMarginContent
            totalCostValue={quote_costs}
            quotePriceCalculated={quote_costs}
            onChangeTotalCost={updateOperatorMargin}
            currency={currency}
            quoteCostsTotal={quote_costs_total}
            isFormSubmitting={isFormSubmitting}
            percent={percent}
            setPercent={setPercent}
            sum={sum}
            setSum={setSum}
            className="sticky"
            isSticky={isSticky}
            aircrafts={aircrafts}
          />
        )}
        <OperatorMarginContent
          totalCostValue={quote_costs}
          quotePriceCalculated={quote_costs}
          onChangeTotalCost={updateOperatorMargin}
          currency={currency}
          quoteCostsTotal={quote_costs_total}
          isFormSubmitting={isFormSubmitting}
          percent={percent}
          setPercent={setPercent}
          sum={sum}
          setSum={setSum}
          isSticky={isSticky}
          aircrafts={aircrafts}
        />
      </React.Fragment>
    </OperatorMarginContext.Provider>
  );
}

OperatorMargin.propTypes = {
  isFormSubmitting: PropTypes.bool,
  aircrafts: PropTypes.array,
};
