import React, { Component } from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";
import { AccordionItemComponent } from "./accordion-item.component";

class AccordionComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.component = UIkit.accordion("#" + this.props.accordionId, this.props.options);
  }

  isOpen(index) {
    if (this.props.items[index] && typeof this.props.items[index].onAccordionShow === "function") {
      this.props.items[index].onAccordionShow();
    }
  }

  isClosed(index) {
    if (this.props.items[index] && typeof this.props.items[index].onAccordionHide === "function") {
      this.props.items[index].onAccordionHide();
    }
  }

  componentWillUnmount() {
    this.component.$destroy(true);
  }

  render() {
    const { classNames, isDisabled, wrapperClassName } = this.props;
    let className = {
      topEl: `gh-accordion disabled-list ${isDisabled ? "uk-disabled" : ""} ${
        classNames.topEl || ""
      }`,
    };
    return (
      <ul className={[className.topEl, ...wrapperClassName].join(" ")} id={this.props.accordionId}>
        {this.props.items.map((item, index) => {
          return (
            <AccordionItemComponent
              key={index}
              title={item.title}
              index={index}
              isOpen={item.active}
              onAccordionHide={this.isClosed.bind(this)}
              onAccordionShow={this.isOpen.bind(this)}
              setActiveTab={this.props.setActiveTab}
              classNames={{}}
            >
              {item.content}
            </AccordionItemComponent>
          );
        })}
      </ul>
    );
  }
}

AccordionComponent.defaultProps = {
  classNames: {},
  options: {},
  accordionId: "",
  onAccordionShow: () => void 0,
  onAccordionHide: () => void 0,
  isOpen: false,
  isDisabled: false,
  items: [],
  wrapperClassName: [],
};

AccordionComponent.propTypes = {
  accordionId: PropTypes.string.isRequired,
  classNames: PropTypes.object,
  options: PropTypes.object,
  isOpen: PropTypes.bool,
  setActiveTab: PropTypes.func,
  onAccordionShow: PropTypes.func,
  onAccordionHide: PropTypes.func,
  isDisabled: PropTypes.bool,
  items: PropTypes.array,
  wrapperClassName: PropTypes.array,
};

export { AccordionComponent };
