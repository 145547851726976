import React from "react";
import PropTypes from "prop-types";
import { AccountLayout } from "../../layouts";
import { ForgotPasswordFormComponent } from "../../";
import { pages } from "../../../configs";
import { filteredPages } from "../../../shared";
import { BlockComponent } from "../../common/";

export const RecoverPasswordComponent = ({ match, title }) => {
  return (
    <AccountLayout activeRoute={match} pageTitle={title} pages={filteredPages(pages)}>
      <section className={"recover-password-page"}>
        <BlockComponent
          visible={true}
          containerClass={"uk-section uk-padding-remove-top gh-section-statistics"}
          parentProps={{ className: "uk-container uk-container-affiliate" }}
        >
          <ForgotPasswordFormComponent visible={true} />
        </BlockComponent>
      </section>
    </AccountLayout>
  );
};

RecoverPasswordComponent.contextTypes = {
  store: PropTypes.object,
};
