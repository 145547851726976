import React from "react";
import staticService from "../../../services/static.service";
import {
  cloneArray,
  CustomValidateError,
  DragAndDrop,
  fileSize,
  ListValidateError,
  ModalComponent,
  storage,
} from "../../../shared";
import { connect } from "react-redux";
import {
  removeDocument,
  removeUploadedDocument,
  validateUploadedDocuments,
  removeTemporaryDocument,
  uploadTemporaryDocument,
} from "../../../actions";
import { actions, Errors, Form } from "react-redux-form";
import { config } from "../../../configs";
import { UploadedFileComponent } from "../../common";
import { bindActionCreators } from "redux";
import arrow from "../../../assets/img/svg/arrowInCircle.svg";
import PropTypes from "prop-types";
import { showNotification } from "../../../utils/showNotification";

class UploadFiles extends React.Component {
  constructor(props) {
    super(props);

    const files = cloneArray(props.model);

    this.state = {
      conditions: [staticService.findByAlias("registerDocumentationConditions2")],
      files: this.props.model.length > 0 ? files : [],
      modelName: this.props.modelName,
      showError: false,
      errorMessage: staticService.findByAlias("noFileUploadMessage"),
      serverErrors: [],
      previewModalTitle: "",
    };
    this.uploadDocuments = this.uploadDocuments.bind(this);
    this.remove = this.remove.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.rememberFiles = this.rememberFiles.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onUploadSuccess = this.onUploadSuccess.bind(this);
    this.openFileInput = this.openFileInput.bind(this);
    this.resetInputFiles = this.resetInputFiles.bind(this);
    this.customRemoveFiles = this.customRemoveFiles.bind(this);
    this.openPreviewFileModal = this.openPreviewFileModal.bind(this);
    this.onClosePreviewFileModal = this.onClosePreviewFileModal.bind(this);
  }

  handleDrop(files) {
    const { multiple } = this.props;

    if (files.length > 1 && !multiple) {
      return showNotification("You can upload only one document", "danger");
    }

    this.setState({
      showError: false,
      serverErrors: [],
    });

    if (!this.validateFile(files)) {
      return false;
    }
    this.props.handleUpdateProgressStatus("uploading", files.length);
    const parseFiles = this.formatFiles(files);
    const _files = this.state.files.concat(parseFiles);
    this.setState({ files: _files });
    this.context.store.dispatch(actions.change(this.state.modelName, _files));
    this.props.handleChange && this.props.handleChange();
  }

  validateFile(files = []) {
    const { allowed } = this.props;
    let validateStatus = true;

    let listAllowedFormats = allowed
      ? allowed.replaceAll(" ", "").replaceAll(",", "").split(".")
      : null;

    for (let i = 0; i < files.length; i++) {
      if (allowed && listAllowedFormats) {
        let currentFileFormat = files.item(i).type.split("/")[1];

        if (!listAllowedFormats.includes(currentFileFormat)) {
          validateStatus = false;
          showNotification(staticService.findByAlias("notAllowedFileFormat"), "danger");
          break;
        }
      }

      if (config.allowedFiles.indexOf(files.item(i).type) === -1) {
        validateStatus = false;
        this.setState({
          showError: true,
          serverErrors: [
            <span
              key={i}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("wrongFileType"),
              }}
            />,
          ],
        });
        break;
      }
      if (fileSize(files.item(i).size) > config.max_file_upload) {
        validateStatus = false;
        this.setState({
          showError: true,
          serverErrors: [
            <span
              key={i}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("maxUploadValidation"),
              }}
            />,
          ],
        });
        break;
      }
    }
    return validateStatus;
  }

  uploadDocuments() {
    if (this.state.files.length > 0) {
      this.context.store.dispatch(
        validateUploadedDocuments(
          this.state.files,
          this.onUploadError,
          this.onUploadSuccess,
          this.state.modelName,
        ),
      );
    } else {
      this.alert(this.state.errorMessage);
    }
  }

  onUploadSuccess() {
    this.rememberFiles();
  }

  onUploadError(error) {
    if (error.status === 422) {
      const errorsFields = Object.keys(error.data);
      this.setState({
        showError: true,
        serverErrors: errorsFields.map((key) => error.data[key]),
      });
    }
  }

  formatFiles(files) {
    const _files = [];
    for (let i = 0; i < files.length; i++) {
      _files.push(files.item(i));
    }
    return _files;
  }

  rememberFiles() {
    storage.add(this.state.modelName, JSON.stringify(this.state.files));
  }

  remove(index) {
    this.props.onFileRemove && this.props.onFileRemove(this.state.files?.[index], index);
    if (!this.props.onlyOneFile) {
      const files = this.state.files.slice(0);
      const [{ id }] = files.splice(index, 1);
      this.setState({ files: files, serverErrors: [], showError: false });
      this.context.store.dispatch(actions.change(this.state.modelName, files));
    } else {
      this.setState({ files: [] });
      this.context.store.dispatch(actions.change(this.state.modelName, []));
    }
    this.context.store.dispatch(actions.resetValidity(this.state.modelName));
    this.props.remember && this.rememberFiles();
    this.fileRef.value = "";
    this.props.handleChange && this.props.handleChange();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.docs &&
      nextProps.docs.length === 0 &&
      nextProps.modelName !== "aocDocumentsModel"
    ) {
      this.resetInputFiles();
    }
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.context.store.dispatch(actions.setPending(this.state.modelName, false));
    }
    if (!nextProps.filesState) {
      this.setState({ files: [] });
    }
    if (this.props.validate !== nextProps.validate) {
      this.setState({ isVisible: false });
    }
    if (nextProps.docs && Object.keys(nextProps.docs).length) {
      this.setState({ files: nextProps.docs });
    }
  }

  finishUpload(info) {
    if (Object.keys(info).length) {
      this.props.handleUpdateProgressStatus("uploaded");
    }

    const uploadedFile = this.state.files[info.fileIndex];
    uploadedFile.file_name = info.file_name;
    uploadedFile.upload = info.upload;
    uploadedFile.file_data = info.file_data;
    uploadedFile.file_type = info.file_type;
    uploadedFile.document_type = info.document_type;
  }

  openFileInput() {
    if (this.fileRef) this.fileRef.click();
  }

  resetInputFiles() {
    if (this.fileRef) {
      this.fileRef.value = [];
    }
  }

  customRemoveFiles() {
    this.props.customRemoveFileAction();
    this.setState({ files: [] });
    return Promise.resolve();
  }

  openPreviewFileModal(file) {
    const srcFile = file.file_data || file.file_url || URL.createObjectURL(file);
    const fileType = file.type || file.file_type;

    this.setState({ previewModalTitle: file.file_name });

    if (fileType.includes("pdf")) {
      this.previewFileOutput.src = srcFile;
      this.previewFileOutput.classList.remove("hide");
    } else {
      this.previewImgOutput.src = srcFile;
      this.previewImgOutput.classList.remove("hide");
    }

    setTimeout(() => this.modalForm.open(), 200);
  }

  onClosePreviewFileModal() {
    this.previewFileOutput.src = "";
    this.previewFileOutput.classList.add("hide");
    this.previewImgOutput.src = "";
    this.previewImgOutput.classList.add("hide");
    this.setState({ previewModalTitle: "" });
  }

  get content() {
    const {
      containerClass,
      required,
      uploadTitle,
      hideRemove,
      customRemoveFileAction,
      removeTemporaryDocument,
      uploadTemporaryDocument,
      docType,
      openModal,
      entity,
      handleUpdateProgressStatus,
      withOutGlobalLoading,
      hiddenUpload,
      onlyOneFile,
      docs,
      screenW,
      allowed,
      customErrors,
      fileFormatAllowed,
    } = this.props;

    const { modelName, showError, serverErrors, files } = this.state;

    const requiredArray = (files) => {
      let status = true;
      if (files.length) {
        files.forEach((file) => {
          if (!file.upload) {
            status = false;
          }
        });
        return files.length > 0 && status;
      }
    };

    return (
      <div className={`gh-upload-container ${containerClass}`}>
        <Form
          model={modelName}
          onSubmit={this.uploadDocuments.bind(this)}
          validators={{
            "": { requiredArray },
          }}
        >
          {uploadTitle ? (
            <div className="gh-uz-title">
              <span>
                <img src={arrow} alt="arrow" />
              </span>
              <h4
                className={required ? "required" : ""}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(uploadTitle),
                }}
              />
            </div>
          ) : (
            ""
          )}

          {showError ? <ListValidateError errors={serverErrors} /> : null}

          <ul data-uk-grid className="disabled-list gh-uploaded-zone uk-grid-small">
            {files.map((file, index) => {
              return (
                <li
                  key={index}
                  className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@l gh-padding-remove-left gh-file-padding uk-item-file"
                >
                  {hideRemove ? (
                    <div className="gh-document-type">{file.document_type_name}</div>
                  ) : (
                    ""
                  )}
                  <UploadedFileComponent
                    index={index}
                    file={file}
                    dispatch={this.context.store.dispatch}
                    removeAction={removeTemporaryDocument}
                    customRemoveFileAction={customRemoveFileAction}
                    onRemoveSuccess={this.remove}
                    onUploadSuccess={this.finishUpload.bind(this)}
                    updateAction={uploadTemporaryDocument}
                    docType={docType}
                    openModal={openModal ? openModal : this.openPreviewFileModal}
                    entity={entity}
                    hideRemove={hideRemove}
                    handleUpdateProgressStatus={handleUpdateProgressStatus}
                    withOutGlobalLoading={withOutGlobalLoading}
                  />
                </li>
              );
            })}
          </ul>

          {hiddenUpload ? (
            ""
          ) : (
            <div
              className={`gh-upload-zone ${
                onlyOneFile && docs.length >= 1 ? "gh-area-disabled" : ""
              }`}
            >
              <DragAndDrop onDragFinish={this.handleDrop} mapProps={{ className: "js-upload" }}>
                {screenW > 576 ? (
                  <span>
                    <span className="gh-uz-text">Drag Files here</span>
                    <span className="gh-uz-prefix">or</span>
                  </span>
                ) : (
                  ""
                )}
              </DragAndDrop>
              <button
                className="uk-button gh-uz-wrapper"
                onClick={this.openFileInput}
                type="button"
              >
                {screenW > 576 ? (
                  <label
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("uploadFiles"),
                    }}
                  />
                ) : (
                  <label
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("browseFiles"),
                    }}
                  />
                )}

                <input
                  type="file"
                  name="files"
                  accept={allowed}
                  ref={(fileRef) => (this.fileRef = fileRef)}
                  onChange={() => this.handleDrop(this.fileRef.files)}
                />
              </button>

              {!customErrors && (
                <Errors
                  model={uploadTitle}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{ required }}
                />
              )}
            </div>
          )}
        </Form>

        {hiddenUpload ? (
          " "
        ) : (
          <div
            className="gh-uz-files-format"
            dangerouslySetInnerHTML={{
              __html: fileFormatAllowed || staticService.findByAlias("fileFormatsAllowed"),
            }}
          />
        )}

        {/*MODALS*/}
        <ModalComponent
          title={this.state.previewModalTitle || "Preview"}
          id="previewDocs"
          ref={(modal) => (this.modalForm = modal)}
          onClose={this.onClosePreviewFileModal}
          modalClass="gh-preview-docs-modal"
        >
          <img
            ref={(previewImg) => (this.previewImgOutput = previewImg)}
            alt="preview"
            className="hide"
          />
          <div className="gh-preview-file gh-padding-10">
            <iframe
              ref={(previewFile) => (this.previewFileOutput = previewFile)}
              title="preview"
              className="gh-preview-file-pdf hide"
            />
          </div>
        </ModalComponent>
      </div>
    );
  }

  render() {
    return <div>{this.content}</div>;
  }
}

UploadFiles.defaultProps = {
  remember: true,
  withOutGlobalLoading: false,
  handleUpdateProgressStatus: () => {},
  multiple: true,
  allowed: ".jpeg, .png, .pdf, .jpg",
};

UploadFiles.propTypes = {
  model: PropTypes.array.isRequired,
  uploadTitle: PropTypes.string,
  docs: PropTypes.array,
  remember: PropTypes.bool,
  customErrors: PropTypes.node,
  customRemoveFileAction: PropTypes.func,
  handleUpdateProgressStatus: PropTypes.func,
  withOutGlobalLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  allowed: PropTypes.string,
  containerClass: PropTypes.string,
  required: PropTypes.bool,
  hideRemove: PropTypes.bool,
  removeTemporaryDocument: PropTypes.func,
  uploadTemporaryDocument: PropTypes.func,
  docType: PropTypes.number,
  openModal: PropTypes.func,
  entity: PropTypes.string,
  hiddenUpload: PropTypes.bool,
  onlyOneFile: PropTypes.bool,
  screenW: PropTypes.number,
  fileFormatAllowed: PropTypes.string,
  modelName: PropTypes.string,
  handleChange: PropTypes.func,
  errors: PropTypes.array,
  validate: PropTypes.number,
  onFileRemove: PropTypes.func,
};

UploadFiles.contextTypes = {
  store: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    errors: state.errorObj,
    validate: state.validateDocuments.file_validation,
    screenW: state.screenDimensions.screenInnerW,
    filesState: state.filesExistState,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadTemporaryDocument,
      removeDocument,
      removeUploadedDocument,
      removeTemporaryDocument,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles);
