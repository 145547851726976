import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../../../../components";
import { UserProfileComponent } from "../common/";
import { storage } from "../../../../shared";

class BrokerProfileComponent extends UserProfileComponent {
  requestButtonProps() {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  get requestBtn() {
    return (
      <div className="uk-width-1-1">
        <ComponentAuthorization
          component={IsVerifiedAuthorization}
          componentProps={this.requestButtonProps()}
          roles={["broker_admin", "broker_team", "sole_trader"]}
        ></ComponentAuthorization>
      </div>
    );
  }
}

BrokerProfileComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = ({ company }) => {
  return {
    company: company,
  };
};

const COMPONENT = connect(mapStateToProps)(BrokerProfileComponent);
export { COMPONENT as BrokerProfileComponent };
