import React from "react";
import PropTypes from "prop-types";
import { config } from "../../configs";
import {
  arrayCompact,
  arrayFlatten,
  distanceBetween,
  getKeyByValue,
  isObjectEmpty,
  MapComponent,
  parseCoordinates,
  percentageFormula,
  filterHelliPadyByCategory,
  storage,
} from "../../shared";
import auth from "../../services/auth";
import googleApi from "../../services/google.service";

export class PadSelectionMapComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleMapPlanDrawn = this.handleMapPlanDrawn.bind(this);
    // this.mapTypeSwitch = this.mapTypeSwitch.bind(this);

    this.state = {
      currentLegPoint: {},
      curvedDirectionLines: [],
      markers: [],
      pads: [],
      zoom: config.mapZoom.default,
      mapType: "roadmap",
      isArrowInfoShown: false,
      mapKey: 0,
      isSelectLegShow: false,
      showLandingSites: true,
      clusterMarkers: [],
      mapWidth: 0,
      defaultPosition: {
        lat: 51.506011,
        lng: -0.128992,
      },
      // mapTypeSwitch: true
    };
  }

  componentDidMount() {
    const { isGeneralInfoFilled, handleLocalPointsClick, legs } = this.props;
    const rule = auth.getMainRole() === "broker" ? isGeneralInfoFilled : false;

    if (rule && legs && legs.length) {
      if (handleLocalPointsClick) {
        handleLocalPointsClick(true);
      }
      setTimeout(() => {
        const { mapRef } = this.state;
        if (mapRef) {
          const coords = mapRef.getCenter();
          const center = `${coords.lat()}|${coords.lng()}`;
          this.handleLocalPoints(parseCoordinates(center));
        }
      }, 100);
    }
    if (this.props.legs.length === 0) {
      setTimeout(() => this.setState({ mapWidth: "100%" }));
    } else {
      this.setState({ mapWidth: "100%" });
    }
  }

  componentWillReceiveProps({
    legs,
    showLandingsPads: nextShowLandingsPads,
    handleLocalPointsClick,
    flightType,
    isGeneralInfoFilled: nextIsGeneralInfoFilled,
  }) {
    if (
      auth.getMainRole() === "broker" &&
      this.props.legs.length !== legs.length &&
      legs.length &&
      handleLocalPointsClick
    ) {
      handleLocalPointsClick(true);
    }
    if (nextIsGeneralInfoFilled) {
      setTimeout(() => this.setState({ mapWidth: "100%" }), 200);
    }
    const markers = this.getLandingSitesMarkers(legs, {
      currentShowLandingsPads: this.props.showLandingsPads,
      nextShowLandingsPads,
    });
    const flightPlanCoordinates = this.getFlightPlanCoordinatesFromLegs(legs);
    const stateMarkers = this.state.markers;
    markers.map((marker, index) => {
      if (
        stateMarkers.length === 0 ||
        marker.lat !== stateMarkers[index].lat ||
        marker.lng !== stateMarkers[index].lng
      ) {
        this.setState({ markers, flightPlanCoordinates });
      } else {
        this.setState({ stateMarkers, flightPlanCoordinates });
      }
    });

    if (flightType !== this.props.flightType) {
      this.setState({ pad: [], markers: [], flightPlanCoordinates: [] });
    }
  }

  resetMarkers() {
    this.setState({ pads: [] });
  }

  getLandingSitesMarkers(legs = [], { currentShowLandingsPads, nextShowLandingsPads }) {
    const { mapRef } = this.state;
    if (nextShowLandingsPads && currentShowLandingsPads !== nextShowLandingsPads && mapRef) {
      const coords = mapRef.getCenter();
      const center = `${coords.lat()}|${coords.lng()}`;
      this.handleLocalPoints(parseCoordinates(center), legs);
    }
    if (!nextShowLandingsPads) {
      this.resetMarkers();
    }
    let markers = legs.map((leg, index) => {
      const duplicatedLegs = this.getLegsWithSamePoint(leg, index, legs); // displaying in InfoWindow suggestion with duplicated legs
      // const fromMarker = JSON.parse(storage.get("landingSites")).find(
      //   (m) =>
      //     m.location.latitude === parseCoordinates(leg.from_coords).lat &&
      //     m.location.longitude === parseCoordinates(leg.from_coords).lng,
      // );
      // const toMarker = JSON.parse(storage.get("landingSites")).find(
      //   (m) =>
      //     m.location.latitude === parseCoordinates(leg.to_coords).lat &&
      //     m.location.longitude === parseCoordinates(leg.to_coords).lng,
      // );
      // if (index === 0) {
      return [
        {
          ...parseCoordinates(leg.from_coords),
          onClick: () => this.handleMarkerClick(parseCoordinates(leg.from_coords), leg),
          closePopUp: this.closePopups,
          name: leg.from,
          type: leg.from_type || leg.from_location.type,
          hasLegPointsDuplication: false,
          rating: leg.rating,
          image: leg.upload && leg.upload.file_url,
          reviews: leg.comments_count,
          duplicates: duplicatedLegs,
          isSecondary: false,
          isInfoShown: false,
          isSelectLegInfoShown: false,
        },
        {
          ...parseCoordinates(leg.to_coords),
          onClick: () => this.handleMarkerClick(parseCoordinates(leg.to_coords), leg),
          closePopUp: this.closePopups,
          name: leg.to,
          type: leg.to_type || leg.to_location.type,
          rating: leg.rating,
          image: leg.upload && leg.upload.file_url,
          reviews: leg.comments_count,
          hasLegPointsDuplication: false,
          duplicates: duplicatedLegs,
          isSecondary: false,
          isInfoShown: false,
          isSelectLegInfoShown: false,
        },
      ];
    });
    return arrayFlatten(markers);
  }

  getLegsWithSamePoint(current, index, legs) {
    const duplications = legs.map((leg, i) => {
      if (current.from_coords === leg.from_coords && i === 0) {
        return { id: leg.id, current: "from", direction: "from", legIndex: i };
      }

      if (current.to_coords === leg.to_coords) {
        return { id: leg.id, current: "to", direction: "to", legIndex: i };
      }

      if (current.from_coords === leg.to_coords && index !== i + 1) {
        return { id: leg.id, current: "from", direction: "to", legIndex: i };
      }

      if (current.to_coords === leg.from_coords && index !== i - 1) {
        return { id: leg.id, current: "to", direction: "from", legIndex: i };
      }
    });

    return arrayCompact(duplications);
  }

  getFlightPlanCoordinatesFromLegs(legs = []) {
    return arrayFlatten(
      legs.map((leg, index) => {
        // if (index === 0) {
        return [parseCoordinates(leg.from_coords), parseCoordinates(leg.to_coords)];
        // }
        // return parseCoordinates(leg.to_coords);
      }),
    );
  }

  showAvailablePads({ markerCoordinates, legId, direction, legIndex, legs }) {
    this.closePopups();
    const offset = (10000 * 0.0001) / 4.3496;
    const markersArray = (markerCoords) => {
      return JSON.parse(storage.get("landingSites")).filter((obj) => {
        const { location: m } = obj;
        return (
          m.latitude >= markerCoords.lat - offset &&
          m.latitude <= markerCoords.lat + offset &&
          m.longitude >= markerCoords.lng - offset &&
          m.longitude <= markerCoords.lng + offset
        );
      });
    };
    const pads = () => {
      if ((legs && legs.length) || (this.props.legs && this.props.legs.length)) {
        const leg = legs ? legs[0] : this.props.legs[0];
        let coordsFrom = {
          lat: Number(leg.from_coords.split("|")[0]),
          lng: Number(leg.from_coords.split("|")[1]),
        };
        let coordsTo = {
          lat: Number(leg.to_coords.split("|")[0]),
          lng: Number(leg.to_coords.split("|")[1]),
        };

        return [...markersArray(coordsFrom), ...markersArray(coordsTo)];
      } else {
        return markersArray(markerCoordinates);
      }
    };
    setTimeout(() => {
      this.setState({
        pads: this.getAvailablePadsMarkers({
          pads: pads(),
          legId,
          direction,
          markerCoordinates,
          legIndex,
        }),
      });
    }, 100);
  }

  getAvailablePadsMarkers({ pads = [], legId, direction, markerCoordinates, legIndex }) {
    return arrayCompact(
      pads.map((pad) => {
        let coords = { lat: pad.location.latitude, lng: pad.location.longitude };
        const notSecondary = this.state.markers.find(
          (m) => m.lat === coords.lat && m.lng === coords.lng,
        );
        return {
          ...coords,
          onClick: () => this.togglePadMarkerInfo(pad),
          onAssignButtonClick: (legSide) =>
            this.handleAssignPad({
              coords,
              legId,
              title: pad.name,
              direction,
              markerCoordinates,
              legIndex: this.props.index,
              pad_id: pad.id,
              place_id: pad.id,
              legSide,
              placeInfo:
                pad.category.display_name +
                ", " +
                pad.location.county +
                ", " +
                pad.location.country,
            }),
          // onMouseOver: () => this.togglePadMarkerInfo(pad, true),
          // onMouseOut: () => this.togglePadMarkerInfo(pad, false),
          assignLegButton: (val) => this.setState({ isSelectLegShow: val }),
          name: pad.name,
          rating: pad.rating,
          image: pad.upload && pad.upload.file_url,
          reviews: pad.comments_count,
          type: pad.category.display_name,
          source: pad.view_link,
          distance: distanceBetween(markerCoordinates, coords, "N"),
          isSecondary: !notSecondary,
          isInfoShown: false,
        };
      }),
    );
  }

  handleAssignPad({
    coords,
    legId: id,
    title,
    direction,
    markerCoordinates: initialCoords,
    legIndex,
    pad_id: place_id,
    legSide,
    placeInfo,
  }) {
    const leg = this.props.legs.find((i) => id === i.id);

    if (!isObjectEmpty(leg)) {
      this.props.handleAssignPad({
        id,
        direction,
        coords,
        title,
        initialCoords,
        legIndex: this.props.legIndex,
        place_id,
        legSide,
        placeInfo,
      });
    }
    //on closing pad info window reset zoom to normal
    this.setState({ zoom: config.mapZoom.min });
  }

  togglePadMarkerInfo(padMarker) {
    this.closePopups();
    this.setState((prevState) => ({
      ...prevState,
      isSelectLegShow: false,
      pads: prevState.pads.map((marker) => {
        if (
          padMarker.location.latitude === marker.lat &&
          padMarker.location.longitude === marker.lng
        ) {
          return { ...marker, isInfoShown: !marker.isInfoShown };
        } else {
          return marker;
        }
      }),
    }));

    // this.setState((prevState) => {
    //   const updatedPads = prevState.pads
    //     .filter((marker) => padMarker.location.latitude !== marker.lat && padMarker.location.longitude !== marker.lng)
    //     .map((marker) => ({ ...marker, isInfoShown: false }));
    //   // const currentPads = prevState.pads
    //   //   .filter((marker) => padMarker.location.latitude === marker.lat && padMarker.location.longitude === marker.lng)
    //   //   .map((marker) => ({ ...marker, isInfoShown: state }));
    //
    //   const updatedMarkers = prevState.markers
    //     .filter((marker) => padMarker.location.latitude === marker.lat && padMarker.location.longitude === marker.lng)
    //     .map((marker) => ({ ...marker, isInfoShown: state }));
    //
    //   return {
    //     ...prevState,
    //     pads: currentPads,
    //   };
    // });
  }

  toggleMarkerInfo(marker, state) {
    this.closePopups();
    this.setState((prevState) => {
      const updatedMarkers = prevState.markers.map((stateMarker) => {
        if (stateMarker.lat === marker.lat && stateMarker.lng === marker.lng) {
          return { ...stateMarker, isInfoShown: !stateMarker.isInfoShown };
        } else {
          return stateMarker;
        }
      });

      return {
        ...prevState,
        markers: [...updatedMarkers],
      };
    });
  }

  handleMapPlanDrawn(curvedDirectionLines = []) {
    if (this.props.returnFlight) {
      this.setState({ curvedDirectionLines });
    } else {
      const lines =
        curvedDirectionLines[0] && curvedDirectionLines[0].filter((_, i) => i % 2 === 0);
      this.setState({ curvedDirectionLines: lines });
    }
  }

  onMapClick(map) {
    if (auth.getMainRole() !== "broker" || this.props.review) {
      return;
    }
    let coords = { lat: map.latLng.lat(), lng: map.latLng.lng() };
    const updatedMarkers = [...this.state.markers];
    if (updatedMarkers.length && updatedMarkers.length > 2) {
      updatedMarkers.splice(updatedMarkers.length - 1, 1);
    }
    this.closePopups();
    const geocoder = new google.maps.Geocoder();
    (() => {
      geocoder
        .geocode({ location: coords })
        .then((res) => {
          if (res.results[0]) {
            const result = res.results[0];
            this.setState({
              markers: [
                ...updatedMarkers,
                {
                  ...coords,
                  onClick: () => this.toggleMarkerInfo(coords, true),
                  // onMouseOver: (state) => this.toggleMarkerInfo(coords, state),
                  // onMouseOut: (state) => this.toggleMarkerInfo(coords, state),
                  handleAssign: (legSide) =>
                    this.handleAssignPad({
                      coords,
                      title: result.formatted_address,
                      legIndex: this.props.index,
                      pad_id: result.place_id,
                      legSide,
                    }),
                  assignLegButton: (val) => this.setState({ isSelectLegShow: val }),
                  name: result.formatted_address,
                  isNewMarker: true,
                  isInfoShown: false,
                },
              ],
            });
          } else {
            window.alert("No results found");
          }
        })
        .catch((e) => window.alert("Geocoder failed due to: " + e));
    })();
    this.state.mapRef.panTo(map.latLng);
  }

  handleLocalPoints(coords, legs) {
    this.closePopups();
    if (!this.props.review) {
      // this.showAvailablePads({
      //   markerCoordinates: coords,
      //   radius
      // });
      // const { mapRef } = this.state;
      // const bounds = mapRef.getBounds();
      // const center = mapRef.getCenter();
      // let radius = null;
      // if (bounds && center) {
      //   const ne = bounds.getNorthEast();
      //   // Calculate radius (in meters).
      //   radius = google.maps.geometry.spherical.computeDistanceBetween(center, ne);
      // }
      // if (isNumber(radius)) {
      this.showAvailablePads({
        markerCoordinates: coords,
        legs,
        // radius,
      });
      // }
    }
  }

  handleMarkerClick(coords, leg) {
    this.closePopups();
    const { markers } = this.state;
    if (!this.props.review && markers.length && markers.length > 2) {
      markers.splice(markers.length - 1, 1);
    }
    if (!this.props.review) {
      this.showSelectLegWindow(coords);
    }
  }

  showSelectLegWindow({ lat, lng }) {
    this.setState((prevState) => ({
      ...prevState,
      markers: prevState.markers.map((marker) => {
        if (marker.lat === lat && marker.lng === lng) {
          return {
            ...marker,
            isSelectLegInfoShown: true,
          };
        } else {
          return marker;
        }
      }),
    }));
  }

  closePopups() {
    this.setState((prevState) => ({
      ...prevState,
      markers: prevState.markers.map((i) => {
        if (i.isSelectLegInfoShown) {
          return { ...i, isSelectLegInfoShown: false };
        }
        return i;
      }),
      pads: prevState.pads.map((i) => {
        return { ...i, isInfoShown: false };
      }),
    }));
  }

  get mapHeight() {
    let height = null;

    if (this.props.screenDimensions.screenH > this.props.screenDimensions.screenW) {
      height =
        this.props.screenDimensions.screenH -
        percentageFormula(this.props.screenDimensions.screenH, 50);
    }
    height =
      this.props.screenDimensions.screenW -
      percentageFormula(this.props.screenDimensions.screenW, 50);
    if (config.maxAllowedMapeHeight > height) return height;
    return config.maxAllowedMapeHeight;
  }

  componentWillUnmount() {
    this.setState({ pads: [] });
  }

  render() {
    if (this.props.visible) {
      let {
        markers: mainLandingSites = [],
        pads,
        curvedDirectionLines = [],
        flightPlanCoordinates = [],
        isSelectLegShow,
        clusterMarkers,
        mapRef,
      } = this.state;

      let { classNames, visible, position } = this.props;

      const landingSites = mainLandingSites.map((item, index, arr) => {
        let itemsWithSameCoords = arr.filter((i) => i.lat === item.lat && i.lng === item.lng);

        if (itemsWithSameCoords.length > 1) {
          let currentsIndex = arr.indexOf(itemsWithSameCoords[0]);
          if (index === currentsIndex) {
            return {
              ...item,
              duplicates: item.duplicates.filter((i) =>
                itemsWithSameCoords[0].duplicates.includes(i),
              ),
            };
          }
          return null;
        }
        return item;
      });

      const markers = [...arrayCompact(landingSites), ...pads];
      const setArrowInfoShown = (val) => this.setState({ isArrowInfoShown: val });
      const setClusterMarker = (markers) => this.setState({ clusterMarkers: markers });
      const setMapRef = (ref) => this.setState({ mapRef: ref });
      const changeSelectLegShow = () => this.setState({ isSelectLegShow: false });

      return (
        <div className={classNames}>
          {/*<button*/}
          {/*  className="uk-button uk-button-small uk-button-default gh-map-type"*/}
          {/*  onClick={e => this.mapTypeSwitch(e)}>*/}
          {/*  {this.state.mapTypeSwitch ? 'satellite' : 'roadmap'}*/}
          {/*</button>*/}
          <MapComponent
            id={this.props.id}
            containerElement={<div style={{ height: this.mapHeight + `px` }} />}
            mapElement={<div style={{ width: this.state.mapWidth, height: `100%` }} />}
            zoom={this.state.zoom}
            markers={markers}
            visible={visible}
            position={position}
            flightPlanCoordinates={[flightPlanCoordinates]}
            curvedDirectionLines={curvedDirectionLines}
            onDirectionsDrawn={this.handleMapPlanDrawn}
            mapType={this.state.mapType}
            setArrowInfoShown={setArrowInfoShown}
            isArrowInfoShown={this.state.isArrowInfoShown}
            setMapRef={setMapRef}
            showLandingsPads={this.props.showLandingsPads}
            closePopups={this.closePopups.bind(this)}
            isSelectLegShow={isSelectLegShow}
            changeSelectLegShow={changeSelectLegShow}
            onMapClick={this.onMapClick.bind(this)}
            setClusterMarker={setClusterMarker}
            clusterMarkers={clusterMarkers}
            mapRef={mapRef}
          />
        </div>
      );
    }
    return null;
  }
}

PadSelectionMapComponent.propTypes = {
  visible: PropTypes.bool,
  onLegUpdated: PropTypes.func,
  legs: PropTypes.array,
  classNames: PropTypes.string,
  position: PropTypes.object,
  screenDimensions: PropTypes.any,
  updateMap: PropTypes.number,
};
