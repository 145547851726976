import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import "./QuotePriceProcessingFees.css";
import staticService from "../../../../../services/static.service";
import Radio from "../../../../common/Radio";
import purpleCheck from "../../../../../assets/img/svg/purpleCheck.svg";
import greenCheck from "../../../../../assets/img/svg/greenCheck.svg";
import stripeLogo from "../../../../../assets/img/svg/stripe_logo_purple.svg";
import { BOOKING_INIT, EUROPEAN_FEE, INTERNATIONAL_FEE, NO_FEE } from "../../../../../configs";
import Auth from "../../../../../services/auth";
import clsx from "clsx";

/*todo: review isRenderedByBrokerBookingDetails => line 94 and line 101 */

const QuotePriceProcessingFees = (props) => {
  const {
    currency,
    handleSelectFee,
    feeSelected,
    stripeFees,
    bookingStatus,
    renderBy,
    isOperatorStripeVerified,
    calculatedPrice,
  } = props;

  const isManualBankTransferAvailable = useMemo(
    () => Auth.hasFeature("manual_bank_transfer"),
    [Auth.hasFeature("manual_bank_transfer")],
  );
  const isSelected = useCallback(
    (feeType) => (feeSelected === feeType ? "gh-fee-selected-btn" : ""),
    [feeSelected],
  );

  const isBookingInit = bookingStatus === BOOKING_INIT;
  const isRenderedByBrokerBookingDetails = renderBy === "BrokerBookingDetails";
  const manualBankTransferClassName =
    isSelected(NO_FEE) && !isManualBankTransferAvailable
      ? "gh-select-fee-button-not-available"
      : isSelected(NO_FEE)
      ? "gh-select-fee-button-green"
      : "";

  React.useEffect(() => {
    !isOperatorStripeVerified && handleSelectFee(NO_FEE);
  }, [isOperatorStripeVerified]);

  /*===========Cards Disabling===========*/
  //disable radio button handler
  const areFeesRadioButtonsDisabled =
    !isOperatorStripeVerified || isBookingInit || isRenderedByBrokerBookingDetails;
  const handleSelectManualBankTransfer = () =>
    isManualBankTransferAvailable && !isBookingInit && !isRenderedByBrokerBookingDetails
      ? handleSelectFee(NO_FEE)
      : null;

  //view: changing contrast
  const disableFeeCard = (isBookingInit && isSelected(NO_FEE)) || !isOperatorStripeVerified;
  const disableManualBankTransferCard =
    (!isManualBankTransferAvailable && !isBookingInit) ||
    (isBookingInit && !isSelected(NO_FEE)) ||
    (isRenderedByBrokerBookingDetails && !isSelected(NO_FEE));

  return (
    <>
      <FeesHeader
        currency={currency}
        isRenderedByBrokerBookingDetails={isRenderedByBrokerBookingDetails}
        isBookingInit={isBookingInit}
        isSelected={isSelected}
        stripeFees={stripeFees}
      />
      {!isRenderedByBrokerBookingDetails && (
        <div className={"gh-quote-details-buttons-wrapper"}>
          <div className="gh-quote-details-no-fee-popover-wrapper uk-margin-small-right">
            <div
              className={clsx("gh-quote-details-card-payment-wrapper", {
                "gh-quote-details-card-payment-selected-fee":
                  isSelected(EUROPEAN_FEE) || isSelected(INTERNATIONAL_FEE),
                "gh-fees-card-disabled": disableFeeCard,
              })}
            >
              <div className="gh-quote-details-title-bold gh-text-black uk-margin-small-bottom">
                Card payment
              </div>
              <Radio
                value={EUROPEAN_FEE}
                name="payment-fee-selector"
                onChange={handleSelectFee}
                selected={feeSelected === EUROPEAN_FEE || (isBookingInit && !!isSelected(NO_FEE))}
                color="purple"
                disabled={areFeesRadioButtonsDisabled}
              >
                European Card
              </Radio>
              <Radio
                value={INTERNATIONAL_FEE}
                name="payment-fee-selector"
                onChange={handleSelectFee}
                selected={feeSelected === INTERNATIONAL_FEE}
                color="purple"
                disabled={areFeesRadioButtonsDisabled}
              >
                International Card
              </Radio>
              <img
                src={purpleCheck}
                alt="icon"
                className="gh-quote-details-card-payment-img purple-check"
              />
            </div>
            {!isOperatorStripeVerified && (
              <span className="gh-quote-details-no-fee-popover gh-quote-details-no-fee-popover-bigger">
                {staticService.findByAlias("stripeOnboardingOperatorNotVerified")}
              </span>
            )}
          </div>
          <div className="gh-quote-details-no-fee-popover-wrapper">
            <div
              onClick={handleSelectManualBankTransfer}
              className={clsx("gh-quote-details-card-payment-wrapper", {
                "btn-style": !isBookingInit && !isRenderedByBrokerBookingDetails,
                "gh-quote-details-card-payment-selected-no-fee": isSelected(NO_FEE),
                "gh-fees-card-disabled": disableManualBankTransferCard,
              })}
            >
              <div
                className="gh-quote-details-title-bold gh-text-black uk-margin-small-bottom"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("bankTransferLabel"),
                }}
              />
              <div
                className={clsx(
                  "gh-quote-details-text-normal",
                  manualBankTransferClassName,
                  isSelected(NO_FEE),
                )}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("manualBankTransferTransaction"),
                }}
              />
              <img src={greenCheck} alt="icon" className="gh-quote-details-card-payment-img" />
            </div>
            {!isManualBankTransferAvailable && !isBookingInit && (
              <span className="gh-quote-details-no-fee-popover">
                Only available upon subscription. Please contact@getheli.com to enable this feature.
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
QuotePriceProcessingFees.propTypes = {
  currency: PropTypes.string.isRequired,
  feeSelected: PropTypes.string,
  stripeFees: PropTypes.string,
  bookingStatus: PropTypes.string,
  handleSelectFee: PropTypes.func,
  renderBy: PropTypes.string,
  isOperatorStripeVerified: PropTypes.bool,
};
QuotePriceProcessingFees.defaultProps = {};

export default QuotePriceProcessingFees;

/* eslint react/prop-types: 0 */
const FeesHeader = ({
  isSelected,
  currency,
  stripeFees,
  isBookingInit,
  isRenderedByBrokerBookingDetails,
}) => {
  return (
    <div className="gh-quote-details-details-row gh-quote-details-quote-price-details-row animated">
      <div
        className="gh-quote-details-text-normal gh-quote-details-value"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("includingTitle") }}
      />
      <div className="gh-quote-details-text-normal gh-quote-details-value">
        <span className="gh-quote-details-title-bold">
          {currency} {stripeFees}
        </span>
        {" of "}
        <span className="gh-quote-stripe-logo">
          <img src={stripeLogo} alt="Stripe" style={{ transform: "translateY(-1px)" }} />
        </span>
        <span>
          {staticService.findByAlias("stripePaymentPreccessingFee")}
          {!isBookingInit &&
            !isRenderedByBrokerBookingDetails &&
            !isSelected(NO_FEE) &&
            ", based on selected card"}
        </span>
      </div>
    </div>
  );
};
