import React, { useEffect, useRef, useState } from "react";
import auth from "../../../services/auth";
import condStrings from "../../../utils/condString";
import Tooltip from "../Tooltip";

import "./styles/index.css";

function UserInitials() {
  const company = auth.getCompanyFromLocalStorage();
  const progressCirlceRef = useRef();
  const [dashoffset, setDashoffset] = useState(0);
  const [dasharray, setDasharray] = useState(0);
  const [status, setStatus] = useState(false);

  const showCheckIcon = company.sign_up_steps.status.strip_full_verification;

  useEffect(() => {
    if (progressCirlceRef.current) {
      const circleTotalLen = 106.1258544921875;

      setDasharray(circleTotalLen);
      setDashoffset(circleTotalLen);

      if (company.sign_up_steps) {
        setStatus(company.sign_up_steps.status.status);

        const accoutProgress = (circleTotalLen / 100) * company.sign_up_steps.status.percentage;
        setDasharray(circleTotalLen + accoutProgress);

        if (company.sign_up_steps.status.percentage === 100) {
          setDashoffset(circleTotalLen - 1);
        }
      }
    }
  }, [company]);

  const userInitialasOverlay = () => {
    if (company && company.sign_up_steps) {
      if (company.sign_up_steps.status.info) {
        return company.sign_up_steps.status.info.replaceAll(
          "<a",
          "<a onclick='event.stopPropagation()' ",
        );
      }
    }

    return "";
  };

  return (
    <Tooltip
      pos="bottom-left"
      className="user-initials-warn"
      disabled={!userInitialasOverlay()}
      trigger={
        <div className={condStrings("gh-user-initials", !status && "uncompleted")}>
          {auth.fullNameInitials}
          <svg className="gh-user-initials-progress" height="36" width="36" ref={progressCirlceRef}>
            <circle
              cx="18"
              cy="18"
              r="17"
              strokeWidth="2"
              strokeDashoffset={dashoffset}
              strokeDasharray={dasharray}
              fill="transparent"
            />
          </svg>
          <span className="gh-user-initials-info"> {showCheckIcon ? CheckIcon : "!"} </span>
        </div>
      }
    >
      <span dangerouslySetInnerHTML={{ __html: userInitialasOverlay() }} />
    </Tooltip>
  );
}

UserInitials.propTypes = {};
UserInitials.defaultProps = {};
export default UserInitials;

const CheckIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="check"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="svg-inline--fa fa-check fa-w-16 fa-7x"
  >
    <path
      fill="currentColor"
      d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
      className=""
    />
  </svg>
);
