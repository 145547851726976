import React from "react";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isObjectEmpty, skipRouteSegments } from "../../../../../shared";
import { BlockComponent } from "../../../../common";
import { TacComponent } from "../../../../forms/";
import staticService from "../../../../../services/static.service";
import {
  downloadAgreement,
  loadTermCondition,
  getQuote,
  getBrokerRequestDetail,
} from "../../../../../actions";
import { BrokerRequestNav } from "../broker-request-nav.component";
import CustomLoader from "../../../../common/CustomLoader/customLoader";

class BrokerPaymentTermConditionReviewComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      backRoute: skipRouteSegments(this.props.path, 4),
      nextRoute: false,
      closeRequestDialog: false,
      continueRoute: "",
      agreement: [],
      helicopterServicesTerms: null,
      isQuoteLoading: false,
    };
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.context.store.dispatch(getBrokerRequestDetail(this.props.path.split("/")[3]));
  }

  componentDidUpdate() {
    const { quoteDetail, request, parentProps } = this.props;
    const { helicopterServicesTerms, isQuoteLoading } = this.state;

    if (!helicopterServicesTerms && quoteDetail.helicopter_services_terms?.length) {
      const terms = quoteDetail.helicopter_services_terms[0];
      const fileFormat = terms.type.split("/")[1];

      this.setState({ helicopterServicesTerms: { url: terms.file_url, format: fileFormat } });
    }

    if (!request.id && !isQuoteLoading) {
      this.setState({ isQuoteLoading: true });
      this.props
        .getQuote(parentProps.match.params.quoteID)
        .then(() => {
          this.setState({ isQuoteLoading: false });
        })
        .catch(() => {
          this.setState({ isQuoteLoading: false });
        });
    }
  }

  componentDidMount() {
    const quote_id = this.props.path.split("/")[5];
    this.props
      .loadTermCondition(`BROKER_FLIGHT_AGREEMENT?quote_id=${quote_id}`)
      .then(({ data }) => {
        if (data) {
          const agreement = this.props.agreements.filter(
            (agreement) => agreement.type === "BROKER_FLIGHT_AGREEMENT",
          );
          if (agreement.length > 0) {
            this.setState({ agreement: agreement });
          }
        }
      });

    if (!this.props.request.id) {
      this.props.getQuote(this.props.parentProps.match.params.quoteID);
    }
  }

  changeRedirectState(state) {
    this.setState({
      nextRoute: true,
      continueRoute:
        state === 1
          ? `${skipRouteSegments(this.props.path, 6)}/step/3${window.location.search}`
          : this.props.parentProps.parentRoute.url +
            "/quote/" +
            this.props.parentProps.match.params.quoteID +
            window.location.search,
    });
  }

  get isAllowed() {
    const { request } = this.props;

    return !!(
      request &&
      request.status !== "expired" &&
      request.status !== "closed" &&
      request.status !== "withdrawn"
    );
  }

  get agreementFromProps() {
    return this.props.agreements.length > 0 ? this.props.agreements[0] : {};
  }

  get Agreement() {
    const { agreement } = this.state;
    return agreement.length > 0 ? agreement[0] : this.agreementFromProps;
  }

  render() {
    const { request, match, parentProps, quoteDetail, path, downloadAgreement, history } =
      this.props;
    const { nextRoute, continueRoute, helicopterServicesTerms } = this.state;

    if (isObjectEmpty(quoteDetail)) return <CustomLoader />;

    if (nextRoute) return <Redirect to={continueRoute} />;

    if (
      quoteDetail.terms_status &&
      !quoteDetail.terms_status.country_terms_list.helicopter_services_terms
    ) {
      if (!quoteDetail.helicopter_services_terms?.length) {
        return <Redirect to={`${skipRouteSegments(path, 6)}/step/3${window.location.search}`} />;
      }
    }

    const downloadCallback = request.id ? downloadAgreement.bind(this, request.id) : null;

    return (
      <div>
        {Object.keys(request).length ? (
          <BrokerRequestNav
            match={match}
            isAllowed={this.isAllowed}
            requestDetail={request}
            backButton={staticService.findByAlias("backToQuote")}
            backPath={`/flights/requests/${request.id}/quote/${parentProps.match.params.quoteID}`}
            history={history}
          />
        ) : (
          ""
        )}
        <BlockComponent visible={true} parentProps={{ className: "gh-broker-terms" }}>
          <div className={"uk-margin-top uk-margin-bottom"}>
            <TacComponent
              html={this.Agreement.description || ""}
              agreeCallback={this.changeRedirectState.bind(this)}
              visible={true}
              classNames={"default"}
              isFull={false}
              flightTerms={staticService.findByAlias("flightTermsTitle")}
              downloadCallback={downloadCallback}
              showOnlyButtons={
                !quoteDetail.terms_status.country_terms_list.helicopter_services_terms &&
                !!quoteDetail.helicopter_services_terms.length
              }
            />

            {!quoteDetail.terms_status.country_terms_list.helicopter_services_terms &&
            helicopterServicesTerms ? (
              <div style={{ marginBottom: 100 }}>
                {helicopterServicesTerms.format === "pdf" ? (
                  <iframe
                    src={helicopterServicesTerms.url}
                    title="preview"
                    className="gh-preview-file-pdf"
                  />
                ) : (
                  <div>
                    <img src={helicopterServicesTerms.url} alt="Services terms" />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </BlockComponent>
      </div>
    );
  }
}

BrokerPaymentTermConditionReviewComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerPaymentTermConditionReviewComponent.propTypes = {
  parentProps: PropTypes.object,
  request: PropTypes.object,
  match: PropTypes.object,
  quoteDetail: PropTypes.object,
  path: PropTypes.string,
  downloadAgreement: PropTypes.func,
  history: PropTypes.object,
  loadTermCondition: PropTypes.func,
  agreements: PropTypes.array,
  getQuote: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    agreements: state.termsCondition,
    request: state.requestDetail,
    quoteDetail: state.quoteDetail,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      downloadAgreement,
      loadTermCondition,
      getBrokerRequestDetail,
      getQuote,
    },
    dispatch,
  );
};

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrokerPaymentTermConditionReviewComponent);
export { COMPONENT as BrokerPaymentTermConditionReviewComponent };
