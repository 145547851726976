import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { actions } from "react-redux-form";
import staticService from "../../services/static.service";
import Auth from "../../services/auth";
import Tooltip from "../../components/common/Tooltip";
import LoadingComponent from "../../components/common/LoadingComponent";
import Button from "../../components/common/Button";
import compareArrays from "../../utils/compareArrays";
import Spinner from "../../components/common/Spinner";

class ComplexTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.actionColumns = this.actionColumns.bind(this);
    this.headerActionColumns = this.headerActionColumns.bind(this);
    this.emitSortChange = this.emitSortChange.bind(this);
    this.getHeaderItem = this.getHeaderItem.bind(this);
    this.getCss = this.getCss.bind(this);
    this.state = {
      displayedTooltip: null,
      loadingShowMoreBtn: false,
      loadingOnSorting: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { loadingOnSorting } = this.state;
    const { rows } = this.props;

    if (loadingOnSorting && rows.length && !compareArrays(rows, prevProps.rows)) {
      this.setState({ loadingOnSorting: false });
    }

    if (rows.length !== prevProps.rows.length) {
      this.setState({ loadingShowMoreBtn: false });
    }

    if (loadingOnSorting && !rows.length) {
      this.setState({ loadingOnSorting: false });
    }
  }

  getIdFromTitle(title) {
    return title.toLowerCase().replace(" ", "_");
  }

  loadMore() {
    this.setState({ loadingShowMoreBtn: true });
    this.props.dispatch && this.props.dispatch(actions.change("isConcat", true));
    this.props.loadMore();
  }

  getCss(col) {
    let css = ["gh-complex-table-td"];
    if (this.props.headers[col].css) {
      css.push(this.props.headers[col].css);
    }
    return css;
  }

  getColumn(col, columns) {
    return columns.map((field, i) => (
      <td
        className={
          this.getCss(i).join(" ") +
          ` ${
            this.props.alignLastCell && (columns.length === i + 1 || columns.length - 1 === i + 1)
              ? "gh-company-table-align-last-cell"
              : ""
          }`
        }
        key={i}
      >
        {col[field] || this.props.emptyCellContent}
      </td>
    ));
  }

  getColumnLink(col, columns) {
    return columns.map((field, i) => {
      if (field === "displayID") {
        const routeOperator = `/flights/my-bookings/${col.booking}`;
        const routeBroker =
          col.booking === ""
            ? `/flights/requests/${col.request}`
            : `/flights/my-bookings/${col.booking}`;
        return (
          <td className={this.getCss(i).join(" ")} key={i}>
            <NavLink to={Auth.getMainRole() === "operator" ? routeOperator : routeBroker}>
              {col[field]}
            </NavLink>
          </td>
        );
      } else {
        return (
          <td className={this.getCss(i).join(" ")} key={i}>
            {col[field] || this.props.emptyCellContent}
          </td>
        );
      }
    });
  }

  getMobileColumnLink(field, col) {
    if (field === "displayID") {
      const routeOperator = `/flights/my-bookings/${col.booking}`;
      const routeBroker =
        col.booking === ""
          ? `/flights/requests/${col.request}`
          : `/flights/my-bookings/${col.booking}`;

      return (
        <NavLink to={Auth.getMainRole() === "operator" ? routeOperator : routeBroker}>
          {col[field]}
        </NavLink>
      );
    }
    return col[field];
  }

  credentialsColumnLink(col, columns) {
    return columns.map((field, i) => (
      <td className={this.getCss(i).join(" ")} key={i}>
        {field === "docs_url" ? (
          <a href={col[field]} className="gh-button-simple">
            {staticService.findByAlias("goToUrl")}
          </a>
        ) : (
          col[field]
        )}
      </td>
    ));
  }

  actionColumns(index) {
    if (this.props.showActionColumn > 0) {
      return this.props.actionElements(index);
    }
  }

  headerActionColumns() {
    if (this.props.showActionColumn > 0) {
      return Array.apply(null, {
        length: this.props.showActionColumn,
      }).map((col, i) => <th key={i}>{this.props.actionsHeaders[i] || ""}</th>);
    }
  }

  emitSortChange(column, order) {
    if (this.state.loadingOnSorting) return;

    if (this.props.sortChange) {
      this.setState({ loadingOnSorting: true });
      return this.props.sortChange(column, order);
    }
  }

  get loaderButton() {
    const { loadingShowMoreBtn } = this.state;
    const { showLoader, rows, showMoreText } = this.props;

    if (showLoader && rows.length > 0) {
      return (
        <div className={"uk-flex uk-flex-center uk-position-relative uk-margin-small-top"}>
          <Button
            color="light"
            variant="outlined"
            loading={loadingShowMoreBtn}
            onClick={this.loadMore.bind(this)}
          >
            {showMoreText}
          </Button>
        </div>
      );
    }

    return null;
  }

  get emptyRow() {
    const { columns, showActionColumn, emptyRowMessage, loadingMessage, firstTimeLoading } =
      this.props;

    const { loadingOnSorting } = this.state;

    return (
      <tr>
        <td colSpan={columns.length + showActionColumn} className={"uk-text-center gh-empty-row"}>
          {loadingOnSorting || !firstTimeLoading ? loadingMessage : emptyRowMessage}
        </td>
      </tr>
    );
  }

  get emptyRowMob() {
    return (
      <li className={"uk-text-center uk-margin-top uk-margin-medium-bottom gh-empty-row-mob"}>
        {this.props.firstTimeLoading ? (
          <div>
            <p>{this.props.emptyRowMessage}</p>
            {this.props.emptyRowButton.link && this.props.emptyRowButton.label && (
              <NavLink
                onClick={this.props.emptyRowButton.onClick}
                to={this.props.emptyRowButton.link}
                className="uk-margin-small-top uk-button uk-button-primary"
              >
                {this.props.emptyRowButton.label}
              </NavLink>
            )}
          </div>
        ) : this.props.isLoading ? (
          this.props.loadingMessage
        ) : (
          this.props.emptyRowMessage
        )}
      </li>
    );
  }

  reverseSortOrder(order) {
    return order === "asc" ? "desc" : "asc";
  }

  getSortIcon(isActive, order = "asc") {
    if (isActive && order === "asc")
      return <span className="uk-margin-small-left" data-uk-icon="icon:arrow-down" />;
    if (isActive && order === "desc")
      return <span className="uk-margin-small-left" data-uk-icon="icon:arrow-up" />;
    return null;
  }

  getActiveSort(sort) {
    if (sort) return "gh-sort-active";
    return "gh-sort-inactive";
  }

  getHeaderItem(header) {
    const { loadingOnSorting } = this.state;
    const { ignoreToSorting } = this.props;

    return (
      <span
        className="uk-position-relative"
        style={header.tooltip ? { display: "flex", alignItems: "center" } : {}}
      >
        {header.filter && !ignoreToSorting.includes(header.sortColumn) ? (
          <a
            className={`${this.getActiveSort(
              header.defaultFilter,
            )} gh-filtered-header gh-text-no-wrap btn-style ${
              loadingOnSorting ? "gh-sort-btn-loading" : ""
            }`}
            onClick={this.emitSortChange.bind(
              this,
              header.sortColumn,
              this.reverseSortOrder(this.props.selectedOrder.sortOrder),
            )}
          >
            {header.title}
            {this.getSortIcon(
              this.props.selectedOrder.field === header.sortColumn,
              this.props.selectedOrder.sortOrder,
            )}
          </a>
        ) : (
          <span>{header.title}</span>
        )}
        {header.tooltip ? (
          <Tooltip
            pos="bottom-left"
            refEl={"self"}
            wrapperClassName="gh-quote-details-tooltip-margin-left"
          >
            {staticService.findByAlias(header.tooltip.messageAlias)}
          </Tooltip>
        ) : (
          ""
        )}
      </span>
    );
  }

  getHeaderItemMobile(header) {
    return (
      <span>
        <b>
          <span>{header.title}</span>
        </b>
        {header.tooltip && (
          <span
            className="uk-icon gh-table-tooltip-mobile gh-text-quaternary"
            data-uk-icon="icon: info"
            data-uk-tooltip={`title: ${staticService.findByAlias(header.tooltip.messageAlias)}`}
            onClick={() => this.toggleTooltip(header)}
          />
        )}
        {header.tooltip && (
          <div
            style={{
              display:
                this.state.displayedTooltip === this.getIdFromTitle(header.title)
                  ? "block"
                  : "none",
            }}
            className="uk-tooltip"
          >
            {staticService.findByAlias(header.tooltip.messageAlias)}
            <div className="gh-tooltip-backdrop" onClick={() => this.toggleTooltip(null)} />
          </div>
        )}
      </span>
    );
  }

  isHighlight(row) {
    return row.highlight ? this.props.highlightClass : "";
  }

  get desktopTemplate() {
    const { loadingOnSorting } = this.state;
    const { tableProps, startRowActions, headers, rows, payments, columns, credentials } =
      this.props;

    return (
      <div className="uk-overflow-auto">
        <table {...tableProps} cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              {startRowActions && <tr />}
              {headers.map((header, index) => {
                return (
                  <th
                    key={index}
                    className={
                      this.getCss(index).join(" ") +
                      ` ${header.title === "Admin" ? "uk-flex uk-flex-center" : ""}`
                    }
                  >
                    {this.getHeaderItem(header)}
                  </th>
                );
              })}
              {this.headerActionColumns()}
            </tr>
          </thead>

          <tbody className="uk-position-relative">
            {loadingOnSorting ? (
              <tr>
                <td style={{ height: 0, padding: 0 }}>
                  <div className="gh-table-overlay">
                    <Spinner />
                  </div>
                </td>
              </tr>
            ) : (
              <></>
            )}

            {rows.length > 0
              ? rows.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      className={this.isHighlight(row)}
                      id={window.innerWidth > 576 ? "ghr" + row.id : ""}
                    >
                      {payments && this.getColumnLink(row, columns)}
                      {!payments && !credentials && this.getColumn(row, columns)}
                      {credentials && this.credentialsColumnLink(row, columns)}
                      {this.actionColumns(index) ? this.actionColumns(index).desktop : null}
                    </tr>
                  );
                })
              : this.emptyRow}
          </tbody>
        </table>
      </div>
    );
  }

  get mobileTemplate() {
    const { rows, columns, headers, payments, startRowActions } = this.props;

    return (
      <ul className="gh-mob-grid uk-hidden@m disabled-list gh-margin-small-bottom">
        {rows.length > 0
          ? rows.map((row, index) => {
              return (
                <li
                  key={index}
                  className={"uk-margin-top uk-position-relative" + " " + this.isHighlight(row)}
                  id={window.innerWidth < 576 ? "ghr" + row.id : ""}
                >
                  {columns.map((field, i) => {
                    return (
                      <div
                        data-uk-grid
                        key={i}
                        className="uk-margin-remove-top gh-padding-small-top"
                      >
                        <div className="uk-width-1-3">{this.getHeaderItemMobile(headers[i])}</div>
                        <div className="uk-width-2-3 gh-text-break gh-mobile-action">
                          {field === "docs_url" ? (
                            <a href={row[field]} className="gh-button-simple">
                              {staticService.findByAlias("goToUrl")}
                            </a>
                          ) : (
                            (payments && this.getMobileColumnLink(field, row)) || row[field]
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="gh-mob-grid-actions uk-text-center">
                    {this.actionColumns(index) ? this.actionColumns(index).mobile : null}
                  </div>
                  {startRowActions && startRowActions(index, rows).mobile}
                </li>
              );
            })
          : this.emptyRowMob}
      </ul>
    );
  }

  get requestsMobileTemplate() {
    const excludedList = ["identifier", "from", "to", "progress"];

    return (
      <ul className="gh-mob-grid uk-hidden@m disabled-list gh-mob-request">
        {this.props.rows.length > 0
          ? this.props.rows.map((row, index) => {
              return (
                <li key={index} className={"uk-margin-top" + this.isHighlight(row)}>
                  <div data-uk-grid>
                    {this.props.columns.map((field, i) => {
                      return (
                        <div
                          key={i}
                          className={`${
                            excludedList.includes(field) ? "uk-width-1-1" : "uk-width-1-2"
                          } uk-margin-bottom uk-margin-remove-top`}
                        >
                          <div
                            className={`gh-mob-request-header ${
                              this.props.headers[i].filter ? " gh-filtered-header" : ""
                            }`}
                          >
                            {this.getHeaderItemMobile(this.props.headers[i])}
                          </div>
                          <div className="gh-mob-request-row">
                            {row[field] ? row[field] : "---"}
                          </div>
                        </div>
                      );
                    })}
                    <div className="gh-mob-grid-actions uk-text-center uk-margin-remove-top uk-margin-bottom">
                      {this.actionColumns(index) ? this.actionColumns(index).mobile : null}
                    </div>
                  </div>
                </li>
              );
            })
          : this.emptyRowMob}
      </ul>
    );
  }

  render() {
    if (this.props.visible) {
      return (
        <div className={this.props.className}>
          {this.props.brokerRequestsList ? this.requestsMobileTemplate : this.mobileTemplate}
          {this.desktopTemplate}
          {this.loaderButton}
        </div>
      );
    }

    return null;
  }
}

ComplexTableComponent.propTypes = {
  credentials: PropTypes.bool,
  payments: PropTypes.bool,
  tableProps: PropTypes.object,
  showActionColumn: PropTypes.number,
  actionElements: PropTypes.func,
  visible: PropTypes.bool,
  showLoader: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  showMoreText: PropTypes.string,
  emptyRowMessage: PropTypes.string,
  highlightClass: PropTypes.string,
  loadingMessage: PropTypes.any,
  selectedOrder: PropTypes.object,
  sortChange: PropTypes.func,
  firstTimeLoading: PropTypes.bool,
  startRowActions: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  emptyRowButton: PropTypes.object,
  dispatch: PropTypes.func,
  brokerRequestsList: PropTypes.any,
  alignLastCell: PropTypes.bool,
  actionsHeaders: PropTypes.array,
  emptyCellContent: PropTypes.any,
  ignoreToSorting: PropTypes.array,
};

ComplexTableComponent.defaultProps = {
  tableProps: {
    className: "uk-table uk-table-primary uk-visible@m",
  },
  payments: false,
  showActionColumn: 0,
  visible: true,
  showLoader: true,
  firstTimeLoading: false,
  showMoreText: "Load More",
  emptyRowMessage: "No items found",
  emptyRowButton: {},
  loadingMessage: (
    <div className="uk-padding">
      <LoadingComponent />
    </div>
  ),
  selectedOrder: { field: "", sortOrder: "" },
  highlightClass: "gh-row-highlight",
  startRowActions: undefined,
  alignLastCell: false,
  actionsHeaders: [],
  emptyCellContent: "",
  ignoreToSorting: [],
};

export { ComplexTableComponent };
