import React from "react";
import staticService from "../../services/static.service";
import InlineEditWrapper from "../inlineEditInput/inlineEditWrapper";

const OperatorPrivateNotesComponent = ({
  notes = "",
  id = null,
  action,
  quote,
  hasQuoteToExpired,
}) => {
  const updateNotes = (note) => {
    return action(id, note, quote);
  };

  return (
    <div className="operator-private-notes">
      <h4
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("yourPrivateNotes"),
        }}
      />
      <InlineEditWrapper
        disabled={notes && !notes.length}
        action={updateNotes}
        defaultValue={notes}
        hasQuoteToExpired={hasQuoteToExpired}
      >
        <div className="uk-text-small gh-text-secondary">{notes ? notes : "---"}</div>
      </InlineEditWrapper>
    </div>
  );
};

export default OperatorPrivateNotesComponent;
