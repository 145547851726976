import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import "./chartBtn.css";

import infoGrafic from "../../../assets/img/svg/infoGrafic.svg";
import infoGraficBorder from "../../../assets/img/svg/infoGraficBorder.svg";

const ChartBtn = (props) => {
  const { onClick, duration, animated, disabled } = props;
  const [isIn, setIsIn] = useState(false);
  const [animationStop, setAnimationStop] = useState(1);
  let timer = null;

  useEffect(() => {
    if (animated) {
      start();
    }

    const timer2 = () =>
      setTimeout(() => {
        clearTimeout(timer);
      }, 12000);
    const timerId = timer2();
    return () => {
      clearTimeout(timer);
      clearTimeout(timerId);
    };
  }, [animated]);

  const start = () => {
    clearInterval(timer);
    setIsIn(true);
    setAnimationStop(1);

    timer = setInterval(() => {
      setAnimationStop((prevProps) => prevProps + 1);
    }, 1000);
  };

  const onExit = () => {
    setIsIn(false);
  };

  const onEnter = () => {
    if (animationStop > duration) {
      clearInterval(timer);
    } else {
      setIsIn(true);
    }
  };

  const buttonClassNames = clsx("gh-wrapper-progress-grafic-btn btn-style", {
    "gh-area-disabled": disabled,
  });

  return (
    <>
      <CSSTransition
        in={animated && isIn}
        timeout={2000}
        classNames={"chart-btn-pulse"}
        onExited={onEnter}
        onEntered={onExit}
      >
        <ChartButton buttonClassNames={buttonClassNames} onClick={onClick} animated={animated} />
      </CSSTransition>
    </>
  );
};

ChartBtn.propTypes = {
  onClick: PropTypes.func,
  duration: PropTypes.number,
  animated: PropTypes.bool,
  disabled: PropTypes.bool,
};
ChartBtn.defaultProps = {
  disabled: false,
  animated: false,
  duration: 10,
};

export default ChartBtn;

// eslint-disable-next-line react/prop-types
const ChartButton = ({ buttonClassNames, animated, onClick }) => {
  const activeClass = animated ? " --active" : "";

  return (
    <div className={buttonClassNames + activeClass} onClick={onClick}>
      <img src={animated ? infoGraficBorder : infoGrafic} alt="grafic" />
      <span className="gh-leg-link-customs-text">View</span>
    </div>
  );
};
