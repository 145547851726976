/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Control, Errors, Form } from "react-redux-form";
import moment from "moment";
import isEqual from "lodash.isequal";
import { BlockComponent } from "../../../../common";
import {
  config,
  RESTRICT_ERROR_MESSAGE,
  validationMessages,
  validationRules,
} from "../../../../../configs";
import {
  editRequest,
  getPurposes,
  REQUEST_UPDATED,
  REVIEW_REQUEST_ADD_OPERATORS,
  sendOnReviewRequest,
  showAvailableCountriesModal,
} from "../../../../../actions";
import {
  CustomValidateError,
  distanceBetween,
  fireTracking,
  getAfterDate,
  legTitle,
  parseCoordinates,
  storage,
  updateModel,
} from "../../../../../shared";
import { LegExtraFieldset } from "../../../../forms/fieldsets";
import { LegFormComponent } from "../../../../forms";
import { IsVerifiedPermission } from "../../../../index";
import staticService from "../../../../../services/static.service";
import RequestGeneralInformation from "../request-general-information";
import RequestNavComponent from "../request-nav-component";
import debounce from "lodash.debounce";
import { getRequestDraft, updateRequestDraft } from "../../../../../actions";
import generateLocationAdditionalInfo, {
  generateSecondaryText,
} from "../../../../../utils/generateLocationAdditionalInfo";
import Tooltip from "../../../../common/Tooltip";
import checkCoverageStatus from "../../../../../utils/checkCoverageStatus";
import BrokerClientReference from "../../../../common/BrokerClientReference";
import auth from "../../../../../services/auth";
import link from "../../../../../assets/img/external-link.svg";
import isAllowForEditing from "../../../../../utils/isAllowForEditing";
import { showNotification } from "../../../../../utils/showNotification";
import Upload from "../../../../Form/components/Upload";
import { generateAccountLocalization } from "../../../../../context";
import { strBrakeLineToNewLine } from "../../../../../utils/strBrakeLineToNewLine";
import { getTime } from "../../../../../utils/tranformDateTimeToISO";
import NewRequestNextStepInfo from "./NewRequestNextStepInfo";

import "./brokerNewRequestComponent.css";

const { max } = validationRules;
const modelName = "legModels";

const defaultLegData = {
  departure_time: moment().add(1, "day").hour(12).minute(0).format(config.timeFormat),
  date: moment().add(1, "day").format(config.dateFormat),
  date_type: "1",
  time_type: "1",
  from_location: {},
  from: "",
  to: "",
  from_coords: "",
  from_coverage_status: false,
  passengers: {
    passengers_amount_type: "1",
    men: 0,
    women: 0,
    children: 0,
    total: 0,
  },
  luggage: {
    hand_bags: 0,
    carry_on_bags: 0,
  },
  local_points: auth.hasFeature("rich_content_map"),
  same_as_previous: false,
  special_items: {
    bicycles: 0,
    golf_club_sets: 0,
    hunting_weapons: 0,
    pets: 0,
    ski_pairs: 0,
    other: "",
  },
  to_location: {},
  to_coords: "",
  to_coverage_status: false,
  number_adults: "0",
  number_children: "0",
  from_source: "",
  to_source: "",
  from_is_private: false,
  to_is_private: false,
};

class BrokerNewRequestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addLeg = this.addLeg.bind(this);
    this.validateLocations = this.validateLocations.bind(this);
    this.parseFormLegs = this.parseFormLegs.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.sumDistance = this.sumDistance.bind(this);
    this.distanceToTime = this.distanceToTime.bind(this);
    this.changeFlightType = this.changeFlightType.bind(this);
    this.updateTextArea = this.updateTextArea.bind(this);
    this.saveAndExit = this.saveAndExit.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.jumpThroughErrors = this.jumpThroughErrors.bind(this);
    this.validateBeforeOpenAccordion = this.validateBeforeOpenAccordion.bind(this);
    this.fillWithTheSameInfo = this.fillWithTheSameInfo.bind(this);
    this.throttleHandleChange = debounce(this.throttleHandleChange.bind(this), 3000, {
      maxWait: 6000,
    });
    this.updateClientReferenceHanlder = this.updateClientReferenceHanlder.bind(this);
    this.clientReferenceAfterSubmit = this.clientReferenceAfterSubmit.bind(this);
    this.updateRequestReferenceId = this.updateRequestReferenceId.bind(this);
    this.accountLocalization = generateAccountLocalization();
    this.state = {
      selectedLocation: { lat: 51.5073509, lng: -0.12775829999998223 },
      disableAddLeg: false,
      allDistance: 0,
      currentDistance: [],
      sum: [],
      currentDate: moment(),
      oldRequest: props.editedRequest,
      confirmData: {
        title: staticService.findByAlias("confirmRequestAlertModalTitle"),
        content: staticService.findByAlias("requestSuccess"),
        status: 0,
      },
      oldRequestId: props.editedRequest.id,
      purposesList: [],
      nextSubmit: false,
      legsOpen: false,
      accordionsOpen: false,
      areaRows: 4,
      minRows: 4,
      maxRows: 20,
      draftInited: false,
      submitClientReference: () => null,
    };
  }

  componentDidMount() {
    const cachedForm = JSON.parse(storage.get("request_form"));
    if (cachedForm) {
      if (cachedForm.flight_type !== "" && cachedForm.flight_purpose !== "") {
        this.setState({ legsOpen: true });
      }
      if (cachedForm.legs && cachedForm.legs[0]) {
        const { from_coords, to_coords } = cachedForm.legs[0];
        if (from_coords.length && to_coords.length) {
          this.setState({ accordionsOpen: true });
        }
      }
    } else {
      this.resetAll();
    }
    this.props.getPurposes().then((purposes) => this.setState({ purposesList: purposes }));
    this.initForm("1", [{ ...defaultLegData }], true);
    fireTracking(this.props.location.pathname);
    window.scrollTo(0, 0);
    if (!this.state.oldRequestId) {
      const pipeline_id =
        (cachedForm && cachedForm.pipeline_id) ||
        (this.props.editedRequest && this.props.editedRequest.pipeline_id);

      if (pipeline_id) {
        this.props.getRequestDraft(pipeline_id).then((draft) => {
          this.setState({ draftInited: true });

          if (draft) {
            const validatedDraft = {
              ...draft,
              pipeline_id,
              legs: draft.legs.map((leg) => ({ ...defaultLegData, ...leg })),
            };

            this.context.store.dispatch(actions.change("legModels", validatedDraft));
            this.context.store.dispatch(editRequest(validatedDraft));
            this.updateReferenceDate(validatedDraft);
          }
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { legs } = this.props.modelRequest;
    const { legs: nextLegs } = nextProps.modelRequest;
    this.setState({ nextLegs });
    if (!isEqual(legs, nextLegs)) {
      this.sumDistance(nextLegs);
    }

    if (legs.length > 1) {
      nextProps.modelRequest.legs.forEach((leg, index) => {
        if (legs.length > index && this.props.modelRequest.legs[index].date !== leg.date) {
          this.updateReferenceDate(nextProps.modelRequest);
        }
      });
    }
    if (
      this.props.modelRequest !== nextProps.modelRequest &&
      nextProps.modelRequest.flight_type !== "" &&
      nextProps.modelRequest.flight_purpose !== "" &&
      !this.state.legsOpen
    ) {
      this.setState({ legsOpen: true });
      if (document.getElementById("gh-accordion-item0")) {
        const firstLegContent = document.getElementById("gh-accordion-item0").children[1];
        firstLegContent.setAttribute("aria-hidden", `${false}`);
        firstLegContent.removeAttribute("hidden");
      }
    }
    if (
      nextLegs[0] &&
      nextLegs[0].from_coords &&
      nextLegs[0].from_coords.length &&
      nextLegs[0].to_coords &&
      nextLegs[0].to_coords.length &&
      !this.state.accordionsOpen
    ) {
      this.setState({ accordionsOpen: true });
      const accordionContentList = document.getElementsByClassName("uk-accordion-content");
      if (accordionContentList) {
        for (let i = 0; i < accordionContentList.length; i++) {
          accordionContentList[i].removeAttribute("hidden");
          accordionContentList[i].setAttribute("aria-hidden", `${false}`);
        }
      }
    }

    if (
      JSON.stringify(nextProps.modelRequest) !== JSON.stringify(this.props.modelRequest) &&
      !nextProps.modelRequest.old_request_id
    ) {
      if (this.state.draftInited) {
        this.throttleHandleChange(nextProps.modelRequest);
      }
    }
  }

  get defaultTime() {
    const m = moment().add(1, "day");
    m.hour(12).minute(0);
    return m;
  }

  get midNightTime() {
    const m = moment().add(1, "day");
    m.hour(0).minute(0);
    return m;
  }

  initFormState(flight_type = "", initLegs = [{ ...defaultLegData }]) {
    let { oldRequest } = this.state;
    const cachedForm = JSON.parse(storage.get("request_form"));
    if (oldRequest && Object.keys(oldRequest).length > 0) {
      delete oldRequest.id;
      oldRequest = JSON.parse(JSON.stringify(oldRequest));
      if (oldRequest.legs && oldRequest.legs.length) {
        oldRequest.legs.forEach((leg, legIndex) => {
          if (leg.from_source === "hellipaddy") {
            leg.from = leg.from.split(",")[0];
          }
          if (leg.to_source === "hellipaddy") {
            leg.to = leg.to.split(",")[0];
          }
          return {
            ...leg,
            local_points:
              auth.hasFeature("rich_content_map") &&
              !(oldRequest.flight_type === "2" && legIndex === 1),
            passengers: {
              ...leg.passengers,
              passengers_amount_type: `${leg.passengers.passengers_amount_type}`,
            },
          };
        });
      } else {
        oldRequest.flight_purpose = "9";
        let legs = [];
        const outbound = {
          ...defaultLegData,
          from_location: oldRequest.from_location,
          to_location: oldRequest.to_location,
          date: moment(oldRequest.departure_at, "DD/MM/YYYY"),
        };
        const inbound = {
          ...defaultLegData,
          from_location: oldRequest.to_location,
          to_location: oldRequest.from_location,
          date: oldRequest.return_at,
        };
        if (oldRequest.return_at) {
          legs = [outbound, inbound];
        } else {
          legs = [outbound];
        }
        oldRequest.legs = legs;
      }

      return {
        ...oldRequest,
        old_request_id: this.state.oldRequestId,
        catering_need: `${oldRequest.catering}`,
        crew_size: `${oldRequest.crew_size}`,
        flight_type: `${oldRequest.flight_type}`,
        flight_purpose: `${oldRequest.flight_purpose}`,
        identifier: oldRequest.identifier || "",
        other_info: (oldRequest.other_info && oldRequest.other_info.replace(/<br\/>/g, "\n")) || "",
      };
    } else {
      this.context.store.dispatch(editRequest({}));
      return {
        legs: initLegs,
        other_info: "",
        preferences: "no-preference",
        catering_need: "0",
        crew_size: "0",
        identifier: "",
        flight_purpose: (cachedForm && cachedForm.flight_purpose) || "",
        flight_type: flight_type,
        private_notes: "",
        flight_purpose_event: (cachedForm && cachedForm.flight_purpose_event) || "",
        flight_purpose_event_id: (cachedForm && cachedForm.flight_purpose_event_id) || "",
        flight_purpose_event_info: (cachedForm && cachedForm.flight_purpose_event_info) || "",
        broker_intention: (cachedForm && cachedForm.broker_intention) || "",
        pipeline_id:
          (cachedForm && cachedForm.pipeline_id) ||
          (this.props.editedRequest && this.props.editedRequest.pipeline_id),
      };
    }
  }

  initForm(type, legs, fromCache = false) {
    const { modelRequest } = this.props;
    const cachedFormState = this.getCachedFormState();
    if (cachedFormState && fromCache) {
      // remove br tag and replace with new line
      if (cachedFormState.other_info)
        cachedFormState.other_info = strBrakeLineToNewLine(cachedFormState.other_info);
      //----------------------------------------
      this.updateReferenceDate(cachedFormState);
      return this.context.store.dispatch(actions.change("legModels", cachedFormState));
    }

    const initialData = this.initFormState(type, legs);

    if (type === "1") {
      return this.context.store.dispatch(
        actions.change("legModels", {
          ...initialData,
          flight_type: type,
          legs: [modelRequest.legs[0]],
        }),
      );
    } else if (type === "2") {
      let prevLeg = modelRequest.legs[0];

      const inbound = {
        ...modelRequest.legs[0],
        departure_time: moment(prevLeg.departure_time, this.accountLocalization.timeFormat)
          .add(2, "hours")
          .format(this.accountLocalization.timeFormat),
        from: prevLeg.to,
        from_coords: prevLeg.to_coords,
        from_is_private: prevLeg.to_is_private,
        from_location: prevLeg.to_location,
        from_source: prevLeg.to_source,
        from_info: prevLeg.to_info,
        from_place_id: prevLeg.to_place_id,
        from_type: prevLeg.to_type,
        from_coverage_status: prevLeg.to_coverage_status,
        from_details: prevLeg.to_details,
        same_as_previous: true,
        to: prevLeg.from,
        to_coords: prevLeg.from_coords,
        to_is_private: prevLeg.from_is_private,
        to_location: prevLeg.from_location,
        to_source: prevLeg.from_source,
        to_info: prevLeg.from_info,
        to_place_id: prevLeg.from_place_id,
        to_type: prevLeg.from_type,
        to_coverage_status: prevLeg.from_coverage_status,
        to_details: prevLeg.from_details,
      };

      return this.context.store.dispatch(
        actions.change("legModels", {
          ...initialData,
          flight_type: type,
          legs: [modelRequest.legs[0], inbound],
        }),
      );
    } else if (type === "3") {
      let prevLeg = modelRequest.legs[0];

      return actions.change("legModels", {
        ...initialData,
        flight_type: type,
        legs: [
          prevLeg,
          {
            ...modelRequest.legs[0],
            departure_time: moment(prevLeg.departure_time, this.accountLocalization.timeFormat)
              .add(2, "hours")
              .format(this.accountLocalization.timeFormat),
            from: prevLeg.to,
            from_coords: prevLeg.to_coords,
            from_is_private: prevLeg.to_is_private,
            from_location: prevLeg.to_location,
            from_source: prevLeg.to_source,
            from_info: prevLeg.to_info,
            from_place_id: prevLeg.to_place_id,
            from_type: prevLeg.to_type,
            from_coverage_status: prevLeg.to_coverage_status,
            from_details: prevLeg.to_details,
            same_as_previous: true,
            to: "",
            to_coords: "",
            to_is_private: false,
            to_location: "",
            to_source: "",
            to_info: "",
            to_place_id: "",
            to_type: "",
            to_coverage_status: "",
            to_details: "",
          },
        ],
      });
    }
  }

  changeFlightType(model, type) {
    this.context.store.dispatch(actions.change(model, type));
    if (type === "2") {
      const inbound = { ...defaultLegData };
      const date = moment();
      const departure_time = moment(
        date.format(config.dateFormat) + " " + defaultLegData.departure_time,
      )
        .add(2, "hour")
        .startOf("hour");
      const comparingDate = date.hour(22);
      inbound.same_as_previous = true;
      inbound.local_points = false;
      if (departure_time.isAfter(comparingDate)) {
        inbound.departure_time = this.midNightTime.format(config.timeFormat);
        inbound.date = moment(defaultLegData.date).add(1, "day").format(config.dateFormat);
        inbound.local_points = false;
      } else {
        inbound.departure_time = departure_time.format(config.timeFormat);
      }
      const returnLegs = [{ ...defaultLegData }, { ...inbound }];
      return this.initForm(type, returnLegs, false);
    }
    if (type === "3") {
      const newDefaultLegData = {
        ...defaultLegData,
        departure_time: moment().add(1, "day").hour(14).minute(0).format(config.timeFormat),
        date: moment().add(1, "day").format(config.dateFormat),
        same_as_previous: true,
      };
      return this.initForm(type, [{ ...defaultLegData }, { ...newDefaultLegData }], false);
    }
    return this.initForm(type, [{ ...defaultLegData }], false);
  }

  updateReferenceDate(request) {
    let prevLeg = {};
    const legs = request.legs.map((legModel, i) => {
      const leg = { ...legModel };
      const legTimeObj = moment(leg.date + " " + leg.departure_time);
      if (legTimeObj.isSameOrBefore(this.state.currentDate)) {
        leg.date = this.state.currentDate.format(config.dateFormat);
      }

      if (i > 0) {
        prevLeg = {
          ...leg,
          referenceDate: prevLeg.date,
          date: getAfterDate(leg.date, prevLeg.date),
        };
        return prevLeg;
      } else {
        prevLeg = leg;
      }
      return leg;
    });
    this.context.store.dispatch(actions.change(modelName + ".legs", legs));
  }

  getCachedFormState() {
    const cachedFormState = storage.get("request_form");
    if (!cachedFormState) {
      this.updateStorage({ ...this.initFormState() });
      return this.getCachedFormState();
    }

    return JSON.parse(cachedFormState);
  }

  updateStorage(formModel) {
    storage.add("request_form", JSON.stringify(formModel));
  }

  handleRequestFormChange(model) {
    if (model) {
      this.updateStorage(model);
    }
  }

  setLandings(index, location, coordModel) {
    const value = location.lat + "|" + location.lng;
    this.context.store.dispatch(actions.change(coordModel, value));
    const totalLegs = this.props.modelRequest.legs.length - 1;
    //update source because it could be changed with helipaddy value
    this.props.updateLeg(
      index,
      this.getSourcePrefix(coordModel) + "_source",
      config.locationSource[0],
    );
    if (totalLegs > index) {
      this.props.updateLeg(index + 1, "from_coords", value);
      this.props.updateLeg(index + 1, "from_source", config.locationSource[0]);
    }
    if (index === 0) this.setState({ selectedLocation: location });
  }

  getSourcePrefix(legLocation) {
    const strip = legLocation.split(".");
    if (strip.length > 0) {
      const prefix = strip[strip.length - 1].split("_");
      return prefix[0];
    }
    return null;
  }

  removeLeg(ev, index) {
    ev.preventDefault();
    const legs = [...this.props.modelRequest.legs];
    if (legs.length > 1 && legs.length <= 2) {
      const legsObj = legs.filter((_, legIndex) => legIndex !== index);
      this.context.store.dispatch(actions.change(`${modelName}.legs`, legsObj));
    }
    if (legs.length >= 2) {
      if (index !== legs.length - 1) {
        let nextLeg = legs[index + 1];
        let prevLeg = legs[index - 1];
        if (nextLeg.same_as_previous) {
          legs[index + 1] = {
            ...nextLeg,
            passengers: prevLeg.passengers,
            luggage: prevLeg.luggage,
            special_items: prevLeg.special_items,
          };
        }
      }
      const legsObj = legs.filter((_, legIndex) => legIndex !== index);
      this.context.store.dispatch(actions.change(`${modelName}.legs`, legsObj));
    }
  }

  addLeg() {
    const { legs } = this.props.modelRequest;
    const index = legs.length;
    const prevLeg = legs[index - 1];
    // this.context.store.dispatch(actions.setInitial("legModels"));

    const isFirstStepFromTouched = this.props.form.legs[0].from_coords.touched;
    const isFirstStepToTouched = this.props.form.legs[0].to_coords.touched;
    const firstStepFromValue = this.props.form.legs[0].from_coords.value;
    const firstStepToValue = this.props.form.legs[0].to_coords.value;
    const isFirstStepFormInvalid = !isFirstStepFromTouched && !firstStepFromValue;
    const isFirstStepToInvalid = !isFirstStepToTouched && !firstStepToValue;

    if (
      this.props.modelRequest.flight_type === "3" &&
      (isFirstStepFormInvalid || isFirstStepToInvalid)
    ) {
      this.context.store.dispatch(actions.setSubmitFailed(`legModels.legs.0.from_coords`));
      this.context.store.dispatch(actions.setSubmitFailed(`legModels.legs.0.to_coords`));
      return false;
    }

    if (!this.props.form.legs[index - 1].$form.valid) {
      this.context.store.dispatch(actions.setTouched(`legModels.legs.${index - 1}.from_coords`));
      this.context.store.dispatch(actions.setTouched(`legModels.legs.${index - 1}.to_coords`));
      return false;
    }
    if (this.props.form.legs[index - 1].from.value && this.props.form.legs[index - 1].from.value) {
      const nextLeg = {
        ...defaultLegData,
        from: prevLeg.to,
        from_coords: prevLeg.to_coords,
        from_source: prevLeg.to_source,
        from_location: prevLeg.to_location,
        from_is_private: prevLeg.to_is_private,
        from_place_id: prevLeg.to_place_id,
        from_details: prevLeg.to_details,
        from_coverage_status: prevLeg.to_coverage_status,
        from_info: prevLeg.to_info,
        from_type: prevLeg.to_type,
        same_as_previous: true,
        luggage: prevLeg.luggage,
        passengers: prevLeg.passengers,
        special_items: prevLeg.special_items,
      };
      const date = moment();
      let departure_time = moment(prevLeg.departure_time).add(2, "hour").startOf("hour");
      if (!departure_time.isValid()) {
        departure_time = moment(prevLeg.departure_time, "HH:mm").add(2, "hour").startOf("hour");
      }
      const comparingDate = date.hour(22);

      if (getTime(departure_time).isAfter(getTime(comparingDate), "hour")) {
        nextLeg.departure_time = this.midNightTime.format(config.timeFormat);
        nextLeg.date = moment(prevLeg.date).add(1, "day").format(config.dateFormat);
      } else {
        nextLeg.departure_time = departure_time.format(config.timeFormat);
        nextLeg.date = moment(prevLeg.date).format(config.dateFormat);
      }
      this.context.store.dispatch(actions.push("legModels.legs", nextLeg));
    }
  }

  onSubmit() {
    const onSuccess = (data) => {
      this.context.store.dispatch({ type: REVIEW_REQUEST_ADD_OPERATORS, payload: data.operators });
      const updatedData = { ...data };

      updatedData.legs = updatedData.legs.map((leg) => ({
        ...leg,
        from: leg.from_location.name || leg.from,
        to: leg.to_location.name || leg.to,
      }));

      updatedData.other_info_attachments = updatedData.other_info_attachments.map((attachment) => {
        if (attachment.file_data_local) {
          attachment.file_data_local = "";
        }

        return attachment;
      });

      this.updateStorage(updatedData);
      if (Object.keys(this.state.oldRequest).length > 0) {
        this.setState({
          confirmData: {
            ...updatedData,
            content: staticService.findByAlias("requestEdited"),
          },
        });
      }
      this.props.history.push("/request-review");
    };

    let requestModel;

    if (!isAllowForEditing()) {
      return showNotification(RESTRICT_ERROR_MESSAGE, "danger");
    }

    if (this.state.oldRequestId) {
      requestModel = {
        ...this.props.modelRequest,
        old_request_id: this.state.oldRequestId,
        other_info: `${this.props.modelRequest.other_info.split("\n").join("<br/>")}`,
      };
    } else {
      requestModel = {
        ...this.props.modelRequest,
        other_info: `${this.props.modelRequest.other_info.split("\n").join("<br/>")}`,
      };
    }

    this.context.store
      .dispatch(actions.submit("legModels", this.props.add(requestModel, onSuccess, "legModels")))
      .catch(() => this.jumpThroughErrors());
  }

  updateLegFromTo(model, value, index) {
    const { modelRequest } = this.props;
    if (modelRequest.flight_type === "2") {
      if (model === "from_is_private") {
        const totalLegs = modelRequest.legs.length - 1;
        if (totalLegs > index) {
          this.props.updateLeg(index + 1, "to_is_private", value);
        }
      }
      if (model === "from_details") {
        const totalLegs = modelRequest.legs.length - 1;
        if (totalLegs > index) {
          this.props.updateLeg(index + 1, "to_details", value);
        }
      }
    }
    if (model === "to_is_private") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, "from_is_private", value);
      }
    }
    if (model === "to_details") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, "from_details", value);
      }
    }
    if (model === "to_place_id" || model === "from_place_id") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, model, value);
      }
    }
    if (model === "date") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, "date", value);
      }
    }
  }

  jumpThroughErrors() {
    const errorSection = document.getElementsByClassName("gh-input-errors");
    for (let i = 0; i < errorSection.length; i++) {
      if (errorSection[i].getElementsByTagName("li")[0].innerText) {
        const element = errorSection[i].getElementsByTagName("li")[0].getBoundingClientRect();
        window.scrollTo({
          top: element.top - document.body.getBoundingClientRect().top - 100,
          left: element.x,
          behavior: "smooth",
        });
      }
    }
  }

  checkErrors() {
    this.setState({ accordionsOpen: true, legsOpen: true }, () => {
      let accordions = document.getElementsByClassName("gh-accordion");
      if (accordions) {
        for (let i = 0; i < accordions.length; i++) {
          const item = accordions[i];
          for (let j = 0; j < item.children.length; j++) {
            item.children[j].classList.add("uk-open");
          }
        }
      }
    });
    setTimeout(() => this.jumpThroughErrors(), 300);
  }

  validateBeforeSubmit(legs = []) {
    let status = true;
    let currentDate = null;
    legs.forEach((leg, index) => {
      const legTimeObj = moment(leg.date + " " + leg.departure_time);
      if (index > 0 && legTimeObj.isSameOrBefore(currentDate)) {
        status = false;
      }
      //  min one person should be set
      if (leg.passengers) {
        const { men, women, children } = leg.passengers;
        const total = men + women + children;
        if (parseInt(total) === 0) {
          status = false;
        }
      }
      currentDate = legTimeObj;
    });
    return status;
  }

  parseFormLegs(formLegs = {}) {
    const legs = Object.keys(formLegs);
    let status = true;
    let _index = null;
    legs.pop();
    legs.forEach((leg, index) => {
      if (
        status &&
        ((formLegs[parseInt(leg)].from_coords && !formLegs[parseInt(leg)].from_coords.valid) ||
          (formLegs[parseInt(leg)].to_coords && !formLegs[parseInt(leg)].to_coords.valid))
      ) {
        status = false;
        _index = index;
      }
    });
    return {
      status: status,
      legIndex: _index,
    };
  }

  validateLocations() {
    if (!this.props.form.$form.submitFailed) return true;
    const validate = this.parseFormLegs(this.props.form.legs);
    return validate.status;
  }

  async handleAssignPad(site) {
    const source = site.source || config.locationSource[1];
    const body = {
      location: [site.location.latitude, site.location.longitude],
      place_id: site.place_id,
      source,
      secondary_text: generateSecondaryText(site.location),
    };

    let checked_coverage_status;
    if (!site.hasOwnProperty("coverage_status")) {
      checked_coverage_status = await checkCoverageStatus(body);
    } else checked_coverage_status = site.coverage_status;

    const currentLegs = [...this.props.modelRequest.legs] || [];
    const legIndex = site.legIndex;
    const side = site.legSide;
    const coords = `${site.location.latitude}|${site.location.longitude}`;
    const coverage_status = checked_coverage_status;
    const generateData = (key) => ({
      [`${key}`]: site.name,
      [`${key}_source`]: source,
      [`${key}_place_id`]: site.place_id,
      [`${key}_type`]: site.type,
      [`${key}_coords`]: coords,
      [`${key}_coverage_status`]: coverage_status,
      [`${key}_info`]: generateLocationAdditionalInfo({
        ...site.location,
        type: site.type,
        source,
      }),
    });

    if (currentLegs.length > 0) {
      if (side === "from") {
        currentLegs[legIndex] = {
          ...currentLegs[legIndex],
          ...generateData("from"),
          from_location: null,
        };
        if (this.props.modelRequest.flight_type === "2" && legIndex === 0) {
          currentLegs[legIndex + 1] = {
            ...currentLegs[legIndex + 1],
            ...generateData("to"),
            to_location: null,
          };
        }
      } else {
        currentLegs[legIndex] = {
          ...currentLegs[legIndex],
          ...generateData("to"),
          to_location: null,
        };

        if (currentLegs[legIndex].to_location) {
          delete currentLegs[legIndex].to_location;
        }

        if (this.props.modelRequest.flight_type === "2" && legIndex === 0) {
          currentLegs[legIndex + 1] = {
            ...currentLegs[legIndex + 1],
            ...generateData("from"),
            from_location: null,
          };
        }
        if (this.props.modelRequest.flight_type === "3" && currentLegs.length - 1 !== legIndex) {
          currentLegs[legIndex + 1] = {
            ...currentLegs[legIndex + 1],
            ...generateData("from"),
            from_location: null,
          };
        }
      }
    }

    setTimeout(() => {
      this.context.store.dispatch(actions.change(`legModels.legs`, currentLegs));
      this.context.store.dispatch(actions.focus(`legModels.legs.[${legIndex}].${side}`));
    }, 100);
  }

  resetForm() {
    let initModel = JSON.parse(JSON.stringify(this.initFormState()));
    initModel.legs[0].departure_time = this.defaultTime.format(config.timeFormat);
    initModel.legs[0].date = moment().add(1, "day").format(config.dateFormat);
    this.context.store.dispatch(actions.change("legModels", initModel));
  }

  resetAll() {
    storage.delete("request_form");
    this.setState({
      // showMap: false,
      selectedLocation: {},
      oldRequest: {},
    });
    this.context.store.dispatch(actions.reset("legModels"));
    this.resetForm();
    this.context.store.dispatch({ type: REQUEST_UPDATED, request: {} });
    window.scrollTo(0, 0);
  }

  sumDistance(legs) {
    let allDistance = 0;
    let currentDistance = [];
    let legIndex = 0;
    legs.map((leg, index) => {
      const distance = parseInt(
        distanceBetween(
          parseCoordinates(legs[index].from_coords),
          parseCoordinates(legs[index].to_coords),
        ).toFixed(0),
      );
      allDistance += distance;
      currentDistance.push(distance);
      legIndex = index;
    });
    this.setState({ currentDistance, allDistance });
    const timeCounted = Math.floor((currentDistance[legIndex] / 130) * 60 + 15);
    if (currentDistance[legIndex] === 0) {
      return this.context.store.dispatch(
        actions.change(`${modelName}.legs.[${legIndex}].time_max`, currentDistance[legIndex]),
      );
    }
    if (timeCounted % 10 === 0) {
      return this.context.store.dispatch(
        actions.change(`${modelName}.legs.[${legIndex}].time_max`, timeCounted * 60),
      );
    } else {
      const rounded =
        timeCounted % 10 !== 0 ? (timeCounted + (10 - (timeCounted % 10))) * 60 : timeCounted * 60;
      return this.context.store.dispatch(
        actions.change(`${modelName}.legs.[${legIndex}].time_max`, rounded),
      );
    }
  }

  distanceToTime(distance) {
    const timeCounted = Math.floor((distance / 130) * 60);
    const h = moment.utc().startOf("day").add({ minutes: timeCounted }).format("HH");
    const m = moment.utc().startOf("day").add({ minutes: timeCounted }).format("mm");
    let roundedMinutes =
      parseInt(m, 10) % 10 !== 0
        ? parseInt(m, 10) + (10 - (parseInt(m, 10) % 10))
        : parseInt(m, 10);
    let roundedHours = parseInt(h, 10);
    if (roundedMinutes === 60) {
      roundedHours++;
      roundedMinutes = parseInt("00", 10);
    }
    return (
      (roundedHours !== 0 ? roundedHours + (roundedHours === 1 ? " hour" : " hours") : "") +
      " " +
      (roundedMinutes !== 0 ? roundedMinutes + " minutes" : "")
    );
  }

  updateTextArea(event) {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  }

  validateBeforeOpenAccordion(validate_location = false) {
    const { dispatch } = this.context.store;

    if (!this.props.modelRequest.flight_purpose)
      dispatch(actions.setTouched(`${modelName}.flight_purpose`));
    if (!this.props.modelRequest.flight_type)
      dispatch(actions.setTouched(`${modelName}.flight_type`));

    if (validate_location) {
      if (this.props.modelRequest.flight_purpose && this.props.modelRequest.flight_type) {
        const locations = [];

        for (let i = 0; i < this.props.modelRequest.legs.length; i++) {
          locations.push(() => {
            if (!this.props.modelRequest.legs[i].from_coords) {
              dispatch(actions.setTouched(`${modelName}.legs[${i}].from_coords`));
            }
            if (!this.props.modelRequest.legs[i].to_coords) {
              dispatch(actions.setTouched(`${modelName}.legs[${i}].to_coords`));
            }
          });
        }

        locations.forEach((fn) => fn());
      }
    }
  }

  updateLegsWithPreviousSteps(legs) {
    legs.forEach((leg, ix) => {
      if (leg.same_as_previous) {
        const { passengers, special_items, luggage } = legs[ix - 1];
        leg.passengers = passengers;
        leg.special_items = special_items;
        leg.luggage = luggage;
      }
    });
    return legs;
  }

  fillWithTheSameInfoNextLeg(model, value, index, modelSection) {
    const { nextLegs } = this.state;
    let legs = JSON.parse(JSON.stringify(nextLegs));
    legs[index].same_as_previous = false;
    let lastKey = model.substring(model.lastIndexOf(".") + 1);
    legs[index][modelSection][lastKey] = value;
    legs = this.updateLegsWithPreviousSteps(legs);
    this.context.store.dispatch(actions.change(`legModels.legs`, legs));
    this.context.store.dispatch(actions.resetValidity("legModels.legs"));
  }

  fillWithTheSameInfo(model, value, index) {
    const { nextLegs } = this.state;
    let legs = JSON.parse(JSON.stringify(nextLegs));
    if (!value) {
      legs[index].passengers = defaultLegData.passengers;
      legs[index].special_items = defaultLegData.special_items;
      legs[index].luggage = defaultLegData.luggage;
    }

    legs[index].same_as_previous = value;
    legs = this.updateLegsWithPreviousSteps(legs);
    this.context.store.dispatch(actions.change(`legModels.legs`, legs));
  }

  throttleHandleChange(edata) {
    const pipelineId = edata?.pipeline_id || this.props?.editedRequest?.pipeline_id;
    this.props.updateRequestDraft({ ...edata, pipeline_id: pipelineId });
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("legModels"));
    this.context.store.dispatch(editRequest({}));
  }

  saveAndExit() {
    this.props
      .updateRequestDraft({
        ...this.props.modelRequest,
        pipeline_id: this.props.modelRequest.pipeline_id,
      })
      .then(() => {
        const pReq = storage.get("pipelineRequest");

        if (pReq) {
          const parsedPReq = JSON.parse(pReq);

          if (![3, 4].includes(parsedPReq.status)) {
            storage.add(
              "pipelineRequest",
              JSON.stringify({
                ...parsedPReq,
                has_saved_drafts: true,
                is_request_created: false,
                status: 3,
                pipeline_id: this.props.modelRequest.pipeline_id,
              }),
            );
          }
        }
        this.props.history.push({
          pathname: "/marketplace/pipelines",
          state: { from_new_request: true },
        });
      });
  }

  updateClientReferenceHanlder(handler) {
    this.setState({ submitClientReference: handler });
  }

  updateRequestReferenceId(referenceId) {
    this.context.store.dispatch(actions.change("legModels.client_id", referenceId));
  }

  clientReferenceAfterSubmit() {
    this.context.store.dispatch(actions.submit("legModels"));
  }

  render() {
    const {
      legs = [],
      number_children,
      number_adults,
      flight_type,
      flight_purpose,
      broker_intention,
    } = this.props.modelRequest;

    const generalInfoFilled = () => {
      return flight_type && flight_purpose;
    };

    const { currentDistance, legsOpen, accordionsOpen, oldRequest } = this.state;

    const cachedForm = JSON.parse(storage.get("request_form"));

    const pipeline_id =
      (cachedForm && cachedForm.pipeline_id) ||
      (this.props.editedRequest && this.props.editedRequest.pipeline_id);

    const newLegs = flight_type === "1" ? [legs[0]] : legs;
    const legContainers = newLegs
      .filter((leg) => Boolean(leg.passengers))
      .map((leg, index) => {
        return (
          <li
            key={index}
            id={`gh-accordion-item${[index]}`}
            className={`${legsOpen ? "uk-open" : ""} gh-accordion-section`}
          >
            <a className="uk-accordion-title">{legTitle(flight_type, index) || "Legs"}</a>
            <div
              className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top"
              aria-hidden={!legsOpen}
            >
              <LegFormComponent
                modelName={"legModels"}
                {...leg}
                leg={leg}
                model={this.props.modelRequest}
                form={this.props.form}
                readOnly={index > 0 && this.props.modelRequest.flight_type === "2"}
                updateFromToModel={this.updateLegFromTo.bind(this)}
                departure_date={leg.date}
                children={number_children}
                adults={number_adults}
                flightType={this.props.modelRequest.flight_type}
                distance={this.state.currentDistance}
                action={this.setLandings.bind(this, index)}
                index={index}
                removeLeg={this.removeLeg.bind(this)}
                onSubmitDistance={this.sumDistance}
                updateReferenceDate={this.updateReferenceDate.bind(this)}
                handleAssignPad={this.handleAssignPad.bind(this)}
                screenDimensions={this.props.screenDimensions}
                currentDistance={currentDistance[index]}
                distanceToTime={this.distanceToTime(currentDistance[index], index)}
                sameInfoCheckbox={index >= 1}
                fillWithTheSameInfo={this.fillWithTheSameInfo}
                fillWithTheSameInfoNextLeg={this.fillWithTheSameInfoNextLeg.bind(this)}
                requestForm={true}
                legsOpen={legsOpen}
                oldRequest={oldRequest}
                openAvailableCountriesModal={this.openAvailableCountriesModal}
              />
            </div>
          </li>
        );
      });

    return (
      <IsVerifiedPermission
        match={this.props.match}
        title={this.props.title}
        skipLayout={true}
        content={staticService.findByAlias("pageAccessRestricted")}
        role={"broker"}
      >
        <section className="new-request">
          {/*NEW REQUEST NAV*/}
          <RequestNavComponent />

          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom gh-padding-medium-top",
            }}
          >
            <div className="request-form-title uk-margin-small-bottom">
              <h3
                className="uk-margin-remove"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("requestForm"),
                }}
              />
            </div>

            <Form
              model="legModels"
              onChange={this.handleRequestFormChange.bind(this)}
              onSubmit={this.onSubmit.bind(this)}
              validators={{
                "": {
                  validateLocations: (vals) => this.validateLocations(),
                  legsDatetime: (vals) => this.validateBeforeSubmit(vals.legs),
                },
              }}
            >
              {/*GENERAL INFORMATION*/}
              <RequestGeneralInformation
                flight_type={flight_type}
                broker_intention={broker_intention}
                flight_purpose={flight_purpose}
                changeFlightType={this.changeFlightType}
                modelName="legModels"
                purposesList={this.state.purposesList}
                generalModel={this.props.modelRequest}
              />
              <div onClick={() => this.validateBeforeOpenAccordion(false)}>
                <ul
                  data-uk-accordion="collapsible: true"
                  className="disabled-list gh-accordion"
                  id="first-accordion"
                  style={{ pointerEvents: generalInfoFilled() ? "auto" : "none" }}
                >
                  {legContainers}
                </ul>
              </div>

              {flight_type === "3" ? (
                <div className="uk-margin-top uk-flex uk-flex-center">
                  <a className="gh-request-add-btn" onClick={this.addLeg}>
                    + {staticService.findByAlias("AddLeg")}
                  </a>
                </div>
              ) : (
                ""
              )}

              {/*AIRCRAFT PERFORMANCE*/}
              <div onClick={() => this.validateBeforeOpenAccordion(true)}>
                <ul
                  data-uk-accordion="collapsible: true; multiple: true "
                  id="second-accordion"
                  className="gh-accordion disabled-list uk-margin-top"
                  style={{ pointerEvents: generalInfoFilled() ? "auto" : "none" }}
                >
                  <li className={`${accordionsOpen ? "uk-open" : ""} gh-accordion-section`}>
                    <a
                      className="uk-accordion-title"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("AircraftPreferences"),
                      }}
                    />
                    <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
                      <LegExtraFieldset model={"legModels"} visible={true} />
                    </div>
                  </li>
                </ul>
              </div>

              {/*ADDITIONAL INFO*/}
              <div onClick={() => this.validateBeforeOpenAccordion(true)}>
                <ul
                  data-uk-accordion="collapsible: true; multiple: true "
                  id="third-accordion"
                  className="gh-accordion disabled-list uk-margin-top"
                  style={{ pointerEvents: generalInfoFilled() ? "auto" : "none" }}
                >
                  <li className={`${accordionsOpen ? "uk-open" : ""} gh-accordion-section`}>
                    <a
                      className="uk-accordion-title"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("additionalInformationLabel"),
                      }}
                    />
                    <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
                      <div className="gh-request-area">
                        <div className="gh-request-area-box">
                          <Control.textarea
                            model=".other_info"
                            // style={{ overflowY: "hidden" }}
                            style={{ maxHeight: 108 }}
                            id="request_area"
                            onChange={this.updateTextArea}
                            changeAction={updateModel}
                            className={"uk-textarea"}
                            validators={{ max: max(1000) }}
                            placeholder={staticService
                              .findByAlias("requestAdditionalInfoPlaceholder")
                              .replace(/<[^>]+>/g, "")
                              .replace(/&nbsp;/g, "")}
                          />
                          <Errors
                            model=".other_info"
                            show={true}
                            wrapper={CustomValidateError}
                            messages={{
                              max: validationMessages().maxMessage(1000),
                            }}
                          />
                        </div>
                      </div>
                      <Control
                        className="uk-margin-top"
                        model=".other_info_attachments"
                        component={({ value, onChange, className }) => (
                          <Upload
                            defaultFileList={value}
                            onChange={onChange}
                            className={className}
                            accept=".png,.jpg,.pdf"
                          />
                        )}
                      />
                    </div>
                  </li>
                </ul>
              </div>

              {/* PRIVATE NOTES */}
              <div onClick={() => this.validateBeforeOpenAccordion(true)}>
                <ul
                  data-uk-accordion="collapsible: true; multiple: true "
                  id="fourth-accordion"
                  className="gh-accordion disabled-list uk-margin-top"
                  style={{ pointerEvents: accordionsOpen ? "auto" : "none" }}
                >
                  <li
                    className={`${
                      accordionsOpen ? "uk-open" : ""
                    } gh-accordion-section uk-position-relative`}
                  >
                    <a
                      className="uk-accordion-title"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("privateNotes"),
                      }}
                    />
                    <div className="absolute tooltip-wrapper">
                      <Tooltip
                        wrapperClassName="gh-private-notes-tooltip-mobile"
                        pos="top-right"
                        mobilePos="top-center"
                      >
                        {staticService.findByAlias("privateNotesNewRequestTooltip")}
                      </Tooltip>
                    </div>
                    <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
                      <div data-uk-grid className="uk-form-controls">
                        <div className="uk-width-1-1 uk-width-1-2@s">
                          <span className="uk-flex">
                            <label
                              className="uk-form-label"
                              dangerouslySetInnerHTML={{
                                __html: staticService.findByAlias("notes"),
                              }}
                            />
                          </span>
                          <Control.text
                            model=".private_notes"
                            className="uk-input"
                            placeholder={staticService.findByAlias("notesPlaceholder")}
                            changeAction={updateModel}
                          />
                        </div>
                        <BrokerClientReference
                          setSubmitCb={this.updateClientReferenceHanlder}
                          afterSubmit={this.clientReferenceAfterSubmit}
                          onReferenceChange={this.updateRequestReferenceId}
                          defaultReferenceId={
                            this.props.modelRequest.client_id || this.props.editedRequest.client_id
                          }
                          resetFormValidity={() =>
                            this.context.store.dispatch(actions.resetValidity("legModels"))
                          }
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/*REVIEW BUTTON*/}
              <div className="uk-margin-top uk-margin-bottom gh-request-gen-btns uk-padding-remove">
                <NextButton
                  onClick={() => {
                    this.checkErrors();
                    this.state.submitClientReference();
                  }}
                />
                {pipeline_id ? (
                  <button
                    onClick={this.saveAndExit}
                    type="button"
                    className="uk-button uk-button-default uk-margin-small-left gh-pipeline-save-exit"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("saveAndExit"),
                      }}
                    />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </Form>
          </BlockComponent>
        </section>
      </IsVerifiedPermission>
    );
  }
}

BrokerNewRequestComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerNewRequestComponent.propTypes = {
  requestDraft: PropTypes.object,
  getRequestDraft: PropTypes.func,
  updateRequestDraft: PropTypes.func,
  modelRequest: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      add: sendOnReviewRequest,
      updateLeg: (index, key, value) => {
        return dispatch(actions.change(`${modelName}.legs.[${index}].${key}`, value));
      },
      getPurposes,
      getRequestDraft,
      updateRequestDraft,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    modelRequest: state.legModels,
    legDistance: state.legDistance,
    form: state.forms.legModels,
    successRequest: !!state.booking_request.length,
    screenDimensions: state.screenDimensions,
    requestID: state.booking_request.id ? state.booking_request.id : null,
    editedRequest: state.editedRequest,
    requestDraft: state.requestDraft,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerNewRequestComponent);
export { COMPONENT as BrokerNewRequestComponent };

// eslint-disable-next-line react/prop-types
const NextButton = ({ onClick, disabled }) => {
  return (
    <div className="new-request-next-button-wrapper">
      <button
        className="uk-button uk-button-primary"
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("next"),
          }}
        />
      </button>
      <NewRequestNextStepInfo />
    </div>
  );
};
