const GetHeliEvents = class EventService {
  constructor() {
    this.events = {};
  }

  setEvent(event, value) {
    this.events[event] = value;
    return this;
  }

  getEvent(event) {
    return this.events[event];
  }

  trigger(event, params = {}) {
    const fn = this.getEvent(event);
    return typeof fn === "function" ? fn(event, params) : null;
  }

  clearEvent(event) {
    delete this.events[event];
  }
};

export default new GetHeliEvents();
