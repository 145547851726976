import jwt_decode from "jwt-decode";
import { roles, OPERATOR_MAIN, BROKER_MAIN } from "../configs";
import staticService from "./static.service";
import { isObjectEmpty, storage } from "../shared";
import { deleteCookie } from "../utils/deleteCookie";

class AuthClass {
  constructor() {
    this.type = this.getType();
    this.onChange = null;
  }

  getFromLocalStorage(item) {
    const _item = localStorage.getItem(item);
    if (_item !== "undefined" && _item !== null) {
      const _parsed = JSON.parse(_item);
      if (typeof _parsed === "string") return _parsed.replace(/^"(.*)"$/, "$1");
      return _parsed;
    }
    return false;
  }

  getCompanyFromLocalStorage() {
    const value = this.getFromLocalStorage("company");

    if (value && typeof value === "object" && !isObjectEmpty(value)) {
      return value;
    } else {
      localStorage.clear();
      deleteCookie();
      window.location.reload();
    }
  }

  getToken() {
    const access_token = this.getFromLocalStorage("access_token");
    if (access_token) {
      return access_token;
    }
    return false;
  }

  getType() {
    const token_type = this.getFromLocalStorage("token_type");
    if (token_type) {
      this.type = token_type;
      return token_type;
    }
    return false;
  }

  getUser() {
    const token = this.getToken();
    if (token) {
      let userData = jwt_decode(this.getToken());
      if (userData) {
        return userData;
      }
    }
    return false;
  }

  getRole() {
    const token = this.getToken();
    if (token) {
      let tokenObj = jwt_decode(this.getToken());
      if (tokenObj) {
        return tokenObj.role;
      }
    }
    return false;
  }

  isVerified() {
    if (this.company.verified > 0) {
      return true;
    }
    return false;
  }

  hasNewRequirements() {
    if (this.company.has_new_requirements > 0) {
      return true;
    }
    return false;
  }

  hasStripeAccount() {
    if (this.company.has_stripe_account) {
      return true;
    }
    return false;
  }

  isStripeVerified() {
    if (this.company.stripe_verified) {
      return true;
    }
    return false;
  }

  isOwner() {
    const token = this.getToken();
    if (token) {
      let tokenObj = jwt_decode(this.getToken());
      return tokenObj.isOwner;
    }
    return false;
  }

  getMainRole() {
    const role = this.getRole();
    if (role) return roles[role];
  }

  isBroker() {
    return this.getMainRole() === BROKER_MAIN;
  }

  isOperator() {
    return this.getMainRole() === OPERATOR_MAIN;
  }

  isAuthenticated() {
    return this.getToken() ? true : false;
  }

  setUserAuthData(tokenData = {}) {
    this.token = tokenData.access_token;
    this.type = tokenData.token_type;
    this.keep_logged = tokenData.keep_logged;
    this.expires_in = tokenData.expires_in;
    this.setAccount("access_token", this.token);
    this.setAccount("token_type", this.type);
    this.setAccount("company", this.getUser().company);
    this.setAccount("login_as_admin", !!this.getUser().loginAsAdmin);
    this.setAccount("managed_by_getheli", !!this.getUser().managedByGetheli);
    this.setAccount("keep_logged", this.keep_logged);
    this.setAccount("expires_in", this.expires_in);
  }

  setAccount(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getAccount(key) {
    const data = this.getFromLocalStorage(key);

    if (data) return data;
    return null;
  }

  logout() {
    if (this.getToken()) {
      const key = localStorage.getItem("pre_register_modal");
      localStorage.clear();
      localStorage.setItem(
        "pre_register_modal",
        typeof key === "string" ? key : JSON.stringify(key),
      );
      this.unset();
      return true;
    }
    return false;
  }

  firstTimeLogged() {
    return this.getUser().last_logged === null;
  }

  get fullName() {
    const user = this.getUser();
    return user.first_name + " " + user.last_name;
  }

  get company() {
    if (this.getAccount("company")) {
      return this.getAccount("company");
    } else {
      localStorage.clear();
      deleteCookie();
      return null;
    }
  }

  get fullNameInitials() {
    const user = this.getUser();
    return (
      user.first_name &&
      user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase()
    );
  }

  get acceptance() {
    return this.getUser().notify_terms_accept;
  }

  get activeSoleTrader() {
    return this.getUser().active_sole_trader;
  }

  get apiAcceptance() {
    return this.getUser().is_api_terms;
  }

  getHeliServiceTerms() {
    return this.getMainRole() === "operator"
      ? JSON.parse(storage.get("userTerms")).country_terms_list.helicopter_services_terms
      : false;
  }

  unset() {
    this.token = null;
    this.type = null;
  }

  getFeatureStatus(alias) {
    const feature =
      this.company && this.company.features && this.company.features.find((f) => f.alias === alias);

    if (feature && !feature.is_enabled) {
      return {
        disabled: true,
        "uk-tooltip": staticService.findByAlias("enableFeature"),
      };
    }
  }

  hasFeature(alias) {
    const feature =
      this.company && this.company.features && this.company.features.find((f) => f.alias === alias);

    return !!(feature && feature.is_enabled);
  }
}

export default new AuthClass();
