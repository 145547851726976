import React from "react";
import { NavLink } from "react-router-dom";

const Backlink = ({ title, path }) => {
  return (
    <NavLink to={path} className="uk-link-text">
      <span data-uk-icon={"arrow-left"} className={"uk-margin-small-right"} />
      <b className={"font-weight-500"}>{title}</b>
    </NavLink>
  );
};

export { Backlink };
