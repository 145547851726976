import React from "react";
import { Form } from "react-redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import BaseFilterComponent from "./base-filter.component";
import { EmptyMarketplaceFilterFieldset } from "../../../forms/fieldsets/";
import {
  getMarketPlaceEmptyLegsByFilter,
  getEmptyLegsSuccess,
  loadActivityFlightsSuccess,
} from "../../../../actions";
import { filterMapStateToProps } from "../../../../shared/";
import { validationRules } from "../../../../configs/";
import { allModels } from "../../../../reducers/";

const modelName = "emptyLegsBrokerFiltersModel";

const { rangeValidation, rangeValidationEndDate } = validationRules;

class BrokerMarketplaceEmptyLegsFiltersComponent extends BaseFilterComponent {
  constructor(props) {
    super(props, modelName);
  }

  get Model() {
    return allModels.emptyLegsBrokerFiltersModel;
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    return (
      <div className="uk-card uk-card-default uk-card-body">
        <div>
          <Form
            model={modelName}
            onSubmit={this.handleFormSubmit.bind(this, "")}
            validators={{
              "": {
                rangeRequired: rangeValidation,
                endDateValidation: rangeValidationEndDate,
              },
            }}
          >
            <EmptyMarketplaceFilterFieldset
              modelName={modelName}
              updateLocation={this.updateLocation.bind(this)}
              disablePastDate={true}
              activity={this.props.activity}
              updateModelFn={this.props.updateModel}
              resetModel={this.reset.bind(this)}
              showActivity={false}
              isSubmitEnabled={this.isSubmitEnabled}
              resetValidity={this.props.resetValidity}
            />
          </Form>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.resetModel(modelName);
  }
}

BrokerMarketplaceEmptyLegsFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      resetModel: (model) => dispatch(actions.reset(model)),
      resetValidity: (model) => dispatch(actions.resetValidity(model)),
      get: getMarketPlaceEmptyLegsByFilter,
      update: (response) => {
        return (dispatch) => {
          dispatch(getEmptyLegsSuccess(response.list));
          dispatch(loadActivityFlightsSuccess(response.mapActivity));
        };
      },
    },
    dispatch,
  );
};

const COMPONENT = connect(
  filterMapStateToProps.bind(null, modelName),
  mapDispatchToProps,
)(BrokerMarketplaceEmptyLegsFiltersComponent);
export { COMPONENT as BrokerMarketplaceEmptyLegsFiltersComponent };
