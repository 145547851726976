import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";

class BankAccountInfoComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let asterics = "";
    // example includes iban example number for account country with placeholder ( characters length in number )
    const realNumberLength =
      this.props.bankAccountFields.account_number.example.split(" ")[0].length -
      this.props.accountInfo.account_last.length;
    Array.from({ length: realNumberLength }).map(() => (asterics += "*"));
    return (
      <div className="uk-align-center">
        {this.props.bankAccountFields &&
          Object.keys(this.props.bankAccountFields).map((field) => (
            <div data-uk-grid key={field}>
              <div className="uk-width-1-1@s uk-width-1-3@m">
                <label>{this.props.bankAccountFields[field].label}</label>
              </div>
              <div className="uk-width-1-1@s uk-width-expand@m">
                {field === "account_number"
                  ? `${asterics} ${this.props.accountInfo.account_last}`
                  : this.props.accountInfo[field]}
              </div>
            </div>
          ))}
        <div data-uk-grid>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <label
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("holderType"),
              }}
            />
          </div>
          <div className="uk-width-1-1@s uk-width-expand@m">
            {this.props.accountInfo.account_type}
          </div>
        </div>
        <div data-uk-grid>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <label
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("HolderName"),
              }}
            />
          </div>
          <div className="uk-width-1-1@s uk-width-expand@m">
            {this.props.accountInfo.account_holder}
          </div>
        </div>
        <div data-uk-grid>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <label
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("AccountCountry"),
              }}
            />
          </div>
          <div className="uk-width-1-1@s uk-width-expand@m">
            {this.props.accountInfo.account_country}
          </div>
        </div>
        <div data-uk-grid>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <label
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("AccountCurrency"),
              }}
            />
          </div>
          <div className="uk-width-1-1@s uk-width-expand@m">
            {this.props.accountInfo.account_currency}
          </div>
        </div>
        <div className={"uk-margin-top uk-text-center"}>
          <a
            className="uk-button uk-button-primary uk-margin-medium-top"
            onClick={this.props.changeAccount}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("ChangeBankAccount"),
              }}
            />
          </a>
        </div>
      </div>
    );
  }
}

BankAccountInfoComponent.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  changeAccount: PropTypes.func.isRequired,
};

BankAccountInfoComponent.defaultProps = {
  accountInfo: {},
};

export { BankAccountInfoComponent };
