import * as types from "../../actions/actionTypes";

function favourites(state = [], action) {
  switch (action.type) {
    case types.LOAD_FAVORITES:
    case types.ADD_FAVORITE:
    case types.DELETE_FAVORITE:
      return [...action.companies];
    default:
      return state;
  }
}

function autocomplete(state = [], action) {
  switch (action.type) {
    case types.POPULATE_AUTOCOMPLETE_LIST:
      return [...action.items];
    case types.CLEAR_AUTOCOMPLETE_LIST:
      return [];
    default:
      return state;
  }
}

export { favourites, autocomplete };
