import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import staticService from "../../../../services/static.service";
import { bindActionCreators } from "redux";
import { BlockComponent, ConfirmComponent } from "../../../common";
import {
  ReviewAdditionalInfoComponent,
  ReviewLegComponent,
  ReviewLegsSummaryComponent,
  ReviewPreferenceComponent,
  ReviewPrivateNotesComponent,
} from "./review";
import { ASSIGN_REQUEST, config } from "../../../../configs";
import { actions, Control } from "react-redux-form";
import { NavLink } from "react-router-dom";
import { alertInstance, isObjectEmpty, spinnerInstance, storage } from "../../../../shared";
import {
  createRequestFromEmptyLeg,
  editRequest,
  getPurposes,
  REQUEST_UPDATED,
  saveRequest,
  requestSwitchBroker,
  REVIEW_REQUEST_ADD_OPERATORS,
  sendOnReviewRequest,
} from "../../../../actions";
import HelicopterSuggestions from "../../QuoteForm/components/HelicopterSuggestions/HelicopterSuggestions";
import auth from "../../../../services/auth";
import RequestEditRights from "../common/RequestStatus/RequestEditRights";
import { showNotification } from "../../../../utils/showNotification";
import BrokerSubmitRequest from "./BrokerSubmitRequestModal";
import Auth from "../../../../services/auth";
import ReviewGeneralInformationComponent from "./review/review-general-information.component";

const BrokerReviewRequestComponent = (props) => {
  const { hidePrivateNotes, brokerCompany, request } = props;
  // eslint-disable-next-line no-unused-vars
  const [confirmData, setConfirmData] = useState({
    title: staticService.findByAlias("confirmRequestAlertModalTitle"),
    content: staticService.findByAlias("requestSuccess"),
    status: 0,
  });
  const [requestForm, setRequestForm] = useState({});
  const [purposes, setPurposes] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [buttonTextBasedOnFeature, setButtonTextBasedOnFeature] = React.useState(
    staticService.findByAlias("SubmitRequest"),
  );
  const confirmComponentRef = useRef();
  const confirmRequestModal = useRef();

  const isEmptyLeg = !!requestForm?.empty_leg_id;

  useEffect(() => {
    setMounted(true);
    window.scrollTo(0, 0);

    props.getPurposes().then((data) => setPurposes(data));

    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (Auth.hasFeature("send_the_request_to_wished_operators") && !isEmptyLeg) {
      setButtonTextBasedOnFeature(staticService.findByAlias("selectOperatorsAndSubmitRequest"));
    } else {
      setButtonTextBasedOnFeature(staticService.findByAlias("SubmitRequest"));
    }
  }, [isEmptyLeg]);

  const updateOperators = (response) => {
    if (response?.operators) {
      props.dispatch({ type: REVIEW_REQUEST_ADD_OPERATORS, payload: response.operators });
    }
  };

  useEffect(() => {
    // fetch request data to get relevant operators list
    if (
      isObjectEmpty(requestForm) ||
      props.bookingRequestOperators?.length > 0 ||
      !props.isCreateRequestForm
    )
      return;

    props.sendOnReviewRequest(requestForm, updateOperators, "legModels");
  }, [requestForm]);

  useEffect(() => {
    const formFromStorage = JSON.parse(storage.get("request_form"));
    if (
      (formFromStorage && mounted && JSON.stringify(request) === JSON.stringify(formFromStorage)) ||
      isObjectEmpty(request)
    ) {
      setRequestForm(formFromStorage);
    } else {
      setRequestForm(request);
    }
  }, [request]);

  const handleAssignPad = ({ coords, title, initialCoords, legIndex }) => {
    const currentLegs = [...this.props.modelRequest.legs] || [];

    if (currentLegs.length > 0) {
      for (let index = 0, legsLength = currentLegs.length; index < legsLength; index++) {
        const i = legIndex || index;

        if (i < legsLength - 1) {
          if (currentLegs[i].from_coords === `${initialCoords.lat}|${initialCoords.lng}`) {
            currentLegs[i] = {
              ...currentLegs[i],
              from_coords: `${coords.lat}|${coords.lng}`,
              from: title,
              from_source: config.locationSource[1],
            };
            break;
          } else if (currentLegs[i].to_coords === `${initialCoords.lat}|${initialCoords.lng}`) {
            currentLegs[i] = {
              ...currentLegs[i],
              to_coords: `${coords.lat}|${coords.lng}`,
              to: title,
              to_source: config.locationSource[1],
            };
            currentLegs[i + 1] = {
              ...currentLegs[i + 1],
              from_coords: `${coords.lat}|${coords.lng}`,
              from: title,
              from_source: config.locationSource[1],
            };
            break;
          }
        } else if (i === legsLength - 1) {
          if (currentLegs[i].from_coords === `${initialCoords.lat}|${initialCoords.lng}`) {
            currentLegs[i] = {
              ...currentLegs[i],
              from_coords: `${coords.lat}|${coords.lng}`,
              from: title,
              from_source: config.locationSource[1],
            };
          } else if (currentLegs[i].to_coords === `${initialCoords.lat}|${initialCoords.lng}`) {
            currentLegs[i] = {
              ...currentLegs[i],
              to_coords: `${coords.lat}|${coords.lng}`,
              to: title,
              to_source: config.locationSource[1],
            };
          }
        }
      }
    }
    props.updateModel("legModels.legs", currentLegs);
  };

  const resetAll = () => {
    storage.delete("request_form");
    props.resetModel("legModels");
    props.resetRequest();
    props.history && props.history.push("/new-request");
  };

  const sendToEdit = () => {
    props.editRequest(JSON.parse(storage.get("request_form")));

    if (
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.fromEmptyLegUrl
    ) {
      return props.history.push(props.history.location.state.fromEmptyLegUrl);
    }
    return props.history.push("/new-request");
  };

  const handleAddOperatorsAndSubmit = (selectedOperators) => {
    onSubmit({ ...requestForm, selected_operators: selectedOperators });
  };

  const handleOpenModalOrSubmit = () => {
    if (isEmptyLeg) {
      onSubmit(requestForm);
    } else {
      // open select operators modal for simple request
      confirmRequestModal.current.openModal();
    }
  };

  const onSubmit = (model) => {
    spinnerInstance.setProp("type", "large").show();
    confirmRequestModal.current?.closeModal();

    const onSuccess = (data) => {
      props.history.push(config.redirectToOpenRequests);
      alertInstance.eventInstance.trigger("global", {
        visible: true,
        message: data.content,
        type: alertInstance.successProp,
      });
      spinnerInstance.setProp("type", "large").hide();

      props.resetModel("requestFromEmptyLegModel");
      props.resetRequest();
    };

    if (model.empty_leg_id) {
      props.submitModel(
        "requestFromEmptyLegModel",
        props.add(model, onSuccess, "requestFromEmptyLegModel"),
      );
    } else {
      props.submitModel("legModels", props.add(model, onSuccess, "legModels"));
    }
  };

  const activeButton = () => {
    if (parseInt(confirmData.status) > 0) {
      return (
        <div className="uk-text-center gh-modal-footer gh-modal-footer-request">
          <a
            className={"uk-button uk-button-primary gh-request-active"}
            target={"_blank"}
            rel="noreferrer"
            href={confirmData.url}
            dangerouslySetInnerHTML={{
              __html: confirmData.btn_label,
            }}
          />
          <hr />
        </div>
      );
    }
  };

  const triggerAction = (
    action,
    quote,
    // eslint-disable-next-line no-unused-vars
    openModal = true,
    options = { successText: "Request assigned with success" },
  ) => {
    if (action === ASSIGN_REQUEST) {
      return props.requestSwitchBroker(quote.id, quote).then((_request) => {
        props.onUpdateRequest(_request, request.id);
        showNotification(options.successText, "success");
      });
    }
  };

  const legAddresses = React.useMemo(() => {
    const legs = requestForm.legs || [];
    const addresses = [];

    if (legs.length > 0) {
      legs
        .filter((leg) => leg.from && leg.from_coords && leg.to && leg.to_coords)
        .forEach((leg) => {
          addresses.push(leg);
        });
    }

    if (+requestForm.flight_type === 2) {
      return [addresses[1], addresses[0]];
    }

    return addresses;
  }, [requestForm]);

  const fromEmptyLegs =
    props.history &&
    props.history.location &&
    props.history.location.state &&
    !!props.history.location.state.fromEmptyLegUrl;

  return (
    <section className="request-review">
      <BlockComponent
        visible={true}
        parentProps={{
          className: "uk-section uk-padding-remove-bottom gh-padding-medium-top",
        }}
      >
        <h1
          className="gh-page-title"
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("requestReview"),
          }}
        />
        {props.showEditRight && (
          <RequestEditRights
            brokersList={request?.edit_rights_brokers_list || []}
            assignAction={triggerAction}
            request={request}
            layout="vertical"
          />
        )}
        <div className="uk-container">
          <ReviewGeneralInformationComponent
            requestForm={requestForm}
            purposes={purposes}
            brokerCompany={brokerCompany}
          />
          {requestForm &&
            requestForm.legs &&
            requestForm.legs.map((leg, index) => (
              <ReviewLegComponent
                key={index}
                leg={leg}
                index={index}
                flightType={requestForm.flight_type}
                nav={props.nav}
              />
            ))}
          {legAddresses ? (
            <ReviewLegsSummaryComponent
              handleAssignPad={handleAssignPad}
              screenDimensions={props.screenDimensions}
              requestForm={requestForm}
              legAddresses={legAddresses}
              review={true}
              returnFlight={requestForm.flight_type === "2"}
            />
          ) : (
            ""
          )}
          <ReviewPreferenceComponent requestForm={requestForm} />
          {auth.hasFeature("instant_price_estimates") &&
            props.showHeliSuggestions &&
            !fromEmptyLegs && <HelicopterSuggestions editingQuote={requestForm} />}
          <ReviewAdditionalInfoComponent requestForm={requestForm} />
          {!hidePrivateNotes && (
            <ReviewPrivateNotesComponent
              requestForm={requestForm}
              onUpdateRequest={props.onUpdateRequest}
            />
          )}
        </div>
        <div className="uk-flex uk-flex-between uk-margin-top uk-margin-bottom gh-request-review-actions">
          <Control.button
            model="legModels"
            onClick={sendToEdit}
            className="uk-button uk-button-primary gh-edit-request-btn gh-request-gen-btns"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("editRequest"),
              }}
            />
          </Control.button>
          <Control.button
            model="legModels"
            onClick={handleOpenModalOrSubmit}
            className="uk-button uk-button-primary gh-request-gen-btns"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: buttonTextBasedOnFeature,
              }}
            />
          </Control.button>
        </div>
      </BlockComponent>
      <ConfirmComponent
        ref={confirmComponentRef}
        info={
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: confirmData.content,
              }}
              className={"uk-margin-bottom"}
            />
            {activeButton()}
            <div className="gh-modal-footer uk-text-center gh-modal-footer-request">
              <div>
                <button
                  className={`uk-button uk-modal-close uk-margin-small-top uk-button-primary`}
                  onClick={resetAll}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("createNew"),
                    }}
                  />
                </button>
              </div>
              <div>
                <NavLink
                  className={"uk-button uk-margin-small-top uk-button-default"}
                  to={"/flights/requests/" + props.requestID}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("viewRequest"),
                  }}
                />
              </div>
              <div>
                <NavLink
                  className={"uk-button uk-margin-small-top uk-button-default"}
                  to={"/flights/requests"}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("viewAllRequests"),
                  }}
                />
              </div>
            </div>
          </div>
        }
        buttonsProps={{}}
        showCloseBtn={true}
        closeBtnTemplate={
          <NavLink className={"gh-modal-close"} to={config.redirectUserDashboard} data-uk-close />
        }
        modalProps={{
          id: "confirm-request",
          title: confirmData.title,
          options: {
            clsPage: "",
            bgClose: false,
            escClose: false,
          },
        }}
      />

      {!isEmptyLeg && (
        <BrokerSubmitRequest
          confirmRequestModal={confirmRequestModal}
          handleAddOperatorsAndSubmit={handleAddOperatorsAndSubmit}
          bookingRequestOperators={props.bookingRequestOperators}
        />
      )}
    </section>
    // </IsVerifiedPermission>
  );
};

BrokerReviewRequestComponent.propTypes = {
  showHeliSuggestions: PropTypes.bool,
  hidePrivateNotes: PropTypes.bool,
  brokerCompany: PropTypes.object,
  history: PropTypes.object,
  editRequest: PropTypes.func,
  request: PropTypes.object,
  requestSwitchBroker: PropTypes.func,
  onUpdateRequest: PropTypes.func,
  showEditRight: PropTypes.bool,
  bookingRequestOperators: PropTypes.array,
  sendOnReviewRequest: PropTypes.func,
  dispatch: PropTypes.func,
  getPurposes: PropTypes.func,
  add: PropTypes.func,
  submitModel: PropTypes.func,
  resetModel: PropTypes.func,
  resetRequest: PropTypes.func,
  updateModel: PropTypes.func,
  requestID: PropTypes.string,
  screenDimensions: PropTypes.object,
  nav: PropTypes.object,
  isCreateRequestForm: PropTypes.bool,
};

BrokerReviewRequestComponent.defaultProps = {
  showHeliSuggestions: false,
  hidePrivateNotes: false,
  brokerCompany: null,
  history: {},
  request: {},
  isCreateRequestForm: false,
};
BrokerReviewRequestComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPurposes,
      add: saveRequest,
      createRequestFromEmptyLeg,
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      submitModel: (model, value) => dispatch(actions.submit(model, value)),
      resetModel: (model) => dispatch(actions.reset(model)),
      resetRequest: () => dispatch({ type: REQUEST_UPDATED, request: {} }),
      editRequest,
      requestSwitchBroker,
      sendOnReviewRequest,
      dispatch,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    modelRequest: state.legModels,
    screenDimensions: state.screenDimensions,
    requestID: state.booking_request.id ? state.booking_request.id : null,
    successRequest: !!state.booking_request.length,
    bookingRequestOperators: state.booking_request.operators,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerReviewRequestComponent);
export { COMPONENT as BrokerReviewRequestComponent };
