import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { REJECTED, UNSUCCESSFUL } from "../../../../../configs";
import staticService from "../../../../../services/static.service";

import "./quoteReason.css";

const QuoteReason = (props) => {
  const { quote } = props;
  const [reason, setReason] = useState("");
  const [reasonType, setReasonType] = useState("");

  useEffect(() => {
    if (quote.status === UNSUCCESSFUL) {
      setReason(quote?.booking_request?.reason || quote.reason || quote.unsuccessful_reason || "");
      setReasonType("unsuccessfulReason");
      return;
    }
    if (quote.status === REJECTED) {
      setReason(quote.rejected_message || "");
      setReasonType("rejectedReason");
      return;
    }

    setReasonType("reasonLabelQuote");
  }, [quote]);

  if (!reason) return "";
  return (
    <div className="gh-quote-reason-wrapper">
      <hr className="gh-divider uk-margin-small-top uk-margin-small-bottom" />
      <div className="gh-quote-reason">
        <div className="gh-quote-general-info-title">
          <p
            className="uk-margin-remove"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(reasonType),
            }}
          />
        </div>

        <div className="gh-quote-general-info-value">{reason || ""}</div>
      </div>
    </div>
  );
};

QuoteReason.propTypes = {
  quote: PropTypes.object,
};

export default QuoteReason;
