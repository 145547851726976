import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const validationMessages = new Map();

export default function InlineEditInput(props) {
  const { setStatus, defaultValue, action, validators } = props;
  const [value, setValue] = useState(defaultValue);
  const [valid, setValid] = useState(true);
  const inputWrapper = useRef();

  const onSubmit = () => {
    if (!valid) return;

    action(value);
    setStatus(false);
  };

  const handleOnChange = (e) => {
    if (validators) {
      for (let i = 0; i < validators.length; i++) {
        if (!validators[i].rule(e.target.value)) {
          validationMessages.set(validators[i].key, validators[i].msg);
        } else {
          validationMessages.delete(validators[i].key);
        }
      }

      if (validationMessages.size) setValid(false);
      else setValid(true);
    }

    setValue(e.target.value);
  };

  const onKeyDownInputHandler = (e) => {
    if (e.key === "Enter") onSubmit();
  };

  const onKeyDownDocHandler = (e) => {
    if (e.key === "Escape") setStatus(false);
  };

  const deactivate = ({ target }) => {
    if (!inputWrapper.current.contains(target) && target !== inputWrapper.current) {
      setStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", deactivate);
    document.addEventListener("keydown", onKeyDownDocHandler);

    return () => {
      document.removeEventListener("click", deactivate);
      document.removeEventListener("keydown", onKeyDownDocHandler);

      validationMessages.clear();
    };
  }, []);

  return (
    <div className="inline-edit_input" ref={inputWrapper}>
      <input
        type="text"
        className="uk-input"
        value={value}
        onChange={handleOnChange}
        onKeyDown={onKeyDownInputHandler}
        autoFocus
      />
      {!!validationMessages.size && (
        <ul className="ul validation-errors disabled-list gh-input-errors">
          <li>{validationMessages.values().next().value}</li>
        </ul>
      )}
      <div className="inline-edit_actions">
        <button className="inline-edit_action success" onClick={() => onSubmit(value)}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="content"
          >
            <path
              fill="currentColor"
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
              className=""
            />
          </svg>
        </button>
        <button className="inline-edit_action error" onClick={() => setStatus(false)}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="times"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            className="svg-inline--fa fa-times fa-w-10 fa-7x"
          >
            <path
              fill="currentColor"
              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
              className=""
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

InlineEditInput.defaultProps = {
  defaultValue: "",
};

InlineEditInput.propTypes = {
  action: PropTypes.func,
  setStatus: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  validators: PropTypes.array,
};
