import React from "react";
import PropTypes from "prop-types";
import { Control, Form, Errors } from "react-redux-form";
import { validationRules, validationMessages, config } from "../../configs";
import { CustomValidateError, updateModel } from "../../shared";
import staticService from "../../services/static.service";
import { NavLink } from "react-router-dom";

const { required, min, alphaNumValidation } = validationRules;

class RequestPasswordFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  get formElements() {
    return (
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("additionalContentPasswordModal"),
          }}
        />
        <div className="uk-margin">
          <label className="uk-form-label">{this.props.label}</label>
          <Control
            className={"uk-input"}
            model={this.props.modelField}
            validators={{ required, min: min(6), alphaNumValidation }}
            changeAction={updateModel}
          />
          <Errors
            model={this.props.modelField}
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(null, this.props.label),
              min: validationMessages().minMessage(6),
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
            }}
          />
        </div>
        <div className="uk-active gh-modal-flex gh-modal-promo-code">
          <Control.button
            model={this.props.modelName}
            onClick={this.props.onClick}
            className={
              "uk-button uk-button-default gh-position-margin-right gh-modal-promo-small uk-margin-bottom gh-width-200"
            }
          >
            <span
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("continueWithoutCode") }}
            ></span>
          </Control.button>
          <Control.button
            model={this.props.modelName}
            disabled={{ valid: false }}
            className={
              "uk-button uk-button-primary  gh-position-margin-left gh-modal-promo-small gh-width-240 uk-margin-bottom gh-width-200"
            }
          >
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("submit") }}></span>
          </Control.button>
          {/*<span*/}
          {/*  className="gh-modal-promo-code-margin gh-modal-promo-font gh-text-modal-promo"*/}
          {/*  dangerouslySetInnerHTML={{__html: staticService.findByAlias('continueWithoutCode')}}*/}
          {/*  onClick={this.props.onClick}*/}
          {/*/>*/}
        </div>
      </div>
    );
  }

  render() {
    return this.props.visible ? (
      <Form model={this.props.modelName} onSubmit={this.props.action}>
        {this.formElements}
      </Form>
    ) : null;
  }
}

RequestPasswordFormComponent.propTypes = {
  modelName: PropTypes.string.isRequired,
  modelField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

export { RequestPasswordFormComponent };
