import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AccordionComponent from "../../../../common/AccordionComponent";
import staticService from "../../../../../services/static.service";
import warningIcon from "../../../../../assets/img/svg/warning-icon.svg";
import Tooltip from "../../../../common/Tooltip";
import flagIcon from "../../../../../assets/img/svg/flag.svg";
import { connect } from "react-redux";
import InfoContainerWithIcon from "../../../../common/InfoContainerWithIcon";
import SiteComponent from "../../../../common/SiteComponent";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";
import pluralize from "../../../../../utils/pluralize";
import { renderTime } from "../../../../../shared";
import Time from "../../../../common/Time";

const QuotePads = (props) => {
  const {
    pads,
    flight_type,
    selectedPadAndAircraft,
    screenW,
    setSelectedPadAndAircraft,
    readOnly,
  } = props;
  const [maxSize, setMaxSize] = useState(0);

  const handleLegSelectAircraft = (padIndex, aircraftIndex, setSelectedPadAndAircraft) => {
    if (selectedPadAndAircraft[0] === padIndex && selectedPadAndAircraft[1] === aircraftIndex)
      return;
    setSelectedPadAndAircraft([padIndex, aircraftIndex]);
  };

  const renderHelicopters = ({
    pads,
    padIndex,
    handleLegSelectAircraft,
    selectedPadAndAircraft,
    setSelectedPadAndAircraft,
    maxSize,
    screenW,
  }) => {
    if (!pads && pads.length <= 0) return;

    return (
      <div className="gh-details-flight-row">
        <div className="gh-quote-details-text-gray gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
          {staticService.findByAlias("helicoptersCrew")}
        </div>
        <div className="gh-quote-details-aircraft-listing-container">
          {pads[padIndex].aircrafts.map(
            (
              { id, manufacturer, aircraft_model, crew_size, is_crew_size_edited },
              aircraftIndex,
            ) => (
              <div
                className="gh-quote-details-details-row gh-quote-details-aircraft-listing-buttons-container"
                key={id + aircraftIndex}
              >
                <button
                  style={readOnly ? { cursor: "default" } : {}}
                  onClick={() =>
                    !readOnly
                      ? handleLegSelectAircraft(padIndex, aircraftIndex, setSelectedPadAndAircraft)
                      : null
                  }
                  className={`gh-quote-details-text-normal gh-quote-details-aircraft-listing-wrapper gh-quote-details-aircraft-listing-flag gh-quote-details-select-aircraft-btn gh-quote-details-${
                    !readOnly &&
                    selectedPadAndAircraft[0] === padIndex &&
                    selectedPadAndAircraft[1] === aircraftIndex
                      ? "select-aircraft-btn-active"
                      : ""
                  } ${
                    pads[padIndex].aircrafts.length !== aircraftIndex + 1 || is_crew_size_edited
                      ? "gh-quote-details-select-aircraft-btn-have-and"
                      : ""
                  }`}
                >
                  <span
                    className="gh-quote-details-aircraft-span gh-quote-details-aircraft-span-aircraft-name"
                    style={{ width: maxSize > 0 ? maxSize : "" }}
                  >
                    {manufacturer} {aircraft_model}
                  </span>
                  <div className="gh-quote-details-button-text-wrapper">
                    <span className="gh-quote-details-aircraft-span">with</span>
                    <span className="gh-quote-details-aircraft-span gh-quote-details-aircraft-span-pilots gh-quote-details-aircraft-span-with-flag">
                      {crew_size} {parseInt(crew_size) === 1 ? "pilot" : "pilots"}
                    </span>
                  </div>
                </button>
                {pads[padIndex].aircrafts.length !== aircraftIndex + 1 || is_crew_size_edited ? (
                  <span
                    className={`gh-quote-details-aircraft-span gh-quote-details-aircraft-andSpan gh-quote-details-text-normal ${
                      is_crew_size_edited && pads[padIndex].aircrafts.length !== aircraftIndex + 1
                        ? "gh-quote-details-aircraft-andSpan-with-additionals"
                        : is_crew_size_edited && pads[padIndex].aircrafts.length === 1
                        ? "gh-quote-details-aircraft-andSpan-with-flag"
                        : ""
                    }`}
                  >
                    {is_crew_size_edited && (
                      <span className="gh-quote-details-aircraft-andSpan-flag">
                        <Tooltip
                          pos="top-left"
                          refEl={"self"}
                          icon={flagIcon}
                          wrapperClassName="gh-quote-details-tooltip-flag"
                        >
                          {staticService.findByAlias("modifiedFromOriginal")}
                        </Tooltip>
                      </span>
                    )}
                    {pads[padIndex].aircrafts.length !== aircraftIndex + 1 && (
                      <span
                        className="gh-quote-details-aircraft-and"
                        style={{
                          marginLeft:
                            screenW <= 768 && screenW > 320 && !is_crew_size_edited ? "auto" : "",
                        }}
                      >
                        and
                      </span>
                    )}{" "}
                  </span>
                ) : null}
              </div>
            ),
          )}
        </div>
      </div>
    );
  };
  const renderPayload = (pad) => {
    const payloadTitle = [
      {
        name: staticService.findByAlias("passengers"),
        objKey: "passengers",
        options: {
          men: { plural: "Men", singular: "Man" },
          women: { plural: "Women", singular: "Woman" },
          children: { plural: "Children", singular: "Child" },
        },
      },
      {
        name: staticService.findByAlias("luggage"),
        objKey: "luggage",
        options: {
          carry_on_bags: { plural: "Carry-on baggages", singular: "Carry-on baggage" },
          hand_bags: { plural: "Hold baggages", singular: "Hold baggage" },
        },
      },
      {
        name: staticService.findByAlias("specialItems"),
        objKey: "special_items",
        options: {
          ski_pairs: { plural: "Ski pairs", singular: "Ski pair" },
          pets: { plural: "Pets", singular: "Pet" },
          hunting_weapons: { plural: "Hunting weapons", singular: "Hunting weapon" },
          golf_club_sets: { plural: "Golf club sets", singular: "Golf club set" },
          bicycles: { plural: "Bicycles", singular: "Bicycle" },
          other: { plural: "", singular: "" },
        },
      },
    ];
    return (
      <React.Fragment>
        <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
          {staticService.findByAlias("payloadRight")}
          {pad.is_payload_edited && (
            <span className="gh-quote-details-tooltip-margin">
              <Tooltip
                sign="<"
                mobilePos="bottom-right"
                pos="top-left"
                refEl={"self"}
                icon={flagIcon}
                wrapperClassName="gh-quote-details-tooltip-flag gh-quote-details-tooltip-fix-mobile"
              >
                {staticService.findByAlias("modifiedFromOriginal")}
              </Tooltip>
            </span>
          )}
        </div>

        <div className="gh-quote-details-payload-wrapper">
          {payloadTitle.map(({ name, objKey, options }, index) => {
            const PayloadValues = Object.entries(options).map(([k, v]) => !!pad[objKey][k]);
            const isPayloadExist = PayloadValues.some((v) => v);

            return (
              (isPayloadExist || objKey === "passengers") && (
                <div
                  key={index}
                  className={`gh-quote-details-text-normal gh-quote-details-payload-row`}
                  style={isPayloadExist ? {} : { marginBottom: 0 }}
                >
                  <span className="gh-quote-details-payload-title gh-quote-details-title-bold">
                    {name}
                  </span>
                  <div className="gh-quote-details-payload-listing-items-wrapper">
                    {isPayloadExist
                      ? Object.entries(options).map(([key, val], index) =>
                          pad[objKey][key] ? (
                            <span
                              className="gh-quote-details-payload-listing-item"
                              style={{ width: `${key === "other" ? "100%" : "auto"}` }}
                              key={index}
                            >
                              {pad[objKey][key]} {pluralize(val, pad[objKey][key])}
                            </span>
                          ) : null,
                        )
                      : "---"}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    // find max select aircraft button width
    const buttons = document.querySelectorAll(".gh-quote-details-aircraft-span-aircraft-name");
    let max = maxSize;
    if (buttons)
      buttons.forEach((el) => {
        if (el && el.clientWidth > max) max = el.clientWidth;
      });
    setMaxSize(max + 2);
  }, [pads, selectedPadAndAircraft]);

  const nauticalMilesString = (val) => {
    if (+val > 1) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("nauticalMilesLeg"),
          }}
        />
      );
    } else {
      const string = staticService.findByAlias("nauticalMilesLeg");
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: string.substring(0, string.length - 1),
          }}
        />
      );
    }
  };

  return (
    <section className="gh-flight-details gh-quote-details-section-card gh-quote-details-section-card--accordion-wrapper">
      <AccordionComponent items={pads} isOpen={true} flightType={flight_type}>
        {pads.map((pad, index) => {
          const {
            from_location,
            to_location,
            to_source,
            from_source,
            departure_time,
            time_max,
            is_refueling,
            maximum_weight_allowed,
            is_from_edited,
            is_to_edited,
            is_departure_time_edited,
          } = pad;

          return (
            <React.Fragment key={index}>
              {renderHelicopters({
                pads,
                padIndex: index,
                selectedPadAndAircraft,
                handleLegSelectAircraft,
                setSelectedPadAndAircraft,
                maxSize,
                screenW,
              })}
              <div className="gh-details-flight-row location">
                <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
                  {staticService.findByAlias("takeOffSite")}
                  {is_from_edited && (
                    <span className="gh-quote-details-tooltip-margin">
                      <Tooltip
                        sign="<"
                        mobilePos="bottom-right"
                        pos="top-left"
                        refEl={"self"}
                        icon={flagIcon}
                        wrapperClassName="gh-quote-details-tooltip-flag gh-quote-details-tooltip-fix-mobile"
                      >
                        {staticService.findByAlias("modifiedFromOriginal")}
                      </Tooltip>
                    </span>
                  )}
                </div>
                <div className="gh-quote-details-flight-row-item gh-quote-details-column">
                  <SiteComponent
                    icon={(from_source && from_source) || (from_location && from_location.source)}
                    name={from_location && from_location.name && from_location.name}
                    secondaryText={generateLocationAdditionalInfo(from_location)}
                    className="gh-quote-details-flight-site-component"
                  />
                  {from_location.details ? (
                    <InfoContainerWithIcon
                      title={staticService.findByAlias("additionalDetailsAboutLocation")}
                      text={from_location.details}
                      className="gh-quote-details-info-container"
                    />
                  ) : null}
                </div>
              </div>
              <div className="gh-details-flight-row location">
                <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
                  {staticService.findByAlias("landigSitesTitleBroker")}
                  {is_to_edited && (
                    <span className="gh-quote-details-tooltip-margin">
                      <Tooltip
                        sign="<"
                        mobilePos="bottom-right"
                        pos="top-left"
                        refEl={"self"}
                        icon={flagIcon}
                        wrapperClassName="gh-quote-details-tooltip-flag gh-quote-details-tooltip-fix-mobile"
                      >
                        {staticService.findByAlias("modifiedFromOriginal")}
                      </Tooltip>
                    </span>
                  )}
                </div>
                <div className="gh-quote-details-flight-row-item gh-quote-details-column">
                  <SiteComponent
                    icon={(to_source && to_source) || (to_location && to_location.source)}
                    name={to_location && to_location.name && to_location.name}
                    secondaryText={generateLocationAdditionalInfo(to_location)}
                    className="gh-quote-details-flight-site-component"
                  />
                  {to_location.details ? (
                    <InfoContainerWithIcon
                      title={staticService.findByAlias("additionalDetailsAboutLocation")}
                      text={to_location.details}
                      className="gh-quote-details-info-container"
                    />
                  ) : null}
                </div>
              </div>
              <div className="gh-details-flight-row">
                <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
                  {staticService.findByAlias("dateOfDeparture")}
                </div>
                <div className="gh-quote-details-text-normal">
                  <Time timestamp={departure_time} />
                </div>
              </div>
              <div className="gh-details-flight-row">
                <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
                  {staticService.findByAlias("timeOfDeparture")}
                  {is_departure_time_edited && (
                    <span className="gh-quote-details-tooltip-margin">
                      <Tooltip
                        sign="<"
                        mobilePos="bottom-right"
                        pos="top-left"
                        refEl={"self"}
                        icon={flagIcon}
                        wrapperClassName="gh-quote-details-tooltip-flag gh-quote-details-tooltip-fix-mobile"
                      >
                        {staticService.findByAlias("modifiedFromOriginal")}
                      </Tooltip>
                    </span>
                  )}
                </div>

                <div className="gh-quote-details-text-normal">
                  <Time timestamp={departure_time} showType="timeUtc" />
                </div>
              </div>
              <div className="gh-details-flight-row">
                <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
                  {staticService.findByAlias("estimatedFlightTimeQuote")}
                  <Tooltip
                    pos="top-center"
                    refEl="self"
                    wrapperClassName="uk-margin-small-left gh-bigger-icon"
                  >
                    {staticService.findByAlias("estimateTimeTooltip")}
                  </Tooltip>
                </div>
                <div className="gh-quote-details-text-normal">
                  {renderTime(time_max, is_refueling)}
                </div>
              </div>
              <div className="gh-details-flight-row">
                <div className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item">
                  {staticService.findByAlias("estimatedDistanceLeg")}
                </div>
                <div className="gh-quote-details-text-normal">
                  <span style={{ paddingRight: 5 }}>{pad?.estimated_distance}</span>
                  {nauticalMilesString(pad?.estimated_distance)}
                  {/*{Number(*/}
                  {/*  distanceBetween(*/}
                  {/*    parseCoordinates(pad.from_coords),*/}
                  {/*    parseCoordinates(pad.to_coords),*/}
                  {/*  ).toFixed(0),*/}
                  {/*).toLocaleString()}{" "}*/}
                  {/*{nauticalMilesString(*/}
                  {/*  distanceBetween(*/}
                  {/*    parseCoordinates(pad.from_coords),*/}
                  {/*    parseCoordinates(pad.to_coords),*/}
                  {/*  ).toFixed(0),*/}
                  {/*)}*/}
                </div>
              </div>
              <div className="gh-details-flight-row">{renderPayload(pad)}</div>
              {maximum_weight_allowed && maximum_weight_allowed !== "0.00" ? (
                <div className="gh-details-flight-row">
                  <div
                    className="gh-quote-details-flight-row-title gh-quote-details-flight-row-item"
                    style={{ color: "transparent" }}
                  >
                    {staticService.findByAlias("maxWeightAllowed")}
                  </div>
                  <InfoContainerWithIcon
                    icon={warningIcon}
                    text={`The maximum weight feasible for this specific leg is ${maximum_weight_allowed} kg`}
                    className="gh-quote-details-warning-message"
                  />
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          );
        })}
      </AccordionComponent>
    </section>
  );
};
const mapStateToProps = (state) => ({
  screenW: state.screenDimensions.screenInnerW,
});
QuotePads.propTypes = {
  pads: PropTypes.arrayOf(PropTypes.object),
  flight_type: PropTypes.number,
  selectedPadAndAircraft: PropTypes.array,
  setSelectedPadAndAircraft: PropTypes.func,
  screenW: PropTypes.number,
  readOnly: PropTypes.bool,
};
export default connect(mapStateToProps, {})(QuotePads);
