import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { updateChangePassword } from "../../../actions";
import { ChangePasswordFieldset } from "../fieldsets";
import { validationRules, validationMessages, profileTabs } from "../../../configs";
import { CustomValidateError, fireTracking } from "../../../shared";
import staticService from "../../../services/static.service";
import auth from "../../../services/auth";
import { MenuComponent } from "../../pages/user-online/common";

const modelName = "editChangePasswordModel";
const { passwordsMatchOld } = validationRules;

class ChangePasswordFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    context.store.dispatch(actions.reset(modelName));
    const items = profileTabs.filter((tab) => tab.roles.indexOf(auth.getRole()) !== -1);
    this.state = {
      selectedTab: items[0],
      tabs: items.map((menu) => {
        menu.title = staticService.findByAlias(menu.alias);
        return menu;
      }),
    };
    fireTracking(props.location.pathname);
  }

  render() {
    return (
      <div data-uk-grid>
        <div className={"uk-width-1-4@m uk-width-1-1@s"}>
          <MenuComponent items={this.state.tabs} />
        </div>
        <div className="uk-width-1-2@s uk-width-1-3@m gh-account-settings-margin gh-modal-promo-small uk-width-1-2@m uk-width-1-1@s gh-change-password-form">
          <Form
            model={modelName}
            validateOn="blur"
            validators={{
              "": { passwordsMatchOld },
            }}
            onSubmit={this.props.update}
          >
            <ChangePasswordFieldset modelName={modelName} userModel={this.props.userModel} />
            <Errors
              model={modelName}
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                passwordsMatchOld: validationMessages().passwordsMatchOldMessage,
              }}
            />
            <div className="uk-margin-top">
              <Control.button
                model={modelName}
                disabled={{ valid: false }}
                className={"uk-button uk-button-primary uk-align-center"}
              >
                <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("update") }} />
              </Control.button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

ChangePasswordFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userModel: state.editChangePasswordModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      update: updateChangePassword,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormComponent);
export { COMPONENT as ChangePasswordFormComponent };
