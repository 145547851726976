import React from "react";
import PropTypes from "prop-types";
import { legTitle } from "../../../../../shared";
import greenMarker from "../../../../../assets/img/svg/greenMarker.svg";
import yellowMarker from "../../../../../assets/img/svg/yellowMarker.svg";
import calendar from "../../../../../assets/img/svg/calendar.svg";
import clock from "../../../../../assets/img/svg/clock.svg";
import staticService from "../../../../../services/static.service";
import checkIcon from "../../../../../assets/img/svg/checkSimple.svg";
import simpleCheckIcon from "../../../../../assets/img/svg/checkSimple.svg";
import arrowRight from "../../../../../assets/img/svg/arrow-right.svg";
import moment from "moment";
import oval from "../../../../../assets/img/svg/oval.svg";
import { timeType } from "../../../../../configs";
import capitalizeString from "../../../../../utils/capitalizeString";
import transformAdditionalInfo from "../../../../../utils/transformAdditionalInfo";
import Time from "../../../../common/Time";

const ReviewLegComponent = ({ leg, index, flightType, nav }) => {
  const dateType = [
    staticService.findByAlias("exactDate"),
    staticService.findByAlias("somePossibilityOfChangeDate"),
    staticService.findByAlias("strongPossibilityChangeDate"),
  ];

  const specialItems = [
    { key: "bicycles", title: "bicycles" },
    { key: "golf_club_sets", title: "golfClubSets" },
    { key: "hunting_weapons", title: "huntingWeapons" },
    { key: "pets", title: "pets" },
    { key: "ski_pairs", title: "skiPairs" },
  ];

  const luggageItems = [
    { key: "carry_on_bags", title: "Carry-onBags" },
    { key: "hand_bags", title: "handBags" },
  ];

  const icon = (place) => {
    switch (place) {
      case "gh_event":
        return (
          <img
            src={yellowMarker}
            className="gh-location-marker"
            alt="marker"
            style={{ marginRight: 10 }}
          />
        );
      case "hellipaddy":
        return (
          <img
            src={greenMarker}
            className="gh-location-marker"
            alt="marker"
            style={{ marginRight: 10 }}
          />
        );
      case "google":
        return (
          <img src={oval} className="gh-location-marker" alt="marker" style={{ marginRight: 10 }} />
        );
    }
  };
  const emptyColumn = (legObj, list) => {
    return list.every((item) => legObj[item.key] === 0) && <h6>---</h6>;
  };
  const legTitleStyle = () => {
    if (nav && `${flightType}` === "3") {
      return {
        paddingTop: 15,
        borderTop: "1px solid #f0f0f0",
      };
    } else {
      return null;
    }
  };

  const returnStaticName = () => {
    const timeInfo = timeType.find((item) => item.key === leg.time_type);
    return timeInfo ? timeInfo.name : "";
  };
  return (
    <div className="gh-simple-section">
      <h4 className="gh-request-section-title uk-margin-bottom" style={legTitleStyle()}>
        {legTitle(flightType, index)}
      </h4>
      <div data-uk-grid>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="uk-flex gh-review-leg">
            {icon(leg.from_source)}
            <div className="gh-location">
              <h6
                className="gh-field-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("from"),
                }}
              />
              <div className="gh-location-fields">
                <h5>{leg.from_location && leg.from_location.name}</h5>
                <span>
                  {transformAdditionalInfo(
                    `${leg.from_location.type}|${leg.from_location.secondary_text}`,
                  )}
                </span>
              </div>
              <div className="uk-flex uk-flex-middle uk-margin-small-bottom">
                {leg.from_is_private ? (
                  <img style={{ marginRight: 10 }} src={checkIcon} alt="check" />
                ) : (
                  <img style={{ marginRight: 10, height: 15 }} src={arrowRight} alt="check" />
                )}
                {leg.from_is_private ? (
                  <h6
                    className="gh-take-off"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("exactTakeOffLocation"),
                    }}
                  />
                ) : (
                  <h6
                    className="gh-take-off"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("operToProvideTakeOff"),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {leg.from_details ? (
            <div className="gh-additional-info">
              <h6
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("additionalDetailsAboutLocation"),
                }}
              />
              <span>{leg.from_details}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="uk-flex gh-review-leg">
            {icon(leg.to_source)}
            <div className="gh-location">
              <h6
                className="gh-field-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("to"),
                }}
              />
              <div className="gh-location-fields">
                <h5>{leg.to_location && leg.to_location.name}</h5>
                <span>
                  {transformAdditionalInfo(
                    `${leg.to_location.type || ""}|${leg.to_location.secondary_text || ""}`,
                  )}
                </span>
              </div>
              <div className="uk-flex uk-flex-middle uk-margin-small-bottom">
                {leg.to_is_private ? (
                  <img style={{ marginRight: 10 }} src={checkIcon} alt="check" />
                ) : (
                  <img style={{ marginRight: 10, height: 15 }} src={arrowRight} alt="check" />
                )}
                {leg.to_is_private ? (
                  <h6
                    className="gh-take-off"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("exactLandingLocation"),
                    }}
                  />
                ) : (
                  <h6
                    className="gh-take-off"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("operToProvideLanding"),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {leg.to_details ? (
            <div className="gh-additional-info">
              <h6
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("additionalDetailsAboutLocation"),
                }}
              />
              <span>{leg.to_details}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <hr className="gh-divider" />
      <div data-uk-grid>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="uk-flex">
            <img src={calendar} alt="icon" className="review-icon" />
            <div className="gh-datetime-review">
              <h6
                className="gh-field-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("date"),
                }}
              />
              <div className="gh-datetime-fields">
                <h5>
                  <Time timestamp={leg.date} />
                </h5>
                <span>{dateType[Number(leg.date_type) - 1]}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="uk-flex">
            <img src={clock} alt="icon" className="review-icon" />
            <div className="gh-datetime-review">
              <h6
                className="gh-field-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("departureTime"),
                }}
              />
              <div className="gh-datetime-fields">
                <h5>
                  {moment(leg.departure_time).format("HH:mm")} (UTC
                  {moment().format("Z")})
                </h5>
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(returnStaticName()),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="gh-divider" />
      <div data-uk-grid className={nav ? "uk-grid-small" : ""}>
        <div className="uk-width-1-1 uk-width-1-4@s">
          <div className="gh-review-grid-section uk-padding-remove-left">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("passengers"),
              }}
            />
            {leg.passengers.men ? (
              <div className="row">
                <h6>{staticService.findByAlias("men")}</h6>
                <span>{leg.passengers.men}</span>
              </div>
            ) : (
              ""
            )}
            {leg.passengers.women ? (
              <div className="row">
                <h6>{staticService.findByAlias("women")}</h6>
                <span>{leg.passengers.women}</span>
              </div>
            ) : (
              ""
            )}
            {leg.passengers.children ? (
              <div className="row">
                <h6>{staticService.findByAlias("children")}</h6>
                <span>{leg.passengers.children}</span>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <h6 className="gh-text-bold">{staticService.findByAlias("total")}</h6>
              <span className="count">
                {leg.passengers.men + leg.passengers.women + leg.passengers.children}
              </span>
            </div>

            <span className="gh-simple-text-request-form">
              <img src={simpleCheckIcon} alt="icon" style={{ marginRight: 7 }} />
              {staticService.findByAlias(
                leg.passengers.passengers_amount_type.toString() === "1"
                  ? "exactPaxNumber"
                  : "posibilityOfChangePax",
              )}
            </span>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-4@s">
          <div className="gh-review-grid-section uk-padding-remove-left">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("luggage"),
              }}
            />
            {luggageItems.map((item) =>
              leg.luggage[item.key] ? (
                <div key={item.key}>
                  <div className="row">
                    <h6>{capitalizeString(staticService.findByAlias(item.title))}</h6>
                    <span>{leg.luggage[item.key]}</span>
                  </div>
                </div>
              ) : (
                ""
              ),
            )}
            {emptyColumn(leg.luggage, luggageItems)}
            {!!leg.luggage.luggage_will_provide_later && (
              <div className="gh-simple-text-request-form gh-checked-icon gh-review-luggage">
                {staticService.findByAlias("willProvideLater")}
              </div>
            )}
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-4@s">
          <div className="gh-review-grid-section uk-padding-remove-left">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("specialItems"),
              }}
            />
            {specialItems.map((item) =>
              leg.special_items[item.key] ? (
                <div key={item.key}>
                  <div className="row">
                    <h6>{capitalizeString(staticService.findByAlias(item.title))}</h6>
                    <span>{leg.special_items[item.key]}</span>
                  </div>
                </div>
              ) : (
                ""
              ),
            )}
            {emptyColumn(leg.special_items, specialItems)}
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-4@s">
          <div
            className="gh-review-grid-section uk-padding-remove-left uk-padding-remove-right"
            style={{ border: 0 }}
          >
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("otherSpecialItems"),
              }}
            />
            <div className="row">
              <h6>{leg.special_items.other ? leg.special_items.other : "---"}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewLegComponent.propTypes = {
  leg: PropTypes.object,
  index: PropTypes.number,
  flightType: PropTypes.string,
  nav: PropTypes.bool,
};

export { ReviewLegComponent };
