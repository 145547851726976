import { applyMiddleware, createStore } from "redux";
import rootReducer from "../reducers/root-reducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const configStore = () => {
  const environment = process.env.NODE_ENV;

  if (environment === "development") {
    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  } else {
    return createStore(rootReducer, applyMiddleware(thunk));
  }
};

export { configStore };
