import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";
import { parseCoordinates } from "../..";
import Marker from "./shapes/Marker";
import Line from "./shapes/Line";
import { getLandingSitesMarkers, primaryIcon } from "../mapUtils";
import { LondonLocation } from "../../../configs";

let bounds;

function FlyDirWrapper(props) {
  const { flyDirCoords, projection, google, map, zoom, setActiveInfo, closeActiveInfo } = props;
  const [prevCoords, setPrevCoords] = useState(null);

  useEffect(() => {
    if (JSON.stringify(flyDirCoords) !== JSON.stringify(prevCoords)) {
      closeActiveInfo();

      bounds = new google.maps.LatLngBounds();

      flyDirCoords.forEach(({ from, to }) => {
        if (from) bounds.extend(parseCoordinates(from));
        if (to) bounds.extend(parseCoordinates(to));
      });

      if (!flyDirCoords || !flyDirCoords.length) {
        map.panTo(LondonLocation);
        map.setZoom(6);
        return;
      }

      if (flyDirCoords.every((pos) => !!pos.to && !!pos.from)) {
        map.fitBounds(bounds, 100);
        return;
      }

      const from = parseCoordinates(flyDirCoords[0].from);
      const to = parseCoordinates(flyDirCoords[0].to);

      map.panTo(from || to || LondonLocation);
      map.setZoom(10);
    }

    setPrevCoords(flyDirCoords);
  }, [flyDirCoords]);

  const mouseOverHandler = useCallback(
    (marker = null, pad = null) =>
      () => {
        if (marker) {
          setActiveInfo({
            visible: true,
            pad,
            marker,
            isSelected: true,
          });
        }
      },
    [setActiveInfo],
  );

  return flyDirCoords.map((marker, idx) => {
    const [info] = getLandingSitesMarkers([marker.pad]);

    if (!marker.to && !marker.from) return;

    return (
      <div key={marker.key || idx}>
        {marker.from && (
          <Marker
            position={parseCoordinates(marker.from) || null}
            map={map}
            google={google}
            info={{
              ...info.from,
              chosenSiteText:
                marker.to === marker.from ? "This is your chosen take-off/landing site" : "",
            }}
            mouseOverHandler={mouseOverHandler}
            icon={primaryIcon(info.from.type)}
          />
        )}
        <Line
          google={google}
          map={map}
          projection={projection}
          range={{ to: marker.to, from: marker.from }}
          zoom={zoom}
        />
        {marker.to && (
          <Marker
            position={parseCoordinates(marker.to) || null}
            map={map}
            google={google}
            info={{
              ...info.to,
              chosenSiteText:
                marker.to === marker.from ? "This is your chosen take-off/landing site" : "",
            }}
            mouseOverHandler={mouseOverHandler}
            icon={primaryIcon(info.to.type)}
          />
        )}
      </div>
    );
  });
}

FlyDirWrapper.propTypes = {
  flyDirCoords: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      pad: PropTypes.object,
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  setActiveInfo: PropTypes.func,
  closeActiveInfo: PropTypes.func,
  projection: PropTypes.object,
  google: PropTypes.object,
  map: PropTypes.object,
  zoom: PropTypes.number,
};

FlyDirWrapper.defaultProps = {
  flyDirCoords: [],
};

export default memo(FlyDirWrapper);
