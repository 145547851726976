import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { InfoWindow } from "google-maps-react";
import { secondaryIcon } from "../../../shared/mapHook/mapUtils";
import mapMarker from "../../../assets/img/svg/mapMarker.svg";
import mapMarkerDisabled from "../../../assets/img/svg/mapMarkerDisabled.svg";
import HomebaseContent from "./HomebaseContent";
import { isPadAndGroupVerified, isPadVerified } from "./AvailableHomebases.utils";
import clsx from "clsx";

export default function AvailableHomebasesMarkers({
  map,
  google,
  pads,
  homebaseInfo,
  setHomebaseInfo,
}) {
  React.useEffect(() => {
    const markers = pads.map((pad, index) => {
      const marker = new google.maps.Marker({
        position: { lat: pad.home_base_location.lat, lng: pad.home_base_location.lng },
        map,
        icon: secondaryIcon(isPadVerified(pad) ? "Heli" : "HeliUnverified"),
        zIndex: isPadVerified(pad) ? 500 + index : 500 - index,
      });

      marker.addListener("click", () => {
        setHomebaseInfo({ pad, marker, visible: true });
      });

      marker.groupLength = pad.group.length + 1;
      marker.isPadAndGroupVerified = isPadAndGroupVerified(pad);
      return marker;
    });

    markers.forEach((marker) => {
      if (marker.groupLength > 1) {
        //if the group is verified, set black color for the marker, instead of gray for unverified,
        // the same is for font color below
        if (marker.isPadAndGroupVerified) {
          marker.setIcon(mapMarker);
        } else {
          marker.setIcon(mapMarkerDisabled);
        }
        marker.setLabel({
          text: marker.groupLength.toString(),
          className: clsx("map-marker-label", {
            "map-marker-label-gray": !marker.isPadAndGroupVerified,
          }),
        });
      }
    });

    return () => {
      markers.forEach((marker) => marker.setMap(null));
    };
  }, [pads]);

  const onInfoWindowOpen = React.useCallback(() => {
    const homebasePadInfo = homebaseInfo.pad;

    if (homebasePadInfo) {
      const MarkerContent = (
        <HomebaseContent
          homebases={[homebasePadInfo, ...homebasePadInfo.group]}
          onInfoClose={() => setHomebaseInfo({ visible: false })}
        />
      );

      ReactDOM.render(React.Children.only(MarkerContent), document.getElementById("homebase-info"));
    }
  }, [homebaseInfo]);

  return (
    <InfoWindow
      marker={homebaseInfo.marker}
      visible={homebaseInfo.visible}
      onOpen={onInfoWindowOpen}
      google={google}
      map={map}
    >
      <div id="homebase-info" />
    </InfoWindow>
  );
}

AvailableHomebasesMarkers.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  pads: PropTypes.array,
  homebaseInfo: PropTypes.object,
  setHomebaseInfo: PropTypes.func,
};
