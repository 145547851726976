import Api from "../services/api-handler";
import handlerError from "./error";
import { DELETE_PIPELINE, GET_PIPELINES } from "./actionTypes";
import { staticText } from "../configs";
import * as types from "./actionTypes";

const entity = "pipeline";

export function getPipelines(page, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity)
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((res) => {
        dispatch({ type: GET_PIPELINES, data: res });
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function deletePipeline(id) {
  return function (dispatch) {
    Api.setProperty("responseMessages", staticText.globalMessages.pipeline).setProperty(
      "skipErrorAlert",
      true,
    );

    return Api.setPath(entity)
      .setProperty("skipErrorAlert", false)
      .delete(id)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
