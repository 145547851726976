import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath, NavLink } from "react-router-dom";
import { BlockComponent } from "../common";
import { config } from "../../configs";
import { redirectTo, getActiveClass, addFailedCssClassToArray, storage } from "../../shared";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../";
import Auth from "../../services/auth";
import staticService from "../../services/static.service";
import { ButtonWithBadgeComponent } from "../pages/user-online/common/button-with-badge.component";

class MarketPlaceLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCss: ["gh-filters-tab"],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filterCss !== nextProps.filterCss) {
      this.setState({
        filterCss: addFailedCssClassToArray(nextProps.filterCss, this.state.filterCss),
      });
    }
  }

  get requestButtonProps() {
    storage.delete("request_form");
    return {
      componentProps: {
        isButton: false,
        path: "/new-request",
        actionTitle: staticService.findByAlias("requestPageQuote"),
        classNames: [
          "uk-button",
          "gh-req-button",
          "uk-align-center",
          "uk-margin-remove-top",
          "uk-width-1-6",
          "uk-padding-remove",
          "uk-margin-small-top",
        ],
        tooltipMessage: staticService.findByAlias("accountUnverified"),
        verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["broker_admin", "broker_team", "sole_trader"],
      component: IsVerifiedAuthorization,
    };
  }

  get marketButtonProps() {
    return {
      componentProps: {
        route: "/marketplace",
        label: staticService.findByAlias("operatorMarketPlaceButton"),
        classes: ["btn-pulse"],
        visible: this.props.showMarketplaceButton,
      },
      roles: ["operator_admin", "operator_team"],
      component: ButtonWithBadgeComponent,
    };
  }

  get buttonProps() {
    return Auth.getMainRole() === "operator" ? this.marketButtonProps : this.requestButtonProps;
  }

  render() {
    const quoteForm = matchPath(window.location.pathname, {
      path: "/new-quote/:id",
      strict: true,
      exact: true,
    });
    if (this.props.isLogged === false) {
      return redirectTo(config.redirectToLogin);
    }

    // const navigationTabs = this.props.navigationTabs.map(({path, title, alias}, index) => {
    //   return (
    //     <li key={index}>
    //       <NavLink to={path}
    //                className={getActiveClass(index, alias, this.props.activeRoute.path.trim())}>
    //         <span dangerouslySetInnerHTML={{__html: staticService.findByAlias(title)}} />
    //       </NavLink>
    //     </li>
    //   )
    // });

    const newQuote = matchPath(window.location.pathname, {
      path: "/new-quote/:id",
      strict: true,
      exact: true,
    });

    const { isFiltersShown = false, requestsObj } = this.props;

    return (
      <section className={"booking-layout"}>
        <BlockComponent visible={true} parentProps={{ className: "uk-margin-medium-top" }}>
          {window.location.pathname === "/marketplace" && (
            <div>
              <h3>{this.props.pageTitle}</h3>
            </div>
          )}
          <div>
            <ComponentAuthorization
              component={this.buttonProps.component}
              componentProps={this.buttonProps.componentProps}
              roles={this.buttonProps.roles}
            />
          </div>
          {!quoteForm ? (
            <div className="uk-margin-small-top">
              <ul className={"disabled-list uk-tab gh-uk-tab"}>
                {/*{navigationTabs}*/}
                {this.props.isFilterVisible ? (
                  <li className={this.state.filterCss.join(" ")}>
                    <a onClick={this.props.onFiltersClick}>
                      {isFiltersShown ? "Close" : "Filters"}{" "}
                      <span className="uk-margin-small-left" data-uk-icon="icon: settings" />
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          ) : (
            ""
          )}
          {this.props.children}
        </BlockComponent>
      </section>
    );
  }
}

MarketPlaceLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  // navigationTabs: PropTypes.array.isRequired,
  activeRoute: PropTypes.object.isRequired,
  isFilterVisible: PropTypes.bool,
  showMarketplaceButton: PropTypes.bool,
  filterCss: PropTypes.string,
};

MarketPlaceLayout.defaultProps = {
  pageTitle: "Market Place",
  // navigationTabs: [],
  isFilterVisible: true,
  showMarketplaceButton: true,
};

const mapStateToProps = ({ errorObj, company }) => {
  return {
    company: company,
    ...errorObj,
  };
};

const component = connect(mapStateToProps)(MarketPlaceLayout);
export { component as MarketPlaceLayout };
