export const prepareNumber = (str = "", { decimalSeparator }) => {
  return str.toString().replace(new RegExp(`[^0-9${decimalSeparator}]`, "g"), "");
};

export const separateThousands = (str = "", { thousandsSeparator }) => {
  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
};

export const validateInputNumber =
  ({ decimalSeparator }) =>
  (value = "") => {
    const preparedValue = prepareNumber(value, { decimalSeparator }).replaceAll(
      decimalSeparator,
      ".",
    );

    return !isNaN(preparedValue);
  };

export const tranformToNumber =
  ({ decimalSeparator }) =>
  (value = "", { defaultValue = false, maximumFractionDigits = 2 }) => {
    const preparedValue = prepareNumber(value, { decimalSeparator }).replaceAll(
      decimalSeparator,
      ".",
    );

    if (isNaN(preparedValue) || !value) return defaultValue;
    return parseFloat(parseFloat(preparedValue).toFixed(maximumFractionDigits));
  };

export const parseNumber =
  ({ decimalSeparator, thousandsSeparator }) =>
  (value, options = { maximumFractionDigits: 2, defaultValue: false }) => {
    if (!value) return options.defaultValue;
    if (isNaN(value)) return value;

    let [left, right] = value.toString().split(".");

    left = separateThousands(left, { thousandsSeparator });

    if (right) right = `${decimalSeparator}${right.substring(0, options.maximumFractionDigits)}`;
    else right = "";

    return `${left}${right}`;
  };

export const parseThousands = (value) => parseFloat(value.replaceAll(/,/g, ""));
