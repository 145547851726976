import React, { useEffect, useState } from "react";
import "./WithdrawComponent.css";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { QUOTE_WITHDRAWN, validationMessages } from "../../../../../configs";
import { withdrawQuote } from "../../../../../actions";
import { spinnerInstance } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import RadioButtonComponent from "../../../../common/RadioButtonComponent";
import ErrorMessageComponent from "../../../../common/ErrorMessageComponent";
import ErrorMessageHookForm from "../../../../common/ErrorMessageHookForm";

const redirectToQuotes = () => {
  spinnerInstance.setProp("size", "large").show();
  window.location.href = "/flights/quotes/filter/all";
};

const WithdrawComponent = (props) => {
  const { reasons, quoteID, closeModal, withdrawQuote } = props;
  if (!reasons) return "";
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();

  const [isOtherReason, setIsOtherReason] = useState(false);

  const onSubmit = (data) => {
    const { other_info, reason } = data;
    const message = isOtherReason ? other_info : reason;
    const request = {
      id: quoteID,
      status: QUOTE_WITHDRAWN,
      reason: message,
    };
    withdrawQuote(request, redirectToQuotes, closeModal);
  };

  useEffect(() => {
    const subscription = watch((value) =>
      value.reason === "Other info" ? setIsOtherReason(true) : setIsOtherReason(false),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="reason"
        rules={{
          required: { value: true, message: staticService.findByAlias("declineReasonIsRequired") },
        }}
        render={({ field: { onChange, value } }) => (
          <RadioButtonComponent
            id={quoteID.toString()}
            selected={value}
            handleChange={onChange}
            items={reasons.map((reason) => ({ label: reason, value: reason }))}
            className="uk-flex-column gh-withdraw-radio-buttons-wrapper"
          />
        )}
      />
      {isOtherReason && (
        <React.Fragment>
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: staticService.findByAlias("declineReasonIsRequired"),
              },
              minLength: {
                value: 4,
                message: validationMessages().minMessage(4)(),
              },
              maxLength: {
                value: 120,
                message: validationMessages().maxMessage(120)(),
              },
            }}
            render={({ field }) => (
              <textarea
                {...field}
                className="uk-textarea uk-margin-small-bottom"
                placeholder="Insert other info"
              />
            )}
            name="other_info"
          />
          <ErrorMessageComponent visibility={!!errors.other_info}>
            {errors.other_info ? errors.other_info.message : ""}
          </ErrorMessageComponent>
        </React.Fragment>
      )}
      <ErrorMessageHookForm errors={errors} notShowKeys={["other_info"]} />

      <div className="uk-width-1-1 uk-text-center">
        <button className="uk-button uk-button-primary" type="submit">
          {staticService.findByAlias("submitDeclineButton")}
        </button>
      </div>
    </form>
  );
};

WithdrawComponent.propTypes = {
  reasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  quoteID: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  withdrawQuote: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ withdrawQuote }, dispatch);
};
export default connect(null, mapDispatchToProps)(WithdrawComponent);
