import PropTypes from "prop-types";
import React from "react";
import csgo from "../../../../utils/condString";

const Textarea = React.forwardRef((props, ref) => {
  const { className, disabled, name, value, placeholder, onChange, pattern, ...rest } = props;

  const textareaRef = React.useRef();

  React.useEffect(() => {
    const textareaAutosizeHandler = (event) => {
      if (event.target.scrollHeight > 400) {
        event.target.style.overflow = "unset";
      }

      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + "px";
    };

    if (textareaRef.current) {
      ref(textareaRef.current);
      textareaRef.current.addEventListener("input", textareaAutosizeHandler);
    }
  }, [textareaRef, ref]);

  const onChangeHanlder = (e) => {
    const value = e.target.value;

    if (pattern) onChange(pattern(value));
    else onChange(e);
  };

  return (
    <textarea
      id={name}
      name={name}
      value={value}
      ref={textareaRef}
      placeholder={placeholder}
      className={csgo(className, "uk-textarea gh-form-textarea")}
      disabled={disabled}
      onChange={onChangeHanlder}
      {...rest}
    />
  );
});

Textarea.displayName = "Textarea";

Textarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.func,
};

Textarea.defaultProps = {
  value: "",
};

export default Textarea;
