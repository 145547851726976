import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { actions } from "react-redux-form";
import { connect } from "react-redux";
// import "core-js/fn/object/assign";
// import "core-js/fn/promise";
import {
  getOperatorRequests,
  toggleRequestFlagStatus,
  getOperatorRequetsSuccess,
  setDefaultRequestsData,
} from "../../../../../../actions";
import { PaginationService } from "../../../../../../services";
import { marketRequestConfig, config } from "../../../../../../configs";
import {
  ComplexTableComponent,
  prepareQueryStringFromObj,
  formatID,
} from "../../../../../../shared";
import GetHeliEvents from "../../../../../../services/event.service";
import staticService from "../../../../../../services/static.service";
import moment from "moment";

const requestPath = "/marketplace/trip/request";

class OperatorMarketPlaceEmptyLegsListComponent extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      operator_requests: this.props.operator_requests,
      filters: [],
      sortOrder: marketRequestConfig.defaultOrder,
    };

    this.catchSuccessResponse = this.catchSuccessResponse.bind(this);
    GetHeliEvents.setEvent("empty-legs", this.catchSuccessResponse);

    this.paginationService = new PaginationService(
      context.store.dispatch,
      getOperatorRequests,
      config.paginationLimit,
      this.state.filters,
    );
    this.paginationService.setProp("columns", marketRequestConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(marketRequestConfig));
    this.paginationService.loadData();
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  componentWillReceiveProps({ operator_requests, filters, ...nextProps }) {
    if (JSON.stringify(this.props.operator_requests) !== JSON.stringify(operator_requests)) {
      if (filters.refreshCollection) {
        GetHeliEvents.trigger("emptylegs-refresh-collection", operator_requests.length);
        this.paginationService.reloadItems();
        this.context.store.dispatch(
          actions.change("operatorMarketplaceRequestsFiltersComponent.refreshCollection", false),
        );
        this.paginationService.setProp("filters", prepareQueryStringFromObj(filters));
      }
      const {
        defaultRequests,
        history: { location },
      } = nextProps;
      if (
        location.state &&
        location.state.anchorId &&
        defaultRequests.data &&
        defaultRequests.data.length
      ) {
        this.paginationService.populateItems(
          defaultRequests.data,
          this.prepareRows,
          this.props.isConcat,
        );
        setTimeout(() => {
          this.setState({ sortOrder: defaultRequests.sort });
        }, 1000);
      } else {
        this.paginationService.populateItems(
          operator_requests,
          this.prepareRows,
          this.props.isConcat,
        );
      }
      this.setState({ operator_requests });
    }
  }

  prepareRows(items) {
    return items.map((item) => {
      item.displayID = formatID(item.id, config.prefixes.request);
      item.on = moment(item.on).format("DD MMMM, YYYY");
      return item;
    });
  }

  onRowClick(request, index) {
    const list = [...this.paginationService.items];
    list[index].highlight = list[index].highlight ? false : list[index].highlight;
    this.props.setDefaultRequestsData({
      data: list,
      sort: this.state.sortOrder,
    });
  }

  tableAction(index) {
    const route = `${requestPath}/${this.paginationService.items[index].id}`;
    const action = (
      <Link
        className={"uk-button uk-button-link"}
        to={{ pathname: route, state: { prevPath: "/marketplace" } }}
        onClick={this.onRowClick.bind(this, this.paginationService.items[index], index)}
      >
        View
      </Link>
    );
    return {
      desktop: <td style={{ verticalAlign: "middle" }}>{action}</td>,
      mobile: action,
    };
  }

  handleSorting(column, order) {
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.paginationService.loadData();
    this.context.store.dispatch(
      actions.change("operatorMarketplaceRequestsFiltersComponent.refreshCollection", true),
    );
  }

  componentWillUnmount() {
    this.props.getOperatorRequetsSuccess({});
  }

  toggleFlaggedStatus(request, items) {
    this.context.store.dispatch(actions.change("isConcat", false));
    this.context.store.dispatch(toggleRequestFlagStatus(request.id, items)).then(() => {
      this.context.store.dispatch(actions.change("isConcat", false));
    });
  }

  render() {
    const { history, setDefaultRequestsData } = this.props;
    const { location } = history;

    if (location.state && location.state.anchorId) {
      setTimeout(() => {
        const element = document.getElementById(location.state.anchorId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          history.replace({ ...history.location, state: undefined });
          setDefaultRequestsData({});
        }
      }, 500);
    }
    return (
      <ComplexTableComponent
        loadMore={this.paginationService.loadMore.bind(this.paginationService)}
        rows={this.paginationService.items}
        headers={this.paginationService.headers}
        columns={this.paginationService.columns}
        showActionColumn={1}
        showLoader={this.paginationService.showLoaderBtn}
        showMoreText={staticService.findByAlias("showMore")}
        actionElements={this.tableAction.bind(this)}
        selectedOrder={this.state.sortOrder}
        sortChange={this.handleSorting.bind(this)}
        firstTimeLoading={this.paginationService.initialLoadState}
      />
    );
  }
}

OperatorMarketPlaceEmptyLegsListComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorMarketPlaceEmptyLegsListComponent.propTypes = {
  history: PropTypes.object,
  setDefaultRequestsData: PropTypes.func,
  getOperatorRequetsSuccess: PropTypes.func,
  operator_requests: PropTypes.func,
  filters: PropTypes.object,
  isConcat: PropTypes.bool,
  defaultRequests: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmptyLegs: getOperatorRequests,
      getOperatorRequetsSuccess,
      setDefaultRequestsData,
    },
    dispatch,
  );
};

const mapStateToProps = ({
  operator_requests,
  operatorMarketplaceRequestsFiltersComponent,
  defaultRequests,
  isConcat,
}) => ({
  operator_requests,
  defaultRequests,
  isConcat: isConcat,
  filters: operatorMarketplaceRequestsFiltersComponent,
});

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorMarketPlaceEmptyLegsListComponent);
export { COMPONENT as OperatorMarketPlaceEmptyLegsListComponent };
