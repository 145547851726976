import React from "react";
import { useCalculatorContext } from "../../CalculatorContext";
import { Description } from "../Description";
import { Accordion } from "../Accordion";
import CalculatorPilotsLabels from "./CalculatorPilotsLabels";
import { Tooltip } from "../tooltip";
import staticService from "../../../../services/static.service";

export function CalculatorLabels() {
  const { activeSections, currency } = useCalculatorContext();

  return (
    <div className="calculator__labels">
      <div className="calculator__labels-aircrafts">
        <Description.Item type="placeholder" />
        <Description>
          <Description.Item as="text">Aircraft</Description.Item>
        </Description>
        <Description>
          <Description.Item as="text">
            {staticService.findByAlias("totalCostOfAircraft")} ({currency})
          </Description.Item>
        </Description>
      </div>
      <div className="calculator__labels-legs">
        <Description>
          <Description.Item as="text">Leg Number</Description.Item>
          <Description.Item as="text">Leg Category</Description.Item>
          <Description.Item as="text">From</Description.Item>
          <Description.Item as="text">To</Description.Item>
        </Description>
        <Accordion
          isOpen={activeSections.includes("flight")}
          className="uk-margin-small-top"
          header={
            <Description>
              <Description.Item as="text" type="header">
                Flight
              </Description.Item>
            </Description>
          }
        >
          <Description>
            <Description.Item as="text">
              Distance (NM)
              <Tooltip
                className="uk-margin-small-left"
                position="top-left"
                content="The distance is calculated using the Haversine formula"
              />
            </Description.Item>
            <Description.Item as="text">Duration of Flight (hrs)</Description.Item>
            {/* <Description.Item as="text">Headwind (Knots)</Description.Item> */}
            <Description.Item as="text">Extra Time (hrs)</Description.Item>
            <Description.Item as="text">
              Total Duration (hrs)
              <Tooltip
                className="uk-margin-small-left"
                position="top-left"
                content="Function of Speed of Aircraft, Distance, Headwind and Duration of Flight"
              />
            </Description.Item>
            <Description.Item as="text">
              Cost/h of Aircraft ({currency})
              <Tooltip
                className="uk-margin-small-left"
                position="top-left"
                content="Your aircraft's default value"
              />
            </Description.Item>
            <Description.Item as="text">Tax Rate (%)</Description.Item>
            <Description.Item as="text" separator>
              Total Cost of Flight ({currency})
            </Description.Item>
          </Description>
        </Accordion>
        <CalculatorPilotsLabels />
        <Accordion
          isOpen={activeSections.includes("fees")}
          className="uk-margin-small-top"
          header={
            <Description>
              <Description.Item as="text" type="header">
                Fees
              </Description.Item>
            </Description>
          }
        >
          <Description>
            <Description.Item as="text">Departure or Handling Fees ({currency})</Description.Item>
            <Description.Item as="text">Tax Rate (%)</Description.Item>
            <Description.Item as="text">Landing Fees ({currency})</Description.Item>
            <Description.Item as="text">Tax Rate (%)</Description.Item>
            <Description.Item as="text" separator>
              Total Fees ({currency})
            </Description.Item>
          </Description>
        </Accordion>
        <Accordion
          isOpen={activeSections.includes("extracosts")}
          className="uk-margin-small-bottom"
          header={
            <Description>
              <Description.Item as="text" type="header">
                Extra Costs
              </Description.Item>
            </Description>
          }
        >
          <Description>
            <Description.Item as="text" className="label-without-border">
              Extra Costs ({currency})
            </Description.Item>
            <Description.Item as="text" />
            <Description.Item as="text">Tax Rate (%)</Description.Item>
            <Description.Item as="text" separator>
              Total Extra Costs ({currency})
            </Description.Item>
          </Description>
        </Accordion>
        <Accordion
          isOpen={activeSections.includes("notes")}
          className="uk-margin-small-bottom"
          header={
            <Description>
              <Description.Item as="text" type="header">
                Operator&apos;s Notes
              </Description.Item>
            </Description>
          }
        >
          <Description className="notes-description">
            <Description.Item as="text" className="notes-description-label">
              Operator&apos;s Notes for Leg
            </Description.Item>
          </Description>
        </Accordion>
        <Description>
          <Description.Item as="text" type="footer" separator>
            Total Cost of Leg ({currency})
          </Description.Item>
        </Description>
      </div>
    </div>
  );
}
