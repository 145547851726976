import React from "react";
import Auth from "../../../../../services/auth";

const useBrokerSubmitRequest = ({
  bookingRequestOperators,
  confirmRequestModal,
  handleAddOperatorsAndSubmit,
}) => {
  const [notRelevantOperators, setNotRelevantOperators] = React.useState([]);
  const [relevantOperators, setRelevantOperators] = React.useState([]);

  const isAdvancedRequestContentPermitted = !!Auth.hasFeature(
    "send_the_request_to_wished_operators",
  );

  const closeModal = () => confirmRequestModal.current.closeModal();

  React.useEffect(() => {
    if (!bookingRequestOperators?.length || !isAdvancedRequestContentPermitted) return;
    const relevantOperators = bookingRequestOperators
      .filter((operator) => operator.is_relevant)
      .map((operator) => ({
        ...operator,
        isChecked: true,
      }));
    const notRelevantOperators = bookingRequestOperators.filter(
      (operator) => !operator.is_relevant,
    );
    setNotRelevantOperators(notRelevantOperators);
    setRelevantOperators(relevantOperators);
  }, [bookingRequestOperators]);

  /**
   * @param {boolean} isChecked - checkbox value
   * @param {object} operator - checkbox operator
   * @param {boolean} [selectAll] - is select all checkbox
   * */
  const onChange = (isChecked, operator, selectAll = false) => {
    //handle select all relevant operators
    if (selectAll) {
      return handleSelectAll(isChecked);
    }
    //handle select single operator
    if (operator.is_relevant) {
      setRelevantOperators((prev) =>
        prev.map((op) => (op.id === operator.id ? { ...op, isChecked } : op)),
      );
    } else {
      setNotRelevantOperators((prev) =>
        prev.map((op) => (op.id === operator.id ? { ...op, isChecked } : op)),
      );
    }
  };

  const handleSelectAll = (isChecked) => {
    setRelevantOperators((prevState) => prevState.map((operator) => ({ ...operator, isChecked })));
    return null;
  };

  const onSubmit = () => {
    const selectedOperators = [...relevantOperators, ...notRelevantOperators].reduce(
      (acc, operator) => {
        if (operator.isChecked) {
          acc.push({ id: operator.id, is_relevant: operator.is_relevant });
        }
        return acc;
      },
      [],
    );

    //add selected operators and submit request
    handleAddOperatorsAndSubmit(selectedOperators);

    closeModal();
  };

  const operators = {
    relevant: relevantOperators,
    notRelevant: notRelevantOperators,
  };

  return {
    operators,
    onSubmit,
    onChange,
    closeModal,
    confirmRequestModal,
    isAdvancedRequestContentPermitted,
  };
};
export default useBrokerSubmitRequest;
