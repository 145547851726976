import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import apiHandler from "../../services/api-handler";
import Calculator from "./Calculator";
import { defaultLegValue, staticDefaultPilotValue } from "./CalculatorContext";
import isNum from "../../utils/isNum";
import staticService from "../../services/static.service";
import Auth from "../../services/auth";
import { useSessionStorage } from "react-use";
import getRandomInt from "../../utils/getRandomInt";

function PriceCalculator(props) {
  const { editingQuote, onFinish, currency, history, submitOnUnmount, demoVersion, banking } =
    props;
  const [availableAircrafts, setAvailableAircrafts] = React.useState([]);
  const [extraAircrafts, setExtraAircrafts] = React.useState([]);
  const [quote, setQuote] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [companyDefaultSettings, setCompanyDefaultSettings] = useSessionStorage(
    "company-quote-defaults",
    null,
  );

  React.useEffect(() => {
    const loadCompanyDefaultSettings = async () => {
      try {
        const response = await apiHandler.setPath("quote", "defaults").getAll();

        setCompanyDefaultSettings(response);
      } catch (error) {
        //
      } finally {
      }
    };

    loadCompanyDefaultSettings();
  }, []);

  const { has_access_to_extra_helicopters } = Auth.getCompanyFromLocalStorage();

  const fillDemoData = (availableAircraftList) => {
    const companyDefaults = JSON.parse(window.sessionStorage.getItem("company-quote-defaults"));
    const companyVat = companyDefaults?.company_vat || banking?.vat_value || "";

    const aircraftLegs = [];
    const selectedAircraft = [];

    const leg = (index = 0) => {
      const pilot = {
        ...staticDefaultPilotValue,
        is_included: companyDefaults.pilot.is_pilot_included,
        payment_type: companyDefaults.pilot.pilot_rate_period,
        payment_amount: companyDefaults.pilot.pilot_rate_amount,
        pilot_shift_duration: companyDefaults.pilot.pilot_duty,
        payment_tax: {
          sum: "",
          percent: companyDefaults.pilot.pilot_tax || companyDefaults.company_vat || "",
        },
        total_pilot_costs: "0",
      };

      return {
        ...defaultLegValue,
        id: getRandomInt(1000, 9999),
        category: index === 1 ? 1 : 2,
        home_base_repositioning: index === 0 ? "from" : index === 2 ? "to" : null,
        crew_size: 1,
        pilots: [pilot],
        from: index === 0 ? availableAircrafts[0].home_base_location : {},
        to: index === 2 ? availableAircrafts[0].home_base_location : {},
        cruise_speed: availableAircrafts[0].cruise_speed,
        price_per_hour: availableAircrafts[0].price_per_hour || "",
        flight_tax: {
          sum: "",
          percent: parsePercentValue(
            {
              flight_tax: {
                sum: "",
                percent: availableAircrafts[0].aircraft_vat,
              },
            },
            companyVat,
          ),
        },
        departure_fee_tax: {
          sum: "",
          percent: companyVat,
        },
        landing_fee_tax: {
          sum: "",
          percent: companyVat,
        },
        extra_cost_tax: {
          sum: "",
          percent: companyVat,
        },
        extra_time: null,
        total_cost_flight: null,
      };
    };

    Array.from(Array(3)).forEach((value, index) => {
      aircraftLegs.push(leg(index));
    });

    selectedAircraft.push({ ...availableAircraftList[0], legs: aircraftLegs });

    let defaultOperatorMargin =
      companyDefaults?.company_margin || availableAircraftList[0]?.default_margin;

    setQuote({
      aircrafts: selectedAircraft.map((aircraft) => ({ ...aircraft, name: aircraft.id })),
      amount: 2,
      booking_request_id: 8548,
      calculation_id: 552,
      created_at: "2022-08-15T08:02:47.000000Z",
      currency: "GBP",
      empty_leg_id: null,
      id: 552,
      operator_margin: { sum: "", percent: defaultOperatorMargin || "" },
      quote_id: 8312,
      quote_price: 2,
      quote_price_total: 2,
      updated_at: "2022-08-15T08:02:51.000000Z",
    });
  };

  const generateQuote = (res) => {
    const companyVat = banking?.vat_value || "";

    setQuote({
      ...res,
      aircrafts: res.aircrafts.map((aircraft) => ({
        ...aircraft,
        name: aircraft.aircraft_id,
        legs: aircraft.legs.map((leg) => {
          return {
            ...leg,
            flight_tax: {
              sum: "",
              percent: parsePercentValue(leg, companyVat),
            },
            departure_fee_tax: {
              sum: "",
              percent: companyVat,
            },
            landing_fee_tax: {
              sum: "",
              percent: companyVat,
            },
            extra_cost_tax: {
              sum: "",
              percent: companyVat,
            },
          };
        }),
      })),
    });
  };

  React.useEffect(() => {
    if (demoVersion && availableAircrafts.length) {
      fillDemoData(availableAircrafts);
    }
  }, [demoVersion, availableAircrafts]);

  React.useEffect(() => {
    if (demoVersion) return;

    apiHandler
      .setPath("quote/switch-to-price-calculator-tab", "")
      .get(editingQuote.quote.calculation_id)
      .then((res) => generateQuote(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoVersion]);

  React.useEffect(() => {
    if (editingQuote && !editingQuote.quote.is_calculator_enabled) {
      history.goBack();
    }
  }, [editingQuote]);

  React.useEffect(() => {
    const loadMyAircraftList = async () => {
      const calculationId = editingQuote?.quote?.calculation_id;

      try {
        setLoading(true);

        if (has_access_to_extra_helicopters) {
          const extraAircraftList = await apiHandler
            .setPath("price-calculator", `aircraft/list/1?id=${calculationId || ""}`)
            .getAll();

          setExtraAircrafts(extraAircraftList);
        }

        const aircraftList = await apiHandler
          .setPath("price-calculator", `aircraft/list?id=${calculationId || ""}`)
          .getAll();

        setAvailableAircrafts(aircraftList);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadMyAircraftList();
  }, []);

  React.useEffect(() => {
    if (error) {
      if (history.action !== "POP") history.goBack();
      else history.push(`/marketplace/trip/quote/${editingQuote.id}`);
    }
  }, [error, history, editingQuote]);

  if (error) {
    return <div className="calculator-loading">Something went wrong!</div>;
  }

  if (loading || (!quote && !demoVersion) || (demoVersion && !companyDefaultSettings)) {
    return (
      <div className="calculator-loading">
        <div uk-spinner="true" className="uk-margin-small-bottom"></div>
        <span>Processing data...</span>
      </div>
    );
  }

  if (!quote && demoVersion) {
    return (
      <div className="calculator-loading">
        <span>{staticService.findByAlias("demoPriceCalculatorNoAircraftMessage")}</span>
      </div>
    );
  }

  return (
    <Calculator
      quote={quote}
      userAircrafts={availableAircrafts}
      extraAircrafts={extraAircrafts}
      onFinish={onFinish}
      currency={currency}
      submitOnUnmount={submitOnUnmount}
      demoVersion={demoVersion}
      banking={banking}
    />
  );
}

PriceCalculator.propTypes = {
  editingQuote: PropTypes.object,
  onFinish: PropTypes.func,
  currency: PropTypes.string,
  history: PropTypes.object,
  submitOnUnmount: PropTypes.bool,
  demoVersion: PropTypes.bool,
  banking: PropTypes.object,
};

PriceCalculator.defaultProps = {
  editingQuote: null,
  onFinish: () => null,
  currency: "GBP",
  submitOnUnmount: false,
};

export default withRouter(PriceCalculator);

// ----------- COMPONENT UTILS----------------

export const parsePercentValue = (leg, countryVat) => {
  let flightPercent = leg.flight_tax.percent;
  let aircraftVat = leg.aircraft_vat;

  if (isNum(flightPercent)) {
    return flightPercent;
  }

  if (isNum(aircraftVat)) {
    return aircraftVat;
  }

  if (isNum(countryVat)) {
    return countryVat;
  }

  return "";
};
