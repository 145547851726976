import * as types from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import GetHeliEvents from "../services/event.service";
import { alertInstance, spinnerInstance } from "../shared";
import { staticText } from "../configs";
import tranformDateTimeToISO from "../utils/tranformDateTimeToISO";
import { showNotification } from "../utils/showNotification";

const entity = "booking-requests";

export function addRequestSuccess(request) {
  return { type: types.ADD_REQUEST_SUCCESS, request };
}

export function updateRequestSuccess(request) {
  return { type: types.REQUEST_UPDATED, request };
}

export function getBrokerRequetsSuccess(requests) {
  return { type: types.GET_BROKER_REQUESTS_LIST, requests };
}

export function removeBrokerRequest(requestId) {
  return { type: types.REMOVE_BROKER_REQUEST, requestId };
}

export function updateBrokerRequest(request) {
  return { type: types.UPDATE_BROKER_REQUEST, request };
}

export function getRequestsSuccess(requests) {
  return { type: types.GET_REQUESTS_LIST, requests };
}

export function getRequestDeclineReasons(reasons) {
  return { type: types.GET_DECLINE_REASONS, reasons };
}

export function sendRequestDeclineReasons(reasons) {
  return { type: types.SEND_DECLINE_REASONS, reasons };
}

export function loadMore(requests) {
  return { type: types.LOAD_MORE_REQUEST, requests };
}

export function saveDefaultSearch(data) {
  return { type: types.SAVE_DEFAULT_SEARCH, data };
}

export function saveResetRequest(requests) {
  return { type: types.SAVE_RESET_REQUESTS_LIST, requests };
}
export function getRemoveFilters(data) {
  return { type: types.REMOVE_FILTERS, data };
}

export function changeSort(data) {
  return { type: types.CHANGE_SORT, data };
}

export function resetSettingsSearch(data) {
  return { type: types.RESET_DEFAULT_SEARCH, data };
}

export function getOperatorRequetsSuccess(requests) {
  return { type: types.GET_OPERATOR_REQUESTS_LIST, requests };
}

export function sendCoordinates(requests) {
  return { type: types.SEND_COORDINATES, requests };
}

export function editRequest(request) {
  return { type: types.EDIT_REQUEST, request };
}

export function getBrokerRequestDetailSuccess(request) {
  return { type: types.GET_BROKER_REQUEST_DETAIL, request };
}

export function getOperatorNewRequetsSuccess(response) {
  return {
    type: types.LOAD_UNREAD_REQUESTS_NOTIFICATION,
    total: response.total,
  };
}

export function updateReducer(action, response) {
  return { type: action, response };
}

export function updateEditRequest() {
  return function (dispatch) {
    dispatch(editRequest({}));
  };
}

export function toggleRequestFlagStatusSuccess(requestId, items, obj) {
  return {
    type: types.TOGGLE_REQUEST_FLAG_STATUS,
    response: { id: requestId, requests: items, updatedObj: obj },
  };
}

export function saveRequest(data, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "legModels").setProperty("skipErrorAlert", false);

    return Api.setPath(entity, null)
      .post(data)
      .then((data) => {
        dispatch(addRequestSuccess(data));
        if (onSuccess) onSuccess(data);
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => {
        alertInstance.eventInstance.trigger("global", {
          visible: true,
          message: err.data.message,
          type: alertInstance.errorProp,
        });
        handlerError(err, dispatch);
      });
  };
}

export function createRequestFromEmptyLeg(payload, onSuccess) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setPath(entity, null)
      .post(payload)
      .then((data) => {
        if (onSuccess) onSuccess(data);
        dispatch(addRequestSuccess(data));
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function saveSearchDefault(data, page, filters = [], onSuccess) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setPath(entity, "marketplace-filter")
      .post(data)
      .then((data) => {
        Api.setProperty("skipErrorAlert", false);
        dispatch(saveDefaultSearch(data));
        return Api.setPath(entity)
          .setQueryParams([{ key: "page", value: page }].concat(filters))
          .getAll()
          .then((requests) => {
            dispatch(saveResetRequest(requests));
            GetHeliEvents.trigger("requests");
            onSuccess(data);
          })
          .catch((err) => handlerError(err, dispatch));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function resetSearchDefault(data, page, filters = [], onSuccess) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setPath(entity, "marketplace-filter")
      .post(data)
      .then((data) => {
        Api.setProperty("skipErrorAlert", false);
        dispatch(resetSettingsSearch(data));
        return Api.setPath(entity)
          .setQueryParams([{ key: "page", value: page }].concat(filters))
          .getAll()
          .then((requests) => {
            dispatch(saveResetRequest(requests));
            GetHeliEvents.trigger("requests");
            onSuccess(data);
          })
          .catch((err) => handlerError(err, dispatch));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateRequest(data, onSuccess = null) {
  return function (dispatch) {
    return Api.setPath(entity, null)
      .setProperty("responseMessages", staticText.globalMessages.request)
      .setProperty("messagePlaceholders", { entityName: "Request" })
      .setProperty("skipErrorAlert", true)
      .put(data.id, data)
      .then((data) => {
        dispatch(updateRequestSuccess(data));
        if (onSuccess !== null) onSuccess(data);
        Api.setProperty("skipErrorAlert", false);
        Api.triggerAlert(Api.serverResponse);
      })
      .catch((err) => {
        if (err.status === 423 && err.data.error.refresh) {
          Api.setProperty("alertModal", true);
          Api.triggerError(err);
          Api.setProperty("alertModal", false);
          dispatch(getBrokerRequestDetail(data.id));
        } else {
          Api.triggerError(err);
        }
        return handlerError(err, dispatch);
      });
  };
}

export function assignRequest(data, onSuccess = null) {
  return function (dispatch) {
    return Api.setPath(entity, null)
      .setProperty("skipErrorAlert", true)
      .put(data.id, data)
      .then((data) => {
        dispatch(updateRequestSuccess(data));
        showNotification("Request assigned with success", "success");
        if (onSuccess !== null) onSuccess(data);
      })
      .catch((err) => {
        if (err.status === 423 && err.data.error.refresh) {
          Api.setProperty("alertModal", true);
          Api.triggerError(err);
          Api.setProperty("alertModal", false);
          dispatch(getBrokerRequestDetail(data.id));
        } else {
          Api.triggerError(err);
        }
        return handlerError(err, dispatch);
      });
  };
}

export function requestSwitchBroker(id, data) {
  return function (dispatch) {
    return Api.setPath("booking-switch-broker", id)
      .setProperty("skipErrorAlert", true)
      .post(data)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function updateIdentifier(data, onSuccess = null) {
  return function (dispatch) {
    return Api.setPath(entity, "update/identifier")
      .setProperty("responseMessages", staticText.globalMessages.request.identifier)
      .post(data)
      .then((data) => {
        dispatch(updateReducer(types.UPDATE_IDENTIFIER, data));
        if (onSuccess !== null) onSuccess(data);
        return data;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getBrokerRequets(page, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity, "my-requests")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((requests) => {
        dispatch(getBrokerRequetsSuccess(requests));
        GetHeliEvents.trigger("requests");
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getBrokerRequetsByFilter(page, filters = []) {
  return function () {
    return Api.setPath(entity, "my-requests")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getRequests(page, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity)
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((requests) => {
        dispatch(getRequestsSuccess(requests));
        Api.setQueryParams([]);
        GetHeliEvents.trigger("requests");
        return Promise.resolve(requests);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getDeclineReasons(ent) {
  return function (dispatch) {
    return Api.setPath(entity, "decline-reasons/" + ent)
      .getAll()
      .then((reasons) => {
        Api.setProperty("skipErrorAlert", false);
        dispatch(getRequestDeclineReasons(reasons));
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch, onError));
  };
}

export function sendDeclineReasons(requestId, reason) {
  return function (dispatch) {
    Api.setProperty("showSpinner", false);
    Api.setProperty("skipErrorAlert", true);
    return Api.setPath(entity, "decline/" + requestId)
      .post(reason)
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch, onError));
  };
}

export function getLoadMore(page, filters = [], enableConcat) {
  enableConcat && enableConcat();
  return function (dispatch) {
    return Api.setPath(entity)
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((requests) => {
        dispatch(loadMore(requests));
        GetHeliEvents.trigger("requests");
        return requests;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getRequestsByFilter(page, filters = []) {
  return function () {
    return Api.setPath(entity)
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getOperatorRequests(page, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity, "own")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((requests) => {
        GetHeliEvents.trigger("empty-legs");
        dispatch(getOperatorRequetsSuccess(requests));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getOperatorRequestsByFilter(page, filters = []) {
  return function () {
    return Api.setPath(entity, "own")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getBrokerRequestDetail(id, onError, onSuccess, loading = true) {
  return function (dispatch) {
    if (loading) spinnerInstance.setProp("type", "large").show();
    return Api.setPath(entity, "")
      .setProperty("skipErrorAlert", true)
      .setProperty("showSpinner", false)
      .setQueryParams()
      .get(id)
      .then((request) => {
        Api.setProperty("skipErrorAlert", false);
        dispatch(getBrokerRequestDetailSuccess(request));
        if (onSuccess) onSuccess(request);
        spinnerInstance.setProp("type", "large").hide();
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch, onError));
  };
}

export function getOperatorRequestDetail(id) {
  return function (dispatch) {
    return Api.setPath(entity, "quote")
      .setQueryParams()
      .get(id)
      .then((requests) => {
        dispatch(getBrokerRequestDetailSuccess(requests));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function downloadAgreement(id) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath(entity, "download")
      .download(id)
      .then((r) => {
        return r.blob();
      })
      .then((blobFile) => {
        spinnerInstance.setProp("type", "large").hide();

        const downloadLink = document.createElement("a");
        const url = URL.createObjectURL(blobFile);

        downloadLink.href = url;
        downloadLink.download = `flight_agreement_${id}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        URL.revokeObjectURL(blobFile);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getOperatorNewRequests() {
  return function (dispatch) {
    return Api.setPath(entity, "notification")
      .getAll()
      .then((response) => {
        dispatch(getOperatorNewRequetsSuccess(response));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function markAsViewed() {
  return function (dispatch) {
    return Api.setPath(entity, "notification/detach")
      .setProperty("skipErrorAlert", true)
      .setProperty("showSpinner", false)
      .post()
      .then((response) => {
        Api.setProperty("showSpinner", true);
        Api.setProperty("skipErrorAlert", false);
        dispatch(getOperatorNewRequetsSuccess(response));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function locations(location, onSuccess) {
  return function (dispatch) {
    return Api.setPath(entity, "locations")
      .setProperty("showSpinner", false)
      .setQueryParams([{ key: "location", value: location }])
      .getAll()
      .then((locations) => {
        onSuccess(locations);
        Api.setProperty("showSpinner", true);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function eventLocations(event, onSuccess, seeAll = false) {
  return function (dispatch) {
    return Api.setPath("public_events", "search")
      .setProperty("showSpinner", false)
      .setQueryParams([
        { key: "keyword", value: event },
        { key: "see_all", value: seeAll },
      ])
      .getAll()
      .then((event) => {
        onSuccess(event);
        Api.setProperty("showSpinner", true);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getLocations(location, onSuccess, homeBase) {
  return function (dispatch) {
    return Api.setPath("get-locations")
      .setProperty("showSpinner", false)
      .setQueryParams(
        homeBase
          ? [
              { key: "keyword", value: location },
              { key: "place", value: "home_base" },
            ]
          : [{ key: "keyword", value: location }],
      )
      .getAll()
      .then((location) => {
        onSuccess(location);
        Api.setProperty("showSpinner", true);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getPurposes() {
  return function (dispatch) {
    return Api.setPath(entity, "flight-purposes")
      .setProperty("showSpinner", false)
      .getAll()
      .then((purposes) => {
        Api.setProperty("showSpinner", true);
        return Promise.resolve(purposes);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function sendOnReviewRequest(data, onSuccess, modelName) {
  const values = { ...data };

  values.legs = values.legs.map((leg) => {
    const datetimeISO = tranformDateTimeToISO(leg.date, leg.departure_time);

    return { ...leg, date: datetimeISO, departure_time: datetimeISO };
  });

  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, modelName)
      .setProperty("responseMessages", staticText.globalMessages.request)
      .setProperty("skipErrorAlert", true);

    return Api.setPath(entity, "validate")
      .post(values)
      .then((request) => {
        if (onSuccess) onSuccess(request);
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => {
        if (err.data.message) {
          alertInstance.eventInstance.trigger("global", {
            visible: true,
            message: err.data.message,
            type: alertInstance.errorProp,
          });
        }
        handlerError(err, dispatch);
        return Promise.reject();
      });
  };
}

export function toggleRequestFlagStatus(requestId, data) {
  return function (dispatch) {
    return Api.setPath(entity, `toggle-flag/` + requestId)
      .setProperty("skipErrorAlert", true)
      .setProperty("showSpinner", true)
      .setQueryParams()
      .post(data)
      .then((res) => {
        // dispatch(toggleRequestFlagStatusSuccess(requestId, items, res));
        Api.setProperty("showSpinner", false);
      })
      .catch((err) => {
        Api.setProperty("showSpinner", false);

        return handlerError(err, dispatch);
      });
  };
}

export function getProgressInformation(id) {
  return function (dispatch) {
    return Api.setPath(entity, `progress-information`)
      .setProperty("showSpinner", false)
      .setQueryParams()
      .get(id)
      .then((response) => {
        dispatch({ type: types.ADD_PROGRESS_INFO, response });

        Api.setProperty("showSpinner", true);
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getReasonsToExpired() {
  return function (dispatch) {
    return Api.setPath(entity, `expire-reasons/booking_request`)
      .setProperty("showSpinner", false)
      .setQueryParams()
      .getAll()
      .then((res) => {
        return res;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function makeRequestExpired(data) {
  return function (dispatch) {
    return Api.setPath(entity, `expire/${data.booking_request_id}`)
      .setProperty("showSpinner", false)
      .setProperty("skipErrorAlert", true)
      .setQueryParams()
      .post({ reason: data.reason })
      .then((res) => {
        return res;
      })
      .catch((err) => err);
  };
}

export function setDefaultRequestsData(requests) {
  return { type: types.SET_DEFAULT_REQUESTS_DATA, requests };
}
