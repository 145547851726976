import React from "react";
import PropTypes from "prop-types";
import flagIcon from "../../../assets/img/svg/flag.svg";

const iconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 26,
  height: 26,
  backgroundColor: "#F2F4F7",
  borderRadius: "50%",
};

const FlagIcon = ({ className, additionalStyle }) => {
  return (
    <span style={{ ...iconStyle, ...additionalStyle }} className={className}>
      <img src={flagIcon} alt="icon" />
    </span>
  );
};

FlagIcon.propTypes = {
  className: PropTypes.string,
  additionalStyle: PropTypes.object,
};

export default FlagIcon;
