import React from "react";
import { Form, Control } from "react-redux-form";
import { updateModel } from "../../../../../shared";
import { config } from "../../../../../configs/";
import staticService from "../../../../../services/static.service";
import auth from "../../../../../services/auth";

export const NotificationsFiltersBar = (props) => {
  if (props.visible) {
    return (
      <Form onSubmit={props.submit} model={props.modelName}>
        <div className="uk-card uk-card-default uk-card-body">
          <div data-uk-grid>
            <div className="uk-width-1-1@s uk-width-expand@m">
              <div data-uk-grid>
                <div className="uk-grid-item-match uk-flex-middle">
                  <Control.select
                    model=".by"
                    defaultValue={undefined}
                    className={"uk-select"}
                    changeAction={updateModel}
                    controlProps={{
                      placeholder: staticService.findByAlias("RelatingtoBooking"),
                    }}
                    style={{ minWidth: 220 }}
                    updateOn="change"
                  >
                    <option value="">Select ...</option>
                    {auth.getMainRole() === "broker"
                      ? config.notificationFiltersBroker.map((type, i) => (
                          <option key={i} value={type.id}>
                            {type.name}
                          </option>
                        ))
                      : config.notificationFiltersOperator.map((type, i) => (
                          <option key={i} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                  </Control.select>
                </div>
                <div className="uk-grid-item-match uk-flex-middle">
                  <label>
                    <Control.checkbox
                      className={"uk-checkbox uk-margin-small-right"}
                      model={".isAttentionRequired"}
                      changeAction={updateModel}
                      updateOn="change"
                    />{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("RequiringMyAttention"),
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1@s uk-width-auto@m">
              <div className={"uk-text-center uk-text-right@m"}>
                <Control.button
                  model={props.modelName}
                  disabled={{ valid: false }}
                  className={"uk-button uk-button-primary"}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("showFilterResults"),
                    }}
                  />
                </Control.button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
  return null;
};
