import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Control, Errors } from "react-redux-form";
import { Link } from "react-router-dom";
import {
  brokerRequestsFilters,
  config,
  EXPIRED,
  OPEN,
  PENDING,
  REQUEST_WITHDRAWN,
  requestListConfig,
  validationMessages,
  validationRules,
  WITHDRAWN,
} from "../../../../../configs";
import { PaginationService } from "../../../../../services";
import {
  DELETE_PROGRESS_INFO,
  editRequest,
  getBrokerRequestDetail,
  getBrokerRequestDetailSuccess,
  getBrokerRequets,
  getBrokerRequetsSuccess,
  getProgressInformation,
  getReasonsToExpired,
  makeRequestExpired,
  removeBrokerRequest,
  SET_FILTERS,
  updateBrokerRequest,
  updateRequest,
} from "../../../../../actions";
import {
  ComplexTableComponent,
  CustomValidateError,
  dateFormat,
  formatID,
  ModalComponent,
  prepareQueryStringFromObj,
  redirectTo,
  setActiveItem,
  spinnerInstance,
  updateModel,
} from "../../../../../shared/";
import { BrokerRequestFiltersComponent } from "../../filters/";
import GetHeliEvents from "../../../../../services/event.service";
import { LegRequests } from "../../common/";
import { ConfirmComponent } from "../../../../common";
import staticService from "../../../../../services/static.service";
import { BrokerReviewRequestComponent } from "../broker-review-request.component";
import ProgressInfoModalContent from "../../../../common/ProgressInfoModalContent";
import ChartBtn from "../../../../common/ChartBtn";
import auth from "../../../../../services/auth";
import Button from "../../../../common/Button";
import { showNotification } from "../../../../../utils/showNotification";
import returnBrokerBookingRequestListTableActionComponent from "./utils/returnBrokerBookingRequestListTableActionComponent";
import clsx from "clsx";
import Tooltip from "../../../../common/Tooltip";
import ReasonComponent from "../../../../common/ReasonComponent";

const { max, required, min } = validationRules;

export const exceptionStatusesProgressInfoRequests = (status) =>
  status === "Withdrawn" || status === "Closed" || status === "Expired";

class BrokerBookingRequestListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisibility: false,
      selectedLegs: [],
      filters: props.parentMatch.params.search
        ? [{ key: "filterBy", value: props.parentMatch.params.search }]
        : [],
      sortOrder: this.sortOrder,
      showFilterIcon: true,
      filterFailed: "",
      isSorted: false,
      request: {},
      filterActive: false,
      editable: false,
      staticContentsAliasesForGrid: {
        default: "NoRequests",
        all: "NoRequests",
        pending: "NoPendingBookings",
        review: "NoQuotes",
        withdrawn: "NoWithdrawnRequests",
        expired: "NoExpiredRequests",
        open: "NoOpenRequests",
        urgent: "NoCurrentRequests",
      },
      dialogProps: this.defaultDialogProps(),
      openedProgressInfoModalItem: null,
      expired_reasons: [],
      request_to_expired: null,
      loading_expired_btn: false,
      isLoading: false,
    };

    this.childRef = React.createRef();

    this.currentFilterBy = props.parentMatch?.params?.search;
    this.advancedQuotingProgressBar = auth.hasFeature("advanced_quoting_progress_bar");
    this.legTemplate = this.legTemplate.bind(this);
    this.flyingOnTemplate = this.flyingOnTemplate.bind(this);
    this.openProgressInfoModal = this.openProgressInfoModal.bind(this);
    this.setFailed = this.setFailed.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.loadDataOnLoad = this.loadDataOnLoad.bind(this);
    this.defaultDialogProps = this.defaultDialogProps.bind(this);
    this.withdrawDialogProps = this.withdrawDialogProps.bind(this);
    this.triggerAction = this.triggerAction.bind(this);
    this.updateCurrentActiveRequest = this.updateCurrentActiveRequest.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.getReasons = this.getReasons.bind(this);
    this.sendExpiredReason = this.sendExpiredReason.bind(this);
    this._mounted = true;
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState && this._mounted) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  loadDataOnLoad(status) {
    if (!status) return;

    this.setState({ isLoading: true });
    this.context.store.dispatch(actions.change("isConcat", false));
    GetHeliEvents.setEvent("requests", this.catchSuccessResponse.bind(this));
    setActiveItem(brokerRequestsFilters, "");
    const statusToApply = () => {
      if (status) {
        return [
          { key: "filterBy", value: status },
          { key: "activity", value: auth.getRole() === "broker_team" ? "own" : "all" },
        ];
      }
      if (this.props.parentMatch.params.search) {
        return [
          { key: "filterBy", value: this.props.parentMatch.params.search },
          { key: "activity", value: auth.getRole() === "broker_team" ? "own" : "all" },
        ];
      }
      return [];
    };
    this.paginationService = new PaginationService(
      this.context.store.dispatch,
      getBrokerRequets,
      config.paginationLimit,
      statusToApply(),
    );
    this.paginationService.setProp("columns", requestListConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(requestListConfig));
    this.paginationService.loadData().finally(() => {
      this.setState({ isLoading: false });
    });
  }

  showNotification() {
    showNotification(staticService.findByAlias("restrictAccessForBroker"), "warning");
  }

  getReasons() {
    this.props.getReasonsToExpired().then((res) => {
      const newValue = res.map((reason) => {
        const newReason = staticService.findByAlias(reason);
        const newValue = staticService.findByAlias("bookingMadeWthAnotherOperator");

        return {
          label: newReason,
          value: reason === "expirationReasonBookingRequestMade" ? newValue : newReason,
        };
      });
      this.setState({ expired_reasons: newValue });
    });
  }

  async sendExpiredReason(reason) {
    const { request_to_expired } = this.state;
    if (!request_to_expired) return;

    this.setState({ loading_expired_btn: true });

    this.paginationService.reloadItems();

    try {
      await this.props
        .makeRequestExpired({ reason: reason, booking_request_id: request_to_expired.id })
        .then((res) => {
          showNotification(res.message, "success");
          this.expiredRequestModal.hide();
          if (this.childRef.current?.resetForm) {
            this.childRef.current.resetForm();
          }
          this.setState({ loading_expired_btn: false });

          return res;
        });

      await this.paginationService.loadData();

      this.setState({ loading_expired_btn: false });
    } catch (error) {
      this.setState({ loading_expired_btn: false });
    }
  }

  prepareRows(items) {
    const handleClick = (isAllowToEdit, item) => {
      if (isAllowToEdit) {
        this.props.history.push(`${this.props.parentMatch.url}/${item.id}`);
      } else {
        this.showNotification();
      }
    };

    return items.map((item) => {
      const isAllowToEdit = item.ability_to_edit;

      item.displayID = (
        <span
          className="gh-table-id"
          onClick={isAllowToEdit ? this.openRequestModal.bind(this, item) : this.showNotification}
        >
          {formatID(item.id, config.prefixes.request)}
        </span>
      );
      item.brokerName = (
        <div>
          {item.authore_of_request.first_name} {item.authore_of_request.last_name}
        </div>
      );
      item.totalLegs = this.legTemplate(item.legs);

      item.on = this.flyingOnTemplate(item);

      item.totalQuotes = (
        <div className="gh-quote-num-mob">
          <Link className="gh-leg-link-customs" to={`${this.props.parentMatch.url}/${item.id}`}>
            {item.quotes}
            <span className="gh-leg-link-customs-text">View</span>
          </Link>
        </div>
      );
      item.identifier = <div>{item.identifier || "---"}</div>;
      item.totalLegs = this.legTemplate(item.legs, isAllowToEdit);
      item.totalQuotes = (
        <div className="gh-quote-num-mob">
          <div
            className="gh-leg-link-customs btn-style"
            onClick={() => handleClick(isAllowToEdit, item)}
          >
            {item.quotes}
            <span className="gh-leg-link-customs-text">View</span>
          </div>
        </div>
      );

      item.progress = (
        <div className="gh-wrapper-progress">
          <div className="gh-wrapper-progress-grafic-icon">
            <div>
              <ChartBtn
                onClick={() =>
                  isAllowToEdit ? this.openProgressInfoModal(item) : this.showNotification()
                }
                classes={["btn-pulse"]}
                animated={!item.is_progress_seen}
                duration={10}
              />
            </div>
          </div>
        </div>
      );

      return item;
    });
  }

  legTemplate(legs) {
    return (
      <a className="gh-leg-link-customs" onClick={this.openModal.bind(this, legs)}>
        {legs.length}
        <span className="gh-leg-link-customs-text">View</span>
      </a>
    );
  }

  flyingOnTemplate(item) {
    const needToExpire =
      item.has_to_expire && (item.statusFromApi === OPEN || item.statusFromApi === PENDING);
    return (
      <Tooltip
        pos="top-center"
        show={needToExpire}
        trigger={
          <div
            className={clsx("gh-mark-as-expired-default", {
              "uk-text-danger gh-mark-as-expired btn-style": needToExpire,
            })}
            onClick={() => {
              if (!needToExpire) return;
              this.setState({ request_to_expired: item }, () => {
                this.expiredRequestModal.open();
              });
            }}
          >
            {dateFormat(item.at, "", "D MMM, YYYY")}

            {needToExpire ? (
              <span className="gh-leg-link-customs-text">
                {staticService.findByAlias("markAsExpiredBtn")}
              </span>
            ) : (
              ""
            )}
          </div>
        }
      >
        {staticService.findByAlias("tooltipMakeExpiredThisRequest")}
      </Tooltip>
    );
  }

  openRequestModal(request) {
    this.setState({ request: request });
    this.fullRequest.open();

    const onSuccess = (data) => {
      this.setState({ request: { ...data, request } });
    };

    const onError = () => {
      spinnerInstance.setProp("type", "large").hide();
    };

    this.context.store.dispatch(getBrokerRequestDetail(request.id, onError, onSuccess, false));
  }

  closeRequestModal() {
    this.fullRequest.hide();
    setTimeout(() => {
      this.setState({ request: {} });
    }, 200);
  }

  openModal(legs) {
    this.modal.open();
    this.setState({ selectedLegs: legs });
  }

  closeModal() {
    this.modal.hide();
    this.setState({ selectedLegs: [], request_to_expired: null });

    if (this.childRef.current?.resetForm) {
      this.childRef.current.resetForm();
    }
  }

  openProgressInfoModal(item) {
    const id = item.id;

    this.setState({ openedProgressInfoModalItem: item });
    this.progressInfoModal.open();
    this.props.getProgressInformation(id).then(() => {
      const updateItems = this.paginationService.items.map((item) =>
        item.id === id ? { ...item, is_progress_seen: true } : item,
      );
      this.paginationService.populateItems(updateItems, this.prepareRows.bind(this), false);
      this.forceUpdate();
    });
  }

  closeProgressInfoModal() {
    this.context.store.dispatch({
      type: DELETE_PROGRESS_INFO,
    });
    this.progressInfoModal.hide();
    if (!this.advancedQuotingProgressBar) this.setState({ openedProgressInfoModalItem: null });
  }

  setFailed(items) {
    if (items.length === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  componentDidMount() {
    const { parentMatch } = this.props;
    const { filters } = this.state;

    const searchParam = parentMatch?.params?.search;

    this.getReasons();
    this.context.store.dispatch({
      type: SET_FILTERS,
      filters: filters,
    });

    if (searchParam) {
      this.loadDataOnLoad(searchParam);
      this.context.store.dispatch(actions.change("brokerRequestFiltersModel.active", true));
    }
  }
  componentDidUpdate() {
    this.currentFilterBy = this.props.parentMatch?.params?.search;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { items } = this.props;
    const { items: nextItems } = nextProps;
    const { filters: stateFilters } = this.state;

    if (JSON.stringify(items) !== JSON.stringify(nextItems)) {
      if (nextProps.filters.refreshCollection) {
        this.setFailed(nextProps.items);
        this.paginationService.reloadItems();
        this.context.store.dispatch(
          actions.change("brokerRequestFiltersModel.refreshCollection", false),
        );
        const filters = prepareQueryStringFromObj(nextProps.filters).concat(stateFilters);
        this.paginationService.setProp("filters", filters);
      }

      if (this.state.isSorted) {
        this.paginationService.reloadItems();
        this.setState({ isSorted: false });
      }

      // console.log("this.paginationService", this.paginationService);
      // console.log("nextProps", nextProps);
      // console.log("this.prepareRows", this.prepareRows);

      this.paginationService.populateItems(
        nextProps.items,
        this.prepareRows.bind(this),
        nextProps.isConcat,
      );
    }
  }

  tableAction(index) {
    const currentRequestStatus = this.paginationService.items[index].statusFromApi;
    const isRequestApproved = this.paginationService.items[index].is_approved;
    const linkToBeCopied = `${window.location.origin}/marketplace/trip/request/${this.paginationService.items[index].id}`;
    const showCopyCondition =
      (currentRequestStatus === OPEN || currentRequestStatus === PENDING) && isRequestApproved;

    if (this.currentFilterBy === EXPIRED || this.currentFilterBy === WITHDRAWN)
      return { desktop: <td></td> };
    if (showCopyCondition)
      return returnBrokerBookingRequestListTableActionComponent(linkToBeCopied);
    else return returnBrokerBookingRequestListTableActionComponent(linkToBeCopied, true);
  }

  get sortOrder() {
    return this.props.parentMatch.params.search &&
      (this.props.parentMatch.params.search === "urgent" ||
        this.props.parentMatch.params.search === "active")
      ? { field: "id", sortOrder: "desc" }
      : requestListConfig.defaultOrder;
  }

  get emptyRowMessage() {
    const alias = this.props.parentMatch.params.search
      ? this.props.parentMatch.params.search
      : "default";
    return staticService.findByAlias(this.state.staticContentsAliasesForGrid[alias]);
  }

  handleSorting(column, order) {
    if (this.state.filterActive) {
      this.paginationService.setFilters(this.props.filters, requestListConfig.filters);
    }
    this.setState({
      sortOrder: { field: column, sortOrder: order },
      isSorted: true,
    });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.paginationService.loadData();
  }

  resetFilters(filters, requests) {
    this.filterToggle(false);
    this.paginationService.setProp("filters", filters);
    this.paginationService.reloadItems();
    this.context.store.dispatch(getBrokerRequetsSuccess(requests));
  }

  filterToggle(state) {
    const states = { filterActive: state };
    if (!state) {
      states.sortOrder = this.sortOrder;
    }
    this.setState(states);
  }

  sendToEdit(request) {
    request &&
      request.legs &&
      request.legs.map((leg) => {
        leg.from_is_private = leg.from_is_private !== 0;
        leg.to_is_private = leg.to_is_private !== 0;
        leg.passengers.passengers_amount_type = `${leg.passengers.passengers_amount_type}`;
        leg.local_points = true;
      });
    this.context.store.dispatch(editRequest(request));
    this.setState({ editable: true });
  }

  onDialogSelect() {
    if (this.props.withdrawRequestReason.length > 3) {
      this.props.updateRequest(
        {
          status: WITHDRAWN,
          id: this.props.requestDetail.id,
          reason: this.props.withdrawRequestReason,
        },
        () => {
          this.props.removeBrokerRequest(this.props.requestDetail.id);
        },
      );

      this.context.store.dispatch(actions.reset("withdrawRequestReason"));
    }
  }

  enterHandler(e) {
    if (e.keyCode === 13 && this.props.withdrawRequestReason.length > 3) {
      e.preventDefault();
      return this.onDialogSelect();
    }
  }

  triggerAction(action, quote) {
    switch (action) {
      case REQUEST_WITHDRAWN:
        this.setState({
          quote: quote,
          action: action,
          dialogProps: this.withdrawDialogProps(),
        });
    }

    this.confirmComponent.openModal();
  }

  withdrawDialogProps() {
    return {
      content: (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("confirmRequestWithdraw"),
            }}
          />
          <div className="uk-margin">
            <Control.textarea
              className={"uk-textarea"}
              model="withdrawRequestReason"
              validators={{
                max: max(config.quoteDescriptionMaxLength, false),
                required,
                min: min(4),
              }}
              changeAction={updateModel}
              onKeyDown={this.enterHandler.bind(this)}
            />
            <Errors
              model="withdrawRequestReason"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(null, "Reason"),
                min: validationMessages().minMessage.bind(null, "4")(),
              }}
            />
            {this.props.fieldError && (
              <ul className="ul validation-errors disabled-list gh-input-errors">
                <li dangerouslySetInnerHTML={{ __html: this.props.fieldError }} />
              </ul>
            )}
            <Errors
              model="withdrawRequestReason"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                max: validationMessages().maxMessage(
                  config.quoteDescriptionMaxLength,
                  "characterLimitExceeded",
                ),
              }}
            />
          </div>
        </div>
      ),
      className: "uk-alert uk-alert-warning gh-alert-without-bg",
      buttonsProps: {
        cancel: { title: staticService.findByAlias("cancel") },
        continue: {
          title: staticService.findByAlias("withdrawButton"),
          css: "uk-button-danger withdraw-request-modal_btn",
          closeOnClick: true,
        },
      },
      showCloseBtn: false,
      onSelect: () => {
        if (this.props.withdrawRequestReason.length > 3) {
          this.onDialogSelect.bind(this)();
        } else {
          this.context.store.dispatch(actions.setTouched("withdrawRequestReason"));
        }
      },
      onReject: () => {
        this.closeModal();
        this.context.store.dispatch(actions.reset("withdrawRequestReason"));
      },
      modalProps: {
        id: "withdraw-request-modal",
        title: staticService.findByAlias("confirmRequestWithdrawModalTitle"),
        options: {
          clsPage: "",
          bgClose: false,
          escClose: false,
        },
      },
    };
  }

  defaultDialogProps() {
    return {
      content: "",
      className: "",
      buttonsProps: {},
      onSelect: () => {},
      onReject: () => {},
      showCloseBtn: true,
      modalProps: {},
      title: "",
    };
  }

  updateCurrentActiveRequest(values, id) {
    const updatedRequest = { ...this.state.request, ...values };

    this.setState({ request: updatedRequest });

    this.paginationService.items = this.paginationService.items.map((itm) =>
      itm.id === id ? { ...itm, ...values } : itm,
    );

    this.context.store.dispatch(getBrokerRequestDetailSuccess(updatedRequest));

    setTimeout(() => this.forceUpdate());
  }

  render() {
    const legs = this.state.selectedLegs;
    const { expired_reasons, loading_expired_btn, isLoading } = this.state;
    const { requestDetail } = this.props;

    const visibleEdit =
      requestDetail &&
      requestDetail.activeQuotes &&
      requestDetail.activeQuotes.length === 0 &&
      requestDetail.status === "open";
    if (this.state.editable) {
      if (requestDetail.empty_leg_id) {
        return redirectTo(`/marketplace/empty-legs/${requestDetail.empty_leg_id}`);
      } else {
        return redirectTo("/new-request");
      }
    }
    return (
      <div>
        {/*------------------ MODALS SECTION --------------------*/}
        <ModalComponent
          title={staticService.findByAlias("expiredReasons")}
          ref={(expiredRequestModal) => (this.expiredRequestModal = expiredRequestModal)}
          id={"expiredRequestModal"}
          onClose={this.closeModal.bind(this)}
          modalClass="gh-expired-reasons-modal"
        >
          <ReasonComponent
            reasons={expired_reasons}
            handleSubmit={this.sendExpiredReason}
            isLoading={loading_expired_btn}
            ref={this.childRef}
          />
        </ModalComponent>

        <ModalComponent
          title={staticService.findByAlias("request-details")}
          options={{ clsPage: "", bgClose: true, escClose: false }}
          onClose={this.closeRequestModal.bind(this)}
          modalClass="gh-broker-nav-request-modal"
          id="full-request-modal"
          ref={(fullRequest) => (this.fullRequest = fullRequest)}
        >
          {Object.keys(this.state.request).length && (
            <BrokerReviewRequestComponent
              request={this.state.request}
              nav={true}
              onUpdateRequest={this.updateCurrentActiveRequest}
              showEditRight
            />
          )}

          <div className="d-flex_container uk-margin-top">
            <div className="d-flex_inner">
              {requestDetail.status !== "withdrawn" && (
                <Button
                  disabled={!requestDetail.ability_to_edit}
                  className="uk-margin-right"
                  onClick={() => this.triggerAction(REQUEST_WITHDRAWN)}
                  color="danger"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService
                        .findByAlias("withdrawButton")
                        .replace(/(<([^>]+)>)/gi, ""),
                    }}
                  />
                </Button>
              )}

              {visibleEdit && (
                <Button
                  disabled={!requestDetail.ability_to_edit}
                  onClick={() => this.sendToEdit(requestDetail)}
                  color="gray"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("edit"),
                    }}
                  />
                </Button>
              )}
            </div>

            <Button onClick={() => this.fullRequest.hide()}>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("close"),
                }}
              />
            </Button>
          </div>
        </ModalComponent>
        <ModalComponent
          title={staticService.findByAlias("requestLegsModalTItle")}
          ref={(modal) => (this.modal = modal)}
          id={"legs"}
          modalClass="legs-modal_component"
          onClose={this.closeModal.bind(this)}
        >
          <LegRequests legs={legs} />
        </ModalComponent>
        <ModalComponent
          title={staticService.findByAlias("progressInfoModalTitle")}
          ref={(progressInfoModal) => (this.progressInfoModal = progressInfoModal)}
          id={"progressInfoModal"}
          modalClass="gh-progress-info-modal small-modal"
          onClose={this.closeProgressInfoModal.bind(this)}
          onCloseOnBackground={this.closeProgressInfoModal.bind(this)}
          options={{ bgClose: false, escClose: false }}
        >
          <ProgressInfoModalContent
            openedProgressInfoModalItem={this.state.openedProgressInfoModalItem}
          />
        </ModalComponent>
        <ConfirmComponent
          ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
          info={this.state.dialogProps.content}
          rejectRequest
          alertType={this.state.dialogProps.className}
          buttonsProps={this.state.dialogProps.buttonsProps}
          modalProps={this.state.dialogProps.modalProps}
          onReject={this.state.dialogProps.onReject}
          onSelect={this.state.dialogProps.onSelect}
          showCloseBtn={this.state.dialogProps.showCloseBtn}
        />
        {this.paginationService ? (
          <BrokerRequestFiltersComponent
            visible={this.props.isFiltersShown}
            sortParams={this.sortOrder}
            history={this.props.history}
            loadData={this.loadDataOnLoad}
            parentFilters={
              this.props.parentMatch.params.search
                ? { filterBy: this.props.parentMatch.params.search }
                : {}
            }
            resetFilters={this.resetFilters.bind(this)}
            headers={this.paginationService.headers}
            onSubmit={this.filterToggle}
            isLoading={isLoading}
          />
        ) : (
          ""
        )}

        {/*------------------ TABLE SECTION ------------------*/}
        {this.paginationService ? (
          <div className="uk-overflow-auto uk-margin-bottom gh-request-list">
            <ComplexTableComponent
              loadMore={this.paginationService.loadMore.bind(this.paginationService)}
              rows={this.paginationService.items}
              headers={this.paginationService.headers}
              columns={this.paginationService.columns}
              showActionColumn={1}
              emptyRowMessage={this.emptyRowMessage}
              showLoader={this.paginationService.showLoaderBtn}
              showMoreText={staticService.findByAlias("showMore")}
              actionElements={this.tableAction.bind(this)}
              selectedOrder={this.state.sortOrder}
              sortChange={this.handleSorting.bind(this)}
              firstTimeLoading={this.paginationService.initialLoadState}
              brokerRequestsList={true}
              dispatch={this.context.store.dispatch}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("requests");
    this._mounted = false;
  }
}

BrokerBookingRequestListComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerBookingRequestListComponent.defaultProps = {
  items: [],
};

BrokerBookingRequestListComponent.propTypes = {
  items: PropTypes.array,
  requestDetail: PropTypes.object,
  isFiltersShown: PropTypes.bool,
  filters: PropTypes.object,
  getProgressInformation: PropTypes.func,
  parentMatch: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
  getReasonsToExpired: PropTypes.func,
  makeRequestExpired: PropTypes.func,
  history: PropTypes.object,
  withdrawRequestReason: PropTypes.any,
  removeBrokerRequest: PropTypes.func,
  isConcat: PropTypes.bool,
  fieldError: PropTypes.any,
  updateRequest: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateRequest: updateRequest,
      updateModel: updateModel,
      removeBrokerRequest: removeBrokerRequest,
      updateBrokerRequest: updateBrokerRequest,
      getProgressInformation: getProgressInformation,
      getReasonsToExpired: getReasonsToExpired,
      makeRequestExpired: makeRequestExpired,
    },
    dispatch,
  );
};

function mapStateToProps({
  broker_requests,
  brokerRequestFiltersModel,
  isConcat,
  requestDetail,
  withdrawRequestReason,
}) {
  return {
    items: broker_requests,
    filters: brokerRequestFiltersModel,
    isConcat,
    requestDetail,
    withdrawRequestReason,
  };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerBookingRequestListComponent);
export { COMPONENT as BrokerBookingRequestListComponent };
