import React from "react";
import staticService from "../../services/static.service";

export const EnquirePassed = () => {
  return (
    <div className="enquiry-passed">
      <div
        className="enquiry-passed-info"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("enquiryThankYou") }}
      />
    </div>
  );
};
