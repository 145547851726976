import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "../../../../Form";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useYupResolver from "../../../../../hooks/useYupResolver";
import clsx from "clsx";
import Textarea from "../../../../Form/components/Textarea";
import Button from "../../../../common/Button";
import Api from "../../../../../services/api-handler";
import { showNotification } from "../../../../../utils/showNotification";
import getApiErrorMessages from "../../../../../utils/getApiErrorMessages";
import { editRequest } from "../../../../../actions";
import { connect } from "react-redux";
import { OPEN, PENDING } from "../../../../../configs";

import "./RequestFeedback.css";

import greenArrowIcon from "../../../../../assets/img/svg/feedbackRespondArrow.svg";
import arrowIcon from "../../../../../assets/img/svg/arrow-left-dark.svg";
import handleHookFormErrors from "../../../../../utils/handleHookFormErrors";
import SuggestMessage from "../../../../common/SuggestMessage";
import staticService from "../../../../../services/static.service";
import { formatNumber } from "../../../../../utils/formatting";

const RequestFeedback = (props) => {
  const {
    isAllowToEditRequest,
    moreDetailsRequest,
    index,
    history,
    onSubmit,
    request,
    editRequest,
    banking,
  } = props;

  const [showHiddenContent, setShowHiddenContent] = useState(!!moreDetailsRequest.broker_response);
  const [hasBrokerResponse, setHasBrokerResponse] = useState(!!moreDetailsRequest.broker_response);
  const [brokerResponse, setBrokerResponse] = useState(moreDetailsRequest.broker_response || "");
  const [showSuggestMessage, setShowSuggestMessage] = useState(true);
  const allowedStatuses = request.status === OPEN || request.status === PENDING;

  const requestFeedbackSchema = yup.object().shape({
    body: yup
      .string()
      .required("This field is required")
      .min(4, "Must be 4 or more characters!")
      .max(700, "Must be 700 or fewer characters!"),
  });

  const form = useForm({
    resolver: useYupResolver(requestFeedbackSchema),
  });

  useEffect(() => {
    const subscription = form.watch((value) => setShowSuggestMessage(!value.body));
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const handleSetSuggestMessage = (message) => {
    form.setValue("body", message);
    form.clearErrors("body");
  };

  const handleBrokerResponse = (message) => {
    setHasBrokerResponse(true);
    setBrokerResponse(message);
  };

  const onFormSubmit = (value) => {
    const { body } = value;
    sendMessage(body);
  };

  const sendMessage = (body) => {
    Api.setCommon()
      .setPath("booking-request/reply-more-information", moreDetailsRequest.id)
      .post({ body: body })
      .then((response) => {
        handleBrokerResponse(body);
        if (onSubmit) onSubmit(response);
      })
      .catch((error) => {
        if (error?.data?.errors) {
          handleHookFormErrors(form.setError, error.data.errors);
        }
        return error;
      });
  };

  const clearFrom = () => {
    form.reset();
    handleOpenRequest();
  };

  const handleOpenRequest = () => {
    if (!showHiddenContent && !hasBrokerResponse) {
      // focus on textarea
      setTimeout(() => {
        form.setFocus("body");
      }, 100);
    }
    setShowHiddenContent((prevState) => !prevState);
  };

  const handleDecline = () => {
    const message = staticService.findByAlias("requestFeedbackDeclineMessage");
    sendMessage(message);
  };

  const handleEditRequest = () => {
    if (!isAllowToEditRequest) return;

    const updatedLegs = request?.legs.map((leg) => ({
      ...leg,
      from_is_private: leg.from_is_private !== 0,
      to_is_private: leg.to_is_private !== 0,
      passengers: {
        ...leg.passengers,
        passengers_amount_type: `${leg.passengers.passengers_amount_type}`,
      },
      local_points: true,
    }));

    editRequest({ ...request, legs: updatedLegs });

    setTimeout(() => {
      if (request.empty_leg_id) {
        return history.push(`/marketplace/empty-legs/${request.empty_leg_id}`);
      } else {
        return history.push("/new-request");
      }
    }, 100);
  };

  return (
    <div className="gh-request-feedback-item">
      <div className="gh-request-feedback-item__wrapper">
        <div className="gh-request-feedback-item__wrapper-btn" onClick={handleOpenRequest}>
          <div
            className={clsx("gh-request-feedback-item__wrapper-btn_arrow-btn btn-style", {
              "--open": showHiddenContent,
            })}
          >
            <img src={arrowIcon} alt="arrowIcon" />
          </div>
        </div>

        <div className="gh-request-feedback-item__content">
          <div className="gh-request-feedback-item__content_title">
            {Object.keys(moreDetailsRequest?.operator_company || {}).length
              ? moreDetailsRequest?.operator_company.name
              : staticService.findByAlias("operator") + " " + `${index + 1}`}
          </div>

          {/*OPERATOR REQUEST SECTION*/}

          <div className="gh-request-feedback-item-row" style={{ marginBottom: 5 }}>
            <label className="uk-form-label" style={{ marginRight: 5 }}>
              {staticService.findByAlias("operatorRequirement")}:
            </label>
            <div
              onClick={handleOpenRequest}
              className={clsx("gh-request-feedback-item__content_text", {
                "--cut-text": !showHiddenContent,
              })}
            >
              {moreDetailsRequest.body || ""}
            </div>
          </div>

          {moreDetailsRequest.quick_price ? (
            <div className="uk-flex uk-flex-middle">
              <label className="uk-form-label" style={{ marginRight: 5 }}>
                {staticService.findByAlias("quickPriceEstimateTitle")}:
              </label>
              <div className="gh-request-feedback-item__content_text">
                <span style={{ fontWeight: 500, marginRight: 3 }}>
                  <span style={{ marginRight: 3 }}>
                    {" "}
                    {moreDetailsRequest?.operator_company?.currency}
                  </span>
                  {formatNumber(moreDetailsRequest.quick_price)}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          {/*<div*/}
          {/*  className={clsx("gh-request-feedback-item__content_text", {*/}
          {/*    "--cut-text": !showHiddenContent,*/}
          {/*  })}*/}
          {/*>*/}
          {/*  {moreDetailsRequest.body || ""}*/}
          {/*</div>*/}

          {/*FEEDBACK FORM SECTION*/}
          {!hasBrokerResponse && showHiddenContent ? (
            <div className="gh-request-feedback__form-wrapper">
              <Form form={form} onSubmit={onFormSubmit} className="gh-request-feedback-form">
                <Form.Item name="body" className="uk-margin-small-top">
                  <LocalTextarea
                    showSuggestMessage={showSuggestMessage}
                    setSuggestMessage={handleSetSuggestMessage}
                  />
                </Form.Item>
              </Form>

              {allowedStatuses ? (
                <div className="gh-request-feedback__form-controls">
                  <button
                    uk-icon="icon: check"
                    className="gh-request-feedback__form-controls_btn btn-style"
                    style={{ color: "#1abc9c" }}
                    onClick={form.handleSubmit(onFormSubmit)}
                  />

                  <button
                    uk-icon="icon: close"
                    className="gh-request-feedback__form-controls_btn btn-style"
                    style={{ color: "#e74c3c" }}
                    onClick={clearFrom}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/*BROKER RESPONSE SECTION*/}
          {hasBrokerResponse && showHiddenContent ? (
            <div className="gh-request-feedback-item__respond">
              <span className="gh-icon">
                <img src={greenArrowIcon} alt="arrow" />
              </span>
              <div className="gh-text">{brokerResponse}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="gh-request-feedback-item__controls">
        <Button
          variant="text"
          color="primary"
          disabled={!isAllowToEditRequest}
          onClick={handleEditRequest}
        >
          Edit Request
        </Button>

        {!hasBrokerResponse && !showHiddenContent && allowedStatuses ? (
          <Button variant="text" color="success" onClick={handleOpenRequest}>
            Respond
          </Button>
        ) : (
          ""
        )}

        {!hasBrokerResponse && allowedStatuses ? (
          <Button variant="text" color="danger" onClick={handleDecline}>
            Decline
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

RequestFeedback.propTypes = {
  moreDetailsRequest: PropTypes.object.isRequired,
  editRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  index: PropTypes.number,
  isAllowToEditRequest: PropTypes.bool,
  onSubmit: PropTypes.func,
  request: PropTypes.object,
  banking: PropTypes.object,
};
RequestFeedback.defaultProps = {
  onSubmit: null,
  request: {},
  banking: {},
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps, { editRequest })(RequestFeedback);

const LocalTextarea = forwardRef((props, ref) => {
  const { showSuggestMessage, setSuggestMessage } = props;

  return (
    <>
      <Textarea {...props} ref={ref} />

      {showSuggestMessage ? (
        <div className="gh-feedback-suggestion-wrapper">
          <SuggestMessage
            message={staticService.findByAlias("brokerFeedbackSuggestMessage")}
            onClick={setSuggestMessage}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
});

LocalTextarea.displayName = "LocalTextarea";

LocalTextarea.propTypes = {
  showSuggestMessage: PropTypes.bool,
  setSuggestMessage: PropTypes.func,
};
