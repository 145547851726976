import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";

const RequestBlockComponent = (props) => {
  return (
    <div className="uk-position-relative">
      <div className={props.topPosition || "uk-margin-medium-top"}>
        <div className="gh-leg-title">
          <span
            className="uk-text-uppercase gh-request-block-title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
          {props.tooltipVisible ? (
            <span
              className="uk-margin-small-left gh-text-bottom gh-yellow"
              style={{ marginTop: 5, minWidth: 20, textTransform: "initial" }}
              data-uk-icon="icon: warning"
              data-uk-tooltip={`title: ${staticService.findByAlias(
                "aircraftProvisionallyTooltip",
              )};`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {props.children}
    </div>
  );
};

RequestBlockComponent.propTypes = {
  title: PropTypes.string.isRequired,
  topPosition: PropTypes.string,
};

export { RequestBlockComponent };
