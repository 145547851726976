import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "react-redux-form";
import {
  ALL,
  BOOKING_INIT,
  config,
  OPEN,
  operatorQuotesFilters,
  QUOTE_OPEN,
  quotesConfig,
  REJECTED,
  REJECTED_UNSUCCESSFUL,
  staticText,
  UNSUCCESSFUL,
} from "../../../../../configs";
import { getOperatorQuotes } from "../../../../../actions";
import {
  ComplexTableComponent,
  fireTracking,
  formatID,
  prepareQueryAttributes,
  prepareQueryStringFromObj,
  setActiveItem,
} from "../../../../../shared/";
import { PaginationService } from "../../../../../services";
import { OperatorBookingQuotesFiltersComponent } from "../../filters/";
import GetHeliEvents from "../../../../../services/event.service";
import staticService from "../../../../../services/static.service";
import Auth from "../../../../../services/auth";
import auth from "../../../../../services/auth";
import envelope from "../../../../../assets/img/envelope.png";
import amendedImg from "../../../../../assets/img/amended.png";
import OperatorMarketAxis from "./OperatorMarketAxis";
import QuotesChaseButton from "./QuotesChaseButton";
import clsx from "clsx";
import Tooltip from "../../../../common/Tooltip";
import { formattedTimestamp } from "../../../../../hooks/useTime";

import "./operator-booking-quote-list.component.css";

class OperatorBookingQuoteListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.match.params.search
        ? [{ key: "filterBy", value: props.match.params.search }]
        : [],
      sortOrder: this.order,
      isFilterTriggerd: false,
      customProps: {},
      isLoading: false,
    };
    this.mergedParams = { ...props.match?.params, ...props.parentMatch?.params };
    this.currentFilterBy = props.match?.params?.search || this.mergedParams?.search;
    this.tableAction = this.tableAction.bind(this);
    this.setFailed = this.setFailed.bind(this);
    this.loadDataOnLoad = this.loadDataOnLoad.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.goTo = this.goTo.bind(this);
    fireTracking(props.location.pathname);
    this._mounted = true;
  }

  removeFilterIcon() {
    const requestID = this.props.match.params.requestID;
    if (!requestID || !document.querySelector(".gh-filters-tab")) return;
    document.querySelector(".gh-filters-tab").style.visibility = "hidden";
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState && this._mounted) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  loadDataOnLoad(status) {
    this.setState({ isLoading: true });

    GetHeliEvents.setEvent("quotes", this.catchSuccessResponse.bind(this));
    setActiveItem(operatorQuotesFilters, "");
    const statusToApply = () => {
      if (this.props.match.params.requestID) {
        return [
          { key: "request_id", value: this.props.match.params.requestID },
          { key: "status", value: status ? status : "all" },
        ];
      }
      if (status) {
        return [{ key: "status", value: status }];
      }
      if (this.props.match.params.search) {
        return [{ key: "status", value: this.props.match.params.search }];
      }
      return [];
    };
    this.setState({ filters: statusToApply() });
    this.paginationService = new PaginationService(
      this.context.store.dispatch,
      getOperatorQuotes,
      config.paginationLimit,
      statusToApply(),
    );

    this.paginationService.setProp("columns", quotesConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(quotesConfig));
    this.paginationService.loadData().finally(() => {
      this.setState({ isLoading: false });
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.loadDataOnLoad();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (match.params.search === "pre_quotes" && prevProps.match.params.search !== "pre_quotes") {
      this.setState({ sortOrder: quotesConfig.orderBySubmittedAt });
    }

    if (match.params.search !== "pre_quotes" && this.state.sortOrder?.field === "submitted_at") {
      this.setState({
        sortOrder: this.isValidDateBefore ? quotesConfig.orderByDate : quotesConfig.defaultOrder,
      });
    }

    this.currentFilterBy = this.props.match?.params?.search || this.mergedParams?.search;
  }

  setFailed(total) {
    if (parseInt(total) === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  prepareRows(items) {
    const { parentMatch, match } = this.props;
    const axisFeature = auth.hasFeature("operator_extended_progress_bar_status");

    return items.map((item) => {
      const filterFromUrl = parentMatch?.params.search || match.params.search || "all";
      const baseUrl = `/flights/quotes/filter/${filterFromUrl}`;
      const route = `${baseUrl}/${item.id}`;
      const isPrequeted = item.is_prequeted;

      item.displayID = !isPrequeted ? (
        <Link to={{ pathname: route, state: { prevPath: match.url } }}>
          {formatID(item.id, config.prefixes.quote)}
        </Link>
      ) : (
        "---"
      );
      item.displayRequestID = formatID(
        isPrequeted && item.booking_request_id ? item.booking_request_id : item.booking_request.id,
        config.prefixes.request,
      );
      item.on = formattedTimestamp(item.departure_time).date;
      item.submitted_at = <QuotedAt quotedAt={item.submitted_at} />;
      item.progress = (
        <OperatorMarketAxis
          disabled={!item.show_axis}
          requestId={item.booking_request_id || item.booking_request?.id}
          requestStatus={item.status}
          axisFeature={axisFeature}
        />
      );
      item.statusFromApi = item.status;
      item.status = this.renderStatus(item, route);
      return item;
    });
  }

  goTo(to) {
    this.props.history.push(to);
  }

  renderStatus(item, route) {
    const { match } = this.props;
    const hasReason =
      item.reason || item.rejected_message || item.unsuccessful_reason || item.expiration_reason;
    const showViewReason = (item.status === UNSUCCESSFUL || item.status === REJECTED) && hasReason;

    if (item.statusFromApi === "pre_quoted") {
      return (
        <div
          onClick={() =>
            this.goTo({
              pathname: `/marketplace/trip/request/${item.booking_request_id}`,
              state: { prevPath: match.url, from: "pre_quotes" },
            })
          }
          className="uk-flex uk-flex-column btn-style"
        >
          <span className={`status-${item.status}`}>{staticText.quoteStatuses[item.status]}</span>
          <span className="gh-active" style={{ fontSize: 12 }}>
            View
          </span>
        </div>
      );
    }

    return (
      <div className="uk-flex uk-flex-column">
        <span style={{ color: "#212121" }}>{staticText.quoteStatuses[item.status]}</span>
        {showViewReason ? (
          <span
            className="btn-style"
            style={{ fontSize: 12, color: "#10b897 " }}
            onClick={() => this.goTo({ pathname: route, state: { prevPath: match.url } })}
          >
            View reason
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      if (nextProps.filters.refreshCollection) {
        this.setFailed(nextProps.items);
        this.context.store.dispatch(
          actions.change("operatorBookingQuotesFiltersModel.refreshCollection", false),
        );
        this.paginationService.reloadItems();
        let filters = this.state.filters;
        if (this.state.isFilterTriggerd) {
          filters = prepareQueryStringFromObj(nextProps.filters);
        }
        this.paginationService.setProp("filters", filters);
      }
      this.paginationService.populateItems(
        nextProps.items,
        this.prepareRows.bind(this),
        nextProps.isConcat,
      );
    }
    this.setEmptyRowMessage();
  }

  setEmptyRowMessage() {
    if (Auth.getMainRole() === "operator") {
      const search = this.props.match.params.search;
      const button = { label: "Marketplace", link: "/marketplace" };
      let messageId = "empty-row-quotes-default";
      if (search === "pending") {
        messageId = "empty-row-quotes-pending";
      } else if (search === "current") {
        messageId = "empty-row-quotes-current";
      }
      this.setState({
        customProps: {
          emptyRowMessage: staticService.findByAlias(messageId),
          emptyRowButton: button,
        },
      });
    }
  }

  tableAction(index) {
    const row = this.paginationService.items[index];
    const filterFromUrl =
      this.props.parentMatch?.params.search || this.props.match.params.search || "all";
    const baseUrl = `/flights/quotes/filter/${filterFromUrl}`;
    const route = `${baseUrl}/${row.id}`;
    const viewLink = { pathname: route, state: { prevPath: this.props.match.url, openChat: true } };
    const showChaseButton =
      this.currentFilterBy === OPEN ||
      this.currentFilterBy === ALL ||
      this.currentFilterBy === BOOKING_INIT ||
      this.currentFilterBy === REJECTED_UNSUCCESSFUL ||
      false;
    const chatFeatures = auth.hasFeature("operator_to_start_conversation");
    const showByStatus = ["open", "booking_init"].includes(row.statusFromApi);
    const conversationIsOpen = row.is_conversation_open;
    const quoteStatus = row.status.props.children[0].props.children.toLowerCase();

    const hasToExpireIsOpenConversation = chatFeatures
      ? row?.booking_request?.status === QUOTE_OPEN ||
        (row?.booking_request?.has_to_expire &&
          row?.booking_request?.status === QUOTE_OPEN &&
          quoteStatus === UNSUCCESSFUL)
      : conversationIsOpen && row?.booking_request?.status === QUOTE_OPEN;

    return {
      desktop: (
        <td style={{ verticalAlign: "middle", position: "relative" }}>
          <ActionButtons
            viewLink={viewLink}
            row={row}
            showChaseButton={showChaseButton}
            chatFeatures={chatFeatures}
            showByStatus={showByStatus}
            conversationIsOpen={conversationIsOpen}
            hasToExpireIsOpenConversation={hasToExpireIsOpenConversation}
          />
        </td>
      ),
      mobile: (
        <span>
          <ActionButtons
            viewLink={viewLink}
            row={row}
            showChaseButton={showChaseButton}
            chatFeatures={chatFeatures}
            showByStatus={showByStatus}
            conversationIsOpen={conversationIsOpen}
            hasToExpireIsOpenConversation={hasToExpireIsOpenConversation}
          />
        </span>
      ),
    };
  }

  handleSorting(column, order) {
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    const filters = prepareQueryAttributes(
      { ...this.props.filters, status: this.state.filters[0].value },
      quotesConfig.filters,
    );
    this.paginationService.setProp("filters", filters.concat(this.state.filters));
    this.paginationService.loadData();
    this.context.store.dispatch(
      actions.change("operatorBookingQuotesFiltersModel.refreshCollection", true),
    );
  }

  get isValidDateBefore() {
    const search = this.props.match.params.search;

    return search === "current" || search === "active" || search === "pending";
  }

  get order() {
    const search = this.props.match.params.search;

    return search === "pre_quotes"
      ? quotesConfig.orderBySubmittedAt
      : this.isValidDateBefore
      ? quotesConfig.orderByDate
      : quotesConfig.defaultOrder;
  }

  onFilterSubmit() {
    return [];
  }

  onFilterReset() {
    const sort = this.order;
    this.setState({ sortOrder: sort });
    this.paginationService.setSortingParameters({
      column: sort.field,
      order: sort.sortOrder,
    });
    return this.state.filters;
  }

  loadMore() {
    const filters = prepareQueryAttributes(
      { ...this.props.filters, status: this.props.match.params.search },
      quotesConfig.filters,
    );
    this.paginationService.setProp("filters", filters.concat(this.state.filters));
    this.context.store.dispatch(actions.change("isConcat", true));
    this.paginationService.loadMore();
  }

  render() {
    const { match } = this.props;
    const { isLoading } = this.state;
    this.removeFilterIcon();

    return (
      <div>
        <OperatorBookingQuotesFiltersComponent
          visible={this.props.isFiltersShown}
          pageFilters={this.state.filters}
          isValidDate={this.isValidDateBefore}
          queryAttributes={quotesConfig.filters}
          onReset={this.onFilterReset.bind(this)}
          onSubmit={this.onFilterSubmit.bind(this)}
          history={this.props.history}
          loadData={this.loadDataOnLoad}
          mergedParams={this.mergedParams}
          isLoading={isLoading}
        >
          {({ template }) => {
            return template;
          }}
        </OperatorBookingQuotesFiltersComponent>

        <div className={"uk-overflow-auto uk-margin-bottom"}>
          <ComplexTableComponent
            loadMore={this.loadMore.bind(this)}
            rows={this.paginationService.items}
            headers={this.paginationService.headers}
            columns={this.paginationService.columns}
            selectedOrder={this.state.sortOrder}
            showActionColumn={1}
            showLoader={this.paginationService.showLoaderBtn}
            showMoreText={staticService.findByAlias("showMore")}
            actionElements={this.tableAction.bind(this)}
            sortChange={this.handleSorting.bind(this)}
            firstTimeLoading={this.paginationService.initialLoadState}
            isLoading={true}
            ignoreToSorting={match.params.search === "pre_quotes" ? ["id"] : []}
            {...this.state.customProps}
          />
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("quotes");
    GetHeliEvents.clearEvent("filter-triggered");
    this._mounted = false;
  }
}

OperatorBookingQuoteListComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorBookingQuoteListComponent.propTypes = {
  isFiltersShown: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  filters: PropTypes.object,
  items: PropTypes.array,
  isConcat: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    items: state.quotes,
    ...state.screenDimensions,
    quoteDiscussion: state.quoteDiscussion && state.quoteDiscussion.data,
    filters: state.operatorBookingQuotesFiltersModel,
    isConcat: state.isConcat,
  };
}

const COMPONENT = connect(mapStateToProps)(OperatorBookingQuoteListComponent);
export { COMPONENT as OperatorBookingQuoteListComponent };

OperatorBookingQuoteListComponent.propTypes = {
  history: PropTypes.object,
  parentMatch: PropTypes.object,
  filters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  match: PropTypes.object,
  location: PropTypes.object,
};

/* eslint-disable react/prop-types */

const QuotedAt = ({ quotedAt }) => {
  const { date, time } = formattedTimestamp(quotedAt);
  if (!date && !time) return "---";
  return (
    <>
      <div style={{ whiteSpace: "nowrap" }}>{date}</div>
      <div>at {time}</div>
    </>
  );
};

const ActionButtons = ({
  viewLink,
  row,
  showChaseButton,
  chatFeatures,
  showByStatus,
  conversationIsOpen,
  hasToExpireIsOpenConversation,
}) => {
  return (
    <div
      className={clsx("gh-operator-quote-list-actions-column", {
        "gh-min-width-140": showChaseButton,
      })}
    >
      <QuotesChaseButton
        className={clsx("gh-table-actions-item gh-item-larger")}
        quoteId={row.id}
        quoteStatus={row.statusFromApi}
        chaseDetails={row.chase_details}
        show={showChaseButton}
        hasToExpireIsOpenConversation={hasToExpireIsOpenConversation}
      />

      {(chatFeatures && showByStatus) || (conversationIsOpen && showByStatus) ? (
        <div style={{ minWidth: 70 }}>
          <Tooltip
            pos="top-center"
            refEl="self"
            trigger={
              <Link className="gh-quote-list-envelope btn-style" to={viewLink}>
                <img src={envelope} alt="envelope" />
                {row.comments_count ? (
                  <span className="uk-label uk-label-danger gh-notifications-label no-border rightTop">
                    {row.comments_count}
                  </span>
                ) : (
                  ""
                )}
              </Link>
            }
          >
            {staticService.findByAlias("quoteLiveChat")}
          </Tooltip>
        </div>
      ) : (
        ""
      )}
      {row.is_amended && (
        <div className="gh-wrapper-badge">
          <span className="badge">
            <img src={amendedImg} style={{ width: 69 }} alt="badge" />
          </span>
        </div>
      )}
    </div>
  );
};
/* eslint-enable no-return-assign, no-param-reassign */
