import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../../../../common/Tooltip";
import clsx from "clsx";
import auth from "../../../../../../../services/auth";
import staticService from "../../../../../../../services/static.service";

import "./MarketplaceFlag.css";

const MarketplaceFlag = (props) => {
  const { request } = props;
  const isVerified = auth.isVerified();

  const tooltipAlias = (request) => {
    if (request.is_flag_set) return "saveForLater";
    if (request.is_need_more_information_set) return "needMoreInformationRequired";
    if (request.is_quote_uncertain_set) return "quoteUncertain";
    return "";
  };

  return (
    <div className="gh-flag-request gh-marketplace-table-action_item">
      <Tooltip
        pos="top-left"
        refEl="self"
        contentClass="tooltip-content-fixed-tabs"
        wrapperClassName="gh-marketplace-wrapper-tooltip"
        show={!!tooltipAlias(request) || !isVerified}
        trigger={
          <div
            className={clsx("gh-flag-request", {
              "is-flagged": tooltipAlias(request) && isVerified,
            })}
          >
            {flagIcon}
          </div>
        }
      >
        <div style={{ whiteSpace: "nowrap" }}>
          {staticService.findByAlias(
            isVerified ? tooltipAlias(request) : "noStripeVerificationTooltipFlag",
          )}
        </div>
      </Tooltip>
    </div>
  );
};

MarketplaceFlag.propTypes = {
  request: PropTypes.object,
};

export default MarketplaceFlag;

const flagIcon = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.68372 12.05C1.68372 12.05 2.64098 11.2 5.51278 11.2C8.38457 11.2 10.2991 12.9 13.1709 12.9C16.0427 12.9 17 12.05 17 12.05V1.85C17 1.85 16.0427 2.7 13.1709 2.7C10.2991 2.7 8.38457 1 5.51278 1C2.64098 1 1.68372 1.85 1.68372 1.85V12.05Z"
      fill="white"
      stroke="#B6B9C0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.68376 18.0001V2.33578"
      stroke="#B6B9C0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
