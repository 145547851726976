import React, { Component } from "react";
import { EventsLayout } from "../layouts";
import { fireTracking, returnRouteUrl, RouteWithSubRoutes } from "../../shared";
import { bindActionCreators } from "redux";
import { config, pages } from "../../configs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMeta,
  loadEventContent,
  loadFilteredEvents,
  loadHomeContent,
  loadMoreEventContent,
  RESET_EVENT_DATA,
} from "../../actions";
import { actions } from "react-redux-form";
import { EventsInfoComponent } from "../common/events-info.component";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import Auth from "../../services/auth";
import DocumentTitle from "../common/DocumentTitle";
import staticService from "../../services/static.service";
import moment from "moment";

const STATIC_FILTERS = [{ key: "entity_target", value: "public_event" }];

class EventListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
      items: props.listEvents,
      roleSelectedPrefix: "a",
      endPagination: false,
      id: 0,
      page: 1,
      isFilterShown: false,
      filters: STATIC_FILTERS,
    };

    this.getByAlias = this.getByAlias.bind(this);
    this.mapItems = this.mapItems.bind(this);
    this.show = this.show.bind(this);
    this.showFilters = this.showFilters.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.filteredPages = this.filteredPages.bind(this);
    fireTracking(this.props.location.pathname);

    this.listOfLinksRef = React.createRef();
  }

  componentDidMount() {
    const key = window.location.pathname.substring(1).replaceAll("/thank-you", "");
    this.props.getMeta(key);

    this.props.loadHomeContent();
    this.props.loadEventContent(this.state.page, STATIC_FILTERS);
    document.documentElement.style.setProperty("--vh", `${this.props.screenInnerH}px`);

    //  open filter if url includes ?gclid
    const searchedParameter = "gclid";
    if (window.location?.search?.includes(searchedParameter)) {
      this.setState({ isFilterShown: true });
    }
  }

  getByAlias(alias = "") {
    const { items } = this.props;
    const result = items.filter((item) => item.alias === alias);
    return result;
  }

  mapItems(alias = "") {
    const result = this.getByAlias(alias);
    const rows = result.filter((item) => {
      return item.type === "both" || item.type === "Both";
    });
    return rows.map((row) => {
      return {
        image: row.icon,
        title: row.title,
        content: row.gh_content,
      };
    });
  }

  show(id) {
    this.setState({ id: id });
  }

  submitFilters() {
    if (this.listOfLinksRef && this.listOfLinksRef.current) {
      this.listOfLinksRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const filterKeys = Object.keys(this.props.filters);
    const filters = [...STATIC_FILTERS];
    filterKeys.forEach((key) => {
      if (this.props.filters[key] !== "") {
        filters.push({ key: key, value: this.props.filters[key] });
      }
    });

    filters.push({ key: "offset", value: moment().utcOffset() / 60 });

    this.setState({ filters: filters });
    this.props.loadFilteredEvents(1, filters);
  }

  loadMoreEvents() {
    this.props.loadMoreEventContent(this.props.totalEvents.current_page + 1, this.state.filters);
  }

  showFilters() {
    this.setState({ isFilterShown: !this.state.isFilterShown });
  }

  resetFilters() {
    this.setState({ page: 1, filters: [] }, () => {
      this.props.loadEventContent(this.state.page, STATIC_FILTERS);
      this.props.resetModel();
    });
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: RESET_EVENT_DATA });
    this.props.resetModel();
  }

  filteredPages() {
    return pages.filter((page) => {
      return !(page.title && page.title.indexOf("bookADemo") !== -1 && Auth.isAuthenticated());
    });
  }

  render() {
    const { listEvents, totalEvents, metaData, countries, updateModel, eventTypes } = this.props;
    return (
      <EventsLayout
        activeRoute={this.props.match}
        pageTitle={this.props.title}
        pages={this.filteredPages()}
        registerPath={config.redirectToRegister}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  {...returnRouteUrl(route, this.props.match.url)}
                  title={route.title}
                  id={listEvents && listEvents.find((index) => index.id === this.state.id)}
                />
              );
            })}
          <Route
            path="/"
            onEnter={fireTracking}
            render={() => {
              return (
                <div className={"uk-background-norepeat uk-background-cover"}>
                  <DocumentTitle title={staticService.findByAlias("eventPageTitle")} />
                  <Helmet>
                    <link rel="canonical" href="https://getheli.com/events" />
                    {metaData &&
                      metaData.meta &&
                      metaData.meta.message &&
                      metaData.meta.message.map((meta) => (
                        <meta key={meta.name} name={meta.name} content={meta.content} />
                      ))}
                  </Helmet>
                  <EventsInfoComponent
                    endPagination={totalEvents && totalEvents.total === listEvents.length}
                    visible={true}
                    eventInfo={this.mapItems("events")}
                    listEvents={listEvents}
                    onClick={this.show}
                    submit={this.submitFilters}
                    updateModel={updateModel}
                    resetFilters={this.resetFilters}
                    eventTypes={eventTypes}
                    showFilters={this.showFilters}
                    isFilterShown={this.state.isFilterShown}
                    loadMore={this.loadMoreEvents.bind(this)}
                    countries={countries}
                    filters={this.props.filters}
                    screenW={this.props.screenW}
                    listOfLinksRef={this.listOfLinksRef}
                  />
                </div>
              );
            }}
          />
        </Switch>
      </EventsLayout>
    );
  }
}

EventListComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadHomeContent,
      loadEventContent,
      loadMoreEventContent,
      loadFilteredEvents,
      getMeta,
      resetModel: () => dispatch(actions.reset("eventsFiltersModel")),
      resetValidity: (model) => dispatch(actions.resetValidity(model)),
      updateModel: (model, value) => dispatch(actions.change(model, value)),
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    totalEvents: state.events.meta.pagination,
    listEvents: state.events.data,
    items: state.home,
    auth: state.auth,
    ...state.screenDimensions,
    metaData: state.metaData,
    eventTypes: state.events.types,
    countries: state.events.countries,
    filters: state.eventsFiltersModel,
    screenW: state.screenDimensions.screenInnerW,
  };
}

EventListComponent.propTypes = {
  items: PropTypes.array,
  listEvents: PropTypes.array,
  loadEventContent: PropTypes.func,
  loadHomeContent: PropTypes.func,
  loadMoreEventContent: PropTypes.func,
  location: PropTypes.shape(),
  match: PropTypes.shape(),
  routes: PropTypes.array,
  screenInnerH: PropTypes.number,
  title: PropTypes.string,
  totalEvents: PropTypes.shape(),
  eventTypes: PropTypes.array,
  filters: PropTypes.object,
};

const EVENTS = connect(mapStateToProps, mapDispatchToProps)(EventListComponent);
export { EVENTS as EventListComponent };
