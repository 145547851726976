import PropTypes from "prop-types";
import React, { useCallback } from "react";
import moment from "moment";
import staticService from "../../../services/static.service";
import { dateFormat } from "../../../shared";
import generateLocationAdditionalInfo from "../../../utils/generateLocationAdditionalInfo";
import SiteComponent from "../SiteComponent";
import { useAccountLocalization } from "../../../context";

import "./styles/index.css";

export default function FlightInlineView(props) {
  const {
    fromLocation,
    fromFallback,
    toLocation,
    toFallback,
    date,
    time,
    direction,
    childs,
    adults,
    luggage,
    dateLabel,
    timeLabel,
  } = props;
  const accountLocalization = useAccountLocalization();

  const generateSiteProps = useCallback((location) => ({
    name: location.name,
    secondaryText: generateLocationAdditionalInfo(location),
    icon: location.source,
  }));

  const generateFlightLocation = useCallback((location, fallback) => {
    if (location && Object.keys(location).length) {
      return <SiteComponent {...generateSiteProps(location)} />;
    }

    return <h6 className="flight-site-title">{fallback}</h6>;
  }, []);

  const generateFlightTime = useCallback((datetime) => {
    return `${moment(datetime).format(accountLocalization.timeFormat)} (UTC${moment().format(
      "Z",
    )})`;
  });

  const generateFlightDate = (date) => {
    return dateFormat(date);
  };

  const getPaxItems = ({ adults, childs, luggage }) => {
    const pax = [];

    if (adults) {
      pax.push(<span key="adults">{`${adults} ${adults === 1 ? "Adult" : "Adults"}`}</span>);
    }

    if (childs) {
      pax.push(<span key="children">{`${childs} ${childs === 1 ? "Child" : "Children"}`}</span>);
    }

    if (luggage) {
      pax.push(<span key="luggage">{`${luggage} Luggage`}</span>);
    }

    return pax;
  };

  return (
    <div className="flight-inline-view">
      <h3 className="flight-direction">{direction}</h3>
      <div className="flight-content">
        <div className="flight-caption">
          <div className="flight-location">
            <span className="flight-location-subtitle flight-subtitle">Client Pick Up</span>
            {generateFlightLocation(fromLocation, fromFallback)}
          </div>
          <div className="flight-location">
            <span className="flight-location-subtitle flight-subtitle">Client Drop Off</span>
            {generateFlightLocation(toLocation, toFallback)}
          </div>
        </div>
        <div className="flight-meta">
          <div className="flight-details">
            <span className="flight-subtitle">{dateLabel}</span>
            <span className="flight-details-content">{generateFlightDate(date, time)}</span>
          </div>
          <div className="flight-details">
            <span className="flight-subtitle">{timeLabel}</span>
            <span className="flight-details-content">{generateFlightTime(time)}</span>
          </div>
          <div className="flight-details">
            <span className="flight-subtitle">{staticService.findByAlias("payloadRight")}</span>
            <span className="flight-details-content pax">
              {!adults && !childs && !luggage ? "---" : getPaxItems({ adults, childs, luggage })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

FlightInlineView.propTypes = {
  fromLocation: PropTypes.object,
  fromFallback: PropTypes.any,
  toLocation: PropTypes.object,
  toFallback: PropTypes.any,
  date: PropTypes.string,
  time: PropTypes.string,
  pax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.string,
  childs: PropTypes.number,
  adults: PropTypes.number,
  luggage: PropTypes.number,
  dateLabel: PropTypes.string,
  timeLabel: PropTypes.string,
};
