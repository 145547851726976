function toNumber(str) {
  return parseFloat(str.replace(/,/g, ""), 10);
}

export default function objectValuesToNumber(obj) {
  return Object.entries(obj).reduce(
    (object, [key, value]) => ({
      ...object,
      [key]: typeof value === "string" ? (isNaN(toNumber(value)) ? value : toNumber(value)) : value,
    }),
    {},
  );
}
