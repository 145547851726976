import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useRef } from "react";
import { useUnmount } from "react-use";
import { primaryIcon } from "./mapUtils";

function CustomMarker(props) {
  const { google, map, markerInfo, setActiveInfo, handleAssignPad, legIndex, legId } = props;
  const marker = useRef(
    new google.maps.Marker({
      icon: primaryIcon(),
    }),
  );

  const mouseOverHandler = useCallback(() => {
    const markerLocationData = {
      latitude: markerInfo.latitude,
      longitude: markerInfo.longitude,
      type: markerInfo.type,
      secondary_text: markerInfo.secondary_text,
      source: markerInfo.source,
      name: markerInfo.location,
      coverage_status: markerInfo.coverage_status,
    };

    if (marker.current) {
      setActiveInfo({
        visible: true,
        marker: marker.current,
        isSelected: false,
        pad: {
          name: markerInfo.location,
          source: markerInfo.source,
          handleAssignButton: (legSide) => {
            removeMarker();
            handleAssignPad({
              ...markerInfo,
              location: markerLocationData,
              name: markerInfo.location,
              legSide,
              legIndex,
              legId,
            });
          },
        },
      });
    }
  }, [markerInfo, marker]);

  const updateMouseEvents = () => {
    marker.current.addListener("mouseover", mouseOverHandler);
  };

  const removeMarker = () => {
    if (marker.current) marker.current.setMap(null);
  };

  useEffect(() => {
    if (markerInfo.place_id) {
      marker.current.setOptions({
        map,
        position: { lat: markerInfo.latitude, lng: markerInfo.longitude },
        title: markerInfo.location,
        ...markerInfo,
      });
      updateMouseEvents();
    } else {
      removeMarker();
    }
  }, [markerInfo]);

  useUnmount(removeMarker);

  return null;
}

CustomMarker.propTypes = {
  google: PropTypes.object,
  map: PropTypes.object,
  markerInfo: PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    title: PropTypes.string,
    place_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  handleAssignPad: PropTypes.func,
  legIndex: PropTypes.number,
};

CustomMarker.defaultProps = {
  markerInfo: {
    place_id: null,
    position: {
      lat: null,
      lng: null,
    },
  },
};

export default memo(CustomMarker);
