import React, { useRef } from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import Button from "../../../../common/Button";
import { getLastItemFromArray, ModalComponent } from "../../../../../shared";
import { connect } from "react-redux";
import { addEmptyLeg, cancelBooking } from "../../../../../actions";
import BookingEmptyLegForm from "./BookingEmptyLegForm/bookingEmptyLegForm";
import { useForm } from "react-hook-form";
import auth from "../../../../../services/auth";
import { CONFIRMED, PROVISIONAL } from "../../../../../configs";
import closeIconDark from "../../../../../assets/img/svg/closeIconDark.svg";

import "./bookingButtons.css";
import tranformDateTimeToISO from "../../../../../utils/tranformDateTimeToISO";

const BookingButtons = (props) => {
  const { cancelBooking, bookingDetail, addEmptyLeg, history, isBrokerAllowedToInteract } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const cancelBookingModalRef = useRef(null);
  const cancelBookingInfoModalRef = useRef(null);
  const createEmptyLegFromThisBookingRef = useRef(null);

  /*=========== FUNCTIONS ===========*/
  const openModal = (modalRef) => {
    modalRef.current.open();
  };

  const closeModal = (modalRef) => {
    modalRef.current.hide();
  };

  const createEmptyLeg = (value) => {
    const data = { ...value };
    const date = tranformDateTimeToISO(data.date, "", "DD MMMM, YYYY");

    data.date = date;
    data.time_from = tranformDateTimeToISO(date, data.time_from);
    data.time_to = tranformDateTimeToISO(date, data.time_to);

    addEmptyLeg(data).then(() => {
      closeModal(createEmptyLegFromThisBookingRef);
    });
  };

  const onSubmit = ({ message }) => {
    closeModal(cancelBookingModalRef);

    cancelBooking(bookingDetail.id, message).then(() => {
      openModal(cancelBookingInfoModalRef);
    });
  };

  const handleCloseInfoModal = () => {
    cancelBookingInfoModalRef.current.hide();
    setTimeout(() => {
      history.push("/flights/my-bookings");
    }, 1000);
  };

  return (
    <div>
      {/*-------------------- BUTTONS --------------------*/}
      <div className="uk-container">
        <div className="gh-booking-details-buttons">
          {bookingDetail.status === PROVISIONAL || bookingDetail.status === CONFIRMED ? (
            <Button
              onClick={() => {
                openModal(cancelBookingModalRef);
              }}
              color="dangerBorder"
              disabled={!isBrokerAllowedToInteract}
            >
              {staticService.findByAlias("cancelBooking")}
            </Button>
          ) : (
            ""
          )}

          {auth.isOperator() &&
          bookingDetail.exists_empty_leg === 0 &&
          !bookingDetail.emptyLeg &&
          bookingDetail.status === CONFIRMED &&
          getLastItemFromArray(bookingDetail.legs).aircrafts.length < 2 ? (
            <Button
              onClick={() => {
                openModal(createEmptyLegFromThisBookingRef);
              }}
            >
              {staticService.findByAlias("createEmptyLeg")}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>

      {/*-------------------- MODALS ---------------------*/}

      {/*CANCEL BOOKING MODAL*/}
      <ModalComponent
        title={staticService.findByAlias("cancelBookingModalTitle")}
        ref={cancelBookingModalRef}
        id="cancelBookingModal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("modalWarning") }}
          className="uk-margin-bottom"
        />

        <form>
          <label>Reason</label>
          <textarea
            className="uk-textarea"
            {...register("message", {
              required: { value: true, message: "Reason is required" },
              minLength: { value: 4, message: "Must be 4 or more characters" },
            })}
          />
          {errors.message && <span className="validation-errors">{errors.message.message}</span>}
        </form>
        <div className="gh-modal-buttons">
          <Button
            variant="outlined"
            color="primaryBorder"
            onClick={() => {
              closeModal(cancelBookingModalRef);
            }}
          >
            {staticService.findByAlias("back")}
          </Button>
          <Button color="success" onClick={handleSubmit(onSubmit)}>
            {staticService.findByAlias("submit")}
          </Button>
        </div>
      </ModalComponent>

      {/*CANCEL BOOKING MODAL INFO*/}
      <ModalComponent
        title={staticService.findByAlias("bookingCancelled")}
        ref={cancelBookingInfoModalRef}
        id="cancelBookingInfoModal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
      >
        <p className="uk-margin-bottom">
          {staticService.findByAlias("bookingCancelledContentModal")}
        </p>
        <div className="uk-flex uk-flex-center">
          <Button variant="outlined" color="primaryBorder" onClick={handleCloseInfoModal}>
            {staticService.findByAlias("close")}
          </Button>
        </div>
      </ModalComponent>

      {/*CREATE EMPTY LEG FROM THIS BOOKING MODAL*/}
      <ModalComponent
        title={staticService.findByAlias("createEmptyLegTitle")}
        ref={createEmptyLegFromThisBookingRef}
        id="createEmptyLegFromThisBooking"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        closeBtnTemplate={
          <span
            className="empty-leg-close"
            onClick={() => closeModal(createEmptyLegFromThisBookingRef)}
          >
            <img src={closeIconDark} alt="closeIconDark" />
          </span>
        }
      >
        <BookingEmptyLegForm
          lastLeg={getLastItemFromArray(bookingDetail.legs)}
          bookingId={bookingDetail.id}
          createEmptyLeg={createEmptyLeg}
          aircrafts={bookingDetail.aircrafts}
        />
      </ModalComponent>
    </div>
  );
};

BookingButtons.propTypes = {
  cancelBooking: PropTypes.func,
  bookingDetail: PropTypes.object,
  addEmptyLeg: PropTypes.func,
  history: PropTypes.object,
  isBrokerAllowedToInteract: PropTypes.bool,
};

export default connect(null, { cancelBooking, addEmptyLeg })(BookingButtons);
