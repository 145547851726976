export const preparePads = (pads) => {
  const uniquePads = [];

  pads.forEach((pad, index) => {
    const uniquePadIndex = uniquePads.findIndex((uniquePad) => {
      return uniquePad.home_base_location.place_id === pad.home_base_location.place_id;
    });

    if (uniquePadIndex !== -1 && uniquePadIndex !== index) {
      uniquePads[uniquePadIndex].group.push(pad);
    } else {
      uniquePads.push({
        ...pad,
        group: [],
      });
    }
  });

  return uniquePads;
};

export const isPadVerified = (pad) => {
  if (pad) {
    return pad.verified && pad?.company?.verified;
  }

  return false;
};
export const isPadAndGroupVerified = (pad) => {
  if (!pad) return false;
  const isCurrentPadVerified = isPadVerified(pad);
  const isPadGroupVerified = pad.group.length ? pad.group.some(isPadVerified) : false;
  return Boolean(isPadGroupVerified || isCurrentPadVerified);
};
