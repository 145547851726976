import React from "react";
// import { BrokerQuoteSlider } from "../../../common/BrokerQuoteSlider";
import UIkit from "uikit";
import staticService from "../../../../services/static.service";
import { AccordionComponent } from "../../../../shared";
import marker from "../../../../assets/img/marker.svg";
import { time } from "../../../../shared";
import moment from "moment";
import { BrokerVettingComponent } from "./broker-vetting.component";
import { BrokerOperatorComments } from "./broker-operator-comments.component";
import envelope from "../../../../assets/img/svg/comment.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/img/svg/download.svg";
import { NavLink } from "react-router-dom";
import { QUOTE_DECLINED } from "../../../../configs";
import { HeliMoreInfoComponent } from "./heli-more-info.component";
import Auth from "../../../../services/auth";
import { generateAccountLocalization } from "../../../../context";

class BrokerQuoteDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.accountLocalization = generateAccountLocalization();
    this.state = {
      selectedHeli: 0,
      activeTab: 0,
      fullOpenAccordion: true,
      heliIndexList: [],
      sliderImageIndex: 0,
      removeIndex: false,
      userCompany: Auth.company,
    };
    this.showPrice = this.showPrice.bind(this);
  }

  componentDidMount() {
    this.sliderTimer = setTimeout(() => {
      UIkit.slider(this.quoteSlider, {
        allowDots: true,
        center: true,
      });
    }, 1000);
    let list = [];
    const { quote } = this.props;
    let sum = 0;
    quote.aircrafts.map((heli, index) => {
      if (index !== 0) {
        list.push({ [index]: [sum + 1, sum + heli.aircraft_images.length] });
        sum += heli.aircraft_images.length;
      } else {
        list.push({ [index]: [heli.aircraft_images.length - 1] });
        sum += heli.aircraft_images.length - 1;
      }
    });
    this.setState({ heliIndexList: list }, () => {
      const { heliIndexList } = this.state;
      UIkit.util.on(this.quoteSlider, "itemshow", (props) => {
        heliIndexList.forEach((el, ix) => {
          const sliderIndex = props.detail[0].index;
          this.setState({ sliderImageIndex: sliderIndex });
          if (el[ix].includes(sliderIndex)) {
            this.setState({ selectedHeli: ix });
          }
        });
      });
    });
  }

  getHeliImageIndex(heli, images) {
    return images && images.findIndex((img) => img.aircraft_id === heli.aircraft_id);
  }
  changeSliderHeli(heliFirstImage, selectedHeli) {
    this.setState({ selectedHeli: selectedHeli });
    UIkit.slider(this.quoteSlider).show(heliFirstImage);
  }

  showPrice(quote) {
    return (
      <span>
        <span>{quote.user_currency.currency} </span>
        {quote.user_price_formatted + " "}
        incl. <span>{quote.user_currency.currency} </span>
        {quote.user_vat_formatted} VAT
      </span>
    );
  }
  componentWillUnmount() {
    clearTimeout(this.sliderTimer);
  }
  getActive(tab) {
    return this.state.activeTab === tab;
  }
  setActiveTab() {
    this.setState({ removeIndex: true });
  }
  quoteLegInfo(quote, leg, index) {
    const { selectedHeli, removeIndex } = this.state;

    const prepareFromPad = (pad, type) => {
      return this.props.showPad(type, pad.from_name, pad.from_coords);
    };

    const prepareToPad = (pad, type) => {
      return this.props.showPad(type, pad.to_name, pad.to_coords);
    };
    if (removeIndex) {
      return {
        title: `LEG ${index + 1}`,
        content: (
          <div data-uk-grid>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("proposedTakeOffSite")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <div className="gh-quote-pad">
                    <img className="gh-quote-pad-marker" src={marker} alt="marker" />
                    <span
                      className="gh-quote-text"
                      onClick={() =>
                        prepareFromPad(leg, staticService.findByAlias("proposedTakeOffSite"))
                      }
                    >
                      {leg.from_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("proposedLandingSite")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <div className="gh-quote-pad">
                    <img className="gh-quote-pad-marker" src={marker} alt="marker" />
                    <span
                      className="gh-quote-text"
                      onClick={() =>
                        prepareToPad(leg, staticService.findByAlias("proposedLandingSite"))
                      }
                    >
                      {leg.to_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("dateOfDeparture")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <span className="gh-quote-text">
                    {moment(leg.departure_time).format("DD MMMM, YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("timeOfDeparture")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <span className="gh-quote-text">
                    {moment(leg.departure_time).format(this.accountLocalization.timeFormat)} (UTC
                    {moment(leg.departure_time).format("Z")})
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("estimateFlightDuration")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <span className="gh-quote-text">{leg.time_max && time(leg.time_max)}</span>
                </div>
              </div>
            </div>
          </div>
        ),
      };
    } else {
      return {
        title: `LEG ${index + 1}`,
        active: this.getActive(index),
        content: (
          <div data-uk-grid>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("proposedTakeOffSite")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <div className="gh-quote-pad">
                    <img className="gh-quote-pad-marker" src={marker} alt="marker" />
                    <span
                      className="gh-quote-text"
                      onClick={() =>
                        prepareFromPad(leg, staticService.findByAlias("proposedTakeOffSite"))
                      }
                    >
                      {leg.from_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("proposedLandingSite")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <div className="gh-quote-pad">
                    <img className="gh-quote-pad-marker" src={marker} alt="marker" />
                    <span
                      className="gh-quote-text"
                      onClick={() =>
                        prepareToPad(leg, staticService.findByAlias("proposedLandingSite"))
                      }
                    >
                      {leg.to_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("dateOfDeparture")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <span className="gh-quote-text">
                    {moment(leg.departure_time).format("DD")}{" "}
                    {moment(leg.departure_time).format("MMMM")},{" "}
                    {moment(leg.departure_time).format("YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("timeOfDeparture")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <span className="gh-quote-text">
                    {moment(leg.departure_time).format(this.accountLocalization.timeFormat)} (UTC
                    {moment(leg.departure_time).format("Z")})
                  </span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-3@s">
                  <span className="gh-quote-label">
                    {staticService.findByAlias("estimateFlightDuration")}
                  </span>
                </div>
                <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
                  <span className="gh-quote-text">{leg.time_max && time(leg.time_max)}</span>
                </div>
              </div>
            </div>
          </div>
        ),
      };
    }
  }

  getAccordionItems(quote, request) {
    const legs = () =>
      quote.pads &&
      quote.pads.map((leg, index) => {
        return this.quoteLegInfo(quote, leg, index);
      });

    if (this.props.screenW > 1199) {
      return [...legs()];
    }
    if (this.props.screenW < 1199) {
      return [
        ...legs(),
        {
          title: staticService.findByAlias("operatorsComments"),
          active: this.getActive(-1),
          content: (
            <BrokerOperatorComments
              quote={quote}
              comments={quote.description ? quote.description : "---"}
            />
          ),
        },
        {
          title: staticService.findByAlias("ghVettingProcess"),
          active: this.getActive(-2),
          content: (
            <BrokerVettingComponent
              quote={quote}
              request={request}
              learnMore={this.props.showVettingMore}
            />
          ),
        },
      ];
    }
  }

  downloadPDF() {
    const { id, quote_aircraft_images, aircrafts } = this.props.quote;
    const { selectedHeli, sliderImageIndex } = this.state;

    return this.props.getPDF({
      quote_id: id,
      aircraft_id: aircrafts[selectedHeli].aircraft_id,
      image_id:
        quote_aircraft_images[sliderImageIndex] && quote_aircraft_images[sliderImageIndex].id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  toggleDialogState(status) {
    this.props.action(status, this.props.quote);
  }

  handleClick(e) {
    const { quote } = this.props;
    if (quote && quote.status === "booking_init") {
      return e.preventDefault();
    }
  }

  get displayDiscussion() {
    const { quote } = this.props;

    if (
      quote &&
      (quote.status === "open" || quote.status === "booking_init" || quote.status === "disabled")
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      quote,
      request,
      screenW,
      onSelectPath,
      openDiscussionModal,
      seenQuote,
      showVettingMore,
    } = this.props;
    const { selectedHeli, removeIndex } = this.state;
    return (
      <div className="gh-full-quote">
        <div data-uk-grid="masonry: true" id="gh-quote-detail-content">
          <div className="uk-width-1-1 uk-width-1-2@m uk-width-2-5@l">
            <div
              className="gh-quote-slider"
              ref={(quoteSlider) => (this.quoteSlider = quoteSlider)}
            >
              {/*<BrokerQuoteSlider*/}
              {/*  detail={true}*/}
              {/*  aircrafts={quote.aircrafts}*/}
              {/*  heliIndex={this.state.selectedHeli}*/}
              {/*  imageIndex={this.state.sliderImageIndex}*/}
              {/*  heliImages={quote.quote_aircraft_images}*/}
              {/*/>*/}
              {/*<BrokerQuoteSlider />*/}
            </div>
            <HeliMoreInfoComponent
              heliKey={this.state.selectedHeli}
              info={quote.aircrafts[this.state.selectedHeli]}
            />
            {quote.aircrafts[this.state.selectedHeli] &&
            quote.aircrafts[this.state.selectedHeli].description ? (
              <p className="gh-text-blue">
                <span
                  key={this.state.selectedHeli}
                  className="gh-animated-description"
                  dangerouslySetInnerHTML={{
                    __html: quote.aircrafts[this.state.selectedHeli].description,
                  }}
                />
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="uk-width-1-1 uk-width-1-2@m uk-width-3-5@l">
            <div className="gh-quote-info">
              <div className="uk-width-1-1">
                <div data-uk-grid>
                  <div className="uk-width-1-3">
                    <span className="gh-quote-label">{staticService.findByAlias("price")}</span>
                  </div>
                  <div className="uk-width-2-3">
                    <span className="gh-quote-text">{this.showPrice(quote)}</span>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 uk-margin-small-top">
                <div data-uk-grid>
                  <div className="uk-width-1-3">
                    <span className="gh-quote-label">
                      {staticService.findByAlias("helicopter")}
                    </span>
                  </div>
                  <div className="uk-width-2-3 gh-margin-one">
                    <span className="gh-quote-text">
                      {quote.aircrafts.length === 1 && (
                        <span>{quote.aircrafts && quote.aircrafts[0].aircraft_model}</span>
                      )}
                      {quote.aircrafts.length !== 1 &&
                        quote.aircrafts.map((heli, heliIndex) => (
                          <span
                            key={heli.aircraft_model}
                            className="gh-heli-label"
                            onClick={() =>
                              this.changeSliderHeli(
                                this.getHeliImageIndex(heli, quote.quote_aircraft_images),
                                heliIndex,
                              )
                            }
                          >
                            {heliIndex === 0 || heliIndex === quote.aircrafts.length ? (
                              ""
                            ) : (
                              <span className="gh-text-green"> + </span>
                            )}
                            <span className={selectedHeli === heliIndex ? "gh-heli-active" : ""}>
                              {heli.aircraft_model}
                            </span>
                          </span>
                        ))}
                    </span>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1">
                <AccordionComponent
                  accordionId="gh-quote-detail-accordion"
                  items={this.getAccordionItems(quote, request)}
                  options={{ collapsible: true, multiple: true }}
                  setActiveTab={this.setActiveTab.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-1-2@m uk-width-2-5@l uk-visible@l gh-margin-small-top">
            <BrokerVettingComponent quote={quote} request={request} learnMore={showVettingMore} />
          </div>
          <div className="uk-width-1-1 uk-width-1-2@m uk-width-3-5@l uk-visible@l">
            <BrokerOperatorComments
              quote={quote}
              comments={quote.description ? quote.description : "---"}
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-margin-medium-top">
          <div data-uk-grid className={screenW < 576 ? "uk-grid-small" : "uk-grid"}>
            <div className="uk-width-1-1 uk-width-2-5@l">
              <div data-uk-grid className={screenW < 576 ? "uk-grid-small uk-flex" : "uk-grid"}>
                {request && request.status !== "pending" && quote.status !== "booking_init" && (
                  <div className="uk-width-1-2">
                    <NavLink
                      onClick={this.handleClick.bind(this)}
                      className={`uk-button uk-button-default gh-quote-button gh-full-quote-btn ${
                        quote.status && quote.status === "booking_init"
                          ? "gh-book-btn-disabled"
                          : "gh-book-btn"
                      }`}
                      to={`${onSelectPath}/payment/${quote.id}/step/2`}
                    >
                      {staticService.findByAlias("bookButton")}
                    </NavLink>
                  </div>
                )}
                {quote && (
                  <div
                    className={
                      request && request.status === "pending"
                        ? "uk-width-1-1"
                        : "uk-width-1-2 pl-15 uk-margin-remove-top"
                    }
                  >
                    <button
                      className="uk-button uk-button-danger gh-decline-btn gh-full-quote-btn"
                      onClick={this.toggleDialogState.bind(this, QUOTE_DECLINED)}
                    >
                      {request && request.status !== "pending"
                        ? staticService.findByAlias("decline")
                        : staticService.findByAlias("withdrawBooking")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-3-5@l gh-second-buttons">
              <div data-uk-grid className={screenW < 576 ? "uk-grid-small uk-flex" : "uk-grid"}>
                {this.displayDiscussion && (
                  <div className="uk-width-1-2 pl-15">
                    <button
                      className="uk-button uk-button-secondary uk-padding-remove gh-discussion-btn gh-full-quote-btn"
                      onClick={openDiscussionModal}
                    >
                      <div className="gh-discussion-btn_envelope">
                        <img className="gh-envelope-icon-broker" src={envelope} alt="envelope" />
                        {!!quote.comments_count && !seenQuote && (
                          <span className="messages-count">{quote.comments_count}</span>
                        )}
                      </div>

                      {!!quote.comments_count && !seenQuote
                        ? staticService.findByAlias("newMessage")
                        : staticService.findByAlias("quoteLiveChat")}
                    </button>
                  </div>
                )}
                <div
                  className={
                    !this.displayDiscussion
                      ? "uk-width-1-1"
                      : "uk-width-1-2 pl-15 uk-margin-remove-top"
                  }
                >
                  <button
                    className="uk-button uk-button-secondary uk-padding-remove gh-discussion-btn gh-full-quote-btn"
                    onClick={this.downloadPDF.bind(this)}
                    {...Auth.getFeatureStatus("quote_to_pdf")}
                  >
                    <svg
                      height="512pt"
                      viewBox="0 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m256 362.667969c-8.832031 0-16-7.167969-16-16v-330.667969c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v330.667969c0 8.832031-7.167969 16-16 16zm0 0" />
                      <path d="m256 362.667969c-4.097656 0-8.191406-1.558594-11.308594-4.695313l-85.332031-85.332031c-6.25-6.25-6.25-16.382813 0-22.636719 6.25-6.25 16.382813-6.25 22.636719 0l74.023437 74.027344 74.027344-74.027344c6.25-6.25 16.386719-6.25 22.636719 0 6.25 6.253906 6.25 16.386719 0 22.636719l-85.335938 85.332031c-3.15625 3.136719-7.25 4.695313-11.347656 4.695313zm0 0" />
                      <path d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 32.363281-26.304688 58.667969-58.667969 58.667969zm0 0" />
                    </svg>
                    {staticService.findByAlias("downloadAsPDF")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { BrokerQuoteDetailComponent };
