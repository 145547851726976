import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { AccountLayout } from "../../layouts";
import { TacComponent } from "../../";
import { config } from "../../../configs";
import { loadTermCondition, download, updateAgreement } from "../../../actions";
import auth from "../../../services/auth";
import staticService from "../../../services/static.service";
import { fireTracking, redirectTo, ModalComponent, unsetBrowserHistory } from "../../../shared";
import jwt_decode from "jwt-decode";

class ReviewAgreementComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      modalVisibility: false,
      redirectPath: config.redirectUserDashboard,
    };
    fireTracking(props.location.pathname);
    this.changeModalVisibility = this.changeModalVisibility.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.redirectToHomePage = this.redirectToHomePage.bind(this);
  }

  changeModalVisibility(state) {
    this.setState({ modalVisibility: state });
  }

  onChangeCondition(state) {
    this.changeModalVisibility(state > 0 ? true : false);
    if (state > 0) {
      this.props.update(false);
    }
  }

  redirectToHomePage() {
    auth.logout();
    unsetBrowserHistory(this.props.history, config.redirectToMainPage);
    window.location.reload();
  }

  componentDidMount() {
    if (this.modal) {
      this.modal.open();
    }
    this.context.store.dispatch(
      loadTermCondition(
        "BROKER_AGREEMENT,OPERATOR_AGREEMENT,BROKER_API_AGREEMENT",
        this.user.banking.country,
      ),
    );
  }

  get user() {
    return auth.getUser();
  }

  get agreementAlias() {
    if (this.user.is_api_terms) {
      return auth.getMainRole().toUpperCase() + "_API_AGREEMENT";
    }
    return auth.getMainRole().toUpperCase() + "_AGREEMENT";
  }

  get agreement() {
    const conditions = this.props.conditions.filter(
      (condition) => condition.type === this.agreementAlias,
    );
    return conditions.length > 0 ? conditions[0].description : "";
  }

  get modalTitle() {
    if (this.user.is_api_terms) {
      return staticService.findByAlias(
        this.user.is_new_api_broker ? "newApiReviewAgreementTitle" : "apiReviewAgreementTitle",
      );
    }
    return staticService.findByAlias("reviewAgreementTitle");
  }

  get modalContent() {
    if (this.user.is_api_terms) {
      return staticService.findByAlias(
        this.user.is_new_api_broker ? "newApiReviewAgreementContent" : "apiReviewAgreementContent",
      );
    }
    return staticService.findByAlias("reviewAgreementContent");
  }

  render() {
    if (auth.activeSoleTrader === 0 || auth.acceptance || auth.apiAcceptance) {
      return (
        <AccountLayout
          activeRoute={this.props.match}
          navVisibility={false}
          pageTitle={this.props.title}
        >
          <section className={"review-agreement-page"}>
            <ModalComponent
              title={
                auth.activeSoleTrader === 0
                  ? staticService.findByAlias("soleTraderAgreementModalTitle")
                  : this.modalTitle
              }
              options={{ clsPage: "", bgClose: false, escClose: false }}
              id={"fleet-modal"}
              ref={(modal) => (this.modal = modal)}
            >
              {auth.activeSoleTrader === 0 ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("soleTraderAgreementModalText"),
                  }}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: this.modalContent }} />
              )}

              {auth.activeSoleTrader === 0 ? (
                <div className="uk-flex uk-flex-center review-btns">
                  <div className="review-btn">
                    <button
                      className="uk-button uk-button-primary"
                      onClick={this.redirectToHomePage}
                    >
                      {staticService.findByAlias("redirectToHomePage")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="uk-flex uk-flex-center review-btns">
                  <div className="uk-margin-right review-btn">
                    <button
                      className={"uk-button uk-button-default"}
                      onClick={this.changeModalVisibility.bind(this, true)}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("ViewTermsLabel"),
                      }}
                    />
                  </div>
                  <div className="uk-margin-right review-btn">
                    <NavLink
                      className={"uk-button uk-button-default"}
                      to={config.redirectToLogout}
                      dangerouslySetInnerHTML={{ __html: staticService.findByAlias("reject") }}
                    />
                  </div>
                  <div className="uk-margin-right review-btn">
                    <button
                      className={"uk-button uk-button-primary"}
                      onClick={this.props.update.bind(this, false)}
                      dangerouslySetInnerHTML={{ __html: staticService.findByAlias("AcceptLabel") }}
                    />
                  </div>
                </div>
              )}
            </ModalComponent>

            <TacComponent
              html={this.agreement}
              agreeCallback={this.onChangeCondition}
              cancelLabel={"reject"}
              acceptLabel={"AcceptLabel"}
              downloadCallback={this.props.download.bind(this, this.agreementAlias)}
              visible={this.state.modalVisibility}
            />
          </section>
        </AccountLayout>
      );
    }
    return redirectTo(this.state.redirectPath);
  }
}

ReviewAgreementComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ auth, termsCondition }) {
  const response = { auth, conditions: termsCondition };
  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      download,
      update: updateAgreement,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ReviewAgreementComponent);
export { COMPONENT as ReviewAgreementComponent };
