import React, { forwardRef } from "react";
import "./SliderWithChildren.css";
import Slider from "../Slider";
import PropTypes from "prop-types";

const SliderWithChildren = (props, ref) => {
  const { children = null, className = "", options = {}, autoPlay } = props;

  return (
    <Slider className={className} ref={ref} options={options} autoPlay={autoPlay}>
      {children}
    </Slider>
  );
};

SliderWithChildren.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  options: PropTypes.object,
  autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

SliderWithChildren.displayName = "SliderWithChildren";

export default forwardRef(SliderWithChildren);
