import React from "react";

const Footer = ({ children, attrProps }) => {
  return (
    <footer style={{ position: "relative", zIndex: 0 }} {...attrProps}>
      {children}
    </footer>
  );
};

export { Footer };
