import {
  AboutComponent,
  AccountComponent,
  BookDemo,
  DestinationsList,
  DispatcherComponent,
  EventListComponent,
  ForbiddenComponent,
  GrantAccessComponent,
  LoginComponent,
  LogoutComponent,
  NotFoundComponent,
  OperatorAocComponent,
  OperatorBookingQuoteListComponent,
  Privacy,
  RecoverPasswordComponent,
  RecoverPasswordHashComponent,
  ReduxForbiddenComponent,
  RegisterComponent,
  RegisterInvitationComponent,
  ReviewAgreementComponent,
} from "../components/pages";
import { DestinationInformationComponent, EventInformationComponent } from "../components/common";
import {
  isBrokerAdminOrOperatorAdminRole,
  isBrokerRole,
  isGuest,
  isOnline,
  isOperatorRole,
} from "../components/";
import { config } from "./";
import { BrokerManageCardsComponent, RedirectComponent } from "../components";
import { ChangePasswordFormComponent } from "../components/forms/profile/change-password-form.component";
import { NotificationsSettingsFormComponent } from "../components/forms/profile/notifications-settings-form.component";
import {
  AgreementPageComponent,
  AocDocumentsRoom,
  PaymentsListComponent,
} from "../components/pages/user-online/common";
import { TeamComponent } from "../components/pages/user-online/operator/team.component";
import { TeamMembersComponent } from "../components/pages/user-online/team-components/members/team-members.component";
import { AccountFleetLayoutComponent } from "../components/pages/user-online/team-components/fleet/account-fleet-layout.component";
import { AccountFleetPlatformLayoutComponent } from "../components/pages/user-online/team-components/platform_fleet";
import { BankAccountComponent } from "../components/pages/user-online/operator/bank-account.component";
import { InviteComponent } from "../components/pages/user-online/team-components/invite_broker/";
import ExternalEquiryForm from "../components/pages/ExternalEnquiryForm";
import OperatorQuoteTripComponent from "../components/pages/user-online/operator/operator-quote-trip.component";
import RequestDetails from "../components/pages/RequestDetails";
import QuoteForm from "../components/pages/QuoteForm";
import QuoteFormCalculator from "../components/pages/QuoteFormCalculator";
import ViewFullQuoteDetails from "../components/pages/ViewFullQuoteDetails";
import ReviewQuote from "../components/pages/PreviewQuote";
import QuoteDetails from "../components/pages/QuoteDetails";
import { BrokerPricingPlanComponent } from "../components/pages/user-online/broker/broker-pricing-plan.component";
import ExternalAppSettings from "../components/pages/user-online/broker/ExternalAppSettings";
import PricingComponent from "../components/pages/pricing/pricing.component";
import ClientsPage from "../components/pages/user-online/broker/ClientsPage";
import NewAuth from "../components/pages/NewAuth";
import OperatorPriceCalculator from "../components/pages/user-online/operator/OperatorPriceCalculator";
import IframeBrokerBrochure from "../components/brochures/IframeBrokerBrochure";
import IframeOperatorBrochure from "../components/brochures/IframeOperatorBrochure/IframeOperatorBrochure";
import TagsList from "../components/pages/TagsList";
import TermsConditions from "../components/TermsConditions/TermsConditions";
import HomeComponent from "../components/pages/HomeComponent";

const notAuthorized = config.messages.notAuthorizedRoute;

export const routes = [
  {
    path: "/",
    component: HomeComponent,
    exact: true,
    title: "Home",
  },
  {
    path: "/iframebroker",
    component: IframeBrokerBrochure,
    exact: true,
    title: "Home",
  },
  {
    path: "/getheli-terms",
    component: TermsConditions,
    exact: true,
    title: "Home",
  },
  {
    path: "/iframeoperator",
    component: IframeOperatorBrochure,
    exact: true,
    title: "Home",
  },
  {
    path: "/new-url/:token",
    component: NewAuth,
    exact: true,
    title: "Home",
  },
  {
    path: "/events",
    component: EventListComponent,
    title: " Events",
    routes: [
      {
        path: "/:url",
        title: "Events-Information",
        component: EventInformationComponent,
      },
    ],
  },
  {
    path: "/destinations",
    component: DestinationsList,
    title: " Destinations",
    routes: [
      {
        path: "/:url",
        title: "Destination-Information",
        component: DestinationInformationComponent,
      },
    ],
  },
  {
    path: "/helicopterflights/:id",
    component: TagsList,
    title: " Tags",
  },
  {
    path: "/enquiry-form",
    component: ExternalEquiryForm,
    title: "External Enquiry Form",
  },
  {
    path: "/get-a-demo",
    component: BookDemo,
    title: "Book A Demo",
  },
  {
    path: "/aoc-documents/:id",
    component: AocDocumentsRoom,
    title: "Aoc_Documents",
  },
  {
    path: "/documents/:type/:id",
    component: AocDocumentsRoom,
    title: "Aoc_Documents_Quote",
  },
  {
    path: "/contact",
    component: AboutComponent,
    title: "Contact",
  },

  {
    path: "/clients",
    component: isBrokerRole(ClientsPage, notAuthorized, true),
    title: "Clients",
  },
  {
    path: "/pricing",
    component: PricingComponent,
    title: "Pricing",
  },
  {
    path: "/price-calculator",
    component: isOperatorRole(OperatorPriceCalculator, notAuthorized, true),
    title: "Clients",
  },
  {
    path: "/privacy",
    component: Privacy,
    title: "Privacy",
  },
  {
    path: "/review-agreement",
    component: isOnline(ReviewAgreementComponent, notAuthorized, true),
    title: "Review agreement",
  },
  {
    path: "/account",
    component: AccountComponent,
    title: "Account",
    routes: [
      {
        path: "/register",
        title: "Sign Up",
        component: isGuest(RegisterComponent),
      },
      {
        path: "/invitation/:hash",
        title: "Sign Up by invitation",
        component: isGuest(RegisterInvitationComponent),
      },
      {
        path: "/agreements",
        component: isOnline(DispatcherComponent("ProfileComponent"), notAuthorized, true, {
          validateSegment: true,
          segment: "account",
        }),
        title: "Terms & Conditions",
        routes: [
          {
            path: "",
            component: isOnline(AgreementPageComponent, notAuthorized, true),
            title: "Terms & Conditions",
          },
        ],
      },
      {
        path: "/information",
        component: isOnline(TeamComponent, notAuthorized, true, {
          validateSegment: true,
          segment: "account",
        }),
        title: "Team management",

        routes: [
          {
            path: "/refer",
            component: isOperatorRole(InviteComponent, notAuthorized, true),
            title: "Broker invitation",
          },
          {
            path: "/preferred",
            component: isBrokerRole(
              DispatcherComponent("FavouritesComponent", true),
              notAuthorized,
              true,
              {
                validateId: false,
              },
            ),
            title: "Preferred Operators",
          },
          {
            path: "/team",
            component: isOnline(TeamMembersComponent, notAuthorized, true),
            title: "Manage team",
          },
        ],
      },
      {
        path: "/aoc",
        component: isOperatorRole(OperatorAocComponent, notAuthorized, true, {
          validateSegment: true,
          segment: "account",
        }),
        title: "AOC",
      },
      {
        path: "/fleet",
        component: isOperatorRole(AccountFleetLayoutComponent, notAuthorized, true),
        title: "Fleet",
      },
      {
        path: "/platform-fleet",
        component: isOperatorRole(AccountFleetPlatformLayoutComponent, notAuthorized, true),
        title: "Platform Fleet",
      },
      {
        path: "/price-calculator",
        component: isBrokerRole(ClientsPage, notAuthorized, true),
        title: "Clients",
      },
      {
        path: "/pricing-plan",
        component: isBrokerRole(BrokerPricingPlanComponent, notAuthorized, true),
        title: "Pricing Plan",
      },
      {
        path: "/widget-configuration",
        component: isBrokerRole(ExternalAppSettings, notAuthorized, true),
        title: "External app settings",
      },
      {
        path: "/banking",
        component: isBrokerAdminOrOperatorAdminRole(
          DispatcherComponent("BankingComponent", true),
          notAuthorized,
          true,
          {
            redirectTo: "/account/information",
          },
        ),
        title: "Bank account details",
        routes: [
          {
            path: "/cards",
            component: isBrokerRole(BrokerManageCardsComponent, notAuthorized, true, {
              redirectTo: "/account/information",
            }),
            title: "Manage Company Cards",
          },
          {
            path: "/account",
            component: isOperatorRole(BankAccountComponent, notAuthorized, true),
            title: "Bank account details",
          },
          {
            path: "/history",
            component: isOnline(PaymentsListComponent, notAuthorized, true, {
              redirectTo: "/account/information",
            }),
            title: "Transaction history",
          },
        ],
      },
      {
        path: "/profile",
        component: isOnline(DispatcherComponent("ProfileComponent"), notAuthorized, true, {
          validateSegment: true,
          segment: "profile",
        }),
        title: "Profile",
        routes: [
          {
            path: "/notifications",
            component: isOnline(NotificationsSettingsFormComponent, notAuthorized, true),
            title: "Notifications",
          },
          {
            path: "/change_password",
            component: isOnline(ChangePasswordFormComponent, notAuthorized, true),
            title: "Change password",
          },
        ],
      },
      {
        path: "/password/forgotten",
        title: "Recover password",
        component: isGuest(RecoverPasswordComponent),
      },

      {
        path: "/request/access",
        title: "Request access",
        component: isGuest(GrantAccessComponent),
      },

      {
        path: "/password/recovery/:hash",
        title: "Recover password",
        component: isGuest(RecoverPasswordHashComponent),
      },

      {
        path: "/login",
        component: isGuest(LoginComponent),
        title: "Sign In",
      },
      {
        path: "/logout",
        component: isOnline(LogoutComponent, notAuthorized),
        title: "Sign Out",
      },
      {
        path: "*",
        component: RedirectComponent,
        title: "Not Found",
      },
    ],
  },
  {
    path: "/marketplace",
    component: isOnline(DispatcherComponent("MarketPlaceComponent"), notAuthorized, true),
    title: "Marketplace",
    routes: [
      {
        path: "/pipelines",
        component: isBrokerRole(
          DispatcherComponent("PipelinesComponent", true),
          notAuthorized,
          true,
        ),
        title: "Pipelines",
      },
      {
        path: "/requests",
        component: isOnline(
          DispatcherComponent("MarketPlaceRequestsComponent", true),
          notAuthorized,
          true,
        ),
        title: "Requests",
        routes: [
          {
            path: "/:id",
            component: isOperatorRole(
              DispatcherComponent("RequestDetailComponent", true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Request detail",
          },
        ],
      },
      {
        path: "/trip",
        component: isOperatorRole(OperatorQuoteTripComponent, notAuthorized, false),
        title: "Quote for Trip",
        routes: [
          {
            path: "/request/:quoteID",
            component: isOperatorRole(RequestDetails, notAuthorized, true),
            title: "Request Details",
            tabName: "request",
          },
          {
            path: "/quote/:quoteID",
            component: isOperatorRole(QuoteForm, notAuthorized, true),
            title: "Quote Form",
            tabName: "quote",
          },
          {
            path: "/calculator/:quoteID",
            component: isOperatorRole(QuoteFormCalculator, notAuthorized, true),
            title: "Price Calculator",
            tabName: "calculator",
          },
        ],
      },
      {
        path: "/preview-quote/:quoteID",
        component: isOperatorRole(ReviewQuote, notAuthorized, true),
        title: "Review Quote",
      },

      {
        path: "/activity",
        component: isOperatorRole(
          DispatcherComponent("MarketPlaceActivityComponent", true),
          notAuthorized,
          true,
        ),
        title: "Market Place activity",
      },
      {
        path: "/empty-legs",
        component: isOnline(
          DispatcherComponent("MarketPlaceEmptyLegsComponent", true),
          notAuthorized,
          true,
        ),
        title: "Empty legs",
        routes: [
          {
            path: "/:id",
            component: isOnline(
              DispatcherComponent("EmptyLegDetailsComponent", true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Empty Leg",
          },
        ],
      },
      {
        path: "/:wrong",
        component: isOnline(
          DispatcherComponent("MarketPlaceActivityComponent", true),
          notAuthorized,
          true,
        ),
        title: "Market Place activity",
      },
    ],
  },

  {
    path: "/flights/requests/filter/:search",
    component: isOnline(DispatcherComponent("BookingRequestsComponent"), notAuthorized, true),
    title: "My Requests",
    routes: [
      {
        path: "/:id",
        component: isBrokerRole(
          DispatcherComponent("BookingRequestComponent", true),
          notAuthorized,
          true,
          {
            validateId: true,
          },
        ),
        title: "Quote request detail",
        routes: [
          {
            path: "/payment/:quoteID",
            component: isBrokerRole(
              DispatcherComponent("PaymentComponent", true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Payment Summary page",
            routes: [
              {
                path: "/step/2",
                component: isBrokerRole(
                  DispatcherComponent("PaymentTermConditionReviewComponent", true),
                  notAuthorized,
                  true,
                ),
                title: "Payment page",
              },
              {
                path: "/step/:id",
                component: isBrokerRole(
                  DispatcherComponent("PaymentBillingComponent", true),
                  notAuthorized,
                  true,
                  {
                    validateId: true,
                  },
                ),
                title: "Payment page",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/flights",
    component: isOnline(DispatcherComponent("BookingComponent"), notAuthorized, true),
    title: "Manage",
    routes: [
      {
        path: "/requests",
        component: isOnline(
          DispatcherComponent("BookingRequestsComponent", true),
          notAuthorized,
          true,
        ),
        title: "My Requests",
        routes: [
          {
            path: "/:id",
            component: isBrokerRole(
              DispatcherComponent("BookingRequestComponent", true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Quote request detail",
            routes: [
              {
                path: "/payment/:quoteID",
                component: isBrokerRole(
                  DispatcherComponent("PaymentComponent", true),
                  notAuthorized,
                  true,
                  {
                    validateId: true,
                  },
                ),
                title: "Payment Summary page",
                routes: [
                  {
                    path: "/step/2",
                    component: isBrokerRole(
                      DispatcherComponent("PaymentTermConditionReviewComponent", true),
                      notAuthorized,
                      true,
                    ),
                    title: "Payment page",
                  },
                  {
                    path: "/step/:id",
                    component: isBrokerRole(
                      DispatcherComponent("PaymentBillingComponent", true),
                      notAuthorized,
                      true,
                      {
                        validateId: true,
                      },
                    ),
                    title: "Payment page",
                  },
                ],
              },
              {
                path: "/quote/:quoteID",
                component: isBrokerRole(ViewFullQuoteDetails, notAuthorized, true),
                title: "Quote Details",
              },
            ],
          },
        ],
      },
      {
        path: "/quotes/filter/:search",
        component: isOnline(
          DispatcherComponent("BookingQuotesComponent", true),
          notAuthorized,
          true,
        ),
        title: "My quotes",
        routes: [
          {
            //hardcoded only for all tab in component OperatorBookingQuotesFiltersComponent line 143(const url)
            path: "/request/:requestID",
            component: isOnline(OperatorBookingQuoteListComponent, notAuthorized, true),
            title: "Quote detail",
          },

          {
            path: "/:id",
            component: isOperatorRole(QuoteDetails, notAuthorized, true),
            title: "Quote detail",
          },
        ],
      },
      {
        path: "/quotes/:id",
        // component: isOperatorRole(DispatcherComponent("BookingQuotesComponent", true), notAuthorized, true),
        component: isOperatorRole(QuoteDetails, notAuthorized, true),
        title: "My quotes",
        // routes: [
        //   {
        //     path: "/:id",
        //     // component: isOperatorRole(DispatcherComponent("BookingQuoteDetailComponent", true), notAuthorized, true),
        //     title: "Quote detail",
        //   },
        // ],
      },
      {
        path: "/my-bookings/filter/:search",
        component: isOnline(
          DispatcherComponent("BookingGenericComponent", true, true),
          notAuthorized,
          true,
        ),
        title: "My history bookings",
        routes: [
          {
            path: "/:id",
            component: isOnline(
              DispatcherComponent("BookingShowComponent", true, true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Booking Details",
          },
        ],
      },
      {
        path: "/my-bookings",
        component: isOnline(
          DispatcherComponent("BookingGenericComponent", true, true),
          notAuthorized,
          true,
        ),
        title: "My bookings",
        routes: [
          {
            path: "/:id",
            component: isOnline(
              DispatcherComponent("BookingShowComponent", true, true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Booking Details",
          },
        ],
      },
      {
        path: "/empty-legs/filter/:search",
        component: isOperatorRole(
          DispatcherComponent("BookingEmptyLegsComponent", true),
          notAuthorized,
          true,
        ),
        title: "My empty legs",
        routes: [
          {
            path: "/:id",
            component: isOperatorRole(
              DispatcherComponent("EmptyLegDetailComponent", true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Empty Leg Details",
          },
        ],
      },
      {
        path: "/empty-legs",
        component: isOperatorRole(
          DispatcherComponent("BookingEmptyLegsComponent", true),
          notAuthorized,
          true,
        ),
        title: "My empty legs",
        routes: [
          {
            path: "/:id",
            component: isOperatorRole(
              DispatcherComponent("EmptyLegDetailComponent", true),
              notAuthorized,
              true,
              {
                validateId: true,
              },
            ),
            title: "Empty Leg Details",
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    component: isOnline(DispatcherComponent("DashBoardComponent"), notAuthorized, true, {
      validateSegment: true,
      segment: 2,
    }),
    title: "Dashboard",
  },
  {
    path: "/new-request/:id",
    component: isBrokerRole(DispatcherComponent("NewRequestComponent"), notAuthorized, true),
    title: "New request",
  },
  {
    path: "/new-request",
    component: isBrokerRole(DispatcherComponent("NewRequestComponent"), notAuthorized, true, {
      validateSegment: true,
      segment: 2,
    }),
    title: "New request",
  },
  {
    path: "/request-review",
    component: isBrokerRole(DispatcherComponent("ReviewRequestComponent"), notAuthorized, true, {
      validateSegment: true,
      segment: 2,
    }),
    title: "New request",
    showHeliSuggestions: true,
    isCreateRequestForm: true,
  },
  {
    path: "/requests",
    component: isBrokerRole(DispatcherComponent("BookingRequestsComponent"), notAuthorized, true, {
      validateSegment: true,
      segment: 2,
    }),
    title: "New request",
  },
  {
    path: "/new-quote/:requestId",
    component: isOperatorRole(DispatcherComponent("NewQuoteComponent"), notAuthorized, true),
    title: "New Quote",
  },
  {
    path: "/notifications",
    component: isOnline(
      DispatcherComponent("NotificationsComponent", false, true),
      notAuthorized,
      true,
      {
        validateSegment: true,
        segment: 2,
      },
    ),
    title: "Notifications",
  },
  {
    path: "/not-found",
    component: NotFoundComponent,
    title: "Not Found",
  },
  {
    path: "/forbidden",
    component: ForbiddenComponent,
    title: "Forbidden",
  },
  {
    path: "/restricted",
    component: ReduxForbiddenComponent,
    title: "Grant access forbidden",
  },
  {
    path: "*",
    component: RedirectComponent,
    title: "Not Found",
  },
];
