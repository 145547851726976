import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Form } from "../../Form";
import { useForm } from "react-hook-form";
import Radio from "../../Form/components/RadioButton";
import Textarea from "../../Form/components/Textarea";
import Button from "../Button";
import useYupResolver from "../../../hooks/useYupResolver";

import "./reasonComponent.css";

/**
 *  required reasons format
 * const reasons = [{ label: "label", value: "value" }
 */

// eslint-disable-next-line react/display-name
const ReasonComponent = forwardRef((props, ref) => {
  const { reasons, handleSubmit, isLoading } = props;
  const [showTextInput, setShowTextInput] = useState(false);
  let otherReason = useMemo(() => reasons.at(-1)?.value, [reasons]);

  const resetForm = () => {
    form.reset();
    setShowTextInput(false);
  };

  useImperativeHandle(ref, () => ({
    resetForm,
  }));

  const reasonSchema = yup.object().shape({
    reasons: yup.string().required("Reason is required"),
    other_reason: yup.string().when("reasons", {
      is: otherReason,
      then: yup
        .string()
        .required("Reason is required")
        .min(4, "Must be 4 or more characters!")
        .max(250, "Must be 250 or fewer characters!"),
    }),
  });

  const form = useForm({
    resolver: useYupResolver(reasonSchema),
  });

  React.useEffect(() => {
    const subscription = form.watch(({ reasons }, { name }) => {
      if (name === "reasons" && reasons === otherReason) {
        setShowTextInput(true);
      } else if (name === "reasons" && reasons !== otherReason) {
        form.unregister("other_reason");
        setShowTextInput(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch, otherReason]);

  const handleSubmitForm = (value) => {
    handleSubmit(value.other_reason || value.reasons);
  };

  return (
    <Form form={form}>
      <Form.Item className="gh-reasons-list" name="reasons">
        <Radio values={reasons} />
      </Form.Item>

      {showTextInput ? (
        <Form.Item className="uk-small-margin-bottom" name="other_reason">
          <Textarea placeholder="Other reason" />
        </Form.Item>
      ) : (
        ""
      )}

      <div className="uk-flex uk-flex-center">
        <Button loading={isLoading} onClick={form.handleSubmit(handleSubmitForm)}>
          Submit
        </Button>
      </div>
    </Form>
  );
});

ReasonComponent.propTypes = {
  reasons: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ReasonComponent;
