import React from "react";
import PropTypes from "prop-types";
import cs from "../../../../utils/condString";

import "./index.css";

export const Input = React.forwardRef((props, ref) => {
  const {
    name,
    onChange,
    value,
    onBlur,
    readOnly,
    onConfirm,
    placeholder,
    onFocus,
    invalid,
    onReset,
  } = props;
  const [focused, setFocused] = React.useState(false);

  const focusHandler = (e) => {
    if (onFocus) onFocus(e);
    setFocused(true);
  };

  const blurHandler = (e) => {
    if (onBlur) onBlur(e);
    if (onConfirm) onConfirm(e);

    setFocused(false);
  };

  return (
    <div className="calculator__input">
      <input
        className={cs("pc-input", invalid && "pc-invalid")}
        ref={ref}
        id={name}
        name={name}
        value={value || ""}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={blurHandler}
        onFocus={focusHandler}
        readOnly={readOnly}
        autoComplete="new-password"
        type="text"
      />
      {onReset && !focused && (
        <button
          type="button"
          tabIndex="-1"
          className="calculator-reset-btn calculator__input-btn reset"
          onClick={onReset}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clipRule="evenodd"
            />
          </svg>
          <span>Reset</span>
        </button>
      )}
    </div>
  );
});

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  onConfirm: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invalid: PropTypes.bool,
  onReset: PropTypes.func,
};

Input.defaultProps = {
  value: "",
};

Input.displayName = "Input";
