import React from "react";
import PropTypes from "prop-types";

class InlineNumberComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0,
    };
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  get label() {
    if (this.props.label) {
      let cssClass = [];
      if (this.props.required) {
        if (cssClass.indexOf("required") === -1) {
          cssClass.push("required");
        }
      }
      cssClass = cssClass.concat(this.props.labelClassName);
      return (
        <label className={cssClass.join(" ") || "uk-form-label uk-margin-remove"}>
          {this.props.label}
        </label>
      );
    }
    return null;
  }

  increment() {
    if (this.state.value < this.props.max) {
      this.setState((prevState) => {
        return {
          value: parseInt(prevState.value) + this.props.step,
        };
      });
      this.props.onChange(parseInt(this.state.value) + 1, this.props.name);
    }
  }

  decrement() {
    if (this.props.min < this.state.value) {
      this.setState((prevState) => {
        return {
          value: parseInt(prevState.value) - this.props.step,
        };
      });
      this.props.onChange(parseInt(this.state.value) - 1, this.props.name);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    const { disabled } = this.props;

    return (
      <div className="uk-flex uk-flex-between uk-flex-middle">
        {this.label}
        <div className="uk-form-controls">
          <div className="uk-width-auto uk-flex uk-flex-center uk-padding-remove">
            <span
              onClick={() => {
                if (disabled) return;
                this.decrement();
              }}
              className="uk-padding-remove gh-number-btn btn-style"
            >
              <span className="gh-counter-icon">-</span>
            </span>
            <span className="gh-inline-counter">{this.state.value}</span>
            <span
              onClick={() => {
                if (disabled) return;
                this.increment();
              }}
              className="uk-padding-remove gh-number-btn btn-style"
            >
              <span className="gh-counter-icon">+</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

InlineNumberComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.array,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number,
  disabled: PropTypes.bool,
};

InlineNumberComponent.defaultProps = {
  label: "",
  labelClassName: [],
  required: false,
  value: "",
  step: 1,
};

export { InlineNumberComponent };
