import React from "react";
import { Route, Switch } from "react-router-dom";
import { BlockComponent } from "../../../common";
import { TabComponent, returnRouteUrl, RouteWithSubRoutes } from "../../../../shared";
import { accountTabs } from "../../../../configs";
import { EditProfileFormComponent } from "../../../forms";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class UserProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    const items = accountTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      selectedTab: items[0],
      tabs: items,
    };
  }

  onTabClick(tab) {
    this.setState({ selectedTab: tab });
  }

  get requestBtn() {
    return "";
  }

  render() {
    return (
      <section className={"profile-page"}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-bottom header-title",
          }}
        >
          <div className="uk-width-1-1">
            <h3
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("account"),
              }}
            />
          </div>
          {this.requestBtn}
        </BlockComponent>
        <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
          <TabComponent
            items={this.state.tabs}
            id={"profile-tab"}
            visible={true}
            tabProps={{ className: "uk-tab uk-margin" }}
            onClick={this.onTabClick.bind(this)}
            selected={this.state.selectedTab}
            linkType={"nav"}
          />
          <Switch>
            {this.props.routes &&
              this.props.routes.map((route, i) => {
                return (
                  <RouteWithSubRoutes
                    key={i}
                    title={route.title}
                    {...returnRouteUrl(route, this.props.match.url)}
                  />
                );
              })}
            <Route path="/" component={EditProfileFormComponent} />
          </Switch>
        </BlockComponent>
      </section>
    );
  }
}

export { UserProfileComponent };
