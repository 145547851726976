export const appTypesOptions = [
  { label: "Type Iframe", value: "type-iframe" },
  { label: "Type Widget", value: "type-widget" },
];

export const appFontsOptions = [
  { label: "Rubik, sans-serif", value: "rubik" },
  { label: "Roboto", value: "roboto" },
];

export const appFontSizeOptions = (startCount = 10, endCount = 40, prefix) => {
  let data = [];

  for (let i = startCount; i <= endCount; i++) {
    data.push({ label: `${i}${prefix ? prefix : ""}`, value: `${i}${prefix ? prefix : ""}` });
  }

  return data;
};

export const colorList = [
  { color: "#10B997" },
  { color: "#242E41" },
  { color: "#6D7480" },
  { color: "#D6D6D5" },
  { color: "#005BFF" },
  { color: "#FFAB00" },
  { color: "#E74C3C" },
  { color: "#E7F2EF" },
  { color: "#212121" },
  { color: "#CD551A" },
];

export const typeOfRules = {
  includes: "Matches",
  no_includes: "Does not match",
  exact: "Exact",
};
