import React from "react";
import auth from "../../../../../services/auth";
import Button from "../../../../common/Button";
import { RequestDetailsFooterContext } from "./RequestDetailsFooter";
import { showNotification } from "../../../../../utils/showNotification";
import staticService from "../../../../../services/static.service";

export default function RequestStartAction() {
  const {
    originalQuoteDetail,
    originalQuoteDetail: { booking_request },
    editingQuoteDetail,
    setShowPrompt,
    history,
    startQuote,
    setQuoteDetailNew,
  } = React.useContext(RequestDetailsFooterContext);

  const [loading, setLoading] = React.useState(false);

  const handleStartQuote = () => {
    setShowPrompt(false);
    setLoading(true);
    setTimeout(() => {
      if (editingQuoteDetail.quote) {
        return history.push({
          pathname: history.location.pathname.replace("request", "quote"),
          search: history.location.search,
          state: history.location.state,
        });
      }

      startQuote(booking_request.id)
        .then((res) => {
          setQuoteDetailNew(res);

          setLoading(false);

          history.push({
            pathname: history.location.pathname.replace("request", "quote"),
            search: history.location.search,
            state: history.location.state,
          });
          return res;
        })
        .catch((err) => {
          const { data } = err;

          if (data?.message) {
            showNotification(
              data.message === "not_allowed" ? "isRequestNotAllowed" : data.message,
              "danger",
            );
          }
          setLoading(false);
        });
    }, 1000);
  };

  return (
    <Button
      color="success"
      onClick={handleStartQuote}
      loading={loading}
      disabled={!auth.isVerified()}
      data-uk-tooltip={(!auth.isVerified() && "Your account is not verified") || ""}
    >
      {originalQuoteDetail.quotes_per_company
        ? "Start New Quote"
        : staticService.findByAlias("startQuoteButton")}
    </Button>
  );
}
