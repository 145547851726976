import React from "react";
import PropTypes from "prop-types";
import { dateFormat, legTitle, renderValues } from "../../../../../shared";
import ImageSlider from "../../../../common/ImageSlider";
import staticService from "../../../../../services/static.service";
import Tooltip from "../../../../common/Tooltip";
import flagIcon from "../../../../../assets/img/svg/flag.svg";
import { ownerApprovalOptions } from "../../../../../configs";

const AircraftDetails = (props) => {
  const { flight_type, selectedPadAndAircraft, sliderImages, pads, currentAircraft } = props;

  const {
    aircraft_model = "---",
    year_manufacture = "---",
    manufacturer = "---",
    engine_type = "---",
    range = "---",
    cruise_speed,
    date_last_refurbished = "---",
    payload = "---",
    description = "",
    max_passengers,
    is_engine_type_edited,
    owner_approval,
  } = currentAircraft || {};

  const isPayloadLocked = React.useMemo(
    () => pads[selectedPadAndAircraft[0]] && pads[selectedPadAndAircraft[0]].is_payload_locked,
    [pads, selectedPadAndAircraft],
  );

  /*===========keys and values for rendering===========*/
  const aircraftDetails = [
    { alias: "manufacturer", value: manufacturer },
    {
      alias: "engineType",
      value:
        renderValues(engine_type) === "---"
          ? engine_type
          : engine_type[0].toUpperCase() + engine_type.slice(1, engine_type.length),
      showTooltip: is_engine_type_edited,
    },
    { alias: "yom", value: year_manufacture },
    {
      alias: "refurbishmentDate",
      value:
        renderValues(date_last_refurbished) === "---"
          ? date_last_refurbished
          : dateFormat(date_last_refurbished, "YYYY-MM-DD", "MMMM, YYYY"),
    },
    { alias: "maxPassengers", value: max_passengers },
    {
      alias: "speed",
      value: renderValues(cruise_speed) === "---" ? cruise_speed : `${cruise_speed} knots`,
    },
    { alias: "range", value: renderValues(range) === "---" ? range : `${range} NM` },
    { alias: "payload", value: renderValues(payload) === "---" ? payload : `${payload} kg` },
    {
      alias: "ownerApprovalLabel",
      value:
        typeof owner_approval === "number"
          ? staticService.findByAlias(
              ownerApprovalOptions.find((option) => option.id === owner_approval).value,
            )
          : "Not specified",
    },
  ];

  return (
    <section className="gh-helicopters-card gh-quote-details-section-card">
      <h4 className="gh-quote-details-title gh-quote-details-first-title gh-quote-details-helicopters-first-title">
        {legTitle(flight_type, selectedPadAndAircraft[0])}
      </h4>
      <h4 className="gh-quote-details-title gh-quote-details-title-bold gh-quote-title-big">
        {manufacturer} {aircraft_model}
      </h4>
      <div className="gh-carousel-wrapper">
        <ImageSlider imagesUrl={sliderImages.map((el) => el.file_url)} />
      </div>

      {sliderImages[0] && sliderImages[0].is_operator_image ? (
        <div className="gh-description-small-gray">
          {staticService.findByAlias("isOperatorImage")}
        </div>
      ) : (
        <div className="gh-description-small-gray">
          {staticService.findByAlias("isNotOperatorImage")}
        </div>
      )}

      <div className="gh-aircraft-details-wrapper">
        <div className="gh-details-box gh-helicopter-details-box">
          {aircraftDetails.map(
            ({ alias, value, hide, showTooltip }, index) =>
              value &&
              !hide && (
                <div className="gh-quote-details-details-row" key={index}>
                  <div className="gh-quote-details-text-gray gh-quote-details-value">
                    {staticService.findByAlias(alias)}
                    {alias === "engineType" && showTooltip ? (
                      <span className="gh-quote-details-tooltip-margin">
                        <Tooltip
                          sign="<"
                          mobilePos="bottom-right"
                          pos="top-right"
                          refEl={"self"}
                          icon={flagIcon}
                          wrapperClassName="gh-quote-details-tooltip-flag gh-quote-details-tooltip-fix-mobile"
                        >
                          {staticService.findByAlias("modifiedFromOriginal")}
                        </Tooltip>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="gh-quote-details-text-normal gh-quote-details-value">
                    {renderValues(value)}
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
      {description ? (
        <div
          className="gh-quote-details-text-normal gh-quote-details-aircraft-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : (
        ""
      )}
    </section>
  );
};
AircraftDetails.propTypes = {
  flight_type: PropTypes.number,
  selectedPadAndAircraft: PropTypes.arrayOf(PropTypes.number),
  sliderImages: PropTypes.arrayOf(PropTypes.object),
  pads: PropTypes.arrayOf(PropTypes.object),
  currentAircraft: PropTypes.object,
};
export default AircraftDetails;
