import React from "react";
import PropTypes from "prop-types";

export const OverlayPopup = ({ classNames = "", isOpen = false, styles = {}, children }) => {
  const className = `gh-overlay-popup ${isOpen ? "shown" : ""} ${classNames}`;
  return (
    <div className={className} style={styles}>
      {children}
    </div>
  );
};

OverlayPopup.propTypes = {
  classNames: PropTypes.string,
  styles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};
