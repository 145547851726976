import Api from "../services/api-handler";
import handlerError from "./error";
import * as types from "./actionTypes";

const entity = "clients";

export function getClientList(page = 1, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity, "")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((res) => {
        dispatch({ type: types.GET_CLIENTS_LIST, payload: res.data });
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function removeClient(id) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", true)
      .setPath(entity, "delete-client")
      .post({ id })
      .catch((err) => {
        handlerError(err, dispatch);
        return err;
      });
  };
}

export function addClient(client) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", true)
      .setPath(entity, "add")
      .post(client)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function updateClient(client) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", true)
      .setPath(entity, `edit/${client.id}`)
      .post(client)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}
