import React from "react";
import PropTypes from "prop-types";
import marker_icon from "../../assets/img/marker_secondary.png";
import { camelize } from "../helpers";

export class MarkerComponent extends React.Component {
  componentWillMount() {
    this.marker = null;
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map && this.marker === null) ||
      (this.props.position !== prevProps.position && this.marker === null)
    ) {
      this.renderMarker();
    }
  }

  componentDidMount() {
    if (this.props.map && this.props.position) {
      this.renderMarker();
    }
  }

  renderMarker() {
    let { map, position, mapCenter } = this.props;
    let pos = position || mapCenter;
    position = new google.maps.LatLng(pos.lat, pos.lng);

    const pref = {
      map: map,
      position: position,
      icon: marker_icon,
    };
    this.marker = new google.maps.Marker(pref);
    const evtNames = ["click"];

    if (this.props.onLoad) this.props.onLoad(this.marker);

    evtNames.forEach((e) => {
      this.marker.addListener(e, this.handleEvent(e));
    });
  }

  handleEvent(evt) {
    return (e) => {
      const evtName = `on${camelize(evt)}`;
      if (this.props[evtName]) {
        this.props[evtName](this.props, this.marker, e, evt);
      }
    };
  }

  render() {
    return null;
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }
}

MarkerComponent.propTypes = {
  position: PropTypes.object,
  map: PropTypes.object,
  onLoad: PropTypes.func,
  infoWindow: PropTypes.object,
};
