import React from "react";
import PropTypes from "prop-types";
import "./Skeleton.css";
import clsx from "clsx";

const Skeleton = ({ className, loading, children }) => {
  return <div className={clsx({ skeleton: loading, [className]: loading })}>{children}</div>;
};

Skeleton.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
};
Skeleton.defaultProps = {
  loading: false,
  children: null,
};
export default Skeleton;
