import React, { useState } from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../../../../services/static.service";
import auth from "../../../../../../../../services/auth";
import Button from "../../../../../../../common/Button";

const ConfirmPaymentModalContent = (props) => {
  const { onConfirm, modalTextAlias, closeModal } = props;
  const [loadingBtn, setLoadingBtn] = useState(false);

  return (
    <React.Fragment>
      <p
        className="gh-booking-payment-details-modal-text uk-margin-small-top gh-text-gray"
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias(modalTextAlias || ""),
        }}
      />
      {auth.isOperator() ? (
        <p
          className="gh-booking-payment-details-modal-text gh-text-gray font-weight-700"
          style={{ margin: "20px 0" }}
        >
          {staticService.findByAlias("thisActionCannotBeUndone.")}
        </p>
      ) : (
        ""
      )}

      <div className="uk-flex uk-flex-center uk-margin-medium-top">
        <Button
          onClick={closeModal}
          variant="outlined"
          color="light"
          className="uk-margin-small-right"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setLoadingBtn(true);
            onConfirm(setLoadingBtn);
          }}
          loading={loadingBtn}
        >
          Confirm
        </Button>
      </div>
    </React.Fragment>
  );
};

ConfirmPaymentModalContent.defaultProps = {
  modalTextAlias: "",
};

ConfirmPaymentModalContent.propTypes = {
  onConfirm: PropTypes.func,
  modalTextAlias: PropTypes.string,
  closeModal: PropTypes.func,
};

export default ConfirmPaymentModalContent;
