import React, { useState } from "react";
import PropTypes from "prop-types";
import "./PublicMobileMenu.css";
import closeIcon from "../../../assets/img/home/closeMenuBlack.svg";
import backArrowIcon from "../../../assets/img/svg/backArrowGreen.svg";
import SecondStep from "./components/SecondStep";
import MainStep from "./components/MainStep";

export const EVENTS_MENU = "EVENTS_MENU";
export const DESTINATION_MENU = "DESTINATION_MENU";
export const GETHELI_BUSINESSES_MENU = "GETHELI_BUSINESSES_MENU";

const PublicMobileMenu = (props) => {
  const { onCloseMenu } = props;
  const [selectedSecondStep, setSelectedSecondStep] = useState("");

  const handleRedirect =
    (url, anchor = "") =>
    () => {
      localStorage.setItem("scroll", anchor);
      window.location.href = url;
    };

  const handleGoBack = () => {
    setSelectedSecondStep("");
  };

  return (
    <div className="gh-public-menu">
      <div className="uk-flex uk-align-center uk-flex-between uk-margin-small-bottom">
        {selectedSecondStep ? (
          <button className="gh-action-menu-btn back" type="button" onClick={handleGoBack}>
            <img src={backArrowIcon} alt="back" />
          </button>
        ) : (
          ""
        )}

        <button className="gh-action-menu-btn" type="button" onClick={onCloseMenu(false)}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      {!selectedSecondStep ? (
        <MainStep handleSelectSecondStep={setSelectedSecondStep} />
      ) : (
        <SecondStep selectedCategory={selectedSecondStep} handleRedirect={handleRedirect} />
      )}
    </div>
  );
};

PublicMobileMenu.propTypes = {
  onCloseMenu: PropTypes.func,
};

export default PublicMobileMenu;
