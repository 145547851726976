import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AccountLayout } from "../../layouts";
import { GrantAccessFormComponent } from "../../";
import { AlertComponent, AlertService } from "../../../shared";
import { pages } from "../../../configs";
import { BlockComponent } from "../../common/block.component";
import event from "../../../services/event.service";
import staticService from "../../../services/static.service";

class GrantAccessComponent extends React.Component {
  constructor(props) {
    super(props);
    const alertService = new AlertService();
    alertService.eventInstance = event;
    this.state = {
      alertInstance: alertService,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  //removing login page from list to not be rendered in navigation
  filteredPages() {
    return pages.filter((page) => {
      return page.hideOnPages && page.hideOnPages.indexOf("register") === -1 ? false : true;
    });
  }

  onSuccess() {
    event.trigger("grant-access", {
      visible: true,
      message: staticService.findByAlias("onSuccessPasswordRecoverPost"),
      type: this.state.alertInstance.successProp,
    });
  }

  onSubmit(model) {}

  render() {
    return (
      <AccountLayout
        activeRoute={this.props.match}
        pageTitle={this.props.title}
        pages={this.filteredPages()}
      >
        <section className={"recover-password-page"}>
          <AlertComponent
            isRelative={true}
            time={10000}
            event={"grant-access"}
            alertInstance={this.state.alertInstance}
          />
          <BlockComponent
            visible={true}
            containerClass={"uk-section uk-padding-remove-top gh-section-statistics"}
            parentProps={{ className: "uk-container uk-container-affiliate" }}
          >
            <GrantAccessFormComponent submit={this.onSubmit} />
          </BlockComponent>
        </section>
      </AccountLayout>
    );
  }
}

GrantAccessComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(GrantAccessComponent);
export { COMPONENT as GrantAccessComponent };
