import { filterModel } from "../shared/helpers";
import _ from "lodash.uniq";

class PaginationService {
  constructor(dispatch, callBack, limit, filters, events) {
    this.page = 1;
    this.limit = limit;
    this.initialLoadState = false;
    this.filters = filters;
    this.skipDispatch = false;
    this.hasLoaded = false;
    this.items = [];
    this.events = events;
    this.columns = [];
    this.headers = [];
    this.sorting = [];
    this.dispatch = dispatch;
    this.loadMoreBtnStatus = true;
    this.fn = callBack;
    this.isLoading = false;

    this.setInitilaPageNumber();
  }

  setInitilaPageNumber() {
    const pageInFilters = this.filters.find((filter) => filter.key === "page");

    if (pageInFilters) {
      this.page = Number(pageInFilters.value);
    }
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  async loadData() {
    if (!this.skipDispatch) {
      this.toggleLoading();
      return this.dispatch(this.fn(this.page, this.filters.concat(this.sorting))).then((res) => {
        this.toggleLoading();
        return Promise.resolve(res);
      });
    }
    return this.fn();
  }

  loadEvent() {
    if (!this.skipDispatch) {
      return this.dispatch(this.fn(this.page, this.events.concat(this.sorting)));
    }
    return this.fn();
  }

  filterList(clbk) {
    this.items = clbk();
  }

  populateItems(items, extraPrepareFn = null, isConcat = true) {
    this.loadMoreBtnStatus = items.length >= this.limit;
    if (extraPrepareFn) {
      items = extraPrepareFn(items);
    }
    if (isConcat) {
      this.items = this.items.concat(items);
    } else {
      this.items = items;
    }
    this.initialLoadState = this.initialLoadState ? true : false;
    return this;
  }

  populateForMarket(items, extraPrepareFn = null) {
    this.loadMoreBtnStatus = items.length >= this.limit;
    if (extraPrepareFn) {
      items = extraPrepareFn(items);
    }
    this.items = _.uniqBy(items, "id");
    // this.items = this.items.concat(items);
    this.initialLoadState = this.initialLoadState ? true : false;
    return this;
  }

  populateItemsLegs(items, nextItems, extraPrepareFn = null) {
    this.loadMoreBtnStatus = items.length >= this.limit;
    if (extraPrepareFn) {
      items = extraPrepareFn(items);
    }
    const id = nextItems.map((next) => next.id);
    this.items = this.items.filter((items) => items.id === id).concat(items);
    this.initialLoadState = this.initialLoadState ? true : false;
    return this;
  }

  reloadItems(reloadPage = true) {
    this.items = [];
    if (reloadPage) this.page = 1;
    return this;
  }

  reloadEvents() {
    this.items = [];
    this.filters = [];
    this.page = 1;
    this.initialLoadState = true;
    return this;
  }

  pushOnTop(newItem) {
    this.items.unshift(newItem);

    if (this.items.length === this.limit) {
      this.items.pop();
    }
  }

  loadMore(currentPage) {
    this.page = currentPage ? currentPage + 1 : this.page + 1;
    this.loadData();
    return this;
  }

  loadMoreEvents() {
    this.page += 1;
    this.loadEvent();
    return this;
  }

  setProp(prop, value) {
    this[prop] = value;
    return this;
  }

  setFilters(filters = {}, attributes = []) {
    this.filters = attributes.reduce((prev, next) => {
      if (filters[next] && filters[next] !== null && filters[next] !== "") {
        prev.push(filterModel(next, filters[next]));
      }
      return prev;
    }, []);
  }

  setSortingParameters(params = {}, orderBy = "orderBy", sortedBy = "sortedBy") {
    this.setProp("sorting", [
      filterModel(orderBy, params.column),
      filterModel(sortedBy, params.order),
    ]);
    this.setProp("page", 1);
  }

  get showLoaderBtn() {
    return this.loadMoreBtnStatus;
  }
}

export { PaginationService };
