/**
 * This is inspired by https://github.com/fullstackreact/google-maps-react/issues/31#issuecomment-474165100
 * google-maps-react doesn't have their own implementation of MarkerCluster.
 */
import React, { memo, useContext, useEffect, useRef } from "react";
import { usePrevious, useToggle, useUnmount } from "react-use";
import PropTypes from "prop-types";
import { InfoWindow } from "google-maps-react";
import MarkerClustererPlus from "@googlemaps/markerclustererplus";
import chillout from "chillout";
import MarkerInfo from "./MarkerInfo/index";
import clusterCircle from "../../assets/img/svg/clusterCircle.svg";
import ReactDOM from "react-dom";
import { secondaryIcon } from "./mapUtils";
import PadSelectionContext from "../../context/PadSelectionContext";

// let clusterer;

const MarkerCluster = (props) => {
  const {
    map,
    google,
    pads,
    markerEventHandlers,
    activeInfo,
    setActiveInfo,
    mapId,
    cardActions,
    ...rest
  } = props;
  const context = useContext(PadSelectionContext);
  const prevPadsLength = usePrevious(pads.length);
  const [padsChanged, padsChangedToggle] = useToggle(false);
  const prevPadsChanged = usePrevious(padsChanged);
  const clusterer = useRef(null);

  useEffect(() => {
    if (pads.length !== prevPadsLength) padsChangedToggle();
  }, [pads.length, prevPadsLength, padsChangedToggle]);

  const onInfoWindowOpen = () => {
    const MarkerContent = (
      <MarkerInfo
        key={mapId}
        pin={activeInfo.pad}
        isSelected={activeInfo.isSelected}
        actions={cardActions}
        updateChosenSitesByRole={context?.updateChosenSitesByRole}
      />
    );
    ReactDOM.render(React.Children.only(MarkerContent), document.getElementById(mapId));
  };

  const mouseOverHandler =
    (marker = null, pad = null) =>
    () => {
      if (marker) {
        setActiveInfo({
          visible: true,
          pad,
          marker,
          isSelected: false,
        });
      }
    };

  useEffect(() => {
    if (map && padsChanged !== prevPadsChanged && pads.length) {
      const mapMarkers = [];
      chillout
        .forEach(pads, (pad) => {
          const entry = new google.maps.Marker({
            position: {
              lat: pad.lat,
              lng: pad.lng,
            },
            map,
            name: pad.name,
            title: pad.name,
            icon: secondaryIcon(pad.category),
            zIndex: 1000,
          });

          entry.addListener("mouseover", mouseOverHandler(entry, pad));

          mapMarkers.push(entry);
        })
        .then(() => {
          if (clusterer.current) {
            clusterer.current.clearMarkers();
            clusterer.current.addMarkers(mapMarkers);

            setTimeout(() => {
              if (clusterer.current.repaint) {
                clusterer.current.repaint();
              }
            });
          } else {
            clusterer.current = new MarkerClustererPlus(map, mapMarkers, {
              gridSize: 80,
              minimumClusterSize: 4,
              zIndex: 999,
              styles: [
                {
                  url: clusterCircle,
                  height: 41,
                  width: 41,
                  textLineHeight: 41,
                  fontWeight: 600,
                  textColor: "#242424",
                },
              ],
              ...rest,
            });
          }
        });
    } else if (map && pads.length === 0) {
      if (clusterer.current) clusterer.current.clearMarkers();
    }
  }, [map, google, pads, padsChanged, prevPadsChanged, markerEventHandlers, rest, pads.length]);

  useUnmount(() => {
    // Cleanup function. Note, this is only returned if we create the markers
    if (clusterer.current) clusterer.current.clearMarkers();
  });

  return (
    <InfoWindow
      marker={activeInfo.marker}
      google={google}
      map={map}
      visible={activeInfo.visible}
      onOpen={() => onInfoWindowOpen()}
    >
      <div id={mapId} />
    </InfoWindow>
  );
};

MarkerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  pads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category_id: PropTypes.number,
      location: {
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      },
    }),
  ),
  cardActions: PropTypes.bool,
  markerEventHandlers: PropTypes.object,
  activeInfo: PropTypes.object,
  setActiveInfo: PropTypes.func,
  mapId: PropTypes.string,
};

MarkerCluster.defaultProps = {
  pads: [],
};

export default memo(MarkerCluster);
