import React from "react";
import { AccountOnlineLayout } from "../../../../layouts";
import ClientsList from "../../../../common/ClientsList";

const ClientsPage = () => {
  return (
    <AccountOnlineLayout activeRoute={"/clients"} pageTitle={"Clients"}>
      <section className="uk-section">
        <div className="uk-container">
          <ClientsList />
        </div>
      </section>
    </AccountOnlineLayout>
  );
};

ClientsPage.propTypes = {};

export default ClientsPage;
