import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import staticService from "../../../services/static.service";
import { TacComponent } from "../../forms";
import { downloadAgreement } from "../../../actions";
import clsx from "clsx";

import link from "../../../assets/img/external-link.svg";

import "./termsAndConditions.css";

const keyCommercialTerms = [
  { aliasKey: "landingPermissionKey", aliasValue: "landingPermissionValue" },
  { aliasKey: "pilotCrewAvailabilityKey", aliasValue: "pilotCrewAvailabilityValue" },
  { aliasKey: "operatingHoursKey", aliasValue: "operatingHoursValue" },
  { aliasKey: "paxLuggageWeightNumbersKey", aliasValue: "paxLuggageWeightNumbersValue" },
  { aliasKey: "confirmationAtTimeOfBookingKey", aliasValue: "confirmationAtTimeOfBookingValue" },
  { aliasKey: "weatherConditionsKey", aliasValue: "weatherConditionsValue" },
  { aliasKey: "postConfirmationAmendmentsKey", aliasValue: "postConfirmationAmendmentsValue" },
];

const TermsAndConditions = (props) => {
  const {
    request,
    title = null,
    disableWrapperStyles = false,
    disableTitle = false,
    screenW,
    withOutBtn,
  } = props;
  const wrapperRef = useRef(null);

  const [state, setState] = useState(false);
  const [wrapperClassName, setWrapperClassName] = useState("");

  const agreement = () => {
    setState(!state);
  };

  const onChangeCondition = (state) => {
    setState(state);
  };

  const isContainerSmall = wrapperRef.current?.offsetWidth < 709;

  useEffect(() => {
    if (!wrapperRef.current) return;
    const wrapperWidth = wrapperRef.current.offsetWidth;
    if (wrapperWidth < 1000) {
      if (wrapperWidth < 620 && screenW > 768) {
        setWrapperClassName(
          "gh-terms-conditions-wrapper-no-col-margin gh-terms-conditions-wrapper-desk-small",
        );
      } else setWrapperClassName("gh-terms-conditions-wrapper-desk-small");
    } else if (wrapperClassName !== "") setWrapperClassName("");
  }, [wrapperRef, screenW]);

  if (typeof request === "undefined" || !request || !Object.keys(request).length) return "";

  return (
    <div
      className={`${
        disableWrapperStyles ? "" : "gh-simple-section gh-terms-conditions-wrapper"
      } ${wrapperClassName}`}
      ref={wrapperRef}
    >
      <div className="gh-terms-conditions-title">
        {title
          ? disableTitle
            ? ""
            : title
          : disableTitle
          ? ""
          : `${staticService.findByAlias("charterTerms")} *`}
      </div>
      <div className="gh-terms-conditions-col-wrapper">
        <div className="gh-terms-conditions-first-col">
          <div className="gh-terms-conditions-subtitle">
            {staticService.findByAlias("keyCommercialTerms")}
          </div>
          <div className="gh-terms-conditions-border" />
          {keyCommercialTerms.map((item, index) => (
            <div className="gh-terms-conditions-text-content" key={index}>
              <span className="gh-terms-conditions-text-bold gh-terms-conditions-text-bold-medium-size gh-terms-conditions-title-size">
                {staticService.findByAlias(item.aliasKey)}
              </span>
              <span>{staticService.findByAlias(item.aliasValue)}</span>
            </div>
          ))}
          <AmendmentsInfo
            show={!isContainerSmall}
            className="gh-terms-conditions-note-push-bottom"
          />
        </div>

        <div className="gh-terms-conditions-col gh-terms-conditions-second-col">
          <div className="gh-terms-conditions-subtitle">
            {staticService.findByAlias("brokerCancellationFees")}
          </div>
          <div className="gh-terms-conditions-border" />
          <div className="uk-column-1-2">
            <div className="gh-terms-conditions-text-bold" style={{ fontSize: "14px" }}>
              {staticService.findByAlias("amountOfNoticeTitle")}
            </div>
            <div className="gh-terms-conditions-text-bold" style={{ fontSize: "14px" }}>
              {staticService.findByAlias("cancellationChargeTitle")}
            </div>
          </div>
          <div className="gh-terms-conditions-border" />
          <div className="uk-column-1-2">
            <div>Over 7 days</div>
            <div>10%</div>
          </div>
          <div className="gh-terms-conditions-border" />
          <div className="uk-column-1-2">
            <div>7 days to 72 hours</div>
            <div>25%</div>
          </div>
          <div className="gh-terms-conditions-border" />
          <div className="uk-column-1-2">
            <div>Less than 72 hours</div>
            <div>50%</div>
          </div>
          <div className="gh-terms-conditions-border" />
          <div className="uk-column-1-2">
            <div>Less than 48 hours</div>
            <div>75%</div>
          </div>
          <div className="gh-terms-conditions-border" />
          <div className="uk-grid">
            <div className="uk-width-1-2">
              Less than 12 hours, or cancellation after the Programme has commenced
            </div>
            <div className="uk-width-1-2">100%</div>
          </div>
          <div className="gh-terms-conditions-border" />
          {!withOutBtn ? (
            <div
              role="button"
              tabIndex={0}
              className="gh-terms-conditions-text-view"
              onClick={agreement}
              onKeyPress={() => {}}
            >
              View full version of terms
              <img className="gh-link-icon" src={link} alt="external-link" />
            </div>
          ) : (
            ""
          )}
          <AmendmentsInfo show={isContainerSmall} />
          <TacComponent
            html={request.agreement && request.agreement.description}
            agreeCallback={() => onChangeCondition(!state)}
            visible={state}
            classNames="full"
            isFull={true}
            flightTerms={staticService.findByAlias("flightTermsTitle")}
            downloadCallback={downloadAgreement(request.id)}
            cancelLabel="close"
            disableAcceptButton={true}
          />
        </div>
      </div>
    </div>
  );
};

TermsAndConditions.propTypes = {
  request: PropTypes.object,
  title: PropTypes.string,
  disableWrapperStyles: PropTypes.bool,
  disableTitle: PropTypes.bool,
  screenW: PropTypes.number,
  withOutBtn: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    screenW: state.screenDimensions.screenInnerW,
  };
}

export default connect(mapStateToProps, { downloadAgreement })(TermsAndConditions);

const AmendmentsInfo = ({ show, className }) =>
  show && (
    <div
      className={clsx(className, "gh-terms-conditions-note uk-margin-small-top")}
      dangerouslySetInnerHTML={{
        __html: staticService.findByAlias("termsAndConditionsAnyAmendedTermsShouldBeAgreed"),
      }}
    />
  );
AmendmentsInfo.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
};
AmendmentsInfo.defaultProps = {
  show: true,
  className: "",
};
