import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "./ImageSlider.css";
import Slider from "./Slider";

const ImageSlider = forwardRef((props, ref) => {
  const { imagesUrl, changeExternalIndex, className, options, autoPlay } = props;

  return (
    <Slider
      imagesUrl={imagesUrl}
      ref={ref}
      changeExternalIndex={changeExternalIndex}
      className={className}
      options={options}
      autoPlay={autoPlay}
      {...props}
    />
  );
});

ImageSlider.displayName = "ImageSlider";
ImageSlider.propTypes = {
  imagesUrl: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeExternalIndex: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.object,
  autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default ImageSlider;
