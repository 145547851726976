import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";
import { replaceAttributesInString } from "../../../../shared";

const EventDetailComponent = ({ visible, event, showContent = "uk-hidden", showWarning = "" }) => {
  if (visible) {
    return (
      <div className="uk-position-relative">
        <div className={showWarning}>
          <span
            style={{ top: "-2px" }}
            className="uk-text-danger gh-vertical-middle uk-margin-small-right uk-position-relative"
            data-uk-icon="icon: warning"
          />
          <span
            dangerouslySetInnerHTML={{
              __html: replaceAttributesInString(
                { event: event.name },
                staticService.findByAlias("QuoteEventDayDetailsLabel"),
              ),
            }}
          />
        </div>
        <div className={showContent}>
          <h4
            className={"uk-margin-remove-bottom"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("EventDayDetailsLabel") }}
          />
          <div
            className={"gh-dark-blue"}
            dangerouslySetInnerHTML={{ __html: event.quote_content || "None" }}
          />
        </div>
      </div>
    );
  }
  return null;
};

EventDetailComponent.propTypes = {
  event: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  showContent: PropTypes.string,
  showWarning: PropTypes.string,
};

export { EventDetailComponent };
