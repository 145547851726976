import React from "react";
import PropTypes from "prop-types";
import { Control, Form, Errors, actions } from "react-redux-form";
import { validationRules, validationMessages } from "../../../../configs";
import { CustomValidateError, updateModel } from "../../../../shared";
import staticService from "../../../../services/static.service";
import { RequestBlockComponent } from "../../../pages/user-online/common";

const { required } = validationRules;

export class BrokerPrivateNotesComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      initialNote: props.note,
      active: false,
    };
    this.addNote = this.addNote.bind(this);
    this.resetNoteToInitialValue = this.resetNoteToInitialValue.bind(this);
    context.store.dispatch(updateModel(`${props.modelName}.private_notes`, props.note));
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.note) {
      this.setState({ active: false });
    }
    if (this.props.note !== nextProps.note) {
      this.setState({
        initialNote: nextProps.note,
      });
      nextContext.store.dispatch(
        updateModel(`${this.props.modelName}.private_notes`, nextProps.note),
      );
    }
  }

  addNote(model) {
    const value = model.private_notes.trim();
    this.context.store.dispatch(
      this.props.action(this.props.id, value, staticService.findByAlias("noteSaved")),
    );
    this.setState({ initialNote: value, active: false });
  }

  resetNoteToInitialValue() {
    this.setState({ active: false });
    this.context.store.dispatch(
      updateModel(`${this.props.modelName}.private_notes`, this.state.initialNote),
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset(this.props.modelName));
  }

  render() {
    const { active } = this.state;
    return (
      <div>
        <div
          className={`flight-item-note-form gh-comment-section uk-background-default uk-padding-small uk-border-rounded ${this.props.className}`}
        >
          <RequestBlockComponent topPosition="flight-item-note-form" title={this.props.title}>
            &nbsp;
          </RequestBlockComponent>
        </div>
        <Form onSubmit={this.addNote} model={this.props.modelName}>
          <Control.textarea
            className="uk-textarea"
            model={".private_notes"}
            disabled={!active}
            // validators={{ required }}
            changeAction={updateModel}
          />
          <div className="uk-margin-small-top">
            {active && (
              <Control.button
                model={this.props.modelName}
                className="uk-button uk-button-primary gh-request-detail-btn"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("save"),
                  }}
                />
              </Control.button>
            )}
            {active && (
              <Control.button
                model={this.props.modelName}
                type="button"
                role="presentation"
                onClick={this.resetNoteToInitialValue}
                className="uk-button uk-button-secondary gh-margin-left-cancel gh-request-detail-btn"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("cancel"),
                  }}
                />
              </Control.button>
            )}
          </div>
        </Form>
        {!active && (
          <button
            className="uk-button uk-button-primary"
            onClick={() => this.setState({ active: !active })}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("edit"),
              }}
            />
          </button>
        )}
      </div>
    );
  }
}

BrokerPrivateNotesComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerPrivateNotesComponent.defaultProps = {
  modelName: "brokerPrivateNotesModel",
  className: "",
};

BrokerPrivateNotesComponent.propTypes = {
  id: PropTypes.number,
  action: PropTypes.func.isRequired,
  modelName: PropTypes.string,
  title: PropTypes.any,
  className: PropTypes.string,
  note: PropTypes.string,
};
