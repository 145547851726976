import React from "react";
import { connect } from "react-redux";
import { AccountLayout, AccountOnlineLayout } from "../layouts";
import { staticText, pages } from "../../configs/";
import auth from "../../services/auth";
import staticService from "../../services/static.service";

class ReduxForbiddenComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: staticText.forbiddenMessage,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error.message && nextProps.error.message !== this.props.error.message) {
      this.setState({ message: staticService.findByAlias(nextProps.error.message) });
    }
  }

  render() {
    const render = () => {
      return (
        <div {...this.props.attrProps}>
          <div data-uk-alert className={"uk-margin-top uk-alert-danger"}>
            <div className="uk-text-center">
              <span dangerouslySetInnerHTML={{ __html: this.state.message }} />
            </div>
          </div>
        </div>
      );
    };

    if (this.props.skipLayout) return render();
    const menu = auth.isAuthenticated()
      ? pages.map((page) => {
          if (page.path === "/") {
            page.title = "Dashboard";
          }
          return page;
        })
      : pages;

    if (auth.getUser()) {
      return (
        <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
          {render()}
        </AccountOnlineLayout>
      );
    }

    return (
      <AccountLayout activeRoute={this.props.match} pages={menu} pageTitle={this.props.title}>
        {render()}
      </AccountLayout>
    );
  }
}

ReduxForbiddenComponent.defaultProps = {
  attrProps: {},
  skipLayout: false,
};

function mapStateToProps(state) {
  return {
    error: state.errorObj,
  };
}

const COMPONENT = connect(mapStateToProps)(ReduxForbiddenComponent);
export { COMPONENT as ReduxForbiddenComponent };
