import React from "react";
import { BlockComponent } from "../common";
import { fireTracking } from "../../shared";
import { pages, config } from "../../configs";
import { BookDemoLayout } from "../layouts/bookDemo.layout";

const parentBlockConfig = {
  minWidth: "320px",
  height: "630px",
  minHeight: "calc(100vh - 150px)",
};

export const BookDemo = (props) => {
  const filteredPages = () => {
    return pages.filter((page) => {
      return !(page.hideOnPages && page.hideOnPages.indexOf("bookDemo") !== -1);
    });
  };

  const calendly = () => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js");
    head.appendChild(script);
  };

  fireTracking(props.location.pathname);
  return (
    <BookDemoLayout
      activeRoute={props.match}
      pageTitle={props.title}
      pages={filteredPages(pages)}
      registerPath={config.redirectToRegister}
    >
      {calendly()}
      <BlockComponent visible={true}>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/demo-getheli/book-a-demo"
          style={parentBlockConfig}
        />
      </BlockComponent>
    </BookDemoLayout>
  );
};
