import React from "react";
import { useCalculatorContext } from "../../../CalculatorContext";
import { Description } from "../../Description";
import { Accordion } from "../../Accordion";
import { Input, ConfirmInput, TaxInput } from "../../Input";
import { Control } from "../../Control";
import { useLegContext } from "../LegContext";
import { SectionArrow } from "../../SectionArrow";
import { useFormContext } from "react-hook-form";

export default function ExtracostsSection() {
  const { activeSections, toggleSectionStatus, resetToOriginal, currency } = useCalculatorContext();
  const { prefix, isLast, onLegExtracostsChange, legId } = useLegContext();
  const { getValues } = useFormContext();

  return (
    <Accordion
      isOpen={activeSections.includes("extracosts")}
      onToggle={toggleSectionStatus("extracosts", !activeSections.includes("extracosts"))}
      className="uk-margin-small-top"
      header={
        <Description>
          <Description.Item as="custom" type="header">
            {isLast && <SectionArrow closed={!activeSections.includes("extracosts")} />}
          </Description.Item>
        </Description>
      }
    >
      <Description>
        <Description.Item>
          <Control name={`${prefix}.extra_cost_amount`}>
            <ConfirmInput
              placeholder="[0]"
              onValueChange={onLegExtracostsChange}
              onReset={resetToOriginal({
                legId,
                field: `${prefix}.extra_cost_amount`,
                callback: onLegExtracostsChange,
              })}
            />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.extra_cost_notes`}>
            <Input placeholder="Please provide description" />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.extra_cost_tax`}>
            <TaxInput
              onValueChange={onLegExtracostsChange}
              total={getValues(`${prefix}.extra_cost_amount`)}
              currency={currency}
            />
          </Control>
        </Description.Item>
        <Description.Item separator>
          <Control name={`${prefix}.extra_cost_total`}>
            <ConfirmInput placeholder="0.00" readOnly />
          </Control>
        </Description.Item>
      </Description>
    </Accordion>
  );
}
