import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { BrokerBookingLayout } from "../../../../layouts";
import { brokerBookingTabs, staticText } from "../../../../../configs";
import { returnRouteUrl, RouteWithSubRoutes } from "../../../../../shared/";
import GetHeliEvents from "../../../../../services/event.service";
import { BrokerBookingRequestListComponent } from "./";
import staticService from "../../../../../services/static.service";
import { updateEditRequest } from "../../../../../actions";
import { connect } from "react-redux";

class BrokerBookingRequestsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersShown: false,
      showFilterIcon: true,
      filterFailed: "",
    };
    staticText.quoteRequestStatuses.open = staticService.findByAlias("openStatus");
    staticText.quoteRequestStatuses.pending = staticService.findByAlias("pendingStatus");
    staticText.quoteRequestStatuses.closed = staticService.findByAlias("closedStatus");
    staticText.quoteRequestStatuses.expired = staticService.findByAlias("expiredStatus");
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({ isFiltersShown: !prevState.isFiltersShown }));
  }

  get layoutParentCssClass() {
    return ["gh-normal"];
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  render() {
    return (
      <BrokerBookingLayout
        pageTitle={staticService.findByAlias("requests")}
        isFilterVisible={this.state.showFilterIcon}
        isFiltersShown={this.state.isFiltersShown}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        navigationTabs={brokerBookingTabs}
        activeRoute={this.props.match}
        parentClassname={this.layoutParentCssClass}
        filterCss={this.state.filterFailed}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  title={route.title}
                  onEnter={this.onEnterPage.bind(this)}
                  {...returnRouteUrl(route, this.props.match.url)}
                />
              );
            })}
          <Route
            path="/"
            render={(props) => (
              <BrokerBookingRequestListComponent
                {...props}
                parentMatch={this.props.match}
                isFiltersShown={this.state.isFiltersShown}
              />
            )}
          />
        </Switch>
      </BrokerBookingLayout>
    );
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("requests");
    this._mounted = false;
    updateEditRequest();
  }
}

BrokerBookingRequestsComponent.propTypes = {
  match: PropTypes.object,
  routes: PropTypes.array,
};

const BrokerBookingRequestsComponentWithRouter = withRouter(BrokerBookingRequestsComponent);
const COMPONENT = connect()(BrokerBookingRequestsComponentWithRouter);
export { COMPONENT as BrokerBookingRequestsComponent };
