import PropTypes from "prop-types";
import React from "react";

export default function Error(props) {
  const { error } = props;

  if (!error.message) {
    return "";
  }
  return (
    <React.Fragment>
      {error.type === "server" ? (
        error.message.map((err, index) => (
          <div className="form-item-error" key={index}>
            {err}
          </div>
        ))
      ) : (
        <span className="form-item-error">{error.message}</span>
      )}
    </React.Fragment>
  );
}

Error.propTypes = {
  error: PropTypes.object,
};

Error.defaultProps = {
  error: {
    message: null,
  },
};
