export const FETCH_RESOURCES_FAIL = "FETCH_RESOURCES_FAIL";

export const ADD_ERROR = "ADD_ERROR";

export const LOAD_SUCCESS = "LOAD_SUCCESS";

export const LOAD_LIST_SUCCESS = "LOAD_LIST_SUCCESS";

export const LOAD_CONDITION_SUCCESS = "LOAD_CONDITION_SUCCESS";

export const LOAD_AIRCRAFT_SUCCESS = "LOAD_AIRCRAFT_SUCCESS";

export const SAVE_AIRCRAFT_SUCCESS = "SAVE_AIRCRAFT_SUCCESS";

export const LOAD_MANUFACTURER_SUCCESS = "LOAD_MANUFACTURER_SUCCESS";

export const RESET_AIRCRAFT_ACTIONS = "RESET_AIRCRAFT_ACTIONS";

export const UPDATE_AIRCRAFT_SUCCESS = "UPDATE_AIRCRAFT_SUCCESS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CHECK_INVITATION_SUCCESS = "CHECK_INVITATION_SUCCESS";

export const CHECK_HASH_SUCCESS = "CHECK_HASH_SUCCESS";

export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";

export const CHECK_HASH_FAILED = "CHECK_HASH_FAILED";

export const CHECK_INVITATION_FAILED = "CHECK_INVITATION_FAILED";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const ACCOUNT_INVITATION_SUCCESS = "ACCOUNT_INVITATION_SUCCESS";

export const SENT_INVITATION_SUCCESS = "SENT_INVITATION_SUCCESS";

export const UPLOAD_DOCUMENTS_SUCCESS = "UPLOAD_DOCUMENTS_SUCCESS";

export const ACTIVE_ROUTE = "ACTIVE_ROUTE";

export const CLOSE_MODAL = "CLOSE_MODAL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const ADD_REQUEST_SUCCESS = "ADD_REQUEST_SUCCESS";

export const GET_BROKER_REQUESTS_LIST = "GET_BROKER_REQUESTS_LIST";

export const REMOVE_BROKER_REQUEST = "REMOVE_BROKER_REQUEST";

export const UPDATE_BROKER_REQUEST_PROGRESS = "UPDATE_BROKER_REQUEST_PROGRESS";

export const UPDATE_BROKER_REQUEST = "UPDATE_BROKER_REQUEST";

export const UPDATE_BROKER_REQUEST_BY_QUOTES = "UPDATE_BROKER_REQUEST_BY_QUOTES";

export const GET_OPERATOR_REQUESTS_LIST = "GET_OPERATOR_REQUESTS_LIST";

export const SEND_COORDINATES = "SEND_COORDINATES";

export const EDIT_REQUEST = "EDIT_REQUEST";

export const GET_OPERATOR_REQUESTS_NOTIFICATION = "GET_OPERATOR_REQUESTS_NOTIFICATION";

export const GET_BROKER_REQUEST_DETAIL = "GET_BROKER_REQUEST_DETAIL";

export const UPDATE_QUOTE_AMEND_STATUS = "UPDATE_QUOTE_AMEND_STATUS";

export const UPDATE_REQUEST_BY_QUOTES = "UPDATE_REQUEST_BY_QUOTES";

export const TOGGLE_REQUEST_FLAG_STATUS = "TOGGLE_REQUEST_FLAG_STATUS";

export const GET_BOOKING_LIST = "GET_BOOKING_LIST";

export const GET_BOOKING_DETAIL = "GET_BOOKING_DETAIL";

export const REMOVE_BOOKING_DETAIL = "REMOVE_BOOKING_DETAIL";

export const GET_AOC_DOCS = "GET_AOC_DOCS";

export const CANCEL_BOOKING = "CANCEL_BOOKING";

export const ADD_COMMENT_TO_BOOKING = "ADD_COMMENT_TO_BOOKING";

export const GET_QUOTE_LIST = "GET_QUOTE_LIST";

export const REJECT_QUOTE = "REJECT_QUOTE";

export const SELECT_QUOTE = "SELECT_QUOTE";

export const QUOTE_DETAIL_CLEAN = "QUOTE_DETAIL_CLEAN";

export const REQUEST_UPDATED = "REQUEST_UPDATED";

export const GET_META = "GET_META";

export const GET_QUOTE_ITEM = "GET_QUOTE_ITEM";

export const UPDATE_QUOTE_ITEM = "UPDATE_QUOTE_ITEM";

export const REMOVE_QUOTE_ITEM = "REMOVE_QUOTE_ITEM";

export const UPDATE_QUOTE_STATUS = "UPDATE_QUOTE_STATUS";

export const GET_DISCUSSION_MESSAGES = "GET_DISCUSSION_MESSAGES";

export const QUOTE_TO_AMEND = "QUOTE_TO_AMEND";

export const REMOVE_QUOTE_TO_AMEND = "REMOVE_QUOTE_TO_AMEND";

export const SEEN_QUOTE = "SEEN_QUOTE";

export const UPDATE_QUOTE_LOCALLY = "UPDATE_QUOTE_LOCALLY";

export const UPDATE_CURRENT_QUOTE_DETAIL = "UPDATE_CURRENT_QUOTE_DETAIL";

export const ADD_DISCUSSION_MESSAGE = "ADD_DISCUSSION_MESSAGE";

export const QUOTE_WITHDRAWN = "QUOTE_WITHDRAWN";

export const QUOTE_UNSUCCESSFULL = "QUOTE_UNSUCCESSFULL";

export const ADD_NEW_QUOTE = "ADD_NEW_QUOTE";

export const STATUS_QUOTE_UPDATED = "STATUS_QUOTE_UPDATED";

export const NEW_BOOKING = "NEW_BOOKING";

export const GET_STRIPE_FIELDS = "GET_STRIPE_FIELDS";

export const RESET_FORGOTTEN_PASSWORD = "RESET_FORGOTTEN_PASSWORD";

export const RESET_MODEL = "RESET_MODEL";

export const LOAD_ACTIVITY_FEED = "LOAD_ACTIVITY_FEED";

export const LOAD_ACTIVITY_FLIGHTS = "LOAD_ACTIVITY_FLIGHTS";

export const ACCOUNT_REMOTE_VALIDATION = "ACCOUNT_REMOTE_VALIDATION";

export const ACCOUNT_REMOTE_FIRST_VALIDATION = "ACCOUNT_REMOTE_FIRST_VALIDATION";

export const ACCOUNT_INVITATIONS_VALIDATION = "ACCOUNT_INVITATIONS_VALIDATION";

export const ACCOUNT_DOCUMENTS_VALIDATION = "ACCOUNT_DOCUMENTS_VALIDATION";

export const ACCOUNT_DOCUMENTS_UPLOAD = "ACCOUNT_DOCUMENTS_UPLOAD";

export const GET_NOTIFICATIONS_LIST = "GET_NOTIFICATIONS_LIST";

export const GET_NOTIFICATIONS_SETTINGS = "GET_NOTIFICATIONS_SETTINGS";

export const GET_UNREAD_NOTIFICATIONS_LIST = "GET_UNREAD_NOTIFICATIONS_LIST";

export const CHANGE_NOTIFICATIONS_SETTINGS = "CHANGE_NOTIFICATIONS_SETTINGS";

export const SAVE_NOTIFICATIONS_SETTINGS = "SAVE_NOTIFICATIONS_SETTINGS";

export const ACCOUNT_FLEET_VALIDATION = "ACCOUNT_FLEET_VALIDATION";

export const ADD_EMPTY_LEG = "ADD_EMPTY_LEG";

export const GET_EMPTY_LEGS = "GET_EMPTY_LEGS";

export const GET_MAP_EMPTY_LEGS = "GET_MAP_EMPTY_LEGS";

export const GET_EMPTY_LEG_DETAILS = "GET_EMPTY_LEG_DETAILS";

export const REMOVE_EMPTY_LEG = "REMOVE_EMPTY_LEG";

export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";

export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

export const ADD_NEW_PAYMENT = "ADD_NEW_PAYMENT";

export const BROADCAST_EVENT = "BROADCAST_EVENT";

export const COMPANY_NOTIFICATION_EVENT = "COMPANY_NOTIFICATION_EVENT";

export const CLEAR_BROADCAST_EVENT = "CLEAR_BROADCAST_EVENT";

export const GET_REQUESTS_LIST = "GET_REQUESTS_LIST";

export const GET_DECLINE_REASONS = "GET_DECLINE_REASONS";

export const SEND_DECLINE_REASONS = "SEND_DECLINE_REASONS";

export const LOAD_MORE_REQUEST = "LOAD_MORE_REQUEST";

export const SAVE_DEFAULT_SEARCH = "SAVE_DEFAULT_SEARCH";

export const SET_DEFAULT_REQUESTS_DATA = "SET_DEFAULT_REQUESTS_DATA";

export const SAVE_RESET_REQUESTS_LIST = "SAVE_RESET_REQUESTS_LIST";

export const REMOVE_FILTERS = "REMOVE_FILTERS";

export const RESET_DEFAULT_SEARCH = "RESET_DEFAULT_SEARCH";

export const CHANGE_SORT = "CHANGE_SORT";

export const GET_DASHBOARD_STATISTIC = "GET_DASHBOARD_STATISTIC";

export const GET_DASHBOARD_AIRCRAFTS = "GET_DASHBOARD_AIRCRAFTS";

export const UPDATE_CARD = "UPDATE_CARD";

export const DELETE_CARD = "DELETE_CARD";

export const SET_DEFAULT_CARD = "SET_DEFAULT_CARD";

export const RESET_CARD_UPDATE = "RESET_CARD_UPDATE";

export const CARDS_LOADED = "CARDS_LOADED";

export const NEW_CARD = "NEW_CARD";

export const NEW_CARD_AND_DEFAULT = "NEW_CARD_AND_DEFAULT";

export const WINDOW_RESIZE = "WINDOW_RESIZE";

export const SET_TEAM_MEMEBERS = "SET_TEAM_MEMBERS";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const SET_AUTH_ROLE = "SET_AUTH_ROLE";

export const SUBMIT_CONTACT_FORM = "SUBMIT_CONTACT_FORM";

export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";

export const PREVIOUS_PATH = "PREVIOUS_PATH";

export const LOAD_STATIC_CONTENT = "LOAD_STATIC_CONTENT";

export const PRE_REGISTER_SUCCESS = "PRE_REGISTER_SUCCESS";

export const CLOSE_CONFIRM_DIALOG = "CLOSE_CONFIRM_DIALOG";

export const OPEN_CONFIRM_DIALOG = "OPEN_CONFIRM_DIALOG";

export const REMOVED_DOCUMENT = "REMOVED_DOCUMENT";

export const RESET_PRE_REGISTER = "RESET_PRE_REGISTER";

export const OPEN_MODAL = "OPEN_MODAL";

export const OPEN_ASSIGN_FORM = "OPEN_ASSIGN_FORM";

export const CLOSE_ASSIGN_FORM = "CLOSE_ASSIGN_FORM";

export const INVITATION_REMOVE_SUCCESS = "INVITATION_REMOVE_SUCCESS";

export const USER_REMOVE_SUCCESS = "USER_REMOVE_SUCCESS";

export const AIRCRAFT_REMOVE_SUCCESS = "AIRCRAFT_REMOVE_SUCCESS";

export const RESET_MEMBER_REMOVE = "RESET_MEMBER_REMOVE";

export const RESET_EMPTY_LEG = "RESET_EMPTY_LEG";

export const RESET_PAYMENT = "RESET_PAYMENT";

export const GET_INVITATIONS = "GET_INVITATIONS";

export const RESET_LIST = "RESET_LIST";

export const BETA_VALIDATION_PASSED = "BETA_VALIDATION_PASSED";

export const BETA_VALIDATION_FAILED = "BETA_VALIDATION_FAILED";

export const SET_COMPANY = "SET_COMPANY";

export const LOAD_FAVORITES = "LOAD_FAVORITES";

export const GET_CREDENTIALS = "GET_CREDENTIALS";

export const POPULATE_AUTOCOMPLETE_LIST = "POPULATE_AUTOCOMPLETE_LIST";

export const CLEAR_AUTOCOMPLETE_LIST = "CLEAR_AUTOCOMPLETE_LIST";

export const ADD_FAVORITE = "ADD_FAVORITE";

export const DELETE_FAVORITE = "DELETE_FAVORITE";

export const PREMIUM_APPLY = "PREMIUM_APPLY";

export const LOAD_AIRCRAFT_GALLERY = "LOAD_AIRCRAFT_GALLERY";

export const RESET_QUOTE_UPDATE_MODEL = "RESET_QUOTE_UPDATE_MODEL";

export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";

export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";

export const LOAD_UNREAD_REQUESTS_NOTIFICATION = "LOAD_UNREAD_REQUESTS_NOTIFICATION";

export const LOCATION_LOAD_SUCCESS = "LOCATION_LOAD_SUCCESS";

export const SET_PAGE_TITLE = "SET_PAGE_TITLE";

export const LOAD_HOME_DATA = "LOAD_HOME_DATA";

export const GET_REGISTER_COUNTRIES = "GET_REGISTER_COUNTRIES";

export const GET_CURRENCIES = "GET_CURRENCIES";

export const LOAD_EVENT_DATA = "LOAD_EVENT_DATA";

export const RESET_EVENT_DATA = "RESET_EVENT_DATA";

export const LOAD_DESTINATION_DATA = "LOAD_DESTINATION_DATA";

export const SHOW_MORE_EVENTS = "SHOW_MORE_EVENTS";

export const SHOW_MORE_DESTINATIONS = "SHOW_MORE_DESTINATIONS";

export const LOAD_EVENT_INFORMATION_DATA = "LOAD_EVENT_INFORMATION_DATA";

export const LOAD_DESTINATION_INFORMATION_DATA = "LOAD_DESTINATION_INFORMATION_DATA";

export const GET_HELI_OPTIONS = "GET_HELI_OPTIONS";

export const STORE_REQUEST = "STORE_REQUEST";

export const HANDLE_EVENT_ID = "HANDLE_EVENT_ID";

export const SET_FILTERS = "SET_FILTERS";

export const SORT_GRID = "SORT_GRID";

export const PAYMENT_DETAILS = "PAYMENT_DETAILS";

export const LOAD_ACTIVITY_FLIGHTS_WITH_PAGINATION = "LOAD_ACTIVITY_FLIGHTS_WITH_PAGINATION";

export const UPDATE_IDENTIFIER = "UPDATE_IDENTIFIER";

export const UPDATE_PRIVATE_NOTES = "UPDATE_PRIVATE_NOTES";

export const DEFAULT_FILTERS = "DEFAULT_FILTERS";

export const OPERATOR_QUOTE_INTERNAL_NOTES_ADDED_WITH_SUCCESS =
  "OPERATOR_QUOTE_INTERNAL_NOTES_ADDED_WITH_SUCCESS";

export const OPERATOR_BOOKING_INTERNAL_NOTES_ADDED_WITH_SUCCESS =
  "OPERATOR_BOOKING_INTERNAL_NOTES_ADDED_WITH_SUCCESS";

export const UPDATE_STEPS_ENQUIRE_FORMS = "UPDATE_STEPS_ENQUIRE_FORMS";

export const UPDATE_AIRCRAFT_FLEET_LOCALLY = "UPDATE_AIRCRAFT_FLEET_LOCALLY";

export const GET_QUOTE_DETAIL_BY_ID = "GET_QUOTE_DETAIL_BY_ID";

export const UPDATE_QUOTE_DETAIL = "UPDATE_QUOTE_DETAIL";

export const UPDATE_QUOTE_LEG_LOCATION = "UPDATE_QUOTE_LEG_LOCATION";

export const CLEAR_QUOTE_DETAIL = "CLEAR_QUOTE_DETAIL";

export const UPDATE_EDITED_QUOTE_DETAIL = "UPDATE_EDITED_QUOTE_DETAIL";

export const UPDATE_BOTH_QUOTE_OBJECTS = "UPDATE_BOTH_QUOTE_OBJECTS";

export const RESET_QUOTE_DETAIL = "RESET_QUOTE_DETAIL";

export const GET_REQUEST_DRAFT = "GET_REQUEST_DRAFT";

export const CLEAN_REQUEST_DRAFT = "CLEAN_REQUEST_DRAFT";

export const UPDATE_REQUEST_DRAFT = "UPDATE_REQUEST_DRAFT";

export const GET_PIPELINES = "GET_PIPELINES";

export const DELETE_PIPELINE = "DELETE_PIPELINE";

export const ADD_PROGRESS_INFO = "ADD_PROGRESS_INFO";

export const DELETE_PROGRESS_INFO = "DELETE_PROGRESS_INFO";

export const GET_AVAILABLE_COUNTRIES = "GET_AVAILABLE_COUNTRIES";

export const SHOW_AVAILABLE_COUNTRIES_MODAL = "SHOW_AVAILABLE_COUNTRIES_MODAL";

export const HIDE_AVAILABLE_COUNTRIES_MODAL = "HIDE_AVAILABLE_COUNTRIES_MODAL";

export const UPDATE_BOOKING_SELECTED_QUOTE = "UPDATE_BOOKING_SELECTED_QUOTE";

export const SEEN_LOCAL_QUOTE = "SEEN_LOCAL_QUOTE";

export const UPDATE_QUOTE_BOOKING = "UPDATE_QUOTE_BOOKING";

export const GET_CLIENTS_LIST = "GET_CLIENTS_LIST";

export const CLIENTS_LIST_REMOVE = "CLIENTS_LIST_REMOVE";

export const CLIENTS_LIST_ADD = "CLIENTS_LIST_ADD";

export const CLIENTS_LIST_UPDATE = "CLIENTS_LIST_UPDATE";

export const UPDATE_MORE_DETAIL_REQUESTS = "UPDATE_MORE_DETAIL_REQUESTS";

export const REVIEW_REQUEST_ADD_OPERATORS = "REVIEW_REQUEST_ADD_OPERATORS";

export const MENU_ITEMS = "MENU_ITEMS";
