import { distanceBetween } from "../../../../shared";

export const n = (value) => {
  if (typeof value === "string") {
    const formatted = value.replace(/[^0-9.]/g, "");

    if (!isNaN(formatted) && parseFloat(formatted)) return parseFloat(formatted);
  }

  return value;
};

export const precision = (num) => parseFloat(Number(num).toFixed(2));

export const getTaxAmount = (total, tax, tax_addition = 0) => {
  if ((tax && tax > 0) || (tax_addition && tax_addition > 0)) {
    const percentage = ((n(tax) + n(tax_addition)) * n(total)) / 100;
    return n(total) + percentage;
  }

  if (total) return n(total);

  return 0;
};

export function getDistance({ from, to, distance }, last_update_by) {
  if (last_update_by === "distance") {
    return precision(n(distance || 0));
  }

  if (from?.latitude && to?.latitude) {
    const distance = distanceBetween(
      {
        lat: from.latitude,
        lng: from.longitude,
      },
      {
        lat: to.latitude,
        lng: to.longitude,
      },
      "N",
    );

    return precision(distance);
  }

  return 0;
}

export function getFlightDuration({
  distance,
  last_update_by,
  speed,
  flight_duration,
  headwind = 0,
}) {
  if (last_update_by === "flight_duration") return precision(flight_duration);

  const total = n(distance) / (n(speed) - n(headwind || 0));

  if (total === Infinity) return 0;
  return precision(total);
}

export function getFlightTotalDuration(duration, extra = 0) {
  return n(duration) + n(extra);
}

export function getTotalAmount(duration, hour_price, tax, tax_addition = 0) {
  const total = n(duration) * n(hour_price);
  const totalWithTax = getTaxAmount(total, tax, tax_addition);

  return precision(totalWithTax);
}
