import React from "react";
import PropTypes from "prop-types";
import "./OperatorMarketAxis.css";
import ChartBtn from "../../../../../common/ChartBtn";
import useOperatorMarketAxis from "./useOperatorMarketAxis";
import Modal from "../../../../../common/Modal";
import MarketAxisContent from "./MarketAxisContent";
import Popup from "reactjs-popup";
import staticService from "../../../../../../services/static.service";

const OperatorMarketAxis = (props) => {
  const { disabled, show, axisFeature } = props;
  if (!show) return null;
  const { onClick, closeModal, isModalOpen, loading, actionsList } = useOperatorMarketAxis(props);

  return (
    <>
      <div className="gh-wrapper-progress gh-axis-btn-wrapper">
        <Popup
          trigger={
            <div>
              <ChartBtn
                disabled={loading || disabled || !axisFeature}
                onClick={onClick}
                classes={["btn-pulse"]}
              />
            </div>
          }
          on="hover"
          keepTooltipInside={true}
          disabled={!disabled && axisFeature}
        >
          {staticService.findByAlias(
            !axisFeature ? "axisDisabledFeatureTooltip" : "noProgressForThisQuote",
          )}
        </Popup>
      </div>

      {isModalOpen && !disabled && axisFeature && (
        <Modal
          className="axis-modal"
          openDefault={true}
          onClose={closeModal}
          title="Your Quote Position in the Market"
        >
          <MarketAxisContent actionsList={actionsList} />
        </Modal>
      )}
    </>
  );
};

// eslint-disable-next-line react/no-unused-prop-types
OperatorMarketAxis.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  axisFeature: PropTypes.bool,
};
OperatorMarketAxis.defaultProps = { show: true };
export default React.memo(OperatorMarketAxis);
