import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import apiHandler from "../../../services/api-handler";
import { loginByToken } from "../../../actions";
import { connect } from "react-redux";

const NewAuth = (props) => {
  const { loginByToken } = props;
  let { token } = useParams();
  let history = useHistory();

  const handleRedirect = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    apiHandler
      .setPath("login_as", "")
      .get(token)
      .then((user) => {
        loginByToken(user, handleRedirect);
      });
  }, []);

  return <div data-uk-spinner="ratio: 3" className={"uk-position-medium uk-position-center"} />;
};

NewAuth.propTypes = {
  loginByToken: PropTypes.func,
};

export default connect(null, { loginByToken })(NewAuth);
