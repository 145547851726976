import React from "react";
import marker from "../../../../assets/img/marker-blue-map.svg";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
import { GoogleApiKey } from "../../../../configs/googleApiKey";

const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <div className="gh-pad-map">
        <GoogleMap defaultZoom={16} center={props.center}>
          <Marker position={props.center} options={{ icon: marker }} />
        </GoogleMap>
      </div>
    );
  }),
);
export class BrokerPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { pad } = this.props;
    const coords = {
      lat: pad && pad.coords && parseFloat(pad.coords.split("|")[0]),
      lng: pad && pad.coords && parseFloat(pad.coords.split("|")[1]),
    };
    return (
      <div className="gh-broker-pad-map">
        <div className="gh-pad-title">
          <img className="gh-quote-pad-marker" src={marker} alt="marker" />
          <span>{this.props.pad.title}</span>
        </div>
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleApiKey}&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={coords}
        />
      </div>
    );
  }
}
