import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppRouter from "./app-router";
import {
  AlertComponent,
  AlertErrorComponent,
  alertInstance,
  AlertModalComponent,
  SpinnerComponent,
  spinnerInstance,
} from "./shared";
import auth from "./services/auth";
import staticService from "./services/static.service";
import event from "./services/event.service";
import { config } from "./configs/app-config";
import ModalAvailableCountries from "./components/common/ModalAvailableCountries";
import { AccountLocalizationProvider } from "./context";
import { SET_USER_ACCOUNT } from "./actions/actionTypes";

class App extends Component {
  constructor(props) {
    super(props);
    staticService.setGlobalMessages();
    alertInstance.eventInstance = event;
  }

  componentDidMount() {
    if (this.props.userOnline === null) {
      this.context.store.dispatch({ type: SET_USER_ACCOUNT, user: auth.getUser() });
    }
    event.setEvent("user-session-expired", () => {
      window.location.href = config.redirectToLogin;
      auth.logout();
    });
  }

  componentWillUnmount() {
    event.clearEvent("user-session-expired");
  }

  render() {
    return (
      <div className={["react-route-container"].join(" ")}>
        <AlertErrorComponent />
        <ModalAvailableCountries />
        <AlertComponent alertInstance={alertInstance} event={"global"} />
        <AlertModalComponent
          alertInstance={alertInstance}
          closeLabel={staticService.findByAlias("close")}
          event={"alert-modal-global"}
        />
        <SpinnerComponent type={"large"} service={spinnerInstance} />
        <AccountLocalizationProvider>
          <AppRouter />
        </AccountLocalizationProvider>
      </div>
    );
  }
}

App.contextTypes = {
  store: PropTypes.object,
};

App.propTypes = {
  userOnline: PropTypes.any,
};

function mapStateToProps({ userOnline }) {
  return { userOnline };
}

export default connect(mapStateToProps)(App);
