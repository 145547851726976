import {
  getDistance,
  getFlightDuration,
  getFlightTotalDuration,
  getTaxAmount,
  getTotalAmount,
  n,
} from "./formulas";

export function updateLegFlightSection(leg) {
  const values = {};
  const valuesToUpdate = {};

  const {
    duration_last_update_by,
    distance_last_update_by,
    price_per_hour,
    cruise_speed,
    extra_time,
    flight_tax,
  } = leg;

  values.distance = getDistance(leg, distance_last_update_by);
  values.flight_duration = getFlightDuration({
    distance: values.distance,
    last_update_by: duration_last_update_by,
    speed: cruise_speed,
    // headwind: n(leg.headwind),
    flight_duration: leg.flight_duration,
  });
  values.price_per_hour = n(leg.price_per_hour);
  values.total_duration = getFlightTotalDuration(values.flight_duration, extra_time || 0);
  values.total_cost_flight = getTotalAmount(
    values.total_duration,
    price_per_hour,
    flight_tax.percent,
  );

  Object.entries(values).forEach(([key, value]) => {
    if (leg[key] !== value) valuesToUpdate[key] = value;
  });

  return valuesToUpdate;
}

export function updateLegFeesSection(leg) {
  const values = {};
  const valuesToUpdate = {};

  const { departure_fee_amount, departure_fee_tax, landing_fee_amount, landing_fee_tax } = leg;

  const departure = getTaxAmount(departure_fee_amount, departure_fee_tax.percent);
  const landing = getTaxAmount(landing_fee_amount, landing_fee_tax.percent);

  let newLanding = landing === "0" || landing === "00" ? 0 : n(landing);
  let newDeparture = departure === "0" || departure === "00" ? 0 : n(departure);

  values.total_fees = newLanding + newDeparture;

  Object.entries(values).forEach(([key, value]) => {
    if (leg[key] !== value) valuesToUpdate[key] = value;
  });

  return valuesToUpdate;
}

export function updateLegExtracostsSection(leg) {
  const values = {};
  const valuesToUpdate = {};

  const { extra_cost_amount, extra_cost_tax } = leg;

  values.extra_cost_total = getTaxAmount(extra_cost_amount, extra_cost_tax.percent);

  Object.entries(values).forEach(([key, value]) => {
    if (leg[key] !== value) valuesToUpdate[key] = value;
  });

  return valuesToUpdate;
}

export function updatePilot(pilot) {
  const values = {};
  const valuesToUpdate = {};

  const { payment_amount, pilot_shift_duration, payment_tax } = pilot;

  const total = n(payment_amount || 0) * n(pilot_shift_duration || 0);

  values.total_pilot_costs = getTaxAmount(total, payment_tax.percent || 0, 0);

  Object.entries(values).forEach(([key, value]) => {
    if (pilot[key] !== value) valuesToUpdate[key] = value;
  });

  return valuesToUpdate;
}

const calculations = {
  leg: {
    flight: updateLegFlightSection,
    fees: updateLegFeesSection,
    extracost: updateLegExtracostsSection,
  },
  pilot: updatePilot,
};

export default calculations;
