import React from "react";
import PropTypes from "prop-types";
import "./checkboxFuncComponent.css";

import checkCheckbox from "../../../assets/img/svg/checkCheckbox.svg";
import staticService from "../../../services/static.service";

const CheckboxFuncComponent = ({ checked, label, onClick, alias, disabled }) => {
  return (
    <div onClick={onClick} className="gh-wrapper-custom-checkbox btn-style">
      <input
        disabled={disabled}
        type="checkbox"
        className="gh-custom-checkbox"
        checked={checked}
        onChange={() => {}}
      />
      <span className="gh-checkmark">
        <img className="gh-check-icon" src={checkCheckbox} alt="check" />
      </span>
      {alias ? (
        <label
          className={`label ${checked ? "checked" : ""}`}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias(alias),
          }}
        />
      ) : (
        <label className={`label ${checked ? "checked" : ""}`}>{label}</label>
      )}
    </div>
  );
};

CheckboxFuncComponent.defaultProps = {
  label: "",
};

CheckboxFuncComponent.propTypes = {
  checked: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func,
  alias: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckboxFuncComponent;
