import React from "react";
import PropTypes from "prop-types";
import { redirectTo, storage, TabComponent } from "../../../../shared";
import { accountTabs } from "../../../../configs";
import { BlockComponent } from "../../../common";
import { UserProfileComponent } from "../common";
import Auth from "../../../../services/auth";
import staticService from "../../../../services/static.service";
import { AccountOnlineLayout } from "../../../layouts";
import PricingPlan from "../../../common/PricingPlan";
import { ComponentAuthorization, IsVerifiedAuthorization } from "../../..";
import { connect } from "react-redux";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class BrokerPricingPlanComponent extends UserProfileComponent {
  constructor(props) {
    super(props);
    const items = accountTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      selectedTab: items[1],
      tabs: items,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    const { history } = this.props;
    if (Auth.getMainRole() === "broker" && !this.props.company.hasOwnProperty("bundle")) {
      return history.push("/account/profile");
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (Auth.getMainRole() === "broker" && !nextProps.company.hasOwnProperty("bundle")) {
      return nextProps.history.push("/account/profile");
    }
  }

  requestButtonProps() {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  get requestBtn() {
    return (
      <div className="uk-width-1-1">
        <ComponentAuthorization
          component={IsVerifiedAuthorization}
          componentProps={this.requestButtonProps()}
          roles={["broker_admin", "broker_team", "sole_trader"]}
        />
      </div>
    );
  }

  render() {
    const { selectedTab, tabs } = this.state;
    const { company } = this.props;

    if (
      company.bundle === undefined ||
      (company.bundle && Object.keys(company.bundle).length === 0)
    ) {
      return redirectTo("/account/profile");
    }

    return (
      <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
        <section className={"team-page"}>
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom header-title",
            }}
          >
            <div className="uk-width-1-1">
              <h3
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("account"),
                }}
              />
            </div>
            <div className={"uk-margin-bottom"}>{this.requestBtn}</div>
          </BlockComponent>
          <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
            <TabComponent
              items={tabs}
              id={"profile-tab"}
              visible={true}
              tabProps={{ className: "uk-tab uk-margin" }}
              onClick={this.onTabClick.bind(this)}
              selected={selectedTab}
              linkType={"nav"}
            />
            <div data-uk-grid>
              <div className="uk-width-1-1@s uk-width-1-3@m uk-visible@m">
                <div className="uk-width-3-4 uk-margin-small-top" />
              </div>
              <div className="uk-margin-top uk-width-3-1@s uk-width-2-3@m">
                <h2 className={"gh-text-slim uk-margin-remove-bottom"}>Pricing Plan</h2>
                <PricingPlan commission={company.commission} bundle={company.bundle} />
              </div>
            </div>
          </BlockComponent>
        </section>
      </AccountOnlineLayout>
    );
  }
}

BrokerPricingPlanComponent.contextTypes = {
  store: PropTypes.object,
};

const COMPONENT = connect(({ company }) => ({ company }))(BrokerPricingPlanComponent);
export { COMPONENT as BrokerPricingPlanComponent };
