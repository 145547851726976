import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form } from "react-redux-form";
import { updateAccount, getAccountDetails, getAccountDetailsSuccess } from "../../../actions";
import { spinnerInstance } from "../../../shared";
import { config } from "../../../configs";
import staticService from "../../../services/static.service";
import Auth from "../../../services/auth";
import StripeLogoWhite from "../../../assets/img/svg/stripe_logo_white.svg";
import Tooltip from "../../common/Tooltip";

const modelName = "accountDetail";
class AccountFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      submittedForm: false,
    };
    context.store.dispatch(actions.reset(modelName));
  }

  componentWillMount() {
    spinnerInstance.setProp("type", "large");
    this.props.loadAccount();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.accountDetail !== nextProps.accountDetail) {
      this.props.updateAddressModel(nextProps.accountDetail);
    }

    if (
      this.props.uploadDocuments.event !== nextProps.uploadDocuments.event &&
      nextProps.uploadDocuments.event
    ) {
      this.props.loadAccount();
    }
  }

  submit(model) {
    if (this.props.accountDetailStatus) {
      this.setState({ submittedForm: true });
    } else {
      spinnerInstance.setProp("type", "large").show();
      this.props.updateAccount(model, this.onSubmitSuccess.bind(this));
    }
  }

  onSubmitSuccess(account) {
    this.props.updateAccountDetails(account);
  }

  get statusCss() {
    const { account } = this.props;
    if (account && account.account_details_warning === false) {
      return "success";
    }
    return "warning";
  }
  get showMessage() {
    const { account } = this.props;
    if (!Auth.hasStripeAccount()) {
      return "operatorAccountFailVerification";
    } else {
      if (account && account.account_details_message) {
        return account.account_details_message;
      } else {
        return false;
      }
    }
  }

  render() {
    const { account } = this.props;
    const stripeUrl = account && account.stripe_account_link && account.stripe_account_link.url;
    const { submittedForm } = this.state;
    if (submittedForm) {
      spinnerInstance.setProp("type", "large").show();
      window.location.href = stripeUrl;
    }
    return (
      <div className="uk-align-center">
        <Form model={"accountDetail"} onSubmit={this.submit.bind(this)}>
          <div className={"uk-alert gh-alert-without-bg uk-alert-" + this.statusCss}>
            <div data-uk-grid className="uk-text-center">
              <div className="uk-width-expand">
                {this.showMessage ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias(this.showMessage),
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <Control.button
            model={"accountDetail"}
            className={"uk-button uk-button-primary uk-align-center"}
          >
            {Auth.isStripeVerified() ? (
              <span className="uk-flex uk-flex-middle uk-flex-center">
                <span>Update</span>
                <img
                  src={StripeLogoWhite}
                  width="57"
                  style={{ transform: "translateY(1px)" }}
                  alt="Stripe"
                />
                <span>Account</span>
              </span>
            ) : (
              <span className="uk-flex uk-flex-middle uk-flex-center">
                <span>Begin</span>
                <img
                  src={StripeLogoWhite}
                  width="57"
                  style={{ transform: "translateY(1px)" }}
                  alt="Stripe"
                />
                <span>Onboarding</span>
              </span>
            )}
          </Control.button>
        </Form>
        <div className="just-accordion_footer">
          <span>
            <span>Powered by</span>
            <span>
              <img src={StripeLogoWhite} alt="Stripe" />
            </span>
          </span>
          <Tooltip
            pos="top-left"
            refEl="self"
            contentClass="stripe-tooltip-content"
            trigger={<span className="icon" uk-icon="info" />}
          >
            {staticService.findByAlias("stripeTooltipText")}
          </Tooltip>
        </div>
      </div>
    );
  }
}

AccountFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  const response = {
    accountDetailStatus: false,
    account: state.accountDetail,
    uploadDocuments: state.uploadDocuments,
    form: state.forms.accountDetail,
  };
  if (state.auth.stripe_account_link) {
    response.accountDetail = state.auth;
    response.accountDetailStatus = true;
  }
  return response;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadAccount: getAccountDetails,
      updateAccountDetails: (account) => dispatch(getAccountDetailsSuccess(account)),
      updateAccount: updateAccount,
      updateAddressModel: (model) => {
        return dispatch(actions.change("accountDetail", model));
      },
      updateModel: (model, value) => {
        const dob = {
          day: value.format("DD"),
          month: value.format("MM"),
          year: value.format("YYYY"),
        };
        dispatch(actions.change("accountDetail.dob", dob));
        return dispatch(actions.change(model, value.format(config.dobFormat)));
      },
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(AccountFormComponent);
export { COMPONENT as AccountFormComponent };
