import * as types from "./actionTypes";
import {
  GET_DASHBOARD_AIRCRAFTS,
  MENU_ITEMS,
  PREVIOUS_PATH,
  UPDATE_STEPS_ENQUIRE_FORMS,
} from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import { staticText } from "../configs/";
import { getLastItemFromArray, showFile, spinnerInstance, storage } from "../shared/";
import staticService from "../services/static.service";
import tranformDateTimeToISO from "../utils/tranformDateTimeToISO";
import auth from "../services/auth";

export function loadTermConditionSuccess(data) {
  return { type: types.LOAD_CONDITION_SUCCESS, data };
}

export function validateInvitationSuccess(data) {
  return { type: types.ACCOUNT_INVITATIONS_VALIDATION, data };
}

export function validateDocumentsSuccess(data) {
  return { type: types.ACCOUNT_DOCUMENTS_VALIDATION, data };
}

export function submitContactSuccess(data) {
  return { type: types.SUBMIT_CONTACT_FORM, data };
}

export function uploadDocumentsSuccess(data) {
  return { type: types.ACCOUNT_DOCUMENTS_UPLOAD, data };
}

export function validateAircraftListSuccess(data) {
  return { type: types.ACCOUNT_FLEET_VALIDATION, data };
}

export function loadUserDashboardStatisticSuccess(data) {
  return { type: types.GET_DASHBOARD_STATISTIC, data };
}

export function loadHomeContentSuccess(data) {
  return { type: types.LOAD_HOME_DATA, data };
}

export function getRegisterCountriesSuccess(data) {
  return { type: types.GET_REGISTER_COUNTRIES, data };
}

export function getCurrenciesSuccess(data) {
  return { type: types.GET_CURRENCIES, data };
}

export function loadEventContentSuccess(data) {
  return { type: types.LOAD_EVENT_DATA, data };
}

export function loadDestinationContentSuccess(data) {
  return { type: types.LOAD_EVENT_DATA, data };
}

export function loadMoreEventContentSuccess(data) {
  return { type: types.SHOW_MORE_EVENTS, data };
}

export function loadMoreDestinationContentSuccess(data) {
  return { type: types.SHOW_MORE_DESTINATIONS, data };
}

export function loadEventInformationtSuccess(data) {
  return { type: types.LOAD_EVENT_INFORMATION_DATA, data };
}

export function loadDestinationInformationtSuccess(data) {
  return { type: types.LOAD_DESTINATION_INFORMATION_DATA, data };
}

export function getHeliOptionsSuccess(data) {
  return { type: types.GET_HELI_OPTIONS, data };
}

export function storeRequestSuccess(data) {
  return { type: types.STORE_REQUEST, data };
}

export function closeConfirmDialog() {
  return { type: types.CLOSE_CONFIRM_DIALOG, visible: false };
}

export function openConfirmDialog() {
  return { type: types.OPEN_CONFIRM_DIALOG, visible: true };
}

export function loadUserDashboardStatistic() {
  return function (dispatch) {
    return Api.setPath("user")
      .setQueryParams()
      .getAll()
      .then((data) => {
        if (auth.getMainRole() === "operator") {
          storage.add("userTerms", JSON.stringify(data.terms_status));
        }
        dispatch(loadUserDashboardStatisticSuccess(data));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadTermCondition(type, country = "") {
  return function (dispatch) {
    return Api.setPath("term-condition", "")
      .setQueryParams([{ key: "country_code", value: country }])
      .get(type)
      .then((data) => dispatch(loadTermConditionSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadHomeContent() {
  return function (dispatch) {
    return Api.setPath("home", "")
      .setQueryParams([])
      .getAll()
      .then((data) => dispatch(loadHomeContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getRegisterCountries(role) {
  return function (dispatch) {
    return Api.setPath("countries", role)
      .setQueryParams([])
      .getAll()
      .then((data) => dispatch(getRegisterCountriesSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getStaticCountry(code) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath("static", "")
      .setQueryParams([{ key: "country_code", value: code }])
      .getAll()
      .then((data) => {
        staticService.setItems(data);
        spinnerInstance.setProp("type", "large").hide();
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getCurrencies() {
  return function (dispatch) {
    return Api.setPath("currencies")
      .setQueryParams([])
      .getAll()
      .then((data) => dispatch(getCurrenciesSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadEventContent(page, items = []) {
  return function (dispatch) {
    return Api.setPath("public_events", "")
      .setQueryParams([{ key: "page", value: page }].concat(items))
      .getAll()
      .then((data) => dispatch(loadEventContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadMoreEventContent(page, items = []) {
  return function (dispatch) {
    return Api.setPath("public_events", "")
      .setQueryParams([{ key: "page", value: page }].concat(items))
      .getAll()
      .then((data) => dispatch(loadMoreEventContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadFilteredEvents(page, items = []) {
  return function (dispatch) {
    return Api.setPath("public_events", "")
      .setQueryParams([{ key: "page", value: page }].concat(items))
      .getAll()
      .then((data) => dispatch(loadEventContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadMoreDestinationContent(page, items = []) {
  return function (dispatch) {
    return Api.setPath("public_events", "")
      .setQueryParams([{ key: "page", value: page }].concat(items))
      .getAll()
      .then((data) => dispatch(loadMoreDestinationContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadEventInformationContent(id, onError) {
  return function (dispatch) {
    return Api.setPath("public_events", id)
      .setQueryParams([])
      .getAll()
      .then((data) => dispatch(loadEventInformationtSuccess(data)))
      .catch((err) => {
        if (onError) onError(err);
        handlerError(err, dispatch);
      });
  };
}

export function loadDestinations() {
  return function (dispatch) {
    return Api.setPath("destinations", "")
      .setQueryParams([])
      .getAll()
      .then((data) => dispatch(loadDestinationContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadDestinationInformationContent(id, onError) {
  return function (dispatch) {
    return Api.setPath("destinations", id)
      .setQueryParams([])
      .getAll()
      .then((data) => dispatch(loadDestinationInformationtSuccess(data)))
      .catch((err) => {
        if (onError) onError(err);
        handlerError(err, dispatch);
      });
  };
}

export function loadFilteredDestinations(page, items = []) {
  return function (dispatch) {
    return Api.setPath("destinations", "")
      .setQueryParams([{ key: "page", value: page }].concat(items))
      .getAll()
      .then((data) => dispatch(loadDestinationContentSuccess(data)))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getHeliOptions(data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "enquireForm").setProperty("skipErrorAlert", true);
    return Api.setPath("get-heli-options", "")
      .setQueryParams(data)
      .getAll()
      .then((data) => {
        dispatch(getHeliOptionsSuccess(data.message));
        Api.setProperty("skipErrorAlert", false);
        return Promise.resolve(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getHeliOptionsDestination(data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "enquireForm").setProperty("skipErrorAlert", true);
    return Api.setPath("get-heli-options-destination", "")
      .setQueryParams(data)
      .getAll()
      .then((data) => {
        dispatch(getHeliOptionsSuccess(data.message));
        Api.setProperty("skipErrorAlert", false);
        return Promise.resolve(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateEventEnquireLog(id, data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "enquireForm").setProperty("skipErrorAlert", true);
    return Api.setPath("update-event-enquiry-log", `${id}`)
      .post(data)
      .then((res) => {
        Api.setProperty("skipErrorAlert", false);
        return Promise.resolve(res);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateDestinationEnquireLog(id, data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "enquireForm").setProperty("skipErrorAlert", true);
    return Api.setPath("update-destination-enquiry-log", `${id}`)
      .post(data)
      .then((res) => {
        Api.setProperty("skipErrorAlert", false);
        return Promise.resolve(res);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function storeRequest(data) {
  const values = { ...data };

  const date = tranformDateTimeToISO(values.date);

  values.date = date;
  values.departure_time = tranformDateTimeToISO(date, values.departure_time);

  if (values.return_time) {
    values.return_time = tranformDateTimeToISO(date, values.return_time);
  }

  return function (dispatch) {
    return Api.setPath("public_events", "enquiry")
      .post(values)
      .then((data) => {
        dispatch(storeRequestSuccess(data));
        return Promise.resolve(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function storeRequestDestination(data) {
  const values = { ...data };

  const date = tranformDateTimeToISO(values.date);

  values.date = date;
  values.departure_time = tranformDateTimeToISO(date, values.departure_time);

  if (values.return_time) {
    values.return_time = tranformDateTimeToISO(date, values.return_time);
  }

  return function (dispatch) {
    return Api.setPath("destinations", "enquiry")
      .post(values)
      .then((data) => {
        dispatch(storeRequestSuccess(data));
        return Promise.resolve(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateStepEnquireForms(step) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_STEPS_ENQUIRE_FORMS,
      payload: step,
    });
  };
}

export function validateInvitations(invitations, handleValidationSuccess, handleValidationErrors) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "invitationsModel").setProperty(
      "skipErrorAlert",
      true,
    );
    return Api.setPath("invitations", "validate")
      .post({ emails: invitations })
      .then((response) => {
        handleValidationSuccess();
        dispatch(validateInvitationSuccess(response));
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => {
        handleValidationErrors(err);
        handlerError(err, dispatch);
      });
  };
}

export function validateDocuments(files, onError, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "companyFilesModel").setProperty(
      "skipErrorAlert",
      true,
    );
    const _form = [];

    files.forEach((file) => {
      _form.push({
        size: file.size,
        mimetypes: file.type,
        name: file.name,
        document_type: file.document_type,
      });
    });
    return Api.setPath("documents", "validate")
      .post({ files: _form })
      .then((response) => {
        dispatch(validateDocumentsSuccess(response));
        if (onSuccess) onSuccess(response);
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => {
        if (onError) onError(err);
        return handlerError(err, dispatch);
      });
  };
}

export function validateUploadedDocuments(files, onError, onSuccess, model) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, model).setProperty("skipErrorAlert", true);
    const _form = [];

    files.forEach((file) => {
      _form.push({ size: file.size, mimetypes: file.type, name: file.name });
    });
    return Api.setPath("documents", "validate")
      .post({ files: _form })
      .then((response) => {
        dispatch(validateDocumentsSuccess(response));
        if (onSuccess) onSuccess(response);
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => {
        if (onError) onError(err);
        return handlerError(err, dispatch);
      });
  };
}

export function uploadDocuments(files, onSuccess, onError) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "companyFilesModel").setProperty(
      "responseMessages",
      staticText.globalMessages.user.stripe,
    );
    const form = new FormData();
    files.forEach((file, i) => form.append(`files[${i}]`, file));
    form.append("verification", true);
    return Api.setPath("documents", "stripe")
      .post(form)
      .then((response) => {
        dispatch(uploadDocumentsSuccess(response));
        onSuccess(response);
      })
      .catch((err) => {
        if (onError) onError(err);
        return handlerError(err, dispatch);
      });
  };
}

export function uploadStripeDocument(file, onSuccess, onError) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "companyFilesModel").setProperty(
      "responseMessages",
      staticText.globalMessages.user.stripe,
    );
    return Api.setPath("documents", "stripe")
      .post(file)
      .then((response) => {
        dispatch(uploadDocumentsSuccess(response));
        onSuccess(response);
      })
      .catch((err) => {
        if (onError) onError(err);
        return handlerError(err, dispatch);
      });
  };
}

export function validateAircraftList(data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "fleetModel").setProperty("skipErrorAlert", true);
    return Api.setPath("aircraft", "list/validate")
      .post({ list: data })
      .then((data) => {
        dispatch(validateAircraftListSuccess(data));
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function submitContact(data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "contactFormModel").setProperty(
      "responseMessages",
      staticText.globalMessages.conctactForm,
    );
    return Api.setPath("home", "contact")
      .post(data)
      .then((data) => {
        dispatch(submitContactSuccess(data));
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function subscribe(onSuccess, onError, data) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large");
    Api.setDispatchAndValidationModel(dispatch, "subscribeModel").setProperty(
      "responseMessages",
      staticText.globalMessages.subscribe,
    );
    return Api.setPath("subscribe")
      .post(data)
      .then((data) => {
        onSuccess(data);
      })
      .catch((err) => onError(err));
  };
}

export function saveFiles(files) {
  const form = new FormData();
  files.forEach((file, i) => form.append(`files[${i}]`, file));
  return Api.setPath("documents", null).post(form);
}

export function removeInvitationSuccess(invitations) {
  return { type: types.INVITATION_REMOVE_SUCCESS, invitations };
}

export function removeInvitation(id) {
  return function (dispatch) {
    return Api.setPath("invitations")
      .setProperty("responseMessages", staticText.globalMessages.invitation)
      .delete(id)
      .then((response) => {
        dispatch(removeInvitationSuccess(response));
        return response;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function download(alias) {
  return function (dispatch) {
    return Api.setPath("agreement", "")
      .download(alias)
      .then((r) => r.blob())
      .then(showFile.bind(null, "platform_" + alias.toLowerCase()))
      .catch((err) => handlerError(err, dispatch));
  };
}

export function downloadFileByUrl(url, filename, type) {
  const splitFileName = filename.split(".");
  const isFileExtension = splitFileName.length > 1;
  let name = isFileExtension ? splitFileName.slice(0, -1).join("") : filename;
  let fileType = isFileExtension ? getLastItemFromArray(splitFileName) : null;

  return function (dispatch) {
    return Api.downloadByUrl(url)
      .then((r) => {
        return r.blob();
      })
      .then(showFile.bind(null, name || filename || "document", type || fileType))
      .catch((err) => handlerError(err, dispatch));
  };
}

export const setPrevPath = (path) => (dispatch) => {
  return dispatch({
    type: PREVIOUS_PATH,
    path,
  });
};

export function getDashboardAircrafts() {
  return function (dispatch) {
    return Api.setPath("empty-leg-aircrafts", "")
      .getAll()
      .then((res) => {
        const getLabel = (helicopter) => {
          return `${helicopter.registration_number}  ${helicopter.type.manufacturer.mark} ${
            helicopter.type.model
          } (${staticService.findByAlias("maxPassengersLabel")} ${helicopter.max_passengers})`;
        };

        const aircrafts = res.message.map((aircraft) => ({
          ...aircraft,
          label: getLabel(aircraft),
          value: aircraft.id,
        }));

        return dispatch({ type: GET_DASHBOARD_AIRCRAFTS, data: aircrafts });
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
export function getMenuItems() {
  return function (dispatch) {
    return Api.setPath("site-data", "")
      .getAll()
      .then((data) => {
        return dispatch({ type: MENU_ITEMS, data });
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
