import * as types from "./actionTypes";
import { STATUS_QUOTE_UPDATED } from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import auth from "../services/auth";
import { config, staticText } from "../configs/";
import GetHeliEvents from "../services/event.service";
import { spinnerInstance } from "../shared/spinner";
import { showFile } from "../shared";
import { getBrokerRequestDetail } from "./request-actions";
import { showNotification } from "../utils/showNotification";

const entity = "quote";

const actionTypes = {
  rejected: types.REJECT_QUOTE,
  booking_init: types.SELECT_QUOTE,
  withdrawn: types.QUOTE_WITHDRAWN,
  unsuccessful: types.QUOTE_UNSUCCESSFULL,
};

export function rejectedSuccess(response) {
  return { type: types.REJECT_QUOTE, response };
}

export function getOperatorQuotesSuccess(quotes) {
  return { type: types.GET_QUOTE_LIST, quotes };
}

export function getQuoteSuccess(quote) {
  return { type: types.GET_QUOTE_ITEM, quote };
}

export function addDiscussionMessageSuccess(messages) {
  return { type: types.ADD_DISCUSSION_MESSAGE, messages };
}

export function getDiscussionMessagesSuccess(messages) {
  return { type: types.GET_DISCUSSION_MESSAGES, messages };
}

export function withdrawQuoteSuccess(response) {
  return { type: types.QUOTE_WITHDRAWN, response: response.data };
}

export function addQuoteSuccess(quote) {
  return { type: types.ADD_NEW_QUOTE, quote };
}

export function cleanQuoteDetails() {
  return { type: types.QUOTE_DETAIL_CLEAN, data: {} };
}

export function rejectQuote(data) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "feedbackModel");
    return Api.setPath(entity, `broker/${data.status}`)
      .put(data.id, data)
      .then((response) => {
        dispatch(rejectedSuccess(response));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateQuoteStatus(
  data,
  placeHolders = { action: "" },
  messageKey = "quote",
  onSuccess,
) {
  return function (dispatch) {
    Api.setProperty("skipErrorAlert", false)
      .setProperty("responseMessages", staticText.globalMessages[messageKey])
      .setProperty("messagePlaceholders", placeHolders);
    return Api.setPath(entity, `${auth.getMainRole()}/${data.status}`)
      .put(data.id, data)
      .then((response) => {
        dispatch({ type: actionTypes[data.status], response });
        if (onSuccess) onSuccess();
        return Promise.resolve();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function quoteWithdrawByBroker(data, placeHolders = { action: "" }, onError) {
  return function (dispatch) {
    Api.setProperty("skipErrorAlert", true)
      .setProperty("responseMessages", staticText.globalMessages.quoteRequestWithdraw)
      .setProperty("messagePlaceholders", placeHolders);
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath(entity, `${auth.getMainRole()}/${data.status}`)
      .put(data.id, data)
      .then((response) => {
        dispatch({ type: actionTypes[data.status], response });
        Api.setProperty("skipErrorAlert", false);
        Api.triggerAlert(Api.serverResponse);
        const quote = response.find((q) => q.id === data.id);
        dispatch({ type: STATUS_QUOTE_UPDATED, response: quote });
        spinnerInstance.setProp("type", "large").hide();
      })
      .catch((err) => {
        if (onError) onError();
        if (err.status === 423 && err.data.error.refresh) {
          Api.setProperty("alertModal", true);
          Api.triggerError(err);
          Api.setProperty("alertModal", false);
          dispatch(getBrokerRequestDetail(data.request));
        } else {
          Api.triggerError(err);
        }
        return handlerError(err, dispatch);
      });
  };
}

export function addDiscussionMessage(quoteId, value) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "feedbackModel.message").setProperty(
      "skipErrorAlert",
      true,
    );
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath("quote-comments", `${quoteId}`)
      .post(value.message ? { body: value.message, date: value.date } : value.file)
      .then((data) => {
        dispatch({ type: types.ADD_ERROR, error: [] });
        Api.setProperty("skipErrorAlert", true);
        dispatch(addDiscussionMessageSuccess(data));
        spinnerInstance.setProp("type", "large").hide();
        return data;
      })
      .catch((err) => {
        if (err.data.message) {
          showNotification(err.data.message, "warning");
        }

        if (err.message) {
          showNotification(err.message, "warning");
        }

        spinnerInstance.setProp("type", "large").hide();
        return handlerError(err, dispatch);
      });
  };
}

export function getDiscussionMessages(quoteId) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").hide();
    return Api.setProperty("skipErrorAlert", true)
      .setPath("quote-comments", quoteId)
      .getAll()
      .then((data) => {
        Api.setProperty("skipErrorAlert", true);
        dispatch(getDiscussionMessagesSuccess(data));
        return Promise.resolve(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getQuotePDF(obj) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath("quote-details-pdf", "")
      .download(
        `${obj.quote_id}/${obj.aircraft_id}?image_id=${obj.image_id}&timezone=${obj.timezone}`,
      )
      .then((r) => r.blob())
      .then(showFile.bind(null, config.prefixes.quote + obj.quote_id))
      .then(() => spinnerInstance.setProp("type", "large").hide())
      .catch((err) => handlerError(err, dispatch));
  };
}

export const sendToAmendQuote = (quoteID) => (dispatch) => {
  return dispatch({ type: types.QUOTE_TO_AMEND, payload: quoteID });
};

export const removeToAmendQuote = () => (dispatch) => {
  return dispatch({ type: types.REMOVE_QUOTE_TO_AMEND });
};

export function withdrawQuote(data, onSuccess, onError) {
  return function (dispatch) {
    Api.setProperty("responseMessages", staticText.globalMessages.quoteWithdrawn2)
      .setProperty("messagePlaceholders", { entityName: "Quote" })
      .setProperty("skipErrorAlert", true);
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath(entity, "")
      .put(data.id, data)
      .then((quote) => {
        dispatch(withdrawQuoteSuccess(quote));
        if (onSuccess) onSuccess(quote);
        Api.setProperty("skipErrorAlert", false);
        Api.triggerAlert(Api.serverResponse);
        spinnerInstance.setProp("type", "large").hide();
      })
      .catch((err) => {
        if (onError) onError();
        if (err.status === 423 && err.data.error.refresh) {
          Api.setProperty("alertModal", true);
          Api.triggerError(err);
          Api.setProperty("alertModal", false);
          dispatch(getQuote(data.id));
        } else {
          Api.triggerError(err);
        }
        return handlerError(err, dispatch);
      });
  };
}

export function getOperatorPreQuotes(page, filters = []) {
  return function (dispatch) {
    return Api.setPath("get-pre-quotes", "")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((quotes) => {
        GetHeliEvents.trigger("quotes");
        dispatch(getOperatorQuotesSuccess(quotes));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getOperatorQuotes(page, filters = []) {
  const quoteTab = filters.find((filter) => filter.key === "status").value;

  if (quoteTab === "pre_quotes") {
    return getOperatorPreQuotes(page, filters);
  }

  return function (dispatch) {
    return Api.setPath(entity, "")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((quotes) => {
        GetHeliEvents.trigger("quotes");
        dispatch(getOperatorQuotesSuccess(quotes));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getOperatorFilterQuotes(page, filters = []) {
  return function () {
    return Api.setPath(entity, "")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getQuote(id, onSuccess = () => {}) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath(entity, "")
      .setQueryParams([])
      .get(id)
      .then((quote) => {
        Api.setProperty("skipErrorAlert", false);
        spinnerInstance.setProp("type", "large").hide();
        Api.setProperty("showSpinner", false);
        dispatch(getQuoteSuccess(quote));
        if (onSuccess !== null) onSuccess(quote);
        return Promise.resolve(quote);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function extendExpirationDate(id, date, quote) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath(entity, "extent-expiration-date")
      .post({ quote_id: id, date: date })
      .then((res) => {
        showNotification(res.message, "success");
        dispatch(getQuoteSuccess({ ...quote, expiration_time: date }));
        spinnerInstance.setProp("type", "large").hide();
        return res;
      })
      .catch((err) => {
        showNotification(err.message, "danger");
        return err;
      });
  };
}

export function addQuote(quote, onSuccess) {
  return function (dispatch) {
    spinnerInstance.setProp("size", "large").show();
    return Api.setPath(entity, `save-quote/${quote.quote.id}`)
      .post(quote)
      .then((quote) => {
        dispatch(addQuoteSuccess(quote));
        if (onSuccess) {
          onSuccess(quote);
        }
        Api.setProperty("skipErrorAlert", false);
        spinnerInstance.setProp("size", "large").hide();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function clearQuote() {
  return function (dispatch) {
    return dispatch(addQuoteSuccess({}));
  };
}

export function getFullQuote(quote_id) {
  return function (dispatch) {
    spinnerInstance.setProp("size", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath(entity, "")
      .get(quote_id)
      .then((res) => {
        Api.setProperty("skipErrorAlert", false);
        spinnerInstance.setProp("size", "large").hide();
        return res;
      })
      .catch((err) => {
        spinnerInstance.setProp("size", "large").hide();
        handlerError(err, dispatch);
      });
  };
}

export function markAssSeenQuotesComments(quoteId) {
  return function () {
    return Api.setPath("quote-comments/mark-as-seen")
      .get(quoteId)
      .then((response) => response.data)
      .catch((err) => err);
  };
}

export function updateQuoteBooking(data) {
  return function (dispatch) {
    dispatch({ type: types.UPDATE_QUOTE_BOOKING, data });
  };
}
