import React from "react";
import PropTypes from "prop-types";
import { Control, Form, Errors, actions } from "react-redux-form";
import { validationRules, validationMessages } from "../../configs";
import { CustomValidateError, updateModel } from "../../shared";
import staticService from "../../services/static.service";
import { CommentRowComponent, RequestBlockComponent } from "../pages/user-online/common";
import { List } from "../../shared/list";
import auth from "../../services/auth";

const { required } = validationRules;

export class FlightItemNoteFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.addComment = this.addComment.bind(this);
  }

  addComment(model) {
    const value = model.body.trim();
    if (value) {
      this.context.store.dispatch(
        this.props.action(this.props.id, value, staticService.findByAlias("noteSaved")),
      );
      this.context.store.dispatch(actions.reset(this.props.modelName));
    }
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset(this.props.modelName));
  }

  render() {
    const notesCount = this.props.noteList.length;
    const { placeholder } = this.props;
    const textAreaClass = window.location.pathname.search("/flights/my-bookings") !== -1;
    return (
      <div>
        <div
          className={`flight-item-note-form gh-comment-section uk-background-default uk-padding-small uk-border-rounded ${this.props.className}`}
        >
          <RequestBlockComponent topPosition="flight-item-note-form" title={this.props.title}>
            {!!notesCount && (
              <List attrProps={{ className: "disabled-list" }}>
                {this.props.noteList.map((note, index) => (
                  <CommentRowComponent
                    posted={note.date}
                    key={index}
                    index={index}
                    content={note.comment}
                    user={note.commenter}
                    total={notesCount}
                  />
                ))}
              </List>
            )}
          </RequestBlockComponent>
        </div>
        {this.props.canWriteNotes && (
          <Form onSubmit={this.addComment} model="flightItemNoteModel">
            <div className={!textAreaClass && "gh-internal-area"}>
              <Control.textarea
                className="uk-textarea gh-flight-item-note-form-textarea"
                model={".body"}
                validators={{ required }}
                changeAction={updateModel}
                disabled={!auth.isVerified()}
                placeholder={placeholder}
              />
              <Errors
                model={".body"}
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  required: validationMessages().requiredMessage.bind(null, "Note"),
                }}
              />
            </div>
            <div className={"uk-margin-small-top"}>
              <Control.button
                model={"flightItemNoteModel"}
                disabled={{ valid: false }}
                className={"uk-button uk-button-primary gh-flight-notes-submit-button"}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.props.buttonText || staticService.findByAlias("addNote"),
                  }}
                />
              </Control.button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

FlightItemNoteFormComponent.contextTypes = {
  store: PropTypes.object,
};

FlightItemNoteFormComponent.defaultProps = {
  modelName: "flightItemNoteModel",
  noteList: [],
  className: "",
  placeholder: "",
};

FlightItemNoteFormComponent.propTypes = {
  id: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  modelName: PropTypes.string,
  canWriteNotes: PropTypes.bool,
  title: PropTypes.any,
  noteList: PropTypes.arrayOf(
    PropTypes.shape({
      commenter: PropTypes.string,
      comment: PropTypes.string,
      datetime: PropTypes.instanceOf(Date),
    }),
  ),
  className: PropTypes.string,
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
};
