import React from "react";
import PropTypes from "prop-types";

class CheckboxComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({ checked: this.props.defaultChecked });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.defaultChecked });
  }

  onChange() {
    if (!this.props.isDisabled) {
      this.setState((prevState) => ({
        checked: !prevState.checked,
      }));
      this.props.onClick(this.state.checked, this.props.value, this.props.modelKey);
    }
  }

  render() {
    return (
      <div onClick={this.onChange}>
        <input
          type="checkbox"
          value={this.props.value !== null ? this.props.value : ""}
          onChange={() => {}}
          checked={this.state.checked}
          disabled={this.props.isDisabled}
          className={"uk-checkbox"}
        />
        {this.props.showLabel ? <label className={"label"}>{this.props.label}</label> : null}
      </div>
    );
  }
}

CheckboxComponent.propTypes = {
  label: PropTypes.string,
  value: () => {
    PropTypes.string || PropTypes.number;
  },
  modelKey: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export { CheckboxComponent };
