import React, { useState } from "react";
import PropTypes from "prop-types";
import leftArrow from "../../../../assets/img/arrow-left.svg";
import uid from "../../../../utils/uid";

const SubMenuEventItem = (props) => {
  const { item, name } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleItemMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <li className={`header-desktop-menu-item ${isOpen ? "--open" : ""}`} key={uid().uid}>
      <span className="link" onClick={toggleItemMenu}>
        {name}
        <img src={leftArrow} alt="down" className="gh-dropdown-arrow" />
      </span>

      <ul className="gh-subnav-menu">
        {/*bestsellers*/}
        {item?.bestsellers?.length ? (
          <>
            <li className="header-desktop-menu-item">
              <span className="link title first-row">Popular events</span>
            </li>
            {item?.bestsellers.map((ev) => (
              <li className="header-desktop-menu-item" key={uid().uid}>
                <a className="link --sub-link" href={`/events/${ev.url}`}>
                  {ev.name}
                </a>
              </li>
            ))}
          </>
        ) : (
          ""
        )}

        {/*events*/}
        {Object.keys(item?.events)?.length
          ? Object.keys(item?.events).map((key, index) => (
              <React.Fragment key={uid().uid}>
                <li className="header-desktop-menu-item">
                  <span
                    className={`link title ${
                      !item?.bestsellers?.length && index === 0 ? "first-row" : ""
                    }`}
                  >
                    {key}
                  </span>
                </li>

                {item.events[key].map((ev) => (
                  <li className="header-desktop-menu-item" key={uid().uid}>
                    <a className="link --sub-link" href={`/events/${ev.url}`}>
                      {ev.name}
                    </a>
                  </li>
                ))}
              </React.Fragment>
            ))
          : ""}
      </ul>
    </li>
  );
};

SubMenuEventItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
};

export default SubMenuEventItem;
