import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Tooltip from "../Tooltip";
import PricingPlanAlert from "./PricingPlanAlert";

import "./styles/index.css";

const formatter = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

function PricingPlanBundle(props) {
  const { bundle, screenDimensions } = props;

  const formatPrice = (price) => {
    if (price) {
      let price_to_format = price;

      if (typeof price_to_format === "string") {
        price_to_format = price_to_format.replaceAll(",", "");
      }

      return `£ ${formatter.format(price)}`;
    }

    return "£ 0";
  };

  return (
    <div className="pricing-plan-form">
      <div className="pricing-plan-group">
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">Pricing Plan</label>
          <input value={bundle.display_name} className="uk-input" disabled />
        </div>
      </div>

      <div className="pricing-plan-group">
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">Commencement date</label>
          <input
            value={moment(bundle.started_at).format("DD MMMM, YYYY")}
            className="uk-input"
            disabled
          />
        </div>
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">End date</label>
          <input
            value={moment(bundle.ended_at).format("DD MMMM, YYYY")}
            className="uk-input"
            disabled
          />
        </div>
      </div>

      <div className="pricing-plan-group">
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">Price</label>
          <input
            value={bundle.price ? formatPrice(bundle.price) : "Free trial"}
            className="uk-input"
            disabled
          />
        </div>
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">Billing Period</label>
          <input
            value={bundle.billing_period ? "Yearly" : "Monthly"}
            className="uk-input"
            disabled
          />
        </div>
      </div>

      {bundle.ads_on && bundle.ads_on.length ? (
        <div className="pricing-plan-group pricing-addos">
          <label className="uk-width-1-1 uk-form-label pricing-addos-title">Add-ons</label>
          <label className="uk-width-1-1 uk-form-label pricing-addos-subtitle">
            Additional Price
          </label>
        </div>
      ) : null}

      {bundle.ads_on && bundle.ads_on.length
        ? bundle.ads_on.map((addon) => (
            <div key={addon.id} className="pricing-plan-group uk-margin-small-bottom">
              <div>
                {screenDimensions.screenInnerW < 689 ? (
                  <label className="uk-form-label">{addon.display_name}</label>
                ) : (
                  <input value={addon.display_name} className="uk-input" disabled />
                )}
              </div>
              <div>
                <input
                  value={addon.price ? formatPrice(addon.price) : "Free trial"}
                  className="uk-input"
                  disabled
                />
              </div>
            </div>
          ))
        : null}

      <div className="pricing-plan-divider" />

      <div className="pricing-plan-group">
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">Maximum Amount of Requests</label>
          <input value={bundle.requests_limit || "Unlimited"} className="uk-input" disabled />
        </div>
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label">Maximum Amount of Bookings</label>
          <input value={bundle.bookings_limit || "Unlimited"} className="uk-input" disabled />
        </div>
      </div>

      <div className="pricing-plan-group">
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label uk-flex uk-flex-middle">
            Amount of Requests to Date
            <Tooltip wrapperClassName="pricing-plan-tooltip" pos="top-left" refEl="self">
              Number of requests since pricing plan&apos;s commencement date
            </Tooltip>
          </label>
          <input value={bundle.requests_count || 0} className="uk-input" disabled />
        </div>
        <div className="uk-margin-small-bottom">
          <label className="uk-form-label uk-flex uk-flex-middle">
            Amount of Bookings to Date
            <Tooltip wrapperClassName="pricing-plan-tooltip" pos="top-left" refEl="self">
              Number of bookings since pricing plan&apos;s commencement date
            </Tooltip>
          </label>
          <input value={bundle.bookings_count || 0} className="uk-input" disabled />
        </div>
      </div>
      <PricingPlanAlert />
    </div>
  );
}

PricingPlanBundle.propTypes = {
  bundle: PropTypes.object,
  screenDimensions: PropTypes.object,
};

const mapStateToProps = ({ screenDimensions }) => ({ screenDimensions });

export default connect(mapStateToProps)(PricingPlanBundle);
