import React from "react";
import uid from "../../../utils/uid";
import { defaultLegValue, defaultPilotValue, useCalculatorContext } from "../CalculatorContext";

export function generateNewRepositioningLeg(aircraft, destination, location) {
  const leg = {
    ...defaultLegValue,
    id: uid().uid,
    home_base_repositioning: destination,
    [destination]: aircraft.home_base_location,
    price_per_hour: aircraft.price_per_hour,
    cruise_speed: aircraft.cruise_speed,
    crew_size: aircraft.crew_size,
    category: 2,
    pilots: [],
  };

  if (destination === "from") leg.to = location;
  else leg.from = location;

  for (let i = 0; i < aircraft.crew_size; i++) {
    leg.pilots.push({ id: uid().uid, ...defaultPilotValue() });
  }

  return leg;
}

export default function useAircraftName({
  updateForm,
  availableAircrafts,
  prefix,
  getValues,
  companyVat,
}) {
  const { demoVersion } = useCalculatorContext();

  const updateLegPilot = React.useCallback(
    (legIndex, aircraft) => {
      const legPilots = getValues(`${prefix}.legs.${legIndex}.pilots`) || [];

      if (demoVersion) {
        return updateForm([[`${prefix}.legs.${legIndex}.pilots`, [legPilots[0]]]]);
      }

      if (legPilots.length > aircraft.crew_size) {
        for (let i = aircraft.crew_size; i < legPilots.length; i++) {
          legPilots.pop();
        }
      } else if (legPilots.length < aircraft.crew_size) {
        for (let i = legPilots.length; i < aircraft.crew_size; i++) {
          legPilots.push({ ...defaultPilotValue(), id: uid().uid });
        }
      }

      updateForm([[`${prefix}.legs.${legIndex}.pilots`, legPilots]]);
    },
    [getValues, prefix, updateForm],
  );

  const updateLegRepositioning = React.useCallback(
    (index, destination, aircraft) => {
      const legs = getValues(`${prefix}.legs`).filter((leg) => !leg.home_base_repositioning);

      const updates = [];

      updates.push([`${prefix}.legs.${index}.${destination}`, aircraft.home_base_location]);

      if (destination === "from") updates.push([`${prefix}.legs.${index}.to`, legs[0].from]);
      else updates.push([`${prefix}.legs.${index}.from`, legs[legs.length - 1].to]);

      updateForm(updates);
    },
    [updateForm, prefix, getValues],
  );

  const createRepositioning = React.useCallback(
    (aircraft) => {
      const legs = getValues(`${prefix}.legs`);

      legs.unshift(generateNewRepositioningLeg(aircraft, "from", legs[0].from));
      legs.push(generateNewRepositioningLeg(aircraft, "to", legs[legs.length - 1].to));

      updateForm([[`${prefix}.legs`, legs]]);
    },
    [getValues, prefix, updateForm],
  );

  const updateLegs = React.useCallback(
    (aircraft) => {
      const legs = getValues(`${prefix}.legs`);
      const aircraftVat =
        aircraft.aircraft_vat || aircraft.aircraft_vat === 0 ? aircraft.aircraft_vat : companyVat;
      const companyDefaults = JSON.parse(window.sessionStorage.getItem("company-quote-defaults"));

      const updatedRepositioning = [];

      for (let i = 0; i < legs.length; i++) {
        const repositioning = getValues(`${prefix}.legs.${i}.home_base_repositioning`);

        if (repositioning) {
          updateLegRepositioning(i, repositioning, aircraft);
          updatedRepositioning.push(repositioning);
        }
        updateForm([
          [`${prefix}.legs.${i}.price_per_hour`, aircraft.price_per_hour],
          [`${prefix}.legs.${i}.cruise_speed`, aircraft.cruise_speed],
          [`${prefix}.legs.${i}.crew_size`, aircraft.crew_size],
          [`${prefix}.legs.${i}.flight_tax`, { sum: "", percent: aircraftVat }],
          [
            `${prefix}.legs.${i}.pilots.0.payment_tax`,
            { sum: "", percent: companyDefaults?.pilot?.pilot_tax || "" },
          ],
          [`${prefix}.legs.${i}.duration_last_update_by`, "locations"],
        ]);
        updateLegPilot(i, aircraft);
      }

      if (!updatedRepositioning.length) createRepositioning(aircraft);
    },
    [prefix, updateForm, updateLegPilot, getValues, updateLegRepositioning, createRepositioning],
  );

  const onAircraftNameChange = React.useCallback(
    (id, values) => {
      let currentAircraft;
      if (values) {
        currentAircraft = values;
      } else {
        currentAircraft = availableAircrafts.find((aircraft) => aircraft.id === +id);
      }

      const aircraftVat =
        currentAircraft.aircraft_vat || currentAircraft.aircraft_vat === 0
          ? currentAircraft.aircraft_vat
          : "";

      updateForm([
        [`${prefix}.cruise_speed`, currentAircraft.cruise_speed],
        [`${prefix}.price_per_hour`, currentAircraft.price_per_hour],
        [`${prefix}.id`, currentAircraft.id],
        [`${prefix}.aircraft_vat`, aircraftVat],
      ]);
      updateLegs(currentAircraft);
    },
    [availableAircrafts, prefix, updateForm, updateLegs],
  );

  return { onChange: onAircraftNameChange };
}
