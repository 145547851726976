import PropTypes from "prop-types";
import React, { useMemo } from "react";
import condStrings from "../../../utils/condString";
import OperProgressLine from "./OperProgressLine";
import SignupProgressCaption from "./SignupProgressCaption";

import "./styles/index.css";

export default function SignupProgress(props) {
  const {
    className,
    steps,
    role,
    isTeamMember,
    screenDimensions: { screenInnerW },
  } = props;

  const StepsCaption = useMemo(
    () =>
      steps.map((step, idx) => (
        <SignupProgressCaption
          isTeamMember={isTeamMember}
          key={step.name}
          step={step}
          order={idx + 1}
          screenInnerW={screenInnerW}
        />
      )),
    [role, steps, screenInnerW],
  );

  const StepsLine = useMemo(() => {
    if (screenInnerW <= 882) return null;

    return steps.map((step, idx) => {
      let withLeftUncompleted = false;
      let withArrow = true;

      if (steps[idx - 1]) {
        if (!steps[idx - 1].status) withLeftUncompleted = true;
      }

      if (!step.status && steps.length !== idx + 1) {
        withArrow = false;
      }

      return (
        <OperProgressLine
          key={step.name}
          isTeamMember={isTeamMember}
          step={step}
          withArrow={withArrow}
          leftUncompleted={withLeftUncompleted}
        />
      );
    });
  }, [role, steps, screenInnerW]);

  return (
    <div
      className={condStrings("signup-progress", className)}
      style={{ "--step-count": steps.length }}
    >
      <h2 className="signup-progress-title">Sign Up Progress</h2>
      <div className="signup-progress-container">{StepsCaption}</div>
      <div className={condStrings("signup-progress-container lines-container", role)}>
        {StepsLine}
      </div>
    </div>
  );
}

SignupProgress.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.bool,
      link: PropTypes.string,
    }),
  ),
  role: PropTypes.string,
  screenDimensions: PropTypes.object,
  isTeamMember: PropTypes.bool,
};

SignupProgress.defaultProps = {
  steps: [],
};
