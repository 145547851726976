import Axios from "axios";
import Auth from "../services/auth";
import { actions } from "react-redux-form";
import { config, ERROR_500, RESTRICT_ERROR_MESSAGE, staticText } from "../configs";
import {
  alertInstance,
  getFirstValueFromObject,
  isObject,
  replaceAttributesInString,
  spinnerInstance,
  storage,
} from "../shared";
import event from "../services/event.service";
import isAllowForEditing from "../utils/isAllowForEditing";
import { deleteCookie } from "../utils/deleteCookie";
import { showNotification } from "../utils/showNotification";

const api = class ApiClass {
  constructor() {
    this.entity = null;
    this.action = null;
    this.dispatch = null;
    this.skipErrorAlert = false;
    this.alertModal = false;
    this.showSpinner = true;
    this.refreshToken = true;
    this.headers = {};
    this.serverResponse = {};
    this.stripeErrorExceptions = staticText.stripeErrors;
    this.validationModel = "";
    this.queryString = "";
    this.failedRequests = [];
    this.http = Axios.create({
      baseURL: config.apiDomain,
      timeout: 50000,
      headers: config.apiHeaders,
      withCredentials: false,
    });
    this.messagePlaceholders = { entityName: "User" };
    this.responseMessages = staticText.globalMessages;

    let timer = null;
    const setTimer = (expires_in) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        Auth.logout();
        window.location.href = config.redirectToLogin;
      }, expires_in * 1000);
    };

    this.http.interceptors.request.use((response) => {
      const time = localStorage.getItem("expires_in");
      const expires_in = parseInt(`${time}`, 10);

      if (time && time !== "undefined") {
        setTimer(expires_in);
      }

      if (this.showSpinner) {
        if (response.method !== "get" || spinnerInstance.Type === "large") {
          const type = spinnerInstance.forceType ? "local" : "large";
          spinnerInstance.setProp("type", type);
        } else {
          const type = spinnerInstance.forceType ? "local" : "default";
          spinnerInstance.setProp("type", type);
        }
        spinnerInstance.show();
      }
      return response;
    });

    this.http.interceptors.response.use(
      (response) => {
        // Do something with response data
        const time = localStorage.getItem("expires_in");
        const expires_in = parseInt(`${time}`, 10);

        if (time && time !== "undefined") {
          setTimer(expires_in);
        }
        this.triggerAlert(response);
        this.serverResponse = response;
        return this.serverResponse.data;
      },
      (error) => {
        const { response } = error;

        if (response.status === 406) {
          localStorage.clear();
          deleteCookie();
          Auth.logout();
          console.log("406 --- 1");
          window.location.reload();
          return;
        }
        if (
          response.status === 401 ||
          (response.status === 404 && response.data.error === "user_not_found") ||
          (response.status === 400 && response.data.error === "token_not_provided")
        ) {
          event.trigger("user-session-expired");
          return;
        }
        spinnerInstance.setProp("type", "default");
        spinnerInstance.hide();
        if (error.response.status === 500) {
          showNotification(ERROR_500, "danger");

          return;
        }
        // response.status !== 422 this condition is set to avoid stack in triggerError method, now 422 error will be triggered.
        if (error.config.method !== "get" && response.status !== 422) {
          if (!this.skipErrorAlert) {
            this.triggerError(error.response);
          }
        } else {
          if (
            response.status !== 401 &&
            response.status !== 402 &&
            response.status !== 500 &&
            !this.skipErrorAlert
          ) {
            try {
              const _message = isObject(error.response.data)
                ? getFirstValueFromObject(error.response.data)
                : error.response.data;

              showNotification(_message, "warning");
            } catch (local_error) {
              // console.log(local_error);
            }
          }
        }

        return this.handleErrors(error.response);
      },
    );
  }

  triggerError(error) {
    let message =
      error.status !== 409
        ? replaceAttributesInString(
            this.messagePlaceholders,
            error.status === 423
              ? this.handleStripeError(error.data)
              : this.responseMessages.onError[error.config.method],
          )
        : error.data.custom_error;

    if (error.status !== 422) {
      let event = "global";
      let modalTitle = false;
      if (this.alertModal) {
        event = "alert-modal-global";
        modalTitle = error.data.error.title;
      }
      alertInstance.eventInstance.trigger(event, {
        visible: true,
        message: message,
        type: alertInstance.errorProp,
        modal: this.alertModal,
        modalTitle: modalTitle,
      });
    }
  }

  triggerAlert(response) {
    if (this.showSpinner) {
      spinnerInstance.setProp("type", "default");
      spinnerInstance.hide();
    }
    if (response.config.method !== "get") {
      const message = replaceAttributesInString(
        this.messagePlaceholders,
        this.responseMessages.onSuccess[response.config.method],
      );
      if (!this.skipErrorAlert) {
        alertInstance.eventInstance.trigger("global", {
          visible: true,
          message,
          type: alertInstance.successProp,
        });
        this.responseMessages = staticText.globalMessages;
      }
    }
  }

  get(id) {
    const url = `${this.url}/${id}${this.queryString}`;
    return this.http.get(url, this._defaultConfigs());
  }

  download(id) {
    const url = `${config.apiDomain}${this.url}/${id}`;
    return fetch(url, this._downloadConfigs());
  }

  downloadByUrl(url) {
    return fetch(url, this._downloadConfigs());
  }

  downloadImage() {
    const url = `${config.apiDomain}${this.url}`;
    return fetch(url, this._downloadConfigs());
  }

  getAll() {
    const url = `${this.url}${this.queryString}`;
    return this.http.get(url, this._defaultConfigs());
  }

  post(data = [], skipEditingCheck) {
    if (!isAllowForEditing() && !skipEditingCheck) {
      spinnerInstance.hide();
      showNotification(RESTRICT_ERROR_MESSAGE, "danger");

      return Promise.reject();
    }

    return this.http.post(this.url, data, this._defaultConfigs("post"));
  }

  put(id, data = []) {
    if (!isAllowForEditing()) {
      spinnerInstance.hide();
      showNotification(RESTRICT_ERROR_MESSAGE, "danger");

      return Promise.reject();
    }

    const url = `${this.url}/${id}`;
    return this.http.put(url, data, this._defaultConfigs("put"));
  }

  delete(id) {
    if (!isAllowForEditing()) {
      spinnerInstance.hide();
      showNotification(RESTRICT_ERROR_MESSAGE, "danger");

      return Promise.reject();
    }

    const url = `${this.url}/${id}`;
    return this.http.delete(url, this._defaultConfigs("DELETE"));
  }

  setPath(entity, action) {
    this.action = action;
    this.entity = entity;
    return this;
  }

  setDispatchAndValidationModel(dispatch, modelName) {
    this.dispatch = dispatch;
    this.validationModel = modelName;
    return this;
  }

  reset() {
    this.entity = null;
    this.action = null;
    this.dispatch = null;
    this.validationModel = "";
    this.queryString = "";
  }

  setCommon() {
    this.reset();
    this.setProperty("skipErrorAlert", true);
    return this;
  }

  get url() {
    let mainUrl = `/${this.entity}`;
    if (this.action) {
      mainUrl += "/" + this.action;
    }
    return mainUrl;
  }

  setQueryParams(params = [], isObject = false) {
    this.queryString = "";

    if (isObject) {
      this.queryString = Object.keys(params).map(
        (key) => `${key}=${encodeURIComponent(params[key])}`,
      );
    }

    if (params.length > 0)
      this.queryString = params.map((param) => `${param.key}=${encodeURIComponent(param.value)}`);

    if (this.queryString.length) {
      this.queryString = "?" + this.queryString.join("&");
    }
    return this;
  }

  setProperty(property, value) {
    this[property] = value;
    return this;
  }

  _defaultConfigs(method = "get") {
    return {
      method: method,
      headers: { ...config.apiHeaders, ...this.appendTokenToHeader },
    };
  }

  _downloadConfigs() {
    return {
      headers: { ...config.downloadHeaders, ...this.appendTokenToHeader },
    };
  }

  get appendTokenToHeader() {
    if (Auth.isAuthenticated()) {
      if (config.environment !== "production") {
        return { "X-Authorization": `${Auth.getType()} ${Auth.getToken()}` };
      } else {
        return { Authorization: `${Auth.getType()} ${Auth.getToken()}` };
      }
    }
    return {};
  }

  refresh_token() {
    const url = `/refresh_token`;
    const keep_logged_local = localStorage.getItem("keep_logged");
    this.skipErrorAlert = true;
    return this.http
      .post(url, { keep_logged: keep_logged_local }, this._defaultConfigs("post"))
      .then((data) => {
        Auth.setUserAuthData(data);
        const firstRequestConfig = this.failedRequests.shift();
        firstRequestConfig.headers = this._defaultConfigs(firstRequestConfig.method);
        const failedRequest = {
          ...firstRequestConfig,
          ...this._defaultConfigs(firstRequestConfig.method),
        };
        return this.http.request(failedRequest);
      });
  }

  setRefreshToken(state) {
    this.refreshToken = state;
    return this;
  }

  resetFormModel() {
    this.dispatch(actions.reset(this.validationModel));
  }

  handleStripeError(stripeError) {
    return stripeError.error.message;
  }

  handleErrors(error) {
    return new Promise((resolve, reject) => {
      switch (error.status) {
        case 422:
          if (this.dispatch) {
            this.dispatch(actions.setSubmitFailed(this.validationModel));
            this.dispatch(actions.setFieldsErrors(this.validationModel, error.data));
          }

          return reject(error);
        case 500:
          showNotification(ERROR_500, "danger");

          return reject(error);
        case 401:
          storage.clearByKeys(["access_token", "token_type"]);
          return reject(error);
        case 402:
          if (this.refreshToken) {
            this.failedRequests.push(error.config);
            return resolve(this.refresh_token());
          } else {
            reject(error);
          }
          break;
        case 406:
          localStorage.clear();
          deleteCookie();
          Auth.logout();
          console.log("406 --- 2");
          window.location.reload();
          break;
        default:
          return reject(error);
      }
    });
  }
};

export default new api();
