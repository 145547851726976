import io from "socket.io-client";
import {
  ADD_DISCUSSION_MESSAGE,
  BROADCAST_EVENT,
  GET_DASHBOARD_STATISTIC,
  GET_OPERATOR_REQUESTS_NOTIFICATION,
  SEEN_QUOTE,
  SET_COMPANY,
  UPDATE_ACCOUNT,
  UPDATE_BOOKING_SELECTED_QUOTE,
  UPDATE_BROKER_REQUEST,
  UPDATE_BROKER_REQUEST_BY_QUOTES,
  UPDATE_CURRENT_QUOTE_DETAIL,
  UPDATE_QUOTE_ITEM,
  UPDATE_QUOTE_LOCALLY,
  UPDATE_REQUEST_BY_QUOTES,
} from "../actions/actionTypes";
import Auth from "./auth";
import GetHeliEvents from "./event.service";
import Echo from "laravel-echo";
import { config } from "../configs/app-config";
import { logoutUserSuccess } from "../actions/auth.actions";
import { getBookingDetailSuccess } from "../actions";

window.io = io;
window.Echo = new Echo({
  broadcaster: "socket.io",
  host: config.websocketPath,
});

class BroadcastService {
  constructor() {
    this.isLoaded = false;
    this.dispatch = null;
  }

  setProperty(prop, value) {
    this[prop] = value;
    return this;
  }

  broadcast() {
    window.Echo.channel("company-notification-" + Auth.company.id).listen(
      ".company.status",
      (user) => {
        Auth.setUserAuthData(user);
        this.dispatch({ type: SET_COMPANY, company: Auth.company });
      },
    );

    window.Echo.channel("user.company.notification-" + Auth.company.id).listen(
      ".user.company.status",
      (company) => {
        Auth.setAccount("company", company);
        this.dispatch({ type: SET_COMPANY, company: company });
      },
    );

    window.Echo.channel("user-deleted-notification-" + Auth.getUser().id).listen(
      ".user.deleted",
      (user) => {
        this.dispatch(logoutUserSuccess(user));
      },
    );

    window.Echo.channel("user-sign-out-notification-" + Auth.getUser().id).listen(
      ".user.sign.out",
      (user) => {
        // this.dispatch(logoutUserSuccess(user));
      },
    );

    window.Echo.channel("role-change-notification-" + Auth.getUser().id).listen(
      ".role.change",
      (user) => {
        Auth.setUserAuthData(user);
        this.dispatch({ type: UPDATE_ACCOUNT, response: Auth.getUser() });
        this.dispatch({ type: SET_COMPANY, company: Auth.company });
      },
    );

    window.Echo.channel("notification.user." + Auth.getUser().id).listen(
      ".messages.new",
      (data) => {
        this.dispatch({ type: BROADCAST_EVENT, response: data });
      },
    );

    window.Echo.channel("dashboard.notification." + Auth.getUser().id).listen(
      ".dashboard.notification",
      (data) => {
        this.dispatch({ type: GET_DASHBOARD_STATISTIC, data });
      },
    );

    window.Echo.channel("quote-comment.notification." + Auth.getUser().id).listen(
      ".quote-comment.notifications",
      (messages) => {
        this.dispatch({ type: ADD_DISCUSSION_MESSAGE, messages });
      },
    );

    window.Echo.channel("booking.updated." + Auth.getUser().id).listen(
      ".booking.updates",
      ({ data }) => {
        this.dispatch(getBookingDetailSuccess(data));
      },
    );

    window.Echo.channel("quote.expired." + Auth.getUser().id).listen(".quote.expired", ({ id }) => {
      this.dispatch({ type: UPDATE_QUOTE_ITEM, id });
    });

    window.Echo.channel("quote.updated." + Auth.getUser().id).listen(
      ".quote.updated.notification",
      (seenQuote) => {
        const role = Auth.getUser().role;

        if (role === "operator_admin") {
          this.dispatch({ type: SEEN_QUOTE, seenQuote });
        } else if (role === "broker_admin") {
          this.dispatch({ type: UPDATE_BOOKING_SELECTED_QUOTE, seenQuote });
          this.dispatch({ type: UPDATE_CURRENT_QUOTE_DETAIL, seenQuote });
          this.dispatch({ type: UPDATE_QUOTE_LOCALLY, seenQuote });
        } else {
          this.dispatch({ type: SEEN_QUOTE, seenQuote });
          this.dispatch({ type: UPDATE_QUOTE_LOCALLY, seenQuote });
        }
        this.dispatch({ type: UPDATE_BOOKING_SELECTED_QUOTE, seenQuote });
      },
    );

    if (Auth.getMainRole() === "broker") {
      window.Echo.channel("broker.quotes.notification-" + Auth.getUser().id).listen(
        ".broker.quotes.notifications",
        (response) => {
          this.dispatch({ type: UPDATE_REQUEST_BY_QUOTES, response });
          this.dispatch({ type: UPDATE_BROKER_REQUEST_BY_QUOTES, response });
        },
      );
      window.Echo.channel("booking_request.updated." + Auth.getUser().id).listen(
        ".booking_request.updated.notification",
        (response) => {
          this.dispatch({
            type: UPDATE_BROKER_REQUEST,
            request: response,
          });
        },
      );
    }

    if (Auth.getMainRole() === "operator") {
      window.Echo.channel("new.request.operator").listen(".new.request.operator", (data) => {
        const allowed = Object.keys(data.companies).filter(
          (id) => parseInt(id) === parseInt(Auth.getUser().id),
        );
        if (allowed.length > 0) {
          GetHeliEvents.trigger("new-requests", {
            marketplace_counter: data.companies[Auth.getUser().id],
          });
          this.dispatch({
            type: GET_OPERATOR_REQUESTS_NOTIFICATION,
            marketplace_counter: data.companies[Auth.getUser().id],
          });
        }
      });
    }
  }
}

export default new BroadcastService();
