import React from "react";
import PropTypes from "prop-types";

export const DisplayContentWithActionComponent = ({
  action,
  closeBtn = "close",
  content = "",
  className = "",
}) => {
  return (
    <div className={className}>
      <p dangerouslySetInnerHTML={{ __html: content }}></p>
      <div className={"uk-text-center"}>
        <button
          className={"uk-button uk-button-primary"}
          type={"button"}
          onClick={action}
          dangerouslySetInnerHTML={{ __html: closeBtn }}
        />
      </div>
    </div>
  );
};

DisplayContentWithActionComponent.propTypes = {
  closeBtn: PropTypes.string,
  action: PropTypes.func,
  content: PropTypes.string,
  className: PropTypes.string,
};
