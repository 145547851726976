import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { HomeLayout } from "../../layouts";
import { BROKER_MAIN, config } from "../../../configs";
import Api from "../../../services/api-handler";
import { mock } from "./mock";
import stripeBlue from "../../../assets/img/svg/stripe-blue.svg";
import check from "../../../assets/img/svg/pricing-check.svg";
import cross from "../../../assets/img/svg/pricing-cross.svg";
import PricingRowComponent from "./pricing-row.component";
import auth from "../../../services/auth";
import { ModalComponent } from "../../../shared";
import Tooltip from "../../common/Tooltip";
import staticService from "../../../services/static.service";
import arrow from "../../../assets/img/svg/pricingArrow.svg";
import "./index.css";
import Button from "../../common/Button";
import PricingBanner from "./PricingBanner";

const currencyList = [
  { key: "GBP", value: "GBP" },
  { key: "USD", value: "USD" },
  { key: "EUR", value: "EUR" },
];

const PricingComponent = (props) => {
  const { location, history, match, title } = props;
  const [promoCode, setPromoCode] = useState("");
  const [pricingList, setPricingList] = useState([]);
  const [bundle, setBundle] = useState("pro");
  const [selectedCurrency, setSelectedCurrency] = useState("GBP");
  const registerPath =
    config.redirectToRegister + (location.search !== "" ? location.search : "?type=" + BROKER_MAIN);

  // MODAL FOR FREE TRIAL LOGIC --->
  const pricingInfoModalRef = useRef(null);
  const pricingWrapperRef = useRef(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openedFirstTime, setOpenedFirstTime] = useState(false);
  const [openedSecondTime, setOpenedSecondTime] = useState(false);
  const [openedFromBottom, setOpenedFromBottom] = useState(false);
  const [counter, setCounter] = useState(0);
  let interval;

  useEffect(() => {
    interval = setInterval(() => {
      setCounter(counter + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    if (modalIsOpen) return;

    if (counter === 10 && !openedFromBottom && !openedSecondTime && !openedFirstTime) {
      openModal();
      setOpenedFirstTime(true);
    }

    if (counter >= 20 && !openedSecondTime) {
      openModal();
      setOpenedSecondTime(true);
    }

    if (counter > 20) {
      clearInterval(interval);
    }
  }, [counter]);

  const openModal = () => {
    setModalIsOpen(true);
    pricingInfoModalRef.current.open();
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setCounter(0);
  };

  useEffect(() => {
    if (openedFromBottom) {
      openModal();
    }
  }, [openedFromBottom]);

  const handleNavigation = ({ currentTarget }) => {
    const scrolledFromTop = currentTarget.innerHeight + currentTarget.scrollY;

    if (scrolledFromTop > pricingWrapperRef.current.clientHeight) {
      setOpenedFromBottom(true);
    }
  };

  const [y, setY] = useState(window.scrollY);
  useEffect(() => {
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  // MODAL FOR FREE TRIAL LOGIC <-----

  useEffect(() => {
    Api.setPath("bundles-prices")
      .get(selectedCurrency)
      .then((res) => {
        setPromoCode(res.promo_code);
        setPricingList(res.bundles);
        return res;
      });
  }, [selectedCurrency]);

  const updateOption = (options, amountType) => {
    return options.map((item) => {
      let objKey = Object.keys(item)[0];
      let newValue = pricingList.find((price) => price.name === objKey);
      if (newValue && newValue.name === objKey) {
        return {
          ...item,
          [objKey]: newValue.amount[amountType]
            ? newValue.symbol + newValue.amount[amountType]
            : null,
          price_value: true,
        };
      } else {
        return item;
      }
    });
  };

  const handleOnCloseModal = () => {
    navigator.clipboard.writeText(promoCode);
    pricingInfoModalRef.current.hide();
    setModalIsOpen(false);
    setCounter(0);
  };

  const pushToRegister = () => {
    return history.push({
      pathname: "/account/register",
      state: { role: "broker", bundle },
    });
  };

  useEffect(() => {
    document.querySelector(".gh-main").classList.add("pricing-page");
    return () => {
      document.querySelector(".gh-main").classList.remove("pricing-page");
    };
  }, []);

  return (
    <HomeLayout activeRoute={match} pageTitle={title} registerPath={registerPath}>
      <div className="gh-pricing" ref={pricingWrapperRef}>
        <div className="uk-container">
          {/* PRISING */}
          <div className="gh-pricing-header">
            <div className="gh-pricing-header-currency">
              <div className="gh-pricing-header-currency-key">
                Currency<sup className="sup-text">2</sup>
              </div>
              <select
                value={selectedCurrency}
                name="currency"
                onChange={(e) => {
                  setSelectedCurrency(e.target.value);
                }}
                className="uk-select uk-input"
              >
                {currencyList.map((item) => (
                  <option key={item.key} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>

            <h4 className="gh-pricing-header-plan-text">Choose the plan that’s right for you.</h4>
            {auth.isAuthenticated() ? (
              <a
                className="gh-pricing-header-btn"
                href={`mailto:contact@getheli.com?subject=Enquiry about ${bundle} bundle`}
              >
                Continue with {bundle.toUpperCase()} {bundle === "commission" ? "model" : "bundle"}{" "}
                <img src={arrow} alt="arrow" />
              </a>
            ) : (
              <a className="gh-pricing-header-btn" onClick={pushToRegister}>
                Continue with {bundle.toUpperCase()} {bundle === "commission" ? "model" : "bundle"}{" "}
                <img src={arrow} alt="arrow" />
              </a>
            )}
          </div>
          <div className="gh-pricing-section">
            <h2 className="section-title">Pricing for Brokers</h2>
            <div className="pricing-block">
              <div data-uk-grid className="uk-grid-small gh-grid-above">
                <div className="uk-width-1-4" />
                <div className="uk-width-3-4" style={{ paddingLeft: 4 }}>
                  <div
                    className="uk-grid-small"
                    style={{ height: "100%", display: "flex", margin: 0 }}
                  >
                    {mock.bundles.map((item) => (
                      <div
                        key={"empty-" + item.title}
                        className={`uk-width-1-5 gh-grid-above-item ${
                          item.title.toLowerCase() === bundle && pricingList.length ? "active" : ""
                        }`}
                        onClick={() => setBundle(item.title.toLowerCase())}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div data-uk-grid className="uk-grid-small uk-margin-remove-top">
                <div className="uk-width-1-4" />
                <div className="uk-width-3-4">
                  <div
                    data-uk-grid
                    className="uk-width-1-1 uk-grid-small uk-margin-remove"
                    style={{ background: "#F2F4F7" }}
                  >
                    {mock.bundles.map((bundle, idx) => (
                      <div key={bundle.title + idx} className="uk-width-1-5 uk-padding-remove-left">
                        <div
                          className="gh-bundle"
                          style={{
                            paddingRight: bundle.title === "Commission" ? 9 : "",
                            boxShadow:
                              bundle.title === "Commission" ? "inset -9px 0 1px -1px #F2F4F7" : "",
                          }}
                        >
                          <div
                            className="gh-bundle-header"
                            style={{
                              background: bundle.bg,
                              borderBottom: bundle.title === "Pro" ? "1px solid #1ABC9C" : "",
                            }}
                          >
                            <h4 className="bundle-title">
                              {bundle.title === "Pro" ? (
                                <div className="recommended">
                                  <div className="recommended-text">Most Popular</div>
                                </div>
                              ) : (
                                ""
                              )}
                              {bundle.title}
                            </h4>
                            {idx !== 0 ? (
                              <span className="bundle-sub">bundle</span>
                            ) : (
                              <span className="bundle-sub">model</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="gh-bundle-options">
                {pricingList.length ? (
                  mock.pricing.list.options.map((option, idx) => {
                    return (
                      <PricingRowComponent
                        key={option.title}
                        option={
                          option.key === "subscription_cost"
                            ? { ...option, values: updateOption(option.values, "total") }
                            : option
                        }
                        index={idx}
                      />
                    );
                  })
                ) : (
                  <div className="uk-flex uk-flex-center uk-padding">
                    <div data-uk-spinner="ratio: 1.7" />
                  </div>
                )}
                <div
                  data-uk-grid
                  className="uk-grid-small uk-flex-middle uk-margin-remove-top"
                  style={{ zIndex: 99 }}
                >
                  <div className="uk-width-1-4" style={{ zIndex: 99 }}>
                    <div className="bundle-addons" style={{ height: 27 }} />
                  </div>
                  <div className="uk-width-3-4 uk-padding-remove-left" style={{ zIndex: 99 }}>
                    <div className="bundle-addons">Add-ons</div>
                  </div>
                </div>

                {pricingList.length
                  ? mock.pricing.list.main.map((option) => {
                      return (
                        <PricingRowComponent
                          key={option.title}
                          option={{ ...option, values: updateOption(option.values, "extra_users") }}
                          main={true}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="grayed-info">
              <sup className="sup-text">1</sup> Billed annually. Prices displayed without VAT
            </div>
            <div className="grayed-info">
              <sup className="sup-text">2</sup> Prices displayed in EUR or USD will be converted to
              GBP when payment is made
            </div>
          </div>

          {/* FEATURES*/}

          <div className="gh-features-section">
            <div className="features-header">
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-2-5">
                  <h2 className="section-title">Features</h2>
                </div>
                <div className="uk-width-3-5" style={{ background: "#E7F2EF" }}>
                  <div data-uk-grid className="uk-grid-small">
                    {mock.bundles.map((bundle, idx) => (
                      <div
                        key={bundle.title}
                        className={`uk-width-1-5 uk-padding-remove-left ${
                          idx === 3 ? "uk-position-relative" : ""
                        }`}
                      >
                        <div className="gh-bundle">
                          <div
                            className="gh-bundle-header"
                            style={{ background: bundle.bg, marginRight: idx === 0 ? 15 : 0 }}
                          >
                            <h4 className="bundle-title">{bundle.title}</h4>
                            {idx !== 0 ? (
                              <span className="bundle-sub">bundle</span>
                            ) : (
                              <span className="bundle-sub">model</span>
                            )}
                          </div>
                        </div>
                        {idx === 3 ? (
                          <div className="recommended recommended-for-banner">
                            <div className="recommended-text">Most Popular</div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="gh-features-options">
              <PricingBanner setBundle={setBundle} bundle={bundle} pricingList={pricingList} />
              {mock.features.list.map((feature, idx) => {
                if (feature.subInfo) {
                  return (
                    <div key={feature.subInfo} className="grayed-info">
                      <sup className="sup-text">3</sup>
                      <span style={{ paddingLeft: 2 }}> {feature.subInfo}</span>
                    </div>
                  );
                }
                if (feature.options.length) {
                  return (
                    <div key={"feature-" + idx} className="gh-feature-card">
                      {feature.title ? <h4 className="card-title">{feature.title}</h4> : ""}
                      <div className="gh-feature-card-inner">
                        <div className="gh-features-overlay">
                          <div className="uk-grid uk-grid-small uk-height-1-1">
                            <div className="uk-width-2-5" />
                            <div className="uk-width-3-5">
                              <div className="uk-grid uk-grid-small uk-height-1-1">
                                {Array.from({ length: 5 }).map((_, idx) => (
                                  <div
                                    key={"mock-" + idx}
                                    className="uk-width-1-5 uk-padding-remove"
                                  >
                                    {idx === 0 ? (
                                      <div
                                        className="bordered"
                                        style={{ transform: "translateX(-8px)" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        {feature.options.map((opt, idx) => (
                          <div
                            key={opt.title}
                            className="uk-grid uk-grid-small"
                            style={{ marginBottom: idx !== feature.options.length - 1 ? 20 : 0 }}
                          >
                            <div className="uk-width-2-5">
                              <div className="uk-flex uk-flex-middle">
                                {opt.stripe ? (
                                  <img src={stripeBlue} alt="stripe" className="stripe-image" />
                                ) : (
                                  ""
                                )}
                                <div className="option-title">
                                  {opt.title}{" "}
                                  {opt.sup ? <sup className="sup-text">{opt.sup}</sup> : ""}
                                </div>
                                {opt.tooltip ? (
                                  <Tooltip
                                    pos="top-right"
                                    refEl="self"
                                    contentClass="pricing-tooltip-content"
                                    wrapperClassName="uk-margin-small-left pricing-tooltip"
                                    trigger={<span className="icon" uk-icon="info" />}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: staticService.findByAlias(opt.tooltip),
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="uk-width-3-5">
                              <div className="uk-grid uk-grid-small">
                                {Object.values(opt.items).map((val, valIdx) => (
                                  <div
                                    key={opt.title + valIdx}
                                    className="uk-width-1-5"
                                    style={{
                                      paddingLeft: valIdx === 0 ? 0 : valIdx === 4 ? 25 : 15,
                                      paddingRight: valIdx === 0 ? 15 : 0,
                                    }}
                                  >
                                    <div className="option-value">
                                      {typeof val === "string" ? (
                                        <span
                                          style={{ fontSize: 14 }}
                                          dangerouslySetInnerHTML={{
                                            __html: val.replace("\n", "<br>"),
                                          }}
                                        />
                                      ) : +val ? (
                                        <img src={check} alt="check" />
                                      ) : (
                                        <img src={cross} alt="cross" />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>

      {/*MODALS*/}

      <ModalComponent
        // title={}
        ref={pricingInfoModalRef}
        id="pricing-page-modal"
        modalProps={{ className: "uk-text-left" }}
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={handleCloseModal}
      >
        <div className="pricing-page-modal-title">
          <h5
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("signUpReferalModalTitle"),
            }}
          />
          <h3
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("referalModalFreeTrialMessage"),
            }}
          />
        </div>
        <div
          className="pricing-page-modal-content"
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("pricingModalText") }}
        />
        <div className="pricing-page-modal-action">
          <span className="code">
            <span className="label">promocode:</span>
            {promoCode}
          </span>
          <Button onClick={handleOnCloseModal}>{staticService.findByAlias("copyAndClose")}</Button>
        </div>
      </ModalComponent>
    </HomeLayout>
  );
};

PricingComponent.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  title: PropTypes.string,
};

export default PricingComponent;
