import React from "react";
import { Control, Fieldset } from "react-redux-form";
import staticService from "../../services/static.service";

export const EventSelectPax = ({
  model,
  updatePax,
  defaultCss = "uk-width-1-1@s uk-width-1-2@m",
  disabled,
}) => {
  return (
    <Fieldset model={model} className={defaultCss}>
      <div data-uk-grid className="uk-grid-small">
        <div className="uk-width-1-3">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("NumberAdults") }}
          />
          <div className="gh-number-field">
            <Control.select
              model={model + `.number_adults`}
              className="uk-input"
              changeAction={updatePax}
              disabled={disabled}
              updateOn="change"
              step={1}
              label={staticService.findByAlias("NumberAdults")}
              required={false}
              max={8}
              min={0}
            >
              {Array.from({ length: 9 }).map((_, index) => {
                return (
                  <option key={index} value={index}>
                    {index}
                  </option>
                );
              })}
            </Control.select>
          </div>
        </div>

        <div className="uk-width-1-3">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("NumberChildren") }}
          />
          <div className="gh-number-field">
            <Control.select
              model={model + `.number_children`}
              className="uk-input"
              changeAction={updatePax}
              disabled={disabled}
              updateOn="change"
              step={1}
              label={staticService.findByAlias("NumberChildren")}
              required={false}
              max={8}
              min={0}
            >
              {Array.from({ length: 9 }).map((_, index) => {
                return (
                  <option key={index} value={index}>
                    {index}
                  </option>
                );
              })}
            </Control.select>
          </div>
        </div>

        <div className="uk-width-1-3">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("luggage") }}
          />
          <div className="gh-number-field">
            <Control.select
              model={model + `.number_luggage`}
              className="uk-input"
              changeAction={updatePax}
              disabled={disabled}
              updateOn="change"
              step={1}
              label={staticService.findByAlias("luggage")}
              required={false}
              max={8}
              min={0}
            >
              {Array.from({ length: 9 }).map((_, index) => {
                return (
                  <option key={index} value={index}>
                    {index}
                  </option>
                );
              })}
            </Control.select>
          </div>
        </div>
      </div>
    </Fieldset>
  );
};
