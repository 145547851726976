import React from "react";
import LazyLoad from "react-lazy-load";
import ImageLoader from "../../shared/image-loader/image-loader.component";
import staticService from "../../services/static.service";
import logo from "../../assets/img/eventThumbnail.png";
import StarIcon from "../../assets/img/svg/star.svg";
import DestinationFilters from "../pages/user-online/filters/DestinationFilters";
import headerImg from "../../assets/img/bg/Bell-5.jpeg";

const imageSrc = (item) => {
  const params = "?width=288&height=193";
  if (item.thumbnail) {
    return item.thumbnail + params;
  } else if (item.photo) {
    return item.photo + params;
  } else {
    return logo;
  }
};

const altType = (item) => {
  if (item.thumbnail_alt) {
    return item.thumbnail_alt;
  } else if (item.photo_alt) {
    return item.photo_alt;
  } else {
    return item.name;
  }
};

const Links = ({ listEvents, onClick, listOfDestinationsRef }) => (
  <div ref={listOfDestinationsRef}>
    {listEvents.map((item, i) => {
      return (
        <div key={item.id} className="uk-grid-item-match gh-margin-top-20 gh-event-card">
          <div
            className={`uk-card uk-card-default uk-card-body uk-text-center gh-padding-event-card ${
              item.is_featured_destination ? "with-border" : ""
            }`}
          >
            <div data-uk-grid>
              <div
                // onClick={() => onClick(item.id)}
                className="uk-width-1-1 uk-width-2-5@s uk-width-1-4@l"
              >
                <a href={`/destinations/${item.url}`} className="link">
                  <div className="uk-flex uk-flex-middle uk-flex-center uk-width-1-1 gh-image-event">
                    <img src={imageSrc(item)} alt={altType(item)} />
                  </div>
                </a>
              </div>
              <div className="uk-width-1-1 uk-width-3-5@s uk-width-3-4@l gh-event-card-content_wrapper">
                <div className="gh-event-card-content">
                  <div className="gh-event-card-header">
                    <div>
                      {!!item.is_featured_destination && (
                        <div className="gh-event-card-meta">
                          <span className="gh-event-icon">
                            <img src={StarIcon} alt="Featured" />
                          </span>
                          <span>{staticService.findByAlias("featuredDestinationType")}</span>
                        </div>
                      )}
                      <a href={`/destinations/${item.url}`} className="link">
                        <div className="gh-title uk-text-left">{item.name}</div>
                      </a>
                    </div>
                    <a
                      href={`/destinations/${item.url}`}
                      className="uk-button uk-button-primary gh-pe-btn"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("priceEstimateBtnLabel"),
                        }}
                      />
                    </a>
                  </div>
                  <div className="uk-inline uk-width-1-1">
                    <div data-uk-grid className="uk-grid-small">
                      <div className="uk-inline uk-width-1-5@l uk-width-1-2">
                        <div className="uk-text-left gh-el-card-item">
                          <span className="gh-event-list-label">flight type</span>
                          <div className="gh-info-description  gh-padding-remove">{item.type}</div>
                        </div>
                      </div>
                      <div className="uk-inline uk-width-1-4@l uk-width-1-2">
                        <div className="uk-text-left gh-el-card-item">
                          <span className="gh-event-list-label">locations</span>
                          <div className="gh-info-description ">{item.locations_string}</div>
                        </div>
                      </div>

                      {item.start_month ? (
                        <div className="uk-inline uk-width-1-5@l uk-width-1-1">
                          <div className="uk-text-left gh-el-card-item">
                            <span className="gh-event-list-label">Peak Season</span>
                            <div
                              className={`gh-info-description  ${
                                item.is_outdated ? "gh-outdated-event" : ""
                              }`}
                            >
                              <span style={{ marginRight: 5 }}>{item.start_month || ""}</span>
                              <span>{item.end_month ? <span>to {item.end_month}</span> : ""}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="uk-inline uk-width-1-4@l uk-width-1-1">
                        {item.is_outdated ? (
                          <div className="uk-text-left">
                            <span className="gh-event-list-label gh-label-last">details</span>
                            <div
                              className=" gh-future-dates"
                              dangerouslySetInnerHTML={{
                                __html: staticService.findByAlias("futureDatesReleased"),
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export const DestinationsInfoComponent = ({
  eventInfo = [],
  listEvents = [],
  onClick,
  loadMore,
  endPagination,
  isFilterShown,
  showFilters,
  eventTypes,
  countries,
  submit,
  resetFilters,
  updateModel,
  filters,
  screenW,
  loadFilteredDestinations,
  loadDestinations,
  handleUpdateCustomFilters,
  customFilters,
  listOfDestinationsRef,
  locationFilters,
  history,
}) => {
  const loaderButton = (listEvents, loadMore, endPagination) => {
    if (!endPagination && listEvents && listEvents.length >= 10) {
      return (
        <div>
          <h3 className="uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium gh-event-title">
            Looking for more?
          </h3>
          <p>
            Click the button below to find more destinations accessible by charter. Click on event
            for more details or an instant price estimate.
          </p>
          <div
            className={
              "uk-text-center uk-position-relative uk-margin-medium-top uk-margin-small-bottom"
            }
          >
            <button
              type={"button"}
              onClick={() => loadMore()}
              className={"uk-button uk-button-default"}
            >
              {staticService.findByAlias("showMore")}
            </button>
          </div>
        </div>
      );
    }
  };

  const gridRows =
    eventInfo &&
    eventInfo.map((item, i) => {
      return (
        <div key={i} className="uk-grid-match uk-flex-middle uk-child-width-1-1@s uk-grid">
          <div className="uk-first-column gh-info-block-description-box uk-width-1-1@s">
            <h1 className="uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium gh-event-title">
              {item.title}
            </h1>
            <span className="uk-margin-medium uk-text-emphasis uk-margin-remove-top uk-scrollspy-inview uk-animation-slide-left-medium">
              <span
                className="gh-subtitle-text"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </span>
          </div>
        </div>
      );
    });

  const eventsBg =
    eventInfo &&
    eventInfo.map((item, i) => {
      return (
        <LazyLoad key={i} debounce={false} className={"uk-child-width-1-1@l"}>
          <div className="uk-background-cover uk-cover-container uk-light">
            <ImageLoader
              className="max_height_img"
              // src={item.image + `?width=${screenW}&height=375`}
              src={headerImg}
              alt={item.title ? item.title : ""}
            />
          </div>
        </LazyLoad>
      );
    });

  return (
    <div className="info-block">
      {eventsBg}
      <div className="uk-section uk-container gh-padding-medium">{gridRows}</div>
      <div className="uk-section uk-container gh-padding-medium uk-padding-remove-top">
        <div className="uk-flex uk-flex-right gh-border-bottom">
          <button onClick={showFilters} className="uk-button uk-button-text gh-button-filter">
            {isFilterShown ? "Close" : "Filters"}
            <span className="uk-margin-small-left" data-uk-icon="icon: settings" />
          </button>
        </div>
        {isFilterShown ? (
          <DestinationFilters
            loadFilteredDestinations={loadFilteredDestinations}
            countries={countries}
            loadDestinations={loadDestinations}
            handleUpdateCustomFilters={handleUpdateCustomFilters}
            customFilters={customFilters}
            listOfDestinationsRef={listOfDestinationsRef}
            eventTypes={eventTypes}
            locationFilters={locationFilters}
            history={history}
          />
        ) : null}
        <Links
          listEvents={listEvents}
          onClick={onClick}
          listOfDestinationsRef={listOfDestinationsRef}
        />
        {loaderButton(listEvents, loadMore, endPagination)}
      </div>
    </div>
  );
};
