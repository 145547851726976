import PropTypes from "prop-types";
import React from "react";
import condStrings from "../../../utils/condString";

import "./Icon.css";

export default function Icon(props) {
  const { onClick, children, disabled, className, style, loading, htmlType } = props;

  return (
    <button
      type={htmlType}
      style={style}
      className={condStrings("gh-icon-btn", className)}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <div uk-spinner="ratio: 0.7" className="uk-margin-small-left" /> : children}
    </button>
  );
}

Icon.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  loading: PropTypes.bool,
  htmlType: PropTypes.oneOf(["button", "submit", "reset"]),
};

Icon.defaultProps = {
  htmlType: "button",
};
