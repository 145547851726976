import React from "react";
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import { compose, lifecycle } from "recompose";
import PropTypes from "prop-types";
import primaryIcon from "../../assets/img/marker_secondary.png";
import secondaryIcon from "../../assets/img/marker_primary.png";
import { arrayFlatten } from "../helpers";
import { Item, List } from "../list";

const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MapComponentOld = compose(
  lifecycle({
    ref: {},
    componentWillMount() {
      let bounds = getMapBounds(this.props.markers);
      this.setState({
        onMapMounted: (ref) => {
          if (ref) {
            this.ref[this.props.id] = {};
            this.ref[this.props.id].map = ref;
            if (bounds && !this.props.woFitBounds) {
              ref.fitBounds(bounds);
            }
          }
        },
        onPlacesChanged: () => {
          if (this.ref[this.props.id].map) {
            if (bounds && !this.props.woFitBounds) {
              this.ref.map.fitBounds(bounds);
            }
          }
        },
        onZoomChanged: () => {
          this.props.onDirectionsDrawn([]);

          if (typeof this.props.onClusterClose === "function") {
            this.props.onClusterClose();
          }
        },
        // mapTypeId: () => {
        // 	if(this.ref[this.props.id].map) {
        // 		const { map } = this.ref;
        // 		return map.getMapTypeId();
        // 	}
        // },
        onIdle: () => {
          if (this.ref[this.props.id].map) {
            const { map } = this.ref[this.props.id];
            const { flightPlanCoordinates: coords = [] } = this.props;
            const directions = coords.map((plan) =>
              getFlightDirectionLines(plan, map.getProjection(), map.getZoom()),
            );
            this.props.onDirectionsDrawn(directions);
          }
        },
        onMarkerClustererClick: (cluster) => {
          const center = cluster.getCenter();
          const markers = cluster.getMarkers();
          this.props.onClusterClick({
            position: { lat: center.lat(), lng: center.lng() },
            markers,
          });
        },

        onMarkerClick: (ev) => {
          this.ref[this.props.id].map.panTo(ev.latLng);
        },

        mountOnUpdate: false,
      });
    },

    componentDidMount() {
      this.setState({ propMarkers: [] });
    },

    componentDidUpdate(oldProps, prevState) {
      const { propMarkers } = this.state;

      if (propMarkers && JSON.stringify(prevState.propMarkers) !== JSON.stringify(propMarkers)) {
        this.setState({ propMarkers: this.props.markers });
        if (propMarkers.length) {
          this.addBounds(this.props, this.ref[this.props.id].map);
        }
      }

      if (
        !this.state.mountOnUpdate &&
        this.props.markers.length !== oldProps.markers.length &&
        this.props.mountOnUpdate
      ) {
        this.setState({ mountOnUpdate: true });
        this.addBounds(this.props, this.ref[this.props.id].map);
      }
    },

    addBounds(props, map) {
      const { flightPlanCoordinates: coords = [] } = props;
      const directions =
        coords.length &&
        coords.map((plan) => getFlightDirectionLines(plan, map.getProjection(), map.getZoom()));
      props.onDirectionsDrawn(directions);
      let bounds = getMapBounds(props.markers);
      if (bounds && !props.woFitBounds) {
        map.fitBounds(bounds);
      }
      if (
        this.ref[this.props.id] &&
        this.ref[this.props.id].map &&
        props.flightPlanCoordinates[0].length === 0 &&
        props.markers.length === 0
      ) {
        this.ref[
          this.props.id
        ].map.__reactInternalMemoizedMaskedChildContext.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
          {
            lat: 53.2976433,
            lng: -1.8852428,
          },
        );
        setTimeout(
          () =>
            this.ref[
              this.props.id
            ].map.__reactInternalMemoizedMaskedChildContext.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
              8,
            ),
          100,
        );
      }
    },

    componentWillReceiveProps(props) {
      if (
        JSON.stringify(props.flightPlanCoordinates) !==
          JSON.stringify(this.props.flightPlanCoordinates) &&
        this.ref[this.props.id] &&
        this.ref[this.props.id].map
      ) {
        this.addBounds(props, this.ref[this.props.id].map);
      }
    },
  }),
  withGoogleMap,
)((props) => {
  if (!props.visible) {
    return null;
  }

  let lat = 53.2976433;
  let lng = -1.8852428;
  if (props.flightPlanCoordinates.length > 0 && props.flightPlanCoordinates[0].length > 0) {
    lat = props.flightPlanCoordinates[0][0].lat;
    lng = props.flightPlanCoordinates[0][0].lng;
  }
  let zoom = 7;
  if (props.zoom) {
    zoom = props.zoom;
  }
  const {
    curvedDirectionLines = [],
    markers = [],
    defaultSetZoom = 7,
    position = {
      lat: lat,
      lng: lng,
    },
  } = props;
  const _props = props;
  const padMarkers = (() => {
    const landingSites = markers.map(({ lat, lng, ...restProps }, i) => {
      return (
        <Marker
          key={i}
          zIndex={100}
          options={{
            icon: restProps.isSecondary ? secondaryIcon : primaryIcon,
          }}
          onClick={(ev) => {
            _props.onMarkerClick(ev);
            return restProps.onClick();
          }}
          position={{ lat, lng }}
        >
          <AssignToPadInfoWindow {...restProps} />
          <SelectLegInfoWindow {...restProps} />
          <ClusterInfoWindow {...restProps} />
        </Marker>
      );
    });

    if (props.hasCluster) {
      return (
        <MarkerClusterer
          onClick={props.onMarkerClustererClick}
          zoomOnClick={false}
          minimumClusterSize={1}
        >
          {landingSites}
          <ClusterInfoWindow {...props.clusterInfo} onClose={props.onClusterClose} />
        </MarkerClusterer>
      );
    }

    return landingSites;
  })();

  const curvedMarkers = arrayFlatten(curvedDirectionLines).map(({ position, icon }, i) => {
    return (
      <Marker
        key={i}
        zIndex={-1}
        options={{
          icon,
        }}
        onClick={props.onClick}
        position={position}
      ></Marker>
    );
  });

  return (
    <GoogleMap
      defaultCenter={position}
      defaultZoom={defaultSetZoom}
      onProjectionChanged={props.onPlacesChanged}
      ref={props.onMapMounted}
      zoom={props.zoom}
      onIdle={props.onIdle}
      onZoomChanged={props.onZoomChanged}
      defaultOptions={defaultMapOptions}
      mapTypeId={props.mapType}
    >
      {props.children}
      {curvedMarkers}
      {padMarkers}
    </GoogleMap>
  );
});

function getFlightDirectionLines(coordinates, mapProjection, mapZoom) {
  const flightDirections = [];

  coordinates.forEach((flight, index) => {
    if (index < coordinates.length - 1) {
      const coodrs = {
        from: flight,
        to: coordinates[index + 1],
      };
      if (mapProjection) flightDirections.push(getCurvedLine(coodrs, mapProjection, mapZoom));
    }
  });

  return flightDirections;
}

function getMarker(position, marker_icon) {
  return new google.maps.Marker({
    position: position,
    icon: marker_icon,
  });
}

function getCurvedLine({ from, to }, projection, mapZoom) {
  let curvature = 0.2;

  let positionFrom = getMarker(from, primaryIcon).getPosition();
  let positionTo = getMarker(to, primaryIcon).getPosition();

  let positionToFirstPoint = projection.fromLatLngToPoint(positionFrom);
  let positionToSecondPoint = projection.fromLatLngToPoint(positionTo);

  let e = new google.maps.Point(
    positionToSecondPoint.x - positionToFirstPoint.x,
    positionToSecondPoint.y - positionToFirstPoint.y,
  ); // endpoint (p2 relative to p1)
  let m = new google.maps.Point(e.x / 2, e.y / 2);
  let o = new google.maps.Point(e.y, -e.x);
  let c = new google.maps.Point(m.x + curvature * o.x, m.y + curvature * o.y);

  let path_defined = "M 0,0 " + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;

  const relativeScale = 1 / Math.pow(2, -mapZoom);

  // let scale = relativeScale < 512 ? relativeScale : 512;
  let drawnSymbol = {
    path: path_defined,
    scale: relativeScale,
    strokeWeight: 3,
    strokeColor: "#242F42",
    strokeOpacity: 1.0,
    fillColor: "none",
  };

  return new google.maps.Marker({
    position: positionFrom,
    clickable: false,
    icon: drawnSymbol,
    zIndex: 0,
  });
}

const getMapBounds = (markers = []) => {
  const coordsArray = markers.map(({ lat, lng }) => ({ lat, lng }));
  if (coordsArray.length > 0) {
    const bounds = new google.maps.LatLngBounds();
    coordsArray.forEach((t) => bounds.extend(t));
    return bounds;
  }
  return null;
};

const AssignToPadInfoWindow = ({
  isSecondary,
  isInfoShown,
  distance,
  onClick,
  name,
  onAssignButtonClick,
  source,
  type,
}) => {
  if (isSecondary && isInfoShown) {
    let distanceTo = (() => {
      if (!distance) {
        return null;
      }

      return (
        <div>
          <span>{distance.toFixed(0)} miles away</span>
        </div>
      );
    })();
    return (
      <InfoWindow onCloseClick={onClick}>
        <ul className={"disabled-list gh-info-window"}>
          <li className={"gh-info-title"}>
            <a href={source} target={"_blank"}>
              <b>{name}</b>
            </a>
          </li>
          <li className={"gh-info-category uk-margin-small-top"}>
            <b>{type.display_name}</b>
          </li>
          <li className={"uk-margin-small-top"}> {distanceTo}</li>
          <li className={"uk-margin-small-top uk-text-center"}>
            <a className={"uk-button uk-button-primary gh-info-btn"} onClick={onAssignButtonClick}>
              Assign to this pad
            </a>
          </li>
        </ul>
      </InfoWindow>
    );
  }
  return null;
};

const SelectLegInfoWindow = ({
  isSelectLegInfoShown,
  closePopUp,
  onLegSelected,
  duplicates = [],
}) => {
  if (!isSelectLegInfoShown) {
    return null;
  }
  const legBtns = duplicates.map(({ id, direction, legIndex }, i) => (
    <Item key={i}>
      <a style={styles.markerInfo.btn} onClick={() => onLegSelected({ id, direction, legIndex })}>
        {`Leg ${legIndex + 1}`} ({direction})
      </a>
    </Item>
  ));
  return (
    <InfoWindow onCloseClick={closePopUp}>
      <div style={{ padding: "15px 25px 15px 10px" }}>
        <b>Select the leg to edit</b>
        <br /> <List>{legBtns}</List>
      </div>
    </InfoWindow>
  );
};

const ClusterInfoWindow = ({ isClusterInfoShown, onClose, ...props }) => {
  if (!isClusterInfoShown) {
    return null;
  }

  return (
    <InfoWindow onCloseClick={onClose}>
      <div className={"uk-padding-small"}>
        <List>
          <Item>
            <table>
              <thead>
                <tr>
                  <th>{props.name}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>departs: {props.flightsInfo.totalDepart}</td>
                  <td>arrives: {props.flightsInfo.totalArrive}</td>
                </tr>
              </tbody>
            </table>
          </Item>
        </List>
      </div>
    </InfoWindow>
  );
};

const styles = {
  markerInfo: {
    btn: {
      padding: "0 15px",
      lineHeight: "28px",
      fontSize: ".875rem",
    },
  },
};

const classNames = {
  markerInfo: {
    btn: "uk-button uk-button-primary",
  },
};

const defaultMapOptions = {
  streetViewControl: false,
  scaleControl: true,
  mapTypeControl: true,
  panControl: true,
  zoomControl: true,
  rotateControl: false,
  fullscreenControl: true,
  mountOnUpdate: false,
  position: {},
};

Map.propTypes = {
  visible: PropTypes.bool,
  mountOnUpdate: PropTypes.bool,
  markers: PropTypes.array,
  flightPlanCoordinates: PropTypes.array,
  hasCluster: PropTypes.bool,
  clusterInfo: PropTypes.object,
  onClusterClick: PropTypes.func,
  onClusterClose: PropTypes.func,
  defaultSetZoom: PropTypes.number,
  position: PropTypes.object,
  woFitBounds: PropTypes.bool,
  onDirectionsDrawn: PropTypes.func,
};

export { MapComponentOld };
