import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Form, Control } from "react-redux-form";
import { config } from "../../../configs";
import {
  DragAndDrop,
  spinnerInstance,
  replaceAttributesInString,
  IconComponent,
  ListValidateError,
  FilePreviewComponent,
  fileSize,
  formatFiles,
} from "../../../shared";
import {
  uploadStripeDocument,
  getAccountDetailsSuccess,
  removeTemporaryDocument,
  uploadTemporaryDocument,
} from "../../../actions";
import staticService from "../../../services/static.service";
import api from "../../../services/api-handler";
import { FileBlockComponent } from "../../pages/user-online/team-components/verification/file-block.component";
import { DocumentUploadOnSubmitComponent } from "../../common/document-upload-on-submit.component";

class AccountUploadDocumentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      showConfirm: false,
      modelName: "companyFilesModel",
      showError: false,
      mainTitle: staticService.findByAlias("uploadAccountVerification"),
      subTitle: staticService.findByAlias("uploadDocuments"),
      errorMessage: staticService.findByAlias("noFileUploadMessage"),
      serverErrors: [],
      preview: false,
      previewClassname: "",
      filePReview: null,
      startUpload: false,
    };
    this.uploadDocuments = this.uploadDocuments.bind(this);
    this.skip = this.skip.bind(this);
    this.remove = this.remove.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.getPreview = this.getPreview.bind(this);
  }

  handleDrop(files) {
    this.setState({ showError: false, serverErrors: [] });
    if (!this.validateFile(files)) {
      return false;
    }
    const parseFiles = formatFiles(files);
    this.setState({ files: parseFiles });
    this.context.store.dispatch(actions.change(this.state.modelName, parseFiles));
    this.fileRef.value = "";
  }

  validateFile(files = []) {
    let validateStatus = true;

    for (let i = 0; i < files.length; i++) {
      if (config.stripeAllowedFiles.indexOf(files.item(i).type) === -1) {
        validateStatus = false;
        this.setState({
          showError: true,
          serverErrors: [
            <span
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("wrongFileType") }}
            />,
          ],
        });
        break;
      }

      if (fileSize(files.item(i).size) > config.stripe_max_upload) {
        validateStatus = false;
        this.setState({
          showError: true,
          serverErrors: [
            <span
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("identity_document") }}
            />,
          ],
        });
        break;
      }
    }
    return validateStatus;
  }

  get formContent() {
    const requiredArray = (files) => {
      return files.length > 0;
    };
    return (
      <div>
        <Form
          model={this.state.modelName}
          validators={{
            "": { requiredArray },
          }}
          onSubmit={this.uploadDocuments.bind(this)}
        >
          <div
            className="uk-margin-medium-top"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("photoEvidence") }}
          />

          {this.state.showError ? (
            <div className="uk-margin-medium-top gh-upload-errors">
              <ListValidateError errors={this.state.serverErrors} />
            </div>
          ) : null}
          <ul className={"disabled-list uploaded-zone uk-margin"}>
            {this.state.files.map((file, index) => {
              return (
                <li key={index} className={"uk-width-1-1 gh-verification-doc"}>
                  <DocumentUploadOnSubmitComponent
                    index={index}
                    file={file}
                    dispatch={this.context.store.dispatch}
                    removeAction={this.props.removeTemporaryDocument}
                    onRemoveSuccess={this.remove}
                    submitUpload={this.state.startUpload}
                    onUploadSuccess={this.finishUpload.bind(this)}
                    updateAction={this.props.uploadTemporaryDocument}
                  />
                </li>
              );
            })}
          </ul>
          <div className={"upload-zone"}>
            <DragAndDrop
              onDragFinish={this.handleDrop}
              mapProps={{
                className: "js-upload uk-placeholder uk-text-center gh-verification-doc-upload",
              }}
            >
              <IconComponent
                visible={true}
                icon={"cloud-upload"}
                id={"cloud-upload"}
                className={"icon"}
                ratio={1.5}
              />
              <div className="uk-text-middle uk-margin-medium-top">Drag Files to Upload</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("fileStripeAllowedFormats"),
                }}
              ></div>
              <div className="uk-text-middle">or</div>
            </DragAndDrop>
            <div className="gh-upload-btn-wrapper">
              <button>Upload a file</button>
              <input
                type="file"
                name="files"
                accept={".jpeg, .png, .jpg"}
                ref={(fileRef) => (this.fileRef = fileRef)}
                onChange={(ev) => this.handleDrop(this.fileRef.files)}
              />{" "}
              (
              <span
                className={""}
                dangerouslySetInnerHTML={{
                  __html: replaceAttributesInString(
                    { size: config.stripe_max_upload },
                    staticService.findByAlias("maxUploadAllowed"),
                  ),
                }}
              />
              )
            </div>
          </div>
          <Control.button
            model={this.state.modelName}
            disabled={{ valid: false }}
            className={"uk-button uk-button-primary uk-align-center"}
          >
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("submit") }} />
          </Control.button>
        </Form>
      </div>
    );
  }

  get validateDocumentStatus() {
    return (
      this.props.accountDetail &&
      (this.props.accountDetail.internal_verification.upload ||
        (this.props.accountDetail.verification.document !== null &&
          this.props.accountDetail.verification_fields.indexOf("verification.document") === -1))
    );
  }

  get validateExternalAccountStatus() {
    return (
      this.props.accountDetail &&
      this.props.accountDetail.internal_verification &&
      this.props.accountDetail.internal_verification.bank
    );
  }

  get accountStatusVerified() {
    return (
      this.props.accountDetail &&
      this.props.accountDetail.verification &&
      this.props.accountDetail.verification.status === "verified"
    );
  }

  get accountStatusPending() {
    return (
      this.props.accountDetail &&
      this.props.accountDetail.verification &&
      this.props.accountDetail.verification.status === "pending"
    );
  }

  get preivewBlock() {
    return (
      <div>
        <ul className={"disabled-list uploaded-zone uk-margin"}>
          {this.props.documents &&
            this.props.documents.map((file, index) => {
              return (
                <FileBlockComponent
                  key={index}
                  fileName={file.name}
                  index={index}
                  showRemoveBtn={false}
                  preview={this.preview.bind(this, file)}
                  isUploaded={true}
                />
              );
            })}
        </ul>
        <FilePreviewComponent
          visible={this.state.preview}
          file={this.state.filePReview}
          classNames={this.state.previewClassname}
          onClose={this.closePreview.bind(this)}
        />
      </div>
    );
  }

  skip() {
    this.setState({ files: [] });
    this.props.changeView();
  }

  uploadDocuments() {
    this.setState({
      startUpload: true,
    });
  }

  finishUpload(info) {
    const uploadedFile = this.state.files[info.fileIndex];
    uploadedFile.upload = info.upload;
    this.setState({
      showError: false,
      serverErrors: [],
      startUpload: false,
    });
    this.context.store.dispatch(actions.change(this.state.modelName, this.state.files));
    this.props.uploadStripeDocument({ file: info.file_name }, this.onSuccess, this.onUploadError);
  }

  onUploadError(err) {
    let serverErrors = null;
    if (err.status === 423) {
      serverErrors = [err.data.error.message];
    }
    if (err.status === 422) {
      const errorsFields = Object.keys(err.data);
      serverErrors = errorsFields.map((key) => err.data[key]);
    }
    this.setState({
      showError: true,
      serverErrors: serverErrors,
      files: [],
      submitUpload: false,
    });
  }

  remove(index) {
    const files = this.state.files.slice(0);
    files.splice(index, 1);
    this.setState({ files: files, submitUpload: false, showError: false, serverErrors: [] });
    this.context.store.dispatch(actions.resetValidity(this.state.modelName));
    this.context.store.dispatch(actions.change(this.state.modelName, files));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.context.store.dispatch(actions.setPending(this.state.modelName, false));
    }
    if (this.props.isBankAccountUpdated !== nextProps.isBankAccountUpdated) {
      this.setState({ editState: true });
    }
    if (
      nextProps.bankAccount !== null &&
      nextProps.bankAccount !== undefined &&
      this.props.bankAccount !== nextProps.bankAccount
    ) {
      this.setState({ editState: true });
    }
  }

  onSuccess(info) {
    this.context.store.dispatch(getAccountDetailsSuccess(info));
    api.setProperty("skipErrorAlert", false);
    api.showSpinner = true;
    spinnerInstance.hide();
  }

  preview(file) {
    this.setState({
      preview: true,
      filePReview: file,
      previewClassname: "full shown",
    });
  }

  closePreview() {
    this.setState({
      preview: false,
      filePReview: null,
      previewClassname: "",
    });
  }

  getPreview(type, alias) {
    return (
      <div>
        <div
          className={`uk-alert gh-alert-without-bg uk-alert-${type}`}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }}
        ></div>
        {this.preivewBlock}
      </div>
    );
  }

  render() {
    if (this.props.visible && this.props.accountDetail) {
      if (
        this.validateExternalAccountStatus &&
        this.validateDocumentStatus &&
        this.accountStatusVerified
      ) {
        return this.getPreview("success", "stripePhotoSignatureValidated");
      }

      if (
        this.validateExternalAccountStatus &&
        this.validateDocumentStatus &&
        this.accountStatusPending
      ) {
        return this.getPreview("warning", "accountInPending");
      }

      if (this.validateExternalAccountStatus && !this.validateDocumentStatus) {
        return <div>{this.formContent}</div>;
      }
    }

    return (
      <div
        className={"uk-alert uk-alert-warning gh-alert-without-bg"}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("bankAccountRequirement") }}
      ></div>
    );
  }
}

AccountUploadDocumentComponent.propTypes = {
  visible: PropTypes.bool,
};

AccountUploadDocumentComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  const response = {
    accountStatus: false,
    uploadDocuments: state.uploadDocuments,
    companyFilesModel: state.companyFilesModel,
    isBankAccountUpdated: state.bankAccount.event,
    accountDetail: state.auth,
    documents: state.auth.documents,
  };

  if (
    state.auth.verification &&
    state.auth.verification.status &&
    state.auth.verification.status === "verified"
  ) {
    response.accountStatus = true;
  }
  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadTemporaryDocument,
      removeTemporaryDocument,
      uploadStripeDocument,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(AccountUploadDocumentComponent);
export { COMPONENT as AccountUploadDocumentComponent };
