import PropTypes from "prop-types";
import React from "react";
import PricingPlanBundle from "./PricingPlanBundle";
import PricingPlanCommission from "./PricingPlanCommission";

export default function PricingPlan(props) {
  const { bundle, commission } = props;

  if (bundle && Object.keys(bundle).length) return <PricingPlanBundle bundle={bundle} />;
  return <PricingPlanCommission commission={commission} />;
}

PricingPlan.propTypes = {
  bundle: PropTypes.object,
  commission: PropTypes.number,
};
