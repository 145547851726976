import * as types from "../../actions/actionTypes";

const initialState = [];

function manufacturer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_MANUFACTURER_SUCCESS:
      return action.manufacturers;
    default:
      return state;
  }
}

export { manufacturer };
