import React from "react";
import PropTypes from "prop-types";
import "./MarketplaceRequestQuotedStatus.css";
import staticService from "../../../../../../../services/static.service";
import Button from "../../../../../../common/Button";
import { useLocation } from "react-use";

const MarketplaceRequestQuotedStatus = ({ quotesNumber, requestId }) => {
  const location = useLocation();
  const link = `/flights/quotes/filter/all/request/${requestId}`;
  const linkObject = {
    pathname: link,
    state: {
      goBackObj: { pathname: location.pathname + location.search, alias: "backToMarketplace" },
    },
  };
  return (
    <div className="gh-text-no-wrap">
      {staticService.findByAlias("quotedStatus")}
      <Button
        onClick={linkObject}
        type="link"
        variant="text"
        color="dark"
        className="gh-marketplace-quoted-status-button"
      >
        ({quotesNumber})
      </Button>
    </div>
  );
};

MarketplaceRequestQuotedStatus.propTypes = {
  quotesNumber: PropTypes.number.isRequired,
  requestId: PropTypes.number.isRequired,
};
MarketplaceRequestQuotedStatus.defaultProps = {};
export default MarketplaceRequestQuotedStatus;
