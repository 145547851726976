import React, { Component } from "react";
import { EventsLayout } from "../../layouts";
import { fireTracking, returnRouteUrl, RouteWithSubRoutes } from "../../../shared";
import { bindActionCreators } from "redux";
import { config, pages } from "../../../configs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getMeta,
  loadDestinations,
  loadEventContent,
  loadFilteredDestinations,
  loadFilteredEvents,
  loadHomeContent,
  loadMoreEventContent,
  RESET_EVENT_DATA,
} from "../../../actions";
import { actions } from "react-redux-form";
import { DestinationsInfoComponent } from "../../common/destinations-info.component";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import Auth from "../../../services/auth";
import DocumentTitle from "../../common/DocumentTitle";
import staticService from "../../../services/static.service";

const STATIC_FILTERS = [{ key: "entity_target", value: "destination" }];

class DestinationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
      items: props.listEvents,
      roleSelectedPrefix: "a",
      endPagination: false,
      id: 0,
      page: 1,
      isFilterShown: false,
      customFilters: {},
      filters: STATIC_FILTERS,
      testState: 1,
    };

    this.getByAlias = this.getByAlias.bind(this);
    this.mapItems = this.mapItems.bind(this);
    this.show = this.show.bind(this);
    this.showFilters = this.showFilters.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.filteredPages = this.filteredPages.bind(this);
    this.handleUpdateCustomFilters = this.handleUpdateCustomFilters.bind(this);
    fireTracking(this.props.location.pathname);

    this.listOfDestinationsRef = React.createRef();
  }

  componentDidMount() {
    const { loadDestinations, loadFilteredDestinations, loadHomeContent, getMeta, screenInnerH } =
      this.props;
    const searchParams = new URLSearchParams(location.search);
    const countryValue = searchParams.get("country");
    const locationValue = searchParams.get("location_name");
    const flightTypeValue = searchParams.get("flight_type");

    const formValue = [
      { key: "country_code", value: countryValue || "" },
      { key: "location_name", value: locationValue || "" },
      { key: "destination_type", value: flightTypeValue || "" },
    ];

    if (countryValue && locationValue) {
      this.showFilters();
      loadFilteredDestinations(1, formValue);
    } else {
      loadDestinations();
    }

    const key = window.location.pathname.substring(1).replaceAll("/thank-you", "");
    getMeta(key);
    loadHomeContent();
    document.documentElement.style.setProperty("--vh", `${screenInnerH}px`);

    //  open filter if url includes ?gclid
    const searchedParameter = "gclid";
    if (
      window.location?.search?.includes(searchedParameter) ||
      countryValue ||
      locationValue ||
      flightTypeValue
    ) {
      this.setState({ isFilterShown: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { isFilterShown } = this.state;

    const searchParams = new URLSearchParams(location.search);
    const oldSearchParams = prevProps?.location?.search;
    const newSearchParams = this?.props?.location?.search;
    const countryValue = searchParams.get("country");
    const locationValue = searchParams.get("locations");
    const flightTypeValue = searchParams.get("flight_type");

    if (oldSearchParams !== newSearchParams && countryValue) {
      this.handleUpdateCustomFilters({
        country: countryValue || "",
        locations: locationValue || "",
        destination_type: flightTypeValue || "",
      });

      if (!isFilterShown) {
        this.showFilters();
      }
      window.location.reload(false);
    }
  }

  handleUpdateCustomFilters(filters) {
    this.setState({ customFilters: filters });
  }

  getByAlias(alias = "") {
    const { items } = this.props;
    return items.filter((item) => item.alias === alias);
  }

  mapItems(alias = "") {
    const result = this.getByAlias(alias);
    const rows = result.filter((item) => {
      return item.type === "both" || item.type === "Both";
    });
    return rows.map((row) => {
      return {
        image: row.icon,
        title: row.title,
        content: row.gh_content,
      };
    });
  }

  show(id) {
    this.setState({ id: id });
  }

  submitFilters() {
    const { filters, loadFilteredEvents } = this.props;

    const filterKeys = Object.keys(filters);
    const staticFilters = [...STATIC_FILTERS];
    filterKeys.forEach((key) => {
      if (filters[key] !== "") {
        staticFilters.push({ key: key, value: filters[key] });
      }
    });

    this.setState({ filters: staticFilters });
    loadFilteredEvents(1, staticFilters);
  }

  loadMoreEvents() {
    this.props.loadMoreDestinations(this.props.totalEvents.current_page + 1, this.state.filters);
  }

  showFilters() {
    this.setState({ isFilterShown: !this.state.isFilterShown });
  }

  resetFilters() {
    this.setState({ page: 1, filters: [] }, () => {
      this.props.loadEventContent(this.state.page, STATIC_FILTERS);
      this.props.resetModel();
    });
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: RESET_EVENT_DATA });
    this.props.resetModel();
  }

  filteredPages() {
    return pages.filter((page) => {
      return !(page.title && page.title.indexOf("bookADemo") !== -1 && Auth.isAuthenticated());
    });
  }

  render() {
    const {
      listEvents,
      totalEvents,
      metaData,
      countries,
      updateModel,
      eventTypes,
      locationFilters,
      loadFilteredDestinations,
      loadDestinations,
      history,
      match,
      title,
      routes,
    } = this.props;

    return (
      <EventsLayout
        activeRoute={match}
        pageTitle={title}
        pages={this.filteredPages()}
        registerPath={config.redirectToRegister}
      >
        <Switch>
          {routes &&
            routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  {...returnRouteUrl(route, match.url)}
                  title={route.title}
                  id={listEvents && listEvents.find((index) => index.id === this.state.id)}
                />
              );
            })}
          <Route
            path="/"
            onEnter={fireTracking}
            render={() => {
              return (
                <div className={"uk-background-norepeat uk-background-cover"}>
                  <DocumentTitle title={staticService.findByAlias("eventPageTitle")} />
                  <Helmet>
                    <link rel="canonical" href="https://getheli.com/destinations" />
                    {metaData?.meta?.message?.map((meta) => (
                      <meta key={meta.name} name={meta.name} content={meta.content} />
                    ))}
                    <title>{staticService.findByAlias("destinationsPageTitle")}</title>
                  </Helmet>
                  <DestinationsInfoComponent
                    endPagination={totalEvents && totalEvents.total === listEvents.length}
                    visible={true}
                    eventInfo={this.mapItems("destinations")}
                    listEvents={listEvents}
                    onClick={this.show}
                    submit={this.submitFilters}
                    updateModel={updateModel}
                    resetFilters={this.resetFilters}
                    eventTypes={eventTypes}
                    showFilters={this.showFilters}
                    isFilterShown={this.state.isFilterShown}
                    loadMore={this.loadMoreEvents.bind(this)}
                    countries={countries}
                    filters={this.props.filters}
                    screenW={this.props.screenW}
                    loadFilteredDestinations={loadFilteredDestinations}
                    loadDestinations={loadDestinations}
                    handleUpdateCustomFilters={this.handleUpdateCustomFilters}
                    customFilters={this.state.customFilters}
                    listOfDestinationsRef={this.listOfDestinationsRef}
                    locationFilters={locationFilters}
                    history={history}
                  />
                </div>
              );
            }}
          />
        </Switch>
      </EventsLayout>
    );
  }
}

DestinationsList.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadHomeContent,
      loadEventContent,
      loadMoreEventContent,
      loadFilteredEvents,
      loadDestinations,
      loadFilteredDestinations,
      getMeta,
      resetModel: () => dispatch(actions.reset("eventsFiltersModel")),
      resetValidity: (model) => dispatch(actions.resetValidity(model)),
      updateModel: (model, value) => dispatch(actions.change(model, value)),
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    totalEvents: state.events.meta.pagination,
    listEvents: state.events.data,
    items: state.home,
    auth: state.auth,
    ...state.screenDimensions,
    metaData: state.metaData,
    eventTypes: state.events.types,
    countries: state.events.countries,
    locationFilters: state.events.locations,
    filters: state.eventsFiltersModel,
    screenW: state.screenDimensions.screenInnerW,
  };
}

DestinationsList.propTypes = {
  items: PropTypes.array,
  listEvents: PropTypes.array,
  loadEventContent: PropTypes.func,
  loadHomeContent: PropTypes.func,
  loadMoreEventContent: PropTypes.func,
  location: PropTypes.shape(),
  match: PropTypes.shape(),
  routes: PropTypes.array,
  screenInnerH: PropTypes.number,
  title: PropTypes.string,
  totalEvents: PropTypes.shape(),
  eventTypes: PropTypes.array,
  filters: PropTypes.object,
  loadDestinations: PropTypes.func,
  loadMoreDestinations: PropTypes.func,
  getMeta: PropTypes.func,
  loadFilteredDestinations: PropTypes.func,
  locationFilters: PropTypes.array,
  loadFilteredEvents: PropTypes.func,
  resetModel: PropTypes.func,
  metaData: PropTypes.object,
  countries: PropTypes.array,
  updateModel: PropTypes.func,
  history: PropTypes.func,
  screenW: PropTypes.number,
};

const EVENTS = connect(mapStateToProps, mapDispatchToProps)(DestinationsList);
export { EVENTS as DestinationsList };
