import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Control, Fieldset, Errors } from "react-redux-form";
import { validationRules, validationMessages } from "../../../configs";
import {
  CustomValidateError,
  updateModel,
  GridComponent,
  prependHttpWithModelUpdate,
} from "../../../shared";
import staticService from "../../../services/static.service";
import { TermsConditionsComponent } from "../../common";
import PhoneInput from "../../common/phoneInput";

const {
  required,
  requiredObject,
  signupValidUrl,
  alphaNumStrictValidation,
  alphaNumValidation,
  min,
  max,
  phoneInputValidation,
} = validationRules;

const CompanyFieldset = ({
  company,
  soleTrader,
  changeIcon,
  changeIconClass,
  termsAction,
  termsConditionsClass,
  countriesList,
  changeCountry,
}) => {
  const checkboxWrapperStyle = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <Fieldset model="userRegisterModel">
      <GridComponent
        visible={true}
        attrProps={{
          id: "row-1",
          className: "uk-margin-small-top uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className="uk-form-label required-contact uk-width-expand"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("companyCountry"),
              }}
            />
            <div className="uk-width-auto">
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "countrySelectTooltip",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.select
            className="uk-select"
            model=".company.country_code"
            changeAction={changeCountry}
            updateOn="change"
            validators={{ required, alphaNumValidation }}
          >
            <option value={""} key="optional">
              Select country
            </option>
            {countriesList.countries &&
              countriesList.countries.map((country, index) => (
                <option key={country.name + index} value={country.code}>
                  {country.name}
                </option>
              ))}
          </Control.select>

          <Errors
            model=".company.country_code"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyCountry"),
              ),
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
            }}
          />
        </div>
        <div className={"validation-wrapper"}>
          <label
            className={"uk-form-label required-contact"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyName"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".company.name"}
            changeAction={updateModel}
            disabled={soleTrader}
            updateOn="change"
            validators={!soleTrader && { required, alphaNumValidation }}
          />

          <Errors
            model={".company.name"}
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyName"),
              ),
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-2",
          className: "uk-child-width-expand@s uk-margin-small-top",
        }}
      >
        <label style={checkboxWrapperStyle}>
          <Control.checkbox
            className="uk-checkbox gh-checkbox"
            model={".company.sole_trader"}
            changeAction={updateModel}
            updateOn="change"
          />
          <span style={{ marginLeft: 15 }}>{staticService.findByAlias("soleTrader")}</span>
        </label>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-3",
          className: "uk-child-width-expand@s uk-margin-small-top",
        }}
      >
        <div className="validation-wrapper">
          <label
            className={"uk-form-label  required-contact"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyPhone"),
            }}
          />
          <Control
            model={".company.phone"}
            changeAction={updateModel}
            disabled={soleTrader}
            placeholder={"+1 213 373 4253"}
            validators={
              !soleTrader && { required: requiredObject("value", true), phoneInputValidation }
            }
            selectedCountry={company.country_code}
            component={PhoneInput}
          />
          <Errors
            model=".company.phone"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyPhone"),
              ),
              phoneInputValidation: validationMessages().phoneValidationMessage,
            }}
          />
        </div>
        <div className="validation-wrapper   ">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className={"required-contact uk-form-label uk-width-expand"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("directPhoneNumber"),
              }}
            />
            <div className={"uk-width-auto"}>
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "directPhoneTooltipMessage",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.text
            model={".phone"}
            changeAction={updateModel}
            placeholder={"+1 213 373 4253"}
            validators={
              !soleTrader && { required: requiredObject("value", true), phoneInputValidation }
            }
            selectedCountry={company.country_code}
            component={PhoneInput}
          />
          <Errors
            model=".phone"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("directPhoneNumber"),
              ),
              phoneInputValidation: validationMessages().phoneValidationMessage,
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-4",
          className: "uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper   ">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className={"uk-form-label uk-width-expand"}
              style={{ marginTop: 9 }}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("companyNumber"),
              }}
            />
            <div className={"uk-width-auto"}>
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "companyNumberTooltip",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.text
            className={"uk-input"}
            model={".company.number"}
            changeAction={updateModel}
            disabled={soleTrader}
            updateOn="change"
            validators={!soleTrader && { alphaNumStrictValidation, max: max(14) }}
          />
          <Errors
            model=".company.number"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              alphaNumStrictValidation: validationMessages().alphaNumStrictValidationMessage,
              max: validationMessages().maxMessage(14),
            }}
          />
        </div>
        <div className="validation-wrapper">
          <label
            className={"uk-form-label"}
            style={{ marginTop: 9 }}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyWebsite"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".company.website"}
            mapProps={{ value: company.website }}
            changeAction={prependHttpWithModelUpdate}
            validators={{ signupValidUrl }}
          />
          <Errors
            model=".company.website"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              signupValidUrl: validationMessages().validUrlMessage,
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-5",
          className: "uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper gh-padding-terms-top uk-width-1-2@s">
          <TermsConditionsComponent
            changeIcon={changeIcon}
            changeIconClass={changeIconClass}
            action={termsAction}
            termsConditionsClass={termsConditionsClass}
            condition={true}
            broker={true}
          />
        </div>
      </GridComponent>
    </Fieldset>
  );
};

CompanyFieldset.propTypes = {
  company: PropTypes.object,
  soleTrader: PropTypes.bool,
  changeIcon: PropTypes.string,
  changeIconClass: PropTypes.string,
  termsAction: PropTypes.func,
  termsConditionsClass: PropTypes.string,
  countriesList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  changeCountry: PropTypes.func,
};

const COMPONENT = connect()(CompanyFieldset);

export { COMPONENT as CompanyFieldset };
