import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { LocationAutocompleteItem, LocationAutocompleteItemShort } from "./";
import staticService from "../../../services/static.service";
import isInViewport from "../../../utils/isInViewport";

const calcDropdownMaxHeight = (node) => () => {
  if (node) {
    const nodeRect = node.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const nodeOffsetTop = nodeRect.top;

    const maxHeight = windowHeight - nodeOffsetTop - 15;

    return Math.max(maxHeight, 380);
  }

  return "";
};

const LocationAutocompleteList = (props) => {
  const {
    onSelect,
    locations,
    visibleOverlay,
    seeAllFunc,
    visibleAllLocations,
    shortType,
    checkCoverage,
    className,
    setRef,
    // relativeEl,
  } = props;
  const [locationsNodes, setLocationsNodes] = useState({ items: [], container: null });
  const [navigateInited, setNavigateInited] = useState(false);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState("");
  const [, setKeyIndex] = useState(-1);

  const dropdownRef = useRef(null);

  const updateDropdownMaxHeight = useCallback(() => {
    setDropdownMaxHeight(calcDropdownMaxHeight(dropdownRef.current));
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", updateDropdownMaxHeight);
    window.addEventListener("resize", updateDropdownMaxHeight);

    return () => {
      window.removeEventListener("scroll", updateDropdownMaxHeight);
      window.removeEventListener("resize", updateDropdownMaxHeight);
    };
  }, []);

  useEffect(() => {
    if (document.querySelectorAll(".gh-locations-container li").length) {
      setLocationsNodes({
        items: [...document.querySelectorAll(".gh-locations-container li")].map((node, index) => ({
          node,
          index,
        })),
        container: null,
      });
    } else if (document.querySelectorAll(".gh-autocomplete-list .gh-short-list-item").length) {
      setLocationsNodes({
        items: [...document.querySelectorAll(".gh-autocomplete-list .gh-short-list-item")].map(
          (node, index) => ({
            node,
            index,
          }),
        ),
        container: document.querySelector(".autocomplete-dropdown-container"),
      });
    } else setLocationsNodes({ items: [], container: null });
  }, [locations]);

  const updateNodesStyle = () => {
    let currentIndex = undefined;

    setKeyIndex((prevState) => {
      currentIndex = prevState;
      return prevState;
    });

    locationsNodes.items.forEach(({ node, index }) => {
      if (index === currentIndex) node.classList.add("active");
      else node.classList.remove("active");
    });
  };

  const checkCurrentNodeViewport = () => {
    let currentIndex = undefined;

    setKeyIndex((prevState) => {
      currentIndex = prevState;
      return prevState;
    });

    if (locationsNodes.items[currentIndex]) {
      if (!isInViewport(locationsNodes.items[currentIndex].node, locationsNodes.container)) {
        if (locationsNodes.container) {
          locationsNodes.container.scrollTo({
            top: locationsNodes.items[currentIndex].node.offsetTop - 50,
            behavior: "smooth",
          });
        } else {
          locationsNodes.items[currentIndex].node.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  };

  const navigate = (ev) => {
    let currentIndex = undefined;

    setKeyIndex((prevState) => {
      currentIndex = prevState;
      return prevState;
    });

    if (ev.code === "ArrowUp") {
      ev.preventDefault();
      ev.stopPropagation();

      if (currentIndex === -1 || currentIndex - 1 === -1) {
        setKeyIndex(locationsNodes.items.length - 1);
      } else {
        setKeyIndex((prevState) => prevState - 1);
      }
      updateNodesStyle();
    } else if (ev.code === "ArrowDown") {
      ev.preventDefault();
      ev.stopPropagation();

      if (
        currentIndex === locationsNodes.items.length - 1 ||
        currentIndex + 1 > locationsNodes.items.length - 1
      ) {
        setKeyIndex(0);
      } else {
        setKeyIndex((prevState) => prevState + 1);
      }
      updateNodesStyle();
    } else if (ev.code === "Enter") {
      ev.preventDefault();
      ev.stopPropagation();

      locationsNodes.items[currentIndex].node.click();
    }

    checkCurrentNodeViewport();
  };

  useEffect(() => {
    if (!navigateInited && locationsNodes.items.length > 1) {
      document.addEventListener("keydown", navigate);
      setNavigateInited(true);
    }

    return () => {
      document.removeEventListener("keydown", navigate);
      setKeyIndex(-1);
      setNavigateInited(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsNodes]);

  const seeAllHandler = (e) => {
    seeAllFunc(e);
  };

  const getLocationsList = useCallback(() => {
    const noValues = Object.values(locations).every((a) => a.length === 0);
    const locationsTitles = Array.from(Object.keys(locations)).filter((elm) => elm !== "events");
    // put events last item
    locationsTitles.push("events");

    return (
      !noValues && (
        <div className="gh-locations-container">
          {locationsTitles.map((title, index) => {
            if (locations[title].length) {
              return (
                <div key={title + index} className="gh-locations-list-section">
                  {/*{console.log(locations[title])}*/}
                  <h5>
                    {title === "venues" ? staticService.findByAlias("addressOrVenues") : title}
                  </h5>
                  <ul className="disabled-list gh-autocomplete-list">
                    {locations[title].map((location, index) => (
                      <LocationAutocompleteItem
                        key={index}
                        onClick={onSelect}
                        item={location}
                        sectionTitle={title}
                        checkCoverage={checkCoverage}
                      />
                    ))}
                  </ul>
                </div>
              );
            }
          })}
        </div>
      )
    );
  }, [locations, onSelect, checkCoverage]);

  let locationInput;
  const listWrapper = document.getElementsByClassName("autocomplete-dropdown-container")[0];

  if (
    listWrapper &&
    listWrapper.parentElement &&
    listWrapper.parentElement.getBoundingClientRect()
  ) {
    locationInput = document
      .getElementsByClassName("autocomplete-dropdown-container")[0]
      .parentElement.getBoundingClientRect();
  }

  if (!visibleOverlay) {
    if (typeof locations === "object") {
      if (Object.values(locations).every((a) => a.length === 0)) return null;
    } else if (!locations.length) return null;
  }
  const screen13inch = window.innerWidth > 1279 && window.innerHeight < 900;
  const autocompleteWidthBreakpoint = locationInput && window.innerWidth > 992 && !screen13inch;
  const autocompleteMinWidthBreakpoint =
    locationInput && locationInput.width < 450 && window.innerWidth > 992 && !screen13inch;

  return (
    <div
      className={`autocomplete-dropdown-container ${
        shortType ? "" : "gh-long-dropdown-list"
      } ${className}`}
      style={{
        width: autocompleteWidthBreakpoint ? locationInput.width - 4 : "100%",
        minWidth: autocompleteMinWidthBreakpoint ? 480 : "100%",
        maxHeight: dropdownMaxHeight || calcDropdownMaxHeight(dropdownRef.current)(),
      }}
      ref={dropdownRef}
      id="locations-list"
    >
      {shortType ? (
        <ul className="disabled-list gh-autocomplete-list">
          {locations.map((location, index) => (
            <LocationAutocompleteItemShort
              key={index}
              onClick={onSelect}
              item={location}
              checkCoverage={checkCoverage}
            />
          ))}
        </ul>
      ) : (
        getLocationsList()
      )}
      {shortType && !visibleAllLocations ? (
        <div className="gh-autocomplete-footer">
          <hr className="gh-divider" />
          <div className="gh-autocomplete-btn-wrapper">
            <button onClick={seeAllHandler} className="gh-autocomplete-btn">
              {staticService.findByAlias("seeAll")}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

LocationAutocompleteList.defaultProps = {
  locations: [],
  className: "",
};

LocationAutocompleteList.propTypes = {
  onSelect: PropTypes.func,
  locations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visibleOverlay: PropTypes.bool,
  seeAllFunc: PropTypes.func,
  visibleAllLocations: PropTypes.bool,
  shortType: PropTypes.bool,
  checkCoverage: PropTypes.bool,
  className: PropTypes.string,
  setRef: PropTypes.func,
  // relativeEl: PropTypes.any,
};

export { LocationAutocompleteList };
