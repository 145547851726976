export const ERROR_500 =
  "An unexpected error happened on the server. Please refresh and if this happens again feel free to use the Feedback button";

export const RESTRICT_ERROR_MESSAGE =
  "Your account is under GetHeli management, and in order to avoid inconsistencies we have restricted your capabilities.";

export const REGISTER = "REGISTER";

export const REQUEST = "REQUEST";

export const ACTIVE_CLASS = "uk-active";

export const QUOTE_UNSUCCESSFULL = "unsuccessful";

export const QUOTE_REJECTED = "rejected";

export const QUOTE_DECLINED = "declined";

export const ASSIGN_REQUEST = "assign";

export const QUOTE_DISCUSS = "discussion";

export const QUOTE_SELECTED = "booking_init";

export const QUOTE_OPEN = "open";

export const QUOTE_WITHDRAWN = "withdrawn";

export const QUOTE_DISCUSSION = "discussion";

export const QUOTE_AMEND = "amend";

export const QUOTE_CLOSED = "closed";

export const REQUEST_WITHDRAWN = "request-withdrawn";

export const BROKER_ADMIN = "broker_admin";
export const BROKER_TEAM = "broker_team";
export const OPERATOR_ADMIN = "operator_admin";
export const OPERATOR_TEAM = "operator_team";
export const OPERATOR_MAIN = "operator";
export const BROKER_MAIN = "broker";
export const SOLE_TRADER = "sole_trader";

export const COMPLETED = "completed";
export const CONFIRMED = "confirmed";
export const CANCELLED = "cancelled";
export const PROVISIONAL = "provisional";
export const CURRENT = "current";

export const dateType = [
  { key: "1", name: "exactDate" },
  { key: "2", name: "somePossibilityOfChangeDate" },
  { key: "3", name: "strongPossibilityChangeDate" },
];

export const timeType = [
  { key: "1", name: "exactTime", shortName: "exactTime" },
  { key: "2", name: "mightBeEarlier", shortName: "tbcByClientItMightBeLater" },
  { key: "3", name: "mightBeLater", shortName: "tbcByClientItMightBeEarlier" },
  { key: "4", name: "toBeConfirmedByClient", shortName: "tbcByClient" },
  { key: "5", name: "suggestBestTime", shortName: "suggestBestTime" },
];

export const CAN_SEND_AIRCRAFT_DATA = "CAN_SEND_AIRCRAFT_DATA";

export const LondonLocation = { lat: 51.509865, lng: -0.118092 };

export const INTERNATIONAL_FEE = "international_quote_price";
export const EUROPEAN_FEE = "european_quote_price";
export const NO_FEE = "manual_bank_transfer_quote_price";

export const ownerApprovalOptions = [
  { id: 0, value: "ownerApprovalOption0" },
  { id: 1, value: "ownerApprovalOption1" },
  { id: 2, value: "ownerApprovalOption2" },
  { id: 3, value: "ownerApprovalOption3" },
];

// Other
// Local Storage KEYS
export const IS_PREV_PAGE_BOOKING_DETAILS = "IS_PREV_PAGE_BOOKING_DETAILS";

export const OPEN = "open";
export const ALL = "all";
export const REJECTED = "rejected";
export const UNSUCCESSFUL = "unsuccessful";
export const WITHDRAWN = "withdrawn";
export const EXPIRED = "expired";
export const PENDING = "pending";
export const BOOKING_INIT = "booking_init";
export const REJECTED_UNSUCCESSFUL = "rejected-unsuccessful";
export const PRE_QUOTED = "pre_quoted";

//Enquiry steps
export const OPTIONS = "options";
export const DETAILS = "details";
export const STEPS_PASSED = "stepsPassed";
