import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import moment from "moment";

import "./ViewDateTime.css";

import clock from "../../../assets/img/svg/clock.svg";
import calendar from "../../../assets/img/svg/calendar.svg";

const ViewDateTime = (props) => {
  const { view, label, dateTime, secondaryText, showUTC } = props;
  const icon = view === "date" ? calendar : clock;
  const visibleUTC = showUTC && view === "time";

  return (
    <div className="gh-view-datetime">
      <div className="gh-view-datetime-icon">
        <img src={icon} alt="icon" />
      </div>

      <div className="gh-view-datetime-content">
        <div className="gh-view-datetime-label">{staticService.findByAlias(label) || label}</div>
        <div className="gh-view-datetime-value">
          <span>{dateTime}</span>

          {visibleUTC ? (
            <span className="gh-view-datetime-utc">{`(UTC${moment().format("Z")})`}</span>
          ) : (
            ""
          )}

          <span className="gh-view-datetime-secondary-text">{secondaryText}</span>
        </div>
      </div>
    </div>
  );
};

ViewDateTime.defaultProps = {
  view: "date",
  label: "",
  dateTime: "",
  secondaryText: "",
  showUTC: true,
};

ViewDateTime.propTypes = {
  view: PropTypes.oneOf(["date", "time"]),
  label: PropTypes.string,
  dateTime: PropTypes.string,
  secondaryText: PropTypes.string,
  showUTC: PropTypes.bool,
};

export default ViewDateTime;
