import PropTypes from "prop-types";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ControlList from "./ControlList";

export function Control(props) {
  const { name, disabled, children, required, notAComponent } = props;
  const { control } = useFormContext();

  const formControl = React.useCallback(
    ({ field, fieldState: { invalid } }) => {
      let childrenProps = {};

      if (notAComponent) {
        childrenProps = { ...field, disabled };
      } else {
        childrenProps = { ...field, disabled, invalid };
      }

      return React.cloneElement(React.Children.only(children), childrenProps);
    },
    [children, disabled],
  );

  return <Controller rules={{ required }} control={control} name={name} render={formControl} />;
}

Control.propTypes = {
  name: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  notAComponent: PropTypes.bool,
};

Control.defaultProps = {
  required: false,
  notAComponent: false,
};

Control.List = ControlList;
