import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { notification } from "uikit";
import staticService from "../../../services/static.service";
import { ComplexTableComponent, ModalComponent } from "../../../shared";
import { PaginationService } from "../../../services";
import { config } from "../../../configs";
import { clientsListConfig } from "../../../configs";
import Button, { ButtonIcon } from "../Button";
import { ClientListForm } from "./ClientsListForm";
import { getClientList, removeClient } from "../../../actions";
import InputNumber from "../../Form/components/InputNumber";
import { showNotification } from "../../../utils/showNotification";

import "./styles/index.css";

const parsePhoneNumber = (phone) => {
  if (phone.value && phone.value.charAt(0) !== "+") return `+${phone.value}`;
  return phone.value || "---";
};

class ClientsList extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      tableHeaders: clientsListConfig.headers,
      columns: clientsListConfig.columns,
      selectedClient: {},
      modalFormVisible: false,
      isLoading: true,
    };
    this.paginationService = new PaginationService(
      context.store.dispatch,
      getClientList,
      config.paginationLimit,
      [],
    );
    this.paginationService.setProp("columns", this.state.columns);
    this.paginationService.setProp("headers", this.state.tableHeaders);
    this.paginationService.loadData().then(() => {
      this.setState({ isLoading: false });
    });
    this.prepareRows = this.prepareRows.bind(this);
    this.tableAction = this.tableAction.bind(this);
    this.prepareUpdateClient = this.prepareUpdateClient.bind(this);
    this.onFormClose = this.onFormClose.bind(this);
    this.onRemoveClientClose = this.onRemoveClientClose.bind(this);
    this.onRemoveClient = this.onRemoveClient.bind(this);
    this.onFormUpdated = this.onFormUpdated.bind(this);
    this.handleRemoveClient = this.handleRemoveClient.bind(this);

    this.clientToRemoveId = null;
  }

  prepareRows(items) {
    return items.map((item) => {
      item.client_identifier = item.client_identifier || "---";
      item.first_name = item.first_name || "---";
      item.last_name = item.last_name || "---";
      item.weight = InputNumber.parse(item.weight, { defaultValue: "---" });
      item.email = item.email || "---";
      item.originalPhone = item.phone;
      item.phone = parsePhoneNumber(item.phone);

      return item;
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.clientsList) !== JSON.stringify(nextProps.clientsList)) {
      this.paginationService.populateItems(nextProps.clientsList, this.prepareRows);
    }
  }

  prepareUpdateClient(client = {}) {
    return () => {
      const preparedClient = Object.fromEntries(
        Object.entries(client).map(([key, value]) => [key, !value || value === "---" ? "" : value]),
      );

      this.setState({ selectedClient: preparedClient, modalFormVisible: true }, () => {
        this.modalForm.open();
      });
    };
  }

  onRemoveClient(id) {
    return () => {
      this.clientToRemoveId = id;
      this.modalRemoveClient.open();
    };
  }

  handleRemoveClient() {
    this.props
      .removeClient(this.clientToRemoveId)
      .then((res) => {
        //if there is an error throw it to catch statement
        if (res?.data?.errors) throw res?.data?.message;
        this.paginationService.filterList(() =>
          this.paginationService.items.filter((itm) => itm.id !== this.clientToRemoveId),
        );
        this.forceUpdate();
        this.clientToRemoveId = null;
        this.onRemoveClientClose();

        notification("Client removed successfully", {
          status: "calculator",
          pos: "top-right",
        });
      })
      .catch(() => {
        this.clientToRemoveId = null;
        this.onRemoveClientClose();
        showNotification("Ups client could not be deleted", "danger");
      });
  }

  onFormClose() {
    this.setState({ selectedClient: {}, modalFormVisible: false }, () => {
      this.modalForm.hide();
    });
  }

  onRemoveClientClose() {
    this.modalRemoveClient.hide();
  }

  onFormUpdated(client = {}) {
    this.paginationService.filterList(() => {
      const newList = [...this.paginationService.items];

      const preparedClient = Object.fromEntries(
        Object.entries(client).map(([key, value]) => {
          if (key === "phone") {
            return ["originalPhone", value];
          }
          return [key, value || "---"];
        }),
      );

      preparedClient.phone = parsePhoneNumber(preparedClient.originalPhone);

      const clientIndex = newList.findIndex((c) => c.id === client.id);

      if (clientIndex === -1) {
        newList.unshift(preparedClient);
      } else {
        newList[clientIndex] = preparedClient;
      }

      return newList;
    });

    this.modalForm.hide();
    this.setState({ modalFormVisible: false });
    this.forceUpdate();
  }

  handleSorting(column, order) {
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.setState({ isLoading: true });
    this.paginationService.reloadItems();
    this.paginationService.loadData().then(() => {
      this.setState({ isLoading: false });
    });
  }

  tableAction(index) {
    const client = this.paginationService.items[index];

    const Content = (
      <div className="uk-flex uk-flex-middle">
        <ButtonIcon className="uk-margin-small-right" onClick={this.prepareUpdateClient(client)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
        </ButtonIcon>
        <ButtonIcon key={client.id} onClick={this.onRemoveClient(client.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </ButtonIcon>
      </div>
    );

    return {
      desktop: <td style={{ verticalAlign: "middle" }}>{Content}</td>,
      mobile: Content,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="clients-list-header">
          <h2 className={"gh-text-slim uk-margin-remove-bottom"}>Clients</h2>
          <Button onClick={this.prepareUpdateClient({})}>Add Client</Button>
        </div>
        <ComplexTableComponent
          loadMore={this.paginationService.loadMore.bind(this.paginationService)}
          rows={this.paginationService.items}
          headers={this.state.tableHeaders}
          columns={this.paginationService.columns}
          isLoading={this.state.isLoading}
          firstTimeLoading={this.paginationService.initialLoadState}
          showLoader={this.paginationService.showLoaderBtn}
          showMoreText={staticService.findByAlias("showMore")}
          showActionColumn={1}
          actionElements={this.tableAction.bind(this)}
          actionsHeaders={["Actions"]}
          emptyCellContent="---"
          sortChange={this.handleSorting.bind(this)}
          selectedOrder={this.state.sortOrder}
        />
        <ModalComponent
          title="Client Form"
          id="clients-list-form"
          ref={(modal) => (this.modalForm = modal)}
          onClose={this.onFormClose}
        >
          <ClientListForm
            client={this.state.selectedClient}
            onUpdate={this.onFormUpdated}
            visible={this.state.modalFormVisible}
          />
        </ModalComponent>
        <ModalComponent
          title={staticService.findByAlias("clientReferenceDeleteClientModal")}
          id="client-remove-modal"
          ref={(modal) => (this.modalRemoveClient = modal)}
          onClose={this.onRemoveClientClose}
          modalClass="gh-client-remove-modal"
        >
          <div className="uk-flex uk-flex-center">
            <Button
              color="danger"
              onClick={this.onRemoveClientClose}
              className="uk-margin-small-right gh-clients-remove-modal-btn"
            >
              No
            </Button>
            <Button className="gh-clients-remove-modal-btn" onClick={this.handleRemoveClient}>
              Yes
            </Button>
          </div>
        </ModalComponent>
      </React.Fragment>
    );
  }
}

ClientsList.contextTypes = {
  store: PropTypes.object,
};

ClientsList.propTypes = {
  clientsList: PropTypes.array,
  removeClient: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    clientsList: state.clientsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeClient,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ClientsList);
export { COMPONENT as ClientsList };
