import React from "react";
import { BlockComponent } from "../common";

export const Privacy = () => {
  return (
    <BlockComponent visible={true}>
      <iframe
        src="https://www.iubenda.com/privacy-policy/89754695/legal?ifr=true&height=683&newmarkup=yes"
        style={{ width: "100%", height: "100vh" }}
      />
    </BlockComponent>
  );
};
