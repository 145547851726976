import * as types from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import GetHeliEvents from "../services/event.service";
import { staticText } from "../configs/static-text.config";
import {
  getBookingDetailSuccess,
  loadActivityFlightsSuccess,
  loadPaginationActivityFlightsSuccess,
} from "./";
import { config } from "../configs";
import { spinnerInstance } from "../shared";
import { showNotification } from "../utils/showNotification";
import staticService from "../services/static.service";

const entity = "empty-leg";

export function getEmptyLegsSuccess(emptyLegs) {
  return { type: types.GET_EMPTY_LEGS, emptyLegs };
}

export function getMapEmptyLegsSuccess(emptyLegs) {
  return { type: types.GET_MAP_EMPTY_LEGS, emptyLegs };
}

export function addEmptyLegSuccess(emptyLeg) {
  return { type: types.ADD_EMPTY_LEG, emptyLeg };
}

export function removeEmptyLegSuccess(response) {
  return { type: types.REMOVE_EMPTY_LEG, response };
}

export function addEmptyLeg(emptyLeg, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "emptyLegFromBookingModel");
    Api.setProperty("skipErrorAlert", true);
    return Api.setPath(entity)
      .post(emptyLeg)
      .then((res) => {
        const value = res.data ? res.data : res;

        dispatch(getBookingDetailSuccess(value.booking));
        dispatch(addEmptyLegSuccess(value));

        if (res.message) {
          showNotification(staticService.findByAlias(res.message) || res.message, "success");
        }

        if (onSuccess) onSuccess(res);
        return res;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function resetEmptyLegs() {
  return function (dispatch) {
    return dispatch(getEmptyLegsSuccess([]));
  };
}

export function getEmptyLegs(page = 1, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity, "own")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((list) => {
        GetHeliEvents.trigger("legs");
        dispatch(getEmptyLegsSuccess(list));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getEmptyLegsRequests(id, onSuccess) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setPath(entity)
      .setProperty("showSpinner", false)
      .setQueryParams()
      .get(id)
      .then((list) => {
        if (onSuccess) onSuccess(list);
        spinnerInstance.setProp("type", "large").hide();
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getEmptyLegsByFilter(page = 1, filters = []) {
  return function () {
    return Api.setPath(entity, "own")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getMarketPlaceEmptyLegsByFilter(page = 1, filters = []) {
  return function () {
    return Api.setPath(entity, "marketplace")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll();
  };
}

export function getMarketPlaceEmptyLegs(page = 1, filters = []) {
  return function (dispatch) {
    return Api.setPath(entity, "marketplace")
      .setQueryParams([{ key: "page", value: page }].concat(filters))
      .getAll()
      .then((data) => {
        dispatch(getEmptyLegsSuccess(data.list));
        GetHeliEvents.trigger("legs");
        return data;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getActivityFlights() {
  return function (dispatch) {
    return Api.setPath(entity, "get-map-activity")
      .setQueryParams([])
      .getAll()
      .then((res) => {
        dispatch(getMapEmptyLegsSuccess(res.list));
        dispatch(loadActivityFlightsSuccess(res.mapActivity));
        return res;
      });
  };
}

export function removeEmpty(id, isWithdrawn = false) {
  return function (dispatch) {
    const { emptyLeg, emptyLegWithdraw } = staticText.globalMessages;
    const notificationMessageObject = isWithdrawn ? emptyLegWithdraw : emptyLeg;

    Api.setProperty("responseMessages", notificationMessageObject);
    return Api.setPath(entity)
      .delete(id)
      .then((response) => {
        dispatch(removeEmptyLegSuccess(response));
        return response;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function removeEmptyById(id) {
  return function (dispatch) {
    return Api.setPath(entity)
      .delete(id)
      .then((response) => {
        return response;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
