import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./QuoteGeneralInfoRequestDetailsModal.css";
import { ModalComponent } from "../../../../../../shared";
import staticService from "../../../../../../services/static.service";
import { BrokerReviewRequestComponent } from "../../../../user-online";
import { getQuoteById } from "../../../../../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const QuoteGeneralInfoRequestDetailsModal = (props) => {
  const { requestID, getQuoteById, requestDetailsModalRef, modalOpen } = props;
  const [request, setRequest] = useState({});

  const closeModal = useCallback(
    () => requestDetailsModalRef.current && requestDetailsModalRef.current.hide(),
    [requestDetailsModalRef],
  );

  const updateCurrentActiveRequest = useCallback(() => {
    getQuoteById(requestID, null)
      .then((res) => {
        setRequest(res.booking_request);
      })
      .catch((err) => {
        setRequest({});
        closeModal();
      });
  }, []);

  useEffect(() => {
    updateCurrentActiveRequest();
    return () => setRequest({});
  }, []);

  return (
    <ModalComponent
      title={staticService.findByAlias("request-details")}
      options={{ clsPage: "", bgClose: true, escClose: false }}
      onClose={closeModal}
      modalClass="gh-broker-nav-request-modal"
      id="full-request-modal"
      ref={requestDetailsModalRef}
    >
      {Object.keys(request).length && modalOpen ? (
        <BrokerReviewRequestComponent
          request={request}
          nav={true}
          onUpdateRequest={updateCurrentActiveRequest}
          hidePrivateNotes
          brokerCompany={request.broker_company}
        />
      ) : (
        ""
      )}
      <div className="d-flex_container">
        <button
          onClick={closeModal}
          className="uk-button uk-button-primary gh-request-gen-btns uk-margin-top"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("close"),
            }}
          />
        </button>
      </div>
    </ModalComponent>
  );
};

QuoteGeneralInfoRequestDetailsModal.propTypes = {
  requestID: PropTypes.number.isRequired,
  getQuoteById: PropTypes.func.isRequired,
  requestDetailsModalRef: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getQuoteById }, dispatch);
};
const mapStateToProps = ({ operator_requests }) => ({ operator_requests });
export default connect(mapStateToProps, mapDispatchToProps)(QuoteGeneralInfoRequestDetailsModal);
