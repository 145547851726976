import PropTypes from "prop-types";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { notification } from "uikit";
import CalculatorContext from "./CalculatorContext";
import { CalculatorLabels, OperatorMargin } from "./components";
import { AircraftsList } from "./components/Aircraft";
import useFormExtraActions from "./hooks/useFormExtraActions";
import { connect } from "react-redux";
import staticService from "../../services/static.service";

const Calculator = (props) => {
  const { quote, userAircrafts, onFinish, currency, demoVersion, banking, extraAircrafts } = props;
  const form = useForm({
    defaultValues: {
      quote_costs: "0.00",
      quote_costs_total: "0.00",
      operator_margin: { sum: "", percent: "" },
      ...quote,
    },
    reValidateMode: "onChange",
  });
  const [activeSections, setActiveSection] = React.useState([
    "flight",
    "pilots",
    "fees",
    "extracosts",
    "notes",
  ]);
  const [activeAircraftIndex, setAircraftActiveIndex] = React.useState(0);
  const [availableAircrafts, setAvailableAircrafts] = React.useState(userAircrafts);

  const { resetToOriginal, updateForm, updateOperatorMargin } = useFormExtraActions(form, {
    aircrafts: quote.aircrafts || [],
  });

  const updateAvailableAircrats = React.useCallback(
    (id, values) => {
      let newAvailableAircrafts = [...availableAircrafts];

      newAvailableAircrafts = newAvailableAircrafts.map((aircraft) => {
        if (aircraft.id === +id) {
          return {
            ...aircraft,
            ...values,
          };
        }

        return aircraft;
      });

      setAvailableAircrafts(newAvailableAircrafts);
    },
    [availableAircrafts],
  );

  const isLocationOriginal = React.useCallback(
    (field, id, destination) => {
      if (quote && quote.aircrafts) {
        const [, aircraft] = field.split(".");

        if (!quote.aircrafts[aircraft]) return true;

        const calculatorLeg = form.getValues(field);
        const quoteLeg = quote.aircrafts[aircraft].legs.find((leg) => leg.id === id);
        if (!quoteLeg || !quoteLeg[destination]?.latitude) return true;

        if (!calculatorLeg[destination]?.latitude) return false;

        if (
          calculatorLeg[destination]?.latitude !== quoteLeg[destination]?.latitude ||
          calculatorLeg[destination]?.longitude !== quoteLeg[destination]?.longitude
        ) {
          return false;
        }
      }

      return true;
    },
    [form, quote],
  );

  const onInvalid = React.useCallback(() => {
    notification("Please fill in all required fields", {
      status: "calculator",
      pos: "top-right",
    });
  }, []);

  const toggleSectionStatus = React.useCallback(
    (key, status) => () => {
      if (status) {
        setActiveSection([...activeSections, key]);
      } else {
        setActiveSection(activeSections.filter((s) => s !== key));
      }
    },
    [activeSections],
  );

  // React.useEffect(() => {
  //   return () => {
  //     if (submitOnUnmount) {
  //       form.handleSubmit(onFinish(), onInvalid)();
  //     }
  //   };
  // }, [submitOnUnmount, form, onFinish, onInvalid]);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <CalculatorContext.Provider
      value={{
        updateAvailableAircrats,
        setAircraftActiveIndex,
        updateOperatorMargin,
        toggleSectionStatus,
        isLocationOriginal,
        resetToOriginal,
        updateForm,
        activeAircraftIndex,
        availableAircrafts,
        extraAircrafts,
        activeSections,
        currency,
        quote,
        demoVersion,
        banking,
      }}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onFinish(), onInvalid)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <OperatorMargin isFormSubmitting={form.formState.isSubmitting} />
          <div className="calculator">
            {/*LEFT SIDE LABELS*/}
            <CalculatorLabels />

            {/*PRICE CALCULATOR CONTENT*/}
            <div className="calculator__aircrafts">
              <AircraftsList banking={banking} />
              <div id="calculator__legs" className="calculator__legs" />
            </div>
          </div>

          {demoVersion ? (
            <div className="uk-margin-top">
              <button type="submit" className="calculator-reset-btn calculator-submit-btn">
                {staticService.findByAlias("exportBtn")}
              </button>
            </div>
          ) : (
            ""
          )}
        </form>
      </FormProvider>
    </CalculatorContext.Provider>
  );
};

Calculator.propTypes = {
  quote: PropTypes.object,
  userAircrafts: PropTypes.array,
  extraAircrafts: PropTypes.array,
  onFinish: PropTypes.func,
  currency: PropTypes.string,
  submitOnUnmount: PropTypes.bool,
  demoVersion: PropTypes.bool,
  banking: PropTypes.object,
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(Calculator);
