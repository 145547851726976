import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { actions, Control, Form } from "react-redux-form";
import {
  BETA_VALIDATION_FAILED,
  download,
  getMeta,
  getStaticCountry,
  loadTermCondition,
  validateReferrer,
  validatingFirstStep,
  validatingUser,
} from "../../../actions";
import { config, OPERATOR_MAIN, validationRules } from "../../../configs";
import Auth from "../../../services/auth";
import { CompanyFieldset, OperatorCompanyFieldset, UserAccountFieldset } from "../fieldsets";
import { storage } from "../../../shared";
import { TacComponent } from "../tac.component";
import { BlockComponent, ConfirmComponent } from "../../common";
import staticService from "../../../services/static.service";
import { RequestPasswordFormComponent } from "../request-password-form.component";
import { Helmet } from "react-helmet/es/Helmet";

const { phoneInputValidation } = validationRules;

class RegisterFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    context.store.dispatch(
      loadTermCondition(
        "BROKER_AGREEMENT,OPERATOR_AGREEMENT,BROKER_CODE_CONDUCT,OPERATOR_CODE_CONDUCT",
      ),
    );
    this.state = {
      termsConditions: "uk-width-auto uk-padding-remove",
      iconClass: "uk-margin-small-right gh-icon-terms",
      modalVisibility: false,
      icon: "copy",
      prevRegisterStep: "",
      next: false,
      activeTab: 0,
      validPass: false,
      soleTrader: null,
      nextError: {},
      currencies: [],
      visibleFields: true,
    };
    this.changeModalVisibility = this.changeModalVisibility.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.fromPromoCode = this.fromPromoCode.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
  }

  changeModalVisibility(state) {
    this.setState({ modalVisibility: state });
  }

  componentDidMount() {
    this.props.getMeta(window.location.pathname.substring(1));
    this.context.store.dispatch(actions.resetValidity("userRegisterModel"));
  }

  openModal(ev) {
    ev.preventDefault();
    this.changeModalVisibility(true);
  }

  resetConditions() {
    this.setState({
      modalVisibility: false,
      icon: "copy",
      iconClass: "uk-margin-small-right gh-icon-terms",
      termsConditions: "uk-width-auto uk-padding-remove",
    });
    this.context.store.dispatch(actions.change("userRegisterModel.tac_accepted", false));
  }

  onChangeCondition(state) {
    if (state === 0) {
      this.resetConditions();
    } else {
      this.setState({
        modalVisibility: false,
        icon: "check",
        iconClass: "uk-margin-small-right uk-icon-button gh-icon-terms",
        termsConditions: "gh-button-terms uk-padding-remove gh-button-terms-text",
      });
      this.context.store.dispatch(actions.change("userRegisterModel.tac_accepted", true));
    }
  }

  onSubmit(user) {
    if (!phoneInputValidation(user.company.phone.value)) {
      return this.context.store.dispatch(
        actions.setErrors(`${"userRegisterModel"}.company.phone`, {
          required: true,
        }),
      );
    }

    if (!phoneInputValidation(user.phone.value)) {
      return this.context.store.dispatch(
        actions.setErrors(`${"userRegisterModel"}.phone`, {
          required: true,
        }),
      );
    }

    const { history, userHomeType, validatingUser } = this.props;
    if (userHomeType === "broker") {
      const bundle = history.location.search.substring(1).split("&")[0].split("=")[1];
      return validatingUser({ ...user, company: { ...user.company, bundle } });
    }
    return validatingUser(user);
  }

  userStepList(userRegisterModel) {
    const { first_name, last_name, email, email_confirmation, password, password_confirmation } =
      userRegisterModel;
    return [
      { first_name },
      { last_name },
      { email },
      { email_confirmation },
      { password },
      { password_confirmation },
    ];
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const userStepFieldsValues = this.userStepList(nextProps.userRegisterModel);
    const isValidationPass =
      userStepFieldsValues.every((item) => Object.values(item)[0].length > 0) &&
      nextProps.userRegisterForm.password.valid &&
      nextProps.userRegisterForm.password_confirmation.valid &&
      nextProps.userRegisterModel.email === nextProps.userRegisterModel.email_confirmation &&
      nextProps.userRegisterForm.company.refferal_code.valid;

    if (
      this.props.userRegisterModel.company.sole_trader !==
      nextProps.userRegisterModel.company.sole_trader
    ) {
      this.setState({ soleTrader: nextProps.userRegisterModel.company.sole_trader }, () => {
        this.context.store.dispatch(actions.reset("userRegisterModel.company.name"));
        this.context.store.dispatch(actions.reset("userRegisterModel.company.number"));
        this.context.store.dispatch(actions.reset("userRegisterModel.company.phone"));
      });
      this.context.store.dispatch(actions.resetValidity("userRegisterModel"));
    }

    if (isValidationPass) {
      this.setState({ next: true, validPass: true });
    } else {
      this.setState({ next: false, validPass: false });
    }

    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.userStepList(nextProps.userRegisterModel).map((item) => {
        if (Object.keys(nextProps.errors).includes(Object.keys(item)[0])) {
          this.context.store.dispatch(actions.change("userRegisterModel.tac_accepted", false));
          this.resetConditions();
          this.props.changeView("account", false);
        }
      });
      this.context.store.dispatch(actions.setPending("userRegisterModel", false));
    }

    if (this.props.validateUser !== nextProps.validateUser) {
      storage.add("userRegisterModel", JSON.stringify(this.props.userRegisterModel));
      this.context.store.dispatch(actions.reset("invitationsModel"));
      this.props.changeView("invitation", false);
    }

    if (this.props.validateFirstStepUser !== nextProps.validateFirstStepUser) {
      this.props.changeView("company", false);
    }

    if (
      this.props.userRegisterModel.company.country_code !==
      nextProps.userRegisterModel.company.country_code
    ) {
      this.context.store.dispatch(
        loadTermCondition(
          "BROKER_AGREEMENT,OPERATOR_AGREEMENT,BROKER_CODE_CONDUCT,OPERATOR_CODE_CONDUCT",
          nextProps.userRegisterModel.company.country_code,
        ),
      );
    }
  }

  get dialogProps() {
    return {
      content: this.betaForm,
      className: "uk-alert",
      buttonsProps: {},
      showCloseBtn: true,
      modalProps: {
        id: "withdraw-request-modal",
        title: staticService.findByAlias("betaModalTitle"),
        options: {
          clsPage: "",
          bgClose: false,
          escClose: false,
        },
      },
    };
  }

  submitFeedback(model) {
    this.props.validateReferrer(model, this.onSuccess);
  }

  onSuccess(response) {
    if (response.referrer) {
      this.context.store.dispatch(
        actions.change("userRegisterModel.company.refferal_code", response.referrer),
      );
      this.confirmComponent.closeModal();
    }
  }

  fromPromoCode(response) {
    this.context.store.dispatch(
      actions.change("userRegisterModel.company.refferal_code", response.referrer),
    );
  }

  onContinue() {
    this.props.validateReferrer(null, this.fromPromoCode);
    this.confirmComponent.closeModal();
  }

  get betaForm() {
    return (
      <RequestPasswordFormComponent
        modelName={"validateReferrer"}
        modelField={".referrer"}
        label={staticService.findByAlias("betaFieldLabel")}
        visible={true}
        onClick={this.onContinue.bind(this)}
        action={this.submitFeedback.bind(this)}
      />
    );
  }

  changeCountry(model, value) {
    const { getStaticCountry, updateModel, countries } = this.props;

    this.setState({ visibleFields: false });
    getStaticCountry(value).then(() => {
      this.setState({
        visibleFields: true,
        icon: "copy",
        iconClass: "uk-margin-small-right gh-icon-terms",
        termsConditions: "uk-width-auto uk-padding-remove",
      });
    });
    updateModel(model, value);
    updateModel("userRegisterModel.tac_accepted", false);
    const country = countries.countries.find((c) => c.code === value);
    if (country) {
      this.setState({
        currencies: country.other_currencies,
      });
    }
  }

  validateFirstStep() {
    return this.props.validatingFirstStep(this.props.userRegisterModel);
  }

  getTabContent(step) {
    switch (step) {
      case "account":
        return (
          <div>
            <UserAccountFieldset userModel={this.props.userRegisterModel} next={this.state.next} />
            <hr className="gh-divider" />
            <div className="gh-register-buttons">
              <Control.button
                model="userRegisterModel"
                disabled={!this.state.next}
                type={"button"}
                className="uk-button uk-button-primary gh-register-next gh-margin-left-normal"
                onClick={this.validateFirstStep.bind(this)}
              >
                {staticService.findByAlias("continue")}
              </Control.button>
              <button
                className="uk-button uk-button-default gh-margin-right-normal gh-register-back"
                type={"button"}
                onClick={this.props.back}
              >
                {staticService.findByAlias("back")}
              </button>
            </div>
          </div>
        );
      case "company":
        return (
          <div>
            {this.props.tab.alias === "operator" ? (
              <OperatorCompanyFieldset
                company={this.props.userRegisterModel.company}
                openModal={this.openModal}
                changeIcon={this.state.icon}
                changeIconClass={this.state.iconClass}
                termsAction={this.openModal}
                termsConditionsClass={this.state.termsConditions}
                countriesList={this.props.countries}
                currenciesList={
                  this.state.currencies || this.props.countries.countries[0].other_currencies
                }
                changeCountry={this.changeCountry}
              />
            ) : (
              <CompanyFieldset
                company={this.props.userRegisterModel.company}
                userRegisterModel={this.props.userRegisterModel}
                soleTrader={
                  this.state.soleTrader || this.props.userRegisterModel.company.sole_trader
                }
                changeIcon={this.state.icon}
                changeIconClass={this.state.iconClass}
                termsAction={this.openModal}
                termsConditionsClass={this.state.termsConditions}
                countriesList={this.props.countries}
                changeCountry={this.changeCountry}
              />
            )}
            <hr className="gh-divider" />
            <div className="uk-margin-small-top gh-register-buttons gh-register-buttons-second">
              <Control.button
                model="userRegisterModel"
                type={"submit"}
                disabled={() => !this.props.tacAccepted}
                className="uk-button uk-button-primary gh-register-next"
              >
                {staticService.findByAlias("createAccount")}
              </Control.button>
              <button
                className="uk-button uk-button-default gh-btn-margin-register gh-register-back"
                type={"button"}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                {staticService.findByAlias("cancel")}
              </button>
            </div>
          </div>
        );
    }
  }

  get agreementAlias() {
    if (this.props.userRegisterModel.type)
      return this.props.userRegisterModel.type.toUpperCase() + "_AGREEMENT";
    else return OPERATOR_MAIN + "_AGREEMENT";
  }

  get agreement() {
    const conditions = this.props.conditions.filter(
      (condition) => condition.type === this.agreementAlias,
    );
    return conditions.length > 0 ? conditions[0].description : "";
  }

  render() {
    const { metaData, article, isVisible, step } = this.props;
    if (isVisible && !Auth.isAuthenticated() && article) {
      return (
        <div>
          <Helmet>
            <link rel="canonical" href="https://getheli.com/account/register" />
            {metaData?.meta?.message?.map((meta) => (
              <meta key={meta.name} name={meta.name} content={meta.content} />
            ))}
          </Helmet>
          <TacComponent
            html={this.agreement}
            agreeCallback={this.onChangeCondition}
            downloadCallback={this.props.download.bind(this, this.agreementAlias)}
            visible={this.state.modalVisibility}
          />

          <BlockComponent
            visible={true}
            containerClass="uk-container gh-container-affiliate"
            parentProps={{
              className: "uk-section uk-padding-remove-top gh-section-statistics",
            }}
          >
            <Form
              model="userRegisterModel"
              className="uk-form-stacked gh-register-form"
              onSubmit={this.onSubmit}
              validators={{
                tac_accepted: (val) => val > 0,
              }}
            >
              {this.state.visibleFields ? this.getTabContent(step) : ""}
            </Form>

            <ConfirmComponent
              ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
              info={this.dialogProps.content}
              buttonsProps={this.dialogProps.buttonsProps}
              modalProps={this.dialogProps.modalProps}
              showCloseBtn={this.dialogProps.showCloseBtn}
              closeBtnTemplate={
                <NavLink
                  className={"gh-modal-close"}
                  to={config.redirectToMainPage}
                  data-uk-close
                />
              }
            />
          </BlockComponent>
        </div>
      );
    }

    return null;
  }
}

RegisterFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    confirm: state.confirm,
    beta: state.beta,
    conditions: state.termsCondition,
    errors: state.errorObj,
    isVisible: !state.auth.id,
    auth: state.auth,
    userRegisterModel: state.userRegisterModel,
    validateUser: state.validateUser.user_validation,
    validateFirstStepUser: state.validateFirstStepUser.user_validation,
    tacAccepted: state.forms.userRegisterModel.tac_accepted.value,
    metaData: state.metaData,
    userRegisterForm: state.forms.userRegisterModel,
    countries: state.countries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMeta,
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      closeModal: (state) => dispatch({ type: BETA_VALIDATION_FAILED, status: state }),
      validateReferrer,
      validatingUser,
      validatingFirstStep,
      download,
      getStaticCountry,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RegisterFormComponent);
export { COMPONENT as RegisterFormComponent };

const locationShape = PropTypes.shape({
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
});

RegisterFormComponent.propTypes = {
  countries: PropTypes.any,
  article: PropTypes.any,
  step: PropTypes.string,
  metaData: PropTypes.object,
  tab: PropTypes.object,
  getStaticCountry: PropTypes.func,
  updateModel: PropTypes.func,
  getMeta: PropTypes.func,
  validateUser: PropTypes.any,
  validateFirstStepUser: PropTypes.any,
  userHomeType: PropTypes.string,
  userRegisterForm: PropTypes.object,
  changeView: PropTypes.func,
  validatingUser: PropTypes.func,
  validateReferrer: PropTypes.func,
  validatingFirstStep: PropTypes.func,
  back: PropTypes.func,
  download: PropTypes.func,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      updated_at: PropTypes.string,
    }),
  ),
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  history: PropTypes.shape({
    action: PropTypes.string,
    length: PropTypes.number,
    location: locationShape,
  }),
  isVisible: PropTypes.bool,
  location: locationShape,
  tacAccepted: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  userRegisterModel: PropTypes.shape({
    bank_account: PropTypes.string,
    company: PropTypes.shape({
      aoc_number: PropTypes.string,
      aoc_company_name: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      number: PropTypes.string,
      phone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      refferal_code: PropTypes.string,
      website: PropTypes.string,
      sole_trader: PropTypes.any,
      country_code: PropTypes.string,
    }),
    email: PropTypes.string,
    email_confirmation: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.any),
    first_name: PropTypes.string,
    helicopters: PropTypes.arrayOf(PropTypes.any),
    invitations: PropTypes.arrayOf(PropTypes.any),
    last_name: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
    phone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tac_accepted: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    type: PropTypes.string,
  }),
};
