import React from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onClickEventClose = this.onClickEventClose.bind(this);
    this.onPropChangeClose = this.onPropChangeClose.bind(this);
    this.state = {
      html: props.html,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.component = UIkit.modal("#" + this.props.id, this.props.options);

    UIkit.util.on(`#${this.props.id}`, "beforehide", this.props.onModalClose);
  }

  componentWillUnmount() {
    if (this.props.destroyOnUnmout) {
      this.component.$destroy(true);
    }
  }

  open() {
    if (document.querySelector("main")) {
      document.querySelector("main").style.zIndex = "unset";
    }

    this.component.show();
    this.setState({ isOpen: true });
  }

  hide() {
    if (document.querySelector("main")) {
      document.querySelector("main").style.zIndex = "1";
    }

    this.component.hide();
    this.setState({ isOpen: false });
  }

  onClickEventClose() {
    this.hide();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onPropChangeClose() {
    this.hide();
    if (this.props.onCloseByProp) {
      this.props.onCloseByProp();
    }
  }

  get closeBtn() {
    return (
      this.props.closeBtnTemplate || (
        <button
          onClick={this.onClickEventClose}
          className="uk-modal-close-default"
          type="button"
          data-uk-close
        />
      )
    );
  }

  render() {
    const _content = this.state.html || this.props.children;
    return (
      <div
        id={this.props.id}
        data-uk-modal={"container: false"}
        onClick={this.props.onCloseOnBackground}
        className={this.props.className}
      >
        <div
          className={`uk-modal-dialog uk-modal-body uk-margin-auto-vertical ${this.props.modalClass}`}
          onClick={(e) => {
            if (this.props.onCloseOnBackground) {
              e.stopPropagation();
            }
          }}
        >
          {this.props.showCloseBtn && this.closeBtn}
          {!!this.props.banner && this.props.banner}
          <section {...this.props.modalProps}>
            <header>
              <div
                className="custom-modal-title"
                dangerouslySetInnerHTML={{ __html: this.props.title }}
              />
            </header>
            <div className={"react-modal-content"}>{_content}</div>
          </section>
        </div>
      </div>
    );
  }
}

ModalComponent.propTypes = {
  modalProps: PropTypes.object,
  modalClass: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  html: PropTypes.object,
  options: PropTypes.object,
  onClose: PropTypes.func,
  onCloseByProp: PropTypes.func,
  onDestroy: PropTypes.func,
  visible: PropTypes.bool,
  closeBtnTemplate: PropTypes.object,
  showCloseBtn: PropTypes.bool,
  banner: PropTypes.node,
  destroyOnUnmout: PropTypes.bool,
  onModalClose: PropTypes.func,
  onCloseOnBackground: PropTypes.func,
  className: PropTypes.string,
};

ModalComponent.defaultProps = {
  showCloseBtn: true,
  banner: null,
  destroyOnUnmout: true,
  onModalClose: () => null,
  onCloseOnBackground: () => null,
  className: "",
};

export { ModalComponent };
