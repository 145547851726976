import React from "react";
import PropTypes from "prop-types";
import { GridComponent, IconComponent } from "../../../../../shared";

const FileBlockComponent = ({
  index,
  fileName,
  uploadFile,
  removeFile,
  preview,
  isUploaded = false,
  showRemoveBtn = true,
  noPreview = true,
}) => {
  return (
    <li key={index} className={"uk-width-1-1 gh-verification-doc"}>
      <GridComponent visible={true} attrProps={{ id: `file-grid-${index}` }}>
        <div className="uk-width-2-3">
          {noPreview ? fileName : <a onClick={preview}> {fileName}</a>}
        </div>
        <div className="uk-width-1-3 uk-text-right">
          {!isUploaded ? (
            <a className="uk-link-text uk-margin-right" onClick={uploadFile}>
              <IconComponent
                visible={true}
                className={"close"}
                icon={"upload"}
                id={"upload" + index}
              />
            </a>
          ) : null}
          {showRemoveBtn ? (
            <a className="uk-link-text" onClick={removeFile}>
              <IconComponent
                visible={true}
                className={"close"}
                icon={"close"}
                id={"close" + index}
              />
            </a>
          ) : null}
        </div>
      </GridComponent>
    </li>
  );
};

FileBlockComponent.propTypes = {
  fileName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isUploaded: PropTypes.bool,
  noPreview: PropTypes.bool,
  showRemoveBtn: PropTypes.bool,
  uploadFile: PropTypes.func,
  removeFile: PropTypes.func,
};

export { FileBlockComponent };
