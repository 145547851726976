import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import checkIcon from "../../../assets/img/svg/checkSimple.svg";
import arrowRight from "../../../assets/img/svg/arrow-right.svg";
import AlternativeBtn from "../AlternativeBtn";
import yellowMarker from "../../../assets/img/svg/yellowMarker.svg";
import greenMarker from "../../../assets/img/svg/greenMarker.svg";
import oval from "../../../assets/img/svg/oval.svg";

import "./locationInfo.css";
import transformAdditionalInfo from "../../../utils/transformAdditionalInfo";

const locationIcons = {
  gh_event: yellowMarker,
  hellipaddy: greenMarker,
  google: oval,
};

const LocationInfo = (props) => {
  const {
    leg,
    legIcon,
    keyDirection,
    direction,
    additionalDetails,
    handleSuggestAlternative,
    showOperatorProvideOptions = true,
  } = props;

  return (
    <div>
      <div className="gh-review-leg-wrapper">
        <img
          src={locationIcons[legIcon]}
          className="gh-leg-location-marker"
          alt="marker"
          style={{ marginRight: 10 }}
        />
        <div className="gh-review-leg-location">
          <h6
            className="gh-leg-field-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias(keyDirection) }}
          />
          <div className="gh-review-leg-location-detail">
            <h5 className="gh-leg-title-location">{leg[direction] && leg[direction].name}</h5>
            <span className="gh-leg-sub-title-location">
              {transformAdditionalInfo(
                `${leg[direction].category || leg[direction].type}|${
                  leg[direction].secondary_text || ""
                }`,
              )}
            </span>
          </div>
          {showOperatorProvideOptions ? (
            <div className="uk-flex uk-flex-middle" style={{ marginTop: 15 }}>
              {leg[`${keyDirection}_is_private`] ? (
                <img style={{ marginRight: 10 }} src={checkIcon} alt="check" />
              ) : (
                <img style={{ marginRight: 10, height: 15 }} src={arrowRight} alt="check" />
              )}
              {leg[`${keyDirection}_is_private`] ? (
                <h6
                  className="gh-leg-take-off"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(
                      keyDirection === "from" ? "exactTakeOffLocation" : "exactLandingLocation",
                    ),
                  }}
                />
              ) : (
                <h6
                  className="gh-leg-take-off"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(
                      keyDirection === "from" ? "operToProvideTakeOff" : "operToProvideLanding",
                    ),
                  }}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {additionalDetails ? (
        <div className="gh-additional-leg-info">
          <div className="gh-additional-leg-info-title">
            {staticService.findByAlias("additionalDetailsAboutLocation")}
          </div>
          <div className="gh-additional-leg-info-text">{additionalDetails}</div>
        </div>
      ) : (
        ""
      )}

      {handleSuggestAlternative ? (
        <div className="gh-wrapper-suggest-btn">
          <AlternativeBtn handleClick={handleSuggestAlternative} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

LocationInfo.propTypes = {
  leg: PropTypes.object,
  legIcon: PropTypes.string,
  keyDirection: PropTypes.string,
  direction: PropTypes.string,
  additionalDetails: PropTypes.any,
  handleSuggestAlternative: PropTypes.func,
  showOperatorProvideOptions: PropTypes.bool,
};

export default LocationInfo;
