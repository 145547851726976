import React from "react";
import { actions } from "react-redux-form";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { alertInstance } from "./";
import {
  ACTIVE_CLASS,
  config,
  QUOTE_AMEND,
  QUOTE_CLOSED,
  QUOTE_DISCUSSION,
  QUOTE_REJECTED,
  QUOTE_UNSUCCESSFULL,
  QUOTE_WITHDRAWN,
  staticText,
} from "../configs";
import Auth from "../services/auth";
import staticService from "../services/static.service";

export function fireTracking(pathname) {
  // ReactGA4.send({ hitType: "pageview", page: pathname });
}

export function convertToLocale(date) {
  const momentUTC = moment.utc(date);
  const _currentTime = momentUTC.local();
  //removes "ago" suffix in case it is less than a minute
  const response = moment().to(_currentTime.format(config.dateServerFormat), true);
  return response;
}

export function pageTitle(suffix = "") {
  const _suffix = suffix !== "" ? " - " + suffix : suffix;
  return staticService.findByAlias("appName") + _suffix;
}

// removes given route link from header on page
export function filteredPages(pages, skip) {
  return pages.filter((page) => {
    return page.hideOnPages ? page.hideOnPages.indexOf(skip) === -1 : true;
  });
}

export function filterMapStateToProps(model, state) {
  return {
    ...state[model],
  };
}

export function soonExpiresFunc(val) {
  const now = moment();
  const date = moment(val).startOf("day").diff(now.startOf("day"), "days");
  return date >= 0 && date < 30;
}

export function dateExpired(val) {
  const date = moment(val);
  const now = moment();
  return date.startOf("day").diff(now.startOf("day"), "days") < 1;
}

export function alert(message) {
  return alertInstance.setProp("messageProp", message).show();
}

export function returnRouteUrl(params = {}, url) {
  const _route = {
    path: `${url}${params.path}`,
    component: params.component,
  };

  if (params.exact) {
    _route.exact = params.exact;
  }

  if (params.routes) {
    _route.routes = params.routes;
  }
  return _route;
}

export function toArray(fileList) {
  return Array.prototype.slice.call(fileList);
}

export function range(start, stop, step = 1) {
  let a = [start],
    b = start;
  while (b < stop) {
    b += step;
    a.push(b);
  }
  return a;
}

export function rangeReverse(start, stop) {
  let a = [start];
  while (start > stop) {
    a.push(--start);
  }
  return a;
}

// export function updatePhone(model, value) {
//   console.warn({ model, value });
// }

export const dateFormat = (date, format, formatToApply = "D MMMM, YYYY") => {
  return moment(date, format).format(formatToApply);
};

export function updateModel(model, value, promise = false) {
  return (dispatch) => {
    dispatch(actions.resetValidity(model));
    dispatch(actions.setTouched(model));
    dispatch(actions.change(model, value));

    if (promise) return Promise.resolve();
  };
}

export function cloneObj(obj) {
  const keys = Object.keys(obj);
  const cleanObj = {};
  keys.reduce((acc, cur) => {
    acc[cur] = obj[cur];
    return acc;
  }, cleanObj);
  return cleanObj;
}

export function cloneArray(original) {
  const cloneArray = [];
  original.reduce((acc, cur) => {
    acc.push(cur);
    return acc;
  }, cloneArray);
  return cloneArray;
}

export function getValuesFromObject(obj) {
  const keys = Object.keys(obj);
  return keys.reduce((prev, next) => {
    return [...prev, obj[next]];
  }, []);
}

export function replaceAttributesInString($attributes, $value) {
  Object.keys($attributes).forEach((key) => {
    const match = `{${key}}`;
    $value = $value.replace(match, $attributes[key]);
  });
  return $value;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function redirectTo(path) {
  return <Redirect to={path} />;
}

export function objectOmit(obj, keys) {
  return Object.keys(obj)
    .filter((key) => [keys].indexOf(key) < 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: obj[key] }), {});
}

/**
 * Returns coordinates splited via "|" key
 *
 * @param {string} coordinates - e.g. "24.22123|0.2231"
 * @returns {{lat: *|string, lng: *|string}}
 */
export function parseCoordinates(coordinates) {
  if (!coordinates || typeof coordinates !== "string" || coordinates.length < 1) {
    //throw Error('Invalid leg coordinates given ' + coordinates);
    return false;
  }
  let chunks = coordinates.split("|");
  return {
    lat: parseFloat(chunks[0]),
    lng: parseFloat(chunks[1]),
  };
}

export function checkObjectProp(object, prop, defaultValue) {
  return object && object[prop] ? object[prop] : defaultValue;
}

export function commaSeparatedNumber(num) {
  if (!isNaN(parseFloat(num))) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num;
}

export function removeCommas(num) {
  return parseFloat(num.toString().replace(/,/g, "")).toFixed(2);
}

/**
 * Returns array merged with children array's
 * eg
 *        arrayFlatten([ [1], 2, [3] ]) -> [1,2,3]
 *
 * @param list
 */
export function arrayFlatten(list) {
  if (list.length) {
    return list.reduce((a, b) => a.concat(Array.isArray(b) ? arrayFlatten(b) : b), []);
  }
  return [];
}

export function isObjectEmpty(object) {
  return !!object && Object.keys(object).length === 0;
}

export function isValid(value) {
  if (undefined === value || null === value) {
    return false;
  }
  return true;
}

export function floatNumberPrecision(number, precision) {
  return isValid(number)
    ? number.toLocaleString("en-gb", {
        minimumFractionDigits: precision,
        maximumFractionDigits: 2,
      })
    : 0;
}

export function skipRouteSegments(route, skip) {
  const segments = route.split("/");
  segments.splice(skip);
  return segments.join("/");
}

export const setActiveItem = (list, status) => {
  const arr = [...list];
  if (status) {
    arr.forEach((tab) => {
      tab.active = false;
    });
    const itemToUpdate = arr.find((item) => item.filter === status);
    return arr.map((item) => {
      if (item.filter === itemToUpdate.filter) {
        item.active = true;
      }
    });
  } else {
    arr.forEach((tab) => {
      tab.active = false;
    });
  }
};

export const time = (time_max) => {
  const h = time_max.split(":")[0];
  const m = time_max.split(":")[1];
  const arr = h.split("");
  const firstNumber = parseInt(arr[0], 10);
  const secondNumber = parseInt(arr[1], 10);
  if (firstNumber === 0 && secondNumber === 1) {
    return arr[1].toString() + " hour " + m + " minutes";
  }
  if (firstNumber === 0 && secondNumber > 1) {
    return arr[1].toString() + " hours " + m + " minutes";
  }
  if (firstNumber === 0 && secondNumber === 0) {
    return m + " minutes";
  }
  if (firstNumber !== 0) {
    return h + " hours " + m + " minutes";
  }
};

export function back(path = "") {
  const segments = path.split("/");
  segments.shift();
  if (segments[segments.length - 1] === ":id") {
    segments.pop();
  }
  return "/" + segments.join("/");
}

export function findRouteSegment(route, segment) {
  const segments = route.split("/");
  return segments.length >= segment ? segments[segment] : null;
}

export function getCommission(price, commission = null) {
  commission = commission || config.commissions.getHeli;
  return commission * price - price;
}

export function getPrice(price, commission) {
  return price + getCommission(price, commission);
}

export function getRoundedPrice(price, commission, precision = 10) {
  return Math.ceil(getPrice(price, commission) / precision) * precision;
}

export function getChargedPrice(price, commission) {
  return (
    (getRoundedPrice(price, commission) + config.commissions.fixed) /
    (2 - config.commissions.stripe)
  );
}

export function getTransactionFee(price, commission) {
  return getChargedPrice(price, commission) - getRoundedPrice(price, commission);
}

export function getVAT(price) {
  return (price / 100) * config.vat;
}

export function getTotalVAT(price, quote_vat, commission) {
  return quote_vat + getVAT(getTransactionFee(price, commission));
}

export function getCommissionVat(commission) {
  return commission * config.commissions.commissionVat;
}

/**
 * Returns an array w/o falsy values
 * e.g.
 *        arrayCompact([1,{},undefined,'',null,4]) -> [1,{},4]
 *
 * @param array
 */
export function arrayCompact(array) {
  return array.filter((i) => i !== undefined && i !== null && i !== "");
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 *
 * @param {from<{lat: 2.215545, lng: -2.21549}>} - starting point
 * @param {to<{lat: -2,589849, lng: 0.1549}>} - ending point
 * @param unit - "K" for kilometers
 * @return {number} - distance
 */
export function distanceBetween({ lat: lat1, lng: lon1 }, { lat: lat2, lng: lon2 }, unit = "N") {
  let radLat1 = (Math.PI * lat1) / 180;
  let radLat2 = (Math.PI * lat2) / 180;
  let theta = lon1 - lon2;
  let radTheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist = dist * 1.609344;
  }
  if (unit === "N") {
    dist = dist * 0.8684;
  }
  return dist;
}

export const formattedTime = (n) => {
  const num = n;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  const hour_prefix = rhours ? (rhours === 1 ? "hour" : "hours") : "hours";
  const minute_prefix = rminutes ? (rminutes === 1 ? "minute" : "minutes") : "minutes";

  let hour_result = "";

  if (rhours) hour_result = `${rhours} ${hour_prefix}`;

  return `${hour_result} ${rminutes} ${minute_prefix}`;
};

export function percentageFormula(value, amount) {
  return (value * amount) / 100;
}

export function scrollToElement(
  element,
  { behavior = "smooth", block = "start", inline = "nearest" } = {},
) {
  if (element instanceof HTMLElement) {
    const options = {
      behavior,
      block,
      inline,
    };
    element.scrollIntoView(options);
  }
}

/**
 * @param {{omitKeys: string[]}} args
 */
export function prepareQueryStringFromObj(data, args = { omitKeys: [] }) {
  data = booleanToInteger(data);
  const _keys = Object.keys(data);
  return _keys.reduce((filters, key) => {
    if (data[key] !== null && !args.omitKeys.includes(key)) {
      return filters.concat([
        {
          key: key,
          value: data[key],
        },
      ]);
    }
    return filters;
  }, []);
}

export function toUppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getAfterDate(currentDate, dateToCompare) {
  const _current = moment(currentDate);
  if (_current.isAfter(dateToCompare)) return currentDate;
  return dateToCompare;
}

export function isAllowed(route) {
  return Auth.isVerified() ? route : config.redirectToForbidden;
}

export function attachTooltipForbidden(message = null) {
  return !Auth.isVerified()
    ? {
        "data-uk-tooltip":
          "title: " + (message !== null ? message : staticText.forbiddenMessage) + " ;",
      }
    : {};
}

export function attachTooltip(message = "") {
  return {
    "data-uk-tooltip": "title: " + message + ";pos: right;",
  };
}

export function formatID(id, prefix) {
  const loop = (max) => {
    let number = 0;
    for (let i = 0; i < max; i++) number += "0";
    return number;
  };
  if (id) {
    const len = id.toString().length;
    if (len < 4) {
      prefix += loop(3 - parseInt(len));
    }
    return prefix + id;
  }
  return null;
}

export function getActiveClass(index, alias, route) {
  const activeRoute = findRouteSegment(route, 2);
  if (activeRoute === undefined && index === 0) {
    return ACTIVE_CLASS;
  }

  if (activeRoute === alias) {
    return ACTIVE_CLASS;
  }

  return "";
}

export function getActiveClassBool(index, alias, route) {
  const activeRoute = findRouteSegment(route, 2);
  if (activeRoute === undefined && index === 0) {
    return true;
  }

  if (activeRoute === alias) {
    return true;
  }

  return false;
}

export function filterHelliPadyByCategory(categories = [], items) {
  if (items.length) {
    return items.reduce((prev, next) => {
      if (categories.indexOf(next.category.display_name) === -1) {
        prev.push(next);
      }
      return prev;
    }, []);
  }
}

export function addFailedCssClassToArray(klass, list) {
  const currentFilter = list;
  const indexOfFailed = currentFilter.indexOf("failed");

  if (klass === "failed") {
    currentFilter.push(klass);
  } else {
    delete currentFilter[indexOfFailed];
  }
  return currentFilter;
}

export function handleDatePickerSelection(value) {
  if (value === null || value === "") return value;

  if (value.format) {
    value = value.utc().toISOString();
  } else {
    value = explode(value);
  }
  return value;
}

export function explode(value, explodeSign = "/", implodeSign = "-") {
  if (value !== null && value.length > 0) {
    value = value.split(explodeSign);
    value.reverse();
    value = value.join(implodeSign);
  }
  return value;
}

export function handleDatePickerSelectionReverse(value) {
  if (value) {
    value = value.split("-");
    value.reverse();
    value = value.join("/");
  }
  return value;
}

export function filterModel(key, value) {
  return {
    key: key,
    value: value,
  };
}

export function aircraftTitle(aircraftModel, showTailNumber = true) {
  let tailNumber = showTailNumber ? aircraftModel.registration_number + " " : "";

  return `${tailNumber}${aircraftModel.manufacturer} ${
    aircraftModel.aircraft_model
  } (${staticService.findByAlias("maxPassengersLabel")} ${aircraftModel.max_passengers})`;
}

export function shortAircraftTitle(aircraftModel) {
  const premium =
    parseInt(aircraftModel.premium) > 0
      ? " (<b>" + staticService.findByAlias("premiumStatus") + "</b>)"
      : "";
  return `${aircraftModel.aircraft_model}${premium}`;
}

export function returnArray(counter) {
  const arr = [];
  for (let i = 0; i < counter; i++) {
    arr.push(i);
  }

  return arr;
}

export function showFile(filename, fileType, blob) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  let newBlob = new Blob([blob], {
    type: "application/pdf",
  });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  let link = document.createElement("a");
  link.href = data;
  link.download = filename + `.${fileType && typeof fileType === "string" ? fileType : "pdf"}`;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data), 100;
  });
}

export function downloadImage(file, objUrl) {
  const a = document.createElement("a");
  a.href = objUrl;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function stringToBoolean(data = {}) {
  const a = {
    true: 1,
    false: 0,
  };
  return Object.keys(data).reduce((prev, next) => {
    if (data[next] === "true" || data[next] === "false") {
      prev[next] = a[data[next]];
    } else {
      prev[next] = data[next];
    }
    return prev;
  }, {});
}

export function booleanToInteger(data = {}) {
  return Object.keys(data).reduce((prev, next) => {
    if (typeof data[next] === "boolean") {
      prev[next] = data[next] ? 1 : 0;
    } else {
      prev[next] = data[next];
    }
    return prev;
  }, {});
}

export function prepareQueryFromModelForFilters(model = {}) {
  return Object.keys(stringToBoolean(model)).reduce((prev, next) => {
    if (
      model[next] !== "" &&
      model[next] !== null &&
      next !== "refreshCollection" &&
      next !== "disableBtn"
    ) {
      prev[next] = model[next];
    }
    return prev;
  }, {});
}

export function convertObjectToArrayForFilters(params = {}) {
  params = stringToBoolean(params);
  return Object.keys(stringToBoolean(params)).reduce((prev, next) => {
    prev.push({
      key: next,
      value: params[next],
    });
    return prev;
  }, []);
}

export function setBrowserHistory(history, path = "/", model = {}) {
  history.push({
    pathname: path,
    search: "?" + queryString.stringify(prepareQueryFromModelForFilters(model)),
  });
}

export function unsetBrowserHistory(history, path = "/") {
  history.push({
    pathname: path,
  });
}

export function print(content) {
  const mywindow = window.open(
    "",
    "PRINT",
    `height=${document.documentElement.clientHeight},width=${document.documentElement.clientWidth}`,
  );
  mywindow.document.write("<html><head><title>" + document.title + "</title>");
  mywindow.document.write("</head><body >");
  mywindow.document.write(content);
  mywindow.document.write("</body></html>");
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.print();
  mywindow.close();
  return true;
}

export function fileSize(size) {
  return size / 1000 / 1000;
}

export function isObject(value) {
  return typeof value == "object" && value instanceof Object && !(value instanceof Array);
}

export function getFirstValueFromObject(object) {
  const _keys = Object.keys(object);
  if (_keys.length > 0) {
    return object[_keys[0]];
  }
  return "";
}

export function prependHttp(value) {
  let url =
    value.indexOf("http://") === 0 || value.indexOf("https://") === 0 ? value : `http://${value}`;
  if (url === "http://" || url === "https://") {
    url = "";
  }
  return url;
}

export function prependHttpWithModelUpdate(model, value) {
  return updateModel(model, prependHttp(value));
}

export function formatFiles(files) {
  const _files = [];
  for (let i = 0; i < files.length; i++) {
    _files.push(files.item(i));
  }
  return _files;
}

export function inArray(needle, items = []) {
  return items.find(function (element) {
    return element === needle;
  });
}

export function utcTime(data) {
  if (data) {
    return ` (UTC${moment(data).format("Z")})`;
  } else {
    return ` (UTC${moment().format("Z")})`;
  }
}

export function convertToNumber(str) {
  const n = parseFloat(typeof str === "string" ? str.replace(/,/g, "") : str);
  if (!str || isNaN(n) || n < 0) return 0;
  return parseFloat(n.toFixed(2));
}

export function mapObjectProps(original, map) {
  const _keys = Object.keys(original);
  let cleanObject = {};
  _keys.forEach((attr) => {
    cleanObject[attr] = map[attr];
  });
  return cleanObject;
}

export function formatVat(vat) {
  const _vat = vat.split(".");
  const lastSegment = _vat.length > 1 ? _vat[_vat.length - 1] : _vat[_vat.length];
  if (lastSegment && lastSegment.length > 1) {
    return _vat[0] + "." + lastSegment.substring(0, 2);
  }
  return vat;
}

export function inArrayWithKey(key, value, items = []) {
  return items.find(function (element) {
    return element[key] === value;
  });
}

export function camelize(str) {
  return str
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
}

export function findParamInQueryString(query, key) {
  const str = queryString.extract(query);
  let params = new URLSearchParams(str);
  return params.get(key);
}

export function prepareQueryAttributes(model = {}, attributes = []) {
  return attributes.reduce((prev, next) => {
    if (queryAttributesType(next.type, model[next.field])) {
      prev.push(filterModel(next.field, getObjectValue(next.type, model[next.field])));
    }
    return prev;
  }, []);
}

export function getObjectValue(type, value) {
  if (type === "bool") return value ? 1 : 0;
  return value;
}

export function queryAttributesType(type, field) {
  let status = false;
  switch (type) {
    case "string":
      if (field && field !== "" && field !== null) status = true;
      break;
    case "array":
      if (field.length > 0) status = true;
      break;
    case "number":
      if (field >= 0) status = true;
      break;
    case "bool":
      if (field >= 0) status = true;
      break;
  }
  return status;
}

export function appendScript(src, async = true, id = "script") {
  const script = document.createElement("script");
  script.src = src;
  script.async = async;
  script.id = id;
  document.body.appendChild(script);
}

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const formatBytes = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export function getStringTimeZoneOffSet(date) {
  const d = date ? new Date(date) : new Date();

  let timezone_offset_min = d.getTimezoneOffset(),
    offset_hrs = parseInt(Math.abs(timezone_offset_min / 60)),
    offset_min = Math.abs(timezone_offset_min % 60),
    timezone_standard;

  if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

  if (offset_min < 10) offset_min = "0" + offset_min;

  if (timezone_offset_min < 0) timezone_standard = "+" + offset_hrs + ":" + offset_min;
  else if (timezone_offset_min > 0) timezone_standard = "-" + offset_hrs + ":" + offset_min;
  else if (timezone_offset_min === 0) timezone_standard = "Z";

  return timezone_standard;
}

export const legTitle = (typeOfFlight, index) => {
  if (`${typeOfFlight}` === "1") {
    return staticService.findByAlias("oneWay");
  }
  if (`${typeOfFlight}` === "2") {
    if (index === 0) {
      return staticService.findByAlias("outbound");
    }
    if (index === 1) {
      return staticService.findByAlias("inbound");
    }
  }
  if (`${typeOfFlight}` === "3") {
    return "Leg " + (index + 1);
  }
};

export const distanceToTime = (distance) => {
  // const timeCounted = Math.floor((distance / 130) * 60 + 15);
  const timeCounted = Math.floor((distance / 130) * 60);
  const h = moment.utc().startOf("day").add({ minutes: timeCounted }).format("HH");
  const m = moment.utc().startOf("day").add({ minutes: timeCounted }).format("mm");
  let roundedMinutes =
    parseInt(m, 10) % 10 !== 0 ? parseInt(m, 10) + (10 - (parseInt(m, 10) % 10)) : parseInt(m, 10);
  let roundedHours = parseInt(h, 10);
  if (roundedMinutes === 60) {
    roundedHours++;
    roundedMinutes = parseInt("00", 10);
  }
  return (
    (roundedHours !== 0 ? roundedHours + (roundedHours === 1 ? " hour" : " hours") : "") +
    " " +
    (roundedMinutes !== 0 ? roundedMinutes + " minutes" : "")
  );
};

export const formatNumber = (value) => {
  if (value === 0) return "0.00";

  const handler = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  if (!value) return value;

  if (typeof value === "string") {
    return handler.format(value.replace(/,/g, ""));
  }

  if (typeof value === "number") {
    return handler.format(value);
  }

  return value;
};

export const renderTime = (time, is_refueling = 0) => {
  if (!time) return "";

  let formattedTime = time.split(":");
  formattedTime = formattedTime.map((el) => (parseInt(el) > 0 ? parseInt(el) : ""));
  const h = formattedTime[0] === 1 ? "hour" : formattedTime[0] > 1 ? "hours" : "";
  const m = formattedTime[1] === 1 ? "minute" : formattedTime[1] > 1 ? "minutes" : "";
  return is_refueling
    ? `${formattedTime[0]} ${h} ${formattedTime[1]} ${m} + ${staticService.findByAlias(
        "refuellingStop",
      )}`
    : `${formattedTime[0]} ${h} ${formattedTime[1]} ${m}`;
};

export const renderValues = (val) => {
  switch (typeof val) {
    case "number": {
      if (val > 0) return val;
      return "---";
    }
    case "string": {
      if (val.trim().length > 0) return val;
      return "---";
    }
    case "undefined": {
      return "---";
    }
    default: {
      if (val === null) return "---";
      return val;
    }
  }
};

export function canWriteCompanyNotes(status) {
  return status !== undefined &&
    status !== QUOTE_REJECTED &&
    status !== QUOTE_WITHDRAWN &&
    status !== QUOTE_DISCUSSION &&
    status !== QUOTE_AMEND &&
    status !== QUOTE_CLOSED &&
    status !== QUOTE_UNSUCCESSFULL &&
    Auth.isAuthenticated()
    ? Auth.isVerified()
    : false;
}
export function departurePlaceholder(departure_time) {
  if (departure_time) {
    const momentInstance = moment().add(config.stripeReleaseInterval, "days");
    if (momentInstance.isSameOrBefore(departure_time)) {
      return momentInstance.format(config.dobFormat);
    } else {
      return moment(departure_time).add(1, "days").format(config.dobFormat);
    }
  }
  return null;
}

export function getLastItemFromArray(arr) {
  return arr[arr.length - 1];
}
