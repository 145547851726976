import React from "react";
import { Control, Fieldset, Errors } from "react-redux-form";
import { validationRules, validationMessages } from "../../../configs";
import { CustomValidateError, updateModel } from "../../../shared";
import staticService from "../../../services/static.service";
import PhoneInput from "../../common/phoneInput";

const { required, requiredObject, validEmail, min, max, invalidEmailDomain, phoneInputValidation } =
  validationRules;

export const UserProfileFieldset = ({ modelName }) => (
  <Fieldset model={modelName}>
    <div className="uk-margin">
      <label
        className={"required-contact uk-form-label"}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("firstName") }}
      ></label>
      <Control.text
        className={"uk-input"}
        model={".first_name"}
        changeAction={updateModel}
        updateOn="change"
        validators={{
          required,
          min: min(2),
          max: max(100),
        }}
      />
      <Errors
        model=".first_name"
        show="touched"
        wrapper={CustomValidateError}
        messages={{
          required: validationMessages().requiredMessage.bind(
            null,
            staticService.findByAlias("firstName"),
          ),
          min: validationMessages().minMessage(2),
          max: validationMessages().maxMessage(100),
        }}
      />
    </div>

    <div className="uk-margin">
      <label
        className={"required-contact uk-form-label"}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("lastName") }}
      ></label>
      <Control.text
        className={"uk-input"}
        model={".last_name"}
        changeAction={updateModel}
        updateOn="change"
        validators={{ required, min: min(2), max: max(100) }}
      />
      <Errors
        model=".last_name"
        show="touched"
        wrapper={CustomValidateError}
        messages={{
          required: validationMessages().requiredMessage.bind(
            null,
            staticService.findByAlias("lastName"),
          ),
          min: validationMessages().minMessage(2),
          max: validationMessages().maxMessage(100),
        }}
      />
    </div>

    <div className="uk-margin">
      <label
        className={"required-contact uk-form-label"}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("directPhoneNumber") }}
        data-uk-tooltip={`title: ${staticService.findByAlias(
          "directPhoneTooltipMessage",
        )}; pos: top-left`}
      />
      <div className="uk-inline uk-width-1-1">
        <span className="uk-form-icon" data-uk-icon={"icon: phone"}></span>
        <Control.text
          model={".phone"}
          changeAction={updateModel}
          updateOn="change"
          validators={{ required: requiredObject("value", true), phoneInputValidation }}
          component={PhoneInput}
        />
      </div>
      <Errors
        model=".phone"
        show="touched"
        wrapper={CustomValidateError}
        messages={{
          required: validationMessages().requiredMessage.bind(
            null,
            staticService.findByAlias("directPhoneNumber"),
          ),
          phoneInputValidation: validationMessages().phoneValidationMessage,
        }}
      />
    </div>

    <div className="uk-margin">
      <label
        className={"required-contact uk-form-label"}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("emailAddress") }}
      ></label>
      <div className="uk-inline uk-width-1-1">
        <span className="uk-form-icon" data-uk-icon={"icon: mail"}></span>
        <Control.text
          type={"email"}
          className={"uk-input"}
          model={".email"}
          changeAction={updateModel}
          updateOn="change"
          validators={{ required, validEmail, invalidEmailDomain }}
        />
      </div>

      <Errors
        model=".email"
        show="touched"
        wrapper={CustomValidateError}
        messages={{
          required: validationMessages().requiredMessage.bind(
            null,
            staticService.findByAlias("emailAddress"),
          ),
          validEmail: validationMessages().validEmailMessage,
          invalidEmailDomain: validationMessages().invalidEmailDomainMessage,
        }}
      />
    </div>
  </Fieldset>
);
