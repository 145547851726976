import Api from "../services/api-handler";
import handlerError from "./error";
import * as types from "./actionTypes";
import { loadUserDashboardStatisticSuccess } from "./generic-actions";
import { staticText } from "../configs";
import { deleteSuccess } from "./favourite-actions";

const entity = "notification";

export function getNotificationsSuccess(notifications) {
  return { type: types.GET_NOTIFICATIONS_LIST, notifications };
}

export function getNotificationsSettings(respones) {
  return { type: types.GET_NOTIFICATIONS_SETTINGS, respones };
}

export function loadUnreadNotificationsSuccess(data) {
  return { type: types.GET_UNREAD_NOTIFICATIONS_LIST, data };
}

export function changeNotificationsSettings(data) {
  return { type: types.CHANGE_NOTIFICATIONS_SETTINGS, data };
}

export function saveNotificationsSettings(data) {
  return { type: types.SAVE_NOTIFICATIONS_SETTINGS, data };
}

export function loadUnreadNotifications(onSuccess = null) {
  return function (dispatch) {
    return Api.setPath(entity, "unread")
      .getAll()
      .then((data) => {
        dispatch(loadUnreadNotificationsSuccess(data));
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function saveNotifications(date) {
  return function (dispatch) {
    return Api.setPath("save-notification-settings")
      .setProperty("responseMessages", staticText.globalMessages.user.notifications)
      .post(date)
      .then(() => {
        dispatch(saveNotificationsSettings(date));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadNotificationsSettings() {
  return function (dispatch) {
    return Api.setPath("notification-settings")
      .setQueryParams()
      .getAll()
      .then((response) => {
        dispatch(getNotificationsSettings(response));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getNotifications(page, filters = []) {
  return function (dispatch) {
    const query = [{ key: "page", value: page }].concat(filters);
    return Api.setPath(entity)
      .setQueryParams(query)
      .getAll()
      .then((notifications) => {
        dispatch(getNotificationsSuccess(notifications));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
