import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Fieldset, Errors } from "react-redux-form";
import { connect } from "react-redux";
import { updateModel, DateTimeComponent } from "../../../shared";
import { ActivityFilterCriteria } from "../../pages/user-online/filters/activity-filter-criteria.component";
import staticService from "../../../services/static.service";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../common/location_autocomplete";
import googleApi from "../../../services/google.service";
import generateLocationAdditionalInfo from "../../../utils/generateLocationAdditionalInfo";
import moment from "moment";

const EmptyMarketplaceFilterFieldset = (props) => {
  const {
    modelName,
    isSubmitEnabled,
    activity,
    updateModelFn,
    resetModel,
    radioLabels = {},
    showActivity = true,
    form,
    resetValidity,
  } = props;
  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    setCurrentValues({
      ...form,
      date_end: moment(form.date_end).isValid() ? form.date_end : "",
      date_start: moment(form.date_start).isValid() ? form.date_start : "",
    });

    if (!moment(form.date_start).isValid()) updateModelFn(`${modelName}.date_start`, "");
    if (!moment(form.date_end).isValid()) updateModelFn(`${modelName}.date_end`, "");
  }, [form]);

  const updateOnSelect = (modelKey, address) => {
    const home_base = address.location;
    const secondary_text = address.secondary_text;
    const lat = address.latitude;
    const lng = address.longitude;
    const source = address.source;
    const additionalInfo = generateLocationAdditionalInfo(address);

    updateModelFn(`${modelName}.${modelKey}_base`, `${home_base}, ${secondary_text}`);
    updateModelFn(`${modelName}.${modelKey}_lat`, lat);
    updateModelFn(`${modelName}.${modelKey}_lng`, lng);
    updateModelFn(`${modelName}.${modelKey}_source`, source);
    updateModelFn(`${modelName}.${modelKey}_info`, additionalInfo);
    updateModelFn(`${modelName}.${modelKey}_coverage_status`, address.coverage_status);
  };

  const fillCoordinates = (modelKey, address) => {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        updateOnSelect(modelKey, address);
      });
    }
  };

  const onSelectLocation = (handleClickOutside, address, modelKey) => {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      fillCoordinates(modelKey, address);
    } else {
      updateOnSelect(modelKey, address);
    }
  };

  const updateGeocodeModel = (model, value) => {
    updateModelFn(`${model}_base`, value);
    updateModelFn(`${model}_info`, "");
  };

  return (
    <Fieldset model={modelName}>
      <div
        className="gh-filters-container uk-flex uk-flex-1 uk-flex-row uk-margin-remove gh-empty-leg-filter-field"
        data-uk-grid
      >
        <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-display-inline-block uk-margin-remove">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("from") }}
          />
          <div className="uk-form-controls uk-position-relative">
            <LocationAutocompleteWrapper>
              {({ locations, search, loader, onBlur, handleClickOutside }) => {
                return (
                  <div className={"uk-position-relative"}>
                    <LocationAutocompleteInput
                      className={["uk-input"]}
                      placeholder={"London"}
                      readOnly={false}
                      onSelect={(location) => {
                        updateGeocodeModel(`${modelName}_from`, location);
                        return search(location);
                      }}
                      value={currentValues.from_base}
                      coverageWarning={currentValues.from_coverage_status}
                      additionalInfo={currentValues.from_info}
                      onBlur={onBlur}
                      loader={loader}
                      debounce={500}
                      model={`${modelName}.from_base`}
                    />
                    <LocationAutocompleteList
                      onSelect={(point) => onSelectLocation(handleClickOutside, point, "from")}
                      locations={locations}
                    />
                  </div>
                );
              }}
            </LocationAutocompleteWrapper>
          </div>
        </div>

        <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-display-inline-block uk-margin-remove">
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("to") }}
          />
          <div className="uk-form-controls uk-position-relative">
            <LocationAutocompleteWrapper>
              {({ locations, search, loader, onBlur, handleClickOutside }) => {
                return (
                  <div className={"uk-position-relative"}>
                    <LocationAutocompleteInput
                      className={["uk-input"]}
                      placeholder={"Paris"}
                      readOnly={false}
                      onSelect={(location) => {
                        updateGeocodeModel(`${modelName}.to`, location);
                        return search(location);
                      }}
                      value={currentValues.to_base}
                      coverageWarning={currentValues.to_coverage_status}
                      additionalInfo={currentValues.to_info}
                      onBlur={onBlur}
                      loader={loader}
                      debounce={500}
                      model={`${modelName}.to_base`}
                    />
                    <LocationAutocompleteList
                      onSelect={(point) => onSelectLocation(handleClickOutside, point, "to")}
                      locations={locations}
                    />
                  </div>
                );
              }}
            </LocationAutocompleteWrapper>
          </div>
        </div>

        <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-display-inline-block uk-margin-remove">
          <label
            className="uk-form-label uk-display-block"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("date") }}
          />
          <div className="uk-form-controls gh-range-hypen gh-range-hypen-filter">
            <Control
              model={`.date_start`}
              className={"uk-input gh-calendar-input"}
              component={DateTimeComponent}
              controlProps={{
                dateFormat: "DD MMMM, YYYY",
                placeholder: "Start date",
                isValidDate: true,
                onClear: () => updateModelFn(modelName + ".date_start", ""),
              }}
              value={currentValues.date_start}
              changeAction={(model, value) => updateModel(model, value.utc().toISOString())}
              updateOn="change"
            />
            <Errors
              model={modelName}
              style={{ position: "absolute" }}
              show="touched"
              wrapper={(props) => {
                const firstRule = props.children.shift();
                if (firstRule && firstRule.props) {
                  return (
                    <ul className="ul validation-errors disabled-list gh-input-errors uk-position-absolute">
                      <li dangerouslySetInnerHTML={{ __html: firstRule.props.children }} />
                    </ul>
                  );
                }
                return null;
              }}
              messages={{
                endDateValidation: staticService.findByAlias("rangeValidationEndDate"),
                rangeRequired: staticService.findByAlias("rangeValidationRequired"),
              }}
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m uk-padding-small uk-padding-remove-top uk-padding-small-top@s uk-display-inline-block uk-margin-remove">
          <div className="uk-form-controls uk-position-relative">
            <Control
              model={`.date_end`}
              className={"uk-input gh-calendar-input"}
              component={DateTimeComponent}
              controlProps={{
                dateFormat: "DD MMMM, YYYY",
                placeholder: "End date",
                isValidDate: true,
                onClear: () => updateModelFn(modelName + ".date_end", ""),
              }}
              value={currentValues.date_end}
              changeAction={(model, value) => updateModel(model, value.utc().toISOString())}
              updateOn="change"
            />
          </div>
        </div>
      </div>
      {showActivity ? (
        <div className={"uk-margin-remove"} data-uk-grid>
          <ActivityFilterCriteria
            updateModel={updateModelFn}
            boxLabels={radioLabels}
            modelName={modelName}
            activity={activity}
          />
        </div>
      ) : null}
      <div className="uk-flex-center" data-uk-grid>
        <div>
          <Control.button
            onClick={() => {
              resetValidity(modelName);
              resetModel(modelName);
            }}
            type={"button"}
            model={modelName}
            className={"uk-button gh-cloud-blue-btn"}
          >
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("resetFilter") }} />
          </Control.button>
        </div>
        <div>
          <Control.button
            model={modelName}
            className={"uk-button uk-button-primary"}
            disabled={isSubmitEnabled}
          >
            <span
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("showFilterResult") }}
            />
          </Control.button>
        </div>
      </div>
    </Fieldset>
  );
};

EmptyMarketplaceFilterFieldset.propTypes = {
  modelName: PropTypes.string,
  form: PropTypes.object,
  isSubmitEnabled: PropTypes.bool,
  activity: PropTypes.string,
  updateModelFn: PropTypes.func,
  resetModel: PropTypes.func,
  radioLabels: PropTypes.object,
  showActivity: PropTypes.bool,
  resetValidity: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    form: state.emptyLegsBrokerFiltersModel,
  };
}

const COMPONENT = connect(mapStateToProps)(EmptyMarketplaceFilterFieldset);

export { COMPONENT as EmptyMarketplaceFilterFieldset };
