import React from "react";
import PropTypes from "prop-types";
import { returnRouteUrl, RouteWithSubRoutes, fireTracking } from "../../../../shared";
import { Route, Switch } from "react-router-dom";
import { BrokerMarketPlaceActivityComponent } from "./broker-marketplace-activity.component";

const BrokerMarketPlaceComponent = ({ match, routes }) => {
  return (
    <Switch>
      {routes &&
        routes.map((route, i) => {
          return (
            <RouteWithSubRoutes key={i} title={route.title} {...returnRouteUrl(route, match.url)} />
          );
        })}
      <Route path="/" onEnter={fireTracking} component={BrokerMarketPlaceActivityComponent} />
    </Switch>
  );
};

BrokerMarketPlaceComponent.contextTypes = {
  store: PropTypes.object,
};

export { BrokerMarketPlaceComponent };
