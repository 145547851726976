import React from "react";
import PropTypes from "prop-types";
import { CLOSE_MODAL } from "../../../../actions";
import { FeedbackFormComponent } from "../../../forms";
import GetHeliEvents from "../../../../services/event.service";
import staticService from "../../../../services/static.service";

export class AbstractBookingDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.onDialogCLose = this.onDialogCLose.bind(this);
    this._mounted = true;
  }

  get feedbackForm() {
    return (
      <FeedbackFormComponent
        submitBut={true}
        feedback={true}
        modelName={"feedbackModel"}
        modelField={".message"}
        label={"Reason"}
        visible={true}
        id={this.props.bookingDetail.empty_leg_booking_id}
        onClickBack={this.closeDialog.bind(this)}
        hasEmptyLegBooking={this.props.bookingDetail.has_empty_leg_booking}
        action={this.onCancelSubmit.bind(this)}
        form={this.props.feedbackModel}
      />
    );
  }

  componentDidMount() {
    this.props.setPageTitle(staticService.findByAlias("bookingDetailPageTitle"));
  }

  onDialogCLose() {
    this.setState({ redirect: true });
    GetHeliEvents.trigger("booking-canceled", { id: thirs.state.bookingDetail.id });
  }

  closeModal() {
    this.props.resetModelProp("emptyLegFromBookingModel.to_name", "");
    this.props.resetModelProp("emptyLegFromBookingModel.time_to", "");
    this.props.resetModelProp("emptyLegFromBookingModel.time_from", "");
    this.props.resetModelProp("emptyLegFromBookingModel.to_coords", "");
    this.props.toggleModal(CLOSE_MODAL, false);
  }

  closeView() {
    return this.props.redirect;
  }

  get confirmPropsBefore() {
    return {
      content: this.feedbackForm,
      buttonsProps: {},
      onSelect: () => {},
      modalProps: {
        id: "withdraw-booking-modal",
        title: staticService.findByAlias("cancelBookingModalTitle"),
        onClose: this.props.closeDialog,
        enableUikitEvents: "false",
      },
    };
  }

  get confirmPropsAfter() {
    return {
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("bookingCancelledContentModal"),
          }}
        />
      ),
      buttonsProps: {
        continue: { title: staticService.findByAlias("close"), css: "uk-button-danger" },
      },
      onSelect: this.onDialogCLose,
      modalProps: {
        id: "withdraw-booking-modal",
        title: staticService.findByAlias("bookingCancelled"),
        onClose: this.onDialogCLose,
        enableUikitEvents: "false",
      },
    };
  }

  componentWillUnmount() {
    this.props.setPageTitle(null);
  }

  render() {
    return null;
  }
}

AbstractBookingDetailComponent.contextTypes = {
  store: PropTypes.object,
};
