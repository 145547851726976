import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";

class AccountStatusComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  get statusCss() {
    if (
      this.props.accountStatus.verification_fields.length > 0 ||
      this.props.accountStatus.verification.status !== "verified"
    ) {
      return "warning";
    }
    return "success";
  }

  get status() {
    if (
      !this.props.accountStatus.internal_verification.bank ||
      this.props.accountStatus.verification_fields.length > 0
    ) {
      return this.props.accountStatus.verification_fields[0];
    } else if (
      this.props.accountStatus.internal_verification.bank &&
      this.props.accountStatus.verification.status === "pending"
    ) {
      return "operatorStripeBankVerification";
    }
    return "operatorBankSuccessVerification";
  }

  render() {
    if (this.props.accountStatus && this.props.accountStatus.verification) {
      return (
        <div className={"uk-alert gh-alert-without-bg uk-alert-" + this.statusCss}>
          <div data-uk-grid>
            <div className="uk-width-expand uk-text-center">
              <span
                className={this.statusCss}
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias(this.status) }}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

AccountStatusComponent.propTypes = {
  accountStatus: PropTypes.object.isRequired,
  dataRequired: PropTypes.array,
};

export { AccountStatusComponent };
