import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { usePipelineViewContext } from "../PipelineViewContext";
import staticService from "../../../../../../services/static.service";
import moment from "moment";
import { config } from "../../../../../../configs";
import { utcTime } from "../../../../../../shared";
import auth from "../../../../../../services/auth";
import Button from "../../../../../common/Button";
import isAllowForEditing from "../../../../../../utils/isAllowForEditing";
import { showNotification } from "../../../../../../utils/showNotification";

const PipelineViewWorkspace = (props) => {
  const { statuses, updatePiplineStatus, sendToMarketplace, handleAssignPipeline } = props;

  const { pipeline, updatePipelineNotes } = usePipelineViewContext();
  const [selectedStatus, setSelectedStatus] = useState(pipeline.status);
  const [note, setNote] = useState("");
  const [selectedBrokerId, setSelectedBrokerId] = useState(null);
  const [selectedStatusColor, setSelectedStatusColor] = useState({
    background: "#f0f0f0",
    text: "#242f42",
  });
  const [loadingAssignBtn, setLoadingAssignBtn] = useState(false);

  useEffect(() => {
    setSelectedBrokerId(pipeline.broker_id);
  }, []);

  const onNotesUpdate = useCallback(
    (event) => {
      setNote(event.target.value);
    },
    [setNote],
  );

  const sendNote = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updatePipelineNotes(note, pipeline.notes);
      setNote("");
    }
  };

  useEffect(() => {
    setSelectedStatus(pipeline.status);

    const statusColor = statuses.find((status) => status.value === +pipeline.status);

    if (statusColor)
      setSelectedStatusColor({ background: statusColor.color, text: statusColor.text_color });
  }, [pipeline.status]);

  const isOptionEnable = (value) => {
    if (pipeline.is_request_created) {
      return value !== 5 && value !== 6 && value !== 7;
    } else {
      return value === 3 || value === 4;
    }
  };

  const StatusesOptions = useMemo(
    () =>
      statuses.map((status) => (
        <option
          key={status.value}
          value={status.value}
          data-label={status.name}
          style={{ "--options-background": status.color, "--option-text": status.text_color }}
          disabled={isOptionEnable(status.value)}
        >
          {`${status.name}`}
        </option>
      )),
    [statuses],
  );

  const updateStatusHanlder = useCallback(async (event) => {
    const value = event.target.value;
    const label = statuses.find((status) => status.value === +value).name;

    updatePiplineStatus(value, label);
  });

  const handleChangeBroker = ({ target }) => {
    setSelectedBrokerId(target.value === "--" ? null : +target.value);
  };

  const assignBroker = () => {
    if (!isAllowForEditing()) {
      showNotification("restrictErrorMessage", "danger");
      return;
    }

    setLoadingAssignBtn(true);
    handleAssignPipeline(selectedBrokerId, pipeline.id).then((res) => {
      setLoadingAssignBtn(false);
      return res;
    });
  };

  const BtnText = useMemo(() => {
    const { has_saved_drafts, is_request_created } = pipeline;
    if (is_request_created) return staticService.findByAlias("requestCreated");
    if (!has_saved_drafts) return staticService.findByAlias("PipelineModalButtonLabel");

    return staticService.findByAlias("continueWithDraft");
  }, [pipeline.has_saved_drafts, pipeline.is_request_created]);

  const isAssignedToAdmin = useMemo(() => {
    const currentUser = auth.getUser();
    return currentUser.role === "broker_admin" && currentUser.id === pipeline.broker_id;
  }, [auth.getUser()]);

  return (
    <div className="pipeline-view-tab">
      <div data-uk-grid className="uk-margin-bottom pipeline-view-wrapper">
        <div className="uk-width-1-1 uk-width-1-2@s pipeline-view-item">
          <div
            className="uk-form-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("status") }}
          />
          <select
            className="uk-select pipeline-view-select"
            value={selectedStatus}
            // disabled={pipeline.is_request_created}
            onChange={updateStatusHanlder}
            style={{
              "--status-background-color": selectedStatusColor.background,
              "--status-text-color": selectedStatusColor.text,
            }}
          >
            {StatusesOptions}
          </select>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s pipeline-view-item">
          <div
            className="uk-form-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("marketplaceRequest") }}
          />
          <button
            disabled={pipeline.is_request_created}
            className="uk-button uk-button-primary"
            type="button"
            onClick={sendToMarketplace}
            style={{ marginTop: "5px" }}
          >
            {BtnText}
          </button>
        </div>
      </div>

      <div data-uk-grid className="uk-margin-bottom pipeline-view-wrapper">
        <div className="uk-width-1-1 uk-width-1-2@s pipeline-view-item">
          <div
            className="uk-form-label"
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("assignTo") }}
          />
          <select
            className="uk-select pipeline-view-select"
            value={selectedBrokerId}
            disabled={!pipeline.ability_to_edit}
            onChange={handleChangeBroker}
            style={{
              "--status-background-color": selectedStatusColor.background,
              "--status-text-color": selectedStatusColor.text,
            }}
          >
            {isAssignedToAdmin ? (
              <option value="--" selected={true}>
                --
              </option>
            ) : (
              ""
            )}

            {pipeline.team_members.map((item) => (
              <option key={item.id} value={item.id}>
                {item.first_name + " " + item.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s uk-margin-remove pipeline-view-item --extra-margin-bottom">
          <Button
            disabled={!selectedBrokerId || selectedBrokerId === pipeline.broker_id}
            loading={loadingAssignBtn}
            onClick={assignBroker}
            className="uk-button uk-button-primary"
            style={{ marginTop: "5px" }}
          >
            {staticService.findByAlias("assign")}
          </Button>
        </div>
      </div>

      <div className="pipeline-view-box pipeline-view-wrapper">
        <span className="uk-form-label">{staticService.findByAlias("yourNotes")}</span>
        <textarea
          className="uk-textarea"
          value={note}
          onChange={onNotesUpdate}
          onKeyDown={sendNote}
          placeholder="Please add here notes about your progress."
          style={{ resize: "none", overflow: "hidden" }}
        />
        <ul className="disabled-list uk-margin-top uk-margin-bottom">
          {pipeline.notes.map((note, idx, arr) => (
            <li key={note.id}>
              <span className="uk-flex uk-flex-between uk-flex-column">
                <span style={{ color: "#1abc9c", fontWeight: 500 }}>
                  {note.user.first_name + " " + note.user.last_name}
                </span>
                <span style={{ fontWeight: 400, margin: "0 0 6px" }}>{note.body}</span>
                <span style={{ fontSize: 14 }}>
                  {moment(note.created_at).format(config.dateFormat) +
                    " | " +
                    moment(note.created_at).format(config.timeFormat) +
                    utcTime()}
                </span>
              </span>
              {idx + 1 !== arr.length ? <hr style={{ margin: "10px 0" }} /> : ""}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

PipelineViewWorkspace.propTypes = {
  statuses: PropTypes.array,
  updatePiplineStatus: PropTypes.func,
  sendToMarketplace: PropTypes.func,
  handleAssignPipeline: PropTypes.func,
};

export default PipelineViewWorkspace;
