import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { preRegister } from "../../../actions";
import { validationRules, validationMessages } from "../../../configs";
import { CustomValidateError, updateModel } from "../../../shared";
import staticService from "../../../services/static.service";
import PhoneInput from "../../common/phoneInput";
import Tooltip from "../../common/Tooltip";

const { validEmail, min, max, phoneInputValidation, isNumber } = validationRules;

class RegisterModalFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelName: "userModalRegisterModel",
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  clearURLHash() {
    window.history.pushState("", document.title, window.location.pathname + window.location.search);
  }

  onSubmit(user) {
    this.props.register(
      { ...user, phone: typeof user.phone === "object" ? user.phone.value : user.phone },
      () => {
        this.clearURLHash();
        this.props.onSuccess();
        this.props.resetModel(this.state.modelName);
      },
    );
  }

  cancel() {
    this.clearURLHash();
    this.props.onCancel();
  }

  get radioItems() {
    return [
      {
        value: "broker",
        label: "Broker",
      },
      {
        value: "operator",
        label: "Operator",
      },
      {
        value: "other",
        label: "Other",
      },
    ];
  }

  get sizes() {
    return ["1-2", "3-5", "6-9", "10+"];
  }

  get isOperator() {
    return this.props.type === "operator" ? true : false;
  }

  get operatorTemplate() {
    return (
      <div data-uk-grid className={"uk-child-width-expand@s"}>
        <div>
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("heliSizeFleetLabel"),
            }}
          />
          <Control.select
            className={"uk-input"}
            model={".size"}
            changeAction={this.props.updateModel}
            updateOn="change"
          >
            <option value={""} key={"optional"}>
              Select size
            </option>
            {this.sizes.map((size, i) => (
              <option value={size} key={i}>
                {size}
              </option>
            ))}
          </Control.select>
        </div>

        <div>
          <label
            className={"uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("flightsPerYearLabel"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".flights"}
            changeAction={this.props.updateModel}
            updateOn="change"
            validators={{
              isNumber,
            }}
          />
          <Errors
            model=".flights"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              isNumber: validationMessages().isNumberMessage,
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.props.visible) {
      return (
        <Form model={this.state.modelName} onSubmit={this.onSubmit}>
          <div data-uk-grid className={"uk-child-width-expand@s"}>
            <div className={"validation-wrapper"}>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("firstName"),
                }}
              />
              <Control.text
                className={"uk-input"}
                model={".first_name"}
                changeAction={this.props.updateModel}
                updateOn="change"
                validators={{
                  min: min(2),
                  max: max(100),
                }}
              />
              <Errors
                model=".first_name"
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  required: validationMessages().requiredMessage.bind(
                    null,
                    staticService.findByAlias("registerModalName"),
                  ),
                  min: validationMessages().minMessage(2),
                  max: validationMessages().maxMessage(100),
                }}
              />
            </div>

            <div className={"validation-wrapper"}>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("lastName"),
                }}
              />
              <Control.text
                className={"uk-input"}
                model={".last_name"}
                changeAction={this.props.updateModel}
                updateOn="change"
                validators={{
                  min: min(2),
                  max: max(100),
                }}
              />
              <Errors
                model=".last_name"
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  required: validationMessages().requiredMessage.bind(
                    null,
                    staticService.findByAlias("registerModalName"),
                  ),
                  min: validationMessages().minMessage(2),
                  max: validationMessages().maxMessage(100),
                }}
              />
            </div>
          </div>

          <div data-uk-grid className={"uk-child-width-expand@s"}>
            <div className={"validation-wrapper"}>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("companyName"),
                }}
              />
              <Control.text
                className={"uk-input"}
                model={".company_name"}
                changeAction={this.props.updateModel}
                updateOn="change"
                validators={{
                  min: min(3),
                  max: max(150),
                }}
              />
              <Errors
                model=".company_name"
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  required: validationMessages().requiredMessage.bind(
                    null,
                    staticService.findByAlias("companyName"),
                  ),
                  min: validationMessages().minMessage(3),
                  max: validationMessages().maxMessage(150),
                }}
              />
            </div>
            <div className={"validation-wrapper"}>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("emailAddress"),
                }}
              />
              <Control.text
                className={"uk-input"}
                model={".email"}
                changeAction={this.props.updateModel}
                updateOn="change"
                validators={{
                  validEmail,
                }}
              />
              <Errors
                model=".email"
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  required: validationMessages().requiredMessage.bind(
                    null,
                    staticService.findByAlias("emailAddress"),
                  ),
                  validEmail: validationMessages().validEmailMessage,
                }}
              />
            </div>
          </div>

          <div data-uk-grid className={"uk-child-width-expand@s"}>
            <div className={"validation-wrapper"}>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("phoneLabel"),
                }}
              />
              <Control.text
                model={".phone"}
                changeAction={this.props.updateModel}
                updateOn="change"
                validators={{
                  phoneInputValidation,
                }}
                component={PhoneInput}
              />
              <Errors
                model=".phone"
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  phoneInputValidation: validationMessages().phoneValidationMessage,
                }}
              />
            </div>
            <div className="validation-wrapper uk-flex uk-align-center uk-margin-remove">
              <label className="gh-checkbox-label">
                <Control.checkbox
                  className="uk-checkbox uk-margin-small-right"
                  model=".booking_demo"
                  changeAction={updateModel}
                  updateOn="change"
                />
                {staticService.findByAlias("bookingDemoCeo")}
              </label>
            </div>
          </div>

          <div
            style={{ fontSize: 12, marginBottom: 15 }}
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("gdprText") }}
          />

          <div className="buttons uk-flex uk-flex-center" data-uk-grid>
            <div>
              <Control.button
                model="userRegisterModel"
                disabled={{ valid: false }}
                className={"uk-button uk-button-primary"}
              >
                {staticService.findByAlias("submit")}
              </Control.button>
            </div>
            <div>
              <button
                className={"uk-button gh-btn-normal"}
                type={"button"}
                onClick={this.cancel.bind(this)}
              >
                {staticService.findByAlias("dismiss")}
              </button>
            </div>
          </div>
        </Form>
      );
    }

    return null;
  }
}

RegisterModalFormComponent.contextTypes = {
  store: PropTypes.object,
};

RegisterModalFormComponent.propTypes = {
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  visible: PropTypes.bool,
  register: PropTypes.func,
  resetModel: PropTypes.func,
  updateModel: PropTypes.func,
  type: PropTypes.string,
};

const mapStateToProps = ({ userModalRegisterModel, post }) => {
  return {
    ...userModalRegisterModel,
    post,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      register: preRegister,
      updateModel: (model, value) => {
        return (dispatch) => {
          dispatch(actions.resetValidity(model));
          dispatch(actions.change(model, value));
        };
      },
      resetModel: (model) => dispatch(actions.reset(model)),
    },
    dispatch,
  );
};
const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RegisterModalFormComponent);
export { COMPONENT as RegisterModalFormComponent };
