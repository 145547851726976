import React from "react";

const ListValidateError = ({ styles = {}, errors }) => {
  return (
    <ul className={"ul validation-errors disabled-list"} style={styles || {}}>
      {errors && errors.map((error, k) => <li key={k}>{error}</li>)}
    </ul>
  );
};

export { ListValidateError };
