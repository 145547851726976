import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";
import { setActiveItem } from "../../../../shared";

const FilterTabsComponent = (props) => {
  const { tabs, changeFunc, isLoading } = props;

  return (
    <ul className="gh-filter-tabs">
      {tabs.map(({ alias, active, filter }, index) => {
        return (
          <li key={index}>
            <a
              className={`${active ? "gh-active" : ""} ${
                isLoading ? "gh-filter-tabs-loading" : ""
              }`}
              onClick={() => {
                if (isLoading) return;

                changeFunc(setActiveItem(tabs, filter), filter);
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(alias),
                }}
              />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

FilterTabsComponent.defaultProps = {
  tabs: [],
};

FilterTabsComponent.propTypes = {
  tabs: PropTypes.array,
  changeFunc: PropTypes.any,
  isLoading: PropTypes.bool,
};

export { FilterTabsComponent };
