import React from "react";
import PropTypes from "prop-types";

const TabItem = ({ isActive = false, classNames = "", title, onItemClick }) => {
  const className = `${isActive ? "uk-active" : ""} ${classNames}`;
  return (
    <li aria-expanded="true" className={className}>
      <a onClick={onItemClick}>{title}</a>
    </li>
  );
};

TabItem.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export { TabItem };
