import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../../../CalculatorContext";
import { useLegContext } from "../LegContext";
import { Description } from "../../Description";
import { Accordion } from "../../Accordion";
import { ConfirmInput, TaxInput } from "../../Input";
import { Control } from "../../Control";
import { SectionArrow } from "../../SectionArrow/";
import { precision } from "../../../services/calculations/formulas";

export default function FlightSection() {
  const { activeSections, toggleSectionStatus, resetToOriginal, updateForm, currency, banking } =
    useCalculatorContext();
  const { prefix, isLast, onLegFlightChange, legId } = useLegContext();
  const { getValues, setValue, watch } = useFormContext();

  const flight_duration_field = watch(`${prefix}.flight_duration`);
  const total_duration_field = watch(`${prefix}.total_duration`);

  const cruise_speed = watch(`${prefix}.cruise_speed`);
  const flight_tax = watch(`${prefix}.flight_tax`);
  const price_per_hour = watch(`${prefix}.price_per_hour`);
  const flight_duration = flight_duration_field !== Infinity ? flight_duration_field : 0;
  const extra_time = watch(`${prefix}.extra_time`);
  const total_duration = total_duration_field !== Infinity ? total_duration_field : 0;

  const defaultTaxRate = {
    sum: "",
    percent: banking?.vat_value,
  };

  React.useEffect(onLegFlightChange, [
    cruise_speed,
    price_per_hour,
    flight_duration,
    extra_time,
    onLegFlightChange,
    total_duration,
    flight_tax,
  ]);

  const updateFormValue = React.useCallback(
    ([name, value]) => setValue(`${prefix}.${name}`, value),
    [prefix, setValue],
  );

  const resetPricePerHour = React.useCallback(() => {
    const [, aircraft] = prefix.split(".");
    const aircraft_price_per_hour = getValues(`aircrafts.${aircraft}.price_per_hour`);

    updateForm([[`${prefix}.price_per_hour`, aircraft_price_per_hour]]);
    onLegFlightChange();
  }, [getValues, onLegFlightChange, prefix, updateForm]);

  return (
    <Accordion
      isOpen={activeSections.includes("flight")}
      onToggle={toggleSectionStatus("flight", !activeSections.includes("flight"))}
      className="uk-margin-small-top"
      header={
        <Description>
          <Description.Item as="custom" type="header">
            {isLast && <SectionArrow closed={!activeSections.includes("flight")} />}
          </Description.Item>
        </Description>
      }
    >
      <Description>
        <Description.Item>
          <Control name={`${prefix}.distance`} required>
            <ConfirmInput
              placeholder="[0]"
              onFocus={toggleSectionStatus("flight", true)}
              onReset={() => {
                updateFormValue(["distance_last_update_by", "locations"]);
                updateFormValue(["duration_last_update_by", "distance"]);
                resetToOriginal({
                  legId,
                  field: `${prefix}.distance`,
                  callback: onLegFlightChange,
                })();
              }}
              onValueChange={() => {
                updateFormValue(["duration_last_update_by", "distance"]);
                updateFormValue(["distance_last_update_by", "distance"]);
                onLegFlightChange();
              }}
            />
          </Control>
        </Description.Item>
        <Description.Item
          className="leg-suffix-padding"
          suffix={`(${precision((flight_duration || 0) * 60)} min)`}
        >
          <Control name={`${prefix}.flight_duration`} required>
            <ConfirmInput
              placeholder="[0]"
              onReset={() => {
                updateFormValue(["duration_last_update_by", "locations"]);
                resetToOriginal({
                  legId,
                  field: `${prefix}.flight_duration`,
                  callback: onLegFlightChange,
                })();
              }}
              onValueChange={() => {
                updateFormValue(["duration_last_update_by", "flight_duration"]);
              }}
            />
          </Control>
        </Description.Item>
        {/* <Description.Item>
          <Control name={`${prefix}.headwind`}>
            <ConfirmInput
              placeholder="[0]"
              onValueChange={onLegFlightChange}
              onReset={resetToOriginal({
                legId,
                field: `${prefix}.headwind`,
                callback: onLegFlightChange,
              })}
            />
          </Control>
        </Description.Item> */}
        <Description.Item
          className="leg-suffix-padding"
          suffix={`(${precision((extra_time || 0) * 60)} min)`}
        >
          <Control name={`${prefix}.extra_time`}>
            <ConfirmInput
              placeholder="[0]"
              onReset={resetToOriginal({
                legId,
                field: `${prefix}.extra_time`,
                callback: onLegFlightChange,
              })}
            />
          </Control>
        </Description.Item>
        <Description.Item
          className="leg-suffix-padding"
          suffix={`(${precision((total_duration || 0) * 60)} min)`}
        >
          <Control name={`${prefix}.total_duration`}>
            <ConfirmInput placeholder="0" readOnly />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.price_per_hour`} required>
            <ConfirmInput placeholder="[0]" onReset={resetPricePerHour} />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.flight_tax`}>
            <TaxInput
              onValueChange={onLegFlightChange}
              total={getValues(`${prefix}.total_cost_flight`)}
              currency={currency}
              value={flight_tax || flight_tax?.percent !== "" ? flight_tax : defaultTaxRate}
            />
          </Control>
        </Description.Item>
        <Description.Item separator>
          <Control name={`${prefix}.total_cost_flight`}>
            <ConfirmInput placeholder="0.00" readOnly />
          </Control>
        </Description.Item>
      </Description>
    </Accordion>
  );
}
