import React, { Component } from "react";
import PropTypes from "prop-types";
import { actions } from "react-redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, Errors, Form } from "react-redux-form";
import { validationMessages, validationRules } from "../../../../configs";
import { updateModel, CustomValidateError } from "./../../../../shared";
import { updateIdentifier } from "../../../../actions";
import staticService from "../../../../services/static.service";
import apiHandler from "../../../../services/api-handler";

class ClientIdentifierComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      clientReference: {},
    };
    this.updateFormModel = this.updateFormModel.bind(this);
    this.fetchReference = this.fetchReference.bind(this);
  }

  fetchReference(id) {
    apiHandler
      .setPath("clients")
      .get(id)
      .then((res) => this.setState({ clientReference: res }));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.identifier && !this.state.clientReference.id) {
      this.fetchReference(this.props.identifier);
    }

    if (this.props.identifier !== nextProps.identifier) {
      this.updateFormModel(nextProps);
    }
  }

  updateFormModel(props) {
    const request = {
      id: props.identifierId,
      status: props.identifierStatus,
      identifier: props.identifier !== null ? props.identifier : "",
    };
    this.context.store.dispatch(actions.change("identifierModel", request));
  }

  get identifier() {
    return (
      <div>
        <div className={"uk-display-inline-block"}>
          <span className="gh-column-text">
            {this.state.clientReference.client_identifier || "---"}
          </span>
        </div>
      </div>
    );
  }

  get editForm() {
    return (
      <div>
        <Form model={"identifierModel"} onSubmit={this.onSubmit.bind(this)}>
          <div className="uk-flex">
            <div className="gh-identifier-value">
              <Control.text
                className="uk-input uk-form-small gh-top-row"
                model={".identifier"}
                validators={{
                  max: validationRules.max(30),
                  alphaNumValidation: validationRules.alphaNumValidation,
                }}
                changeAction={updateModel}
                placeholder={"Identifier"}
              />
            </div>
            <div className="gh-identifier-buttons">
              <Control.button
                model={"identifierModel"}
                disabled={{ valid: false }}
                className="uk-button-link uk-icon"
              >
                <i data-uk-icon={"icon: check"} />
              </Control.button>
              <button
                className="uk-button-link gh-red"
                data-uk-icon={"icon: close"}
                onClick={this.changeMode.bind(this)}
                type="button"
              />
            </div>
          </div>
          <Errors
            model={".identifier"}
            show={true}
            wrapper={CustomValidateError}
            styles={{
              fontSize: 12,
            }}
            messages={{
              alphaNumValidation: validationMessages().alphaNumValidationMessage,
              max: validationMessages().maxMessage(30),
            }}
          />
        </Form>
      </div>
    );
  }

  onSubmit(model) {
    const identifier = {
      ...model,
      id: this.props.requestId,
      status: this.props.requestStatus,
    };

    this.props.updateIdentifier(identifier, this.onSuccess.bind(this));
  }

  onSuccess() {
    this.setState((prevState) => ({ editMode: !prevState.editMode }));
  }

  changeMode() {
    this.updateFormModel(this.props);
    this.setState((prevState) => ({ editMode: !prevState.editMode }));
  }

  render() {
    const { match } = this.props;
    return (
      <div className={match ? "uk-flex uk-flex-middle gh-flex-form" : ""}>
        {match ? (
          <h4 className="gh-column-label">{staticService.findByAlias("clientIdentifier")}</h4>
        ) : (
          <div className="uk-text-uppercase">
            <small
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("clientIdentifier"),
              }}
            />
          </div>
        )}
        <div className="gh-text-big gh-identifier-form">{this.identifier}</div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.resetModel();
  }
}

ClientIdentifierComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateIdentifier,
      resetModel: () => dispatch(actions.reset("identifierModel")),
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ClientIdentifierComponent);
export { COMPONENT as ClientIdentifierComponent };
