import React, { Component } from "react";
import PropTypes from "prop-types";
import { spinnerInstance } from "../../shared";
import api from "../../services/api-handler";
import { CustomValidateError } from "../../shared";
import close from "../../assets/img/svg/crossInCircle.svg";

export class UploadedFileComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startUpload: false,
      hasError: false,
      error: "",
      file: {},
      uploadFinish: false,
      fileStatus: "",
      fileNameError: "",
    };
    this.slice_size = 1000 * 1024;
    this.fileReader = null;

    this.onSuccess = this.onSuccess.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.upload = this.upload.bind(this);
    this.onError = this.onError.bind(this);
    this.showStatus = this.showStatus.bind(this);
    this.showFileType = this.showFileType.bind(this);
    api.setDispatchAndValidationModel(props.dispatch, null);
    api.showSpinner = false;
  }

  componentDidMount() {
    if (this.props.file instanceof File && !this.state.startUpload && !this.props.file.upload) {
      this.setState({ startUpload: true });
      this.startUpload();
    }
  }

  startUpload() {
    this.fileReader = new FileReader();
    this.upload(0);
    this.setState({ fileStatus: "uploading" });

    if (!this.props.withOutGlobalLoading) {
      spinnerInstance.show();
    }
  }

  upload(start) {
    const next_slice = start + this.slice_size + 1;
    const blob = this.props.file;
    this.fileReader.onloadend = (event) => {
      if (event.target.readyState !== FileReader.DONE) {
        return;
      }
      this.props
        .updateAction({
          file_data: event.target.result,
          file: this.props.file.name.replaceAll(" ", ""),
          file_type: this.props.file.type,
          file_size: this.props.file.size,
          document_type: this.props.docType,
        })
        .then((data) => this.onSuccess(data, next_slice))
        .catch(this.onError);
    };
    this.fileReader.readAsDataURL(blob);
  }

  onSuccess(response) {
    if (response.file_size === this.props.file.size) {
      // Update upload progress
      this.props.onUploadSuccess({
        ...response,
        upload: true,
        fileIndex: this.props.index,
      });
      this.setState({
        uploadFinish: true,
        file: response.file_name,
        fileStatus: "uploaded",
      });
      api.setProperty("skipErrorAlert", true);
      api.showSpinner = false;
      spinnerInstance.hide();
    }
  }

  onError(error) {
    if (error) {
      this.props.handleUpdateProgressStatus("error", null, 1);
    }

    let errorMess = null;
    if (error.status === 422) {
      const errorKeys = Object.keys(error.data);

      if (errorKeys.length > 0 && error.data[errorKeys[0]][0]) {
        errorMess = error.data[errorKeys[0]][0];
      }
    } else {
      errorMess = error.message ? error.message : "";
    }
    this.setState({
      hasError: true,
      error: errorMess,
      fileStatus: "error",
    });
  }

  removeFile() {
    const { file, removeAction, onRemoveSuccess, index, customRemoveFileAction } = this.props;

    if (customRemoveFileAction) {
      return customRemoveFileAction(file);
    }

    if (file.id) {
      return removeAction({
        file_name: file.name,
        entity: this.props.entity,
        entity_id: file.id,
        file,
      }).then(() => {
        onRemoveSuccess(index);
        api.setProperty("skipErrorAlert", false);
      });
    }
    if (this.state.uploadFinish || file.upload) {
      return removeAction({
        file_name: file.name,
        file,
      }).then(() => {
        onRemoveSuccess(index);
        api.setProperty("skipErrorAlert", false);
      });
    }

    onRemoveSuccess(index);
  }

  get Error() {
    return <CustomValidateError>{[<span key={1}>{this.state.error}</span>]}</CustomValidateError>;
  }

  showStatus() {
    switch (this.state.fileStatus) {
      case "uploading":
        return <span className="gh-file-uploading">Uploading...</span>;
      case "uploaded":
        return (
          <a
            className="gh-file-view gh-file-ready"
            onClick={() => this.props.openModal(this.props.file)}
          >
            View
          </a>
        );
      case "error":
        return <span className="gh-file-error">Couldn’t upload</span>;
      default:
        return (
          <a
            className="gh-file-view gh-file-ready"
            onClick={() => this.props.openModal(this.props.file)}
          >
            View
          </a>
        );
    }
  }

  showFileType() {
    const { fileStatus } = this.state;
    const { file } = this.props;

    if (fileStatus === "uploading") {
      return <div uk-spinner="ratio: 0.7" />;
    } else {
      switch (file.type || file.file_type) {
        case "image/jpeg":
          return <span>jpg</span>;
        case "image/jpg":
          return <span>jpg</span>;
        case "image/png":
          return <span>png</span>;
        case "application/pdf":
          return <span>pdf</span>;
        default:
          return "";
      }
    }
  }

  render() {
    const { file, index, hideRemove } = this.props;
    const { fileStatus } = this.state;

    return (
      <div className="gh-file-container" style={hideRemove ? { padding: 0 } : {}}>
        <div className="gh-uz-file">
          <div className="gh-uz-file-content">
            <span className={`gh-uz-file-format ${fileStatus === "error" ? "error" : ""}`}>
              {this.showFileType()}
            </span>
            <div className="gh-uz-file-box">
              <div className="gh-uz-file-name">{file.name || file.file_name}</div>
              <div className="gh-uz-file-status">{this.showStatus()}</div>
            </div>
          </div>

          {!hideRemove ? (
            <div className="gh-uz-file-close">
              <a className="uk-link-text" onClick={this.removeFile.bind(this)}>
                <img className={"close"} id={"close" + index} src={close} alt="close" />
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

UploadedFileComponent.propTypes = {
  index: PropTypes.number,
  updateAction: PropTypes.func,
  removeAction: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  file: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  handleUpdateProgressStatus: PropTypes.func,
  withOutGlobalLoading: PropTypes.bool,
  hideRemove: PropTypes.bool,
  customRemoveFileAction: PropTypes.func,
};

UploadedFileComponent.defaultProps = {
  handleUpdateProgressStatus: () => {},
  hideRemove: false,
};
