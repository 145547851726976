import React, { useEffect, useState } from "react";
import info from "../../assets/img/svg/info.svg";
import staticService from "../../services/static.service";
import { func } from "prop-types";

const debounce = (fn, ms = 300) => {
  let timeHandler;
  return function () {
    clearTimeout(timeHandler);
    timeHandler = setTimeout(() => {
      fn.apply(this, arguments);
    }, ms);
  };
};

export const GhTooltip = ({
  side,
  alias,
  disabled,
  secondary,
  tKey,
  icon,
  message,
  adjustPos = true,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState(side);

  const elementPosition = (el) => {
    if (el) return el.getBoundingClientRect().top;
  };

  const onTooltipHover = () => {
    const tooltipIcon = document.getElementById(`${(alias || message) + tKey}`);
    const tooltipWrapper = document.getElementById(`${"box" + (alias || message) + tKey}`);
    const isTooltipOnTop = elementPosition(tooltipIcon) < window.innerHeight / 2;
    const isDesktop = window.innerWidth > 639;

    if (adjustPos) {
      if (isTooltipOnTop) {
        const p = side.includes("top") ? side.replace("top", "bottom") : side;
        setTooltipPosition(p);
        const tooltipWrapperStyle = isDesktop
          ? `translate(${side.includes("right") ? "-6px" : "-270px"}, 14px)`
          : `translate(-165px, 14px)`;

        tooltipWrapper.style.transform = tooltipWrapperStyle;
      } else {
        const p = side.includes("bottom") ? side.replace("bottom", "top") : side;
        setTooltipPosition(p);

        const tooltipWrapperStyle = isDesktop
          ? `translate(${side.includes("right") ? "-6px" : "-270px"}, ${-Math.abs(
              70 + tooltipWrapper.offsetHeight - 38,
            )}px)`
          : `translate(${side.includes("right") ? "-6px" : "-165px"}, ${-Math.abs(
              70 + tooltipWrapper.offsetHeight - 38,
            )}px)`;

        tooltipWrapper.style.transform = tooltipWrapperStyle;
      }
    }

    return () => {
      tooltipIcon.removeEventListener("mouseover", onTooltipHover);
    };
  };

  useEffect(() => {
    const tooltipIcon = document.getElementById(`${(alias || message) + tKey}`);
    tooltipIcon.addEventListener("mouseover", onTooltipHover);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 639)
      document.addEventListener(
        "scroll",
        debounce(() => {
          onTooltipHover();
        }, 300),
      );
  });

  return (
    <span
      id={(alias || message) + tKey}
      className={`gh-tooltip ${disabled ? "disabled" : ""} ${tooltipPosition} ${
        secondary ? secondary : ""
      }`}
    >
      {icon ? icon : <img className="gh-tooltip-icon" src={info} alt="tooltip" />}
      {alias ? (
        <span
          id={"box" + alias + tKey}
          className="gh-tooltip-box"
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }}
        />
      ) : (
        ""
      )}
      {message ? (
        <span
          id={"box" + message + tKey}
          className="gh-tooltip-box"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        ""
      )}
    </span>
  );
};
