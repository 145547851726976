import React, { Component } from "react";
import PropTypes from "prop-types";

const _loaded = {};

class ImageLoader extends Component {
  //initial state: image loaded stage
  constructor(props) {
    super(props);
    this.state = {
      loaded: _loaded[this.props.src],
    };
    this.onLoad = this.onLoad.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextState.loaded || this.props.src !== nextProps.src;
  }

  //image onLoad handler to update state to loaded
  onLoad() {
    _loaded[this.props.src] = true;
    this.setState(() => ({ loaded: true }));
  }

  render() {
    let { className = "", loadedClassName = "", loadingClassName = "" } = this.props;

    const classN = `${className} ${this.state.loaded ? loadedClassName : loadingClassName}`;

    return (
      <img
        src={this.props.src}
        alt={this.props.alt}
        onClick={this.props.onClick}
        className={classN}
        onLoad={this.onLoad}
      />
    );
  }
}

ImageLoader.propTypes = {
  loadedClassName: PropTypes.string,
  alt: PropTypes.string,
  loadingClassName: PropTypes.string,
  classNames: PropTypes.string,
  src: PropTypes.string,
};

ImageLoader.defaultTypes = {
  classNames: "",
  loadingClassName: "img-loading",
  loadedClassName: "img-loaded",
  alt: "",
  src: "",
};

export default ImageLoader;
