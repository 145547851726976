import React from "react";
import staticService from "../../../../../services/static.service";
import { config } from "../../../../../configs";

const ReviewPreferenceComponent = ({ requestForm }) => {
  const crewSize = () => {
    if (requestForm.crew_size === 0 || requestForm.crew_size) {
      return config.pilotPreferences[Number(requestForm.crew_size)].title;
    }
  };
  return (
    <div className="gh-simple-section review-preference">
      <h4
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("AircraftPreferences"),
        }}
      />
      <div data-uk-grid>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("helicopterType"),
              }}
            />
            <div className="gh-info-text">
              {requestForm.preferences &&
                config.aircraftPreferences.find((a) => a.id === requestForm.preferences).title}
            </div>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("CrewRequirements"),
              }}
            />
            <div className="gh-info-text">{crewSize()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReviewPreferenceComponent };
