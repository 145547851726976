import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../../CalculatorContext";
import { Description } from "../Description";
import { Accordion } from "../Accordion";
import uid from "../../../../utils/uid";

export default function CalculatorPilotsLabels() {
  const { activeSections, activeAircraftIndex, currency } = useCalculatorContext();
  const { watch } = useFormContext();

  const aircraftLegsPilots = watch(`aircrafts.${activeAircraftIndex}.legs`) || [{ pilots: [1] }];
  const labelsCount = Math.max(...aircraftLegsPilots.map((leg) => leg.pilots.length));

  const Labels = React.useMemo(() => {
    return Array.from(Array(labelsCount))
      .map(() => uid().uid)
      .map((uid, idx) => (
        <Description key={uid}>
          <Description.Item as="text">Pilot {idx + 1}</Description.Item>
          <Description.Item as="text">Included in the Price</Description.Item>
          <Description.Item as="text">Pilot rate period</Description.Item>
          <Description.Item as="text">Pilot rate amount ({currency || "GBP"})</Description.Item>
          <Description.Item as="text">Pilot duty (hours or days)</Description.Item>
          <Description.Item as="text">Pilot tax rate (%)</Description.Item>
          <Description.Item as="text" separator type="info">
            Total Cost of Pilot {idx + 1} ({currency || "GBP"})
          </Description.Item>
        </Description>
      ));
  }, [labelsCount]);

  return (
    <Accordion
      isOpen={activeSections.includes("pilots")}
      className="uk-margin-small-bottom pilots-list"
      forcedMaxH={labelsCount * 356 + 52}
      header={
        <Description>
          <Description.Item as="text" type="header">
            Pilot(s)
          </Description.Item>
        </Description>
      }
    >
      {Labels}
      <Description>
        <Description.Item />
      </Description>
    </Accordion>
  );
}
