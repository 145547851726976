import React from "react";
import ButtonCopyToClipboard from "../../../../../common/ButtonCopyToClipboard";
import staticService from "../../../../../../services/static.service";
import Popup from "reactjs-popup";
import ConditionalRendering from "../../../../../common/ConditionalRendering";
import info from "../../../../../../assets/img/svg/info.svg";

const returnBrokerBookingRequestListTableActionComponent = (linkToBeCopied, hide = false) => {
  return {
    desktop: (
      <td style={{ verticalAlign: "middle" }}>
        <ConditionalRendering showComponent={!hide}>
          <Popup
            trigger={
              <div>
                <ButtonCopyToClipboard textToBeCopied={linkToBeCopied} disabled={hide} />
              </div>
            }
            on="hover"
            position={["top center", "left center"]}
            keepTooltipInside={true}
          >
            {staticService.findByAlias("generateLinkAndSendViaEmail")}
          </Popup>
        </ConditionalRendering>
      </td>
    ),
    mobile: (
      <div>
        <ConditionalRendering showComponent={!hide}>
          <div className="gh-mob-request-header uk-text-bold uk-text-left">
            LINK
            <Popup
              trigger={
                <img src={info} alt="info" className="uk-margin-small-left" style={{ width: 14 }} />
              }
              on="hover"
              keepTooltipInside={true}
            >
              {staticService.findByAlias("generateLinkAndSendViaEmail")}
            </Popup>
          </div>

          <ButtonCopyToClipboard
            textToBeCopied={linkToBeCopied}
            disabled={hide}
            style={{ display: "flex" }}
          />
        </ConditionalRendering>
      </div>
    ),
  };
};

export default returnBrokerBookingRequestListTableActionComponent;
