import React, { Component } from "react";
import PropTypes from "prop-types";
import googleApi from "../../services/google.service";
import { config } from "../../configs";
import {
  arrayCompact,
  arrayFlatten,
  distanceBetween,
  getKeyByValue,
  isObjectEmpty,
  MapComponentOld,
  parseCoordinates,
  percentageFormula,
  filterHelliPadyByCategory,
} from "../../shared";

export class PadSelectionMapComponentOld extends Component {
  constructor(props) {
    super(props);

    this.handleMapPlanDrawn = this.handleMapPlanDrawn.bind(this);
    // this.mapTypeSwitch = this.mapTypeSwitch.bind(this);

    this.state = {
      currentLegPoint: {},
      curvedDirectionLines: [],
      markers: [],
      pads: [],
      zoom: config.mapZoom.min,
      mapType: "roadmap",
      mapWidth: 0,
      // mapTypeSwitch: true
    };
  }

  componentDidMount() {
    if (this.props.requestForm) {
      if (this.props.legs.length > 0) {
        this.setState({ mapWidth: "100%" });
      } else {
        setTimeout(() => this.setState({ mapWidth: "100%" }));
      }
    } else {
      this.setState({ mapWidth: "100%" });
    }
  }

  componentWillReceiveProps({ legs, showLandingsPads: nextShowLandingsPads }) {
    const markers = this.getLandingSitesMarkers(legs, {
      currentShowLandingsPads: this.props.showLandingsPads,
      nextShowLandingsPads,
    });
    const flightPlanCoordinates = this.getFlightPlanCoordinatesFromLegs(legs);
    this.setState({ markers, flightPlanCoordinates });
    if (JSON.stringify(legs) !== JSON.stringify(this.props.legs)) {
      this.resetMarkers();
    }
  }
  // mapTypeSwitch(e) {
  //   e.preventDefault();
  //   const { mapTypeSwitch } = this.state;
  //   this.setState({ mapTypeSwitch: !mapTypeSwitch});
  //   mapTypeSwitch
  //     ? this.setState({ mapType: 'satellite' })
  //     : this.setState({ mapType: 'roadmap' })
  // }

  resetMarkers() {
    this.setState({ pads: [] });
  }

  getLandingSitesMarkers(legs = [], { currentShowLandingsPads, nextShowLandingsPads }) {
    if (nextShowLandingsPads && currentShowLandingsPads !== nextShowLandingsPads) {
      this.handleMarkerClick(parseCoordinates(this.props.legs[0].to_coords), legs[0]);
    }
    let markers = legs.map((leg, index) => {
      const duplicatedLegs = this.getLegsWithSamePoint(leg, index, legs); // displaying in InfoWindow suggestion with duplicated legs
      // if (index === 0) {
      return [
        {
          ...parseCoordinates(leg.from_coords),
          onClick: () => {
            this.handleMarkerClick(parseCoordinates(leg.from_coords), leg);
            // this.props.handleMarkerClick();
          },
          closePopUp: this.closePopups,
          name: leg.from,
          hasLegPointsDuplication: false,
          duplicates: duplicatedLegs,
          isSecondary: false,
          isInfoShown: false,
          isSelectLegInfoShown: false,
        },
        {
          ...parseCoordinates(leg.to_coords),
          onClick: () => {
            this.handleMarkerClick(parseCoordinates(leg.to_coords), leg);
            // this.props.handleMarkerClick();
          },
          closePopUp: this.closePopups,
          name: leg.to,
          hasLegPointsDuplication: false,
          duplicates: duplicatedLegs,
          isSecondary: false,
          isInfoShown: false,
          isSelectLegInfoShown: false,
        },
      ];
      // }

      // return {
      //   ...parseCoordinates(leg.to_coords),
      //   onClick: () =>
      //     this.handleMarkerClick(parseCoordinates(leg.to_coords), leg),
      //   hasLegPointsDuplication: false,
      //   duplicates: duplicatedLegs.filter((i) => i.current === "to"),
      //   isSecondary: false,
      //   isInfoShown: false,
      //   isSelectLegInfoShown: false,
      // };
    });
    return arrayFlatten(markers);
  }

  getLegsWithSamePoint(current, index, legs) {
    const duplications = legs.map((leg, i) => {
      if (current.from_coords === leg.from_coords && i === 0) {
        return { id: leg.id, current: "from", direction: "from", legIndex: i };
      }

      if (current.to_coords === leg.to_coords) {
        return { id: leg.id, current: "to", direction: "to", legIndex: i };
      }

      if (current.from_coords === leg.to_coords && index !== i + 1) {
        return { id: leg.id, current: "from", direction: "to", legIndex: i };
      }

      if (current.to_coords === leg.from_coords && index !== i - 1) {
        return { id: leg.id, current: "to", direction: "from", legIndex: i };
      }
    });

    return arrayCompact(duplications);
  }

  getFlightPlanCoordinatesFromLegs(legs = []) {
    return arrayFlatten(
      legs.map((leg, index) => {
        // if (index === 0) {
        return [parseCoordinates(leg.from_coords), parseCoordinates(leg.to_coords)];
        // }
        // return parseCoordinates(leg.to_coords);
      }),
    );
  }

  showAvailablePads({ markerCoordinates, legId, direction, legIndex }) {
    this.closePopups();
    googleApi
      .getPadsByCoordinatesAndRadiusOld(markerCoordinates)
      .then((response) => {
        const data = filterHelliPadyByCategory(config.helliPadSkipCategories, response.data);
        if (data) {
          this.setState({
            pads: this.getAvailablePadsMarkers({
              pads: data,
              legId,
              direction,
              markerCoordinates,
              legIndex,
            }),
          });
        } else {
          this.setState({ pads: [] });
        }
      })
      .catch((e) => {
        this.setState({ pads: [] });
      });
  }

  getAvailablePadsMarkers({ pads = [], legId, direction, markerCoordinates, legIndex }) {
    return arrayCompact(
      pads.map((pad) => {
        let coords = { lat: pad.location.latitude, lng: pad.location.longitude };
        const county =
          pad.location.county && pad.location.county !== "null" ? pad.location.county + ", " : "";
        const country =
          pad.location.country && pad.location.country !== "XX" ? pad.location.country : "";
        return {
          ...coords,
          onClick: () => this.togglePadMarkerInfo(pad),
          onAssignButtonClick: () =>
            this.handleAssignPad({
              coords,
              legId,
              title: pad.name,
              direction,
              markerCoordinates,
              legIndex,
              pad_id: pad.id,
              type: pad.category.display_name,
              inputAdditional: `${pad.category.display_name} ${
                county && country ? " • " + county + country : " "
              }`,
              secondary_text: `${county + country}`,
              place_id: pad.id,
            }),
          name: pad.name,
          type: pad.category.display_name,
          source: pad.view_link,
          distance: distanceBetween(markerCoordinates, coords, "N"),
          isSecondary: true,
          isInfoShown: false,
        };
      }),
    );
  }

  handleAssignPad({
    coords,
    legId: id,
    title,
    direction,
    markerCoordinates: initialCoords,
    legIndex,
    pad_id: place_id,
    type,
    inputAdditional,
    secondary_text,
  }) {
    const leg = this.props.legs.find((i) => id === i.id);

    if (!isObjectEmpty(leg)) {
      this.props.handleAssignPad({
        id,
        direction,
        coords,
        title,
        initialCoords,
        legIndex,
        place_id,
        type,
        inputAdditional,
        secondary_text,
      });
    }
    //on closing pad info window reset zoom to normal
    this.setState({ zoom: config.mapZoom.min });
  }

  togglePadMarkerInfo(padMarker) {
    this.setState((prevState) => {
      const updatedPads = prevState.pads
        .filter(
          (marker) =>
            padMarker.location.latitude !== marker.lat &&
            padMarker.location.longitude !== marker.lng,
        )
        .map((marker) => ({ ...marker, isInfoShown: false }));
      const currentPad = prevState.pads
        .filter(
          (marker) =>
            padMarker.location.latitude === marker.lat &&
            padMarker.location.longitude === marker.lng,
        )
        .map((marker) => ({ ...marker, isInfoShown: !marker.isInfoShown }));

      return {
        ...prevState,
        pads: [...currentPad, ...updatedPads],
      };
    });
  }

  handleMapPlanDrawn(curvedDirectionLines = []) {
    this.setState({ randomKey: Math.random });
    if (this.props.returnFlight) {
      this.setState({ curvedDirectionLines });
    } else {
      const lines =
        curvedDirectionLines[0] && curvedDirectionLines[0].filter((_, i) => i % 2 === 0);
      this.setState({ curvedDirectionLines: lines });
    }
  }

  handleMarkerClick(coords, leg) {
    this.closePopups();
    const markers = this.state.markers.filter((i) => i.lng === coords.lng && i.lat === coords.lat);

    if (markers.length === 1 && !this.props.review && leg && coords) {
      const direction = getKeyByValue(leg, `${coords.lat}|${coords.lng}`);

      this.showAvailablePads({
        markerCoordinates: coords,
        legId: leg.id,
        direction,
      });
    } else if (markers.length > 1) {
      this.showSelectLegWindow(coords);
    }
  }

  showSelectLegWindow({ lat, lng }) {
    this.setState((prevState) => ({
      ...prevState,
      markers: prevState.markers.map((marker) => {
        if (marker.lat === lat && marker.lng === lng) {
          return {
            ...marker,
            isSelectLegInfoShown: true,
            onLegSelected: ({ id, legIndex }) => {
              this.closePopups();
              const leg = this.props.legs.find((i) => i.id === id);
              if (leg) {
                let direction = getKeyByValue(leg, `${lat}|${lng}`);

                this.showAvailablePads({
                  markerCoordinates: parseCoordinates(leg[direction]),
                  legId: leg.id,
                  direction,
                  legIndex,
                });
              }
            },
          };
        }

        if (marker.isInfoShown) {
          return {
            ...marker,
            isInfoShown: false,
          };
        }

        return marker;
      }),
    }));
  }

  closePopups() {
    this.setState((prevState) => ({
      ...prevState,
      markers: prevState.markers.map((i) => {
        if (i.isSelectLegInfoShown) {
          return { ...i, isSelectLegInfoShown: false };
        }
        return i;
      }),
      pads: prevState.pads.map((i) => {
        return { ...i, isInfoShown: false };
      }),
    }));
  }

  get mapHeight() {
    let height = null;

    if (this.props.screenDimensions.screenH > this.props.screenDimensions.screenW) {
      height =
        this.props.screenDimensions.screenH -
        percentageFormula(this.props.screenDimensions.screenH, 50);
    }
    height =
      this.props.screenDimensions.screenW -
      percentageFormula(this.props.screenDimensions.screenW, 50);
    if (config.maxAllowedMapeHeight > height) return height;
    return config.maxAllowedMapeHeight;
  }

  render() {
    if (this.props.visible) {
      let {
        markers: mainLandingSites = [],
        pads,
        curvedDirectionLines = [],
        flightPlanCoordinates = [],
      } = this.state;
      let { classNames, visible, position } = this.props;

      const landingSites = mainLandingSites.map((item, index, arr) => {
        let itemsWithSameCoords = arr.filter((i) => i.lat === item.lat && i.lng === item.lng);

        if (itemsWithSameCoords.length > 1) {
          let currentsIndex = arr.indexOf(itemsWithSameCoords[0]);
          if (index === currentsIndex) {
            return {
              ...item,
              duplicates: item.duplicates.filter((i) =>
                itemsWithSameCoords[0].duplicates.includes(i),
              ),
            };
          }
          return null;
        }
        return item;
      });

      const markers = [...arrayCompact(landingSites), ...pads];

      return (
        <div className={classNames}>
          {/*<button*/}
          {/*  className="uk-button uk-button-small uk-button-default gh-map-type"*/}
          {/*  onClick={e => this.mapTypeSwitch(e)}>*/}
          {/*  {this.state.mapTypeSwitch ? 'satellite' : 'roadmap'}*/}
          {/*</button>*/}
          <MapComponentOld
            id={this.props.id}
            containerElement={<div style={{ height: this.mapHeight + `px` }} />}
            mapElement={<div style={{ width: this.state.mapWidth, height: `100%` }} />}
            zoom={this.state.zoom}
            markers={markers}
            visible={visible}
            position={position}
            flightPlanCoordinates={[flightPlanCoordinates]}
            curvedDirectionLines={curvedDirectionLines}
            onDirectionsDrawn={this.handleMapPlanDrawn}
            mapType={this.state.mapType}
          />
        </div>
      );
    }
    return null;
  }
}

PadSelectionMapComponentOld.propTypes = {
  visible: PropTypes.bool,
  onLegUpdated: PropTypes.func,
  legs: PropTypes.array,
  classNames: PropTypes.string,
  position: PropTypes.object,
  screenDimensions: PropTypes.any,
  updateMap: PropTypes.number,
};
