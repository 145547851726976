import React, { Component } from "react";
import PropTypes from "prop-types";
import { prepareQueryStringFromObj } from "../../../../shared";
import GetHeliEvents from "../../../../services/event.service";

class BaseFilterComponent extends Component {
  constructor(props, modelName) {
    super(props);
    this.state = {
      modelName: modelName,
      loading: "",
      reset: "",
      city: true,
    };
    this.submit = this.submit.bind(this);
    this.action = this.action.bind(this);
  }

  shouldComponentUpdate() {
    return true;
  }

  get Model() {
    return {
      from: "",
      to: "",
      to_info: "",
      from_info: "",
      departure_time: "",
      disableBtn: false,
      refreshCollection: false,
      activity: "all",
    };
  }

  handleFormSubmit(type = "confirmed", model, count = 0) {
    this.setState({
      loading: " show",
      count: +1,
    });
    this.submit(type, model);
  }

  incrementCount(count) {
    this.setState({ count });
  }

  submit(type, model) {
    this.props.updateModel(this.state.modelName + ".disableBtn", true);
    const filters = prepareQueryStringFromObj({ ...model });
    GetHeliEvents.trigger("filter-triggered", { model: this.state.modelName, filters });
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
    this.action(type, filters, this.props.pageFilters);
  }

  action(type = "", filters = [], pageFilters = []) {
    const own = "own";
    const all = "all";

    this.props.get(1, filters.concat(pageFilters), type).then((items) => {
      this.props.updateModel(this.state.modelName + ".disableBtn", false);
      this.props.updateModel(this.state.modelName + ".refreshCollection", true);
      this.props.update(items);
      this.setState({
        loading: "",
        reset: "",
        activityLeg: this.state.activityLeg === own ? all : own,
      });
    });
  }

  reset() {
    if (this.Model) {
      this.props.resetModel(this.state.modelName);
      GetHeliEvents.trigger("reset-filter-triggered", { model: this.state.modelName });
      this.setState({ reset: " show" });
      if (this.props.onReset) {
        this.props.onReset();
      }
      this.action();
    }
  }

  updateLocation(model, location) {
    this.props.updateModel(model, location);
  }

  // get isSubmitEnabled() {
  //   return (this.props.disableBtn || (this.props.from.length === 0
  //           && this.props.to.length === 0 && this.props.departure_time === null)) ? true : false;
  // }

  get isSubmitEnabledLeg() {
    return this.props.disableBtn ||
      (this.props.from.length === 0 &&
        this.props.activity !== this.state.activityLeg &&
        this.props.to.length === 0 &&
        this.props.departure_time === null)
      ? true
      : false;
  }

  render() {
    return null;
  }
}

BaseFilterComponent.defaultProps = {
  pageFilters: [],
};

BaseFilterComponent.propTypes = {
  pageFilters: PropTypes.array,
  visible: PropTypes.bool,
  path: PropTypes.string,
  queryParams: PropTypes.object,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};

export default BaseFilterComponent;
