import AbstractService from "../../../services/abstract.service";

class AlertService extends AbstractService {
  constructor() {
    super();
    this.messageProp = "";
    this.typeProp = "success";
    this.eventInstance = null;
    this.successProp = "success";
    this.errorProp = "danger";
  }

  get message() {
    return this.messageProp;
  }

  get type() {
    return this.typeProp;
  }

  success(info = {}) {
    if (!this.disabled) {
      this.show();
    }
  }

  show(message = {}, type = "danger") {
    this.setProp("typeProp", type);
    this.setProp("messageProp", this.getMessage(message));
    super.show();
  }

  getMessage(message) {
    if (typeof message === "string" || message instanceof String) {
      return message;
    } else {
      const _keys = Object.keys(message);
      return message[_keys[0]];
    }
  }

  resetProps() {
    this.setProp("messageProp", "");
    this.setProp("typeProp", "success");
  }
}

const alertInstance = new AlertService();
export { alertInstance, AlertService };
