import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  sendDeclineReasons,
  startQuote,
  updateQuoteDetail,
  toggleRequestFlagStatus,
  getQuoteById,
  updateEditedQuote,
  setDefaultRequestsData,
  needMoreInformation,
} from "../../../../../actions";
import RequestDeclineAction from "./RequestDeclineAction";
import RequestLaterAction from "./RequestLaterAction";
import RequestStartAction from "./RequestStartAction";
import RequestMoreInformationAction from "./RequestMoreInformationAction";
import clsx from "clsx";

import "./RequestDetailsFooter.styles.css";

export const RequestDetailsFooterContext = React.createContext({});

function RequestDetailsFooter(props) {
  const {
    quoteDetail: { editingQuoteDetail, originalQuoteDetail },
    declineReasons,
    sendDeclineReasons,
    setShowPrompt,
    history,
    startQuote,
    updateQuoteDetail,
    toggleRequestFlagStatus,
    getQuoteById,
    updateEditedQuote,
    defaultRequests,
    setDefaultRequestsData,
    needMoreInformation,
    setQuoteDetailNew,
  } = props;

  const showAdditionalButtons = [
    parseInt(originalQuoteDetail.quotes_per_company) === 0,
    !originalQuoteDetail.is_declined,
    !originalQuoteDetail.empty_leg_id,
  ].every(Boolean);

  return (
    <RequestDetailsFooterContext.Provider
      value={{
        editingQuoteDetail,
        originalQuoteDetail,
        declineReasons,
        sendDeclineReasons,
        setShowPrompt,
        history,
        startQuote,
        updateQuoteDetail,
        toggleRequestFlagStatus,
        getQuoteById,
        updateEditedQuote,
        defaultRequests,
        setDefaultRequestsData,
        needMoreInformation,
        setQuoteDetailNew,
        showAdditionalButtons,
      }}
    >
      <div className={clsx("gh-request-details-buttons", { "--sticky": showAdditionalButtons })}>
        <RequestDeclineAction />
        <RequestLaterAction />
        <RequestMoreInformationAction />
        <RequestStartAction />
      </div>
    </RequestDetailsFooterContext.Provider>
  );
}

RequestDetailsFooter.propTypes = {
  quoteDetail: PropTypes.shape({
    editingQuoteDetail: PropTypes.object,
    originalQuoteDetail: PropTypes.object,
  }),
  declineReasons: PropTypes.array,
  sendDeclineReasons: PropTypes.func,
  setShowPrompt: PropTypes.func,
  history: PropTypes.object,
  startQuote: PropTypes.func,
  updateQuoteDetail: PropTypes.func,
  toggleRequestFlagStatus: PropTypes.func,
  getQuoteById: PropTypes.func,
  updateEditedQuote: PropTypes.func,
  defaultRequests: PropTypes.object,
  setDefaultRequestsData: PropTypes.func,
  needMoreInformation: PropTypes.func,
  setQuoteDetailNew: PropTypes.func,
};

const mapStateToProps = (store) => ({
  quoteDetail: store.setQuoteDetail,
  declineReasons: (store.requests.reasons || []).map((item) => ({ value: item, label: item })),
  defaultRequests: store.defaultRequests,
});

export default connect(mapStateToProps, {
  sendDeclineReasons,
  startQuote,
  updateQuoteDetail,
  toggleRequestFlagStatus,
  getQuoteById,
  updateEditedQuote,
  setDefaultRequestsData,
  needMoreInformation,
})(RequestDetailsFooter);
