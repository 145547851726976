import PropTypes from "prop-types";
import React from "react";
import csgo from "../../../../utils/condString";

const Input = React.forwardRef((props, ref) => {
  const { className, disabled, name, value, placeholder, onChange, pattern, type, ...rest } = props;

  const onChangeHanlder = (e) => {
    const value = e.target.value;

    if (pattern) onChange(pattern(value));
    else onChange(e);
  };

  return (
    <input
      id={name}
      name={name}
      value={value || ""}
      ref={ref}
      placeholder={placeholder}
      className={csgo(className, "uk-input")}
      disabled={disabled}
      onChange={onChangeHanlder}
      type={type}
      {...rest}
    />
  );
});

Input.displayName = "Input";

Input.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.func,
};

Input.defaultProps = {
  size: "md",
  value: "",
  type: "text",
};

export default Input;
