import React from "react";
import PropTypes from "prop-types";
import { BlockComponent } from "./";

export const SectionBlockComponent = ({
  visible = true,
  showTitle = true,
  title = "Block title",
  classNames = ["uk-accordion-content", "gh-accordion-content", "uk-margin-remove"],
  sectionClassName,
  children,
}) => (
  <BlockComponent visible={visible} parentProps={{ className: "uk-padding-remove-top" }}>
    <ul className="gh-accordion disabled-list">
      <li className="gh-accordion-section uk-open">
        {showTitle ? (
          <span className={`uk-accordion-title gh-accordion-title ${sectionClassName}`}>
            {title}
          </span>
        ) : (
          ""
        )}
        <div className={classNames.join(" ")}>{children}</div>
      </li>
    </ul>
  </BlockComponent>
);

SectionBlockComponent.propTypes = {
  showTitle: PropTypes.bool,
  visible: PropTypes.bool,
  title: PropTypes.string,
  classNames: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  sectionClassName: PropTypes.string,
};

SectionBlockComponent.defaultProps = {
  sectionClassName: "",
};
