import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AccordionComponent } from "../../../../shared";
import { bankAccountTabs } from "../../../../configs";
import { CLEAR_ERRORS, pushAccountStatus } from "../../../../actions";
import {
  BankAccountFormComponent,
  AccountFormComponent,
  AccountUploadDocumentComponent,
} from "../../../../components";

class BankAccountComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // selectedTab: bankAccountTabs[0],
      bank_account_detail: false,
      document: false,
      account_detail: false,
    };
    this.getActive = this.getActive.bind(this);
  }

  // onTabClick(tab, ev) {
  //   ev.preventDefault();
  //   this.setState({selectedTab: tab});
  // }

  getTabContent(alias) {
    switch (alias) {
      case "account_detail":
        return <AccountFormComponent visible={true} />;
      case "bank_account_detail":
        return <BankAccountFormComponent visible={true} />;
      // case 'document':
      //   return <AccountUploadDocumentComponent visible={this.state.document} />;
    }
  }

  getAccordionItems() {
    return bankAccountTabs.map((nav) => {
      return {
        title: nav.title,
        active: nav.active,
        content: this.getTabContent(nav.alias),
        onAccordionShow: () => {
          this.setState((prev) => {
            prev[nav.alias] = true;
            return { ...prev };
          });
        },
        onAccordionHide: () => {
          this.setState((prev) => {
            prev[nav.alias] = false;
            return { ...prev };
          });
        },
      };
    });
  }

  getActive(loopEl) {
    if (loopEl.alias === this.state.selectedTab.alias) {
      return " uk-active";
    }
    return " ";
  }

  render() {
    return (
      <section className={"team-page uk-margin"}>
        <div className="uk-padding-large gh-mob-padding">
          <AccordionComponent
            accordionId={"just"}
            items={this.getAccordionItems()}
            options={{ collapsible: false, multiple: true }}
            wrapperClassName={["stripe-accordion"]}
          />
        </div>
      </section>
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: CLEAR_ERRORS });
    this.context.store.dispatch(pushAccountStatus());
  }
}

BankAccountComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ accountDetail }) {
  return { accountDetail };
}

const COMPONENT = connect(mapStateToProps)(BankAccountComponent);
export { COMPONENT as BankAccountComponent };
