import React from "react";
import "./InfoBox.css";
import InfoLight from "../../../assets/img/svg/info_light.svg";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";

const InfoBox = ({ alias, className }) => {
  return (
    <div className={`gh-can-not-estimate-price-wrapper ${className && className}`}>
      <img src={InfoLight} alt="info icon" className="gh-can-not-estimate-price-icon" />
      <p
        className="gh-can-not-estimate-price-text"
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias(alias) }}
      />
    </div>
  );
};

InfoBox.propTypes = {
  alias: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default InfoBox;
