import React from "react";
import PropTypes from "prop-types";

const ConditionalRendering = ({ showComponent, content, children }) => {
  if (!showComponent) return "";
  return <React.Fragment>{children ? children : content}</React.Fragment>;
};
ConditionalRendering.propTypes = {
  showComponent: PropTypes.bool.isRequired,
  children: PropTypes.any,
  content: PropTypes.element,
};
ConditionalRendering.defaultProps = {
  children: null,
  content: null,
};
export default ConditionalRendering;
