import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  explode,
  redirectTo,
  renderTime,
  replaceAttributesInString,
  skipRouteSegments,
  utcTime,
} from "../../../../../shared";
import { BlockComponent, ConfirmComponent } from "../../../../common";
import { config, QUOTE_OPEN } from "../../../../../configs";
import {
  cleanQuoteDetails,
  doPayment,
  getBrokerRequestDetailSuccess,
  getCards,
  getQuote,
  RESET_CARD_UPDATE,
  RESET_PAYMENT,
  setDefaultCard,
  updateQuoteBooking,
} from "../../../../../actions";
import { WalletComponent } from "./";
import staticService from "../../../../../services/static.service";
import { BrokerRequestNav } from "../broker-request-nav.component";
import AccordionComponent from "../../../../common/AccordionComponent";
import SiteComponent from "../../../../common/SiteComponent";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";
import InfoContainerWithIcon from "../../../../common/InfoContainerWithIcon";
import Auth from "../../../../../services/auth";

class BrokerPaymentBillingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backRoute: skipRouteSegments(this.props.path, 6),
      modalVisibility: false,
      consentModalVisibility: false,
      redirectPath: false,
      removeIndex: false,
      quote: {
        price: 0,
      },
      activeTab: 0,
      paymentModel: {
        quote_id: null,
        changedCard: 0,
      },
      messageError: "",
      card: null,
      isCardDefaultChanged: false,
      showConfirm: false,
      moment: moment().add(config.stripeReleaseInterval, "days"),
      isLoading: false,
      payOnlyDeposit: false,
      paymentDetails: {},
    };

    this._mounted = true;

    this.handleUpdateState = this.handleUpdateState.bind(this);
  }

  handleUpdateState(key, value) {
    this.setState({ [key]: value });
  }

  get defaultCard() {
    return this.state.card !== null
      ? this.state.card
      : this.props.cards.reduce((prev, next) => {
          if (next.default) return next;
          return prev;
        }, {});
  }

  get departurePlaceholder() {
    if (this.props.quoteRequest && this.props.quoteRequest.departure) {
      if (this.state.moment.isSameOrBefore(this.props.quoteRequest.departure)) {
        return this.state.moment.toISOString();
      } else {
        return moment(this.props.quoteRequest.departure).add(1, "days").toISOString();
      }
    }
    return null;
  }

  get info() {
    return (
      <div>
        <span
          style={{ display: "block", marginBottom: 25 }}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("onSuccessPaymentPost"),
          }}
        />
        <div className="gh-modal-footer uk-text-center">
          <NavLink
            className={"uk-button uk-margin-small-top uk-button-primary"}
            to={"/flights/requests/filter/pending"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("continue"),
            }}
          />
        </div>
      </div>
    );
  }

  get error() {
    const { computedMatch } = this.props;
    return (
      <div>
        <div className="gh-padding-small-bottom">{this.state.messageError}</div>
        <div className="gh-modal-footer uk-text-center">
          <NavLink
            className={"uk-button uk-margin-small-top uk-button-primary"}
            to={computedMatch.url}
            // to={'/flights/requests/filter/review'}
            onClick={() => this.closeErrorDialog()}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("continue"),
            }}
          />
        </div>
      </div>
    );
  }

  get cards() {
    const cards = this.props.cards ? this.props.cards : [];
    const { payOnlyDeposit, paymentDetails } = this.state;

    return (
      <WalletComponent
        cards={cards}
        selectedCard={this.state.card}
        onChange={this.activeCard.bind(this)}
        quote={this.props.quote}
        save={this.openConsentDialog.bind(this)}
        payOnlyDeposit={payOnlyDeposit}
        paymentDetails={paymentDetails}
        handleUpdateState={this.handleUpdateState}
      />
    );
  }

  get isAllowed() {
    return !!(
      this.props.quoteRequest &&
      this.props.quoteRequest.status !== "expired" &&
      this.props.quoteRequest.status !== "closed" &&
      this.props.quoteRequest.status !== "withdrawn"
    );
  }

  componentDidMount() {
    if (this._mounted) {
      this.setState({
        paymentModel: {
          quote_id: this.props.parentProps.match.params.quoteID,
          changedCard: 0,
        },
      });
      if (!this.props.quote.id) {
        this.props.getQuote(this.props.parentProps.match.params.quoteID);
      }
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this._mounted) {
      if (nextProps.payment.event) {
        this.setState({ modalVisibility: true });
      }
      if (
        this._mounted &&
        this.props.quote !== nextProps.quote &&
        nextProps.quote.status !== QUOTE_OPEN
      ) {
        this.setState({ redirectPath: "/flights/requests" });
      }
    }
    if (nextProps && nextProps.cards.length && !this.state.card) {
      const selectedCard = nextProps.cards.find((card) => card.selected);

      if (selectedCard) {
        this.setState({ card: selectedCard });
      } else {
        this.setState({ card: { card_id: "manual" } });
      }
    }
  }

  openConsentDialog() {
    this.consentModal.openModal();
  }

  closeConsentDialog() {
    this.consentModal.closeModal();
    this.props.resetReduxAction();
  }

  confirmBooking() {
    const { payOnlyDeposit, paymentDetails } = this.state;
    let paymentModel = {};
    this.setState({ isLoading: true });

    if (this.state.card.card_id === "manual") {
      paymentModel = {
        quote_id: this.props.parentProps.match.params.quoteID,
        changedCard: 0,
        payment_method: 2, //this will mean it is manual_bank_transfer
        payment_date: explode(this.departurePlaceholder, "/", "-"),
        pay_only_deposit: payOnlyDeposit,
        secure_with_deposit: paymentDetails.secure_with_deposit || null,
        deposit_amount: paymentDetails.deposit_amount || null,
      };
    } else {
      paymentModel = {
        quote_id: this.props.parentProps.match.params.quoteID,
        changedCard: 0,
        card: this.defaultCard.card_id,
        payment_method: 1,
        card_type: this.props.quote.card_type,
        payment_date: explode(this.departurePlaceholder, "/", "-"),
        pay_only_deposit: payOnlyDeposit,
        secure_with_deposit: paymentDetails.secure_with_deposit || null,
        deposit_amount: paymentDetails.deposit_amount || null,
      };
    }

    this.props.finishPayment(paymentModel, this.onSuccess.bind(this), this.onError.bind(this));
  }

  onSuccess() {
    this.successModal.openModal();
    this.props.resetReduxAction(RESET_PAYMENT);
    this.setState({ isLoading: false });
  }

  onError(err) {
    let errorMessage = err;
    this.setState({
      messageError: errorMessage,
    });
    this.errorModal.openModal();
    this.props.resetReduxAction(RESET_PAYMENT);
    this.setState({ isLoading: false });
  }

  closeErrorDialog() {
    this.errorModal.closeModal();
    this.props.resetReduxAction(RESET_PAYMENT);
    location.reload();
  }

  getInfo(tag) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: replaceAttributesInString(
            { brokerconfirmation_date: moment(this.departurePlaceholder).format(config.dobFormat) },
            staticService.findByAlias(tag),
          ),
        }}
      />
    );
  }

  activeCard(card) {
    const { updateQuoteBooking } = this.props;

    if (card && card.card_id === "manual") {
      updateQuoteBooking({ transaction_fee: 0 });
    }

    this.setState({ card });
  }

  render() {
    const {
      match,
      quoteRequest,
      quote,
      quote: { helicopter_services_terms, terms_status },
    } = this.props;
    const { card } = this.state;

    if (this.state.redirectPath) return redirectTo(this.state.redirectPath);

    const displayTerms =
      (quote.terms_status && quote.terms_status.country_terms_list.helicopter_services_terms) ||
      (quote.helicopter_services_terms && quote.helicopter_services_terms.length);

    const helicopterServicesTerms = terms_status?.country_terms_list?.helicopter_services_terms
      ? "backToTC"
      : "";
    const flightContract =
      helicopter_services_terms && helicopter_services_terms.length ? "backToFlightContract" : "";

    const rowVars = {
      aircrafts: "helicopter",
      from_name: "takeOffSite",
      to_name: "landigSitesTitleBroker",
      date: "dateOfDeparture",
      departure_time: "timeOfDeparture",
      time_max: "estimateFlightDuration",
      estimated_distance: "estimatedDistanceLeg",
    };

    const LegInfoRow = ({
      rowName,
      rowValue,
      from_source,
      from_location,
      to_location,
      to_source,
      is_refueling,
      estimated_distance,
    }) => {
      if (!rowName) return "";
      const nauticalMilesString = (val) => {
        if (+val > 1) {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("nauticalMilesLeg"),
              }}
            />
          );
        } else {
          const string = staticService.findByAlias("nauticalMilesLeg");
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: string.substring(0, string.length - 1),
              }}
            />
          );
        }
      };

      const modifiedValues = () => {
        if (rowName === "from_name") {
          return (
            <div className="gh-quote-details-flight-row-item gh-quote-details-column">
              <SiteComponent
                icon={from_source}
                name={from_location && from_location.name}
                secondaryText={generateLocationAdditionalInfo(from_location)}
                className="gh-quote-details-flight-site-component gh-payment-leg-row"
              />
              {from_location.details ? (
                <InfoContainerWithIcon
                  title={staticService.findByAlias("additionalDetailsAboutLocation")}
                  text={from_location.details}
                  className="gh-quote-details-info-container"
                />
              ) : null}
            </div>
          );
        }
        if (rowName === "to_name") {
          return (
            <div className="gh-quote-details-flight-row-item gh-quote-details-column">
              <SiteComponent
                icon={to_source}
                name={to_location && to_location.name}
                secondaryText={generateLocationAdditionalInfo(to_location)}
                className="gh-quote-details-flight-site-component gh-payment-leg-row"
              />
              {to_location.details ? (
                <InfoContainerWithIcon
                  title={staticService.findByAlias("additionalDetailsAboutLocation")}
                  text={to_location.details}
                  className="gh-quote-details-info-container"
                />
              ) : null}
            </div>
          );
        }
        if (rowName === "aircrafts") {
          if (rowValue.length === 1)
            return rowValue[0].manufacturer + " " + rowValue[0].aircraft_model;
          return rowValue.map((heli, idx) =>
            idx !== rowValue.length - 1
              ? heli.manufacturer + " " + heli.aircraft_model + ", "
              : heli.manufacturer + " " + heli.aircraft_model,
          );
        }
        if (rowName === "time_max") {
          return renderTime(rowValue, is_refueling);
        }
        if (rowName === "departure_time") {
          return moment(rowValue).format("HH:mm") + utcTime();
        }
        if (rowName === "date") {
          return moment(rowValue).format(config.dateFormat);
        }
        if (rowName === "estimated_distance") {
          return (
            <span>
              <span style={{ paddingRight: 5 }}>{estimated_distance}</span>
              {nauticalMilesString(estimated_distance)}
            </span>
          );
        }
      };
      return (
        <div style={{ marginBottom: 15 }}>
          <div className="gh-row-info-title">{staticService.findByAlias(rowVars[rowName])}</div>
          <div className="gh-row-info-text">{modifiedValues()}</div>
        </div>
      );
    };
    const displayOperatorName =
      Auth.hasFeature("remove_anonymity_for_brokers") && quote.operator_company;
    const OperatorName = () => (
      <div
        style={{
          marginBottom: 15,
          backgroundColor: "#ffffff",
          borderRadius: 4,
          padding: "15px 25px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="gh-row-info-title uk-margin-remove">
            {staticService.findByAlias("operator")}
          </span>
          <span className="gh-row-info-text">{quote.operator_company.name}</span>
        </div>
      </div>
    );

    return (
      <div className="gh-padding-bottom-50">
        <BrokerRequestNav
          match={match}
          isAllowed={this.isAllowed}
          requestDetail={quoteRequest}
          backButton={staticService.findByAlias(
            helicopterServicesTerms || flightContract || "backToQuote",
          )}
          backPath={
            displayTerms
              ? this.state.backRoute + "/step/2"
              : this.state.backRoute.replace("/payment/", "/quote/")
          }
          history={this.props.history}
        />
        <BlockComponent visible={true} parentProps={{ className: "" }}>
          <ConfirmComponent
            ref={(consentModal) => (this.consentModal = consentModal)}
            info={
              card && card.card_id === "manual"
                ? this.getInfo("paymentProvisionalMessage")
                : this.getInfo("paymentConsentMessage")
            }
            onSelect={this.confirmBooking.bind(this)}
            onReject={this.closeConsentDialog.bind(this)}
            buttonsProps={{
              continue: {
                title: staticService.findByAlias("paymentAgree"),
                css: "uk-button-primary",
              },
              cancel: {
                title: staticService.findByAlias("cancel"),
                css: "uk-button-default",
              },
            }}
            showCloseBtn={true}
            modalProps={{
              id: "consent-agree",
              title:
                card && card.card_id === "manual"
                  ? staticService.findByAlias("requestProvisionalBookingConfirmation")
                  : staticService.findByAlias("requestBookingConfirmation"),
              options: { clsPage: "", bgClose: false, escClose: false },
            }}
            continueDisabled={this.state.isLoading}
          />
          <ConfirmComponent
            ref={(successModal) => (this.successModal = successModal)}
            info={this.info}
            buttonsProps={{}}
            modalProps={{
              id: "confirm-billing-request",
              title: staticService.findByAlias("modalBookingPaymentTitle"),
              options: { clsPage: "", bgClose: false, escClose: false },
            }}
          />
          <ConfirmComponent
            ref={(onError) => (this.errorModal = onError)}
            info={this.error}
            buttonsProps={{}}
            showCloseBtn={true}
            onReject={this.closeErrorDialog.bind(this)}
            modalProps={{
              // id: 'confirm-billing-request',
              title: staticService.findByAlias("bankAuthenticationFailed"),
              options: { clsPage: "", bgClose: false, escClose: false },
            }}
          />

          <div
            data-uk-grid
            className="uk-flex"
            style={{ flexDirection: window.innerWidth > 576 ? "row" : "column-reverse" }}
          >
            <div className="uk-width-2-3@l uk-width-1-2@m uk-width-1-1@s">
              <div className="uk-position-relative">
                <h3
                  className="gh-section-title-large"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("PaymentDetailsPageTitle"),
                  }}
                />
                {this.cards}
              </div>
            </div>
            <div className="uk-width-1-3@l uk-width-1-2@m uk-width-1-1@s">
              <div className="uk-margin-bottom gh-payment-legs">
                <h3
                  className="gh-section-title-large"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("TripSummaryTitle"),
                  }}
                />
                {displayOperatorName ? <OperatorName /> : ""}
                {Object.keys(quote).length ? (
                  <AccordionComponent
                    items={quote.pads}
                    isOpen={true}
                    flightType={quoteRequest.flight_type}
                  >
                    {quote.pads.map((pad, idx) => (
                      <div key={pad.date + idx}>
                        {Object.keys(rowVars).map((el, nameIdx) => (
                          <LegInfoRow key={nameIdx} rowName={el} rowValue={pad[el]} {...pad} />
                        ))}
                      </div>
                    ))}
                  </AccordionComponent>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </BlockComponent>
      </div>
    );
  }

  componentWillUnmount() {
    this._mounted = false;
    this.props.resetReduxAction(RESET_PAYMENT);
    this.props.cleanQuoteDetails();
  }
}

BrokerPaymentBillingComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerPaymentBillingComponent.propTypes = {
  parentProps: PropTypes.object,
  updateQuoteBooking: PropTypes.func,
  match: PropTypes.object,
  quoteRequest: PropTypes.object,
  quote: PropTypes.object,
  cards: PropTypes.array,
  resetReduxAction: PropTypes.func,
  cleanQuoteDetails: PropTypes.func,
};

function mapStateToProps(state) {
  const response = {
    quoteRequest: state.requestDetail,
    quote: state.quoteDetail,
    paymentModel: state.paymentModel,
    payment: state.finalizedPayment,
    cardInfo: {
      total: 0,
    },
    cardUpdated: {
      event: false,
    },
    ...state.cards,
  };

  if (state.cardUpdated.event) {
    response.cardUpdated = state.cardUpdated;
  }

  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerDetails: getCards,
      setDefaultCard: setDefaultCard,
      finishPayment: doPayment,
      resetReduxAction: (action = RESET_CARD_UPDATE) => dispatch({ type: action }),
      getBrokerRequestDetailSuccess,
      getQuote,
      cleanQuoteDetails,
      updateQuoteBooking,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerPaymentBillingComponent);
export { COMPONENT as BrokerPaymentBillingComponent };
