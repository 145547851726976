import React from "react";
import { Item, List } from "../../../../../shared/list/index";

export const ActivityFeedList = ({ activityFeed = [] }) => {
  const activityItems = activityFeed.map((item, i) => {
    const date = item.created_at;
    const fromToTitle = item.title.split("->");

    return (
      <Item key={i}>
        <div
          className={`uk-background-default uk-padding-small ${
            i > 0 ? "uk-margin-small-top" : ""
          } uk-border-rounded`}
        >
          <div data-uk-grid className="uk-flex uk-flex-middle">
            <div className="uk-width-expand">
              <div className="gh-text-secondary">
                {fromToTitle[0]} <span data-uk-icon="icon: arrow-right;"></span> {fromToTitle[1]}
              </div>
              <div className="uk-text-uppercase">
                <small>{date}</small>
              </div>
            </div>
            <div className="uk-width-auto">
              <div className={`uk-text-uppercase ${getClassNameByStatus(item.action)}`}>
                <small>{item.action_title}</small>
              </div>
            </div>
          </div>
        </div>
      </Item>
    );
  });

  return <List attrProps={{ className: "disabled-list" }}>{activityItems}</List>;
};

const getClassNameByStatus = (status) => {
  switch (status) {
    case "booking_created":
      return "gh-text-primary";
    case "requested":
      return "gh-text-quaternary";
    default:
      return "gh-text-tertiary";
  }
};
