import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { CustomValidateError, IconComponent, storage, updateModel } from "../../../shared";
import { validateInvitations } from "../../../actions";
import { BaseRegisterFormTemplate } from "./base-form.component";
import staticService from "../../../services/static.service";
import { InvitationFormElementComponent } from "./invitation-form-element.component";
import * as types from "../../../actions/actionTypes";
import { config, validationMessages, validationRules } from "../../../configs";
import { showNotification } from "../../../utils/showNotification";

const {
  validEmail,
  validEmailDomainSignUp,
  validEmailDomain,
  validateInviteEmail,
  duplicateEmail,
  invalidEmailDomainSignUp,
  invalidEmailDomain,
} = validationRules;

class InvitationFormComponent extends BaseRegisterFormTemplate {
  constructor(props) {
    super(props);
    this.state = {
      invitations: [],
      modelName: "invitationsModel",
      mainTitle: staticService.findByAlias("invitationSendInvitation"),
      subTitle: staticService.findByAlias("invitationTeamInvitation"),
      step: props.step,
    };
    this.add = this.add.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.skip = this.skip.bind(this);
    this.remove = this.remove.bind(this);
    this.back = this.back.bind(this);
    this.handleValidationSuccess = this.handleValidationSuccess.bind(this);
    this.handleValidationErrors = this.handleValidationErrors.bind(this);
  }

  add() {
    this.context.store.dispatch(actions.resetValidity(this.state.modelName));
    if (
      this.props.tempForm.valid &&
      !duplicateEmail(this.props.invitationsModel, this.props.invitationTempModal) &&
      this.props.invitationTempModal.length
    ) {
      this.context.store.dispatch(
        actions.push(this.state.modelName, this.props.invitationTempModal),
      );
      this.context.store.dispatch(actions.reset("invitationTempModal"));
    }
  }

  handleEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      return this.add();
    }
  }

  clearTemp() {
    this.context.store.dispatch(actions.reset("invitationTempModal"));
  }

  remove(index, collection) {
    this.context.store.dispatch(actions.resetValidity(this.state.modelName));
    collection.forEach((email, i) => {
      const modelName = this.state.modelName + `[${i}]`;
      this.context.store.dispatch(actions.resetValidity(modelName));
      this.context.store.dispatch(actions.change(modelName, email));
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.context.store.dispatch(actions.setPending(this.state.modelName, false));
    }
  }

  handleValidationSuccess() {
    storage.add(this.state.modelName, JSON.stringify(this.props.invitationsModel));
    this.context.store.dispatch(
      actions.change("userRegisterModel.invitations", this.props.invitationsModel),
    );
    this.props.changeView();
  }

  handleValidationErrors(err) {
    const errors = err?.data;
    if (!Object.values(errors)?.length) return;
    showNotification("This email address belongs to a Get Heli user", "danger");
  }

  onSubmit(invitations) {
    this.context.store.dispatch(
      validateInvitations(invitations, this.handleValidationSuccess, this.handleValidationErrors),
    );
  }

  getRemoveIcon(index, removeFunc) {
    return (
      <div className="uk-width-1-4 uk-text-center">
        <a className="uk-link-text" onClick={removeFunc.bind(null, index)}>
          <IconComponent visible={true} className={"close"} icon={"close"} id={"close" + index} />
        </a>
      </div>
    );
  }

  back() {
    this.context.store.dispatch({
      type: types.ACCOUNT_REMOTE_VALIDATION,
      user: { user_validation: 0 },
    });
    this.context.store.dispatch(actions.change("userRegisterModel.tac_accepted", false));
    return this.props.back();
  }

  get validateValidationList() {
    const list =
      this.props.invitationsModel.length &&
      this.props.invitationsModel.filter((email) => email !== "");
    return list.length > 0;
  }

  get enabled() {
    return (
      this.props.invitationsModel.length > 0 &&
      this.validateValidationList &&
      this.props.form.$form.valid
    );
  }

  get subTitleDesktop() {
    return (
      <ul className={"disabled-list gh-form-nav gh-uk-tab"} data-uk-tab>
        {this.fillExtraSpaces}

        <li className="uk-active uk-width-1-4@s uk-width-1-4@m">
          <a href="#" className="uk-text-left">
            {this.state.subTitle}
          </a>
        </li>
      </ul>
    );
  }

  get subTitle() {
    return (
      <div>
        <div className={"uk-hidden@m"}>{this.subTitleMobile}</div>
        <div className={"uk-visible@m"}>{this.subTitleDesktop}</div>
      </div>
    );
  }

  getContinueButtonTemplate(options = {}) {
    return (
      <Control.button
        type={"submit"}
        model={this.state.modelName}
        disabled={(field) => !this.enabled}
        className={"uk-button uk-button-primary uk-align-center uk-align-right@m"}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("continue"),
          }}
        />
      </Control.button>
    );
  }

  getSkipButtonTemplate() {
    return (
      <Control.button
        type={"button"}
        disabled={this.props.invitationsModel.length}
        model={this.state.modelName}
        className="uk-button uk-button-text uk-align-center"
        onClick={this.skip}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("skipStep"),
          }}
        />
      </Control.button>
    );
  }

  get formContent() {
    return (
      <div>
        <Form model={this.state.modelName} onSubmit={this.onSubmit.bind(this)}>
          <ul className="uk-margin disabled-list uk-margin-top uk-margin-bottom">
            {this.props.invitationsModel.length
              ? this.props.invitationsModel.map((member, index) => {
                  return (
                    <li
                      key={index}
                      className="uk-width-1-1 uk-width-1-2@s gh-padding-remove-left gh-file-padding"
                      // style={{
                      //   paddingLeft: index === 0 && 0,
                      //   paddingRight: index % 4 === 0 && 0,
                      // }}
                    >
                      <InvitationFormElementComponent
                        visible={true}
                        remove={this.remove}
                        index={index}
                        member={member}
                      />
                    </li>
                  );
                })
              : ""}
          </ul>
          <div className="uk-text-center uk-padding-remove uk-flex uk-flex-center">
            <div className="gh-add-member-container">
              <div className="uk-position-relative">
                <Control.text
                  model="invitationTempModal"
                  changeAction={updateModel}
                  updateOn="change"
                  onKeyDown={this.handleEnter}
                  placeholder={config.invitationPlaceholder}
                  className="uk-input gh-member-mail"
                  validators={{
                    validEmail,
                    invalidEmailDomain: this.props.userRegisterModel.company.sole_trader
                      ? null
                      : this.props.userType === "operator"
                      ? invalidEmailDomainSignUp
                      : invalidEmailDomain,
                    validEmailDomain: this.props.userRegisterModel.company.sole_trader
                      ? null
                      : this.props.userType === "operator"
                      ? validEmailDomainSignUp.bind(null, this.props.email)
                      : validEmailDomain.bind(null, this.props.email),
                    validateInviteEmail: validateInviteEmail(this.props.email),
                    duplicateEmail: duplicateEmail(
                      this.props.invitationsModel,
                      this.props.invitationTempModal,
                    ),
                  }}
                />
                {this.props.invitationTempModal.length ? (
                  <span
                    className="gh-clear-input"
                    onClick={this.clearTemp.bind(this)}
                    data-uk-icon="icon: close"
                  />
                ) : (
                  ""
                )}
              </div>
              <a
                onClick={this.add}
                className="gh-add-member-btn"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("addTeamMemberLabel"),
                }}
              />
            </div>
          </div>
          <Errors
            model="invitationTempModal"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              validEmail: validationMessages().validEmailMessage,
              invalidEmailDomain: !this.props.userRegisterModel.company.sole_trader
                ? validationMessages().invalidEmailDomainMessage
                : null,
              validEmailDomain: !this.props.userRegisterModel.company.sole_trader
                ? validationMessages().validEmailMessageDomain.bind(null, this.props.email)
                : null,
              validateInviteEmail: () => staticService.findByAlias("invitationValidationOwn"),
              duplicateEmail: () => {
                if (duplicateEmail(this.props.invitationsModel, this.props.invitationTempModal)) {
                  return staticService.findByAlias("invitationEmailDuplicate");
                }
              },
            }}
          />
          <hr className="gh-divider" />
          <div className="uk-flex uk-flex-between gh-register-btns">
            <div className="uk-visible@m" style={{ width: 158 }}>
              <span>{""}</span>
            </div>
            <div>{this.getSkipButtonTemplate()}</div>
            <div>{this.getContinueButtonTemplate()}</div>
          </div>
          {/*<div className="uk-hidden@s uk-flex uk-flex-center gh-register-btns">*/}
          {/*  {this.getSkipButtonTemplate()}*/}
          {/*</div>*/}
        </Form>
        <p className="uk-text-center">
          <small
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("teamMessageUnderSkip"),
            }}
          />
        </p>
      </div>
    );
  }

  render() {
    if (this.props.visible) {
      return super.render();
    }
    return null;
  }
}

InvitationFormComponent.propTypes = {
  changeView: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

InvitationFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    errors: state.errorObj,
    auth: state.auth,
    form: state.forms.invitationsModel,
    tempForm: state.forms.invitationTempModal,
    userRegisterModel: state.userRegisterModel,
    invitationsModel: state.invitationsModel,
    invitationTempModal: state.invitationTempModal,
    email: state.userRegisterModel.email,
    userType: state.userRegisterModel.type,
    validate: state.validateInvitations.invitations_validation,
  };
};

const COMPONENT = connect(mapStateToProps)(InvitationFormComponent);
export { COMPONENT as InvitationFormComponent };
