import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";
import { actions } from "react-redux-form";

export const validDatetimeFormat = (value, { dateFormat, timeFormat }) => {
  if (value && moment(value).isValid()) {
    if (dateFormat) return moment(value).format(dateFormat);
    if (timeFormat) return moment(value).format(timeFormat);
  }

  return value;
};

const DateTimeComponent = (props) => {
  const {
    dateFormat,
    className,
    defaultValue,
    disabled,
    dispatch,
    isValidDate,
    isValidDateBefore,
    label,
    name,
    readOnly,
    onChange,
    onCloseAction,
    placeholder,
    required,
    showClearIcon,
    timeConstraints,
    timeFormat,
    value,
    attrProps,
    closeOnSelect,
    time,
    requestCalendarFields,
    icon,
    iconLabel,
    calendarLabel,
    onClear,
    hideIcon,
    utcStyle,
    ...rest
  } = props;
  const [date, setDate] = useState(undefined);

  const input = useRef();

  useEffect(() => {
    const formattedDate =
      dateFormat && defaultValue
        ? Datetime.moment(defaultValue).subtract(1, "day")
        : Datetime.moment().subtract(1, "day");

    setDate(formattedDate);
  }, [defaultValue]);

  const isValidDateHandler = (current) => {
    if (isValidDate) {
      return current.isAfter(date);
    }

    if (isValidDateBefore) {
      return current.isBefore(date);
    }

    return true;
  };

  const onBlurHandler = (val) => {
    if (val) {
      onCloseAction ? onCloseAction(name, value) : value;
    }
  };

  const renderViewHandler = (_, renderDefault) => {
    return (
      <div className="wrapper">
        {renderDefault()}
        {!!requestCalendarFields && requestCalendarFields}
      </div>
    );
  };

  const onClearHandler = () => {
    dispatch(actions.resetValidity(name));
    dispatch(actions.setTouched(name));
    dispatch(actions.change(name, ""));
  };

  const formatDate = React.useCallback(
    (value) => {
      if (value && moment(value).isValid()) {
        if (dateFormat) return moment(value).format(dateFormat);
        if (timeFormat) return moment(value).format(timeFormat);
      }

      return value;
    },
    [dateFormat, timeFormat],
  );

  return (
    <div {...attrProps}>
      <label className={"label"}>{label}</label>
      {time && (
        <span
          aria-hidden="true"
          onClick={() => !!input.current && input.current._onInputClick()}
          className="gh-utc"
          style={utcStyle}
        >
          (UTC{moment().format("Z")})
        </span>
      )}
      <Datetime
        initialValue={timeFormat ? "12:00" : defaultValue}
        ref={input}
        inputProps={{
          name,
          required,
          placeholder,
          className: className ? className : "",
          readOnly,
          disabled,
        }}
        value={validDatetimeFormat(value, { dateFormat, timeFormat }) || ""}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        onChange={onChange}
        timeConstraints={timeConstraints}
        closeOnSelect={closeOnSelect}
        isValidDate={isValidDateHandler}
        onClose={onBlurHandler}
        onBlur={onBlurHandler}
        renderView={renderViewHandler}
        renderInput={(props) => (
          <input {...props} autoComplete="off" value={value ? formatDate(props.value) : ""} />
        )}
        {...rest}
      />
      {!hideIcon ? (
        icon ? (
          <img src={icon} alt="Calendar" className="gh-enquire-calendar-icon" />
        ) : (
          <span className="uk-form-icon gh-calendar-icon" data-uk-icon="icon: calendar" />
        )
      ) : (
        ""
      )}
      {!!iconLabel && <span className="gh-icon-label">{iconLabel}</span>}

      {!!calendarLabel && calendarLabel}

      {showClearIcon && (
        <span
          onClick={() => {
            if (onClear && typeof onClear === "function") {
              onClear(name);
            } else onClearHandler();
          }}
          className="uk-form-icon gh-close-icon"
          data-uk-icon="icon: close"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

DateTimeComponent.propTypes = {
  attrProps: PropTypes.object,
  className: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  isValidDate: PropTypes.bool,
  isValidDateBefore: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showClearIcon: PropTypes.bool,
  timeConstraints: PropTypes.object,
  timeFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  time: PropTypes.bool,
  readOnly: PropTypes.bool,
  requestCalendarFields: PropTypes.object,
  onCloseAction: PropTypes.func,
  icon: PropTypes.string,
  iconLabel: PropTypes.string,
  calendarLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideIcon: PropTypes.bool,
  utcStyle: PropTypes.object,
  utc: PropTypes.bool,
};

DateTimeComponent.defaultProps = {
  attrProps: {},
  label: "",
  defaultValue: undefined,
  placeholder: "Pick a date",
  className: "",
  dateFormat: "DD MMMM, YYYY",
  timeFormat: false,
  required: false,
  isValidDate: false,
  value: undefined,
  timeConstraints: {},
  showClearIcon: true,
  disabled: false,
  closeOnSelect: true,
  hideIcon: false,
  utcStyle: {},
  readOnly: true,
};

export { DateTimeComponent };
