import PropTypes from "prop-types";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { useLegContext } from "./LegContext";

const type = "AircraftLeg";

export default function LegDragBtn(props) {
  const { index, prefix, config } = useLegContext();
  const { getValues } = useFormContext();
  const { moveLeg, setIsDragging } = props;

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => ({ id: getValues(`${prefix}.id`), index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  React.useEffect(() => setIsDragging(isDragging), [isDragging, setIsDragging]);

  const legRef = React.useRef(null);
  const [{ handlerID }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerID: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!legRef.current) return;

      const dragIdx = item.index;
      const hoverIdx = index;

      if (dragIdx === hoverIdx) return;

      const hoverBoundingRect = legRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIdx < hoverIdx && hoverClientY < hoverMiddleY) return;
      if (dragIdx > hoverIdx && hoverClientY > hoverMiddleY) return;

      moveLeg(dragIdx, hoverIdx);

      item.index = hoverIdx;
    },
  });

  drag(drop(legRef));

  return (
    <button
      type="button"
      className="calculator-reset-btn leg-move-btn"
      ref={legRef}
      data-handler-id={handlerID}
      disabled={!config.move}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
      </svg>
    </button>
  );
}

LegDragBtn.propTypes = {
  moveLeg: PropTypes.func,
  setIsDragging: PropTypes.func,
};
