import React from "react";
import PropTypes from "prop-types";
import { MarketPlaceLayout } from "../../../layouts";
import { operatorMarketplaceTabs } from "../../../../configs";
import { fireTracking } from "../../../../shared";

const OperatorMarketPlaceEmptyLegsComponent = (props) => {
  fireTracking(props.location.pathname);

  return (
    <MarketPlaceLayout
      pageTitle={"Market Place"}
      navigationTabs={operatorMarketplaceTabs}
      activeRoute={props.match}
    ></MarketPlaceLayout>
  );
};
OperatorMarketPlaceEmptyLegsComponent.contextTypes = {
  store: PropTypes.object,
};

export { OperatorMarketPlaceEmptyLegsComponent };
