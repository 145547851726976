import React from "react";
import { Switch, Route } from "react-router-dom";
import { operatorBookingTabs } from "../../../../../configs";
import { BookingLayout } from "../../../../layouts";
import { returnRouteUrl, RouteWithSubRoutes } from "../../../../../shared/";
import staticService from "../../../../../services/static.service";
import { OperatorBookingEmptyLegListComponent } from "./";

export class OperatorBookingEmptyLegsComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersShown: false,
      showFilterIcon: true,
      filterFailed: "",
    };
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      isFiltersShown: !prevState.isFiltersShown,
    }));
  }

  get layoutParentCssClass() {
    return ["gh-normal"];
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  render() {
    return (
      <BookingLayout
        pageTitle={staticService.findByAlias("emptyLegRequestsLabel")}
        isFiltersShown={this.state.isFiltersShown}
        isFilterVisible={this.state.showFilterIcon}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        navigationTabs={operatorBookingTabs}
        parentClassname={this.layoutParentCssClass}
        activeRoute={this.props.match}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  onEnter={this.onEnterPage.bind(this)}
                  mainUrl={this.props.match.url}
                  title={route.title}
                  {...returnRouteUrl(route, this.props.match.url)}
                />
              );
            })}
          <Route
            path="/"
            render={(props) => (
              <OperatorBookingEmptyLegListComponent
                {...props}
                parentMatch={this.props.match}
                isFiltersShown={this.state.isFiltersShown}
              />
            )}
          />
        </Switch>
      </BookingLayout>
    );
  }
}
