import React from "react";
import { tabs } from "../../../configs";
import staticService from "../../../services/static.service";
import { BlockComponent } from "../../common";

const RegisterSelectRole = ({ clickTab, article }) => {
  const styles = {
    background: `
        linear-gradient(rgba(36, 47, 66, 0.6),
        rgba(36, 47, 66, 0.6)), 
        url(${article && article.icon})`,
    height: "calc(100vh - 86px)",
  };
  return (
    <div className="register-select-role" style={styles}>
      {article && (
        <BlockComponent
          visible={true}
          containerClass={"uk-container uk-container-affiliate"}
          parentProps={{ className: "uk-section uk-padding-remove-bottom" }}
        >
          <h1
            className="uk-margin-small-bottom gh-text-white gh-sign-title"
            dangerouslySetInnerHTML={{ __html: article.title }}
          />
          <p className="uk-margin-medium-top uk-margin-medium-bottom gh-text-white">
            <span dangerouslySetInnerHTML={{ __html: article.gh_content }} />
          </p>
        </BlockComponent>
      )}
      <BlockComponent
        visible={true}
        containerClass={"uk-container uk-container-affiliate"}
        parentProps={{
          className: "uk-section uk-padding-remove-top gh-section-statistics",
        }}
      >
        <div className="gh-register-landing">
          <div>
            {tabs.map((tab) => (
              <div className="uk-margin gh-register-section" key={tab.alias}>
                <h4 className="uk-width-1-1 gh-register-text">
                  {tab.alias === "broker"
                    ? staticService.findByAlias("brokerRegisterText")
                    : staticService.findByAlias("operatorRegisterText")}
                </h4>
                <button
                  className="uk-button uk-button-primary uk-padding-remove gh-register-select-button"
                  onClick={() => clickTab(tab)}
                >
                  {tab.alias === "broker"
                    ? staticService.findByAlias("iAmABroker")
                    : staticService.findByAlias("iAmAnOperator")}
                </button>
              </div>
            ))}
          </div>
        </div>
      </BlockComponent>
    </div>
  );
};

export { RegisterSelectRole };
