import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class AutocompleteDropdownComponent extends Component {
  constructor(props) {
    super(props);
    this.index = 0;
    this.onSelect = this.onSelect.bind(this);
  }

  componentWillReceiveProps(nextProps) {}

  componentDidMount() {
    window.addEventListener("keydown", (e) => {
      if (this.props.autocomplete.length > 0) {
        const lastIndex = this.props.autocomplete.length - 1;
        if (e.which === 40) {
          this.props.onSelect(this.props.autocomplete[this.index], false);
          this.index = lastIndex > this.index ? this.index + 1 : 0;
        } else if (e.which === 38) {
          this.props.onSelect(this.props.autocomplete[this.index], false);
          this.index = this.index > 0 ? this.index - 1 : lastIndex;
        } else if (e.which === 13) {
          this.onSelect(this.props.autocomplete[this.index]);
        }
      }
    });
  }

  get hidden() {
    return this.props.autocomplete.length === 0 ? "uk-hidden" : "";
  }

  onSelect(selected) {
    this.index = 0;
    this.props.onSelect(selected);
  }

  render() {
    return (
      <ul
        className={[
          "disabled-list",
          "gh-autocomplete",
          "autocomplete-dropdown-container",
          this.hidden,
        ].join(" ")}
        ref={(ul) => (this.el = ul)}
      >
        {this.props.autocomplete.length > 0
          ? this.props.autocomplete.map((item, i) => (
              <li key={i} onClick={this.onSelect.bind(this, item)}>
                {item.name}
              </li>
            ))
          : null}
      </ul>
    );
  }
}

AutocompleteDropdownComponent.contextTypes = {
  store: PropTypes.object,
};

AutocompleteDropdownComponent.protTypes = {
  onSelect: PropTypes.func,
};

const mapStateToProps = ({ autocomplete }) => {
  return {
    autocomplete,
  };
};

const COMPONENT = connect(mapStateToProps)(AutocompleteDropdownComponent);
export { COMPONENT as AutocompleteDropdownComponent };
