import React from "react";
import { AccountLayout, AccountOnlineLayout } from "../layouts";
import { staticText, pages } from "../../configs/";
import auth from "../../services/auth";

export const ForbiddenComponent = ({
  content = staticText.forbiddenMessage,
  match,
  title,
  skipLayout = false,
  attrProps = {},
}) => {
  const render = () => {
    return (
      <div {...attrProps}>
        <div data-uk-alert className={"uk-margin-top uk-alert-danger"}>
          <div className="uk-text-center">{content}</div>
        </div>
      </div>
    );
  };

  if (skipLayout) return render();

  const menu = auth.isAuthenticated()
    ? pages.map((page) => {
        if (page.path === "/") {
          page.title = "Dashboard";
        }
        return page;
      })
    : pages;

  if (auth.getUser()) {
    return (
      <AccountOnlineLayout activeRoute={match} pageTitle={title}>
        {render()}
      </AccountOnlineLayout>
    );
  }

  return (
    <AccountLayout activeRoute={match} pages={menu} pageTitle={title}>
      {render()}
    </AccountLayout>
  );
};
