import React, { useEffect } from "react";
import staticService from "../../../../../../services/static.service";
import { usePipelineViewContext } from "../PipelineViewContext";
import { Form } from "../../../../../Form";
import PhoneInput from "../../../../../common/phoneInput";
import { useForm } from "react-hook-form";
import Input from "../../../../../Form/components/Input";
import useYupResolver from "../../../../../../hooks/useYupResolver";
import * as yup from "yup";
import Button from "../../../../../common/Button";
import { isValidPhoneNumber } from "libphonenumber-js";
import isAllowForEditing from "../../../../../../utils/isAllowForEditing";
import { showNotification } from "../../../../../../utils/showNotification";

export default function PipelineViewCustomer() {
  const { pipeline, editCustomerInfo } = usePipelineViewContext();

  const isValidPhone = (value) =>
    typeof value === "object" ? isValidPhoneNumber(value.value, value.code) : true;

  const loginSchema = yup.object().shape({
    email: yup.string().email(({ value }) => `${value} is not a valid email`),
    phone: yup
      .mixed()
      .test("phone", staticService.findByAlias("phoneValidationMessage"), isValidPhone),
  });

  const form = useForm({
    resolver: useYupResolver(loginSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (Object.keys(pipeline).length) {
      form.setValue("first_name", pipeline.first_name || "");
      form.setValue("last_name", pipeline.last_name || "");
      form.setValue("email", pipeline.email || "");
      form.setValue("phone", pipeline.phone || "");
    }
  }, [pipeline]);

  const onSubmit = (values) => {
    if (!isAllowForEditing()) {
      showNotification("restrictErrorMessage", "danger");
      return;
    }

    const defaultValue = {
      first_name: pipeline.first_name || "",
      last_name: pipeline.last_name || "",
      email: pipeline.email || "",
      phone: pipeline.phone || "",
    };

    if (JSON.stringify(defaultValue) !== JSON.stringify(values)) {
      editCustomerInfo(
        {
          ...values,
          name: `${values.first_name} ${values.last_name}`,
          phone: typeof values.phone === "object" ? values.phone.value : values.phone,
        },
        pipeline.id,
      );
    }
  };

  return (
    <div className="pipeline-view-tab">
      <div className="pipeline-view-input-group uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
        <Form form={form} id={pipeline + "pipeline-form"}>
          <div data-uk-grid className="uk-form-controls">
            <Form.Item
              className="uk-width-1-1 uk-width-1-2@s pipeline-item"
              label="First Name"
              name="first_name"
            >
              <Input placeholder="John" />
            </Form.Item>
            <Form.Item
              className="uk-width-1-1 uk-width-1-2@s pipeline-item"
              label="Last Name"
              name="last_name"
            >
              <Input placeholder="John" />
            </Form.Item>
            <Form.Item
              className="uk-width-1-1 uk-width-1-2@s uk-margin-top pipeline-item"
              label="Email"
              name="email"
            >
              <Input placeholder="email@example.com" />
            </Form.Item>
            <Form.Item
              className="uk-width-1-1 uk-width-1-2@s uk-margin-top pipeline-item"
              label="Phone"
              name="phone"
            >
              <PhoneInput />
            </Form.Item>
          </div>

          <div className="uk-flex uk-flex-right uk-margin-small-top pipeline-item">
            <Button className="gh-pipeline-button-save" onClick={form.handleSubmit(onSubmit)}>
              {staticService.findByAlias("save")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
