import React from "react";
import PropTypes from "prop-types";
import InputNumber from "../../Form/components/InputNumber";
import Popup from "reactjs-popup";
import "./priceInputComponent.css";
import staticService from "../../../services/static.service";

const PriceInputComponent = (props) => {
  const {
    additionalClass,
    field: { name, ref },
    onChange,
    value,
    fieldState: { error },
    label,
    showTooltip,
    onEnterValidate,
  } = props;

  const renderInputContent = () => {
    return (
      <div className={`gh-price-input-wrapper ${additionalClass}`}>
        <label>{label}</label>
        <InputNumber
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          onEnterValidate={onEnterValidate}
        />
      </div>
    );
  };

  return (
    <div>
      {showTooltip ? (
        <Popup
          trigger={renderInputContent()}
          on="hover"
          position={["top center"]}
          keepTooltipInside={true}
        >
          {staticService.findByAlias("thePriceIsEditable")}
        </Popup>
      ) : (
        renderInputContent()
      )}

      <div className="gh-message-error">{error && error.message ? error.message : ""}</div>
    </div>
  );
};

PriceInputComponent.defaultProps = {
  additionalClass: "",
};

PriceInputComponent.propTypes = {
  additionalClass: PropTypes.string,
  field: PropTypes.object,
  fieldState: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  showTooltip: PropTypes.bool,
  onEnterValidate: PropTypes.bool,
};

export default PriceInputComponent;
