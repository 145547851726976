import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import angle from "../../../assets/img/svg/arrow-left-dark.svg";
import "./eventHelicopters.css";
import EventHelicopterItem from "./components/EventHelicopterItem";
import Button from "../Button";

export default function HelicoptersList({
  helicoptersList,
  enquireFurther,
  back,
  loadingBtn,
  typeOfTrip,
  warningMessages,
}) {
  return (
    <div className="helicopters-list">
      {helicoptersList && helicoptersList.length === 0 ? (
        <div className="uk-flex uk-flex-center uk-flex-middle gh-gray-warning">
          <span
            className="uk-text-warning gh-danger-opostrov gh-vertical-middle uk-margin-right uk-position-relative"
            data-uk-icon="icon: warning"
          />
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("enquireNoHeliMessage"),
            }}
          />
        </div>
      ) : (
        ""
      )}

      <div
        className="helicopters-list-content"
        style={{
          maxHeight: helicoptersList && helicoptersList.length ? "800px" : "0",
        }}
      >
        {helicoptersList &&
          helicoptersList.map((item, index) => {
            const {
              estimated_price,
              id,
              model,
              description,
              images,
              max_passengers,
              duration,
              tooltip_message,
              warning_message,
            } = item;
            return (
              <React.Fragment key={index}>
                <EventHelicopterItem
                  estimated_price={estimated_price}
                  helicopterId={id}
                  model={model}
                  description={description}
                  duration={duration}
                  images={images}
                  max_passengers={max_passengers}
                  isFromEventPage
                  typeOfTrip={typeOfTrip}
                  warningMessages={warningMessages}
                  tooltipMessage={tooltip_message}
                  warningMessage={warning_message}
                >
                  <Button onClick={() => enquireFurther(item)} loading={loadingBtn}>
                    {staticService.findByAlias("enquireFurtherButton")}
                  </Button>
                </EventHelicopterItem>
              </React.Fragment>
            );
          })}
      </div>
      <div className="gh-enquire-actions --heli-options">
        <Button color="gray" onClick={back}>
          <img src={angle} alt="angle-left" className="gh-angle" />
          <span>{staticService.findByAlias("back")}</span>
        </Button>

        {!helicoptersList.length ? (
          <Button onClick={enquireFurther} loading={loadingBtn}>
            {staticService.findByAlias("enquireFurtherNextButton")}
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

HelicoptersList.propTypes = {
  helicoptersList: PropTypes.array,
  enquireFurther: PropTypes.func,
  back: PropTypes.func,
  loadingBtn: PropTypes.bool,
  typeOfTrip: PropTypes.string,
  warningMessages: PropTypes.object,
};
