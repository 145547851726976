import React from "react";
import "./InfoContainerWithIcon.css";
import PropTypes from "prop-types";

const InfoContainerWithIcon = ({ title = "", text = "", icon, className = "" }) => {
  return (
    <div
      className={`gh-info-container-warning-box ${
        title && "gh-info-container-warning-box--with-title"
      } ${className}`}
    >
      {icon && (
        <div className="gh-info-container-icon-wrapper">
          <img style={{ width: window.innerWidth < 575 ? 80 : 40 }} src={icon} alt="warning_icon" />
        </div>
      )}
      <div className="gh-info-container-text-wrapper">
        <p className="gh-info-container-title">{title}</p>
        <p style={{ margin: 0 }} className="gh-info-container-text">
          {text}
        </p>
      </div>
    </div>
  );
};

InfoContainerWithIcon.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
};

export default InfoContainerWithIcon;
