import isNum from "./isNum";
/**
 * @param {number} number     Number to format
 * @param {number} digits     Maximum digits after end
 * @returns                   Formatted number
 */

export function formatNumber(number, digits = 2) {
  if (isNum(number))
    return new Intl.NumberFormat("en-GB", {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    }).format(number);

  return number;
}
