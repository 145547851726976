import React from "react";
import moment from "moment";

export const LandingItem = ({ pad = {} }) => {
  moment.updateLocale("en", {
    relativeTime: {
      s: "just now",
      ss: "in %d seconds",
      m: "in a minute",
      mm: "in %d minutes",
      h: "in an hour",
      hh: "in %d hours",
      d: "in one day",
      dd: "in %d days",
      M: "in a month",
      MM: "in %d months",
      y: "in a year",
      yy: "in %d years",
    },
  });
  return (
    <div data-uk-grid className="uk-grid uk-margin-small-top">
      <div className="uk-width-1-1@s uk-width-2-3@m">
        {/*<label className={'uk-hidden@s gh-margin-bottom-10'}>Destination:</label>*/}
        <span>
          <span className="gh-with-full">
            {pad.from_name}
            <span className="uk-margin-small-left uk-margin-small-right uk-icon">
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <polyline fill="none" stroke="#000" points="10 5 15 9.5 10 14" />
                <line fill="none" stroke="#000" x1="4" y1="9.5" x2="15" y2="9.5" />
              </svg>
            </span>
          </span>
          <span>{pad.to_name}</span>
        </span>
      </div>
      {/*<div className="uk-width-1-1@s uk-width-1-3@m">*/}
      {/*	<label className={'uk-hidden@s'}>Date:</label> {moment().to(pad.departure_time, true)}*/}
      {/*</div>*/}
    </div>
  );
};
