import PropTypes from "prop-types";
import React from "react";
import { PriceCalculator } from "../../../packages/";
import apiHandler from "../../../services/api-handler";
import auth from "../../../services/auth";
import { spinnerInstance } from "../../../shared";
import { showNotification } from "../../../utils/showNotification";
import { connect } from "react-redux";
import { notification } from "uikit";

export async function updateQuoteCalulator(data, quoteId) {
  apiHandler.setProperty("skipErrorAlert", true);
  spinnerInstance.setProp("type", "large").show();

  try {
    const response = await apiHandler.setPath("quote/switch-to-quote-tab", quoteId).post(data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  } finally {
    spinnerInstance.setProp("type", "large").hide();
  }
}

const QuoteFormCalculator = (props) => {
  const { editingQuote, handleChangeTab, setQuoteDetailAmend, dashboard } = props;

  const currency = React.useMemo(() => auth.getUser().banking.currency, []);

  const onCalculatorSubmit = React.useCallback(
    () => async (data) => {
      try {
        const response = await updateQuoteCalulator(
          { ...data, is_used: true },
          editingQuote?.quote?.calculation_id,
        );

        setQuoteDetailAmend(response);
        handleChangeTab("quote", window.location.pathname);

        return Promise.resolve(response);
      } catch (error) {
        if (error?.data?.message) {
          notification("Please fill in all required fields", {
            status: "calculator",
            pos: "top-right",
          });
        }

        if (error.message) {
          showNotification(error.message, "danger");
        }
        return Promise.resolve();
      }
    },
    [],
  );

  return (
    <PriceCalculator
      editingQuote={editingQuote}
      currency={currency}
      onFinish={onCalculatorSubmit}
      submitOnUnmount={true}
      banking={dashboard.banking}
    />
  );
};

QuoteFormCalculator.propTypes = {
  editingQuote: PropTypes.object,
  handleChangeTab: PropTypes.func,
  setQuoteDetailAmend: PropTypes.func,
  dashboard: PropTypes.object,
};

const mapStateToProps = (store) => ({
  dashboard: store.dashboard,
});

export default connect(mapStateToProps)(QuoteFormCalculator);
