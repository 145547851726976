import ReactDOM from "react-dom";
import React from "react";
import CustomPromptModal from "./CustomPromptModal";

const UserLeaveConfirmation = (value, callback, confirmOpen, setConfirmOpen) => {
  const container = document.createElement("div");

  container.setAttribute("custom-confirm-view", "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  document.body.appendChild(container);

  ReactDOM.render(
    <CustomPromptModal
      confirmOpen={confirmOpen}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      value={value}
    />,
    container,
  );
};
export default UserLeaveConfirmation;
