import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { notification } from "uikit";
import useYupResolver from "../../../hooks/useYupResolver";
import { Form } from "../../Form";
import Button from "../Button";
import { addClient, updateClient } from "../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PhoneInput, { isValidPhone } from "../phoneInput";
import Input from "../../Form/components/Input";
import InputNumber from "../../Form/components/InputNumber";

const clientIdentifierShema = yup.object().shape({
  client_identifier: yup.string().required("This field is required"),
  email: yup.string().email(({ value }) => `${value} is not a valid email`),
  weight: yup
    .string()
    .test("len", "The weight may not be greater than 8 characters", (val) =>
      val ? val.length <= 8 : true,
    ),
  // phone: yup.mixed().test("phone", "Invalid phone number", isValidPhone),
  global: yup.string().test({
    name: "global",
    message: "Please fill in client email or phone",
    test: function () {
      if (!this.parent.email) {
        if (!this.parent.phone) {
          return false;
        }

        if (typeof this.parent.phone === "object") {
          if (
            this.parent.phone.value === this.parent.phone.dialCode ||
            this.parent.phone.value.length < this.parent.phone.dialCode.length
          ) {
            return false;
          }
        }
      }

      return true;
    },
  }),
});

export default function ClientListForm(props) {
  const { client, onUpdate, visible, addClient, updateClient } = props;
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    resolver: useYupResolver(clientIdentifierShema),
  });

  React.useEffect(() => {
    if (client && client.id) {
      Object.entries(client).forEach(([key, value]) => {
        if (key === "phone") {
          form.setValue(key, client.originalPhone?.value ? client.originalPhone : "");
        } else {
          form.setValue(key, value);
        }
      });
    }
  }, [client, form]);

  React.useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [form, visible]);

  const addClientHandler = React.useCallback(
    async (client) => {
      setLoading(true);
      addClient(client)
        .then((res) => {
          if (res) {
            onUpdate(res);
            notification("Client created successfully", {
              status: "calculator",
              pos: "top-right",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = (msg, idx, arr) => msg[0] + (idx !== arr.length - 1 ? ", " : "");
          notification(
            Object.values(err.data.errors).map((msg, idx, arr) => {
              return errorMessage(msg, idx, arr);
            }),
            {
              status: "danger",
              pos: "top-right",
            },
          );
        });
    },
    [onUpdate, addClient],
  );

  const updateClientHandler = React.useCallback(
    async (client) => {
      setLoading(true);
      updateClient(client)
        .then((res) => {
          if (res) {
            onUpdate(res);
            notification("Client updated successfully", {
              status: "calculator",
              pos: "top-right",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = (msg, idx, arr) => msg[0] + (idx !== arr.length - 1 ? ", " : "");
          notification(
            Object.values(err.data.errors).map((msg, idx, arr) => {
              return errorMessage(msg, idx, arr);
            }),
            {
              status: "danger",
              pos: "top-right",
            },
          );
        });
    },
    [onUpdate, updateClient],
  );

  const onFormSubmit = React.useCallback(
    (values) => {
      const data = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value ? value : null]),
      );

      if (client.id) {
        updateClientHandler(data);
      } else {
        addClientHandler(data);
      }
    },
    [updateClientHandler, addClientHandler, client],
  );

  const onFormInvalid = React.useCallback(
    (errors) => {
      if (errors.phone && !errors.phone.message) {
        if (form.getValues().id) {
          updateClientHandler(form.getValues());
        } else {
          addClientHandler(form.getValues());
        }
      }
    },
    [updateClientHandler, addClientHandler, form],
  );

  if (!visible) return null;

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      {form.formState.errors.global && (
        <div className="uk-alert-danger uk-padding-small uk-margin-bottom" data-uk-alert>
          <a className="uk-alert-close" data-uk-close />
          <p className="uk-margin-remove">{form.formState.errors.global.message}</p>
        </div>
      )}
      <div data-uk-grid className="uk-form-controls">
        <Form.Item className="uk-width-1-1" label="Client Identifier" name="client_identifier">
          <Input placeholder="Client Identifier" />
        </Form.Item>
        <Form.Item
          className="uk-width-1-1 uk-width-1-3@s uk-margin-top"
          label="First Name"
          name="first_name"
        >
          <Input placeholder="Client First Name" />
        </Form.Item>
        <Form.Item
          className="uk-width-1-1 uk-width-1-3@s uk-margin-top"
          label="Last Name"
          name="last_name"
        >
          <Input placeholder="Client Last Name" />
        </Form.Item>
        <Form.Item
          className="uk-width-1-1 uk-width-1-3@s uk-margin-top"
          label="Weight (kg)"
          name="weight"
        >
          <InputNumber className="uk-input" />
        </Form.Item>
        <Form.Item className="uk-width-1-1 uk-width-1-2@s uk-margin-top" label="Email" name="email">
          <Input placeholder="Client Email Address" />
        </Form.Item>
        <Form.Item className="uk-width-1-1 uk-width-1-2@s uk-margin-top" label="Phone" name="phone">
          <PhoneInput selectedCountry={"gb"} />
        </Form.Item>
      </div>
      <div className="uk-flex uk-flex-right uk-margin-top">
        <Button loading={loading} onClick={form.handleSubmit(onFormSubmit, onFormInvalid)}>
          {client.id ? "Update" : "Create"}
        </Button>
      </div>
    </Form>
  );
}

ClientListForm.propTypes = {
  client: PropTypes.object,
  onUpdate: PropTypes.func,
  addClient: PropTypes.func,
  updateClient: PropTypes.func,
  visible: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addClient,
      updateClient,
    },
    dispatch,
  );
};

const COMPONENT = connect(() => ({}), mapDispatchToProps)(ClientListForm);
export { COMPONENT as ClientListForm };
