import PropTypes from "prop-types";
import React, { memo, useCallback, useContext, useRef, useState } from "react";
import { Map as GoogleMap } from "google-maps-react";
import { LondonLocation, mapStyles } from "../../configs";
import MarkerCluster from "./markerCluster";
import FlyDirsWrapper from "./FlyDirsWrapper";
import PadSelectionContext from "../../context/PadSelectionContext";
import CustomMarker from "./CustomMarker";
import Api from "../../services/api-handler";

const MapRoot = (props) => {
  const { pads, clustererConfig, flyDirCoords, ...rest } = props;
  const [currProjection, setCurrProjection] = useState();
  const [currZoom, setCurrZoom] = useState();
  const [activeInfo, setActiveInfo] = useState({
    marker: null,
    visible: false,
    pad: null,
    isSelected: false,
  });
  const [customPos, setCustomPos] = useState({});
  const { cardActions, handleAssignPad, review, legIndex, legId } = useContext(PadSelectionContext);
  const mapDynamicId = useRef(Math.random().toString(16).substring(7));

  const closeActiveInfo = useCallback(() => {
    setActiveInfo({
      visible: false,
      marker: null,
      pad: null,
      isSelected: false,
    });
  }, []);

  const mapClickHandler = async (_, __, pos) => {
    if (activeInfo.visible || customPos.place_id) {
      closeActiveInfo();
      setCustomPos({});
    } else {
      if (review) return;

      const coords = { lat: pos.latLng.lat(), lng: pos.latLng.lng() };
      const response = await Api.setProperty("skipErrorAlert", true)
        .setPath("reverse-geocode", "")
        .setQueryParams([
          { key: "latitude", value: coords.lat },
          { key: "longitude", value: coords.lng },
        ])
        .getAll();

      if (response) {
        setCustomPos(response);
      }
    }
  };

  const onZoomChanged = useCallback((_, _map) => setCurrZoom(_map.getZoom()), []);
  const onProjectionChanged = useCallback((_, _map) => setCurrProjection(_map.getProjection()), []);
  const onReadyHandler = (_, _map) => {
    onZoomChanged(null, _map);
  };

  return (
    <GoogleMap
      minZoom={4}
      zoom={10}
      clickableIcons
      initialCenter={LondonLocation}
      google={window.google}
      onProjectionChanged={onProjectionChanged}
      onZoomChanged={onZoomChanged}
      onReady={onReadyHandler}
      styles={mapStyles}
      onClick={mapClickHandler}
      gestureHandling="cooperative"
      {...rest}
    >
      <MarkerCluster
        pads={pads}
        setActiveInfo={setActiveInfo}
        activeInfo={activeInfo}
        mapId={mapDynamicId.current}
        cardActions={cardActions}
        {...clustererConfig}
      />
      <CustomMarker
        markerInfo={customPos}
        setActiveInfo={setActiveInfo}
        handleAssignPad={handleAssignPad}
        legIndex={legIndex}
        legId={legId}
      />
      {!!currProjection && !!currZoom && (
        <FlyDirsWrapper
          flyDirCoords={flyDirCoords}
          projection={currProjection}
          zoom={currZoom}
          setActiveInfo={setActiveInfo}
          closeActiveInfo={closeActiveInfo}
        />
      )}
    </GoogleMap>
  );
};

MapRoot.propTypes = {
  pads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category_id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  clustererConfig: PropTypes.object,
  flyDirCoords: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      pad: PropTypes.object,
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
};

MapRoot.defaultProps = {
  pads: [],
  clustererConfig: {},
};

export default memo(MapRoot);
