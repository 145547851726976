import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import moment from "moment";
import Auth from "../../../../../services/auth";
import { config, OPERATOR_ADMIN } from "../../../../../configs";
import "./fleetList.css";

export class FleetItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderActive: false,
    };
    this.triggerAction = this.triggerAction.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoaderActive !== nextProps.isLoaderActive && !nextProps.isLoaderActive) {
      this.setState({
        isLoaderActive: false,
      });
    }
  }

  triggerAction(action) {
    this.setState({
      isLoaderActive: true,
    });
    action();
  }

  get verified() {
    const { status } = this.props;

    return (
      <div className="fleet-item_status">
        <label className="fleet-item_status-label gh-dark-blue">
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("aircraftVerified"),
            }}
          />
          {status === 2 ? (
            <span
              className="uk-margin-small-left gh-animated-label gh-yellow-tooltip"
              data-uk-icon="icon: info"
              data-uk-tooltip={`title: ${staticService.findByAlias(
                "verifiedButExpiresSoon",
              )}; pos: top-left`}
            />
          ) : (
            ""
          )}
          {status === 3 ? (
            <span
              className="uk-margin-small-left gh-yellow-tooltip"
              data-uk-icon="icon: info"
              data-uk-tooltip={`title: ${staticService.findByAlias(
                "mustBeVerified",
              )}; pos: top-left`}
            />
          ) : (
            ""
          )}
        </label>
        <div className="gh-verified-checkbox uk-text-center uk-margin-remove">
          <input
            type="checkbox"
            className={`fleet-status-checkbox uk-checkbox ${
              status !== 1 ? "gh-yellow-checkbox" : ""
            }`}
            readOnly={true}
            checked={status !== 3}
          />
        </div>
      </div>
    );
  }

  get unVerified() {
    return (
      <div className="fleet-item_status">
        <label className="fleet-item_status-label gh-dark-blue">
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("aircraftVerified"),
            }}
          />
          <span
            className="uk-margin-small-left gh-animated-label gh-yellow-tooltip"
            data-uk-icon="icon: info"
            style={{ transform: "translateY(-2px)" }}
            data-uk-tooltip={`title: ${staticService.findByAlias(
              "notVerifiedMessage",
            )}; pos: top-left`}
          />
        </label>
        <div className="fleet-item_status-checkbox gh-verified-checkbox uk-text-center uk-margin-remove" />
      </div>
    );
  }

  get loading() {
    return this.state.isLoaderActive ? "show danger" : "";
  }

  render() {
    const { tailNumber, detailsCallback, status, aircraft } = this.props;
    return (
      <li className="fleet-item">
        <div className="fleet-item_info">
          <div className={"gh-dark-blue"}>
            <b>{tailNumber}</b>
          </div>
          <div className={"uk-margin-small-top"}>
            <button
              className={"uk-button uk-button-primary uk-button-small"}
              onClick={detailsCallback.bind(this, aircraft)}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("aircraftDetailsButton"),
              }}
            />
          </div>
        </div>
        {status !== undefined && status !== 4 ? this.verified : this.unVerified}
      </li>
    );
  }
}

FleetItemComponent.contextTypes = {
  store: PropTypes.object,
};

FleetItemComponent.propTypes = {
  isLoaderActive: PropTypes.bool,
  status: PropTypes.number,
  aircraft: PropTypes.object,
  tailNumber: PropTypes.string,
  detailsCallback: PropTypes.func,
};
