import React from "react";
import PropTypes from "prop-types";
import { dateFormat } from "../../../shared";
import Api from "../../../services/api-handler";

class LocationAutocompleteItemShort extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  async onClick() {
    const { item: location, onClick } = this.props;
    const body = {
      location: [location.latitude, location.longitude],
      place_id: location.place_id,
      source: location.source,
      secondary_text: location.secondary_text,
    };
    const inputData = Object.assign({}, location);

    if (this.props.checkCoverage) {
      const response = await Api.setProperty("skipErrorAlert", true)
        .setProperty("alertModal", false)
        .setPath("check-location-coverage", "")
        .post(body);
      inputData.coverage_status = response.message;
    }

    onClick(inputData);
  }

  render() {
    const { location, secondary_text, type, start_date, end_date } = this.props.item;
    return (
      <li className="gh-short-list-item" onClick={this.onClick}>
        <div className="gh-sl-title">{location}</div>
        <div className="gh-sl-secondary">
          {type +
            " • " +
            dateFormat(start_date, "YYYY-MM-DD", "ddd D MMMM, YYYY") +
            " - " +
            dateFormat(end_date, "YYYY-MM-DD", "ddd D MMMM, YYYY") +
            " • " +
            secondary_text}
        </div>
      </li>
    );
  }
}

LocationAutocompleteItemShort.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.shape({
    location: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    distance: PropTypes.string,
    url: PropTypes.string,
    place_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  }),
  checkCoverage: PropTypes.bool,
};

LocationAutocompleteItemShort.defaultProps = {
  item: [],
  checkCoverage: false,
};

export { LocationAutocompleteItemShort };
