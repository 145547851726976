import React from "react";
import DocumentTitle from "../../common/DocumentTitle";
import { config } from "../../../configs";
import staticService from "../../../services/static.service";

const IframeBrokerBrochure = () => {
  const environment = process.env.NODE_ENV;

  const url = `https://${config.envURL[environment]}.getheli.com/pdf/get-heli-api-brokers-fv.pdf`;

  return (
    <div>
      <DocumentTitle title={staticService.findByAlias("brokerPdfPageTitle")} />
      <iframe src={url} title="testPdf" height="100%" width="100%" style={{ height: "100vh" }} />
    </div>
  );
};

export default IframeBrokerBrochure;
