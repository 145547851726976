import PropTypes from "prop-types";
import React, { useRef, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import staticService from "../../../services/static.service";
import { ModalComponent } from "../../../shared";
import { hideAvailableCountriesModal } from "../../../actions/availableCountriesAction";
import AvailableCountriesContent from "./AvailableCountriesContent";

import "./modalAvailableCountries.css";
import AvailableHomebases from "./AvailableHomebases";

function ModalAvailableCountries(props) {
  const { availableCountries } = props;
  const modalRef = useRef();

  useEffect(() => {
    if (availableCountries.isOpen) openModalHandler();
  }, [availableCountries.isOpen]);

  const openModalHandler = useCallback(() => {
    modalRef.current.open();
  }, [modalRef]);

  const hideModalHandler = useCallback(() => {
    hideAvailableCountriesModal()(props.dispatch);
  }, [modalRef]);

  const isModalOpen = modalRef.current?.component?.$el?.classList?.contains("uk-open");

  return (
    <ModalComponent
      title={staticService.findByAlias("availableCountries")}
      id="available-countries-modal"
      ref={modalRef}
      onModalClose={hideModalHandler}
      options={{
        bgClose: false,
      }}
    >
      <AvailableCountriesContent {...props}>
        <AvailableHomebases isModalOpen={isModalOpen} />
      </AvailableCountriesContent>
    </ModalComponent>
  );
}

ModalAvailableCountries.propTypes = {
  availableCountries: PropTypes.shape({
    items: PropTypes.array,
    isOpen: PropTypes.bool,
  }),
  dispatch: PropTypes.func,
};

export default connect((store) => ({ availableCountries: store.availableCountries }))(
  ModalAvailableCountries,
);
