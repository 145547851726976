import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Form } from "../../../../Form";
import { useForm } from "react-hook-form";
import useYupResolver from "../../../../../hooks/useYupResolver";
import Button from "../../../../common/Button";
import AutocompleteInputHookForm from "../../../../Form/components/AutocompleteInputHookForm";
import DatePicker from "../../../../Form/components/DatePicker";
import TimePicker from "../../../../Form/components/TimePicker/TimePicker";
import Select from "../../../../Form/components/Select";
import { connect } from "react-redux";
import staticService from "../../../../../services/static.service";
import { addEmptyLeg, loadUserDashboardStatistic, resetEmptyLegs } from "../../../../../actions";
import tranformDateTimeToISO from "../../../../../utils/tranformDateTimeToISO";
import { showNotification } from "../../../../../utils/showNotification";
import moment from "moment";
import { config } from "../../../../../configs";

const DashboardEmptyLegForm = (props) => {
  const { aircrafts, addEmptyLeg, modalRef, loadUserDashboardStatistic, resetEmptyLegs } = props;

  // FORM VALIDATION
  const emptyLegFormSchema = yup.object().shape({
    from_location: yup.object().required(staticService.findByAlias("pleaseSelect")).nullable(),
    to_location: yup.object().required(staticService.findByAlias("pleaseSelect")).nullable(),
    aircraft_id: yup
      .number()
      .required(staticService.findByAlias("pleaseSelectAircraft"))
      .nullable(),
    date: yup.string().required(staticService.findByAlias("pleaseSelectDate")).nullable(),
    from_time: yup.string().required(staticService.findByAlias("pleaseSelectDate")).nullable(),
    to_time: yup.string().required(staticService.findByAlias("pleaseSelectDate")).nullable(),
  });

  // INIT FORM
  const form = useForm({
    resolver: useYupResolver(emptyLegFormSchema),
    defaultValues: {
      from_location: "",
      to_location: "",
      date: moment().add(2, "h"),
      from_time: moment().add(2, "h").startOf("h"),
      to_time: moment().add(3, "h").startOf("h"),
    },
  });

  let date = form.watch("date");
  let fromTime = form.watch("from_time");
  let toTime = form.watch("to_time");

  const isValidDate = (currentDate) => {
    const yesterday = moment().add(2, "h").subtract(1, "day");
    // DATE IS VALID FROM TODAY
    return currentDate.isAfter(yesterday);
  };

  const onFormSubmit = (value) => {
    const formValue = {
      aircraft_id: value.aircraft_id,
      from_location: value.from_location,
      to_location: value.to_location,
      time_from: tranformDateTimeToISO(
        moment(value.date).format(config.dateFormat),
        moment(value.from_time).format(config.timeFormat),
      ),
      time_to: tranformDateTimeToISO(
        moment(value.date).format(config.dateFormat),
        moment(value.to_time).format(config.timeFormat),
      ),
    };

    addEmptyLeg(formValue)
      .then((res) => {
        loadUserDashboardStatistic();
        resetEmptyLegs();
        form.reset();
        modalRef.hide();

        return res;
      })
      .catch((err) => {
        if (err.message) {
          showNotification(staticService.findByAlias(err.message) || err.message, "danger");
        }
        return err;
      });
  };

  const minFromToday = useMemo(() => {
    const currentDate = moment().add(2, "h");
    if (date.isAfter(currentDate)) {
      return 0;
    } else return moment().add(2, "h").startOf("h");
  }, [date]);

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <div className="gh-dashboard-create-empty-leg-form">
        <Form.Item label="From" name="from_location">
          <AutocompleteInputHookForm
            placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
          />
        </Form.Item>

        <Form.Item label="To" name="to_location">
          <AutocompleteInputHookForm
            placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
          />
        </Form.Item>

        <Form.Item label="Date" name="date">
          <DatePicker placeholder="Date" isValidDate={isValidDate} />
        </Form.Item>

        <Form.Item
          label={staticService.findByAlias("helicopter")}
          name="aircraft_id"
          options={aircrafts}
        >
          <Select placeholder={staticService.findByAlias("pleaseSelectHelicopter")} />
        </Form.Item>

        <div className="gh-dashboard-create-empty-leg-row">
          <Form.Item label="From" name="from_time">
            <TimePicker
              placeholder="From"
              timeConstraints={{
                hours: { min: minFromToday, max: toTime },
                minutes: {
                  step: config.minuteStep,
                },
              }}
            />
          </Form.Item>

          <Form.Item label="To" name="to_time">
            <TimePicker
              placeholder="To"
              timeConstraints={{
                hours: { min: moment(fromTime).add(1, "h"), max: 23 },
                minutes: {
                  step: config.minuteStep,
                },
              }}
            />
          </Form.Item>
        </div>
      </div>

      <Button style={{ width: "100%", marginTop: 30 }} onClick={form.handleSubmit(onFormSubmit)}>
        Publish Empty Leg
      </Button>
    </Form>
  );
};

DashboardEmptyLegForm.defaultProps = {
  aircrafts: [],
};

DashboardEmptyLegForm.propTypes = {
  aircrafts: PropTypes.array,
  addEmptyLeg: PropTypes.func,
  modalRef: PropTypes.object,
  loadUserDashboardStatistic: PropTypes.func,
  resetEmptyLegs: PropTypes.func,
};

const mapStateToProps = (store) => ({
  aircrafts: store.aircrafts,
});

export default connect(mapStateToProps, {
  addEmptyLeg,
  loadUserDashboardStatistic,
  resetEmptyLegs,
})(DashboardEmptyLegForm);
