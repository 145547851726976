import PropTypes from "prop-types";
import React from "react";
import { parseUploadErrors, removeFileHanlder } from "./Upload.utils";
import removeIcon from "../../../../assets/img/svg/crossInCircle.svg";
import condStrings from "../../../../utils/condString";
import { uploadTemporaryDocument } from "../../../../actions";
import { showNotification } from "../../../../utils/showNotification";

function UploadFile(props) {
  const { file, updateFile, onRemoveSuccess, viewFile, readOnly } = props;
  const [loading, setLoading] = React.useState(false);

  const uploadHandler = async () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("file", file.originFileObj);

    await uploadTemporaryDocument(formData)()
      .then((res) => {
        setLoading(false);
        updateFile({
          ...res,
          uid: file.uid,
          file_data_local: file.file_data_local,
          upload: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        showNotification(parseUploadErrors(err?.data), "danger");
        onRemoveSuccess(file);
      });
  };

  const removeHandler = async () => {
    if (!file.error) {
      removeFileHanlder(file);
    }

    onRemoveSuccess(file);
  };

  const showFileFullsize = React.useCallback(
    (event) => {
      event.preventDefault();

      const fileType = file.id ? file.type : file.file_type;

      if (fileType?.includes("image")) {
        viewFile({
          file_url: file.file_data || file.file_data_local,
          file_name: file.file_name || file.name,
        });
      } else {
        window.open(file.file_data, "_blank");
      }
    },
    [file, viewFile],
  );

  // const imagePreviewThumb = React.useMemo(() => {
  //   // *** Disabled feature ***
  //   //
  //   // if (file.file_type.includes("image")) {
  //   //   return file.file_data_local || file.file_data;
  //   // }

  //   return "";
  // }, [file.file_type, file.file_data_local, file.file_data]);

  React.useEffect(() => {
    if (file.originFileObj && !file.error) {
      uploadHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={condStrings("upload-file", !!file.error && "is-error")}>
      {!readOnly && !loading && (
        <button type="button" className="upload-file__remove" onClick={removeHandler}>
          <img src={removeIcon} alt="Remove File" />
        </button>
      )}
      <div className="upload-file__preview">
        <span>{(file.file_name || file.name)?.split(".").pop()}</span>

        {loading && (
          <div className="upload-file__preview-inner upload-file__loading">
            <div uk-spinner="ratio: 0.8" />
          </div>
        )}
      </div>
      <div className="upload-file__caption">
        <h5 className="upload-file__title" title={file.file_name || file.name}>
          {file.file_name || file.name}
        </h5>
        <div className="upload-file__meta">
          {!file.error && (
            <a
              role="button"
              className={condStrings("upload-file__fullsize", loading && "is-disabled")}
              onClick={showFileFullsize}
            >
              {loading ? "Uploading..." : "View"}
            </a>
          )}
          {file.error && <span className="upload-file__warn">This file will be ignored.</span>}
        </div>
      </div>
    </div>
  );
}

UploadFile.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    uid: PropTypes.string,
    file_name: PropTypes.string,
    name: PropTypes.string,
    file_type: PropTypes.string,
    type: PropTypes.string,
    file_data: PropTypes.string,
    file_data_local: PropTypes.string,
    originFileObj: PropTypes.object,
    error: PropTypes.string,
  }),
  updateFile: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  viewFile: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default React.memo(UploadFile);
