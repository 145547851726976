import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import mail from "../../../assets/img/svg/invitaionMail.svg";
import close from "../../../assets/img/svg/crossInCircle.svg";

class InvitationFormElementComponent extends React.Component {
  constructor(props) {
    super(props);
    this.remove = this.remove.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.invitationsModel.length > nextProps.invitationsModel.length) {
      this.props.remove(this.props.index, nextProps.invitationsModel);
    }
  }

  remove() {
    this.context.store.dispatch(actions.remove("invitationsModel", this.props.index));
  }

  render() {
    if (this.props.visible) {
      return (
        <div className="gh-li-container">
          <div className="gh-list-card-item">
            <div className="gh-lc-content">
              <div className="gh-lc-icon">
                <span>
                  <img src={mail} alt="mail" />
                </span>
              </div>
              <div className="gh-lc-title">{this.props.member}</div>
            </div>
            <div className="gh-lc-close">
              <a className="uk-link-text" onClick={this.remove}>
                <img className={"close"} id={"close" + this.props.index} src={close} alt="close" />
              </a>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

InvitationFormElementComponent.propTypes = {
  remove: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  index: PropTypes.number,
  member: PropTypes.string.isRequired,
};

InvitationFormElementComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    form: state.forms.invitationsModel,
    invitationsModel: state.invitationsModel,
    email: state.userRegisterModel.email,
  };
};

const COMPONENT = connect(mapStateToProps)(InvitationFormElementComponent);
export { COMPONENT as InvitationFormElementComponent };
