import * as types from "../../actions/actionTypes";

function setQuoteDetail(state = {}, action) {
  switch (action.type) {
    case types.GET_QUOTE_DETAIL_BY_ID:
      return {
        ...state,
        originalQuoteDetail: action.quoteDetail,
        editingQuoteDetail: action.quoteDetail,
      };
    case types.UPDATE_QUOTE_DETAIL:
      return {
        ...state,
        editingQuoteDetail: action.updatedQuote,
      };
    case types.UPDATE_QUOTE_LEG_LOCATION: {
      return {
        ...state,
        editingQuoteDetail: {
          ...state.editingQuoteDetail,
          legs: state.editingQuoteDetail.legs.map((leg) => {
            if (leg.id === action.location.id) {
              return { ...leg, ...action.location };
            }

            return leg;
          }),
        },
      };
    }
    case types.CLEAR_QUOTE_DETAIL:
      return {
        ...state,
        originalQuoteDetail: {},
        editingQuoteDetail: {},
      };
    case types.UPDATE_EDITED_QUOTE_DETAIL:
      return {
        ...state,
        originalQuoteDetail: action.quote.originalQuote,
        editingQuoteDetail: action.quote.editingQuote,
      };
    case types.UPDATE_BOTH_QUOTE_OBJECTS:
      return {
        ...state,
        originalQuoteDetail: action.quoteDetail.original,
        editingQuoteDetail: action.quoteDetail.editing,
      };
    case types.RESET_QUOTE_DETAIL:
      return {
        ...state,
        originalQuoteDetail: action.quoteDetail,
        editingQuoteDetail: action.quoteDetail,
      };
    default:
      return state;
  }
}

export { setQuoteDetail };
