import React from "react";
import PropTypes from "prop-types";
import { camelize } from "../helpers";

export class PolylineComponent extends React.Component {
  componentWillMount() {
    this.marker = null;
    this.centerPosition = new google.maps.LatLngBounds();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map && this.marker !== null) ||
      JSON.stringify(this.props.position) !==
        JSON.stringify(prevProps.position && this.marker !== null)
    ) {
      this.renderMarker();
    }
  }

  componentDidMount() {
    if (this.props.map && this.props.position) {
      this.renderMarker();
    }
  }

  setPositionCenter(coordinates) {
    for (let i = 0; i < coordinates.length; i++) {
      this.centerPosition.extend(new google.maps.LatLng(coordinates[i].lat, coordinates[i].lng));
    }
  }

  renderMarker() {
    let { map, position } = this.props;
    this.marker = new google.maps.Polyline({
      strokeColor: this.props.strokeColor,
      strokeOpacity: 1.0,
      strokeWeight: 3,
      geodesic: true,
      path: position,
      map: map,
    });

    this.setPositionCenter(position);
    const evtNames = ["click", "mouseover", "mouseout"];

    evtNames.forEach((e) => {
      this.marker.addListener(e, this.handleEvent(e));
    });
  }

  handleEvent(evt) {
    return (e) => {
      const evtName = `on${camelize(evt)}`;
      if (this.props[evtName]) {
        this.props[evtName](this.props, this.marker, e, evt);
      }
    };
  }

  render() {
    return null;
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }
}

PolylineComponent.propTypes = {
  strokeColor: PropTypes.string,
  map: PropTypes.object,
  infoWindow: PropTypes.object,
  position: PropTypes.array,
  onLoad: PropTypes.func,
};

PolylineComponent.defaultProps = {
  strokeColor: "#242F42",
};
