import React from "react";
import { Redirect } from "react-router-dom";
import Auth from "../services/auth";
import { config } from "../configs";
import { findRouteSegment } from "../shared/helpers";
import { storage } from "../shared/storage";
import { fireTracking } from "../shared";

const RouteAuthorization =
  (allowedRoles, skipRoleValidation) =>
  (WrappedComponent, notAuthorizedMessage, redirect = false, options = {}) => {
    return class WithAuthorization extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          roles: allowedRoles || [],
          notFoundRedirect: false,
        };
        fireTracking(props.location.pathname);
      }

      componentDidMount() {
        if (options.validateId) {
          const validate =
            parseInt(this.props.match.params.id) || parseInt(this.props.match.params.quoteID);
          if (!(validate > 0)) {
            this.setState({ notFoundRedirect: true });
          }
        }

        if (options.validateSegment) {
          const validate = findRouteSegment(this.props.location.pathname, options.segment);
          if (validate) {
            this.setState({ notFoundRedirect: true });
          }
        }

        if (!Auth.isAuthenticated()) {
          storage.add("prevUrl", this.props.location.pathname);
        }
      }
      redirectToReview() {
        return <Redirect to={config.redirectToReviewAgreement} />;
      }
      render() {
        // check if user has to update his policy agreement with the platform

        if (
          Auth.activeSoleTrader === 0 &&
          this.props.path !== config.redirectToReviewAgreement &&
          this.props.path !== config.redirectToLogout
        ) {
          return this.redirectToReview();
        }
        if (
          (Auth.acceptance || Auth.apiAcceptance) &&
          this.props.path !== config.redirectToReviewAgreement &&
          this.props.path !== config.redirectToLogout
        ) {
          return this.redirectToReview();
        }
        if (this.state.notFoundRedirect) return <Redirect to={config.redirectToNotFound} />;
        if (
          Auth.isAuthenticated() &&
          (this.state.roles.includes(Auth.getRole()) || skipRoleValidation)
        ) {
          return <WrappedComponent {...this.props} />;
        } else {
          if (redirect && !Auth.isAuthenticated()) {
            return <Redirect to={config.redirectToLogin} />;
          }
          if (options.redirectTo) {
            return <Redirect to={options.redirectTo} />;
          }

          return <Redirect to={config.redirectToMainPage} />;
        }
      }
    };
  };

const AuthorizeGuest = (WrappedComponent) => {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      fireTracking(props.location.pathname);
    }
    render() {
      if (!Auth.isAuthenticated()) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to={config.redirectToIfNotAuthorized} />;
      }
    }
  };
};

const isBrokerRole = RouteAuthorization(["broker_team", "broker_admin", "sole_trader"]);
const isBrokerAdminOrOperatorAdminRole = RouteAuthorization([
  "broker_admin",
  "operator_admin",
  "sole_trader",
]);
const isOperatorRole = RouteAuthorization(["operator_team", "operator_admin"]);
const isOnline = RouteAuthorization([], true);

export {
  isBrokerRole,
  isOperatorRole,
  isOnline,
  isBrokerAdminOrOperatorAdminRole,
  AuthorizeGuest as isGuest,
};
