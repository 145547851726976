import React from "react";
import { Options } from "../Options";
import { useLegContext } from "./LegContext";

export default function LegOptions() {
  const { setIsHidden, isHidden, onRemove, config } = useLegContext();
  // const { activeAircraftIndex, updateForm } = useCalculatorContext();
  // const { watch, getValues } = useFormContext();
  // const [moveSelectionActive, setMoveSelectionActive] = React.useState(false);

  // const aircrafts = watch("aircrafts");
  // const moveToAircraft = React.useCallback(
  //   (legIndex, aircraftIndex) => () => {
  //     const legToMove = getValues(`aircrafts.${activeAircraftIndex}.legs.${legIndex}`);
  //     const targetAircraftLegs = getValues(`aircrafts.${aircraftIndex}.legs`);
  //
  //     updateForm([[`aircrafts.${aircraftIndex}.legs`, [...targetAircraftLegs, legToMove]]]);
  //
  //     notification(`Leg #${legIndex + 1} - moved to Aircraft #${aircraftIndex + 1}`, {
  //       status: "calculator",
  //       pos: "top-right",
  //     });
  //   },
  //   [activeAircraftIndex, getValues, updateForm],
  // );

  // const AircraftsToMove = React.useMemo(() => {
  //   if (aircrafts.length > 1) {
  //     return Array.from(Array(aircrafts.length)).map((_, idx) =>
  //       idx !== activeAircraftIndex ? (
  //         <Options.Item onClick={moveToAircraft(index, idx)} key={idx}>
  //           Aircraft {idx + 1}
  //         </Options.Item>
  //       ) : null,
  //     );
  //   }
  //
  //   return null;
  // }, [activeAircraftIndex, aircrafts.length, index, moveToAircraft]);

  return (
    <Options>
      {/*{AircraftsToMove && config.copy && (*/}
      {/*  <Accordion*/}
      {/*    header={<Options.Item>Move to Aircraft</Options.Item>}*/}
      {/*    isOpen={moveSelectionActive}*/}
      {/*    onToggle={() => setMoveSelectionActive(!moveSelectionActive)}*/}
      {/*  >*/}
      {/*    {AircraftsToMove}*/}
      {/*  </Accordion>*/}
      {/*)}*/}
      <Options.Item onClick={() => setIsHidden(!isHidden)}>
        {isHidden ? "Show" : "Hide"}
      </Options.Item>
      {config.remove && (
        <Options.Item onClick={onRemove} danger>
          Remove
        </Options.Item>
      )}
    </Options>
  );
}
