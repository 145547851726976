import React from "react";
import PropTypes from "prop-types";
import { camelize } from "../helpers";

const evtNames = ["ready", "click"];

class BasicMapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: new google.maps.LatLngBounds(),
      currentLocation: props.position,
      fitBound: false,
    };
    this.setLatLng = this.setLatLng.bind(this);
    this.googleMap = null;
  }

  componentWillUnmount() {
    this.googleMap = null;
  }

  componentWillReceiveProps(props) {
    if (
      props.totalMarkers === props.markers.length &&
      props.markers.length > 0 &&
      !this.state.fitBound &&
      props.isFitBoundEnabled
    ) {
      props.markers.forEach((marker) => this.state.bounds.extend(marker.getPosition()));
      this.googleMap.fitBounds(this.state.bounds);
      this.setState({ fitBound: true });
    }
  }

  setLatLng({ lat, lng }) {
    return new google.maps.LatLng(lat, lng);
  }

  loadMap() {
    if (this.map) {
      this.googleMap = new google.maps.Map(this.map, {
        zoom: this.props.zoom,
        clickableIcons: false,
        minZoom: this.props.minZoom,
        maxZoom: this.props.maxZoom,
        center: this.setLatLng(this.state.currentLocation),
        mapTypeId: google.maps.MapTypeId.satellite,
        disableDefaultUI: true,
      });

      evtNames.forEach((e) => {
        this.googleMap.addListener(e, this.handleEvent(e));
      });

      if (this.props.onLoad) this.props.onLoad(this.googleMap);

      google.maps.event.trigger(this.googleMap, "ready");
    }
  }

  handleEvent(evtName) {
    let timeout;
    const handlerName = `on${camelize(evtName)}`;
    return (e) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        if (this.props[handlerName]) {
          this.props[handlerName](this.props, this.googleMap, e);
        }
      }, 0);
    };
  }

  componentDidMount() {
    if (this.props.centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          this.setState({
            currentLocation: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            },
          });
        });
      }
    }

    this.loadMap();
  }

  renderChildren() {
    const { children } = this.props;
    if (!children) return;
    return React.Children.map(children, (c) => {
      if (!c) return;
      return React.cloneElement(c, {
        map: this.googleMap,
        mapCenter: this.state.currentLocation,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentLocation !== this.state.currentLocation) {
      this.recenterMap();
    }
  }

  recenterMap() {
    const map = this.googleMap;
    const curr = this.state.currentLocation;
    const maps = google.maps;

    if (map) {
      let center = new maps.LatLng(curr.lat, curr.lng);
      map.panTo(center);
    }
  }

  render() {
    return (
      <div ref={(map) => (this.map = map)}>
        Loading map...
        {this.renderChildren()}
      </div>
    );
  }
}

BasicMapComponent.propTypes = {
  zoom: PropTypes.number,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  position: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  centerAroundCurrentLocation: PropTypes.bool,
  isFitBoundEnabled: PropTypes.bool,
  markers: PropTypes.array,
  totalMarkers: PropTypes.number,
  onLoad: PropTypes.func,
};

BasicMapComponent.defaultProps = {
  zoom: 5,
  minZoom: 2,
  maxZoom: 15,
  markers: [],
  totalMarkers: 0,
  isFitBoundEnabled: true,
  centerAroundCurrentLocation: false,
};

export { BasicMapComponent };
