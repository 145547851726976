import React from "react";
import PropTypes from "prop-types";

import "./SuggestMessage.css";

const SuggestMessage = (props) => {
  const { message, onClick } = props;

  return (
    <div onClick={() => onClick(message)} className="gh-suggest-message">
      {message}
    </div>
  );
};

SuggestMessage.defaultProps = {
  message: "",
  onClick: () => {},
};

SuggestMessage.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
};

export default SuggestMessage;
