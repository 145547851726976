import Api from "../services/api-handler";
import * as types from "./actionTypes";

export const getAvailableCountries = () => async (dispatch) => {
  try {
    const countries = await Api.setProperty("skipErrorAlert", true)
      .setPath("available-countries", "")
      .getAll();
    dispatch({ type: types.GET_AVAILABLE_COUNTRIES, payload: countries });
    return Promise.resolve(countries);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const showAvailableCountriesModal = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({ type: types.SHOW_AVAILABLE_COUNTRIES_MODAL });
    return resolve();
  });

export const hideAvailableCountriesModal = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({ type: types.HIDE_AVAILABLE_COUNTRIES_MODAL });
    return resolve();
  });
