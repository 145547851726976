import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import googleApi from "../../../services/google.service";
import { updateQuoteDetail } from "../../../actions";
import AutocompleteInput from "../AutocompleteInput";
import LocationInfo from "../LocationInfo";
import ResetBtn from "../ResetBtn";
import staticService from "../../../services/static.service";
import generateLocationAdditionalInfo from "../../../utils/generateLocationAdditionalInfo";

import "./quoteLegLocation.css";

import yellowMarker from "../../../assets/img/svg/yellowMarker.svg";
import greenMarker from "../../../assets/img/svg/greenMarker.svg";
import oval from "../../../assets/img/svg/oval.svg";
const QuoteLegLocation = (props) => {
  const { leg, control, legIndex, originalQuote, updateDetailQuote, clearLocation } = props;

  const locationIcons = {
    gh_event: yellowMarker,
    hellipaddy: greenMarker,
    google: oval,
  };

  const handleUpdateLocation = async (value, keyDirection, onChange) => {
    clearLocation(keyDirection, leg.id, `is_${keyDirection}_edited`);
    if (!value.location) {
      return onChange(value);
    }

    if (!value.latitude && !value.longitude) {
      const coordinates = await googleApi
        .getCoordinatesByPlaceId(value.place_id)
        .then((result) => ({ latitude: result.lat, longitude: result.lng }));
      onChange(value.location);
      updateDetailQuote(keyDirection, leg.id, `is_${keyDirection}_edited`, {
        ...value,
        ...coordinates,
      });
    } else {
      onChange(value.location);
      updateDetailQuote(keyDirection, leg.id, `is_${keyDirection}_edited`, value);
    }
  };

  return (
    <div className="gh-legs-location-wrapper">
      <div className="gh-legs-location-wrapper-item">
        {leg.is_from_edited ? (
          <div className="gh-legs-autocomplete-wrapper gh-legs-autocomplete-wrapper-first">
            <img
              src={locationIcons[leg.from_source]}
              className="gh-leg-location-marker gh-legs-edit-img"
              alt="marker"
              style={{ marginRight: 10 }}
            />
            <Controller
              control={control}
              defaultValue={leg.from_location.name}
              name={`from${legIndex}`}
              rules={{
                required: {
                  value: true,
                  message: `From ${staticService.findByAlias("locationIsRequired")}`,
                },
              }}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <AutocompleteInput
                  inputRef={ref}
                  onChange={(value) => {
                    handleUpdateLocation(value, "from", onChange);
                  }}
                  value={value}
                  coverageWarning={leg.from_coverage_status}
                  fieldState={fieldState}
                  placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
                  label={staticService.findByAlias("from")}
                  tooltipSide="right"
                  debounce={200}
                  showFlagIcon={
                    leg.from_location.name !== originalQuote.legs[legIndex].from_location.location
                  }
                  secondaryInputText={
                    generateLocationAdditionalInfo(leg.from_location) || leg.from_info
                  }
                />
              )}
            />
            <div style={{ marginTop: 20 }}>
              <ResetBtn
                handleReset={() =>
                  updateDetailQuote("from", leg.id, "is_from_edited", {}, legIndex)
                }
              />
            </div>
          </div>
        ) : (
          <LocationInfo
            leg={leg}
            legIcon={leg.from_source}
            keyDirection="from"
            direction="from_location"
            additionalDetails={leg.from_location.details}
            handleSuggestAlternative={() => clearLocation("from", leg.id, "is_from_edited")}
          />
        )}
      </div>
      <div className="gh-legs-location-wrapper-item">
        {leg.is_to_edited ? (
          <div className="gh-legs-autocomplete-wrapper">
            <img
              src={locationIcons[leg.to_source]}
              className="gh-leg-location-marker gh-legs-edit-img"
              alt="marker"
              style={{ marginRight: 10 }}
            />
            <Controller
              control={control}
              defaultValue={leg.to_location.name}
              name={`to${legIndex}`}
              rules={{
                required: {
                  value: true,
                  message: `To ${staticService.findByAlias("locationIsRequired")}`,
                },
              }}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <AutocompleteInput
                  inputRef={ref}
                  onChange={(value) => {
                    handleUpdateLocation(value, "to", onChange);
                  }}
                  value={value}
                  coverageWarning={leg.to_coverage_status}
                  fieldState={fieldState}
                  placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
                  label={staticService.findByAlias("to")}
                  tooltipSide="right"
                  debounce={200}
                  showFlagIcon={
                    leg.to_location.name !== originalQuote.legs[legIndex].to_location.location
                  }
                  secondaryInputText={
                    leg.to_info || generateLocationAdditionalInfo(leg.to_location)
                  }
                />
              )}
            />
            <div style={{ marginTop: 20 }}>
              <ResetBtn
                handleReset={() => updateDetailQuote("to", leg.id, "is_to_edited", {}, legIndex)}
              />
            </div>
          </div>
        ) : (
          <LocationInfo
            leg={leg}
            legIcon={leg.to_location.source}
            keyDirection="to"
            direction="to_location"
            additionalDetails={leg.to_location.details}
            handleSuggestAlternative={() => clearLocation("to", leg.id, "is_to_edited")}
          />
        )}
      </div>
    </div>
  );
};

QuoteLegLocation.propTypes = {
  leg: PropTypes.object,
  control: PropTypes.object,
  legIndex: PropTypes.number,
  clearLocation: PropTypes.func,
  originalQuote: PropTypes.object,
  updateDetailQuote: PropTypes.func,
};

export default connect(null, { updateQuoteDetail })(QuoteLegLocation);
