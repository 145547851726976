import React, { Component } from "react";
import { actions } from "react-redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import auth from "../../../../../services/auth";
import { ModalComponent, alertInstance, CustomValidateError } from "../../../../../shared";
import { AddFormComponent } from "./";
import { inviteMembers } from "../../../../../actions";
import staticService from "../../../../../services/static.service";
import FlagIcon from "../../../../common/FlagIcon";
import Tooltip from "../../../../common/Tooltip";
import Button from "../../../../common/Button";

const modelName = "inviteMembersModel";

class AddMembersComponent extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.addEmailField = this.addEmailField.bind(this);
    this.submit = this.submit.bind(this);
    this.resetServerErrors = this.resetServerErrors.bind(this);
    this.state = {
      email: auth.getUser().email,
      formVisible: true,
      errors: [],
      modalTitle: "inviteNewMember",
    };
  }

  closeModal() {
    this.modal.hide();
    this.resetServerErrors();
  }

  resetServerErrors() {
    this.setState({ errors: [], formVisible: true, modalTitle: "inviteNewMember" });
  }

  openModal() {
    this.modal.open();
    this.context.store.dispatch(actions.reset(modelName + ".emails"));
    this.context.store.dispatch(actions.reset("userEmailModel"));
  }

  addEmailField() {
    const existEmail = this.props.inviteMembersModel.emails.filter(
      (row) => row.email === this.props.userEmailModel.email,
    );
    if (existEmail.length === 0) {
      this.context.store.dispatch(actions.push(modelName + ".emails", this.props.userEmailModel));
      this.context.store.dispatch(actions.reset("userEmailModel"));
    }
  }

  removeEmailField(index) {
    const fields = this.props.inviteMembersModel.emails.filter((item, i) => index !== i);
    this.context.store.dispatch(actions.change(modelName + ".emails", fields));
  }

  onError(err) {
    const keys = Object.keys(err.data);
    const errors = keys.map((key) => {
      return {
        error: err.data[key],
        email: this.props.userEmailModel.email,
      };
    });
    let formVisible = this.state.formVisible;
    let modalTitle = "inviteNewMember";
    if (err.status === 432) {
      formVisible = false;
      modalTitle = "";
    }
    this.setState({ errors: errors, formVisible: formVisible, modalTitle });
  }

  submit() {
    const emails = this.Emails;
    if (emails.length > 0) {
      this.props.sendInvitations(
        { emails: emails },
        this.handleInvitationsSent.bind(this),
        this.onError.bind(this),
      );
    } else {
      alertInstance.eventInstance.trigger("global", {
        visible: true,
        message: staticService.findByAlias("invitationValidation"),
        type: alertInstance.errorProp,
      });
    }
  }

  handleInvitationsSent(invitations) {
    this.context.store.dispatch(actions.reset(modelName));
    this.setState({ errors: [] });
    this.closeModal();
    alertInstance.eventInstance.trigger("global", {
      visible: true,
      message: staticService.findByAlias("onSuccessInvitationPost"),
      type: alertInstance.successProp,
    });
    this.props.onModalClose(invitations);
  }

  get modalError() {
    return !this.state.formVisible ? (
      <div className={"validation-errors uk-alert gh-alert-without-bg"}>
        <div
          className={"gh-font-size uk-text-center"}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias(this.state.errors[0].error[0]),
          }}
        />
        <div className={"uk-margin-medium-top uk-text-center"}>
          <a className={"uk-button uk-button-secondary "} onClick={this.closeModal}>
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("close") }} />
          </a>
        </div>
      </div>
    ) : (
      ""
    );
  }

  get Emails() {
    const emails = this.props.inviteMembersModel.emails.map((mail) => mail);
    if (this.props.userEmailModel.email !== "") {
      emails.push(this.props.userEmailModel);
    }
    return emails;
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("userEmailModel"));
  }

  render() {
    const isAllowToInviteTeamMember = auth.hasFeature("invite_team_member");

    return (
      <div className={"uk-padding-remove-vertical"}>
        <div className="uk-flex uk-align-right">
          <Tooltip
            pos="top-center"
            refEl="self"
            show={!isAllowToInviteTeamMember && auth.isBroker()}
            trigger={
              <Button
                disabled={!isAllowToInviteTeamMember && auth.isBroker()}
                onClick={this.openModal}
              >
                {staticService.findByAlias("inviteNewMember")}
                <span className="gh-padding-small-left" data-uk-icon="icon: plus" />
              </Button>
            }
          >
            {staticService.findByAlias("notTeamMemberWarning")}
          </Tooltip>
        </div>

        <ModalComponent
          title={staticService.findByAlias(this.state.modalTitle)}
          ref={(modal) => (this.modal = modal)}
          id={"invite-members-modal"}
          onClose={this.closeModal}
          modalProps={{ className: "uk-text-left" }}
          options={{ clsPage: "", bgClose: false, escClose: false }}
        >
          <AddFormComponent
            submit={this.submit.bind(this)}
            closeModal={this.closeModal}
            visible={this.state.formVisible}
            form={this.props.form}
            errors={this.state.errors}
            removeEmailField={this.removeEmailField.bind(this)}
            resetServerErrors={this.resetServerErrors.bind(this)}
            openModal={this.openModal}
            teamMembers={this.props.teamMembers}
            userEmailModel={this.props.userEmailModel}
            inviteMembersModel={this.props.inviteMembersModel}
            addEmailField={this.addEmailField}
          />
          {this.modalError}
        </ModalComponent>
      </div>
    );
  }
}

AddMembersComponent.contextTypes = {
  store: PropTypes.object,
};

AddMembersComponent.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};

const mapStateToProps = ({ inviteMembersModel, userEmailModel, teamMembers, errorObj, forms }) => {
  return {
    inviteMembersModel,
    userEmailModel,
    teamMembers,
    errorObj,
    form: forms.userEmailModel.$form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendInvitations: inviteMembers,
      toggleModal: (type, state) => dispatch({ type: type, visible: state }),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(AddMembersComponent);
export { COMPONENT as AddMembersComponent };
