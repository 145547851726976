import React from "react";
import PropTypes from "prop-types";
import { errorInstance, IconComponent } from "../../index";

class AlertErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    errorInstance.onChange = () => this.forceUpdate();
  }

  close() {
    errorInstance.close();
    this.forceUpdate();
  }

  render() {
    if (errorInstance.isVisible) {
      return (
        <div className={"app-alert"} style={{ position: "fixed" }}>
          <header>
            <IconComponent
              visible={true}
              icon={"close"}
              id={"close"}
              onClick={this.close}
              className={"close"}
            />
          </header>
          <ul className={"disabled-list"}>
            {errorInstance.Errors.map((error, i) => (
              <li key={i} dangerouslySetInnerHTML={{ __html: error }} />
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }
}

AlertErrorComponent.contextTypes = {
  store: PropTypes.object,
};

export { AlertErrorComponent };
