import React from "react";
import calculations from "./services/calculations";

const CalculatorContext = React.createContext();

export const useCalculatorContext = () => React.useContext(CalculatorContext);

export const staticDefaultPilotValue = {
  name: "",
  is_included: "1",
  payment_type: "1",
  payment_amount: "",
  pilot_shift_duration: "",
  payment_tax: { sum: "", percent: "" },
  total_pilot_costs: "",
};

export const defaultPilotValue = () => {
  const companyDefaults = JSON.parse(window.sessionStorage.getItem("company-quote-defaults"));

  if (companyDefaults) {
    const pilot = {
      ...staticDefaultPilotValue,
      is_included: companyDefaults.pilot.is_pilot_included,
      payment_type: companyDefaults.pilot.pilot_rate_period,
      payment_amount: companyDefaults.pilot.pilot_rate_amount,
      pilot_shift_duration: companyDefaults.pilot.pilot_duty,
      payment_tax: {
        sum: "",
        percent: companyDefaults.pilot.pilot_tax || companyDefaults.company_vat,
      },
      total_pilot_costs: "0",
    };

    const pilotTotal = calculations.pilot(pilot);

    return {
      ...pilot,
      total_pilot_costs: pilotTotal.total_pilot_costs || "",
    };
  }

  return staticDefaultPilotValue;
};

export const defaultLegValue = {
  name: "",
  category: 2,
  from: {},
  to: {},
  distance: "",
  headwind: "",
  flight_duration: "",
  extra_time: "",
  total_duration: "",
  price: "",
  flight_tax: {
    sum: "",
    percent: "",
  },
  total_pilots_costs: "",
  total_cost_flight: "",
  distance_last_update_by: "locations",
  duration_last_update_by: "locations",
  departure_fee_amount: "",
  departure_fee_tax: {
    percent: "",
    sum: "",
  },
  landing_fee_amount: "",
  landing_fee_tax: {
    percent: "",
    sum: "",
  },
  total_fees: "",
  extra_cost_amount: "",
  extra_cost_notes: "",
  extra_cost_tax: {
    percent: "",
    sum: "",
  },
  extra_cost_total: "",
  operator_notes: "",
  total_cost_leg: "",
};

export default CalculatorContext;
