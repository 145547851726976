import PropTypes from "prop-types";
import React, { useState } from "react";
import cs from "../../../../utils/condString";
import "./ColorPicker.css";
import useClickOutside from "../../../../hooks/useClickOutside";
import { validateHexColor } from "../../../../utils/validateHexColor";
import { validateColorName } from "../../../../utils/validateColorName";

const ColorPicker = React.forwardRef((props, ref) => {
  const {
    className,
    disabled,
    name,
    value,
    placeholder,
    onChange,
    pattern,
    defaultColors,
    ...rest
  } = props;

  const [showColors, setShowColors] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [lastValidValue, setLastValidValue] = useState(value);

  const domNode = useClickOutside(() => setShowColors(false));

  const onChangeHandler = (e) => {
    const value = e.target.value;

    if (validateHexColor(value) || validateColorName(value)) {
      setLastValidValue(value);
      setInputError(false);
    } else {
      setInputError(true);
    }

    if (pattern) onChange(pattern(value));
    else onChange(e);
  };

  const handleSelectColor = (color) => {
    setLastValidValue(color);
    onChange(color);

    if (pattern) onChange(pattern(color));
    else onChange(color);
  };

  const handleOnBlur = (e) => {
    const value = e.target.value;
    if (!validateHexColor(value) || !validateColorName(value)) {
      onChange(lastValidValue);
      setInputError(false);
    }
  };

  return (
    <div className="gh-color-picker uk-flex">
      <div
        className="gh-color-picker-dropdown-btn"
        onClick={() => {
          setShowColors(true);
        }}
      >
        <div className="gh-color-picker-dropdown-btn --color" style={{ backgroundColor: value }} />
        <span uk-icon={`icon: chevron-${showColors ? "up" : "down"}; ratio: 2.5`} />

        <div ref={domNode} className={`gh-color-list ${showColors ? "--show" : ""}`}>
          {defaultColors.map(({ color }, index) => (
            <div
              className="gh-color-item"
              key={color + index}
              style={{ backgroundColor: color }}
              onClick={() => handleSelectColor(color)}
            >
              {color === value ? <span uk-icon="icon: check" /> : ""}
            </div>
          ))}
        </div>
      </div>

      <div className="gh-wrapper-input-color">
        <input
          id={name}
          name={name}
          value={value}
          ref={ref}
          placeholder={placeholder}
          className={cs(className, "uk-input")}
          disabled={disabled}
          onChange={onChangeHandler}
          onBlurCapture={handleOnBlur}
          {...rest}
        />
        {inputError ? <span className="gh-input-color-warning">!</span> : ""}
      </div>
    </div>
  );
});

ColorPicker.displayName = "ColorPicker";

ColorPicker.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  pattern: PropTypes.func,
  defaultColors: PropTypes.array,
};

ColorPicker.defaultProps = {
  size: "md",
  value: "",
};

export default ColorPicker;
