import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Form } from "../../../../../../Form";
import Input from "../../../../../../Form/components/Input";
import Radio from "../../../../../../Form/components/RadioButton";
import Button from "../../../../../../common/Button";
import useYupResolver from "../../../../../../../hooks/useYupResolver";

import "./ExternalAppRulesForm.css";

const ExternalAppRulesForm = (props) => {
  const { setAppRules, handleCloseRuleModal } = props;

  const [showTypeError, setShowTypeError] = useState(false);

  const externalAppRulesSchema = yup.object().shape({
    parameter: yup.string().required("Page URL is required"),
  });

  const form = useForm({
    resolver: useYupResolver(externalAppRulesSchema),
  });

  const handleChangeType = (value) => {
    setShowTypeError(false);
    return value;
  };

  const onSubmit = (values) => {
    if (!values.type) {
      return setShowTypeError(true);
    }

    setAppRules((prevState) => [...prevState, values]);
    handleCloseRuleModal();
  };

  return (
    <Form form={form} id="external-app-rules">
      <div data-uk-grid className="uk-form-controls uk-margin-top uk-margin-bottom">
        <Form.Item className="uk-width-1-1" label="Page URL" name="parameter">
          <Input placeholder="/example" />
        </Form.Item>
      </div>

      <div data-uk-grid className="uk-form-controls uk-margin-top ">
        <Form.Item label="Type" name="type">
          <Radio
            className="uk-flex-column uk-flex-top"
            pattern={handleChangeType}
            values={[
              {
                label: "Matches",
                value: "includes",
                className: "uk-margin-small-bottom",
              },
              {
                label: "Does not match",
                value: "no_includes",
                className: "uk-margin-small-bottom",
              },
              {
                label: "Exact",
                value: "exact",
              },
            ]}
          />
        </Form.Item>
      </div>
      {showTypeError ? <div className="gh-message-error">Type is required</div> : ""}

      <hr className="gh-divider" />

      <div className="uk-flex uk-flex-right">
        <Button onClick={form.handleSubmit(onSubmit)}>Add</Button>
      </div>
    </Form>
  );
};

ExternalAppRulesForm.propTypes = {
  setAppRules: PropTypes.func,
  handleCloseRuleModal: PropTypes.func,
};

export default ExternalAppRulesForm;
