import eventIcon from "../../assets/img/svg/eventIcon.svg";
import hotelIcon from "../../assets/img/svg/hotelIcon.svg";
import cutleryIcon from "../../assets/img/svg/cutlery.svg";
import landingSiteIcon from "../../assets/img/svg/landingLetter.svg";
import planeIcon from "../../assets/img/svg/plane.svg";
import apoiIcon from "../../assets/img/svg/circleIcon.svg";
import eventSecondary from "../../assets/img/svg/eventSecondary.svg";
import hotelSecondary from "../../assets/img/svg/hotelSecondary.svg";
import restaurantSecondary from "../../assets/img/svg/restaurantSecondary.svg";
import landingSecondary from "../../assets/img/svg/mapMarkerSecondary.svg";
import planeSecondary from "../../assets/img/svg/planeSecondary.svg";
import apoiSecondary from "../../assets/img/svg/apoiSecondary.svg";
import heliSecondary from "../../assets/img/svg/heliSecondary.svg";
import heliSecondaryUnverified from "../../assets/img/svg/heliSecondaryUnverified.svg";
import eventPrimary from "../../assets/img/svg/evenPrimary.svg";
import hotelPrimary from "../../assets/img/svg/hotelPrimary.svg";
import restaurantPrimary from "../../assets/img/svg/restaurantPrimary.svg";
import landingPrimary from "../../assets/img/svg/mapMarkerPrimary.svg";
import planePrimary from "../../assets/img/svg/planePrimary.svg";
import apoiPrimary from "../../assets/img/svg/apoiPrimary.svg";
import { storage } from "..";
import staticService from "../../services/static.service";
import auth from "../../services/auth";

export function returnNormalPlaceType(type) {
  if (type) {
    if (type.toLowerCase().includes("event")) return "Event";
    if (type.toLowerCase().includes("point")) return "Point of Interest";

    return type;
  }
}
export function placeTypeIcon(type) {
  switch (type) {
    case "Event":
      return eventIcon;
    case "Hotel":
      return hotelIcon;
    case "Restaurant":
      return cutleryIcon;
    case "Landing Site":
      return landingSiteIcon;
    case "Aerodrome":
      return planeIcon;
    case "Aerial Point of Interest":
      return apoiIcon;
    default:
      return apoiIcon;
  }
}

export function secondaryIcon(type) {
  switch (type) {
    case "Event":
      return eventSecondary;
    case "Hotel":
      return hotelSecondary;
    case "Restaurant":
      return restaurantSecondary;
    case "Landing Site":
      return landingSecondary;
    case "Aerodrome":
      return planeSecondary;
    case "Aerial Point of Interest":
      return apoiSecondary;
    case "Heli":
      return heliSecondary;
    case "HeliUnverified":
      return heliSecondaryUnverified;
    default:
      return apoiSecondary;
  }
}

export function primaryIcon(type) {
  switch (type) {
    case "Event":
      return eventPrimary;
    case "Hotel":
      return hotelPrimary;
    case "Restaurant":
      return restaurantPrimary;
    case "Landing Site":
      return landingPrimary;
    case "Aerodrome":
      return planePrimary;
    case "Aerial Point of Interest":
      return apoiPrimary;
    default:
      return apoiPrimary;
  }
}

export function areCoordinatesTheSame(location1, location2) {
  if (location1 === location2) return true;
  if (!location1 || !location2) return false;

  const [lat1, lng1] = location1.split("|").map(Number);
  const [lat2, lng2] = location2.split("|").map(Number);

  return lat1 === lat2 && lng1 === lng2;
}

export function getLandingSitesMarkers(legs = []) {
  const sites = JSON.parse(storage.get("landingSites")) || [];

  return legs.map((leg) => {
    const updatedLegInfo = {
      from: {},
      to: {},
    };

    let legTo, legFrom;

    const padTo = {
      leg: {
        to_type: leg.to_location?.type || leg.to_type,
        to_source: leg.to_location?.source || leg.to_source,
      },
    };

    const padFrom = {
      leg: {
        from_type: leg.from_location?.type || leg.from_type,
        from_source: leg.from_location?.source || leg.from_source,
      },
    };

    if (leg.from_location?.latitude) {
      padFrom.leg.from_coords = `${leg.from_location.latitude}|${leg.from_location.longitude}`;
    } else {
      padFrom.leg.from_coords = leg.from_coords;
    }

    if (leg.to_location?.latitude) {
      padTo.leg.to_coords = `${leg.to_location.latitude}|${leg.to_location.longitude}`;
    } else {
      padTo.leg.to_coords = leg.to_coords;
    }

    if ((leg.to_location && leg.to_location.type) || leg.to_type) {
      legTo = sites
        .filter((site) =>
          areCoordinatesTheSame(
            `${site.location.latitude}|${site.location.longitude}`,
            padTo.leg.to_coords,
          ),
        )
        .find(
          (site) =>
            site.source === padTo.leg.to_source ||
            site.category.display_name.toLowerCase() === padTo.leg.to_type.toLowerCase(),
        );
    }
    if ((leg.from_location && leg.from_location.type) || leg.from_type) {
      legFrom = sites
        .filter((site) =>
          areCoordinatesTheSame(
            `${site.location.latitude}|${site.location.longitude}`,
            padFrom.leg.from_coords,
          ),
        )
        .find(
          (site) =>
            site.source === padFrom.leg.from_source ||
            site.category.display_name.toLowerCase() === padFrom.leg.from_type.toLowerCase(),
        );
    }

    if (legTo) {
      updatedLegInfo.to = {
        name: legTo.name,
        type: returnNormalPlaceType(legTo.category.display_name),
        category: returnNormalPlaceType(legTo.category.display_name),
        rating: legTo.rating,
        image: legTo.upload && legTo.upload.file_url,
        reviews: legTo.comments_count,
        source: legTo.source || "hellipaddy",
        start_date: legTo.start_date,
        end_date: legTo.end_date,
        view_link: legTo.view_link,
        direction: "to",
      };
    } else {
      updatedLegInfo.to = {
        name: leg.to,
        type: returnNormalPlaceType(leg.to_type || (leg.to_location && leg.to_location.type)),
        source: "google",
        direction: "to",
      };
    }

    if (legFrom) {
      updatedLegInfo.from = {
        name: legFrom.name,
        type: returnNormalPlaceType(legFrom.category.display_name),
        category: returnNormalPlaceType(legFrom.category.display_name),
        rating: legFrom.rating,
        image: legFrom.upload && legFrom.upload.file_url,
        reviews: legFrom.comments_count,
        source: legFrom.source || "hellipaddy",
        start_date: legFrom.start_date,
        end_date: legFrom.end_date,
        view_link: legFrom.view_link,
        direction: "from",
      };
    } else {
      updatedLegInfo.from = {
        name: leg.from,
        type: returnNormalPlaceType(leg.from_type || (leg.from_location && leg.from_location.type)),
        source: "google",
        direction: "from",
      };
    }

    return updatedLegInfo;
  });
}

export function generateChosenSiteText(dir, chooseByRole = false) {
  if (auth.getMainRole() === "operator" && chooseByRole) {
    if (dir === "from") {
      return staticService.findByAlias("operatorChosenTakeOff");
    } else {
      return staticService.findByAlias("operatorChosenLanding");
    }
  } else {
    if (dir === "from") {
      return staticService.findByAlias("brokerChosenTakeOff");
    } else {
      return staticService.findByAlias("brokerChosenLanding");
    }
  }
}
