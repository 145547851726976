import React from "react";
import PropTypes from "prop-types";

const Icon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-329 -159) translate(23 159) translate(306)">
            <circle cx="10" cy="10" r="10" fill={color[0]} />
            <path
              fill={color[1]}
              fillRule="nonzero"
              d="M10.584 5.448c.405 0 .728-.101.968-.304.24-.203.36-.475.36-.816 0-.363-.12-.645-.36-.848-.24-.203-.563-.304-.968-.304-.373 0-.683.101-.928.304-.245.203-.368.485-.368.848 0 .341.123.613.368.816.245.203.555.304.928.304zm-1.84 9.696c.192 0 .395-.019.608-.056.213-.037.424-.088.632-.152.208-.064.405-.139.592-.224.187-.085.35-.176.488-.272v-.544h-1.12L11.384 7l-.288-.192c-.224 0-.483.019-.776.056-.293.037-.59.083-.888.136-.299.053-.581.115-.848.184-.267.07-.48.136-.64.2v.544l.992.224-1.088 4.96c-.053.245-.096.47-.128.672-.032.203-.048.41-.048.624 0 .181.077.35.232.504.155.155.435.232.84.232z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Icon.defaultProps = {
  color: ["#6D7481", "#fff"],
};

Icon.propTypes = {
  color: PropTypes.arrayOf(PropTypes.string),
};

export default Icon;
