import React from "react";
import PropTypes from "prop-types";
import "./LearnMoreVetting.css";

import arrow from "../../../assets/img/svg/arrowInCircle.svg";
import staticService from "../../../services/static.service";

const LearMoreVetting = ({ vetting, className }) => {
  const list = [
    { key: "", title: "getHeliEvaluate", text: "getHeliEvaluateText" },
    {
      key: "company_registration",
      title: "companyRegistrationTitle",
      text: "companyRegistrationText",
    },
    { key: "aoc_evidence", title: "aqeEvidence", text: "aqeEvidenceText" },
    {
      key: "helicopter_on_aoc",
      title: "helicopterOnAocTitle",
      text: "helicopterOnAocText",
    },
    {
      key: "helicopter_insurance",
      title: "helicopterInsuranceTitle",
      text: "helicopterInsuranceText",
    },
  ];
  return (
    <div className={`gh-vetting-more ${className}`}>
      {list.map((item, index) => (
        <div
          key={"key" + index}
          className="gh-vm-more"
          style={{
            display:
              item.key === "helicopter_insurance" &&
              vetting &&
              vetting.helicopter_insurance === false
                ? "none"
                : "block",
          }}
        >
          <div className="gh-vm-more-head">
            <span>
              <img src={arrow} alt="arrow" />
            </span>
            <h4
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias(item.title),
              }}
            />
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(item.text),
            }}
          />
        </div>
      ))}
    </div>
  );
};
LearMoreVetting.propTypes = {
  className: PropTypes.string,
  vetting: PropTypes.object,
};

export default LearMoreVetting;
