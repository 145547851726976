import React from "react";
import PropTypes from "prop-types";
import { BlockComponent } from "../block.component";
import "./SecondFooter.css";
import airCharterAssociation from "../../../assets/img/home/aca-logo.png";
import staticService from "../../../services/static.service";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const SecondFooter = (props) => {
  const {
    menuItems: { footer },
  } = props;

  const handleRedirect =
    (url, anchor = "") =>
    () => {
      localStorage.setItem("scroll", anchor);
      window.location.href = url;
    };

  return (
    <BlockComponent
      visible={true}
      parentProps={{
        className:
          "uk-section uk-section-default uk-padding-small uk-padding-remove-left uk-padding-remove-right",
      }}
    >
      <div className="gh-footer-row">
        <div className="gh-members">
          <h4 className="gh-footer-title">{staticService.findByAlias("ghProudHomeText")}</h4>
          <div className="gh-members-wrapper">
            <div className="gh-member">
              <img src={airCharterAssociation} alt="The Air Charter Association" />
            </div>
          </div>
        </div>

        <div className="gh-footer-side-menu">
          <div className="gh-footer-side-menu-col">
            <div className="gh-footer-side-menu-subtitle">
              {staticService.findByAlias("aboutMenu")}
            </div>
            <ul className="gh-footer-side-menu-list">
              <li className="gh-footer-side-menu-item">
                <span onClick={handleRedirect("/", "gh-why-choose")} className="whyChooseLink link">
                  {staticService.findByAlias("whyGetHeliMenu")}
                </span>
              </li>
              <li className="gh-footer-side-menu-item">
                <a href="/media" className="link">
                  {staticService.findByAlias("mediaMenu")}
                </a>
              </li>
              <li className="gh-footer-side-menu-item">
                <a href="/contact" className="link">
                  {staticService.findByAlias("contactUsMenu")}
                </a>
              </li>
            </ul>
          </div>

          <div className="gh-footer-side-menu-col">
            <div className="gh-footer-side-menu-subtitle">
              {staticService.findByAlias("popularDestinations")}
            </div>
            <ul className="gh-footer-side-menu-list">
              {footer?.destinations.map((destination) => (
                <li className="gh-footer-side-menu-item" key={destination.url}>
                  <a href={`/destinations/${destination.url}`} className="link">
                    {destination.location_name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="gh-footer-side-menu-col">
            <div className="gh-footer-side-menu-subtitle">
              {staticService.findByAlias("upcomingEvents")}
            </div>
            <ul className="gh-footer-side-menu-list">
              {footer?.events.map((event) => (
                <li className="gh-footer-side-menu-item" key={event.url}>
                  <a href={`/events/${event.url}`} className="link">
                    {event.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="gh-footer-side-menu-col">
            <div className="gh-footer-side-menu-subtitle">
              {staticService.findByAlias("techSolutionsMenu")}
            </div>
            <ul className="gh-footer-side-menu-list">
              <li className="gh-footer-side-menu-item">
                <span onClick={handleRedirect("/pricingcalculator")} className="link">
                  {staticService.findByAlias("theGetHeliApiMenu")}
                </span>
              </li>
              <li className="gh-footer-side-menu-item">
                <a href="/helicoptermarketplace" className="link">
                  {staticService.findByAlias("theGetHeliMarketplaceMenu")}
                </a>
              </li>
              <li className="gh-footer-side-menu-item">
                <NavLink to="/account/login" className="link">
                  {staticService.findByAlias("resetPasswordRedirect")}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BlockComponent>
  );
};

SecondFooter.propTypes = {
  menuItems: PropTypes.object,
};

const mapStateToProps = (store) => ({
  menuItems: store.menuItems,
});

export default connect(mapStateToProps)(SecondFooter);
