import React, { useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { routes } from "./configs/routes";
import { RouteWithSubRoutes } from "./shared";
import UserLeaveConfirmation from "./components/common/UserLeaveConfirmation";

const AppRouter = () => {
  const [confirmOpen, setConfirmOpen] = useState(true);

  return (
    <Router
      getUserConfirmation={(modalName, callback) => {
        return UserLeaveConfirmation(modalName, callback, confirmOpen, setConfirmOpen);
      }}
    >
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
  );
};

export default AppRouter;
