import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import filtersIcon from "../../../../assets/img/svg/filters-icon.svg";
import useYupResolver from "../../../../hooks/useYupResolver";
import { prepareQueryStringFromObj } from "../../../../shared";
import Button from "../../../common/Button";
import { Form } from "../../../Form";
import Input from "../../../Form/components/Input";

const pipelineFiltersResolver = yup.object({
  keyword: yup.string(),
});

export default function BrokerPipeLinesFilters({ onChange }) {
  const [areFiltersVisible, setAreFiltersVisible] = React.useState(false);

  const form = useForm({ resolver: useYupResolver(pipelineFiltersResolver) });

  const onSubmit = React.useCallback(
    (values) => {
      onChange(prepareQueryStringFromObj(values));
    },
    [onChange],
  );

  const resetFilters = React.useCallback(() => {
    form.setValue("keyword", "");
    onChange(prepareQueryStringFromObj({ keyword: "" }));
  }, [form, onChange]);

  return (
    <React.Fragment>
      <div className="uk-flex uk-flex-right uk-margin-small-top">
        <a
          style={{
            color: "#242f42",
            fontWeight: 500,
          }}
          onClick={(e) => {
            e.preventDefault();
            setAreFiltersVisible(!areFiltersVisible);
          }}
        >
          <span style={{ marginRight: 5 }}>{areFiltersVisible ? "Close" : "Filters"}</span>{" "}
          <img src={filtersIcon} alt="filters-icon" />
        </a>
      </div>
      {areFiltersVisible && (
        <div className="uk-card uk-card-default uk-card-body uk-margin-small-top">
          <Form
            form={form}
            onSubmit={onSubmit}
            className="uk-flex uk-flex-center uk-flex-column uk-flex-middle uk-container-xsmall uk-margin-auto"
          >
            <Form.Item label="Find a lead" name="keyword" className="uk-width-1-1">
              <Input placeholder="ID, Client Name, Email, Phone" />
            </Form.Item>
            <div data-uk-grid className="uk-margin-top">
              <div className="uk-flex uk-flex-middle uk-flex-center uk-width-expand@s">
                <Button
                  color="secondary"
                  type="button"
                  onClick={resetFilters}
                  style={{ backgroundColor: "rgba(223, 226, 232, 1)" }}
                >
                  Reset
                </Button>
              </div>
              <div className="uk-flex uk-flex-middle uk-flex-center uk-width-expand@s">
                <Button type="submit">Search</Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

BrokerPipeLinesFilters.propTypes = {
  onChange: PropTypes.func,
};
