export const generateSecondaryText = (address) => {
  const country = address.country ? (address.country === "GB" ? "UK" : address.country) : "";
  return [address.county, country].filter(Boolean).join(", ");
};

/**
 * @name generateLocationAdditionalInfo
 *
 * @param {object} address
 * @param {number} address.latitude
 * @param {string} address.type
 * @param {string} address.category
 * @param {string} address.county
 * @param {string} address.country
 * @param {string} address.secondary_text
 * @param {string} address.source
 * @param {string} address.name
 *
 * @returns {string} Location additional info
 */
export default (address) => {
  if (!address) return "";

  const latitude = address?.lat || address?.latitude;
  const placeID = address.place_id;

  const getCategory = () => {
    const category = address.type || address.category;

    if (address.source === "gh_event") return "Event";
    return category;
  };

  const getSecondaryText = () => {
    if (address.secondary_text) {
      return `${address.secondary_text || ""}`;
    }

    return generateSecondaryText(address);
  };

  if (
    (latitude && (address.category || address.type)) ||
    (placeID && (address.category || address.type))
  ) {
    return `${getCategory()}|${getSecondaryText(address)}`;
  }

  return "";
};
