import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";

export function DescriptionItem(props) {
  const { className, children, as, type, prefix, separator, suffix } = props;

  return (
    <div
      className={cs("calculator__description-item", className, type, as, separator && "separator")}
    >
      {prefix && <span className="description-prefix">{prefix}</span>}
      {children}
      {suffix && <span className="description-suffix">{suffix}</span>}
    </div>
  );
}

DescriptionItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  as: PropTypes.oneOf(["text", "input", "custom"]),
  type: PropTypes.oneOf(["header", "footer", "info", "placeholder"]),
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  separator: PropTypes.bool,
};

DescriptionItem.defaultProps = {
  as: "input",
};
