import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";

const QuotePriceVatRow = ({ vat, currency, title, show }) => {
  if (!show) return null;
  return (
    <div className="gh-quote-details-details-row gh-quote-details-quote-price-details-row">
      <div className="gh-quote-details-text-normal gh-quote-details-value">{title}</div>
      <div className="gh-quote-details-text-normal gh-quote-details-value">
        <span className="gh-quote-details-title-bold">
          {currency} {vat}{" "}
        </span>
        {staticService.findByAlias("vatTitle")}
      </div>
    </div>
  );
};
QuotePriceVatRow.propTypes = {
  currency: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  show: PropTypes.bool,
};
QuotePriceVatRow.defaultProps = {
  show: true,
};
export default QuotePriceVatRow;
