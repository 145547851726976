import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import staticService from "../../../../../services/static.service";
import Button from "../../../../common/Button";
import { Form } from "../../../../Form";
import Textarea from "../../../../Form/components/Textarea";
import { RequestDetailsFooterContext } from "./RequestDetailsFooter";
import * as yup from "yup";
import useYupResolver from "../../../../../hooks/useYupResolver";
import handleHookFormErrors from "../../../../../utils/handleHookFormErrors";
import Auth from "../../../../../services/auth";
import InputNumber from "../../../../Form/components/InputNumber";
import { connect } from "react-redux";
import Modal from "../../../../common/Modal";

const RequestMoreInformationAction = (props) => {
  const { banking } = props;

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    return () => {
      body[0].style.overflow = "auto";
    };
  }, []);

  const operatorQuestion = yup.object().shape({
    body: yup
      .string()
      .required("This field is required")
      .min(4, "Must be 4 or more characters!")
      .max(700, "Must be 700 or fewer characters!"),
  });

  const form = useForm({
    resolver: useYupResolver(operatorQuestion),
  });

  const {
    editingQuoteDetail: { booking_request, helicopters },
    originalQuoteDetail,
    needMoreInformation,
    history,
    showAdditionalButtons,
    setShowPrompt,
  } = React.useContext(RequestDetailsFooterContext);

  const defaultHelicopter = useMemo(() => helicopters[0], [helicopters]);

  const hideComponent =
    parseInt(originalQuoteDetail.quotes_per_company) > 0 ||
    originalQuoteDetail.is_declined ||
    originalQuoteDetail.empty_leg_id;

  const hasRequestDetails =
    booking_request?.is_need_more_information_set || booking_request?.more_detail_requests;

  const hasFeatureOperatorAskAQuestion = Auth.hasFeature("operator_ask_a_question");

  const isButtonDisabled = hasRequestDetails || !hasFeatureOperatorAskAQuestion;

  const tooltipMessage = !hasFeatureOperatorAskAQuestion
    ? "featureNotActivatedContactGetheli"
    : null;

  const [loading, setLoading] = React.useState(false);

  const modalRef = React.useRef();

  const handleOpenModal = () => modalRef.current.openModal();

  const handleCloseModal = () => modalRef.current.closeModal();

  const onSubmit = React.useCallback(({ body, quick_price }) => {
    if (hasRequestDetails) return;
    setShowPrompt(false);
    setLoading(true);
    const data = { body: body, quick_price: quick_price };

    needMoreInformation(booking_request.id, data)
      .then((res) => {
        setLoading(false);
        history.push("/marketplace");
        return res;
      })
      .catch((err) => {
        const { data } = err;
        if (data.errors) {
          handleHookFormErrors(form.setError, data.errors);
        }
        setLoading(false);
        return err;
      });
  }, []);

  if (!showAdditionalButtons) return null;

  return (
    <>
      <Button
        disabled={isButtonDisabled}
        onClick={handleOpenModal}
        variant="outlined"
        tooltipProps={{ message: tooltipMessage }}
        className="gh-request-more-info-button"
      >
        {staticService.findByAlias("needMoreInformation")}
      </Button>
      <Modal
        onClose={handleCloseModal}
        title={staticService.findByAlias("askYourQuestionModalTitle")}
        ref={modalRef}
      >
        <Form form={form} onSubmit={onSubmit}>
          {/*<div className="uk-margin-bottom">*/}
          {/*  <label className="uk-form-label">{staticService.findByAlias("helicopter")}</label>*/}
          {/*  <div>{defaultHelicopter.name || ""}</div>*/}
          {/*</div>*/}

          <Form.Item name="body" label={staticService.findByAlias("askYourQuestionLabel")}>
            <Textarea style={{ minHeight: 90 }} placeholder="Your message..." />
          </Form.Item>

          <div className="uk-flex">
            <Form.Item name="quick_price" label={staticService.findByAlias("quickPriceLabel")}>
              <InputNumber className="uk-input" />
            </Form.Item>
            <span className="uk-flex uk-flex-middle uk-margin-small-left">
              {banking && banking.currency ? banking.currency : ""}
            </span>
          </div>

          <div className="uk-flex uk-flex-middle uk-flex-between uk-margin-top">
            <Button color="gray" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button color="success" type="submit" loading={loading}>
              Send
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

RequestMoreInformationAction.propTypes = {
  setShowPrompt: PropTypes.func,
  banking: PropTypes.object,
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(RequestMoreInformationAction);
