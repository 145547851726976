import React from "react";
import uid from "./uid";

export default (str) =>
  typeof str === "string"
    ? str
        .replace(/(<br>|<br \/>|<br\/>)/g, "\n")
        .split("\n")
        .map((str) => (
          <p className="uk-margin-remove" key={uid().uid}>
            {str}
          </p>
        ))
    : str;
