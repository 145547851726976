import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  getTaxAmount,
  n,
  precision,
} from "../../../packages/PriceCalculator/services/calculations/formulas";
import { formatNumber, ModalComponent } from "../../../shared";
import { InputNumber } from "../../../packages/PriceCalculator/components/Input/InputNumber";
import { useFullQuoteContext } from "../../pages/ViewFullQuoteDetails/ViewFullQuoteContext";
import "../../../packages/PriceCalculator/components/OperatorMargin/index.css";
import "./DownloadPDFModal.css";
import Button from "../Button";
import auth from "../../../services/auth";
import { config } from "../../../configs";
import RadioButtonComponent from "../RadioButtonComponent";
import staticService from "../../../services/static.service";
import Tooltip from "../Tooltip";
import { connect } from "react-redux";
import ErrorMessageComponent from "../ErrorMessageComponent";

const pdfPath = config.pdfPath || "https://pdf-staging.getheli.com/";

const DownloadPDFModal = ({ modalRef, quoteDetail, quoteId, banking }) => {
  const { feeSelected } = useFullQuoteContext();
  const quoteCosts = React.useMemo(
    () => (quoteDetail[feeSelected] && quoteDetail[feeSelected].total_without_vat) || 0,
    [quoteDetail, feeSelected],
  );

  const initialStates = {
    quoteCostsTotal: quoteCosts || "0",
    taxInput: { sum: 0, percent: 0, vat: 0 },
    comments: "",
    includedMargin: false,
    showOperatorNameInPdf: false,
    errorMessage: { vat: "" },
  };

  const [quoteCostsTotal, setQuoteCostsTotal] = useState(initialStates.quoteCostsTotal);
  const [taxInput, setTaxInput] = useState(initialStates.taxInput);
  const [comments, setComments] = useState(initialStates.comments);
  const [includedMargin, setIncludedMargin] = useState(initialStates.includedMargin);
  const [showOperatorNameInPdf, setShowOperatorNameInPdf] = useState(
    initialStates.showOperatorNameInPdf,
  );
  const [errorMessage, setErrorMessage] = useState(initialStates.errorMessage);
  const commentsRef = useRef();

  const currency = React.useMemo(
    () => (quoteDetail && quoteDetail.currency && quoteDetail.currency.currency) || "",
    [quoteDetail],
  );

  const clearVatErrorMessage = React.useCallback(
    () => setErrorMessage((prevState) => ({ ...prevState, vat: "" })),
    [setErrorMessage],
  );

  const closeModal = () => modalRef.current.hide();

  const onCommentsChange = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + 12 + "px";

    setComments(e.target.value);
  };

  const resetStates = () => {
    setQuoteCostsTotal(initialStates.quoteCostsTotal);
    setTaxInput(initialStates.taxInput);
    setComments(initialStates.comments);
    setIncludedMargin(initialStates.includedMargin);
    setShowOperatorNameInPdf(initialStates.showOperatorNameInPdf);
    setErrorMessage(initialStates.errorMessage);
  };

  const resetCommentsSection = () => {
    commentsRef.current.style.height = "58px";
    setComments("");
    closeModal();
  };

  const onChangeHandler = React.useCallback(
    (type) => (event) => {
      const value = event.replace(",", "");
      let newValue = {};
      let sum = null;

      if (type === "percent") {
        if (value > 100) return;
        sum = precision((n(quoteCosts) / 100) * value);
        newValue.percent = value;
        newValue.sum = sum;
      } else if (type === "vat") {
        // enter manually VAT value
        if (n(value) > taxInput.sum) {
          setErrorMessage((prevState) => ({
            ...prevState,
            vat: staticService.findByAlias("vatShouldBeSmallerThanTotal"),
          }));
        } else clearVatErrorMessage();
        newValue = { ...taxInput, vat: value };
      }

      // assign calculated VAT value
      if (sum) {
        const bankingVatValue = banking?.vat_value ?? 0;
        const vat = precision((sum / 100) * Number(bankingVatValue), 2);
        newValue.vat = n(vat);
        clearVatErrorMessage();
      }

      setTaxInput(newValue);
      const quote_costs_total = getTaxAmount(quoteCosts, newValue.percent);
      setQuoteCostsTotal(quote_costs_total);
    },
    [quoteCosts, taxInput, setTaxInput, setQuoteCostsTotal],
  );

  const handleChangeIncludedMargin = (value) => {
    setIncludedMargin(value);
  };

  const isAllowToAddMargin = useMemo(
    () => auth.hasFeature("add_broker_margin_to_the_pdf"),
    [auth.hasFeature("add_broker_margin_to_the_pdf")],
  );

  const isAllowedOperatorNameInPdf = useMemo(
    () => auth.hasFeature("show_operator_name_in_pdf"),
    [auth.hasFeature("show_operator_name_in_pdf")],
  );

  const isAllowEditShowIncludedMargin = useMemo(
    () => auth.hasFeature("is_allow_to_edit_show_included_margin"),
    [auth.hasFeature("is_allow_to_edit_show_included_margin")],
  );

  const isSubmitButtonDisabled = useMemo(() => !!errorMessage.vat, [errorMessage]);

  const brokerMarginValue = useMemo(
    () => formatNumber(taxInput.sum?.toString()),
    [taxInput, formatNumber],
  );

  React.useEffect(() => {
    const quote_costs_total = getTaxAmount(quoteCosts, taxInput.percent);
    setQuoteCostsTotal(quote_costs_total);
  }, [quoteCosts]);

  const redirectToPDF = () => {
    const access_token = auth.getAccount("access_token") || "";
    const pdfPathParams = new URLSearchParams(window.location.search);

    const broker_vat = parseFloat(taxInput.vat || "0").toFixed(2);

    pdfPathParams.append("entity_type", "quote");
    pdfPathParams.append("entity_id", quoteId);
    pdfPathParams.append("broker_margin", taxInput.sum);
    pdfPathParams.append("broker_vat", broker_vat);
    pdfPathParams.append("comments", comments);
    pdfPathParams.append("access_token", access_token);
    pdfPathParams.append("included_margin", includedMargin.toString());
    pdfPathParams.append("show_operator_name_in_pdf", showOperatorNameInPdf.toString());

    window.open(`${pdfPath}preview?${pdfPathParams.toString()}`, "_blank");

    resetCommentsSection();
    resetStates();
  };

  return (
    <div className="gh-download-pdf-modal-wrapper">
      <ModalComponent onClose={resetStates} ref={modalRef} modalClass="" id="download-pdf-modal">
        <div className="calculator__operator-margin gh-download-pdf-calculator__operator-margin">
          <div className="operator-margin__section gh-download-pdf-operator-margin__section uk-margin-small-right">
            <h4 className="operator-margin__subtitle gh-download-pdf-operator-margin__subtitle">
              {staticService.findByAlias("pdfModalQuotePrice")}
            </h4>
            <h2 className="operator-margin__title operator-margin-title-pdf">
              {currency} {formatNumber(quoteCosts.toString())}
            </h2>
          </div>

          <Tooltip
            pos="top-center"
            wrapperClassName="gh-tooltip-download-pdf"
            show={!isAllowToAddMargin}
            trigger={
              <div className="operator-margin__section operator-margin__section-tax-inputs gh-download-pdf-operator-margin__section">
                <h4 className="operator-margin__subtitle gh-download-pdf-operator-margin__subtitle">
                  Broker Margin
                </h4>
                <div className="tax-input tax-input-pdf-column">
                  <div className="tax-input-pdf-row">
                    <div className="tax-input__part gh-pdf-modal-margin-percent">
                      <InputNumber
                        disabled={!isAllowToAddMargin}
                        placeholder="[0.00]"
                        value={taxInput.percent}
                        onChange={onChangeHandler("percent")}
                        suffix={
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
                          </svg>
                        }
                      />
                      <span className="gh-color-primary gh-download-pdf-operator-margin__subtitle">
                        ({currency} {brokerMarginValue} excl.
                      </span>
                    </div>
                    <div className="tax-input__part gh-pdf-modal-margin-input">
                      <div className="gh-pdf-modal-vat-input">
                        <InputNumber
                          disabled={!isAllowToAddMargin}
                          placeholder="[0.00]"
                          value={taxInput.vat}
                          onChange={onChangeHandler("vat")}
                          invalid={!!errorMessage.vat}
                        />
                        <ErrorMessageComponent
                          visibility={!!errorMessage.vat}
                          className="gh-pdf-modal-input-error"
                        >
                          {errorMessage.vat}
                        </ErrorMessageComponent>
                      </div>
                      <span className="gh-color-primary gh-download-pdf-operator-margin__subtitle">
                        {staticService.findByAlias("vatLabel")})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            {staticService.findByAlias("featureIsntEnabledTooltip")}
          </Tooltip>

          <div className="operator-margin__section gh-download-pdf-operator-margin__section">
            <h4 className="operator-margin__subtitle gh-download-pdf-operator-margin__subtitle">
              {staticService.findByAlias("pdfModalTotalQuotePrice")}
            </h4>
            <h2 className="operator-margin__title operator-margin-title-pdf">
              {currency} {formatNumber(quoteCostsTotal.toString())}
            </h2>
          </div>
        </div>

        <div className="gh-radio-wrapper-pdf-modal">
          <label className="uk-form-label">
            {staticService.findByAlias("includedBrokerMargin")}
          </label>

          <Tooltip
            pos="top-right"
            wrapperClassName="gh-tooltip-download-pdf"
            show={!isAllowEditShowIncludedMargin || !isAllowToAddMargin}
            trigger={
              <RadioButtonComponent
                selected={includedMargin}
                handleChange={handleChangeIncludedMargin}
                disabled={!isAllowEditShowIncludedMargin || !isAllowToAddMargin}
                items={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
            }
          >
            {staticService.findByAlias("featureIsntEnabledTooltip")}
          </Tooltip>
        </div>

        <div className="gh-radio-wrapper-pdf-modal">
          <label className="uk-form-label">
            {staticService.findByAlias("displayOperatorNameInThePdf")}
          </label>

          <Tooltip
            pos="top-right"
            wrapperClassName="gh-tooltip-download-pdf"
            show={!isAllowedOperatorNameInPdf}
            trigger={
              <RadioButtonComponent
                id={"showOperatorNameInPdfRadio"}
                selected={showOperatorNameInPdf}
                handleChange={setShowOperatorNameInPdf}
                disabled={!isAllowedOperatorNameInPdf}
                items={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
            }
          >
            {staticService.findByAlias("featureIsntEnabledTooltip")}
          </Tooltip>
        </div>

        <div className="uk-margin-bottom">
          <label className="uk-form-label">
            {staticService.findByAlias("commentsOfTheCustormer")}
          </label>
          <textarea
            ref={commentsRef}
            className="uk-input"
            value={comments}
            onChange={onCommentsChange}
            placeholder="Type your comments..."
            style={{
              lineHeight: 1.2,
              padding: "8px 10px",
              minHeight: 58,
              overflow: "hidden",
              resize: "none",
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-flex uk-flex-center">
          <Button
            className="gh-download-pdf-button"
            onClick={redirectToPDF}
            disabled={isSubmitButtonDisabled}
          >
            <DownloadSVG />
            Download as PDF
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};
DownloadPDFModal.propTypes = {
  modalRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  quoteDetail: PropTypes.object,
  quoteId: PropTypes.number,
  banking: PropTypes.object,
};
const mapStateToProps = ({ dashboard }) => ({
  banking: dashboard.banking,
});
export default connect(mapStateToProps)(DownloadPDFModal);

const DownloadSVG = () => {
  return (
    <div className="gh-download-svg-wrapper">
      <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <path d="m256 362.667969c-8.832031 0-16-7.167969-16-16v-330.667969c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v330.667969c0 8.832031-7.167969 16-16 16zm0 0" />
        <path d="m256 362.667969c-4.097656 0-8.191406-1.558594-11.308594-4.695313l-85.332031-85.332031c-6.25-6.25-6.25-16.382813 0-22.636719 6.25-6.25 16.382813-6.25 22.636719 0l74.023437 74.027344 74.027344-74.027344c6.25-6.25 16.386719-6.25 22.636719 0 6.25 6.253906 6.25 16.386719 0 22.636719l-85.335938 85.332031c-3.15625 3.136719-7.25 4.695313-11.347656 4.695313zm0 0" />
        <path d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 32.363281-26.304688 58.667969-58.667969 58.667969zm0 0" />
      </svg>
    </div>
  );
};
