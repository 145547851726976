import React from "react";
import staticService from "../../../services/static.service";
import check from "../../../assets/img/svg/tabCheck.svg";
import roundCheck from "../../../assets/img/svg/check.svg";
import PropTypes from "prop-types";

const RegisterTabs = (props) => {
  const { view, step, screen, tabs } = props;
  const additionalList = ["invitation", "fleet", "upload", "coc"];

  return (
    <ul
      className="uk-container gh-register-tabs"
      style={{ justifyContent: step < 3 ? "flex-start" : "space-between" }}
    >
      {tabs.map((tab, index) => {
        const condition = step < 3 && additionalList.includes(tab.title);
        const passed = step > index + 1;
        const current = view === tab.title;

        return (
          <li
            key={index}
            className={`gh-register-tab ${current ? "active" : ""}`}
            style={{
              display: condition ? "none" : "flex",
              marginRight: step < 3 ? "10px" : "0",
            }}
          >
            {screen > 768 && passed && (
              <div className="gh-rt-icon">
                <img src={check} alt="check" />
              </div>
            )}
            <div className="gh-rt-content">
              <span className={`gh-rt-number ${passed ? "passed" : ""} ${current ? "active" : ""}`}>
                <span>{index + 1}</span>
                {screen < 768 && passed && (
                  <span>
                    <img src={roundCheck} alt="check" />
                  </span>
                )}
              </span>
              <span
                className={`gh-rt-title ${passed ? "passed" : ""} ${current ? "active" : ""} ${
                  screen < 768 && step > 2 && !current ? "uk-hidden" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias(tab.alias) }}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};

RegisterTabs.propTypes = {
  view: PropTypes.string,
  step: PropTypes.number,
  screen: PropTypes.number,
  tabs: PropTypes.array,
};

export { RegisterTabs };
