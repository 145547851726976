import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";

class AlertComponent extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      visible: false,
      message: "",
      type: "success",
    };

    this.clear = this.clear.bind(this);
    this.mount = this.mount.bind(this);
    this.hide = this.hide.bind(this);
    this.mounted = true;
  }

  componentDidMount() {
    this.mount();
  }

  mount() {
    if (this.mounted) {
      if (this.props.alertInstance.eventInstance) {
        this.props.alertInstance.eventInstance.setEvent(this.props.event, (event, data) => {
          this.setState({ visible: data.visible, message: data.message, type: data.type });
          if (data.visible) {
            this.timer = setTimeout(() => {
              this.hide();
              if (
                window.location.pathname.includes("/flights/requests/") &&
                data.type !== "success"
              ) {
                window.location.reload();
              }
            }, this.props.time || 5000);
          }
        });
      }
    }
  }

  hide() {
    this.setState({ visible: false, message: "", type: "success" });
  }

  clear() {
    clearTimeout(this.timer);
  }

  get position() {
    return this.props.isRelative ? "uk-position-relative" : "uk-position-fixed";
  }

  render() {
    const classNames = ["gh-alert", "uk-text-center", this.position];
    if (this.state.visible) {
      return (
        <div className={classNames.join(" ")}>
          <div className={"uk-alert uk-alert-" + this.state.type} data-uk-alert>
            <a
              className="uk-alert-close opacity-8"
              onClick={this.hide}
              data-uk-icon={"icon: close"}
            />
            <p dangerouslySetInnerHTML={{ __html: this.state.message }} />
          </div>
        </div>
      );
    }
    return null;
  }

  componentWillUnmount() {
    this.clear();
    this.mounted = false;
    this.props.alertInstance.eventInstance = null;
  }
}

AlertComponent.propTypes = {
  isRelative: PropTypes.bool,
  time: PropTypes.number,
  event: PropTypes.string,
  alertInstance: PropTypes.object,
};

export { AlertComponent };
