import React from "react";
import PropTypes from "prop-types";
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { RouteWithSubRoutes, returnRouteUrl, TabComponent, storage } from "../../../../shared";
import { accountTabs, bankingTabs } from "../../../../configs";
import { VerificationComponent } from "../team-components";
import {
  BlockComponent,
  ComponentAuthorization,
  IsVerifiedAuthorization,
} from "../../../../components";
import { AccountOnlineLayout } from "../../../layouts";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";
import { MenuComponent, UserProfileComponent } from "../common";
import auth from "../../../../services/auth";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class BrokerBankingComponent extends UserProfileComponent {
  constructor(props) {
    super(props);
    const pages = [...accountTabs];
    const brokerTabs = pages.map((page) => {
      if (page.title === "Banking") {
        return { ...page, path: "/account/banking/cards" };
      } else {
        return page;
      }
    });
    const items = brokerTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      selectedTab: bankingTabs[0],
      tabs: items,
    };
    this.getActive = this.getActive.bind(this);
    this.verifiedTabs = this.verifiedTabs.bind(this);
  }

  componentDidMount() {
    if (auth.hasFeature("store_payment_cards") || auth.hasFeature("store_payment_card")) {
      return this.props.history.push(bankingTabs[1].path);
    }
    return this.props.history.push(bankingTabs[2].path);
  }

  onTabClick(tab, ev) {
    ev.preventDefault();
    this.setState({ selectedTab: tab });
  }

  getActive(loopEl) {
    if (loopEl.alias === this.state.selectedTab.alias) {
      return " uk-active";
    }
    return "";
  }

  verifiedTabs(tabs) {
    if (auth.getUser().hasApiKey === false) {
      return tabs.filter((tab) => tab.alias !== "credentials");
    }
    if (!auth.hasFeature("store_payment_cards") && !auth.hasFeature("store_payment_card")) {
      return tabs.filter((tab) => tab.alias === "transactions");
    }
    return tabs;
  }

  requestButtonProps() {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  get requestBtn() {
    return (
      <div className="uk-width-1-1">
        <ComponentAuthorization
          component={IsVerifiedAuthorization}
          componentProps={this.requestButtonProps()}
          roles={["broker_admin", "broker_team", "sole_trader"]}
        />
      </div>
    );
  }

  render() {
    return (
      <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
        <section className={"team-page"}>
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom header-title",
            }}
          >
            <div className="uk-width-1-1">
              <h3
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("account"),
                }}
              />
            </div>
            {this.requestBtn}
          </BlockComponent>
          <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
            <TabComponent
              items={this.state.tabs}
              id={"profile-tab"}
              visible={true}
              tabProps={{ className: "uk-tab uk-margin" }}
              onClick={this.onTabClick.bind(this)}
              selected={this.state.selectedTab}
              linkType={"nav"}
            />
            <div data-uk-grid>
              <div className="uk-width-1-1@s uk-width-1-3@m">
                <div className="uk-width-3-4 uk-margin-small-top">
                  <MenuComponent items={this.verifiedTabs(bankingTabs)} />
                </div>
              </div>
              <div className="uk-width-3-1@s uk-width-2-3@m">
                <Switch>
                  {this.props.routes &&
                    this.props.routes.map((route, i) => {
                      return (
                        <RouteWithSubRoutes
                          key={i}
                          title={route.title}
                          {...returnRouteUrl(route, this.props.match.url)}
                        />
                      );
                    })}
                  <Redirect from="/company/banking" exact to="/company/banking/cards" />
                  <Route path="/" component={VerificationComponent} />
                </Switch>
              </div>
            </div>
          </BlockComponent>
        </section>
      </AccountOnlineLayout>
    );
  }
}

BrokerBankingComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ screenDimensions, accountUpdated }) {
  return {
    accountUpdated: accountUpdated,
    ...screenDimensions,
  };
}

const COMPONENT = connect(mapStateToProps)(BrokerBankingComponent);
export { COMPONENT as BrokerBankingComponent };
