import React from "react";
import moment from "moment";
import { config } from "../configs";

/**
 * @param customTimestampFormat {object} Time format.
 * @param customTimestampFormat.time Time format.
 * @param customTimestampFormat.date Date format.
 * @param initialTimestamp {string}
 */
const useTime = (initialTimestamp = "", customTimestampFormat = {}) => {
  const [timestamp, setTimestamp] = React.useState(initialTimestamp);
  const [timestampFormat, setTimestampFormat] = React.useState({
    time: customTimestampFormat?.time || config.timeFormat,
    date: customTimestampFormat?.date || config.dateFormat,
  });

  const momentTimestamp = moment(timestamp);

  let date = React.useMemo(
    () => momentTimestamp.format(timestampFormat.date),
    [momentTimestamp, timestampFormat],
  );

  let utc = React.useMemo(
    () => ` (UTC${momentTimestamp.format("Z")})`,
    [momentTimestamp, timestampFormat],
  );

  let time = React.useMemo(
    () => momentTimestamp.format(timestampFormat.time),
    [momentTimestamp, timestampFormat],
  );

  let timeUtc = React.useMemo(
    () => momentTimestamp.format(timestampFormat.time) + utc,
    [momentTimestamp, timestampFormat, utc],
  );

  if (!timestamp) {
    time = "";
    date = "";
    utc = "";
  }

  return { date, time, timeUtc, utc, setTimestamp, setTimestampFormat, formattedTimestamp };
};

export default useTime;

/**
 * Function to manually format the timestamp.
 * @param {string} timestampParam - date and time in ISO format.
 * @param {string} [timeFormatParam] - time format for moment.
 * @param {string} [dateFormatParam] - date format for moment.
 */
export const formattedTimestamp = (timestampParam, timeFormatParam, dateFormatParam) => {
  if (!timestampParam) return { date: "", time: "", timeUtc: "" };
  const momentTimestamp = moment(timestampParam);
  const date = momentTimestamp.format(dateFormatParam || config.dateFormat);
  const time = momentTimestamp.format(timeFormatParam || config.timeFormat);
  const timeUtc = `${momentTimestamp.format(
    timeFormatParam || config.timeFormat,
  )} (UTC${momentTimestamp.format("Z")})`;
  return { date, time, timeUtc };
};
