import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { config } from "../../../configs";
import staticService from "../../../services/static.service";

const BrokerLastSeen = ({ dateAndTime, show }) => {
  if (!show) return "";
  if (!dateAndTime) return <div>{staticService.findByAlias("notSeenYet")}</div>;
  const date = moment(dateAndTime).format(config.dateFormat);
  const time = moment(dateAndTime).format(config.timeFormat);
  return (
    <>
      <div>{date}</div>
      <div>at {time}</div>
    </>
  );
};

BrokerLastSeen.propTypes = { dateAndTime: PropTypes.string.isRequired, show: PropTypes.bool };
BrokerLastSeen.defaultProps = { show: true };
export default BrokerLastSeen;
