import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { QUOTE_OPEN } from "../../../../../configs";
import Auth from "../../../../../services/auth";
import { aircraftTitle } from "../../../../../shared/";
import staticService from "../../../../../services/static.service";
import { BrokerQuoteInfo } from "./broker-quote-info.component";
import withdrawn from "../../../../../assets/img/withdrawn-badge.png";
import on_hold from "../../../../../assets/img/on_hold_badge.png";
import LiveChatComponent from "../../../../common/LiveChatComponent/liveChatComponent";
import OperatorCompanyBanner from "../../../../common/OperatorCompanyBanner/operatorCompanyBanner";
import Button from "../../../../common/Button";
import { bindActionCreators } from "redux";
import { REMOVE_QUOTE_ITEM } from "../../../../../actions";
import ImageSlider from "../../../../common/ImageSlider";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ExpirationBanner from "../../../../common/ExpirationBanner";

class BrokerQuoteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      status: QUOTE_OPEN,
      quote: props.quote || {},
      selectedHeli: 0,
    };
    this.onSelect = this.onSelect.bind(this);
    this.getAircraft = this.getAircraft.bind(this);
    this.showPrice = this.showPrice.bind(this);
    this.changeSliderHeli = this.changeSliderHeli.bind(this);
  }

  onSelect(message) {
    this.props.onCancel(message);
  }

  toggleDialogState(status) {
    if (this.props.action) this.props.action(status, this.props.quote);
  }

  actions(isQuoteExpired) {
    const {
      onSelectPath,
      quote,
      viewFullQuoteOnClick,
      removeQuotes,
      history,
      brokerIsAllowedToEdit,
    } = this.props;

    const handleClick = () => {
      const path = viewFullQuoteOnClick
        ? viewFullQuoteOnClick
        : `${onSelectPath}/quote/${quote.id}`;

      if (removeQuotes) {
        removeQuotes();
      }
      history.push(path);
    };

    return (
      //  fix chat alignment, after project deps update
      <div className="/*uk-text-center uk-text-right@m*/ gh-quote-buttons-box">
        <div className="gh-quote-buttons">
          <div className="gh-bok-dec-buttons">
            <Button
              onClick={handleClick}
              type="button"
              variant="outlined"
              style={{ whiteSpace: "nowrap" }}
              className="gh-quote-button"
              disabled={isQuoteExpired}
            >
              {staticService.findByAlias("viewFullQuoteButton")}
            </Button>
          </div>

          {!this.props.hideChat && (
            <LiveChatComponent
              quoteId={quote.id}
              titleAlias="quoteDiscussionBroker"
              disabled={!brokerIsAllowedToEdit}
              commentsCount={quote.comments_count}
            />
          )}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      this.props.quoteUpdate.status !== nextProps.quoteUpdate.status &&
      nextProps.quoteUpdate.status === "open"
    ) {
      this.toggleDialogState();
    }
  }

  getAircraft(aircraft, index) {
    Auth.getMainRole();
    if (parseInt(aircraft.premium) > 0 && !this.props.skipPremium) {
      return (
        <a key={index} onClick={this.props.gallery.bind(this, aircraft)}>
          <span
            dangerouslySetInnerHTML={{
              __html: aircraftTitle(aircraft, this.props.showTail),
            }}
          />
          {index !== this.props.quote.aircrafts.length - 1 ? ", " : ""}
        </a>
      );
    }

    return (
      <span key={index}>
        <span
          dangerouslySetInnerHTML={{
            __html: aircraftTitle(aircraft, this.props.showTail),
          }}
        />
        {index !== this.props.quote.aircrafts.length - 1 ? ", " : ""}
      </span>
    );
  }

  showPrice(quote) {
    return (
      <span>
        <span>{quote.user_currency.currency} </span>
        {quote.user_price_formatted + " "}
        incl. <span>{quote.user_currency.currency} </span>
        {quote.user_vat_formatted} VAT
      </span>
    );
  }

  changeSliderHeli(index) {
    this.setState({ selectedHeli: index });
  }

  render() {
    const {
      visible,
      quote,
      showPad,
      hideOnHoldOverlay,
      displayBanner,
      displayConnectBorders,
      hideBorderBottom,
      quoteIndex,
      operIndex,
    } = this.props;
    const { selectedHeli } = this.state;

    const isQuoteOnHold = !hideOnHoldOverlay && quote.status === "on_hold";
    const isQuoteWithdraw = quote.status === "withdrawn" && Auth.isBroker();
    const isQuoteExpired = quote.expiration_time && moment().isAfter(quote.expiration_time);

    return visible ? (
      <div className="uk-background-default gh-margin-small-top gh-quote-card">
        {!!quote.is_amend_notified && (
          <div className="gh-quote-card_amend-alert uk-alert-warning uk-text-uppercase">
            Amended Quote
          </div>
        )}

        {displayBanner && (
          <OperatorCompanyBanner
            operatorCompany={quote.operator_company}
            quoteIndex={quoteIndex}
            operId={quote.operator}
            operIndex={operIndex}
            // absolute={quote.status === "withdrawn" || quote.status === "on_hold"}
          />
        )}

        {quote.expiration_time && !isQuoteOnHold && !isQuoteWithdraw ? (
          <ExpirationBanner date={quote.expiration_time} />
        ) : (
          ""
        )}

        <div
          className={`${Auth.getMainRole() === "broker" ? "gh-quote-wrapper" : ""} ${
            displayConnectBorders ? "gh-quote-border" : ""
          }`}
          style={{
            borderBottom: hideBorderBottom
              ? 0
              : Auth.getMainRole() === "broker"
              ? "1px solid #242E41"
              : 0,
          }}
        >
          {isQuoteOnHold ? (
            <div className="gh-quote-card-overlay">
              <div className="gh-quote-withdrawn">
                <div className="info">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("quoteOnHoldStatus"),
                    }}
                  />
                  <hr className="line" />
                  <span
                    className="reason-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("reasonLabel"),
                    }}
                  />
                  <p className="reason-text">
                    {!Auth.isVerified()
                      ? staticService.findByAlias("missingInfoBrokerReason")
                      : staticService.findByAlias("missingInfoOperReason")}
                  </p>
                </div>
                <span className="badge">
                  <img src={on_hold} alt="badge" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {isQuoteWithdraw ? (
            <div className="gh-quote-card-overlay">
              <div className="gh-quote-withdrawn">
                <div className="info">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("quoteWasWithdrawn"),
                    }}
                  />
                  <hr className="line" />
                  <span
                    className="reason-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("reasonLabel"),
                    }}
                  />
                  <p className="reason-text">{quote.reason}</p>
                </div>
                <span className="badge">
                  <img src={withdrawn} alt="badge" />
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="uk-grid-small gh-quote-detail uk-padding-remove"
          >
            <div className="uk-width-1-1 uk-width-1-3@l">
              <div className="gh-quote-slider">
                <ImageSlider
                  imagesUrl={quote.aircrafts.map((item) => item.aircraft_images[0].file_url)}
                  selectedImg={selectedHeli}
                  getSelectedImg={this.changeSliderHeli}
                />

                {quote.aircrafts[selectedHeli] &&
                quote.aircrafts[selectedHeli].aircraft_images[0].is_operator_image ? (
                  <p className="gh-description-small-gray" style={{ padding: "0 10px" }}>
                    {staticService.findByAlias("isOperatorImage")}
                  </p>
                ) : (
                  <p className="gh-description-small-gray" style={{ padding: "0 10px" }}>
                    {staticService.findByAlias("isNotOperatorImage")}
                  </p>
                )}
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-2-3@s uk-width-1-2@l uk-margin-small-top">
              <BrokerQuoteInfo
                quote={quote}
                selectedHeli={selectedHeli}
                changeSliderHeli={this.changeSliderHeli}
                showPad={showPad}
                price={this.showPrice(quote)}
              />
            </div>
            <div className="uk-width-1-1 uk-width-1-3@s uk-width-1-6@l uk-margin-remove-top">
              {this.actions(isQuoteExpired)}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

BrokerQuoteComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  quote: PropTypes.object.isRequired,
  onSelectPath: PropTypes.string,
  onCancel: PropTypes.func,
  action: PropTypes.func,
  gallery: PropTypes.func,
  skipPremium: PropTypes.bool,
  showTail: PropTypes.bool,
  showPad: PropTypes.func,
  quoteUpdate: PropTypes.object,
  hideChat: PropTypes.bool,
  viewFullQuoteOnClick: PropTypes.string,
  removeQuotes: PropTypes.func,
  history: PropTypes.object,
  hideOnHoldOverlay: PropTypes.bool,
  displayBanner: PropTypes.bool,
  displayConnectBorders: PropTypes.bool,
  hideBorderBottom: PropTypes.bool,
  quoteIndex: PropTypes.number,
  brokerIsAllowedToEdit: PropTypes.bool,
  operIndex: PropTypes.func,
};

BrokerQuoteComponent.defaultProps = {
  skipPremium: false,
  showTail: true,
  hideChat: false,
  viewFullQuoteOnClick: null,
  hideOnHoldOverlay: false,
  brokerIsAllowedToEdit: false,
};

BrokerQuoteComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { removeQuotes: () => dispatch({ type: REMOVE_QUOTE_ITEM }) },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    quoteUpdate: state.quoteUpdate,
  };
}

const BrokerQuoteComponentWithRouter = withRouter(BrokerQuoteComponent);

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerQuoteComponentWithRouter);
export { COMPONENT as BrokerQuoteComponent };
