import moment from "moment";
import { config } from "../configs";

const parseHoursAndMinutes = (time) => {
  if (moment(time).isValid()) {
    return moment(time).format("HH:mm").split(":");
  }

  return time.split(":");
};

export default function tranformDateTimeToISO(date = "", time = "", format = "") {
  const datetime = moment(date, format);

  if (datetime.isValid()) {
    if (time && Array.isArray(time.split(":"))) {
      const [h, m] = parseHoursAndMinutes(time).map(Number);

      datetime.set("hours", h);
      datetime.set("minutes", m);
    }

    return datetime.utc().toISOString();
  }

  return "";
}
// return time from iso string, instead of date and time
export const getTime = (dateTime) => {
  return moment({ h: dateTime.hours(), m: dateTime.minutes() });
};
