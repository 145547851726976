import React from "react";
import staticService from "../../../services/static.service";
import moment from "moment/moment";
import { FollowUsTooltipComponent } from "../follow-us-tooltip.component";
import { Link } from "react-router-dom";
import { BlockComponent } from "../block.component";
import Auth from "../../../services/auth";

const MainFooter = () => {
  return (
    <BlockComponent
      visible={true}
      parentProps={{
        className:
          "uk-section uk-section-default uk-padding-small uk-padding-remove-left uk-padding-remove-right",
      }}
    >
      <div
        className="gh-main-footer uk-margin-small-top uk-flex-middle uk-visible@m uk-flex-between"
        data-uk-grid
      >
        <div className="uk-width-1-1@s uk-width-auto@m  gh-copyright uk-margin-remove-right">
          <div className={""}>
            Copyright © {staticService.findByAlias("appName")} {moment().format("Y")}
          </div>
        </div>
        <div className={"uk-width-1-1@s uk-width-auto@m uk-clearfix"}>
          <div className={"uk-align-right@m"}>
            <ul className="disabled-list uk-subnav uk-margin-remove uk-subnav-divider gh-subnav ">
              <FollowUsTooltipComponent />
              {Auth.isAuthenticated() ? (
                <li>
                  <Link to={"/contact"}>
                    <span
                      dangerouslySetInnerHTML={{ __html: staticService.findByAlias("contact") }}
                    />
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li>
                <a
                  href="https://www.iubenda.com/privacy-policy/89754695"
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Privacy Policy"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("privacyPolicy"),
                    }}
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://www.iubenda.com/privacy-policy/89754695/cookie-policy"
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Cookie Policy"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("CookiePolicy"),
                    }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gh-main-footer uk-margin-small-top uk-flex-middle uk-hidden@m" data-uk-grid>
        <div className="uk-width-1-1@s uk-width-1-2@m uk-clearfix">
          <div className="uk-align-right@m">
            <ul className="disabled-list uk-flex uk-flex-wrap  uk-margin-remove gh-subnav uk-child-width-1-2 uk-child-width-1-4@s">
              <li className="uk-text-center gh-padding-small-bottom">
                <FollowUsTooltipComponent />
              </li>
              {Auth.isAuthenticated() ? (
                <li className="uk-text-center gh-padding-small-bottom">
                  <Link to={"/contact"}>
                    <span
                      dangerouslySetInnerHTML={{ __html: staticService.findByAlias("contact") }}
                    />
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li className="uk-text-center">
                <a
                  href="https://www.iubenda.com/privacy-policy/89754695"
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Privacy Policy"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("privacyPolicy"),
                    }}
                  />
                </a>
              </li>
              <li className="uk-text-center">
                <a
                  href="https://www.iubenda.com/privacy-policy/89754695/cookie-policy"
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Cookie Policy"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("CookiePolicy"),
                    }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="uk-width-1-1@s uk-width-1-2@m uk-text-center gh-copyright uk-margin-remove-right">
          <div>
            Copyright © {staticService.findByAlias("appName")} {moment().format("Y")}
          </div>
        </div>
      </div>
    </BlockComponent>
  );
};

MainFooter.propTypes = {};

export default MainFooter;
