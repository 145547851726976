import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import Button from "../../../../common/Button";
import Modal from "../../../../common/Modal";
import useBrokerSubmitRequest from "./useBrokerSubmitRequest";
import Checkbox from "../../../../Form/components/Checkbox";
import clsx from "clsx";
import AvailableHomebases from "../../../../common/ModalAvailableCountries/AvailableHomebases";
import Skeleton from "../../../../common/Skeleton";
import AvailableCountriesContent from "../../../../common/ModalAvailableCountries/AvailableCountriesContent";
import { connect } from "react-redux";

import "./BrokerSubmitRequestModal.css";

const BrokerSubmitRequest = (props) => {
  const {
    closeModal,
    onSubmit,
    operators,
    isAdvancedRequestContentPermitted,
    onChange,
    confirmRequestModal,
  } = useBrokerSubmitRequest(props);

  return (
    <>
      <Modal
        className={clsx("broker-submit-request", {
          "--advanced": isAdvancedRequestContentPermitted,
        })}
        ref={confirmRequestModal}
        onClose={closeModal}
        title={staticService.findByAlias("submitRequestTitle")}
      >
        {isAdvancedRequestContentPermitted && (
          <AdvancedSubmitRequestContentRedux operators={operators} onChange={onChange} />
        )}

        <div className="broker-submit-request-confirm-text">
          {staticService.findByAlias("confirmRequestModal")}
        </div>

        <div className="gh-review-request-modal-buttons">
          <Button variant="outlined" color="primaryBorder" onClick={closeModal}>
            {staticService.findByAlias("cancel")}
          </Button>

          <Button onClick={onSubmit}>{staticService.findByAlias("confirmBrokerRequest")}</Button>
        </div>
      </Modal>
    </>
  );
};
BrokerSubmitRequest.propTypes = {};
export default BrokerSubmitRequest;

/* eslint-disable react/prop-types */
const OptionsTables = ({ operators, ...rest }) => {
  return (
    <div className="submit-request-tables-wrapper">
      <OptionsTable
        options={operators.relevant}
        title="GH Relevant Operators"
        showSelectAll={true}
        {...rest}
      />
      <OptionsTable options={operators.notRelevant} title="Other Operators (verified)" {...rest} />
    </div>
  );
};

const OptionsTable = ({ options, onChange, title, showSelectAll }) => {
  const showOptions = options?.length > 0;
  return (
    <div className="submit-request-table">
      <div className="submit-request-options-header">
        <h3 className="submit-request-table-title">{title}</h3>
        {!!showSelectAll && (
          <Checkbox
            value={options.every((el) => el.isChecked)}
            label="Select All"
            onChange={(e) => onChange(e, null, true)}
          />
        )}
      </div>
      <div className={clsx("submit-request-options-list")}>
        <OptionsPlaceholder show={!showOptions} />
        <ListingOptions options={options} onChange={onChange} show={showOptions} />
      </div>
    </div>
  );
};

OptionsTable.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
  onChange: PropTypes.func,
  title: PropTypes.string,
};

const OptionsPlaceholder = ({ show }) =>
  !!show && (
    <div className="submit-request-table-disabled">
      {staticService.findByAlias("submit-request-table-no-relevant-operators")}
    </div>
  );

const ListingOptions = ({ options, onChange, show }) =>
  !!show &&
  options.map((option) => (
    <div key={option.id} className="submit-request-option">
      <div type="button" className="submit-request-checkbox-wrapper">
        <Checkbox
          value={option.isChecked}
          label={<OptionTableValue option={option} />}
          onChange={(e) => onChange(e, option)}
        />
      </div>
    </div>
  ));

const OptionTableValue = ({ option }) => {
  return (
    <>
      <span className="submit-request-option-value">{option.name}</span>
      <span className="submit-request-option-value">{option.country.code}</span>
    </>
  );
};

const AdvancedSubmitRequestContent = ({ operators, onChange, availableCountries, dispatch }) => {
  const [isMapLoading, setIsMapLoading] = React.useState(true);

  return (
    <>
      <Skeleton className="preview-request-skeleton" loading={isMapLoading}>
        <AvailableCountriesContent
          availableCountries={availableCountries}
          dispatch={dispatch}
          forceFetchCountries={true}
        >
          <AvailableHomebases
            isModalOpen={true}
            className="preview-request-homebase-map"
            height="400px"
            callbackOnMapLoaded={setIsMapLoading.bind(null, false)}
          />
        </AvailableCountriesContent>
      </Skeleton>

      <OptionsTables operators={operators} onChange={onChange} />
    </>
  );
};

const AdvancedSubmitRequestContentRedux = connect(
  (store) => ({ availableCountries: store.availableCountries }),
  null,
)(AdvancedSubmitRequestContent);

/* eslint-enable */
