import React from "react";
import PropTypes from "prop-types";
import "./Spinner.css";
import condString from "../../../utils/condString";
import { CSSTransition } from "react-transition-group";

const Spinner = ({ className, width, height, show }) => {
  const computedWidth = width ? width : height ? height : "initial";
  const computedHeight = height ? height : width ? width : "initial";
  const style = { width: computedWidth, height: computedHeight };

  return (
    <CSSTransition in={show} timeout={250} unmountOnExit classNames="gh-animation-spinner">
      <div className={condString("gh-spinner", className)}>
        <svg
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          data-svg="spinner"
          style={style}
        >
          <circle
            fill="none"
            stroke="#000"
            cx="15"
            cy="15"
            r="14"
            style={{ strokeWidth: "0.333333px" }}
          />
        </svg>
      </div>
    </CSSTransition>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  show: PropTypes.bool,
};
Spinner.defaultProps = { show: true, width: "40px", height: "40px" };
export default React.memo(Spinner);
