import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";
import checkCheckbox from "../../../../assets/img/svg/checkCheckbox.svg";
import staticService from "../../../../services/static.service";

import "./Checkbox.css";

const Checkbox = React.forwardRef((props, ref) => {
  const { className, disabled, name, value, onChange, pattern, alias, label, ...rest } = props;

  const onChangeHandler = () => {
    if (pattern) onChange(pattern(!value));
    else onChange(!value);
  };

  return (
    <div onClick={onChangeHandler} className="gh-wrapper-checkbox btn-style">
      <input
        id={name}
        name={name}
        value={value}
        checked={value}
        ref={ref}
        className={cs(className, "gh-checkbox")}
        disabled={disabled}
        onChange={onChangeHandler}
        type="checkbox"
        {...rest}
      />
      <span className="gh-checkmark">
        <img className="gh-check-icon" src={checkCheckbox} alt="check" />
      </span>
      {alias ? (
        <label
          className={`label ${value ? "checked" : ""}`}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias(alias),
          }}
        />
      ) : (
        <label className={`label ${value ? "checked" : ""}`}>{label}</label>
      )}
    </div>
  );
});

Checkbox.displayName = "Checkbox";

Checkbox.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.func,
  alias: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Checkbox.defaultProps = {
  size: "md",
  value: "",
};

export default Checkbox;
