import React from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";
import { SharedComponent } from "../shared.component";

class GridComponent extends SharedComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.component = UIkit.grid("#" + this.props.attrProps.id, {});
  }

  render() {
    return this.props.visible ? (
      <div {...this.props.attrProps} style={this.props.styles ? this.props.styles : {}}>
        {this.props.children}
      </div>
    ) : null;
  }
}

GridComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  attrProps: PropTypes.object,
};

GridComponent.defaultProps = {
  attrProps: {
    className: "uk-child-width-expand@s uk-text-center",
    id: "uk-grid",
  },
};

export { GridComponent };
