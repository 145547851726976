import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import envelope from "../../../assets/img/svg/comment.svg";
import grayEnvelope from "../../../assets/img/svg/commentGray.svg";
import staticService from "../../../services/static.service";
import { ConfirmComponent } from "../confirm.component";
import LiveChatContent from "./components/liveChatContent";
import { connect } from "react-redux";
import {
  getDiscussionMessages,
  markAssSeenQuotesComments,
  SEEN_LOCAL_QUOTE,
} from "../../../actions";
import { ModalComponent } from "../../../shared";
import PreviewFile from "./components/previewFile";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import { roles } from "../../../configs";

import "./liveChatComponent.css";

const LiveChatComponent = (props) => {
  const {
    quoteId,
    getDiscussionMessages,
    markAssSeenQuotesComments,
    commentsCount,
    titleAlias,
    resetModel,
    userRole,
    btnClassName,
    seenLocalQuote,
    brokerChatLabelAlias,
    operatorChatLabelAlias,
    disabled,
    triggerToOpen,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewTitle, setPreviewTitle] = useState("");
  const [chatIsOpen, setChatIsOpen] = useState(false);

  const dialogChatModal = useRef(null);
  const previewFile = useRef(null);

  useEffect(() => {
    if (triggerToOpen) {
      openModal(quoteId);
    }
  }, [triggerToOpen]);

  const openModal = (quoteId) => {
    getDiscussionMessages(quoteId).then(() => {
      seenLocalQuote(quoteId);
      dialogChatModal.current.openModal();
      setChatIsOpen(true);
      markAssSeenQuotesComments(quoteId);
    });
  };

  const closeModal = () => {
    dialogChatModal.current.closeModal();
    setChatIsOpen(false);
    resetModel("feedbackModel");
  };

  const openPreviewFile = (file) => {
    setPreviewTitle(file.file_name || file.name || file.title || "");
    setSelectedFile(file);
    previewFile.current.open();
  };

  const closePreviewFile = () => {
    openModal(quoteId);
    setPreviewTitle("");
    setSelectedFile(null);
  };

  const additionalContent = useMemo(
    () =>
      userRole === "operator"
        ? staticService.findByAlias(operatorChatLabelAlias || "discussion-operator-message")
        : staticService.findByAlias(brokerChatLabelAlias || "addAnyQuestions"),
    [userRole, operatorChatLabelAlias, brokerChatLabelAlias],
  );

  return (
    <div className="gh-live-chat-wrapper">
      <button
        className={`uk-button uk-button-secondary uk-padding-remove gh-discussion-btn gh-quote-button gh-full-quote-btn ${btnClassName}`}
        onClick={() => openModal(quoteId)}
        disabled={disabled}
      >
        <div className="gh-discussion-btn_envelope">
          <img
            className="gh-envelope-icon-broker"
            src={disabled ? grayEnvelope : envelope}
            alt="envelope"
          />
          {commentsCount && !chatIsOpen ? (
            <span className="messages-count">{commentsCount}</span>
          ) : (
            ""
          )}
        </div>
        {commentsCount && !chatIsOpen
          ? staticService.findByAlias("newMessage")
          : staticService.findByAlias("quoteLiveChat")}
      </button>

      {/*MODALS*/}

      <ConfirmComponent
        ref={dialogChatModal}
        info={
          <LiveChatContent
            openPreviewFile={openPreviewFile}
            {...props}
            chatIsOpen={chatIsOpen}
            userRole={userRole}
          />
        }
        additionalContent={{
          content: additionalContent,
        }}
        modalProps={{
          id: `discuss-modal-${quoteId}`,
          additionalClass: "discuss-modal-modal",
          title: staticService.findByAlias(titleAlias),
          onClose: closeModal,
          options: {
            clsPage: "",
            bgClose: false,
            escClose: false,
          },
        }}
        onSelect={() => {}}
        showCloseBtn
        onReject={closeModal}
      />

      <ModalComponent
        title={previewTitle}
        ref={previewFile}
        id="preview-file-account"
        modalClass="gh-preview-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={closePreviewFile}
      >
        <PreviewFile selectedFile={selectedFile} />
      </ModalComponent>
    </div>
  );
};

LiveChatComponent.propTypes = {
  quoteId: PropTypes.any.isRequired,
  commentsCount: PropTypes.number.isRequired,
  getDiscussionMessages: PropTypes.func,
  markAssSeenQuotesComments: PropTypes.func,
  titleAlias: PropTypes.string,
  resetModel: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  seenLocalQuote: PropTypes.func,
  brokerChatLabelAlias: PropTypes.string,
  operatorChatLabelAlias: PropTypes.string,
  disabled: PropTypes.bool,
  triggerToOpen: PropTypes.bool,
};

LiveChatComponent.defaultProps = {
  btnClassName: "",
  triggerToOpen: false,
};
const mapStateToProps = ({ userOnline }) => ({ userRole: roles[userOnline.role] });
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDiscussionMessages,
      markAssSeenQuotesComments,
      resetModel: (modelName) => dispatch(actions.reset(modelName)),
      seenLocalQuote: (quoteId) => dispatch({ type: SEEN_LOCAL_QUOTE, quoteId: quoteId }),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatComponent);
