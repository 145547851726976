import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { actions, Control, Errors } from "react-redux-form";
import onClickOutside from "react-onclickoutside";
import { eventLocations, getLocations, locations } from "../../../actions";
import { validationRules } from "../../../configs";
import { CustomValidateError, replaceAttributesInString } from "../../../shared";
import eventService from "../../../services/event.service";
import staticService from "../../../services/static.service";

const { alphaNumSpaceValidation, required } = validationRules;

class LocationAutocompleteWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      loader: "",
      validate: true,
      validationAlias: staticService.findByAlias("alphaNumValidationMessage"),
      validationMessage: "",
      fieldType: props.inputType ? props.inputType : "locations",
      seeAllEvents: false,
      visibleOverlay: false,
      visibleList: false,
      place: props.place || "",
    };

    //simulates cancel requests
    this._totalRequestsSent = 0;
    this._totalRequestsReceived = 0;
    this.search = this.search.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onBlurPad = this.onBlurPad.bind(this);
    this.locationValidationTemplate = this.locationValidationTemplate.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  onSuccess(locations) {
    this.setState({ visibleOverlay: true });
    this._totalRequestsReceived += 1;
    if (this._totalRequestsReceived === this._totalRequestsSent) {
      this.setState({ locations: locations });
      this.setState({ loader: "" });
      eventService.trigger("locations-loaded");
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.clearLocationsErrors === true) {
      this.setState({ validationMessage: "" });
      this.props.onClearSuccess();
    }
  }

  handleClickOutside() {
    this.setState({ visibleOverlay: false });
    this.setState({ locations: [] });
    this.props.onClickOutside();
  }

  clearErrors() {
    this.setState({
      validate: true,
      validationMessage: "",
    });
  }

  search(location, requiredAlias, seeAll = 0) {
    if (this.state.locations.length > 0) {
      this.handleClickOutside();
    }

    const _trimmedLocation = location.length ? location.trim() : location;
    if (_trimmedLocation === "") {
      this.setState({
        validate: false,
        validationMessage: staticService.findByAlias(requiredAlias || "pleaseSelect"),
      });
      this.props.validate(false);
      return;
    }

    if (
      _trimmedLocation !== "" &&
      _trimmedLocation.length &&
      _trimmedLocation.length > 0 &&
      _trimmedLocation.length < 3
    ) {
      this.setState({
        validate: false,
        validationMessage: replaceAttributesInString(
          { length: 3 },
          staticService.findByAlias("minMessage"),
        ),
      });
      this.props.validate(false);
      return;
    }

    if (!alphaNumSpaceValidation(_trimmedLocation)) {
      this.setState({
        validate: false,
        validationMessage: staticService.findByAlias("alphaNumValidationMessage"),
      });
      this.props.validate(false);
      return;
    }

    if (_trimmedLocation !== "" && _trimmedLocation.length && _trimmedLocation.length > 2) {
      this.setState({ validate: true });
      this._totalRequestsSent += 1;
      this.setState({ loader: "show", locations: [] });
      if (this.state.fieldType === "events") {
        return this.props.eventLocations(_trimmedLocation, this.onSuccess, seeAll);
      }
      if (requiredAlias === "home_base") {
        return this.props.getLocations(_trimmedLocation, this.onSuccess, requiredAlias);
      } else {
        return this.props.getLocations(_trimmedLocation, this.onSuccess);
      }
    }
  }

  onBlur(ev) {
    this.context.store.dispatch(actions.setTouched(ev.target.name + "_coords"));
  }

  onBlurPad(ev) {
    this.context.store.dispatch(actions.setTouched(ev.target.name));
  }

  locationValidationTemplate(
    modelKey,
    model,
    requiredAlias = "pleaseSelect",
    showInitError = "touched",
    validators = null,
  ) {
    const { loading, focus } = this.props;

    if (this.props.is_distance_exceeds && !focus) {
      return (
        !focus && (
          <ul className={"ul validation-errors disabled-list"}>
            <li>{staticService.findByAlias("distanceExceeds")}</li>
          </ul>
        )
      );
    }

    return (
      <Fragment>
        {!loading || loading === -1 ? (
          <div>
            {!focus && (
              <ul
                className={
                  "ul validation-errors disabled-list" + (this.state.validate ? " hide" : "")
                }
              >
                <li>{this.state.validationMessage}</li>
              </ul>
            )}
            <Control
              model={modelKey}
              validators={validators || { required }}
              mapProps={{ type: "hidden" }}
            />
            {this.state.validate ? (
              <Errors
                model={modelKey}
                show={showInitError}
                styles={{ position: "absolute" }}
                messages={{
                  required: staticService.findByAlias(requiredAlias),
                }}
                wrapper={CustomValidateError}
              />
            ) : null}
          </div>
        ) : null}
      </Fragment>
    );
  }

  render() {
    return (
      <div>
        <div
          onClick={this.handleClickOutside}
          className={`gh-overlay ${
            this.state.visibleOverlay && this.state.place !== "iframe" ? " visible" : ""
          }`}
        />
        {this.props.children({
          locations: this.state.locations,
          search: this.search,
          handleClickOutside: this.handleClickOutside,
          loader: this.state.loader,
          validate: this.state.validate,
          visibleOverlay: this.state.visibleOverlay,
          validationMessage: this.state.validationMessage,
          locationValidationTemplate: this.locationValidationTemplate,
          onBlur: this.onBlur,
          onBlurPad: this.onBlurPad,
          clearErrors: this.clearErrors,
          place: this.state.place,
        })}
      </div>
    );
  }
}

LocationAutocompleteWrapper.contextTypes = {
  store: PropTypes.object,
};

LocationAutocompleteWrapper.propTypes = {
  validate: PropTypes.func,
  onClickOutside: PropTypes.func,
  clearLocationsErrors: PropTypes.bool,
  onClearSuccess: PropTypes.func,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  focus: PropTypes.bool,
  is_distance_exceeds: PropTypes.bool,
  place: PropTypes.string,
};

LocationAutocompleteWrapper.defaultProps = {
  validate: () => {},
  onClickOutside: () => {},
  clearLocationsErrors: null,
  onClearSuccess: null,
  loading: -1,
  is_distance_exceeds: false,
  place: "",
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      locations,
      eventLocations,
      getLocations,
    },
    dispatch,
  );
};

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(onClickOutside(LocationAutocompleteWrapper));
export { COMPONENT as LocationAutocompleteWrapper };
