import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import auth from "../../services/auth";
import staticService from "../../services/static.service";
import { ModalComponent } from "../../shared";
import DashboardEmptyLegForm from "../pages/user-online/operator/DashboardEmptyLegForm";

class DashboardBlockComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
    };
    const path =
      typeof props.navPath === "object" ? props.navPath[auth.getMainRole()] : props.navPath;
    this.state = {
      path: path,
    };
  }

  animateCard() {
    this.setState({ animate: true });
    setTimeout(() => this.setState({ animate: false }), 9000);
  }

  componentDidMount() {
    this.animateCard();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.quantity && this.props.highlight && this.props.quantity !== nextProps.quantity) {
      this.animateCard();
    }
  }

  render() {
    const { animate, path } = this.state;
    const {
      visible,
      parentClass,
      colLength,
      screenW,
      index,
      mainClass,
      highlight,
      color,
      quantity,
      navTitle,
      title,
      createEmptyLeg,
    } = this.props;

    return visible ? (
      <div
        className={
          parentClass ||
          `uk-width-1-1@s uk-width-1-${colLength}@m uk-flex ${
            screenW > 960 ? (index % 2 === 0 ? "uk-flex-right" : "uk-flex-left") : "uk-flex-center"
          } ${
            (screenW > 960 && index > 1) || (screenW < 960 && index > 0) ? "gh-dashboard-item" : ""
          }`
        }
      >
        <div
          className={
            mainClass ||
            `uk-card uk-card-default uk-card-body uk-padding-remove ${
              highlight ? `gh-row-highlight ${animate ? "gh-pulse-animation" : ""}` : ""
            }`
          }
        >
          <div className={"gh-dashboard-bl uk-flex uk-flex-middle"}>
            <div data-uk-grid className={"uk-margin-remove-left"}>
              <div className={"uk-width-1-3 uk-text-center gh-count " + color}>{quantity}</div>
              <div className="uk-width-2-3 uk-flex uk-flex-middle">
                <div className={"gh-dashboard-desc"}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias(title),
                    }}
                  />
                  <div>
                    <div className="uk-flex">
                      <NavLink to={path}>{navTitle}</NavLink>
                      {createEmptyLeg ? (
                        <React.Fragment>
                          <span style={{ margin: "0 5px" }}>or</span>
                          <div
                            className="btn-style uk-green-color"
                            onClick={() => {
                              this.modalForm.open();
                            }}
                          >
                            Create Empty Leg
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalComponent
          title="Create Empty-Leg"
          id="dashboard-create-empty-leg-id"
          modalClass="dashboard-create-empty-leg"
          ref={(modal) => (this.modalForm = modal)}
          onClose={this.onFormClose}
          options={{ clsPage: "", bgClose: false, escClose: false }}
        >
          <DashboardEmptyLegForm modalRef={this.modalForm} />
        </ModalComponent>
      </div>
    ) : null;
  }
}

DashboardBlockComponent.propTypes = {
  title: PropTypes.string.isRequired,
  navTitle: PropTypes.string.isRequired,
  quantity: PropTypes.any.isRequired,
  navPath: PropTypes.any.isRequired,
  mainClass: PropTypes.string,
  parentClass: PropTypes.string,
  color: PropTypes.string,
  colLength: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  screenW: PropTypes.number,
  index: PropTypes.number,
  highlight: PropTypes.bool,
  createEmptyLeg: PropTypes.bool,
};

export { DashboardBlockComponent };
