import React, { Component } from "react";
import { fireTracking, isObjectEmpty, ModalComponent, redirectTo } from "../../../shared";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMeta, loadDestinationInformationContent, loadEventContent } from "../../../actions";
import { actions } from "react-redux-form";
import LazyLoad from "react-lazy-load";
import ImageLoader from "../../../shared/image-loader/image-loader.component";
import staticService from "../../../services/static.service";
import { Helmet } from "react-helmet/es/Helmet";
import EventEnquire from "../event-enquire";
import Auth from "../../../services/auth";
import DocumentTitle from "../DocumentTitle";
import CustomLoader from "../CustomLoader/customLoader";
import DestinationInformationContent from "./components/DestinationInformationContent";

import "./destinationInformation.css";

const parentBlockConfig = {
  minHeight: "calc(100vh - 150px)",
};

class DestinationInformationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
      items: [],
      roleSelectedPrefix: "a",
      eventData: {},
      redirect: false,
      seoTitle: "",
      eventInfo: {},
    };

    this.getByAlias = this.getByAlias.bind(this);
    this.mapItems = this.mapItems.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onError = this.onError.bind(this);
    this.onLoadDestination = this.onLoadDestination.bind(this);
    fireTracking(this.props.location.pathname);
  }

  componentDidMount() {
    this.onLoadDestination();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const oldLocation = location;
    const newLocation = prevProps.location;

    if (oldLocation.pathname !== newLocation.pathname) {
      this.onLoadDestination();
    }
  }

  onLoadDestination() {
    const { id, match, loadDestinationInformationContent, getMeta, screenInnerH } = this.props;
    const theId = id !== undefined ? id.id : match.params.url;

    const key = window.location.pathname.substring(1).replaceAll("/thank-you", "");
    getMeta(key);

    loadDestinationInformationContent(theId, this.onError).then((res) => {
      const {
        data: { data },
      } = res;

      this.setState({ eventInfo: data });

      if (data.seo_title) {
        this.setState({ seoTitle: data.seo_title });
      } else if (data.event_name) {
        this.setState({ seoTitle: data.event_name });
      } else {
        this.setState({ seoTitle: "" });
      }

      window.scrollTo(0, 0);
      if (res === undefined) {
        return this.setState({ redirect: true });
      }
    });

    document.documentElement.style.setProperty("--vh", `${screenInnerH}px`);
    this.context.store.dispatch(actions.reset("enquireForm"));
  }

  onError(error) {
    if (error.status === 404) {
      this.setState({ redirect: true });
    }
  }

  getByAlias(alias = "") {
    const { items } = this.props;
    return items.filter((item) => item.alias === alias);
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.hide();
  }

  mapItems(alias = "") {
    const result = this.getByAlias(alias);
    const rows = result.filter((item) => {
      return item.type === "both";
    });
    return rows.map((row) => {
      return {
        image: row.icon,
        title: row.title,
        content: row.gh_content,
      };
    });
  }

  render() {
    const { metaData, history } = this.props;
    const { eventInfo } = this.state;

    if (this.state.redirect === true) {
      return redirectTo("/destinations");
    }

    if (isObjectEmpty(eventInfo)) return <CustomLoader />;
    return (
      <div style={parentBlockConfig} className="gh-event-detail">
        {eventInfo.photo ? (
          <LazyLoad debounce={false} className="uk-background-cover uk-cover-container uk-light">
            <ImageLoader
              className="max_height_img"
              src={eventInfo.photo}
              alt={eventInfo.photo_alt}
            />
          </LazyLoad>
        ) : (
          ""
        )}

        <div className="uk-container">
          <DocumentTitle title={this.state.seoTitle} />

          <Helmet>
            <link rel="canonical" href={`https://getheli.com/destinations/${eventInfo.url}`} />
            {metaData &&
              metaData.meta &&
              metaData.meta.message &&
              metaData.meta.message.map((meta) => (
                <meta key={meta.name} name={meta.name} content={meta.content} />
              ))}
          </Helmet>

          <ModalComponent
            title={staticService.findByAlias("enquiryFormLabel")}
            id="enquire-form"
            ref={(modal) => (this.modal = modal)}
            onClose={this.closeModal}
          >
            <EventEnquire event={eventInfo} history={history} fromDestinations />
          </ModalComponent>

          <div className="uk-container">
            {!Auth.isAuthenticated() ? (
              <div className="uk-section back-section">
                <div className="uk-flex uk-flex-middle">
                  <div
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    className="uk-button uk-button-default button-return"
                  >
                    <span className="uk-icon" data-uk-icon="icon: arrow-left" />
                  </div>
                  <div className="button-return-label uk-margin-left ">Back</div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="uk-section" id="gh-events-enquire-form-page">
              <div data-uk-grid className="uk-flex-top uk-grid">
                <div className="uk-width-1-1 uk-width-2-3@l">
                  <DestinationInformationContent
                    event={eventInfo}
                    handleOpenModal={this.openModal}
                  />
                </div>
                <div className="uk-width-1-3@l uk-visible@l sticky-10">
                  <EventEnquire event={eventInfo} history={history} fromDestinations />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("enquireForm"));
  }
}

DestinationInformationComponent.contextTypes = {
  store: PropTypes.object,
};

DestinationInformationComponent.propTypes = {
  location: PropTypes.object,
  id: PropTypes.string,
  match: PropTypes.object,
  getMeta: PropTypes.func,
  loadDestinationInformationContent: PropTypes.func,
  items: PropTypes.array,
  metaData: PropTypes.object,
  history: PropTypes.object,
  screenInnerH: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadDestinationInformationContent,
      loadEventContent,
      getMeta,
      resetValidity: (model) => dispatch(actions.resetValidity(model)),
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    infoEvents: state.eventsInformation,
    metaData: state.metaData,
    screenW: state.screenDimensions.screenInnerW,
  };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(DestinationInformationComponent);
export { COMPONENT as DestinationInformationComponent };
