import React, { memo, useContext, useState, useEffect } from "react";
import MarkerInfoContext from "./MarkerInfoContext";
import cardMapMarker from "../../../assets/img/svg/cardMapMarker.svg";
import mapCardStar from "../../../assets/img/svg/mapCardStar.svg";
import reviewIcon from "../../../assets/img/svg/reviewsIcon.svg";
import arrowRight from "../../../assets/img/svg/mapArrowRight.svg";
import { generateChosenSiteText, placeTypeIcon } from "../mapUtils";
import link from "../../../assets/img/external-link.svg";

function DefaultMarker() {
  const {
    pin,
    pin: { name, image, rating, reviews, category, handleAssignButton, view_link, direction },
    isSelected,
    updateChosenSitesByRole,
  } = useContext(MarkerInfoContext);
  const [showInfo, setShowInfo] = useState(false);
  const [chosenSiteText, setChosenSiteText] = useState();

  useEffect(() => {
    setChosenSiteText(generateChosenSiteText(direction, updateChosenSitesByRole));
  }, [direction]);

  const formatViewLink = () => {
    if (!view_link) return;

    if (!view_link.match(/http/gi)) {
      return `https://${view_link}`;
    }

    return view_link;
  };

  const title = () => {
    return view_link ? (
      <a href={formatViewLink()} target="_blank" rel="noreferrer">
        {name} <img className="gh-link-icon" src={link} alt="link" />
      </a>
    ) : (
      name
    );
  };

  return (
    <div className="legInfoWindow" id="id-info">
      {!!image && (
        <div
          className="imageBox"
          style={{
            background: `url(${image}) center center / cover no-repeat`,
          }}
        />
      )}
      <div className="content">
        <h4 className="top-head">{title()}</h4>
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width uk-width-1-2">
            <span className="item-icon">
              <img src={placeTypeIcon(category)} alt="cutleryIcon" />{" "}
              <span className="text">{category}</span>
            </span>
          </div>
          <div className="uk-width uk-width-1-2">
            <span className="item-icon">
              <img src={cardMapMarker} alt="cardMapMarker" /> <span className="text">-</span>
            </span>
          </div>
          {/*<div className="uk-width uk-width-1-2">*/}
          {/*  <span className="item-icon">*/}
          {/*    <img src={mapCardStar} alt="mapCardStar" />{" "}*/}
          {/*    <span className="text">{rating || 0}</span>*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div className="uk-width uk-width-1-2">*/}
          {/*  <span className="item-icon">*/}
          {/*    <img src={reviewIcon} alt="reviewIcon" />{" "}*/}
          {/*    <span className="text">{reviews || 0} reviews</span>*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>
        {isSelected && direction ? (
          <h4 className="bottom-head">{pin.chosenSiteText || chosenSiteText}</h4>
        ) : (
          <div className="legInfoWindowActionLegs">
            {showInfo ? (
              <div>
                <a role="button" onClick={() => handleAssignButton("from")}>
                  FROM
                </a>
                <span style={{ margin: "0 15px", fontWeight: 600, fontSize: 14 }}>or</span>
                <a role="button" onClick={() => handleAssignButton("to")}>
                  TO
                </a>
              </div>
            ) : (
              <a onClick={() => setShowInfo(true)}>
                <h4 className="bottom-head secondary">
                  Use this helipad <img src={arrowRight} alt="arrow" />
                </h4>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(DefaultMarker);
