import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

export default function UploadFilesView(props) {
  const { children } = props;
  const [activeFile, setActiveFile] = React.useState({});

  const closeHandler = (event) => {
    event.preventDefault();
    setActiveFile({});
  };

  return (
    <React.Fragment>
      {activeFile.file_url &&
        ReactDOM.createPortal(
          <div id="upload-file-view-modal" onClick={closeHandler}>
            <div className="upload-file-view" onClick={(event) => event.stopPropagation()}>
              <h3 className="title">{activeFile.file_name}</h3>
              <a href="" data-uk-close onClick={closeHandler} />
              <img src={activeFile.file_url} alt={activeFile.file_name} />
            </div>
          </div>,
          document.getElementById("app"),
        )}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          viewFile: setActiveFile,
        }),
      )}
    </React.Fragment>
  );
}

UploadFilesView.propTypes = {
  children: PropTypes.any,
};
