class StorageService {
  constructor() {}

  add(key, value) {
    localStorage.setItem(key, value);
  }

  get(key) {
    return localStorage.getItem(key);
  }

  delete(key) {
    localStorage.removeItem(key);
  }

  clearByKeys(keys = []) {
    keys.forEach((key) => this.delete(key));
  }

  saveMany(data = []) {
    data.forEach((info, key) => this.add(key, info));
  }
}
const storage = new StorageService();
export { storage };
