import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";
import "./RadioButton.css";

const Radio = React.forwardRef((props, ref) => {
  const {
    className,
    name,
    value,
    onChange,
    disabled,
    pattern,
    values = [],
    required = false,
  } = props;

  const onChangeHandler = (e) => {
    if (pattern) onChange(pattern(e.target.value));
    else onChange(e.target.value);
  };

  return (
    <div id={name} ref={ref} className={cs("uk-radio-wrapper", className)}>
      {values.map((item) => (
        <div key={item.value} className={cs("uk-radio-item", item.className)}>
          <input
            id={`${name}-${item.value}`}
            name={name}
            value={item.value}
            checked={item.value === value}
            onChange={onChangeHandler}
            disabled={disabled}
            type="radio"
            required={required}
            className="uk-radio uk-radio-button"
          />
          <label htmlFor={`${name}-${item.value}`} className="btn-style uk-radio-item__value">
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
});

Radio.displayName = "Radio";

Radio.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
};

Radio.defaultProps = {
  value: "",
  values: [],
};

export default Radio;
