import React from "react";
import PropTypes from "prop-types";
import { pages, staticText } from "../../configs";
import { appendScript } from "../../shared";
import { MainLayout } from "./main.layout";
import { Header } from "../common";

export class BookDemoLayout extends MainLayout {
  componentWillMount() {
    super.componentWillMount();
    this.setState({ pages: this.props.pages });
  }

  get header() {
    return (
      <Header
        activeRoute={this.props.activeRoute}
        extraProps={{ navClassName: "uk-navbar-container uk-navbar gh-dashboard-logout-container-nav" }}
        className={"gh-dashboard-nav gh-dashboard-logout-header-nav"}
        pages={this.props.pages}
        navVisibility={this.props.navVisibility}
      />
    );
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = staticText.appName + " - " + this.props.pageTitle;
    document.body.classList.add("gh-dashboard");
    this.timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    appendScript("//cdn.iubenda.com/cs/iubenda_cs.js", true, "iubenda");
    this.setState({ pages: this.updatePagesRoutes(this.props.pages, this.props.registerPath) });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.registerPath !== nextProps.registerPath) {
      this.setState({ pages: this.updatePagesRoutes(this.props.pages, nextProps.registerPath) });
    }
  }

  updatePagesRoutes(pages = [], signUpPath) {
    return pages.map((page, i) => {
      if (page.alias && page.alias === "signUp") {
        page.path = signUpPath;
      }
      return page;
    });
  }

  render() {
    return super.render();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const iubendaScript = document.getElementById("iubenda");
    if (iubendaScript && iubendaScript.remove) {
      iubendaScript.remove();
    }
  }
}

BookDemoLayout.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pages: PropTypes.array,
  navVisibility: PropTypes.bool,
};

BookDemoLayout.defaultProps = {
  pages: pages,
  pageTitle: "Events",
};
