import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { BlockComponent } from "../../../../common";
import {
  getTeamMembers,
  getTeamMembersSuccess,
  RESET_MEMBER_REMOVE,
  updateRole,
} from "../../../../../actions";
import { config, teamMembersConfig } from "../../../../../configs";
import { PaginationService } from "../../../../../services";
import auth from "../../../../../services/auth";
import {
  capitalizeFirstLetter,
  CheckboxComponent,
  ComplexTableComponent,
} from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import GetHeliEvents from "../../../../../services/event.service";

class TeamTableComponent extends Component {
  constructor(props, context) {
    super(props);
    this.paginationService = new PaginationService(
      context.store.dispatch,
      getTeamMembers,
      config.paginationLimit,
      [],
    );
    this.paginationService.setProp("columns", teamMembersConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(teamMembersConfig));
    this.paginationService.loadData();
    this.onChange = this.onChange.bind(this);
    this.prepareRows = this.prepareRows.bind(this);
    this.populate = this.populate.bind(this);
    this.state = { initialLoadState: false };
  }

  componentDidMount() {
    GetHeliEvents.setEvent("members", () => {
      if (!this.state.initialLoadState) this.setState({ initialLoadState: true });
    });
  }

  get isAdmin() {
    return (
      this.user.role === "operator_admin" ||
      this.user.role === "broker_admin" ||
      this.user.role === "sole_trader"
    );
  }

  get user() {
    return this.props.userOnline !== null ? this.props.userOnline : auth.getUser();
  }

  removeUser(user) {
    this.props.onModalOpen(user);
  }

  prepareRows(items) {
    return items.map((item) => {
      item.is_admin = this.getIsAdminTemplate(item);
      item.full_name = `${item.first_name} ${item.last_name}`;
      item.active = (
        <span className={"gh-count-" + item.status}>{capitalizeFirstLetter(item.status)}</span>
      );
      if (this.isAdmin && !item.owner && this.user.id !== item.id) {
        item.remove = (
          <a onClick={this.removeUser.bind(this, item)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              style={{
                width: 20,
                height: 20,
              }}
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        );
      } else {
        item.remove = "";
      }

      return item;
    });
  }

  onChange(checked, user) {
    this.props.updateRole(user, () => {
      const members = this.props.teamMembers.map((userM) => {
        if (userM.id === user) {
          userM.role = checked ? 0 : 1;
        }
        return { ...userM };
      });
      this.props.getTeamMembersSuccess(members);
    });
  }

  isChecked(item, currentUser) {
    return (
      (item.id === currentUser.id && this.isAdmin) ||
      (item.id !== currentUser.id && parseInt(item.role) > 0)
    );
  }

  getIsAdminTemplate(item) {
    let disabled = true;
    if (item.status === "active" && this.isAdmin) {
      disabled = item.owner || this.user.id === item.id ? true : false;
    }

    return (
      <CheckboxComponent
        onClick={this.onChange}
        showLabel={false}
        value={item.id}
        isDisabled={disabled}
        defaultChecked={this.isChecked(item, this.user)}
      />
    );
  }

  populate(teamMembers) {
    this.paginationService.reloadItems();
    this.paginationService.populateItems(teamMembers, this.prepareRows);
    return this.paginationService.items;
  }

  render() {
    return (
      <BlockComponent visible={true}>
        <h2
          className={"gh-text-slim uk-margin-remove-bottom"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("teamMembers") }}
        />
        <ComplexTableComponent
          loadMore={this.paginationService.loadMore.bind(this.paginationService)}
          rows={this.populate(this.props.teamMembers)}
          headers={this.paginationService.headers}
          columns={this.paginationService.columns}
          showActionColumn={0}
          showLoader={false}
          firstTimeLoading={this.state.initialLoadState}
          showMoreText={staticService.findByAlias("showMore")}
          actionElements={() => {}}
          alignLastCell={true}
        />
      </BlockComponent>
    );
  }

  componentWillUnmount() {
    this.props.getTeamMembersSuccess([]);
  }
}

TeamTableComponent.contextTypes = {
  store: PropTypes.object,
};

TeamTableComponent.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
};

const mapStateToProps = ({ teamMembers, userOnline }) => {
  return { teamMembers, userOnline };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMembers: getTeamMembers,
      updateRole: updateRole,
      resetRemoveAction: () => dispatch({ type: RESET_MEMBER_REMOVE }),
      getTeamMembersSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(TeamTableComponent);
export { COMPONENT as TeamTableComponent };
