import React from "react";
import { actions, Control, Form } from "react-redux-form";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  DateTimeComponent,
  prepareQueryAttributes,
  RadioComponent,
  setActiveItem,
  updateModel,
} from "../../../../shared";
import { brokerRequestsFilters, config, requestListConfig } from "../../../../configs/";
import { getBrokerRequetsByFilter, getBrokerRequetsSuccess } from "../../../../actions/";
import staticService from "../../../../services/static.service";
import { ActivityFilterCriteria, FilterTabsComponent } from "./";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../common";
import BasicRequestFiltersComponent from "./basic-request-filters.component";
import calendar from "../../../../assets/img/svg/calendar.svg";
import auth from "../../../../services/auth";

const modelName = "brokerRequestFiltersModel";

class BrokerRequestFiltersComponent extends BasicRequestFiltersComponent {
  constructor(props) {
    super(props, modelName);
    this.state = {
      modelName: modelName,
      filterTabs: brokerRequestsFilters,
      filterBy: "",
    };
    this.updateTabList = this.updateTabList.bind(this);
  }

  componentDidMount() {
    if (this.props.parentFilters && this.props.parentFilters.filterBy) {
      const el = this.props.parentFilters && this.props.parentFilters.filterBy;
      const active = this.state.filterTabs.find((item) => item.filter === el);
      setActiveItem(this.state.filterTabs, active.filter);
    }

    const currentActiveTab = this.props.history.location.pathname.split("/").pop();
    // this.updateTabList([], currentActiveTab === "requests" ? "all" : currentActiveTab);

    if (auth.getRole() === "broker_team") {
      this.props.updateModel(modelName, { activity: "own" });
    }
  }

  reset() {
    // this.props.resetModel(modelName);

    this.props.updateModel(modelName, {
      disableBtn: false,
      refreshCollection: false,
      only_my: false,
      active: false,
      radius: "",
      activity: auth.getRole() === "broker_team" ? "own" : "all",
      near_by: "",
      home_info: "",
      orderBy: requestListConfig.defaultOrder.field,
      sortedBy: "desc",
    });

    let newFilters = [
      ...this.props.filters,
      { key: "activity", value: auth.getRole() === "broker_team" ? "own" : "all" },
    ];

    this.props.get(1, newFilters).then((response) => {
      if (this.props.resetFilters) {
        this.props.resetFilters(newFilters, response);
      }
    });
  }

  applyFilters(filters) {
    let _filters = prepareQueryAttributes({ ...filters }, requestListConfig.filters);
    const updatedFilters = [
      ..._filters,
      {
        key: "orderBy",
        value: this.props.orderBy || this.props.sortParams.field,
      },
      {
        key: "sortedBy",
        value: this.props.sortedBy || this.props.sortParams.sortOrder,
      },
      { key: "filterBy", value: this.props.parentFilters.filterBy || "" },
      { key: "clientId", value: filters.client_id || "" },
    ];

    this.props.get(1, updatedFilters).then((requests) => {
      this.updateModel(requests, this.props.filtersModel);
    });
  }

  isChecked(type) {
    return this.props.filtersModel.sortedBy === type;
  }

  updateTabList(list, status) {
    this.props.history.push(`/flights/requests/filter/${status}`);
    this.setState({ filterBy: status });
    this.props.loadData(status);
    setActiveItem(this.state.filterTabs, status);
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div>
        <FilterTabsComponent
          tabs={this.state.filterTabs}
          changeFunc={this.updateTabList}
          isLoading={isLoading}
        />
        {!this.props.visible ? null : (
          <div className="uk-card uk-card-default uk-card-body gh-broker-request-filters">
            <div>
              <Form model={modelName} onSubmit={this.handleFormSubmit.bind(this)}>
                <div data-uk-grid className="gh-filters-container">
                  {this.props.screenW < 960 && (
                    <div className="uk-width-1-1">
                      <div className="uk-width-1-1@s uk-width-expand@m">
                        <label
                          className="uk-form-label"
                          dangerouslySetInnerHTML={{
                            __html: staticService.findByAlias("sortBy"),
                          }}
                        />
                        <div className="uk-form-controls">
                          <Control.select model={".orderBy"} className="uk-select">
                            {this.props.headers.map((header) => {
                              return header.sortColumn ? (
                                <option value={header.sortColumn} key={header.sortColumn}>
                                  {header.title}
                                </option>
                              ) : (
                                ""
                              );
                            })}
                          </Control.select>
                        </div>
                      </div>
                      <div className="gh-mob-radio-row">
                        <div className="uk-inline">
                          <RadioComponent
                            label={staticService.findByAlias("ascendant")}
                            model={".sortedBy"}
                            onClick={(isChecked, val, model) => {
                              const _modelName = modelName + model;
                              return this.props.updateModel(_modelName, val);
                            }}
                            value={"asc"}
                            wrapperClassName="gh-radio-wrapper-with-label"
                            checked={this.isChecked("asc")}
                          />
                        </div>

                        <div className="uk-inline gh-mob-radio-margin">
                          <RadioComponent
                            label={staticService.findByAlias("descendant")}
                            model={".sortedBy"}
                            onClick={(isChecked, val, model) => {
                              const _modelName = modelName + model;
                              return this.props.updateModel(_modelName, val);
                            }}
                            value={"desc"}
                            wrapperClassName="gh-radio-wrapper-with-label gh-right-radio"
                            checked={this.isChecked("desc")}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("NearByTo"),
                      }}
                    />
                    <div className="uk-form-controls uk-position-relative">
                      <LocationAutocompleteWrapper>
                        {({
                          locations,
                          search,
                          handleClickOutside,
                          loader,
                          onBlur,
                          validate,
                          validationMessage,
                        }) => {
                          return (
                            <div className={"uk-position-relative"}>
                              <LocationAutocompleteInput
                                className={["uk-input"]}
                                placeholder={"London"}
                                readOnly={false}
                                onSelect={(location) => {
                                  this.updateGeocodeModel(location);
                                  return search(location);
                                }}
                                onBlur={onBlur}
                                loader={loader}
                                debounce={500}
                                value={this.props.near_by}
                                additionalInfo={this.props.home_info}
                                model={`${modelName}.near_by`}
                              />
                              <LocationAutocompleteList
                                onSelect={this.onSelectLocation.bind(this, handleClickOutside)}
                                locations={locations}
                              />
                              <ul
                                className={
                                  "ul validation-errors disabled-list" + (validate ? " hide" : "")
                                }
                              >
                                <li>{validationMessage}</li>
                              </ul>
                            </div>
                          );
                        }}
                      </LocationAutocompleteWrapper>
                    </div>
                  </div>

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("WithinRadius"),
                      }}
                    />
                    <div className="uk-form-controls">
                      <Control.select model={".radius"} className="uk-select">
                        {config.miles.map((radius, index) => (
                          <option value={radius.id} key={index}>
                            {radius.title}
                          </option>
                        ))}
                      </Control.select>
                    </div>
                  </div>

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("date"),
                      }}
                    />
                    <div className="uk-form-controls uk-position-relative">
                      <Control
                        model={`.date`}
                        className={"uk-input gh-calendar-input"}
                        component={DateTimeComponent}
                        icon={calendar}
                        changeAction={(model, value) =>
                          updateModel(model, value.utc().toISOString())
                        }
                        updateOn="change"
                        controlProps={{
                          dateFormat: "DD MMMM, YYYY",
                          onClear: () => this.props.updateModel(modelName + ".date", ""),
                          isValidDateBefore: false,
                          isValidDate: false,
                        }}
                      />
                    </div>
                  </div>

                  <div className="uk-width-1-1@s uk-width-expand@m">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("clientRef"),
                      }}
                    />
                    <div className="uk-form-controls uk-position-relative">
                      <Control.text
                        className={"uk-input"}
                        model={".client_id"}
                        changeAction={updateModel}
                        updateOn="change"
                        placeholder={
                          staticService.findByAlias("typeTheClient") || "Type the client"
                        }
                      />
                    </div>
                  </div>
                </div>

                <div data-uk-grid>
                  <ActivityFilterCriteria
                    updateModel={this.props.updateModel}
                    modelName={modelName}
                    boxLabels={{ label1: "MyRequests", label2: "AllRequests" }}
                    activity={this.props.activity}
                  />
                </div>
                <div className="uk-flex-center" data-uk-grid>
                  <div className="uk-width-1-2 uk-text-right">
                    <Control.button
                      onClick={this.reset}
                      type={"button"}
                      model={modelName}
                      className="uk-button gh-cloud-blue-btn gh-filters-btn"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("resetFilter"),
                        }}
                      />
                    </Control.button>
                  </div>
                  <div className="uk-width-1-2 uk-text-left">
                    <Control.button
                      model={modelName}
                      className="uk-button uk-button-primary gh-filters-btn"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("showFilterResult"),
                        }}
                      />
                    </Control.button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    setActiveItem([], "");
    this.props.resetModel(modelName);
  }
}

BrokerRequestFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerRequestFiltersComponent.propTypes = {
  sortParams: PropTypes.shape({
    sortOrder: PropTypes.string,
    field: PropTypes.string,
  }),
  resetFilters: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  parentFilters: PropTypes.object,
  isLoading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      resetModel: (model) => dispatch(actions.reset(model)),
      get: getBrokerRequetsByFilter,
      update: getBrokerRequetsSuccess,
    },
    dispatch,
  );
};

const mapProps = (props) => {
  return {
    ...props[modelName],
    filters: props.filters,
    screenW: props.screenDimensions.screenInnerW,
    filtersModel: props.brokerRequestFiltersModel,
  };
};

const COMPONENT = connect(mapProps, mapDispatchToProps)(BrokerRequestFiltersComponent);
export { COMPONENT as BrokerRequestFiltersComponent };
