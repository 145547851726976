import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import StarIcon from "../../../assets/img/svg/star.svg";
import staticService from "../../../services/static.service";
import { dateFormat } from "../../../shared";
import logo from "../../../assets/img/eventThumbnail.png";

const imageSrc = (item) => {
  const params = "?width=288&height=193";
  if (item.thumbnail) {
    return item.thumbnail + params;
  } else if (item.photo) {
    return item.photo + params;
  } else {
    return logo;
  }
};

const altType = (item) => {
  if (item.thumbnail_alt) {
    return item.thumbnail_alt;
  } else if (item.photo_alt) {
    return item.photo_alt;
  } else {
    return item.name;
  }
};

const EventCard = (props) => {
  const { event } = props;

  return (
    <div className="uk-grid-item-match gh-margin-top-20 gh-event-card">
      <div
        className={`uk-card uk-card-default uk-card-body uk-text-center gh-padding-event-card ${
          event.is_featured_event || event.is_featured_destination ? "with-border" : ""
        }`}
      >
        <div data-uk-grid>
          <div className="uk-width-1-1 uk-width-2-5@s uk-width-1-4@l">
            <NavLink
              to={{
                pathname: `/${event.class}/${event.url}`,
              }}
              className="uk-button uk-flex uk-flex-middle uk-padding-remove"
            >
              <div className="uk-flex uk-flex-middle uk-flex-center uk-width-1-1 gh-image-event">
                <img src={imageSrc(event)} alt={altType(event)} />
              </div>
            </NavLink>
          </div>
          <div className="uk-width-1-1 uk-width-3-5@s uk-width-3-4@l gh-event-card-content_wrapper">
            <div className="gh-event-card-content">
              <div className="gh-event-card-header">
                <div>
                  {!!event.is_featured_event && (
                    <div className="gh-event-card-meta">
                      <span className="gh-event-icon">
                        <img src={StarIcon} alt="Featured" />
                      </span>
                      <span>{staticService.findByAlias("featuredEventsType")}</span>
                    </div>
                  )}

                  {!!event.is_featured_destination && (
                    <div className="gh-event-card-meta">
                      <span className="gh-event-icon">
                        <img src={StarIcon} alt="Featured" />
                      </span>
                      <span>{staticService.findByAlias("featuredDestinationType")}</span>
                    </div>
                  )}

                  <NavLink
                    to={{
                      pathname: `/${event.class}/${event.url}`,
                    }}
                    className="uk-button uk-padding-remove-left gh-padding-remove uk-flex uk-flex-middle"
                  >
                    <div className="gh-title uk-text-left">{event.name}</div>
                  </NavLink>
                </div>
                <NavLink
                  to={{
                    pathname: `/${event.class}/${event.url}`,
                  }}
                  className="uk-button uk-button-primary gh-pe-btn"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("priceEstimateBtnLabel"),
                    }}
                  />
                </NavLink>
              </div>

              {event.class === "events" ? (
                <div className="uk-inline uk-width-1-1">
                  <div data-uk-grid className="uk-grid-small">
                    <div className="uk-inline uk-width-1-6@l uk-width-1-2">
                      <div className="uk-text-left gh-el-card-item">
                        <span className="gh-event-list-label">type</span>
                        <div className="gh-info-description  gh-padding-remove">{event.type}</div>
                      </div>
                    </div>
                    <div className="uk-inline uk-width-1-5@l uk-width-1-2">
                      <div className="uk-text-left gh-el-card-item">
                        <span className="gh-event-list-label">location</span>
                        <div className="gh-info-description ">{event.country}</div>
                      </div>
                    </div>
                    <div className="uk-inline uk-width-1-3@l uk-width-1-1">
                      <div className="uk-text-left gh-el-card-item">
                        <span className="gh-event-list-label">dates</span>
                        <div
                          className={`gh-info-description  ${
                            event.is_outdated ? "gh-outdated-event" : ""
                          }`}
                        >
                          {event.start_date ? dateFormat(event.start_date) : "-"} -{" "}
                          {event.end_date ? dateFormat(event.end_date) : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="uk-inline uk-width-1-4@l uk-width-1-1">
                      {event.is_outdated ? (
                        <div className="uk-text-left">
                          <span className="gh-event-list-label gh-label-last">details</span>
                          <div
                            className=" gh-future-dates"
                            dangerouslySetInnerHTML={{
                              __html: staticService.findByAlias("futureDatesReleased"),
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="uk-inline uk-width-1-1">
                  <div data-uk-grid className="uk-grid-small">
                    <div className="uk-inline uk-width-1-5@l uk-width-1-2">
                      <div className="uk-text-left gh-el-card-item">
                        <span className="gh-event-list-label">flight type</span>
                        <div className="gh-info-description  gh-padding-remove">{event.type}</div>
                      </div>
                    </div>
                    <div className="uk-inline uk-width-1-4@l uk-width-1-2">
                      <div className="uk-text-left gh-el-card-item">
                        <span className="gh-event-list-label">locations</span>
                        <div className="gh-info-description ">{event.locations_string}</div>
                      </div>
                    </div>

                    {event.start_month ? (
                      <div className="uk-inline uk-width-1-5@l uk-width-1-1">
                        <div className="uk-text-left gh-el-card-item">
                          <span className="gh-event-list-label">Peak Season</span>
                          <div
                            className={`gh-info-description  ${
                              event.is_outdated ? "gh-outdated-event" : ""
                            }`}
                          >
                            <span style={{ marginRight: 5 }}>{event.start_month || ""}</span>
                            <span>{event.end_month ? <span>to {event.end_month}</span> : ""}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="uk-inline uk-width-1-4@l uk-width-1-1">
                      {event.is_outdated ? (
                        <div className="uk-text-left">
                          <span className="gh-event-list-label gh-label-last">details</span>
                          <div
                            className=" gh-future-dates"
                            dangerouslySetInnerHTML={{
                              __html: staticService.findByAlias("futureDatesReleased"),
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventCard;
