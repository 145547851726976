import * as types from "../../actions/actionTypes";

function aircraft(state = [], action) {
  switch (action.type) {
    case types.LOAD_AIRCRAFT_SUCCESS:
    case types.SAVE_AIRCRAFT_SUCCESS:
    case types.UPDATE_AIRCRAFT_SUCCESS:
    case types.AIRCRAFT_REMOVE_SUCCESS:
      return action.airCrafts;
    case types.UPDATE_AIRCRAFT_FLEET_LOCALLY:
      return state.map((item) =>
        item.id.toString() === action.fleet.id.toString()
          ? { ...item, files: action.fleet.files }
          : item,
      );
    default:
      return state;
  }
}

function aircraftSaved(state = {}, action) {
  switch (action.type) {
    case types.RESET_AIRCRAFT_ACTIONS:
      return { isUpdated: false, isAdded: false, airCrafts: action.airCrafts };
    default:
      return state;
  }
}

function premiumApply(state = false, action) {
  switch (action.type) {
    case types.PREMIUM_APPLY:
      return true;
    case types.RESET_MODEL:
      return false;
    default:
      return state;
  }
}

function helicopterGallery(state = [], action) {
  switch (action.type) {
    case types.LOAD_AIRCRAFT_GALLERY:
      return action.images;
    case types.RESET_MODEL:
      return [];
    default:
      return state;
  }
}

export { aircraft, aircraftSaved, premiumApply, helicopterGallery };
