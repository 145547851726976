import React from "react";
import PropTypes from "prop-types";
import "./pricingBanner.css";
import cross from "../../../../assets/img/svg/pricing-cross.svg";
const PricingBanner = ({ setBundle, bundle, pricingList }) => {
  const isActiveBundle = (checkBundle) => {
    if (bundle === checkBundle) {
      return " gh-active-bundle";
    }
    return "";
  };
  const bannerRef = React.useRef(null);
  const [classNameForBanner, setClassNameForBanner] = React.useState(" ");

  React.useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  const handleScrollEvent = React.useCallback(() => {
    if (!bannerRef.current) return;
    const bannerRect = bannerRef.current.getBoundingClientRect();
    if (bannerRect.top < 145) {
      setClassNameForBanner(" ");
    } else {
      setClassNameForBanner(" gh-banner-not-is-pinned");
    }
  }, [bannerRef]);

  const formattedData = React.useMemo(
    () =>
      (pricingList?.length > 0 &&
        pricingList.reduce((acc, item, index) =>
          index === 1
            ? {
                [acc.name.toString().toLowerCase()]: { ...acc },
                [item.name.toString().toLowerCase()]: { ...item },
              }
            : { ...acc, [item.name.toString().toLowerCase()]: { ...item } },
        )) ||
      {},
    [pricingList],
  );
  const returnPrice = React.useCallback(
    (bundle) => `${formattedData[bundle]?.amount.total} ${formattedData[bundle]?.symbol}`,
    [formattedData],
  );
  return (
    <div className={"gh-pricing-banner-wrapper" + classNameForBanner} ref={bannerRef}>
      <div className="gh-features-overlay">
        <div className="uk-grid uk-grid-small uk-height-1-1">
          <div className="uk-width-2-5" />
          <div className="uk-width-3-5">
            <div className="uk-grid uk-grid-small uk-height-1-1">
              {Array.from({ length: 5 }).map((_, idx) => (
                <div key={"mock-" + idx} className="uk-width-1-5 uk-padding-remove">
                  {idx === 0 ? (
                    <div className="bordered" style={{ transform: "translateX(-8px)" }} />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="uk-grid uk-grid-small gh-pricing-sticky-banner" style={{ marginBottom: 20 }}>
        <div className="uk-width-2-5 gh-pricing-first-line">
          <div className="uk-flex uk-flex-middle">
            <div className="option-title">Subscription Cost</div>
          </div>
        </div>
        <div className="uk-width-3-5">
          <div className="uk-grid uk-grid-small">
            <div
              className={
                "uk-width-1-5 gh-pricing-banner-handle-click gh-pricing-second-line" +
                isActiveBundle("commission")
              }
              style={{ paddingLeft: 0, paddingRight: 15 }}
              onClick={() => setBundle("commission")}
            >
              <div className="option-value">
                <img src={cross} alt="cross" />
              </div>
            </div>
            <div
              className={"uk-width-1-5 gh-pricing-banner-handle-click" + isActiveBundle("lite")}
              style={{ paddingLeft: 15, paddingRight: 0 }}
              onClick={() => setBundle("lite")}
            >
              <div className="option-value">
                <span>
                  <span className="bundle-option-numbers">{returnPrice("lite")}</span> per month{" "}
                  <sup className="sup-text">2</sup>
                </span>
              </div>
            </div>
            <div
              className={
                "uk-width-1-5 gh-pricing-banner-handle-click" + isActiveBundle("essentials")
              }
              style={{ paddingLeft: 15, paddingRight: 0 }}
              onClick={() => setBundle("essentials")}
            >
              <div className="option-value">
                <span>
                  <span className="bundle-option-numbers">{returnPrice("essentials")}</span> per
                  month <sup className="sup-text">2</sup>
                </span>
              </div>
            </div>
            <div
              className={
                "uk-width-1-5 gh-pricing-banner-handle-click uk-position-relative" +
                isActiveBundle("pro")
              }
              style={{ paddingLeft: 15, paddingRight: 0 }}
              onClick={() => setBundle("pro")}
            >
              <div className="option-value">
                <span>
                  <span className="bundle-option-numbers">{returnPrice("pro")}</span> per month{" "}
                  <sup className="sup-text">2</sup>
                </span>
              </div>
            </div>
            <div
              className={
                "uk-width-1-5 gh-pricing-banner-handle-click" + isActiveBundle("unlimited")
              }
              style={{ paddingLeft: 25, paddingRight: 0 }}
              onClick={() => setBundle("unlimited")}
            >
              <div className="option-value">
                <span>
                  <span className="bundle-option-numbers">{returnPrice("unlimited")}</span> per
                  month <sup className="sup-text">2</sup>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
PricingBanner.propTypes = {
  setBundle: PropTypes.func.isRequired,
  bundle: PropTypes.string.isRequired,
  pricingList: PropTypes.array.isRequired,
};
export default React.memo(PricingBanner);
