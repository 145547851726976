import * as types from "./actionTypes";
import handlerError from "./error";
import Api from "../services/api-handler";
import { staticText } from "../configs/";
import { aircraftTitle, spinnerInstance } from "../shared";

const entity = "manufacturer";

export function loadAircraftListSuccess(airCrafts) {
  return { type: types.LOAD_AIRCRAFT_SUCCESS, airCrafts };
}

export function loadManufacturerListSuccess(manufacturers) {
  return { type: types.LOAD_MANUFACTURER_SUCCESS, manufacturers };
}

export function saveAircraftSuccess(airCrafts) {
  return { type: types.SAVE_AIRCRAFT_SUCCESS, airCrafts };
}

export function updateAircraftSuccess(airCrafts) {
  return { type: types.UPDATE_AIRCRAFT_SUCCESS, airCrafts };
}

export function updateAircraftFleetLocally(fleet) {
  return { type: types.UPDATE_AIRCRAFT_FLEET_LOCALLY, fleet };
}

export function loadManufacturerList() {
  return function (dispatch) {
    return Api.setPath(entity, null)
      .getAll()
      .then((manufacturers) => {
        dispatch(loadManufacturerListSuccess(manufacturers));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function validateTailNumber(data, onSuccess, hideSpinner = true) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "fleetModel");
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath("aircraft", "validate/number")
      .post(data)
      .then((response) => {
        onSuccess(response);
        Api.setProperty("skipErrorAlert", false);
        if (hideSpinner) spinnerInstance.setProp("type", "large").hide();
      })
      .catch((err) => {
        handlerError(err, dispatch);
        spinnerInstance.setProp("type", "large").hide();
      });
  };
}

export function addAircraft(data, onSuccess) {
  Api.setProperty("messagePlaceholders", {
    aircraftName: aircraftTitle(data),
  }).setProperty("responseMessages", staticText.globalMessages.team);
  return function (dispatch) {
    return Api.setPath("aircraft", null)
      .post(data)
      .then((aircrafts) => {
        dispatch(saveAircraftSuccess(aircrafts));
        spinnerInstance.setProp("type", "large").hide();
        if (onSuccess) onSuccess(aircrafts);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function updateAircraft(data, id, onSuccess) {
  Api.setProperty("responseMessages", staticText.globalMessages.team).setProperty(
    "messagePlaceholders",
    {
      aircraftName: data.registration_number,
    },
  );
  spinnerInstance.setProp("type", "large").show();
  return function (dispatch) {
    return Api.setPath("aircraft", null)
      .put(id, data)
      .then((aircrafts) => {
        dispatch(loadManufacturerList());
        dispatch(loadMyAircraftList());
        dispatch(updateAircraftSuccess(aircrafts));
        spinnerInstance.setProp("type", "large").hide();
        if (onSuccess) onSuccess(aircrafts);
        return aircrafts;
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function removeAircraftSuccess(airCrafts) {
  return { type: types.AIRCRAFT_REMOVE_SUCCESS, airCrafts };
}

export function removeAircraft(aircraft, onSuccess) {
  return function (dispatch) {
    Api.setProperty("responseMessages", staticText.globalMessages.helicopter).setProperty(
      "messagePlaceholders",
      {
        aircraftName: aircraft.registration_number,
      },
    );
    return Api.setPath("aircraft", null)
      .delete(aircraft.id)
      .then((aircraftList) => {
        if (onSuccess) onSuccess(aircraftList);
        dispatch(removeAircraftSuccess(aircraftList));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadMyAircraftList(page) {
  return function (dispatch) {
    return Api.setPath("aircraft", page ? `list?page=${page}` : "list")
      .setQueryParams()
      .getAll()
      .then((aircraftList) => {
        dispatch(loadAircraftListSuccess(aircraftList));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadMyAircraftListExtraHelicopters(page) {
  return function (dispatch) {
    return Api.setPath("aircraft", page ? `list/1?page=${page}` : "list/1")
      .getAll()
      .then((aircraftList) => {
        dispatch(loadAircraftListSuccess(aircraftList));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadAircraftFilter() {
  return function (dispatch) {
    return Api.setPath("aircraft", "filter")
      .setQueryParams()
      .getAll()
      .then((aircraftList) => {
        dispatch(loadAircraftListSuccess(aircraftList));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function premiumSuccess(data) {
  return { type: types.PREMIUM_APPLY, data };
}

export function premiumApply(data) {
  Api.setProperty("skipErrorAlert", true);
  return function (dispatch) {
    return Api.setPath("aircraft", `premium/${data.id}`)
      .post({ message: data.message })
      .then((data) => {
        dispatch(premiumSuccess(data));
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadHelicopterGallerySuccess(images) {
  return { type: types.LOAD_AIRCRAFT_GALLERY, images };
}

export function loadHelicopterGallery(type_id, manufacturer_id) {
  Api.setProperty("skipErrorAlert", true);
  return function (dispatch) {
    return Api.setPath("aircraft-image", `${type_id}/${manufacturer_id}`)
      .getAll()
      .then((data) => {
        dispatch(loadHelicopterGallerySuccess(data));
        Api.setProperty("skipErrorAlert", false);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function requestVerification(id, onSuccess, onError) {
  return function (dispatch) {
    Api.setProperty("skipErrorAlert", true);
    Api.setProperty("showSpinner", false);
    return Api.setPath("aircraft", "validate/" + id)
      .post()
      .then((response) => {
        Api.setProperty("skipErrorAlert", false);
        Api.setProperty("showSpinner", true);
        return onSuccess(response);
      })
      .catch((err) => {
        onError(err);
        return handlerError(err, dispatch);
      });
  };
}
