import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import staticService from "../../../services/static.service";
import { updateQuoteDetail } from "../../../actions";
import FlagIcon from "../FlagIcon";
import ResetBtn from "../ResetBtn";
import CheckboxFuncComponent from "../CheckboxFuncComponent";
import PassengerInfoDetail from "../PassengerInfoDetail";
import Tooltip from "../Tooltip";

import simpleCheckIcon from "../../../assets/img/svg/checkSimple.svg";

import PriceInputComponent from "../PriceInputComponent";
import { Controller, useForm } from "react-hook-form";
import QuotePassengersReadOnlyRadioButton from "./QuotePassengersReadOnlyRadioButton";
import "./quotePassengersInfo.css";
import AlternativeBtn from "../AlternativeBtn";

const QuotePassengersInfo = (props) => {
  const {
    leg,
    originalLeg,
    updatePassengersInfo,
    legIndex,
    editingQuote,
    originalQuote,
    updateQuoteDetail,
    readOnly,
    isWillProvideLaterDisplayed = true,
    updateTheSamePassengers,
    updateLeg,
    screenDimensions,
    passengerAmountError,
    setPassengerAmountError,
    isEmptyLeg,
  } = props;

  const { control } = useForm();
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [editingPassengersDetail, setEditingPassengersDetail] = useState(false);
  const [isEditedInfo, setIsEditedInfo] = useState({
    is_edited_passengers: false,
    is_edited_luggage: false,
    is_edited_special_items: false,
    is_edited_other_special_items: false,
  });
  const [isFromQuoteForm, setIsFromQuoteForm] = useState(false);

  useEffect(() => {
    if (passengerAmountError !== null && passengerAmountError !== undefined)
      setEditingPassengersDetail(true);
  }, [passengerAmountError]);

  useEffect(() => {
    // check by url if component is loaded from QuoteForm
    const currentPathname = window.location.href;
    if (currentPathname.includes("marketplace/trip/quote")) {
      setIsFromQuoteForm(true);
    }
    return () => {
      setIsFromQuoteForm(false);
    };
  }, []);

  useEffect(() => {
    const isEdited = Object.keys(isEditedInfo).filter((i) => isEditedInfo[i] === true);
    if (isEdited.length) {
      setEditingPassengersDetail(true);
    }
  }, [isEditedInfo]);

  useEffect(() => {
    setTotalPassengers(leg.passengers.men + leg.passengers.women + leg.passengers.children);

    if (readOnly) return;

    const originalSpecialItems = (({ other, ...o }) => o)(originalLeg.special_items);
    const editingSpecialItems = (({ other, ...o }) => o)(leg.special_items);

    setIsEditedInfo({
      is_edited_passengers:
        JSON.stringify(originalLeg.passengers) !== JSON.stringify(leg.passengers),
      is_edited_luggage: JSON.stringify(originalLeg.luggage) !== JSON.stringify(leg.luggage),
      is_edited_special_items:
        JSON.stringify(originalSpecialItems) !== JSON.stringify(editingSpecialItems),
      is_edited_other_special_items:
        JSON.stringify(leg.special_items.other === null ? "" : leg.special_items.other) !==
        JSON.stringify(
          originalLeg.special_items.other === null ? "" : originalLeg.special_items.other,
        ),
    });
  }, [leg, readOnly]);

  const resetPassengersInfo = (legIndex) => {
    let skip = false;
    const newQuoteData = {
      ...editingQuote,
      legs: editingQuote.legs.map((item, index) => {
        if (index < legIndex) {
          return item;
        } else if (index === legIndex) {
          return {
            ...item,
            passengers: originalQuote.legs[index].passengers,
            luggage: originalQuote.legs[index].luggage,
            special_items: originalQuote.legs[index].special_items,
            maximum_weight_allowed: originalQuote.legs[index].maximum_weight_allowed || "0",
            same_as_previous: false,
          };
        } else if (legIndex < index && item.same_as_previous) {
          if (skip) {
            return item;
          } else {
            return {
              ...item,
              passengers: originalQuote.legs[index - 1].passengers,
              luggage: originalQuote.legs[index - 1].luggage,
              special_items: originalQuote.legs[index - 1].special_items,
              maximum_weight_allowed: originalQuote.legs[index - 1].maximum_weight_allowed || "0",
              same_as_previous: true,
            };
          }
        } else {
          skip = true;
          return item;
        }
      }),
    };

    updateQuoteDetail(newQuoteData);
  };

  return (
    <div className="gh-passengers">
      <div className="gh-passengers-info">
        <div className="gh-passengers-info-item">
          <h4 className="title-item">
            passengers{" "}
            {isEditedInfo.is_edited_passengers ? (
              <Tooltip
                pos={`bottom-${screenDimensions.screenInnerW > 768 ? "right" : "left"}`}
                refEl="self"
                wrapperClassName="uk-margin-small-left"
                trigger={<FlagIcon />}
              >
                {staticService.findByAlias("modifiedFromOriginal")}
              </Tooltip>
            ) : (
              ""
            )}
          </h4>
          <PassengerInfoDetail
            editing={editingPassengersDetail}
            data={leg.passengers}
            legId={leg.id}
            category="passengers"
            updatePassengersInfo={updatePassengersInfo}
            legIndex={legIndex}
            items={[
              { key: "men", title: "men", alias: "men" },
              { key: "women", title: "women", alias: "women" },
              { key: "children", title: "children", alias: "children" },
              { key: "total", title: "total", alias: "total", totalValue: totalPassengers },
            ]}
            passengerAmountError={passengerAmountError}
          />
          {leg.passengers && !editingPassengersDetail ? (
            <div>
              <div className="gh-passengers-comment">
                <img src={simpleCheckIcon} alt="icon" />
                <span className="gh-passengers-comment-text">
                  {staticService.findByAlias(
                    leg.passengers.passengers_amount_type === 1
                      ? "exactPaxNumber"
                      : "posibilityOfChangePax",
                  )}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="gh-passengers-info-item">
          <h4 className="title-item">
            {staticService.findByAlias("luggage")}
            {isEditedInfo.is_edited_luggage ? (
              <Tooltip
                pos={`bottom-${screenDimensions.screenInnerW > 768 ? "right" : "left"}`}
                refEl="self"
                wrapperClassName="uk-margin-small-left"
                trigger={<FlagIcon />}
              >
                {staticService.findByAlias("modifiedFromOriginal")}
              </Tooltip>
            ) : (
              ""
            )}
          </h4>
          <PassengerInfoDetail
            data={leg.luggage}
            editing={editingPassengersDetail}
            legId={leg.id}
            category="luggage"
            updatePassengersInfo={updatePassengersInfo}
            legIndex={legIndex}
            items={[
              { key: "carry_on_bags", title: "Carry-on bags", alias: "Carry-onBags" },
              { key: "hand_bags", title: "hand bags", alias: "handBags" },
            ]}
          />
          {isWillProvideLaterDisplayed &&
          leg.luggage &&
          leg.luggage.luggage_will_provide_later === 1 &&
          !editingPassengersDetail ? (
            <div className="gh-passengers-comment">
              <img src={simpleCheckIcon} alt="icon" />
              <span className="gh-passengers-comment-text">
                {staticService.findByAlias("willProvideLater")}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="gh-passengers-info-item">
          <h4 className="title-item">
            {staticService.findByAlias("specialItems")}
            {isEditedInfo.is_edited_special_items ? (
              <Tooltip
                pos={`bottom-${screenDimensions.screenInnerW > 768 ? "right" : "left"}`}
                refEl="self"
                wrapperClassName="uk-margin-small-left"
                trigger={<FlagIcon />}
              >
                {staticService.findByAlias("modifiedFromOriginal")}
              </Tooltip>
            ) : (
              ""
            )}
          </h4>
          <PassengerInfoDetail
            data={leg.special_items}
            editing={editingPassengersDetail}
            legId={leg.id}
            legIndex={legIndex}
            category="special_items"
            updatePassengersInfo={updatePassengersInfo}
            items={[
              { key: "bicycles", title: "bicycles", alias: "bicycles" },
              { key: "golf_club_sets", title: "golf club sets", alias: "golfClubSets" },
              { key: "hunting_weapons", title: "hunting weapons", alias: "huntingWeapons" },
              { key: "pets", title: "pets", alias: "pets" },
              { key: "ski_pairs", title: "ski pairs", alias: "skiPairs" },
            ]}
          />
        </div>
        <div className="gh-passengers-info-item">
          <h4 className="title-item">
            {staticService.findByAlias("otherSpecialItems")}
            {isEditedInfo.is_edited_other_special_items ? (
              <Tooltip
                pos={`bottom-left`}
                refEl="self"
                wrapperClassName="uk-margin-small-left"
                trigger={<FlagIcon />}
              >
                {staticService.findByAlias("modifiedFromOriginal")}
              </Tooltip>
            ) : (
              ""
            )}
          </h4>
          <PassengerInfoDetail
            data={leg.special_items}
            editing={editingPassengersDetail}
            legId={leg.id}
            legIndex={legIndex}
            category="special_items"
            updatePassengersInfo={updatePassengersInfo}
            items={[{ key: "other", title: "other" }]}
          />
        </div>
      </div>
      {editingPassengersDetail ? (
        <ResetBtn
          handleReset={() => {
            setEditingPassengersDetail(false);
            resetPassengersInfo(legIndex);
            if (setPassengerAmountError) setPassengerAmountError(null);
          }}
        />
      ) : (
        ""
      )}
      <QuotePassengersReadOnlyRadioButton show={false} />
      {isFromQuoteForm ? (
        <div
          className="gh-passengers-comment gh-estimate-check-item checked"
          style={{ justifyContent: "flex-end" }}
        >
          {!editingPassengersDetail ? (
            <AlternativeBtn handleClick={() => setEditingPassengersDetail(true)} />
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: editingPassengersDetail ? 0 : window.innerWidth < 475 ? 15 : 0,
            }}
            className="gh-left-auto"
          >
            <label
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("maximumWeightAllowed"),
              }}
            />
            <Controller
              control={control}
              name="maximum_weight_allowed"
              rules={{
                required: {
                  value: true,
                  message: staticService.findByAlias("maxWeightAllowedRequired"),
                },
              }}
              render={({ field, fieldState }) => (
                <PriceInputComponent
                  additionalClass="max-weight"
                  field={field}
                  label="KG"
                  fieldState={fieldState}
                  value={leg.maximum_weight_allowed !== "0.00" ? leg.maximum_weight_allowed : ""}
                  onChange={(value) => {
                    field.onChange(value);
                    updateLeg("maximum_weight_allowed", { [leg.id]: value || "0.00" });
                  }}
                />
              )}
            />
          </div>
        </div>
      ) : window.location.href.includes("preview-quote") &&
        leg.maximum_weight_allowed &&
        leg.maximum_weight_allowed !== "0.00" ? (
        <div className="gh-leg-max-weight">
          <label
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("maximumWeightAllowed"),
            }}
          />
          <span className="gh-max-weight">{leg.maximum_weight_allowed}</span>
          <label className="gh-symbol-label">KG</label>
        </div>
      ) : (
        ""
      )}

      {legIndex > 0 ? (
        <div className="uk-margin-bottom uk-margin-medium-top gh-passengers-same-as">
          {!readOnly && (
            <CheckboxFuncComponent
              label={staticService.findByAlias("sameAsPreviousLeg")}
              checked={leg.same_as_previous}
              onClick={() => {
                updateTheSamePassengers(!leg.same_as_previous, legIndex);
              }}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  screenDimensions: store.screenDimensions,
});

QuotePassengersInfo.defaultProps = {
  readOnly: false,
  hideCheckbox: false,
  isEmptyLeg: false,
};

QuotePassengersInfo.propTypes = {
  leg: PropTypes.object,
  originalLeg: PropTypes.object,
  updatePassengersInfo: PropTypes.func,
  legIndex: PropTypes.number,
  updateQuoteDetail: PropTypes.func,
  editingQuote: PropTypes.object,
  originalQuote: PropTypes.object,
  readOnly: PropTypes.bool,
  isWillProvideLaterDisplayed: PropTypes.bool,
  updateTheSamePassengers: PropTypes.func,
  hideCheckbox: PropTypes.bool,
  updateLeg: PropTypes.func,
  screenDimensions: PropTypes.object,
  passengerAmountError: PropTypes.number,
  setPassengerAmountError: PropTypes.func,
  isEmptyLeg: PropTypes.bool,
};

export default connect(mapStateToProps, { updateQuoteDetail })(QuotePassengersInfo);
