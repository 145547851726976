import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getAvailableCountries } from "../../../actions";
import { showNotification } from "../../../utils/showNotification";
import getApiErrorMessages from "../../../utils/getApiErrorMessages";
import getRandomInt from "../../../utils/getRandomInt";
import greenMarker from "../../../assets/img/svg/greenMarker.svg";
import staticService from "../../../services/static.service";
import auth from "../../../services/auth";

const AvailableCountriesContent = (props) => {
  const { availableCountries, dispatch, forceFetchCountries, children } = props;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!availableCountries.items.length && (availableCountries.isOpen || forceFetchCountries)) {
      (async () => {
        try {
          setLoading(true);
          await getAvailableCountries()(dispatch);
        } catch (error) {
          showNotification(getApiErrorMessages(error), "danger");
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [availableCountries]);

  const LoadingList = Array.from(Array(4)).map((_, idx) => (
    <li
      className="available-countries-item skeleton"
      style={{
        width: getRandomInt(60, 90) + "%",
      }}
      key={idx}
    />
  ));

  const ActualList = availableCountries.items.map(({ code, name }) => (
    <li className="available-countries-item" key={code}>
      <span className="icon">
        <img alt="Icon" src={greenMarker} />
      </span>
      <span className="title">{name}</span>
    </li>
  ));

  return (
    <>
      <div className="available-countries-content">
        <h5 className="available-countries-title">
          {staticService.findByAlias("availableCountriesDescrp")}
        </h5>
        <ul className="available-countries-list disabled-list">
          {loading ? LoadingList : ActualList}
        </ul>
      </div>
      {auth.isAuthenticated() && children}
    </>
  );
};

AvailableCountriesContent.propTypes = {
  availableCountries: PropTypes.object,
  dispatch: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  forceFetchCountries: PropTypes.bool,
};
AvailableCountriesContent.defaultProps = { forceFetchCountries: false };
export default AvailableCountriesContent;
