import React from "react";
import { Switch } from "react-router-dom";
import { RouteWithSubRoutes, returnRouteUrl } from "../../shared";

export const AccountComponent = ({ match, routes }) => {
  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <RouteWithSubRoutes key={i} title={route.title} {...returnRouteUrl(route, match.url)} />
        );
      })}
    </Switch>
  );
};
