import React from "react";
import GetHeliEvents from "../services/event.service";
import { config } from "../configs";
import { redirectTo } from "../shared";

class WrapperComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      allowed: true,
    };
  }

  componentWillMount() {
    GetHeliEvents.setEvent("server-error", (event, error) => {
      let path = "";
      let allowed = true;
      switch (error.status) {
        case 403:
          allowed = false;
          path = config.redirectToForbidden;
          break;
        case 404:
          allowed = false;
          path = config.redirectToNotFound;
          break;
      }
      this.setState({ path: path, allowed: allowed });
    });
  }

  render() {
    if (this.state.allowed) {
      return this.props.children;
    }
    return redirectTo(this.state.path);
  }
}

export { WrapperComponent };
