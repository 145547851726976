import React from "react";
import PropTypes from "prop-types";
import { checkObjectProp } from "../helpers";

const RowComponent = ({
  attrProps,
  actionColumn,
  actionElements,
  row,
  rowIndex,
  isHeader = false,
  headersColumnProps = [],
}) => {
  const tagProps = attrProps || {};
  const actions = (index) => (actionColumn ? actionElements(index) : null);
  const columnProps = (index) => {
    if (headersColumnProps.length > 0 && headersColumnProps[index]) {
      return headersColumnProps[index];
    }
    return {};
  };
  if (isHeader) {
    return (
      <tr {...tagProps}>
        {row.map((column, index) => (
          <th key={index} {...columnProps(index)}>
            {column.content || column}
          </th>
        ))}
        {actions(rowIndex)}
      </tr>
    );
  }

  return (
    <tr className="gh-fleet-row" {...tagProps}>
      {row.map((column, index) => (
        <td key={index}>{checkObjectProp(column, "content", column)}</td>
      ))}
      {actions(rowIndex)}
    </tr>
  );
};

RowComponent.propTypes = {
  attrProps: PropTypes.object,
  row: PropTypes.array,
  actionColumn: PropTypes.bool,
  isHeader: PropTypes.bool,
  actionElements: PropTypes.any,
  rowIndex: PropTypes.number,
  headersColumnProps: PropTypes.array,
};

RowComponent.defaultProps = {
  attrProps: {},
  row: [],
  actionColumn: false,
};

export { RowComponent };
