import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import useLegTotalCost from "../../hooks/useLegTotalCost";
import { Description } from "../Description";
import { Control } from "../Control";
import { ConfirmInput } from "../Input";
import FlightSection from "./sections/FlightSection";
import LegContext from "./LegContext";
import PilotsSection from "./sections/PilotsSection";
import FeesSection from "./sections/FeesSection";
import ExtracostsSection from "./sections/ExtracostsSections";
import NotesSection from "./sections/NotesSection";
import GeneralSection from "./sections/GeneralSection";
import LegDragBtn from "./LegDragBtn";
import useLegCalculation from "../../hooks/useLegCalculation";
import { useCalculatorContext } from "../../CalculatorContext";
import cs from "../../../../utils/condString";

function Leg(props) {
  const { prefix, index, isLast, minPilotsLen, legsLen, onRemove, onTotalUpdate, moveLeg } = props;
  const { updateForm } = useCalculatorContext();
  const { watch, getValues, setValue } = useFormContext();
  const [isHidden, setIsHidden] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);

  const legCalculation = useLegCalculation({
    getValues,
    setValue,
    prefix,
    updateForm,
  });

  const legRepositioning = watch(`${prefix}.home_base_repositioning`);
  // const legCategory = watch(`${prefix}.category`);
  // const repositionLegCategory = 2;

  useLegTotalCost(prefix, onTotalUpdate, index);

  const legId = watch(`${prefix}.id`);

  const config = React.useMemo(
    () => ({
      remove: index > 2,
      move: false,
      copy: isNaN(legId) && !legRepositioning,
    }),
    [legId, legsLen, legRepositioning],
  );

  return (
    <div className={cs("leg", isHidden && "hidden", isDragging && "is-dragging")}>
      <LegContext.Provider
        value={{
          prefix,
          isLast,
          isHidden,
          setIsHidden,
          index,
          onRemove,
          config,
          legId,
          ...legCalculation,
        }}
      >
        <LegDragBtn moveLeg={moveLeg} setIsDragging={setIsDragging} />

        <GeneralSection />
        <FlightSection />
        <PilotsSection minPilotsLen={minPilotsLen} />
        <FeesSection />
        <ExtracostsSection />
        <NotesSection />

        <Description className="uk-margin-small-top">
          <Description.Item as="text" type="footer" separator>
            <Control name={`${prefix}.total_cost_leg`}>
              <ConfirmInput placeholder="0.00" readOnly />
            </Control>
          </Description.Item>
        </Description>

        <Control name={`${prefix}.calculation_leg_id`} notAComponent>
          <input type="hidden" />
        </Control>
      </LegContext.Provider>
    </div>
  );
}

Leg.propTypes = {
  prefix: PropTypes.string,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  minPilotsLen: PropTypes.number,
  legsLen: PropTypes.number,
  onRemove: PropTypes.func,
  onTotalUpdate: PropTypes.func,
  moveLeg: PropTypes.func,
};

Leg.defaultProps = {};

export default React.memo(Leg);
