import React from "react";
import PropTypes from "prop-types";
import QuoteTabItem from "./QuoteTabItem";
import { getTabDisabledStatus, getTabTooltipMessage } from "./quoteTabs.utils";

import "./quoteTabs.css";

const QuoteTabs = (props) => {
  const {
    location: { pathname },
    match: { url },
    routes,
    handleChangeTab,
    quoteId,
    editingQuote,
  } = props;

  const isTabDisabled = React.useMemo(() => getTabDisabledStatus(editingQuote), [editingQuote]);
  const tabTooltipMessage = React.useMemo(() => getTabTooltipMessage(editingQuote), [editingQuote]);

  const onTabClick = React.useCallback(
    (tabName) => () => {
      handleChangeTab(tabName, pathname);
    },
    [pathname],
  );

  return (
    <div className="gh-quote-tabs" id="quote-trip-tabs">
      {routes.map((tab) => (
        <React.Fragment key={tab.path}>
          <QuoteTabItem
            title={tab.title}
            path={tab.path}
            tabName={tab.tabName}
            tooltipMessage={tabTooltipMessage(tab.tabName)}
            disabled={isTabDisabled(tab.tabName)}
            active={pathname === url + "/" + tab.tabName + "/" + quoteId}
            onClick={onTabClick(tab.tabName)}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

QuoteTabs.propTypes = {
  routes: PropTypes.array,
  handleChangeTab: PropTypes.func,
  quoteId: PropTypes.number,
  location: PropTypes.object,
  match: PropTypes.object,
  editingQuote: PropTypes.object,
};

export default React.memo(QuoteTabs);
