import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Redirect } from "react-router-dom";
import { staticText } from "../../../configs";
import { BaseRegisterFormTemplate } from "./base-form.component";
import { BlockComponent } from "../../common";
import { register, LOAD_CONDITION_SUCCESS } from "../../../actions";
import { storage, explode } from "../../../shared/";
import staticService from "../../../services/static.service";
import api from "../../../services/api-handler";

class SummaryFormComponent extends BaseRegisterFormTemplate {
  constructor(props) {
    super(props);
    this.state = {
      mainTitle: staticService.findByAlias("summaryMainTitle"),
      redirectPath: false,
      statuses: this.props.userRegisterModel.company.sole_trader.value
        ? staticText.registerTabTitle.summary.statuses
            .filter((stat) => stat.title !== "Documentation")
            .map((status) => {
              status.title = staticService.findByAlias(status.alias);
              return status;
            })
        : staticText.registerTabTitle.summary.statuses.map((status) => {
            status.title = staticService.findByAlias(status.alias);
            return status;
          }),
    };
    this.isActive = this.isActive.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.backClicked = false;
    api.showSpinner = true;
  }

  filterEmails(emails) {
    return emails.reduce((prev, next) => {
      if (next && next !== "") {
        prev.push(next);
      }
      return prev;
    }, []);
  }

  componentDidMount() {
    this.context.store.dispatch(actions.change("userRegisterModel.files", this.props.files.length));
    this.context.store.dispatch(
      actions.change("userRegisterModel.invitations", this.filterEmails(this.props.invitations)),
    );

    if (this.props.userModel.type === "operator") {
      this.context.store.dispatch(
        actions.change("userRegisterModel.helicopters", this.props.helicopters),
      );
    }
  }

  isActive(prop) {
    return this.props && (this.props[prop] || this.props[prop] > 0) ? "checked" : "not-checked";
  }

  isAllowed(permission, userType) {
    return permission === "both" || permission === userType;
  }

  onSubmit(user) {
    this.context.store.dispatch(
      register(
        {
          ...user,
          helicopters: user.helicopters.map((heli) => {
            return { ...heli, date_last_refurbished: explode(heli.date_last_refurbished) };
          }),
        },
        this.props.files,
      ),
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors && nextProps.errors !== null) {
      this.context.store.dispatch(actions.setPending("userRegisterModel", false));
    }

    if (this.props.isRegistered !== nextProps.isRegistered) {
      this.setState({ redirectPath: "/dashboard" });
    }
  }

  back() {
    this.backClicked = true;
    this.props.back();
  }

  get content() {
    const rows = this.state.statuses.reduce((prev, next, index) => {
      {
        if (!this.isAllowed(next.only, this.props.userModel.type)) return prev;
        return prev.concat([
          <li key={index}>
            <div data-uk-grid>
              <div className="uk-width-expand">{next.title}</div>
              <div className="uk-width-auto uk-text-right">
                <span
                  className={["uk-checkbox", "gh-checkbox", this.isActive(next.prop)].join(" ")}
                />
              </div>
            </div>
          </li>,
        ]);
      }
    }, []);
    return (
      <BlockComponent
        visible={true}
        containerClass={"uk-container uk-container-affiliate"}
        parentProps={{ className: "uk-section uk-padding-remove-top gh-section-statistics" }}
      >
        <Form onSubmit={this.onSubmit} model="userRegisterModel">
          <ul className="disabled-list uk-list uk-margin-medium-top gh-status-account-summary">
            {rows}
            <li>
              <div data-uk-grid>
                <div className="uk-width-expand">{staticService.findByAlias("codeOfConduct")}</div>
                <div className="uk-width-auto uk-text-right">
                  <span className={["uk-checkbox", "gh-checkbox", "checked"].join(" ")} />
                </div>
              </div>
            </li>
          </ul>
          <div className="uk-margin-medium-top uk-text-center uk-margin-medium-bottom gh-buttons-summary">
            <button
              type={"button"}
              onClick={this.back.bind(this)}
              className={"uk-button gh-cloud-blue-btn uk-margin-small-bottom uk-margin-small-left"}
            >
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("back") }} />
            </button>
            <Control.button
              model="userRegisterModel"
              className={"uk-button uk-button-primary uk-margin-small-bottom uk-margin-small-left"}
            >
              <span
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("createAccount") }}
              />
            </Control.button>
          </div>
        </Form>
      </BlockComponent>
    );
  }

  render() {
    if (!this.state.redirectPath) {
      return super.render();
    }
    return <Redirect to={this.state.redirectPath} />;
  }

  componentWillUnmount() {
    if (!this.backClicked) {
      storage.clearByKeys([
        "userRegisterModel",
        "invitationsModel",
        "companyFilesModel",
        "itemsForGrid",
        "itemsForServer",
      ]);
      this.context.store.dispatch(actions.reset("userRegisterModel"));
      this.context.store.dispatch(actions.reset("invitationsModel"));
      this.context.store.dispatch(actions.reset("companyFilesModel"));
      this.context.store.dispatch(actions.reset("fleetModels"));
      this.context.store.dispatch({ type: LOAD_CONDITION_SUCCESS, data: [] });
      this.backClicked = false;
    }
  }
}

SummaryFormComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    files: state.companyFilesModel,
    userModel: state.userRegisterModel,
    userRegisterModel: state.forms.userRegisterModel,
    helicopters: state.fleetModels,
    fleet: state.fleetModels.length,
    documentation: state.companyFilesModel.length,
    invitations: state.invitationsModel,
    invitation: state.invitationsModel.length,
    account:
      state.userRegisterModel.email !== "" || storage.get("userRegisterModel") !== undefined
        ? true
        : false,
    isRegistered: state.auth.user ? true : false,
  };
}

const COMPONENT = connect(mapStateToProps)(SummaryFormComponent);
export { COMPONENT as SummaryFormComponent };
