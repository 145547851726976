import React, { Component } from "react";
import PropTypes from "prop-types";
import { RadioComponent } from "../../../../shared";
import staticService from "../../../../services/static.service";
import auth from "../../../../services/auth";

class ActivityFilterCriteria extends Component {
  constructor(props) {
    super(props);
    this.isChecked = this.isChecked.bind(this);
  }

  isChecked(type) {
    return this.props.activity === type ? true : false;
  }

  render() {
    return (
      <div className="gh-mob-radio-row">
        <div className="uk-inline gh-mob-radio" style={{ marginRight: "15px" }}>
          <RadioComponent
            label={staticService.findByAlias(this.props.boxLabels.label1)}
            model={".activity"}
            onClick={(isChecked, val, model) => {
              const _modelName = this.props.modelName + model;
              return this.props.updateModel(_modelName, val);
            }}
            value={"own"}
            wrapperClassName="gh-radio-wrapper-with-label"
            checked={this.isChecked("own")}
          />
        </div>

        <div className="uk-inline gh-mob-radio-margin gh-mob-radio" style={{ marginRight: "15px" }}>
          <RadioComponent
            label={staticService.findByAlias(this.props.boxLabels.label2)}
            model={".activity"}
            onClick={(isChecked, val, model) => {
              const _modelName = this.props.modelName + model;
              return this.props.updateModel(_modelName, val);
            }}
            value={"all"}
            wrapperClassName="gh-radio-wrapper-with-label"
            checked={this.isChecked("all")}
          />
        </div>
      </div>
    );
  }
}

ActivityFilterCriteria.propTypes = {
  activity: PropTypes.string,
  updateModel: PropTypes.func,
  modelName: PropTypes.string,
  boxLabels: PropTypes.object,
};

ActivityFilterCriteria.defaultProps = {
  boxLabels: {
    label1: "MyQuotes",
    label2: "AllQuotes",
  },
};

export { ActivityFilterCriteria };
