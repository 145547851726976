import React from "react";
import ViewFullQuoteDetails from "../../../ViewFullQuoteDetails/ViewFullQuoteDetails";

const OperatorBookingQuoteDetailComponent = (props) => {
  return (
    <div>
      <ViewFullQuoteDetails {...props} />
    </div>
  );
};

export { OperatorBookingQuoteDetailComponent };
