import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { recoverPassword } from "../../actions";
import { validationRules, validationMessages } from "../../configs";
import { CustomValidateError, replaceAttributesInString } from "../../shared";
import staticService from "../../services/static.service";
import showIcon from "../../assets/img/show.png";
import hideIcon from "../../assets/img/hide.png";

const { min, hasWhiteSpace, passwordValid, required, matchField } = validationRules;

class RecoverPasswordFormComponent extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      showPassword: false,
      showConfirmPassword: false,
    };

    context.store.dispatch(actions.reset("passwordConfirmModel"));
    this.updateMatchModel = this.updateMatchModel.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.forgottenStatus !== nextProps.forgottenStatus) {
      this.context.store.dispatch(actions.reset("passwordConfirmModel"));
    }
  }

  updateMatchModel(matchModelName, model, value) {
    return (dispatch) => {
      const modelName = model.split(".");
      dispatch(actions.resetValidity(model));
      dispatch(actions.change(model, value));
      if (this.props.model[matchModelName] !== "") {
        dispatch(
          actions.validate(modelName[0] + "." + matchModelName, {
            matchField: matchField(value),
          }),
        );
      }
    };
  }

  render() {
    const { showPassword, showConfirmPassword } = this.state;
    if (!this.props.visible) return null;

    return (
      <Form
        model="passwordConfirmModel"
        className={"gh-recover-pass uk-margin-large-top"}
        onSubmit={this.props.recoverPassword.bind(null, this.props.requestHash)}
      >
        <label
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("resetPasswordTitle"),
          }}
        />

        <div className="uk-width-1-1 uk-align-center gh-wrapper-input-password">
          <label
            className={"required uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("password"),
            }}
          />
          <Control.text
            type={!showPassword ? "password" : ""}
            className={"uk-input"}
            model={".password"}
            changeAction={this.updateMatchModel.bind(null, "password_confirmation")}
            updateOn="change"
            validateOn="blur"
            validators={{
              hasWhiteSpace,
              passwordValid,
              required,
              min: min(8),
              matchField: matchField(this.props.model.password_confirmation),
            }}
          />

          <div
            className="gh-icon-show-password"
            onClick={() => {
              this.setState({ showPassword: !showPassword });
            }}
          >
            <img src={showPassword ? showIcon : hideIcon} alt="" />
          </div>

          <Errors
            model=".password"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              hasWhiteSpace: replaceAttributesInString(
                { field: "Password" },
                staticService.findByAlias("spaceValidationMessage"),
              ),
              passwordValid: staticService.findByAlias("newPasswordValidation"),
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("password"),
              ),

              min: validationMessages().minMessage(8),
              matchField: validationMessages().matchFieldMessage.bind(null, "Passwords"),
            }}
          />
        </div>
        <small
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("passwordValidation"),
          }}
        />
        <div className="uk-width-1-1 uk-align-center gh-wrapper-input-password">
          <label
            className={"required uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: `${staticService.findByAlias("confirm")} ${staticService.findByAlias(
                "password",
              )}`,
            }}
          />
          <Control.text
            type={!showConfirmPassword ? "password" : ""}
            className={"uk-input"}
            model={".password_confirmation"}
            changeAction={this.updateMatchModel.bind(null, "password")}
            updateOn="change"
            validateOn="blur"
            validators={{
              hasWhiteSpace,
              passwordValid,
              required,
              min: min(8),
              matchField: matchField(this.props.model.password),
            }}
          />

          <div
            className="gh-icon-show-password"
            onClick={() => {
              this.setState({ showConfirmPassword: !showConfirmPassword });
            }}
          >
            <img src={showConfirmPassword ? showIcon : hideIcon} alt="" />
          </div>

          <Errors
            model=".password_confirmation"
            show="touched"
            wrapper={CustomValidateError}
            validateOn={"submit"}
            messages={{
              hasWhiteSpace: replaceAttributesInString(
                { field: "Password Confirmation" },
                staticService.findByAlias("spaceValidationMessage"),
              ),
              passwordValid: staticService.findByAlias("newPasswordValidation"),
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("confirmPasswordLabel"),
              ),
              min: validationMessages().minMessage(8),
            }}
          />
        </div>
        <div className="uk-margin-small-top">
          <Control.button
            model="passwordConfirmModel"
            disabled={{ valid: false }}
            className={"uk-button uk-button-primary uk-align-center"}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("submit"),
              }}
            />
          </Control.button>
        </div>
      </Form>
    );
  }
}

RecoverPasswordFormComponent.contextTypes = {
  store: PropTypes.object,
};

RecoverPasswordFormComponent.propTypes = {
  requestHash: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    errors: state.errorObj,
    model: state.passwordConfirmModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      recoverPassword: recoverPassword,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordFormComponent);
export { COMPONENT as RecoverPasswordFormComponent };
