import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./radioButtonComponent.css";

const RadioButtonComponent = (props) => {
  const { items, selected, handleChange, id, className = "", field, fieldState, disabled } = props;
  const disabledClassName = useMemo(() => (disabled ? "gh-radio-btn-disabled" : ""), [disabled]);

  return (
    <div>
      <div className={`gh-wrapper-radio-btn ${className} ${disabledClassName}`}>
        {items.map((item, index) => (
          <label
            className={`gh-radio-btn ${item.value === selected ? "selected" : ""}`}
            key={index}
          >
            {item.label}
            <input
              ref={field ? field.ref : null}
              id={id + index}
              name={field ? field.name : id + index}
              type="radio"
              checked={item.value === selected}
              onChange={() => {
                handleChange(item.value);
              }}
              disabled={disabled}
            />
            <span className="checkmark" />
          </label>
        ))}
      </div>
      <div className="gh-message-error">
        {fieldState.error && fieldState.error.message && selected === null
          ? fieldState.error.message
          : ""}
      </div>
    </div>
  );
};

RadioButtonComponent.defaultProps = {
  fieldState: {},
  disabled: false,
  handleChange: () => {},
};

RadioButtonComponent.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.any,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  fieldState: PropTypes.object,
  field: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RadioButtonComponent;
