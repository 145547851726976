import React from "react";
import PropTypes from "prop-types";
import staticService from "../../services/static.service";
import { actions, Control, Errors, Form } from "react-redux-form";
import { CustomValidateError, updateModel } from "../../shared";
import { config, validationMessages, validationRules } from "../../configs";
import angle from "../../assets/img/svg/arrow-left-dark.svg";
import PhoneInput, { isValidPhone } from "./phoneInput";
import Button from "./Button";

const { required, requiredObject, validEmail, min, max, invalidEmailDomainShort } = validationRules;

const privacyPolicyLink = "https://www.iubenda.com/privacy-policy/89754695";

export const AdditionalInfo = ({ model, submit, form, back, loadingBtn, formDispatch }) => {
  const formEmail = form && form.email && form.email.length;
  const formPhone = form && form.phone && form.phone.length;
  const mandatory = (val) => {
    if (formEmail || formPhone) {
      return null;
    } else {
      return required(val);
    }
  };

  React.useEffect(() => {
    return () => {
      formDispatch(actions.resetValidity(model));
    };
  }, []);

  return (
    <div className="enquire-additional gh-padding-small">
      <div
        className="gh-enquire-title"
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("additionalDetailsLabel"),
        }}
      />
      <Form model={model} onSubmit={submit} className="enquire-additional-content">
        <div className="uk-width-1-1">
          <div className="validation-wrapper">
            <label
              className={"required-contact uk-form-label"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("firstName"),
              }}
            />
            <Control.text
              className="uk-input"
              model={".first_name"}
              changeAction={updateModel}
              placeholder="Steven"
              updateOn="change"
              validators={{
                required,
                min: min(2),
                max: max(100),
              }}
            />
            <Errors
              model=".first_name"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("firstName"),
                ),
                min: validationMessages().minMessage(2),
                max: validationMessages().maxMessage(100),
              }}
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-margin-top">
          <div className="validation-wrapper">
            <label
              className={"required-contact uk-form-label"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("lastName"),
              }}
            />
            <Control.text
              className="uk-input"
              model={".last_name"}
              placeholder="McDonald"
              changeAction={updateModel}
              updateOn="change"
              validators={{ required, min: min(2), max: max(100) }}
            />
            <Errors
              model=".last_name"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("lastName"),
                ),
                min: validationMessages().minMessage(2),
                max: validationMessages().maxMessage(100),
              }}
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-margin-top">
          <div className="validation-wrapper">
            <label
              className={"required-contact uk-form-label"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("emailAddress"),
              }}
            />
            <Control.text
              type={"email"}
              className="uk-input"
              model={".email"}
              placeholder="steven.mcdonald@gmail.com"
              changeAction={updateModel}
              updateOn="change"
              validateOn={"change"}
              validators={{
                mandatory,
                validEmail,
                invalidEmailDomainShort,
              }}
            />
            <Errors
              model=".email"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("emailAddress"),
                ),
                validEmail: validationMessages().validEmailMessage,
                invalidEmailDomainShort: validationMessages().invalidEmailDomainShortMessage,
              }}
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-margin-top">
          <div className="validation-wrapper">
            <label
              className="uk-form-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("phoneNumber"),
              }}
            />
            <Control.text
              model={".phone"}
              changeAction={updateModel}
              updateOn="change"
              placeholder={"+1 213 373 4253"}
              validators={{
                phoneInputValidation: isValidPhone,
              }}
              component={PhoneInput}
            />
            <Errors
              model=".phone"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                phoneInputValidation: validationMessages().phoneValidationMessage,
              }}
            />
          </div>
        </div>
        <div className="uk-width-1-1 uk-margin-top">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("additionalInformationLabel"),
            }}
          />
          <Control.textarea
            className="uk-textarea"
            model={".other_info"}
            placeholder="Special comments and additional information"
            validators={{ max: max(config.quoteDescriptionMaxLength, false) }}
            changeAction={updateModel}
          />
          <Errors
            model={".enquire_additional_info"}
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              max: validationMessages().maxMessage(
                config.quoteDescriptionMaxLength,
                "characterLimitExceeded",
              ),
            }}
          />
        </div>

        <div className="gh-enquire-actions" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Button color="gray" onClick={back}>
            <img src={angle} alt="angle-left" className="gh-angle" />
            <span>{staticService.findByAlias("back")}</span>
          </Button>
          <Button onClick={submit} loading={loadingBtn} className="event-enquiry-for-tag-manager">
            {staticService.findByAlias("submit")}
          </Button>
        </div>
        <p className="font-size-13 uk-margin-remove text-gray-light gh-enquire-privacy-policy">
          *By submitting this form, you consent to Get Heli using your information to contact you by
          telephone and email about your enquiry and/or related products and services. This
          information is stored in line with the Get Heli{" "}
          <a
            href={privacyPolicyLink}
            target="_blank"
            className="font-weight-500 gh-link-gray uk-padding-remove"
            rel="noreferrer"
          >
            {" "}
            privacy policy
          </a>
          .
        </p>
      </Form>
    </div>
  );
};

AdditionalInfo.propTypes = {
  model: PropTypes.string,
  submit: PropTypes.func,
  form: PropTypes.object,
  back: PropTypes.func,
  loadingBtn: PropTypes.bool,
  formDispatch: PropTypes.func,
};
