import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, actions } from "react-redux-form";
import { FilterBookingFieldset } from "../../../forms/fieldsets/";
import BaseFilterComponent from "./base-filter.component";
import { getEmptyLegsByFilter, getEmptyLegsSuccess } from "../../../../actions";
import { filterMapStateToProps } from "../../../../shared/";
import { config, operatorEmptyLegsFilters } from "../../../../configs/";
import { allModels } from "../../../../reducers/";
import { FilterTabsComponent } from "./filter-tabs.component";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";
import googleApi from "../../../../services/google.service";

const modelName = "emptyLegsFiltersModel";

class OperatorBookingEmptyLegsFiltersComponent extends BaseFilterComponent {
  constructor(props) {
    super(props, modelName);
    this.state = {
      modelName: "emptyLegsFiltersModel",
      activityLeg: "own",
      screenW: 0,
      filterTabs: operatorEmptyLegsFilters,
    };
    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.updateOnSelect = this.updateOnSelect.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.updateDepartureTime = this.updateDepartureTime.bind(this);
  }

  componentDidMount() {
    this.setState({ screenW: window.innerWidth });
  }

  get Model() {
    return allModels.emptyLegsFiltersModel;
  }

  updateOnSelect(modelKey, address) {
    if (address) {
      this.context.store.dispatch(
        actions.change(`${this.state.modelName}.${modelKey}`, address.location),
      );
      this.props.updateModel(
        `${this.state.modelName}.${modelKey}_info`,
        generateLocationAdditionalInfo(address),
      );
    }
  }

  updateDepartureTime(model, value) {
    this.context.store.dispatch(actions.change(model, value));
  }

  updateGeocodeModel(modelKey, value) {
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
    };
    this.updateOnSelect(modelKey, address);
  }

  fillCoordinates(modelKey, address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(modelKey, address);
      });
    }
  }

  onSelectLocation(handleClickOutside, modelKey, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address);
    } else {
      this.updateOnSelect(modelKey, address);
    }
  }

  render() {
    window.onresize = () => this.setState({ screenW: innerWidth });

    const labels = () => {
      if (this.state.screenW < 578) {
        return { label1: "myLegs", label2: "allLegs" };
      } else {
        return { label1: "MyEmptyLegs", label2: "AllEmptyLegs" };
      }
    };

    return (
      <div>
        <FilterTabsComponent tabs={this.state.filterTabs} changeFunc={this.updateTabList} />
        {!this.props.visible ? null : (
          <div className="uk-card uk-card-default uk-card-body">
            <div>
              <Form model={modelName} onSubmit={this.handleFormSubmit.bind(this, "")}>
                <FilterBookingFieldset
                  modelName={modelName}
                  activity={this.props.activity}
                  disablePastDate={true}
                  updateModelFn={this.props.updateModel}
                  updateLocation={this.updateLocation.bind(this)}
                  radioLabels={labels()}
                  resetModel={this.reset.bind(this)}
                  isSubmitEnabled={this.isSubmitEnabledLeg}
                  getCount={(count) => this.incrementCount(count)}
                  updateGeocodeModel={this.updateGeocodeModel}
                  onSelectLocation={this.onSelectLocation}
                  from={this.props.from}
                  from_info={this.props.from_info}
                  to_info={this.props.to_info}
                  to={this.props.to}
                  updateDepartureTime={this.updateDepartureTime}
                />
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.resetModel(modelName);
  }
}

OperatorBookingEmptyLegsFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      get: getEmptyLegsByFilter,
      resetModel: (model) => dispatch(actions.reset(model)),
      update: getEmptyLegsSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(
  filterMapStateToProps.bind(null, modelName),
  mapDispatchToProps,
)(OperatorBookingEmptyLegsFiltersComponent);
export { COMPONENT as OperatorBookingEmptyLegsFiltersComponent };
