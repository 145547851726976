import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { RequestTabComponent } from "./new-quote";
import { MarketPlaceLayout } from "../../../layouts/";
import { operatorMarketplaceTabs } from "../../../../configs";
import { getOperatorRequestDetail, addQuote, QUOTE_TO_AMEND } from "../../../../actions";
import { IsVerifiedPermission } from "../../../../components/";
import { fireTracking, alertInstance } from "../../../../shared/";
import auth from "../../../../services/auth";
import { ConditionalPermission } from "../../../permissions/";
import staticService from "../../../../services/static.service";
import { WrapperComponent } from "../../../";

class OperatorNewQuoteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
    fireTracking(props.location.pathname);
  }

  componentWillMount() {
    this.context.store.dispatch(getOperatorRequestDetail(this.props.match.params.requestId));
  }

  formSubmit() {
    this.context.store.dispatch(addQuote(this.props.quoteModel));
  }

  get enabled() {
    return !this.props.request.leg_owner || this.props.request.leg_owner === auth.getUser().id;
  }

  get status() {
    return this.props.request.status !== "closed" && this.props.request.status !== "expired";
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: QUOTE_TO_AMEND, payload: {} });
  }

  render() {
    return (
      <WrapperComponent>
        <IsVerifiedPermission
          match={this.props.match}
          title={this.props.title}
          skipLayout={true}
          content={staticService.findByAlias("pageAccessRestricted")}
          role={"operator"}
        >
          <ConditionalPermission condition={this.enabled && this.status}>
            <MarketPlaceLayout
              pageTitle={staticService.findByAlias("NewQuote")}
              navigationTabs={operatorMarketplaceTabs}
              activeRoute={this.props.match}
            >
              <div className={"uk-margin-top uk-margin-bottom"}>
                <RequestTabComponent
                  visible={this.props.childComponentVisibility}
                  request={this.props.request}
                  history={this.props.match}
                  onSubmit={this.formSubmit}
                />
              </div>
            </MarketPlaceLayout>
          </ConditionalPermission>
        </IsVerifiedPermission>
      </WrapperComponent>
    );
  }
}

OperatorNewQuoteComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    request: state.requestDetail,
    childComponentVisibility: !!state.requestDetail.id,
    quoteModel: state.quoteModel,
  };
}

const COMPONENT = connect(mapStateToProps)(OperatorNewQuoteComponent);
export { COMPONENT as OperatorNewQuoteComponent };
