import React from "react";
import PropTypes from "prop-types";
import resetIcon from "../../../assets/img/reset.png";
import staticService from "../../../services/static.service";

const textStyle = {
  fontFamily: "Rubik, sans-serif",
  fontSize: 15,
  color: "#242F42",
  marginLeft: 10,
};

const ResetBtn = ({ handleReset, text }) => {
  return (
    <div
      className="btn-style gh-btn-reset"
      style={{ display: "flex", alignItems: "center", cursor: "pointer", width: "fit-content" }}
      onClick={handleReset}
    >
      <img src={resetIcon} alt="reset" style={{ width: 17, height: 17 }} />
      <span style={textStyle}>
        {text ? text : `${staticService.findByAlias("resetToOriginal")}`}
      </span>
    </div>
  );
};

ResetBtn.propTypes = {
  handleReset: PropTypes.func,
  text: PropTypes.string,
};

export default ResetBtn;
