import React from "react";
import { getTaxAmount } from "../services/calculations/formulas";

export default function useFormExtraActions(form, { aircrafts }) {
  const resetToOriginal = React.useCallback(
    ({ legId, pilotId, field, callback }) =>
      () => {
        const parts = field.split(".");
        const [, aircraft] = parts;
        const property = parts[parts.length - 1];

        let value = "";

        const leg = aircrafts[aircraft].legs.find((leg) => leg.id === legId);

        if (leg) {
          if (pilotId) {
            const pilot = leg.pilots.find((pilot) => pilot.id === pilotId);
            if (pilot) value = pilot[property];
          } else {
            value = leg[property];
          }
        }

        form.setValue(field, value);

        if (callback) callback(value);
      },
    [form, aircrafts],
  );

  const updateForm = React.useCallback(
    (fields) => {
      fields.forEach(([name, value]) => form.setValue(name, value, { shouldValidate: true }));
    },
    [form],
  );

  const updateOperatorMargin = React.useCallback(() => {
    const aircrafts = form.getValues("aircrafts");

    const quote_costs = aircrafts.reduce((total, aircraft) => {
      const totalOfCurrentLeg = aircraft.legs.reduce((totalLeg, leg) => {
        return totalLeg + +leg.total_cost_leg;
      }, 0);

      return total + totalOfCurrentLeg;
    }, 0);

    const operator_margin = form.getValues("operator_margin");
    const quote_costs_total = getTaxAmount(quote_costs, operator_margin.percent);

    form.setValue("quote_costs", quote_costs);
    form.setValue("quote_costs_total", quote_costs_total);
  }, [form]);

  return { resetToOriginal, updateForm, updateOperatorMargin };
}
