import { useRef, useState } from "react";
import Api from "../../../../../../services/api-handler";
import moment from "moment";
import { showNotification } from "../../../../../../utils/showNotification";
import { BOOKING_INIT, config, OPEN } from "../../../../../../configs";

let apiInProgress = false;
let animInProgress = false;

const useQuotesChaseButton = ({
  quoteId,
  quoteStatus,
  chaseDetails,
  hasToExpireIsOpenConversation,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [updatedChaseDetails, setUpdatedChaseDetails] = useState(null);

  const popupRef = useRef(null);

  const hideComponent = !(
    quoteStatus === OPEN ||
    quoteStatus === BOOKING_INIT ||
    hasToExpireIsOpenConversation
  );

  const handleButtonClick = (e) => {
    animateLoading(setIsButtonLoading);

    const nowISO = moment().toISOString();
    Api.setProperty("showSpinner", false)
      .setProperty("skipErrorAlert", true)
      .setPath("chase-user")
      .post({ id: quoteId, chase_at: nowISO })
      .then((res) => {
        setUpdatedChaseDetails(res?.data);

        animateLoadingStop(setIsButtonLoading);
        e.target.classList.contains("desktop") && popupRef.current.open();
        showNotification("chaseOperatorNotificationMessage", "success");
      })
      .catch(() => {
        animateLoadingStop(setIsButtonLoading);
        showNotification("Something went wrong", "danger");
      });
  };

  const fistApiCallLastChase =
    chaseDetails?.last_chase && moment(chaseDetails.last_chase).format(config.dateAtTimeFormat);
  const updatedLastChase =
    updatedChaseDetails?.last_chase &&
    moment(updatedChaseDetails.last_chase).format(config.dateAtTimeFormat);
  const lastChase = updatedLastChase || fistApiCallLastChase;

  return { handleButtonClick, isButtonLoading, lastChase, hideComponent, popupRef };
};

export default useQuotesChaseButton;

const animateLoading = (setIsButtonLoading) => {
  setIsButtonLoading(true);
  apiInProgress = true;
  animInProgress = true;
  setTimeout(() => {
    if (!apiInProgress) setIsButtonLoading(false);
    animInProgress = false;
  }, 1000);
};

const animateLoadingStop = (setIsButtonLoading) => {
  if (!animInProgress) setIsButtonLoading(false);
  apiInProgress = false;
};
