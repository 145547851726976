import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BlockComponent, ConfirmComponent } from "../../../../common";
import { QUOTE_OPEN, config, QUOTE_REJECTED } from "../../../../../configs";
import { RequestDetailComponent, RequestStatusComponent } from "../../common/";
import { fireTracking, redirectTo } from "../../../../../shared/";
import {
  getQuote,
  getBrokerRequestDetail,
  updateQuoteStatus,
  addDiscussionMessage,
  getDiscussionMessages,
  ADD_DISCUSSION_MESSAGE,
  SEEN_QUOTE,
  CLEAR_ERRORS,
} from "../../../../../actions/";
import staticService from "../../../../../services/static.service";
import { FeedbackFormComponent } from "../../../../forms";
import { actions } from "react-redux-form";
import envelope from "../../../../../assets/img/envelope.png";
import auth from "../../../../../services/auth";
import moment from "moment";

const model = "feedbackModel";

class BrokerPaymentSummaryComponent extends React.Component {
  constructor(props) {
    super(props);
    fireTracking(props.location.pathname);
    this.state = {
      redirectAfter: false,
      redirectToSecondStep: false,
      redirectToRequest: false,
      loadedQuoteStatus: false,
      seenQuote: true,
    };
  }

  componentDidMount() {
    const quoteID = parseInt(this.props.parentProps.match.params.quoteID);
    if (Object.keys(this.props.requestDetail).length === 0) {
      this.context.store.dispatch(
        getBrokerRequestDetail(this.props.parentProps.parentRoute.params.id),
      );
    }
    if (!this.props.quote.id || quoteID !== this.props.quote.id) {
      this.props.getQuote(quoteID);
    } else {
      this.setState({
        loadedQuoteStatus: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quote !== nextProps.quote) {
      this.setState({
        loadedQuoteStatus: true,
      });
    }
    if (Object.keys(nextProps.seenQuote).length === 0 && typeof nextProps.quote !== "undefined") {
      this.setState({ seenQuote: nextProps.quote.seen_by_broker });
    }

    if (
      Object.keys(nextProps.seenQuote).length &&
      nextProps.seenQuote.quote_id === nextProps.quote.id
    ) {
      this.setState({ seenQuote: nextProps.seenQuote.seen_by_broker });
    }
  }

  requestChanges() {
    this.props.getDiscussionMessages(this.props.quote.id).then(() => {
      this.confirmComponent.openModal();
    });
  }

  closeModal() {
    this.confirmComponent.closeModal();
    this.context.store.dispatch({ type: ADD_DISCUSSION_MESSAGE, messages: [] });
    this.context.store.dispatch({ type: SEEN_QUOTE, seenQuote: {} });
  }

  sendDiscussionMessage(model) {
    this.context.store.dispatch(addDiscussionMessage(this.props.quote.id, model));
    this.context.store.dispatch(actions.reset("feedbackModel"));
  }

  submitFeedback(model) {
    return this.sendDiscussionMessage(model);
  }

  get feedbackForm() {
    return (
      <FeedbackFormComponent
        modelName={model}
        feedback={true}
        modelField={".message"}
        label={""}
        visible={true}
        action={this.submitFeedback.bind(this)}
        form={this.props.feedbackModel}
        fieldError={this.props.feedbackFormError}
        discussionStart={false}
        role={auth.getRole() === "broker_admin"}
      />
    );
  }

  get dialogContent() {
    const atLeastOneSystem = () => {
      return (
        this.props.quoteDiscussion &&
        !!this.props.quoteDiscussion.filter((data) => data.is_system === 0).length
      );
    };
    return (
      <div>
        <div
          className={`gh-discussion-container ${
            this.props.quoteDiscussion && this.props.quoteDiscussion.length === 0
              ? "gh-no-messages"
              : ""
          }`}
          id="gh-discussion-container"
        >
          {this.props.quoteDiscussion &&
            this.props.quoteDiscussion.map((data) => {
              return (
                <div
                  key={data.id}
                  className={`gh-message-body ${
                    data.user.toLowerCase().includes("operator") ? "second-role-body" : ""
                  }`}
                >
                  <div
                    className={
                      data.is_system !== 1 ? "gh-message-box" : "gh-bubble-message-container"
                    }
                  >
                    <div className="gh-message-header">
                      {data.is_system !== 1 ? (
                        <div className="gh-message-header-user">{data.user}</div>
                      ) : (
                        ""
                      )}
                      <span
                        className={
                          data.is_system !== 1 ? "gh-message-header-posted" : "gh-amended-time"
                        }
                      >
                        {moment(data.posted)
                          .add(moment().utcOffset() / 60, "hours")
                          .format("D MMMM, YYYY HH:mm")}
                      </span>
                    </div>
                    <div className="gh-message-text">{data.body}</div>
                  </div>
                </div>
              );
            })}
          {this.props.quoteDiscussion &&
          (this.props.quoteDiscussion.length === 0 || !atLeastOneSystem()) ? (
            <div className="gh-bubble-message-container gh-first-message">
              <div className="gh-bubble-message">
                <div className="gh-system-role">
                  {staticService.findByAlias("getHeliSystemLabel")}
                </div>
                <div>
                  {staticService.findByAlias("brokerFirstMessageToAdd") +
                    `${auth.getMainRole() === "broker" ? " Operator" : " Broker"}`}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="uk-margin">{this.feedbackForm}</div>
      </div>
    );
  }

  handleConfirm() {
    this.props
      .getQuote(this.props.parentProps.match.params.quoteID)
      .then(() => {
        this.setState({ redirectToSecondStep: true });
      })
      .catch((err) => {
        if (err.status === 422) this.setState({ redirectToRequest: true });
      });
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: ADD_DISCUSSION_MESSAGE, messages: [] });
    this.context.store.dispatch({ type: SEEN_QUOTE, seenQuote: {} });
    this.context.store.dispatch({ type: CLEAR_ERRORS });
  }

  render() {
    const { quote, requestDetail, backUrl, parentProps } = this.props;
    const { redirectAfter, redirectToSecondStep, redirectToRequest, loadedQuoteStatus, seenQuote } =
      this.state;
    if (redirectAfter) {
      return redirectTo(`/flights/requests/filter/urgent/${requestDetail.id}`);
    }
    if (redirectToSecondStep) {
      return redirectTo(`${parentProps.match.url}/step/2`);
    }
    if (redirectToRequest) {
      return redirectTo(`/flights/requests/${requestDetail.id}`);
    }
    if (loadedQuoteStatus) {
      if (quote.status && quote.status === QUOTE_OPEN) {
        return (
          <BlockComponent visible={true} parentProps={{ className: "uk-margin" }}>
            <Link className="uk-link-text" to={backUrl || parentProps.parentRoute.url}>
              <b className={"font-weight-500"}>
                <span className="uk-margin-small-right" data-uk-icon="icon: arrow-left" />
                {staticService.findByAlias("backToRequest")}
              </b>
            </Link>
            <div className={"uk-margin-top"}>
              <RequestStatusComponent request={requestDetail} requestStatus={false} />
              <hr />
            </div>
            <div className={"uk-margin-top"}>
              <RequestDetailComponent
                request={requestDetail}
                quote={quote}
                title={staticService.findByAlias("quote-details")}
                showTitle={true}
              />
            </div>
            <div className="gh-modal-flex-direction">
              <div className="uk-text-right uk-margin-top uk-margin-bottom gh-margin-left-medium">
                <button
                  className="uk-button uk-button-primary gh-payment-continue"
                  onClick={this.handleConfirm.bind(this)}
                >
                  {staticService.findByAlias("continue")} with Booking
                </button>
              </div>
              <div className="uk-text-right uk-margin-top uk-margin-bottom gh-request-changes">
                <button
                  className="uk-button uk-button-secondary uk-button-default gh-request-changes-btn"
                  onClick={() => this.requestChanges()}
                >
                  {!seenQuote && <img className="gh-envelope-icon" src={envelope} alt="envelope" />}
                  {!seenQuote
                    ? staticService.findByAlias("newMessage")
                    : staticService.findByAlias("quoteDiscussion")}
                </button>
              </div>
            </div>

            <ConfirmComponent
              ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
              info={this.dialogContent}
              additionalContent={{
                content: staticService.findByAlias("addAnyQuestions"),
              }}
              modalProps={{
                id: "remove-empty-leg-modal",
                title: staticService.findByAlias("askForAnUpdatedQuote"),
                onClose: this.closeModal.bind(this),
                options: {
                  clsPage: "",
                  bgClose: false,
                  escClose: false,
                },
              }}
              onSelect={() => {}}
              showCloseBtn
              onReject={this.closeModal.bind(this)}
            />
          </BlockComponent>
        );
      }
      return redirectTo(config.bookingRoute);
    }
    return null;
  }
}

BrokerPaymentSummaryComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerPaymentSummaryComponent.propTypes = {
  parentProps: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    requestDetail: state.requestDetail,
    quote: state.quoteDetail,
    backUrl: state.prevPath,
    quoteDiscussion: state.quoteDiscussion && state.quoteDiscussion.data,
    feedbackModel: state.feedbackModel,
    seenQuote: state.seenQuote,
    feedbackFormError:
      state.errorObj &&
      state.errorObj.errors &&
      state.errorObj.errors.body &&
      state.errorObj.errors.body[0],
  };
}

const COMPONENT = connect(mapStateToProps, { getQuote, getDiscussionMessages })(
  BrokerPaymentSummaryComponent,
);
export { COMPONENT as BrokerPaymentSummaryComponent };
