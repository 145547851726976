import GetHeliEvents from "../services/event.service";
import api from "../services/api-handler";
import * as types from "./actionTypes";

export default function handlerError(
  error,
  dispatch,
  callback = null,
  errorType = types.ADD_ERROR,
) {
  GetHeliEvents.trigger("server-error", error);
  api.setProperty("skipErrorAlert", false);

  if (callback instanceof Function) {
    callback(error.data);
  }
  // Dispatch specific "some resources failed" if needed...
  dispatch({ type: types.FETCH_RESOURCES_FAIL });

  // Dispatch the generic "global errors" action
  // This is what makes its way into state.errors
  dispatch({ type: errorType, error: error.data });
}
