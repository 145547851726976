import PropTypes from "prop-types";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Error from "./ErrorItem";
import cs from "../../utils/condString";

export default function FormItem(props) {
  const { name, label, className, children, disabled, options, type, ...rest } = props;
  const { control } = useFormContext();

  const formControl = React.useCallback(
    ({ field, fieldState, fieldState: { invalid, error } }) => (
      <div className={cs(className, "form-control", invalid && "invalid")} {...rest}>
        {label && type !== "checkbox" && type !== "radio" && (
          <label className="uk-form-label">{label}</label>
        )}
        {React.cloneElement(React.Children.only(children), {
          ...field,
          fieldState,
          options,
          label,
          disabled,
        })}

        <div style={{ minHeight: 24 }}>
          <Error error={error} />
        </div>
      </div>
    ),
    [children, className, disabled, label, options, type],
  );

  return <Controller control={control} name={name} render={formControl} />;
}

FormItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  type: PropTypes.string,
};
