import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { validationMessages, validationRules, config } from "../../../../configs/";
import {
  CustomValidateError,
  updateModel,
  range,
  DateTimeComponent,
  rangeReverse,
  explode,
  handleDatePickerSelection,
  GridComponent,
  soonExpiresFunc,
  dateExpired,
} from "../../../../shared/";
import { validateTailNumber } from "../../../../actions";
import staticService from "../../../../services/static.service";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../common/location_autocomplete";
import googleApi from "../../../../services/google.service";
import { CheckCard } from "../../../pages/user-online/common";
import UploadFiles from "../upload-files.component";
import * as types from "../../../../actions";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";
import ErrorMessageComponent from "../../../common/ErrorMessageComponent";
import { clearInvalidDate, transformPickMonth } from "../../../../utils/dateTimePicker";

const modelName = "fleetModel";

const yearsRange = rangeReverse(config.yearRanges.end, config.yearRanges.start);

const maxCrew = range(1, config.max_crew);

const { required, max, min, fieldIsRequiredOnlyNumbers, numeric } = validationRules;

class FleetFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelName: modelName,
      manufacturerModels: [],
      airCraft: null,
      airCraftModel: null,
      isLoaded: false,
      years: yearsRange,
      showLoader: false,
      maxPassengers: [],
      home_base: null,
      selectedHome: false,
      address: null,
      expireSoonError: false,
      dateExpireError: false,
      isValidHeliInsurance: true,
      uploadingFile: false,
      filesUploadedCount: 0,
      filesToUploadCount: 0,
      helicopterOnAocWarning: !props.fleetModel.is_helicopter_on_aoc /*true*/,
    };
    this.validateTailNumber = this.validateTailNumber.bind(this);
    this.handleUpdateProgressStatus = this.handleUpdateProgressStatus.bind(this);
  }

  componentDidUpdate() {
    const { filesUploadedCount, filesToUploadCount, uploadingFile } = this.state;
    if (filesToUploadCount !== 0) {
      if (filesToUploadCount !== filesUploadedCount && !uploadingFile) {
        this.setState({ uploadingFile: true });
      }

      if (filesToUploadCount === filesUploadedCount && uploadingFile) {
        this.setState({ uploadingFile: false, filesUploadedCount: 0, filesToUploadCount: 0 });
      }
    }
  }

  handleUpdateProgressStatus(status, count, decreaseCount = 0) {
    if (status === "uploading") {
      this.setState({ filesToUploadCount: this.state.filesToUploadCount + count });
    } else if (status === "uploaded") {
      this.setState({ filesUploadedCount: this.state.filesUploadedCount + 1 }, () => {
        this.context.store.dispatch(actions.resetErrors(`${modelName}.aoc_document`));
      });
    } else if (status === "error" && decreaseCount) {
      this.setState({
        uploadingFile: false,
        filesToUploadCount: this.state.filesToUploadCount - decreaseCount,
      });
    } else if (status === "error") {
      this.setState({ uploadingFile: false });
    }
  }

  validateTailNumber(val) {
    if (this.props.skipTailNumberValidation) return false;

    const validate = this.props.fleets.find(function (fleet) {
      return fleet.registration_number === val;
    });
    return !validate;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const {
      errors,
      auth,
      changeView,
      manufacturers,
      heliInsuranceFilesModel,
      heliEvidenceAOCModel,
      fleetModel,
    } = this.props;

    if (errors !== nextProps.errors && nextProps.errors !== null) {
      this.setState({ showLoader: false });
      this.context.store.dispatch(actions.setPending(modelName, false));
    }

    if (auth !== nextProps.auth && nextProps.auth.invitationResponse) {
      this.setState({ isVisible: false });
      changeView();
    }

    if (nextProps.manufacturers && nextProps.manufacturers.length) {
      const filteredManufacturers = manufacturers.filter(
        (manufacturer) => manufacturer.id == nextProps.fleetModel.manufacturer,
      );

      if (filteredManufacturers.length > 0) {
        const filteredManufacturerModels = filteredManufacturers[0].types.filter(
          (model) => model.id == nextProps.fleetModel.type_id,
        );
        this.setState({
          airCraftModel: filteredManufacturerModels[0],
          years: yearsRange,
          airCraft: filteredManufacturers[0],
          manufacturerModels: filteredManufacturers[0].types,
          maxPassengers:
            filteredManufacturerModels.length > 0
              ? range(1, filteredManufacturerModels[0].max_passengers)
              : [],
        });
      }
    }

    if (
      nextProps.heliInsuranceFilesModel.length ||
      nextProps.heliPhotosFilesModel.length ||
      nextProps.heliEvidenceAOCModel.length
    ) {
      this.context.store.dispatch(actions.change("filesExistState", true));
    }

    if (heliInsuranceFilesModel.length !== nextProps.heliInsuranceFilesModel.length) {
      this.context.store.dispatch({ type: types.CLEAR_ERRORS });
      this.context.store.dispatch(actions.resetValidity(`${modelName}.files`));
    }

    // if (heliEvidenceAOCModel.length !== nextProps.heliEvidenceAOCModel.length) {
    //   this.context.store.dispatch({ type: types.CLEAR_ERRORS });
    //   this.context.store.dispatch(actions.resetValidity(`${modelName}.files`));
    // }

    if (!nextProps.fleetModel.is_helicopter_on_aoc) {
      this.setState({ helicopterOnAocWarning: true });
    } else {
      this.setState({ helicopterOnAocWarning: false });
    }

    const nextExpirationDate = nextProps.fleetModel && nextProps.fleetModel.expiration_date;

    if (nextExpirationDate && nextExpirationDate.length && soonExpiresFunc(nextExpirationDate)) {
      this.setState({ expireSoonError: true });
    } else {
      this.setState({ expireSoonError: false });
    }

    if (nextExpirationDate && nextExpirationDate.length && dateExpired(nextExpirationDate)) {
      this.setState({ dateExpireError: true });
    } else {
      this.setState({ dateExpireError: false });
    }

    // eslint-disable-next-line no-prototype-builtins
    if (
      (!!nextProps.fleetModel.expiration_date || (nextProps.errors && nextProps.errors["files"])) &&
      (!nextProps.heliInsuranceFilesModel || !nextProps.heliInsuranceFilesModel.length)
    ) {
      this.setState({ isValidHeliInsurance: false });
    } else {
      this.setState({ isValidHeliInsurance: true });
    }
  }

  onSuccessValidation(fleet) {
    const originalFleet = fleet;
    const changedFleet = {
      manufacturer: this.state.airCraft.name,
      type_id: this.state.airCraftModel.model,
    };
    originalFleet.date_last_refurbished = clearInvalidDate(
      transformPickMonth(originalFleet.date_last_refurbished),
    );
    this.props.onUpdateItems(originalFleet, changedFleet);
  }

  onSubmit(fleet) {
    const { heliInsuranceFilesModel, heliPhotosFilesModel, heliEvidenceAOCModel } = this.props;
    // refactor: hot fix for validation(home-base, aoc_document)
    if (this.isAutocompleteHomebaseInvalid(fleet)) return;
    if (this.isAOCInvalid(heliEvidenceAOCModel)) return;
    //----------------

    const array = [];
    const list = array.concat(heliInsuranceFilesModel, heliPhotosFilesModel, heliEvidenceAOCModel);
    let docs = list.map((doc) => {
      // eslint-disable-next-line no-unused-vars
      const { file_data, ...file } = doc;
      return file;
    });
    docs = docs.filter((file) => !!file.file_name);

    let _fleet = {
      ...fleet,
      date_last_refurbished: clearInvalidDate(transformPickMonth(fleet.date_last_refurbished)),
      files: docs,
    };

    if (_fleet.lat) {
      _fleet = { ..._fleet, home_base_coords: `${_fleet.lat}|${_fleet.lng}` };

      delete _fleet.lat;
      delete _fleet.lng;
    }

    this.context.store.dispatch(
      validateTailNumber(_fleet, this.onSuccessValidation.bind(this, _fleet)),
    );
  }

  onPickerChange(model, value) {
    if (this.props.errors && this.props.errors["expiration_date"]) {
      delete this.props.errors.expiration_date;
    }

    return updateModel(model, handleDatePickerSelection(value));
  }

  onModelChange(model, value) {
    const filtered = this.state.manufacturerModels.filter((model) => model.id === value);

    if (filtered.length > 0) {
      this.setState({
        airCraftModel: filtered[0],
        years: yearsRange,
        maxPassengers: range(1, filtered[0].max_passengers),
      });
    }
    this.resetMaxPassengers();
    return updateModel(model, value);
  }

  onManufactureChange(model, value) {
    const filtered = this.props.manufacturers.filter((manufacturer) => manufacturer.id == value);
    if (filtered.length > 0) {
      const { types } = filtered[0];
      this.setState({
        airCraft: filtered[0],
        manufacturerModels: types,
        maxPassengers: [],
      });
    }
    this.resetModel();
    this.resetMaxPassengers();
    return updateModel(model, value);
  }

  resetModel() {
    this.context.store.dispatch(actions.resetValidity(`${modelName}.type_id`));
    this.context.store.dispatch(actions.change(`${modelName}.type_id`, 0));
  }

  resetMaxPassengers() {
    this.context.store.dispatch(actions.resetValidity(`${modelName}.max_passengers`));
    this.context.store.dispatch(actions.change(`${modelName}.max_passengers`, 0));
  }

  updateGeocodeModel(value) {
    if (this.props.fleetModel.home_base.length === 0) this.setState({ address: null });
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
    };
    this.updateOnSelect(address);
  }

  onSelectLocation(handleClickOutside, address) {
    this.setState({ selected: true, address: address });
    this.context.store.dispatch(actions.resetValidity(`${modelName}.home_base`));
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(address);
    } else {
      this.updateOnSelect(address);
    }
  }

  fillCoordinates(address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(address);
      });
    }
  }
  updateOnSelect(address) {
    const {
      manufacturer,
      type_id,
      year_manufacture,
      date_last_refurbished,
      registration_number,
      max_passengers,
      id,
      max_crew,
      ...rest
    } = this.props.fleetModel;

    const additionalInfo = {
      home_base: address.location,
      lat: address.latitude,
      lng: address.longitude,
      source: address.source,
      home_info: generateLocationAdditionalInfo(address),
      home_coverage_status: address.coverage_status,
    };
    const home_base_location = {
      ...address,
      ...additionalInfo,
    };

    this.context.store.dispatch(
      actions.change(modelName, {
        manufacturer,
        type_id,
        id,
        year_manufacture,
        date_last_refurbished,
        registration_number,
        max_passengers,
        max_crew,
        ...rest,
        ...additionalInfo,
        home_base_location,
      }),
    );
  }

  get unVerified() {
    return (
      <div className="gh-status-box">
        <label className="gh-dark-blue">
          <div className="uk-flex uk-flex-middle">
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("aircraftVerified"),
              }}
            />
          </div>
        </label>
        <div className="gh-verified-checkbox uk-text-center uk-margin-remove">
          <span
            className="uk-margin-small-left gh-yellow-tooltip"
            data-uk-icon="icon: info"
            data-uk-tooltip={`title: ${staticService.findByAlias(
              "notVerifiedMessage",
            )}; pos: top-right`}
          />
        </div>
      </div>
    );
  }

  isAutocompleteHomebaseInvalid(fleet) {
    if (fleet) {
      return !fleet.lat && !fleet.lng;
    }
    const fleetModel = this.props.fleetModel;
    const fleetForm = this.props.fleetForm;
    const showAutoCompleteError =
      !fleetModel.lat &&
      !fleetModel.lng &&
      fleetForm.home_base.touched &&
      fleetForm.home_base.valid;

    return showAutoCompleteError;
  }

  isAOCInvalid(aocFilesArray) {
    const showAutoCompleteError = !aocFilesArray?.length;
    if (showAutoCompleteError) {
      this.context.store.dispatch(
        actions.setErrors(`${modelName}.aoc_document`, {
          required: "Please provide the AOC document.",
        }),
      );
    }
    return showAutoCompleteError;
  }

  render() {
    const registrationNumberUnique = this.validateTailNumber;
    if (this.props.visible) {
      return (
        <Form
          model={modelName}
          onSubmit={this.onSubmit.bind(this)}
          className="gh-aircraft-form"
          validateOn="submit"
          validators={{
            cruise_speed: { required: (val) => val > 0 },
            price_per_hour: { required: (val) => val > 0 },
            aircraft_vat: numeric,
          }}
        >
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-0",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div className={"uk-margin "}>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("registerTailNumber"),
                }}
              />
              <div className="uk-form-controls">
                <Control.text
                  model=".registration_number"
                  className={"uk-input"}
                  placeholder={"Tail Number"}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    required,
                    max: max(10),
                    registrationNumberUnique,
                  }}
                />
                <Errors
                  model=".registration_number"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    registrationNumberUnique: staticService.findByAlias(
                      "validateTailNumberMessage",
                    ),
                    max: validationMessages().maxMessage(10),
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("TailNumber"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("status"),
                }}
              />
              <div className="uk-form-controls">
                <div className="gh-status-field pastel">{this.unVerified}</div>
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-1",
              className: "uk-margin-small-top uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("manufacturer"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".manufacturer"
                  className={"uk-select"}
                  changeAction={this.onManufactureChange.bind(this)}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select ...</option>
                  {this.props.manufacturers.map((manufacturer, i) => (
                    <option key={i} value={manufacturer.id}>
                      {manufacturer.name}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".manufacturer"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("manufacturer"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("model"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".type_id"
                  className={"uk-select"}
                  changeAction={this.onModelChange.bind(this)}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select ...</option>
                  {this.state.manufacturerModels.map((type, i) => (
                    <option key={i} value={type.id}>
                      {type.model}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".type_id"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("model"),
                    ),
                  }}
                />
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-3",
              className: "uk-margin-medium-top uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("MaxCrewSize"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".max_crew"
                  className={"uk-select"}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select Crew</option>
                  {maxCrew.map((number, i) => (
                    <option key={i} value={number}>
                      {number}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".max_crew"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("MaxCrewSize"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("maxPassengers"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".max_passengers"
                  className={"uk-select"}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select passenger</option>
                  {this.state.maxPassengers.map((passenger, i) => (
                    <option key={i} value={passenger}>
                      {passenger}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".max_passengers"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("maxPassengers"),
                    ),
                  }}
                />
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-2",
              className:
                "uk-margin-medium-top uk-margin-small-bottom uk-margin-top uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("manufactureYear"),
                }}
              />
              <div className="uk-form-controls">
                <Control.select
                  model=".year_manufacture"
                  className={"uk-select"}
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{ required }}
                >
                  <option value="">Select ...</option>
                  {this.state.years.map((year, i) => (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model={".year_manufacture"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("manufactureYear"),
                    ),
                  }}
                />
              </div>
            </div>
            <div>
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("lastRefurbished"),
                }}
              />
              <div className="uk-form-controls uk-position-relative">
                <Control
                  model=".date_last_refurbished"
                  className={"uk-input gh-calendar-input"}
                  component={DateTimeComponent}
                  readOnly
                  controlProps={{
                    isValidDateBefore: true,
                    showClearIcon: false,
                    dateFormat: "MMMM, YYYY",
                    utc: true,
                  }}
                  changeAction={this.onPickerChange.bind(this)}
                  updateOn="change"
                />
                <Errors
                  model={".date_last_refurbished"}
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("lastRefurbished"),
                    ),
                  }}
                />
              </div>
            </div>
          </GridComponent>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-0",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label required"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("cruiseSpeed"),
                }}
              />
              <div className="uk-form-controls">
                <Control.text
                  model=".cruise_speed"
                  className={"uk-input"}
                  placeholder="100"
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    fieldIsRequiredOnlyNumbers: fieldIsRequiredOnlyNumbers,
                  }}
                />
                <Errors
                  model=".cruise_speed"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: "Field is required.",
                    fieldIsRequiredOnlyNumbers: "Field is required. \nPlease enter a valid number",
                  }}
                />
              </div>
            </div>
            <div>
              <label className={"uk-form-label required"}>
                {staticService.findByAlias("pricePerHour")}
                <span style={{ paddingLeft: 4 }}>
                  ({this.props.userRegisterModel?.company?.currency || ""})
                </span>
              </label>
              <div className="uk-form-controls">
                <Control.text
                  model=".price_per_hour"
                  className={"uk-input"}
                  placeholder="3500"
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    fieldIsRequiredOnlyNumbers: fieldIsRequiredOnlyNumbers,
                  }}
                />
                <Errors
                  model=".price_per_hour"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: "Field is required.",
                    fieldIsRequiredOnlyNumbers: "Field is required. \nPlease enter a valid number",
                  }}
                />
              </div>
            </div>
          </GridComponent>

          <GridComponent
            visible={true}
            attrProps={{
              id: "row-0",
              className: "uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className={"uk-form-label"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("taxRateOfAircraftLabel"),
                }}
              />
              <div className="uk-form-controls">
                <Control.text
                  model=".aircraft_vat"
                  className={"uk-input"}
                  placeholder="100"
                  changeAction={updateModel}
                  updateOn="change"
                  validators={{
                    fieldIsRequiredOnlyNumbers: numeric,
                  }}
                />
                <Errors
                  model=".aircraft_vat"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    fieldIsRequiredOnlyNumbers: "Please enter a valid number",
                  }}
                />
              </div>
            </div>
            <div />
          </GridComponent>

          <div className="uk-margin-medium-top uk-margin-small-bottom">
            {/*<label className='uk-form-label required' dangerouslySetInnerHTML={{__html: staticService.findByAlias('homeBase')}} />*/}
            <div className="uk-grid">
              <label
                className="uk-form-label required"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("homeBase"),
                }}
              />
              <span
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias("iconBaseHome")}`}
              />
            </div>
            <div className="uk-form-controls uk-position-relative">
              <LocationAutocompleteWrapper>
                {({
                  locations,
                  search,
                  handleClickOutside,
                  loader,
                  onBlur,
                  locationValidationTemplate,
                }) => {
                  return (
                    <div className={"uk-position-relative"}>
                      <LocationAutocompleteInput
                        className={["uk-input"]}
                        placeholder={"London"}
                        readOnly={false}
                        value={
                          this.props.fleetModel.home_base === "null"
                            ? ""
                            : this.props.fleetModel.home_base
                        }
                        coverageWarning={this.props.fleetModel.home_coverage_status}
                        additionalInfo={this.props.fleetModel.home_info || ""}
                        onSelect={(location) => {
                          this.updateGeocodeModel(location);
                          return search(location, "home_base");
                        }}
                        onBlur={onBlur}
                        loader={loader}
                        debounce={500}
                        model={`${modelName}.home_base`}
                        onClick={() =>
                          this.context.store.dispatch(actions.setTouched(`${modelName}.home_base`))
                        }
                      />
                      <LocationAutocompleteList
                        onSelect={this.onSelectLocation.bind(this, handleClickOutside)}
                        locations={locations}
                      />
                      {this.isAutocompleteHomebaseInvalid() && (
                        <ErrorMessageComponent>
                          {staticService.findByAlias("pleaseSelect")}
                        </ErrorMessageComponent>
                      )}
                      {locationValidationTemplate(
                        `${modelName}.home_base`,
                        ".home_base",
                        "home_base",
                        "touched",
                        {
                          required,
                          min: min(3),
                        },
                      )}
                    </div>
                  );
                }}
              </LocationAutocompleteWrapper>
            </div>
          </div>
          <div className="uk-margin uk-margin-medium-top">
            <div className="uk-margin-small-bottom">
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("aocLabel"),
                }}
              />
              {this.state.helicopterOnAocWarning ? (
                <span
                  className="uk-margin-small-left gh-animated-label gh-yellow-tooltip"
                  data-uk-icon="icon: info"
                  data-uk-tooltip={`title: ${staticService.findByAlias(
                    "aocCheckboxUntickMessage",
                  )}; pos: top-left`}
                />
              ) : (
                ""
              )}
            </div>
            <CheckCard
              warning={this.state.helicopterOnAocWarning}
              model=".is_helicopter_on_aoc"
              title="helicopterInAoc"
            />
          </div>
          <Errors model=".is_helicopter_on_aoc" show="touched" wrapper={CustomValidateError} />
          <div className="uk-margin-medium uk-margin-remove-bottom">
            <UploadFiles
              containerClass="uk-margin-remove-bottom"
              model={this.props.heliEvidenceAOCModel}
              modelName="heliEvidenceAOCModel"
              uploadTitle="heliEvidenceAOC"
              openModal={this.props.openPreview}
              entity="aircrafts"
              docType={3}
              handleUpdateProgressStatus={this.handleUpdateProgressStatus}
              withOutGlobalLoading
              required
            />
            <Errors
              model=".aoc_document"
              className="uk-margin-bottom"
              show
              wrapper={CustomValidateError}
            />
          </div>
          <GridComponent
            visible={true}
            attrProps={{
              id: "row-4",
              className: "uk-margin-medium-top uk-margin-small-bottom uk-child-width-expand@s",
            }}
          >
            <div>
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("insuranceHelicopter"),
                }}
              />
              <div className="uk-form-controls uk-position-relative">
                <Control
                  model=".expiration_date"
                  className={"uk-input gh-calendar-input"}
                  readOnly
                  component={DateTimeComponent}
                  controlProps={{
                    isValidDate: true,
                    isValidDateBefore: false,
                    showClearIcon: false,
                    dateFormat: config.dobFormat,
                    utc: true,
                  }}
                  changeAction={this.onPickerChange.bind(this)}
                  updateOn="change"
                />
                <Errors model=".expiration_date" show="touched" wrapper={CustomValidateError} />
                <ul className="ul validation-errors disabled-list gh-input-errors">
                  {this.state.expireSoonError && (
                    <li
                      className="uk-position-relative gh-alert-warning"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("InsuranceWillExpireSoon"),
                      }}
                    />
                  )}
                  {this.state.dateExpireError && (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("velidHeliInsuranceRequired"),
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
            <div className="uk-visible@s" />
          </GridComponent>
          <div className="uk-margin-medium uk-margin-bottom">
            <UploadFiles
              containerClass="uk-margin-remove-bottom"
              model={this.props.heliInsuranceFilesModel}
              modelName="heliInsuranceFilesModel"
              uploadTitle="evidenceOfHelicopter"
              openModal={this.props.openPreview}
              docType={1}
              customErrors={
                !this.state.isValidHeliInsurance && (
                  <ul
                    className="ul validation-errors disabled-list gh-input-errors"
                    style={{ marginTop: 6 }}
                  >
                    <li
                      style={{ position: "unset" }}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("pleaseProvideEvidence"),
                      }}
                    />
                  </ul>
                )
              }
              handleUpdateProgressStatus={this.handleUpdateProgressStatus}
              withOutGlobalLoading
            />

            <Errors
              model=".files"
              className="uk-margin-bottom"
              show
              wrapper={CustomValidateError}
            />
          </div>
          <div className="uk-margin">
            <UploadFiles
              model={this.props.heliPhotosFilesModel}
              modelName="heliPhotosFilesModel"
              uploadTitle="helicopterPhotos"
              openModal={this.props.openPreview}
              docType={2}
              handleUpdateProgressStatus={this.handleUpdateProgressStatus}
              withOutGlobalLoading
            />
          </div>
          <div className="uk-flex uk-flex-between">
            <button
              type="button"
              className="uk-button gh-cancel-btn gh-aircraft-btn"
              onClick={this.props.cancel}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("cancel"),
                }}
              />
            </button>

            {this.props.submitBtn ? (
              this.props.submitBtn
            ) : (
              <Control.button
                disabled={this.state.uploadingFile}
                type="submit"
                model={modelName}
                className="uk-button uk-button-primary gh-aircraft-btn"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("addAircraft"),
                  }}
                />
              </Control.button>
            )}
          </div>
        </Form>
      );
    }
    return null;
  }
}

FleetFormComponent.propTypes = {
  onUpdateItems: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  skipTailNumberValidation: PropTypes.bool,
  submitBtn: PropTypes.any,
  fleets: PropTypes.array,
  manufacturers: PropTypes.array,
  cancel: PropTypes.func,
  fleetModel: PropTypes.object,
  heliInsuranceFilesModel: PropTypes.array,
  heliPhotosFilesModel: PropTypes.array,
  heliEvidenceAOCModel: PropTypes.array,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  auth: PropTypes.any,
  changeView: PropTypes.func,
  openPreview: PropTypes.func,
  userRegisterModel: PropTypes.object,
};

FleetFormComponent.contextTypes = {
  store: PropTypes.object,
};

FleetFormComponent.defaultProps = {
  manufacturers: [],
  skipTailNumberValidation: false,
};

const mapStateToProps = (state) => {
  return {
    manufacturers: state.manufacturer && state.manufacturer.length ? state.manufacturer : [],
    fleetModel: state.fleetModel,
    fleetForm: state.forms.fleetModel,
    heliInsuranceFilesModel: state.heliInsuranceFilesModel,
    heliPhotosFilesModel: state.heliPhotosFilesModel,
    heliEvidenceAOCModel: state.heliEvidenceAOCModel,
    userRegisterModel: state.userRegisterModel,
    errors: state.errorObj,
  };
};

const COMPONENT = connect(mapStateToProps)(FleetFormComponent);
export { COMPONENT as FleetFormComponent };
