import React from "react";
import { config } from "../../configs";
import DocumentTitle from "../common/DocumentTitle";

const TermsConditions = () => {
  const environment = process.env.NODE_ENV;

  const url = `https://${config.envURL[environment]}.getheli.com/web-assets/images/pdf/GetHeliTerms.pdf`;

  return (
    <div>
      <DocumentTitle title="Getheli Terms" />
      <iframe src={url} title="testPdf" height="100%" width="100%" style={{ height: "100vh" }} />
    </div>
  );
};

export default TermsConditions;
