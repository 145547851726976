import { storage } from "../shared";

export default () => {
  const loginIsAdmin = JSON.parse(storage.get("login_as_admin"));
  const managedByGetheli = JSON.parse(storage.get("managed_by_getheli"));

  if ((loginIsAdmin && managedByGetheli) || (!loginIsAdmin && !managedByGetheli)) {
    return true;
  } else {
    return false;
  }
};
