import React from "react";
import stripeBlue from "../../../assets/img/svg/stripe-blue.svg";
import check from "../../../assets/img/svg/pricing-check.svg";
import cross from "../../../assets/img/svg/pricing-cross.svg";

export default function PricingRowComponent({ option, index, main }) {
  const cellValue = (value, priceType) => {
    if (typeof value === "string") {
      if (priceType) {
        return (
          <span>
            <span
              className={
                index === 0 || (option.type && option.type === "extra_user") ? "numbers" : ""
              }
            >
              {value}
            </span>{" "}
            per month <sup className="sup-text">1</sup>
          </span>
        );
      }
      return value;
    }
    if (+value > 1) {
      return value;
    } else {
      if (value) {
        return <img src={check} alt="check" />;
      }
      return <img src={cross} alt="cross" />;
    }
  };
  return (
    <div className="uk-grid uk-grid-small uk-flex-middle uk-margin-remove-top">
      <div className="uk-width-1-4">
        <div className="gh-pricing-row" style={{ paddingTop: index === 0 ? 10 : 0 }}>
          {option.stripe ? <img src={stripeBlue} alt="stripe" className="stripe-image" /> : ""}
          <div className="gh-pricing-item-title">{option.title}</div>
        </div>
      </div>
      <div className="uk-width-3-4 uk-grid uk-grid-small uk-margin-remove">
        {option.values.map((val, idx) => (
          <div
            key={option.title + idx}
            className="uk-width-1-5 uk-padding-remove-left"
            style={{
              background: "#fff",
              boxShadow:
                Object.keys(val)[0] === "Commission" ? "inset -10px 0 1px -1px #F2F4F7" : "",
              paddingRight: 9,
            }}
          >
            <div
              className="bundle-option"
              style={{
                paddingTop: index === 0 ? 10 : 0,
                height: main ? 70 : 50,
              }}
            >
              {cellValue(Object.values(val)[0], val.price_value)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
