import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../CalculatorContext";

export default function useLegTotalCost(name, onTotalUpdate, legIndex) {
  const { activeAircraftIndex } = useCalculatorContext();
  const { watch, setValue } = useFormContext();

  const legs = watch(`aircrafts.${activeAircraftIndex}.legs`);
  const total_cost_flight = watch(`${name}.total_cost_flight`);
  const total_fees = watch(`${name}.total_fees`);
  const extra_cost_total = watch(`${name}.extra_cost_total`);

  const legTotal = legs[legIndex].pilots.reduce((pilotTotal, pilot) => {
    if (pilot.is_included === "2") {
      return pilotTotal + +pilot.total_pilot_costs;
    } else {
      return pilotTotal + 0;
    }
  }, 0);

  React.useEffect(() => {
    let total_cost_leg = 0;

    total_cost_leg += +total_cost_flight;
    total_cost_leg += +total_fees;
    total_cost_leg += +extra_cost_total;
    total_cost_leg += +legTotal;

    setValue(`${name}.total_cost_leg`, total_cost_leg);
    onTotalUpdate();
  }, [
    total_cost_flight,
    total_fees,
    extra_cost_total,
    legTotal,
    setValue,
    name,
    onTotalUpdate,
    legs,
  ]);
}
