import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { actions, Control, Errors, Fieldset } from "react-redux-form";
import { config, dateType, timeType, validationMessages, validationRules } from "../../configs";
import {
  CustomValidateError,
  DateTimeComponent,
  handleDatePickerSelection,
  updateModel,
} from "../../shared";
import googleApi from "../../services/google.service";
import { InlineNumberFieldset } from "./fieldsets";
import staticService from "../../services/static.service";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../common";
import calendar from "../../assets/img/svg/calendar.svg";
import clock from "../../assets/img/svg/clock.svg";
import { CLEAR_ERRORS } from "../../actions";
import generateLocationAdditionalInfo from "../../utils/generateLocationAdditionalInfo";
import PadSelectionMap from "../common/PadSelectionMap/PadSelectionMap";
import Tooltip from "../common/Tooltip";
import auth from "../../services/auth";
import tranformDateTimeToISO from "../../utils/tranformDateTimeToISO";

const { required } = validationRules;

const fillCoordinatesLoading = new Map();

class LegFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      range: config.rangeTime,
      updateModelFromTo: false,
      radioChecked: true,
      time_from_min: 0,
      max: 23,
      leg: {
        from: null,
        to: null,
      },
      paxAmountDefault: "1",
      clearLocationsErrors: false,
      fieldsValidated: false,
      fillCoordinatesLoading: false,
      isDistanceExceeds: false,
    };
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.setMinInterval = this.setMinInterval.bind(this);
    this.validateLegDateTime = this.validateLegDateTime.bind(this);
    this.updateLegOnSelect = this.updateLegOnSelect.bind(this);
    this.updateNextLeg = this.updateNextLeg.bind(this);
    this.setLegsData = this.setLegsData.bind(this);
    this.showErrorStatus = this.showErrorStatus.bind(this);
    this.validateLegDistance = this.validateLegDistance.bind(this);
    this.validateOtherSpecialItems = this.validateOtherSpecialItems.bind(this);

    fillCoordinatesLoading.set("from", false).set("to", false);
  }

  componentDidMount() {
    this.setMinInterval(moment(this.props.date + " " + this.props.departure_time));
    if (this.props.from_coords === "" && this.props.form.$form.submitFailed) {
      this.context.store.dispatch(
        actions.setTouched(`${this.props.modelName}.legs.[${this.props.index}].from_coords`),
      );
    }

    if (this.props.to_coords === "" && this.props.form.$form.submitFailed) {
      this.context.store.dispatch(
        actions.setTouched(`${this.props.modelName}.legs.[${this.props.index}].to_coords`),
      );
    }

    this.setLegsData();
    this.validateLegDistance();
  }

  setLegsData() {
    if (this.props.model.legs[this.props.index]) {
      this.setState({
        leg: {
          ...this.state.leg,
          to: this.props.model.legs[this.props.index].to_coords,
          from: this.props.model.legs[this.props.index].from_coords,
        },
      });
    }
  }

  onPickerChange(model, value) {
    value.set("hour", moment().get("hour"));
    value.set("minute", moment().get("minute"));
    if (moment(value).isAfter(moment())) {
      this.context.store.dispatch(
        actions.resetValidity(`${this.props.modelName}.legs.[${this.props.index}].departure_time`),
      );
      this.context.store.dispatch(actions.resetValidity(this.props.modelName));
    }
    const { legs } = this.props.model;
    if (legs.length - 1 > this.props.index) {
      this.context.store.dispatch(
        actions.change(
          `${this.props.modelName}.legs.[${this.props.index + 1}].date`,
          moment(value).format(config.dateFormat),
        ),
      );
    }
    this.setMinInterval(value);
    return updateModel(model, handleDatePickerSelection(value));
  }

  setMinInterval(value) {
    const now = moment();
    let time_to_start = this.state.time_from_min;
    const time = now.add(2, "hour").startOf("hour").format(config.timeFormat);
    if (now.format("YYYY-MM-DD") === value.format("YYYY-MM-DD") && now.hour() > value.hour()) {
      const modelName = `${this.props.modelName}.legs.[${this.props.index}].departure_time`;
      time_to_start = time.split(":");
      this.context.store.dispatch(actions.change(modelName, time));
      this.setState({ time_from_min: parseInt(time_to_start[0]) });
    } else {
      this.setState({ time_from_min: 1 });
    }
  }

  get date() {
    return this.props.date && this.props.date !== ""
      ? moment(this.props.date).format("DD MMMM, YYYY")
      : this.props.date;
  }

  get time() {
    return this.props.leg.departure_time || this.props.leg.departure;
  }

  updateRange(modelName, momentValue) {
    if (this.props.index > 0) {
      const { legs } = this.props.model;
      const prevLeg = legs[this.props.index - 1];
      const prevLegDate = this.getDateTimeObject(prevLeg.date, prevLeg.departure_time);
      if (prevLegDate.isSameOrBefore(momentValue)) {
        this.context.store.dispatch(actions.resetValidity(this.props.modelName));
      }
    }

    if (moment().isSameOrBefore(momentValue)) {
      this.context.store.dispatch(actions.resetValidity(this.props.modelName));
    }
    if (
      this.props.errorObj &&
      Object.keys(this.props.errorObj).length &&
      moment().isSameOrBefore(momentValue)
    ) {
      this.context.store.dispatch(actions.resetValidity(this.props.modelName));
      this.context.store.dispatch({ type: CLEAR_ERRORS });
    }
    this.context.store.dispatch(actions.change(modelName, momentValue.format(config.timeFormat)));
  }

  updateGeocodeModel(model, value) {
    this.context.store.dispatch(
      actions.change(`${this.props.modelName}.legs.[${this.props.index}].${model}`, value),
    );
    this.context.store.dispatch(
      actions.change(`${this.props.modelName}.legs.[${this.props.index}].${model}_coords`, ""),
    );
    this.context.store.dispatch(
      actions.change(
        `${this.props.modelName}.legs.[${this.props.index}].${model}_coverage_status`,
        false,
      ),
    );
    this.context.store.dispatch(
      actions.change(`${this.props.modelName}.legs.[${this.props.index}].${model}_info`, ""),
    );
    this.props.updateFromToModel(model, value, this.props.index);
  }

  fillCoordinates(modelKey, address) {
    const model = `${this.props.modelName}.legs.[${this.props.index}].${modelKey}_coords`;
    if (address.place_id !== null || address.place_id !== "") {
      fillCoordinatesLoading.set(modelKey, true);
      googleApi
        .getCoordinatesByPlaceId(address.place_id)
        .then((result) => {
          address.latitude = result.lat;
          address.longitude = result.lng;
          address.from_place_id = result.place_id;
          this.updateLegOnSelect(modelKey, address, this.props.index);
          this.updateNextLeg(modelKey, address, this.props.index);
        })
        .finally(() => fillCoordinatesLoading.set(modelKey, false));
      this.context.store.dispatch(actions.setTouched(model));
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.departure_time !== nextProps.departure_time) {
      nextProps.model.legs.forEach((leg, index) =>
        this.validateLegDateTime(leg, index, nextProps.model.legs),
      );
    }

    const {
      model,
      flightType,
      form: { $form },
      company,
    } = this.props;
    const { fieldsValidated } = this.state;
    const {
      model: { legs: cLegs },
    } = this.props;
    const {
      model: { legs: nLegs },
    } = nextProps;
    const { index: legIndx } = this.props;

    if (JSON.stringify(company.features) !== JSON.stringify(nextProps.company.features)) {
      this.forceUpdate();
    }

    if (
      cLegs[legIndx].from_coords !== nLegs[legIndx].from_coords ||
      cLegs[legIndx].to_coords !== nLegs[legIndx].to_coords
    ) {
      // this.validateLegDistance(nextProps.distance);
    }

    if (flightType !== nextProps.flightType && flightType) {
      for (let i = 0; i < this.props.model.legs.length; i++) {
        this.context.store.dispatch(
          actions.setUntouched(`${this.props.modelName}.legs.[${i}].from_coords`),
        );
        this.context.store.dispatch(
          actions.setUntouched(`${this.props.modelName}.legs.[${i}].to_coords`),
        );

        setTimeout(() => {
          this.context.store.dispatch(
            actions.resetErrors(`${this.props.modelName}.legs.[${i}].to_coords`),
          );
          this.context.store.dispatch(
            actions.resetErrors(`${this.props.modelName}.legs.[${i}].from_coords`),
          );
        });
      }

      this.setState({ clearLocationsErrors: true });
    }

    if (
      model &&
      model.flight_purpose &&
      this.props.to &&
      this.props.from &&
      !this.state.updateModelFromTo
    ) {
      const updates = [];

      for (let i = 0; i < model.legs.length; i++) {
        const { from_location, to_location } = model.legs[i];

        if (from_location && Object.keys(from_location).length) {
          updates.push(
            {
              key: "from",
              address: from_location,
              index: i,
            },
            {
              key: "to",
              address: to_location,
              index: i,
            },
          );
        }
      }

      this.setState({ updateModelFromTo: true });

      updates.forEach(({ key, address, index }) => {
        setTimeout(() => this.updateLegOnSelect(key, address, index), index);
      });
    }

    if (flightType !== nextProps.flightType) {
      this.context.store.dispatch(
        actions.setUntouched(`${this.props.modelName}.legs.[${this.props.index}].from_coords`),
      );
      this.context.store.dispatch(
        actions.setUntouched(`${this.props.modelName}.legs.[${this.props.index}].to_coords`),
      );
    }

    if ($form.submitFailed && !fieldsValidated) {
      if (!this.props.model.legs[0].from_coords) {
        this.context.store.dispatch(
          actions.setErrors(`${this.props.modelName}.legs.[0].from_coords`, {
            required: true,
          }),
        );
      }
      if (!this.props.model.legs[0].to_coords) {
        this.context.store.dispatch(
          actions.setErrors(`${this.props.modelName}.legs.[0].to_coords`, {
            required: true,
          }),
        );
      }

      this.setState({ fieldsValidated: true });
    }
  }

  validateLegDateTime(leg, index, legs) {
    const model = `${this.props.modelName}.legs.[${index}].date`;
    const bool = this.compareWithCurrentDate(leg, index, legs);
    if (bool) {
      this.context.store.dispatch(actions.resetValidity(model));
    } else {
      this.context.store.dispatch(actions.setErrors(model, { compareDate: true }));
    }
  }

  validateLegDistance(nextDistance = null) {
    const distance = nextDistance || this.props.distance;

    if (!isNaN(distance[0])) {
      if (distance[0] > 850) {
        this.context.store.dispatch(
          actions.setErrors(`${this.props.modelName}.legs.[${this.props.index}].from_coords`, {
            is_distance_exceeds: true,
          }),
        );
      } else {
        this.context.store.dispatch(
          actions.setErrors(`${this.props.modelName}.legs.[${this.props.index}].from_coords`, {
            is_distance_exceeds: false,
          }),
        );
      }
    }
  }

  validatePassengers(leg, total) {
    const hasFieldChanged = ["men", "women", "children"]
      .map((key) => leg.passengers[key].pristine)
      .includes(false);
    const submitFailed = this.props.form.$form.submitFailed;
    return (hasFieldChanged || submitFailed) && total === 0
      ? staticService.findByAlias("requestMinPassengersError")
      : "";
  }

  validateOtherSpecialItems(value) {
    if (value && value.length < 3) {
      return "The body must be at least 3 characters.";
    } else {
      return "";
    }
  }

  updateNextLeg(modelKey, address, index) {
    if (modelKey === "to" && this.props.model.legs.length !== index + 1) {
      this.updateLegOnSelect("from", address, index + 1);
    }
    if (
      modelKey === "from" &&
      this.props.model.legs.length !== index + 1 &&
      this.props.flightType === "2"
    ) {
      this.updateLegOnSelect("to", address, index + 1);
    }
  }

  getDateTimeObject(date, time) {
    return moment(tranformDateTimeToISO(date, time));
  }

  compareDate() {
    const currentLegDate = this.getDateTimeObject(this.props.date, this.props.departure_time);
    if (this.props.model.legs.length > 0) {
      const { legs } = this.props.model;
      if (this.props.index > 0) {
        const prevLeg = legs[this.props.index - 1];
        const prevLegDate = this.getDateTimeObject(prevLeg.date, prevLeg.departure_time);
        return prevLegDate.isBefore(currentLegDate);
      }
      if (this.props.index >= 0 && this.props.index !== legs.length - 1) {
        const nextLeg = legs[this.props.index + 1];
        const nextLegDate = this.getDateTimeObject(nextLeg.date, nextLeg.departure_time);
        return currentLegDate.isBefore(nextLegDate);
      }
    }
    return true;
  }

  dateBeforeCurrent() {
    for (let legIndex = 0; legIndex < this.props.model.legs.length; legIndex++) {
      this.context.store.dispatch(
        actions.resetValidity(`${this.props.modelName}.legs.[${legIndex}].departure_time`),
      );
    }

    const currentLegDate = this.getDateTimeObject(this.props.date, this.props.departure_time);

    return !currentLegDate.isBefore(moment());
  }

  compareWithCurrentDate(leg, index, legs) {
    if (index > 0) {
      const prevLeg = legs[index - 1];
      const prevLegDate = this.getDateTimeObject(prevLeg.date, prevLeg.departure_time);
      const currentLegDate = this.getDateTimeObject(leg.date, leg.departure_time);
      return prevLegDate.isBefore(currentLegDate);
    }
    return true;
  }

  updateLegOnSelect(modelKey, address, index) {
    const model = { ...this.props.model.legs[index] };
    model[modelKey] = address.location || address.name;
    model[`${modelKey}_coords`] = `${address.latitude}|${address.longitude}`;
    model[`${modelKey}_info`] = generateLocationAdditionalInfo(address);
    model[`${modelKey}_source`] = address.source;
    model[`${modelKey}_place_id`] = address.place_id;
    model[`${modelKey}_coverage_status`] = address.coverage_status;
    model[`${modelKey}_type`] = address.type;
    delete model[`${modelKey}_location`];
    this.context.store.dispatch(actions.change(`${this.props.modelName}.legs.[${index}]`, model));
    this.context.store.dispatch(
      actions.resetValidity(`${this.props.modelName}.legs.[${index}].${modelKey}`),
    );
    this.context.store.dispatch(
      actions.resetValidity(`${this.props.modelName}.legs.[${index}].${modelKey}_coords`),
    );

    this.validateLegDistance();
  }
  showPads(markerClickFunc) {
    markerClickFunc(this.props.leg);
  }
  onSelectLocation(handleClickOutside, modelKey, address) {
    if (address.latitude) {
      this.setState(
        {
          leg: {
            ...this.state.leg,
            [modelKey]: `${address.latitude}|${address.longitude}`,
          },
        },
        () => {
          if (this.state.leg.to && this.state.leg.from) {
            this.props.onSubmitDistance(this.props.model.legs);
            this.context.store.dispatch(
              actions.resetValidity(`${this.props.modelName}.legs.[${this.props.index}]`),
            );
          }
        },
      );
    }

    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address);
    } else {
      this.updateLegOnSelect(modelKey, address, this.props.index);
      this.updateNextLeg(modelKey, address, this.props.index);
    }
  }

  showErrorStatus(key, index) {
    return () => {
      if (key === "from") {
        if (this.props.form.legs[index]["from_coords"].errors.is_distance_exceeds) {
          return true;
        }
      }

      if (!this.props.form.legs[index][key].focus) {
        if (
          this.props.form.legs[index][`${key}_coords`].touched ||
          this.props.form.$form.submitted
        ) {
          return true;
        }
      }

      return false;
    };
  }

  render() {
    const { paxAmountDefault } = this.state;
    const { leg, storageLegModal, aircraft } = this.props;
    const { men, women, children } = this.props.model.legs[this.props.index].passengers;
    const isPrivateSiteFrom = this.props.model.legs[this.props.index].from_is_private;
    const isPrivateSiteTo = this.props.model.legs[this.props.index].to_is_private;

    const { dispatch } = this.context.store;

    const displayShortTimeOption = () => {
      if (this.props.screenW < 1200) {
        return staticService.findByAlias(
          timeType.find((d) => +d.key === +this.props.model.legs[this.props.index].time_type)
            .shortName,
        );
      } else {
        return staticService.findByAlias(
          timeType.find((d) => +d.key === +this.props.model.legs[this.props.index].time_type).name,
        );
      }
    };

    const displayShortTimeOptionLabel = (item) => {
      if (this.props.screenW < 1200) {
        return staticService.findByAlias(timeType.find((d) => +d.key === +item.key).shortName);
      } else {
        return staticService.findByAlias(timeType.find((d) => +d.key === +item.key).name);
      }
    };

    return (
      <Fieldset model={`${this.props.modelName}.legs.[${this.props.index}]`}>
        <div>
          <div data-uk-grid className="uk-margin">
            <div className="uk-width-1-1@s uk-width-1-2@m">
              <label
                className="uk-form-label required"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("from"),
                }}
              />

              <LocationAutocompleteWrapper
                clearLocationsErrors={this.state.clearLocationsErrors}
                onClearSuccess={() => this.setState({ clearLocationsErrors: false })}
                loading={
                  this.props.form.legs[this.props.index].from_coords.touched &&
                  fillCoordinatesLoading.get("from")
                }
                focus={this.props.form.legs[this.props.index].from.focus}
                is_distance_exceeds={
                  this.props.form.legs[this.props.index]["from_coords"].errors.is_distance_exceeds
                }
              >
                {({
                  locations,
                  search,
                  handleClickOutside,
                  loader,
                  onBlur,
                  locationValidationTemplate,
                }) => {
                  return (
                    <div className="uk-position-relative uk-margin-bottom">
                      <LocationAutocompleteInput
                        className={["uk-input"]}
                        placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
                        disabled={this.props.readOnly}
                        value={this.props.model.legs[this.props.index].from}
                        additionalInfo={this.props.model.legs[this.props.index].from_info}
                        coverageWarning={
                          this.props.model.legs[this.props.index].from_coverage_status
                        }
                        onSelect={(location) => {
                          this.updateGeocodeModel("from", location);
                          return search(location);
                        }}
                        onBlur={(...args) => {
                          this.context.store.dispatch(
                            actions.blur(`${this.props.modelName}.legs.[${this.props.index}].from`),
                          );
                          onBlur(...args);
                        }}
                        locations={locations}
                        loader={loader}
                        debounce={500}
                        model={`${this.props.modelName}.legs.[${this.props.index}].from`}
                        onFocus={() =>
                          this.context.store.dispatch(
                            actions.focus(
                              `${this.props.modelName}.legs.[${this.props.index}].from`,
                            ),
                          )
                        }
                        is_distance_exceeds={
                          this.props.form.legs[this.props.index]["from_coords"].errors
                            .is_distance_exceeds
                        }
                        checkCoverage
                      />
                      <LocationAutocompleteList
                        onSelect={this.onSelectLocation.bind(this, handleClickOutside, "from")}
                        locations={locations}
                        checkCoverage
                      />
                      {locationValidationTemplate(
                        `${this.props.modelName}.legs.[${this.props.index}].from_coords`,
                        ".from_coords",
                        "pleaseSelect",
                        this.showErrorStatus("from", this.props.index),
                      )}
                    </div>
                  );
                }}
              </LocationAutocompleteWrapper>
              <div className="uk-flex uk-flex-center gh-private-checkbox">
                <label
                  className={`gh-checkbox-label uk-margin-small-right ${
                    this.props.readOnly && "gh-checkbox-label-disabled"
                  }`}
                >
                  <Control.checkbox
                    className="uk-checkbox uk-margin-small-right"
                    disabled={this.props.readOnly}
                    model={`${this.props.modelName}.legs.[${this.props.index}].from_is_private`}
                    onClick={(e) => this.updateGeocodeModel("from_is_private", e.target.checked)}
                    changeAction={updateModel}
                    updateOn="change"
                  />{" "}
                  {staticService.findByAlias("exactTakeOffLocation")}
                </label>
                <Tooltip pos="top-left" refEl="self" contentClass="private-tooltip-content">
                  {staticService.findByAlias("privateSiteDetailsTooltipTakeOff")}
                </Tooltip>
              </div>

              {isPrivateSiteFrom && (
                <div className="uk-position-relative uk-margin-small-top gh-private-text">
                  <Control.text
                    model={`${this.props.modelName}.legs.[${this.props.index}].from_details`}
                    className="uk-input"
                    disabled={this.props.readOnly}
                    placeholder={staticService.findByAlias("privateSiteDetailsPlaceholder")}
                    changeAction={(model, value) => this.updateGeocodeModel("from_details", value)}
                    updateOn="change"
                  />
                </div>
              )}
            </div>
            <div className="uk-width-1-1@s uk-width-1-2@m">
              <label
                className="uk-form-label required"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("to"),
                }}
              />

              <LocationAutocompleteWrapper
                clearLocationsErrors={this.state.clearLocationsErrors}
                onClearSuccess={() => this.setState({ clearLocationsErrors: false })}
                loading={
                  this.props.form.legs[this.props.index].to_coords.touched &&
                  fillCoordinatesLoading.get("to")
                }
                focus={this.props.form.legs[this.props.index].to.focus}
              >
                {({
                  locations,
                  search,
                  handleClickOutside,
                  loader,
                  onBlur,
                  locationValidationTemplate,
                }) => {
                  return (
                    <div className="uk-position-relative uk-margin-bottom">
                      <LocationAutocompleteInput
                        className={["uk-input"]}
                        placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
                        disabled={this.props.readOnly}
                        value={this.props.model.legs[this.props.index].to}
                        additionalInfo={this.props.model.legs[this.props.index].to_info}
                        coverageWarning={this.props.model.legs[this.props.index].to_coverage_status}
                        onSelect={(location) => {
                          this.updateGeocodeModel("to", location);
                          return search(location);
                        }}
                        onBlur={(...args) => {
                          this.context.store.dispatch(
                            actions.blur(`${this.props.modelName}.legs.[${this.props.index}].to`),
                          );
                          onBlur(...args);
                        }}
                        loader={loader}
                        locations={locations}
                        debounce={500}
                        model={`${this.props.modelName}.legs.[${this.props.index}].to`}
                        onFocus={() =>
                          this.context.store.dispatch(
                            actions.focus(`${this.props.modelName}.legs.[${this.props.index}].to`),
                          )
                        }
                        checkCoverage
                      />
                      <LocationAutocompleteList
                        onSelect={this.onSelectLocation.bind(this, handleClickOutside, "to")}
                        locations={locations}
                        checkCoverage
                      />
                      {locationValidationTemplate(
                        `${this.props.modelName}.legs.[${this.props.index}].to_coords`,
                        ".to_coords",
                        "pleaseSelect",
                        this.showErrorStatus("to", this.props.index),
                      )}
                    </div>
                  );
                }}
              </LocationAutocompleteWrapper>

              <div className="uk-flex uk-flex-center gh-private-checkbox">
                <label
                  className={`gh-checkbox-label uk-margin-small-right ${
                    this.props.readOnly && "gh-checkbox-label-disabled"
                  }`}
                >
                  <Control.checkbox
                    className="uk-checkbox uk-margin-small-right"
                    model={`${this.props.modelName}.legs.[${this.props.index}].to_is_private`}
                    disabled={this.props.readOnly}
                    onClick={(e) => this.updateGeocodeModel("to_is_private", e.target.checked)}
                    changeAction={updateModel}
                    updateOn="change"
                  />{" "}
                  {staticService.findByAlias("exactLandingLocation")}
                </label>
                <Tooltip pos="top-left" refEl="self">
                  {staticService.findByAlias("privateSiteDetailsTooltipLanding")}
                </Tooltip>
              </div>

              {isPrivateSiteTo && (
                <div className="uk-position-relative uk-margin-small-top gh-private-text">
                  <Control.text
                    model={`${this.props.modelName}.legs.[${this.props.index}].to_details`}
                    className="uk-input"
                    disabled={this.props.readOnly}
                    placeholder={staticService.findByAlias("privateSiteDetailsPlaceholder")}
                    changeAction={(model, value) => this.updateGeocodeModel("to_details", value)}
                    updateOn="change"
                  />
                </div>
              )}
            </div>
          </div>

          {auth.hasFeature("instant_estimated_travel_times") ? (
            <div>
              <hr className="gh-request-divider" />
              <div className="new-request_flex">
                <div className="new-request_flex-item">
                  <div className="gh-flight-duration">
                    <span
                      className="gh-request-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("estimatedFlightTime"),
                      }}
                    />
                    <span className="uk-margin-small-left gh-estimated-time">
                      {this.props.distanceToTime}
                    </span>
                  </div>
                </div>
                {this.props.currentDistance >= 160 && (
                  <div className="new-request_flex-item">
                    <div className="uk-flex uk-flex-middle gh-gray-warning">
                      <span
                        className="uk-text-warning gh-danger-opostrov gh-vertical-middle uk-margin-small-right uk-position-relative"
                        data-uk-icon="icon: warning"
                      />
                      <span
                        className="gh-estimate-warning"
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("brokerWarningDistance"),
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <hr className="gh-request-divider" />

          <div id="map">
            <div className="gh-map-header">
              <div
                className="uk-flex"
                style={{
                  alignItems: "baseline",
                  lineHeight: "6px",
                }}
              >
                <h4
                  className="uk-margin-remove gh-request-label"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("map"),
                  }}
                />
              </div>
              {!this.props.emptyLeg && auth.hasFeature("rich_content_map") && (
                <label className="gh-checkbox-label gh-margin-small-top-mobile">
                  <Control.checkbox
                    className="uk-checkbox uk-margin-small-right"
                    model={`${this.props.modelName}.legs.[${this.props.index}].local_points`}
                    changeAction={updateModel}
                    updateOn="change"
                    disabled={this.props.readOnly}
                  />{" "}
                  {staticService.findByAlias("localPointsOfInterest")}
                </label>
              )}
            </div>
            {this.props.legsOpen || this.props.emptyLeg ? (
              <PadSelectionMap
                classNames="uk-margin gh-pad-selection"
                legs={[leg]}
                flyDirCoords={[
                  {
                    key: this.props.index,
                    pad: leg,
                    from: leg.from_coords,
                    to: leg.to_coords,
                  },
                ]}
                screenDimensions={this.props.screenDimensions}
                showAvailablePads={
                  auth.hasFeature("rich_content_map") &&
                  !!this.props.leg.local_points &&
                  !this.props.readOnly
                }
                legIndex={this.props.index}
                handleAssignPad={this.props.handleAssignPad}
                flightType={this.props.model.flight_type}
                cardActions={
                  this.props.index === 1 && this.props.model.flight_type === "2" ? false : true
                }
                review={this.props.readOnly}
              />
            ) : (
              ""
            )}
          </div>

          <div className="uk-flex uk-flex-middle gh-gray-warning">
            <span
              className="uk-text-warning gh-danger-opostrov gh-vertical-middle uk-margin-small-right uk-position-relative"
              data-uk-icon="icon: warning"
            />
            <span
              className="gh-estimate-warning"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("surveyedHelipads"),
              }}
            />
          </div>

          <hr className="gh-request-divider uk-visible@s" />

          <div data-uk-grid className="uk-margin-top">
            <div className="uk-width-1-1@s uk-width-1-2@m">
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("date"),
                }}
              />
              <div className="uk-position-relative gh-datepicker-field">
                <Control
                  value={this.date}
                  model={`${this.props.modelName}.legs.[${this.props.index}].date`}
                  className="uk-input gh-calendar-input"
                  component={DateTimeComponent}
                  disabled={this.props.emptyLeg}
                  icon={calendar}
                  readOnly
                  calendarLabel={
                    <div className="gh-calendar-label disabled">
                      {staticService.findByAlias(
                        dateType.find(
                          (d) => +d.key === +this.props.model.legs[this.props.index].date_type,
                        ).name,
                      )}
                    </div>
                  }
                  controlProps={{
                    dateFormat: "DD MMMM, YYYY",
                    isValidDate: true,
                    closeOnSelect: false,
                    referenceDate: this.props.referenceDate,
                    showClearIcon: false,
                    requestCalendarFields: (
                      <div className="gh-picker-box">
                        <div className="gh-picker-box-content">
                          {dateType.map((item) => (
                            <label
                              key={item.key}
                              className={`gh-radio-label ${
                                this.props.model.legs[this.props.index].date_type === item.key
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Control.radio
                                model={`${this.props.modelName}.legs.[${this.props.index}].date_type`}
                                className="uk-radio"
                                changeAction={updateModel}
                                value={item.key}
                                checked={
                                  this.props.model.legs[this.props.index].date_type === item.key
                                }
                              />
                              <span
                                style={{ paddingLeft: 11 }}
                                dangerouslySetInnerHTML={{
                                  __html: staticService.findByAlias(item.name),
                                }}
                              />
                            </label>
                          ))}
                        </div>
                      </div>
                    ),
                  }}
                  changeAction={this.onPickerChange.bind(this)}
                  updateOn="change"
                  validators={{
                    required,
                    compareDate: this.compareDate.bind(this),
                  }}
                />
                <Errors
                  model={`${this.props.modelName}.legs.[${this.props.index}].date`}
                  show={true}
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(null, "date"),
                  }}
                />
              </div>
            </div>
            <div className="uk-width-1-1@s uk-width-1-2@m">
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("departureTime"),
                }}
              />
              <div className="uk-position-relative gh-datepicker-field">
                <Control
                  value={this.time}
                  model={`${this.props.modelName}.legs.[${this.props.index}].departure_time`}
                  className="uk-input gh-calendar-input"
                  component={DateTimeComponent}
                  icon={clock}
                  calendarLabel={
                    <div className="gh-calendar-label disabled">{displayShortTimeOption()}</div>
                  }
                  time={true}
                  readOnly
                  controlProps={{
                    dateFormat: false,
                    timeFormat: config.timeFormat,
                    placeholder: staticService.findByAlias("time"),
                    timeConstraints: {
                      hours: {
                        min: (storageLegModal && 12) || this.state.time_from_min,
                        max: (storageLegModal && 23) || this.state.max,
                        step: 1,
                      },
                      minutes: {
                        step: config.minuteStep,
                      },
                    },
                    showClearIcon: false,
                    requestCalendarFields: (
                      <div className="gh-picker-box">
                        <div className="gh-picker-box-content">
                          {timeType.map((item) => (
                            <label
                              key={item.key}
                              className={`gh-radio-label ${
                                this.props.model.legs[this.props.index].time_type === item.key
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Control.radio
                                model={`${this.props.modelName}.legs.[${this.props.index}].time_type`}
                                className="uk-radio"
                                changeAction={updateModel}
                                value={item.key}
                                checked={
                                  this.props.model.legs[this.props.index].time_type === item.key
                                }
                              />
                              <span
                                style={{ paddingLeft: 11 }}
                                dangerouslySetInnerHTML={{
                                  __html: displayShortTimeOptionLabel(item),
                                }}
                              />
                            </label>
                          ))}
                        </div>
                      </div>
                    ),
                  }}
                  changeAction={this.props.updateRange || this.updateRange.bind(this)}
                  validators={{
                    dateBeforeCurrent: this.dateBeforeCurrent.bind(this),
                    compareDate: this.compareDate.bind(this),
                  }}
                />
                <Errors
                  model={`${this.props.modelName}.legs.[${this.props.index}].departure_time`}
                  show={true}
                  wrapper={CustomValidateError}
                  messages={{
                    dateBeforeCurrent: staticService.findByAlias("departureTimeRuleError"),
                    compareDate: staticService.findByAlias("oldDates"),
                  }}
                />
              </div>
            </div>
          </div>

          <hr className="gh-request-divider" />

          {this.props.sameInfoCheckbox ? (
            <div style={{ marginBottom: 18 }}>
              <label>
                <Control.checkbox
                  className="uk-checkbox uk-margin-small-right"
                  model={`${this.props.modelName}.legs.[${this.props.index}].same_as_previous`}
                  changeAction={(model, value) =>
                    this.props.fillWithTheSameInfo(model, value, this.props.index)
                  }
                  updateOn="change"
                />{" "}
                {staticService.findByAlias("sameAsPreviousLeg")}
              </label>
            </div>
          ) : (
            ""
          )}

          <div data-uk-grid className="uk-grid-small gh-request-data-fields">
            <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
              <div className="gh-request-info-section uk-padding-remove-left">
                <label
                  className="uk-form-label uk-margin-bottom required"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("passengers"),
                  }}
                />
                <InlineNumberFieldset
                  model={`${this.props.modelName}.legs.[${this.props.index}].passengers`}
                  modelSection="passengers"
                  visible={true}
                  maxPassengers={aircraft && aircraft.max_passengers}
                  legIndex={this.props.index}
                  fillWithTheSameInfoNextLeg={this.props.fillWithTheSameInfoNextLeg}
                  total={(leg && leg.passengers) || {}}
                  items={[
                    { key: "men", title: "men" },
                    { key: "women", title: "women" },
                    { key: "children", title: "children" },
                  ]}
                />

                <hr className="gh-request-divider uk-visible@s" />

                <div className="uk-flex uk-flex-between uk-flex-middle gh-margin-top-mobile">
                  <label
                    className="uk-form-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("total"),
                    }}
                  />
                  <div className="gh-badge-counter">{men + women + children + 0}</div>
                </div>
                <CustomValidateError>
                  {[
                    <span key={1}>
                      {this.validatePassengers(
                        this.props.form.legs[this.props.index],
                        men + women + children + 0,
                      )}
                    </span>,
                  ]}
                </CustomValidateError>

                <hr className="gh-request-divider uk-visible@s" />

                <div className="uk-form-controls gh-request-radios gh-margin-top-mobile">
                  <div className="uk-margin-small-bottom">
                    <label className="gh-radio-label">
                      <div>
                        <Control.radio
                          model={`${this.props.modelName}.legs.[${this.props.index}].passengers.passengers_amount_type`}
                          className="uk-radio"
                          changeAction={(model, value) =>
                            this.props.fillWithTheSameInfoNextLeg(
                              model,
                              value,
                              this.props.index,
                              "passengers",
                            )
                          }
                          value="1"
                          checked={
                            this.props.model.legs[this.props.index].passengers
                              .passengers_amount_type === "1" || paxAmountDefault === "1"
                          }
                        />
                      </div>
                      <span
                        style={{ paddingLeft: 11 }}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("exactPaxNumber"),
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <label className="gh-radio-label">
                      <div>
                        <Control.radio
                          model={`${this.props.modelName}.legs.[${this.props.index}].passengers.passengers_amount_type`}
                          className="uk-radio"
                          changeAction={(model, value) =>
                            this.props.fillWithTheSameInfoNextLeg(
                              model,
                              value,
                              this.props.index,
                              "passengers",
                            )
                          }
                          value="2"
                          checked={
                            this.props.model.legs[this.props.index].passengers
                              .passengers_amount_type === "2" || paxAmountDefault === "2"
                          }
                        />
                      </div>
                      <span
                        style={{ paddingLeft: 11 }}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("posibilityOfChangePax"),
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
              <hr className="gh-request-divider uk-hidden@s" />

              <div className="gh-request-info-section">
                <label
                  className="uk-form-label uk-margin-bottom"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("luggage"),
                  }}
                />
                <InlineNumberFieldset
                  model={`${this.props.modelName}.legs.[${this.props.index}].luggage`}
                  modelSection="luggage"
                  visible={true}
                  legIndex={this.props.index}
                  fillWithTheSameInfoNextLeg={this.props.fillWithTheSameInfoNextLeg}
                  capitalizeTitles
                  items={[
                    { key: "carry_on_bags", title: "Carry-onBags" },
                    { key: "hand_bags", title: "handBags" },
                  ]}
                />
                <label className="gh-checkbox-label uk-margin-medium-top">
                  <Control.checkbox
                    className="uk-checkbox uk-margin-small-right"
                    model={`${this.props.modelName}.legs.[${this.props.index}].luggage.luggage_will_provide_later`}
                    changeAction={(model, value) =>
                      this.props.fillWithTheSameInfoNextLeg(
                        model,
                        value,
                        this.props.index,
                        "luggage",
                      )
                    }
                    updateOn="change"
                  />{" "}
                  {staticService.findByAlias("willProvideLater")}
                </label>
              </div>
            </div>

            <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
              <hr className="gh-request-divider uk-hidden@s" />

              <div className="gh-request-info-section gh-remove-border">
                <label
                  className="uk-form-label uk-margin-bottom"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("specialItems"),
                  }}
                />
                <InlineNumberFieldset
                  model={`${this.props.modelName}.legs.[${this.props.index}].special_items`}
                  modelSection="special_items"
                  visible={true}
                  legIndex={this.props.index}
                  fillWithTheSameInfoNextLeg={this.props.fillWithTheSameInfoNextLeg}
                  capitalizeTitles
                  items={[
                    { key: "bicycles", title: "bicycles" },
                    { key: "golf_club_sets", title: "golfClubSets" },
                    { key: "hunting_weapons", title: "huntingWeapons" },
                    { key: "pets", title: "pets" },
                    { key: "ski_pairs", title: "skiPairs" },
                  ]}
                  additionClasses="gh-remove-border"
                />
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
              <hr className="gh-request-divider uk-hidden@s" />

              <div
                className="gh-request-info-section gh-remove-border uk-padding-remove-right"
                style={{ border: 0 }}
              >
                <label
                  className="uk-form-label uk-margin-bottom"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("otherSpecialItems"),
                  }}
                />
                <Control.textarea
                  model={`${this.props.modelName}.legs.[${this.props.index}].special_items.other`}
                  className="uk-textarea"
                  changeAction={(model, value) =>
                    this.props.fillWithTheSameInfoNextLeg(
                      model,
                      value,
                      this.props.index,
                      "special_items",
                    )
                  }
                  placeholder={staticService.findByAlias("description")}
                />
                <CustomValidateError>
                  {[
                    <span key={1}>
                      {this.validateOtherSpecialItems(
                        this.props.form.legs[this.props.index].special_items.other.value,
                      )}
                    </span>,
                  ]}
                </CustomValidateError>
              </div>
            </div>
          </div>
          {this.props.flightType === "3" && this.props.index > 1 ? (
            <div>
              <hr className="gh-request-divider" />
              <div className="uk-width-1-1 uk-flex uk-flex-center">
                <button
                  onClick={(ev) => this.props.removeLeg(ev, this.props.index)}
                  className="uk-button uk-button-text uk-text-danger gh-button-simple"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("removeLeg"),
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Fieldset>
    );
  }
}

LegFormComponent.contextTypes = {
  store: PropTypes.object,
};
LegFormComponent.propTypes = {
  modelName: PropTypes.string.isRequired,
  index: PropTypes.number,
  updateFromToModel: PropTypes.func,
  onSubmitDistance: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  model: PropTypes.object,
  departure_time: PropTypes.string,
  from_coords: PropTypes.string,
  to_coords: PropTypes.string,
  date: PropTypes.string,
  form: PropTypes.object,
  leg: PropTypes.object,
  errorObj: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  passengers: PropTypes.object,
  storageLegModal: PropTypes.object,
  screenW: PropTypes.number,
  flightType: PropTypes.string,
  readOnly: PropTypes.bool,
  currentDistance: PropTypes.number,
  distanceToTime: PropTypes.string,
  handleAssignPad: PropTypes.func,
  screenDimensions: PropTypes.object,
  updateRange: PropTypes.func,
  sameInfoCheckbox: PropTypes.bool,
  fillWithTheSameInfo: PropTypes.func,
  removeLeg: PropTypes.func,
  referenceDate: PropTypes.string,
  emptyLeg: PropTypes.bool,
  distance: PropTypes.arrayOf(PropTypes.number),
};

function mapStateToProps(state) {
  return {
    legDistance: state.legDistance,
    errorObj: state.errorObj,
    screenW: state.screenDimensions.screenInnerW,
    emptyLegs: state.emptyLegs,
    company: state.company,
  };
}

const COMPONENT = connect(mapStateToProps)(LegFormComponent);
export { COMPONENT as LegFormComponent };
