import React from "react";
import PropTypes from "prop-types";
import cs from "../../../../utils/condString";

import "./index.css";

export function Accordion(props) {
  const { className, isOpen, onToggle, header, forcedMaxH, children } = props;

  const contentRef = React.useRef();

  return (
    <div className={cs("accordion", className)}>
      <div onClick={onToggle} className={cs("accordion__title", onToggle && "clickable")}>
        {header}
      </div>
      <div
        className="accordion__content"
        ref={contentRef}
        style={{
          maxHeight: isOpen
            ? forcedMaxH || (contentRef.current && contentRef.current.scrollHeight)
            : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.any,
  onToggle: PropTypes.func,
  header: PropTypes.element,
  isOpen: PropTypes.bool,
  forcedMaxH: PropTypes.number,
  className: PropTypes.string,
};
