import {
  apiDomain,
  deployment_version,
  environment,
  iframeToken,
  landingSitesApiKey,
  landingSitesDomain,
  pdfUri,
  staticFilesVersion,
  stripePublicKey,
  url,
  websocketPath,
} from "../../env/env";
import moment from "moment";
import { generateAccountLocalization } from "../context";

const accountLocalization = generateAccountLocalization();

export const config = {
  appName: "Get Heli Ltd",
  envURL: {
    development: "api-staging",
    demo: "demo-api",
    production: "api",
  },
  apiDomain: apiDomain,
  environment: environment || "production",
  landingSitesDomain: landingSitesDomain,
  landingSitesApiKey: landingSitesApiKey,
  websocketPath: websocketPath,
  invitationPlaceholder: "colleague@yourcompany.com",
  staticFilesVersion: staticFilesVersion || 1,
  url: url || "https://external.getheli.com",
  pdfPath: pdfUri,
  iframeToken: iframeToken || "b7nONSoum7a9jTEZXrtA",
  apiHeaders: {
    Accept: "application/json, */*",
    "Content-Type": "application/json",
    version: deployment_version || 1,
  },
  downloadHeaders: {
    Accept: "application/pdf, *!/!*",
    "Content-Type": "application/pdf",
  },
  messages: {
    notAuthorizedComponent:
      "Unfortunately you do not have required permission to access this resource",
    notAuthorizedRoute: "Unfortunately you do not have required permission to access this page!!!",
  },

  allowedFiles: ["application/pdf", "image/png", "image/jpeg"],
  stripeAllowedFiles: ["image/png", "image/jpeg", "image/jpg"],
  gaCode: "G-E371FKTXCX",
  currency: "GBP",
  currencyCode: "GBP",
  redirectToIfNotAuthorized: "/",
  redirectToMainPage: "/",
  redirectToEvents: "/events",
  redirectToMarketPlace: "/marketplace",
  redirectToEmptyMarketPlace: "/marketplace/empty-legs",
  redirectToAgreements: "/account/agreements",
  redirectToLogin: "/account/login",
  redirectToLogout: "/account/logout",
  redirectUserDashboard: "/dashboard",
  redirectToForbidden: "/forbidden",
  redirectToNotFound: "/not-found",
  redirectToRegister: "/account/register",
  redirectToReviewAgreement: "/review-agreement",
  bookingRoute: "/flights/my-bookings",
  redirectToEmptyleg: "/flights/empty-legs",
  redirectToOpenQuotes: "/flights/quotes/filter/all",
  redirectToOpenRequests: "/flights/requests/filter/open",
  max_crew: 2,
  language: "en",
  max_file_upload: 15,
  stripe_max_upload: 8,
  max_passengers: 15,
  allowed_passengers: 15,
  yearRanges: { start: 1950, end: moment().format("YYYY") },
  paginationLimit: 10,
  rangeTime: 10,
  minDeviceWidth: 375,
  maxAllowedMapeHeight: 800,
  nexusDeviceWidth: 415,
  gridMobile: 768,
  stripeReleaseInterval: 90, //days
  commissions: {
    stripe: 1 + 0.014,
    getHeli: 1 + 0.05,
    fixed: 0.2,
  },
  vat: 100 / 6,

  filterDateFormat: "DD MMMM, YYYY",
  dobFormat: "DD MMMM, YYYY",
  departureFormat: "HH:mm DD MMMM, YYYY",
  timeFormat: accountLocalization.timeFormat,
  dateFormat: "DD MMMM, YYYY",
  dateServerFormat: "DD MMMM, YYYY HH:mm",
  monthFormat: "MMMM, YYYY",
  dateAtTimeFormat: "MMM DD, YYYY [at] HH:mm",

  prefixes: {
    request: "GHR",
    request_details: "GHR",
    quote: "GHQ",
    booking: "GHB",
    empty_leg: "GHE",
  },

  countries: [
    { id: "US", name: "United States" },
    { id: "GB", name: "United Kingdom" },
  ],

  currencies: [
    { id: "usd", name: "USD" },
    { id: "gbp", name: "GBP" },
    { id: "eur", name: "Euro" },
  ],

  paymentAccounts: [
    { id: "individual", name: "Individual" },
    { id: "company", name: "Company" },
  ],

  stripePublicKey: stripePublicKey,
  notificationFiltersOperator: [
    { id: "quote", name: "Relating to Quotes" },
    { id: "booking", name: "Relating to Bookings" },
  ],

  notificationFiltersBroker: [
    { id: "request", name: "Relating to Requests" },
    { id: "booking", name: "Relating to Bookings" },
  ],

  aircraftPreferences: [
    { id: "twin-engine", title: "Twin-engine" },
    { id: "single-engine", title: "Single-engine" },
    { id: "no-preference", title: "No Preference" },
  ],

  pilotPreferences: [
    { id: 0, title: "No Preference" },
    { id: 1, title: "One pilot" },
    { id: 2, title: "Two pilots" },
  ],

  quoteStatuses: [
    { id: "all", title: "All" },
    { id: "open", title: "Open" },
    { id: "pre_quoted", title: "Pre Quoted" },
    { id: "booking_init", title: "Pending Confirmation" },
    { id: "withdrawn", title: "Withdrawn" },
    { id: "unsuccessful", title: "Unsuccessful" },
    { id: "closed", title: "Closed" },
    { id: "rejected", title: "Rejected" },
    { id: "amended", title: "Amended" },
  ],

  quoteStatusObject: {
    all: "All",
    open: "Open",
    booking_init: "Pending Confirmation",
    withdrawn: "Withdrawn",
    unsuccessful: "Unsuccessful",
    closed: "Closed",
    rejected: "Rejected",
    amended: "Amended",
    seen_by_broker: "Quote Seen by Broker",
    on_hold: "Quote On Hold",
    further_details_required: "Further Details Required",
    discussion_started: "Discussion Started",
    quote_expired: "Quote Expired",
    operator_preparing_quote: "Operator Preparing Quote",
  },

  miles: [
    { id: "", title: "Choose radius ..." },
    { id: "25", title: "25 nautical miles" },
    { id: "50", title: "50 nautical miles" },
    { id: "100", title: "100 nautical miles" },
    { id: "200", title: "200 nautical miles" },
    { id: "250", title: "250 nautical miles" },
  ],

  bookingStatuses: [
    { id: "all", title: "All" },
    { id: "confirmed", title: "Confirmed" },
    { id: "canceled", title: "Canceled" },
    { id: "completed", title: "Completed" },
  ],

  contactSubjects: [
    { id: "flight", title: "Flight enquiry" },
    { id: "getting_started", title: "Getting started" },
    { id: "support", title: "Support" },
    { id: "api", title: "API enquiry" },
    { id: "press", title: "Press enquiry" },
    { id: "general", title: "General enquiry" },
  ],
  googleSearchCountriesMapByCountries: [
    "Netherlands",
    "UK",
    "France",
    "Jersey",
    "Guernsey",
    "Ireland",
    "Isle of Man",
    "Belgium",
    "Luxembourg",
  ],

  mapZoom: {
    default: 12,
    min: 8,
    max: 20,
  },

  googleSearchCountriesMap: ["GB", "FR", "JE", "GG", "IM"],

  helliPadSkipCategories: ["Aerial Point of Interest"],

  minuteStep: 5,

  stripeAmountLimit: 899999.99,

  locationSource: ["google", "hellipaddy", "custom"],

  defaultMapPosition: { lat: 53.2976433, lng: -1.8852428 },

  // contactMapPosition: {lat: 51.5061936, lng: -0.2119999},
  contactMapPosition: { lat: 51.4695, lng: -0.178583 },

  quoteDescriptionMaxLength: 400,
};
