import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fireTracking, findRouteSegment, print, redirectTo } from "../../../../../shared";
import { config } from "../../../../../configs";
import { loadTermCondition, loadTermConditionSuccess } from "../../../../../actions";
import auth from "../../../../../services/auth";
import UploadTerms from "./components/UploadTerms";

const agreements = {
  broker: {
    "code-of-conduct": "BROKER_CODE_CONDUCT",
    "getheli-platform": "BROKER_AGREEMENT",
  },
  operator: {
    "code-of-conduct": "OPERATOR_CODE_CONDUCT",
    "getheli-platform": "OPERATOR_AGREEMENT",
    "flight-term": "OPERATOR_FLIGHT_AGREEMENT",
    "payment-partner": "STRIPE_AGREEMENT",
  },
};

class AgreementViewComponent extends Component {
  constructor(props) {
    super(props);
    fireTracking(props.location.pathname);

    this.state = {
      agreementAlias: "OPERATOR_CODE_CONDUCT",
      redirect: false,
    };
    this.setAgreementAlias = this.setAgreementAlias.bind(this);
  }

  setAgreementAlias(pathname) {
    const alias = findRouteSegment(pathname, 3) ? findRouteSegment(pathname, 3) : "code-of-conduct";
    const state = {
      agreementAlias: agreements[auth.getMainRole()][alias],
    };
    if (!agreements[auth.getMainRole()][alias]) {
      state.redirect = true;
    } else {
      state.redirect = false;
    }
    this.setState(state);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setAgreementAlias(nextProps.location.pathname);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.props.load(
      "STRIPE_AGREEMENT,OPERATOR_CODE_CONDUCT,BROKER_CODE_CONDUCT,OPERATOR_FLIGHT_AGREEMENT,BROKER_FLIGHT_AGREEMENT,OPERATOR_AGREEMENT,BROKER_AGREEMENT",
      this.user.banking.country,
    );
    this.setAgreementAlias(this.props.location.pathname);
  }

  get findAgreement() {
    return this.props.agreements.reduce((prev, next) => {
      if (next.type === this.state.agreementAlias) {
        return next.description;
      }
      return prev;
    }, "");
  }

  get agreement() {
    return this.props.agreements.length > 0 ? this.findAgreement : "";
  }

  get user() {
    return auth.getUser();
  }

  render() {
    const { redirect, agreementAlias } = this.state;

    if (redirect) return redirectTo(config.redirectToAgreements);

    if (agreementAlias === "OPERATOR_FLIGHT_AGREEMENT" && !auth.getHeliServiceTerms())
      return <UploadTerms />;

    return (
      <section className={"agreement-view"}>
        <div className={"uk-text-right"}>
          <a onClick={print.bind(this, this.agreement)} className={"uk-icon gh-print-icon"} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: this.agreement }} />
      </section>
    );
  }
}

AgreementViewComponent.propTypes = {
  location: PropTypes.object,
  load: PropTypes.func,
  agreements: PropTypes.array,
};

AgreementViewComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    agreements: state.termsCondition,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      load: loadTermCondition,
      resetAgreements: loadTermConditionSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(AgreementViewComponent);
export { COMPONENT as AgreementViewComponent };
