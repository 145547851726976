import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useAccountLocalization } from "../../../../../context";
import { utcTime } from "../../../../../shared";

import "./bookingAuditItem.css";

const BookingAuditItem = (props) => {
  const { name, action, date, indexKey } = props;
  const accountLocalization = useAccountLocalization();

  return (
    <div className="gh-broker-booking-details-audit-row" key={indexKey}>
      <div className="gh-broker-booking-details-audit-top-line">
        <div className="gh-broker-booking-details-audit-user-name">{name || ""}</div>
        <div className="gh-broker-booking-details-audit-date">
          {moment(date).format("DD MMMM, YYYY")} |{" "}
          {moment(date).format(accountLocalization.timeFormat) + utcTime()}
        </div>
      </div>
      <div
        className="gh-broker-booking-details-audit-comment"
        dangerouslySetInnerHTML={{ __html: action || "" }}
      />
    </div>
  );
};

BookingAuditItem.propTypes = {
  name: PropTypes.string,
  action: PropTypes.string,
  date: PropTypes.string,
  indexKey: PropTypes.number,
};

export default BookingAuditItem;
