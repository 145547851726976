import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { RouteWithSubRoutes, returnRouteUrl } from "../../../../../shared/";
import { NotificationsListComponent } from "./";

const NotificationsComponent = ({ match, routes }) => {
  return (
    <Switch>
      {routes &&
        routes.map((route, i) => {
          return (
            <RouteWithSubRoutes key={i} title={route.title} {...returnRouteUrl(route, match.url)} />
          );
        })}
      <Route path="/" component={NotificationsListComponent} />
    </Switch>
  );
};
NotificationsComponent.contextTypes = {
  store: PropTypes.object,
};

export { NotificationsComponent };
