import React from "react";
import PropTypes from "prop-types";
import Auth from "../services/auth";

export class ComponentAuthorization extends React.Component {
  render() {
    if (
      Auth.isAuthenticated() &&
      (this.props.roles.includes(Auth.getRole()) || this.props.skipRoleValidation)
    ) {
      return (
        <this.props.component {...this.props.componentProps}>
          {this.props.children}
        </this.props.component>
      );
    }
    return null;
  }
}

ComponentAuthorization.propTypes = {
  roles: PropTypes.array,
  component: PropTypes.func.isRequired,
  skipRoleValidation: PropTypes.bool,
  componentProps: PropTypes.object.isRequired,
};
