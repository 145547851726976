import Axios from "axios";
import { geocodeByAddress, geocodeByPlaceId } from "react-places-autocomplete";
import { config } from "../configs/";

const googleAPI = class GoogleApiService {
  constructor() {}

  getCoordinatesByAddress(address) {
    return new Promise((resolve, reject) => {
      geocodeByAddress(address)
        .then((results) => {
          let [address] = results;
          if (
            address &&
            address.hasOwnProperty("geometry") &&
            address.geometry.hasOwnProperty("location")
          ) {
            return resolve({
              lat: address.geometry.location.lat(),
              lng: address.geometry.location.lng(),
            });
          }
          throw new Error("Could not get location");
        })
        .catch((error) => reject("Error", error));
    });
  }

  getCoordinatesByPlaceId(placeId) {
    return new Promise((resolve, reject) => {
      geocodeByPlaceId(placeId)
        .then((results) => {
          let [address] = results;
          if (
            address &&
            address.hasOwnProperty("geometry") &&
            address.geometry.hasOwnProperty("location")
          ) {
            return resolve({
              lat: address.geometry.location.lat(),
              lng: address.geometry.location.lng(),
            });
          }
          throw new Error("Could not get location");
        })
        .catch((error) => reject("Error", error));
    });
  }

  getPadsByCoordinatesAndRadius() {
    let url = `${config.landingSitesDomain}?region=EUR`;
    // let url = `${
    //   config.apiDomain
    // }/get-local-points-of-interest?coords[latitude]=${lat}&coords[longitude]=${lng}&radius=${Math.round(50)}`;
    // let url = `${config.landingSitesDomain}?coords=${lat},${lng}&radius=${radius}`;
    const headers = {
      Authorization: config.landingSitesApiKey,
    };
    return Axios.get(url, { headers });
  }

  // getPadsByCoordinatesAndRadius({ lat, lng }, radius = 10) {
  //   let url = `${config.landingSitesDomain}?coords[latitude]=${lat}&coords[longitude]=${lng}&radius=${radius}`;
  //   const headers = {
  //     authorization: config.landingSitesApiKey,
  //   };
  //   return Axios.get(url, { headers });
  // }

  getPadsByCoordinatesAndRadiusOld({ lat, lng }, radius = 10) {
    // let url = `${config.landingSitesDomain}?coords=${lat},${lng}&radius=${radius}`;
    let url = `${config.landingSitesDomain}?coords[latitude]=${lat}&coords[longitude]=${lng}&radius=${radius}`;
    const headers = {
      authorization: config.landingSitesApiKey,
    };
    return Axios.get(url, { headers });
  }
};

export default new googleAPI();
