import React, { Component } from "react";
import PropTypes from "prop-types";
import { Control, Errors, Form } from "react-redux-form";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import moment from "moment";
import {
  CustomValidateError,
  isObjectEmpty,
  DateTimeComponent,
  aircraftTitle,
} from "../../../../../shared";
import googleApi from "../../../../../services/google.service";
import { validationRules, config, validationMessages } from "../../../../../configs";
import { addEmptyLeg } from "../../../../../actions";
import staticService from "../../../../../services/static.service";
import eventService from "../../../../../services/event.service";
import {
  LocationAutocompleteWrapper,
  LocationAutocompleteInput,
  LocationAutocompleteList,
} from "../../../../common";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";

const { required } = validationRules;
const modelName = "emptyLegFromBookingModel";

class BookingEmptyLegComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstLeg: {},
      aircrafts: [],
      max: 23,
      time_from_min: "",
      time_to_min: "",
      time_from: "",
      time_to: "",
      isInit: false,
      locationsLoaded: false,
    };
    this.clearDateInput = this.clearDateInput.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.fillCoordinates = this.fillCoordinates.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.emptyLeg) !== JSON.stringify(nextProps.emptyLeg)) {
      this.initForm(nextProps.booking);
    }
    if (this.props.booking !== nextProps.booking) {
      this.initForm(nextProps.booking);
    }
  }

  componentDidMount() {
    this.initForm(this.props.booking);
    eventService.setEvent("locations-loaded", () => {
      const _modelCoord = `${modelName}.to_coords`;
      this.context.store.dispatch(actions.change(_modelCoord, ""));
    });

    const { booking } = this.props;

    // if (booking && booking.legs) {
    //   const updates = [];

    //   for (let i = 0; i < booking.legs.length; i++) {
    //     const { from_location, to_location, from_place_id, to_place_id } = booking.legs[i];

    //     if (from_location && to_location && !from_place_id && !to_place_id) {
    //       updates.push(
    //         {
    //           key: "from",
    //           address: from_location,
    //           index: i,
    //         },
    //         {
    //           key: "to",
    //           address: to_location,
    //           index: i,
    //         },
    //       );
    //     }
    //   }

    //   updates.forEach(({ key, address, index }) => {
    //     setTimeout(() => this.updateLegOnSelect(key, address, index), index);
    //   });
    // }
  }

  componentWillUnmount() {
    eventService.clearEvent("locations-loaded");
  }

  initForm(booking) {
    if (!booking || isObjectEmpty(booking)) return;
    const lastLeg = booking.legs[booking.legs.length - 1];
    let emptyLeg = {
      booking_id: booking.id,
      from_name: lastLeg.to,
      from_source: lastLeg.to_source,
      from_coords: lastLeg.to_coords,
      date: this.momentDate(lastLeg.date),
      from_place_id: lastLeg.to_place_id || (lastLeg.to_location && lastLeg.to_location.place_id),
    };

    if (booking.legs) {
      const totalLegs =
        parseInt(booking.legs.length) > 0
          ? parseInt(booking.legs.length) - 1
          : parseInt(booking.legs.length);
      const lastLeg = booking.legs[totalLegs];
      const hour = lastLeg.departure_time.split(":");
      const to = this.momentTime(lastLeg.departure_time).add(5, "minutes");
      this.setState({
        firstLeg: emptyLeg,
        aircrafts: booking.aircrafts.map((i) => i.id),
        isInit: true,
        time_from_min: parseInt(hour[0]),
        time_to_min: parseInt(hour[0]),
        time_from: this.momentTime(lastLeg.departure_time),
        time_to: to,
      });
      this.context.store.dispatch(actions.change(modelName + ".time_from", lastLeg.departure_time));
      this.context.store.dispatch(
        actions.change(modelName + ".time_to", to.format(config.timeFormat + ":ss")),
      );
    }
  }

  momentDate(date) {
    return moment(date).format("DD MMMM, YYYY");
  }

  momentTime(time) {
    const now = moment().format("YYYY-MM-DD");
    return moment(now + " " + time);
  }

  getTimeToMin(momentTime) {
    if (momentTime.format) {
      const hour = momentTime.format("H");
      return parseInt(hour);
    }
  }

  updateFromModel(modelName, momentValue) {
    const { legs } = this.props.booking;
    const legTime = moment(legs[legs.length - 1].departure_time, "HH:mm:ss");
    if (!momentValue.isAfter(legTime)) {
      momentValue = legTime;
    }
    this.setState({ time_from: momentValue, time_to_min: this.getTimeToMin(momentValue) });
    this.context.store.dispatch(actions.change(modelName, momentValue.format("HH:mm:ss")));
    this.updateToModel("emptyLegFromBookingModel.time_to", moment(momentValue).add(5, "minutes"));
  }

  updateToModel(modelName, momentValue) {
    if (!momentValue.isAfter(this.momentTime(this.props.emptyLegFromBookingModel.time_from))) {
      momentValue = moment(this.state.time_from).add(5, "minutes");
    }
    this.setState({ time_to: momentValue });
    this.context.store.dispatch(actions.change(modelName, momentValue.format("HH:mm:ss")));
  }

  submit(model) {
    const emptyLeg = {
      ...model,
      ...this.state.firstLeg,
      aircraft_id: this.state.aircrafts[0],
    };
    this.context.store.dispatch(addEmptyLeg(emptyLeg, this.props.onSuccess));
  }

  clearDateInput(model, state) {
    this.context.store.dispatch(actions.change(model, this.state.time_from.format("HH:mm:ss")));
    const input = {};
    input[state] = this.state.time_from;
    this.setState(input);
  }

  get minutesRange() {
    if (this.state.time_from && this.state.time_to) {
      const to_hour = this.state.time_from.hours();
      const from_hour = this.state.time_to.hours();
      if (to_hour !== from_hour && this.state.time_from_min !== from_hour) {
        return { min: 0, max: 59, step: config.minuteStep };
      }

      if (to_hour === from_hour) {
        const _moment = moment(this.state.time_from).add(5, "minutes");
        return { min: _moment.minutes(), max: 59, step: config.minuteStep };
      }
    }
    return { min: 0, max: 59, step: config.minuteStep };
  }

  updateGeocodeModel(model, value) {
    const _modelName = `${modelName}.${model}_name`;
    this.context.store.dispatch(actions.change(_modelName, value));
    this.context.store.dispatch(actions.change(`${modelName}.${model}_info`, ""));
    if (value === "") {
      const _modelCoord = `${modelName}.to_coords`;
      this.context.store.dispatch(actions.change(_modelCoord, ""));
    }
  }

  fillCoordinates(modelKey, address) {
    const model = `${modelName}.to_name`;
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateLegOnSelect(modelKey, address);
      });
    } else {
      this.context.store.dispatch(actions.setTouched(model));
    }
  }

  updateLegOnSelect(modelKey, address) {
    const model = { ...this.props.emptyLegFromBookingModel };
    model[`${modelKey}_name`] = address.location || address.name;
    model[`${modelKey}_coords`] = `${address.latitude}|${address.longitude}`;
    model[`${modelKey}_source`] = address.source;
    model[`${modelKey}_info`] = generateLocationAdditionalInfo(address);
    model[`${modelKey}_place_id`] = address.place_id;
    model[`${modelKey}_coverage_status`] = address.coverage_status;
    delete model[`${modelKey}_location`];
    this.context.store.dispatch(actions.change(modelName, model));
  }

  onSelectLocation(handleClickOutside, modelKey, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address);
    } else {
      this.updateLegOnSelect(modelKey, address);
    }
  }

  locationValidation(isValid) {
    if (!isValid) {
      const _modelCoord = `${modelName}.to_coords`;
      this.context.store.dispatch(actions.change(_modelCoord, ""));
    }
  }

  render() {
    const { booking } = this.props;
    if (isObjectEmpty(booking)) {
      return null;
    }
    const { legs } = booking;
    const helisAssigned = booking.aircrafts.map((aircraft, i) => (
      <div key={i} dangerouslySetInnerHTML={{ __html: aircraftTitle(aircraft) }} />
    ));
    const srcPointName = legs.length > 0 ? legs[legs.length - 1].to : "";
    const date = legs.length > 0 ? legs[legs.length - 1].date : "";
    return (
      <div>
        <h3
          className={"uk-h3"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("createEmptyLegTitle") }}
        />

        <Form
          model={modelName}
          onSubmit={this.submit.bind(this)}
          validators={{
            time_from: required,
            time_to: required,
          }}
        >
          <Errors model={".booking_id"} show="touched" wrapper={CustomValidateError} />
          <div className="uk-padding-small">
            <table className="uk-table">
              <tbody>
                <tr>
                  <td>From:</td>
                  <td>{srcPointName}</td>
                </tr>
                <tr>
                  <td>Date:</td>
                  <td>{this.momentDate(date)}</td>
                </tr>
                <tr>
                  <td>Aircraft:</td>
                  <td>{helisAssigned}</td>
                </tr>
                <tr>
                  <td>To:</td>
                  <td>
                    <div className={"uk-position-relative"}>
                      <LocationAutocompleteWrapper validate={this.locationValidation.bind(this)}>
                        {({
                          locations,
                          search,
                          handleClickOutside,
                          loader,
                          onBlurPad,
                          locationValidationTemplate,
                        }) => {
                          return (
                            <div className={"uk-position-relative"}>
                              <LocationAutocompleteInput
                                className={["uk-input"]}
                                placeholder={"Paris"}
                                readOnly={false}
                                value={this.props[modelName].to_name}
                                coverageWarning={this.props[modelName].to_coverage_status}
                                additionalInfo={this.props[modelName].to_info}
                                onSelect={(location) => {
                                  this.updateGeocodeModel("to", location);
                                  return search(location);
                                }}
                                onBlur={onBlurPad}
                                loader={loader}
                                debounce={500}
                                model={`${modelName}.to_name`}
                              />
                              <LocationAutocompleteList
                                onSelect={this.onSelectLocation.bind(
                                  this,
                                  handleClickOutside,
                                  "to",
                                )}
                                locations={locations}
                              />
                              {locationValidationTemplate(
                                `${modelName}.to_coords`,
                                `.to_coords`,
                                "selectDestinationPoint",
                                true,
                              )}
                            </div>
                          );
                        }}
                      </LocationAutocompleteWrapper>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Availability Window:</td>
                </tr>
              </tbody>
            </table>
            <div
              data-uk-grid
              className="uk-child-width-1-2@m uk-child-width-1-1@s uk-margin-remove"
            >
              <div className="uk-padding-small uk-padding-remove-vertical">
                <label dangerouslySetInnerHTML={{ __html: staticService.findByAlias("from") }} />
                <div className={"uk-position-relative"}>
                  <Control
                    value={this.state.time_from}
                    model={`.time_from`}
                    className={"uk-input gh-calendar-input"}
                    component={DateTimeComponent}
                    time={true}
                    controlProps={{
                      dateFormat: false,
                      placeholder: staticService.findByAlias("time"),
                      timeFormat: config.timeFormat,
                      timeConstraints: {
                        hours: { min: this.state.time_from_min, max: this.state.max, step: 1 },
                        minutes: { step: 5 },
                      },
                      showClearIcon: false,
                    }}
                    changeAction={this.updateFromModel.bind(this)}
                    updateOn="change"
                  />
                  <Errors
                    model={".time_from"}
                    show="touched"
                    messages={{
                      required: validationMessages().requiredMessage.bind(
                        null,
                        staticService.findByAlias("from"),
                      ),
                    }}
                    wrapper={CustomValidateError}
                  />
                </div>
              </div>
              <div className="uk-padding-small uk-padding-remove-vertical">
                <label dangerouslySetInnerHTML={{ __html: staticService.findByAlias("to") }} />
                <div className={"uk-position-relative"}>
                  <Control
                    value={this.state.time_to}
                    model={`.time_to`}
                    className={"uk-input gh-calendar-input"}
                    component={DateTimeComponent}
                    time={true}
                    controlProps={{
                      dateFormat: false,
                      timeFormat: config.timeFormat,
                      placeholder: staticService.findByAlias("time"),
                      showClearIcon: false,
                      timeConstraints: {
                        hours: { min: this.state.time_to_min, max: this.state.max, step: 1 },
                        minutes: this.minutesRange,
                      },
                      onClear: (info) => this.clearDateInput(info.name, "time_to"),
                    }}
                    changeAction={this.updateToModel.bind(this)}
                    updateOn="change"
                  />
                  <Errors
                    model={".time_to"}
                    messages={{
                      required: validationMessages().requiredMessage.bind(
                        null,
                        staticService.findByAlias("to"),
                      ),
                    }}
                    wrapper={CustomValidateError}
                    show="touched"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-middle uk-margin-medium-top">
            <Control.button
              model={modelName}
              disabled={{ valid: false }}
              className={"uk-button uk-button-primary uk-align-center uk-button-large"}
            >
              <span
                dangerouslySetInnerHTML={{ __html: staticService.findByAlias("publishEmptyLeg") }}
              />
            </Control.button>
          </div>
        </Form>
      </div>
    );
  }
}

BookingEmptyLegComponent.contextTypes = {
  store: PropTypes.object,
};

BookingEmptyLegComponent.propTypes = {
  booking: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

const mapStateToProps = ({ emptyLeg, emptyLegFromBookingModel }) => ({
  emptyLeg,
  emptyLegFromBookingModel,
});

const COMPONENT = connect(mapStateToProps)(BookingEmptyLegComponent);
export { COMPONENT as BookingEmptyLegComponent };
