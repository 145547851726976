import React from "react";
import PropTypes from "prop-types";

import "./selectComponent.css";

const isUsed = (id, items) => items.find((item) => item.id === id);

const SelectComponent = (props) => {
  const {
    options,
    extraOptions,
    onChange,
    field: { ref, name, value },
    fieldState: { error },
    optionKey,
    disabled,
    selectedAircrafts,
    helicopterLabel,
    selectedValue,
  } = props;
  const selectValueDetermined = selectedValue || selectedValue === 0 ? selectedValue : value;

  return (
    <div className="gh-wrapper-select">
      {/*fix background, after project deps update --gh-white-bg--*/}
      <select
        className="uk-select gh-custom-select"
        name={name}
        value={selectValueDetermined}
        onChange={onChange}
        ref={ref}
        disabled={disabled}
      >
        <option value="--" disabled selected={selectValueDetermined === undefined}>
          --
        </option>

        {options
          .filter(
            (item) =>
              (item.hasOwnProperty("is_verified") && !!item.is_verified) ||
              !item.hasOwnProperty("is_verified"),
          )
          .map((item) => (
            <option
              key={item.id}
              value={item.id}
              disabled={isUsed(item.id, selectedAircrafts || []) || disabled}
            >
              {optionKey === "aircraft_model" ? helicopterLabel(item) : item[optionKey]}
            </option>
          ))}

        {extraOptions?.length >= 1 && (
          <>
            <option disabled className="extra-option">
              --
            </option>
          </>
        )}

        {extraOptions?.length >= 1
          ? extraOptions.map((item) => (
              <option
                key={item.id}
                value={item.id}
                disabled={isUsed(item.id, selectedAircrafts || []) || disabled}
              >
                {optionKey === "aircraft_model" ? item.name : item[optionKey]}
              </option>
            ))
          : null}
      </select>
      <div className="gh-message-error">{error && error.message ? error.message : ""}</div>
    </div>
  );
};

SelectComponent.propTypes = {
  options: PropTypes.array,
  extraOptions: PropTypes.array,
  fieldState: PropTypes.object,
  onChange: PropTypes.func,
  field: PropTypes.object,
  optionKey: PropTypes.string,
  disabled: PropTypes.bool,
  selectedAircrafts: PropTypes.array,
  helicopterLabel: PropTypes.func,
  selectedValue: PropTypes.any,
};

SelectComponent.defaultProps = {
  selectedAircrafts: [],
};

export default SelectComponent;
