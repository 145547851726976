import React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteWithSubRoutes, returnRouteUrl } from "../../../../../shared";
import { BrokerPaymentSummaryComponent } from "./";

const BrokerPaymentComponent = (props) => {
  return (
    <Switch>
      {props.routes.map((route, i) => {
        return (
          <RouteWithSubRoutes
            key={i}
            parentProps={props}
            title={route.title}
            {...returnRouteUrl(route, props.match.url)}
          />
        );
      })}
      <Route
        path="/"
        render={(prop) => <BrokerPaymentSummaryComponent {...prop} parentProps={props} />}
      />
    </Switch>
  );
};

export { BrokerPaymentComponent };
