import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import staticService from "../../../../../../services/static.service";
import generateLocationAdditionalInfo from "../../../../../../utils/generateLocationAdditionalInfo";
import AutocompleteInput from "../../../../../common/AutocompleteInput";
import Button from "../../../../../common/Button";
import { allModels } from "../../../../../../reducers/models";
import { aircraftTitle, DateTimeComponent } from "../../../../../../shared";
import { config } from "../../../../../../configs";
import googleAPI from "../../../../../../services/google.service";
import { useAccountLocalization } from "../../../../../../context";
import SiteComponent from "../../../../../common/SiteComponent";
import Time from "../../../../../common/Time";

import "./bookingEmptyLegForm.css";

const BookingEmptyLegForm = (props) => {
  const { lastLeg, createEmptyLeg, bookingId, aircrafts } = props;
  const accountLocalization = useAccountLocalization();
  const [emptyLegValue, setEmptyLegValue] = useState({
    ...allModels.emptyLegFromBookingModel,
    time_from: "12:00",
    time_to: "12:05",
    booking_id: bookingId,
    aircraft_id: aircrafts[0].id,
    date: moment(lastLeg.date).format(config.dateFormat),
    from_coords: lastLeg.to_coords,
    from_info: "",
    from_name: lastLeg.to_name,
    from_place_id: lastLeg.to_location.place_id,
    from_source: lastLeg.to_source,
  });

  const { handleSubmit, control } = useForm();

  const updateLocationValue = (address) => {
    const newAddress = {
      ...emptyLegValue,
      to_info: generateLocationAdditionalInfo(address),
      to_name: address.location || address.name,
      to_place_id: address.place_id,
      to_source: address.source,
      to_location: address,
    };

    if (address.place_id && (!address.latitude || !address.longitude)) {
      googleAPI.getCoordinatesByPlaceId(address.place_id).then((coordinates) => {
        const googleAddress = { ...address, latitude: coordinates.lat, longitude: coordinates.lng };
        setEmptyLegValue({
          ...newAddress,
          to_info: generateLocationAdditionalInfo(googleAddress),
          to_coords: `${coordinates.lat}|${coordinates.lng}`,
        });
      });
    } else {
      setEmptyLegValue({
        ...newAddress,
        to_coords: `${address.latitude}|${address.longitude}`,
      });
    }
  };

  const updateTimeValue = (e, objKey) => {
    const time = moment(e).format(accountLocalization.timeFormat);
    let startTime = moment(
      objKey === "from" ? time : emptyLegValue.time_from,
      accountLocalization.timeFormat,
    );
    let endTime = moment(
      objKey === "to" ? time : emptyLegValue.time_to,
      accountLocalization.timeFormat,
    );
    // calculate total duration
    let duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    let hours = parseInt(duration.asHours());
    // duration in minutes
    let minutes = parseInt(duration.asMinutes()) % 60;

    if (startTime.hours() === endTime.hours()) {
      if (minutes > 5) {
        setEmptyLegValue({ ...emptyLegValue, [`time_${objKey}`]: time });
      } else if (objKey === "from") {
        // add 5 minutes
        let newTime = moment(e).add(5, "minutes").format(accountLocalization.timeFormat);

        setEmptyLegValue({
          ...emptyLegValue,
          [`time_${objKey}`]: time,
          [`time_${objKey === "from" ? "to" : "from"}`]: newTime,
        });
      } else if (objKey === "to") {
        setEmptyLegValue({ ...emptyLegValue, [`time_${objKey}`]: time });
      }
    } else if (hours > 0) {
      setEmptyLegValue({ ...emptyLegValue, [`time_${objKey}`]: time });
    } else {
      let newTime = moment(e).add(5, "minutes").format(accountLocalization.timeFormat);
      setEmptyLegValue({
        ...emptyLegValue,
        [`time_${objKey}`]: time,
        [`time_${objKey === "from" ? "to" : "from"}`]: newTime,
      });
    }
  };

  const onSubmit = () => {
    createEmptyLeg(emptyLegValue);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="gh-booking-empty-leg-form">
        <div className="gh-booking-empty-leg-form-row">
          <div className="gh-booking-empty-leg-form-key">From</div>
          <div className="gh-booking-empty-leg-form-value">
            <SiteComponent
              icon={lastLeg.to_source}
              name={lastLeg.to_location && lastLeg.to_location.name}
              secondaryText={generateLocationAdditionalInfo(lastLeg.to_location)}
              className="gh-quote-details-flight-site-component gh-payment-leg-row"
            />
          </div>
        </div>
        <div className="gh-booking-empty-leg-form-row">
          <div className="gh-booking-empty-leg-form-key">Date</div>
          <div className="gh-booking-empty-leg-form-value">
            <Time timestamp={lastLeg.departure_time} />
          </div>
        </div>
        <div className="gh-booking-empty-leg-form-row">
          <div className="gh-booking-empty-leg-form-key">Aircraft</div>
          <div className="gh-booking-empty-leg-form-value">
            {aircrafts.map((aircraft, index) => (
              <div key={aircraft.id}>
                {aircraftTitle(aircraft)}
                {index > 0 ? ", " : ""}
              </div>
            ))}
          </div>
        </div>
        <div className="gh-booking-empty-leg-form-row">
          <div className="gh-booking-empty-leg-form-key">To</div>
          <div className="gh-booking-empty-leg-form-value">
            <Controller
              control={control}
              defaultValue={emptyLegValue.to_name}
              name="to"
              rules={{
                required: {
                  value: true,
                  message: `To ${staticService.findByAlias("locationIsRequired")}`,
                },
              }}
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <AutocompleteInput
                  inputRef={ref}
                  onChange={(address) => {
                    onChange(address.location);
                    updateLocationValue(address);
                  }}
                  value={value}
                  coverageWarning={false}
                  fieldState={fieldState}
                  placeholder="Paris"
                  tooltipSide="right"
                  debounce={200}
                  showFlagIcon={false}
                  secondaryInputText={
                    emptyLegValue.to_location
                      ? generateLocationAdditionalInfo(emptyLegValue.to_location) ||
                        emptyLegValue.to_info
                      : ""
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="gh-booking-empty-leg-form-row time-row">
          <div className="gh-booking-empty-leg-form-row-col">
            <Controller
              control={control}
              defaultValue="12:00"
              name="time_from"
              render={() => (
                <div>
                  <label>From</label>
                  <DateTimeComponent
                    className="uk-input gh-calendar-input gh-padding-left-10"
                    attrProps={{ className: "uk-position-relative" }}
                    dateFormat={false}
                    timeFormat={config.timeFormat}
                    showClearIcon={false}
                    hideIcon={true}
                    utcStyle={{ left: 59 }}
                    placeholder={staticService.findByAlias("time")}
                    timeConstraints={{
                      hours: {
                        step: 1,
                      },
                      minutes: {
                        step: config.minuteStep,
                      },
                    }}
                    initialViewMode="time"
                    time={true}
                    onChange={(time) => {
                      updateTimeValue(time, "from");
                    }}
                    value={emptyLegValue.time_from || "12:00"}
                  />
                </div>
              )}
            />
          </div>
          <div className="gh-booking-empty-leg-form-row-col">
            <Controller
              control={control}
              defaultValue="12:05"
              name="time_to"
              render={() => (
                <div>
                  <label>To</label>
                  <DateTimeComponent
                    className="uk-input gh-calendar-input gh-padding-left-10"
                    attrProps={{ className: "uk-position-relative" }}
                    dateFormat={false}
                    timeFormat={config.timeFormat}
                    showClearIcon={false}
                    hideIcon={true}
                    utcStyle={{ left: 59 }}
                    placeholder={staticService.findByAlias("time")}
                    timeConstraints={{
                      hours: {
                        min: parseInt(emptyLegValue.time_from),
                        step: 1,
                      },
                      minutes: {
                        min: moment(emptyLegValue.time_from, accountLocalization.timeFormat)
                          .add(5, "minutes")
                          .minutes(),
                        step: config.minuteStep,
                      },
                    }}
                    initialViewMode="time"
                    time={true}
                    onChange={(time) => {
                      updateTimeValue(time, "to");
                    }}
                    value={emptyLegValue.time_to || "12:05"}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="uk-flex uk-flex-center">
          <Button onClick={handleSubmit(onSubmit)}>
            {staticService.findByAlias("publishEmptyLeg")}
          </Button>
        </div>
      </div>
    </form>
  );
};

BookingEmptyLegForm.propTypes = {
  lastLeg: PropTypes.object,
  createEmptyLeg: PropTypes.func,
  bookingId: PropTypes.number,
  aircrafts: PropTypes.array,
};

export default BookingEmptyLegForm;
