import PropTypes from "prop-types";
import React from "react";
import cs from "../../../../utils/condString";

import "./index.css";

const Select = React.forwardRef((props, ref) => {
  const {
    name,
    value,
    onBlur,
    placeholder,
    onChange,
    readOnly,
    onAfterBlur,
    options,
    onSelect,
    onFocus,
    invalid,
    disabled,
    extraOptions,
  } = props;
  const [bg, setBg] = React.useState();

  React.useEffect(() => {
    if (value) {
      const activeOption = options.find((opt) => opt.value.toString() === value.toString());
      if (activeOption) setBg(activeOption.background);
    }
  }, [value, options]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    onChange(value);

    if (onSelect) onSelect(value);
  };

  const onBlurHandler = (e) => {
    if (onBlur) onBlur(e);
    if (onAfterBlur) onAfterBlur(e);
  };

  return (
    <select
      id={name}
      className={cs("calculator__select", invalid && "pc-invalid")}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      readOnly={readOnly}
      ref={ref}
      name={name}
      value={value}
      onFocus={onFocus}
      style={{ backgroundColor: bg }}
      disabled={disabled}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((opt) => (
        <option key={opt.value} value={opt.value} disabled={opt.disabled}>
          {opt.label}
        </option>
      ))}

      {extraOptions?.length >= 1 && (
        <>
          <option disabled>--</option>
        </>
      )}

      {extraOptions?.length >= 1
        ? extraOptions.map((opt) => (
            <option key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.label}
            </option>
          ))
        : null}
    </select>
  );
});

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onAfterBlur: PropTypes.func,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  onBlur: () => null,
  value: "",
  onFocus: () => null,
  options: [],
};

Select.displayName = "Select";

export { Select };
