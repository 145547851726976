import React from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { SharedComponent } from "../shared.component";

// loads the Icon plugin
UIkit.use(Icons);

class IconComponent extends SharedComponent {
  constructor(props) {
    super(props);
    this.state = {
      icon: props.icon,
      className: props.className,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.icon !== nextProps.icon) {
      this.setState({ icon: nextProps.icon, className: nextProps.className });
    }
  }

  get ratio() {
    return this.props.ratio ? this.props.ratio : 1;
  }

  render() {
    return this.props.visible ? (
      <span
        onClick={this.props.onClick}
        className={this.state.className || "uk-form-icon"}
        id={this.props.id}
        data-uk-icon={`icon: ${this.state.icon};ratio: ${this.ratio}`}
      />
    ) : null;
  }
}

IconComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  ratio: PropTypes.number,
};
export { IconComponent };
