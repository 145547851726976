import React from "react";
import PropTypes from "prop-types";
import { NavWrapper } from "./nav-wrapper.component";

const Header = (props) => {
  document.querySelector("html").removeAttribute("class");
  document.querySelector("html").removeAttribute("style");

  return (
    <header className={props.className || "gh-nav"}>
      <NavWrapper
        activeRoute={props.activeRoute}
        extraProps={props.extraProps}
        pages={props.pages}
        showNav={props.navVisibility}
        test={true}
      />
      <div className="uk-navbar-dropbar uk-navbar-dropbar-slide" />
      {props.children}
    </header>
  );
};

Header.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pages: PropTypes.array,
  extraProps: PropTypes.object,
  className: PropTypes.string,
  navVisibility: PropTypes.bool,
};

export { Header };
