import moment from "moment";
import * as types from "../../actions/actionTypes";
import { explode } from "../../shared";
import { config } from "../../configs";

function activityFeed(state = {}, action) {
  switch (action.type) {
    case types.LOAD_ACTIVITY_FEED:
      return action.activityFeed;
    default:
      return state;
  }
}

function updateRangeDate(currentDate, newDate, type = "start") {
  const dateObject = moment(newDate);
  if (
    (dateObject.isBefore(currentDate) && type === "start") ||
    (dateObject.isAfter(currentDate) && type === "end")
  ) {
    return dateObject.format(config.dobFormat);
  }

  return explode(currentDate, "-", "/");
}

function activityFlights(state = {}, action) {
  switch (action.type) {
    case types.LOAD_ACTIVITY_FLIGHTS:
      const obj = action.activityFlights["range"];
      if (obj) {
        obj.date_start = moment(obj.date_start, "DD/MM/YYYY").format("DD MMMM, YYYY");
        obj.date_end = moment(obj.date_end, "DD/MM/YYYY").format("DD MMMM, YYYY");
      }
      return { ...action.activityFlights, ...obj };
    case types.LOAD_ACTIVITY_FLIGHTS_WITH_PAGINATION:
      const legs = { ...state["empty-legs"], ...action.activityFlights["empty-legs"] };
      let range = { ...state.range };
      const current_range = { ...state.range };
      const new_range = { ...action.activityFlights.range };
      if (new_range.date_start !== "") {
        range.date_start = updateRangeDate(
          explode(current_range.date_start),
          explode(new_range.date_start),
          "start",
        );
        range.date_end = updateRangeDate(
          explode(current_range.date_end),
          explode(new_range.date_end),
          "end",
        );
      }
      return {
        "empty-legs": legs,
        range: range,
      };
    /*    case types.RESET_MODEL:
      return {
        bookings: {from: '', to: ''},
        'empty-legs': {from: '', to: ''},
      };*/
    default:
      return state;
  }
}

export { activityFeed, activityFlights };
