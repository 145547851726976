import React from "react";
import PropTypes from "prop-types";

import "./index.css";

export const TextArea = React.forwardRef((props, ref) => {
  const { name, onChange, value, onBlur, readOnly, placeholder, onFocus } = props;

  return (
    <div className="calculator__input">
      <textarea
        className="pc-input pc-textarea"
        ref={ref}
        id={name}
        name={name}
        value={value || ""}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        autoComplete="new-password"
      />
    </div>
  );
});

TextArea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextArea.defaultProps = {
  value: "",
};

TextArea.displayName = "TextArea";
