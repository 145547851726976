import Api from "../services/api-handler";
import handlerError from "./error";
import * as types from "./actionTypes";
import { staticText } from "../configs/";

const entity = "company";

export function getCompanyDetailsSuccess(details) {
  return { type: types.GET_COMPANY_DETAILS, details };
}

export function getCompanyDetails() {
  return function (dispatch) {
    return Api.setPath(entity, "details")
      .setQueryParams()
      .getAll()
      .then((details) => {
        dispatch(getCompanyDetailsSuccess(details));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getReferrer(onSuccess) {
  return function (dispatch) {
    return Api.setPath(entity, "referrer")
      .setQueryParams({})
      .getAll()
      .then(onSuccess)
      .catch((err) => handlerError(err, dispatch));
  };
}

export function validateReferrer(model, onSuccess, fromPromoCode) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "validateReferrer");
    return Api.setPath(entity, "validate/code")
      .setProperty("skipErrorAlert", true)
      .post(model)
      .then((response) => {
        Api.setProperty("skipErrorAlert", false);
        return onSuccess(response), fromPromoCode(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function sendInvitation(model, onSuccess) {
  return function (dispatch) {
    return Api.setProperty("skipErrorAlert", true)
      .setPath("invitations", "broker")
      .post(model)
      .then((response) => {
        Api.setProperty("skipErrorAlert", false);
        return onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function saveCompanyChanges(model, files, onSuccess) {
  return function (dispatch) {
    // display green notification banner
    // Api.setDispatchAndValidationModel(dispatch, "companyModel").setProperty(
    //   "responseMessages",
    //   staticText.globalMessages.user.verification
    // );
    return Api.setPath(entity, model.id)
      .setProperty("responseMessages", staticText.globalMessages.user.verification)
      .post({ ...model, files })
      .then((res) => {
        onSuccess();
        return res;
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function uploadDocument(file, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "companyFilesModel").setProperty(
      "responseMessages",
      staticText.globalMessages.user.upload,
    );
    const form = new FormData();
    form.append("files[0]", file);
    return Api.setPath("documents")
      .post(form)
      .then((response) => {
        onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function uploadFiles(file, onSuccess, model) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, model).setProperty(
      "responseMessages",
      staticText.globalMessages.user.upload,
    );
    const form = new FormData();
    form.append("files[0]", file);
    return Api.setPath("documents")
      .post(form)
      .then((response) => {
        onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function uploadTemporaryDocument(post) {
  Api.showSpinner = false;
  Api.setProperty("skipErrorAlert", true);
  return function () {
    return Api.setPath("documents", "temporary").post(post);
  };
}

export function removeTemporaryDocument(file) {
  Api.setProperty("skipErrorAlert", true);
  return function () {
    return Api.setPath("documents", "temporary/remove").post(file);
  };
}

export function removeDocument(id, onSuccess) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, "companyFilesModel").setProperty(
      "responseMessages",
      staticText.globalMessages.user.upload,
    );
    return Api.setPath("documents")
      .delete(id)
      .then((response) => {
        onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function removeUploadedDocument(id, onSuccess, model) {
  return function (dispatch) {
    Api.setDispatchAndValidationModel(dispatch, model).setProperty(
      "responseMessages",
      staticText.globalMessages.user.upload,
    );
    return Api.setPath("documents")
      .delete(id)
      .then((response) => {
        onSuccess(response);
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
