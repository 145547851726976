import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, Form, Errors, actions } from "react-redux-form";
import { getRegisterCountries, saveCompanyChanges } from "../../actions";
import { validationRules, validationMessages, RESTRICT_ERROR_MESSAGE } from "../../configs";
import {
  CustomValidateError,
  updateModel,
  prependHttpWithModelUpdate,
  prependHttp,
  ModalComponent,
  alertInstance,
} from "../../shared";
import Auth from "../../services/auth";
import staticService from "../../services/static.service";
import UploadFiles from "./register/upload-files.component";
import PhoneInput from "../common/phoneInput";
import auth from "../../services/auth";
import jumpThroughErrors from "../../utils/jumpThroughErrors";
import isAllowForEditing from "../../utils/isAllowForEditing";
import { showNotification } from "../../utils/showNotification";

const {
  required,
  requiredObject,
  validEmail,
  signupValidUrl,
  phoneInputValidation,
  invalidEmailDomain,
  alphaNumStrictValidation,
  max,
} = validationRules;

class CompanyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelName: "companyModel",
      // website: "",
      changed: false,
      showWarning: false,
      isAocTouched: false,
      selectedFile: {},
      firstTimeLoaded: true,
      fileReader: new FileReader(),
      files: [],
      pages: [],
      currentFile: null,
      documents: [],
      validateEmailField: false,
    };
    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.setValue = this.setValue.bind(this);
    this.updateAOCShared = this.updateAOCShared.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.loadFileReader = this.loadFileReader.bind(this);
    this.sortDocs = this.sortDocs.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.removeLogoFromModel = this.removeLogoFromModel.bind(this);
    this.props.getRegisterCountries(Auth.getMainRole());
    this.state.fileReader.onload = this.loadFileReader.bind(this);
  }

  isAdmin() {
    return (
      Auth.getRole() === "operator_admin" ||
      Auth.getRole() === "broker_admin" ||
      Auth.getRole() === "sole_trader"
    );
  }

  isBroker() {
    return Auth.getMainRole() === "broker";
  }

  componentDidMount() {
    const { companyDetails } = this.props;
    if (companyDetails.documents && companyDetails.documents.length) {
      this.sortDocs(companyDetails.documents);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { companyModel, banking } = this.props;

    if (
      Object.keys(nextProps.companyModel).length &&
      companyModel.documents === undefined &&
      nextProps.companyModel.documents &&
      nextProps.companyModel.documents.length
    ) {
      this.sortDocs(nextProps.companyModel.documents);
    }
    if (nextProps.banking && banking !== nextProps.banking) {
      this.context.store.dispatch(
        actions.change(`${this.state.modelName}.currency`, nextProps.banking.currency),
      );
    }
    if (
      Object.keys(companyModel).length > 1 &&
      JSON.stringify(companyModel) === JSON.stringify(nextProps.companyModel)
    ) {
      this.setState({ firstTimeLoaded: false });
    }

    if (
      !this.state.firstTimeLoaded &&
      JSON.stringify(companyModel) !== JSON.stringify(nextProps.companyModel)
    ) {
      this.setState({ changed: true });
    }
    if (
      Object.keys(companyModel).length &&
      JSON.stringify(companyModel) !== JSON.stringify(nextProps.companyModel) &&
      (companyModel.documents && companyModel.documents.length) !==
        nextProps.certificateFilesModel.length
    ) {
      this.setState({ showWarning: true });
    }
    if (nextProps.companyModel.email && nextProps.companyModel.email.length) {
      this.setState({ validateEmailField: true });
    } else {
      this.setState({ validateEmailField: false });
    }
  }

  openModal(file) {
    this.setState({ selectedFile: file }, () => {
      this.uploadFileHandler(file);
      this.modal.open();
    });
  }

  sortDocs(docs) {
    let certificateList = [];
    if (docs) {
      docs.map((doc) => {
        if (doc.document_type === null || doc.document_type === 1) {
          certificateList.push(doc);
        }
      });
    }
    this.context.store.dispatch(actions.change("certificateFilesModel", certificateList));
  }

  closeModal() {
    this.modal.hide();
    setTimeout(() => {
      this.setState({
        selectedFile: {},
        files: [],
        pages: [],
        currentFile: null,
      });
    }, 500);
  }

  loadFileReader(e) {
    PDFJS.getDocument(new Uint8Array(e.target.result)).then((pdf) => {
      // Hardcoded to match the current viewport
      let scale = 1.5;

      let viewport, canvas, context, renderContext;

      // This is a good example of why handling DOM directly w/React is a bad idea
      // Ideally we just use data and grab context from canvas using something like
      // <canvas ref={(c) => this.context = c.getContext('2d')} />
      // otherwise you need to manually keep track of DOM manipulations
      const pageContainer = this._pageContainer;
      let { pages } = this.state;
      pages.map((page) => pageContainer.removeChild(page));
      pages = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          viewport = page.getViewport(scale);

          // Prepare canvas using PDF page dimensions.
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");

          canvas.height = viewport.height;
          canvas.width = 600;

          // Render PDF page into canvas context.
          renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          pageContainer.appendChild(canvas);
          pages.push(canvas);
        });
      }
      this.setState({ pages });
    });
  }

  loadFile(file) {
    // Quick example of short-circuit evaluation
    file !== this.state.currentFile &&
      (this.setState({ currentFile: file }) || this.state.fileReader.readAsArrayBuffer(file));
  }

  uploadFileHandler(file) {
    const { files } = this.state;
    const blob = new Blob([file]);
    // const file = e.target.files[0];
    files.push(file);
    this.setState({ files });
    this.loadFile(blob);
  }

  get modalContent() {
    const { selectedFile } = this.state;
    if (selectedFile && selectedFile.type && selectedFile.type.includes("image")) {
      return (
        <div className="gh-preview-file">
          <img src={selectedFile.file_data} alt="preview" />
        </div>
      );
    } else {
      if (selectedFile.file_data) {
        return (
          <div className="gh-preview-file">
            {/*<div ref={(c) => (this._pageContainer = c)} />*/}
            <iframe src={selectedFile.file_data} title="preview" className="gh-preview-file-pdf" />
          </div>
        );
      }
    }
  }

  previewFile() {
    return (
      <ModalComponent
        title={this.state.selectedFile.file_name || this.state.selectedFile.name || ""}
        ref={(modal) => (this.modal = modal)}
        id="preview-file-account"
        modalClass="gh-preview-modal"
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={this.closeModal.bind(this)}
      >
        {this.modalContent}
      </ModalComponent>
    );
  }

  updateAOCShared(model, value) {
    if (!this.state.isAocTouched) this.setState({ isAocTouched: true });

    return (dispatch) => {
      dispatch(actions.resetValidity(model));
      dispatch(actions.change(model, value ? 1 : 0));
    };
  }

  successSubmit(details) {
    this.setState({ isAocTouched: false, changed: false });
    alertInstance.eventInstance.trigger("global", {
      visible: true,
      message: staticService.findByAlias("companyInformationUpdated"),
      type: alertInstance.successProp,
    });
    this.uploadedFiles([]);
    this.context.store.dispatch(actions.setUntouched(this.state.modelName));
    this.context.store.dispatch(
      actions.change(`${this.state.modelName}.email`, details.email ? details.email : ""),
    );
    this.forceUpdate();
  }

  removeLogoFromModel() {
    this.context.store.dispatch(actions.change("companyModel.company_logo", []));
    return Promise.resolve();
  }

  submit(model) {
    const _model = { ...model };
    const { certificateFilesModel, submit } = this.props;
    const files = certificateFilesModel.map((doc) => {
      const { file_data, ...file } = doc;
      return file;
    });
    return submit(_model, files, this.successSubmit)
      .then((res) => res)
      .catch((err) => {
        Object.entries(err.data).forEach(([key, value]) => {
          this.context.store.dispatch(
            actions.setErrors(`${this.state.modelName}.${key}`, { [key]: value[0] }),
          );
        });
        jumpThroughErrors();
      });
  }

  setValue(value) {
    this.context.store.dispatch(actions.change(`${this.state.modelName}.website`, value));
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("certificateFilesModel"));
  }

  resetFormValidity() {
    this.context.store.dispatch(actions.resetValidity("companyModel"));
  }

  render() {
    const { companyModel, banking, countries } = this.props;

    const companyCountry = () => {
      const country =
        countries &&
        countries.countries &&
        countries.countries.find((country) => country.code === companyModel.country_code);
      return country && country.name;
    };

    return (
      <Form
        model="companyModel"
        className="uk-form-stacked gh-company-form"
        onBeforeSubmit={this.resetFormValidity.bind(this)}
        validators={{
          name: Auth.getRole() === "sole_trader" ? "" : { required },
          email:
            !this.state.validateEmailField || Auth.getRole() === "sole_trader"
              ? ""
              : { validEmail, invalidEmailDomain },
          phone:
            Auth.getRole() === "sole_trader"
              ? ""
              : { required: requiredObject("value", true), phoneInputValidation },
        }}
        onSubmit={this.submit}
      >
        <div className="uk-width-1-1 uk-margin-small-bottom uk-margin-top">
          {auth.getUser().role.includes("admin") ? (
            <UploadFiles
              handleChange={() => this.setState({ changed: true })}
              model={companyModel.company_logo}
              modelName="companyModel.company_logo"
              uploadTitle="companyLogo"
              docType={1}
              openModal={this.openModal.bind(this)}
              docs={companyModel.company_logo}
              entity="company_document"
              fileFormatAllowed={staticService.findByAlias("pngFormatAllowed")}
              allowed=".png"
              customRemoveFileAction={this.removeLogoFromModel.bind(this)}
              multiple={false}
              onlyOneFile={true}
            />
          ) : (
            ""
          )}
        </div>
        <div className="uk-width-1-1 uk-margin-small-bottom uk-margin-top">
          <label
            className="required-contact uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyName"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".name"}
            disabled={["sole_trader", "operator_team"].includes(Auth.getRole())}
            changeAction={updateModel}
            updateOn="change"
          />
          <Errors
            model=".name"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyName"),
              ),
            }}
          />
        </div>
        <div className={"uk-width-1-1 uk-margin-small-bottom"}>
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyNumber"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".number"}
            changeAction={updateModel}
            updateOn="change"
            validators={
              Auth.getRole() !== "sole_trader" && {
                alphaNumStrictValidation,
                max: max(14),
              }
            }
            disabled={["sole_trader", "operator_team"].includes(Auth.getRole())}
          />
          <Errors
            model=".number"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              alphaNumStrictValidation: validationMessages().alphaNumStrictValidationMessage,
              max: validationMessages().maxMessage(14),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-margin-small-bottom">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyAddress"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".address"}
            disabled={["sole_trader", "operator_team"].includes(Auth.getRole())}
            changeAction={updateModel}
            updateOn="change"
          />
          <Errors
            model=".address"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("companyAddress"),
              ),
            }}
          />
        </div>
        <div className="uk-width-1-1 uk-margin-small-bottom">
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyCountry"),
            }}
          />
          <div className="gh-replaced-field">{companyCountry()}</div>
        </div>
        {Auth.getMainRole() === "operator" ? (
          <div className="uk-width-1-1 uk-margin-small-bottom">
            <label
              className="uk-form-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("companyCurrency"),
              }}
            />
            <div className="gh-replaced-field">
              {banking && banking.currency ? banking.currency : ""}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="uk-width-1-1 uk-margin-small-bottom">
          <label
            className="required-contact uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyPhone"),
            }}
          />
          <Control
            model={".phone"}
            disabled={["sole_trader", "operator_team"].includes(Auth.getRole())}
            validators={{ required: requiredObject("value", true), phoneInputValidation }}
            changeAction={updateModel}
            updateOn="change"
            component={PhoneInput}
          />
          {Auth.getRole() !== "sole_trader" && (
            <Errors
              model=".phone"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("companyPhone"),
                ),
                phoneInputValidation: validationMessages().phoneValidationMessage.bind(
                  null,
                  staticService.findByAlias("directPhoneNumber"),
                ),
              }}
            />
          )}
        </div>
        <div className={"uk-width-1-1 uk-margin-small-bottom"}>
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyEmail"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".email"}
            disabled={!this.isAdmin()}
            changeAction={updateModel}
            updateOn="change"
            validators={
              this.state.validateEmailField
                ? {
                    validEmail,
                    invalidEmailDomain,
                  }
                : {}
            }
          />
          <Errors
            model=".email"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              validEmail: validationMessages().validEmailMessage,
              invalidEmailDomain: validationMessages().invalidEmailDomainMessage,
            }}
          />
        </div>
        <div className={"uk-width-1-1 uk-margin-small-bottom"}>
          <label
            className="uk-form-label"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("companyWebsite"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".website"}
            controlProps={{
              onChange: (event) => {
                return this.setValue(prependHttp(event.target.value));
              },
            }}
            disabled={!this.isAdmin()}
            changeAction={prependHttpWithModelUpdate}
            validators={{ signupValidUrl }}
          />
          <Errors
            model=".website"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              validUrl: validationMessages().validUrlMessage,
            }}
          />
        </div>
        {this.isAdmin() ? (
          <div>
            {Auth.getRole() !== "sole_trader" && (
              <div>
                <h2 className="gh-text-slim uk-margin-remove-bottom uk-margin-large-top">
                  Documents
                </h2>
                <div
                  className="gh-provide-title"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("provideCopies"),
                  }}
                />
                {this.previewFile()}
                <UploadFiles
                  handleChange={() => this.setState({ changed: true })}
                  model={this.props.certificateFilesModel}
                  modelName="certificateFilesModel"
                  uploadTitle="evidenceOfCertificate"
                  docType={1}
                  openModal={this.openModal.bind(this)}
                  docs={this.props.certificateFilesModel}
                  entity="company_document"
                />
              </div>
            )}
            {this.state.showWarning ? (
              <div data-uk-alert className="uk-alert-warning gh-account-restricted gh-without-bg">
                <a className="uk-alert-close gh-alert-close" data-uk-close></a>
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("userChangesPersonalData"),
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <div className="uk-width-1-1 uk-margin-small-bottom">
              <div>
                <div className="uk-flex uk-flex-center uk-margin-small-top uk-margin-remove-right">
                  <Control.button
                    model={this.state.modelName}
                    type={"submit"}
                    disabled={!this.state.changed}
                    className={"uk-button uk-button-primary uk-align-center"}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("update"),
                      }}
                    />
                  </Control.button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Form>
    );
  }

  uploadedFiles(files) {
    this.setState({ files: files });
  }
}

CompanyComponent.propTypes = {
  companyDetails: PropTypes.object,
  companyModel: PropTypes.object,
  banking: PropTypes.object,
  certificateFilesModel: PropTypes.array,
  submit: PropTypes.func,
  getRegisterCountries: PropTypes.func,
  countries: PropTypes.object,
};

CompanyComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({
  companyModel,
  forms,
  certificateFilesModel,
  companyDetails,
  countries,
  dashboard,
}) {
  return {
    companyModel,
    form: forms.companyModel,
    certificateFilesModel: certificateFilesModel,
    companyDetails,
    countries,
    banking: dashboard.banking,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submit: saveCompanyChanges,
      getRegisterCountries,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(CompanyComponent);
export { COMPONENT as CompanyComponent };
