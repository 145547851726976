import * as types from "../../actions/actionTypes";
import auth from "../../services/auth";

function companyDetails(state = {}, action) {
  switch (action.type) {
    case types.GET_COMPANY_DETAILS:
      return { ...action.details };
    default:
      return state;
  }
}

function company(state = {}, action) {
  switch (action.type) {
    case types.SET_COMPANY:
      return { ...action.company };
    case types.LOGIN_SUCCESS:
      return { ...auth.company };
    default:
      return state;
  }
}

export { companyDetails, company };
