import React from "react";
import PropTypes from "prop-types";
import { checkObjectProp } from "../../shared";
import UIkit from "uikit";
import { validationRules } from "../../configs";
import staticService from "../../services/static.service";
import Auth from "../../services/auth";
import { ADD_DISCUSSION_MESSAGE } from "../../actions";
const { required } = validationRules;

class ConfirmComponent extends React.Component {
  closeModal() {
    this.Modal.hide();
  }

  openModal() {
    this.Modal.show();
  }

  onCancel() {
    if (this.props.onReject) return this.props.onReject();
    this.closeModal();
    setTimeout(() => {
      this.props.onClose();
    }, 200);
  }

  onContinue(event) {
    if (this.props.onSelect) return this.props.onSelect(event);
    this.closeModal();
  }

  onView() {
    if (this.props.onView) return this.props.onView();
    this.closeModal();
  }

  onViewButton() {
    if (this.props.onViewButton) return this.props.onViewButton();
    this.closeModal();
  }

  onSubmitButton() {
    if (this.props.onSubmitButton) return this.props.onSubmitButton();
  }

  // onWithdrawButton() {
  //   if (this.props.onWithdrawButton) return this.props.onWithdrawButton();
  // }

  get Modal() {
    return this.modal ? this.modal : null;
  }

  get hasFooter() {
    return checkObjectProp(this.props.buttonsProps, "continue", false) ||
      checkObjectProp(this.props.buttonsProps, "viewButton", false) ||
      checkObjectProp(this.props.buttonsProps, "submitButton", false) ||
      checkObjectProp(this.props.buttonsProps, "cancel", false)
      ? true
      : false;
  }

  get closeBtn() {
    return (
      this.props.closeBtnTemplate || (
        <button
          onClick={this.onCancel.bind(this)}
          className="uk-modal-close-default"
          type="button"
          data-uk-close
        />
      )
    );
  }

  componentDidMount() {
    this.modal = UIkit.modal(
      "#" + checkObjectProp(this.props.modalProps, "id", "confirm-modal"),
      checkObjectProp(this.props.modalProps, "options", {}),
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const discussionContainer = document.getElementById("gh-discussion-container");
    if (discussionContainer) {
      discussionContainer.scrollTop = discussionContainer.scrollHeight;
    }
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: ADD_DISCUSSION_MESSAGE, messages: [] });
    if (this.Modal) {
      this.Modal.$destroy(true);
    }
  }

  render() {
    const { modalProps } = this.props;

    return (
      <div
        id={checkObjectProp(modalProps, "id", "confirm-modal")}
        data-uk-modal={"container: false"}
      >
        <div
          className={`uk-modal-dialog uk-modal-body uk-margin-auto-vertical ${
            modalProps.additionalClass || ""
          }`}
        >
          {this.props.showCloseBtn ? this.closeBtn : null}

          <section>
            <header>
              <h3
                dangerouslySetInnerHTML={{
                  __html: checkObjectProp(this.props.modalProps, "title", ""),
                }}
              />
            </header>
            <div
              className="gh-padding-small-bottom"
              dangerouslySetInnerHTML={{
                __html: checkObjectProp(this.props.modalProps, "warning", ""),
              }}
            />
            {this.props.hasEmptyLegBooking &&
            Auth.getMainRole() === "operator" &&
            this.props.modalProps.title === staticService.findByAlias("cancelBooking") ? (
              <div className="gh-padding-small-bottom">
                <div className="uk-height uk-align-left gh-margin-right-15">
                  <span
                    className="uk-text-danger gh-vertical-middle uk-margin-small-right"
                    data-uk-icon="icon: warning"
                  />
                </div>
                <span
                  className="font-weight-500 gh-vertical-middle"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("modalWarningEmptyLeg"),
                  }}
                />
              </div>
            ) : null}
            {this.props.buttonsProps &&
            this.props.buttonsProps.continue &&
            this.props.buttonsProps.continue.title ===
              staticService.findByAlias("withdrawButton") ? (
              ""
            ) : this.props.rejectRequest &&
              this.props.modalProps &&
              this.props.modalProps.title !== staticService.findByAlias("CancelRequestBooking") &&
              Auth.getMainRole() === "broker" ? (
              <div className="gh-padding-small-bottom ">
                {/*<div className="uk-height uk-align-left gh-margin-bottom-0 gh-margin-right-15"><span className="uk-text-danger gh-vertical-middle uk-margin-small-right" data-uk-icon="icon: warning"></span></div>*/}
                <span
                  className="font-weight-500 gh-vertical-middle"
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("warningBrokerReject"),
                  }}
                />
              </div>
            ) : null}
            {this.props.additionalContent && (
              <div className="gh-padding-small-bottom ">
                <div className="uk-height uk-align-left gh-margin-bottom-0 gh-margin-right-15" />
                <span className="font-weight-500 gh-vertical-middle">
                  {this.props.additionalContent.content}
                </span>
              </div>
            )}
            <div className={"react-modal-content"}>{this.props.info}</div>
            {this.hasFooter ? (
              <div className="uk-modal-footer gh-modal-footer uk-text-center gh-modal-grid">
                {checkObjectProp(this.props.buttonsProps, "cancel", false) ? (
                  <button
                    className={`uk-button uk-modal-close uk-margin-small-top  uk-padding-remove ${checkObjectProp(
                      this.props.buttonsProps.cancel,
                      "css",
                      "uk-button uk-button-default",
                    )}`}
                    onClick={this.onCancel.bind(this)}
                  >
                    {checkObjectProp(this.props.buttonsProps.cancel, "title", "Reject")}
                  </button>
                ) : null}
                {checkObjectProp(this.props.buttonsProps, "continue", false) ? (
                  <button
                    className={`uk-button uk-margin-small-top ${
                      this.props.buttonsProps.continue.closeOnClick && "uk-modal-close"
                    } ${checkObjectProp(
                      this.props.buttonsProps.continue,
                      "css",
                      "uk-button-primary",
                    )}`}
                    onClick={this.onContinue.bind(this)}
                    disabled={this.props.continueDisabled}
                  >
                    {checkObjectProp(this.props.buttonsProps.continue, "title", "Continue")}
                  </button>
                ) : null}
                {checkObjectProp(this.props.buttonsProps, "view", false) ? (
                  <button
                    className={`uk-button uk-modal-close uk-margin-small-top ${checkObjectProp(
                      this.props.buttonsProps.view,
                      "css",
                      "uk-button-secondary",
                    )}`}
                    onClick={this.onView.bind(this)}
                  >
                    {checkObjectProp(this.props.buttonsProps.view, "title", "View")}
                  </button>
                ) : null}
                {checkObjectProp(this.props.buttonsProps, "viewButton", false) &&
                this.props.hasEmptyLegBooking &&
                Auth.getMainRole() === "operator" ? (
                  <button
                    className={`uk-button uk-modal-close uk-margin-small-top uk-padding-remove ${checkObjectProp(
                      this.props.buttonsProps.viewButton,
                      "css",
                      "uk-button-primary",
                    )}`}
                    onClick={this.onViewButton.bind(this)}
                  >
                    {checkObjectProp(
                      this.props.buttonsProps.viewButton,
                      "title",
                      "View Empty Leg ",
                    )}
                  </button>
                ) : null}
                {checkObjectProp(this.props.buttonsProps, "submitButton", false) ? (
                  <button
                    disabled={!this.props.messageValid || this.props.messageValidMax}
                    className={`uk-button uk-modal-close uk-margin-small-top ${checkObjectProp(
                      this.props.buttonsProps.submitButton,
                      "css",
                      "uk-button-secondary",
                    )}`}
                    onClick={this.onSubmitButton.bind(this)}
                  >
                    {checkObjectProp(this.props.buttonsProps.submitButton, "title", "Submit")}
                  </button>
                ) : null}
                {/*{*/}
                {/*  (checkObjectProp(this.props.buttonsProps, 'withdrawButton', false)) ?*/}
                {/*    <button*/}
                {/*      className={`uk-button uk-modal-close uk-margin-small-top ${checkObjectProp(this.props.buttonsProps.withdrawButton, 'css', 'uk-button-secondary')}`}*/}
                {/*      onClick={this.onWithdrawButton.bind(this)}>*/}
                {/*      {checkObjectProp(this.props.buttonsProps.withdrawButton, 'title', 'Withdraw')}*/}
                {/*    </button> : null*/}
                {/*}*/}
              </div>
            ) : null}
          </section>
        </div>
      </div>
    );
  }
}
ConfirmComponent.contextTypes = {
  store: PropTypes.object,
};

ConfirmComponent.defaultProps = {
  modalProps: {},
};

ConfirmComponent.propTypes = {
  showCloseBtn: PropTypes.bool,
  info: PropTypes.any.isRequired,
  hasEmptyLegBooking: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  additionalContent: PropTypes.object,
  rejectRequest: PropTypes.bool,
  onSelect: PropTypes.func,
  onReject: PropTypes.func,
  onView: PropTypes.func,
  buttonsProps: PropTypes.object,
  modalProps: PropTypes.object,
  alertType: PropTypes.string,
  closeBtnTemplate: PropTypes.object,
};

export { ConfirmComponent };
