import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import staticService from "../../../services/static.service";
import condStrings from "../../../utils/condString";
import checkSimpleIcon from "../../../assets/img/svg/check-light.svg";
import checkIcon from "../../../assets/img/svg/check.svg";
import warnIcon from "../../../assets/img/svg/exclamation-circle.svg";
import stripeLogo from "../../../assets/img/svg/logo-stripe-purple.svg";

function SignupProgressCaption(props) {
  const {
    step,
    history,
    location: { hash, pathname },
    order,
    screenInnerW,
    isTeamMember,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const [minWidth, setMinWidth] = useState(58);
  const captionRef = useRef();

  useEffect(() => {
    setIsActive(pathname + hash === step.link);
  }, [pathname, hash]);

  useEffect(() => {
    if (captionRef.current) {
      const iconWidth = 24;
      const textWidth = captionRef.current.querySelector(".name").getBoundingClientRect();

      setMinWidth(iconWidth + textWidth.width);
    }
  }, [isActive]);

  const titleClickHandler = () => {
    if (step.link) {
      if (isTeamMember) {
        UIkit.notification({
          message: staticService.findByAlias("signupProgressTeamMemberWarning"),
          status: "teamMemberWarning",
          pos: "top-right",
          timeout: 5000,
        });
      } else {
        history.push(step.link);
      }
    }
  };

  const captionName = useMemo(() => {
    if (screenInnerW <= 882) {
      if (isActive) {
        if (step.name === "Stripe") return <img src={stripeLogo} alt="Stripe" />;
        return step.name;
      }

      return order;
    }

    if (step.name === "Stripe") return <img src={stripeLogo} alt="Stripe" />;
    return step.name;
  }, [step.name, order, screenInnerW, isActive]);

  const captionIcon = useMemo(() => {
    let check = checkSimpleIcon;

    if (screenInnerW <= 882) {
      check = checkIcon;

      if (isActive) {
        return <span className="order-mobile-circle">{order}</span>;
      }
    }

    if (!step.status) return <img src={warnIcon} alt={step.name} />;
    return <img src={check} alt={step.name} />;
  }, [step.status, screenInnerW, isActive]);

  return (
    <div
      className={condStrings("signup-progress-step", step.name === "Stripe" && "stripe")}
      role="button"
      onClick={titleClickHandler}
    >
      <div
        className={condStrings(
          "signup-progress-step_caption",
          step.status && "completed",
          isActive && "is-active",
        )}
        style={{ "--min-width": minWidth + "px" }}
        ref={captionRef}
      >
        <span className="status">{captionIcon}</span>
        <h5 className="name">{captionName}</h5>
      </div>
    </div>
  );
}

SignupProgressCaption.propTypes = {
  step: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.bool,
    link: PropTypes.string,
  }),
  history: PropTypes.object,
  order: PropTypes.number,
  screenInnerW: PropTypes.number,
  location: PropTypes.object,
  isTeamMember: PropTypes.bool,
};

export default withRouter(SignupProgressCaption);
