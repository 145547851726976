import PropTypes from "prop-types";
import React from "react";
import { Map as GoogleMap } from "google-maps-react";
import apiHandler from "../../../services/api-handler";
import AvailableHomebasesMarkers from "./AvailableHomebasesMarkers";
import { preparePads } from "./AvailableHomebases.utils";
import auth from "../../../services/auth";
import clsx from "clsx";

export const COVERAGE_MAP_MAX_ZOOM = 8;

const AvailableHomebases = ({ isModalOpen, className, width, height, callbackOnMapLoaded }) => {
  const [homebases, setHomebases] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [homebaseInfo, setHomebaseInfo] = React.useState({ visible: false });
  const [isMapReady, setIsMapReady] = React.useState(null);
  const isOperator = auth.isOperator();
  const [mapInstance, setMapInstance] = React.useState(null);

  React.useEffect(() => {
    if (isOperator) return;

    const fetchHomebases = async () => {
      try {
        apiHandler.setProperty("skipErrorAlert", "true");
        const response = await apiHandler.setPath("booking-requests", "coverage-map").getAll();
        setHomebases(preparePads(response));
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    fetchHomebases();
  }, [isOperator]);

  React.useEffect(() => {
    if (isModalOpen === false) {
      setHomebaseInfo({ visible: false });
      mapInstance?.setZoom(4);
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    if (mapInstance) {
      callbackOnMapLoaded();
    }
  }, [mapInstance]);

  if (loading || error) return null;

  return (
    <div className={clsx("uk-margin-top", className)}>
      <GoogleMap
        minZoom={5}
        maxZoom={COVERAGE_MAP_MAX_ZOOM}
        zoom={4}
        initialCenter={{ lat: 46.107928, lng: 8.660525 }}
        mapTypeControl={false}
        streetViewControl={false}
        google={window.google}
        onClick={() => setHomebaseInfo({ visible: false })}
        onReady={(_, map) => setMapInstance(map)}
        containerStyle={{
          position: "relative",
          width,
          height,
        }}
      >
        {mapInstance && (
          <AvailableHomebasesMarkers
            pads={homebases}
            homebaseInfo={homebaseInfo}
            setHomebaseInfo={setHomebaseInfo}
          />
        )}
      </GoogleMap>
    </div>
  );
};

AvailableHomebases.propTypes = {
  isModalOpen: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  callbackOnMapLoaded: PropTypes.func,
};

AvailableHomebases.defaultProps = {
  isModalOpen: false,
  className: "",
  width: "100%",
  height: "680px",
  callbackOnMapLoaded: () => {},
};

export default AvailableHomebases;
