import React from "react";
import PropTypes from "prop-types";
import { Control, Form, Errors } from "react-redux-form";
import { updateModel, CustomValidateError } from "../../../../../shared";
import { validationRules, validationMessages } from "../../../../../configs";
import staticService from "../../../../../services/static.service";

const { required, validEmail } = validationRules;

export class InviteFormComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Form
        model={"userEmailModel"}
        className={"uk-form-stacked"}
        validators={{
          email: { required, validEmail },
        }}
        onSubmit={this.props.submit}
      >
        <div data-uk-grid>
          <div className="uk-width-expand">
            <Control.text
              className={"uk-input uk-input-default"}
              model={".email"}
              type={"email"}
              changeAction={updateModel}
              placeholder={staticService.findByAlias("brokerEmailPlaceholder")}
              updateOn="change"
            />

            <Errors
              model=".email"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(null, "Email"),
                validEmail: validationMessages().validEmailMessage,
              }}
            />
          </div>
          <div className={"uk-width-auto"}>
            <Control.button model={"userEmailModel"} className={"uk-button uk-button-primary"}>
              {staticService.findByAlias("inviteBtn")}
            </Control.button>
          </div>
        </div>
      </Form>
    );
  }
}

InviteFormComponent.propTypes = {
  submit: PropTypes.func.isRequired,
};
