import React from "react";
/*======================*/
//Import main assets firstly
import "./assets/css/theme/index.css";
import "./assets/css/main/app.css";
/*======================*/
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import App from "./app";
import { configStore, storage } from "./shared";
import staticService from "./services/static.service";
import { WINDOW_RESIZE } from "./actions/";
// import "core-js/fn/object/assign";
// import "core-js/fn/promise";
import "core-js-bundle";
import { deleteCookie } from "./utils/deleteCookie";
import Auth from "./services/auth";

const store = configStore();

const wrappedApp = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(
  <div data-uk-spinner="ratio: 3" className={"uk-position-medium uk-position-center"} />,
  document.getElementById("app"),
);

staticService
  .loadItems()
  .then((response) => {
    staticService.setItems(response.data);
    // ReactGA4.initialize(config.gaCode);
    ReactDOM.render(wrappedApp, document.getElementById("app"));
  })
  .catch((err) => {
    if (err && err.response && err.response.status === 401) {
      storage.clearByKeys(["access_token", "token_type"]);
      window.location.href = "/";
    }

    if (err && err.response && err.response.status === 406) {
      localStorage.clear();
      deleteCookie();
      Auth.logout();
      console.log("406 --- 3");
      window.location.reload();
    }
  });

window.addEventListener("resize", (ev) => {
  const newSize = {
    screenW: ev.target.outerWidth,
    screenH: ev.target.outerHeight,
    screenInnerW: ev.target.innerWidth,
    screenInnerH: ev.target.innerHeight * 0.01,
  };
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${newSize.screenInnerH}px`);
  store.dispatch({ type: WINDOW_RESIZE, data: newSize });
});
