import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import flightType1 from "../../../../../assets/img/svg/one.svg";
import flightType2 from "../../../../../assets/img/svg/return.svg";
import flightType3 from "../../../../../assets/img/svg/multi.svg";
import fastQuoteIcon from "../../../../../assets/img/svg/fast_quote.svg";
import fullQuoteIcon from "../../../../../assets/img/svg/full_quote.svg";
import auth from "../../../../../services/auth";
import { OPERATOR_MAIN } from "../../../../../configs";
import { brokerIntentionOptions } from "../request-general-information";
import arrowRight from "../../../../../assets/img/svg/arrow-right.svg";
import { formatNumber } from "../../../../../utils/formatting";
import { connect } from "react-redux";

const flightIcons = [flightType1, flightType2, flightType3];

const brokerIntentionIcons = {
  fast_quote: fastQuoteIcon,
  full_quote: fullQuoteIcon,
};

const ReviewGeneralInformationComponent = ({
  requestForm,
  purposes,
  quoteForm,
  brokerCompany,
  banking,
}) => {
  const flightType = [
    staticService.findByAlias("oneWay"),
    staticService.findByAlias("return"),
    staticService.findByAlias("multiLeg"),
  ];

  return (
    <div
      className="gh-simple-section gh-general-info-section"
      style={{ marginTop: quoteForm ? 0 : 20 }}
    >
      <h4
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("generalInformation"),
        }}
      />
      <div className="gh-simple-section_grid">
        <InfoBrokerSection
          companyName={brokerCompany?.name || staticService.findByAlias("getHeliVettedBrokerLabel")}
          companyUser={!brokerCompany?.behalf_of_status && requestForm?.broker?.full_name}
          show={auth.getMainRole() === OPERATOR_MAIN}
        />
        <div className="gh-gi-card">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("purposeOfFlight"),
              }}
            />
            <div className="gh-info-text">
              {purposes.length && requestForm.flight_purpose && purposes
                ? purposes[Number(requestForm.flight_purpose) - 1].value
                : "---"}
              {requestForm.flight_purpose_event ? (
                <span>
                  {" | "}
                  {requestForm.flight_purpose_event}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="gh-gi-card">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("flightType"),
              }}
            />
            <div className="gh-info-text uk-flex uk-flex-middle">
              <span style={{ width: 28, marginRight: 10 }}>
                <img
                  style={{ width: "100%" }}
                  src={flightIcons[Number(requestForm.flight_type) - 1]}
                  alt="typeIcon"
                />
              </span>
              <span>{flightType[Number(requestForm.flight_type) - 1]}</span>
            </div>
          </div>
        </div>
        <div className="gh-gi-card">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("brokerIntentionLabel"),
              }}
            />

            {requestForm.broker_intention ? (
              <div className="gh-info-text uk-flex uk-flex-middle">
                <span style={{ width: 25, height: 25, marginRight: 10 }}>
                  <img
                    style={{ width: "100%" }}
                    src={brokerIntentionIcons[requestForm.broker_intention]}
                    alt="typeIcon"
                  />
                </span>
                <span>
                  <span>
                    {staticService.findByAlias(
                      brokerIntentionOptions.find(
                        (opt) => opt.type === requestForm.broker_intention,
                      )?.label,
                    )}
                  </span>
                </span>
              </div>
            ) : (
              "---"
            )}
          </div>
        </div>
      </div>

      {requestForm.more_detail_requests && auth.isOperator() ? (
        <div className="gh-simple-section_grid">
          <div className="gh-gi-card">
            <div className="gh-section">
              <h6
                className="gh-field-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("preQuoteFeedback"),
                }}
              />
              <div className="gh-info-text">{requestForm.more_detail_requests.body}</div>

              {requestForm.more_detail_requests.quick_price ? (
                <div className="uk-margin-top uk-margin-small-bottom">
                  <h6
                    className="gh-field-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("quickPriceLabel"),
                    }}
                  />
                  <div className="gh-info-text">
                    <span style={{ marginRight: 3 }}>{banking?.currency}</span>
                    {formatNumber(requestForm.more_detail_requests.quick_price)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {requestForm.more_detail_requests.broker_response ? (
                <div className="gh-info-text">
                  <img src={arrowRight} alt="-->" className="uk-margin-small-right" />
                  {requestForm.more_detail_requests.broker_response}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

ReviewGeneralInformationComponent.propTypes = {
  requestForm: PropTypes.object,
  purposes: PropTypes.array,
  quoteForm: PropTypes.bool,
  brokerCompany: PropTypes.object,
  banking: PropTypes.object,
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(ReviewGeneralInformationComponent);

//Sections
const InfoBrokerSection = ({ companyName, companyUser, show }) => {
  if (!show) return "";

  return (
    <div className="gh-gi-card">
      <div className="gh-section">
        <h6
          className="gh-field-label"
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("inContactWith"),
          }}
        />
        <div className="gh-info-text">{companyName}</div>
        {companyUser && (
          <div className="gh-info-text" style={{ fontSize: 13, lineHeight: 1 }}>
            {companyUser}
          </div>
        )}
      </div>
    </div>
  );
};
InfoBrokerSection.propTypes = {
  show: PropTypes.bool,
  companyName: PropTypes.string,
  companyUser: PropTypes.string,
};
InfoBrokerSection.defaultProps = {
  show: true,
};
