import React from "react";

class SharedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.component = null;
  }

  shouldComponentUpdate() {
    return true;
  }

  componentWillUnmount() {
    if (this.component) {
      this.component.$destroy();
    }
  }

  render() {
    return null;
  }
}

export { SharedComponent };
