import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";
import { actions, Control, Errors } from "react-redux-form";
import { CustomValidateError, updateModel } from "../../../../shared";
import {
  LocationAutocompleteInput,
  LocationAutocompleteList,
  LocationAutocompleteWrapper,
} from "../../../common";
import { config, validationMessages, validationRules } from "../../../../configs";
import googleApi from "../../../../services/google.service";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";
import { showAvailableCountriesModal } from "../../../../actions";
import link from "../../../../assets/img/external-link.svg";
import Button from "../../../common/Button";

const { required } = validationRules;

const radioGroup = [
  {
    type: "1",
    label: "oneWay",
  },
  {
    type: "2",
    label: "return",
  },
  {
    type: "3",
    label: "multiLeg",
  },
];

export const brokerIntentionOptions = [
  {
    type: "fast_quote",
    label: "fastQuote",
  },
  {
    type: "full_quote",
    label: "fullQuote",
  },
];

class RequestGeneralInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputKeywords: "",
      visibleAllLocations: false,
    };
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.updateOnSelect = this.updateOnSelect.bind(this);
    this.selectPurpose = this.selectPurpose.bind(this);
  }

  selectPurpose(model, value) {
    this.context.store.dispatch(actions.change(model, value));
    if (value !== "3") {
      this.context.store.dispatch(actions.reset(`${this.props.modelName}.flight_purpose_event`));
      this.context.store.dispatch(actions.reset(`${this.props.modelName}.flight_purpose_event_id`));
    }
    this.context.store.dispatch(actions.resetErrors(`${this.props.modelName}.broker_intention`));
    this.context.store.dispatch(
      actions.resetErrors(`${this.props.modelName}.additional_information`),
    );
  }

  updateGeocodeModel(model, value) {
    this.setState({ inputKeywords: value });
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
      place_id: value.place_id,
    };
    this.updateOnSelect(address);
  }

  onSelectLocation(handleClickOutside, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(address);
    } else {
      this.updateOnSelect(address);
    }
  }

  fillCoordinates(address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(address);
      });
    }
  }

  updateOnSelect(address) {
    if (address) {
      this.context.store.dispatch(
        actions.change(`${this.props.modelName}.flight_purpose_event_id`, address.place_id),
      );
      this.context.store.dispatch(
        actions.change(`${this.props.modelName}.flight_purpose_event`, address.location),
      );
      this.context.store.dispatch(
        actions.change(
          `${this.props.modelName}.flight_purpose_event_info`,
          generateLocationAdditionalInfo(address),
        ),
      );
    }
    if (this.state.visibleAllLocations) {
      this.setState({ visibleAllLocations: false });
    }
  }

  render() {
    const {
      flight_type,
      flight_purpose,
      generalModel,
      changeFlightType,
      disabledFlightType,
      broker_intention,
    } = this.props;

    return (
      <div className="gh-general-info">
        <h4
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("generalInformation"),
          }}
        />
        <div data-uk-grid>
          <div className="uk-width-1-1 uk-width-3-4@s">
            <div data-uk-grid className="gh-section">
              <div className="uk-width-1-1 uk-padding-remove-left uk-margin-small-bottom">
                <h6
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("purposeOfFlight"),
                  }}
                />
              </div>
              <div className="uk-width-1-1 uk-width-1-2@s uk-padding-remove-left gh-padding-field-right uk-margin-remove">
                <Control.select
                  model=".flight_purpose"
                  className="uk-select gh-white-bg"
                  validators={{ required }}
                  changeAction={this.selectPurpose}
                  defaultValue={"0"}
                >
                  <option value="" selected disabled hidden>
                    {staticService.findByAlias("selectPurpose")}
                  </option>
                  {this.props.purposesList.map((purpose) => (
                    <option key={purpose.key} value={purpose.key}>
                      {purpose.value}
                    </option>
                  ))}
                </Control.select>
                <Errors
                  model=".flight_purpose"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(
                      null,
                      staticService.findByAlias("purposeOfFlight"),
                    ),
                  }}
                />
              </div>
              <div className="uk-width-1-1 uk-width-1-2@s uk-padding-remove-left gh-padding-field-left gh-field-margin">
                <LocationAutocompleteWrapper inputType="events">
                  {({
                    locations,
                    search,
                    handleClickOutside,
                    loader,
                    onBlur,
                    locationValidationTemplate,
                    visibleOverlay,
                  }) => {
                    return (
                      <div className="uk-position-relative">
                        <LocationAutocompleteInput
                          className={["uk-input"]}
                          placeholder={
                            flight_purpose === "3"
                              ? staticService.findByAlias("typeNameOfEvent")
                              : ""
                          }
                          disabled={flight_purpose !== "3"}
                          value={generalModel.flight_purpose_event}
                          additionalInfo={generalModel.flight_purpose_event_info}
                          onSelect={(location) => {
                            this.updateGeocodeModel("flight_purpose_event", location);
                            return search(location, "selectEventErrorMsg");
                          }}
                          onBlur={onBlur}
                          loader={loader}
                          visibleOverlay={visibleOverlay}
                          locations={locations}
                          debounce={500}
                          model={`${this.props.modelName}.flight_purpose_event`}
                        />
                        <LocationAutocompleteList
                          onSelect={this.onSelectLocation.bind(this, handleClickOutside)}
                          locations={locations}
                          visibleOverlay={visibleOverlay}
                          visibleAllLocations={this.state.visibleAllLocations}
                          shortType={true}
                          seeAllFunc={(e) => {
                            this.setState({ visibleAllLocations: true });
                            e.preventDefault();
                            search(this.state.inputKeywords, "selectEventErrorMsg", 1);
                          }}
                        />
                        {flight_purpose === "3" &&
                          !this.props.generalModel.flight_purpose_event_id &&
                          locationValidationTemplate(
                            `${this.props.modelName}.flight_purpose_event_id`,
                            `.flight_purpose_event_id`,
                            "selectEventErrorMsg",
                          )}
                      </div>
                    );
                  }}
                </LocationAutocompleteWrapper>
              </div>
              <div className="uk-width-1-1 uk-width-1-2@m uk-margin-top uk-padding-remove-left">
                <h6
                  style={{ marginBottom: 9 }}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("brokerIntentionLabel"),
                  }}
                />
                <div data-uk-grid className="uk-grid-small">
                  {brokerIntentionOptions.map((radio) => (
                    <label
                      key={radio.type}
                      className={`gh-radio-label ${
                        broker_intention === radio.type ? "active" : ""
                      }`}
                    >
                      <Control.radio
                        model=".broker_intention"
                        className="uk-radio"
                        value={radio.type}
                        changeAction={updateModel}
                        checked={flight_type === radio.type}
                        validators={{ required }}
                      />
                      <span
                        style={{ paddingLeft: 11 }}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias(radio.label),
                        }}
                      />
                    </label>
                  ))}
                </div>
                <Errors
                  model=".broker_intention"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: "Please select your intention",
                  }}
                />
              </div>

              <div className="uk-width-1-1 uk-width-1-2@m uk-margin-top uk-padding-remove-left">
                <div className="gh-see-coverage-map">
                  <h6 style={{ marginBottom: 6 }}>{staticService.findByAlias("operatorsLabel")}</h6>
                  <Button
                    variant="outlined"
                    onClick={() => showAvailableCountriesModal()(this.context.store.dispatch)}
                  >
                    <span>{staticService.findByAlias("seeCoverageMap")}</span>
                    <img className="gh-link-icon" src={link} alt="external-link" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-1-4@s">
            <div className="gh-section gh-second-section">
              <h6
                style={{ marginBottom: 4 }}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("flightType"),
                }}
              />
              <div data-uk-grid className="uk-grid-small gh-flex-box-reverse">
                {radioGroup.map((radio) => (
                  <div style={{ marginTop: 26 }} key={radio.type}>
                    <label
                      className={`gh-radio-label ${flight_type === radio.type ? "active" : ""}`}
                      style={{ cursor: disabledFlightType ? "initial" : "pointer" }}
                    >
                      <Control.radio
                        model=".flight_type"
                        className="uk-radio"
                        changeAction={changeFlightType}
                        value={radio.type}
                        checked={flight_type === radio.type}
                        validators={{ required }}
                        disabled={disabledFlightType}
                      />
                      <span
                        style={{ paddingLeft: 11 }}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias(radio.label),
                        }}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <Errors
              model=".flight_type"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("flightType"),
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

RequestGeneralInformation.propTypes = {
  broker_intention: PropTypes.string,
  flight_type: PropTypes.string,
  flight_purpose: PropTypes.string,
  generalModel: PropTypes.object,
  changeFlightType: PropTypes.func,
  disabledFlightType: PropTypes.bool,
  modelName: PropTypes.string,
  purposesList: PropTypes.array,
};

RequestGeneralInformation.contextTypes = {
  store: PropTypes.object,
};

export default RequestGeneralInformation;
