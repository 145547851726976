import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import { formatNumber } from "../../../shared";
import PriceInputComponent from "../PriceInputComponent";
import ResetBtn from "../ResetBtn";
import staticService from "../../../services/static.service";
import RadioButtonComponent from "../RadioButtonComponent";
import { updateQuoteDetail } from "../../../actions";
import { validationRules } from "../../../configs";
import Tooltip from "../Tooltip";
import InputNumber from "../../Form/components/InputNumber";
import AlternativeBtn from "../AlternativeBtn";
import QuotePriceRepositioning from "./quotePriceRepositioning";
import calculatePercentage from "../../../utils/calculatePercentage";

import "./quotePrice.css";

import closeIcon from "../../../assets/img/svg/closeIcon.svg";
import checkIcon from "../../../assets/img/svg/check.svg";

const includedFees = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

const refundableFees = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
  { label: "Weather Only", value: 2 },
];

const renderRefundableFeeView = (valueKey) => {
  const item = refundableFees.find((item) => item.value === valueKey);

  return (
    <div className="gh-view-price gh-view-price-small">
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={item.value ? checkIcon : closeIcon} alt="check" />
        <span className="gh-text-price">{item.label}</span>
      </div>
    </div>
  );
};

const { min } = validationRules;

const QuotePrice = (props) => {
  const {
    banking,
    editingQuote,
    editingQuote: { quote_price, quote, is_custom_quote_price },
    originalQuote,
    control,
    updateQuoteDetail,
    setError,
    clearErrors,
    readOnly,
    setValue,
    handleChangeTab,
  } = props;
  const [isEditedPrice, setIsEditedPrice] = useState(false);

  useEffect(() => {
    if (readOnly) return;
    if (
      JSON.stringify(editingQuote.quote_price) !== JSON.stringify(editingQuote.quote.quote_price)
    ) {
      setIsEditedPrice(true);
    } else {
      setIsEditedPrice(false);
    }
  }, [editingQuote, originalQuote, readOnly]);

  useEffect(() => {
    // check if the total price was updated manual

    if (quote.quote_price.total === quote_price.total && is_custom_quote_price) {
      return updateQuoteDetail({ ...editingQuote, is_custom_quote_price: false });
    }

    if (quote.quote_price.total !== quote_price.total && !is_custom_quote_price) {
      return updateQuoteDetail({ ...editingQuote, is_custom_quote_price: true });
    }
  }, [editingQuote]);

  const changeTotalPrice = (value) => {
    const price = InputNumber.toNumber(value, { defaultValue: "" });
    // const vat = parseFloat(((price / 100) * banking.vat_value).toFixed(2));

    if ((!price || !min("1", true)(price)) && value) {
      return setError(
        "total",
        { type: "manual", message: `${value} ${staticService.findByAlias("validPriceError")}` },
        { shouldFocus: true },
      );
    } else {
      clearErrors(["total", "vat"]);
    }

    const landing_fees = InputNumber.toNumber(editingQuote.quote_price.landing_fees, {
      defaultValue: "",
    });

    // let numValue = Number(value.replaceAll(",", ""));

    if (landing_fees && value && price > landing_fees) {
      setError("landing_fees", {
        type: "manual",
        message: staticService.findByAlias("landingFeesShouldBeSmallerThanTotal"),
      });
    } else {
      clearErrors("landing_fees");
    }

    setValue("total", value);
    updateQuotePrice({
      total: value,
      vat: "0.00",
      landing_fees: "0.00",
      //  clear deposit value and error
      deposit_amount: {
        percent: "0",
        amount: "0.00",
      },
    });
    clearErrors("secure-with-deposit");
    //  ------------------------------
  };

  const changePrice = (value, key) => {
    if (value) {
      const price = InputNumber.toNumber(value, { defaultValue: "" });
      const total = InputNumber.toNumber(editingQuote.quote_price.total, { defaultValue: "" });

      if (!price || (!min(1, true)(price) && price !== 0)) {
        return setError(
          key,
          { type: "manual", message: `${value} ${staticService.findByAlias("validPriceError")}` },
          { shouldFocus: true },
        );
      } else if (key === "vat" || key === "landing_fees") {
        if (total < price) {
          setError(key, {
            type: "manual",
            message: `${
              key === "vat"
                ? `${staticService.findByAlias("vatShouldBeSmallerThanTotal")}`
                : `${staticService.findByAlias("landingFeesShouldBeSmallerThanTotal")}`
            }`,
          });
        } else {
          clearErrors([key, "total"]);
        }
      } else {
        clearErrors(key);
      }
      setValue(key, value);
    }

    const newQuote = {
      ...editingQuote,
      quote_price: { ...editingQuote.quote_price, [key]: value || "0.00" },
    };
    updateQuoteDetail(newQuote);
  };

  const changeDeposit = (e) => {
    const value = parseInt(e) || "";
    const totalValue = +editingQuote.quote_price.total.replaceAll(",", "");

    if (totalValue === 0) {
      setError("secure-with-deposit", {
        type: "manual",
        message: staticService.findByAlias("QuoteFormDepositErrorWhenTotalPriceIsZero"),
      });
    } else {
      clearErrors("secure-with-deposit");
    }
    if (value > 100 || totalValue === 0) return;

    const newQuote = {
      ...editingQuote,
      quote_price: {
        ...editingQuote.quote_price,
        deposit_amount: {
          percent: value,
          amount: calculatePercentage(value, totalValue),
        },
      },
    };
    updateQuoteDetail(newQuote);
  };

  const changeRadioQuotePrice = (value, key) => {
    const { percent, amount } = editingQuote.quote_price.deposit_amount;

    const newQuote = {
      ...editingQuote,
      quote_price: {
        ...editingQuote.quote_price,
        [key]: value,
        deposit_amount: {
          percent: key === "secure_with_deposit" ? "" : percent,
          amount: key === "secure_with_deposit" ? "0.00" : amount,
        },
      },
    };
    updateQuoteDetail(newQuote);
  };

  const changeRefundableFees = (value) => {
    const newQuote = {
      ...editingQuote,
      quote_price: { ...editingQuote.quote_price, are_additional_landing_fees_refundable: value },
    };
    updateQuoteDetail(newQuote);
  };

  const updateQuotePrice = (value) => {
    const newQuote = { ...editingQuote, quote_price: { ...editingQuote.quote_price, ...value } };
    updateQuoteDetail(newQuote);
  };

  const resetPrice = () => {
    clearErrors(["total", "vat", "landing_fees"]);
    setValue("vat", "0.00", { shouldValidate: true });
    setValue("landing_fees", "0.00", { shouldValidate: true });
    const newQuote = { ...editingQuote, quote_price: editingQuote.quote.quote_price };
    updateQuoteDetail(newQuote);
  };

  return (
    <div className="gh-simple-section gh-quote-price">
      <div className="gh-quote-price-wrapper">
        <div className="gh-quote-price-col">
          <h4 className="title-item">{staticService.findByAlias("quotePriceTitle")}</h4>

          <div className="gh-quote-price-row-wrapper-total">
            <div className="gh-quote-price-row total">
              <div className="gh-quote-price-title total">{staticService.findByAlias("total")}</div>

            {readOnly ? (
              <div className="gh-view-price total">
                <span>{banking ? banking.currency : "GBP"}</span>
                <span>{formatNumber(quote_price.total)}</span>
              </div>
            ) : (
              <Controller
                control={control}
                name="total"
                defaultValue={quote_price.total !== "0.00" ? quote_price.total : ""}
                rules={{
                  required: { value: true, message: staticService.findByAlias("totalError") },
                }}
                render={({ field, fieldState }) => (
                  <PriceInputComponent
                    additionalClass="total"
                    field={field}
                    label={banking ? banking.currency : "GBP"}
                    fieldState={fieldState}
                    value={quote_price.total !== "0.00" ? quote_price.total : ""}
                    showTooltip
                    onEnterValidate
                    onChange={(value) => {
                      field.onChange(value);
                      changeTotalPrice(value);
                    }}
                  />
                )}
              />
            )}
            </div>

            {!readOnly ? (
              <div className="gh-quote-price-actions">
                {isEditedPrice && (
                  <ResetBtn text={staticService.findByAlias("reset")} handleReset={resetPrice} />
                )}

                {editingQuote?.quote?.status === "draft" && editingQuote?.quote?.calculation_id && (
                  <AlternativeBtn
                    text="Automatic Price Settings"
                    handleClick={() => handleChangeTab("calculator", window.location.pathname)}
                  />
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="gh-quote-price-row vat">
            <div className="gh-quote-price-title">
              {staticService.findByAlias("includingTitle")}
            </div>
            {readOnly ? (
              <div className="gh-view-price">
                <span>{banking ? banking.currency : "GBP"}</span>
                <span>{quote_price.vat ? quote_price.vat : "0.00"}</span>
              </div>
            ) : (
              <Controller
                control={control}
                name="vat"
                rules={{
                  validate: {
                    isSmallThanTotal: (value) =>
                      !value ||
                      value === "0.00" ||
                      Number(editingQuote.quote_price.total.replace(/,/g, "")) >
                        Number(value.replace(/,/g, "")) ||
                      `${staticService.findByAlias("vatShouldBeSmallerThanTotal")}`,
                  },
                }}
                render={({ field, fieldState }) => (
                  <PriceInputComponent
                    field={field}
                    label={banking ? banking.currency : "GBP"}
                    fieldState={fieldState}
                    value={quote_price.vat !== "0.00" ? quote_price.vat : ""}
                    onEnterValidate
                    onChange={(value) => {
                      changePrice(value, "vat");
                    }}
                  />
                )}
              />
            )}
            <div className="gh-quote-price-title">{staticService.findByAlias("vatTitle")}</div>
          </div>
          <div className="gh-quote-price-row landing-fees">
            <div className="gh-quote-price-title">
              {staticService.findByAlias("includingTitle")}
            </div>
            {readOnly ? (
              <div className="gh-view-price">
                <span>{banking ? banking.currency : "GBP"}</span>
                <span>{quote_price.landing_fees}</span>
              </div>
            ) : (
              <Controller
                control={control}
                name="landing_fees"
                rules={{
                  validate: {
                    isSmallThanTotal: (value) =>
                      !value ||
                      value === "0.00" ||
                      Number(editingQuote.quote_price.total.replace(/,/g, "")) >
                        Number(value.replace(/,/g, "")) ||
                      `${staticService.findByAlias("landingFeesShouldBeSmallerThanTotal")}`,
                  },
                }}
                render={({ field, fieldState }) => (
                  <PriceInputComponent
                    field={field}
                    label={banking ? banking.currency : "GBP"}
                    fieldState={fieldState}
                    value={quote_price.landing_fees !== "0.00" ? quote_price.landing_fees : ""}
                    onEnterValidate
                    onChange={(value) => {
                      changePrice(value, "landing_fees");
                    }}
                  />
                )}
              />
            )}
            <div className="gh-quote-price-title">
              {staticService.findByAlias("ofLandingFeesTitle")}
            </div>
          </div>

          {quote_price.repositioning_cost &&
          quote_price.repositioning_cost !== "0.00" &&
          !is_custom_quote_price ? (
            <QuotePriceRepositioning percentage={quote_price.repositioning_cost} />
          ) : (
            ""
          )}
        </div>

        <div className="gh-quote-price-col radio">
          <div className="gh-quote-price-row radio">
            <div className="gh-quote-price-title">
              {staticService.findByAlias("allLandingFees")}
            </div>
            <div className="gh-quote-price-title">
              {readOnly ? (
                <div className="gh-view-price gh-view-price-small">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={quote_price.are_landing_fees_included ? checkIcon : closeIcon}
                      alt="check"
                    />
                    <span className="gh-text-price">
                      {quote_price.are_landing_fees_included ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              ) : (
                <RadioButtonComponent
                  selected={quote_price.are_landing_fees_included}
                  items={includedFees}
                  handleChange={(e) => {
                    changeRadioQuotePrice(e, "are_landing_fees_included");
                  }}
                  id="included-fees"
                />
              )}
            </div>
          </div>

          {quote_price.are_landing_fees_included === 0 ? (
            <div className="gh-quote-price-row radio">
              <div className="gh-quote-price-title gh-quote-price-title-addition-landing-fees">
                {staticService.findByAlias("estimateFeesTitle")}
                <div style={{ marginLeft: 15 }}>
                  <Tooltip refEl="self" sign=">" pos="bottom-left">
                    {staticService.findByAlias("estimateFeesTooltip")}
                  </Tooltip>
                </div>
              </div>
              <div className="gh-quote-price-title">
                {readOnly ? (
                  <div className="gh-view-price" style={{ justifyContent: "start" }}>
                    <span>{banking ? banking.currency : "GBP"}</span>
                    <span style={{ marginLeft: 10 }}>{quote_price.additional_landing_fees}</span>
                  </div>
                ) : (
                  <Controller
                    control={control}
                    name="additional_landing_fees"
                    render={({ field, fieldState }) => (
                      <PriceInputComponent
                        field={field}
                        additionalClass="uk-padding-remove-left"
                        label={banking ? banking.currency : "GBP"}
                        fieldState={fieldState}
                        onEnterValidate
                        value={
                          quote_price.additional_landing_fees !== "0.00"
                            ? quote_price.additional_landing_fees
                            : ""
                        }
                        onChange={(value) => {
                          changePrice(value, "additional_landing_fees");
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="gh-quote-price-row radio">
            <div className="gh-quote-price-title">
              {staticService.findByAlias("landingFeesRefundable")}
            </div>
            <div className="gh-quote-price-title">
              {readOnly ? (
                renderRefundableFeeView(quote_price.are_additional_landing_fees_refundable)
              ) : (
                <RadioButtonComponent
                  selected={quote_price.are_additional_landing_fees_refundable}
                  items={refundableFees}
                  handleChange={changeRefundableFees}
                  id="refundable-fees"
                />
              )}
            </div>
          </div>

          <div className="gh-quote-price-row radio" style={{ backgroundColor: "#ffffff" }}>
            <div className="gh-quote-price-title">
              {staticService.findByAlias("secureWithDepositLabel")}
              <div style={{ marginLeft: 15 }}>
                <Tooltip refEl="self" sign=">" pos="bottom-left">
                  {staticService.findByAlias("secureWithDepositTooltip")}
                </Tooltip>
              </div>
            </div>
            <div className="gh-quote-price-title">
              {readOnly ? (
                <div className="gh-view-price gh-view-price-small">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={quote_price.secure_with_deposit ? checkIcon : closeIcon}
                      alt="check"
                    />
                    <span className="gh-text-price">
                      {quote_price.secure_with_deposit ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              ) : (
                <RadioButtonComponent
                  selected={quote_price.secure_with_deposit}
                  items={includedFees}
                  handleChange={(e) => {
                    changeRadioQuotePrice(e, "secure_with_deposit");
                  }}
                  id="secure-with-deposit"
                />
              )}
            </div>
          </div>

          <div
            className={`gh-quote-price-row gh-deposit-row radio ${
              !quote_price.secure_with_deposit ? "--hide" : ""
            }`}
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="gh-quote-price-title">
              {staticService.findByAlias("depositAmountLabel")}
            </div>

            <div className="gh-quote-price-title gh-deposit-amount">
              {readOnly ? (
                <div className="gh-view-percent uk-margin-small-right uk-margin-small-left">
                  {quote_price.deposit_amount.percent}%
                </div>
              ) : (
                <Controller
                  control={control}
                  name="secure-with-deposit"
                  render={({ field, fieldState }) => (
                    <PriceInputComponent
                      type="number"
                      field={field}
                      placeholder="0"
                      label="%"
                      fieldState={fieldState}
                      value={quote_price.deposit_amount.percent}
                      onChange={changeDeposit}
                    />
                  )}
                />
              )}

              <div>
                {readOnly ? "[" : ""}
                {banking ? banking.currency : "GBP"}
                <span className="uk-margin-small-left">
                  {formatNumber(quote_price.deposit_amount.amount)}
                </span>
                {readOnly ? "]" : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  banking: dashboard.banking,
});

QuotePrice.propTypes = {
  banking: PropTypes.object,
  editingQuote: PropTypes.object,
  originalQuote: PropTypes.object,
  control: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  handleChangeTab: PropTypes.func,
};

export default connect(mapStateToProps, { updateQuoteDetail })(QuotePrice);
