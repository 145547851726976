import "./brokerClientReference.css";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { Autocomplete } from "../";
import { Form } from "../../";
import staticService from "../../../services/static.service";
import { useForm } from "react-hook-form";
import apiHandler from "../../../services/api-handler";
import { addClient, updateClient } from "../../../actions";
import * as yup from "yup";
import useYupResolver from "../../../hooks/useYupResolver";
import PhoneInput from "../phoneInput";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Input from "../../Form/components/Input";
import Button from "../Button";
import externalLinkSVG from "../../../assets/img/external-link.svg";
import { Link } from "react-router-dom";
import ErrorMessageComponent from "../ErrorMessageComponent";
import clsx from "clsx";

const clientIdentifierShema = yup.object().shape({
  email: yup.string().email(({ value }) => `${value} is not a valid email`),
  // phone: yup.mixed().test("phone", "Invalid phone number", isValidPhone),
});

const stringYup = yup.string().max(20, "Must be 20 characters or less");

function BrokerClientReference(props) {
  const { afterSubmit, setSubmitCb, defaultReferenceId, onReferenceChange, resetFormValidity } =
    props;
  const [identifier, setIdentifier] = React.useState("");
  const [referenceId, setReferenceId] = React.useState(defaultReferenceId);
  const [isFormVisible, setIsFormVisible] = React.useState(false);
  const [autocompleteError, setAutocompleteError] = React.useState(null);

  const autocompleteRef = React.useRef(null);

  useEffect(() => {
    if (!identifier) {
      resetForm();
    }
  }, [identifier]);

  const form = useForm({
    resolver: useYupResolver(clientIdentifierShema),
    mode: "onBlur",
  });

  const resetForm = useCallback(() => {
    form.reset({ first_name: "", last_name: "", email: "", phone: "" });
    onReferenceChange("");
    setReferenceId("");
  }, [form, onReferenceChange, setReferenceId]);

  const setValueMiddleware = async (value) => {
    const message = await stringYup.validate(value).catch((error) => error);
    if (message?.message) {
      setAutocompleteError(message.message);
    } else {
      setAutocompleteError(null);
    }

    setIdentifier(value);
  };

  const onSubmit = React.useCallback(
    async (values) => {
      if (autocompleteError) return;
      const data = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value ? value : null]),
      );

      apiHandler.setProperty("skipErrorAlert", true).setProperty("alertModal", true);

      data.client_identifier = identifier;
      data.id = referenceId;

      const callback = referenceId ? props.updateClient : props.addClient;

      callback(data)
        .then((res) => {
          resetFormValidity();
          if (res.data) {
            onReferenceChange(res.data.id);
            setReferenceId(res.data.id);
          } else {
            onReferenceChange(res.id);
            setReferenceId(res.id);
          }
          afterSubmit();
        })
        .catch((err) => {
          if (err.status === 422) {
            Object.keys(err.data.errors).forEach((key) => {
              form.setError(key, {
                type: "manual",
                message: err.data.errors[key][0],
              });
            });
          }
        });
    },
    [afterSubmit, identifier, referenceId],
  );

  const onSelectReference = useCallback(
    (reference) => {
      form.setValue("first_name", reference.first_name || "");
      form.setValue("last_name", reference.last_name || "");
      form.setValue("email", reference.email || "");
      form.setValue("phone", reference.phone?.value ? reference.phone : "");
      setIdentifier(reference.client_identifier);
      onReferenceChange(reference.id);
      setReferenceId(reference.id);
    },
    [form, onReferenceChange],
  );

  const handleAddNewClient = useCallback(() => {
    autocompleteRef?.current.setOptionsVisible(false);
    setIsFormVisible(true);
    setIdentifier("");
    setAutocompleteError(null);
  }, [setIsFormVisible]);

  React.useEffect(() => {
    const fetchReference = async (id) => {
      const res = await apiHandler.setPath("clients").get(id);

      if (res) {
        onSelectReference(res);
        setIsFormVisible(true);
      }
    };

    if (defaultReferenceId) {
      fetchReference(defaultReferenceId);
    }
  }, [defaultReferenceId]);

  React.useEffect(() => {
    if (setSubmitCb) {
      setSubmitCb(() => form.handleSubmit(onSubmit)());
    }
  }, [setSubmitCb, onSubmit]);

  const searchReferenceHandler = React.useCallback(async (value) => {
    const res = await apiHandler
      .setPath("clients", "search")
      .setQueryParams([{ key: "keyword", value }])
      .getAll();

    if (!res || res?.length === 0) {
      autocompleteRef?.current.setOptionsVisible(true);
    }

    return Promise.resolve(res);
  }, []);

  const hideAddNewButton = React.useMemo(
    () => isFormVisible && identifier?.length > 0,
    [isFormVisible, identifier],
  );

  return (
    <React.Fragment>
      <div className="gh-broker-client-reference-autocomplete-wrapper uk-width-1-1 uk-width-1-2@s ">
        <Autocomplete
          placeholder={staticService.findByAlias("searchByNamePhoneEmail")}
          name="identifier"
          className="gh-broker-client-reference-autocomplete"
          optionsPosition="top"
          endpoint={searchReferenceHandler}
          onSelect={onSelectReference}
          value={identifier}
          setValue={setValueMiddleware}
          autocompleteRef={autocompleteRef}
          manualHideOptions={!hideAddNewButton}
          config={{
            propName: "client_identifier",
            additionalRender: (reference) => reference.email || reference.phone?.value || "",
            additionalLastItem: (
              <Button
                className={clsx("gh-broker-client-reference-add-new-btn", {
                  "uk-hidden": hideAddNewButton,
                })}
                type="button"
                color="light"
                variant="text"
                onClick={handleAddNewClient}
              >
                + Add New
              </Button>
            ),
          }}
          label={
            <div className="uk-flex">
              <Link to="/clients" target="_blank" className="uk-form-label pointer">
                <label className="pointer">
                  {staticService.findByAlias("clientIdentifier")}
                  <img src={externalLinkSVG} alt="link" className="gh-external-link-svg" />
                </label>
              </Link>
            </div>
          }
        />
        <ErrorMessageComponent
          visibility={!!autocompleteError}
          className="gh-broker-client-reference-autocomplete-error"
        >
          {autocompleteError || ""}
        </ErrorMessageComponent>
      </div>
      {isFormVisible && (
        <div className="broker-client-reference-inputs uk-margin-top uk-width-1-1">
          <Form form={form}>
            <div data-uk-grid className="uk-form-controls">
              <Form.Item
                className="uk-width-1-1 uk-width-1-2@s"
                label="First Name"
                name="first_name"
              >
                <Input placeholder="Client First Name" />
              </Form.Item>
              <Form.Item className="uk-width-1-1 uk-width-1-2@s" label="Last Name" name="last_name">
                <Input placeholder="Client Last Name" />
              </Form.Item>
              <Form.Item
                className="uk-width-1-1 uk-width-1-2@s uk-margin-remove"
                label="Email"
                name="email"
              >
                <Input placeholder="Client Email Address" />
              </Form.Item>
              <Form.Item
                className="uk-width-1-1 uk-width-1-2@s uk-margin-remove"
                label="Phone"
                name="phone"
              >
                <PhoneInput countryCodeEditable={false} />
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </React.Fragment>
  );
}

BrokerClientReference.propTypes = {
  defaultReferenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  afterSubmit: PropTypes.func,
  setSubmitCb: PropTypes.func,
  onReferenceChange: PropTypes.func,
  resetFormValidity: PropTypes.func,
  addClient: PropTypes.func,
  updateClient: PropTypes.func,
};

BrokerClientReference.defaultProps = {
  afterSubmit: () => null,
  onReferenceChange: () => null,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addClient,
      updateClient,
    },
    dispatch,
  );
};

const COMPONENT = connect(() => ({}), mapDispatchToProps)(BrokerClientReference);
export default COMPONENT;
