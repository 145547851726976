import * as types from "../../actions/actionTypes";

function requestDraft(state = {}, action) {
  switch (action.type) {
    case types.GET_REQUEST_DRAFT:
      return state;
    case types.UPDATE_REQUEST_DRAFT:
      return state;
    case types.CLEAN_REQUEST_DRAFT:
      return {};
    default:
      return state;
  }
}

export { requestDraft };
