import React, { useState } from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";

import "./bookingPrivateNotes.css";

const BookingPrivateNotes = (props) => {
  const { privateNotes, handleSavePrivateNotes } = props;
  const [visibleButtons, setVisibleButtons] = useState(false);
  const [inputValue, setInputValue] = useState(privateNotes || "");

  const handleChangeInputValue = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="gh-booking-private-notes">
      <div className="gh-booking-info-bar-key">{staticService.findByAlias("yourPrivateNotes")}</div>
      <div className="gh-booking-private-notes-wrapper">
        <textarea
          value={inputValue}
          onChange={handleChangeInputValue}
          type="text"
          className="gh-booking-private-notes-input"
          onBlur={() => {
            setTimeout(() => {
              setVisibleButtons(false);
            }, 200);
          }}
          onFocus={() => {
            setVisibleButtons(true);
          }}
        />

        <div className={`gh-booking-private-notes-buttons ${visibleButtons ? "visible" : ""}`}>
          <button
            uk-icon="icon: check"
            className="gh-booking-info-bar-identifier-btn btn-style"
            style={{ color: "#1abc9c" }}
            onClick={() => {
              handleSavePrivateNotes(inputValue);
            }}
          />

          <button
            uk-icon="icon: close"
            className="gh-booking-info-bar-identifier-btn btn-style"
            style={{ color: "#e74c3c" }}
            onClick={() => {
              setVisibleButtons(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

BookingPrivateNotes.defaultProps = {
  handleSavePrivateNotes: () => {},
};

BookingPrivateNotes.propTypes = {
  privateNotes: PropTypes.string,
  handleSavePrivateNotes: PropTypes.func,
};

export default BookingPrivateNotes;
