import React from "react";
import PropTypes from "prop-types";

const TextareaHookForm = (props) => {
  const {
    additionalClass,
    field: { name, ref },
    onChange,
    value,
    fieldState: { error },
    label,
    placeholder,
  } = props;

  return (
    <div>
      {label ? <label>{label}</label> : ""}
      <textarea
        additionalClass
        name={name}
        ref={ref}
        placeholder={placeholder}
        className={`gh-text-area ${additionalClass}`}
        value={value}
        onChange={onChange}
      />
      <div className="gh-message-error">{error && error.message ? error.message : ""}</div>
    </div>
  );
};

TextareaHookForm.propTypes = {
  additionalClass: PropTypes.string,
  field: PropTypes.object,
  fieldState: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextareaHookForm;
