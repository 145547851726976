import React, { useMemo } from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";

import "./operatorCompanyBanner.css";
import moment from "moment";
import { config } from "../../../configs";

export default function OperatorCompanyBanner(props) {
  const { operatorCompany, absolute, wrapperClassName, expirationTime, index } = props;
  const isQuoteExpired = useMemo(
    () => (expirationTime ? moment().isAfter(expirationTime) : null),
    [expirationTime],
  );

  return (
    <div
      className={`gh-quote-card_operator-company ${wrapperClassName} ${absolute ? "absolute" : ""}`}
    >
      {expirationTime ? (
        <div className="gh-expiration-time-on-banner">
          {staticService.findByAlias(isQuoteExpired ? "quoteExpiredOn" : "quoteExpiresOn")}
          <span>{moment(expirationTime).format(config.dateFormat)}</span>
          <span>at</span>
          <span>{moment(expirationTime).format(config.timeFormat)}</span>
          <span>
            (UTC
            {moment(expirationTime).format("Z")})
          </span>
        </div>
      ) : (
        ""
      )}

      {Object.keys(operatorCompany).length
        ? operatorCompany.name
        : staticService.findByAlias("operator") + " " + `${index + 1}`}
    </div>
  );
}

OperatorCompanyBanner.propTypes = {
  operatorCompany: PropTypes.object,
  absolute: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  expirationTime: PropTypes.object,
  index: PropTypes.number,
};

OperatorCompanyBanner.defaultProps = {
  operatorCompany: {},
  wrapperClassName: "",
  index: 0,
};
