import { config } from "../configs";
import auth from "../services/auth";
import { deployment_version } from "../../env/env";

/**
 *
 * @param {Object} address
 * @param {Array<Number>} address.location
 * @param {(String|Number)} address.place_id
 * @param {String} [address.source]
 * @param {String} [address.secondary_text]
 * @returns {(Boolean|Object)}
 */
export default async function checkCoverageStatus(address) {
  const { location, place_id } = address;

  if (!location || !location.length || !place_id) return false;

  const URL = `${config.apiDomain}/check-location-coverage`;
  const headers = {
    authorization: `Bearer ${auth.getFromLocalStorage("access_token")}`,
    Accept: "application/json, */*",
    "Content-Type": "application/json",
    version: deployment_version || 1,
  };

  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(URL, { method: "POST", headers, body: JSON.stringify(address) });
    return Promise.resolve(response.message);
  } catch (error) {
    return Promise.reject(error);
  }
}
