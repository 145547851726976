import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions, Form } from "react-redux-form";
import moment from "moment";
import { BlockComponent } from "../../../../common";
import { config, RESTRICT_ERROR_MESSAGE } from "../../../../../configs";
import {
  editRequest,
  getEmptyLegDetailsSuccess,
  getPurposes,
  REQUEST_UPDATED,
  sendOnReviewRequest,
  showAvailableCountriesModal,
} from "../../../../../actions";
import {
  storage,
  fireTracking,
  getAfterDate,
  updateModel,
  distanceBetween,
  parseCoordinates,
  legTitle,
} from "../../../../../shared/";
import { LegExtraFieldset } from "../../../../forms/fieldsets";
import { LegFormComponent } from "../../../../forms";
import { IsVerifiedPermission } from "../../../../";
import staticService from "../../../../../services/static.service";
import RequestGeneralInformation from "./../request-general-information";
import { getEmptyLegInfo, getMarketPlaceEmptyLegs } from "../../../../../actions";
import BackLink from "../../../../common/backlink";
import BrokerClientReference from "../../../../common/BrokerClientReference";
import Tooltip from "../../../../common/Tooltip";
import Upload from "../../../../Form/components/Upload";
import isAllowForEditing from "../../../../../utils/isAllowForEditing";
import { showNotification } from "../../../../../utils/showNotification";
import link from "../../../../../assets/img/external-link.svg";

const modelName = "requestFromEmptyLegModel";

const defaultLegData = {
  departure_time: moment(),
  date: moment().add(1, "day").format(config.dateFormat),
  date_type: "1",
  time_type: "1",
  from_location: {},
  from: "",
  to: "",
  from_coords: "",
  passengers: {
    passengers_amount_type: "1",
    men: 0,
    women: 0,
    children: 0,
    total: 0,
  },
  luggage: {
    hand_bags: 0,
    carry_on_bags: 0,
  },
  special_items: {
    bicycles: 0,
    golf_club_sets: 0,
    hunting_weapons: 0,
    pets: 0,
    ski_pairs: 0,
    other: "",
  },
  to_location: {},
  to_coords: "",
  number_adults: "0",
  number_children: "0",
  from_source: "",
  to_source: "",
  from_is_private: false,
  to_is_private: false,
  accordionsOpen: false,
  submitClientReference: () => null,
};

class BrokerEmptyLegDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.validateLocations = this.validateLocations.bind(this);
    this.parseFormLegs = this.parseFormLegs.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.sumDistance = this.sumDistance.bind(this);
    this.distanceToTime = this.distanceToTime.bind(this);
    this.setInitialData = this.setInitialData.bind(this);
    this.viewRequest = this.viewRequest.bind(this);
    this.updateStorage = this.updateStorage.bind(this);
    this.updateStorageFromResponse = this.updateStorageFromResponse.bind(this);
    this.updateTextArea = this.updateTextArea.bind(this);
    this.updateClientReferenceHanlder = this.updateClientReferenceHanlder.bind(this);
    this.clientReferenceAfterSubmit = this.clientReferenceAfterSubmit.bind(this);
    this.updateRequestReferenceId = this.updateRequestReferenceId.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.jumpThroughErrors = this.jumpThroughErrors.bind(this);
    this.state = {
      selectedLocation: { lat: 51.5073509, lng: -0.12775829999998223 },
      disableAddLeg: false,
      allDistance: 0,
      currentDistance: [],
      sum: [],
      currentDate: moment(),
      oldRequest: props.editedRequest,
      confirmData: {
        title: staticService.findByAlias("confirmRequestAlertModalTitle"),
        content: staticService.findByAlias("requestSuccess"),
        status: 0,
      },
      oldRequestId: props.editedRequest.id,
      purposesList: [],
      emptyLegDetails: {},
      areaRows: 4,
      minRows: 4,
      maxRows: 20,
      legsOpen: true,
      accordionsOpen: true,
    };
  }

  get defaultTime() {
    const m = moment().add(1, "day");
    m.hour(12).minute(0);
    return m;
  }

  componentDidMount() {
    this.props.getEmptyLegInfo(this.props.match.params.id).then((res) => {
      const { emptyLegDetails: emptyLeg } = this.props;
      this.props.getPurposes().then((purposes) => this.setState({ purposesList: purposes }));
      const leg = {
        ...defaultLegData,
        ...emptyLeg,
        departure_time: emptyLeg.departure,
      };
      this.initForm("1", [leg], true);
      fireTracking(this.props.location.pathname);
    });
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.emptyLegDetails !== nextProps.emptyLegDetails) {
      this.setState({ emptyLegDetails: nextProps.emptyLegDetails });
      this.setInitialData({ ...defaultLegData, ...nextProps.emptyLegDetails });
    }
  }

  updateRange(modelName, momentValue) {
    const storageModel = JSON.parse(storage.get("request_form"));
    const { time_interval } = storageModel.legs[0];

    let nextTimeValue = momentValue.format("HH:mm");

    if (time_interval) {
      const minTime = moment(time_interval[0]).format("HH:mm");
      const maxTime = moment(time_interval[1]).format("HH:mm");

      const momentMinTime = moment(minTime, "HH:mm");
      const momentMaxTime = moment(maxTime, "HH:mm");

      if (momentValue.isSameOrBefore(momentMinTime)) {
        nextTimeValue = minTime;
      } else if (momentValue.isSameOrAfter(momentMaxTime)) {
        nextTimeValue = maxTime;
      }
    }

    this.context.store.dispatch(actions.change(modelName, nextTimeValue));
    this.setState({ selectedTime: moment(nextTimeValue, "HH:mm") });
  }

  setInitialData(emptyLegDetails) {
    if (emptyLegDetails) {
      const min = emptyLegDetails.time_interval[0].split(":");
      const max = emptyLegDetails.time_interval[1].split(":");
      const now = moment().format(config.dobFormat);
      const modelObj = {
        ...this.props.modelRequest,
        legs: [
          {
            ...emptyLegDetails,
            departure_time: emptyLegDetails.departure,
            min: parseInt(min[0]),
            max: parseInt(max[0]),
            minMin: parseInt(min[1]),
            maxMin: parseInt(max[1]),
            timeTo: moment(now + " " + emptyLegDetails.time_interval[1]),
            timeFrom: moment(now + " " + emptyLegDetails.time_interval[0]),
            selectedTime: moment(now + " " + emptyLegDetails.time_interval[0]),
          },
        ],
      };
      this.context.store.dispatch(actions.change(modelName, modelObj));
      storage.add("request_form", JSON.stringify(modelObj));
    }
  }

  initFormState(flight_type = "1", initLegs = []) {
    let { oldRequest } = this.state;
    if (oldRequest && Object.keys(oldRequest).length > 0) {
      delete oldRequest.id;
      oldRequest = JSON.parse(JSON.stringify(oldRequest));
      this.sumDistance(oldRequest.legs);
      oldRequest.legs.map((leg) => {
        if (leg.from_source === "hellipaddy") {
          leg.from = leg.from.split(",")[0];
        }
        if (leg.to_source === "hellipaddy") {
          leg.to = leg.to.split(",")[0];
        }
        return {
          ...leg,
          local_points: true,
          passengers: {
            ...leg.passengers,
            passengers_amount_type: `${leg.passengers.passengers_amount_type}`,
          },
        };
      });
      return {
        ...oldRequest,
        old_request_id: this.state.oldRequestId,
        catering_need: `${oldRequest.catering}`,
        crew_size: `${oldRequest.crew_size}`,
        flight_type: `${oldRequest.flight_type}`,
        flight_purpose: `${oldRequest.flight_purpose}`,
        identifier: oldRequest.identifier || "",
        other_info: oldRequest.other_info.replace(/<br\/>/g, "\n"),
      };
    } else {
      this.context.store.dispatch(editRequest({}));
      this.sumDistance(initLegs);
      return {
        legs: initLegs,
        other_info: "",
        preferences: this.props.aircraft.engine_type,
        catering_need: "0",
        crew_size: this.props.aircraft.crew_size,
        identifier: "",
        flight_purpose: "",
        flight_type: "1",
        private_notes: "",
        flight_purpose_event: "",
        flight_purpose_event_id: "",
        broker_intention: "",
      };
    }
  }

  initForm(type, legs, fromCache = false) {
    this.context.store.dispatch(
      actions.change("requestFromEmptyLegModel", this.initFormState(type, legs)),
    );
  }

  updateReferenceDate(request) {
    let prevLeg = {};
    const legs = request.legs.map((legModel, i) => {
      const leg = { ...legModel };
      const legTimeObj = moment(leg.date + " " + leg.departure_time);
      if (legTimeObj.isSameOrBefore(this.state.currentDate)) {
        leg.date = this.state.currentDate.format(config.dateFormat);
      }

      if (i > 0) {
        prevLeg = {
          ...leg,
          referenceDate: prevLeg.date,
          date: getAfterDate(leg.date, prevLeg.date),
        };
        return prevLeg;
      } else {
        prevLeg = leg;
      }
      return leg;
    });
    this.context.store.dispatch(actions.change(modelName + ".legs", legs));
  }

  updateStorageFromResponse(model) {
    storage.add("request_form", JSON.stringify(model));
  }

  updateStorage(formModel) {
    const { emptyLegDetails } = this.state;
    if (Object.keys(emptyLegDetails).length) {
      const min = emptyLegDetails.time_interval[0].split(":");
      const max = emptyLegDetails.time_interval[1].split(":");
      const now = moment().format(config.dobFormat);
      const modelObj = {
        ...formModel,
        preferences: this.props.aircraft.engine_type,
        crew_size: this.props.aircraft.crew_size,
        legs: [
          {
            ...emptyLegDetails,
            departure_time: emptyLegDetails.departure,
            min: parseInt(min[0]),
            max: parseInt(max[0]),
            minMin: parseInt(min[1]),
            maxMin: parseInt(max[1]),
            timeTo: moment(now + " " + emptyLegDetails.time_interval[1]),
            timeFrom: moment(now + " " + emptyLegDetails.time_interval[0]),
            selectedTime: moment(now + " " + emptyLegDetails.time_interval[0]),
          },
        ],
      };
      storage.add("request_form", JSON.stringify(modelObj));
    }
  }

  handleRequestFormChange(model) {
    if (model && model.legs && model.legs.length) {
      this.updateStorage(model);
    }
  }

  setLandings(index, location, coordModel) {
    const value = location.lat + "|" + location.lng;
    this.context.store.dispatch(actions.change(coordModel, value));
    const totalLegs = this.props.modelRequest.legs.length - 1;
    //update source because it could be changed with helipaddy value
    this.props.updateLeg(
      index,
      this.getSourcePrefix(coordModel) + "_source",
      config.locationSource[0],
    );
    if (totalLegs > index) {
      this.props.updateLeg(index + 1, "from_coords", value);
      this.props.updateLeg(index + 1, "from_source", config.locationSource[0]);
    }
    if (index === 0) this.setState({ selectedLocation: location });
  }

  getSourcePrefix(legLocation) {
    const strip = legLocation.split(".");
    if (strip.length > 0) {
      const prefix = strip[strip.length - 1].split("_");
      return prefix[0];
    }
    return null;
  }

  removeLeg(ev, index) {
    ev.preventDefault();
    const legs = [...this.props.modelRequest.legs];
    if (legs.length > 1) {
      const legsObj = legs.filter((_, legIndex) => legIndex !== index);
      this.context.store.dispatch(actions.change(`${modelName}.legs`, legsObj));
    }
  }

  onSubmit(model) {
    const onSuccess = (data) => {
      this.updateStorageFromResponse(data);
      this.props.history.push({
        pathname: "/request-review",
        state: { fromEmptyLegUrl: this.props.history.location.pathname },
      });
    };

    if (!isAllowForEditing()) {
      return showNotification(RESTRICT_ERROR_MESSAGE, "danger");
    }

    const requestModel = {
      ...model,
      other_info: `${model.other_info.split("\n").join("<br/>")}`,
    };
    this.context.store
      .dispatch(
        actions.submit(
          modelName,
          this.props.add(
            {
              ...requestModel,
              empty_leg_id: model.empty_leg_id ? model.empty_leg_id : model.legs[0].id,
            },
            onSuccess,
            modelName,
          ),
        ),
      )
      .catch(() => this.jumpThroughErrors());
  }

  updateLegFromTo(model, value, index) {
    const { modelRequest } = this.props;
    if (modelRequest.flight_type === "2") {
      if (model === "from_is_private") {
        const totalLegs = modelRequest.legs.length - 1;
        if (totalLegs > index) {
          this.props.updateLeg(index + 1, "to_is_private", value);
        }
      }
      if (model === "from_details") {
        const totalLegs = modelRequest.legs.length - 1;
        if (totalLegs > index) {
          this.props.updateLeg(index + 1, "to_details", value);
        }
      }
    }
    if (model === "to_is_private") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, "from_is_private", value);
      }
    }
    if (model === "to_details") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, "from_details", value);
      }
    }
    if (model === "date") {
      const totalLegs = modelRequest.legs.length - 1;
      if (totalLegs > index) {
        this.props.updateLeg(index + 1, "date", value);
      }
    }
  }

  validateBeforeSubmit(legs = []) {
    let status = true;
    let currentDate = null;
    legs.forEach((leg, index) => {
      const legTimeObj = moment(leg.date + " " + leg.departure_time);
      if (index > 0 && legTimeObj.isSameOrBefore(currentDate)) {
        status = false;
      }
      currentDate = legTimeObj;

      //  min one person should be set
      const { men, women, children } = leg.passengers;
      const total = men + women + children;
      if (parseInt(total) === 0) {
        status = false;
      }
    });
    return status;
  }

  parseFormLegs(formLegs = {}) {
    const legs = Object.keys(formLegs);
    let status = true;
    let _index = null;
    legs.pop();
    legs.forEach((leg, index) => {
      if (
        status &&
        ((formLegs[parseInt(leg)].from_coords && !formLegs[parseInt(leg)].from_coords.valid) ||
          (formLegs[parseInt(leg)].to_coords && !formLegs[parseInt(leg)].to_coords.valid))
      ) {
        status = false;
        _index = index;
      }
    });
    return {
      status: status,
      legIndex: _index,
    };
  }

  validateLocations() {
    if (!this.props.form.$form.submitFailed) return true;
    const validate = this.parseFormLegs(this.props.form.legs);
    return validate.status;
  }

  resetForm() {
    let initModel = JSON.parse(JSON.stringify(this.initFormState()));
    initModel.legs[0].departure_time = this.defaultTime.format(config.timeFormat);
    initModel.legs[0].date = moment().add(1, "day").format(config.dateFormat);
    this.context.store.dispatch(actions.change("requestFromEmptyLegModel", initModel));
  }

  viewRequest() {
    this.setState({
      min: 2,
      max: 23,
      departure_time: this.props.modelRequest.time_interval[0],
    });
    this.props.resetModel();
  }

  sumDistance(legs) {
    let allDistance = 0;
    let currentDistance = [];
    let legIndex = 0;
    legs &&
      legs.map((leg, index) => {
        const distance = parseInt(
          distanceBetween(
            parseCoordinates(legs[index].from_coords),
            parseCoordinates(legs[index].to_coords),
          ).toFixed(0),
        );
        allDistance += distance;
        currentDistance.push(distance);
        legIndex = index;
      });
    this.setState({ currentDistance: currentDistance });
    this.setState({ allDistance: allDistance });
    const timeCounted = Math.floor((currentDistance[legIndex] / 130) * 60 + 15);
    if (currentDistance[legIndex] === 0) {
      return this.context.store.dispatch(
        actions.change(`${modelName}.legs.[${legIndex}].time_max`, currentDistance[legIndex]),
      );
    }
    if (timeCounted % 10 === 0) {
      return this.context.store.dispatch(
        actions.change(`${modelName}.legs.[${legIndex}].time_max`, timeCounted * 60),
      );
    } else {
      const rounded =
        timeCounted % 10 !== 0 ? (timeCounted + (10 - (timeCounted % 10))) * 60 : timeCounted * 60;
      return this.context.store.dispatch(
        actions.change(`${modelName}.legs.[${legIndex}].time_max`, rounded),
      );
    }
  }

  distanceToTime(distance) {
    const timeCounted = Math.floor((distance / 130) * 60 + 15);
    const h = moment.utc().startOf("day").add({ minutes: timeCounted }).format("HH");
    const m = moment.utc().startOf("day").add({ minutes: timeCounted }).format("mm");
    let roundedMinutes =
      parseInt(m, 10) % 10 !== 0
        ? parseInt(m, 10) + (10 - (parseInt(m, 10) % 10))
        : parseInt(m, 10);
    let roundedHours = parseInt(h, 10);
    if (roundedMinutes === 60) {
      roundedHours++;
      roundedMinutes = parseInt("00", 10);
    }
    return (
      (roundedHours !== 0 ? roundedHours + (roundedHours === 1 ? " hour" : " hours") : "") +
      " " +
      (roundedMinutes !== 0 ? roundedMinutes + " minutes" : "")
    );
  }

  updateTextArea(event) {
    const textareaLineHeight = 24;
    const { maxRows, areaRows } = this.state;

    const previousRows = event.target.rows;
    event.target.rows = areaRows; // reset number of rows in textarea

    const currentRows = event.target.scrollHeight / textareaLineHeight;

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    this.setState({
      areaRows: currentRows < maxRows ? currentRows : maxRows,
    });
  }

  fillWithTheSameInfoNextLeg(model, value, index, modelKey) {
    const { legs } = this.props.modelRequest;
    this.context.store.dispatch(
      actions.change(`legModels.legs.[${index}].luggage.same_as_previous_leg`, false),
    );
    if (legs.length > 1) {
      legs.map((leg, legIndex) => {
        if (leg.luggage.same_as_previous_leg) {
          this.context.store.dispatch(
            actions.change(`legModels.legs.[${legIndex}].${modelKey}`, value),
          );
          this.context.store.dispatch(actions.change(model, value));
        } else {
          this.context.store.dispatch(actions.change(model, value));
        }
      });
    } else {
      this.context.store.dispatch(actions.change(model, value));
      this.context.store.dispatch(actions.resetValidity("legModels.legs"));
    }
  }

  updateClientReferenceHanlder(handler) {
    this.setState({ submitClientReference: handler });
  }

  clientReferenceAfterSubmit() {
    this.setState({ removeOnUnmout: false });
    this.context.store.dispatch(actions.submit("requestFromEmptyLegModel"));
  }

  updateRequestReferenceId(referenceId) {
    this.context.store.dispatch(actions.change("requestFromEmptyLegModel.client_id", referenceId));
  }

  jumpThroughErrors() {
    const errorSection = document.getElementsByClassName("gh-input-errors");
    for (let i = 0; i < errorSection.length; i++) {
      if (errorSection[i].getElementsByTagName("li")[0].innerText) {
        const element = errorSection[i].getElementsByTagName("li")[0].getBoundingClientRect();
        window.scrollTo({
          top: element.top - document.body.getBoundingClientRect().top - 100,
          left: element.x,
          behavior: "smooth",
        });
      }
    }
  }

  checkErrors() {
    this.setState({ accordionsOpen: true, legsOpen: true }, () => {
      let accordions = document.getElementsByClassName("gh-accordion");
      if (accordions) {
        for (let i = 0; i < accordions.length; i++) {
          const item = accordions[i];
          for (let j = 0; j < item.children.length; j++) {
            item.children[j].classList.add("uk-open");
          }
        }
      }
    });
    setTimeout(() => this.jumpThroughErrors(), 300);
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("requestFromEmptyLegModel"));
  }

  render() {
    const {
      legs = [],
      number_children,
      number_adults,
      flight_type,
      flight_purpose,
    } = this.props.modelRequest;

    const storageLegModal = JSON.parse(storage.get("request_form"));
    const { currentDistance, areaRows, legsOpen, accordionsOpen } = this.state;
    const legContainers =
      JSON.parse(storage.get("request_form")) &&
      legs.map((leg, index) => {
        return (
          <li
            key={index}
            id={`gh-accordion-item${[index]}`}
            className={`${legsOpen ? "uk-open" : ""} gh-accordion-section`}
          >
            <a className="uk-accordion-title">
              {legTitle(flight_type, index) || staticService.findByAlias("oneWay")}
            </a>
            <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
              <LegFormComponent
                modelName="requestFromEmptyLegModel"
                model={this.props.modelRequest}
                storageLegModal={storageLegModal && storageLegModal.legs && storageLegModal.legs[0]}
                fromEmptyLeg={this.props.location.state && this.props.location.state.fromEmptyLeg}
                form={this.props.form}
                {...leg}
                leg={leg}
                readOnly={true}
                emptyLeg={true}
                updateFromToModel={this.updateLegFromTo.bind(this)}
                departure_date={leg.date}
                children={number_children}
                adults={number_adults}
                flightType={this.props.modelRequest.flight_type}
                distance={this.state.currentDistance}
                action={this.setLandings.bind(this, index)}
                index={index}
                removeLeg={() => {}}
                disableDisplayLandings={true}
                onSubmitDistance={this.sumDistance}
                updateReferenceDate={this.updateReferenceDate.bind(this)}
                handleAssignPad={() => {}}
                screenDimensions={this.props.screenDimensions}
                visibleEstimateTime={true}
                currentDistance={currentDistance[index]}
                allDistance={this.state.allDistance}
                updateRange={this.updateRange.bind(this)}
                distanceToTime={this.distanceToTime(currentDistance[index], index)}
                fillWithTheSameInfoNextLeg={this.fillWithTheSameInfoNextLeg.bind(this)}
                aircraft={this.props.aircraft}
              />
            </div>
          </li>
        );
      });

    return (
      <IsVerifiedPermission
        match={this.props.match}
        title={this.props.title}
        skipLayout={true}
        content={staticService.findByAlias("pageAccessRestricted")}
        role={"broker"}
      >
        <section className="new-request">
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom gh-padding-medium-top",
            }}
          >
            <div className="request-form-title uk-margin-bottom">
              <BackLink to="/marketplace/empty-legs">
                {staticService.findByAlias("backToEmptyLegsList")}
              </BackLink>
            </div>

            <Form
              model="requestFromEmptyLegModel"
              onChange={this.handleRequestFormChange.bind(this)}
              onSubmit={this.onSubmit.bind(this)}
              validators={{
                "": {
                  validateLocations: (vals) => this.validateLocations(),
                  legsDatetime: (vals) => this.validateBeforeSubmit(vals.legs),
                },
              }}
            >
              {/*GENERAL INFORMATION*/}
              <RequestGeneralInformation
                flight_type={flight_type}
                flight_purpose={flight_purpose}
                changeFlightType={() => {}}
                modelName="requestFromEmptyLegModel"
                purposesList={this.state.purposesList}
                generalModel={this.props.modelRequest}
                disabledFlightType={true}
              />

              <ul
                data-uk-accordion="collapsible: true"
                className="disabled-list gh-accordion"
                id="first-accordion"
              >
                {legContainers}
              </ul>

              {/*AIRCRAFT PERFORMANCE*/}
              <ul
                data-uk-accordion="collapsible: true; multiple: true "
                id="second-accordion"
                className="gh-accordion disabled-list uk-margin-top"
              >
                <li className={`${accordionsOpen ? "uk-open" : ""} gh-accordion-section`}>
                  <a
                    className="uk-accordion-title"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("AircraftPreferences"),
                    }}
                  />
                  <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
                    <LegExtraFieldset
                      model={"requestFromEmptyLegModel"}
                      visible={true}
                      aircraft={this.props.aircraft}
                      disabled={true}
                    />
                  </div>
                </li>
              </ul>

              {/*ADDITIONAL INFO*/}

              <ul
                data-uk-accordion="collapsible: true; multiple: true "
                id="third-accordion"
                className="gh-accordion disabled-list uk-margin-top"
              >
                <li className={`${accordionsOpen ? "uk-open" : ""} gh-accordion-section`}>
                  <a
                    className="uk-accordion-title"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("additionalInformationLabel"),
                    }}
                  />
                  <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
                    <div className="gh-request-area">
                      <div className="gh-request-area-box">
                        <Control.textarea
                          model=".other_info"
                          rows={areaRows}
                          id="request_area"
                          onChange={this.updateTextArea}
                          changeAction={updateModel}
                          className={"uk-textarea"}
                          placeholder={staticService
                            .findByAlias("requestAdditionalInfoPlaceholder")
                            .replace(/<[^>]+>/g, "")
                            .replace(/&nbsp;/g, "")}
                        />
                      </div>
                    </div>
                    <Control
                      className="uk-margin-top"
                      model=".other_info_attachments"
                      component={({ value, onChange, className }) => (
                        <Upload
                          defaultFileList={value}
                          onChange={onChange}
                          className={className}
                          accept=".png,.jpg,.pdf"
                        />
                      )}
                    />
                  </div>
                </li>
              </ul>

              {/* PRIVATE NOTES */}
              <div>
                <ul
                  data-uk-accordion="collapsible: true; multiple: true "
                  id="fourth-accordion"
                  className="gh-accordion disabled-list uk-margin-top"
                >
                  <li className={`uk-open gh-accordion-section`}>
                    <a
                      className="uk-accordion-title"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("privateNotes"),
                      }}
                    />
                    <div className="uk-accordion-content uk-margin-remove-top uk-padding-remove-top">
                      <div data-uk-grid className="uk-form-controls">
                        <div className="uk-width-1-1 uk-width-1-2@s">
                          <span className="uk-flex">
                            <label
                              className="uk-form-label"
                              dangerouslySetInnerHTML={{
                                __html: staticService.findByAlias("notes"),
                              }}
                            />
                            <Tooltip wrapperClassName="uk-margin-small-left" pos="top-right">
                              {staticService.findByAlias("privateNotesNewRequestTooltip")}
                            </Tooltip>
                          </span>
                          <Control.text
                            model=".private_notes"
                            className="uk-input"
                            placeholder={staticService.findByAlias("notesPlaceholder")}
                            changeAction={updateModel}
                          />
                        </div>
                        <BrokerClientReference
                          setSubmitCb={this.updateClientReferenceHanlder}
                          afterSubmit={this.clientReferenceAfterSubmit}
                          onReferenceChange={this.updateRequestReferenceId}
                          defaultReferenceId={this.props.modelRequest.client_id}
                          resetFormValidity={() =>
                            this.context.store.dispatch(
                              actions.resetValidity("requestFromEmptyLegModel"),
                            )
                          }
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="uk-margin-top uk-margin-bottom gh-request-gen-btns">
                <button
                  className="uk-button uk-button-primary"
                  type="button"
                  onClick={this.state.submitClientReference}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("reviewRequest"),
                    }}
                  />
                </button>
              </div>
            </Form>
          </BlockComponent>
        </section>
      </IsVerifiedPermission>
    );
  }
}

BrokerEmptyLegDetailsComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerEmptyLegDetailsComponent.propTypes = {
  modelRequest: PropTypes.object,
  getEmptyLegDetailsSuccess: PropTypes.func,
  editedRequest: PropTypes.object,
  getEmptyLegInfo: PropTypes.func,
  match: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
  emptyLegDetails: PropTypes.object,
  getPurposes: PropTypes.func,
  updateLeg: PropTypes.func,
  history: PropTypes.object,
  screenDimensions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      add: sendOnReviewRequest,
      updateModel: (model, val) => dispatch(actions.change(model, val)),
      updateLeg: (index, key, value) => {
        return dispatch(actions.change(`${modelName}.legs.[${index}].${key}`, value));
      },
      getPurposes,
      getEmptyLegInfo: getEmptyLegInfo,
      getMarketPlaceEmptyLegs,
      getEmptyLegDetailsSuccess,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    modelRequest: state.requestFromEmptyLegModel,
    legDistance: state.legDistance,
    form: state.forms.requestFromEmptyLegModel,
    successRequest: !!state.booking_request.length,
    screenDimensions: state.screenDimensions,
    requestID: state.booking_request.id ? state.booking_request.id : null,
    editedRequest: state.editedRequest,
    emptyLegDetails: state.emptyLegDetails,
    booking_request: state.booking_request,
    aircraft: state.emptyLegDetails.aircraft,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerEmptyLegDetailsComponent);
export { COMPONENT as BrokerEmptyLegDetailsComponent };
