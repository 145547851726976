import React, { useState } from "react";
import PropTypes from "prop-types";
import { Control, Errors, Form } from "react-redux-form";
import { CustomValidateError, updateModel } from "../../../../shared";
import { validationMessages, validationRules } from "../../../../configs";
import staticService from "../../../../services/static.service";
import Button from "../../../common/Button";

const { required, min, max, alpha } = validationRules;

export const DeclineReasons = (props) => {
  const { reasons, submitDecline, form } = props;

  const [isLoading, setIsLoading] = useState(false);
  const reason = form.reason && form.reason.value;
  const other_info = form.other_info && form.other_info.value;
  const rule = (val) => {
    return val > 3 && val < 120;
  };
  const disabledRule = () => {
    if (reason === "Other info" && other_info && rule(other_info.length)) {
      return false;
    } else return !(reason && reason !== "Other info" && reason.length);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    submitDecline(setIsLoading);
  };

  return (
    <Form model="declineRequestModel">
      {Array.isArray(reasons) &&
        reasons.map((reason) => (
          <div key={reason} className="uk-margin-small-bottom">
            <label>
              <Control.radio
                className="uk-radio uk-margin-small-right"
                model={".reason"}
                changeAction={updateModel}
                updateOn="change"
                value={reason}
              />{" "}
              {reason}
            </label>
          </div>
        ))}
      <div className="uk-margin-small-bottom">
        <label>
          <Control.radio
            className="uk-radio uk-margin-small-right"
            model={".reason"}
            changeAction={updateModel}
            updateOn="change"
            value={"Other info"}
          />{" "}
          Other info
        </label>
      </div>
      {form && form.reason && form.reason.value === "Other info" && (
        <div className="uk-margin-small-bottom">
          <Control.textarea
            className="uk-textarea"
            model={".other_info"}
            changeAction={updateModel}
            placeholder="Insert other info"
            updateOn="change"
            validators={{
              required,
              alpha,
              min: min(4),
              max: max(120),
            }}
          />
          <Errors
            model=".other_info"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("declineOtherInfo"),
              ),
              min: validationMessages().minMessage(4),
              max: validationMessages().maxMessage(120),
            }}
          />
        </div>
      )}
      <div className="uk-flex uk-flex-center uk-margin-top">
        <Button
          loading={isLoading}
          onClick={handleSubmit}
          disabled={disabledRule()}
          color="success"
        >
          {staticService.findByAlias("submitDeclineButton")}
        </Button>
      </div>
    </Form>
  );
};

DeclineReasons.propTypes = {
  reasons: PropTypes.array,
  submitDecline: PropTypes.func,
  form: PropTypes.object,
};
