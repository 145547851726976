import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import { Control, Errors } from "react-redux-form";
import { CustomValidateError, DateTimeComponent } from "../../../shared";
import { config, validationMessages } from "../../../configs";

const EnquireTime = (props) => {
  const { label, disabled, validators, onChange, model } = props;

  return (
    <div className="gh-time-enquire-form">
      <label className="uk-form-label">{staticService.findByAlias(label)}</label>

      <Control
        model={model}
        className="uk-input gh-time-input"
        component={DateTimeComponent}
        disabled={disabled}
        validators={validators}
        readOnly
        controlProps={{
          dateFormat: false,
          timeFormat: config.timeFormat,
          placeholder: staticService.findByAlias("time"),
          showClearIcon: false,
          onCloseAction: onChange,
          timeConstraints: {
            minutes: {
              step: config.minuteStep,
            },
          },
        }}
        attrProps={{ className: "enquire-time-icon" }}
        changeAction={onChange}
      />
      <Errors
        model={model}
        show="touched"
        wrapper={(props) =>
          CustomValidateError({ ...props, containerClass: ["gh-input-errors_static"] })
        }
        messages={{
          required: validationMessages().requiredMessage.bind(null, "Time of departure"),
        }}
      />
    </div>
  );
};

EnquireTime.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  validators: PropTypes.any,
  onChange: PropTypes.any,
  model: PropTypes.string,
};

export default EnquireTime;
