import React from "react";
import PropTypes from "prop-types";

export const FlightDirection = ({ visible, reverseDirection }) => {
  const reverse = reverseDirection ? "back" : "";
  const css = ["gh-helicopter-wrapper", reverse];
  if (visible) {
    return <div className={css.join(" ")}></div>;
  }

  return null;
};

FlightDirection.propTypes = {
  visible: PropTypes.bool.isRequired,
  reverseDirection: PropTypes.bool,
};
