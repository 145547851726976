import React from "react";
import PhoneInput2 from "react-phone-input-2";
import PropTypes from "prop-types";
import condStrings from "../../../utils/condString";
import "./default.min.css";
import "./index.css";
import { parsePhoneInputValue } from "./phoneInput.utils";

const PhoneInput = React.forwardRef((props, ref) => {
  const { containerClass, inputClass, name, onChange, selectedCountry, value, ...rest } = props;

  const handleOnChange = (value, { countryCode, dialCode }) => {
    onChange({ value, code: countryCode ? countryCode.toUpperCase() : "", dialCode });
  };

  return (
    <div className="phone-input_wrapper">
      <PhoneInput2
        {...rest}
        containerClass={condStrings("phone-input-container", containerClass)}
        inputClass={condStrings("", inputClass)}
        country={selectedCountry.toLowerCase()}
        onChange={handleOnChange}
        specialLabel={null}
        enableAreaCodes
        value={parsePhoneInputValue(value)}
        inputProps={{
          name,
          ref: ref || {},
        }}
      />
    </div>
  );
});

PhoneInput.displayName = "PhoneInput";

PhoneInput.defaultProps = {
  containerClass: "",
  inputClass: "",
  name: "",
  onChange: () => null,
  selectedCountry: "gb",
};

PhoneInput.propTypes = {
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selectedCountry: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default PhoneInput;
export * from "./phoneInput.utils";
