import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Control, actions } from "react-redux-form";
import PropTypes from "prop-types";
import { BlockComponent, ConfirmComponent } from "../../../../common";
import {
  CLEAR_AUTOCOMPLETE_LIST,
  addFavourite,
  deleteFavourite,
  favourites,
  searchFavourite,
} from "../../../../../actions";
import auth from "../../../../../services/auth";
import staticService from "../../../../../services/static.service";
import { ButtonComponent, replaceAttributesInString } from "../../../../../shared";
import { validationRules, BROKER_ADMIN } from "../../../../../configs";
import { AutocompleteDropdownComponent } from "./autocomplete-dropdown.component";
import { NormalPermission } from "../../../../permissions/normalPermission";

class BrokerFavouritesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: null,
      noMatch: false,
      dialogProps: this.defaultProps,
    };

    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.delete = this.delete.bind(this);
    this.addOperator = this.addOperator.bind(this);
    this.autocompleteSelect = this.autocompleteSelect.bind(this);
    this.searchTrigger = this.searchTrigger.bind(this);
    this.displayNoMatch = this.displayNoMatch.bind(this);
    this.addDialogProps = this.addDialogProps.bind(this);
    this.deleteConfirmProps = this.deleteConfirmProps.bind(this);
  }

  get defaultProps() {
    return {
      content: "",
    };
  }

  componentWillMount() {
    this.props.get(auth.company.id);
  }

  componentDidMount() {
    window.addEventListener("keydown", (e) => {
      if (this.props.autocomplete.length > 0) {
        if (e.which === 40 || e.which === 38) {
          this.input.blur();
        }
      }
    });
  }

  deleteConfirmProps(html) {
    return {
      content: <div dangerouslySetInnerHTML={{ __html: html }} />,
      buttonsProps: {
        continue: { title: staticService.findByAlias("continue"), css: "uk-button-danger" },
        cancel: { title: staticService.findByAlias("cancel"), css: "uk-button-default" },
      },
      modalProps: {
        id: "withdraw-favourite-modal",
        title: staticService.findByAlias("removeFavouriteOperator"),
        onClose: this.closeModal,
      },
      onSelect: this.delete,
      onReject: this.closeModal,
    };
  }

  delete() {
    this.props.delete(auth.company.id, { operator: this.state.current.id });
  }

  autocompleteSelect(response, clear = true) {
    this.context.store.dispatch(actions.change("favourite", response));
    if (clear) {
      this.props.clearAutocomplete();
    }
  }

  onSuccesSearch(response) {
    let state = response.length === 0;
    this.setState({ dialogProps: this.addDialogProps(this.noMatchObject(state)) });
  }

  searchTrigger(model, value) {
    if (value.length >= 2) {
      this.props.searchFavourite(this.props.company.id, value, this.onSuccesSearch.bind(this));
    }
  }

  displayNoMatch() {
    return (
      <ul className="ul validation-errors">
        <li>
          <span
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("noOperatorMatchCriteria"),
            }}
          ></span>
        </li>
      </ul>
    );
  }

  addDialogProps(props) {
    return {
      content: this.form(props),
      buttonsProps: {},
      modalProps: {
        id: "add-favourite-modal",
        title: staticService.findByAlias("addFavouriteOperator"),
        onClose: this.closeModal,
      },
    };
  }

  form(props) {
    const { min } = validationRules;
    const ariaProps = {
      role: "combobox",
      autoComplete: "off",
      "aria-autocomplete": "list",
      "aria-expanded": "false",
    };
    return (
      <Form model={"favourite"} onSubmit={this.onSubmit}>
        {props.noMatch ? this.displayNoMatch() : null}
        <div>
          <div className="uk-margin">
            <Control.text
              getRef={(ref) => (this.input = ref)}
              className={"uk-input"}
              placeholder={staticService.findByAlias("searchOperators")}
              model={".name"}
              validators={{ min: min(2) }}
              changeAction={this.searchTrigger}
              debounce={500}
              mapProps={ariaProps}
            />
            <div className={"uk-position-relative"}>
              <AutocompleteDropdownComponent onSelect={this.autocompleteSelect} />
            </div>
          </div>
          <div className="uk-margin-small-top uk-child-width-1-1 uk-child-width-1-2@m" data-uk-grid>
            <div>
              <Control.button
                model={"favourite"}
                type={"button"}
                onClick={this.closeModal}
                className={"uk-button uk-button-default uk-align-center uk-align-right@m"}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cancel") }}
                ></span>
              </Control.button>
            </div>
            <div>
              <Control.button
                model={"favourite"}
                disabled={(props) => {
                  return props.value.id === null;
                }}
                className={"uk-button uk-button-primary uk-align-center uk-align-left@m"}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: staticService.findByAlias("submit") }}
                ></span>
              </Control.button>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  closeModal() {
    this.setState({ current: null, dialogProps: this.defaultProps });
    this.confirmComponent.closeModal();
    this.context.store.dispatch(actions.change("favourite", { name: "", id: null }));
  }

  onSubmit(model) {
    this.props.add(this.props.company.id, { operator: model.id }, this.closeModal);
  }

  get info() {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("removeMemberConfirmMessage"),
        }}
      />
    );
  }

  removeUser(company) {
    const html = replaceAttributesInString(
      { company: company.name },
      staticService.findByAlias("favouritesRemoveConfirmation"),
    );
    this.setState({ current: company, dialogProps: this.deleteConfirmProps(html) });
    this.confirmComponent.openModal();
  }

  noMatchObject(state = false) {
    return { noMatch: state };
  }

  addOperator() {
    this.setState({ dialogProps: this.addDialogProps(this.noMatchObject()) });
    this.confirmComponent.openModal();
  }

  get favouriteStatusMsg() {
    return auth.company.favourites_status === 0 || auth.company.favourites_status === null
      ? "favourite_update_month_zero"
      : "favourite_update_month_greater";
  }

  get disabled() {
    return this.props.company.favourites_status > 0 || this.props.favourites.length === 3;
  }

  get extraLeftMsg() {
    return auth.company.max_allowed > 0 ? staticService.findByAlias(this.favouriteStatusMsg) : "";
  }

  render() {
    return (
      <BlockComponent visible={true}>
        <h2
          className={"gh-text-slim uk-margin-remove-bottom"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("favouriteOperatorsLabel") }}
        ></h2>
        <p
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("favouritesParagraph") }}
        ></p>
        <ul className={"disabled-list uk-margin-top uk-margin-bottom gh-favourites"}>
          {this.props.favourites.map((company) => {
            return (
              <li key={company.id} className={"uk-margin-small-bottom"}>
                <div data-uk-grid className={"uk-padding-small"}>
                  <div className={"uk-width-2-3"}>{company.name}</div>
                  <NormalPermission condition={auth.getRole() === BROKER_ADMIN}>
                    <div className={"uk-width-1-3 uk-text-center"}>
                      <a onClick={this.removeUser.bind(this, company)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          style={{
                            width: 20,
                            height: 20,
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </NormalPermission>
                </div>
              </li>
            );
          })}
        </ul>
        <div className={"uk-clearfix"}>
          <div data-uk-grid>
            <div className={"uk-width-1-1@s uk-width-1-2@m uk-text-center uk-text-left@m"}>
              {this.extraLeftMsg}
            </div>
            <div className={"uk-width-1-1@s uk-width-1-2@m uk-text-center uk-text-right@m"}>
              <NormalPermission condition={auth.getRole() === BROKER_ADMIN}>
                <ButtonComponent
                  action={this.addOperator}
                  mapProps={{
                    className: "uk-button uk-button-primary",
                    type: "button",
                    disabled: this.disabled,
                  }}
                >
                  {staticService.findByAlias("addOperatorButonLabel")}
                </ButtonComponent>
              </NormalPermission>
            </div>
          </div>
        </div>
        <ConfirmComponent
          ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
          info={this.state.dialogProps.content}
          buttonsProps={this.state.dialogProps.buttonsProps}
          modalProps={this.state.dialogProps.modalProps}
          onReject={this.state.dialogProps.onReject}
          onSelect={this.state.dialogProps.onSelect}
        />
      </BlockComponent>
    );
  }
}

BrokerFavouritesComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = ({ favourites, company, autocomplete }) => {
  return {
    favourites,
    company,
    autocomplete,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: (type, state) => dispatch({ type: type, visible: state }),
      clearAutocomplete: () => dispatch({ type: CLEAR_AUTOCOMPLETE_LIST }),
      delete: deleteFavourite,
      add: addFavourite,
      get: favourites,
      searchFavourite,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerFavouritesComponent);
export { COMPONENT as BrokerFavouritesComponent };
