import React from "react";
import auth from "../../../../../services/auth";
import staticService from "../../../../../services/static.service";
import Button from "../../../../common/Button";
import RadioButtonComponent from "../../../../common/RadioButtonComponent";
import { RequestDetailsFooterContext } from "./RequestDetailsFooter";
import Modal from "../../../../common/Modal";

export default function RequestDeclineAction() {
  const {
    originalQuoteDetail,
    declineReasons,
    sendDeclineReasons,
    setShowPrompt,
    history,
    showAdditionalButtons,
  } = React.useContext(RequestDetailsFooterContext);

  const [declineReason, setDeclineReason] = React.useState(null);
  const [otherDeclineReason, setOtherDeclineReason] = React.useState("");

  const declineQuoteModalRef = React.useRef();

  const handleOpenModal = () => declineQuoteModalRef.current.openModal();

  const handleCloseModal = () => declineQuoteModalRef.current.closeModal();

  const handleDeclineRequest = () => {
    setShowPrompt(false);
    sendDeclineReasons(originalQuoteDetail.id, {
      reason: declineReason === "Other info" ? otherDeclineReason : declineReason,
    }).then(() => {
      history.push("/marketplace");
    });
  };

  if (showAdditionalButtons) {
    return (
      <>
        <Button
          disabled={!auth.isVerified()}
          onClick={handleOpenModal}
          data-uk-tooltip={(!auth.isVerified() && "Your account is not verified") || ""}
          variant="outlined"
          color="danger"
        >
          Decline Request
        </Button>
        <Modal
          className="gh-decline-modal"
          onClose={handleCloseModal}
          title={staticService.findByAlias("operatorDeclineModalTitle")}
          ref={declineQuoteModalRef}
        >
          <div>
            <RadioButtonComponent
              selected={declineReason}
              items={[...declineReasons, { label: "Other info", value: "Other info" }]}
              handleChange={setDeclineReason}
              id="included-fees"
            />
            {declineReason === "Other info" ? (
              <div>
                <textarea
                  value={otherDeclineReason}
                  className="uk-textarea other-textarea"
                  placeholder="Insert other info"
                  minLength={4}
                  onChange={(e) => setOtherDeclineReason(e.target.value)}
                />
                {otherDeclineReason.length > 0 && otherDeclineReason.length < 4 ? (
                  <div className="gh-message-error">Must be 4 or more characters!</div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
              <button
                type="button"
                className="uk-button uk-button-primary gh-request-gen-btns"
                onClick={handleDeclineRequest}
                disabled={
                  declineReason === "" ||
                  (declineReason === "Other info" && declineReason.length < 4)
                }
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  return null;
}
