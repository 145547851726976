import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { parseCoordinates, inArrayWithKey, utcTime } from "../../../../shared";
import { FlightDirection } from "../../../../components/common";
import { config } from "../../../../configs";
import staticService from "../../../../services/static.service";
import checkIcon from "../../../../assets/img/svg/checkSimple.svg";
import capitalizeString from "../../../../utils/capitalizeString";

const gridSize = {
  full: ["uk-width-1-1@m", "uk-width-1-1@m", "uk-width-1-1@m", "uk-width-1-1@m"],
  responsive: [
    "uk-width-expand@l uk-width-1-1@s",
    "uk-width-expand@l uk-width-1-1@s",
    "uk-width-1-4@l uk-width-1-1@s",
    "uk-width-1-5@l uk-width-1-1@s",
  ],
};

const showOtherInfo = (key, value, index) => {
  if (key === "luggage_will_provide_later") {
    return (
      <div key={index}>
        <small className="gh-text-normal">
          <span className="gh-text-normal">
            {value ? "Luggage information will be provided later" : ""}
          </span>
        </small>
      </div>
    );
  }

  return (
    <div key={index}>
      <small className="gh-text-normal">
        {capitalizeString(staticService.findByAlias(key))}:{" "}
        <span className="gh-text-normal">{value}</span>
      </small>
    </div>
  );
};

const LegDetails = ({ requestInfo, leg, showHelicopImage = true, isResponsive = true }) => {
  const {
    from: fromAddr,
    to: toAddr,
    from_coords,
    to_coords,
    from_details,
    from_is_private,
    to_is_private,
    to_details,
    from_location: fromLocation,
    to_location: toLocation,
    from_info: fromInfo,
    to_info: toInfo,
  } = leg;
  const fromCoords = parseCoordinates(from_coords);
  const toCoords = parseCoordinates(to_coords);
  const gridDimensions = isResponsive ? gridSize.responsive : gridSize.full;

  return (
    <div>
      <div data-uk-grid className="gh-leg-info">
        <div className={"uk-width-1-2@l"}>
          <FlightDirection visible={showHelicopImage} />
        </div>
      </div>

      <div data-uk-grid className="gh-leg-info">
        <div className={gridDimensions[0]}>
          <PadInfo
            addr={fromAddr}
            lat={fromCoords.lat}
            lng={fromCoords.lng}
            details={from_details}
            isPrivate={from_is_private}
            side="from"
            location={fromLocation}
            info={fromInfo}
          />
        </div>
        <div className={gridDimensions[1]}>
          <PadInfo
            addr={toAddr}
            lat={toCoords.lat}
            lng={toCoords.lng}
            details={to_details}
            isPrivate={to_is_private}
            side="to"
            location={toLocation}
            info={toInfo}
          />
        </div>

        <div className={gridDimensions[2]}>
          <div>
            <small className="gh-text-normal">
              {staticService.findByAlias("quoteDetailDepartureDate")}:{" "}
              <span className="gh-text-normal">{moment(leg.date).format("DD MMMM, YYYY")}</span>
            </small>
          </div>
          <div>
            <small className="gh-text-normal">
              {staticService.findByAlias("quoteDetailDepartureTime")}:{" "}
              <span className="gh-text-normal">
                {moment(leg.date + " " + leg.departure_time).format(config.timeFormat) +
                  utcTime(leg.date)}
              </span>
            </small>
          </div>
          <div>
            <div>
              <small className="gh-text-normal">
                {staticService.findByAlias("AircraftPreferences")}:{" "}
                <span className="gh-text-normal">
                  {requestInfo.preferences &&
                    inArrayWithKey("id", requestInfo.preferences, config.aircraftPreferences).title}
                </span>
              </small>
            </div>
            <div>
              <small className="gh-text-normal">
                {staticService.findByAlias("CrewRequirements")}:{" "}
                <span className="gh-text-normal">
                  {requestInfo.crew_size >= 0 &&
                    inArrayWithKey("id", requestInfo.crew_size, config.pilotPreferences).title}
                </span>
              </small>
            </div>
          </div>
        </div>

        <div className={gridDimensions[3]}>
          <div>
            <small className="gh-text-normal">
              {staticService.findByAlias("MenCount")}:{" "}
              <span className="gh-text-normal">{leg.passengers.men}</span>
            </small>
          </div>
          <div>
            <small className="gh-text-normal">
              {staticService.findByAlias("WomenCount")}:{" "}
              <span className="gh-text-normal">{leg.passengers.women}</span>
            </small>
          </div>
          <div>
            <small className="gh-text-normal">
              {staticService.findByAlias("ChildrenCount")}:{" "}
              <span className="gh-text-normal">{leg.passengers.children}</span>
            </small>
          </div>
          <div>
            <small className="gh-text-normal">
              {leg.passengers.passengers_amount_type === 2 ? (
                <span className="gh-text-normal">Possibility of change (+ 1 or 2 passengers)</span>
              ) : (
                ""
              )}
            </small>
          </div>
          <br />
          {Object.keys(leg.luggage).map((item, idx) =>
            leg.luggage[item] ? showOtherInfo(item, leg.luggage[item], idx) : "",
          )}
          <br />
          {Object.keys(leg.special_items).map((item, idx) =>
            leg.special_items[item] ? showOtherInfo(item, leg.special_items[item], idx) : "",
          )}
        </div>
      </div>
    </div>
  );
};
const address = (addr, location, info) => {
  if (addr.includes(", ")) {
    return <h3 className="uk-h3 uk-margin-remove">{addr}</h3>;
  }
  return (
    <h3 className="uk-h3 uk-margin-remove">
      {addr}, {location ? location.secondary_text : info}
    </h3>
  );
};
const PadInfo = ({ addr, lat, lng, details, isPrivate, side, location, info }) => (
  <div>
    {address(addr, location, info)}
    <div>
      <small>
        <span>
          {lat.toFixed(2)}° N, {lng.toFixed(2)}° W
        </span>
      </small>
    </div>
    {isPrivate && window.location.pathname.search("/flights/my-bookings/") === -1 ? (
      <div className="uk-margin-top">
        <div className="uk-flex uk-flex-middle">
          <img style={{ marginRight: 10 }} src={checkIcon} alt="check" />
          {side === "from" ? (
            <h6
              className="gh-take-off"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("exactTakeOffLocation"),
              }}
            />
          ) : (
            <h6
              className="gh-take-off"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("exactLandingLocation"),
              }}
            />
          )}
        </div>

        {details && (
          <div
            className="gh-additional-info"
            style={{ margin: "10px 0 0 0", background: "none", padding: "15px 0" }}
          >
            <h6
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("additionalDetailsAboutLocation"),
              }}
            />
            <span>{details}</span>
          </div>
        )}
      </div>
    ) : null}
  </div>
);

LegDetails.defaultProps = {
  requestInfo: {},
  leg: {},
  showHelicopImage: true,
  isResponsive: true,
};

LegDetails.propTypes = {
  requestInfo: PropTypes.object.isRequired,
  leg: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  showHelicopImage: PropTypes.bool,
  isResponsive: PropTypes.bool,
};

export { LegDetails };
