import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions, Form, Control, Errors } from "react-redux-form";
import { FlightLegFormComponent, AssignHeliControlComponent } from "./";
import {
  CustomValidateError,
  updateModel,
  floatNumberPrecision,
  getRoundedPrice,
  getVAT,
  checkObjectProp,
  removeCommas,
  aircraftTitle,
  ModalComponent,
  formatVat,
} from "../../../../../../shared";
import { distanceBetween, parseCoordinates } from "../../../../../../shared/";
import { PadSelectionMapComponent, PadSelectionMapComponentOld } from "../../../../../common";
import { validationRules, validationMessages, config, staticText } from "../../../../../../configs";
import staticService from "../../../../../../services/static.service";
import { clearQuote, getCurrencies } from "../../../../../../actions";
import { EventDetailComponent } from "../../../../user-online/common";
import { spinnerInstance } from "../../../../../../shared/spinner";

const { required, isPrice, isNumber, isGreaterThanZero, lessThan, max } = validationRules;

const modelName = "quoteModel";

class FlightFormComponent extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateLegModel = this.updateLegModel.bind(this);
    this.onModalSubmit = this.onModalSubmit.bind(this);
    this.updatePriceModel = this.updatePriceModel.bind(this);
    this.updateVatModel = this.updateVatModel.bind(this);
    this.state = {
      legs: props.legItems,
      heliPads: [],
      showFinalPrice: false,
      allDistance: 0,
      selectedHelicopters: [],
    };
  }

  onModalSubmit(helicopters) {
    this.setState({ selectedHelicopters: helicopters });
    this.modal.hide();
  }

  submit(model) {
    this.setState({ error: false });
    this.props.onFormSubmit(model);
  }
  componentDidMount() {
    this.props.getCurrencies();
    const { quoteToAmend, banking } = this.props;
    this.setState({
      selectedHelicopters: this.props.amendedHeli ? this.props.amendedHeli : [],
    });
    if (Object.keys(quoteToAmend).length) {
      this.context.store.dispatch(
        actions.change(`${modelName}.currency`, quoteToAmend.currency.currency),
      );
    } else {
      this.context.store.dispatch(
        actions.change(`${modelName}.currency`, banking.currency_data.currency),
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ legs: nextProps.legItems });
    if (nextProps.newQuote.hasOwnProperty("id") && nextProps.newQuote.id) {
      this.context.store.dispatch(clearQuote());
      this.setState({ selectedHelicopters: [] });
    }
    this.sumDistance();
  }

  removeHeliAssigned(id) {
    this.setState({
      selectedHelicopters: this.state.selectedHelicopters.filter((heliId) => heliId !== id),
    });
    this.onItemChange(id);
  }

  updateLegModel(index, leg) {
    this.context.store.dispatch(
      actions.map(modelName + ".legs", (item, i) => {
        if (index === i) {
          return leg;
        }

        if (index + 1 === i) {
          return { ...item, from: leg.to, from_coords: leg.to_coords };
        }

        return item;
      }),
    );
  }

  updatePriceModel(model, value) {
    const { vat_value } = this.props.banking;
    const price = value.replace(/\,/g, "");
    const view_price = price !== "" ? parseInt(price, 10).toLocaleString() : price;
    const vat = price !== "" ? floatNumberPrecision((price / 100) * Number(vat_value), 2) : "";
    return (dispatch) => {
      dispatch(actions.resetValidity(model));
      dispatch(actions.setTouched(model));
      const updateModel = {
        ...this.props.quoteModel,
        view_price: isPrice(price) ? view_price : price,
        price: price,
        final_price: floatNumberPrecision(getRoundedPrice(price), 2),
        quote_vat: vat !== "" ? removeCommas(vat) : "",
        view_quote_vat: vat,
      };
      dispatch(actions.change(modelName, updateModel));
    };
  }

  updateVatModel(model, value) {
    let vat = value.replace(/\,/g, "");
    vat = vat !== "" ? parseFloat(vat).toLocaleString() : vat;
    const updateModel = {
      ...this.props.quoteModel,
      quote_vat: value !== "" ? removeCommas(value) : "",
      view_quote_vat: formatVat(vat),
    };
    return (dispatch) => {
      dispatch(actions.resetValidity(model));
      dispatch(actions.change(modelName, updateModel));
    };
  }

  handleAssignPad({ id, direction, coords, title }) {
    const { lat, lng } = coords;

    let index = this.state.legs.indexOf(this.state.legs.find((i) => i.id === id));

    let legs = this.state.legs.map((leg, i) => {
      if (leg.id === id) {
        const directionKeyTitle = direction.split("_")[0];

        return {
          ...leg,
          [direction]: `${lat}|${lng}`,
          [directionKeyTitle]: title,
          [directionKeyTitle + "_source"]: config.locationSource[1],
        };
      }

      //update from destination for next sibling leg
      if (index >= 0 && index + 1 === i && direction === "to_coords") {
        return {
          ...leg,
          ["from_coords"]: `${lat}|${lng}`,
          ["from"]: title,
          ["from_source"]: config.locationSource[1],
        };
      }

      return leg;
    });

    this.context.store.dispatch(actions.change(modelName + ".legs", legs));
    this.setState({ heliPads: [] });
  }

  handleSubmitFailed(model) {
    if (model.helicopters.$form.errors) {
      if (typeof this.props.onFormSubmitFailed === "function") {
        this.props.onFormSubmitFailed();
      }
    }
  }

  get Event() {
    return this.hasEvent ? this.props.request.events[0] : {};
  }

  get hasEvent() {
    return (
      this.props.request.events &&
      this.props.request.events.length > 0 &&
      this.props.request.events[0].quote_content !== "" &&
      this.props.request.events[0].quote_content !== null
    );
  }

  get assignedHelicopters() {
    return this.state.selectedHelicopters.map((id, i) => {
      const heli = this.props.request.helicopters.reduce((prev, next) => {
        if (next.id === id) return next;
        return prev;
      }, {});
      const aircraft_title = aircraftTitle(heli);

      if (aircraft_title !== "undefined undefined undefined (undefined)") {
        if (!this.props.loading) {
          spinnerInstance.setProp("size", "large").hide();
        }
        return (
          <span key={i} className="uk-label gh-aircraft-label">
            <span dangerouslySetInnerHTML={{ __html: aircraft_title }} />
            <a
              onClick={this.removeHeliAssigned.bind(this, heli.id)}
              className="uk-margin-small-left uk-icon"
              data-uk-icon="icon: close; stroke-width: 1.5"
            />
          </span>
        );
      } else if (i === 0) {
        spinnerInstance.setProp("size", "large").show();
      }
    });
  }

  get bookingHelicopter() {
    if (this.props.request && this.props.request.emptyLegAircraft) {
      return (
        <span className="uk-label  gh-aircraft-label">
          <span
            dangerouslySetInnerHTML={{
              __html: aircraftTitle(this.props.request.emptyLegAircraft),
            }}
          />
        </span>
      );
    }
    return null;
  }

  get Modal() {
    if (this.props.request && !this.props.request.emptyLegAircraft) {
      return (
        <ModalComponent
          title={""}
          ref={(modal) => (this.modal = modal)}
          id={"success-modal"}
          options={{ clsPage: "", bgClose: false, escClose: false }}
          onClose={this.closeModal}
        >
          <AssignHeliControlComponent
            departure={this.props.request.departure}
            onModalClose={this.closeModal}
            onSubmit={this.onModalSubmit}
            currentSelectedHelicopters={this.props.selectedAircrafts}
            selectedHelicopters={this.state.selectedHelicopters}
            onItemChange={this.onItemChange.bind(this)}
            helicopters={this.props.request.helicopters ? this.props.request.helicopters : []}
          />
        </ModalComponent>
      );
    }
  }

  sumDistance() {
    const { legItems } = this.props;
    let allDistance = 0;
    legItems.map((leg, index) => {
      const distance = parseInt(
        distanceBetween(
          parseCoordinates(legItems[index].from_coords),
          parseCoordinates(legItems[index].to_coords),
        ).toFixed(0),
      );
      allDistance += distance;
    });
    if (allDistance) this.setState({ allDistance });
  }

  render() {
    const {
      isMapVisible,
      legItems: legs,
      banking,
      currencies,
      quoteToAmend,
      initialLegs,
    } = this.props;
    const currencyDefaultVal = () => {
      if (Object.keys(quoteToAmend).length) {
        return quoteToAmend.currency.currency;
      } else {
        return banking && banking.currency_data && banking.currency_data.currency;
      }
    };
    const legsControls = legs.map((leg, i) => {
      return (
        <div key={i} id={"leg-container-" + i} className={i > 0 ? "uk-margin-medium-top" : ""}>
          <FlightLegFormComponent
            title={`Leg ${i + 1}`}
            changeAction={(changedLeg) => this.updateLegModel(i, changedLeg)}
            leg={leg}
            initialLeg={initialLegs[i]}
            isFromDisabled={i > 0}
            index={i}
          />
        </div>
      );
    });
    const helicopterRequired = (val) => val && val.length > 0;

    return (
      <div>
        <h4
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("landing-site"),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("landingSiteAdvice"),
          }}
        />
        <Form
          ref={(node) => (this.form = node)}
          model={modelName}
          onSubmit={this.submit}
          onSubmitFailed={this.handleSubmitFailed.bind(this)}
          validators={{
            helicopters: { helicopterRequired },
          }}
        >
          <div id={"legs-section"}>{legsControls}</div>

          <PadSelectionMapComponentOld
            classNames={"uk-margin gh-pad-selection"}
            legs={legs}
            handleAssignPad={this.handleAssignPad.bind(this)}
            screenDimensions={this.props.screenDimensions}
            visible={isMapVisible}
            updateMap={this.state.updateMap}
            handleMarkerClick={() => {}}
          />

          <div className="uk-flex">
            <span
              style={{ top: "10px" }}
              className="uk-text-warning	 gh-danger-opostrov gh-vertical-middle uk-margin-small-right uk-position-relative"
              data-uk-icon="icon: warning"
            />
            <span
              className={"uk-margin-small"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("pinsOnMapAreOnlySuggestions"),
              }}
            />
          </div>

          <h4
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("quoteCommentLabel"),
            }}
          />

          <div className="uk-margin uk-position-relative gh-padding-small-bottom">
            <Control.textarea
              maxLength={config.quoteDescriptionMaxLength + 1}
              validators={{ max: max(config.quoteDescriptionMaxLength, false) }}
              changeAction={updateModel}
              updateOn="change"
              model={".description"}
              className="uk-textarea"
              rows="5"
              placeholder={staticService.findByAlias("additionalPlaceholder")}
            />
            <Errors
              model={".description"}
              show="touched"
              messages={{
                max: validationMessages().maxMessage(
                  config.quoteDescriptionMaxLength,
                  "characterLimitExceeded",
                ),
              }}
              wrapper={CustomValidateError}
            />
          </div>

          <EventDetailComponent
            visible={this.hasEvent}
            event={this.Event}
            showWarning={"uk-hidden"}
            showContent={""}
          />

          <h4
            className="uk-margin-small-bottom"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("assignAircraft"),
            }}
          />

          <div className={"gh-aircrafts-tags uk-margin-small-bottom"}>
            {checkObjectProp(this.props.request, "emptyLegAircraft", null) === null
              ? this.assignedHelicopters
              : this.bookingHelicopter}
          </div>

          {this.props.request && !this.props.request.emptyLegAircraft ? (
            <div>
              <a onClick={this.openModal}>+ {staticService.findByAlias("addAircraft")}</a>
            </div>
          ) : null}

          <Errors
            model={".helicopters"}
            show="touched"
            messages={{
              helicopterRequired: () => staticService.findByAlias("assignHelicopterAlert"),
            }}
            wrapper={CustomValidateError}
          />

          <h4
            className="uk-margin-remove gh-padding-large-top"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("priceDetails"),
            }}
          />

          <div
            className="uk-margin uk-padding uk-margin-remove-top gh-padding-small-top gh-margin-remove-bottom"
            data-uk-grid
          >
            <div
              className={
                "uk-width-1-1@s uk-width-1-4@m uk-position-relative uk-padding-remove gh-height-75"
              }
            >
              <div className="uk-flex">
                {/*<Control.select*/}
                {/*  className="uk-select uk-input gh-bg-white"*/}
                {/*  model=".currency"*/}
                {/*  style={{ width: 140, marginRight: 15 }}*/}
                {/*  changeAction={updateModel}*/}
                {/*  defaultValue={currencyDefaultVal()}*/}
                {/*  updateOn="change"*/}
                {/*>*/}
                {/*  {currencies &&*/}
                {/*    currencies.currencies_details &&*/}
                {/*    currencies.currencies_details.map((cur, index) => (*/}
                {/*      <option key={index} value={cur.code}>*/}
                {/*        {cur.symbol + " " + cur.code}*/}
                {/*      </option>*/}
                {/*    ))}*/}
                {/*</Control.select>*/}

                <div className="uk-flex uk-flex-middle uk-width-1-1">
                  <span className="uk-margin-small-right">{banking.currency_data.currency}</span>
                  <div className="gh-pound">
                    <Control.text
                      className="uk-input"
                      changeAction={this.updatePriceModel}
                      updateOn="change"
                      placeholder="0,000.00"
                      model={".view_price"}
                    />
                  </div>
                  {this.state.showFinalPrice ? (
                    <p>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("FinalPrice"),
                        }}
                      />
                      {this.props.price}
                      <span className="uk-margin-small-left">£</span>
                    </p>
                  ) : null}
                  <Control
                    type={"hidden"}
                    validators={{
                      required,
                      isPrice,
                      isGreaterThanZero,
                      max: max(config.stripeAmountLimit, true),
                    }}
                    model={".price"}
                  />
                  <Errors
                    model={".price"}
                    show={this.props.priceTouched}
                    messages={{
                      required: validationMessages().requiredMessage.bind(null, "Price"),
                      isPrice: validationMessages().isPriceMessage,
                      isGreaterThanZero: validationMessages().isPriceMessage,
                      max: validationMessages().maxMessage(
                        config.stripeAmountLimit,
                        "maxNumberValidatorMessage",
                        staticText.quotedPrice,
                      ),
                    }}
                    wrapper={CustomValidateError}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                "uk-padding-remove uk-width-1-1@s uk-width-auto@m gh-padding-left-20 uk-margin-remove-top"
              }
            >
              <span
                className="gh-vat-padding-10 gh-tax-line-height"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("vatInclusiveOf"),
                }}
              />
            </div>
            <div className="uk-padding-remove uk-position-relative uk-width-1-1@s uk-width-1-4@m gh-height-75 gh-margin-remove-top">
              <div>
                <Control.text
                  className="uk-input"
                  changeAction={this.updateVatModel}
                  updateOn={"change"}
                  placeholder="0,000.00"
                  model={".view_quote_vat"}
                />
                <div className={"uk-position-absolute gh-vat-position-error"}>
                  <Control
                    type={"hidden"}
                    validators={{
                      isNumber,
                      lessThan: lessThan(this.props.original_price),
                    }}
                    model={".quote_vat"}
                  />
                  <Errors
                    model={".quote_vat"}
                    show={this.props.vatTouched}
                    messages={{
                      isNumber: validationMessages().isNumberMessage,
                      lessThan: validationMessages().lessThanMessage.bind(
                        null,
                        staticService.findByAlias("vatLabel"),
                        staticService.findByAlias("quotePrice"),
                      ),
                    }}
                    wrapper={CustomValidateError}
                  />
                </div>
              </div>
            </div>
            <div
              className={"uk-padding-remove uk-width-1-1@s uk-width-auto@m uk-margin-remove-top"}
            >
              <span
                className={"gh-vat-padding-10 gh-tax-line-height"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("vatRelevantTax"),
                }}
              />
            </div>
          </div>
        </Form>

        {this.Modal}
      </div>
    );
  }

  onItemChange(id) {
    this.context.store.dispatch(actions.xor("quoteModel.helicopters", id));
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.context.store.dispatch(
      actions.change("quoteModel.helicopters", this.state.selectedHelicopters),
    );
    this.modal.hide();
  }
}

FlightFormComponent.defaultTypes = {
  isMapVisible: false,
  legs: [],
  request: {},
};

FlightFormComponent.propTypes = {
  isMapVisible: PropTypes.bool,
  initialLegs: PropTypes.array,
  onFormSubmit: PropTypes.func.isRequired,
  onFormSubmitFailed: PropTypes.func,
  legs: PropTypes.array,
};

FlightFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    quoteModel: state.quoteModel,
    banking: state.dashboard.banking,
    currencies: state.currencies,
    price: state.quoteModel.final_price,
    view_price: state.quoteModel.view_price,
    original_price: state.quoteModel.price,
    legItems: state.quoteModel.legs,
    request: state.requestDetail,
    quoteToAmend: state.quoteToAmend,
    screenDimensions: state.screenDimensions,
    selectedAircrafts: state.quoteModel.helicopters,
    newQuote: state.newQuote,
    priceTouched: state.forms.quoteModel.view_price.touched,
    vatTouched: state.forms.quoteModel.view_quote_vat.touched,
    description: state.forms.quoteModel.description.value,
  };
};

const COMPONENT = connect(mapStateToProps, { getCurrencies })(FlightFormComponent);
export { COMPONENT as FlightFormComponent };
