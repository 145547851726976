import * as types from "../../actions/actionTypes";

function auth(state = {}, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return action.user;
    case types.GET_ACCOUNT_DETAILS:
      return action.account;
    case types.LOGOUT_SUCCESS:
      return { logout: true };
    case types.CHECK_INVITATION_SUCCESS:
      return {
        invitation: { invitationValidity: true, user: action.response },
      };
    case types.CHECK_HASH_SUCCESS:
      return { hashCheck: action.response };
    case types.REGISTER_SUCCESS:
      return { user: action.user };
    case types.ACCOUNT_INVITATION_SUCCESS:
      return { account: action.user };
    case types.RECOVER_PASSWORD_SUCCESS:
      return action.response;
    case types.SENT_INVITATION_SUCCESS:
      return { invitationResponse: action.response };
    case types.UPLOAD_DOCUMENTS_SUCCESS:
      return { uploadResponse: action.response };
    case types.FORGOT_PASSWORD_SUCCESS:
      return { forgot: action.data };
    case types.UPDATE_PROFILE_SUCCESS:
      return { updatedProfile: "Profile updated with success" };
    case types.UPDATE_PASSWORD_SUCCESS:
      return { updatedPassword: "Password updated!" };
    case types.RESET_FORGOTTEN_PASSWORD:
      return {};
    default:
      return state;
  }
}

function validateUser(state = { user_validation: 0 }, action) {
  switch (action.type) {
    case types.ACCOUNT_REMOTE_VALIDATION:
      return action.user;
    case types.RESET_MODEL:
      return { user_validation: 0 };
    default:
      return state;
  }
}

function validateFirstStepUser(state = { user_validation: 0 }, action) {
  switch (action.type) {
    case types.ACCOUNT_REMOTE_FIRST_VALIDATION:
      return action.user;
    case types.RESET_MODEL:
      return { user_validation: 0 };
    default:
      return state;
  }
}

function bankAccount(state = { event: false }, action) {
  switch (action.type) {
    case types.UPDATE_BANK_ACCOUNT:
      return { event: true, data: action.response };
    default:
      return state;
  }
}

function cardUpdated(state = { event: false, showNotification: false, action: "default" }, action) {
  switch (action.type) {
    case types.UPDATE_CARD:
      return {
        event: true,
        data: action.response,
        showNotification: false,
        action: "update",
      };
    case types.SET_DEFAULT_CARD:
      return {
        event: true,
        data: action.response,
        showNotification: true,
        action: "default-card",
      };
    case types.DELETE_CARD:
      return {
        event: true,
        data: action.response,
        showNotification: true,
        action: "delete",
      };
    case types.RESET_CARD_UPDATE:
      return { event: false, showNotification: false, action: "default" };
    default:
      return state;
  }
}

function accountUpdated(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_ACCOUNT:
      return action.response;
    default:
      return state;
  }
}

function beta(state = false, action) {
  switch (action.type) {
    case types.BETA_VALIDATION_PASSED:
      return action.status;
    default:
      return state;
  }
}

function betaFailed(state = false, action) {
  switch (action.type) {
    case types.BETA_VALIDATION_FAILED:
      return action.status;
    default:
      return state;
  }
}

function userType(state = "broker", action) {
  switch (action.type) {
    case types.SET_AUTH_ROLE:
      return action.role;
    default:
      return state;
  }
}

function userOnline(state = null, action) {
  switch (action.type) {
    case types.SET_USER_ACCOUNT:
      return action.user;
    case types.UPDATE_ACCOUNT:
      return action.response;
    default:
      return state;
  }
}

export {
  auth,
  validateUser,
  validateFirstStepUser,
  bankAccount,
  accountUpdated,
  cardUpdated,
  userType,
  beta,
  betaFailed,
  userOnline,
};
