import React from "react";
import auth from "../../services/auth";
import { config } from "../../configs";
import { Redirect } from "react-router-dom";

const HomeComponent = () => {
  if (auth.isAuthenticated()) {
    return <Redirect to={config.redirectUserDashboard} />;
  } else {
    return <Redirect to={config.redirectToLogin} />;
  }
};

export default HomeComponent;
