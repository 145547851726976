import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import staticService from "../../../../../../services/static.service";
import { config, QUOTE_REJECTED, staticText } from "../../../../../../configs";
import PropTypes from "prop-types";
import { formatID } from "../../../../../../shared";
import Button from "../../../../../common/Button";
import "./QuoteGeneralInfo.css";
import QuoteGeneralInfoRequestDetailsModal from "../QuoteGeneralInfoRequestDetailsModal";
import stripeLogo from "../../../../../../assets/img/svg/stripe_logo_purple.svg";
import moment from "moment";
import Datetime from "react-datetime";
import tranformDateTimeToISO from "../../../../../../utils/tranformDateTimeToISO";

const QuoteGeneralInfo = (props) => {
  const {
    owner,
    status,
    booking_request,
    info,
    requestID,
    payment,
    expirationDate,
    extendExpirationDate,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [viewMode, setViewMode] = useState("days");

  const requestDetailsModalRef = useRef(null);
  const isQuoteExpired = moment().isAfter(expirationDate);
  const isAbleToEditExpirationDate = status === "open" || status === "booking_init";

  useEffect(() => {
    setSelectedDate(moment(info.expiration_time));
  }, []);

  const openModal = useCallback(() => {
    setModalOpen(true);
    requestDetailsModalRef.current && requestDetailsModalRef.current.open();
  }, [requestDetailsModalRef.current]);

  const handleChangeDate = (date) => {
    if (viewMode === "time" && date.isSame(moment(), "days")) {
      const invalidTime = moment(date).isBefore(moment());

      if (invalidTime) {
        setSelectedDate(moment());
      } else {
        setSelectedDate(date);
      }
    } else if (viewMode === "days" && date.isSame(moment(), "days")) {
      setSelectedDate(moment());
    } else {
      setSelectedDate(date);
    }
  };

  const isValidDateHandler = (current) =>
    current.isAfter(moment()) || current.isSame(moment(), "days");

  const handleClose = () => {
    setSelectedDate(moment(info.expiration_time));
  };

  const handleSave = () => {
    const date = selectedDate.format(config.dateFormat);
    const time = selectedDate.format(config.timeFormat);
    const transformedDate = tranformDateTimeToISO(date || "", time || "");

    extendExpirationDate(info.id, transformedDate, info);
  };

  const isSameDate = useMemo(() => {
    return (
      moment(selectedDate).format(config.timeFormat) ===
        moment(info.expiration_time).format(config.timeFormat) &&
      moment(selectedDate).format(config.dateFormat) ===
        moment(info.expiration_time).format(config.dateFormat)
    );
  }, [selectedDate, info.expiration_time]);

  return (
    <div
      className={`gh-quote-general-info-wrapper ${
        status && status === QUOTE_REJECTED ? "gh-quote-general-info-wrapper-rejected" : ""
      }`}
    >
      <div className="gh-quote-general-info-col">
        <div className="gh-quote-general-info-title">
          <p
            className="uk-margin-remove"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("requestPageID"),
            }}
          />
        </div>
        <div className="gh-quote-general-info-value">
          <p className="uk-margin-remove">
            <Button
              onClick={openModal}
              type="button"
              variant="text"
              className="gh-quote-general-info-link"
            >
              {formatID(booking_request && booking_request.id, config.prefixes.request)}
            </Button>
          </p>
        </div>
      </div>
      <div className="gh-quote-general-info-col">
        <div className="gh-quote-general-info-title">
          <p
            className="uk-margin-remove"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("Quoted"),
            }}
          />
        </div>
        <div className="gh-quote-general-info-value">
          <p className="uk-margin-remove">{owner ? owner : "---"}</p>
        </div>
      </div>
      <div className="gh-quote-general-info-col gh-quote-general-info-col-mobile-margin-top">
        <div className="gh-quote-general-info-title">
          <p
            className="uk-margin-remove"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("status"),
            }}
          />
        </div>
        <div className="gh-quote-general-info-value">
          <p className="uk-margin-remove">{staticText.quoteStatuses[status]}</p>
        </div>
      </div>

      {expirationDate ? (
        <div className="gh-quote-general-info-col gh-quote-general-info-col-mobile-margin-top --expiration-time">
          <div className="gh-quote-general-info-title">
            <p
              className="uk-margin-remove"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias(
                  isQuoteExpired ? "quoteExpiredOn" : "quoteExpiresOn",
                ),
              }}
            />
          </div>

          <div className="gh-quote-general-info-value">
            <div className="uk-flex gh-quote-general-info-wrapper-expiration uk-margin-remove">
              <span>{moment(selectedDate || "").format(config.dateFormat)}</span>
              <span>at</span>
              <span>{moment(selectedDate || "").format(config.timeFormat)}</span>
              <span>
                (UTC
                {moment(selectedDate || "").format("Z")})
              </span>

              {isAbleToEditExpirationDate ? (
                <Datetime
                  initialValue={moment()}
                  inputProps={{
                    className: "gh-booking-info-bar-edit-btn btn-style",
                  }}
                  value={selectedDate}
                  onChange={handleChangeDate}
                  isValidDate={isValidDateHandler}
                  timeConstraints={{ hours: { step: 1 }, minutes: { step: config.minuteStep } }}
                  timeFormat={config.timeFormat}
                  dateFormat={config.dateFormat}
                  initialViewMode={viewMode}
                  onNavigate={(view) => {
                    setViewMode(view);
                  }}
                  renderInput={(props) => (
                    <span className="gh-booking-info-bar-edit-btn btn-style" {...props}>
                      {selectedDate && isSameDate ? <span>Edit</span> : ""}
                    </span>
                  )}
                />
              ) : (
                ""
              )}
              {selectedDate && !isSameDate && (
                <div className="uk-flex">
                  <button
                    uk-icon="icon: check"
                    className="gh-booking-info-bar-identifier-btn btn-style"
                    style={{ color: "#1abc9c" }}
                    onClick={handleSave}
                  />

                  <button
                    uk-icon="icon: close"
                    className="gh-booking-info-bar-identifier-btn btn-style"
                    style={{ color: "#e74c3c" }}
                    onClick={handleClose}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {payment ? (
        <div className="gh-quote-general-info-col">
          <div className="gh-quote-general-info-title">
            <p
              className="uk-margin-remove"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("paymentMethodLabel"),
              }}
            />
          </div>
          <div className="gh-quote-general-info-value">
            <p className="uk-margin-remove">
              {payment.payment_method === 1 ? (
                <img src={stripeLogo} alt="Stripe" style={{ width: 40 }} />
              ) : (
                staticService.findByAlias("manualBankTransferLabel")
              )}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      {payment ? (
        <div className="gh-quote-general-info-col gh-quote-general-info-col-mobile-margin-top">
          <div className="gh-quote-general-info-title">
            <p
              className="uk-margin-remove"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("gridBookingsHeadePayed"),
              }}
            />
          </div>
          <div className="gh-quote-general-info-value --deposit">
            <p className="uk-margin-remove --percent">{info.quote_price.deposit_amount.percent}%</p>
            <p className="uk-margin-remove">{`[${info.currency.currency} ${info.quote_price.deposit_amount.amount}]`}</p>
          </div>
        </div>
      ) : (
        ""
      )}

      <QuoteGeneralInfoRequestDetailsModal
        requestDetailsModalRef={requestDetailsModalRef}
        requestID={requestID}
        modalOpen={modalOpen}
      />
    </div>
  );
};
QuoteGeneralInfo.propTypes = {
  owner: PropTypes.string,
  status: PropTypes.string,
  booking_request: PropTypes.object,
  info: PropTypes.object,
  requestID: PropTypes.number.isRequired,
  payment: PropTypes.object,
  expirationDate: PropTypes.string,
  extendExpirationDate: PropTypes.func,
};

export default QuoteGeneralInfo;
