import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { config, QUOTE_OPEN, QUOTE_REJECTED, QUOTE_UNSUCCESSFULL } from "../../../configs";
import { LandingItem } from "../../pages/user-online/common/landing-item.component";
import Auth from "../../../services/auth";
import { attachTooltip, checkObjectProp, aircraftTitle } from "../../../shared/";
import staticService from "../../../services/static.service";
import { generateAccountLocalization } from "../../../context";

class QuoteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.accountLocalization = generateAccountLocalization();
    this.state = {
      dialog: false,
      status: QUOTE_OPEN,
      quote: props.quote || {},
    };
    this.onSelect = this.onSelect.bind(this);
    this.toggleDialogState = this.toggleDialogState.bind(this);
    this.getAircraft = this.getAircraft.bind(this);
  }

  onSelect(message) {
    this.props.onCancel(message);
  }

  toggleDialogState(status) {
    this.props.action(status, this.props.quote);
  }

  actions(status) {
    switch (status) {
      case "open":
        return (
          <div className="uk-text-center uk-text-right@m">
            <div>
              <NavLink
                to={`${this.props.onSelectPath}/payment/${this.props.quote.id}`}
                className="uk-button uk-button-primary uk-margin-small-right uk-margin-small-bottom"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("acceptButtonLabel"),
                  }}
                />
              </NavLink>

              <button
                className="uk-button uk-button-danger  uk-margin-small-right uk-margin-small-bottom"
                type={"button"}
                onClick={this.toggleDialogState.bind(this, QUOTE_REJECTED)}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("decline"),
                  }}
                />
              </button>
            </div>
          </div>
        );
      case "booking_init":
        return (
          <div className="uk-align-center uk-align-right@m uk-margin-small-right uk-margin-top gh-long-button-block">
            <button
              className="uk-button uk-button-danger gh-full-width-btn gh-long-button"
              type={"button"}
              onClick={this.toggleDialogState.bind(this, QUOTE_UNSUCCESSFULL)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("withdrawBookingRequest"),
                }}
              />
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.quoteUpdate.status !== nextProps.quoteUpdate.status &&
      nextProps.quoteUpdate.status === "open"
    ) {
      this.toggleDialogState();
    }
  }

  getAircraft(aircraft, index) {
    const role = Auth.getMainRole();
    if (parseInt(aircraft.premium) > 0 && !this.props.skipPremium) {
      return (
        <a key={index} onClick={this.props.gallery.bind(this, aircraft)}>
          <span
            dangerouslySetInnerHTML={{
              __html: aircraftTitle(aircraft, this.props.showTail),
            }}
          />
          {index !== this.props.quote.aircrafts.length - 1 ? ", " : ""}
        </a>
      );
    }

    return (
      <span key={index}>
        <span
          dangerouslySetInnerHTML={{
            __html: aircraftTitle(aircraft, this.props.showTail),
          }}
        />
        {index !== this.props.quote.aircrafts.length - 1 ? ", " : ""}
      </span>
    );
  }

  showPrice(quote) {
    const role = Auth.getMainRole();
    const price = (quote) => {
      return quote.price;
    };
    const vat = (quote) => {
      return role === "operator" ? (
        <span>
          {" "}
          incl. <span>{quote.currency.currency} </span>
          {quote.quote_vat} of VAT
        </span>
      ) : (
        ""
      );
    };

    return (
      <div data-uk-grid className="uk-child-width-1-1">
        <div className="uk-width-1-1 uk-width-1-4@m gh-label-min">
          <b
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(role === "broker" ? "charterPrice" : "price"),
            }}
          />
          :
        </div>
        <div className="uk-width-1-1 uk-width-auto@m uk-margin-remove">
          <b>
            <span className="reset-span">{quote.currency.currency} </span>
          </b>
          <span>
            <b>{price(quote)}</b>
          </span>
          {vat(quote)}
        </div>
      </div>
    );
  }

  render() {
    const { visible, quote, relevantMessage } = this.props;
    return visible ? (
      <div
        className={`uk-background-default uk-padding-small uk-border-rounded ${
          relevantMessage ? " uk-margin-small-top" : " uk-margin-medium-top"
        }`}
      >
        <div data-uk-grid className={"gh-quote-detail"}>
          <div className="uk-width-1-1@s uk-width-1-2@m">
            {quote.is_favourite !== 0 ? (
              <div className="uk-width-1-4 gh-label-min">
                <a
                  {...attachTooltip(staticService.findByAlias("tooltipFavouriteMess"))}
                  className={"gh-star"}
                >
                  <span data-uk-icon={"icon: star"} />
                </a>
              </div>
            ) : null}

            {this.showPrice(quote)}

            <div data-uk-grid className="uk-child-width-1-1 uk-margin-small-top">
              <div className="uk-width-1-1 uk-width-1-4@m gh-label-min gh-request-titles">
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("helicopters"),
                  }}
                />
                :
              </div>
              <div className="uk-width-expand uk-margin-remove">
                {quote.aircrafts && quote.aircrafts.length > 0
                  ? quote.aircrafts.map((aircraft, index) => this.getAircraft(aircraft, index))
                  : "---"}
              </div>
            </div>
            {checkObjectProp(quote, "description", true) ? (
              <div data-uk-grid className="uk-child-width-1-1 uk-margin-small-top">
                <div className="uk-width-1-1 uk-width-1-4@m gh-label-min gh-request-titles">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("comment"),
                    }}
                  />
                  :
                </div>
                <div className="uk-width-expand uk-margin-auto-top">
                  {quote.description !== null ? <span>{quote.description}</span> : "---"}
                </div>
              </div>
            ) : null}

            <div data-uk-grid className="uk-child-width-1-1 uk-margin-small-top">
              <div className="uk-width-1-1 uk-width-1-4@m gh-label-min gh-request-titles">
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("departure"),
                  }}
                />
                :
              </div>
              <div className="uk-width-auto uk-margin-remove uk-position-relative uk-flex">
                {moment(quote.departure_time).format(this.accountLocalization.timeFormat)}
                <span style={{ marginLeft: 5, marginRight: 5 }}>(UTC{moment().format("Z")})</span>
                {moment(quote.departure_time).format("D MMMM, YYYY")}
              </div>
            </div>
            {/* <div data-uk-grid className="uk-child-width-1-1 uk-margin-small-top">
              <div className="uk-width-1-4 gh-label-min">
                <span dangerouslySetInnerHTML={{__html: staticService.findByAlias('eventDay')}}></span>:
              </div>
              <div className="uk-width-auto">
                {parseInt(quote.event_day) > 0 ? staticService.findByAlias('yes') : staticService.findByAlias('no')}
              </div>
            </div> */}
            {quote.pads && quote.pads.length
              ? quote.pads.map((pad) => <LandingItem pad={pad} key={pad.id} />)
              : null}
          </div>

          <div className="uk-width-1-1@s uk-width-1-2@m">
            {this.props.customAction ? this.props.customAction(quote) : this.actions(quote.status)}
          </div>
        </div>
      </div>
    ) : null;
  }
}

QuoteComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  quote: PropTypes.object.isRequired,
  onSelectPath: PropTypes.string,
  onCancel: PropTypes.func,
  customAction: PropTypes.func,
  action: PropTypes.func,
  gallery: PropTypes.func,
  skipPremium: PropTypes.bool,
  showTail: PropTypes.bool,
};

QuoteComponent.defaultProps = {
  skipPremium: false,
  showTail: true,
};

QuoteComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    quoteUpdate: state.quoteUpdate,
  };
}

const COMPONENT = connect(mapStateToProps)(QuoteComponent);
export { COMPONENT as QuoteComponent };
