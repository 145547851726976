import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  alertInstance,
  RouteWithSubRoutes,
  returnRouteUrl,
  TabComponent,
  storage,
} from "../../../../shared";
import {
  accountTabs,
  OPERATOR_ADMIN,
  OPERATOR_MAIN,
  OPERATOR_TEAM,
  teamTabs,
} from "../../../../configs";
import { VerificationComponent } from "../team-components";
import { ButtonWithBadgeComponent, MenuComponent, UserProfileComponent } from "../common/";
import auth from "../../../../services/auth";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";
import { ComponentAuthorization } from "../../../ComponentAuthorization";
import { IsVerifiedAuthorization } from "../../../IsVerifiedAuthorization";
import { BlockComponent } from "../../../common";
import { AccountOnlineLayout } from "../../../layouts";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class TeamComponent extends UserProfileComponent {
  constructor(props) {
    super(props);
    const items = accountTabs.filter(
      (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
    );
    this.state = {
      selectedTab: teamTabs[0],
      tabs: items,
    };
    this.getActive = this.getActive.bind(this);
    this.verifiedTabs = this.verifiedTabs.bind(this);
  }

  onTabClick(tab, ev) {
    ev.preventDefault();
    this.setState({ selectedTab: tab });
  }

  getActive(loopEl) {
    if (loopEl.alias === this.state.selectedTab.alias) {
      return " uk-active";
    }
    return "";
  }

  requestButtonProps() {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  get requestBtn() {
    return (
      <div className="uk-width-1-1">
        <ComponentAuthorization
          component={IsVerifiedAuthorization}
          componentProps={this.requestButtonProps()}
          roles={["broker_admin", "broker_team", "sole_trader"]}
        />
      </div>
    );
  }

  verifiedTabs(tabs) {
    if (auth.getUser().hasApiKey === false) {
      return tabs.filter((tab) => tab.alias !== "credentials");
    }
    return tabs;
  }

  render() {
    return (
      <AccountOnlineLayout activeRoute={this.props.match} pageTitle={this.props.title}>
        <section className={"team-page"}>
          <BlockComponent
            visible={true}
            parentProps={{
              className: "uk-section uk-padding-remove-bottom header-title",
            }}
          >
            <div className="uk-width-1-1">
              <h3
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("account"),
                }}
              />
            </div>
            {this.requestBtn}
            {Auth.getMainRole() === OPERATOR_ADMIN ||
            Auth.getMainRole() === OPERATOR_TEAM ||
            Auth.getMainRole() === OPERATOR_MAIN ? (
              <div className={"uk-margin-bottom"}>
                <ButtonWithBadgeComponent
                  route={"/marketplace"}
                  classes={["btn-pulse"]}
                  visible={true}
                  label={staticService.findByAlias("operatorMarketPlaceButton")}
                />
              </div>
            ) : (
              ""
            )}
          </BlockComponent>
          <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
            <TabComponent
              items={this.state.tabs}
              id={"profile-tab"}
              visible={true}
              tabProps={{ className: "uk-tab uk-margin" }}
              onClick={this.onTabClick.bind(this)}
              selected={this.state.selectedTab}
              linkType={"nav"}
            />
            <div data-uk-grid>
              <div className="uk-width-1-1@s uk-width-1-3@m">
                <div className="uk-width-3-4 uk-margin-small-top">
                  <MenuComponent items={this.verifiedTabs(teamTabs)} />
                </div>
              </div>
              <div className="uk-width-3-1@s uk-width-2-3@m">
                <Switch>
                  {this.props.routes &&
                    this.props.routes.map((route, i) => {
                      return (
                        <RouteWithSubRoutes
                          key={i}
                          title={route.title}
                          {...returnRouteUrl(route, this.props.match.url)}
                        />
                      );
                    })}
                  <Route path="/" component={VerificationComponent} />
                </Switch>
              </div>
            </div>
          </BlockComponent>
        </section>
      </AccountOnlineLayout>
    );
  }
}

TeamComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ accountUpdated }) {
  return {
    accountUpdated: accountUpdated,
  };
}

const COMPONENT = connect(mapStateToProps)(TeamComponent);
export { COMPONENT as TeamComponent };
