import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Control, Errors } from "react-redux-form";
import { distanceBetween, parseCoordinates } from "../../../../../../shared/";
import googleApi from "../../../../../../services/google.service";
import helicopterIcon from "../../../../../../assets/img/svg/helicopter.svg";
import { validationRules, validationMessages } from "../../../../../../configs";
import staticService from "../../../../../../services/static.service";
import { CustomValidateError } from "../../../../../../shared/";
import {
  LocationAutocompleteWrapper,
  LocationAutocompleteInput,
  LocationAutocompleteList,
} from "../../../../../common";
import generateLocationAdditionalInfo from "../../../../../../utils/generateLocationAdditionalInfo";

const { required } = validationRules;

class FlightLegFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      leg: props.leg,
      pads: [],
      modelName: "quoteModel",
    };

    this.updateLegOnSelect = this.updateLegOnSelect.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.fillCoordinates = this.fillCoordinates.bind(this);
    this.updateNextLeg = this.updateNextLeg.bind(this);
  }

  sortByShortestDistance(startPoint, collection) {
    let arr = [...collection];
    return arr.sort((a, b) => {
      const coords1 = { lat: a.latitude, lng: a.longitude };
      const coords2 = { lat: b.latitude, lng: b.longitude };
      return distanceBetween(startPoint, coords1) - distanceBetween(startPoint, coords2);
    });
  }

  componentDidMount() {
    for (let i = 0; i < this.props.legs.length; i++) {
      if (!this.props.legs[i].to_place_id && this.props.legs[i].to_location) {
        this.props.updateModelProp(
          `${this.state.modelName}.legs.[${i}].to_place_id`,
          this.props.legs[i].to_location.place_id,
        );
      }

      if (!this.props.legs[i].from_place_id && this.props.legs[i].from_location) {
        this.props.updateModelProp(
          `${this.state.modelName}.legs.[${i}].from_place_id`,
          this.props.legs[i].from_location.place_id,
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ leg: nextProps.legs[nextProps.index] });

    const { leg, legs } = this.props;

    if (leg && leg.to && leg.from) {
      const updates = [];

      for (let i = 0; i < legs.length; i++) {
        const { from_location, to_location } = legs[i];

        if (from_location && to_location) {
          updates.push(
            {
              key: "from",
              address: from_location,
              index: i,
            },
            {
              key: "to",
              address: to_location,
              index: i,
            },
          );
        }
      }

      updates.forEach(({ key, address, index }) => {
        setTimeout(() => this.updateLegOnSelect(key, address, index), index);
      });
    }
  }

  updateGeocodeModel(model, value) {
    this.props.updateModelProp(
      `${this.state.modelName}.legs.[${this.props.index}].${model}`,
      value,
    );
    this.props.updateModelProp(
      `${this.state.modelName}.legs.[${this.props.index}].${model}_info`,
      "",
    );
  }

  updateNextLeg(modelKey, address, index) {
    if (
      modelKey === "to" &&
      this.props.legs.length !== index + 1 &&
      this.props.legs[index + 1].from
    ) {
      this.updateLegOnSelect("from", address, index + 1);
    }
  }

  fillCoordinates(modelKey, address) {
    const model = `${this.state.modelName}.legs.[${this.props.index}].${modelKey}_coords`;
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateLegOnSelect(modelKey, address, this.props.index);
        // this.updateNextLeg(modelKey, address, this.props.index);
      });
    } else {
      this.props.touchAction(model);
    }
  }

  updateLegOnSelect(modelKey, address, index) {
    const model = { ...this.props.legs[index] };
    model[modelKey] = address.location || address.name;
    model[`${modelKey}_coords`] = `${address.latitude}|${address.longitude}`;
    model[`${modelKey}_source`] = address.source;
    model[`${modelKey}_info`] = generateLocationAdditionalInfo(address);
    model[`${modelKey}_place_id`] = address.place_id;
    model[`${modelKey}_coverage_status`] = address.coverage_status;
    delete model[`${modelKey}_location`];
    this.props.updateModelProp(`${this.state.modelName}.legs.[${index}]`, model);
  }

  onSelectLocation(handleClickOutside, modelKey, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address);
    } else {
      this.updateLegOnSelect(modelKey, address, this.props.index);
      // this.updateNextLeg(modelKey, address, this.props.index);
    }
  }

  render() {
    const { title, legs, initialLeg, index } = this.props;
    let distance = 0;
    if (legs[index]) {
      distance = distanceBetween(
        parseCoordinates(legs[index].from_coords),
        parseCoordinates(legs[index].to_coords),
      );
    }
    return (
      <div>
        <div className="gh-leg-title uk-text-uppercase">
          {title}
          <img src={helicopterIcon} className="uk-margin-small-left" alt="" width="24px" />
        </div>

        <div className="gh-leg-content">
          <div className="uk-margin uk-grid">
            <div className="uk-width-1-1@s uk-width-1-2@m">
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("from"),
                }}
              />
              <div className="uk-form-controls uk-position-relative">
                <LocationAutocompleteWrapper>
                  {({
                    locations,
                    search,
                    handleClickOutside,
                    loader,
                    onBlurPad,
                    locationValidationTemplate,
                  }) => {
                    return (
                      <div className={"uk-position-relative"}>
                        <LocationAutocompleteInput
                          className={["uk-input"]}
                          placeholder={"London"}
                          value={this.props.legs[this.props.index].from}
                          coverageWarning={this.props[this.props.index].from_coverage_status}
                          additionalInfo={this.props.legs[this.props.index].from_info}
                          onSelect={(location) => {
                            this.updateGeocodeModel("from", location);
                            return search(location);
                          }}
                          onBlur={onBlurPad}
                          loader={loader}
                          debounce={500}
                          model={`${this.state.modelName}.legs.[${this.props.index}].from`}
                        />
                        <LocationAutocompleteList
                          onSelect={this.onSelectLocation.bind(this, handleClickOutside, "from")}
                          locations={locations}
                        />
                        {locationValidationTemplate(
                          `${this.state.modelName}.legs.[${this.props.index}].from`,
                          `${this.state.modelName}.legs.[${this.props.index}].from`,
                        )}
                      </div>
                    );
                  }}
                </LocationAutocompleteWrapper>
              </div>
              <p>
                <label
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("OriginalFrom"),
                  }}
                />
                : <b className={"font-weight-500"}>{initialLeg.from}</b>
              </p>
              {initialLeg.from_details && (
                <p>
                  <label
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("privateSiteDetails"),
                    }}
                  />
                  : <b className={"font-weight-500"}>{initialLeg.from_details}</b>
                </p>
              )}
            </div>
            <div className="uk-width-1-1@s uk-width-1-2@m">
              <label
                className="uk-form-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("to"),
                }}
              />
              <div className="uk-form-controls">
                <LocationAutocompleteWrapper>
                  {({
                    locations,
                    search,
                    handleClickOutside,
                    loader,
                    onBlurPad,
                    locationValidationTemplate,
                  }) => {
                    return (
                      <div className={"uk-position-relative"}>
                        <LocationAutocompleteInput
                          className={["uk-input"]}
                          placeholder={"Paris"}
                          value={this.props.legs[this.props.index].to}
                          coverageWarning={this.props.legs[this.props.index].to_coverage_status}
                          additionalInfo={this.props.legs[this.props.index].to_info}
                          onSelect={(location) => {
                            this.updateGeocodeModel("to", location);
                            return search(location);
                          }}
                          onBlur={onBlurPad}
                          loader={loader}
                          debounce={500}
                          model={`${this.state.modelName}.legs.[${this.props.index}].to`}
                        />
                        <LocationAutocompleteList
                          onSelect={this.onSelectLocation.bind(this, handleClickOutside, "to")}
                          locations={locations}
                        />
                        {locationValidationTemplate(
                          `${this.state.modelName}.legs.[${this.props.index}].to`,
                          `${this.state.modelName}.legs.[${this.props.index}].to`,
                        )}
                      </div>
                    );
                  }}
                </LocationAutocompleteWrapper>
              </div>
              <p>
                <label
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("OriginalTo"),
                  }}
                />
                : <b className={"font-weight-500"}>{initialLeg.to}</b>
              </p>
              {initialLeg.to_details && (
                <p>
                  <label
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("privateSiteDetails"),
                    }}
                  />
                  : <b className={"font-weight-500"}>{initialLeg.to_details}</b>
                </p>
              )}
            </div>
          </div>
          <div className={"uk-margin-top uk-text-center uk-width-1-1@m"}>
            <span>
              {staticService.findByAlias("distance")}:{" "}
              <b className={"font-weight-500"}>{distance.toFixed(0)}</b> nautical miles
            </span>
          </div>
        </div>
      </div>
    );
  }
}

FlightLegFormComponent.propTypes = {
  initialLeg: PropTypes.object,
  index: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    legs: state.quoteModel.legs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (leg, index) => dispatch(actions.change(`quoteModel.legs[${index}]`, leg)),
      touchAction: (model) => dispatch(actions.setTouched(model)),
      updateModelProp: (prop, value) => dispatch(actions.change(prop, value)),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(FlightLegFormComponent);
export { COMPONENT as FlightLegFormComponent };
