import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../../../shared";
import { Control } from "../Control";
import { TaxInput } from "../Input";
import condStrings from "../../../../utils/condString";
import OperatorMarginSettingsModal from "./OperatorMarginSettingsModal";
import { OperatorMarginContext } from "./OperatorMargin";
import Popup from "reactjs-popup";
import { useCalculatorContext } from "../../CalculatorContext";

import wheelSettings from "../../../../assets/img/svg/wheelSettings.svg";

const OperatorMarginContent = (props) => {
  const {
    currency,
    quotePriceCalculated,
    totalCostValue,
    onChangeTotalCost,
    quoteCostsTotal,
    isFormSubmitting,
    className,
    percent,
    setPercent,
    sum,
    setSum,
    isSticky,
    aircrafts,
  } = props;

  const { demoVersion } = useCalculatorContext();

  const { loadingCompanyDefaultsSettings, companyDefaultSettings } =
    React.useContext(OperatorMarginContext);

  const [settingsModalVisible, setSettingsModalVisible] = React.useState(false);

  return (
    <React.Fragment>
      <div className={condStrings("calculator__operator-margin", className)}>
        <div className="operator-margin__section">
          <h4 className="operator-margin__subtitle">Quote Price Calculated</h4>
          <h2 className="operator-margin__title">
            {currency} {formatNumber(quotePriceCalculated)}
          </h2>
        </div>
        <div className="operator-margin__section">
          <h4 className="operator-margin__subtitle">Operator Margin</h4>
          <Control name="operator_margin">
            <TaxInput
              total={totalCostValue}
              onValueChange={onChangeTotalCost}
              currency={currency}
              percent={percent || companyDefaultSettings?.company_margin}
              setPercent={setPercent}
              sum={sum}
              setSum={setSum}
              isSticky={isSticky}
              confirmOnBlur
              hideActions
            />
          </Control>
        </div>
        <div className="operator-margin__section">
          <h4 className="operator-margin__subtitle">Total Quote Price</h4>
          <h2 className="operator-margin__title">
            {currency} {formatNumber(quoteCostsTotal)}
          </h2>
        </div>
        {!demoVersion ? (
          <div className="operator-margin__section operator-margin__section-actions">
            <button
              type="submit"
              disabled={isFormSubmitting}
              className="calculator-reset-btn calculator-submit-btn"
            >
              Use price to quote
              {isFormSubmitting && (
                <div data-uk-spinner="ratio: 0.7" className="uk-margin-small-left" />
              )}
            </button>

            <Popup
              trigger={
                <button
                  type="button"
                  disabled={isFormSubmitting || loadingCompanyDefaultsSettings}
                  className="calculator-reset-btn operator-margin__settings-btn"
                  onClick={() => setSettingsModalVisible(true)}
                >
                  <img src={wheelSettings} alt="settings" />
                </button>
              }
              on="hover"
              position={["top right"]}
              keepTooltipInside={true}
            >
              Price calculator default values
            </Popup>
          </div>
        ) : (
          <div className="operator-margin__section operator-margin__section-actions">
            <Popup
              trigger={
                <button
                  type="button"
                  disabled={isFormSubmitting || loadingCompanyDefaultsSettings}
                  className="calculator-reset-btn operator-margin__settings-btn"
                  onClick={() => setSettingsModalVisible(true)}
                >
                  <img src={wheelSettings} alt="settings" />
                </button>
              }
              on="hover"
              position={["top right"]}
              keepTooltipInside={true}
            >
              Price calculator default values
            </Popup>
          </div>
        )}
      </div>
      <OperatorMarginSettingsModal
        visible={settingsModalVisible}
        setVisible={setSettingsModalVisible}
        aircrafts={aircrafts}
      />
    </React.Fragment>
  );
};

OperatorMarginContent.propTypes = {
  currency: PropTypes.string,
  quotePriceCalculated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalCostValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeTotalCost: PropTypes.func,
  quoteCostsTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isFormSubmitting: PropTypes.bool,
  className: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setPercent: PropTypes.func,
  sum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSum: PropTypes.func,
  isSticky: PropTypes.bool,
  aircrafts: PropTypes.array,
};

OperatorMarginContent.defaultProps = {};

export default OperatorMarginContent;
