import PropTypes from "prop-types";
import React from "react";

export default function QuotePriceRepositioning(props) {
  const { percentage } = props;

  return (
    <div className="quote-price__repositioning">
      <div className="quote-price__repositioning-label">
        Helicopter (re)positioning cost out of total
      </div>
      <div className="quote-price__repositioning-percentage">
        <div className="percentage__progress-label">{percentage}%</div>
        <div className="percentage__progress">
          <div className="percentage__progress-bar" style={{ width: `${Number(percentage)}%` }} />
        </div>
      </div>
    </div>
  );
}

QuotePriceRepositioning.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
