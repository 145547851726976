import React from "react";
import calculations from "../services/calculations";

export default function useLegLocation({ legPath, getValues, updateForm }) {
  const [, aircraftIndex] = React.useMemo(() => legPath.split("."), [legPath]);

  const onClientLocationUpdated = React.useCallback(
    (direction, location, aircraft, legId) => {
      const legs = getValues(`aircrafts.${aircraft}.legs`);

      const legIndex = legs.findIndex((leg) => leg.id === legId);

      if (direction === "from") {
        const repositionigFromIndex = legs.findIndex((l) => l.home_base_repositioning === "from");

        if (repositionigFromIndex !== -1) {
          if (legIndex - 1 === repositionigFromIndex) {
            updateForm([[`aircrafts.${aircraft}.legs.${repositionigFromIndex}.to`, location]]);

            setTimeout(() => {
              const values = calculations.leg.flight(legs[legIndex - 1]);

              updateForm(
                Object.entries(values).map(([key, value]) => [
                  `aircrafts.${aircraft}.legs.${repositionigFromIndex}.${key}`,
                  value,
                ]),
              );
            }, 10);
          }
        }
      }

      if (direction === "to") {
        const repositionigToIndex = legs.findIndex((l) => l.home_base_repositioning === "to");

        if (repositionigToIndex !== -1) {
          if (legIndex + 1 === repositionigToIndex) {
            updateForm([[`aircrafts.${aircraft}.legs.${repositionigToIndex}.from`, location]]);

            setTimeout(() => {
              const values = calculations.leg.flight(legs[legIndex + 1]);
              updateForm(
                Object.entries(values).map(([key, value]) => [
                  `aircrafts.${aircraft}.legs.${repositionigToIndex}.${key}`,
                  value,
                ]),
              );
            }, 10);
          } else if (legIndex === repositionigToIndex - 2) {
            updateForm([[`aircrafts.${aircraft}.legs.${repositionigToIndex - 1}.from`, location]]);
            setTimeout(() => {
              const values = calculations.leg.flight(legs[0]);
              updateForm(
                Object.entries(values).map(([key, value]) => [
                  `aircrafts.${aircraft}.legs.${repositionigToIndex - 1}.${key}`,
                  value,
                ]),
              );
            }, 10);
          }
        }
      }
    },
    [getValues, updateForm],
  );

  const updateClientLegInOtherAircrafts = React.useCallback(
    (direction, location, aircraft, legId) => {
      const legs = getValues(`aircrafts.${aircraft}.legs`);
      const clientLegIndex = legs.findIndex((leg) => leg.id === legId);

      if (clientLegIndex !== -1) {
        updateForm([[`aircrafts.${aircraft}.legs.${clientLegIndex}.${direction}`, location]]);
      }
    },
    [getValues, updateForm],
  );

  const onLocationChange = React.useCallback(
    (direction, location) => {
      const aircrafts = getValues("aircrafts");
      const updatingLeg = getValues(legPath);

      if (!isNaN(updatingLeg.id)) {
        for (let i = 0; i < aircrafts.length; i++) {
          onClientLocationUpdated(direction, location, i, updatingLeg.id);

          if (i !== aircraftIndex) {
            updateClientLegInOtherAircrafts(direction, location, i, updatingLeg.id);
          }
        }
      }
    },
    [getValues, legPath, onClientLocationUpdated, updateClientLegInOtherAircrafts, aircraftIndex],
  );

  return { onLocationChange };
}
