import React from "react";
import PropTypes from "prop-types";
import "./CardsListingOverlay.css";

const CardsListingOverlay = ({ message, show }) => {
  if (!show) return null;
  return (
    <div className="gh-cards-overlay">
      <p className="inner-message">{message}</p>
    </div>
  );
};

CardsListingOverlay.propTypes = { message: PropTypes.string, show: PropTypes.bool };
CardsListingOverlay.defaultProps = { show: true };
export default CardsListingOverlay;
