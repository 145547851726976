import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "react-redux-form";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  ComplexTableComponent,
  formatID,
  ModalComponent,
  prepareQueryStringFromObj,
} from "../../../../../../shared";
import {
  getBrokerRequestDetailSuccess,
  getDeclineReasons,
  getOperatorRequestDetail,
  getRemoveFilters,
  getRequests,
  getRequestsSuccess,
  resetQuoteDetail,
  sendDeclineReasons,
  sendRequestDeclineReasons,
  setDefaultRequestsData,
  SORT_GRID,
} from "../../../../../../actions";
import { PaginationService } from "../../../../../../services";
import { config, operatorMarketRequestConfig } from "../../../../../../configs";
import GetHeliEvents from "../../../../../../services/event.service";
import { DeclineReasons, LegRequests } from "../../../common/";
import staticService from "../../../../../../services/static.service";
import trashIcon from "../../../../../../assets/img/svg/trash.svg";
import auth from "../../../../../../services/auth";
import MarketplaceRequestQuotedStatus from "./MarketplaceRequestQuotedStatus";
import amendedImg from "../../../../../../assets/img/amended.png";
import MarketplaceFlag from "./MarketplaceFlag/MarketplaceFlag";
import envelope from "../../../../../../assets/img/envelope.png";
import clsx from "clsx";
import { showNotification } from "../../../../../../utils/showNotification";
import Tooltip from "../../../../../common/Tooltip";

const modelName = "operatorMarketplaceRequestsFiltersComponent";
const modelDefault = {
  sortedBy: "asc",
  orderBy: "departure",
  activity: "own",
  date: null,
  date_end: "",
  date_start: "",
  disableBtn: false,
  fleetCheck: true,
  lat: null,
  lng: null,
  near_by: "",
  notQuotedOnly: false,
  radius: "",
  redirect: false,
  declined: true,
};

export const defaultFilters = [
  { key: "activity", value: "own" },
  { key: "fleetCheck", value: 1 },
  { key: "notQuotedOnly", value: 0 },
  { key: "declined", value: 1 },
  { key: "near_by", value: "" },
  { key: "radius", value: "" },
  { key: "disableBtn", value: 0 },
  { key: "date_end", value: "" },
  { key: "date_start", value: "" },
  { key: "refreshCollection", value: 0 },
  { key: "lat", value: "" },
  { key: "lng", value: "" },
];

class OperatorMarketPlaceRequestsListComponent extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      view: "market",
      defaultOrder: {},
      modalVisibility: false,
      selectedLegs: [],
      request: {},
      form: {},
      tabs: [
        { title: "Market", view: "market" },
        { title: "Empty legs", view: "empty-legs" },
      ],
      filters: [],
      filterActive: false,
    };
    this.catchSuccessResponse = this.catchSuccessResponse.bind(this);
    GetHeliEvents.setEvent("requests", this.catchSuccessResponse);

    this.paginationService = new PaginationService(
      context.store.dispatch,
      getRequests,
      config.paginationLimit,
      defaultFilters,
    );

    this.paginationService.setProp("columns", operatorMarketRequestConfig.columns);
    this.paginationService.setProp(
      "headers",
      staticService.setGridHeaders(operatorMarketRequestConfig),
    );

    this.legTemplate = this.legTemplate.bind(this);
    this.selectedView = this.selectedView.bind(this);
    this.submitDecline = this.submitDecline.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.context.store.dispatch(resetQuoteDetail());
    if (!this.props.history.location.state) this.props.setDefaultRequestsData({});
  }

  componentDidMount() {
    const { updateModel } = this.props;
    updateModel("isConcat", true);

    this.paginationService.loadData().then((res) => {
      const sort = {
        field: res.default_filters.orderBy,
        sortOrder: res.default_filters.sortedBy,
      };

      this.setState({
        sortOrder: sort,
      });

      this.context.store.dispatch({
        type: SORT_GRID,
        sort: sort,
      });
    });
  }

  legTemplate(legs) {
    return (
      <a className={"uk-link"} onClick={this.openModal.bind(this, legs)}>
        <span className={"uk-label uk-label-success"}>{legs.length}</span>
      </a>
    );
  }

  get defaultFilters() {
    this.props.getRequests(1, defaultFilters).then(() => {
      this.props.getRemoveFilters(modelDefault);
    });
    this.props.updateModel(modelName, modelDefault);
  }

  openModal(legs) {
    this.modal.open();
    this.setState({ selectedLegs: legs });
  }

  closeModal() {
    this.modal.hide();
    this.setState({ selectedLegs: [] });
  }

  openDeclineModal(request) {
    this.props.getDeclineReasons("booking_request").then(() => this.declineModal.open());
    this.setState({ request: request });
  }

  closeDeclineModal() {
    this.declineModal.hide();
    this.setState({ redirect: true });
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  selectedView(view, ev) {
    ev.preventDefault();
    this.setState({ view: view });
  }

  prepareRows(items) {
    return items.map((item) => {
      item.displayID = formatID(item.id, config.prefixes.request);
      item.totalLegs = this.legTemplate(item.legs);
      item.on = moment(item.on).format("DD MMMM, YYYY");
      item.created_at = (
        <div className="gh-marketplace-posted-on">
          {moment(item.created_at).format("DD MMMM, YYYY")}
          <span className="gh-marketplace-relevant-operators">
            {item.relevant_operators_sent_message || ""}
          </span>
        </div>
      );
      item.quotes_per_company =
        parseInt(item.quotes_per_company) === 0 ||
        item.is_need_more_information_set ||
        item.quotes_per_company === "Unquoted" ||
        item.quotes_per_company === "Declined" ? (
          item.is_declined ? (
            staticService.findByAlias("declinedStatus")
          ) : item.is_need_more_information_set ? (
            staticService.findByAlias("preQuote")
          ) : (
            staticService.findByAlias("notQuotedStatus")
          )
        ) : (
          <MarketplaceRequestQuotedStatus quotesNumber={item.quotes} requestId={item.id} />
        );
      return item;
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const {
      defaultRequests,
      history: { location },
    } = nextProps;

    if (JSON.stringify(this.props.itemsData) !== JSON.stringify(nextProps.itemsData)) {
      if (nextProps.reduxFilters.refreshCollection) {
        GetHeliEvents.trigger("requests-refresh-collection", nextProps.itemsData.length);
        this.paginationService.reloadItems();
        this.context.store.dispatch(
          actions.change("operatorMarketplaceRequestsFiltersComponent.refreshCollection", false),
        );
        this.paginationService.setProp(
          "filters",
          prepareQueryStringFromObj(nextProps.reduxFilters, { omitKeys: ["page"] }),
        );
      }

      if (
        location.state &&
        !location.state.emptyLeg &&
        location.state.anchorId &&
        defaultRequests.data &&
        defaultRequests.data.length
      ) {
        const currentPage = defaultRequests.data.length / 10;

        this.paginationService.populateItems(
          defaultRequests.data,
          this.prepareRows.bind(this),
          this.props.isConcat,
        );

        setTimeout(() => {
          this.paginationService.setProp("page", currentPage);
        }, 1000);

        setTimeout(() => {
          this.context.store.dispatch({
            type: SORT_GRID,
            sort: defaultRequests.sort,
          });
          this.paginationService.setSortingParameters({
            column: defaultRequests.sort.field,
            order: defaultRequests.sort.sortOrder,
          });
        }, 500);
      } else {
        this.paginationService.populateItems(
          nextProps.itemsData,
          this.prepareRows.bind(this),
          this.props.isConcat,
        );
      }
    }

    this.setState({ form: nextProps.form });
    if (
      (nextProps.itemsData && nextProps.itemsData.length < 1) ||
      (defaultRequests && defaultRequests.data && defaultRequests.data.length < 1)
    ) {
      this.setEmptyRowMessage;
    }
  }

  handleSorting(column, order) {
    this.context.store.dispatch(actions.change("isConcat", false));
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.paginationService.loadData().then(() => {
      this.context.store.dispatch(actions.change("isConcat", true));
    });
  }

  onRowClick(request, index) {
    this.context.store.dispatch(getBrokerRequestDetailSuccess(request));
    const list = [...this.paginationService.items];
    list[index].highlight = list[index].highlight ? false : list[index].highlight;
    this.props.setDefaultRequestsData({
      ...this.props.requestObj,
      data: list,
      sort: this.props.sortOrder,
    });
  }

  tableAction(index) {
    const currentRequest = this.paginationService.items[index];
    const showTrashIcon =
      auth.getCompanyFromLocalStorage().verified !== 0 &&
      !this.paginationService.items[index].is_declined &&
      typeof this.paginationService.items[index].quotes_per_company !== "object";
    const showEnvelope = currentRequest.has_broker_responded;

    const action = (
      <div className="gh-table-actions gh-marketplace-table-action">
        <Link
          className="uk-button uk-button-link uk-padding-remove gh-min-width-small gh-marketplace-table-action_item"
          to={{
            pathname: `/marketplace/trip/request/${currentRequest.id}`,
            state: { originPath: "/marketplace" },
          }}
          onClick={this.onRowClick.bind(this, currentRequest, index)}
        >
          View
        </Link>
        <MarketplaceFlag request={currentRequest} />
        <button
          style={{ minWidth: 80 }}
          className={clsx(
            "uk-button uk-button-link uk-padding-remove uk-text-danger gh-marketplace-table-action_item",
            {
              "gh-invisible": !showTrashIcon,
            },
          )}
          onClick={this.openDeclineModal.bind(this, this.paginationService.items[index])}
        >
          <img src={trashIcon} alt="icon" />
        </button>
        {/*<Link*/}
        {/*  className={clsx("gh-marketplace-envelope gh-marketplace-table-action_item", {*/}
        {/*    "gh-invisible": !showEnvelope,*/}
        {/*  })}*/}
        {/*  to={{*/}
        {/*    pathname: `/marketplace/trip/request/${currentRequest.id}`,*/}
        {/*    state: { originPath: "/marketplace" },*/}
        {/*  }}*/}
        {/*  onClick={this.onRowClick.bind(this, currentRequest, index)}*/}
        {/*>*/}
        {/*  <img src={envelope} alt="envelope" />*/}
        {/*</Link>*/}

        <Tooltip
          pos="top-left"
          refEl="self"
          contentClass="tooltip-content-fixed-tabs"
          wrapperClassName="gh-marketplace-wrapper-envelope-tooltip"
          trigger={
            <Link
              className={clsx("gh-marketplace-envelope gh-marketplace-table-action_item", {
                "gh-invisible": !showEnvelope,
              })}
              to={{
                pathname: `/marketplace/trip/request/${currentRequest.id}`,
                state: { originPath: "/marketplace" },
              }}
              onClick={this.onRowClick.bind(this, currentRequest, index)}
            >
              <img src={envelope} alt="envelope" />
            </Link>
          }
        >
          {staticService.findByAlias("envelopeTooltip")}
        </Tooltip>

        {this.paginationService.items[index].been_amended && (
          <div className="gh-wrapper-badge">
            <span className="badge">
              <img src={amendedImg} style={{ width: 69 }} alt="badge" />
            </span>
          </div>
        )}
      </div>
    );
    return {
      desktop: <td style={{ verticalAlign: "middle", position: "relative" }}>{action}</td>,
      mobile: action,
    };
  }

  get setEmptyRowMessage() {
    const button = {
      label: "Remove all filters",
      link: "/marketplace",
      onClick: () => this.defaultFilters,
    };
    this.setState({
      customProps: {
        emptyRowButton: button,
      },
    });
  }

  submitDecline(clb) {
    const { form, request } = this.state;
    const { sendDeclineReasons, resetModel, updateModel } = this.props;
    const reason = form.reason.value === "Other info" ? form.other_info.value : form.reason.value;

    sendDeclineReasons(request.id, {
      reason: reason,
    }).then(() => {
      updateModel("isConcat", false);

      this.paginationService.setProp("page", 1);
      this.paginationService.setProp("filters", defaultFilters);
      this.paginationService.loadData().then(() => {
        resetModel("declineRequestModel");

        updateModel("isConcat", true);
        this.closeDeclineModal();
        showNotification("declineRequestModalSuccess", "success");

        clb(false);
      });
    });
  }

  componentWillUnmount() {
    this.props.getRequestsSuccess({});
  }

  render() {
    const { history, setDefaultRequestsData, sortOrder, defaultRequests } = this.props;
    const { location } = history;

    if (location.state && location.state.anchorId && !location.state.emptyLeg) {
      const element = document.getElementById(location.state.anchorId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        history.replace({ ...history.location, state: undefined });
        setDefaultRequestsData({});
      }
    }

    return (
      <div className={"uk-overflow-auto uk-margin-bottom"}>
        <ModalComponent
          title={staticService.findByAlias("requestLegsModalTItle")}
          ref={(modal) => (this.modal = modal)}
          id={"legs"}
          onClose={this.closeModal.bind(this)}
          modalClass="legs-modal_component"
        >
          <LegRequests legs={this.state.selectedLegs} />
        </ModalComponent>
        <ModalComponent
          title={staticService.findByAlias("operatorDeclineModalTitle")}
          ref={(declineModal) => (this.declineModal = declineModal)}
          id={"reasons"}
          onClose={this.closeDeclineModal.bind(this)}
          modalClass="gh-decline-modal"
        >
          <DeclineReasons
            reasons={this.props.reasons}
            form={this.state.form}
            submitDecline={this.submitDecline}
          />
        </ModalComponent>
        <ComplexTableComponent
          loadMore={this.paginationService.loadMore.bind(this.paginationService)}
          rows={this.paginationService.items}
          headers={this.paginationService.headers}
          columns={this.paginationService.columns}
          showActionColumn={1}
          showLoader={this.paginationService.showLoaderBtn}
          showMoreText={staticService.findByAlias("showMore")}
          actionElements={this.tableAction.bind(this)}
          // selectedOrder={(defaultRequests && defaultRequests.sort) || sortOrder}
          selectedOrder={this.state.sortOrder}
          sortChange={this.handleSorting.bind(this)}
          isLoading={true}
          {...this.state.customProps}
        />
      </div>
    );
  }
}

OperatorMarketPlaceRequestsListComponent.contextTypes = {
  store: PropTypes.object,
};

OperatorMarketPlaceRequestsListComponent.propTypes = {
  sendDeclineReasons: PropTypes.func,
  resetModel: PropTypes.func,
  updateModel: PropTypes.func,
  history: PropTypes.object,
  sortOrder: PropTypes.object,
  itemsData: PropTypes.array,
  reduxFilters: PropTypes.object,
  defaultRequests: PropTypes.object,
  isConcat: PropTypes.bool,
  form: PropTypes.object,
  reasons: PropTypes.array,
  getRequestsSuccess: PropTypes.func,
  setDefaultRequestsData: PropTypes.func,
  requestObj: PropTypes.object,
  getDeclineReasons: PropTypes.func,
  getRemoveFilters: PropTypes.func,
  filters: PropTypes.object,
  getRequests: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    requestObj: state.requests,
    itemsData: state.requests.data,
    defaultRequests: state.defaultRequests,
    reduxFilters: state.operatorMarketplaceRequestsFiltersComponent,
    defaultFiltersOP: state.requests.default_filters,
    reasons: state.requests.reasons,
    isConcat: state.isConcat,
    form: state.forms.declineRequestModel,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      getRequests: getRequests,
      getDeclineReasons: getDeclineReasons,
      sendDeclineReasons: sendDeclineReasons,
      resetReasons: sendRequestDeclineReasons,
      getRemoveFilters: getRemoveFilters,
      resetModel: (model) => dispatch(actions.reset(model)),
      getOperatorRequestDetail,
      setDefaultRequestsData,
      getRequestsSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorMarketPlaceRequestsListComponent);
export { COMPONENT as OperatorMarketPlaceRequestsListComponent };
