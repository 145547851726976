import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import marker from "../../../../../assets/img/marker.svg";
import staticService from "../../../../../services/static.service";
import { renderTime } from "../../../../../shared";
import info from "../../../../../assets/img/svg/info_light.svg";
import oneWay from "../../../../../assets/img/svg/flight_one_way.svg";
import returnFlight from "../../../../../assets/img/svg/flight_return.svg";
import multiLegs from "../../../../../assets/img/svg/flight_multi_legs.svg";

// eslint-disable-next-line react/display-name
const BrokerQuoteInfo = forwardRef(({ quote, changeSliderHeli, showPad, price, selectedHeli }) => {
  const prepareFromPad = (pad, type) => {
    if (!showPad) return "";
    return showPad(type, pad.from_name, pad.from_coords);
  };

  const prepareToPad = (pad, type) => {
    if (!showPad) return "";
    return showPad(type, pad.to_name, pad.to_coords);
  };

  return (
    <div data-uk-grid className="uk-grid-small gh-grid-small gh-padding-quote-content">
      <div className="uk-width-1-3 uk-width-1-1@s">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1 uk-width-1-3@s">
            <span className="gh-quote-label">{staticService.findByAlias("price")}</span>
          </div>
          <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
            <span className="gh-quote-text">{price}</span>
          </div>
        </div>
      </div>
      <div className="uk-width-1-2 uk-width-1-1@s gh-first-element-margin">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1 uk-width-1-3@s">
            <span className="gh-quote-label">{staticService.findByAlias("helicopter")}</span>
          </div>
          <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
            <span className="gh-quote-text gh-cursor">
              {quote.aircrafts && quote.aircrafts.length === 1 && (
                <span>{quote.aircrafts && quote.aircrafts[0].aircraft_model}</span>
              )}
              {quote.aircrafts &&
                quote.aircrafts.length !== 1 &&
                quote.aircrafts.map((heli, heliIndex) => (
                  <span
                    key={heliIndex}
                    className="gh-heli-label"
                    onClick={() => {
                      changeSliderHeli(heliIndex);
                    }}
                  >
                    {heliIndex === 0 || heliIndex === quote.aircrafts.length ? (
                      ""
                    ) : (
                      <span className="gh-text-green"> + </span>
                    )}
                    <span className={selectedHeli === heliIndex ? "gh-heli-active" : ""}>
                      {heli.aircraft_model}
                    </span>
                  </span>
                ))}
            </span>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 gh-margin-ex-small-top">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1 uk-width-1-3@s">
            <span className="gh-quote-label">
              {staticService.findByAlias("proposedTakeOffSite")}
            </span>
          </div>
          <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
            <div className="gh-quote-pad">
              <img className="gh-quote-pad-marker" src={marker} alt="marker" />
              <span
                className="gh-quote-text"
                onClick={() =>
                  prepareFromPad(
                    quote.pads && quote.pads[0],
                    staticService.findByAlias("proposedTakeOffSite"),
                  )
                }
              >
                {quote.pads && quote.pads[0] && quote.pads[0].from_name}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 gh-margin-ex-small-top">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1 uk-width-1-3@s" />
          {quote.flight_type === 1 ? (
            <div data-uk-grid style={{ alignItems: "center" }} className="uk-grid-small ">
              <div className="status-flight-line">
                <img src={oneWay} alt="one-way" />
              </div>
              <span>{staticService.findByAlias("oneWay")}</span>
            </div>
          ) : quote.flight_type === 2 ? (
            <div data-uk-grid style={{ alignItems: "center" }} className="uk-grid-small">
              <div className="status-flight-line">
                <img src={returnFlight} alt="return" />
              </div>
              <span>{staticService.findByAlias("return")}</span>
            </div>
          ) : (
            <div data-uk-grid style={{ alignItems: "center" }} className="uk-grid-small">
              <div className="status-flight-line">
                <img src={multiLegs} alt="multi-legs" />
              </div>
              <span>{staticService.findByAlias("multiLeg")}</span>
            </div>
          )}
        </div>
      </div>
      <div className="uk-width-1-1 gh-margin-ex-small-top">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1 uk-width-1-3@s">
            <span className="gh-quote-label">
              {staticService.findByAlias("proposedLandingSite")}
            </span>
          </div>
          <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
            <div className="gh-quote-pad">
              <img className="gh-quote-pad-marker" src={marker} alt="marker" />
              <span
                className="gh-quote-text"
                onClick={() =>
                  prepareToPad(
                    quote.pads && quote.pads[quote.pads.length - 1],
                    staticService.findByAlias("proposedLandingSite"),
                  )
                }
              >
                {quote.pads &&
                  quote.pads[quote.pads.length - 1] &&
                  quote.pads[quote.pads.length - 1].to_name}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 gh-margin-ex-small-top">
        <div data-uk-grid className="uk-grid-small">
          <div className="uk-width-1-1 uk-width-1-3@s">
            <span className="gh-quote-label">
              {staticService.findByAlias("estimateFlightDuration")}
            </span>
          </div>
          <div className="uk-width-1-1 uk-width-2-3@s gh-margin-one">
            <span className="gh-quote-text">{renderTime(quote.time_max, quote.is_refueling)}</span>
          </div>
        </div>
      </div>
      {quote.padsDiffer && (
        <div
          className="uk-width-1-1 gh-margin-ex-small-top"
          style={{
            background: "#f0f0f0",
            padding: "10px 14px 10px 14px",
          }}
        >
          <img src={info} alt="info" />
          <span className="uk-margin-medium-left gh-info-container-text">
            {staticService.findByAlias("flightVariation")}
          </span>
        </div>
      )}
    </div>
  );
});

BrokerQuoteInfo.propTypes = {
  quote: PropTypes.object,
  changeSliderHeli: PropTypes.func,
  showPad: PropTypes.func,
  price: PropTypes.element,
  selectedHeli: PropTypes.number,
};

export { BrokerQuoteInfo };
