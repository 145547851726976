import PropTypes from "prop-types";
import React from "react";
import RCSelect from "react-select";

import "./Select.styles.css";

const Select = React.forwardRef((props, ref) => {
  const { name, value, onChange, multiple, allowSelectAll, allOption } = props;
  //add select all option
  const options = allowSelectAll ? [allOption, ...props.options] : props.options;

  const changeHandler = (selected) => {
    //handle select all
    const lastSelectedItem = selected?.[selected?.length - 1];
    const isSelectedAllOption = lastSelectedItem?.value === allOption.value;

    if (allowSelectAll && isSelectedAllOption) {
      const filteredOptions = options.filter((option) => option.value !== allOption.value);
      return onChange(filteredOptions.map((item) => item.value));
    }
    //handle multi select
    if (Array.isArray(selected)) {
      return onChange(selected.map((item) => item.value));
    }
    //default
    onChange(selected.value);
  };

  const rcSelectValue = React.useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((item) => ({
        value: item,
        label: options.find((option) => option.value === item).label,
      }));
    }

    if (value) {
      return options.find((item) => item.value === value);
    }

    return null;
  }, [value, options]);

  return (
    <RCSelect
      {...props}
      options={options}
      classNamePrefix="gh-select"
      id={name}
      ref={ref}
      value={rcSelectValue}
      onChange={changeHandler}
      isMulti={multiple}
      closeMenuOnSelect={!multiple}
    />
  );
});

Select.displayName = "Select";

Select.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  onBlur: PropTypes.func,
  multiple: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

Select.defaultProps = {
  value: "",
  options: [],
  onBlur: () => null,
  allowSelectAll: false,
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default Select;
