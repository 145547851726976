import React from "react";
import PropTypes from "prop-types";
import greenMarker from "../../../assets/img/svg/greenMarker.svg";
import yellowMarker from "../../../assets/img/svg/yellowMarker.svg";
import oval from "../../../assets/img/svg/oval.svg";
import { dateFormat } from "../../../shared";
import staticService from "../../../services/static.service";
import Api from "../../../services/api-handler";
import checkCoverageStatus from "../../../utils/checkCoverageStatus";

class LocationAutocompleteItem extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      coverageStatus: true,
    };
  }

  async onClick() {
    const { item: location, onClick } = this.props;
    const body = {
      location: [location.latitude, location.longitude],
      place_id: location.place_id,
      source: location.source,
      secondary_text: location.secondary_text,
    };
    const inputData = { ...location };

    if (this.props.checkCoverage) {
      const status = await checkCoverageStatus(body);
      inputData.coverage_status = status;
    }

    onClick(inputData);
  }

  get icon() {
    switch (this.props.sectionTitle) {
      case "city":
        return <img src={oval} className="gh-pin" alt="marker" />;
      case "events":
        return <img src={yellowMarker} className="gh-pin" alt="marker" />;
      case "helipads":
        return <img src={greenMarker} className="gh-pin" alt="marker" />;
      case "venues":
        return <img src={oval} className="gh-pin" alt="marker" />;
    }
  }

  get locationItem() {
    switch (this.props.sectionTitle) {
      case "city":
        return (
          <div className="gh-location-row">
            <div className="gh-sl-title">{this.props.item.location}</div>
            <div className="gh-sl-secondary">{this.props.item.secondary_text}</div>
          </div>
        );
      case "events":
        return (
          <div className="gh-location-row">
            <div className="gh-sl-title">{this.props.item.location}</div>
            <div className="gh-sl-secondary">
              {staticService.findByAlias("event") +
                " • " +
                dateFormat(this.props.item.start_date, "YYYY-MM-DD", "ddd D MMMM, YYYY") +
                " - " +
                dateFormat(this.props.item.end_date, "YYYY-MM-DD", "ddd D MMMM, YYYY") +
                " • " +
                this.props.item.secondary_text}
            </div>
          </div>
        );
      case "helipads":
        return (
          <div className="gh-location-row">
            <div className="gh-sl-title">{this.props.item.location}</div>
            <div className="gh-sl-secondary">
              {this.props.item.category + " • " + this.props.item.secondary_text}
            </div>
          </div>
        );
      case "venues":
        return (
          <div className="gh-location-row">
            <div className="gh-sl-title">{this.props.item.location}</div>
            <div className="gh-sl-secondary">{this.props.item.secondary_text}</div>
          </div>
        );
    }
  }
  render() {
    return (
      <li className="gh-location-list-item" onClick={this.onClick}>
        {this.icon}
        {this.locationItem}
      </li>
    );
  }
}

LocationAutocompleteItem.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.shape({
    location: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    distance: PropTypes.string,
    url: PropTypes.string,
    place_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  }),
  checkCoverage: PropTypes.bool,
};

LocationAutocompleteItem.defaultProps = {
  item: [],
  checkCoverage: false,
};

export { LocationAutocompleteItem };
