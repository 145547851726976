import React from "react";
import { useFormContext } from "react-hook-form";
import { useCalculatorContext } from "../../../CalculatorContext";
import { Description } from "../../Description";
import { Accordion } from "../../Accordion";
import { ConfirmInput, TaxInput } from "../../Input";
import { Control } from "../../Control";
import { useLegContext } from "../LegContext";
import { SectionArrow } from "../../SectionArrow";

export default function FeesSection() {
  const { activeSections, toggleSectionStatus, resetToOriginal, currency } = useCalculatorContext();
  const { prefix, isLast, onLegFeesChange, legId } = useLegContext();
  const { getValues } = useFormContext();

  return (
    <Accordion
      isOpen={activeSections.includes("fees")}
      onToggle={toggleSectionStatus("fees", !activeSections.includes("fees"))}
      className="uk-margin-small-top"
      header={
        <Description>
          <Description.Item as="custom" type="header">
            {isLast && <SectionArrow closed={!activeSections.includes("fees")} />}
          </Description.Item>
        </Description>
      }
    >
      <Description>
        <Description.Item>
          <Control name={`${prefix}.departure_fee_amount`}>
            <ConfirmInput
              placeholder="[0]"
              onValueChange={onLegFeesChange}
              onFocus={toggleSectionStatus("fees", true)}
              onReset={resetToOriginal({
                legId,
                field: `${prefix}.departure_fee_amount`,
                callback: onLegFeesChange,
              })}
            />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.departure_fee_tax`}>
            <TaxInput
              onValueChange={onLegFeesChange}
              total={getValues(`${prefix}.departure_fee_amount`)}
              currency={currency}
            />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.landing_fee_amount`}>
            <ConfirmInput
              placeholder="[0]"
              onValueChange={onLegFeesChange}
              onReset={resetToOriginal({
                legId,
                field: `${prefix}.landing_fee_amount`,
                callback: onLegFeesChange,
              })}
            />
          </Control>
        </Description.Item>
        <Description.Item>
          <Control name={`${prefix}.landing_fee_tax`}>
            <TaxInput
              onValueChange={onLegFeesChange}
              total={getValues(`${prefix}.landing_fee_amount`)}
              currency={currency}
            />
          </Control>
        </Description.Item>
        <Description.Item separator>
          <Control name={`${prefix}.total_fees`}>
            <ConfirmInput placeholder="0.00" readOnly />
          </Control>
        </Description.Item>
      </Description>
    </Accordion>
  );
}
