import React from "react";
import PropTypes from "prop-types";
import { ModalComponent } from "../../";

class AlertModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      message: "",
      modalTitle: "Server error",
    };

    this.mount = this.mount.bind(this);
    this.hide = this.hide.bind(this);
    this.openModal = this.openModal.bind(this);
    this.mounted = true;
  }

  componentDidMount() {
    this.mount();
  }

  mount() {
    if (this.mounted) {
      if (this.props.alertInstance.eventInstance) {
        this.props.alertInstance.eventInstance.setEvent(this.props.event, (event, data) => {
          if (data.modal) {
            this.openModal();
            this.setState({
              message: data.message,
              modalTitle: data.modalTitle,
            });
          }
        });
      }
    }
  }

  hide() {
    this.setState({ message: "" });
    this.closeModal();
    window.location.reload();
  }

  openModal() {
    if (this.modalRef) {
      this.modalRef.open();
    }
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  render() {
    return (
      <ModalComponent
        title={this.state.modalTitle}
        ref={(modal) => (this.modalRef = modal)}
        id={"alert-modal"}
        options={{ clsPage: "", bgClose: false, escClose: false }}
        onClose={this.hide}
      >
        <p dangerouslySetInnerHTML={{ __html: this.state.message }} />
        <div className={"uk-margin-small-top uk-text-center"}>
          <button
            className={"uk-button uk-button-secondary"}
            onClick={this.hide}
            dangerouslySetInnerHTML={{ __html: this.props.closeLabel }}
          />
        </div>
      </ModalComponent>
    );
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.alertInstance.eventInstance = null;
  }
}

AlertModalComponent.propTypes = {
  time: PropTypes.number,
  event: PropTypes.string,
  closeLabel: PropTypes.string,
  alertInstance: PropTypes.object,
};

export { AlertModalComponent };
