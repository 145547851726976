import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import staticService from "../../../../services/static.service";

class MarketOverviewItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
    };
  }

  shouldComponentUpdate(props, states) {
    return states.loaded;
  }

  componentDidUpdate() {
    if (this.props.total) {
      this.setState({ loaded: false });
    }
  }

  render() {
    return this.props.visible ? (
      <div data-uk-grid>
        <div className="uk-width-1-3 uk-text-center gh-count gh-count-default">
          {this.props.total}
        </div>
        <div className="uk-width-2-3">
          <NavLink to={this.props.info.path}>
            <span
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias(this.props.info.alias) }}
            />
          </NavLink>
        </div>
      </div>
    ) : null;
  }
}

MarketOverviewItemComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  info: PropTypes.object.isRequired,
  total: PropTypes.string,
};

export { MarketOverviewItemComponent };
