import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { BlockComponent, DashboardBlockComponent } from "../../../common";
import Auth from "../../../../services/auth";
import { staticText, config } from "../../../../configs";
import {
  loadUserDashboardStatistic,
  loadUserDashboardStatisticSuccess,
  updateLastLogged,
  SET_USER_ACCOUNT,
  editRequest,
} from "../../../../actions";
import { checkObjectProp, fireTracking, storage } from "../../../../shared";
import { MainDashboard } from "../common";
import staticService from "../../../../services/static.service";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../../../";
import auth from "../../../../services/auth";

class BrokerDashBoardComponent extends MainDashboard {
  constructor(props) {
    super(props);
    fireTracking(props.location.pathname);
  }

  blockLength(length) {
    return length >= 2 ? length : 2;
  }

  componentDidMount() {
    this.props.loadUserDashboardStatistic();
    this.context.store.dispatch({
      type: SET_USER_ACCOUNT,
      user: Auth.getUser(),
    });
  }

  componentDidUpdate() {
    if (Auth.firstTimeLogged() && Auth.isVerified() && this.confirmComponent) {
      this.confirmComponent.openModal();
    }
  }

  get buttonsProps() {
    return {};
  }

  get dialogMessage() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("dialogBroker"),
          }}
        />
        <div className="gh-modal-footer uk-text-center gh-dashboard-modal-nav">
          <button
            className={"uk-button uk-margin-small-top uk-button-default gh-dm-link"}
            onClick={this.closeDialog}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("noThanksBtn"),
            }}
          />
          <NavLink
            className={"uk-button uk-margin-small-top uk-button-primary gh-dm-link"}
            to={config.redirectToEmptyMarketPlace}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("viewMarketplaceBtn"),
            }}
          />
        </div>
      </div>
    );
  }

  closeDialog() {
    super.closeDialog();
    if (Auth.firstTimeLogged()) {
      this.props.updateLastLogged();
    }
  }

  get modalProps() {
    return {
      id: "confirm-modal",
      title: staticService.findByAlias("dashboardModalBrokerTitle"),
      onClose: this.closeDialog.bind(this),
    };
  }

  componentProps() {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  get mainContent() {
    if (this.props.dashboardModel.bookings.provisional_bookings === undefined) return "";
    return (
      <section className={"dashboard-page"}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-bottom header-title",
          }}
        >
          <div className="uk-width-1-1">
            <h3
              className="uk-margin-remove-bottom"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("dashboardTitle"),
              }}
            />
          </div>

          <div className="uk-width-1-1 uk-position-relative">
            <div className="uk-hidden@m uk-margin-small-bottom">Welcome back, {Auth.fullName}</div>
            <span className="gh-welcome-message uk-visible@m">Welcome back, {Auth.fullName}</span>

            <div>
              <ComponentAuthorization
                component={IsVerifiedAuthorization}
                componentProps={this.componentProps()}
                roles={["broker_admin", "broker_team", "sole_trader"]}
              />
            </div>
          </div>
        </BlockComponent>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-top gh-section-statistics",
          }}
        >
          <h4
            className="uk-margin-bottom gh-statistics-head"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(staticText.dashboard.block_requests.title),
            }}
          />
          <div>
            {staticText.dashboard.block_requests.blocksTitles.map((row) => {
              return (
                <div
                  data-uk-grid
                  className="uk-grid-match uk-child-width-1-2"
                  key={staticText.dashboard.block_requests.title}
                >
                  {row.map((block, blockIndex) => {
                    return (
                      <DashboardBlockComponent
                        key={block.blTitle}
                        quantity={checkObjectProp(
                          this.props.dashboardModel[block.parentKey],
                          block.qty,
                          0,
                        )}
                        title={block.blTitle}
                        visible={true}
                        navPath={block.blNav}
                        color={block.css}
                        colLength={this.blockLength(
                          staticText.dashboard.block_requests.blocksTitles.length,
                        )}
                        navTitle={block.blNavTitle}
                        screenW={this.props.screenW}
                        index={blockIndex}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </BlockComponent>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-top gh-section-statistics",
          }}
        >
          <h4
            className="uk-margin-bottom gh-statistics-head"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(staticText.dashboard.block_bookings.title),
            }}
          />
          <div>
            {staticText.dashboard.block_bookings.blocksTitles.map((row) => {
              const { bookings } = this.props.dashboardModel;
              const hideProvisional =
                auth.isBroker() &&
                !auth.hasFeature("manual_bank_transfer") &&
                bookings.provisional_bookings <= 0;
              return (
                <div
                  data-uk-grid
                  className="uk-grid-match uk-child-width-1-2"
                  key={staticText.dashboard.block_bookings.title}
                >
                  {row.map((block, blockIndex) => {
                    if (block.qty === "provisional_bookings" && hideProvisional) {
                      return "";
                    }

                    let highlight = false;

                    if (bookings) {
                      if (
                        block.qty === "provisional_bookings" &&
                        bookings.provisional_bookings_highlight
                      ) {
                        highlight = true;
                      }
                    }

                    return block.blTitle !== "mock" ? (
                      <DashboardBlockComponent
                        key={block.blTitle}
                        quantity={checkObjectProp(
                          this.props.dashboardModel[block.parentKey],
                          block.qty,
                          0,
                        )}
                        title={block.blTitle}
                        visible={true}
                        navPath={block.blNav}
                        color={block.css}
                        colLength={this.blockLength(
                          staticText.dashboard.block_bookings.blocksTitles.length,
                        )}
                        navTitle={block.blNavTitle}
                        highlight={highlight}
                        screenW={this.props.screenW}
                        index={hideProvisional ? blockIndex - 1 : blockIndex}
                      />
                    ) : this.props.screenW > 960 ? (
                      <span>{""}</span>
                    ) : (
                      ""
                    );
                  })}
                </div>
              );
            })}
          </div>
        </BlockComponent>
        {this.dialog}
      </section>
    );
  }

  componentWillUnmount() {
    this.props.loadUserDashboardStatisticSuccess({ total: null });
    this.context.store.dispatch(editRequest({}));
  }
}

BrokerDashBoardComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadUserDashboardStatistic,
      loadUserDashboardStatisticSuccess,
      updateLastLogged,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardModel: state.dashboard,
    company: state.company,
    screenW: state.screenDimensions.screenInnerW,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerDashBoardComponent);
export { COMPONENT as BrokerDashBoardComponent };
