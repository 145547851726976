import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import UploadFiles from "../../forms/register/upload-files.component";
import Button from "../Button";
import { getStripFields } from "../../../actions";
import auth from "../../../services/auth";
import { filterFiles } from "./providePaymentModal.utils";

import "./providePaymentModalContent.css";

const ProvidePaymentModalContent = (props) => {
  const {
    provisionalPaymentInformationModel,
    closeModal,
    brokerName,
    registeredAddress,
    handleSubmit,
    openPreviewFileModal,
    closingModal,
    stripeFields,
    getStripFields,
    resetModel,
    paymentInfo,
    bookingID,
    readOnly,
    updateModel,
  } = props;

  // form config
  const {
    register,
    getValues,
    formState: { isValid },
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  // loading logic start
  const [filesToUploadCount, setFilesToUploadCount] = useState(0);
  const [filesUploadedCount, setFilesUploadedCount] = useState(0);
  const [loadingProceedBtn, setLoadingProceedBtn] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isShowBrokerCompanyInfo, setIsShowBrokerCompanyInfo] = useState(false);

  useEffect(() => {
    if (paymentInfo) {
      updateModel("provisionalPaymentInformationModel", paymentInfo.file);
    }
  }, [readOnly, paymentInfo]);

  useEffect(() => {
    if ((brokerName || registeredAddress) && auth.isOperator()) {
      setIsShowBrokerCompanyInfo(true);
    } else {
      setIsShowBrokerCompanyInfo(false);
    }
  }, [readOnly, brokerName, registeredAddress]);

  const handleUpdateProgressStatus = (status, count, decreaseCount) => {
    if (status === "uploading") {
      setFilesToUploadCount(filesToUploadCount + count);
    } else if (status === "uploaded") {
      setFilesUploadedCount(filesUploadedCount + 1);
    } else if (status === "error" && decreaseCount) {
      setUploadingFile(false);
      setFilesToUploadCount(filesToUploadCount - decreaseCount);
    } else if (status === "error") {
      setUploadingFile(false);
    }
  };

  useEffect(() => {
    if (filesToUploadCount !== 0) {
      if (filesToUploadCount !== filesUploadedCount && !uploadingFile) {
        setUploadingFile(true);
      }

      if (filesToUploadCount === filesUploadedCount && uploadingFile) {
        setUploadingFile(false);
        setFilesUploadedCount(0);
        setFilesToUploadCount(0);
      }
    }
  }, [filesUploadedCount, filesToUploadCount, uploadingFile]);

  useEffect(() => {
    if (bookingID) {
      getStripFields(bookingID);
    }
  }, [bookingID]);

  // loading logic finish

  // clear form on close  modal
  useEffect(() => {
    if (closingModal) {
      resetModel("filesExistState", false);
      resetModel("provisionalPaymentInformationModel", []);

      reset();
    }
  }, [closingModal]);

  const handleSend = () => {
    setLoadingProceedBtn(true);
    const formValues = getValues();

    let files = [];

    if (provisionalPaymentInformationModel) {
      files = filterFiles(provisionalPaymentInformationModel);
    }

    return handleSubmit({ ...formValues, file: files }, setLoadingProceedBtn);
  };

  return (
    <div className="gh-provide-payment">
      {!readOnly ? (
        <span>{staticService.findByAlias("paymentInformationModalSecondaryText")}</span>
      ) : (
        ""
      )}
      {isShowBrokerCompanyInfo ? (
        <div className="gh-provide-payment-broker-info">
          <div className="gh-provide-payment-broker-info-row">
            <div className="gh-provide-payment-broker-info-key">
              {staticService.findByAlias("brokerName")}
            </div>
            <div className="gh-provide-payment-broker-info-value">{brokerName}</div>
          </div>
          <div className="gh-provide-payment-broker-info-row">
            <div className="gh-provide-payment-broker-info-key">
              {staticService.findByAlias("registeredAddressLabel")}
            </div>
            <div className="gh-provide-payment-broker-info-value">{registeredAddress}</div>
          </div>
        </div>
      ) : (
        ""
      )}
      {!readOnly ? (
        <div className="gh-provide-payment-warning">
          {staticService.findByAlias("providePaymentWarningText")}
        </div>
      ) : (
        ""
      )}
      <div className="gh-provide-payment-form">
        {!readOnly
          ? staticService.findByAlias("bankAccountDetails")
          : paymentInfo.account_holder || paymentInfo.account_number || paymentInfo.routing_number
          ? staticService.findByAlias("bankAccountDetails")
          : ""}

        <form>
          {Object.keys(stripeFields).map((item, index) => (
            <div
              className={`gh-provide-payment-form-row ${
                readOnly && !paymentInfo[item] ? "hide" : ""
              }`}
              key={index}
            >
              <label
                className={`uk-form-label gh-provide-payment-row-label ${
                  !readOnly ? "required-contact" : ""
                }`}
              >
                {stripeFields[item].label}
              </label>
              {readOnly ? (
                <input
                  {...register(stripeFields[item].key)}
                  className="uk-input gh-provide-payment-input"
                  disabled={true}
                  value={paymentInfo ? paymentInfo[item] : ""}
                />
              ) : (
                <input
                  {...register(stripeFields[item].key, {
                    required: !provisionalPaymentInformationModel.length,
                  })}
                  placeholder={stripeFields[item].example}
                  className="uk-input gh-provide-payment-input"
                />
              )}
            </div>
          ))}
        </form>
      </div>
      {!readOnly ? (
        <div className="gh-provide-payment-alternative-text">
          <span>or</span>
          {staticService.findByAlias("paymentInformationModalAlternativeText")}
        </div>
      ) : (
        ""
      )}

      {readOnly && paymentInfo.file.length ? staticService.findByAlias("documentsTitle") : ""}
      {readOnly && paymentInfo.file.length ? (
        <UploadFiles
          model={filterFiles(provisionalPaymentInformationModel)}
          modelName="provisionalPaymentInformationModel"
          openModal={(file) => {
            openPreviewFileModal(file);
          }}
          hiddenUpload={true}
          docs={paymentInfo.file}
          hideRemove={true}
        />
      ) : (
        ""
      )}
      {!readOnly ? (
        <UploadFiles
          model={provisionalPaymentInformationModel}
          modelName="provisionalPaymentInformationModel"
          uploadTitle=""
          docs={provisionalPaymentInformationModel}
          handleUpdateProgressStatus={handleUpdateProgressStatus}
          withOutGlobalLoading
          openModal={openPreviewFileModal}
        />
      ) : (
        ""
      )}
      {!readOnly ? (
        <div className="gh-review-request-modal-buttons">
          <Button
            variant="outlined"
            color="primaryBorder"
            onClick={() => {
              closeModal("providePaymentInfo");
            }}
          >
            {staticService.findByAlias("later")}
          </Button>
          <Button
            loading={loadingProceedBtn}
            disabled={(!isValid && !provisionalPaymentInformationModel.length) || uploadingFile}
            onClick={handleSend}
          >
            {staticService.findByAlias("proceed")}
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

ProvidePaymentModalContent.propTypes = {
  provisionalPaymentInformationModel: PropTypes.array,
  closeModal: PropTypes.func,
  brokerName: PropTypes.string,
  registeredAddress: PropTypes.string,
  handleSubmit: PropTypes.func,
  openPreviewFileModal: PropTypes.func,
  closingModal: PropTypes.bool,
  stripeFields: PropTypes.object,
  getStripFields: PropTypes.func,
  resetModel: PropTypes.func,
  paymentInfo: PropTypes.object,
  bookingID: PropTypes.number,
  readOnly: PropTypes.bool,
  updateModel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  provisionalPaymentInformationModel: state.provisionalPaymentInformationModel,
  stripeFields: state.stripeFields,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetModel: (model) => dispatch(actions.reset(model)),
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      getStripFields,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvidePaymentModalContent);
