import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { downloadDocImage } from "../../../../actions";

import download from "../../../../assets/img/svg/downloadIcon.svg";
import loader from "../../../../assets/img/svg/loader.svg";

const PreviewFile = (props) => {
  const { selectedFile, downloadDocImage } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadImg = () => {
    if (!isDownloading) {
      const file = { ...selectedFile, name: selectedFile.title };

      setIsDownloading(true);
      const url = selectedFile.file_url.split("storage/")[1] || selectedFile.file_url.slice(40);
      return downloadDocImage(file, url)
        .then(() => setIsDownloading(false))
        .catch(() => setIsDownloading(false));
    }
  };

  if (!selectedFile) return "";
  return (
    <div>
      {selectedFile && selectedFile.file_type && selectedFile.file_type.includes("image") ? (
        <div className="gh-preview-file wrapper-preview-chat">
          <img src={selectedFile.file_url} alt="preview" />
          <div className="wrapper-download-btn">
            <a onClick={downloadImg} className="download-icon gh-padding-10">
              {isDownloading ? (
                <img src={loader} alt="download" className="loader" />
              ) : (
                <img src={download} alt="download" />
              )}
            </a>
          </div>
        </div>
      ) : (
        <div className="gh-preview-file gh-padding-10">
          <iframe src={selectedFile.file_url} title="preview" className="gh-preview-file-pdf" />
        </div>
      )}
    </div>
  );
};

PreviewFile.propTypes = {
  selectedFile: PropTypes.object,
  downloadDocImage: PropTypes.func,
};

export default connect(null, { downloadDocImage })(PreviewFile);
