export default function objectNullValuesToString(o) {
  return Object.entries(o).reduce((accumulator, [key, val]) => {
    if (val === null) {
      accumulator[key] = "";
    } else if (typeof val === "object" && !Array.isArray(val)) {
      accumulator[key] = objectNullValuesToString(val);
    } else if (Array.isArray(val)) {
      accumulator[key] = val.map(objectNullValuesToString);
    } else accumulator[key] = val;

    return accumulator;
  }, {});
}
