import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { actions } from "react-redux-form";
import { connect } from "react-redux";
import { getReferrer, sendInvitation } from "../../../../../actions";
import { ModalComponent } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import { InviteFormComponent } from "./";

class InviteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.get((response) => {
      this.setState({ ...response });
    });
  }

  submit(model) {
    this.props.sendInvitation(model, this.openModal);
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.hide();
    this.props.resetModel();
  }

  get Form() {
    return this.state.code !== null && this.state.code !== "" ? (
      <InviteFormComponent submit={this.submit} />
    ) : null;
  }

  get code() {
    return this.state.code !== null && this.state.code !== "" ? (
      this.state.code
    ) : (
      <span
        className={"uk-margin"}
        dangerouslySetInnerHTML={{ __html: staticService.findByAlias("noRefferalCode") }}
      />
    );
  }

  get Code() {
    return (
      <div className={"uk-margin-bottom"}>
        <b dangerouslySetInnerHTML={{ __html: staticService.findByAlias("RefferalCodeLabel") }} />:{" "}
        {this.code}
      </div>
    );
  }

  render() {
    // --------------------------------------------------
    // disabling component
    this.props.history.push("/account/information");
    return "";
    // --------------------------------------------------
    return (
      <div>
        <h2
          className={"gh-text-slim uk-margin-remove-bottom"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("inviteBrokerTitle") }}
        />
        <p
          className={"uk-margin"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("inviteBrokerDescription") }}
        />
        {this.Code}
        {this.Form}
        <ModalComponent
          title={staticService.findByAlias("InvitationSentTitle")}
          ref={(modal) => (this.modal = modal)}
          options={{ bgClose: false, escClose: false }}
          id={"broker-invitation-sent-success"}
          onClose={this.closeModal.bind(this)}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("sentRefferalCodelModalContent"),
            }}
          />
          <div className="uk-text-center">
            <button className="uk-button uk-button-secondary" onClick={this.closeModal.bind(this)}>
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("close") }} />
            </button>
          </div>
        </ModalComponent>
      </div>
    );
  }
}

InviteComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({ companyDetails, company }) {
  return {
    companyModel: companyDetails,
    company,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      get: getReferrer,
      sendInvitation,
      resetModel: () => dispatch(actions.reset("userEmailModel")),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(InviteComponent);
export { COMPONENT as InviteComponent };
