import React from "react";
import { useCalculatorContext } from "../../../CalculatorContext";
import { Description } from "../../Description";
import { Accordion } from "../../Accordion";
import { TextArea } from "../../Input";
import { Control } from "../../Control";
import { useLegContext } from "../LegContext";
import { SectionArrow } from "../../SectionArrow";

export default function NotesSection() {
  const { activeSections, toggleSectionStatus } = useCalculatorContext();
  const { prefix, isLast } = useLegContext();

  return (
    <Accordion
      isOpen={activeSections.includes("notes")}
      onToggle={toggleSectionStatus("notes", !activeSections.includes("notes"))}
      className="uk-margin-small-top"
      header={
        <Description>
          <Description.Item as="custom" type="header">
            {isLast && <SectionArrow closed={!activeSections.includes("notes")} />}
          </Description.Item>
        </Description>
      }
    >
      <Description className="notes-description">
        <Description.Item>
          <Control name={`${prefix}.operator_notes`}>
            <TextArea placeholder="General comment if needed" />
          </Control>
        </Description.Item>
      </Description>
    </Accordion>
  );
}
