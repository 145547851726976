import React, { Component } from "react";
import PropTypes from "prop-types";
import { MapComponent, parseCoordinates, percentageFormula } from "../../../../../shared";
import { MiniTabComponent } from "../../../../common";
import { config } from "../../../../../configs";
import { RangeFormComponent } from "../../../../../components";
import staticService from "../../../../../services/static.service";

class MapActivityComponent extends Component {
  constructor(props) {
    super(props);

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleMapPlanDrawn = this.handleMapPlanDrawn.bind(this);
    this.getTabSection = this.getTabSection.bind(this);
    this.prepareMapMarkers = this.prepareMapMarkers.bind(this);
    this.state = {
      tabSelected: "bookings",
      flights: [],
      legs: [],
      markers: [],
      curvedDirectionLines: [],
      clusterInfo: {
        position: { lat: null, lng: null },
        isInfoShown: false,
        items: [],
      },
      date: new Date(),
      zoom: config.mapZoom.min,
      rangeSubmitTriggered: false,
    };
  }

  componentDidMount() {
    this.props.onDatePicked();
    const items =
      this.props.tabSelected === "bookings" ? this.props.bookings : this.props.emptyLegs;
    if (items) this.prepareMapMarkers(items);
  }

  componentWillReceiveProps({ bookings = {}, emptyLegs = [], tabSelected, flights = {} }) {
    const items = tabSelected === "bookings" ? bookings : emptyLegs;

    if (flights.range && !this.state.rangeSubmitTriggered) {
      const range =
        tabSelected === "bookings" ? flights.range.bookingRange : flights.range.emptyLegRange;
      this.props.updateRangeModel("range", range);
    }

    if (JSON.stringify(items) !== JSON.stringify(this.props[this.props.tabSelected])) {
      this.prepareMapMarkers(items);
    }
  }

  prepareMapMarkers(items) {
    const markers = Object.keys(items).map((item) => {
      const booking = items[item];
      return {
        ...parseCoordinates(booking.geocode),
        onClick: () => this.handleMarkerClick(item),
        name: item,
        flightsInfo: {
          legs: booking.legs,
          totalArrive: booking.total_arrive,
          totalDepart: booking.total_depart,
        },
        isClusterInfoShown: false,
        clusterInfoType: this.props.clusterInfoWindow || false,
        path: config.redirectToEmptyMarketPlace,
        viewLabel: staticService.findByAlias("details"),
      };
    });

    this.setState({ markers });
  }

  handleTabClick(tabSelected) {
    this.props.onTabSelected(tabSelected);
  }

  load(model) {
    if (!this.state.rangeSubmitTriggered) {
      this.setState({ rangeSubmitTriggered: true });
    }
    this.props.onDatePicked(model);
  }

  handleMapPlanDrawn(curvedDirectionLines = []) {
    this.setState({ curvedDirectionLines });
  }

  closeAllPopups() {
    this.setState((prevState) => ({
      markers: prevState.markers.map((i) => ({ ...i, isClusterInfoShown: false })),
    }));
  }

  handleMarkerClick(pointTitle) {
    this.closeAllPopups();
    this.setState((prevState) => ({
      ...prevState,
      markers: prevState.markers.map((item) => {
        if (item.name === pointTitle) {
          return {
            ...item,
            isClusterInfoShown: true,
          };
        }
        return item;
      }),
    }));
  }

  getFlightPlanFromMarkers(markers = []) {
    let result = [];
    markers.forEach((marker) => {
      const legsInvolved = marker.flightsInfo.legs.filter((i) => marker.name === i.from);
      return legsInvolved.forEach((leg) => {
        result.push([parseCoordinates(leg.from_coords), parseCoordinates(leg.to_coords)]);
      });
    });
    return result;
  }

  getTabSection() {
    return (
      <div>
        <RangeFormComponent
          modelName={"range"}
          format={config.dobFormat}
          action={this.load.bind(this)}
          visible={true}
        />
        <div className={this.props.showTab ? "uk-margin-bottom" : ""}>
          <MiniTabComponent
            visible={this.props.showTab}
            selectedView={this.props.tabSelected}
            tabs={[
              { title: "Bookings", view: "bookings" },
              { title: "Empty legs", view: "empty-legs" },
            ]}
            onClick={this.handleTabClick}
          />
        </div>
      </div>
    );
  }

  get mapHeight() {
    let height = null;

    if (this.props.screenDimensions.screenH > this.props.screenDimensions.screenW) {
      height =
        this.props.screenDimensions.screenH -
        percentageFormula(this.props.screenDimensions.screenH, 50);
    }
    height =
      this.props.screenDimensions.screenW -
      percentageFormula(this.props.screenDimensions.screenW, 50);
    if (config.maxAllowedMapeHeight > height) return height;
    return config.maxAllowedMapeHeight;
  }

  get operatorMap() {
    const mapHeight = this.mapHeight || 600;
    const flightsPlanCoordinates = this.getFlightPlanFromMarkers(this.state.markers).filter(
      (i) => i.length > 0,
    );
    return (
      <div className={"gh-google-map"}>
        <MapComponent
          key={1}
          containerElement={<div style={{ height: `${mapHeight}px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          markers={this.state.markers}
          visible={true}
          mountOnUpdate={true}
          zoom={this.state.zoom}
          flightPlanCoordinates={flightsPlanCoordinates}
          onDirectionsDrawn={this.handleMapPlanDrawn}
          curvedDirectionLines={this.state.curvedDirectionLines}
        ></MapComponent>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div key={0}>{this.getTabSection()}</div>
        {this.operatorMap}
      </div>
    );
  }
}

MapActivityComponent.contextTypes = {
  store: PropTypes.object,
};

MapActivityComponent.propTypes = {
  flights: PropTypes.object,
  onDatePicked: PropTypes.func,
  screenDimensions: PropTypes.object,
  parentHistory: PropTypes.object,
  onTabSelected: PropTypes.func,
  updateRangeModel: PropTypes.func,
  showTab: PropTypes.bool,
  clusterInfoWindow: PropTypes.bool,
};

export { MapActivityComponent };
