import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./QuoteDetailsBookingModalContent.css";
import CheckboxFuncComponent from "../../../../common/CheckboxFuncComponent";
import staticService from "../../../../../services/static.service";
import { useForm, Controller } from "react-hook-form";
import {
  departurePlaceholder,
  explode,
  isObjectEmpty,
  replaceAttributesInString,
} from "../../../../../shared";
import ErrorMessageComponent from "../../../../common/ErrorMessageComponent";
import { saveBooking } from "../../../../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { config } from "../../../../../configs";
import auth from "../../../../../services/auth";

const initialValue = {
  is_information_confirmed: false,
  is_agree: false,
};
const QuoteDetailsBookingModalContent = (props) => {
  const {
    quoteDepartureTime,
    saveBooking,
    quoteID,
    requestID,
    parentUrl,
    quotePrice,
    paymentMethod,
    changeModalTitle,
    history,
  } = props;

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({ defaultValues: initialValue });
  const [bookingID, setBookingID] = useState(null);
  const formValues = watch();

  useEffect(() => {
    if (paymentMethod === 2 && bookingID)
      changeModalTitle(staticService.findByAlias("operatorUponConfirmationProvisionalModalTitle"));
    else if (paymentMethod === 1 && bookingID)
      changeModalTitle(staticService.findByAlias("operatorUponConfirmationModalTitle"));
  }, [paymentMethod, bookingID]);

  const onSubmit = () => {
    saveBooking(
      {
        booking_request_id: requestID,
        quote_id: quoteID,
        price: quotePrice,
        payment_date: explode(departurePlaceholder(quoteDepartureTime), "/", "-"),
        is_information_confirmed: formValues.is_information_confirmed,
      },
      (res) => {
        if (paymentMethod === 1) {
          setBookingID(res.booking_id);
        } else {
          history.push(`/flights/my-bookings/filter/all/${res.booking_id}#payment_information`);
        }
      },
      (e) => console.error(e),
    );
  };

  return (
    <div className="gh-quote-details-booking-modal">
      {paymentMethod === 2 && !bookingID ? (
        <div
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("manualBankTransferModalTextOperator"),
          }}
        />
      ) : (
        ""
      )}

      {!bookingID ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="uk-flex uk-flex-middle">
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CheckboxFuncComponent
                  alias={
                    auth.getHeliServiceTerms()
                      ? "bookingConfirmModalContent"
                      : "bookingConfirmModalContentShort"
                  }
                  checked={!!value}
                  onClick={() => onChange(!value)}
                />
              )}
              name="is_agree"
            />
          </div>
          <div className="uk-margin-top uk-flex uk-flex-middle">
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <CheckboxFuncComponent
                  alias="tickOnConfirmOpsBooking"
                  checked={!!value}
                  onClick={() => onChange(!value)}
                />
              )}
              name="is_information_confirmed"
            />
          </div>
          {paymentMethod === 1 ? (
            <div className="uk-margin-medium-top uk-flex uk-flex-middle">
              <span
                className="gh-quote-details-booking-modal-span"
                dangerouslySetInnerHTML={{
                  __html: replaceAttributesInString(
                    { payment_date: departurePlaceholder(quoteDepartureTime) },
                    staticService.findByAlias("operatorUponConfirmation"),
                  ),
                }}
              />
            </div>
          ) : (
            ""
          )}

          <ErrorMessageComponent visibility={!isObjectEmpty(errors)}>
            Pleas confirm!
          </ErrorMessageComponent>
          <div className="uk-modal-footer gh-modal-footer uk-text-center gh-modal-grid">
            <button
              style={{ minHeight: 50, marginBottom: window.innerWidth < 769 ? 15 : 0 }}
              type="button"
              className="uk-button uk-modal-close uk-button-default"
              onClick={() => {}}
            >
              <span>Cancel</span>
            </button>
            <button
              style={{ minHeight: 50 }}
              type="submit"
              className="uk-button uk-button-primary gh-request-gen-btns"
              disabled={!formValues.is_information_confirmed || !formValues.is_agree}
            >
              <span>
                {staticService.findByAlias(paymentMethod === 1 ? "confirmBooking" : "confirm")}
              </span>
            </button>
          </div>
        </form>
      ) : (
        <div>
          {paymentMethod === 1 ? (
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("booking-confirmed-message"),
              }}
            />
          ) : (
            ""
          )}
          <div className="gh-modal-footer uk-text-center">
            <NavLink
              className={"uk-button uk-margin-small-top uk-button-primary gh-mobile-btn-normal"}
              to={config.bookingRoute + "/" + bookingID}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("viewBooking"),
              }}
            />
            <NavLink
              className={"uk-button uk-margin-small-top uk-button-default gh-mobile-btn-normal"}
              to={parentUrl}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("goBackToQuotes"),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

QuoteDetailsBookingModalContent.propTypes = {
  quoteDepartureTime: PropTypes.string,
  parentUrl: PropTypes.string.isRequired,
  quoteID: PropTypes.number.isRequired,
  requestID: PropTypes.number.isRequired,
  saveBooking: PropTypes.func.isRequired,
  changeModalTitle: PropTypes.func.isRequired,
  quotePrice: PropTypes.string.isRequired,
  paymentMethod: PropTypes.number,
  history: PropTypes.object,
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ saveBooking }, dispatch);
};

export default connect(null, mapDispatchToProps)(QuoteDetailsBookingModalContent);
