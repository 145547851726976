import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BlockComponent, DashboardBlockComponent } from "../../../common";
import Auth from "../../../../services/auth";
import { staticText } from "../../../../configs";
import {
  getDashboardAircrafts,
  loadUserDashboardStatisticSuccess,
  SET_USER_ACCOUNT,
} from "../../../../actions";
import { checkObjectProp, fireTracking } from "../../../../shared";
import { _dashboard } from "../../../../reducers";
import { ButtonWithBadgeComponent, MainDashboard } from "../common";
import staticService from "../../../../services/static.service";

class OperatorDashBoardComponent extends MainDashboard {
  constructor(props) {
    super(props);
    fireTracking(props.location.pathname);
  }

  get buttonsProps() {
    return {};
  }

  get mainContent() {
    return (
      <section className={"dashboard-page"}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-bottom header-title",
          }}
        >
          <div className="uk-width-1-1">
            <h3
              className="uk-margin-remove-bottom"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("dashboardTitle"),
              }}
            />
          </div>
          <div className="uk-width-1-1 uk-position-relative">
            <div className="uk-hidden@m uk-margin-small-bottom">Welcome back, {Auth.fullName}</div>
            <span className="gh-welcome-message uk-visible@m">Welcome back, {Auth.fullName}</span>

            <div>
              <ButtonWithBadgeComponent
                route={"/marketplace"}
                visible={true}
                classes={["btn-pulse"]}
                label={staticService.findByAlias("operatorMarketPlaceButton")}
              />
            </div>
          </div>
        </BlockComponent>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-top gh-section-statistics",
          }}
        >
          <h4
            className="uk-margin-bottom gh-statistics-head"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(staticText.dashboard.operator_first.title),
            }}
          />
          <div>
            {staticText.dashboard.operator_first.blocksTitles.map((row, index) => {
              return (
                <div data-uk-grid className="uk-grid-match uk-child-width-1-2" key={index}>
                  {row.map((block, blockIndex) => {
                    const { charter_flights } = this.props.dashboardModel;
                    return (
                      <DashboardBlockComponent
                        key={blockIndex}
                        quantity={checkObjectProp(charter_flights, block.qty, 0)}
                        title={block.blTitle}
                        index={blockIndex}
                        visible={true}
                        navPath={block.blNav}
                        color={block.css}
                        colLength={this.blockLength(
                          staticText.dashboard.operator_first.blocksTitles.length,
                        )}
                        highlight={charter_flights && charter_flights[`${block.qty}_highlight`]}
                        navTitle={block.blNavTitle}
                        screenW={this.props.screenW}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </BlockComponent>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-top gh-section-statistics",
          }}
        >
          <h4
            className="uk-margin-bottom gh-statistics-head"
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias(staticText.dashboard.block_quotes.title),
            }}
          />
          <div>
            {staticText.dashboard.block_quotes.blocksTitles.map((row, index) => {
              return (
                <div data-uk-grid className="uk-grid-match uk-child-width-1-2" key={index}>
                  {row.map((block, blockIndex) => {
                    return (
                      <DashboardBlockComponent
                        key={blockIndex}
                        quantity={checkObjectProp(
                          this.props.dashboardModel.empty_legs,
                          block.qty,
                          0,
                        )}
                        title={block.blTitle}
                        visible={true}
                        navPath={block.blNav}
                        color={block.css}
                        colLength={this.blockLength(
                          staticText.dashboard.block_quotes.blocksTitles.length,
                        )}
                        navTitle={block.blNavTitle}
                        screenW={this.props.screenW}
                        index={blockIndex}
                        createEmptyLeg
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </BlockComponent>
        {this.dialog}
      </section>
    );
  }

  blockLength(length) {
    return length >= 2 ? length : 2;
  }

  componentDidMount() {
    this.props.getDashboardAircrafts();

    this.context.store.dispatch({
      type: SET_USER_ACCOUNT,
      user: Auth.getUser(),
    });
  }

  componentWillUnmount() {
    this.props.loadUserDashboardStatisticSuccess(_dashboard);
  }
}

OperatorDashBoardComponent.contextTypes = {
  store: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadUserDashboardStatisticSuccess,
      getDashboardAircrafts,
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardModel: state.dashboard,
    screenW: state.screenDimensions.screenInnerW,
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(OperatorDashBoardComponent);
export { COMPONENT as OperatorDashBoardComponent };
