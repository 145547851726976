import React from "react";
import PropTypes from "prop-types";

class NumberComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0,
    };
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  get label() {
    if (this.props.label) {
      let cssClass = [];
      if (this.props.required) {
        if (cssClass.indexOf("required") === -1) {
          cssClass.push("required");
        }
      }
      cssClass = cssClass.concat(this.props.labelClassName);
      return (
        <label className={cssClass.join(" ") || "uk-form-label gh-number-label"}>
          {this.props.label}
        </label>
      );
    }
    return null;
  }

  increment(ev) {
    ev.preventDefault();

    if (this.state.value < this.props.max) {
      this.setState((prevState) => {
        return {
          value: parseInt(prevState.value) + this.props.step,
        };
      });
      this.props.onChange(parseInt(this.state.value) + 1, this.props.name, "increment");
    }
  }

  decrement(ev) {
    ev.preventDefault();

    if (this.state.value > this.props.min) {
      this.setState((prevState) => {
        return {
          value: parseInt(prevState.value) - this.props.step,
        };
      });
      this.props.onChange(parseInt(this.state.value) - 1, this.props.name, "decrement");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    return (
      <div {...this.props.attrProps}>
        {this.label}
        <div className="uk-form-controls uk-margin-small-top">
          <div className="uk-width-auto uk-flex-center uk-padding-remove">
            <a
              href="#"
              onClick={this.props.disabled ? (e) => e.preventDefault() : this.decrement}
              className="uk-padding-remove gh-spinner-decrease"
            >
              <span className="" data-uk-icon="icon: minus"></span>
            </a>
            <span className={"gh-counter"}>{this.state.value}</span>
            <a
              href="#"
              onClick={this.props.disabled ? (e) => e.preventDefault() : this.increment}
              className="uk-padding-remove gh-spinner-increase"
            >
              <span className="" data-uk-icon="icon: plus"></span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

NumberComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.array,
  className: PropTypes.string,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number,
  disabled: PropTypes.bool,
};

NumberComponent.defaultProps = {
  label: "",
  className: "uk-input",
  labelClassName: [],
  required: false,
  value: "",
  step: 1,
};

export { NumberComponent };
