import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
// import "core-js/fn/object/assign";
// import "core-js/fn/promise";
import {
  returnRouteUrl,
  RouteWithSubRoutes,
  fireTracking,
  convertObjectToArrayForFilters,
  setBrowserHistory,
  unsetBrowserHistory,
  prepareQueryFromModelForFilters,
} from "../../../../shared";
import { MarketPlaceLayout } from "../../../layouts";
import { operatorMarketplaceTabs, operatorMarketRequestConfig } from "../../../../configs";
import { markAsViewed } from "../../../../actions";
import { MiniTabComponent } from "../../../common";
import {
  OperatorMarketPlaceEmptyLegsListComponent,
  OperatorMarketPlaceRequestsListComponent,
  OperatorMarketplaceRequestsFiltersComponent,
} from "./";
import GetHeliEvents from "../../../../services/event.service";
import { actions } from "react-redux-form";
import staticService from "../../../../services/static.service";
import { requests } from "../../../../reducers";

class OperatorMarketPlaceRequestsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.selectedView = this.selectedView.bind(this);
    this.state = {
      filters: queryString.parse(props.location.search),
      view: this.props.history.location?.state?.emptyLeg ? "empty-legs" : "requests",
      isFiltersShown: false,
      requestID: null,
      filterFailed: "",
      method: this.getMethod(),
      requestsObj: {
        default_filters: {},
      },
      tabs: [
        {
          title: staticService.findByAlias("requestsForQuotesLabel"),
          view: "requests",
        },
        {
          title: staticService.findByAlias("emptyLegRequestsLabel"),
          view: "empty-legs",
        },
      ],
    };
    fireTracking(props.location.pathname);
    this.setFailed = this.setFailed.bind(this);
  }

  getMethod(view = null) {
    return view === "empty-legs" ? "getOperatorRequestsByFilter" : "getRequestsByFilter";
  }

  selectedView(view, ev) {
    ev.preventDefault();
    this.setState({
      view: view,
      filterFailed: "",
      isFiltersShown: false,
      method: this.getMethod(view),
    });
  }

  get view() {
    switch (this.state.view) {
      case "empty-legs":
        return (
          <OperatorMarketPlaceEmptyLegsListComponent
            filters={this.state.filters}
            history={this.props.history}
          />
        );
      default:
        return (
          <OperatorMarketPlaceRequestsListComponent
            filters={this.state.filters}
            history={this.props.history}
            queryParams={convertObjectToArrayForFilters(
              queryString.parse(this.props.location.search),
            )}
          />
        );
    }
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      isFiltersShown: !prevState.isFiltersShown,
    }));
  }

  onRequestDetailEnter(id) {
    this.setState({ requestID: id });
  }

  setFailed(total) {
    if (parseInt(total) === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  componentDidMount() {
    GetHeliEvents.setEvent("requests-refresh-collection", (event, total) => {
      this.setFailed(total);
    });

    GetHeliEvents.setEvent("emptylegs-refresh-collection", (event, total) => {
      this.setFailed(total);
    });

    // if (this.props.history.location.state && this.props.history.location.state.emptyLeg) {
    //   this.setState({ view: "empty-legs" });
    // }

    this.context.store.dispatch(
      actions.change("operatorMarketplaceRequestsFiltersComponent", this.state.filters),
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch(actions.reset("operatorMarketplaceRequestsFiltersComponent"));
    GetHeliEvents.clearEvent("requests-refresh-collection");
    GetHeliEvents.clearEvent("emptylegs-refresh-collection");
    if (parseInt(this.props.total) > 0) {
      this.context.store.dispatch(markAsViewed());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.requests &&
      nextProps.requests.data &&
      nextProps.requests.data.length &&
      (!this.state.requestsObj ||
        JSON.stringify(requests) !== JSON.stringify(this.state.requestsObj))
    ) {
      this.setState({ requestsObj: nextProps.requests });
    }
  }

  onSubmit(model) {
    this.setState({ filters: prepareQueryFromModelForFilters(model) });
    setBrowserHistory(this.props.history, "/marketplace", model);
  }

  onReset() {
    this.setState({ filters: queryString.parse(this.props.location.search) });
    unsetBrowserHistory(this.props.history, "/marketplace");
  }

  render() {
    return (
      <MarketPlaceLayout
        pageTitle={"Marketplace"}
        isFiltersShown={this.state.isFiltersShown}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        navigationTabs={operatorMarketplaceTabs}
        activeRoute={this.props.match}
        isFilterVisible={this.state.requestID === null}
        filterCss={this.state.filterFailed}
        showMarketplaceButton={false}
        requestsObj={this.state.requestsObj}
      >
        {this.state.isFiltersShown ? (
          <OperatorMarketplaceRequestsFiltersComponent
            visible={this.state.isFiltersShown}
            getMethodName={this.state.method}
            sortParams={this.props.sort}
            queryParams={this.state.filters}
            onSubmit={this.onSubmit.bind(this)}
            onReset={this.onReset.bind(this)}
            history={this.props.history}
            headers={operatorMarketRequestConfig.headers}
            defaultFilters={this.state.requestsObj.default_filters}
            requests={this.props.requests.data}
          />
        ) : null}
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  {...returnRouteUrl(route, this.props.match.url)}
                  title={route.title}
                  onRequest={this.onRequestDetailEnter.bind(this)}
                />
              );
            })}
          <Route
            path="/"
            onEnter={fireTracking}
            render={() => {
              return (
                <div className={"uk-margin-top"}>
                  {this.state.requestsObj ? (
                    <MiniTabComponent
                      visible={true}
                      tabs={this.state.tabs}
                      selectedView={this.state.view}
                      onClick={this.selectedView}
                      itemsCount={[
                        { count: this.state.requestsObj.requests },
                        { count: this.state.requestsObj.empty_leg_requests },
                      ]}
                    />
                  ) : (
                    ""
                  )}

                  {this.view}
                </div>
              );
            }}
          />
          <Route
            path="/requests/empty-legs/:id"
            onEnter={fireTracking}
            render={() => <div className={"uk-margin-top"} />}
          />
        </Switch>
      </MarketPlaceLayout>
    );
  }
}

OperatorMarketPlaceRequestsComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

OperatorMarketPlaceRequestsComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    sort: state.gridSort,
    // sort: state.requests.default_filters,
    requests: state.requests,
    emptyLegs: state.emptyLegs,
    total: state.request_notification,
    defaultRequests: state.defaultRequests,
  };
};

const COMPONENT = connect(mapStateToProps)(OperatorMarketPlaceRequestsComponent);
export { COMPONENT as OperatorMarketPlaceRequestsComponent };
