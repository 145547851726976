import React from "react";
import PropTypes from "prop-types";

const ButtonComponent = ({
  mapProps = { className: "uk-button uk-button-primary", type: "button" },
  action,
  children,
}) => {
  return (
    <button {...mapProps} onClick={action}>
      {children}
    </button>
  );
};

ButtonComponent.propTypes = {
  mapProps: PropTypes.object,
  action: PropTypes.func.isRequired,
};

export { ButtonComponent };
