import React from "react";
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from "react-google-maps";
import { compose, lifecycle, shouldUpdate } from "recompose";
import PropTypes from "prop-types";
import chillout from "chillout";
import primaryIcon from "../../assets/img/svg/mapMarkerPrimary.svg";
import { arrayFlatten } from "../helpers";
import link from "../../assets/img/external-link.svg";
import cardMapMarker from "../../assets/img/svg/cardMapMarker.svg";
import mapCardStar from "../../assets/img/svg/mapCardStar.svg";
import reviewIcon from "../../assets/img/svg/reviewsIcon.svg";
import arrowRight from "../../assets/img/svg/mapArrowRight.svg";
import eventIcon from "../../assets/img/svg/eventIcon.svg";
import hotelIcon from "../../assets/img/svg/hotelIcon.svg";
import cutleryIcon from "../../assets/img/svg/cutlery.svg";
import landingSiteIcon from "../../assets/img/svg/landingLetter.svg";
import planeIcon from "../../assets/img/svg/plane.svg";
import apoiIcon from "../../assets/img/svg/circleIcon.svg";
import eventPrimary from "../../assets/img/svg/evenPrimary.svg";
import hotelPrimary from "../../assets/img/svg/hotelPrimary.svg";
import restaurantPrimary from "../../assets/img/svg/restaurantPrimary.svg";
import landingPrimary from "../../assets/img/svg/mapMarkerPrimary.svg";
import planePrimary from "../../assets/img/svg/planePrimary.svg";
import apoiPrimary from "../../assets/img/svg/apoiPrimary.svg";
import eventSecondary from "../../assets/img/svg/eventSecondary.svg";
import hotelSecondary from "../../assets/img/svg/hotelSecondary.svg";
import landingSecondary from "../../assets/img/svg/mapMarkerSecondary.svg";
import restaurantSecondary from "../../assets/img/svg/restaurantSecondary.svg";
import planeSecondary from "../../assets/img/svg/planeSecondary.svg";
import apoiSecondary from "../../assets/img/svg/apoiSecondary.svg";
import clusterCircle from "../../assets/img/svg/clusterCircle.svg";

const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

let count = {};

const notSameCoords = (coords, flightPlanCoordinates) => {
  return flightPlanCoordinates[0]
    .map((item) => item.lat !== coords.lat && item.lng !== coords.lng)
    .some((item) => item === true);
};

const MapComponent = compose(
  lifecycle({
    ref: {},
    componentWillMount() {
      let bounds = getMapBounds(this.props.markers);
      this.setState({
        mapMarkers: [],

        setState: (key, data) => this.setState({ [key]: data }),

        onMapMounted: (ref) => {
          if (ref) {
            this.ref[this.props.id] = {};
            this.ref[this.props.id].map = ref;
            this.props.setMapRef(this.ref[this.props.id].map);
            if (bounds && !this.props.woFitBounds) {
              ref.fitBounds(bounds);
            }
          }
        },
        onPlacesChanged: () => {
          if (this.ref[this.props.id].map) {
            if (bounds && !this.props.woFitBounds) {
              this.ref.map.fitBounds(bounds);
            }
          }
        },
        onZoomChanged: () => {
          count[this.props.id] = 1;
          this.props.closePopups();
          this.props.onDirectionsDrawn([]);
          if (typeof this.props.onClusterClose === "function") {
            this.props.onClusterClose();
          }
        },

        onIdle: () => {
          if (this.ref[this.props.id].map && count[this.props.id] <= 1) {
            const { map } = this.ref[this.props.id];
            const { flightPlanCoordinates: coords = [] } = this.props;
            const directions = coords.map(
              (plan) => getFlightDirectionLines(plan, map.getProjection(), map.getZoom()),
              map,
            );
            this.props.onDirectionsDrawn(directions);
            count[this.props.id]++;
            // this.props.changeSelectLegShow()
          }
        },
        onMarkerClustererClick: (cluster) => {
          this.props.closePopups();
          const center = cluster.getCenter();
          const markers = cluster.getMarkers();
          // this.props.onClusterClick({
          //   position: { lat: center.lat(), lng: center.lng() },
          //   markers,
          // });
        },

        onMarkerClick: (ev) => {
          this.ref[this.props.id].map.panTo(ev.latLng);
        },

        onArrowHover: (ev) => {
          this.props.setArrowInfoShown(true);
        },

        onArrowOut: (ev) => {
          this.props.setArrowInfoShown(false);
        },

        mountOnUpdate: false,
      });
    },

    clusterer(restMarkers) {
      function primaryIcon(type) {
        switch (type) {
          case "Event":
            return eventPrimary;
          case "Hotel":
            return hotelPrimary;
          case "Restaurant":
            return restaurantPrimary;
          case "Landing Site":
            return landingPrimary;
          case "Aerodrome":
            return planePrimary;
          case "Aerial Point of Interest":
            return apoiPrimary;
          default:
            return apoiPrimary;
        }
      }

      function secondaryIcon(type) {
        switch (type) {
          case "Event":
            return eventSecondary;
          case "Hotel":
            return hotelSecondary;
          case "Restaurant":
            return restaurantSecondary;
          case "Landing Site":
            return landingSecondary;
          case "Aerodrome":
            return planeSecondary;
          case "Aerial Point of Interest":
            return apoiSecondary;
          default:
            return apoiSecondary;
        }
      }
      const mapMarkers = [];
      chillout
        .forEach(restMarkers, ({ lat, lng, ...props }, i) => {
          const marker = (
            <Marker
              key={i}
              zIndex={100}
              options={{
                icon:
                  props.isSecondary && notSameCoords({ lat, lng }, this.props.flightPlanCoordinates)
                    ? secondaryIcon(props.type)
                    : primaryIcon(props.type),
              }}
              // onClick={(ev) => {
              //   this.state.onMarkerClick(ev);
              //   return props.onClick();
              // }}
              onClick={props.onClick}
              position={{ lat, lng }}
            >
              <AssignToPadInfoWindow {...props} isSelectLegShow={this.props.isSelectLegShow} />
              <SelectLegInfoWindow {...props} />
            </Marker>
          );
          mapMarkers.push(marker);
        })
        .then(() => {
          this.setState({ mapMarkers });
        });
    },

    componentDidMount() {
      this.setState({ propMarkers: [] });
    },

    componentDidUpdate(oldProps, prevState) {
      const { propMarkers } = this.state;
      if (propMarkers && JSON.stringify(prevState.propMarkers) !== JSON.stringify(propMarkers)) {
        this.setState({ propMarkers: this.props.markers });
        if (propMarkers.length) {
          this.addBounds(this.props, this.ref[this.props.id].map);
        }
      }

      if (
        !this.state.mountOnUpdate &&
        this.props.markers.length !== oldProps.markers.length &&
        this.props.mountOnUpdate
      ) {
        this.setState({ mountOnUpdate: true });
        this.addBounds(this.props, this.ref[this.props.id].map);
      }
    },

    addBounds(props, map) {
      const { flightPlanCoordinates: coords = [] } = props;
      const directions =
        coords.length &&
        coords.map(
          (plan) => getFlightDirectionLines(plan, map.getProjection(), map.getZoom()),
          map,
        );
      props.onDirectionsDrawn(directions);
      let bounds = getMapBounds(props.markers);
      if (bounds && !props.woFitBounds) {
        map.fitBounds(bounds);
      }
      if (
        this.ref[this.props.id] &&
        this.ref[this.props.id].map &&
        props.flightPlanCoordinates[0].length === 0 &&
        props.markers.length === 0
      ) {
        this.ref[
          this.props.id
        ].map.__reactInternalMemoizedMaskedChildContext.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
          {
            lat: 51.506011,
            lng: -0.128992,
          },
        );
        setTimeout(
          () =>
            this.ref[
              this.props.id
            ].map.__reactInternalMemoizedMaskedChildContext.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
              12,
            ),
          100,
        );
      }
    },

    componentWillReceiveProps(nextProps) {
      if (
        JSON.stringify(nextProps.flightPlanCoordinates) !==
          JSON.stringify(this.props.flightPlanCoordinates) &&
        this.ref[this.props.id] &&
        this.ref[this.props.id].map
      ) {
        this.addBounds(nextProps, this.ref[this.props.id].map);
      }
    },
  }),
  withGoogleMap,
)((props) => {
  if (!props.visible) {
    return null;
  }

  let lat = 51.506011;
  let lng = -0.128992;
  if (props.flightPlanCoordinates.length > 0 && props.flightPlanCoordinates[0].length > 0) {
    lat = props.flightPlanCoordinates[0][0].lat;
    lng = props.flightPlanCoordinates[0][0].lng;
  }
  let zoom = 12;
  if (props.zoom) {
    zoom = props.zoom;
  }
  const {
    curvedDirectionLines = [],
    markers = [],
    flightPlanCoordinates = [],
    defaultSetZoom = 12,
    position = {
      lat: lat,
      lng: lng,
    },
    isSelectLegShow,
  } = props;
  const _props = props;

  const padMarkers = (() => {
    function primaryIcon(type) {
      switch (type) {
        case "Event":
          return eventPrimary;
        case "Hotel":
          return hotelPrimary;
        case "Restaurant":
          return restaurantPrimary;
        case "Landing Site":
          return landingPrimary;
        case "Aerodrome":
          return planePrimary;
        case "Aerial Point of Interest":
          return apoiPrimary;
        default:
          return apoiPrimary;
      }
    }

    function secondaryIcon(type) {
      switch (type) {
        case "Event":
          return eventSecondary;
        case "Hotel":
          return hotelSecondary;
        case "Restaurant":
          return restaurantSecondary;
        case "Landing Site":
          return landingSecondary;
        case "Aerodrome":
          return planeSecondary;
        case "Aerial Point of Interest":
          return apoiSecondary;
        default:
          return apoiSecondary;
      }
    }

    // if (flightPlanCoordinates[0].length) {
    //   return markers.map(({ lat, lng, ...restProps }, i) => {
    //     return (
    //       <Marker
    //         key={i}
    //         zIndex={100}
    //         options={{
    //           icon:
    //             restProps.isSecondary && notSameCoords({ lat, lng }, flightPlanCoordinates)
    //               ? secondaryIcon(restProps.type)
    //               : primaryIcon(restProps.type),
    //         }}
    //         onClick={(ev) => {
    //           _props.onMarkerClick(ev);
    //           return restProps.onClick();
    //         }}
    //         position={{ lat, lng }}
    //       >
    //         <AssignToPadInfoWindow {...restProps} isSelectLegShow={isSelectLegShow} />
    //         <SelectLegInfoWindow {...restProps} />
    //       </Marker>
    //     );
    //   });
    // }
    const checkPropsChange = (props, nextProps) => {
      return nextProps.markers.length !== props.markers.length; //re-render only if markers prop changed
    };

    const MapMarkerClusterer = shouldUpdate(checkPropsChange)((props) => {
      return <MarkerClusterer {...props}>{props.children}</MarkerClusterer>;
    });

    const landingSites = () => {
      if (props.showLandingsPads) {
        const duplicatedMarkers = markers.filter((m) => m.hasOwnProperty("duplicates"));

        return (
          <div>
            {duplicatedMarkers.length
              ? duplicatedMarkers.map(({ lat, lng, ...props }, i) => {
                  return (
                    <Marker
                      key={i}
                      zIndex={100}
                      options={{
                        icon:
                          props.isSecondary && notSameCoords({ lat, lng }, flightPlanCoordinates)
                            ? secondaryIcon(props.type)
                            : primaryIcon(props.type),
                      }}
                      onClick={(ev) => {
                        _props.onMarkerClick(ev);
                        return props.onClick();
                      }}
                      position={{ lat, lng }}
                    >
                      <AssignToPadInfoWindow {...props} isSelectLegShow={isSelectLegShow} />
                      <SelectLegInfoWindow {...props} />
                    </Marker>
                  );
                })
              : ""}
            <MapMarkerClusterer
              averageCenter
              minimumClusterSize={4}
              enableRetinaIcons
              gridSize={80}
              styles={[
                {
                  url: clusterCircle,
                  height: 41,
                  width: 41,
                },
              ]}
            >
              {markers.map(({ lat, lng, ...restProps }, i) => {
                return (
                  <Marker
                    key={i}
                    zIndex={100}
                    options={{
                      icon:
                        restProps.isSecondary && notSameCoords({ lat, lng }, flightPlanCoordinates)
                          ? secondaryIcon(restProps.type)
                          : primaryIcon(restProps.type),
                    }}
                    onClick={(ev) => {
                      _props.onMarkerClick(ev);
                      return restProps.onClick();
                    }}
                    position={{ lat, lng }}
                  >
                    <AssignToPadInfoWindow {...restProps} isSelectLegShow={isSelectLegShow} />
                    <SelectLegInfoWindow {...restProps} />
                  </Marker>
                );
              })}
            </MapMarkerClusterer>
          </div>
        );
      } else {
        if (flightPlanCoordinates[0].length) {
          return markers.map(({ lat, lng, ...props }, i) => {
            return (
              <Marker
                key={i}
                zIndex={100}
                options={{
                  icon:
                    props.isSecondary && notSameCoords({ lat, lng }, flightPlanCoordinates)
                      ? secondaryIcon(props.type)
                      : primaryIcon(props.type),
                }}
                onClick={(ev) => {
                  _props.onMarkerClick(ev);
                  return props.onClick();
                }}
                position={{ lat, lng }}
              >
                <AssignToPadInfoWindow {...props} isSelectLegShow={isSelectLegShow} />
                <SelectLegInfoWindow {...props} />
              </Marker>
            );
          });
        }
      }
    };

    return landingSites();
  })();

  const curvedMarkers = arrayFlatten(curvedDirectionLines).map(
    ({ position, icon, midLine, rotation }, i) => {
      const currentZoom = props.mapRef.getZoom();
      return (
        <div key={i}>
          <Marker
            zIndex={1}
            options={{
              icon: {
                path: 1,
                fillColor: "#242f42",
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: "white",
                scale: 7,
                rotation: rotation,
              },
            }}
            // onMouseOver={props.onArrowHover}
            // onMouseOut={props.onArrowOut}
            position={midLine}
          >
            <ArrowInfo isArrowInfoShown={props.isArrowInfoShown} />
          </Marker>
          <Marker
            zIndex={-1}
            options={{
              icon,
            }}
            position={position}
          />
        </div>
      );
    },
  );

  return (
    <GoogleMap
      defaultCenter={position}
      defaultZoom={defaultSetZoom}
      onProjectionChanged={props.onPlacesChanged}
      ref={props.onMapMounted}
      // onDragStart={props.closePopups}
      zoom={props.zoom}
      onIdle={props.onIdle}
      onZoomChanged={props.onZoomChanged}
      defaultOptions={defaultMapOptions}
      mapTypeId={props.mapType}
      onClick={props.onMapClick}
    >
      {props.children}
      {curvedMarkers}
      {padMarkers}
    </GoogleMap>
  );
});

function getFlightDirectionLines(coordinates, mapProjection, mapZoom, map) {
  const flightDirections = [];

  coordinates.forEach((flight, index) => {
    if (index < coordinates.length - 1) {
      const coodrs = {
        from: flight,
        to: coordinates[index + 1],
      };
      if (mapProjection) flightDirections.push(getCurvedLine(coodrs, mapProjection, mapZoom, map));
    }
  });

  return flightDirections;
}

function getMarker(position, marker_icon) {
  return new google.maps.Marker({
    position: position,
    icon: marker_icon,
  });
}

function getCurvedLine({ from, to }, projection, mapZoom, map) {
  let curvature = 0.2;

  let positionFrom = getMarker(from, primaryIcon).getPosition();
  let positionTo = getMarker(to, primaryIcon).getPosition();

  let positionToFirstPoint = projection.fromLatLngToPoint(positionFrom);
  let positionToSecondPoint = projection.fromLatLngToPoint(positionTo);

  let e = new google.maps.Point(
    positionToSecondPoint.x - positionToFirstPoint.x,
    positionToSecondPoint.y - positionToFirstPoint.y,
  ); // endpoint (p2 relative to p1)
  let m = new google.maps.Point(e.x / 2, e.y / 2);
  let o = new google.maps.Point(e.y, -e.x);
  let c = new google.maps.Point(m.x + curvature * o.x, m.y + curvature * o.y);

  let path_defined = "M 0,0 " + "q " + c.x + "," + c.y + " " + e.x + "," + e.y;

  let curvedLineMiddlePoint = new google.maps.Point(
    c.x + positionToFirstPoint.x - curvature * o.x * 0.5,
    c.y + positionToFirstPoint.y - curvature * o.y * 0.5,
  );

  let aPt = projection.fromPointToLatLng(curvedLineMiddlePoint);
  let lineHeading = window.google.maps.geometry.spherical.computeHeading(positionFrom, positionTo); // direction to head of the curved line
  const relativeScale = 1 / Math.pow(2, -mapZoom);

  // let scale = relativeScale < 512 ? relativeScale : 512;
  let drawnSymbol = {
    path: path_defined,
    scale: relativeScale,
    strokeWeight: 3,
    strokeColor: "#242F42",
    strokeOpacity: 1.0,
    fillColor: "none",
  };

  return new google.maps.Polyline({
    position: positionFrom,
    clickable: false,
    icon: drawnSymbol,
    zIndex: 0,
    midLine: aPt,
    rotation: lineHeading,
  });
}

const getMapBounds = (markers = []) => {
  const coordsArray = markers.map(({ lat, lng }) => ({ lat, lng }));
  if (coordsArray.length > 0) {
    const bounds = new google.maps.LatLngBounds();
    coordsArray.forEach((t) => bounds.extend(t));
    return bounds;
  }
  return null;
};

function placeTypeIcon(type) {
  switch (type) {
    case "Event":
      return eventIcon;
    case "Hotel":
      return hotelIcon;
    case "Restaurant":
      return cutleryIcon;
    case "Landing Site":
      return landingSiteIcon;
    case "Aerodrome":
      return planeIcon;
    case "Aerial Point of Interest":
      return apoiIcon;
    default:
      return apoiIcon;
  }
}

const AssignToPadInfoWindow = ({
  isSecondary,
  isNewMarker,
  isInfoShown,
  onClick,
  isSelectLegShow,
  name,
  onAssignButtonClick,
  handleAssign,
  assignLegButton,
  image,
  source,
  rating,
  type,
  reviews,
}) => {
  if (isInfoShown && (isSecondary || isNewMarker)) {
    const assignButton = (site) => {
      if (isNewMarker) {
        handleAssign(site);
      } else {
        onAssignButtonClick(site);
      }
    };
    return (
      <InfoWindow>
        <div className="legInfoWindow">
          {image ? (
            <div
              className="imageBox"
              style={{
                background: `url(${image}) center center / cover no-repeat`,
              }}
            />
          ) : (
            ""
          )}
          <div className="content">
            <h4 className="top-head">
              {source ? (
                <a href={source}>
                  {" "}
                  {name} <img className="gh-link-icon" src={link} alt="link" />
                </a>
              ) : (
                name
              )}
            </h4>

            <div data-uk-grid className="uk-grid-small">
              <div className="uk-width uk-width-1-2">
                <span className="item-icon">
                  <img src={placeTypeIcon(type)} alt="cutleryIcon" />{" "}
                  <span className="text">{type}</span>
                </span>
              </div>
              <div className="uk-width uk-width-1-2">
                <span className="item-icon">
                  <img src={cardMapMarker} alt="cardMapMarker" /> <span className="text">-</span>
                </span>
              </div>
              {/*<div className="uk-width uk-width-1-2">*/}
              {/*  <span className="item-icon">*/}
              {/*    <img src={mapCardStar} alt="mapCardStar" />{" "}*/}
              {/*    <span className="text">{rating || 0}</span>*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className="uk-width uk-width-1-2">*/}
              {/*  <span className="item-icon">*/}
              {/*    <img src={reviewIcon} alt="reviewIcon" />{" "}*/}
              {/*    <span className="text">{reviews || 0} reviews</span>*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
            {!isSelectLegShow ? (
              <a onClick={assignLegButton}>
                <h4 className="bottom-head secondary">
                  Use this helipad <img src={arrowRight} alt="arrow" />
                </h4>
              </a>
            ) : (
              <div style={{ marginTop: 20 }} className="legInfoWindowActionLegs">
                <a onClick={() => assignButton("from")}>FROM</a>
                <span style={{ margin: "0 15px", fontWeight: 600, fontSize: 14 }}>or</span>
                <a onClick={() => assignButton("to")}>TO</a>
              </div>
            )}
          </div>
        </div>
      </InfoWindow>
    );
  }
  return null;
};

const ArrowInfo = ({ isArrowInfoShown = false }) => {
  if (!isArrowInfoShown) return null;
  return (
    <InfoWindow>
      <div className="arrowInfoWindow">Outbound</div>
    </InfoWindow>
  );
};

const SelectLegInfoWindow = ({
  isSelectLegInfoShown,
  closePopUp,
  type,
  name,
  rating,
  image,
  reviews,
}) => {
  if (!isSelectLegInfoShown) {
    return null;
  }
  return (
    <InfoWindow>
      <div className="legInfoWindow">
        {image ? (
          <div
            className="imageBox"
            style={{
              background: `url('https://images.pexels.com/photos/3244513/pexels-photo-3244513.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260') center center / cover no-repeat`,
            }}
          />
        ) : (
          ""
        )}
        <div className="content">
          <a>
            <h4 className="top-head">
              {name} <img className="gh-link-icon" src={link} alt="link" />
            </h4>
          </a>
          <div data-uk-grid className="uk-grid-small">
            <div className="uk-width uk-width-1-2">
              <span className="item-icon">
                <img src={placeTypeIcon(type)} alt="cutleryIcon" />{" "}
                <span className="text">{type}</span>
              </span>
            </div>
            <div className="uk-width uk-width-1-2">
              <span className="item-icon">
                <img src={cardMapMarker} alt="cardMapMarker" /> <span className="text">-</span>
              </span>
            </div>
            {/*<div className="uk-width uk-width-1-2">*/}
            {/*  <span className="item-icon">*/}
            {/*    <img src={mapCardStar} alt="mapCardStar" />{" "}*/}
            {/*    <span className="text">{rating || 0}</span>*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div className="uk-width uk-width-1-2">*/}
            {/*  <span className="item-icon">*/}
            {/*    <img src={reviewIcon} alt="reviewIcon" />{" "}*/}
            {/*    <span className="text">{reviews || 0} reviews</span>*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
          <h4 className="bottom-head">This is your chosen take-off site</h4>
        </div>
      </div>
    </InfoWindow>
  );
};

const defaultMapOptions = {
  streetViewControl: false,
  scaleControl: true,
  mapTypeControl: true,
  panControl: true,
  zoomControl: true,
  rotateControl: false,
  fullscreenControl: true,
  mountOnUpdate: false,
  position: {},
};

Map.propTypes = {
  visible: PropTypes.bool,
  mountOnUpdate: PropTypes.bool,
  markers: PropTypes.array,
  flightPlanCoordinates: PropTypes.array,
  hasCluster: PropTypes.bool,
  clusterInfo: PropTypes.object,
  onClusterClick: PropTypes.func,
  onClusterClose: PropTypes.func,
  defaultSetZoom: PropTypes.number,
  position: PropTypes.object,
  woFitBounds: PropTypes.bool,
  onDirectionsDrawn: PropTypes.func,
};

export { MapComponent };
