import * as types from "../../actions/actionTypes";

const initialState = { errors: [], error: {} };

export function errorObj(state = initialState.errors, action) {
  switch (action.type) {
    case types.ADD_ERROR:
      return action.errors || action.error || state;
    case types.CLEAR_ERRORS:
      return null;
    case types.CHECK_INVITATION_FAILED:
      return { failedInvitation: action.errors || action.error };
    case types.CHECK_HASH_FAILED:
      return { failedHash: action.errors || action.error };
    case types.REDIRECT_TO_LOGIN:
      return action;
    default:
      return state;
  }
}
