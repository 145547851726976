import React, { Component } from "react";
import "./EventEnquire.css";
import checkIcon from "../../../assets/img/svg/check.svg";
import { EnquireForm } from "../event-enquire-form";
import { AdditionalInfo } from "../event-additionl-info";
import HelicoptersList from "../eventHelicopters";
import staticService from "../../../services/static.service";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import googleApi from "../../../services/google.service";
import { config, DETAILS, OPTIONS, STEPS_PASSED } from "../../../configs";
import { bindActionCreators } from "redux";
import {
  CLEAR_ERRORS,
  getHeliOptions,
  getHeliOptionsDestination,
  storeRequest,
  storeRequestDestination,
  updateDestinationEnquireLog,
  updateEventEnquireLog,
  updateStepEnquireForms,
} from "../../../actions";
import { EnquirePassed } from "../event-enquire-passed";
import {
  convertObjectToArrayForFilters,
  handleDatePickerSelection,
  updateModel,
} from "../../../shared";
import moment from "moment";
import tranformDateTimeToISO from "../../../utils/tranformDateTimeToISO";

const modelName = "enquireForm";
let isSwapButtonDisabled = false;

class EventEnquire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "flights",
      stepIndex: 0,
      steps: [
        staticService.findByAlias("flightLabel"),
        staticService.findByAlias("heliOptionsLabel"),
        staticService.findByAlias("detailsLabel"),
      ],
      typeOfTrip: "one",
      form: {},
      numberOfPax: 0,
      logId: null,
      timezoneOffset: "",
      loadingBtn: false,
      swapButtonLoading: false,
      isToLocationSwapped: false,
      fromCustomMessage: "",
      fromLandingFeeCustomMessage: "",
      toCustomMessage: "",
      toLandingFeeCustomMessage: "",
      warningMessages: {},
    };
    this.loadView = this.loadView.bind(this);
    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.switchTypeOfTrip = this.switchTypeOfTrip.bind(this);
    this.updateRange = this.updateRange.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.goToOptions = this.goToOptions.bind(this);
    this.goToDetails = this.goToDetails.bind(this);
    this.submitEnquire = this.submitEnquire.bind(this);
    this.submitEnquireDestination = this.submitEnquireDestination.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.resetReturn = this.resetReturn.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.updatePax = this.updatePax.bind(this);
    this.step = this.step.bind(this);
    this.handleFlipLocations = this.handleFlipLocations.bind(this);
  }

  updateForm(event) {
    this.context.store.dispatch(
      actions.change(`${modelName}.to_coords`, event.venue_coords ? event.venue_coords : ""),
    );
    this.context.store.dispatch(actions.change(`${modelName}.to`, event.event_name));
    this.context.store.dispatch(actions.change(`${modelName}.public_event_id`, event.id));
    if (event.event_location) {
      this.context.store.dispatch(
        actions.change(`${modelName}.to_secondary_text`, event.event_location.secondary_text),
      );
    }
  }

  componentDidMount() {
    const { event, updateStepEnquireForms } = this.props;
    updateStepEnquireForms("flights");

    if (Object.keys(event).length) {
      this.updateForm(event);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { phone, email } = nextProps.enquireForm;
    const { number_adults, number_children } = nextProps.enquireForm;
    const event = nextProps.event;
    const nextEventID = event.id;
    const eventID = this.props.enquireForm.public_event_id;
    const shouldUpdate = eventID !== nextEventID;

    if (Object.keys(event).length && shouldUpdate) {
      this.updateForm(event);
    }
    if (this.props.enquireForm.from_coords !== nextProps.enquireForm.from_coords) {
      this.context.store.dispatch(actions.resetValidity("enquireForm.from"));
      this.context.store.dispatch(actions.resetValidity("enquireForm.from_coords"));
      this.context.store.dispatch({ type: CLEAR_ERRORS });
    }
    const paxNumber = number_adults + number_children;
    this.setState({
      numberOfPax: paxNumber,
      typeOfTrip: nextProps.enquireForm.typeOfTrip,
      form: nextProps.enquireForm,
    });
    if (phone && email && (phone.length || email.length)) {
      this.context.store.dispatch(actions.resetValidity(modelName));
    }
  }

  componentDidUpdate(prevProps) {
    const { history } = this.props;

    if (prevProps.activeStep !== this.props.activeStep) {
      if (
        history.location.pathname.search("thank-you") !== -1 &&
        this.props.activeStep !== "stepsPassed"
      ) {
        history.push(history.location.pathname.replaceAll("/thank-you", ""));
      }
    }
  }

  step(step, index) {
    const { stepIndex } = this.state;
    const current = stepIndex === index;
    const passed = stepIndex > index;
    const number = index + 1;
    const cls = () => {
      if (current) {
        return { class: " gh-step-current", val: number };
      }
      if (passed) {
        return {
          class: " gh-step-passed",
          val: <img src={checkIcon} alt="check" />,
        };
      }
      return { class: "", val: number };
    };

    return (
      <div key={step} className="gh-step">
        <div className="gh-step-num-box">
          <div className={"gh-step-num" + cls().class}>
            <span>{cls().val}</span>
          </div>
        </div>
        <span className="gh-step-title">{step}</span>
      </div>
    );
  }

  changeStep(step) {
    const { updateStepEnquireForms } = this.props;
    switch (step) {
      case "options":
        this.setState({ view: "options", stepIndex: 1 });
        updateStepEnquireForms("options");
        return;
      case "details":
        this.setState({ view: "details", stepIndex: 2 });
        updateStepEnquireForms("details");
        return;
      case "stepsPassed":
        this.setState({ view: "stepsPassed", stepIndex: 3 });
        updateStepEnquireForms("stepsPassed");
        return;
      default:
        this.setState({ view: "flights", stepIndex: 0 });
        updateStepEnquireForms("flights");
        return;
    }
  }

  switchTypeOfTrip(e, type) {
    e.preventDefault();
    this.context.store.dispatch(actions.reset(`${modelName}.return_time`));
    this.context.store.dispatch(actions.change(`${modelName}.typeOfTrip`, type));
  }

  goToOptions(formValue) {
    const { enquireForm, fromDestinations, getHeliOptionsDestination } = this.props;

    if (formValue) {
      this.setState({ loadingBtn: true });
    }

    const optionsFilter = {
      from_name: enquireForm.from,
      to_name: enquireForm.to,
      event_id: enquireForm.public_event_id,
      from_coords: enquireForm.from_coords,
      to_coords: enquireForm.to_coords,
      pax: this.state.numberOfPax,
      adults_amount: enquireForm.number_adults,
      children_amount: enquireForm.number_children,
      number_luggage: enquireForm.number_luggage,
      from_secondary_text: enquireForm.from_secondary_text,
      to_secondary_text: enquireForm.to_secondary_text,
    };

    optionsFilter.departure_time = tranformDateTimeToISO(
      enquireForm.date,
      enquireForm.departure_time,
    );

    if (enquireForm.return_time) {
      optionsFilter.return_time = tranformDateTimeToISO(
        enquireForm.return_date,
        enquireForm.return_time,
      );
    }

    const changeStep = (data) => {
      const { event } = this.props;
      this.setState({ loadingBtn: false });
      if (data !== undefined) {
        if (event.hide_enquiry_options) {
          this.changeStep("details");
        } else {
          this.changeStep("options");
        }

        this.setState({
          logId: data.log_id ? data.log_id : null,
          timezoneOffset: moment().utcOffset() / 60,
          warningMessages: data.warning_messages,
        });
      }
    };

    if (fromDestinations) {
      getHeliOptionsDestination(
        convertObjectToArrayForFilters({
          ...optionsFilter,
          destination_id: optionsFilter.event_id,
        }),
      ).then((data) => {
        changeStep(data);
      });
    } else {
      this.props
        .getHeliOptions(convertObjectToArrayForFilters(optionsFilter))
        .then((data) => {
          changeStep(data);
        })
        .catch(() => this.setState({ loadingBtn: false }));
    }
  }

  goToDetails(heli) {
    const { updateEventEnquireLog, updateDestinationEnquireLog, fromDestinations } = this.props;
    this.setState({ loadingBtn: true });

    const data = {
      log_id: heli.log_id || "",
      value: {
        selected_aircraft: heli.id || null,
        log_id: this.state.logId,
      },
    };

    const changeStep = () => {
      this.setState({ loadingBtn: false });
      this.context.store.dispatch(actions.change(`${modelName}.aircraft_type_id`, heli.id));
      this.changeStep("details");
    };

    if (fromDestinations) {
      updateDestinationEnquireLog(data.log_id, data.value)
        .then(() => {
          changeStep();
        })
        .catch(() => this.setState({ loadingBtn: false }));
    } else {
      updateEventEnquireLog(data.log_id, data.value)
        .then(() => {
          changeStep();
        })
        .catch(() => this.setState({ loadingBtn: false }));
    }
  }

  submitEnquire() {
    const { logId, timezoneOffset } = this.state;
    const newData = { log_id: logId, timezone_offset: timezoneOffset };
    const { history, enquireForm } = this.props;

    this.setState({ loadingBtn: true });
    if (this.props.enquireForm.email.length === 0) {
      const { email, ...rest } = enquireForm;
      this.props.storeRequest({ ...rest, ...newData }).then((data) => {
        this.setState({ loadingBtn: false });
        if (data) {
          this.changeStep("stepsPassed");
          if (window.dataLayer) {
            window.dataLayer.push({ event: "generate_lead" });
          }
        }
      });
    } else if (this.props.enquireForm.phone.length === 0) {
      const { phone, ...rest } = enquireForm;
      this.props.storeRequest({ ...rest, ...newData }).then((data) => {
        this.setState({ loadingBtn: false });
        if (data) {
          this.changeStep("stepsPassed");
          if (window.dataLayer) {
            window.dataLayer.push({ event: "generate_lead" });
          }
        }
      });
    } else {
      this.props.storeRequest({ ...enquireForm, ...newData }).then((data) => {
        this.setState({ loadingBtn: false });
        if (data) {
          this.changeStep("stepsPassed");
          if (window.dataLayer) {
            window.dataLayer.push({ event: "generate_lead" });
          }
        }
      });
    }
  }

  submitEnquireDestination() {
    const { logId, timezoneOffset } = this.state;
    const newData = { log_id: logId, timezone_offset: timezoneOffset };
    const { history, enquireForm, storeRequestDestination } = this.props;
    this.setState({ loadingBtn: true });

    storeRequestDestination({
      ...enquireForm,
      ...newData,
      destination_id: enquireForm.public_event_id,
    }).then((data) => {
      this.setState({ loadingBtn: false });
      if (data) {
        this.changeStep("stepsPassed");
        if (window.dataLayer) {
          window.dataLayer.push({ event: "generate_lead" });
        }
      }
    });
  }

  fillCoordinates(modelKey, address, index) {
    const model = `${modelName}.${modelKey}_coords`;
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateLegOnSelect(modelKey, address, index);
      });
    } else {
      this.context.store.dispatch(actions.setTouched(model));
    }
  }

  updateLegOnSelect(modelKey, address) {
    const model = { ...this.props.enquireForm };
    if (address) {
      model[modelKey] = address.location;
      model[`${modelKey}_coords`] = `${address.latitude}|${address.longitude}`;
      model[`${modelKey}_source`] = address.source;
      model[`${modelKey}_info`] = `${address.category || "Event"}|${address.secondary_text || ""}`;
      model[`${modelKey}_coverage_status`] = address.coverage_status;
      model[`${modelKey}description`] = address.description;
      model[`${modelKey}_secondary_text`] = address.secondary_text;
      this.context.store.dispatch(actions.change(`${modelName}`, model));
    }
  }

  onSelectLocation(handleClickOutside, modelKey, address) {
    const { isToLocationSwapped } = this.state;
    const { custom_message, landing_fee_custom_message } = address;

    if (
      (modelKey === "from" && !isToLocationSwapped) ||
      (modelKey === "to" && isToLocationSwapped)
    ) {
      this.setState({
        fromCustomMessage: custom_message,
        fromLandingFeeCustomMessage: landing_fee_custom_message,
      });
    } else if (
      (modelKey === "from" && isToLocationSwapped) ||
      (modelKey === "to" && !isToLocationSwapped)
    ) {
      this.setState({
        toCustomMessage: custom_message,
        toLandingFeeCustomMessage: landing_fee_custom_message,
      });
    }

    handleClickOutside();
    if (address && address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address, 0);
    } else {
      this.updateLegOnSelect(modelKey, address, 0);
    }
  }

  updateGeocodeModel(model, value) {
    this.context.store.dispatch(actions.change(`${modelName}.${model}`, value));
    this.context.store.dispatch(actions.change(`${modelName}.${model}_coords`, ""));
    this.context.store.dispatch(actions.change(`${modelName}.${model}_info`, ""));
  }

  onPickerChange(model, value) {
    return updateModel(model, handleDatePickerSelection(value));
  }

  updateRange(model, value) {
    const { typeOfTrip } = this.state;
    if (!value.format) return;
    const newValue = value.format(config.timeFormat);

    if (typeOfTrip === "one" && model === `${modelName}.departure_time`) {
      this.context.store.dispatch(actions.change(model, newValue));
      this.context.store.dispatch(actions.resetValidity(`${modelName}.return_time`));
    }
    if (typeOfTrip !== "one" && model === `${modelName}.departure_time`) {
      this.context.store.dispatch(actions.change(model, newValue));
      this.context.store.dispatch(actions.resetValidity(`${modelName}.return_time`));
      const returnTime = moment(value, "HH:mm").add(1, "hours").format(config.timeFormat);
      this.context.store.dispatch(actions.change(`${modelName}.return_time`, returnTime));
    }
    this.context.store.dispatch(actions.resetValidity(`${modelName}.departure_time`));
    if (model === `${modelName}.return_time`) {
      this.context.store.dispatch(actions.resetValidity(model));
      this.context.store.dispatch(actions.change(model, newValue));
    }
  }

  resetReturn() {
    setTimeout(() => {
      this.context.store.dispatch(actions.reset(`${modelName}.return_time`));
      this.context.store.dispatch(actions.resetErrors(`${modelName}.return_time`));
      this.context.store.dispatch(actions.resetValidity(`${modelName}.return_time`));
    }, 100);
  }

  updateTime(e) {
    const { name, value } = e.target;
    const model = `${modelName}.${name}`;
    const val = moment(value, "H:mm");
    this.context.store.dispatch(actions.resetValidity(model));
    if (this.state.typeOfTrip === "one") {
      this.resetReturn();
    }
    this.context.store.dispatch(actions.change(model, val.format(config.timeFormat)));
  }

  updatePax(model, value) {
    this.props.updateModel(model, parseInt(value, 10));
    this.resetReturn();
  }

  handleFlipLocations(e) {
    e.preventDefault();
    if (isSwapButtonDisabled) return;
    const {
      event: { venue_coords },
    } = this.props;
    const {
      to,
      to_coords,
      from_coords,
      from,
      from_coverage_status,
      from_info,
      from_source,
      to_coverage_status = null,
      to_info = null,
      to_source = null,
    } = this.props.enquireForm;
    const formKeys = {
      to_coords: from_coords,
      from_coords: to_coords,
      from: to,
      to: from,
      to_coverage_status: from_coverage_status,
      to_info: from_info,
      to_source: from_source,
      from_coverage_status: to_coverage_status,
      from_info: to_info,
      from_source: to_source,
    };
    if (!to_coords || !from_coords || !to || !from) return;
    isSwapButtonDisabled = true;
    this.setState({ swapButtonLoading: true });
    Object.entries(formKeys).forEach(([formKey, formValue]) =>
      this.context.store.dispatch(actions.change(`${modelName}.${formKey}`, formValue)),
    );
    setTimeout(() => (isSwapButtonDisabled = false), 300);
    setTimeout(() => this.setState({ swapButtonLoading: false }), 0);

    if (to_coords === venue_coords) this.setState({ isToLocationSwapped: true });
    else this.setState({ isToLocationSwapped: false });

    if (this.props.fromDestinations) {
      this.setState({ isToLocationSwapped: !this.state.isToLocationSwapped });
    }
  }

  loadView(view) {
    const { event, fromDestinations } = this.props;
    const {
      fromCustomMessage,
      fromLandingFeeCustomMessage,
      toCustomMessage,
      toLandingFeeCustomMessage,
    } = this.state;

    switch (view) {
      case OPTIONS:
        return (
          <HelicoptersList
            helicoptersList={this.props.heliOptions}
            enquireFurther={this.goToDetails}
            typeOfTrip={this.state.typeOfTrip}
            back={() => this.changeStep("flights")}
            loadingBtn={this.state.loadingBtn}
            warningMessages={this.state.warningMessages}
          />
        );
      case DETAILS:
        return (
          <AdditionalInfo
            model={modelName}
            submit={fromDestinations ? this.submitEnquireDestination : this.submitEnquire}
            form={this.state.form}
            back={() => this.goToOptions()}
            loadingBtn={this.state.loadingBtn}
            formDispatch={this.context.store.dispatch}
          />
        );
      case STEPS_PASSED:
        return <EnquirePassed />;
      default:
        return (
          <EnquireForm
            model={modelName}
            form={this.props.enquireForm}
            onSelectLocation={this.onSelectLocation}
            updateGeocodeModel={this.updateGeocodeModel}
            date={this.date}
            onPickerChange={this.onPickerChange.bind(this)}
            switchType={this.switchTypeOfTrip}
            typeOfTrip={this.props.enquireForm.typeOfTrip}
            updateRange={this.updateRange}
            goToOptions={this.goToOptions}
            updateTime={this.updateTime}
            numberOfPax={this.state.numberOfPax}
            updatePax={this.updatePax}
            loadingBtn={this.state.loadingBtn}
            handleFlipLocations={this.handleFlipLocations}
            swapButtonLoading={this.state.swapButtonLoading}
            isToLocationSwapped={this.state.isToLocationSwapped}
            fromDestinations={this.props.fromDestinations}
            fromCustomMessage={fromCustomMessage}
            fromLandingFeeCustomMessage={fromLandingFeeCustomMessage}
            toCustomMessage={
              fromDestinations ? toCustomMessage : event?.event_location?.custom_message
            }
            toLandingFeeCustomMessage={
              fromDestinations
                ? toLandingFeeCustomMessage
                : event?.event_location?.landing_fee_custom_message
            }
          />
        );
    }
  }

  render() {
    const { steps } = this.state;
    const { event } = this.props;

    const filteredSteps = steps.filter((step) => {
      if (step === "Options" && event.hide_enquiry_options) {
        return;
      }
      return step;
    });

    return (
      <div className="uk-background-default">
        <div className="gh-enquire">
          <div className="gh-steps">
            {filteredSteps.map((item, index) => this.step(item, index))}
          </div>
          <div className="gh-enquire-content">{this.loadView(this.props.activeStep)}</div>
        </div>
      </div>
    );
  }
}

EventEnquire.contextTypes = {
  store: PropTypes.object,
};

EventEnquire.propTypes = {
  history: PropTypes.object,
  activeStep: PropTypes.any,
  storeRequest: PropTypes.func,
  enquireForm: PropTypes.object,
  updateEventEnquireLog: PropTypes.func,
  event: PropTypes.object,
  heliOptions: PropTypes.array,
  getHeliOptions: PropTypes.func,
  updateStepEnquireForms: PropTypes.func,
  updateModel: PropTypes.func,
  fromDestinations: PropTypes.bool,
  getHeliOptionsDestination: PropTypes.func,
  updateDestinationEnquireLog: PropTypes.func,
  storeRequestDestination: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      getHeliOptions: getHeliOptions,
      getHeliOptionsDestination: getHeliOptionsDestination,
      updateEventEnquireLog: updateEventEnquireLog,
      updateDestinationEnquireLog: updateDestinationEnquireLog,
      storeRequest: storeRequest,
      storeRequestDestination: storeRequestDestination,
      updateStepEnquireForms: updateStepEnquireForms,
    },
    dispatch,
  );
};

function mapStateToProps({ enquireForm, heliOptions, activeStep }) {
  return {
    enquireForm: enquireForm,
    heliOptions: heliOptions,
    activeStep: activeStep,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventEnquire);
