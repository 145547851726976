import React from "react";
import staticService from "../../../../services/static.service";
import logo from "../../../../assets/img/svg/vetting-logo.svg";
import check from "../../../../assets/img/check-circle.png";
import link from "../../../../assets/img/external-link.svg";
import { dateFormat, isObjectEmpty } from "../../../../shared";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import auth from "../../../../services/auth";

export const BrokerVettingComponent = ({ quote, learnMore }) => {
  const vetting = quote.vetting_process;
  const removeAnonymityForBrokers = auth.hasFeature("remove_anonymity_for_brokers");

  if (!vetting || isObjectEmpty(vetting)) return "";
  return (
    <div className="gh-vetting">
      <h4 className="gh-box-logo">
        <img className="gh-box-logo-img" src={logo} alt="logo" />
      </h4>
      <h4 className="gh-box-title" onClick={learnMore}>
        {staticService.findByAlias("ghVettingProcess")}
        <img className="gh-link-icon" src={link} alt="external-link" />
      </h4>
      <div data-uk-grid>
        <div className="uk-width-1-1">
          <div data-uk-grid className="uk-grid-small">
            <div className="uk-width-1-1 uk-width-3-5@s">
              <span className="gh-box-label">{staticService.findByAlias("lastAuditDate")}</span>
            </div>
            <div className="uk-width-1-1 uk-width-2-5@s gh-vetting-date">
              <span className="gh-box-text">{dateFormat(vetting.last_audit_date)}</span>
            </div>
          </div>
          <div className="uk-width-1-1 gh-margin-ex-small-top">
            <div data-uk-grid className="uk-grid-small">
              <div className="uk-width-1-1 uk-width-3-5@s">
                <span className="gh-box-label">
                  {staticService.findByAlias("confirmedByOperatorOn")}
                </span>
              </div>
              <div className="uk-width-1-1 uk-width-2-5@s gh-vetting-date">
                <span className="gh-box-text">{dateFormat(vetting.confirmed_by_operator_at)}</span>
              </div>
            </div>
          </div>
          {vetting.company_registration ? (
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-3-5 uk-width-3-5@s">
                  <span className="gh-box-label">
                    {staticService.findByAlias("companyRegistration")}
                  </span>
                </div>
                <div className="uk-width-1-5 uk-width-2-5@s">
                  <img className="gh-box-icon" src={check} alt="icon" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {vetting.aoc_evidence ? (
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-3-5 uk-width-3-5@s">
                  <span className="gh-box-label">{staticService.findByAlias("AOCEvidence")}</span>
                </div>
                <div className="uk-width-1-5 uk-width-2-5@s">
                  <img className="gh-box-icon" src={check} alt="icon" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {vetting.helicopter_on_aoc ? (
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-3-5 uk-width-3-5@s">
                  <span className="gh-box-label">
                    {staticService.findByAlias("helicopterOnAOC")}
                  </span>
                </div>
                <div className="uk-width-1-5 uk-width-2-5@s">
                  <img className="gh-box-icon" src={check} alt="icon" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {vetting.helicopter_insurance ? (
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <div data-uk-grid className="uk-grid-small">
                <div className="uk-width-3-5 uk-width-3-5@s">
                  <span className="gh-box-label">
                    {staticService.findByAlias("helicopterInsurance")}
                  </span>
                </div>
                <div className="uk-width-1-5 uk-width-2-5@s">
                  <img className="gh-box-icon" src={check} alt="icon" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/*<hr className="gh-divider" />*/}
          {/*<a className="gh-vetting-more-link" onClick={learnMore}>*/}
          {/*  <h4>{staticService.findByAlias("learnMoreAboutVetting")}</h4>*/}
          {/*  <img className="gh-link-icon" src={link} alt="external-link" />*/}
          {/*</a>*/}

          {auth.hasFeature("downloadable_documents") && quote.id && (
            <div className="uk-width-1-1 gh-margin-ex-small-top">
              <Link to={`/documents/quote/${quote.id}`} target="_blank">
                <h4 className="gh-box-title uk-margin-remove-bottom">
                  {staticService.findByAlias("ghVettingProcessViewDocuments")}
                  <img className="gh-link-icon" src={link} alt="external-link" />
                </h4>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BrokerVettingComponent.propTypes = {
  removeAnonymityForBrokers: PropTypes.bool,
};
BrokerVettingComponent.defaultProps = {
  removeAnonymityForBrokers: false,
};
