import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";
import auth from "../services/auth";

const AccountLocalizationContext = createContext();

const timeFormats = {
  1: "HH:mm",
  2: "LT",
};

const generateAccountLocalization = () => {
  let timeFormat = timeFormats[1];

  if (auth.isAuthenticated()) {
    timeFormat = timeFormats[auth.company.hours_format || 1];
  }

  return { timeFormat };
};

const useAccountLocalization = () => useContext(AccountLocalizationContext);

const AccountLocalizationProvider = ({ children }) => {
  const config = React.useMemo(generateAccountLocalization, []);

  return (
    <AccountLocalizationContext.Provider value={{ ...config }}>
      {children}
    </AccountLocalizationContext.Provider>
  );
};

AccountLocalizationProvider.propTypes = {
  children: PropTypes.any,
};

export {
  AccountLocalizationProvider,
  AccountLocalizationContext,
  useAccountLocalization,
  generateAccountLocalization,
};
