import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, actions } from "react-redux-form";
import { updateChangePassword } from "../../../actions";
import { profileTabs } from "../../../configs";
import { fireTracking } from "../../../shared";
import staticService from "../../../services/static.service";
import auth from "../../../services/auth";
import {
  NotificationsSettingsBrokerComponent,
  NotificationsSettingsOperatorComponent,
} from "../../pages/user-online/common/notifications";
import { MenuComponent } from "../../pages/user-online/common";

class NotificationsSettingsFormComponent extends React.Component {
  constructor(props) {
    super(props);
    const items = profileTabs.filter((tab) => tab.roles.indexOf(auth.getRole()) !== -1);
    this.state = {
      selectedTab: items[0],
      tabs: items.map((menu) => {
        menu.title = staticService.findByAlias(menu.alias);
        return menu;
      }),
    };
    fireTracking(props.location.pathname);
  }

  render() {
    return (
      <div data-uk-grid>
        <div className={"uk-width-1-4@m uk-width-1-1@s"}>
          <MenuComponent items={this.state.tabs} />
        </div>
        <div className="uk-width-1-1@s  gh-account-settings-margin gh-modal-promo-small uk-width-1-2@m">
          {(auth.getRole() === "broker_team" ||
            auth.getRole() === "broker_admin" ||
            auth.getRole() === "sole_trader") && <NotificationsSettingsBrokerComponent />}
          {(auth.getRole() === "operator_team" || auth.getRole() === "operator_admin") && (
            <NotificationsSettingsOperatorComponent />
          )}
        </div>
      </div>
    );
  }
}

NotificationsSettingsFormComponent.contextTypes = {
  store: PropTypes.object,
  getNotifications: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  return {
    getNotifications: state.getNotificationsSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveChanges: updateChangePassword,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(NotificationsSettingsFormComponent);
export { COMPONENT as NotificationsSettingsFormComponent };
