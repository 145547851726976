import React from "react";
import PropTypes from "prop-types";

const WarningMessage = (props) => {
  const { message } = props;

  if (!message) return "";

  return (
    <div className="uk-flex" style={{ alignItems: "baseline", marginBottom: "5px" }}>
      <span
        className="uk-text-warning uk-margin-small-right"
        data-uk-icon="icon: warning"
        style={{ display: "inline-block", minWidth: "20px" }}
      />
      <span
        className="gh-estimate-warning uk-text-warning"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  );
};

WarningMessage.propTypes = {
  message: PropTypes.string,
};

export default WarningMessage;
