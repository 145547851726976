import React from "react";
import PropTypes from "prop-types";
import { InputNumber } from "./InputNumber";
import cs from "../../../../utils/condString";

import "./index.css";

export const ConfirmInput = React.forwardRef((props, ref) => {
  const {
    name,
    onChange,
    value,
    onFocus,
    onBlur,
    readOnly,
    onValueChange,
    placeholder,
    onReset,
    hideActions,
    invalid,
    onClick,
    maxLength,
  } = props;
  const [focused, setFocused] = React.useState(false);
  const [input, setInput] = React.useState(value);
  const confirmRef = React.useRef();
  const cancelRef = React.useRef();

  React.useEffect(() => setInput(value), [value]);

  const confirmHandler = () => {
    onChange(input);
    if (onValueChange) onValueChange();
  };

  const cancelHandler = () => {
    onChange("");
    if (onValueChange) onValueChange();
  };

  const focusHandler = (e) => {
    if (onFocus) onFocus(e);
    setFocused(true);
  };

  const blurHandler = (e) => {
    if (onBlur) onBlur(e);

    if (e.relatedTarget === confirmRef.current) {
      confirmHandler();
    } else if (e.relatedTarget === cancelRef.current) {
      cancelHandler();
    } else {
      setInput(value);
    }

    setFocused(false);
  };

  const keyDownHandler = (e) => {
    if (e.key === "Tab" || e.key === "Escape") e.target.blur();
    if (e.key === "Enter") {
      e.relatedTarget = confirmRef.current;
      blurHandler(e);
    }
  };

  return (
    <div className="calculator__input" onClick={onClick}>
      <InputNumber
        ref={ref}
        name={name}
        value={input}
        placeholder={placeholder}
        onChange={(e) => {
          if (!maxLength || maxLength >= e.length) {
            setInput(e);
          }
        }}
        onKeyDown={keyDownHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        readOnly={readOnly}
        invalid={invalid}
      />
      {onReset && !focused && (
        <button
          type="button"
          tabIndex="-1"
          className="calculator-reset-btn calculator__input-btn reset"
          onClick={onReset}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clipRule="evenodd"
            />
          </svg>
          <span>Reset</span>
        </button>
      )}
      {!hideActions && (
        <div className={cs("calculator__input-actions", focused && !readOnly && "visible")}>
          <button
            type="button"
            tabIndex="-1"
            className="calculator-reset-btn calculator__input-btn"
            ref={confirmRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
          </button>
          <button
            type="button"
            className="calculator-reset-btn calculator__input-btn"
            tabIndex="-1"
            ref={cancelRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
});

ConfirmInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onReset: PropTypes.func,
  hideActions: PropTypes.bool,
  invalid: PropTypes.bool,
  onClick: PropTypes.func,
  maxLength: PropTypes.number,
};

ConfirmInput.defaultProps = {
  value: "",
};

ConfirmInput.displayName = "ConfirmInput";
