import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { getOperatorNewRequests, loadUserDashboardStatistic } from "../../../../actions";
import GetHeliEvents from "../../../../services/event.service";

class ButtonWithBadgeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      in: false,
      animationStop: 1,
      counter: 0,
    };
    this.onEnter = this.onEnter.bind(this);
    this.onExit = this.onExit.bind(this);
    this.start = this.start.bind(this);

    this.timer = null;
  }

  componentDidMount() {
    this.props.loadUserDashboardStatistic();
    if (this.props.visible && !this.props.firstTimeLoaded) {
      this.props.getOperatorNewRequests();
    }
    this.setState({ counter: this.props.marketplace_counter });
    GetHeliEvents.setEvent("new-requests", (ev, data) => {
      this.setState({ counter: data.marketplace_counter });
      if (parseInt(data.marketplace_counter) > parseInt(this.props.marketplace_counter)) {
        this.start();
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.marketplace_counter &&
      nextProps.marketplace_counter !== this.props.marketplace_counter
    ) {
      this.setState({ counter: nextProps.marketplace_counter });
    }
    if (
      this.props.marketplace_counter &&
      nextProps.marketplace_counter &&
      nextProps.marketplace_counter !== this.props.marketplace_counter
    ) {
      this.start();
    }
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("new-requests");
    clearInterval(this.timer);
  }

  start() {
    clearInterval(this.timer);
    this.setState({
      in: true,
      animationStop: 1,
    });

    this.timer = setInterval(() => {
      this.setState({ animationStop: this.state.animationStop + 1 });
    }, 1000);
  }

  onExit() {
    this.setState({
      in: false,
    });
  }

  onEnter() {
    if (this.state.animationStop > 10) {
      clearInterval(this.timer);
    } else {
      this.setState({ in: true });
    }
  }

  get classes() {
    return [
      "uk-button",
      "uk-button-primary",
      "gh-btn-max-width-200",
      "uk-align-center",
      "uk-margin-remove-top",
      "uk-padding-remove",
      "uk-margin-small-top",
      "uk-position-relative",
    ].concat(this.props.classes);
  }

  get badge() {
    if (this.state.counter > 0) {
      return (
        <span
          className={"uk-label uk-label-danger gh-notifications-label no-border rightTop bigger"}
        >
          {this.state.counter}
        </span>
      );
    }
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <CSSTransition
        in={this.state.in}
        timeout={this.props.duration || 2000}
        classNames={"pulse"}
        onExited={this.onEnter}
        onEntered={this.onExit}
      >
        {() => {
          return (
            <NavLink to={this.props.route} className={this.classes.join(" ")}>
              {this.props.label} {this.badge}
            </NavLink>
          );
        }}
      </CSSTransition>
    );
  }
}

ButtonWithBadgeComponent.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  classes: PropTypes.array,
  duration: PropTypes.number,
  visible: PropTypes.bool,
};

ButtonWithBadgeComponent.defaultProps = {
  classes: [],
  duration: 2000,
};

ButtonWithBadgeComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ...state.request_notification,
    ...state.dashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOperatorNewRequests,
      loadUserDashboardStatistic,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ButtonWithBadgeComponent);
export { COMPONENT as ButtonWithBadgeComponent };
