import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { BlockComponent } from "../../../common";
import { TabComponent, returnRouteUrl, RouteWithSubRoutes, storage } from "../../../../shared";
import { accountTabs, companyTabs } from "../../../../configs";
import staticService from "../../../../services/static.service";
import Auth from "../../../../services/auth";
import { IsVerifiedAuthorization, ComponentAuthorization } from "../../../../components";

const blockClasses = ["uk-section", "uk-padding-remove-top"];

class BrokerCompanyComponent extends React.Component {
  constructor(props) {
    super(props);
    const items = accountTabs.filter((tab) => tab.roles.indexOf(Auth.getRole()) !== -1);
    this.state = {
      selectedTab: items[0],
      tabs: items,
    };
  }

  onTabClick(tab) {
    this.setState({ selectedTab: tab });
  }

  requestButtonProps() {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  }

  render() {
    return (
      <section className={"profile-page"}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-bottom header-title",
          }}
        >
          <div className="uk-width-1-1">
            <h3
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("company"),
              }}
            />
          </div>
          <div className="uk-width-1-1">
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={this.requestButtonProps()}
              roles={["broker_admin", "broker_team", "sole_trader"]}
            />
          </div>
        </BlockComponent>
        <BlockComponent visible={true} parentProps={{ className: blockClasses.join(" ") }}>
          <TabComponent
            items={this.state.tabs}
            id={"profile-tab"}
            visible={true}
            tabProps={{ className: "uk-tab uk-margin" }}
            onClick={this.onTabClick.bind(this)}
            selected={this.state.selectedTab}
            linkType={"nav"}
          />

          <Switch>
            {this.props.routes &&
              this.props.routes.map((route, i) => {
                return (
                  <RouteWithSubRoutes
                    key={i}
                    title={route.title}
                    {...returnRouteUrl(route, this.props.match.url)}
                  />
                );
              })}
            <Redirect from="/account/banking" exact to="/account/banking/account" />
          </Switch>
        </BlockComponent>
      </section>
    );
  }
}

BrokerCompanyComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = ({ company }) => {
  return {
    company: company,
  };
};

const COMPONENT = connect(mapStateToProps)(BrokerCompanyComponent);
export { COMPONENT as BrokerCompanyComponent };
