import * as types from "../../actions/actionTypes";

function teamMembers(state = [], action) {
  switch (action.type) {
    case types.SET_TEAM_MEMEBERS:
    case types.USER_REMOVE_SUCCESS:
      return [...action.members];
    default:
      return state;
  }
}

function invitations(state = [], action) {
  switch (action.type) {
    case types.GET_INVITATIONS:
    case types.INVITATION_REMOVE_SUCCESS:
      return [...action.invitations];
    case types.RESET_LIST:
      return [];
    default:
      return state;
  }
}

export { teamMembers, invitations };
