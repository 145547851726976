import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Control, Form, Errors } from "react-redux-form";
import { validationRules, validationMessages, config } from "../../configs";
import { updateModel, CustomValidateError, aircraftTitle } from "../../shared";
import staticService from "../../services/static.service";

const { required } = validationRules;

export class PremiumFormComponent extends React.Component {
  onSuccess(model) {
    this.props.onSuccess({
      id: this.props.aircraftModel.id,
      message: model.message,
    });
  }

  get refurbishedDate() {
    const _date = moment(this.props.aircraftModel.date_last_refurbished);
    if (_date.isValid()) return _date.format(config.dobFormat);
    return "-";
  }

  render() {
    return (
      <Form model={"premium"} className={"uk-form-stacked"} onSubmit={this.onSuccess.bind(this)}>
        <p>{staticService.findByAlias("premiumModalContent")}</p>

        <ul className="disabled-list uk-list uk-list-divider">
          <li>
            <b className={"font-weight-500"}>
              {staticService.findByAlias("premiumModalAircraftLabel")}
            </b>
            : <span dangerouslySetInnerHTML={{ __html: aircraftTitle(this.props.aircraftModel) }} />
          </li>
          <li>
            <b className={"font-weight-500"}>
              {staticService.findByAlias("premiumModalDateTitle")}
            </b>
            : {this.refurbishedDate}
          </li>
          <li>
            <b className={"font-weight-500"}>
              {staticService.findByAlias("premiumModalLuxuryLabel")}
            </b>
            :
            <div className={"uk-margin-medium-top"}>
              <Control.textarea
                className={"uk-textarea"}
                model={".message"}
                changeAction={updateModel}
                placeholder={staticService.findByAlias("premiumModalTextBoxLabel")}
                updateOn="change"
                validators={{ required }}
              />
              <Errors
                model=".message"
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  required: validationMessages().requiredMessage.bind(
                    null,
                    staticService.findByAlias("premiumModalTextBoxLabel"),
                  ),
                }}
              />
            </div>
          </li>
        </ul>
        <p>{staticService.findByAlias("premiumModalExtraContent")}</p>
        <hr className="uk-divider" />
        <div className={"uk-flex uk-flex-center uk-margin-medium-top"}>
          <div>
            <Control.button model={"premium"} className={"uk-button uk-button-primary"}>
              {staticService.findByAlias("submit")}
            </Control.button>
          </div>

          <div className={"uk-margin-left"}>
            <Control.button
              model={"premium"}
              type={"button"}
              onClick={this.props.onCancel}
              className={"uk-button uk-button-danger"}
            >
              {staticService.findByAlias("cancel")}
            </Control.button>
          </div>
        </div>
      </Form>
    );
  }
}

PremiumFormComponent.propTypes = {
  aircraftModel: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
