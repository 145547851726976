import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import SelectComponent from "../SelectComponent";
import { inArrayWithKey } from "../../../shared";
import { config, ownerApprovalOptions } from "../../../configs";
import trashIcon from "../../../assets/img/svg/trash.svg";
import moment from "moment";
import staticService from "../../../services/static.service";
import Tooltip from "../Tooltip";
import FlagIcon from "../FlagIcon";
import infoRed from "../../../assets/img/svg/info_red.svg";
import infoYellow from "../../../assets/img/svg/info_yellow.svg";
import { connect } from "react-redux";
import "./aircraftComponent.css";

const helicopterLabel = (helicopter) => {
  const date = moment(helicopter.date_last_refurbished);
  let aircraftDate = date.isValid() ? `refurb.${date.format("YYYY")}` : helicopter.year_manufacture;

  return `${helicopter.registration_number}  ${helicopter.manufacturer} ${helicopter.aircraft_model} (${aircraftDate})`;
};

const AircraftComponent = (props) => {
  const {
    control,
    editingQuote,
    editingQuote: { helicopters, extra_helicopters },
    handleChangeHelicopter,
    additionalClassName,
    handleDeleteRow,
    helicopter,
    indexKey,
    handleChangeAircraftOption,
    clearErrors,
    selectedHeliList,
    leg: { selected_aircrafts, selected_additional_aircrafts, date },
    readOnly,
    screenDimensions,
    aircraftKey,
    index,
  } = props;

  const currentHeli = selectedHeliList.find((h) => h.uid === helicopter.uid);
  let isHeliInsured = true;

  const manageHeliWarning = (heli) => {
    const departure = date;

    if (heli.expiration_date !== null) {
      const departureDate = moment(departure);
      const heliInsuranceExpirationDate = moment(heli.expiration_date);

      isHeliInsured = heliInsuranceExpirationDate.isAfter(departureDate);
    }

    let warning = null;

    if (heli.busy_state === "by_booking" && !isHeliInsured) {
      warning = (
        <span
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "HeliUnassuredAndBooked",
          )}; pos: top-left`}
        >
          <img src={infoRed} alt="info" />
        </span>
      );
    } else if (heli.busy_state === "by_quote" && !isHeliInsured) {
      warning = (
        <span
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "HeliUnassuredAndQuoted",
          )}; pos: top-left`}
        >
          <img src={infoYellow} alt="info" />
        </span>
      );
    } else if (heli.busy_state !== null) {
      warning = (
        <span
          data-uk-tooltip={`title: ${staticService.findByAlias(
            heli.busy_state === "by_booking" ? "heliAssignedToBooking" : "heliAssignedToQuote",
          )}; pos: top-left`}
        >
          <img src={heli.busy_state === "by_booking" ? infoRed : infoYellow} alt="info" />
        </span>
      );
    } else if (!isHeliInsured) {
      warning = (
        <span
          className={"gh-tooltip-heli"}
          data-uk-tooltip={`title: ${staticService.findByAlias(
            "HelicopterInsuranceWillExpireBeforeFlight",
          )}; pos: top-left`}
        >
          <img src={infoRed} alt="info" />
        </span>
      );
    }

    return warning;
  };

  const displayTooltip =
    currentHeli.busy_state &&
    (currentHeli.busy_state === "by_booking" ||
      currentHeli.busy_state === "by_quote" ||
      !isHeliInsured);

  const onHelicopterSelect = (value, field) => {
    field.onChange(value);
    handleChangeHelicopter(value, indexKey);
    clearErrors([
      `${
        aircraftKey === "selected_additional_aircrafts" ? "alternative_" : ""
      }aircraft_model${indexKey}`,
    ]);
  };

  return (
    <div className={`gh-aircraft-wrapper-content ${additionalClassName}`} key={indexKey}>
      <div className="gh-aircraft-wrapper-content-col">
        <div style={{ display: "flex", width: "100%" }}>
          <h4 className="title-item gh-tooltip-wrapper">
            {staticService.findByAlias(
              `${
                aircraftKey === "selected_aircrafts"
                  ? "helicopterSelection"
                  : "alternativeTitleQuote"
              }`,
            )}
            <div className="gh-aircraft-select-tooltip-mob">
              {displayTooltip ? manageHeliWarning(currentHeli) : ""}
            </div>

            {aircraftKey === "selected_aircrafts" && index === 0 ? (
              <div style={{ marginLeft: 15 }}>
                <Tooltip
                  pos="top-right"
                  mobilePos="top-left"
                  refEl="primaryAircraftTooltip"
                  wrapperClassName="gh-alternative-helicopters-tooltip"
                >
                  {staticService.findByAlias("primaryAircraftTooltip")}
                </Tooltip>
              </div>
            ) : (
              ""
            )}
            <div className="gh-aircraft-wrapper-wrapper-btn mobile">
              {(!additionalClassName.includes("first-row") && !readOnly) ||
              aircraftKey === "selected_additional_aircrafts" ? (
                <div className="delete-btn btn-style" onClick={handleDeleteRow}>
                  <img src={trashIcon} alt="Delete" />
                </div>
              ) : (
                ""
              )}
            </div>
          </h4>
        </div>
        {readOnly ? (
          <div className="gh-aircraft-view-value">{helicopterLabel(helicopter)}</div>
        ) : (
          <Controller
            control={control}
            name={`${
              aircraftKey === "selected_additional_aircrafts" ? "alternative_" : ""
            }aircraft_model${indexKey}`}
            rules={{
              required: {
                value: aircraftKey !== "selected_additional_aircrafts",
                message: staticService.findByAlias("helicopterSelectionError"),
              },
            }}
            defaultValue={helicopter && helicopter.id ? helicopter.id : undefined}
            render={({ field, fieldState }) => (
              <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                <SelectComponent
                  options={helicopters}
                  extraOptions={extra_helicopters}
                  selectedAircrafts={[...selected_aircrafts, ...selected_additional_aircrafts]}
                  field={field}
                  fieldState={fieldState}
                  selectedValue={helicopter ? helicopter.id : null}
                  onChange={(value) => onHelicopterSelect(value, field)}
                  optionKey="aircraft_model"
                  helicopterLabel={helicopterLabel}
                />
                <div className="gh-aircraft-select-tooltip-desk">
                  {displayTooltip ? manageHeliWarning(currentHeli) : ""}
                </div>
              </div>
            )}
          />
        )}
      </div>

      <div className="gh-aircraft-wrapper-content-col">
        <h4 className="title-item">{staticService.findByAlias("helicopterType")}</h4>
        <div
          className={`${
            readOnly ? "gh-aircraft-view-value helicopter-type" : "gh-aircraft-wrapper-value"
          }`}
        >
          {helicopter && helicopter.engine_type
            ? inArrayWithKey("id", helicopter.engine_type, config.aircraftPreferences).title
            : "--"}
          {helicopter.engine_type &&
          helicopter.engine_type !== editingQuote.preferences &&
          editingQuote.preferences !== "no-preference" ? (
            <Tooltip
              pos={`bottom-right`}
              refEl="self"
              wrapperClassName="uk-margin-small-left"
              trigger={<FlagIcon />}
            >
              {staticService.findByAlias("modifiedFromOriginal")}
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="gh-aircraft-wrapper-content-col --owners-approval">
        <h4 className="title-item">{staticService.findByAlias("ownersApprovalRequired")}</h4>
        <div className="gh-aircraft-wrapper-last-col">
          {readOnly ? (
            <div className="gh-aircraft-view-value">
              {helicopter.owner_approval !== undefined
                ? staticService.findByAlias(
                    ownerApprovalOptions.find((option) => option.id === helicopter.owner_approval)
                      ?.value,
                  )
                : ""}
            </div>
          ) : (
            <Controller
              control={control}
              name={`owner_approval_${indexKey}`}
              rules={{
                required: {
                  value:
                    aircraftKey === "selected_aircrafts" &&
                    typeof helicopter.owner_approval !== "number",

                  message: staticService.findByAlias("ownersApprovalError"),
                },
              }}
              render={({ field, fieldState }) => (
                <SelectComponent
                  options={ownerApprovalOptions.map((opt) => ({
                    ...opt,
                    value: staticService.findByAlias(opt.value),
                  }))}
                  field={field}
                  selectedValue={helicopter.owner_approval}
                  fieldState={fieldState}
                  onChange={(value) => {
                    field.onChange(value);
                    handleChangeAircraftOption("owner_approval", value, indexKey);
                    clearErrors([`owner_approval_${indexKey}`]);
                  }}
                  optionKey="value"
                  disabled={!helicopter.id}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="gh-aircraft-wrapper-content-col">
        <h4 className="title-item">{staticService.findByAlias("crew")}</h4>
        <div className="gh-aircraft-wrapper-last-col">
          {readOnly ? (
            <div className="gh-aircraft-view-value">
              {helicopter.crew_size === 1 && staticService.findByAlias("onePilot")}
              {helicopter.crew_size === 2 && staticService.findByAlias("twoPilots")}
            </div>
          ) : (
            <Controller
              control={control}
              name={`crew_size${indexKey}`}
              rules={{
                required: {
                  value: aircraftKey !== "selected_additional_aircrafts" && !helicopter.crew_size,
                  message: staticService.findByAlias("crewError"),
                },
              }}
              defaultValue={null}
              render={({ field, fieldState }) => (
                <SelectComponent
                  options={[
                    { id: 1, value: staticService.findByAlias("onePilot") },
                    { id: 2, value: staticService.findByAlias("twoPilots") },
                  ]}
                  field={field}
                  selectedValue={helicopter ? helicopter.crew_size : null}
                  fieldState={fieldState}
                  onChange={(value) => {
                    field.onChange(value);
                    handleChangeAircraftOption("crew_size", value, indexKey);
                    clearErrors([`crew_size${indexKey}`]);
                  }}
                  optionKey="value"
                  disabled={!helicopter.id}
                />
              )}
            />
          )}

          <div className={`gh-crew-buttons ${readOnly ? "read-only" : ""}`}>
            {helicopter.crew_size &&
            helicopter.crew_size !== editingQuote.crew_size &&
            editingQuote.crew_size !== 0 ? (
              <Tooltip
                pos={`bottom-${screenDimensions.screenInnerW > 748 ? "left" : "right"}`}
                refEl="self"
                wrapperClassName="uk-margin-small-left"
                trigger={<FlagIcon />}
              >
                {staticService.findByAlias("modifiedFromOriginal")}
              </Tooltip>
            ) : (
              ""
            )}

            <div className="gh-aircraft-wrapper-wrapper-btn">
              {(!additionalClassName.includes("first-row") && !readOnly) ||
              aircraftKey === "selected_additional_aircrafts" ? (
                <div className="delete-btn btn-style" onClick={handleDeleteRow}>
                  <img src={trashIcon} alt="Delete" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  screenDimensions: store.screenDimensions,
});

AircraftComponent.propTypes = {
  editingQuote: PropTypes.object,
  control: PropTypes.object,
  handleChangeHelicopter: PropTypes.func,
  additionalClassName: PropTypes.string,
  handleDeleteRow: PropTypes.func,
  helicopter: PropTypes.object,
  handleChangeAircraftOption: PropTypes.func,
  indexKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearErrors: PropTypes.func,
  leg: PropTypes.object,
  readOnly: PropTypes.bool,
  selectedHeliList: PropTypes.array,
  screenDimensions: PropTypes.object,
  aircraftKey: PropTypes.string,
  index: PropTypes.number,
};

AircraftComponent.defaultProps = {
  additionalClassName: "",
};

export default connect(mapStateToProps)(AircraftComponent);
