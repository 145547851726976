import React from "react";
import PropTypes from "prop-types";
import { RowComponent } from "./row.component";

const TableComponent = ({
  tableProps,
  rows,
  showActionColumn,
  headers,
  visible,
  headersColumnProps = [],
  actionElements,
}) => {
  if (visible) {
    return (
      <table {...tableProps}>
        <thead>
          {<RowComponent row={headers} isHeader={true} headersColumnProps={headersColumnProps} />}
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => {
            return (
              <RowComponent
                key={rowIndex}
                actionColumn={showActionColumn}
                actionElements={actionElements}
                row={row}
                rowIndex={rowIndex}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
  return null;
};

TableComponent.propTypes = {
  tableProps: PropTypes.object,
  showActionColumn: PropTypes.bool,
  actionElements: PropTypes.any,
  visible: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  headersColumnProps: PropTypes.array,
};

TableComponent.defaultProps = {
  tableProps: {},
  rows: [],
  headers: [],
  showActionColumn: false,
  visible: false,
};

export { TableComponent };
