import moment from "moment";

export const renderTime = (time, options = { showOffset: true }) => {
  let formattedTime = time;

  if (moment(time).isValid()) {
    formattedTime = moment(time).format("HH:mm");
  }

  if (options.showOffset) {
    formattedTime += ` (UTC${moment().format("Z")})`;
  }

  return formattedTime;
};
