import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment";
import staticService from "../../../services/static.service";
import { Controller, useForm } from "react-hook-form";
import AutocompleteInput from "../../common/AutocompleteInput";
import generateLocationAdditionalInfo from "../../../utils/generateLocationAdditionalInfo";
import { DateTimeComponent, NumberComponent } from "../../../shared";
import PhoneInput from "../../common/phoneInput";
import { config } from "../../../configs";
import calendar from "../../../assets/img/svg/calendar.svg";
import check from "../../../assets/img/svg/white-check.svg";
import { useAccountLocalization } from "../../../context";

import "./externalEnquiryForm.css";

const TripInfoErrors = ["from", "to", "return_at", "departure_at", "departure_date"];

function ExternalEquiryForm(props) {
  const {
    location: { search },
  } = props;
  const accountLocalization = useAccountLocalization();
  const [formLocations, setFormLocations] = useState({ from_location: {}, to_location: {} });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm();
  const [typeOfTrip, setTypeOfTrip] = useState("round");
  const [token, setToken] = useState("");
  const [formPax, setFormPax] = useState(0);
  const [formSubmitStatus, setFormSubmitStatus] = useState();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(search);

      if (searchParams.has("token")) {
        setToken(searchParams.get("token"));
      }
    }
  }, [search]);

  const updateLocationHandler = (dir, location) => {
    setFormLocations((prevState) => ({ ...prevState, [`${dir}_location`]: location }));
  };

  const switchType = (type) => {
    if (type === "round") {
      const departureAtVal = getValues("departure_at");

      if (departureAtVal) {
        setValue(
          "return_at",
          moment(departureAtVal, accountLocalization.timeFormat)
            .add(1, "hours")
            .format(config.timeFormat),
        );
      }
    } else {
      setValue("return_at", "");
      clearErrors("return_at");
    }

    setTypeOfTrip(type);
  };

  const validateRange = () => {
    const [dep_at, ret_at] = getValues(["departure_at", "return_at"]);

    const setErr = () =>
      setError("return_at", {
        type: "manual",
        message: "Time of return must be after time of departure",
      });

    if (!dep_at && ret_at) {
      setErr();
      return false;
    }

    if (typeOfTrip === "round") {
      if (
        Number(dep_at.replace("0", "1").replace(":", "")) >=
        Number(ret_at.replace("0", "1").replace(":", ""))
      ) {
        setErr();
        return false;
      }
    }

    return true;
  };

  const updateRange = (name, val) => {
    if (!val.format) return;

    const newValue = val.format(config.timeFormat);

    if (typeOfTrip === "one" && name === "departure_at") {
      setValue("departure_at", newValue);
    }
    if (typeOfTrip !== "one" && name === "departure_at") {
      const returnTime = moment(val, accountLocalization.timeFormat)
        .add(1, "hours")
        .format(config.timeFormat);

      setValue("departure_at", newValue);
      setValue("return_at", returnTime);
    }
    if (name === "return_at") {
      setValue("return_at", newValue);
    }

    validateRange();
  };

  const updatePax = (val) => {
    if (formPax + val > 8)
      setError("pax", { message: staticService.findByAlias("exceededPaxNumber") });
    else clearErrors("pax");

    setFormPax((prevState) => prevState + val);
  };

  const apiErrorsHandler = (errs) => {
    if (errs && Object.keys(errs).length) {
      Object.keys(errs).forEach((key) => {
        setError(key, { message: errs[key][0] });
      });
    }
  };

  const submitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    const res = await fetch(`${config.apiDomain}/v2.1/pipeline`, {
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    });
    const data = await res.json();

    if (res.ok) {
      return Promise.resolve(data);
    }

    return Promise.reject(data);
  };

  const onSubmit = async (values) => {
    const [dep_hrs, dep_mins] = values.departure_at.split(":");
    const [ret_hrs, ret_mins] = (values.return_at && values.return_at.split(":")) || [0, 0];
    const data = {
      ...values,
      ...formLocations,
      departure_at: moment(values.departure_date)
        .add(Number(dep_hrs), "hours")
        .add(Number(dep_mins), "minutes")
        .format("YYYY-MM-DD HH:mm:ss"),
      return_at:
        typeOfTrip === "round"
          ? moment(values.departure_date)
              .add(Number(ret_hrs), "hours")
              .add(Number(ret_mins), "minutes")
              .format("YYYY-MM-DD HH:mm:ss")
          : "",
      phone: values.phone.value,
      passenger_amount:
        values.passenger_amount.number_adults + values.passenger_amount.number_children,
    };

    delete data.departure_date;

    try {
      await submitHandler(data);
      setFormSubmitStatus("success");
    } catch (error) {
      if (error.errors) {
        const isTripStep = Object.keys(error.errors).some((key) => TripInfoErrors.includes(key));

        if (isTripStep) setCurrentStep(0);

        apiErrorsHandler(error.errors);
      } else setFormSubmitStatus("failed");
    }
  };

  const validateTripStep = async () => {
    const result = await trigger([...TripInfoErrors], {});
    const range = validateRange();

    if (result && range) setCurrentStep(1);
  };

  if (formSubmitStatus === "success") {
    return (
      <div className="external-enquiry-form_wrapper">
        <div className="external-enquiry-status">
          <div className="icon">
            <svg className="checkmark-enq" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <div className="content">
            <h3 className="content__title">Thank You!</h3>
            <p className="content__descrp">We will be in touch with you shortly.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="external-enquiry-form_wrapper">
      <div className="external-enquiry-form enquire-form-content">
        {formSubmitStatus === "failed" || !token ? (
          <div className="uk-flex uk-flex-middle gh-gray-warning uk-margin-small-top uk-margin-small-bottom">
            <span
              className="uk-text-warning gh-danger-opostrov gh-vertical-middle uk-margin-small-right uk-position-relative"
              data-uk-icon="icon: warning"
            />
            <span className="gh-estimate-warning">Invalid token! Please contact support!</span>
          </div>
        ) : (
          ""
        )}
        <div
          className={`form-content ${formSubmitStatus === "failed" || !token ? "disabled" : ""}`}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="gh-steps-container">
              <div className={`gh-step-item ${currentStep === 0 ? "active" : ""}`}>
                <Controller
                  control={control}
                  name="from"
                  rules={{
                    required: {
                      value: true,
                      message: `From ${staticService.findByAlias("locationIsRequired")}`,
                    },
                  }}
                  render={({ field: { onChange, value, ref, name }, fieldState }) => (
                    <AutocompleteInput
                      inputRef={ref}
                      onChange={(val) => {
                        clearErrors(name);
                        updateLocationHandler("from", val);
                        onChange(val.location);
                      }}
                      value={value}
                      fieldState={fieldState}
                      placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
                      label={staticService.findByAlias("from")}
                      tooltipSide="right"
                      debounce={200}
                      secondaryInputText={generateLocationAdditionalInfo(
                        formLocations.from_location,
                      )}
                      place={"iframe"}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="to"
                  rules={{
                    required: {
                      value: true,
                      message: `To ${staticService.findByAlias("locationIsRequired")}`,
                    },
                  }}
                  render={({ field: { onChange, value, ref, name }, fieldState }) => (
                    <AutocompleteInput
                      inputRef={ref}
                      onChange={(val) => {
                        clearErrors(name);
                        updateLocationHandler("to", val);
                        onChange(val.location);
                      }}
                      value={value}
                      fieldState={fieldState}
                      placeholder={staticService.findByAlias("newRequestLocationPlaceholder")}
                      label={staticService.findByAlias("to")}
                      tooltipSide="right"
                      debounce={200}
                      secondaryInputText={generateLocationAdditionalInfo(formLocations.to_location)}
                      place={"iframe"}
                    />
                  )}
                />
                <div className="pax-wrapper uk-position-relative uk-margin-top">
                  <div className="gh-number-field">
                    <Controller
                      control={control}
                      name="passenger_amount.number_adults"
                      defaultValue={0}
                      render={({ field: { onChange, value, name } }) => (
                        <NumberComponent
                          name={name}
                          label={staticService.findByAlias("NumberAdults")}
                          className="uk-input"
                          max={8}
                          min={0}
                          step={1}
                          onChange={(val, _, action) => {
                            updatePax(action === "increment" ? 1 : -1);
                            onChange(val);
                          }}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="gh-number-field">
                    <Controller
                      control={control}
                      name="passenger_amount.number_children"
                      defaultValue={0}
                      render={({ field: { onChange, value, name } }) => (
                        <NumberComponent
                          name={name}
                          label={staticService.findByAlias("NumberChildren")}
                          className="uk-input"
                          max={8}
                          min={0}
                          step={1}
                          onChange={(val, _, action) => {
                            updatePax(action === "increment" ? 1 : -1);
                            onChange(val);
                          }}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors.pax && (
                    <div
                      className="validation-errors disabled-list gh-input-errors"
                      title={errors.pax.message}
                    >
                      {errors.pax.message}
                    </div>
                  )}
                </div>
                <div className="gh-enquire-calendar">
                  <label
                    className="uk-form-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("date"),
                    }}
                  />
                  <div className="uk-form-controls uk-position-relative gh-enquire-calendar-box">
                    <Controller
                      control={control}
                      name="departure_date"
                      rules={{ required: { value: true, message: "Departure Date is required" } }}
                      render={({ field: { onChange, value, name } }) => (
                        <DateTimeComponent
                          name={name}
                          onChange={(val) => {
                            clearErrors(name);
                            onChange(val.toString());
                          }}
                          icon={calendar}
                          value={value}
                          className="uk-input gh-calendar-input"
                          isValidDate
                          isValidDateBefore
                          dateFormat={"DD MMMM, YYYY"}
                          showClearIcon={false}
                          attrProps={{ className: "enquire-time-icon" }}
                        />
                      )}
                    />
                    {errors.departure_date && (
                      <div
                        className="validation-errors disabled-list gh-input-errors"
                        title={errors.departure_date.message}
                      >
                        {errors.departure_date.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-1 uk-margin-top">
                  <label
                    className="uk-form-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("typeOfFlightLabel"),
                    }}
                  />
                  <div className="gh-switcher">
                    <button
                      className={`uk-button uk-text-center ${
                        typeOfTrip === "round" ? "uk-button-primary" : "uk-button-default"
                      }`}
                      type="button"
                      onClick={() => switchType("round")}
                    >
                      <img className="gh-switcher-checked" src={check} alt="check" />
                      {staticService.findByAlias("roundtrip")}
                    </button>
                    <button
                      className={`uk-button uk-text-center ${
                        typeOfTrip === "round" ? "uk-button-default" : "uk-button-primary"
                      }`}
                      type="button"
                      onClick={() => switchType("one")}
                    >
                      <img className="gh-switcher-checked" src={check} alt="check" />
                      {staticService.findByAlias("one-way")}
                    </button>
                  </div>
                </div>
                <div className="input-group uk-margin-top">
                  <div className="time-item uk-position-relative uk-margin-small-right">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("timeOfDeparture"),
                      }}
                    />
                    <Controller
                      control={control}
                      name="departure_at"
                      rules={{
                        required: { value: true, message: "Time of Departure is required" },
                      }}
                      render={({ field: { value, name } }) => (
                        <DateTimeComponent
                          name={name}
                          onChange={(val) => {
                            clearErrors(name);
                            clearErrors("return_at");
                            updateRange("departure_at", val);
                          }}
                          value={value}
                          className="uk-input gh-time-input"
                          dateFormat={false}
                          timeFormat={config.timeFormat}
                          placeholder={staticService.findByAlias("time")}
                          showClearIcon={false}
                          attrProps={{ className: "enquire-time-icon" }}
                        />
                      )}
                    />
                    {errors.departure_at && (
                      <div
                        className="validation-errors disabled-list gh-input-errors"
                        title={errors.departure_at.message}
                      >
                        {errors.departure_at.message}
                      </div>
                    )}
                  </div>
                  <div className="time-item uk-position-relative">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("timeOfReturn"),
                      }}
                    />
                    <Controller
                      control={control}
                      name="return_at"
                      rules={{
                        required: {
                          value: typeOfTrip === "round",
                          message: "Time of Return is required",
                        },
                      }}
                      render={({ field: { value, name } }) => (
                        <DateTimeComponent
                          name={name}
                          onChange={(val) => {
                            clearErrors(name);
                            updateRange("return_at", val);
                          }}
                          value={value}
                          disabled={typeOfTrip === "one"}
                          className="uk-input gh-time-input"
                          dateFormat={false}
                          timeFormat={config.timeFormat}
                          placeholder={staticService.findByAlias("time")}
                          showClearIcon={false}
                          attrProps={{ className: "enquire-time-icon" }}
                        />
                      )}
                    />
                    {errors.return_at && (
                      <div
                        className="validation-errors disabled-list gh-input-errors"
                        title={errors.return_at.message}
                      >
                        {errors.return_at.message}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  onClick={validateTripStep}
                  className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: staticService.findByAlias("continue") }}
                  />
                </button>
              </div>
              <div className={`gh-step-item ${currentStep === 1 ? "active" : ""}`}>
                <div className="input-group uk-margin-top">
                  <div className="input-wrapper uk-margin-small-right">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("firstName"),
                      }}
                    />
                    <Controller
                      control={control}
                      name="first_name"
                      rules={{ required: { value: true, message: "First Name is required" } }}
                      render={({ field }) => (
                        <input {...field} placeholder="John" className="uk-input" />
                      )}
                    />
                    {errors.first_name && (
                      <div
                        className="validation-errors disabled-list gh-input-errors"
                        title={errors.first_name.message}
                      >
                        {errors.first_name.message}
                      </div>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label
                      className="uk-form-label"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("lastName"),
                      }}
                    />
                    <Controller
                      control={control}
                      name="last_name"
                      rules={{ required: { value: true, message: "Last Name is required" } }}
                      render={({ field }) => (
                        <input {...field} placeholder="Smith" className="uk-input" />
                      )}
                    />
                    {errors.last_name && (
                      <div
                        className="validation-errors disabled-list gh-input-errors"
                        title={errors.last_name.message}
                      >
                        {errors.last_name.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-wrapper uk-margin-top">
                  <label
                    className="uk-form-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("yourEmail"),
                    }}
                  />
                  <Controller
                    control={control}
                    name="email"
                    rules={{ required: { value: true, message: "Email is required" } }}
                    render={({ field }) => (
                      <input {...field} placeholder="example@mail.com" className="uk-input" />
                    )}
                  />
                  {errors.email && (
                    <div
                      className="validation-errors disabled-list gh-input-errors"
                      title={errors.email.message}
                    >
                      {errors.email.message}
                    </div>
                  )}
                </div>
                <div className="input-wrapper uk-margin-top">
                  <label
                    className="uk-form-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("phoneNumber"),
                    }}
                  />
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: { value: true, message: "Phone is required" } }}
                    render={({ field }) => <PhoneInput {...field} />}
                  />
                  {errors.phone && (
                    <div
                      className="validation-errors disabled-list gh-input-errors"
                      title={errors.phone.message}
                    >
                      {errors.phone.message}
                    </div>
                  )}
                </div>
                <div className="input-wrapper uk-margin-top">
                  <label
                    className="uk-form-label"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("additionalInformation"),
                    }}
                  />
                  <Controller
                    control={control}
                    name="additional_information"
                    render={({ field }) => <textarea className="uk-textarea" {...field} />}
                  />
                </div>
                <div className="input-group uk-margin-top">
                  <button
                    type="button"
                    onClick={() => setCurrentStep(0)}
                    className="uk-button uk-button-default gh-margin-right-normal"
                  >
                    <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("back") }} />
                  </button>
                  <button type="submit" className="uk-button uk-button-primary uk-width-1-1">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("getEtimateLabel"),
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

ExternalEquiryForm.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default ExternalEquiryForm;
