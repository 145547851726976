import * as types from "../../actions/actionTypes";

function quoteUpdate(state = { isSelected: false, isRejected: false, requestQuote: {} }, action) {
  switch (action.type) {
    case types.REJECT_QUOTE:
    case types.QUOTE_UNSUCCESSFULL:
      return { isRejected: true, requestQuote: { ...state, ...action.response } };
    case types.SELECT_QUOTE:
      return { isSelected: true, requestQuote: action.response };
    case types.RESET_QUOTE_UPDATE_MODEL:
      return { isSelected: false, isRejected: false, requestQuote: {} };
    default:
      return state;
  }
}

function quotes(state = [], action) {
  switch (action.type) {
    case types.GET_QUOTE_LIST:
      return action.quotes;
    default:
      return state;
  }
}

function quoteDetail(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_CURRENT_QUOTE_DETAIL:
      let updatedQuote =
        state.id === action.seenQuote.quote_id
          ? {
              ...state,
              comments_count: action.seenQuote.comments_count,
              seen_by_broker: action.seenQuote.seen_by_broker,
              seen_by_operator: action.seenQuote.seen_by_operator,
              is_conversation_open: action.seenQuote.is_conversation_open,
            }
          : state;

      return { ...state, ...updatedQuote };
    case types.GET_QUOTE_ITEM:
      return action.quote;
    case types.UPDATE_QUOTE_ITEM:
      if (action.id === state.id) {
        return { ...state, update_on_expired_quote: true };
      } else {
        return state;
      }
    case types.SEEN_LOCAL_QUOTE:
      return { ...state, seen_by_broker: true, seen_by_operator: true, comments_count: 0 };
    case types.QUOTE_WITHDRAWN:
    case types.STATUS_QUOTE_UPDATED:
      return { ...state, ...action.response };
    case types.QUOTE_DETAIL_CLEAN:
      return action.data;
    case types.PAYMENT_DETAILS: {
      return {
        ...state,
        ...action.response,
      };
    }
    case types.SEEN_QUOTE:
      if (state.id === action.seenQuote.quote_id) {
        return {
          ...state,
          ...action.seenQuote,
        };
      }
      return {
        ...state,
      };

    case types.OPERATOR_QUOTE_INTERNAL_NOTES_ADDED_WITH_SUCCESS: {
      return {
        ...state,
        internalNotes: action.payload,
      };
    }
    case types.UPDATE_QUOTE_BOOKING:
      return {
        ...state,
        ...action.data,
      };
    case types.REMOVE_QUOTE_ITEM:
      return {};

    default:
      return state;
  }
}

function newQuote(state = {}, action) {
  switch (action.type) {
    case types.ADD_NEW_QUOTE:
      return action.quote;
    default:
      return state;
  }
}

function assignAircraft(state = false, action) {
  switch (action.type) {
    case types.OPEN_ASSIGN_FORM:
    case types.CLOSE_ASSIGN_FORM:
      return action.visible;
    default:
      return state;
  }
}

function quoteDiscussion(state = [], action) {
  switch (action.type) {
    case types.GET_DISCUSSION_MESSAGES:
      return action.messages;
    case types.ADD_DISCUSSION_MESSAGE:
      if (state.quote_id === action.messages.quote_id) return action.messages;
      return state;
    default:
      return state;
  }
}

function quoteToAmend(state = {}, action) {
  switch (action.type) {
    case types.QUOTE_TO_AMEND:
      return action.payload;
    case types.REMOVE_QUOTE_TO_AMEND:
      return {};
    default:
      return state;
  }
}

function seenQuote(state = {}, action) {
  switch (action.type) {
    case types.SEEN_QUOTE:
      return action.seenQuote;
    default:
      return state;
  }
}

export {
  quoteUpdate,
  quotes,
  quoteDetail,
  newQuote,
  assignAircraft,
  quoteDiscussion,
  quoteToAmend,
  seenQuote,
};
