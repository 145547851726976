import PropTypes from "prop-types";
import React from "react";
import { ASSIGN_REQUEST } from "../../../../../configs";
import staticService from "../../../../../services/static.service";
import condStrings from "../../../../../utils/condString";
import Tooltip from "../../../../common/Tooltip";
import Select from "../../../../Form/components/Select";

const layoutClassNames = {
  horizontal: "uk-flex uk-flex-middle uk-first-column",
  vertical: "",
};

export default function RequestEditRights(props) {
  const { brokersList, assignAction, request, layout } = props;
  const [loading, setLoading] = React.useState(false);

  const assignRequest = (values) => {
    setLoading(true);

    assignAction(ASSIGN_REQUEST, { type_of_request: 0, broker_id: values, id: request.id }, false, {
      successText: "Edit rights changed with success",
    })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectableBrokers = React.useMemo(() => {
    const validBrokers = brokersList.filter((broker) => !broker.owner_of_request);

    return {
      options: validBrokers.map((broker) => ({
        value: broker.id,
        label: `${broker.first_name} ${broker.last_name}`,
      })),
      value: validBrokers.filter((broker) => broker.selected).map((broker) => broker.id),
    };
  }, [brokersList]);

  return (
    <div className={condStrings("request-edit-rights", layoutClassNames[layout])}>
      <h4 className="gh-column-label" style={{ whiteSpace: "nowrap" }}>
        {staticService.findByAlias("editRights")}
      </h4>
      <Tooltip
        pos={`top-left`}
        refEl="self"
        show={!request.ability_to_edit_rights}
        styles={{
          flex: 1,
        }}
        trigger={
          <div className="form-control">
            <Select
              value={selectableBrokers.value}
              options={selectableBrokers.options}
              onChange={assignRequest}
              isDisabled={!request.ability_to_edit_rights || loading}
              isLoading={loading}
              multiple
            />
          </div>
        }
      >
        {staticService.findByAlias("restrictAccessForBroker")}
      </Tooltip>
    </div>
  );
}

RequestEditRights.propTypes = {
  brokersList: PropTypes.array,
  assignAction: PropTypes.func,
  request: PropTypes.object,
  layout: PropTypes.oneOf(["vertical", "horizontal"]),
};

RequestEditRights.defaultProps = {
  brokersList: [],
  layout: "horizontal",
};
