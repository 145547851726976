import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";
import getTextWidth from "../../../utils/getTextWidth";
import transformAdditionalInfo from "../../../utils/transformAdditionalInfo";
import staticService from "../../../services/static.service";
import { connect } from "react-redux";

import "./location-autocomplete.css";
import checkCoverageStatus from "../../../utils/checkCoverageStatus";
import { actions } from "react-redux-form";

const ariaProps = {
  role: "combobox",
  autoComplete: "off",
  "aria-autocomplete": "list",
  "aria-expanded": "false",
};

const calculateTextWidth = getTextWidth();

const LocationAutocompleteInput = (props) => {
  const {
    additionalInfo,
    value,
    locations,
    loader,
    min,
    placeholder,
    model,
    className,
    debounce,
    readOnly,
    onClick,
    disabled,
    onSelect,
    onBlur,
    visibleOverlay,
    onFocus,
    coverageWarning,
    is_distance_exceeds,
    coverageVerificationBody,
    checkCoverage,
    dispatch,
  } = props;
  const [triggerRerender, setTriggerRerender] = useState(0);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [inputMaxWidth, setInputMaxWidth] = useState(undefined);
  const [innerWidth, setInnerWidth] = useState();
  const [atLeastOnceRendered, setAtLeastOnceRendered] = useState(false);
  const [coverageVerifed, setCoverageVerified] = useState(false);
  const input = useRef();

  const updateResize = () => setInnerWidth(window.innerWidth);
  const coverageVerification = async () => {
    if (coverageVerificationBody) {
      setCoverageVerified(true);

      const status = await checkCoverageStatus(coverageVerificationBody);
      dispatch(actions.change(`${model}_coverage_status`, status));
    }
  };

  // Coverage verification
  useEffect(() => {
    if (!coverageVerifed && checkCoverage) {
      coverageVerification();
    }
  }, [coverageVerificationBody]);

  useEffect(() => {
    window.addEventListener("resize", updateResize);

    setTimeout(() => setAtLeastOnceRendered(true));

    return () => {
      return window.removeEventListener("resize", updateResize);
    };
  }, []);

  const generateAdditionalInfoWidth = () => {
    if (input.current) {
      const _input = input.current.querySelector("input");
      const getInputnputWidth = () => {
        const inputStyles = window.getComputedStyle(_input);
        const inputPadding =
          parseInt(inputStyles.getPropertyValue("padding-left")) +
          parseInt(inputStyles.getPropertyValue("padding-right"));

        return _input.offsetWidth - inputPadding;
      };
      const inputWidth = getInputnputWidth();
      const inputValueWidth = calculateTextWidth(value, _input);

      return inputWidth - inputValueWidth - (atLeastOnceRendered ? 10 : 30);
    }

    return 0;
  };

  useEffect(() => {
    if (value && value.length && additionalInfo && additionalInfo.length) {
      const maxWidth = generateAdditionalInfoWidth();
      // trigger additional rerender to update additional info width (uikit accordion bug)
      if (maxWidth < 0) {
        setTimeout(() => setTriggerRerender(triggerRerender + 1), 0);
        return;
      }

      setInputMaxWidth({ maxWidth });

      if (maxWidth > 16) setShowAdditionalInfo(true);
      else setShowAdditionalInfo(false);
    } else {
      setShowAdditionalInfo(false);
    }
  }, [value, additionalInfo, innerWidth, className, model]);

  const onChange = (event) => {
    const input = event.nativeEvent.target;
    if (input) input.setAttribute("aria-expanded", "true");
    onSelect(input.value, model);
  };

  const onBlurHandler = (event) => {
    const input = event.nativeEvent.target;
    if (input) input.setAttribute("aria-expanded", "false");
    onBlur(event, model);
  };

  const onFocusHandler = () => {
    if (onFocus) onFocus();
  };

  const checkTypeLengthLocation = () => {
    if (Array.isArray(locations)) {
      return visibleOverlay || (locations && locations.length > 0);
    } else {
      return locations && Object.values(locations)[0] && Object.values(locations)[0].length > 0;
    }
  };

  const getAdditionalInfo = useCallback(() => {
    if (showAdditionalInfo && additionalInfo) {
      return (
        <div className="gh-autocomplete-info" style={{ ...inputMaxWidth }}>
          <span className="info-item">{transformAdditionalInfo(additionalInfo)}</span>
        </div>
      );
    }

    return null;
  }, [additionalInfo, showAdditionalInfo, value, inputMaxWidth]);

  return (
    <div
      className={"gh-autocomplete-wrapper gh-location-input-wrapper " + loader}
      style={{
        zIndex: checkTypeLengthLocation() ? "1000" : "0",
      }}
      ref={input}
    >
      <DebounceInput
        {...ariaProps}
        minLength={min}
        placeholder={placeholder}
        name={model}
        className={className.join(" ")}
        value={value || ""}
        type={"search"}
        debounceTimeout={debounce}
        readOnly={readOnly}
        onBlur={onBlurHandler}
        onChange={onChange}
        onFocus={onFocusHandler}
        onClick={onClick}
        disabled={disabled}
      />
      {showAdditionalInfo && getAdditionalInfo()}
      {coverageWarning && !is_distance_exceeds && (
        <span
          className="coverage-warning"
          title={staticService.findByAlias("noHelicoptersInFleet")}
        >
          {staticService.findByAlias("noHelicoptersInFleet")}
        </span>
      )}
    </div>
  );
};

LocationAutocompleteInput.propTypes = {
  additionalInfo: PropTypes.string,
  value: PropTypes.string,
  locations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loader: PropTypes.string,
  min: PropTypes.number,
  placeholder: PropTypes.string,
  model: PropTypes.string,
  className: PropTypes.array,
  debounce: PropTypes.number,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  visibleOverlay: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  coverageWarning: PropTypes.bool,
  is_distance_exceeds: PropTypes.bool,
  coverageVerificationBody: PropTypes.object,
  checkCoverage: PropTypes.bool,
  dispatch: PropTypes.func,
};

LocationAutocompleteInput.defaultProps = {
  min: 1,
  debounce: 300,
  className: [],
  placeholder: null,
  onFocus: null,
  coverageWarning: false,
  is_distance_exceeds: false,
};

const mapStateToProps = (state, ownProps) => {
  let coverageVerificationBody = null;
  const model = ownProps.model.split(".");

  if (model[0] === "legModels") {
    if (model.includes("to") || model.includes("from")) {
      let legIndex = model.find((key) => key.match(/\[(.*?)\]/));

      if (legIndex) {
        legIndex = legIndex.replace(/[[\]]/g, "");

        const leg = state.legModels.legs[legIndex];
        const direction = model[model.length - 1];

        if (leg[direction + "_location"]) {
          const locationInfo = leg[direction + "_location"];

          coverageVerificationBody = {
            location: [locationInfo.latitude, locationInfo.longitude],
            place_id: locationInfo.place_id,
            source: locationInfo.source,
            secondary_text: locationInfo.secondary_text,
          };
        }
      }
    }
  }

  return {
    coverageVerificationBody,
  };
};

const COMPONENT = connect(mapStateToProps)(LocationAutocompleteInput);
export { COMPONENT as LocationAutocompleteInput };

// const [atLeastOnceRendered, setAtLeastOnceRendered] = useState(false);
// (atLeastOnceRendered ? 10 : 30);
