import React from "react";
import PropTypes from "prop-types";
import { spinnerInstance } from "./spinner.service";

class SpinnerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.subscriber = null;
    this.state = {
      visible: props.visible || false,
      type: props.type || "large",
      service: props.service || spinnerInstance,
    };
  }

  componentWillMount() {
    this.subscriber = () => this.forceUpdate();
    this.state.service.onChange = (state = false, type = "large") => {
      if (this.subscriber !== null) {
        this.setState({ visible: state, type: type });
      }
    };
  }

  render() {
    if (this.state.visible) {
      if (this.state.type === "large") {
        return (
          <div className={"loader-overlay"}>
            <div className={"loader " + (this.state.type || "default")}></div>
          </div>
        );
      }
      return <div className={"loader " + (this.state.type || "default")}></div>;
    }
    return null;
  }

  componentWillUnmount() {
    this.subscriber = null;
  }
}

SpinnerComponent.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.string,
  service: PropTypes.object,
};

export { SpinnerComponent };
