import React from "react";
import PropTypes from "prop-types";
import "./BackLinkStyled.css";
import { Link } from "react-router-dom";

const BackLinkStyled = ({ linkName = "Back", title, to }) => {
  if (!to) return null;
  return (
    <div className="gh-short-nav gh-back-link-nav" id="quoteVisualsNav">
      <div className="uk-container">
        <div className="uk-flex uk-flex-between uk-flex-column">
          <Link className="uk-link-text gh-back-link-nav-title" to={to} id="backToRequest">
            <span className="uk-margin-small-right" data-uk-icon="icon: arrow-left" />
            <span style={{ fontWeight: 500 }}>{linkName}</span>
          </Link>
          {title && <h6 className="gh-short-nav-title">{title}</h6>}
        </div>
      </div>
    </div>
  );
};
BackLinkStyled.propTypes = {
  linkName: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default BackLinkStyled;
