import React from "react";
import PropTypes from "prop-types";
import "./Button.css";
import { NavLink } from "react-router-dom";
import Icon from "./Icon";
import clsx from "clsx";
import withTooltip from "./hocButton";

const Button = (props) => {
  const {
    type,
    onClick,
    linkAdditionalOnClick,
    children,
    variant,
    disabled,
    color,
    className,
    style,
    target,
    loading,
    id,
  } = props;

  const linkOnClick = React.useCallback(
    (e) => (disabled || loading ? e.preventDefault() : linkAdditionalOnClick(e)),
    [disabled, loading, linkAdditionalOnClick],
  );

  const buttonClassName = clsx(
    className,
    { disabled: disabled || loading },
    `gh-main-button-component-${color}`,
    `gh-main-button-component-${variant}`,
    "gh-main-button-component",
  );

  if (type === "link")
    return (
      <NavLink
        className={buttonClassName}
        onClick={linkOnClick}
        to={onClick}
        style={style}
        target={target}
      >
        {children}
        {loading ? <div uk-spinner="ratio: 0.7" className="uk-margin-small-left" /> : ""}
      </NavLink>
    );
  return (
    <button
      disabled={disabled}
      onClick={!disabled && !loading ? onClick : null}
      className={buttonClassName}
      style={style}
      type={type}
      id={id}
    >
      {children}
      {loading ? <div uk-spinner="ratio: 0.7" className="uk-margin-small-left" /> : ""}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "link", "submit"]),
  variant: PropTypes.oneOf(["contained", "text", "outlined"]),
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "primaryBorder",
    "secondary",
    "success",
    "danger",
    "dangerBorder",
    "warning",
    "light",
    "dark",
    "gray",
    "transparentBlue",
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  target: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  linkAdditionalOnClick: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  tooltipProps: PropTypes.object,
  id: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  variant: "contained",
  disabled: false,
  color: "success",
  className: "",
  style: {},
  target: "",
  linkAdditionalOnClick: () => {},
  id: "",
  tooltipProps: {
    message: "",
    position: "",
  },
};

export const ButtonIcon = Icon;

export default withTooltip(Button);
