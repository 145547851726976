import React from "react";
import PropTypes from "prop-types";
import UIkit from "uikit";
import Icons from "../../assets/js/uk-icons-material";
import { GridComponent, IconComponent } from "../../shared";

// loads the Icon plugin
UIkit.use(Icons);

class FeatureComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: `icon-${props.icon}-${props.index}`,
      gridId: "grid-" + props.index,
    };
  }

  get desktopTempl() {
    return (
      <div className="uk-panel" style={{ height: "100%" }}>
        <GridComponent
          visible={true}
          styles={{ height: "100%" }}
          id={this.state.gridId}
          attrProps={{
            id: this.state.gridId,
            className: "uk-child-width-expand uk-text-left",
          }}
        >
          <div className="gh-fl-item">
            <div className={"uk-position-relative gh-services-icon " + this.props.icon} />
            <h4 className="gh-fl-title">{this.props.title}</h4>
            <div
              className="el-meta uk-margin gh-feature-list-item-description uk-text-meta"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          </div>
        </GridComponent>
      </div>
    );
  }

  get sliderTempl() {
    return (
      <div className="uk-text-left gh-fl-item">
        <div>
          <div
            className={"uk-position-relative uk-margin-bottom gh-services-icon " + this.props.icon}
          />
        </div>
        <div>
          <h4 className="gh-fl-title" dangerouslySetInnerHTML={{ __html: this.props.title }} />
          <p
            className="uk-margin gh-feature-list-item-description uk-text-meta "
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }
    return (
      <div className="gh-feature-list-item-container">
        {this.props.isSlider ? this.sliderTempl : this.desktopTempl}
      </div>
    );
  }
}

FeatureComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  parentProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isSlider: PropTypes.bool,
};

FeatureComponent.defaultProps = {
  isSlider: false,
};

export { FeatureComponent };
