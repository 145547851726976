import React from "react";
import { Control, Fieldset, Errors, actions } from "react-redux-form";
import { validationRules, validationMessages } from "../../../configs";
import { CustomValidateError, replaceAttributesInString, updateModel } from "../../../shared";
import staticService from "../../../services/static.service";
import showIcon from "../../../assets/img/show.png";
import hideIcon from "../../../assets/img/hide.png";

const { min, hasWhiteSpace, passwordValid, required, matchField } = validationRules;

export const ChangePasswordFieldset = ({ modelName, userModel }) => {
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const updateMatchModel = (matchModelName, model, value) => {
    return (dispatch) => {
      const modelName = model.split(".");
      dispatch(actions.resetValidity(model));
      dispatch(actions.change(model, value));
      if (userModel[matchModelName] !== "") {
        dispatch(
          actions.validate(modelName[0] + "." + matchModelName, { matchField: matchField(value) }),
        );
      }
    };
  };
  return (
    <Fieldset model={modelName}>
      <div className="uk-margin">
        <label
          className={"required uk-form-label"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("oldPassword") }}
        />
        <div className="uk-inline uk-width-1-1 gh-wrapper-input-password">
          <span className="uk-form-icon" data-uk-icon="icon: lock;" />
          <Control.text
            type={!showOldPassword ? "password" : ""}
            className={"uk-input"}
            model={".old_password"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ required, min: min(6) }}
          />
          <div
            className="gh-icon-show-password"
            onClick={() => {
              setShowOldPassword(!showOldPassword);
            }}
          >
            <img src={showOldPassword ? showIcon : hideIcon} alt="" />
          </div>
        </div>

        <Errors
          model=".old_password"
          show="touched"
          wrapper={CustomValidateError}
          messages={{
            required: validationMessages().requiredMessage.bind(
              null,
              staticService.findByAlias("oldPassword"),
            ),
            min: validationMessages().minMessage(6),
          }}
        />
      </div>
      <div className="uk-margin">
        <label
          className={"required uk-form-label"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("password") }}
        />
        <div className="uk-inline uk-width-1-1 gh-wrapper-input-password">
          <span className="uk-form-icon" data-uk-icon="icon: lock;" />
          <Control.text
            type={!showPassword ? "password" : ""}
            className={"uk-input"}
            model={".password"}
            changeAction={updateMatchModel.bind(null, "password_confirmation")}
            updateOn="change"
            validateOn="blur"
            validators={{
              hasWhiteSpace,
              passwordValid,
              required,
              min: min(8),
              matchField: matchField(userModel.password_confirmation),
            }}
          />
          <div
            className="gh-icon-show-password"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <img src={showPassword ? showIcon : hideIcon} alt="" />
          </div>
        </div>

        <Errors
          model=".password"
          show="touched"
          wrapper={CustomValidateError}
          messages={{
            hasWhiteSpace: replaceAttributesInString(
              { field: "Password" },
              staticService.findByAlias("spaceValidationMessage"),
            ),
            passwordValid: staticService.findByAlias("newPasswordValidation"),
            required: validationMessages().requiredMessage.bind(
              null,
              staticService.findByAlias("password"),
            ),

            min: validationMessages().minMessage(8),
            matchField: validationMessages().matchFieldMessage.bind(null, "Passwords"),
          }}
        />
      </div>

      <div className="uk-margin" style={{ marginBottom: 0 }}>
        <label
          className={"required uk-form-label"}
          dangerouslySetInnerHTML={{
            __html: `${staticService.findByAlias("confirm")} ${staticService.findByAlias(
              "password",
            )}`,
          }}
        />
        <div className="uk-inline uk-width-1-1 gh-wrapper-input-password">
          <span className="uk-form-icon" data-uk-icon="icon: lock;" />
          <Control.text
            type={!showConfirmPassword ? "password" : ""}
            className={"uk-input"}
            model={".password_confirmation"}
            changeAction={updateMatchModel.bind(null, "password")}
            updateOn="change"
            validateOn="blur"
            validators={{
              hasWhiteSpace,
              passwordValid,
              required,
              min: min(8),
              matchField: matchField(userModel.password),
            }}
          />
          <div
            className="gh-icon-show-password"
            onClick={() => {
              setConfirmShowPassword(!showConfirmPassword);
            }}
          >
            <img src={showConfirmPassword ? showIcon : hideIcon} alt="" />
          </div>
        </div>

        <Errors
          model=".password_confirmation"
          show="touched"
          wrapper={CustomValidateError}
          validateOn={"submit"}
          messages={{
            hasWhiteSpace: replaceAttributesInString(
              { field: "Password Confirmation" },
              staticService.findByAlias("spaceValidationMessage"),
            ),
            passwordValid: staticService.findByAlias("newPasswordValidation"),
            required: validationMessages().requiredMessage.bind(
              null,
              staticService.findByAlias("confirmPasswordLabel"),
            ),
            min: validationMessages().minMessage(8),
          }}
        />
      </div>
    </Fieldset>
  );
};
