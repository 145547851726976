/**
 * @param {number} minutes
 * @returns {object}
 * */
export const convertMinutesToTimeUnits = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const days = Math.floor(hours / 24);
  const hoursLeft = hours % 24;
  const weeks = Math.floor(days / 7);
  const daysLeft = days % 7;
  return { weeks, days: daysLeft, hours: hoursLeft, minutes: minutesLeft };
};

// time units: weeks, days, hours, minutes
export const timeUnitsSingularPluralShort = {
  weeks: { singular: "week", plural: "weeks", short: "w" },
  days: { singular: "day", plural: "days", short: "d" },
  hours: { singular: "hour", plural: "hours", short: "h" },
  minutes: { singular: "min", plural: "mins", short: "m" },
};

/**
 * String time units.
 * @typedef {'minutes'|'hours'|'days'|'weeks'} TimeUnits
 */

/**
 * @param {number|string} unit - number of time units
 * @param {TimeUnits} unitName - name of time unit
 * @param {boolean=false} short - return short version of time unit name
 * */
export const timeUnitsApplyPluralSingularShort = (unit, unitName, short) => {
  if (!unit) return "";
  if (short) return `${unit} ${timeUnitsSingularPluralShort[unitName].short}`;
  if (unit === 1) return `${unit} ${timeUnitsSingularPluralShort[unitName].singular}`;
  return `${unit} ${timeUnitsSingularPluralShort[unitName].plural}`;
};
