import React from "react";
import PropTypes from "prop-types";
import { BlockComponent } from "../../../common";
import {
  LegDetails,
  RequestBlockComponent,
  PadDetail,
  BrokerPriceBlock,
  EventDetailComponent,
} from "./";
import { AllowPermission } from "../../../permissions";
import { checkObjectProp, aircraftTitle } from "../../../../shared";
import staticService from "../../../../services/static.service";
import { BrokerPrivateNotesComponent } from "../broker/broker-private-notes.component";
import { addPrivateNoteToRequest } from "../../../../actions";

const RequestDetailComponent = ({
  request,
  showTitle = true,
  showHelicopImage = true,
  isResponsive = true,
  title = staticService.findByAlias("request-details"),
  topMargin = " uk-margin-medium-top",
  quote,
}) => {
  const legs = quote ? quote.pads : request.legs;
  const hasEvents = request.events && request.events.length > 0;
  const event = hasEvents ? request.events[0] : {};
  return (
    <BlockComponent
      visible={true}
      parentProps={{
        className: ["uk-padding-remove-top uk-margin-bottom", topMargin].join(" "),
      }}
    >
      <ul data-uk-accordion="collapsible: false" className="gh-accordion disabled-list">
        <li className="uk-open uk-primary gh-accordion-section">
          {showTitle ? <span className="uk-accordion-title gh-accordion-title">{title}</span> : ""}
          <div className="uk-accordion-content gh-accordion-content uk-margin-remove gh-req-quotes-details">
            {legs &&
              legs.map((leg, index) => (
                <RequestBlockComponent
                  key={index}
                  title={"Leg " + (index + 1)}
                  topPosition={index === 0 ? "uk-margin" : "uk-margin-medium-top"}
                >
                  {quote ? (
                    <PadDetail
                      requestInfo={request}
                      leg={leg}
                      index={index}
                      isResponsive={isResponsive}
                      showHelicopImage={showHelicopImage}
                    />
                  ) : (
                    <LegDetails
                      requestInfo={request}
                      leg={leg}
                      index={index}
                      isResponsive={isResponsive}
                      showHelicopImage={showHelicopImage}
                    />
                  )}
                </RequestBlockComponent>
              ))}

            <RequestBlockComponent title={staticService.findByAlias("criticalInfoLabel")}>
              <div className="uk-margin-top">
                {quote && quote.padsDiffer && (
                  <div>
                    <span
                      className="uk-text-danger gh-vertical-middle uk-margin-small-right"
                      data-uk-icon="icon: warning"
                    />
                    <span
                      className="font-weight-500 gh-vertical-middle"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("pads-differ"),
                      }}
                    />
                  </div>
                )}
                <div className="uk-margin-top uk-flex uk-flex-top">
                  <div className="gh-dark-blue uk-inline">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("comment"),
                      }}
                    />
                    :
                  </div>
                  <div className="uk-inline uk-margin-small-left">
                    {quote
                      ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: quote.description,
                            }}
                          />
                        ) || "None"
                      : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: request.other_info,
                            }}
                          />
                        ) || "None"}
                  </div>
                </div>
                <EventDetailComponent visible={hasEvents} event={event} />
              </div>

              {checkObjectProp(quote, "price", null) !== null ? (
                <AllowPermission role={"broker"}>
                  <BrokerPriceBlock quote={quote} />
                </AllowPermission>
              ) : null}
            </RequestBlockComponent>
            {quote && (
              <RequestBlockComponent
                title={staticService.findByAlias("aircraft")}
                topPosition={"uk-margin"}
              >
                <ul className={"uk-margin-medium-bottom disabled-list company-summary"}>
                  {quote.aircrafts &&
                    quote.aircrafts.map((aircraft, index) => (
                      <li key={index}>
                        <span
                          className={"uk-label gh-aircraft-label"}
                          dangerouslySetInnerHTML={{
                            __html: aircraftTitle(aircraft, false),
                          }}
                        />
                      </li>
                    ))}
                </ul>
              </RequestBlockComponent>
            )}

            <AllowPermission role="broker">
              <BrokerPrivateNotesComponent
                title={staticService.findByAlias("privateNotes")}
                className="gh-mob-clear-padding gh-margin-top-20"
                action={addPrivateNoteToRequest}
                note={request.private_notes === null ? "" : request.private_notes}
                id={request.id}
              />
            </AllowPermission>
          </div>
        </li>
      </ul>
    </BlockComponent>
  );
};

RequestDetailComponent.propTypes = {
  request: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  showHelicopImage: PropTypes.bool,
  isResponsive: PropTypes.bool,
  title: PropTypes.string,
  quote: PropTypes.object,
};

export { RequestDetailComponent };
