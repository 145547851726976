import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useLegContext } from "../LegContext";
import { Description } from "../../Description";
import { Control } from "../../Control";
import { defaultPilotValue, useCalculatorContext } from "../../../CalculatorContext";
import { Accordion } from "../../Accordion";
import { SectionArrow } from "../../SectionArrow";
import { Pilot } from "../../Pilot";
import uid from "../../../../../utils/uid";

export default function PilotsSection({ minPilotsLen }) {
  const { activeSections, toggleSectionStatus } = useCalculatorContext();
  const { getValues, watch } = useFormContext();
  const { prefix, isLast, onPilotChange } = useLegContext();

  const pilotsLen = watch(`${prefix}.pilots`);

  const PilotsGapes = React.useMemo(() => {
    if (minPilotsLen - pilotsLen.length > 0) {
      return Array.from(Array(minPilotsLen - pilotsLen.length)).map((_, index) => (
        <Pilot.Placeholder key={index} />
      ));
    }

    return null;
  }, [minPilotsLen, pilotsLen.length]);

  return (
    <Accordion
      isOpen={activeSections.includes("pilots")}
      onToggle={toggleSectionStatus("pilots", !activeSections.includes("pilots"))}
      className="uk-margin-small-top pilots-list"
      forcedMaxH={minPilotsLen * 356 + 52}
      header={
        <Description>
          <Description.Item as="custom" type="header">
            {isLast && <SectionArrow closed={!activeSections.includes("pilots")} />}
          </Description.Item>
        </Description>
      }
    >
      <Control.List name={`${prefix}.pilots`}>
        {({ fields, remove, add }) => {
          const count = fields.length;

          return (
            <React.Fragment>
              {fields.map((field, idx) => (
                <Pilot
                  key={field.id}
                  prefix={`${prefix}.pilots.${idx}`}
                  onRemove={() => remove(idx)}
                  count={count}
                  defaultData={defaultPilotValue()}
                />
              ))}
              {PilotsGapes}
              <Description>
                <Description.Item>
                  <button
                    type="button"
                    className="calculator-btn calculator-add-pilot-btn"
                    tabIndex="-1"
                    disabled={count === 2}
                    onClick={() => {
                      add({ id: uid().uid, ...defaultPilotValue() });

                      onPilotChange(`${prefix}.pilots.${pilotsLen.length}`)();
                    }}
                  >
                    + Additional Pilot
                  </button>
                </Description.Item>
              </Description>
            </React.Fragment>
          );
        }}
      </Control.List>
    </Accordion>
  );
}

PilotsSection.propTypes = {
  minPilotsLen: PropTypes.number,
};
