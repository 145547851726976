import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { actions } from "react-redux-form";
import { fireTracking, replaceAttributesInString } from "../../../../../shared";
import {
  getBrokerRequestDetail,
  loadHelicopterGallery,
  PREVIOUS_PATH,
  quoteWithdrawByBroker,
  rejectQuote,
  REQUEST_UPDATED,
  RESET_QUOTE_UPDATE_MODEL,
  updateIdentifier,
  updateQuoteStatus,
  updateRequest,
} from "../../../../../actions";
import {
  BlockComponent,
  ConfirmComponent,
  MiniTabComponent,
  QuoteComponent,
} from "../../../../common";
import {
  config,
  QUOTE_REJECTED,
  QUOTE_UNSUCCESSFULL,
  REQUEST_WITHDRAWN,
  WITHDRAWN,
} from "../../../../../configs";
import {
  GalleryModalComponent,
  RequestDetailComponent,
  RequestStatusComponent,
} from "../../common";
import { NormalPermission } from "../../../../permissions";
import staticService from "../../../../../services/static.service";
import { FeedbackFormComponent } from "../../../../forms";

const model = "feedbackModel";

class BrokerBookingRequestDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "request-details",
      action: null,
      quote: null,
      galleryState: false,
      aircraftModel: {},
      tabs: [
        {
          title: staticService.findByAlias("flightDetails"),
          view: "request-details",
        },
        { title: staticService.findByAlias("quotes"), view: "quotes" },
      ],
      closeRequestDialog: false,
      dialogProps: this.defaultDialogProps(),
    };
    this.loadData = this.loadData.bind(this);
    this.loadView = this.loadView.bind(this);
    this.selectedView = this.selectedView.bind(this);
    this.triggerAction = this.triggerAction.bind(this);
    this.quoteCancel = this.quoteCancel.bind(this);
    this.quoteRejected = this.quoteRejected.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
    this.openGallery = this.openGallery.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.defaultDialogProps = this.defaultDialogProps.bind(this);
    this.withdrawDialogProps = this.withdrawDialogProps.bind(this);
    this.onErrorCloseModal = this.onErrorCloseModal.bind(this);
    fireTracking(props.location.pathname);
  }

  componentDidMount() {
    if (!this.props.requestDetail.id || !this.props.requestDetail.activeQuotes) {
      this.loadData(this.props.match.params.id);
    }
    this.props.onEnter(false);
  }

  emptyModel(data) {
    return Object.assign(
      {
        message: "",
        status: "",
        id: "",
      },
      data,
    );
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.requestDetail.activeQuotes !== nextProps.requestDetail.activeQuotes) {
      this.setState({ closeRequestDialog: false });
    }

    if (this.props.requestDetail !== nextProps.requestDetail) {
      const request = {
        id: nextProps.requestDetail.id,
        status: nextProps.requestDetail.status,
        identifier:
          nextProps.requestDetail.identifier !== null ? nextProps.requestDetail.identifier : "",
      };
      this.context.store.dispatch(actions.change("identifierModel", request));
    }

    if (nextProps.quoteUpdate.isRejected) {
      this.props.resetModel(model);
      this.setState({
        quote: null,
        action: null,
        dialogProps: this.defaultDialogProps(),
      });
      this.context.store.dispatch({ type: RESET_QUOTE_UPDATE_MODEL });
      this.confirmComponent.closeModal();
    }

    if (
      !this.isAllowed ||
      (nextProps.requestDetail.status && nextProps.requestDetail.status === WITHDRAWN)
    ) {
      this.setState({ view: "request-details" });
    }

    if (nextProps.requestDetail.activeQuotes && nextProps.requestDetail.activeQuotes.length > 0) {
      this.setState({ view: "quotes" });
    }

    // if (nextProps.match.path === '/flights/requests/:id' && nextProps.requestDetail.status === closed)
  }

  loadData(id) {
    this.context.store.dispatch(getBrokerRequestDetail(id));
  }

  openGallery(aircraftModel) {
    this.setState({ galleryState: true, aircraftModel: aircraftModel });
    this.props.loadHelicopterGallery(aircraftModel.id, aircraftModel.manufacturer_id);
    this.gallery.modal.open();
  }

  closeGallery() {
    this.setState({ galleryState: false, aircraftModel: {} });
  }

  closeModal() {
    this.confirmComponent.closeModal();
  }

  onErrorCloseModal() {
    this.props.resetModel(model);
    this.confirmComponent.closeModal();
  }

  quoteCancel(model) {
    this.context.store.dispatch(
      quoteWithdrawByBroker(
        {
          status: QUOTE_UNSUCCESSFULL,
          message: model.message,
          id: model.id,
          request: this.props.requestDetail.id,
        },
        { action: "changed to unsuccessfull", entityName: "quote" },
        this.onErrorCloseModal,
      ),
    );
  }

  quoteRejected(model) {
    this.context.store.dispatch(
      updateQuoteStatus(
        {
          status: QUOTE_REJECTED,
          message: model.message,
          id: model.id,
        },
        { action: "rejected" },
      ),
    );
  }

  triggerAction(action, quote) {
    switch (action) {
      case QUOTE_REJECTED:
      case QUOTE_UNSUCCESSFULL:
        this.setState({
          quote: quote,
          action: action,
          dialogProps: this.defaultDialogProps(quote),
        });
        this.props.updateModel(model, this.emptyModel({ id: quote.id, status: action }));
        break;
      case REQUEST_WITHDRAWN:
        this.setState({
          quote: quote,
          action: action,
          dialogProps: this.withdrawDialogProps(),
        });
        break;
    }
    this.confirmComponent.openModal();
  }

  get quotes() {
    const { requestDetail, reject } = this.props;
    return (
      <div className={"uk-margin-bottom"}>
        {requestDetail && requestDetail.relevant_operators_message && (
          <div className="uk-width-1-1 uk-text-center uk-margin-small-top">
            {requestDetail.relevant_operators_message}
          </div>
        )}
        {(requestDetail.activeQuotes &&
          requestDetail.activeQuotes.map((quote, index) => (
            <div key={index}>
              <QuoteComponent
                key={index}
                visible={true}
                showTail={false}
                quote={quote}
                onSelectPath={"/flights/requests/" + requestDetail.id}
                action={this.triggerAction}
                gallery={this.openGallery}
                relevantMessage={requestDetail.relevant_operators_message}
                onReject={reject}
              />
              {quote.status === "booking_init" && requestDetail.activeQuotes.length > 1 ? (
                <div>
                  <hr className="uk-margin-small-bottom uk-margin-medium-top" />
                  <h4 className="uk-width-1-1@s uk-margin-remove uk-padding-remove gh-title">
                    Other Quotes Received
                  </h4>
                </div>
              ) : (
                ""
              )}
            </div>
          ))) ||
          ""}
        {this.noQuotes}
      </div>
    );
  }

  get noQuotes() {
    if (
      this.props.requestDetail.activeQuotes &&
      this.props.requestDetail.activeQuotes.length === 0
    ) {
      return (
        <div
          data-uk-alert
          className={"uk-alert-warning uk-margin-top uk-text-center gh-alert-without-bg"}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("noQuotesRequestMessage"),
            }}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }

  loadView(view) {
    switch (view) {
      case "quotes":
        return this.quotes;
      default:
        return (
          <RequestDetailComponent
            request={this.props.requestDetail}
            showHelicopImage={this.props.screenW > config.minDeviceWidth}
          />
        );
    }
  }

  selectedView(view, ev) {
    ev.preventDefault();
    this.setState({ view: view });
  }

  onDialogSelect() {
    this.props.updateRequest({
      status: WITHDRAWN,
      id: this.props.requestDetail.id,
    });
  }

  submitFeedback(model) {
    return this.state.quote.status === "open" ? this.quoteRejected(model) : this.quoteCancel(model);
  }

  get isAllowed() {
    return !!(
      this.props.requestDetail &&
      this.props.requestDetail.status !== "expired" &&
      this.props.requestDetail.status !== "closed" &&
      this.props.requestDetail.status !== "withdrawn"
    );
  }

  get feedbackForm() {
    return (
      <FeedbackFormComponent
        modelName={model}
        feedback={true}
        modelField={".message"}
        label={"Reason"}
        visible={true}
        action={this.submitFeedback.bind(this)}
      />
    );
  }

  defaultDialogProps(quote = null) {
    return {
      content: this.feedbackForm,
      className: "uk-alert uk-alert-default",
      buttonsProps: {},
      onSelect: () => {},
      onReject: () => {
        this.props.resetModel(model);
      },
      showCloseBtn: true,
      modalProps: {
        id: "withdraw-booking-modal",
        options: {
          clsPage: "",
          bgClose: false,
          escClose: false,
        },
        title:
          quote && quote.status === "open"
            ? staticService.findByAlias("rejectQuoteTitle")
            : staticService.findByAlias("CancelRequestBooking"),
        className: "gh-margin-bottom-0",
      },
    };
  }

  withdrawDialogProps() {
    return {
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("confirmRequestWithdraw"),
          }}
        />
      ),
      className: "uk-alert uk-alert-warning gh-alert-without-bg",
      buttonsProps: {
        cancel: { title: staticService.findByAlias("cancel") },
        continue: {
          title: staticService.findByAlias("withdrawButton"),
          css: "uk-button-danger",
        },
      },
      showCloseBtn: false,
      onSelect: this.onDialogSelect.bind(this),
      onReject: this.closeModal,
      modalProps: {
        id: "withdraw-request-modal",
        title: staticService.findByAlias("confirmRequestWithdrawModalTitle"),
        options: {
          clsPage: "",
          bgClose: false,
          escClose: false,
        },
      },
    };
  }

  render() {
    return (
      <BlockComponent visible={true} parentProps={{ className: "uk-margin" }}>
        <Link className="uk-link-text" to="/flights/requests/filter/all">
          <b className={"font-weight-500"}>
            <span className="uk-margin-small-right" data-uk-icon="icon: arrow-left" />
            {staticService.findByAlias("backToMyRequests")}
          </b>
        </Link>

        <div className="uk-padding">
          <div>
            <RequestStatusComponent
              request={this.props.requestDetail}
              requestStatus={this.isAllowed}
              action={this.triggerAction}
              editIdentifier={true}
              visible={this.state.closeRequestDialog}
            />
            <hr />
          </div>
          <NormalPermission condition={this.isAllowed}>
            <MiniTabComponent
              visible={true}
              tabs={this.state.tabs}
              selectedView={this.state.view}
              onClick={this.selectedView}
            />
          </NormalPermission>
          <ConfirmComponent
            ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
            info={this.state.dialogProps.content}
            rejectRequest
            alertType={this.state.dialogProps.className}
            buttonsProps={this.state.dialogProps.buttonsProps}
            modalProps={this.state.dialogProps.modalProps}
            onReject={this.state.dialogProps.onReject}
            onSelect={this.state.dialogProps.onSelect}
            showCloseBtn={this.state.dialogProps.showCloseBtn}
          />
          {this.loadView(this.state.view)}
          <GalleryModalComponent
            onClose={this.closeGallery}
            ref={(gallery) => (this.gallery = gallery)}
            items={this.props.heliImages}
            modalTitle={replaceAttributesInString(
              {
                manufacturer: this.state.aircraftModel.manufacturer,
                model: this.state.aircraftModel.aircraft_model,
              },
              staticService.findByAlias("premiumGalleryModalTitle"),
            )}
            modalContent={replaceAttributesInString(
              {
                manufacturer: this.state.aircraftModel.manufacturer,
                model: this.state.aircraftModel.aircraft_model,
              },
              staticService.findByAlias("premiumGalleryModalContent"),
            )}
          />
        </div>
      </BlockComponent>
    );
  }

  componentWillUnmount() {
    this.context.store.dispatch({ type: RESET_QUOTE_UPDATE_MODEL });
    this.context.store.dispatch({ type: REQUEST_UPDATED, request: {} });
    this.props.resetModel(model);
    this.props.onEnter(true);
    this.context.store.dispatch({
      type: PREVIOUS_PATH,
      path: this.props.match.url,
    });
  }
}

BrokerBookingRequestDetailComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerBookingRequestDetailComponent.propTypes = {
  match: PropTypes.object,
  requestDetail: PropTypes.object,
  onEnter: PropTypes.func,
  location: PropTypes.object,
  quoteUpdate: PropTypes.func,
  resetModel: PropTypes.func,
  reject: PropTypes.func,
  updateRequest: PropTypes.func,
  loadHelicopterGallery: PropTypes.func,
  updateModel: PropTypes.func,
  screenW: PropTypes.any,
  heliImages: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reject: rejectQuote,
      updateRequest: updateRequest,
      updateIdentifier: updateIdentifier,
      updateModel: (modelName, value) => dispatch(actions.change(modelName, value)),
      resetModel: (modelName) => dispatch(actions.reset(modelName)),
      loadHelicopterGallery,
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    requestDetail: state.requestDetail || {},
    quoteUpdate: state.quoteUpdate,
    heliImages: state.helicopterGallery,
    ...state.screenDimensions,
  };
}

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BrokerBookingRequestDetailComponent);
export { COMPONENT as BrokerBookingRequestDetailComponent };
