import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Auth from "../services/auth";
import { attachTooltipForbidden } from "../shared";

export class IsVerifiedAuthorization extends React.Component {
  constructor(props) {
    super(props);
  }

  get button() {
    return (
      <button
        type={"button"}
        onClick={this.props.action}
        className={this.props.classNames.join(" ")}
      >
        {this.props.actionTitle}
      </button>
    );
  }

  get disabledButton() {
    return (
      <button
        {...attachTooltipForbidden(this.props.tooltipMessage)}
        type={"button"}
        disabled={true}
        className={this.props.classNames.join(" ")}
      >
        {this.props.actionTitle}
      </button>
    );
  }

  get link() {
    return (
      <Link
        onClick={this.props.onClick}
        to={this.props.path || this.props.objPath}
        className={this.props.classNames.join(" ")}
      >
        {this.props.actionTitle}
      </Link>
    );
  }

  get disabledLink() {
    const klass = ["gh-disabled-link"].concat(this.props.classNames);
    return (
      <a {...attachTooltipForbidden(this.props.tooltipMessage)} className={klass.join(" ")}>
        {this.props.actionTitle}
      </a>
    );
  }

  render() {
    if (Auth.isAuthenticated() && this.props.verified) {
      return this.props.isButton ? this.button : this.link;
    }
    return this.props.isButton ? this.disabledButton : this.disabledLink;
  }
}

IsVerifiedAuthorization.propTypes = {
  tooltipMessage: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
  verified: PropTypes.bool,
  path: PropTypes.string,
  actionTitle: PropTypes.string.isRequired,
  action: PropTypes.func,
  classNames: PropTypes.array,
  objPath: PropTypes.object,
};
