import React from "react";
import moment from "moment";
import { Item } from "../../../../../shared/list";
import { formatID, convertToLocale } from "../../../../../shared/";
import { config } from "../../../../../configs/";
import staticService from "../../../../../services/static.service";

export const NotificationItem = ({ isRead = false, notification }) => {
  moment.updateLocale("en", {
    relativeTime: {
      s: "just now",
      ss: "%d seconds ago",
      m: "a minute ago",
      mm: "%d minutes ago",
      h: "an hour ago",
      hh: "%d hours ago",
      d: "one day ago",
      dd: "%d days ago",
      M: "a month ago",
      MM: "%d months ago",
      y: "a year ago",
      yy: "%d years ago",
    },
  });

  const departure = () => {
    return notification.departure ? notification.departure : "--";
  };

  const isNull = (value) => (value !== null ? value : "");
  const location = (direction = "from") => {
    if (notification.extra_details) {
      const _location = notification.extra_details[direction + "Location"];
      return isNull(_location.locality) + " " + isNull(_location.postalCode);
    }
    return notification[direction];
  };
  return (
    <Item>
      <div
        className={`uk-background-default uk-padding-small uk-border-rounded uk-margin-small-top gh-notification ${
          isRead ? "" : "gh-notification-unread"
        }`}
      >
        <div className="uk-grid uk-margin-remove-left" data-uk-grid>
          <div className="uk-width-1-4@m uk-width-1-1 subject-col gh-padding-remove-left">
            <span className={"font-weight-700 uk-hidden@m"}>{notification.subject}</span>
            <span className={"uk-visible@m"}>{notification.subject}</span>
          </div>

          <div className="uk-width-1-6@m uk-width-1-2@s uk-width-1-1 uk-margin-remove-left uk-margin-remove-right small-col gh-padding-remove-left uk-text-center">
            <label className={"font-weight-700 uk-hidden@m"}>
              {staticService.findByAlias("notificationReferenceLabel")}:
            </label>
            {formatID(
              notification[notification.custom_type],
              config.prefixes[notification.custom_type],
            )}
          </div>

          <div className="uk-width-1-6@m uk-width-1-2@s uk-width-1-1 uk-margin-remove-left uk-margin-remove-right small-col gh-padding-remove-left uk-text-center">
            <label className={"font-weight-700 uk-hidden@m"}>
              {staticService.findByAlias("notificationDepartureLabel")}:
            </label>{" "}
            {departure()}
          </div>

          <div className="uk-width-1-5@m uk-width-1-1 uk-margin-remove-left medium-col gh-padding-remove-left">
            <div className={"uk-flex gh-notification-location"}>
              <div
                className={
                  "uk-margin-remove-left uk-margin-remove-top gh-padding-remove-left location"
                }
              >
                <label className={"font-weight-700 uk-hidden@s"}>
                  {staticService.findByAlias("from")}:
                </label>{" "}
                {location("from")}
              </div>
              <div className={"uk-margin-remove-left gh-padding-remove-left r-arrow uk-visible@s"}>
                <span className="uk-margin-small-left uk-margin-small-right uk-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline fill="none" stroke="#000" points="10 5 15 9.5 10 14"></polyline>
                    <line fill="none" stroke="#000" x1="4" y1="9.5" x2="15" y2="9.5"></line>
                  </svg>
                </span>
              </div>
              <div className={"uk-margin-remove-left gh-padding-remove-left location"}>
                <label className={"font-weight-700 uk-hidden@s"}>
                  {staticService.findByAlias("to")}:
                </label>{" "}
                {location("to")}
              </div>
            </div>
          </div>
          <div className="uk-width-1-6@m uk-width-1-3@s uk-width-1-1 small-col created-col gh-padding-remove-left">
            <label className={"font-weight-700 uk-hidden@m"}>
              {staticService.findByAlias("notificationCreatedLabel")}:
            </label>{" "}
            {convertToLocale(notification.created)}
          </div>
        </div>
      </div>
    </Item>
  );
};
