import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, Form, Errors, actions } from "react-redux-form";
import { validationRules, config } from "../../configs";
import { CustomValidateError, updateModel, DateTimeComponent } from "../../shared";
import staticService from "../../services/static.service";

const { rangeValidation, rangeValidationEndDate } = validationRules;

class RangeFormComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  get formElements() {
    return (
      <div style={{ zIndex: 10 }} data-uk-grid>
        <div data-uk-grid className="uk-width-1-1@s uk-width-2-3@m uk-margin-medium-bottom">
          <div className="uk-width-1-1@s uk-width-1-2@m">
            <div className={"uk-inline gh-full-width gh-range-hypen gh-range-hypen-operator"}>
              <span className="uk-form-icon" data-uk-icon="icon: calendar" />
              <Control
                model={`.date_start`}
                className={"uk-input gh-calendar-input"}
                component={DateTimeComponent}
                changeAction={(model, value) => updateModel(model, value.utc().toISOString())}
                updateOn="change"
                controlProps={{
                  dateFormat: "DD MMMM, YYYY",
                  placeholder: "Start date",
                  onClear: () => this.props.clear(this.props.modelName + ".date_start"),
                  isValidDate: true,
                }}
              />
            </div>
          </div>
          <div className="uk-width-1-1@s uk-width-1-2@m gh-medium-padding-left">
            <div className={"uk-inline gh-full-width"}>
              <span className="uk-form-icon" data-uk-icon="icon: calendar" />
              <Control
                model={`.date_end`}
                className={"uk-input gh-calendar-input"}
                component={DateTimeComponent}
                changeAction={(model, value) => updateModel(model, value.utc().toISOString())}
                updateOn="change"
                controlProps={{
                  dateFormat: "DD MMMM, YYYY",
                  placeholder: "End date",
                  onClear: () => this.props.clear(this.props.modelName + ".date_end"),
                  isValidDate: true,
                }}
              />
            </div>
          </div>
          <div className={"uk-margin-remove"}>
            <Errors
              model={this.props.modelName}
              wrapper={CustomValidateError}
              messages={{
                endDateValidation: staticService.findByAlias("rangeValidationEndDate"),
                rangeRequired: staticService.findByAlias("rangeValidationRequired"),
              }}
            />
          </div>
        </div>
        <div
          data-uk-grid
          className={"uk-width-1-1@s uk-width-1-3@m uk-margin-remove-top uk-margin-medium-bottom"}
        >
          <div className={"uk-text-center gh-full-width"}>
            <Control.button
              model={this.props.modelName}
              disabled={(fieldValue) => !fieldValue.valid || !fieldValue.touched}
              className={"uk-button uk-button-primary"}
            >
              <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("submit") }} />
            </Control.button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.visible ? (
      <Form
        model={this.props.modelName}
        validators={{
          "": { rangeRequired: rangeValidation, endDateValidation: rangeValidationEndDate },
        }}
        onSubmit={this.props.action}
      >
        {this.formElements}
      </Form>
    ) : null;
  }
}

RangeFormComponent.propTypes = {
  modelName: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  format: PropTypes.string,
};

RangeFormComponent.defaultProps = {
  format: config.filterDateFormat,
};

const mapStateToProps = (state) => {
  return {
    date_start: state.forms.range.date_start.value,
    date_end: state.forms.range.date_end.value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clear: (model) => dispatch(actions.change(model, "")),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(RangeFormComponent);
export { COMPONENT as RangeFormComponent };
