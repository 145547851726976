import UIkit from "uikit";
import staticService from "../services/static.service";

export const showNotification = (
  message = "",
  status = "primary",
  pos = "top-right",
  timeout = 5000,
  closeAllPrevious = false,
) => {
  // status: "primary", "success", "warning", "danger"
  // pos: "top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"

  if (closeAllPrevious) UIkit.notification.closeAll();

  UIkit.notification({
    message: staticService.findByAlias(message) || message,
    status: status,
    pos: pos,
    timeout: timeout,
  });
};
