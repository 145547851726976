import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconComponent, spinnerInstance } from "../../shared";
import api from "../../services/api-handler";
import { CustomValidateError } from "../../shared/errors/custom-validate.component";

export class DocumentUploadOnSubmitComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startUpload: false,
      hasError: false,
      error: "",
      file: {},
      uploadFinish: false,
    };
    this.slice_size = 1000 * 1024;
    this.fileReader = null;

    this.onSuccess = this.onSuccess.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.upload = this.upload.bind(this);
    this.onError = this.onError.bind(this);
    api.setDispatchAndValidationModel(props.dispatch, null);
    api.showSpinner = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.file instanceof File && !this.state.startUpload && nextProps.submitUpload) {
      this.setState({ startUpload: true });
      this.startUpload();
    }
  }

  startUpload() {
    this.fileReader = new FileReader();
    this.upload(0);
    spinnerInstance.setProp("type", "large");
    spinnerInstance.show();
  }

  upload(start) {
    const next_slice = start + this.slice_size + 1;
    const blob = this.props.file.slice(start, next_slice);
    this.fileReader.onloadend = (event) => {
      if (event.target.readyState !== FileReader.DONE) {
        return;
      }
      this.props
        .updateAction({
          file_data: event.target.result,
          file: this.props.file.name,
          file_type: this.props.file.type,
          file_size: this.props.file.size,
        })
        .then((data) => this.onSuccess(data, next_slice))
        .catch(this.onError);
    };
    this.fileReader.readAsDataURL(blob);
  }

  onSuccess(response, next_slice) {
    if (next_slice < this.props.file.size) {
      // More to upload, call function recursively
      this.upload(next_slice);
    } else {
      // Update upload progress
      this.props.onUploadSuccess({
        ...response,
        upload: true,
        fileIndex: this.props.index,
      });
      this.setState({
        uploadFinish: true,
        file: response.file_name,
        startUpload: false,
      });
    }
  }

  onError(error) {
    let errorMess = null;
    if (error.status === 422) {
      const errorKeys = Object.keys(error.data);

      if (errorKeys.length > 0 && error.data[errorKeys[0]][0]) {
        errorMess = error.data[errorKeys[0]][0];
      }
    } else {
      errorMess = error.data.message ? error.data.message : "";
    }
    this.setState({
      hasError: true,
      error: errorMess,
      startUpload: false,
    });
  }

  removeFile() {
    if (this.state.uploadFinish) {
      this.props.removeAction({ file_name: this.props.file.name }).then(() => {
        this.props.onRemoveSuccess(this.props.index);
        api.setProperty("skipErrorAlert", false);
      });
    } else {
      this.props.onRemoveSuccess(this.props.index);
    }
  }

  get Error() {
    return <CustomValidateError>{[<span key={1}>{this.state.error}</span>]}</CustomValidateError>;
  }

  render() {
    const { file, index } = this.props;
    return (
      <div className={"uk-clearfix"}>
        {this.state.hasError ? this.Error : null}
        <div data-uk-grid>
          <div className="uk-width-2-3">{file.name}</div>
          <div className="uk-width-1-3 uk-text-right">
            <a className="uk-link-text" onClick={this.removeFile.bind(this)}>
              <IconComponent
                visible={true}
                className={"close"}
                icon={"close"}
                id={"close" + index}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

DocumentUploadOnSubmitComponent.propTypes = {
  index: PropTypes.number,
  updateAction: PropTypes.func,
  removeAction: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  submitUpload: PropTypes.bool,
  file: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};
