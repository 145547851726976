import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import UIkit from "uikit";
import staticService from "../../../services/static.service";
import condStrings from "../../../utils/condString";

function OperProgressLine(props) {
  const {
    step,
    history,
    location: { hash, pathname },
    leftUncompleted,
    withArrow,
    isTeamMember,
  } = props;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(pathname + hash === step.link);
  }, [pathname, hash]);

  const titleClickHandler = () => {
    if (step.link) {
      if (isTeamMember) {
        UIkit.notification({
          message: staticService.findByAlias("signupProgressTeamMemberWarning"),
          status: "teamMemberWarning",
          pos: "top-right",
          timeout: 5000,
        });
      } else {
        history.push(step.link);
      }
    }
  };

  return (
    <div
      className={condStrings(
        "signup-progress-step_line",
        step.status && "completed",
        leftUncompleted && "with-border",
        isActive && "is-active",
      )}
      role="link"
      onClick={titleClickHandler}
    >
      {withArrow && (
        <svg
          className={condStrings(
            "signup-progress-step_arrow",
            step.status && "completed",
            isActive && "is-active",
          )}
          width="4"
          height="9"
          viewBox="0 0 4 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L3.08297 3.89668C3.33244 4.2436 3.33391 4.71069 3.08665 5.05917L1 8"
            stroke="#F0F0F0"
            strokeLinecap="round"
          />
        </svg>
      )}
    </div>
  );
}

OperProgressLine.propTypes = {
  step: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.bool,
    link: PropTypes.string,
  }),
  location: PropTypes.object,
  history: PropTypes.object,
  leftUncompleted: PropTypes.bool,
  withArrow: PropTypes.bool,
  isTeamMember: PropTypes.bool,
};

export default withRouter(OperProgressLine);
