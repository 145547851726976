import React from "react";
import PropTypes from "prop-types";
import { Control, Fieldset, Errors, actions } from "react-redux-form";
import { validationRules, validationMessages } from "../../../configs";
import {
  CustomValidateError,
  updateModel,
  GridComponent,
  replaceAttributesInString,
} from "../../../shared";
import staticService from "../../../services/static.service";
import hideIcon from "../../../assets/img/hide.png";
import showIcon from "../../../assets/img/show.png";

const {
  required,
  validEmail,
  min,
  max,
  matchField,
  hasWhiteSpace,
  invalidEmailDomain,
  passwordValid,
  alphaNumValidation,
} = validationRules;

export const UserAccountFieldset = (props) => {
  const { showEmailsInputs, userModel } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const checkboxWrapperStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const updateMatchModel = (matchModelName, model, value) => {
    return (dispatch) => {
      const modelName = model.split(".");
      dispatch(actions.resetValidity(model));
      dispatch(actions.reset("validateFirstStepUser"));
      dispatch(actions.change(model, value));
      if (userModel[matchModelName] !== "") {
        dispatch(
          actions.validate(modelName[0] + "." + matchModelName, {
            matchField: matchField(value),
          }),
        );
      }
    };
  };

  const showReferralCode = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get("type");
    return referralCode === "broker";
  }, []);

  return (
    <Fieldset model="userRegisterModel">
      <GridComponent
        visible={true}
        attrProps={{
          id: "row-1",
          className: "uk-margin-small-bottom uk-child-width-expand@s",
        }}
      >
        <div className={"validation-wrapper"}>
          <label
            className={"required-contact uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("firstName"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".first_name"}
            changeAction={updateModel}
            updateOn="change"
            validators={{
              required,
              min: min(2),
              max: max(100),
            }}
          />
          <Errors
            model=".first_name"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("firstName"),
              ),
              min: validationMessages().minMessage(2),
              max: validationMessages().maxMessage(100),
            }}
          />
        </div>

        <div className={"validation-wrapper"}>
          <label
            className={"required-contact uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("lastName"),
            }}
          />
          <Control.text
            className={"uk-input"}
            model={".last_name"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ required, min: min(2), max: max(100) }}
          />
          <Errors
            model=".last_name"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("lastName"),
              ),
              min: validationMessages().minMessage(2),
              max: validationMessages().maxMessage(100),
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={showEmailsInputs}
        attrProps={{
          id: "row-2",
          className: "uk-margin-small-bottom uk-child-width-expand@s",
        }}
      >
        <div className={"validation-wrapper"}>
          <label
            className={"required-contact uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("emailAddress"),
            }}
          />
          <Control.text
            type={"email"}
            className={"uk-input"}
            model={".email"}
            changeAction={updateMatchModel.bind(null, "email_confirmation")}
            updateOn="change"
            validateOn={"change"}
            validators={{
              required,
              validEmail,
              invalidEmailDomain,
              matchField: matchField(userModel.email_confirmation),
            }}
          />
          <Errors
            model=".email"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("emailAddress"),
              ),
              validEmail: validationMessages().validEmailMessage,
              invalidEmailDomain: validationMessages().invalidEmailDomainMessage,
              matchField: validationMessages().matchFieldMessage.bind(null, "Emails"),
            }}
          />
        </div>

        <div className={"validation-wrapper"}>
          <label
            className={"required-contact uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: `${staticService.findByAlias("confirm")} ${staticService.findByAlias(
                "emailAddress",
              )}`,
            }}
          />
          <Control.text
            type={"email"}
            className={"uk-input"}
            model={".email_confirmation"}
            changeAction={updateMatchModel.bind(null, "email")}
            updateOn="change"
            validateOn={"change"}
            validators={{
              required,
              validEmail,
              invalidEmailDomain,
              matchField: matchField(userModel.email),
            }}
          />
          <Errors
            model=".email_confirmation"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("confirmEmailAddress"),
              ),
              validEmail: validationMessages().validEmailMessage,
              invalidEmailDomain: validationMessages().invalidEmailDomainMessage,
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={true}
        attrProps={{
          id: "row-4",
          className: "uk-margin-small-bottom uk-child-width-expand@s  uk-margin-medium-top",
        }}
      >
        <div className={"validation-wrapper"}>
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className={"uk-form-label required-contact uk-width-expand"}
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("password"),
              }}
            />
            <div className={"uk-width-auto"}>
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "passwordValidationTooltip",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <div className="gh-wrapper-input-password">
            <Control.text
              type={!showPassword ? "password" : ""}
              className={"uk-input"}
              model={".password"}
              changeAction={updateMatchModel.bind(null, "password_confirmation")}
              updateOn="change"
              validateOn="blur"
              validators={{
                hasWhiteSpace,
                passwordValid,
                required,
                min: min(8),
                matchField: matchField(userModel.password_confirmation),
              }}
            />
            <Errors
              model=".password"
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                hasWhiteSpace: replaceAttributesInString(
                  { field: "Password" },
                  staticService.findByAlias("spaceValidationMessage"),
                ),
                passwordValid: staticService.findByAlias("newPasswordValidation"),
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("password"),
                ),

                min: validationMessages().minMessage(8),
                matchField: validationMessages().matchFieldMessage.bind(null, "Passwords"),
              }}
            />

            <div
              className="gh-icon-show-password"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <img src={showPassword ? showIcon : hideIcon} alt="" />
            </div>
          </div>
        </div>

        <div className={"validation-wrapper"}>
          <label
            className={"required-contact uk-form-label"}
            dangerouslySetInnerHTML={{
              __html: `${staticService.findByAlias("confirm")} ${staticService.findByAlias(
                "password",
              )}`,
            }}
          />
          <div className="gh-wrapper-input-password">
            <Control.text
              type={!showConfirmPassword ? "password" : ""}
              className={"uk-input"}
              model={".password_confirmation"}
              changeAction={updateMatchModel.bind(null, "password")}
              updateOn="change"
              validateOn="blur"
              validators={{
                hasWhiteSpace,
                passwordValid,
                required,
                min: min(8),
                matchField: matchField(userModel.password),
              }}
            />
            <Errors
              model=".password_confirmation"
              show="touched"
              wrapper={CustomValidateError}
              validateOn={"submit"}
              messages={{
                hasWhiteSpace: replaceAttributesInString(
                  { field: "Password Confirmation" },
                  staticService.findByAlias("spaceValidationMessage"),
                ),
                passwordValid: staticService.findByAlias("newPasswordValidation"),
                required: validationMessages().requiredMessage.bind(
                  null,
                  staticService.findByAlias("confirmPasswordLabel"),
                ),
                min: validationMessages().minMessage(8),
              }}
            />

            <div
              className="gh-icon-show-password"
              onClick={() => {
                setConfirmShowPassword(!showConfirmPassword);
              }}
            >
              <img src={showConfirmPassword ? showIcon : hideIcon} alt="" />
            </div>
          </div>
        </div>
      </GridComponent>
      <GridComponent
        visible={showReferralCode}
        attrProps={{
          id: "row-6",
          className: "uk-margin-small-bottom uk-child-width-expand@s",
        }}
      >
        <div className="validation-wrapper uk-width-1-2@s">
          <div data-uk-grid className="uk-flex uk-flex-bottom">
            <label
              className="uk-form-label uk-width-expand"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("refferalCode"),
              }}
            />
            <div className={"uk-width-auto"}>
              <span
                className="gh-register-tooltip"
                data-uk-icon="icon: info"
                data-uk-tooltip={`title: ${staticService.findByAlias(
                  "refferalCodeTooltip",
                )}; pos: top-left`}
              />
            </div>
          </div>
          <Control.text
            className="uk-input"
            model={".company.refferal_code"}
            updateOn="change"
            validateOn={"change"}
            validators={{
              min: min(2),
              max: max(100),
              alphaNumValidation,
            }}
          />
          <Errors
            model=".company.refferal_code"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              min: validationMessages().minMessage(2),
              max: validationMessages().maxMessage(100),
              alpha: validationMessages().alphaMessage.bind(
                null,
                staticService.findByAlias("refferalCode"),
              ),
            }}
          />
        </div>
      </GridComponent>

      <GridComponent
        visible={showEmailsInputs}
        attrProps={{
          id: "row-5",
          className:
            "uk-flex uk-flex-left uk-width-1-1 uk-margin-small-bottom uk-margin-medium-top",
        }}
      >
        <label style={checkboxWrapperStyle}>
          <Control.checkbox
            className="uk-checkbox gh-checkbox"
            model={".hub_spot_privacy_policy"}
            changeAction={updateModel}
            updateOn="change"
          />
          <span style={{ marginLeft: 15 }}>
            {staticService.findByAlias("hubSpotPrivacyPolicy")}
          </span>
        </label>
      </GridComponent>
    </Fieldset>
  );
};

UserAccountFieldset.defaultProps = {
  showEmailsInputs: true,
};

UserAccountFieldset.propTypes = {
  showEmailsInputs: PropTypes.bool,
  userModel: PropTypes.object,
};
