import PropTypes from "prop-types";
import React from "react";
import condStrings from "../../../../utils/condString";

import "./index.css";

export function Modal(props) {
  const { id, className, visible, title, onVisibilityChange, children } = props;

  const visibilityTrigger = React.useCallback(
    (status) => () => {
      if (onVisibilityChange) onVisibilityChange(status);
    },
    [onVisibilityChange],
  );

  return (
    <div className={condStrings("calculator__modal", className, visible && "visible")} id={id}>
      <div
        className="calculator__modal-backdrop"
        aria-hidden="true"
        onClick={visibilityTrigger(false)}
      />
      <div className="calculator__modal-content">
        <div className="calculator__modal-title">
          <h3>{title}</h3>
          <button
            type="button"
            className="calculator-reset-btn close-btn"
            tabIndex="-1"
            onClick={visibilityTrigger(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  visible: PropTypes.bool,
  title: PropTypes.string,
  onVisibilityChange: PropTypes.func,
  children: PropTypes.any,
};

Modal.defaultProps = {};
