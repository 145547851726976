import React, { Component } from "react";
import PropTypes from "prop-types";
import { actions } from "react-redux-form";
import {
  MarkerComponent,
  PolylineComponent,
  parseCoordinates,
  percentageFormula,
  BasicMapComponent,
  InfoWindowComponent,
  shortAircraftTitle,
  redirectTo,
} from "../../../../../shared";
import { config } from "../../../../../configs";
import { loadActivityFlightsSuccess } from "../../../../../actions";
import { allModels } from "../../../../../reducers/models";
import GetHeliEvents from "../../../../../services/event.service";
import staticService from "../../../../../services/static.service";

class BrokerMapActivityComponent extends Component {
  constructor(props) {
    super(props);
    this.prepareMapMarkers = this.prepareMapMarkers.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.loadMarkerInfoWindow = this.loadMarkerInfoWindow.bind(this);
    this.isWindowActive = this.isWindowActive.bind(this);
    this.infoTemplate = this.infoTemplate.bind(this);
    this.clearOldLines = this.clearOldLines.bind(this);
    this.updateRangeModel = this.updateRangeModel.bind(this);
    this.state = {
      tabSelected: "bookings",
      flights: [],
      legs: [],
      markers: [],
      googleMarkers: [],
      totalMarkers: 0,
      zoom: config.mapZoom.min,
      labels: [
        staticService.findByAlias("date"),
        staticService.findByAlias("aircraft"),
        staticService.findByAlias("details"),
      ],
      showingInfoWindow: false,
      activeLine: {},
      activeMarker: {},
      selectedPlace: {},
      selectedMarkerPlace: {},
      emptyLegPath: null,
      redirect: false,
      markerInfoWindow: [],
      updateRange: false,
      initialRange: allModels.range,
    };

    this.arcs = [];
  }

  componentDidMount() {
    const items =
      this.props.tabSelected === "bookings" ? this.props.bookings : this.props.emptyLegs;
    if (items) this.prepareMapMarkers(items);

    GetHeliEvents.setEvent("filter-triggered", () => {
      this.context.store.dispatch(loadActivityFlightsSuccess({}));
      this.onMapClick();
    });

    GetHeliEvents.setEvent("reset-filter-triggered", () => {
      this.updateRangeModel(this.state.initialRange);
      this.context.store.dispatch(loadActivityFlightsSuccess({}));
    });
  }

  componentWillReceiveProps({ bookings = {}, emptyLegs = [], tabSelected, flights }) {
    const items = tabSelected === "bookings" ? bookings : emptyLegs;
    if (JSON.stringify(items) !== JSON.stringify(this.props[this.props.tabSelected])) {
      this.prepareMapMarkers(items);
    }

    if (this.state.updateRange && flights.range) {
      const rangeStates = this.updateRangeModel(flights.range);
      this.setState({ updateRange: false, initialRange: rangeStates });
    }
  }

  updateRangeModel(range) {
    const rangeStates = { ...allModels.range };
    this.props.rangeModelAttributes &&
      this.props.rangeModelAttributes.forEach((attribute) => {
        const model = this.props.rangeModel + "." + attribute;
        rangeStates[attribute] = range[attribute];
        this.context.store.dispatch(actions.change(model, range[attribute]));
      });
    return rangeStates;
  }

  findArcPositions(legs) {
    const leg = legs && legs.length > 0 ? legs[0] : {};
    const response = { leg: [] };
    if (leg.from_coords && leg.to_coords) {
      response.leg = leg;
    }
    return response;
  }

  prepareMapMarkers(items) {
    const markers = Object.keys(items).map((name) => {
      const item = items[name];
      const arc = this.findArcPositions(item.legs);
      return {
        ...item,
        markerPosition: parseCoordinates(item.geocode),
        leg: arc.leg,
        viewLabel: staticService.findByAlias("viewLabel"),
      };
    });
    this.setState({ markers: markers });
  }

  getInfoWindow(leg, marker) {
    return {
      arcId: leg.id,
      pins: marker.pins,
      date: leg.date,
      labels: this.state.labels,
      aircraft: shortAircraftTitle(marker.aircraft),
    };
  }

  onMarkerClick(props, marker, e) {
    this.setState({
      selectedMarkerPlace: { ...props, position: e.latLng },
      activeMarker: marker,
      activeLine: {},
      selectedPlace: {},
    });
  }

  onArcClick(props, marker, e) {
    this.setState({
      selectedPlace: { ...props, position: e.latLng },
      activeLine: marker,
      showingInfoWindow: true,
      activeMarker: {},
      selectedMarkerPlace: {},
    });
  }

  componentWillMount() {
    window.clientRender = (event) => {
      event.preventDefault();
      this.setState({
        emptyLegPath: config.redirectToEmptyMarketPlace + "/" + event.target.dataset.id,
        redirect: true,
      });
    };
  }

  onMarkerCreated(marker) {
    this.setState((prev) => {
      prev.googleMarkers.push(marker);
      return { googleMarkers: prev.googleMarkers };
    });
  }

  onMarkerInOut(props, marker, e, event) {
    let strokeOpacity = 1;
    if (event === "mouseover") strokeOpacity = 0.7;
    marker.setOptions({ strokeOpacity: strokeOpacity });
  }

  get mapHeight() {
    let height = null;

    if (this.props.screenDimensions.screenH > this.props.screenDimensions.screenW) {
      height =
        this.props.screenDimensions.screenH -
        percentageFormula(this.props.screenDimensions.screenH, 50);
    }
    height =
      this.props.screenDimensions.screenW -
      percentageFormula(this.props.screenDimensions.screenW, 50);
    if (config.maxAllowedMapeHeight > height) return height;
    return config.maxAllowedMapeHeight;
  }

  infoTemplate(info) {
    if (info.infoWindow) {
      return (
        '<ul class="uk-list uk-padding-small uk-margin-remove-bottom">' +
        '<li><label class="font-weight-700">' +
        this.state.labels[0] +
        "</label>: <span>" +
        info.infoWindow.date +
        "</span></li>" +
        '<li><label class="font-weight-700">' +
        this.state.labels[1] +
        "</label>: <span>" +
        info.infoWindow.aircraft +
        "</span></li>" +
        '<li class="uk-text-center"><a data-id="' +
        info.infoWindow.arcId +
        '" class="uk-button uk-button-primary uk-button-small" onclick="window.clientRender(event)">' +
        this.state.labels[2] +
        "</a></li>"
      );
      ("</ul>");
    }
    return null;
  }

  get brokerMap() {
    const mapHeight = this.mapHeight || 600;
    return (
      <div style={{ width: "100%", height: `${mapHeight}px` }} className={"google-map"}>
        <BasicMapComponent
          markers={this.state.googleMarkers}
          totalMarkers={this.state.markers.length}
          zoom={7}
          onLoad={() => this.setState({ updateRange: true })}
          onClick={this.onMapClick.bind(this)}
          centerAroundCurrentLocation={false}
          position={config.defaultMapPosition}
        >
          {this.loadMarkerInfoWindow()}
          {this.loadMarkers(this.state.markers)}
          {this.loadArcs(this.state.markers)}

          <InfoWindowComponent
            marker={this.state.activeLine}
            position={this.state.selectedPlace.position}
            name={"main"}
            onClose={this.onMapClick.bind(this)}
            visible={this.state.showingInfoWindow}
          >
            {this.infoTemplate(this.state.selectedPlace)}
          </InfoWindowComponent>
        </BasicMapComponent>
      </div>
    );
  }

  loadMarkers(markers = []) {
    if (markers.length > 0) {
      return markers.map((marker, i) => {
        return (
          <MarkerComponent
            key={i + "-marker"}
            onClick={this.onMarkerClick}
            infoWindow={this.getInfoWindow(marker.leg, marker)}
            onLoad={this.onMarkerCreated.bind(this)}
            position={marker.markerPosition}
          />
        );
      });
    }
  }

  isWindowActive(i) {
    let active = false;
    if (
      this.arcs.length > 0 &&
      this.state.selectedMarkerPlace.infoWindow &&
      !this.state.activeLine.map
    ) {
      const marker = this.arcs[i].props;
      this.state.selectedMarkerPlace.infoWindow.pins.forEach((pin) => {
        if (marker.infoWindow.arcId === pin) {
          active = true;
        }
      });
    }
    return active;
  }

  loadMarkerInfoWindow() {
    if (this.arcs.length > 0) {
      return this.arcs.map((arc, i) => {
        return (
          <InfoWindowComponent
            marker={arc.marker}
            position={arc.centerPosition.getCenter()}
            name={"info" + i}
            key={"info" + i}
            onClose={this.onMapClick.bind(this)}
            visible={this.isWindowActive(i)}
          >
            {this.infoTemplate(arc.props)}
          </InfoWindowComponent>
        );
      });
    }
    return [];
  }

  clearOldLines() {
    //clears old arcs
    if (this.arcs.length > 0) {
      this.arcs.forEach((arc) => {
        arc.marker.setMap(null);
      });
    }
    this.arcs.length = 0;
  }

  loadArcs(markers = []) {
    const alreadyCreatedArcs = [];
    const arcs = [];
    this.clearOldLines();
    if (markers.length > 0) {
      markers.forEach((marker) => {
        marker.legs.forEach((leg) => {
          if (alreadyCreatedArcs.indexOf(leg.id) === -1) {
            alreadyCreatedArcs.push(leg.id);
            arcs.push(
              <PolylineComponent
                key={leg.id + "-line"}
                ref={(ref) => {
                  if (ref !== null) this.arcs.push(ref);
                }}
                onClick={this.onArcClick.bind(this)}
                infoWindow={this.getInfoWindow(leg, marker)}
                onMouseover={this.onMarkerInOut.bind(this)}
                onMouseout={this.onMarkerInOut.bind(this)}
                position={[parseCoordinates(leg.from_coords), parseCoordinates(leg.to_coords)]}
              />,
            );
          }
        });
      });
    }

    return arcs;
  }

  onMapClick() {
    this.setState({
      showingInfoWindow: false,
      activeMarker: {},
      selectedMarkerPlace: {},
      selectedPlace: {},
      activeLine: {},
    });
  }

  render() {
    if (this.state.redirect) return redirectTo(this.state.emptyLegPath);
    return <div>{this.brokerMap}</div>;
  }

  componentWillUnmount() {
    GetHeliEvents.clearEvent("filter-triggered");
    GetHeliEvents.clearEvent("reset-filter-triggered");
    this.context.store.dispatch(loadActivityFlightsSuccess({}));
  }
}

BrokerMapActivityComponent.contextTypes = {
  store: PropTypes.object,
};

BrokerMapActivityComponent.propTypes = {
  flights: PropTypes.object,
  screenDimensions: PropTypes.object,
  parentHistory: PropTypes.object,
  onTabSelected: PropTypes.func,
  rangeModelAttributes: PropTypes.arrayOf(PropTypes.string),
  rangeModel: PropTypes.string,
};

export { BrokerMapActivityComponent };
