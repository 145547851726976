import React from "react";
import PropTypes from "prop-types";
import cs from "../../utils/condString";
import { actions, Control, Errors, Form } from "react-redux-form";
import { config, validationMessages, validationRules } from "../../configs";
import { CustomValidateError, formatBytes, updateModel } from "../../shared";
import staticService from "../../services/static.service";
import { connect } from "react-redux";
import attachIcon from "../../assets/img/svg/attach-icon.svg";
import pdfIcon from "../../assets/img/svg/pdf.svg";
import wordIcon from "../../assets/img/svg/word.svg";
import { bindActionCreators } from "redux";
import { CLEAR_ERRORS } from "../../actions";

const { max, min, required } = validationRules;

const filesIcon = {
  pdf: pdfIcon,
  docx: wordIcon,
};

class FeedbackFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      redirect: false,
      disabledAttachBtn: false,
      areaIndex: 0,
      prevKey: "",
    };

    this.checkTypeFile = this.checkTypeFile.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.pressSend = this.pressSend.bind(this);
    this.handleSendForm = this.handleSendForm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ disabledAttachBtn: !!nextProps.form.message });
  }

  checkTypeFile(type) {
    const typeToArr = type.split(".");
    return typeToArr[typeToArr.length - 1];
  }

  keyDownHandler(element) {
    element.target.addEventListener("input", (e) => {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    });

    if (this.props.fieldError) this.props.resetErrorObj();

    const { prevKey } = this.state;
    if (prevKey && prevKey.toLowerCase().includes("shift") && element.key === "Enter") {
      this.submitRef.click();
      setTimeout(() => this.setState({ prevKey: "" }));
    }
    this.setState({ prevKey: element.key });
  }

  pressSend() {
    setTimeout(() => {
      this.setState({ areaIndex: Date.now() });
      this.props.resetModel("feedbackModel");
    }, 200);
    const area = document.getElementById("textareaField");
    if (area) area.innerHTML = "";
  }

  handleSendForm(formValue) {
    this.props.action(formValue);
    this.pressSend();
  }

  formElements() {
    const {
      label,
      prevFile,
      removePrevFile,
      modelField,
      sendAttach,
      submitBut,
      modelName,
      chatIsOpen,
      feedback,
      fieldError,
      quoteSelectedStatus,
      chatModal,
    } = this.props;
    const area = document.getElementById(feedback ? "textareaFieldFeedback" : "textareaField");

    if (chatIsOpen && area) {
      setTimeout(() => area.focus(), 100);
    }

    const addAttachment = (e) => {
      sendAttach(e);
      setTimeout(() => this.hiddenInput.focus(), 100);
      this.hiddenInput.addEventListener("input", (element) => {
        const { prevKey } = this.state;
        if (prevKey && prevKey.toLowerCase().includes("shift") && element.key === "Enter") {
          this.submitRef.click();
          setTimeout(() => this.setState({ prevKey: "" }));
        }
        this.setState({ prevKey: element.key });
      });
    };

    return (
      <div className={cs("feedback-form", this.props.className)}>
        <div className="uk-margin-small" style={{marginBottom: 20}}>
          <label className="uk-form-label">{label}</label>
          {quoteSelectedStatus && quoteSelectedStatus === "booking_init" ? (
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("confirmBookingRequestWithdraw"),
              }}
            />
          ) : (
            ""
          )}
          <div className="uk-margin-small gh-feedback-form-textarea">
            <input
              type="text"
              style={{ height: 0, width: 0, border: 0 }}
              className="gh-hidden-input"
              autoFocus={chatIsOpen}
              ref={(hiddenInput) => (this.hiddenInput = hiddenInput)}
            />
            {prevFile && prevFile.prev_url ? (
              <div className="wrapper-prev-file">
                {this.checkTypeFile(prevFile.file.name) === "jpeg" ||
                this.checkTypeFile(prevFile.file.name) === "png" ||
                this.checkTypeFile(prevFile.file.name) === "gif" ||
                this.checkTypeFile(prevFile.file.name) === "jpg" ? (
                  <div>
                    <div
                      className="prev-file"
                      style={{ backgroundImage: `url(${prevFile.prev_url})` }}
                    >
                      <div className="prev-file-close" onClick={removePrevFile}>
                        <button type="button" data-uk-close />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="prev-file --doc">
                      {filesIcon[this.checkTypeFile(prevFile.file.name)] ? (
                        <span className="prev-file-icon">
                          <img src={filesIcon[this.checkTypeFile(prevFile.file.name)]} alt="DOC" />
                        </span>
                      ) : (
                        ""
                      )}
                      <div className="d-flex uk-flex-column">
                        <div>{prevFile.file.name}</div>
                        <div style={{ fontSize: 11 }}>{formatBytes(prevFile.file.size)}</div>
                      </div>
                      <div className="prev-file-close" onClick={removePrevFile}>
                        <button type="button" data-uk-close />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="wrapper-chat-input">
                {feedback || chatIsOpen ? (
                  <Control.textarea
                    className="uk-textarea"
                    key={this.state.areaIndex}
                    model={modelField}
                    id={feedback ? "textareaFieldFeedback" : "textareaField"}
                    style={{ minHeight: 60 }}
                    validators={
                      this.props.modelName === "declineModel"
                        ? {
                            required,
                            min: min(4),
                            max: max(config.quoteDescriptionMaxLength, false),
                          }
                        : { max: max(config.quoteDescriptionMaxLength, false) }
                    }
                    changeAction={updateModel}
                    onKeyDown={this.keyDownHandler}
                    placeholder={
                      this.props.chatModal ? staticService.findByAlias("liveChatPlaceholder") : ""
                    }
                  />
                ) : (
                  ""
                )}

                {chatModal ? (
                  <div>
                    <button
                      className="attach-btn"
                      disabled={this.state.disabledAttachBtn}
                      onClick={(e) => {
                        e.preventDefault();
                        this.fileRef.click();
                      }}
                    >
                      <img src={attachIcon} alt="Attach" className="attach-img" />
                    </button>
                    <input
                      type="file"
                      accept=".docx, .pdf, .jpeg, .png, .gif, .jpg"
                      ref={(fileRef) => (this.fileRef = fileRef)}
                      onChange={addAttachment}
                      style={{ display: "none" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {fieldError && (
              <ul className="ul validation-errors disabled-list gh-input-errors chat-errors">
                <li dangerouslySetInnerHTML={{ __html: fieldError }} />
              </ul>
            )}
            <Errors
              model={modelField}
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required:
                  modelName === "declineModel"
                    ? validationMessages().requiredMessage("Decline message")
                    : null,
                min: validationMessages().minMessage(4, "min"),
                max: validationMessages().maxMessage(config.quoteDescriptionMaxLength),
              }}
            />
          </div>
        </div>
        {!submitBut && (
          <div className="uk-margin-small-top">
            <button
              ref={(ref) => (this.submitRef = ref)}
              className={"uk-button uk-button-primary submit-msg"}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("submit"),
                }}
              />
            </button>
            <div className="gh-shortcut-box">
              <kbd>
                <span style={{ fontSize: 16 }}>⇧</span> Shift
              </kbd>{" "}
              +{" "}
              <kbd>
                <span style={{ fontSize: 16, marginTop: 1 }}>⏎</span> Enter
              </kbd>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    return this.props.visible ? (
      <Form model={this.props.modelName} onSubmit={this.handleSendForm}>
        {this.formElements.bind(this)}
      </Form>
    ) : null;
  }
}

FeedbackFormComponent.propTypes = {
  modelName: PropTypes.string.isRequired,
  modelField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  prevFile: PropTypes.object,
  removePrevFile: PropTypes.func,
  sendAttach: PropTypes.func,
  submitBut: PropTypes.func,
  quoteSelectedStatus: PropTypes.string,
  chatModal: PropTypes.bool,
  fieldError: PropTypes.string,
  className: PropTypes.string,
};

FeedbackFormComponent.defaultProps = {
  className: "",
};

function mapStateToProps(state) {
  return {
    quoteSelectedStatus: state.quoteSelectedStatus,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetModel: (modelName) => dispatch(actions.reset(modelName)),
      resetErrorObj: () => dispatch({ type: CLEAR_ERRORS }),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(FeedbackFormComponent);
export { COMPONENT as FeedbackFormComponent };
// export { FeedbackFormComponent };
