import React from "react";
import PropTypes from "prop-types";
import { Footer, Header } from "../common";
import { pages } from "../../configs/";
import { pageTitle } from "../../shared/";
import Auth from "../../services/auth";
import MainFooter from "../common/MainFooter";
import SecondFooter from "../common/SecondFooter";

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      isFollowUsTooltipOpen: false,
    };
  }

  componentWillMount() {
    document.title = pageTitle(this.props.pageTitle);
  }

  get header() {
    return <Header activeRoute={this.props.activeRoute} pages={this.props.pages} />;
  }

  initCookie() {
    const s = document.createElement("script"),
      tag = document.getElementsByTagName("script")[0];
    s.src = "https://cdn.iubenda.com/iubenda.js";
    tag.parentNode.insertBefore(s, tag);
  }

  componentDidMount() {
    this.initCookie();
  }

  get footer() {
    const isAuthenticated = Auth.isAuthenticated();

    if (!isAuthenticated) {
      return (
        <Footer>
          <SecondFooter />
          <MainFooter />
        </Footer>
      );
    }

    return (
      <Footer>
        <MainFooter />
      </Footer>
    );
  }

  get main() {
    return <main style={{ position: "relative", zIndex: 1 }}>{this.props.children}</main>;
  }

  render() {
    return (
      <div className={"page-wrapper"}>
        {this.header}
        {this.main}
        {this.footer}
      </div>
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
}

MainLayout.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pages: PropTypes.array,
};

MainLayout.defaultProps = {
  pages: pages,
  pageTitle: "Home",
};

export { MainLayout };
