import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { fireTracking } from "../../../../../shared";
import { agreementTabs } from "../../../../../configs";
import auth from "../../../../../services/auth";
import { MenuComponent } from "../menu.component";
import { AgreementViewComponent } from "./agreement-view.component";
import staticService from "../../../../../services/static.service";

class AgreementPageComponent extends Component {
  constructor(props) {
    super(props);
    const agreeTabs = agreementTabs.map((item) =>
      item.alias === "FlightTermsMenu" && !auth.getHeliServiceTerms()
        ? { ...item, alias: "flightTermsMenuItem" }
        : item,
    );
    const items = agreeTabs.filter((tab) => tab.roles.indexOf(auth.getRole()) !== -1);

    this.state = {
      selectedTab: items[0],
      tabs: items.map((menu) => {
        menu.title = staticService.findByAlias(menu.alias);
        return menu;
      }),
    };
    fireTracking(props.location.pathname);
  }

  onTabClick(tab) {
    this.setState({ selectedTab: tab });
  }

  render() {
    return (
      <section className={"agreement-section"}>
        <div data-uk-grid>
          <div className={"uk-width-1-4@m uk-width-1-1@s"}>
            <MenuComponent items={this.state.tabs} />
          </div>
          <div className={"uk-width-3-4@m uk-width-1-1@s"}>
            <Switch>
              <Route path="/" component={AgreementViewComponent} />
            </Switch>
          </div>
        </div>
      </section>
    );
  }
}

AgreementPageComponent.contextTypes = {
  store: PropTypes.object,
};

AgreementPageComponent.propTypes = {
  location: PropTypes.object,
};

export { AgreementPageComponent };
