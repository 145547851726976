export const _dashboard = {
  bookings: { current_week: 0, confirmed: 0, canceled: 0 },
  last: { total_quotes: null, bookings: 0 },
  current_week: { quotes: 0, booking: 0 },
  first: { pending: 0, cancelled: 0, requests_pending: 0 },
  total: 0,
};

const userLoginModel = {
  password: "",
  email: "",
  invalid_credentials: "",
  keep_logged: 0,
};

const userEmailModel = {
  email: "",
};

const enquireForm = {
  number_adults: 0,
  number_children: 0,
  number_luggage: 0,
  departure_time: "",
  return_time: "",
  date: "",
  return_date: "",
  typeOfTrip: "one",
  phone: "",
  email: "",
};

const commentModel = { body: "" };
const userPassword = {
  password: "",
  url: "account/register",
};

const validateReferrer = {
  referrer: "",
};

const subscribeModel = {
  ...userEmailModel,
  type: "broker",
  first_name: "",
  last_name: "",
};

const withdrawRequestReason = "";

const feedbackModel = {
  message: "",
  status: "",
  id: "",
  date: "",
};

const declineModel = {
  message: "",
  status: "",
  id: "",
};

const contactFormModel = {
  message: "",
  name: "",
  email: "",
  phone: "",
  subject: "flight",
};

const editProfileModel = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
};

const editChangePasswordModel = {
  old_password: "",
  password: "",
  password_confirmation: "",
};

const cardUpdateModel = {
  card_id: "",
  address_line1: "",
  address_city: "",
  address_state: "",
  address_country: "UK",
  address_zip: "UK",
};

const bankAccountModel = {
  bank_account: {
    country: "GB",
    currency: "gbp",
    routing_number: "",
    account_number: "",
    account_holder_name: "",
    account_holder_type: "company", //individual or company
  },
  agreement: false,
};

const dashboardModel = {
  bookings: {
    all: "",
    canceled: "",
    confirmed: "",
    current_week: "",
  },
  empty_legs: {
    all: "",
    pending: "",
  },
  requests: {
    all: "",
    urgent: "",
    pending: "",
  },
  total_quotes: "",
  total: null,
};

const address = {
  city: "",
  country: "GB",
  line1: "",
  line2: "",
  /*state: '',*/
  postal_code: "",
};

const accountDetail = {
  address: { ...address },
  dob: {
    day: "",
    month: "",
    year: "",
  },
  birthday: "",
  first_name: "",
  last_name: "",
  same_address: true,
  companyAddress: { ...address },
};

const userModalRegisterModel = {
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  phone: "",
  booking_demo: false,
  optional: "",
  type: "broker",
  size: "",
  flights: "",
};

const userRegisterModel = {
  company: {
    name: "",
    number: "",
    aoc_number: "",
    aoc_company_name: "",
    phone: "",
    website: "",
    email: "",
    refferal_code: "",
    sole_trader: false,
  },
  first_name: "",
  last_name: "",
  email: "",
  email_confirmation: "",
  phone: "",
  bank_account: "",
  password: "",
  password_confirmation: "",
  tac_accepted: 0,
  type: "broker",
  invitations: [],
  files: [],
  helicopters: [],
  hub_spot_privacy_policy: false,
};

const declineRequestModel = {
  reason: "",
  other_info: "",
};

const passwordConfirmModel = {
  password: "",
  password_confirmation: "",
};

const fleetModel = {
  aircraft_vat: null,
  manufacturer: 0,
  type_id: 0,
  home_base: "",
  home_info: "",
  id: "",
  year_manufacture: 0,
  date_last_refurbished: null,
  registration_number: "",
  max_passengers: 0,
  max_crew: 0,
  expiration_date: "",
  files: [],
  to_delete: [],
};

export const passengerModel = {
  number_adults: 0,
  number_children: 0,
};

export const legModel = {
  from: "",
  to: "",
  departure_time: null,
  to_coords: "",
  date: null,
  date_type: "1",
  passengers: { passengers_amount_type: "1" },
  other_info: "",
  from_coords: "",
  from_info: "",
  to_info: "",
  identifier: "",
  to_place_id: "",
  from_place_id: "",
  ...passengerModel,
};

const notificationFilters = {
  by: "",
  isAttentionRequired: "",
};

const quoteModel = {
  booking_request_id: "",
  final_price: "",
  view_price: "",
  view_quote_vat: "",
  price: "",
  description: "",
  event_day: 0,
  legs: [],
  helicopters: [],
  quote_vat: "",
  is_information_confirmed: false,
};

const paymentModel = {
  display_number: "",
  card: {
    number: "",
    cvc: "",
    exp_month: "",
    exp_year: "",
  },
};

const quoteLegModel = {
  from: "",
  to: "",
};

const aocModel = {};
const operatorBookingConfirmed = false;
const operatorBookingAgreement = false;
const invitationTempModal = "";
const aocDocumentsModel = [];
const invitationsModel = [];
const companyFilesModel = [];
const certificateFilesModel = [];
const aocFilesModel = [];
const operatorInvoiceModel = [];
const evidenceFilesModel = [];
const heliInsuranceFilesModel = [];
const heliPhotosFilesModel = [];
const heliEvidenceAOCModel = [];
const provisionalPaymentInformationModel = [];
const fleetModels = [];
const filesExistState = false;
const helicopterServicesTermsFiles = [];
const legModels = {
  flight_type: "",
  legs: [],
  other_info: "",
  preferences: "",
  catering_need: "0",
  crew_size: 0,
  identifier: "",
  flight_purpose_event_id: "",
  flight_purpose_event: "",
  flight_purpose: "",
  broker_intention: "",
};

const identifierModel = {
  identifier: "",
  status: "",
  id: "",
};

const range = {
  date_end: "",
  date_start: "",
};

const emptyLegFromBookingModel = {
  booking_id: "",
  aircraft_id: "",
  from_name: "",
  from_coords: "",
  from_info: "",
  from_place_id: "",
  to_info: "",
  to_name: "",
  to_coords: "",
  to_place_id: "",
  date: "",
  time_from: "",
  time_to: "",
};

const requestFromEmptyLegModel = {
  flight_type: "",
  legs: [],
  other_info: "",
  preferences: "",
  catering_need: "0",
  crew_size: 0,
  identifier: "",
  flight_purpose_event_id: "",
  flight_purpose_event: "",
  flight_purpose_event_info: "",
  flight_purpose: "",
};

const companyModel = {
  company_logo: [],
};

const quoteFormModel = {
  helicopterServicesTerms: [],
};

const externalAppSettings = {
  background_image: [],
};

const inviteMembersModel = {
  emails: [],
};

const operatorBookingQuotesFiltersModel = {
  status: "all",
  departure_time: null,
  active: false,
  from: "",
  from_info: "",
  to: "",
  to_info: "",
  aircraft: "",
  disableBtn: false,
  refreshCollection: false,
  aircrafts: [],
  selectedAircrafts: [],
  isToggled: false,
  activity: "all",
  ...range,
};

const bookingsConfirmedFiltersModel = {
  from: "",
  from_info: "",
  to: "",
  to_info: "",
  departure_time: null,
  disableBtn: false,
  refreshCollection: false,
  activity: "all",
  forceUpdate: false,
  sortedBy: "asc",
  orderBy: "departure",
  ...range,
};

const bookingsHistoryFiltersModel = {
  from: "",
  to: "",
  date: null,
  disableBtn: false,
  refreshCollection: false,
  activity: "all",
};

const emptyLegsFiltersModel = {
  from: "",
  from_info: "",
  to: "",
  to_info: "",
  count: 0,
  departure_time: null,
  disableBtn: false,
  refreshCollection: false,
  activity: "all",
};

const emptyLegsBrokerFiltersModel = {
  from_base: "",
  from_lat: "",
  from_lng: "",
  from_source: "",
  to_base: "",
  to_lat: "",
  to_lng: "",
  to_source: "",
  disableBtn: false,
  refreshCollection: false,
  activity: "all",
  ...range,
};

const geocode = {
  lat: null,
  lng: null,
};

const premium = {
  id: null,
  message: "",
};

const operatorMarketplaceRequestsFiltersComponent = {
  near_by: "",
  home_info: "",
  radius: "",
  fleetCheck: true,
  notQuotedOnly: false,
  declined: 0,
  disableBtn: false,
  refreshCollection: false,
  orderBy: "departure",
  sortedBy: "asc",
  ...geocode,
  ...range,
};

const isConcat = true;

const quoteSelectedStatus = "";

const eventsFiltersModel = {
  type: "",
  name: "",
  location: "",
  ...range,
};

const brokerBookingRequestsFiltersModel = {
  disableBtn: false,
  refreshCollection: false,
  only_my: false,
  active: false,
  radius: "",
  activity: "all",
  near_by: "",
  home_info: "",
  ...range,
};

const brokerRequestFiltersModel = {
  disableBtn: false,
  refreshCollection: false,
  only_my: false,
  active: false,
  radius: "",
  activity: "all",
  near_by: "",
  home_info: "",
  orderBy: "id",
  sortedBy: "desc",
  client_id: "",
};

const favourite = {
  name: "",
  id: null,
};

const flightItemNoteModel = { body: "" };

const brokerPrivateNotesModel = { private_notes: "" };

export const allModels = {
  userLogin: userLoginModel,
  userEmailModel: userEmailModel,
  userRegisterModel: userRegisterModel,
  declineRequestModel: declineRequestModel,
  companyFilesModel: companyFilesModel,
  certificateFilesModel: certificateFilesModel,
  aocDocumentsModel: aocDocumentsModel,
  aocFilesModel: aocFilesModel,
  operatorInvoiceModel: operatorInvoiceModel,
  evidenceFilesModel: evidenceFilesModel,
  heliInsuranceFilesModel: heliInsuranceFilesModel,
  heliPhotosFilesModel: heliPhotosFilesModel,
  heliEvidenceAOCModel: heliEvidenceAOCModel,
  provisionalPaymentInformationModel: provisionalPaymentInformationModel,
  invitationsModel: invitationsModel,
  invitationTempModal: invitationTempModal,
  operatorBookingConfirmed,
  operatorBookingAgreement,
  fleetModel: fleetModel,
  fleetModels: fleetModels,
  filesExistState: filesExistState,
  passwordConfirmModel: passwordConfirmModel,
  editProfileModel: editProfileModel,
  editChangePasswordModel: editChangePasswordModel,
  legModels: legModels,
  legModel: legModel,
  quoteModel,
  aocModel,
  quoteLegModel,
  feedbackModel,
  declineModel,
  withdrawRequestReason,
  paymentModel,
  bankAccountModel,
  emptyLegFromBookingModel,
  requestFromEmptyLegModel,
  accountDetail,
  notificationFilters,
  dashboardModel,
  inviteMembersModel,
  operatorBookingQuotesFiltersModel,
  brokerBookingRequestsFiltersModel,
  bookingsConfirmedFiltersModel,
  bookingsHistoryFiltersModel,
  operatorMarketplaceRequestsFiltersComponent,
  isConcat,
  quoteSelectedStatus,
  emptyLegsFiltersModel,
  cardUpdateModel,
  contactFormModel,
  eventsFiltersModel,
  companyModel,
  externalAppSettings,
  subscribeModel,
  identifierModel,
  userModalRegisterModel,
  userPassword,
  favourite,
  premium,
  enquireForm,
  commentModel,
  validateReferrer,
  range,
  emptyLegsBrokerFiltersModel,
  brokerRequestFiltersModel,
  flightItemNoteModel,
  brokerPrivateNotesModel,
  quoteFormModel,
  helicopterServicesTermsFiles,
};
