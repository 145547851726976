import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { operatorBookingTabs } from "../../../../../configs";
import { BookingLayout } from "../../../../layouts";
import { returnRouteUrl, RouteWithSubRoutes } from "../../../../../shared/";
import { OperatorBookingQuoteListComponent } from "./";
import staticService from "../../../../../services/static.service";

export class OperatorBookingQuotesComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersShown: false,
      showFilterIcon: true,
      filterFailed: "",
    };
  }

  get layoutParentCssClass() {
    return ["gh-normal"];
  }

  toggleFiltersBar() {
    this.setState((prevState) => ({
      isFiltersShown: !prevState.isFiltersShown,
    }));
  }

  onEnterPage(state) {
    this.setState({ showFilterIcon: state });
  }

  render() {
    const { history } = this.props;
    const showFilter = history.location.pathname !== "/flights/quotes/filter/pre_quotes";

    return (
      <BookingLayout
        pageTitle={staticService.findByAlias("quotes")}
        isFiltersShown={this.state.isFiltersShown}
        onFiltersClick={this.toggleFiltersBar.bind(this)}
        navigationTabs={operatorBookingTabs}
        parentClassname={this.layoutParentCssClass}
        activeRoute={this.props.match}
        isFilterVisible={showFilter && this.state.showFilterIcon}
        filterCss={this.state.filterFailed}
        history={this.props.history}
      >
        <Switch>
          {this.props.routes &&
            this.props.routes.map((route, i) => {
              return (
                <RouteWithSubRoutes
                  key={i}
                  {...returnRouteUrl(route, this.props.match.url)}
                  onEnter={this.onEnterPage.bind(this)}
                  parentMatch={this.props.match}
                  title={route.title}
                />
              );
            })}
          <Route
            path="/"
            render={(props) => (
              <OperatorBookingQuoteListComponent
                {...props}
                match={this.props.match}
                isFiltersShown={this.state.isFiltersShown}
              />
            )}
          />
        </Switch>
      </BookingLayout>
    );
  }
}
OperatorBookingQuotesComponent.propTypes = {
  history: PropTypes.object.isRequired,
};
