import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import staticService from "../../../services/static.service";
import { distanceToTime, formattedTime } from "../../../shared";
import RadioButtonComponent from "../RadioButtonComponent";

import closeIcon from "../../../assets/img/svg/closeIcon.svg";
import checkedIcon from "../../../assets/img/svg/checkSimple.svg";

import "./quoteEstimate.css";

const QuoteEstimate = (props) => {
  const { leg, updateLeg, readOnly, control } = props;

  return (
    <div className="gh-estimate-row">
      <div className="gh-estimate-row-item">
        <div className="gh-estimate-row-item-key">
          {staticService.findByAlias("estimatedDistance2")}
        </div>
        <div className="gh-estimate-row-item-value">
          <span style={{ marginRight: 3 }}>{leg.estimated_distance}</span>
          <span>{staticService.findByAlias("estimatedDistance")}</span>
        </div>
      </div>
      <div className="gh-estimate-row-item">
        <div className="gh-estimate-row-item-key">
          {staticService.findByAlias("estimatedDuration")}
        </div>
        <div className="gh-estimate-row-item-value">
          <span style={{ marginRight: 3 }}>
            {leg.estimated_duration
              ? formattedTime(leg.estimated_duration)
              : distanceToTime(leg.estimated_distance)}
          </span>
        </div>
      </div>
      <div className="gh-estimate-row-item">
        {readOnly ? (
          <div className={`gh-estimate-check-item ${leg.is_refueling ? "checked" : ""}`}>
            {leg.is_refueling ? (
              <img src={checkedIcon} alt="check" />
            ) : (
              <img src={closeIcon} alt="x" />
            )}
            <span className="gh-estimate-check-item-text">
              {staticService.findByAlias(
                leg.is_refueling
                  ? "refuellingStopEnRouteRequired"
                  : "noRefuellingStopEnRouteRequired",
              )}
            </span>
          </div>
        ) : (
          <React.Fragment>
            <div className="gh-estimate-row-item-key" style={{ whiteSpace: "nowrap" }}>
              {staticService.findByAlias("refuellingStopEnRouteRequired")}
            </div>

            <Controller
              control={control}
              name={`owner_approval_${leg.id}`}
              rules={{
                required: {
                  value: leg.is_refueling === null,
                  message: staticService.findByAlias("refuellingStopError"),
                },
              }}
              render={({ field, fieldState }) => (
                <RadioButtonComponent
                  field={field}
                  selected={leg.is_refueling}
                  fieldState={fieldState}
                  items={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  handleChange={(value) => {
                    field.onChange(value);
                    updateLeg("is_refueling", { [leg.id]: value });
                  }}
                  id="refuelling_stop_en_route_required"
                />
              )}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

QuoteEstimate.propTypes = {
  leg: PropTypes.object,
  updateLeg: PropTypes.func,
  readOnly: PropTypes.bool,
  control: PropTypes.object,
};

export default QuoteEstimate;
