import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RegisterByInvitationFormComponent } from "../../";
import { AccountLayout } from "../../layouts";
import { pages, config } from "../../../configs";
import { checkInvitation } from "../../../actions";
import { fireTracking, redirectTo, filteredPages } from "../../../shared";
import { ConfirmComponent } from "../../common";
import staticService from "../../../services/static.service";

class RegisterInvitationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      modalVisibility: false,
      redirectPath: config.redirectUserDashboard,
      isValid: true,
    };
    fireTracking(props.location.pathname);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.timer = null;
  }

  componentWillMount() {
    this.context.store.dispatch(checkInvitation(this.props.match.params.hash, this.onError));
  }

  onSuccess() {
    this.timer = setTimeout(() => {
      this.redirectTo(true, config.redirectUserDashboard);
    }, 1500);
  }

  onError() {
    this.setState({ isValid: false });
  }

  changeModalVisibility(state = true) {
    this.setState({ modalVisibility: state });
    if (!state) {
      this.redirectTo(true, config.redirectUserDashboard);
    }
  }

  redirectTo(state = true, path = "") {
    this.setState({ redirect: state, redirectPath: path });
  }

  get info() {
    return (
      <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("newAccountCreated") }} />
    );
  }

  get layout() {
    return (
      <div>
        <section className={"register-page"}>
          <RegisterByInvitationFormComponent
            onSuccess={this.onSuccess}
            invitationHash={this.props.match.params.hash}
            visible={this.props.showForm}
          />
        </section>
      </div>
    );
  }

  get expiredLayout() {
    return (
      <div data-uk-alert className={"uk-alert-danger uk-text-center"}>
        {staticService.findByAlias("invitationExpiredNotification")}
      </div>
    );
  }

  render() {
    if (this.props.match.params.hash && !this.state.redirect) {
      return (
        <AccountLayout
          activeRoute={this.props.match}
          pageTitle={this.props.title}
          pages={filteredPages(pages)}
        >
          <ConfirmComponent
            visible={this.state.modalVisibility}
            info={this.info}
            buttonsProps={{
              cancel: { title: staticService.findByAlias("goToList") },
            }}
            modalProps={{
              id: "confirm-request",
              title: staticService.findByAlias("newAccountModalTitle"),
              onClose: this.changeModalVisibility.bind(this, false),
            }}
            onReject={this.changeModalVisibility.bind(this, false)}
          />
          {this.state.isValid ? this.layout : this.expiredLayout}
        </AccountLayout>
      );
    }

    return redirectTo(this.state.redirectPath);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
}

RegisterInvitationComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  const response = {
    showForm: state.auth.invitation ? true : false,
  };
  return response;
}

const COMPONENT = connect(mapStateToProps)(RegisterInvitationComponent);
export { COMPONENT as RegisterInvitationComponent };
