import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import { FeedbackFormComponent } from "../../../forms";
import { ConfirmComponent } from "../../../common";
import { quoteWithdrawByBroker, REQUEST_UPDATED, updateQuoteStatus } from "../../../../actions";
import { EUROPEAN_FEE, QUOTE_REJECTED, QUOTE_UNSUCCESSFULL } from "../../../../configs";
import staticService from "../../../../services/static.service";
import { useFullQuoteContext } from "../ViewFullQuoteContext";

const DeclineButtonComponent = (props) => {
  const {
    quote,
    requestId,
    feedbackModel,
    updateQuoteStatus,
    resetModel,
    quoteWithdrawByBroker,
    disabled,
    resetRequest,
  } = props;

  const { setFeeSelected } = useFullQuoteContext();

  const confirmComponent = useRef(null);

  const submitFeedback = async (model) => {
    const backToRequest = document.getElementById("backToRequest");
    if (quote.status === "open") {
      updateQuoteStatus(
        {
          status: QUOTE_REJECTED,
          message: model.message,
          id: quote.id,
        },
        { action: "rejected" },
        confirmComponent.current.closeModal(),
        resetModel("declineModel"),
      ).then(() => {
        if (backToRequest) backToRequest.click();
      });
    } else {
      await quoteWithdrawByBroker(
        {
          status: QUOTE_UNSUCCESSFULL,
          id: quote.id,
          request: requestId,
          reason: model.message,
        },
        { action: "changed to unsuccessfull", entityName: "quote" },
        confirmComponent.current.closeModal(),
        resetModel("declineModel"),
      );
    }
    resetRequest();
  };

  return (
    <React.Fragment>
      <button
        disabled={disabled}
        className={`uk-button uk-button-danger gh-decline-btn gh-quote-button uk-padding-remove gh-full-quote-btn ${
          quote && quote.status === "pending" ? "gh-decline-full" : ""
        }`}
        onClick={() => confirmComponent.current.openModal()}
      >
        {quote.status === "open"
          ? staticService.findByAlias("decline")
          : staticService.findByAlias("withdrawBooking")}
      </button>

      {/*MODALS*/}

      <ConfirmComponent
        ref={confirmComponent}
        info={
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias(
                  quote && quote.status === "open"
                    ? "warningBrokerReject"
                    : "confirmBookingRequestWithdraw",
                ),
              }}
            />
            <FeedbackFormComponent
              label=""
              feedback={true}
              modelName="declineModel"
              modelField={".message"}
              form={feedbackModel}
              visible={true}
              action={submitFeedback}
            />
          </div>
        }
        showCloseBtn={true}
        modalProps={{
          id: "decline-booking-modal",
          options: {
            clsPage: "",
            bgClose: false,
            escClose: false,
          },
          title:
            quote && quote.status === "open"
              ? staticService.findByAlias("rejectQuoteTitle")
              : staticService.findByAlias("CancelRequestBooking"),
          className: "gh-margin-bottom-0",
        }}
      />
    </React.Fragment>
  );
};

DeclineButtonComponent.propTypes = {
  quote: PropTypes.object,
  feedbackModel: PropTypes.object,
  updateQuoteStatus: PropTypes.func,
  requestId: PropTypes.string,
  resetModel: PropTypes.func,
  quoteWithdrawByBroker: PropTypes.func,
  disabled: PropTypes.bool,
  resetRequest: PropTypes.func,
};

DeclineButtonComponent.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  feedbackModel: state.feedbackModel,
  declineModel: state.feedbackModel,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateQuoteStatus,
      quoteWithdrawByBroker,
      resetModel: (model) => dispatch(actions.reset(model)),
      resetRequest: () => dispatch({ type: REQUEST_UPDATED, request: {} }),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineButtonComponent);
