import * as types from "../../actions/actionTypes";

function availableCountries(
  state = {
    isOpen: false,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case types.GET_AVAILABLE_COUNTRIES:
      return {
        ...state,
        items: action.payload,
      };
    case types.SHOW_AVAILABLE_COUNTRIES_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case types.HIDE_AVAILABLE_COUNTRIES_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

export { availableCountries };
