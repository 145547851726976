import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import AutocompleteInput from "../../../../../components/common/AutocompleteInput";
import { useCalculatorContext } from "../../../CalculatorContext";
import useLegLocation from "../../../hooks/useLegLocation";
import { Control } from "../../Control";
import { Description } from "../../Description";
import { Select } from "../../Select";
import { useLegContext } from "../LegContext";
import LegOptions from "../LegOptions";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";
import condStrings from "../../../../../utils/condString";

export default function GeneralSection() {
  const { updateForm, isLocationOriginal, resetToOriginal } = useCalculatorContext();
  const { prefix, index, onLegFlightChange, legId } = useLegContext();
  const { control, getValues } = useFormContext();

  const { onLocationChange } = useLegLocation({ legPath: prefix, getValues, updateForm });

  const onLocationSelected = React.useCallback(
    (direction) => (value) => {
      if (typeof value === "string") {
        updateForm([[`${prefix}.${direction}`, { name: value }]]);
      } else {
        updateForm([[`${prefix}.${direction}`, { ...value, name: value.location || value.name }]]);
      }

      if (value && value.latitude) {
        onLocationChange(direction, { ...value, name: value.location || value.name });
      }

      const from = getValues(`${prefix}.from`);
      const to = getValues(`${prefix}.to`);

      if (from?.latitude && to?.latitude) {
        updateForm([
          [`${prefix}.distance_last_update_by`, "locations"],
          [`${prefix}.duration_last_update_by`, "locations"],
        ]);
        onLegFlightChange();
      }
    },
    [getValues, prefix, updateForm, onLegFlightChange, onLocationChange],
  );

  return (
    <Description>
      <Description.Item
        prefix={`Leg ${index + 1}`}
        suffix={<LegOptions />}
        className="uk-flex-between"
      ></Description.Item>
      <Description.Item>
        <Control name={`${prefix}.category`}>
          <Select
            placeholder="[Category]"
            options={[
              { label: "Client Leg", value: 1, background: "#d8e2f5" },
              {
                label: "(Re)Positioning Leg",
                value: 2,
                background: "#e7f2ef",
              },
            ]}
          />
        </Control>
      </Description.Item>
      <Description.Item
        className={condStrings(
          "calculator-location-input",
          !isLocationOriginal(prefix, legId, "from") && "edited",
        )}
      >
        <Controller
          control={control}
          name={`${prefix}.from.name`}
          rules={{
            required: {
              value: true,
            },
          }}
          render={({ field: { value, ref }, fieldState }) => (
            <AutocompleteInput
              inputRef={ref}
              onChange={onLocationSelected("from")}
              value={value}
              fieldState={fieldState}
              placeholder="Please provide address"
              tooltipSide="right"
              debounce={200}
              secondaryInputText={generateLocationAdditionalInfo(getValues(`${prefix}.from`))}
            />
          )}
        />
        {!isLocationOriginal(prefix, legId, "from") && (
          <button
            type="button"
            tabIndex="-1"
            className="calculator-reset-btn calculator__input-btn reset"
            onClick={resetToOriginal({
              legId,
              field: `${prefix}.from`,
              callback: onLocationSelected("from"),
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                clipRule="evenodd"
              />
            </svg>
            <span>Reset</span>
          </button>
        )}
      </Description.Item>
      <Description.Item
        className={condStrings(
          "calculator-location-input",
          !isLocationOriginal(prefix, legId, "from") && "edited",
        )}
      >
        <Controller
          control={control}
          name={`${prefix}.to.name`}
          rules={{
            required: {
              value: true,
            },
          }}
          render={({ field: { value, ref }, fieldState }) => (
            <AutocompleteInput
              inputRef={ref}
              onChange={onLocationSelected("to")}
              value={value}
              fieldState={fieldState}
              placeholder="Please provide address"
              tooltipSide="right"
              debounce={200}
              secondaryInputText={generateLocationAdditionalInfo(getValues(`${prefix}.to`))}
            />
          )}
        />
        {!isLocationOriginal(prefix, legId, "to") && (
          <button
            type="button"
            tabIndex="-1"
            className="calculator-reset-btn calculator__input-btn reset"
            onClick={resetToOriginal({
              legId,
              field: `${prefix}.to`,
              callback: onLocationSelected("to"),
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                clipRule="evenodd"
              />
            </svg>
            <span>Reset</span>
          </button>
        )}
      </Description.Item>
    </Description>
  );
}
