import React from "react";
import PropTypes from "prop-types";
import "./QuotesChaseButton.css";
import Button from "../../../../../common/Button";
import clsx from "clsx";
import useQuotesChaseButton from "./useQuotesChaseButton";
import Popup from "reactjs-popup";
import infoSVG from "../../../../../../assets/img/svg/info.svg";

const QuotesChaseButton = ({ className, show, ...rest }) => {
  const { handleButtonClick, isButtonLoading, lastChase, hideComponent, popupRef } =
    useQuotesChaseButton(rest);

  if (hideComponent || !show) return null;
  return (
    <>
      <Button
        loading={isButtonLoading}
        variant="text"
        onClick={handleButtonClick}
        className={clsx(className, "gh-marketplace-chase-button mobile")}
      >
        Chase Broker
      </Button>
      <Popup
        trigger={
          <div
            className={clsx("gh-chase-button-tooltip-trigger-wrapper", {
              "gh-mobile-hide": !lastChase,
            })}
          >
            <Button
              loading={isButtonLoading}
              variant="text"
              onClick={handleButtonClick}
              className={clsx(className, "gh-marketplace-chase-button desktop")}
            >
              Chase
              <br />
              Broker
            </Button>
            <img src={infoSVG} alt="info" className="gh-chase-button-tooltip-icon" />
          </div>
        }
        disabled={!lastChase}
        on="hover"
        keepTooltipInside={true}
        position={["top center", "right center", "left center"]}
        ref={popupRef}
      >
        Last chase on {lastChase}
      </Popup>
    </>
  );
};

QuotesChaseButton.propTypes = {
  className: PropTypes.string,
  quoteId: PropTypes.number.isRequired,
  chaseDetails: PropTypes.object,
  quoteStatus: PropTypes.string.isRequired,
  show: PropTypes.bool,
};
QuotesChaseButton.defaultProps = { className: "", show: true };

export default QuotesChaseButton;
