import React from "react";
import PropTypes from "prop-types";
import { checkObjectProp } from "../../../../../shared";
import { AllowPermission } from "../../../../permissions";
import { NEW_CARD } from "../../../../../actions";
import staticService from "../../../../../services/static.service";
import { CardsComponent } from "./";
import auth from "../../../../../services/auth";
import "./WalletComponent.css";
import CheckboxFuncComponent from "../../../../common/CheckboxFuncComponent";

class WalletComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isSubmitButtonDisabled: true,
    };
    this.setEditMode = this.setEditMode.bind(this);
    this.setPaymentDetails = this.setPaymentDetails.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.cards !== prevProps.cards ||
      this.props.quote?.operator_stripe_verification_status !==
        prevProps.quote?.operator_stripe_verification_status
    ) {
      const paymentMethodsAreNotAvailable =
        !auth.hasFeature("manual_bank_transfer") &&
        this.props.quote?.operator_stripe_verification_status === false;

      const noCardsAndNoBankTransferFeature =
        !this.props.cards.length && !auth.hasFeature("manual_bank_transfer");

      const result = paymentMethodsAreNotAvailable || noCardsAndNoBankTransferFeature;

      this.setState({ isSubmitButtonDisabled: result });
    }
  }

  get hidden() {
    return this.state.editMode ? "uk-hidden" : "";
  }

  setEditMode(state) {
    this.setState({ editMode: state });
  }

  btnTooltip(cards) {
    return !cards.length && !auth.hasFeature("manual_bank_transfer")
      ? {
          "data-uk-tooltip": `title: ${staticService.findByAlias(
            "requestBookingConfirmationTooltip",
          )}; pos: top-center;`,
        }
      : {};
  }

  setPaymentDetails(details) {
    this.props.handleUpdateState("paymentDetails", details);
  }

  render() {
    const {
      price,
      save,
      quote,
      cards,
      selectedCard,
      handleUpdateState,
      payOnlyDeposit,
      paymentDetails,
    } = this.props;

    const sameCurrency = quote.currency && quote.currency.currency === quote.user_currency.currency;

    return (
      <section className="gh-wallet-component-wrapper">
        <div>
          <CardsComponent
            loaderPosition={"wallet-loader"}
            newCardAction={NEW_CARD}
            selectedCard={this.props.onChange}
            paymentView={true}
            triggerInitMode={this.setEditMode}
            cardIsSelected={selectedCard}
            setPaymentDetails={this.setPaymentDetails}
          />
        </div>
        {price !== null && quote.currency ? (
          <div
            className={`${this.hidden} uk-padding uk-padding-remove-top gh-wallet-component-bottom-section`}
          >
            <hr className="gh-divider gh-wallet-component-divider" />
            <div>
              {!sameCurrency ? (
                <div className="gh-wallet-price-row">
                  <AllowPermission role="broker">
                    <div
                      className="gh-wallet-price-key"
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("Approximately") + ": ",
                      }}
                    />
                    <div className="gh-wallet-price-value">
                      <b className="gh-text-primary">
                        {quote.user_currency.currency}{" "}
                        {checkObjectProp(quote, "user_total_price", 0)}
                      </b>
                      <span>
                        , incl. {quote.user_currency.currency}{" "}
                        {checkObjectProp(quote, "user_total_vat", 0)} VAT
                      </span>
                    </div>
                  </AllowPermission>
                </div>
              ) : (
                ""
              )}
              <div className="gh-wallet-price-row">
                <AllowPermission role="broker">
                  <div
                    className="gh-wallet-price-key"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("charterPrice") + " (inc.): ",
                    }}
                  />
                  <div className="gh-wallet-price-value">
                    {quote.currency.currency} {checkObjectProp(quote, "user_price_formatted", 0)}
                  </div>
                </AllowPermission>
              </div>
              <div className="gh-wallet-price-row">
                <AllowPermission role="broker">
                  <div
                    className="gh-wallet-price-key"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("transactionFee") + ": ",
                    }}
                  />
                  <div className="gh-wallet-price-value">
                    {quote.currency.currency} {checkObjectProp(quote, "transaction_fee", 0)}
                  </div>
                </AllowPermission>
              </div>

              <div className="gh-wallet-price-row">
                <AllowPermission role="broker">
                  <div
                    className="gh-wallet-price-key"
                    dangerouslySetInnerHTML={{
                      __html:
                        staticService.findByAlias(
                          payOnlyDeposit ? "totalPriceLabel" : "totalPrice",
                        ) + ": ",
                    }}
                  />
                  <div className="gh-wallet-price-value deposit-row">
                    <div>
                      <b className={!payOnlyDeposit ? "gh-text-primary" : ""}>
                        {quote.currency.currency} {checkObjectProp(quote, "total_price", 0)}
                      </b>
                      <span>
                        , incl. {quote.currency.currency} {checkObjectProp(quote, "total_vat", 0)}{" "}
                        VAT
                      </span>
                    </div>

                    {paymentDetails.secure_with_deposit ? (
                      <div>
                        <CheckboxFuncComponent
                          label={staticService.findByAlias("payOnlyDeposit")}
                          checked={payOnlyDeposit}
                          onClick={() => handleUpdateState("payOnlyDeposit", !payOnlyDeposit)}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </AllowPermission>
              </div>

              <div className={`gh-wallet-price-row ${!payOnlyDeposit ? "--hide" : ""}`}>
                <AllowPermission role="broker">
                  <div
                    className="gh-wallet-price-key"
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("depositAmount"),
                    }}
                  />
                  <div className="gh-wallet-price-value">
                    <b className="gh-text-primary">
                      {quote.currency.currency}{" "}
                      {checkObjectProp(paymentDetails.deposit_amount, "amount", 0)}
                    </b>
                    <span className="uk-margin-small-left w-400">
                      {staticService.findByAlias("secureDeposit")}
                    </span>
                  </div>
                </AllowPermission>
              </div>
            </div>

            <div className="uk-text-center uk-margin-top">
              <button
                className="uk-button uk-button-primary gh-full-width-btn"
                {...this.btnTooltip(cards)}
                type={"button"}
                disabled={this.state.isSubmitButtonDisabled}
                onClick={save}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(
                      !auth.hasFeature("manual_bank_transfer") ||
                        (selectedCard && selectedCard.card_id !== "manual")
                        ? "requestBookingConfirmation"
                        : "requestProvisionalBookingConfirmation",
                    ),
                  }}
                />
              </button>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

WalletComponent.propTypes = {
  save: PropTypes.func,
  onChange: PropTypes.func,
  quote: PropTypes.object,
  price: PropTypes.any,
  card: PropTypes.object,
  selectedCard: PropTypes.object,
  cards: PropTypes.array,
  handleUpdateState: PropTypes.func,
  paymentDetails: PropTypes.object,
  payOnlyDeposit: PropTypes.bool,
};

export { WalletComponent };
