import React from "react";
import PropTypes from "prop-types";
import "./backlink.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";

function BackLink(props) {
  const { to, onClick, children, className, back, toUpdateModel, updateModel } = props;
  const history = useHistory();

  const onClickHandler = () => {
    if (toUpdateModel) {
      updateModel("isConcat", true);
    }

    if (to) history.push(to);
    else if (onClick) onClick();
    else if (back) history.goBack();
    else history.push("/");
  };

  return (
    <button className={`backlink ${className}`} onClick={onClickHandler}>
      <span className="uk-margin-small-right" data-uk-icon="icon: arrow-left" />
      {children}
    </button>
  );
}

BackLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
  back: PropTypes.bool,
  toUpdateModel: PropTypes.bool,
  updateModel: PropTypes.func,
};

BackLink.defaultProps = {
  to: null,
  onClick: null,
  className: "",
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(BackLink);
