import React from "react";
import staticService from "../../../services/static.service";
import { BlockComponent } from "../../common";

const RegisterStepTitle = ({ userType, step }) => {
  return (
    <BlockComponent visible={true} containerClass="uk-container gh-register-title">
      {step < 3 ? (
        userType === "broker" ? (
          <h3
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("brokerAccountSignUp") }}
          />
        ) : (
          <div>
            <h3
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("operatorAccountSignUp"),
              }}
            />
            {step === 1 ? (
              <h5
                className="gh-signup-subtitle"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("operatorSignupSubTitle"),
                }}
              />
            ) : (
              ""
            )}
          </div>
        )
      ) : (
        <h3
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("profileCompletionProgress"),
          }}
        />
      )}
    </BlockComponent>
  );
};

export { RegisterStepTitle };
