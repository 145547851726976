import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useLegContext } from "../Leg/LegContext";
import { useCalculatorContext } from "../../CalculatorContext";
import { Description } from "../Description";
import { Control } from "../Control";
import { ConfirmInput, Input, TaxInput } from "../Input";
import { Radio, RadioGroup } from "../Radio";
import { Options } from "../Options";
import { PilotPlaceholder } from "./PilotPlaceholder";
import cs from "../../../../utils/condString";

import "./index.css";

export function Pilot(props) {
  const { prefix, onRemove, count, defaultData } = props;
  const { toggleSectionStatus, resetToOriginal, currency, demoVersion, activeAircraftIndex } =
    useCalculatorContext();
  const { getValues, setValue, watch } = useFormContext();
  const { onPilotChange, legId } = useLegContext();

  const pilotId = watch(`${prefix}.id`);
  const is_included = watch(`${prefix}.is_included`);

  const payment_amount = getValues(`${prefix}.payment_amount`);
  const pilot_shift_duration = getValues(`${prefix}.pilot_shift_duration`);

  React.useEffect(() => {
    if (is_included === "2") {
      onPilotChange(prefix)();
    }
  }, [is_included]);

  // React.useEffect(() => {
  //   if (demoVersion) {
  //     const pilotValue = getValues(prefix);
  //     console.log("pilotValue", pilotValue);
  //     console.log("defaultData", defaultData);
  //     console.log("activeAircraftIndex", activeAircraftIndex);
  //
  //     setValue(prefix, defaultData);
  //
  //     // if (!pilotValue.is_included) {
  //     //   setValue(prefix, defaultData);
  //     // } else {
  //     //   setValue(prefix, pilotValue);
  //     // }
  //   }
  // }, [demoVersion, activeAircraftIndex]);

  return (
    <Description className={cs("pilot", is_included === "1" && "included")}>
      <Description.Item
        suffix={
          count > 1 && (
            <Options>
              <Options.Item onClick={onRemove} danger>
                Remove
              </Options.Item>
            </Options>
          )
        }
      >
        <Control name={`${prefix}.name`}>
          <Input placeholder="[Name]" onFocus={toggleSectionStatus("pilots", true)} />
        </Control>
      </Description.Item>
      <Description.Item>
        <Control name={`${prefix}.is_included`}>
          <RadioGroup>
            <Radio value="1" label="Yes" />
            <Radio value="2" label="No" />
          </RadioGroup>
        </Control>
      </Description.Item>
      <Description.Item className="included-option">
        <Control name={`${prefix}.payment_type`} required={is_included === "2" ? true : false}>
          <RadioGroup>
            <Radio value="1" label="Hourly" />
            <Radio value="2" label="Daily" />
          </RadioGroup>
        </Control>
      </Description.Item>
      <Description.Item className="included-option">
        <Control name={`${prefix}.payment_amount`} required={is_included === "2" ? true : false}>
          <ConfirmInput
            placeholder="[0]"
            onValueChange={onPilotChange(prefix)}
            onReset={resetToOriginal({
              legId,
              pilotId,
              field: `${prefix}.payment_amount`,
              callback: onPilotChange(prefix),
            })}
          />
        </Control>
      </Description.Item>
      <Description.Item className="included-option">
        <Control
          name={`${prefix}.pilot_shift_duration`}
          required={is_included === "2" ? true : false}
        >
          <ConfirmInput
            placeholder="[0]"
            onValueChange={onPilotChange(prefix)}
            onReset={resetToOriginal({
              legId,
              pilotId,
              field: `${prefix}.pilot_shift_duration`,
              callback: onPilotChange(prefix),
            })}
            maxLength={2}
          />
        </Control>
      </Description.Item>
      <Description.Item className="included-option">
        <Control name={`${prefix}.payment_tax`}>
          <TaxInput
            total={payment_amount * pilot_shift_duration}
            onValueChange={onPilotChange(prefix)}
            currency={currency}
          />
        </Control>
      </Description.Item>
      <Description.Item separator type="info" className="included-option">
        <Control name={`${prefix}.total_pilot_costs`}>
          <ConfirmInput placeholder="0.00" readOnly />
        </Control>
      </Description.Item>

      <Control name={`${prefix}.calculation_pilot_id`} notAComponent>
        <input type="hidden" />
      </Control>
    </Description>
  );
}

Pilot.propTypes = {
  prefix: PropTypes.string,
  onRemove: PropTypes.func,
  count: PropTypes.number,
};

Pilot.Placeholder = PilotPlaceholder;
