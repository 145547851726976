import React from "react";
import PropTypes from "prop-types";
import "./progressInfoModalContent.css";
import CustomProgressBar from "../CustomProgressBar";
import ProgressInfoRenotify from "./ProgressInfoRenotify";
import LoadingComponent from "../LoadingComponent";
import Spinner from "../Spinner";
import { connect } from "react-redux";
import auth from "../../../services/auth";
import { exceptionStatusesProgressInfoRequests } from "../../pages";
import isObjectValidAndNotEmpty from "../../../utils/isObjectValidAndNotEmpty";
import { Link } from "react-router-dom";

const ProgressInfoModalContent = (props) => {
  const { progressInfo, openedProgressInfoModalItem } = props;
  const advancedQuotingProgressBar = auth.hasFeature("advanced_quoting_progress_bar");

  const isAnonymityRemoved =
    (isObjectValidAndNotEmpty(progressInfo) && progressInfo?.is_anonymity_removed) || false;
  const isNotAnonymityRemoved =
    (isObjectValidAndNotEmpty(progressInfo) && !progressInfo?.is_anonymity_removed) || false;

  const exceptionStatuses =
    exceptionStatusesProgressInfoRequests(openedProgressInfoModalItem?.status) || false;
  const requestIsInReview = progressInfo.review_status;
  const noProgressInfo = progressInfo?.actions?.length === 0;

  const showTextOnly = exceptionStatuses || requestIsInReview || noProgressInfo;

  if (showTextOnly)
    return (
      <ProgressInfoText text={openedProgressInfoModalItem?.relevant_operators_message || ""} />
    );

  if (!advancedQuotingProgressBar && openedProgressInfoModalItem)
    return (
      <div>
        <div
          className="gh-progress-text uk-margin-small-top"
          dangerouslySetInnerHTML={{
            __html: openedProgressInfoModalItem.relevant_operators_message,
          }}
        />
        {openedProgressInfoModalItem.relevant_operators_progress ? (
          <div className="gh-request-progress">
            <div
              className="gh-request-progress-content"
              style={{ width: `${openedProgressInfoModalItem.relevant_operators_progress}%` }}
            />
            <progress value={openedProgressInfoModalItem.relevant_operators_progress} max="100" />
          </div>
        ) : (
          ""
        )}
      </div>
    );

  if (isAnonymityRemoved)
    return (
      <LoadingComponent show={isObjectValidAndNotEmpty(progressInfo)}>
        <div className="gh-progress-info-wrapper">
          <div className="gh-progress-info-actions">
            {progressInfo.actions &&
              progressInfo.actions.map((item, index) => (
                <div key={index} className="gh-progress-info-actions-row">
                  <div className="gh-progress-info-actions-row-name">{item.name}</div>
                  <div className="gh-progress-info-actions-row-value">{item.action}</div>
                  {(item.renotify_ability || !!item.renotify_wait_time) &&
                    (auth.hasFeature("renotify_operators_once") ||
                      auth.hasFeature("renotify_operators")) && (
                      <div className="gh-progress-info-actions-row-value">
                        <ProgressInfoRenotify
                          requestId={progressInfo?.booking_request?.id}
                          companyId={item.id}
                          waitTime={item.renotify_wait_time}
                        />
                      </div>
                    )}

                  {item.order === 2 ? (
                    <Link
                      to={`/flights/requests/filter/open/${progressInfo.booking_request.id}`}
                      className="gh-view-request-btn"
                    >
                      View
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
        </div>
      </LoadingComponent>
    );

  if (isNotAnonymityRemoved)
    return (
      <LoadingComponent show={isObjectValidAndNotEmpty(progressInfo)}>
        <div className="gh-progress-info-wrapper">
          <div>
            <div className="gh-progress-info-actions progress">
              {progressInfo.actions &&
                progressInfo.actions.map((item, index) => (
                  <div key={index} className="gh-progress-info-actions-row">
                    <div className="gh-progress-info-actions-row-name">{item.name}</div>
                    <div className="gh-progress-info-actions-row-value progress">
                      <CustomProgressBar percent={item.percentage} />
                    </div>
                  </div>
                ))}
            </div>
            <div className="gh-progress-info-actions reasons">
              {progressInfo.reasons &&
                progressInfo.reasons.map((item, index) => (
                  <div key={index} className="gh-progress-info-actions-row">
                    <div className="gh-progress-info-actions-row-name" />
                    <div className="gh-progress-info-actions-row-reasons">
                      <div className="gh-progress-info-actions-row-reasons-percent">
                        {item.percentage}%
                      </div>
                      <div className="gh-progress-info-actions-row-reasons-value">{item.name}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </LoadingComponent>
    );

  return <Spinner className="uk-flex uk-flex-center uk-margin-small-top" />;
};

ProgressInfoModalContent.propTypes = {
  progressInfo: PropTypes.object,
  openedProgressInfoModalItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
  progressInfo: state.progressInfo,
});

export default connect(mapStateToProps)(ProgressInfoModalContent);

// eslint-disable-next-line react/prop-types
const ProgressInfoText = ({ text }) => (
  <div
    className="gh-progress-text uk-margin-small-top"
    dangerouslySetInnerHTML={{
      __html: text,
    }}
  />
);
