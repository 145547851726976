import React from "react";
import { Control, Fieldset, Errors } from "react-redux-form";
import { validationRules, validationMessages } from "../../../configs";
import { CustomValidateError, updateModel } from "../../../shared";
import staticService from "../../../services/static.service";

const { required } = validationRules;

export const BankAccountFieldset = ({ modelName, dynamicFields }) => {
  return (
    <Fieldset model={modelName} className="just-accordion_form">
      {Object.keys(dynamicFields).map((key) => (
        <div className="uk-margin validation-wrapper" key={key}>
          <label className={"required uk-form-label"}>{dynamicFields[key].label}</label>
          <div className="input-wrapper">
            <Control.text
              className={"uk-input"}
              model={`.${key}`}
              changeAction={updateModel}
              updateOn="change"
              placeholder={dynamicFields[key].example}
              validators={{ required }}
            />
            <Errors
              model={`.${key}`}
              show="touched"
              wrapper={CustomValidateError}
              messages={{
                required: validationMessages().requiredMessage.bind(null, dynamicFields[key].label),
              }}
            />
          </div>
        </div>
      ))}

      <div className="uk-margin validation-wrapper">
        <label
          className={"required uk-form-label"}
          dangerouslySetInnerHTML={{
            __html: staticService.findByAlias("HolderName"),
          }}
        />
        <div className="input-wrapper">
          <Control.text
            className={"uk-input"}
            model={".account_holder_name"}
            changeAction={updateModel}
            updateOn="change"
            validators={{ required }}
          />
          <Errors
            model=".account_holder_name"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(
                null,
                staticService.findByAlias("HolderName"),
              ),
            }}
          />
        </div>
      </div>
    </Fieldset>
  );
};
