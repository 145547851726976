import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./QuoteVisualsLayout.css";
import AircraftDetails from "../AircraftDetails";
import { BrokerVettingComponent } from "../../../user-online";
import TermsAndConditions from "../../../../common/TermsAndConditions/termsAndConditions";
import TermsAndConditionsAccordion from "../../../../common/TermsAndConditionsAccordion";
import QuotePads from "../QuotePads";
import QuotePriceSection from "../QuotePriceSection";
import OperatorComments from "../OperatorComments";
import { isObjectEmpty, ModalComponent } from "../../../../../shared";
import { connect } from "react-redux";
import staticService from "../../../../../services/static.service";
import LearMoreVetting from "../../../../common/LearnMoreVetting";
import auth from "../../../../../services/auth";
import UploadFiles from "../../../../forms/register/upload-files.component";

let currentAircraft;
const QuoteVisualsLayout = (props) => {
  const { screenW, quoteDetail, showVetting, history, feeSelected, setFeeSelected } = props;

  /*===========States===========*/
  const [selectedPadAndAircraft, setSelectedPadAndAircraft] = useState([0, 0]);
  const [sliderImages, setSliderImages] = useState([]);

  const learnMoreVettingModalRef = useRef(null);

  const {
    pads = [],
    description: operatorComments,
    flight_type = 1,
    user_currency = null,
    currency: currencyObject = null,
    european_quote_price = {},
    international_quote_price = {},
    manual_bank_transfer_quote_price = {},
    payment = {},
    status = "",
    operator_stripe_verification_status,
  } = quoteDetail || {};

  const { currency = "" } = user_currency || currencyObject || {};
  const { aircraft_model = "---", description = "" } = currentAircraft || {};

  useEffect(() => setSelectedPadAndAircraft([0, 0]), []);

  useEffect(() => {
    setSelectedPadAndAircraft([0, 0]);
  }, [quoteDetail]);

  /*===========Get current aircraft info===========*/
  useEffect(() => {
    if (pads.length <= 0) return;
    if (
      !pads[selectedPadAndAircraft[0]] ||
      !pads[selectedPadAndAircraft[0]].aircrafts[selectedPadAndAircraft[1]]
    )
      return setSelectedPadAndAircraft([0, 0]);

    currentAircraft = pads[selectedPadAndAircraft[0]].aircrafts[selectedPadAndAircraft[1]];
  }, [selectedPadAndAircraft, pads]);

  /*===========Get images===========*/
  useEffect(() => {
    if (isObjectEmpty(quoteDetail)) return;
    if (
      !pads[selectedPadAndAircraft[0]] ||
      !pads[selectedPadAndAircraft[0]].aircrafts[selectedPadAndAircraft[1]]
    )
      return setSelectedPadAndAircraft([0, 0]);

    if (
      pads[selectedPadAndAircraft[0]].aircrafts[selectedPadAndAircraft[1]] &&
      pads[selectedPadAndAircraft[0]].aircrafts[selectedPadAndAircraft[1]].aircraft_images.length >
        0
    ) {
      const aircraftImages = [
        ...pads[selectedPadAndAircraft[0]].aircrafts[selectedPadAndAircraft[1]].aircraft_images,
      ];
      setSliderImages([...aircraftImages]);
    }
  }, [quoteDetail, selectedPadAndAircraft]);

  const displayTerms =
    auth.getMainRole() === "operator"
      ? auth.getHeliServiceTerms()
      : quoteDetail.terms_status &&
        quoteDetail.terms_status.country_terms_list.helicopter_services_terms;

  return (
    <div className="gh-quote-details-wrapper">
      <div className="gh-quote-section-wrapper">
        <div className="gh-quote-card-wrapper gh-quote-card-wrapper-left">
          {screenW > 768 ? (
            <AircraftDetails
              flight_type={flight_type}
              selectedPadAndAircraft={selectedPadAndAircraft}
              aircraft_model={aircraft_model}
              sliderImages={sliderImages}
              description={description}
              pads={pads}
              currentAircraft={currentAircraft}
            />
          ) : null}
          {!isObjectEmpty(quoteDetail) && showVetting && (
            <section className="gh-vetting-process-card gh-quote-details-section-card">
              <BrokerVettingComponent
                quote={quoteDetail}
                learnMore={() => learnMoreVettingModalRef.current.open()}
              />
            </section>
          )}

          {displayTerms && (flight_type !== 1 || !showVetting) ? (
            <section className="gh-quote-details-terms-and-conditions-wrapper gh-quote-details-terms-and-conditions-column">
              {screenW > 768 ? (
                <TermsAndConditions request={quoteDetail.booking_request} />
              ) : (
                <TermsAndConditionsAccordion request={quoteDetail.booking_request} />
              )}
            </section>
          ) : quoteDetail.helicopter_services_terms?.length ? (
            <div className="uk-margin-top gh-quote-details-wrapper-services-terms">
              <UploadFiles
                model={quoteDetail.helicopter_services_terms}
                modelName="quoteFormModel.helicopterServicesTerms"
                uploadTitle="flightContract"
                docType={1}
                docs={quoteDetail.helicopter_services_terms}
                entity="company_document"
                multiple={false}
                onlyOneFile={true}
                hiddenUpload={true}
                hideRemove={true}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="gh-quote-card-wrapper gh-quote-card-wrapper-right">
          {screenW < 768 ? (
            <AircraftDetails
              flight_type={flight_type}
              selectedPadAndAircraft={selectedPadAndAircraft}
              aircraft_model={aircraft_model}
              sliderImages={sliderImages}
              description={description}
              pads={pads}
              currentAircraft={currentAircraft}
            />
          ) : null}

          <QuotePads
            pads={pads}
            flight_type={flight_type}
            selectedPadAndAircraft={selectedPadAndAircraft}
            setSelectedPadAndAircraft={setSelectedPadAndAircraft}
          />

          <QuotePriceSection
            renderBy="QuoteDetails"
            currency={currency}
            bookingStatus={status}
            calculatedPrices={{
              european_quote_price,
              international_quote_price,
              manual_bank_transfer_quote_price,
            }}
            payment={payment}
            history={history}
            feeSelected={feeSelected}
            setFeeSelected={setFeeSelected}
            quotePrice={quoteDetail.quote_price}
            isOperatorStripeVerified={operator_stripe_verification_status}
          />

          <OperatorComments>{operatorComments}</OperatorComments>
        </div>
      </div>

      {displayTerms && flight_type === 1 && showVetting ? (
        <section className="gh-quote-details-terms-and-conditions-wrapper">
          {quoteDetail && !isObjectEmpty(quoteDetail) ? (
            <TermsAndConditions request={quoteDetail.booking_request} />
          ) : (
            ""
          )}
        </section>
      ) : (
        ""
      )}

      {quoteDetail.helicopter_services_terms?.length &&
      flight_type === 1 &&
      showVetting &&
      displayTerms ? (
        <div className="uk-margin-top">
          <UploadFiles
            model={quoteDetail.helicopter_services_terms}
            modelName="quoteFormModel.helicopterServicesTerms"
            uploadTitle="flightContract"
            docType={1}
            docs={quoteDetail.helicopter_services_terms}
            entity="company_document"
            multiple={false}
            onlyOneFile={true}
            hiddenUpload={true}
            hideRemove={true}
          />
        </div>
      ) : (
        ""
      )}

      {/*===========Modals===========*/}
      {showVetting && (
        <ModalComponent
          title={staticService.findByAlias("ghVettingProcess")}
          ref={learnMoreVettingModalRef}
          modalClass="gh-vetting-modal"
          id="learn-more-vetting"
          options={{ clsPage: "", bgClose: false, escClose: false }}
        >
          <LearMoreVetting
            className="gh-quote-details-vetting-more"
            vetting={quoteDetail && quoteDetail.vetting_process}
          />
        </ModalComponent>
      )}
    </div>
  );
};
QuoteVisualsLayout.propTypes = {
  screenW: PropTypes.number,
  quoteDetail: PropTypes.object,
  showVetting: PropTypes.bool,
  history: PropTypes.object,
  feeSelected: PropTypes.string,
  setFeeSelected: PropTypes.func,
};

QuoteVisualsLayout.defaultProps = {
  showVetting: true,
};

function mapStateToProps(state) {
  return {
    screenW: state.screenDimensions.screenInnerW,
  };
}

export default connect(mapStateToProps, null)(QuoteVisualsLayout);
