class ErrorService {
  constructor() {
    this.isVisible = false;
    this.errors = [];
    this.onChange = (info) => {};
  }

  setErrors(errors) {
    if (errors && errors.length) {
      this.errors = errors;
    } else {
      this.errors.push(errors);
    }
  }

  show(errors = []) {
    this.setErrors(errors);
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.errors = [];
  }

  get Errors() {
    return this.errors;
  }
}
const errorInstance = new ErrorService();

export { errorInstance };
