import React, { Component } from "react";
import staticService from "../../services/static.service";
import "../../assets/css/components/FollowUsTooltipComponent.css";
class FollowUsTooltipComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFollowUsLinksOpen: false,
    };

    this.openFollowUsLinks = this.openFollowUsLinks.bind(this);
    this.closeFollowUsLinks = this.closeFollowUsLinks.bind(this);
  }

  openFollowUsLinks() {
    this.setState({ isFollowUsLinksOpen: true });
  }

  closeFollowUsLinks() {
    this.setState({ isFollowUsLinksOpen: false });
  }

  render() {
    return (
      <div className="FollowUsTooltipComponent" onMouseLeave={this.closeFollowUsLinks}>
        <div className={"uk-padding-remove-left"}>
          <ul className="FollowUsTooltipComponent-link-wrapper">
            <li>
              <a
                href="https://www.instagram.com/getheli/"
                target="_blank"
                rel="noreferrer"
                className="footer-svg inst"
              >
                <span
                  style={{ cursor: "pointer", color: "#1abc9c" }}
                  data-uk-icon={"icon: instagram; width: 24px;"}
                />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/gethelico"
                target="_blank"
                rel="noreferrer"
                className="footer-svg twit"
              >
                <span
                  style={{ cursor: "pointer", color: "#1abc9c" }}
                  data-uk-icon={"icon: twitter; width: 24px;"}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/getheli/"
                target="_blank"
                rel="noreferrer"
                className="footer-svg svg-in"
              >
                <span
                  style={{ cursor: "pointer", color: "#1abc9c" }}
                  data-uk-icon={"icon: linkedin; width: 24px;"}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/gethelico/"
                target="_blank"
                rel="noreferrer"
                className="footer-svg svg-fb"
              >
                <span
                  style={{ cursor: "pointer", color: "#1abc9c" }}
                  data-uk-icon={"icon: facebook; width: 22px;"}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

FollowUsTooltipComponent.propTypes = {};

export { FollowUsTooltipComponent };
