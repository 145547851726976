import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import moment from "moment";
import { DateTimeComponent } from "../../../../../shared";
import { config } from "../../../../../configs";
import tranformDateTimeToISO from "../../../../../utils/tranformDateTimeToISO";
import calendar from "../../../../../assets/img/svg/calendar.svg";
import clock from "../../../../../assets/img/svg/clock.svg";

import "./QuoteExpirationDate.css";

const QuoteExpirationDate = (props) => {
  const { updateQuoteDetail, editingQuote } = props;
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const currentMinTime = moment().add(2, "hour").startOf("hour");
  const isTodaySelected = useMemo(() => moment().isSame(date, "day"), [date]);

  useEffect(() => {
    const transformedDate = tranformDateTimeToISO(date || "", time || "");

    updateQuoteDetail({ ...editingQuote, expiration_date: transformedDate });
  }, [date, time]);

  useEffect(() => {
    if (editingQuote.expiration_time) {
      setTime(moment(editingQuote.expiration_time).format(config.timeFormat));
      setDate(moment(editingQuote.expiration_time).format(config.dateFormat));
    }
  }, []);

  const handleChangeDate = (value) => {
    setDate(moment(value).format(config.dateFormat));
    const isToday = moment().isSame(value, "day");

    if (isToday) {
      return setTime(currentMinTime.format(config.timeFormat));
    }

    if (!time && !isToday) {
      return setTime("12:00");
    }
  };

  const handleChangeTime = (value) => {
    const invalidTime = moment(value).startOf("hour").isBefore(currentMinTime);

    if (!date) {
      setDate(moment().format(config.dateFormat));
      setTime(currentMinTime.format(config.timeFormat));
      return;
    }

    if (isTodaySelected && value.isSame(moment("00:00", config.timeFormat))) {
      return setTime(moment(currentMinTime).format(config.timeFormat));
    }

    if (isTodaySelected && invalidTime) {
      setTime("23:00");
    } else {
      setTime(moment(value).format(config.timeFormat));
    }
  };

  return (
    <div className="gh-simple-section gh-additional-info-wrapper">
      <div className="gh-additional-info-title" style={{ marginTop: 0 }}>
        {staticService.findByAlias("quoteExpirationTitle")}
      </div>

      <div className="gh-quote-expiration-wrapper">
        <div className="gh-quote-expiration-col">
          <DateTimeComponent
            label={staticService.findByAlias("expirationDate")}
            className="uk-input"
            onChange={handleChangeDate}
            value={date}
            showClearIcon={false}
            icon={calendar}
            isValidDate={true}
          />
        </div>
        <div className="gh-quote-expiration-col">
          <DateTimeComponent
            placeholder="Pick a time"
            label={staticService.findByAlias("expirationTime")}
            className="uk-input"
            onChange={handleChangeTime}
            dateFormat={false}
            timeFormat={config.timeFormat}
            value={time}
            showClearIcon={false}
            icon={clock}
            timeConstraints={{ minutes: { step: 5 } }}
          />
        </div>
      </div>
    </div>
  );
};

QuoteExpirationDate.propTypes = {
  editingQuote: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
};

export default QuoteExpirationDate;
