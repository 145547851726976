import React, { Component } from "react";
import { Errors, Form, actions, Control } from "react-redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import auth from "../../../../../services/auth";
import {
  CustomValidateError,
  IconComponent,
  ModalComponent,
  updateModel,
  alertInstance,
} from "../../../../../shared";
import { validationMessages, validationRules, config } from "../../../../../configs";
import { inviteMembers } from "../../../../../actions";
import staticService from "../../../../../services/static.service";

const {
  validEmail,
  required,
  validEmailDomain,
  validateInviteEmail,
  alreadyExistsComplex,
  invalidEmailDomain,
} = validationRules;

const modelName = "inviteMembersModel";

class AddFormComponent extends Component {
  constructor(props) {
    super(props);

    this.getRemoveIcon = this.getRemoveIcon.bind(this);
    this.state = {
      email: auth.getUser().email,
      errors: [],
    };
    this.hasRemoveValidationForDomainFeature = auth.hasFeature(
      "remove_validation_for_domain_when_inviting_members",
    );
  }

  getRemoveIcon(index) {
    return (
      <a className="uk-link-text" onClick={this.props.removeEmailField.bind(this, index)}>
        <span data-uk-close={"close"} />
      </a>
    );
  }

  get validateInvitations() {
    return !this.props.form.valid && this.props.inviteMembersModel.emails.length === 0;
  }

  get emails() {
    const teamMembers = this.props.teamMembers.map((mail) => {
      return { email: mail.email };
    });
    return teamMembers.concat(this.props.inviteMembersModel.emails);
  }

  render() {
    const { emails } = this.props.inviteMembersModel;
    if (!this.props.visible) return null;
    return (
      <div className={"uk-padding-remove-vertical"}>
        <ul className={"disabled-list"}>
          {emails.map((invitation, index) => {
            return (
              <li key={index} className={"uk-inline gh-email-invitation-input"}>
                {invitation.email}{" "}
                {invitation.is_admin ? <b className={"font-weight-500"}>(admin)</b> : ""}{" "}
                {this.getRemoveIcon(index)}
              </li>
            );
          })}
        </ul>
        <Form model={"userEmailModel"} onSubmit={this.props.addEmailField}>
          <div data-uk-grid>
            <div className={"uk-width-2-3@s"}>
              <Control.text
                model={`.email`}
                changeAction={(model, value) => {
                  this.props.resetServerErrors();
                  return updateModel(model, value.trim());
                }}
                updateOn="change"
                placeholder={config.invitationPlaceholder}
                className={"uk-input uk-text-center"}
                validators={{
                  required,
                  validEmail,
                  invalidEmailDomain,
                  validateInviteEmail: validateInviteEmail(this.state.email),
                  alreadyExistsComplex: alreadyExistsComplex(this.emails),
                  validEmailDomain: validEmailDomain.bind(null, this.state.email),
                }}
              />
              <Errors
                model={`.email`}
                show="touched"
                wrapper={CustomValidateError}
                messages={{
                  validateInviteEmail: staticService.findByAlias("invitationValidationOwn"),
                  alreadyExistsComplex: staticService.findByAlias("invitationEmailDuplicate"),
                  validEmail: validationMessages().validEmailMessage,
                  invalidEmailDomain: validationMessages().invalidEmailDomainMessage,
                  required: validationMessages().requiredMessage(
                    staticService.findByAlias("emailAddress"),
                  ),
                  validEmailDomain: validationMessages().validEmailMessageDomain.bind(
                    null,
                    this.state.email,
                  ),
                }}
              />
              <ul className={"disabled-list validation-errors"}>
                {this.props.errors.map((err, index) => {
                  return (
                    <li
                      key={index}
                      className={"uk-inline"}
                      dangerouslySetInnerHTML={{ __html: staticService.findByAlias(err.error[0]) }}
                    />
                  );
                })}
              </ul>
            </div>
            <div className={"uk-width-1-3@s"}>
              <div>
                <label>
                  <Control.checkbox
                    className={"uk-checkbox uk-margin-small-right"}
                    model={".is_admin"}
                    changeAction={updateModel}
                    updateOn="change"
                  />{" "}
                  Is admin
                </label>
              </div>
            </div>
          </div>
          <div className={"uk-text-center"}>
            <button className={"gh-add-icon"}>
              <IconComponent
                visible={true}
                icon={"plus"}
                id={"icon-plus"}
                className={"uk-icon-button uk-button-primary"}
              />
            </button>
          </div>
        </Form>
        <div className="uk-margin-medium-top uk-text-center">
          <a className={"uk-button uk-inline"} onClick={this.props.closeModal}>
            <span dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cancel") }} />
          </a>
          <button
            type={"button"}
            onClick={this.props.submit.bind(this, modelName)}
            disabled={this.validateInvitations}
            className={"uk-button uk-button-primary uk-inline"}
          >
            <span
              dangerouslySetInnerHTML={{ __html: staticService.findByAlias("sendInvitations") }}
            />
          </button>
        </div>
      </div>
    );
  }
}

AddFormComponent.propTypes = {
  closeModal: PropTypes.func,
  submit: PropTypes.func.isRequired,
  resetServerErrors: PropTypes.func,
  addEmailField: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  removeEmailField: PropTypes.func,
  inviteMembersModel: PropTypes.object,
  errors: PropTypes.array,
  teamMembers: PropTypes.array,
  form: PropTypes.object,
  visible: PropTypes.bool,
  userEmailModel: PropTypes.shape({ email: PropTypes.string }),
};

export { AddFormComponent };
