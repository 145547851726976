import React, { useRef, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";

import "./AutocompleteInputHookForm.css";
import { LocationAutocompleteList, LocationAutocompleteWrapper } from "../../../common";
import staticService from "../../../../services/static.service";
import generateLocationAdditionalInfo from "../../../../utils/generateLocationAdditionalInfo";
import transformAdditionalInfo from "../../../../utils/transformAdditionalInfo";
import { isObjectEmpty } from "../../../../shared";
import googleApi from "../../../../services/google.service";
import getTextWidth from "../../../../utils/getTextWidth";
const calculateTextWidth = getTextWidth();
const AutocompleteInputHookForm = (props) => {
  const { placeholder, debounce, value, onChange, inputRef, coverageWarning, showCoverage, place } =
    props;
  const input = useRef();

  const [additionalInfoWidth, setAdditionalInfoWidth] = useState(0);

  const additionalInfo = useMemo(() => {
    if (value && !isObjectEmpty(value)) {
      const getAdditionalInfo = generateLocationAdditionalInfo(value);

      return transformAdditionalInfo(getAdditionalInfo);
    } else return "";
  }, [value]);

  const fillCoordinates = (address) => {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        props.onChange(address);
      });
    }
  };

  const generateAdditionalInfoWidth = () => {
    if (input.current && value.location) {
      const _input = input.current.querySelector("input");
      const getInputnputWidth = () => {
        const inputStyles = window.getComputedStyle(_input);
        const inputPadding =
          parseInt(inputStyles.getPropertyValue("padding-left")) +
          parseInt(inputStyles.getPropertyValue("padding-right"));

        return _input.offsetWidth - inputPadding;
      };
      const inputWidth = getInputnputWidth();
      const inputValueWidth = calculateTextWidth(value.location, _input);

      return inputWidth - inputValueWidth - 10;
    }

    return 0;
  };

  useEffect(() => {
    if (value && value.location) {
      const width = generateAdditionalInfoWidth();

      setAdditionalInfoWidth(width);
    }
  }, [value]);

  const onSelectLocation = (handleClickOutside, address) => {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      fillCoordinates(address);
    } else {
      props.onChange(address);
    }
  };

  return (
    <LocationAutocompleteWrapper place={place}>
      {({ locations, search, handleClickOutside, loader }) => {
        return (
          <div className="uk-position-relative">
            <div
              className="gh-hook-autocomplete-input gh-wrapper-autocomplete-input gh-location-input-wrapper"
              ref={input}
            >
              <DebounceInput
                type="search"
                value={value?.location ? value.location : value}
                className={["default-input-style uk-input"]}
                placeholder={placeholder ? placeholder : ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const input = e.nativeEvent.target;
                  onChange(value);
                  if (value.length >= 3) {
                    if (input) input.setAttribute("aria-expanded", "true");
                    return search(value);
                  }
                }}
                debounceTimeout={debounce || 500}
                inputRef={inputRef}
              />

              <div
                className="gh-autocomplete-info"
                style={{
                  maxWidth: additionalInfoWidth ? additionalInfoWidth : "45%",
                  textAlign: "end",
                  display: additionalInfoWidth < 0 ? "none" : "",
                }}
              >
                <span className="info-item">{additionalInfo}</span>
              </div>

              {coverageWarning && showCoverage && (
                <span
                  className="coverage-warning"
                  title={staticService.findByAlias("noHelicoptersInFleet")}
                >
                  {staticService.findByAlias("noHelicoptersInFleet")}
                </span>
              )}
              {loader ? (
                <div data-uk-spinner="ratio: 0.8" className="input-autocomplete-loader" />
              ) : (
                ""
              )}
            </div>
            <LocationAutocompleteList
              onSelect={(point) => onSelectLocation(handleClickOutside, point)}
              locations={locations}
            />
          </div>
        );
      }}
    </LocationAutocompleteWrapper>
  );
};

AutocompleteInputHookForm.propTypes = {
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  coverageWarning: PropTypes.bool,
  showCoverage: PropTypes.bool,
  place: PropTypes.string,
};

AutocompleteInputHookForm.defaultProps = {
  showCoverage: false,
  place: "",
};

export default AutocompleteInputHookForm;
