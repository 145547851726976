import PropTypes from "prop-types";
import React from "react";
import PricingPlanAlert from "./PricingPlanAlert";

import "./styles/index.css";

export default function PricingPlanCommission(props) {
  const { commission } = props;

  return (
    <div className="pricing-plan-form">
      <div className="uk-margin-small-bottom">
        <label className="uk-form-label" htmlFor="pricing_plan">
          Pricing Plan
        </label>
        <input
          name="pricing_plan"
          id="pricing_plan"
          value="Commission-based"
          placeholder="Pricing plan name"
          className="uk-input"
          disabled
        />
      </div>
      <div>
        <label className="uk-form-label" htmlFor="last_name">
          Commission
        </label>
        <input
          name="last_name"
          id="last_name"
          value={commission + "%"}
          placeholder="0%"
          className="uk-input"
          disabled
        />
      </div>
      <PricingPlanAlert />
    </div>
  );
}

PricingPlanCommission.propTypes = {
  commission: PropTypes.number,
};
