import React from "react";
import PropTypes from "prop-types";
import { AccountOnlineLayout } from "../../../../layouts";
import { PriceCalculator } from "../../../../../packages";

import "./OperatorPriceCalculator.css";
import auth from "../../../../../services/auth";
import apiHandler from "../../../../../services/api-handler";
import { connect } from "react-redux";

async function downloadCalculator(data) {
  apiHandler
    .setPath("price-calculator/download-excel")
    .post(data)
    .then((res) => {
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `price_calculator.xls`);
      document.body.appendChild(link);
      link.click();

      return res;
    });
}

const OperatorPriceCalculator = (props) => {
  const { match, banking } = props;

  const currency = React.useMemo(() => auth.getUser().banking.currency, []);

  const onCalculatorSubmit = React.useCallback(
    () => async (data) => {
      try {
        const response = await downloadCalculator({
          ...data,
          currency: banking?.currency || data.currency,
        });

        return Promise.resolve(response);
      } catch (error) {
        return Promise.resolve();
      }
    },
    [banking],
  );

  return (
    <AccountOnlineLayout activeRoute={match} pageTitle={"Price Calculator"}>
      <section className="uk-section">
        <div className="uk-container">
          <h2 className="gh-text-slim">Price Calculator</h2>

          <div className="gh-operator-price-calculator">
            <PriceCalculator
              demoVersion
              currency={currency}
              banking={auth.getUser().banking}
              onFinish={onCalculatorSubmit}
            />
          </div>
        </div>
      </section>
    </AccountOnlineLayout>
  );
};

OperatorPriceCalculator.propTypes = {
  match: PropTypes.object,
  banking: PropTypes.object,
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(OperatorPriceCalculator);
