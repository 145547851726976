import React from "react";
import PropTypes from "prop-types";
import { AccountNavWrapper } from "./account-nav-wrapper.component";

const AccountHeader = (props) => {
  document.querySelector("html").removeAttribute("class");
  document.querySelector("html").removeAttribute("style");
  return (
    <header className={props.className || "gh-nav"}>
      <AccountNavWrapper
        activeRoute={props.activeRoute}
        extraProps={props.extraProps}
        pages={props.pages}
      />
      <div className="uk-navbar-dropbar uk-navbar-dropbar-slide" />
      {props.children}
    </header>
  );
};

AccountHeader.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pages: PropTypes.array,
  extraProps: PropTypes.object,
  className: PropTypes.string,
};

export { AccountHeader };
