import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBookingInfo } from "../../../../../actions";
import { bindActionCreators } from "redux";
import ViewFullBookingDetails from "../../../ViewFullBookingDetails/viewFullBookingDetails";

const BookingShowComponent = (props) => {
  const { match, getBookingInfo, history, clearQuoteDetail } = props;
  const bookingID = match.params.id;

  /*===========Lifecycles===========*/
  useEffect(() => {
    if (bookingID) {
      getBookingInfo(bookingID).catch(() => history.push("/not-found"));
    }

    return () => {
      clearQuoteDetail();
    };
  }, [bookingID]);

  return <ViewFullBookingDetails history={history} match={match} />;
};

BookingShowComponent.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  getBookingInfo: PropTypes.func,
  clearQuoteDetail: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBookingInfo,
      clearQuoteDetail: () => dispatch({ type: "REMOVE_BOOKING_DETAIL" }),
    },
    dispatch,
  );
};

const COMPONENT = connect(null, mapDispatchToProps)(BookingShowComponent);
export { COMPONENT as BookingShowComponent };
