import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateIdentifier } from "../../../../../actions";
import Api from "../../../../../services/api-handler";
import staticService from "../../../../../services/static.service";
import InlineEditWrapper from "../../../../inlineEditInput/inlineEditWrapper";
import apiHandler from "../../../../../services/api-handler";
import PhoneInput from "../../../../common/phoneInput";

const ReviewPrivateNotesComponent = ({ requestForm, onUpdateRequest, updateIdentifier }) => {
  const [clientReference, setClientReference] = React.useState({});
  const requestHasToExpire = requestForm.has_to_expire;

  React.useEffect(() => {
    const fetchReference = async (id) => {
      const res = await apiHandler.setPath("clients").get(id);

      if (res) {
        setClientReference(res);
      }
    };

    if (requestForm && requestForm.client_id) {
      fetchReference(requestForm.client_id);
    }
  }, [requestForm]);

  const updatePrivateNotes = (value) => {
    return Api.setProperty("skipErrorAlert", true)
      .setPath(`add-private-note/booking_request/${requestForm.id}`, "")
      .post({
        private_notes: value,
      })
      .then((res) => onUpdateRequest(res, requestForm.id));
  };

  return (
    <div className="gh-simple-section review-notes">
      <h4
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("privateNotes"),
        }}
      />
      <div data-uk-grid>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("notes"),
              }}
            />
            <InlineEditWrapper
              disabled={!onUpdateRequest || requestHasToExpire}
              action={updatePrivateNotes}
              defaultValue={requestForm.private_notes || ""}
            >
              <div className="gh-info-text">
                {requestForm.private_notes ? requestForm.private_notes : "---"}
              </div>
            </InlineEditWrapper>
          </div>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <div className="gh-section">
            <h6
              className="gh-field-label"
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("clientIdentifier"),
              }}
            />
            <div className="gh-info-text">
              {clientReference.client_identifier ? clientReference.client_identifier : "---"}
            </div>
          </div>
        </div>
      </div>
      {clientReference.client_identifier && (
        <div data-uk-grid className="uk-form-controls uk-margin-top">
          <div className="uk-width-1-1 uk-width-1-2@s">
            <label className="uk-form-label">Client First Name</label>
            <input value={clientReference.first_name || "---"} className="uk-input" disabled />
          </div>
          <div className="uk-width-1-1 uk-width-1-2@s">
            <label className="uk-form-label">Client Last Name</label>
            <input value={clientReference.last_name || "---"} className="uk-input" disabled />
          </div>
          <div className="uk-width-1-1 uk-width-1-2@s uk-margin-top">
            <label className="uk-form-label">Client Email Address</label>
            <input value={clientReference.email || "---"} className="uk-input" disabled />
          </div>
          <div className="uk-width-1-1 uk-width-1-2@s uk-margin-top">
            <label className="uk-form-label">Client Phone Number</label>
            <PhoneInput value={clientReference.phone} disabled />
          </div>
        </div>
      )}
    </div>
  );
};

ReviewPrivateNotesComponent.propTypes = {
  requestForm: PropTypes.object,
  onUpdateRequest: PropTypes.func,
  updateIdentifier: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateIdentifier,
    },
    dispatch,
  );
};

const COMPONENT = connect(null, mapDispatchToProps)(ReviewPrivateNotesComponent);

export { COMPONENT as ReviewPrivateNotesComponent };
