import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Control, Form, Errors, actions } from "react-redux-form";
import { subscribe } from "../../actions";
import { validationRules, validationMessages } from "../../configs";
import { IconComponent, updateModel, CustomValidateError, alertInstance } from "../../shared";
import { RadioGroupComponent } from "./fieldsets/radio-group.component";
import staticService from "../../services/static.service";

const { required, validEmail } = validationRules;

class SubscribeFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess() {
    const type = this.props.type;
    this.props.actions.resetModel("subscribeModel");
    this.props.actions.updateModel("subscribeModel.type", type);
  }

  onError(err) {
    if (err.data.isSubscribed) {
      alertInstance.show("Subscribed already!", alertInstance.ERROR);
    }
  }

  get radioItems() {
    return [
      {
        value: "operator",
        label: "I am an Operator",
      },
      {
        value: "broker",
        label: "I am a Broker",
      },
    ];
  }

  render() {
    if (this.props.visible) {
      return (
        <Form
          model={"subscribeModel"}
          className={"uk-form-stacked"}
          validators={{
            email: { required, validEmail },
            first_name: { required },
            last_name: { required },
          }}
          onSubmit={this.props.actions.subscribe.bind(null, this.onSuccess, this.onError)}
        >
          <div className={"uk-position-relative"}>
            <div className="uk-margin-small-bottom">
              <label>First name</label>
              <div>
                <Control.text
                  className={"uk-input uk-input-default"}
                  model={".first_name"}
                  changeAction={updateModel}
                  updateOn="change"
                />
                <Errors
                  model=".first_name"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(null, "First name"),
                  }}
                />
              </div>
            </div>
            <div className="uk-margin-small-bottom">
              <label>Last name</label>
              <div>
                <Control.text
                  className={"uk-input uk-input-default"}
                  model={".last_name"}
                  changeAction={updateModel}
                  updateOn="change"
                />
                <Errors
                  model=".last_name"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(null, "Last name"),
                  }}
                />
              </div>
            </div>
            <div className="uk-margin-small-bottom">
              <label>Email</label>
              <div>
                <div className={"uk-position-relative"}>
                  <IconComponent visible={true} icon={"mail"} id={"icon-mail"} />
                  <Control.text
                    className={"uk-input uk-input-default"}
                    model={".email"}
                    type={"email"}
                    changeAction={updateModel}
                    updateOn="change"
                  />
                </div>

                <Errors
                  model=".email"
                  show="touched"
                  wrapper={CustomValidateError}
                  messages={{
                    required: validationMessages().requiredMessage.bind(null, "Email"),
                    validEmail: validationMessages().validEmailMessage,
                  }}
                />
              </div>
            </div>

            {this.props.showRadio ? (
              <div className="uk-margin-small-bottom">
                <label>{staticService.findByAlias("subscribeQuestion")}</label>
                <div>
                  <RadioGroupComponent
                    items={this.radioItems}
                    model={".type"}
                    defaultValue={this.props.type}
                    parentModel={"subscribeModel"}
                    updateModelDispatch={this.props.actions.updateModel}
                  />
                </div>
              </div>
            ) : null}
            <div className={"uk-text-center uk-margin-medium-bottom uk-margin-medium-top"}>
              <Control.button
                model={"subscribeModel"}
                className={"uk-button uk-button-primary gh-button-responsive"}
              >
                {staticService.findByAlias("submit")}
              </Control.button>
            </div>
          </div>
        </Form>
      );
    }
    return null;
  }
}

SubscribeFormComponent.propTypes = {
  visible: PropTypes.bool,
  showRadio: PropTypes.bool,
};

SubscribeFormComponent.contextTypes = {
  store: PropTypes.object,
};

const mapStateToProps = ({ subscribeModel }) => {
  return {
    ...subscribeModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        subscribe: subscribe,
        updateModel: (model, value) => dispatch(actions.change(model, value)),
        resetModel: (model) => dispatch(actions.reset(model)),
      },
      dispatch,
    ),
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(SubscribeFormComponent);
export { COMPONENT as SubscribeFormComponent };
