import React from "react";
import PropTypes from "prop-types";
import { OverlayPopup } from "../../shared/overlay/overlay-popup";
import staticService from "../../services/static.service";

const overlayFullStyles = {
  overlay: {
    background: "#f2f4f7",
  },
  header: {
    height: "auto",
  },
  title: {
    color: "#fff",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    background: "#242f42",
  },
  container: {
    background: "#f2f4f7",
    height: "inherit",
  },
};

const overlayDefaultStyles = {
  overlay: {
    background: "#f2f4f7",
    width: "90%",
    margin: "auto",
  },
  header: {
    height: "auto",
  },
  title: {
    color: "#fff",
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    background: "#242f42",
  },
  container: {
    background: "#f2f4f7",
    height: "inherit",
  },
};

export const TacComponent = (props) => {
  const {
    html,
    termsType,
    agreeCallback,
    visible = true,
    classNames = "full",
    isFull = true,
    downloadCallback = null,
    downloadLabel,
    cancelLabel,
    acceptLabel,
    disableAcceptButton,
    flightTerms,
    showOnlyButtons,
  } = props;

  const overlayStyles = isFull ? overlayFullStyles : overlayDefaultStyles;
  const headingClass = isFull ? "" : " gh-navbar-item-in-page";
  const downloadBtnStyle = { display: downloadCallback ? "" : "none" };
  if (visible && isFull) {
    document.body.classList.add("gh-overlay-none");
    document.body.classList.remove("gh-overlay-auto");
  } else {
    document.body.classList.add("gh-overlay-auto");
    document.body.classList.remove("gh-overlay-none");
  }
  return (
    <OverlayPopup isOpen={visible} styles={overlayStyles.overlay} classNames={classNames}>
      {visible && (
        <div style={overlayStyles.container}>
          {!showOnlyButtons ? (
            <div>
              {" "}
              <div data-uk-header className="gh-dashboard-nav" style={overlayStyles.header}>
                <nav data-uk-navbar className={`uk-navbar-container nav${headingClass} `}>
                  <div className="uk-navbar-left">
                    {termsType === "api" ? (
                      <span
                        style={overlayStyles.title}
                        className={`uk-navbar-item uk-logo${headingClass}`}
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("apiAgreement"),
                        }}
                      />
                    ) : (
                      <span
                        style={overlayStyles.title}
                        className={`uk-navbar-item uk-logo${headingClass}`}
                        dangerouslySetInnerHTML={{
                          __html: flightTerms || staticService.findByAlias("agreement"),
                        }}
                      />
                    )}
                  </div>
                </nav>
              </div>
              <div
                data-uk-body
                className={"uk-padding tac-body gh-container"}
                style={overlayStyles.bodyContainer}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          ) : (
            ""
          )}

          <div
            style={overlayStyles.footer}
            className={"uk-flex uk-flex-around overlay-footer mobile"}
          >
            <div style={downloadBtnStyle} className={"uk-visible@s"}>
              <button
                onClick={downloadCallback}
                className={"uk-button uk-button-primary"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(downloadLabel),
                }}
                target={"_blank"}
              />
            </div>

            <div className={"uk-visible@s"}>
              <button
                onClick={agreeCallback.bind(this, 0)}
                className={"uk-button uk-button-default"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(cancelLabel),
                }}
              />
            </div>
            {!disableAcceptButton && (
              <div className={"uk-visible@s"}>
                <button
                  onClick={agreeCallback.bind(this, 1)}
                  className={"uk-button uk-button-primary"}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(acceptLabel),
                  }}
                />
              </div>
            )}
            <div className="uk-hidden@s " style={downloadBtnStyle}>
              <button
                onClick={downloadCallback}
                className={"uk-button mob-btn uk-button-primary uk-margin-small-top"}
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(downloadLabel),
                }}
                target={"_blank"}
              />
            </div>
            <div className={"uk-hidden@s"}>
              <button
                onClick={agreeCallback.bind(this, 0)}
                className={
                  "uk-button uk-button-default mob-btn uk-margin-small-top uk-margin-small-bottom"
                }
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(cancelLabel),
                }}
              />
            </div>
            {!disableAcceptButton && (
              <div className={"uk-hidden@s"}>
                <button
                  onClick={agreeCallback.bind(this, 1)}
                  className={
                    "uk-button uk-button-primary mob-btn uk-margin-small-top uk-margin-small-bottom"
                  }
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias(acceptLabel),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </OverlayPopup>
  );
};

TacComponent.propTypes = {
  classNames: PropTypes.string,
  html: PropTypes.string.isRequired,
  agreeCallback: PropTypes.func.isRequired,
  downloadCallback: PropTypes.func,
  visible: PropTypes.bool,
  isFull: PropTypes.bool,
  downloadLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  acceptLabel: PropTypes.string,
  disableAcceptButton: PropTypes.bool,
  termsType: PropTypes.string,
  flightTerms: PropTypes.string,
  showOnlyButtons: PropTypes.bool,
};

TacComponent.defaultProps = {
  downloadLabel: "viewAgreement",
  cancelLabel: "disagree",
  acceptLabel: "agree",
  disableAcceptButton: false,
  showOnlyButtons: false,
};
