import React from "react";
import staticService from "../../../../../../services/static.service";
import FlightInlineView from "../../../../../common/FlightInlineView";
import { usePipelineViewContext } from "../PipelineViewContext";

export default function PipelineViewEnquiry() {
  const { pipeline } = usePipelineViewContext();

  return (
    <div className="pipeline-view-tab">
      <div className="pipeline-view-box">
        <FlightInlineView
          adults={pipeline.adults}
          childs={pipeline.children}
          luggage={pipeline.luggage}
          fromLocation={pipeline.from_location}
          fromFallback={pipeline.from}
          toLocation={pipeline.to_location}
          toFallback={pipeline.to}
          date={pipeline.departure_at}
          time={pipeline.departure_at}
          pax={pipeline.passengers}
          direction={
            pipeline.return_at
              ? staticService.findByAlias("outbound")
              : staticService.findByAlias("oneWay")
          }
          dateLabel={staticService.findByAlias("dateOfDeparture")}
          timeLabel={staticService.findByAlias("timeOfDeparture")}
        />
      </div>
      {pipeline.return_at && (
        <div className="pipeline-view-box">
          <FlightInlineView
            adults={pipeline.adults}
            childs={pipeline.children}
            luggage={pipeline.luggage}
            fromLocation={pipeline.to_location}
            fromFallback={pipeline.to}
            toLocation={pipeline.from_location}
            toFallback={pipeline.from}
            date={pipeline.return_at}
            time={pipeline.return_at}
            pax={pipeline.passengers}
            direction={staticService.findByAlias("inbound")}
            dateLabel={staticService.findByAlias("dateOfReturn")}
            timeLabel={staticService.findByAlias("timeOfReturn")}
          />
        </div>
      )}
      <div className="pipeline-view-box">
        <span className="uk-form-label">
          {staticService.findByAlias("additionalInformationPipelineTitle")}
        </span>
        <div className="gh-section">
          <div
            className="gh-info-text"
            dangerouslySetInnerHTML={{ __html: pipeline.additional_information || "---" }}
          />
        </div>
      </div>
    </div>
  );
}
