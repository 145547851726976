import handlerError from "./error";
import Api from "../services/api-handler";
import * as types from "./actionTypes";

const entity = "audit";
const flightsEntity = "booking";

export function loadActivityFlightsSuccess(activityFlights) {
  return { type: types.LOAD_ACTIVITY_FLIGHTS, activityFlights };
}

export function loadPaginationActivityFlightsSuccess(activityFlights) {
  return { type: types.LOAD_ACTIVITY_FLIGHTS_WITH_PAGINATION, activityFlights };
}

export function loadActivityFeedSuccess(activityFeed) {
  return { type: types.LOAD_ACTIVITY_FEED, activityFeed };
}

export function getMetaData(meta) {
  return { type: types.GET_META, meta };
}

export function getMeta(page) {
  return function (dispatch) {
    return Api.setPath("unauth-pages", page)
      .getAll()
      .then((res) => {
        return dispatch(getMetaData(res));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function loadActivityFeed() {
  return function (dispatch) {
    return Api.setPath(entity)
      .setQueryParams()
      .getAll()
      .then((activityFeed) => {
        return dispatch(loadActivityFeedSuccess(activityFeed));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getActivityFlightsByDate(range) {
  return function (dispatch) {
    return Api.setPath(flightsEntity)
      .setQueryParams(range, true)
      .getAll()
      .then((activityFeed) => {
        Api.setQueryParams([]);
        return dispatch(loadActivityFlightsSuccess(activityFeed));
      })
      .catch((err) => handlerError(err, dispatch));
  };
}
