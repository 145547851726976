import React from "react";
import moment from "moment";
import { Item } from "../../../../shared/list";
import staticService from "../../../../services/static.service";
import { utcTime } from "../../../../shared";
import { useAccountLocalization } from "../../../../context";

export const CommentRowComponent = ({
  posted,
  user,
  content,
  total,
  isBold = false,
  isBoldLabel = "",
  index = 0,
  borderWidth = "1px",
  legs,
  sectionForBroker,
}) => {
  const accountLocalization = useAccountLocalization();
  const date = new Date(posted);
  const border = index > 0 && index < total ? borderWidth + " solid #e5e5e5" : "none";
  const formattedContent = () => {
    if (content !== "" && content !== null) {
      if (typeof content === "string") {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
      }
      return <div>{content}</div>;
    }
  };
  let existPrivate = false;
  legs &&
    legs.map((leg) => {
      if (leg.from_details || leg.to_details) {
        existPrivate = true;
      }
    });
  if ((existPrivate && legs !== undefined) || (content !== "" && content !== null)) {
    return (
      <div key={index} style={{ borderTop: border, paddingTop: "15px", paddingBottom: "15px" }}>
        <Item>
          <div data-uk-grid>
            <div className="uk-width-1-4 gh-comment-author">
              <span className="gh-audit-author">{user}</span>
            </div>
            <div className="uk-flex-1 uk-flex-column">
              {formattedContent()}
              {sectionForBroker &&
                legs.map((leg, index) => (
                  <div key={`key-${index}`}>
                    {leg.from_details && (
                      <div>
                        {leg.from_details} -{" "}
                        <span>
                          {staticService.findByAlias("furtherSiteInformation")},{" "}
                          {staticService.findByAlias("departure")}, Leg {index + 1}
                        </span>
                      </div>
                    )}
                    {leg.to_details && (
                      <div>
                        {leg.to_details} -{" "}
                        <span>
                          {staticService.findByAlias("furtherSiteInformation")},{" "}
                          {staticService.findByAlias("destination")}, Leg {index + 1}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            {isBold ? (
              <div>
                <b>{isBoldLabel}</b>{" "}
                <span className={"small-font"}>
                  ({moment(date).format("DD MMMM, YYYY")} |{" "}
                  {moment(date).format(accountLocalization.timeFormat) + utcTime()})
                </span>
              </div>
            ) : (
              <div>
                {moment(date).format("DD MMMM, YYYY")} |{" "}
                {moment(date).format(accountLocalization.timeFormat) + utcTime()}
              </div>
            )}
          </div>
        </Item>
      </div>
    );
  }
  return null;
};
