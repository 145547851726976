import PropTypes from "prop-types";
import React from "react";
import UploadFile from "./UploadFile";
import UploadFilesView from "./UploadFilesView";
import condStrings from "../../../../utils/condString";
import uid from "../../../../utils/uid";

import "./Upload.styles.css";
import { showNotification } from "../../../../utils/showNotification";

function Upload(props) {
  const { className, disabled, accept, defaultFileList, onChange, limit, readOnly, multiple } =
    props;
  const [filesList, setFilesList] = React.useState(defaultFileList);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [globalErrors, setGlobalErrors] = React.useState(null);

  React.useEffect(() => {
    if (defaultFileList) {
      defaultFileList.find((file) => {
        if (file.error) {
          setGlobalErrors(file.error);
        }
      });
    }
  }, [defaultFileList]);

  const fileInputRef = React.useRef();

  const openSelectFilesDialog = () => {
    fileInputRef.current.click();
  };

  const onFileInputChange = (event) => {
    const files = event.target.files;
    const processedFiles = [];

    for (let i = 0; i < files.length; i++) {
      processedFiles.push({
        uid: uid().uid,
        file_name: files[i].name,
        file_type: files[i].type,
        file_data_local: URL.createObjectURL(files[i]),
        originFileObj: files[i],
      });
    }

    setFilesList((prev) => [...prev, ...processedFiles]);

    fileInputRef.current.value = "";
  };

  const updateFile = React.useCallback(
    (file) => {
      const newFilesList = filesList.map((f) => {
        if (f.uid === file.uid) {
          return file;
        }

        return f;
      });

      setFilesList(newFilesList);
      onChange(newFilesList);
    },
    [onChange, filesList],
  );

  const onFileRemoved = React.useCallback(
    (file) => {
      const newFilesList = filesList.filter((f) => {
        if (f.id && file.id) {
          return f.id !== file.id;
        }

        return f.uid !== file.uid;
      });

      setFilesList(newFilesList);
      onChange(newFilesList);
    },
    [onChange, filesList],
  );

  const dragleave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
  };

  const dragenter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(true);
  };

  const dragover = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const drop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);

    const dt = e.dataTransfer;
    const files = dt.files;

    if (files.length > 1) {
      showNotification("You can only upload one file at a time.", "warning");
    } else {
      onFileInputChange({ target: { files } });
    }
  };

  return (
    <React.Fragment>
      <div className={condStrings("upload", className)}>
        <div className="upload__files">
          <UploadFilesView>
            {filesList.map((file) => (
              <UploadFile
                key={file.uid || file.id}
                updateFile={updateFile}
                onRemoveSuccess={onFileRemoved}
                setGlobalErrors={setGlobalErrors}
                file={file}
                readOnly={readOnly}
              />
            ))}
          </UploadFilesView>
        </div>
        {!readOnly && (
          <React.Fragment>
            <div
              className={condStrings(
                "upload__select",
                (disabled || limit <= filesList.length) && "is-disabled",
                isDragOver && "is-dragover",
              )}
              onDragLeave={dragleave}
              onDragEnter={dragenter}
              onDragOver={dragover}
              onDrop={drop}
            >
              <div className="upload__select-text">
                <span>Drag Files here</span>
                <span className="upload__select-text-delimiter">&nbsp;or&nbsp;</span>
              </div>
              <button type="button" className="upload__select-btn" onClick={openSelectFilesDialog}>
                Upload Files
              </button>
            </div>
            <span className="upload__description">
              Files formats allowed: {accept.replaceAll(".", "").split(",").join(", ")}
            </span>
            {globalErrors && (
              <div className="upload__errors">
                <span>{globalErrors}</span>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {!readOnly && (
        <input
          ref={fileInputRef}
          type="file"
          className="upload__select-file-input"
          accept={accept}
          multiple={multiple}
          onChange={onFileInputChange}
        />
      )}
    </React.Fragment>
  );
}

Upload.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  accept: PropTypes.string,
  limit: PropTypes.number,
  defaultFileList: PropTypes.array,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
};

Upload.defaultProps = {
  accept: "*/*",
  limit: Infinity,
  defaultFileList: [],
};

export default React.memo(Upload);
