import React from "react";
import PropTypes from "prop-types";
import "./LoadingComponent.css";
import Spinner from "../Spinner";
import { CSSTransition } from "react-transition-group";
import condString from "../../../utils/condString";

const LoadingComponent = ({ children, show, className, unmountOnExit }) => {
  return (
    <div className={condString("gh-loading-component", className)}>
      <Spinner width="40px" show={!show} className="gh-loading-component-spinner" />
      <CSSTransition
        in={show}
        timeout={300}
        unmountOnExit={unmountOnExit}
        classNames="gh-animation-loading-component"
      >
        {children}
      </CSSTransition>
    </div>
  );
};

LoadingComponent.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  className: PropTypes.string,
  unmountOnExit: PropTypes.bool,
};
LoadingComponent.defaultProps = { unmountOnExit: true };
export default LoadingComponent;
