import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import ExternalAppSettingsForm from "./components/ExternalAppSettingsForm";
import Api from "../../../../../services/api-handler";
import { ModalComponent, storage, TabComponent } from "../../../../../shared";
import ExternalAppRulesForm from "./components/ExternalAppRulesForm";
import { AccountOnlineLayout } from "../../../../layouts";
import { BlockComponent } from "../../../../common";
import { ComponentAuthorization } from "../../../../ComponentAuthorization";
import { IsVerifiedAuthorization } from "../../../../IsVerifiedAuthorization";
import Auth from "../../../../../services/auth";
import { accountTabs } from "../../../../../configs";

const ExternalAppSettings = (props) => {
  const { match, title, path } = props;

  const [formValues, setFormValues] = useState([]);
  const [appRules, setAppRules] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [appDomain, setAppDomain] = useState("");
  const tabs = accountTabs.filter(
    (tab) => tab.permission === "both" || tab.permission === Auth.getRole(),
  );
  const selectedTab = useMemo(() => tabs.filter((item) => item.path === path), [tabs]);

  const previewImageModal = useRef();
  const addExternalRuleModal = useRef();

  useEffect(() => {
    Api.setPath("get-internal-configuration")
      .get("")
      .then((res) => {
        setApiKey(res.api_key);
        setAppDomain(res.domain);
        setAppRules(res.rules);
        setFormValues(res.form_data);
      });
  }, []);

  const requestButtonProps = () => {
    storage.delete("request_form");
    return {
      isButton: false,
      path: "/new-request",
      actionTitle: staticService.findByAlias("requestPageQuote"),
      classNames: [
        "uk-button",
        "gh-req-button",
        "uk-align-center",
        "uk-margin-remove-top",
        "uk-width-1-6",
        "uk-padding-remove",
        "uk-margin-small-top",
      ],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : 0,
    };
  };

  const handlePreviewFile = (file) => {
    const fileUrl = typeof file === "string" ? file : file.file_data;
    setSelectedFile(fileUrl);
    previewImageModal.current.open();
  };

  const modalContent = () => {
    if (!selectedFile) return "";

    return <img src={selectedFile} alt="" />;
  };

  const handleOpenRuleModal = () => {
    addExternalRuleModal.current.open();
  };

  const handleCloseRuleModal = () => {
    addExternalRuleModal.current.hide();
  };

  return (
    <AccountOnlineLayout activeRoute={match} pageTitle={title}>
      <section className={"team-page"}>
        <BlockComponent
          visible={true}
          parentProps={{
            className: "uk-section uk-padding-remove-bottom header-title",
          }}
        >
          <div className="uk-width-1-1">
            <h3
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("account"),
              }}
            />
          </div>
          <div className="uk-width-1-1">
            <ComponentAuthorization
              component={IsVerifiedAuthorization}
              componentProps={requestButtonProps()}
              roles={["broker_admin", "broker_team", "sole_trader"]}
            />
          </div>
        </BlockComponent>
        <BlockComponent
          visible={true}
          parentProps={{ className: ["uk-section", "uk-padding-remove-top"].join(" ") }}
        >
          <TabComponent
            items={tabs}
            id={"profile-tab"}
            visible={true}
            tabProps={{ className: "uk-tab uk-margin" }}
            selected={selectedTab[0]}
            linkType={"nav"}
          />
          <div data-uk-grid>
            <div className="uk-width-1-1@s uk-width-1-3@m uk-visible@m">
              <div className="uk-width-3-4 uk-margin-small-top" />
            </div>
            <div className="uk-margin-top uk-width-3-1@s uk-width-2-3@m">
              <h2 className={"gh-text-slim uk-margin-remove-bottom"}>
                {staticService.findByAlias("externalAppSettingsTitle")}
              </h2>

              <ExternalAppSettingsForm
                formValues={formValues}
                handlePreviewFile={handlePreviewFile}
                appRules={appRules}
                handleOpenRuleModal={handleOpenRuleModal}
                apiKey={apiKey}
                appDomain={appDomain}
              />

              <ModalComponent
                title={""}
                ref={previewImageModal}
                id="preview-file-app-settings"
                modalClass="gh-preview-modal"
                options={{ clsPage: "", bgClose: false, escClose: false }}
              >
                <div className="gh-preview-file">{modalContent()}</div>
              </ModalComponent>

              <ModalComponent
                title={"Add external rule"}
                ref={addExternalRuleModal}
                id="addExternalRuleModal"
                modalClass="gh-preview-modal"
                options={{ clsPage: "", bgClose: false, escClose: false }}
              >
                <ExternalAppRulesForm
                  setAppRules={setAppRules}
                  handleCloseRuleModal={handleCloseRuleModal}
                />
              </ModalComponent>
            </div>
          </div>
        </BlockComponent>
      </section>
    </AccountOnlineLayout>
  );
};

ExternalAppSettings.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
  path: PropTypes.string,
};

export default ExternalAppSettings;
