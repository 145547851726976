import React from "react";
import Api from "../../../../../../services/api-handler";
import { showNotification } from "../../../../../../utils/showNotification";
import { config, ERROR_500 } from "../../../../../../configs";
import {
  convertMinutesToTimeUnits,
  timeUnitsApplyPluralSingularShort,
  timeUnitsSingularPluralShort,
} from "../../../../../../utils/convertMinutesToTimeUnits";
import clsx from "clsx";

const useOperatorMarketAxis = ({ requestId, requestStatus }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [actionsList, setActionsList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const onClick = async () => {
    setLoading(true);
    const actions = await getApiData(requestId, requestStatus);
    setLoading(false);

    if (!actions) {
      return showNotification(ERROR_500, "danger");
    }
    if (!actions.length) {
      return showNotification("there are no actions for this quote");
    }
    transformActionObject(actions);
    setActionsList(actions);
    openModal();
  };

  return { closeModal, onClick, isModalOpen, loading, actionsList };
};
export default useOperatorMarketAxis;

/*===========Helper functions===========*/
const getApiData = (requestId, requestStatus) => {
  const path =
    requestStatus === "pre_quoted"
      ? `booking-requests/progress-information/${requestId}/?status=pre_quotes`
      : `booking-requests/progress-information/${requestId}`;

  return Api.setProperty("skipErrorAlert", true)
    .setQueryParams({})
    .setPath(path)
    .getAll()
    .then(({ data }) => data)
    .catch(() => null);
};

const transformActionObject = (actions) => {
  actions.map((action) => {
    const isUserActionsOperator = action.operator === "You";

    //add % to value, if the value is 0, the bar color is set to gray
    action.price_percentage = formatPricePercentage(action.price_percentage);

    //add new field highlighted to action object
    action.highlighted = isUserActionsOperator;

    //response time tag color determination, value converting
    action.response_time_className = clsx({
      "gh-axis-tag-yellow": action.response_time > 35 && action.response_time < 2880,
      "gh-axis-tag-red": action.response_time >= 2880,
    });
    action.response_time_converted = formatMinutes(action.response_time);

    // if the operator is the user, show broker last seen instead of operator status
    const brokerLastSeen = action.broker_last_seen || null;
    const statusSeenTitle = isUserActionsOperator ? "Broker Last Seen" : "Stage";
    const statusSeenValue = isUserActionsOperator ? brokerLastSeen : transformStatus(action.status);

    action.statusSeenTitle = statusSeenTitle;
    action.statusSeenValue = statusSeenValue;
    action.isUserActionsOperator = isUserActionsOperator;
  });
};

const formatPricePercentage = (price_percentage) => {
  return price_percentage < 50
    ? `calc(${price_percentage}% + 15px - 10px)`
    : `calc(${price_percentage}% - 15px - 10px)`;
};
const formatMinutes = (minutesParam) => {
  if (minutesParam === 0) return `0 ${timeUnitsSingularPluralShort.minutes.singular}`;

  const { weeks, days, hours, minutes } = convertMinutesToTimeUnits(minutesParam);
  // return greatest time unit that is not 0
  return weeks
    ? timeUnitsApplyPluralSingularShort(weeks, "weeks")
    : days
    ? timeUnitsApplyPluralSingularShort(days, "days")
    : hours
    ? timeUnitsApplyPluralSingularShort(hours, "hours")
    : timeUnitsApplyPluralSingularShort(minutes, "minutes");
};

const transformStatus = (status) => {
  // overwrite status name
  const newStatuses = { ...config.quoteStatusObject, open: "New Quote Submitted" };
  return newStatuses[status] || status;
};
