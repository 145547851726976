import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import "./alternativeBtn.css";

const AlternativeBtn = (props) => {
  const { text, handleClick } = props;

  return (
    <div className="gh-alternative-btn btn-style" onClick={handleClick}>
      {text ? text : `${staticService.findByAlias("suggestAlternative")}`}
    </div>
  );
};

AlternativeBtn.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

export default AlternativeBtn;
