import * as types from "../../actions/actionTypes";

function credentials(state = [], action) {
  switch (action.type) {
    case types.GET_CREDENTIALS:
      return [...action.credentials];
    default:
      return state;
  }
}

export { credentials };
