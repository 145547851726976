import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessageHookForm.css";
import ErrorMessageComponent from "../ErrorMessageComponent";

const ErrorMessageHookForm = (props) => {
  const { errors, notShowKeys } = props;
  if (!errors || !Object.keys(errors)) return "";

  return (
    <div className="gh-error-messages-wrapper">
      {Object.entries(errors).map(
        ([key, value], index) =>
          value.message &&
          !notShowKeys.includes(key) && (
            <ErrorMessageComponent key={index}>{value.message}</ErrorMessageComponent>
          ),
      )}
    </div>
  );
};
ErrorMessageHookForm.propTypes = {
  // errors: errors object from React Hook Form
  errors: PropTypes.object.isRequired,
  // notShowKeys: list of key that you want to ignore or don't show
  notShowKeys: PropTypes.arrayOf(PropTypes.string),
};
export default ErrorMessageHookForm;
