import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { BlockComponent } from "../../../../common";
import { getInvitations, getInvitationsSuccess, RESET_MEMBER_REMOVE } from "../../../../../actions";
import { config, invitationConfig } from "../../../../../configs";
import { PaginationService } from "../../../../../services";
import auth from "../../../../../services/auth";
import { ComplexTableComponent, CheckboxComponent } from "../../../../../shared";
import staticService from "../../../../../services/static.service";
import GetHeliEvents from "../../../../../services/event.service";

class InvitationTableComponent extends Component {
  constructor(props, context) {
    super(props);
    this.paginationService = new PaginationService(
      context.store.dispatch,
      getInvitations,
      config.paginationLimit,
      [],
    );
    this.paginationService.setProp("columns", invitationConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(invitationConfig));
    this.paginationService.loadData();
    this.state = { initialLoadState: false };
    this.prepareRows = this.prepareRows.bind(this);
  }

  get isAdmin() {
    return (
      this.user.role === "operator_admin" ||
      this.user.role === "broker_admin" ||
      this.user.role === "sole_trader"
    );
  }

  get user() {
    return this.props.userOnline !== null ? this.props.userOnline : auth.getUser();
  }

  componentDidMount() {
    GetHeliEvents.setEvent("invitations", () => {
      if (!this.state.initialLoadState) this.setState({ initialLoadState: true });
    });
  }

  removeUser(user) {
    this.props.onModalOpen(user);
  }

  prepareRows(items) {
    return items.map((item) => {
      item.is_admin = this.getIsAdminTemplate(item);
      item.remove = "";
      if (this.isAdmin) {
        item.remove = (
          <a onClick={this.removeUser.bind(this, item)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              style={{
                width: 20,
                height: 20,
              }}
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        );
      }
      return item;
    });
  }

  onChange() {}

  getIsAdminTemplate(item) {
    let disabled = true;
    if (item.status === "active" && this.isAdmin) {
      disabled = item.owner;
    }
    return (
      <CheckboxComponent
        onClick={this.onChange}
        showLabel={false}
        value={item.id}
        isDisabled={disabled}
        defaultChecked={parseInt(item.admin) > 0 ? true : false}
      />
    );
  }

  populate(items) {
    this.paginationService.reloadItems();
    this.paginationService.populateItems(items, this.prepareRows);
    return this.paginationService.items;
  }

  render() {
    return (
      <BlockComponent visible={this.props.invitations.length > 0}>
        <h2
          className={"gh-text-slim uk-margin-remove-bottom uk-margin-top"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("pendingInvites") }}
        />
        <ComplexTableComponent
          loadMore={this.paginationService.loadMore.bind(this.paginationService)}
          rows={this.populate(this.props.invitations)}
          headers={this.paginationService.headers}
          columns={this.paginationService.columns}
          showActionColumn={0}
          firstTimeLoading={this.state.initialLoadState}
          showLoader={false}
          showMoreText={staticService.findByAlias("showMore")}
          actionElements={() => {}}
          alignLastCell={true}
        />
      </BlockComponent>
    );
  }

  componentWillUnmount() {
    this.props.getInvitationsSuccess([]);
  }
}

InvitationTableComponent.contextTypes = {
  store: PropTypes.object,
};

InvitationTableComponent.propTypes = {
  onModalOpen: PropTypes.func.isRequired,
};

const mapStateToProps = ({ invitations, userOnline }) => {
  return {
    invitations,
    userOnline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInvitations,
      resetRemoveAction: () => dispatch({ type: RESET_MEMBER_REMOVE }),
      getInvitationsSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(InvitationTableComponent);
export { COMPONENT as InvitationTableComponent };
