import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { emptyLegConfig, config } from "../../../../../configs";
import { getMarketPlaceEmptyLegs, getActivityFlights } from "../../../../../actions";
import {
  ComplexTableComponent,
  prepareQueryStringFromObj,
  fireTracking,
} from "../../../../../shared/";
import { PaginationService } from "../../../../../services";
import { BrokerMarketplaceEmptyLegsFiltersComponent } from "../../filters";
import GetHeliEvents from "../../../../../services/event.service";
import staticService from "../../../../../services/static.service";
import auth from "../../../../../services/auth";
import PadSelectionMap from "../../../../common/PadSelectionMap/PadSelectionMap";
import moment from "moment";
import compareArrays from "../../../../../utils/compareArrays";

class BrokerEmptyLegListComponent extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      modalVisibility: false,
      isFiltersShown: false,
      redirect: false,
      selectedRowId: null,
      showFilterIcon: true,
      filters: [],
      filterFailed: "",
      sortOrder: emptyLegConfig.defaultOrder,
    };

    this.catchSuccessResponse = this.catchSuccessResponse.bind(this);
    GetHeliEvents.setEvent("legs", this.catchSuccessResponse);
    this.paginationService = new PaginationService(
      context.store.dispatch,
      getMarketPlaceEmptyLegs,
      config.paginationLimit,
      this.state.filters,
    );
    this.paginationService.setProp("columns", emptyLegConfig.columns);
    this.paginationService.setProp("headers", staticService.setGridHeaders(emptyLegConfig));
    this.paginationService.loadData();

    this.tableAction = this.tableAction.bind(this);
    this.changeModalVisibility = this.changeModalVisibility.bind(this);
    this.setFailed = this.setFailed.bind(this);
    fireTracking(props.location.pathname);
    this._mounted = true;
  }

  catchSuccessResponse() {
    if (!this.paginationService.initialLoadState && this._mounted) {
      this.paginationService.setProp("initialLoadState", true);
      this.forceUpdate();
    }
  }

  prepareRows(items) {
    return items.map((item) => {
      const { manufacturer, aircraft_model, max_passengers } = item.aircraft;
      const paxLabel = staticService.findByAlias("maxPassengersLabel");

      item.posted_on = moment(item.posted_on).format(config.dateFormat);
      item.helicopter = `${manufacturer} ${aircraft_model} (${paxLabel} ${max_passengers})`;
      item.requested = <div>{item.requested ? "Yes" : "No"}</div>;
      item.date = moment(item.date).format(config.dateFormat);
      return item;
    });
  }

  changeModalVisibility() {
    this.setState({
      modalVisibility: !this.state.modalVisibility,
    });
  }

  setFailed(items) {
    if (items.length === 0) {
      this.setState({ filterFailed: "failed" });
    } else {
      this.setState({ filterFailed: "" });
    }
  }

  componentDidMount() {
    this.context.store.dispatch(getActivityFlights());
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (!compareArrays(this.props.items, nextProps.items)) {
      if (nextProps.filters.refreshCollection) {
        this.setFailed(nextProps.items);
        this.paginationService.reloadItems();
        this.context.store.dispatch(
          actions.change("emptyLegsBrokerFiltersModel.refreshCollection", false),
        );
        const filters = prepareQueryStringFromObj(nextProps.filters).concat(this.state.filters);
        this.paginationService.setProp("filters", filters);
      }
      this.paginationService.populateItems(nextProps.items, this.prepareRows);
    }
  }

  handleSorting(column, order) {
    this.setState({ sortOrder: { field: column, sortOrder: order } });
    this.paginationService.setSortingParameters({
      column: column,
      order: order,
    });
    this.paginationService.loadData();
    this.context.store.dispatch(
      actions.change("emptyLegsBrokerFiltersModel.refreshCollection", true),
    );
  }

  tableAction(index) {
    const { parentMatch, history } = this.props;
    const route = `${parentMatch.url}/${this.paginationService.items[index].id}`;
    const createRequestLink = (
      <a onClick={() => history.push({ pathname: route, state: { fromEmptyLeg: true } })}>
        {staticService.findByAlias("createRequestLabel")}
      </a>
    );

    if (auth.isVerified()) {
      return {
        desktop: (
          <td className="gh-complex-table-td" style={{ verticalAlign: "middle" }}>
            {createRequestLink}
          </td>
        ),
        mobile: createRequestLink,
      };
    }
    return null;
  }

  render() {
    const { screenDimensions, mapItems } = this.props;

    const flyDirCoords = [...mapItems].map((itm) => ({
      from: itm.from_coords,
      to: itm.to_coords,
      pad: itm,
      key: itm.id,
    }));

    return (
      <div className={"uk-overflow-auto"} style={{ paddingBottom: 70 }}>
        <BrokerMarketplaceEmptyLegsFiltersComponent visible={this.props.isFiltersShown} />

        <div className={"uk-margin-medium-bottom uk-margin-small-top"}>
          <PadSelectionMap
            flyDirCoords={flyDirCoords}
            screenDimensions={screenDimensions}
            withPads={false}
          />
        </div>

        <ComplexTableComponent
          loadMore={this.paginationService.loadMore.bind(this.paginationService)}
          rows={this.paginationService.items}
          headers={this.paginationService.headers}
          columns={this.paginationService.columns}
          showActionColumn={1}
          showLoader={this.paginationService.showLoaderBtn}
          showMoreText={staticService.findByAlias("showMore")}
          actionElements={this.tableAction.bind(this)}
          selectedOrder={this.state.sortOrder}
          sortChange={this.handleSorting.bind(this)}
          emptyRowMessage={staticService.findByAlias("emptyLegNoRows")}
          firstTimeLoading={this.paginationService.initialLoadState}
          isLoading={true}
        />
      </div>
    );
  }

  componentWillUnmount() {
    this._mounted = false;
    GetHeliEvents.clearEvent("legs");
  }
}

BrokerEmptyLegListComponent.propTypes = {
  isFiltersShown: PropTypes.bool,
  parentMatch: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
  items: PropTypes.array,
  filters: PropTypes.object,
  history: PropTypes.object,
  screenDimensions: PropTypes.object,
  mapItems: PropTypes.array,
};

BrokerEmptyLegListComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps({
  emptyLegs,
  emptyLegsBrokerFiltersModel,
  activityFlights,
  screenDimensions,
  mapEmptyLegs,
}) {
  return {
    items: emptyLegs,
    mapItems: mapEmptyLegs,
    filters: emptyLegsBrokerFiltersModel,
    activityFlights,
    screenDimensions,
  };
}

const COMPONENT = connect(mapStateToProps)(BrokerEmptyLegListComponent);
export { COMPONENT as BrokerEmptyLegListComponent };
