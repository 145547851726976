import * as types from "./actionTypes";
import { spinnerInstance } from "../shared";
import Api from "../services/api-handler";
import handlerError from "./error";

export function getDetailQuoteById(quote) {
  return { type: types.GET_QUOTE_DETAIL_BY_ID, quoteDetail: { ...quote } };
}

export function updateQuoteDetail(updatedQuote) {
  return { type: types.UPDATE_QUOTE_DETAIL, updatedQuote };
}

export function updateQuoteLegLocation(location) {
  return { type: types.UPDATE_QUOTE_LEG_LOCATION, location };
}

export function clearQuoteDetail() {
  return { type: types.CLEAR_QUOTE_DETAIL };
}

export function updateEditedQuote(quote) {
  return { type: types.UPDATE_EDITED_QUOTE_DETAIL, quote };
}

export function resetQuoteDetail(quoteDetail = {}) {
  return { type: types.RESET_QUOTE_DETAIL, quoteDetail };
}

export function updateBothQuoteObjects(quoteDetail = { original: {}, editing: {} }) {
  return { type: types.UPDATE_BOTH_QUOTE_OBJECTS, quoteDetail };
}

export function getQuoteById(id, quoteId, onSuccess = () => {}) {
  return function (dispatch) {
    const urlID = quoteId ? `${id}/${quoteId}` : id;

    return Api.setProperty("skipErrorAlert", true)
      .setPath("booking-requests/with-quote", "")
      .get(urlID)
      .then((quote) => {
        Api.setProperty("skipErrorAlert", false);
        if (onSuccess !== null) onSuccess(quote);
        return Promise.resolve(quote);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function previewQuote(data) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setPath("quote/validate-quote", "")
      .post(data)
      .then((res) => {
        spinnerInstance.setProp("type", "large").hide();
        Api.setProperty("showSpinner", false);
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function startQuote(bookingRequestId) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", false)
      .setPath("quote/start-quote", bookingRequestId)
      .post()
      .then((res) => {
        spinnerInstance.setProp("type", "large").hide();
        Api.setProperty("showSpinner", false);
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function updateQuoteLeg(quoteId, data) {
  return function (dispatch) {
    spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", false)
      .setPath("quote/generate-automatic-price", quoteId)
      .post(data)
      .then((res) => {
        spinnerInstance.setProp("type", "large").hide();
        Api.setProperty("showSpinner", false);
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}

export function needMoreInformation(bookingRequestId, message) {
  return function (dispatch) {
    // spinnerInstance.setProp("type", "large").show();
    return Api.setProperty("skipErrorAlert", true)
      .setProperty("alertModal", false)
      .setPath("booking-request/need-more-information", bookingRequestId)
      .post(message)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        handlerError(err, dispatch);
        return Promise.reject(err);
      });
  };
}
