import React from "react";
import staticService from "../../../services/static.service";
import Tooltip from "../Tooltip";
import RadioButtonComponent from "../RadioButtonComponent";
import PropTypes from "prop-types";

const QuotePassengersReadOnlyRadioButton = (props) => {
  const { selected, items, show } = props;
  if (!show) return "";
  return (
    <div className="gh-passengers-bottom-line uk-flex uk-flex-right">
      <div className="gh-quote-lock-payload uk-flex gh-flex-middle uk-margin-remove-top">
        <div className="uk-flex  uk-margin-right gh-flex-middle">
          {staticService.findByAlias("lockPayload")}
          <div style={{ marginLeft: 15 }}>
            <Tooltip
              pos="bottom-left"
              mobilePos="bottom-center"
              contentClass="payload-tooltip-content"
            >
              {staticService.findByAlias("lockPayloadTooltip")}
            </Tooltip>
          </div>
        </div>
        <RadioButtonComponent
          disabled={true}
          selected={selected}
          items={items}
          id="lockPayload-disabled"
        />
      </div>
    </div>
  );
};
QuotePassengersReadOnlyRadioButton.propTypes = {
  show: PropTypes.bool,
  selected: PropTypes.bool,
  items: PropTypes.array,
};
QuotePassengersReadOnlyRadioButton.defaultProps = {
  items: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  selected: true,
  show: true,
};

export default QuotePassengersReadOnlyRadioButton;
