import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { forgotPassword, RESET_FORGOTTEN_PASSWORD } from "../../actions";
import { validationRules, validationMessages } from "../../configs";
import { CustomValidateError, updateModel, AlertComponent, AlertService } from "../../shared";
import staticService from "../../services/static.service";
import event from "../../services/event.service";

const { required, validEmail } = validationRules;

class ForgotPasswordFormComponent extends React.Component {
  constructor(props, context) {
    super(props);
    const alertService = new AlertService();
    alertService.eventInstance = event;
    this.state = {
      showForm: props.visible,
      isSubmit: false,
      alertInstance: alertService,
    };
    context.store.dispatch(actions.reset("userEmailModel"));
  }

  onSuccess() {
    this.context.store.dispatch(actions.reset("userEmailModel"));
    this.context.store.dispatch({ type: RESET_FORGOTTEN_PASSWORD });

    event.trigger("reset-password", {
      visible: true,
      message: staticService.findByAlias("onSuccessPasswordRecoverPost"),
      type: this.state.alertInstance.successProp,
    });
  }

  render() {
    return (
      <Form
        model="userEmailModel"
        className={"gh-recover-pass uk-margin-xlarge-top"}
        validators={{
          email: { required, validEmail },
        }}
        onSubmit={this.props.actions.forgotPassword.bind(this, this.onSuccess.bind(this))}
      >
        <h3
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("resetPasswordTitle") }}
        ></h3>
        <AlertComponent
          isRelative={true}
          time={10000}
          event={"reset-password"}
          alertInstance={this.state.alertInstance}
        />

        <p dangerouslySetInnerHTML={{ __html: staticService.findByAlias("resetPasswordDesc") }}></p>
        <div className={"uk-margin"}>
          <label
            dangerouslySetInnerHTML={{
              __html: staticService.findByAlias("resetPasswordPlaceholder"),
            }}
          ></label>
          <Control.text
            type={"email"}
            className={"uk-input"}
            model={".email"}
            updateOn={"change"}
            changeAction={updateModel}
            placeholder={""}
          />
          <Errors
            model=".email"
            show="touched"
            wrapper={CustomValidateError}
            messages={{
              required: validationMessages().requiredMessage.bind(null, "Email"),
              validEmail: validationMessages().validEmailMessage,
            }}
          />
        </div>
        <button
          type="submit"
          className={"uk-button uk-button-primary uk-align-center"}
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("submit") }}
        ></button>

        <p>
          <span
            dangerouslySetInnerHTML={{ __html: staticService.findByAlias("resetPasswordSignIn") }}
          ></span>
          <NavLink to={"/account/register"}>
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("resetPasswordSignUpNow"),
              }}
            ></span>
          </NavLink>
        </p>
      </Form>
    );
  }
}

ForgotPasswordFormComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    forgottenStatus: state.auth && state.auth.forgot ? true : false,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        forgotPassword: forgotPassword,
      },
      dispatch,
    ),
  };
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormComponent);
export { COMPONENT as ForgotPasswordFormComponent };
