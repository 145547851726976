/* eslint-disable no-console */
import generateLocationAdditionalInfo from "./generateLocationAdditionalInfo";
import { distanceBetween, parseCoordinates } from "../shared";

const quoteFormToOriginal = (original) => {
  /**
   * @param {String} type "quote" | "leg"
   * @param {String} key Object propery
   * @param {Object} [params] Optional parameters
   * @param {(String|Number)} params.id Leg ID
   * @param {String} params.dir Leg direction "from" | "to"
   * @param {Object} params.leg Leg
   */
  return (type, key, params) => {
    if (!type) return console.log("{type} Type is required");
    if (!key) return console.log("{key} Key is required");

    if (type == "leg") {
      if (key === "location") {
        if (!params) return console.log("{params} Please provide params");
        if (!params.id) return console.log("{params.id} Leg ID is required");
        if (!params.dir) return console.log("{params.dir} Leg location direction is required");
        if (!params.leg) return console.log("{params.leg} Leg is required");

        const originalLeg = original.legs.find((leg) => +leg.id === +params.id);

        if (!originalLeg) return console.log("Original Leg not found");

        const distance = distanceBetween(
          {
            lat: originalLeg[`${params.dir}_location`].latitude,
            lng: originalLeg[`${params.dir}_location`].longitude,
          },
          {
            lat: params.leg[`${params.dir === "from" ? "from" : "to"}_location`].latitude,
            lng: params.leg[`${params.dir === "from" ? "from" : "to"}_location`].longitude,
          },
        ).toFixed(0);

        return { location: originalLeg[`${params.dir}_location`], distance };
      }
    }
  };
};

export default quoteFormToOriginal;
