import * as types from "../../actions/actionTypes";

function bookingList(state = [], action) {
  switch (action.type) {
    case types.GET_BOOKING_LIST:
      return action.bookings;
    case types.CANCEL_BOOKING:
      return !!state.length
        ? state.map((booking) => {
            if (booking.id === action.booking.id) {
              return action.booking;
            }
            return booking;
          })
        : [action.booking];
    default:
      return state;
  }
}

function aocDocs(state = [], action) {
  switch (action.type) {
    case types.GET_AOC_DOCS:
      return action.docs;
    default:
      return state;
  }
}

function bookingSuccess(state = {}, action) {
  switch (action.type) {
    case types.NEW_BOOKING:
      return action.booking;
    default:
      return state;
  }
}

function bookingDetail(state = {}, action) {
  switch (action.type) {
    case types.CANCEL_BOOKING:
      return {
        ...state,
        status: "cancelled",
      };
    case types.GET_BOOKING_DETAIL:
      return { ...action.bookingDetail };
    case types.REMOVE_BOOKING_DETAIL:
      return {};
    case types.UPDATE_IDENTIFIER: {
      const booking_request = { ...state.booking_request };
      booking_request.identifier = action.response.identifier;
      return { ...state, booking_request: booking_request };
    }
    case types.ADD_COMMENT_TO_BOOKING:
      {
        if (action.hasOwnProperty("id") && state.hasOwnProperty("id") && action.id === state.id) {
          return {
            ...state,
            comments: [...state.comments, action.comment],
          };
        }
      }
      break;
    case types.OPERATOR_BOOKING_INTERNAL_NOTES_ADDED_WITH_SUCCESS: {
      return {
        ...state,
        internalNotes: action.payload,
      };
    }
    case types.UPDATE_BOOKING_SELECTED_QUOTE: {
      if (state.booking_request && state.booking_request.selectedQuote) {
        const updatedQuote =
          state.booking_request.selectedQuote.id === action.seenQuote.quote_id
            ? action.seenQuote
            : {};

        return {
          ...state,
          booking_request: {
            ...state.booking_request,
            selectedQuote: {
              ...state.booking_request.selectedQuote,
              ...updatedQuote,
            },
          },
        };
      }
      return {
        ...state,
        booking_request: state.booking_request,
      };
    }

    default:
      return state;
  }
}

function stripeFields(state = {}, action) {
  switch (action.type) {
    case types.GET_STRIPE_FIELDS:
      return action.payload;
    default:
      return state;
  }
}

export { bookingList, bookingSuccess, bookingDetail, aocDocs, stripeFields };
