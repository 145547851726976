import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ModalComponent } from "../../../../../shared";
import { PipelineViewContext } from "./PipelineViewContext";
import PipelineViewCustomer from "./tabs/PipelineViewCustomer";
import PipelineViewEnquiry from "./tabs/PipelineViewEnquiry";
import PipelineViewWorkspace from "./tabs/PipelineViewWorkspace";
import condStrings from "../../../../../utils/condString";
import staticService from "../../../../../services/static.service";

import "./styles/index.css";

export const tabsKeys = {
  customer: "pipeline-customer-tab",
  enquiry: "pipeline-enquiry-tab",
  workspace: "pipeline-workspace-tab",
};

export default function BrokerPipelineView(props) {
  const {
    pipeline,
    refClbk,
    onClose,
    statuses,
    updatePiplineStatus,
    updatePipelineNotes,
    sendToMarketplace,
    defaultActiveTab,
    editCustomerInfo,
    handleAssignPipeline,
  } = props;
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  useEffect(() => setActiveTab(defaultActiveTab || tabsKeys.customer), [defaultActiveTab]);

  const modalRef = useRef();

  useEffect(() => {
    if (modalRef.current) {
      refClbk(modalRef.current);
    }
  }, [modalRef]);

  const changeTabHandler = useCallback((key) => () => {
    setActiveTab(key);
  });

  const onModalCloseHandler = useCallback(() => {
    setActiveTab(tabsKeys.customer);
    if (onClose) onClose();
  });

  return (
    <PipelineViewContext.Provider value={{ pipeline, updatePipelineNotes, editCustomerInfo }}>
      <ModalComponent
        ref={modalRef}
        id="requestModal"
        modalClass="pipline-view-modal"
        onClose={onModalCloseHandler}
        options={{ bgClose: false }}
      >
        <div className="gh-quote-tabs pipline-view-tabs">
          <div
            onClick={changeTabHandler(tabsKeys.customer)}
            className={condStrings(
              "gh-quote-tabs-tab btn-style",
              activeTab === tabsKeys.customer && "active",
            )}
          >
            {staticService.findByAlias("customer")}
          </div>
          <div
            onClick={changeTabHandler(tabsKeys.enquiry)}
            className={condStrings(
              "gh-quote-tabs-tab btn-style",
              activeTab === tabsKeys.enquiry && "active",
            )}
          >
            {staticService.findByAlias("enquiry")}
          </div>
          <div
            onClick={changeTabHandler(tabsKeys.workspace)}
            className={condStrings(
              "gh-quote-tabs-tab btn-style",
              activeTab === tabsKeys.workspace && "active",
            )}
          >
            {staticService.findByAlias("workspace")}
          </div>
        </div>
        {activeTab === tabsKeys.customer && <PipelineViewCustomer />}
        {activeTab === tabsKeys.enquiry && <PipelineViewEnquiry />}
        {activeTab === tabsKeys.workspace && (
          <PipelineViewWorkspace
            updatePiplineStatus={updatePiplineStatus}
            statuses={statuses}
            sendToMarketplace={sendToMarketplace}
            handleAssignPipeline={handleAssignPipeline}
          />
        )}
      </ModalComponent>
    </PipelineViewContext.Provider>
  );
}

BrokerPipelineView.propTypes = {
  pipeline: PropTypes.object,
  refClbk: PropTypes.func,
  onClose: PropTypes.func,
  statuses: PropTypes.array,
  updatePiplineStatus: PropTypes.func,
  updatePipelineNotes: PropTypes.func,
  sendToMarketplace: PropTypes.func,
  defaultActiveTab: PropTypes.string,
  editCustomerInfo: PropTypes.func,
  handleAssignPipeline: PropTypes.func,
};

BrokerPipelineView.defaultProps = {
  statuses: [],
};
