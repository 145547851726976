import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { formatBytes, utcTime } from "../../shared";
import { useAccountLocalization } from "../../context";
import pdfIcon from "../../assets/img/svg/pdf.svg";
import wordIcon from "../../assets/img/svg/word.svg";

const filesIcon = {
  pdf: pdfIcon,
  docx: wordIcon,
};

const TextMessage = ({ data, seenBy, openModal, isSecondRole }) => {
  const accountLocalization = useAccountLocalization();

  return (
    <div
      className={`${data.is_system !== 1 ? "gh-message-box" : "gh-bubble-message-container"} ${
        !data.body ? "doc" : ""
      }`}
    >
      <div className="gh-message-header" style={isSecondRole ? { justifyContent: "flex-end" } : {}}>
        {data.is_system !== 1 ? <div className="gh-message-header-user">{data.user}</div> : ""}
      </div>

      <div
        className={`gh-message-text ${data.file ? "doc" : ""}`}
        onClick={
          data.file
            ? () => {
                openModal(data.file);
              }
            : null
        }
        // style={isSecondRole ? {display: 'flex', justifyContent: 'flex-end'} : {}}
      >
        {data.body ? (
          <span dangerouslySetInnerHTML={{ __html: data.body.replaceAll("\n", "<br/>") }} />
        ) : (
          <div className="uk-flex">
            <div style={{ marginRight: 10 }}>
              <img src={filesIcon[data.file_type]} alt="DOC" />
            </div>
            <div className="d-flex uk-flex-column">
              <div>{data.file_name}</div>
              <div style={{ fontSize: 11 }}>{formatBytes(data.file.size)}</div>
            </div>
          </div>
        )}
      </div>

      <div className="gh-bottom-line">
        <span className="gh-message-header-posted">
          <span>{moment(data.posted).format("DD MMM, YYYY")}</span>
          <span style={{ padding: "0 3px" }}>|</span>
          <span>{moment(data.posted).format(accountLocalization.timeFormat) + utcTime()}</span>
        </span>

        {seenBy ? (
          <div className="gh-seen-message">
            Seen by <span style={{ marginLeft: 3 }}>{seenBy}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

TextMessage.propTypes = {
  data: PropTypes.object,
  seenBy: PropTypes.string,
  openModal: PropTypes.func,
  isSecondRole: PropTypes.bool,
};

export default TextMessage;
