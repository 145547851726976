import React from "react";
import { Control } from "../Control";
import { Aircraft } from "./Aircraft";
import { defaultLegValue, defaultPilotValue, useCalculatorContext } from "../../CalculatorContext";
import apiHandler from "../../../../services/api-handler";
import { showNotification } from "../../../../utils/showNotification";
import { spinnerInstance } from "../../../../shared";
import uid from "../../../../utils/uid";
import { parsePercentValue } from "../..";

export function AircraftsList(props) {
  const { banking } = props;
  const { setAircraftActiveIndex, activeAircraftIndex, demoVersion, availableAircrafts } =
    useCalculatorContext();
  const selectedAircrafts = React.useRef(new Map());

  const generateNewAircraft = () => {
    setAircraftActiveIndex(activeAircraftIndex + 1);

    const companyVat = banking?.vat_value || "";

    const aircraftLegs = [];

    Array.from(Array(3)).forEach((value, index) => {
      aircraftLegs.push({
        ...defaultLegValue,
        id: index,
        category: index === 1 ? 1 : 2,
        home_base_repositioning: index === 0 ? "from" : index === 2 ? "to" : null,
        crew_size: 1,
        pilots: [{ id: uid().uid, ...defaultPilotValue }],
        from: index === 0 ? availableAircrafts[activeAircraftIndex + 1].home_base_location : {},
        to: index === 2 ? availableAircrafts[activeAircraftIndex + 1].home_base_location : {},
        cruise_speed: availableAircrafts[activeAircraftIndex + 1].cruise_speed,
        price_per_hour: availableAircrafts[activeAircraftIndex + 1].price_per_hour || "",
        flight_tax: {
          sum: "",
          percent: parsePercentValue(
            {
              flight_tax: {
                sum: "",
                percent: availableAircrafts[activeAircraftIndex + 1].aircraft_vat,
              },
            },
            companyVat,
          ),
        },
        departure_fee_tax: {
          sum: "",
          percent: companyVat,
        },
        landing_fee_tax: {
          sum: "",
          percent: companyVat,
        },
        extra_cost_tax: {
          sum: "",
          percent: companyVat,
        },
        extra_time: null,
        total_cost_flight: null,
      });
    });

    return {
      id: uid().uid,
      crew_size: 1,
      price_per_hour: "",
      cruise_speed: "",
      total_aircraft_costs: "",
      legs: aircraftLegs,
    };
  };

  const onRemoveHandler = React.useCallback(
    (cb, aircraftId) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (demoVersion) {
        cb();
        setAircraftActiveIndex(0);
        return;
      }

      spinnerInstance.setProp("type", "large").show();
      apiHandler
        .setProperty("skipErrorAlert", true)
        .setPath("quote/aircrafts/remove", "")
        .delete(aircraftId)
        .then((res) => {
          spinnerInstance.setProp("type", "large").hide();
          if (res.message) {
            showNotification(res.message, "success");
          }

          const nextAircraftIndex = activeAircraftIndex - 1 >= 0 ? activeAircraftIndex - 1 : 0;

          cb();
          setAircraftActiveIndex(nextAircraftIndex);
        })
        .catch((err) => {
          spinnerInstance.setProp("type", "large").hide();
          return err;
        });
      return;
    },
    [setAircraftActiveIndex, activeAircraftIndex],
  );

  const onAfterAdd = React.useCallback(
    () => setAircraftActiveIndex(activeAircraftIndex + 1),
    [activeAircraftIndex, setAircraftActiveIndex],
  );

  return (
    <Control.List name="aircrafts">
      {({ add, fields, remove }) => {
        const aircraftsLen = fields.length;

        return (
          <div className="aircrafts-container">
            <div className="aircrafts-list">
              {fields.map((field, index) => (
                <Aircraft
                  aircraft={field}
                  key={field.id}
                  prefix={`aircrafts.${index}`}
                  index={index}
                  aircraftsLen={aircraftsLen}
                  selectedAircrafts={selectedAircrafts.current}
                  onRemove={onRemoveHandler(() => remove(index), field.calculation_aircraft_id)}
                  banking={banking}
                />
              ))}
            </div>
            {demoVersion ? (
              <button
                type="button"
                className="calculator-reset-btn calculator-add-btn"
                tabIndex="-1"
                style={{
                  visibility: aircraftsLen < availableAircrafts.length ? "visible" : "hidden",
                }}
                onClick={() => {
                  add(generateNewAircraft());
                  onAfterAdd();
                }}
              >
                + Add Aircraft
              </button>
            ) : null}
          </div>
        );
      }}
    </Control.List>
  );
}
