import React from "react";
import PropTypes from "prop-types";
import { ModalComponent, redirectTo } from "../../../../shared";
import staticService from "../../../../services/static.service";
import { REQUEST_WITHDRAWN } from "../../../../configs";
import { RequestStatusComponent } from "../common";
import moment from "moment";
import link from "../../../../assets/img/external-link.svg";
import { editRequest, updateReducer, UPDATE_PRIVATE_NOTES } from "../../../../actions";
import { BrokerReviewRequestComponent } from "./broker-review-request.component";
import BackLink from "../../../common/backlink";
import Button from "../../../common/Button";
import { showNotification } from "../../../../utils/showNotification";
import { generateAccountLocalization } from "../../../../context";

class BrokerRequestNav extends React.Component {
  constructor(props) {
    super(props);
    this.accountLocalization = generateAccountLocalization();
    this.state = {
      editable: false,
      request: {},
    };
    this.sendToEdit = this.sendToEdit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onUpdateRequest = this.onUpdateRequest.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }
  openRequest(request) {
    this.setState({ request: request }, () => this.fullRequest.open());
    this.fullRequest.open();
  }

  showNotification() {
    showNotification(staticService.findByAlias("restrictAccessForBroker"), "warning");
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.isSendToEdit) {
      this.sendToEdit(this.props.requestDetail);
    }
  }

  closeRequestModal() {
    this.fullRequest.hide();
    setTimeout(() => {
      this.setState({ request: {} });
    }, 200);
  }

  sendToEdit(request) {
    if (!this.props.brokerIsAllowedToEdit) {
      return;
    }

    request &&
      request.legs &&
      request.legs.map((leg) => {
        leg.from_is_private = leg.from_is_private !== 0;
        leg.to_is_private = leg.to_is_private !== 0;
        leg.passengers.passengers_amount_type = `${leg.passengers.passengers_amount_type}`;
        leg.local_points = true;
      });
    this.context.store.dispatch(editRequest(request));
    this.setState({ editable: true });
  }

  goBack() {
    const { backPath, history } = this.props;

    if (backPath) {
      history.push(backPath);
    } else {
      history.goBack();
    }
  }

  onUpdateRequest(data) {
    this.setState({ request: { ...this.state.request, ...data } });
    this.context.store.dispatch(updateReducer(UPDATE_PRIVATE_NOTES, data));
  }

  render() {
    const {
      match,
      triggerAction,
      requestDetail,
      closeRequestDialog,
      isAllowed,
      visibleWithdraw,
      backButton,
      teamMembersList,
      brokerIsAllowedToEdit,
    } = this.props;

    const hasRequestToExpired = requestDetail.has_to_expire;

    const visibleEdit =
      requestDetail?.activeQuotes?.length === 0 && requestDetail.status === "open";

    if (this.state.editable) {
      if (requestDetail.empty_leg_id) {
        return redirectTo(`/marketplace/empty-legs/${requestDetail.empty_leg_id}`);
      } else {
        return redirectTo("/new-request");
      }
    }

    const displayRule = () =>
      visibleEdit || (visibleWithdraw && requestDetail.status !== "withdrawn");

    return (
      <div className="gh-request-nav">
        <ModalComponent
          title={staticService.findByAlias("request-details")}
          options={{ clsPage: "", bgClose: true, escClose: true }}
          modalClass="gh-broker-nav-request-modal"
          onClose={this.closeRequestModal.bind(this)}
          id="full-request-modal"
          ref={(fullRequest) => (this.fullRequest = fullRequest)}
        >
          {Object.keys(this.state.request).length && (
            <BrokerReviewRequestComponent
              request={this.state.request}
              nav={true}
              onUpdateRequest={this.onUpdateRequest}
              hasRequestToExpired={hasRequestToExpired}
            />
          )}

          <div className="d-flex_container uk-margin-top">
            <div className="d-flex_inner">
              {visibleWithdraw && requestDetail.status !== "withdrawn" && (
                <Button
                  onClick={() =>
                    brokerIsAllowedToEdit
                      ? triggerAction(REQUEST_WITHDRAWN)
                      : this.showNotification()
                  }
                  color="danger"
                  className="uk-margin-right"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService
                        .findByAlias("withdrawButton")
                        .replace(/(<([^>]+)>)/gi, ""),
                    }}
                  />
                </Button>
              )}

              {visibleEdit && (
                <Button
                  onClick={() =>
                    brokerIsAllowedToEdit ? this.sendToEdit(requestDetail) : this.showNotification()
                  }
                  color="gray"
                  disabled={hasRequestToExpired}
                  tooltipProps={{
                    message: hasRequestToExpired
                      ? staticService.findByAlias("requestIsExpiredTooltip")
                      : "",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: staticService.findByAlias("edit"),
                    }}
                  />
                </Button>
              )}
            </div>

            <Button onClick={() => this.fullRequest.hide()}>
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("close"),
                }}
              />
            </Button>
          </div>
        </ModalComponent>

        <div className="gh-short-nav">
          <div className="uk-container">
            <div className="uk-flex uk-flex-between">
              <BackLink onClick={this.goBack}>{backButton}</BackLink>
            </div>
          </div>
        </div>
        <div className="uk-container">
          <div className="gh-request-info">
            <RequestStatusComponent
              request={requestDetail}
              requestStatus={isAllowed}
              action={triggerAction}
              editIdentifier={true}
              visible={closeRequestDialog}
              match={match}
              teamMembersList={teamMembersList}
              brokerIsAllowedToEdit={brokerIsAllowedToEdit}
            />

            <hr className="gh-divider" />

            <div data-uk-grid className="uk-grid-small uk-margin-remove-top">
              <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
                <h4 className="gh-column-label">{staticService.findByAlias("from")}</h4>
                <p className="gh-column-text">{requestDetail.from}</p>
              </div>
              <div className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m">
                <h4 className="gh-column-label">{staticService.findByAlias("to")}</h4>
                <p className="gh-column-text">{requestDetail.to}</p>
              </div>
              <div className="uk-width-1-1 uk-width-1-4@m">
                <div data-uk-grid className="uk-grid-small uk-flex uk-flex-center">
                  <div className="uk-width-1-2 uk-width-1-3@m">
                    <h4 className="gh-column-label">{staticService.findByAlias("legsLabel")}</h4>
                    <p className="gh-column-text">
                      {requestDetail.legs && requestDetail.legs.length}
                    </p>
                  </div>
                  <div className="uk-width-1-2 uk-width-2-3@m">
                    <h4 className="gh-column-label">{staticService.findByAlias("dateLabel")}</h4>
                    <p className="gh-column-text">
                      {requestDetail.departure && moment(requestDetail.departure).format("DD MMMM")}
                      , {requestDetail.departure && moment(requestDetail.departure).format("YYYY")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-1-4@m">
                <div data-uk-grid className="uk-grid-small uk-flex uk-flex-center">
                  <div className="uk-width-1-2 uk-width-2-3@m">
                    <h4 className="gh-column-label">{staticService.findByAlias("timeLabel")}</h4>
                    <p className="gh-column-text">
                      {moment(requestDetail.departure).format(this.accountLocalization.timeFormat)}{" "}
                      (UTC
                      {moment(requestDetail.departure).format("Z")})
                    </p>
                  </div>
                  <div className="uk-width-1-2 uk-width-1-3@m">
                    <h4 className="gh-column-label">{staticService.findByAlias("paxLabel")}</h4>
                    <p className="gh-column-text">
                      {Object.keys(requestDetail).length
                        ? +requestDetail.number_adults + +requestDetail.number_children
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="uk-text-left gh-full-quote-actions uk-margin-top">
            {displayRule() ? (
              <div className="gh-short-nav-actions uk-margin-remove-left">
                {visibleEdit ? (
                  <Button
                    onClick={() =>
                      brokerIsAllowedToEdit
                        ? this.sendToEdit(requestDetail)
                        : this.showNotification()
                    }
                    type="button"
                    variant="text"
                    color="primary"
                    className="uk-margin-small-right"
                    disabled={hasRequestToExpired}
                    tooltipProps={{
                      message: hasRequestToExpired
                        ? staticService.findByAlias("requestIsExpiredTooltip")
                        : "",
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("editThisRequest"),
                      }}
                    />
                  </Button>
                ) : (
                  ""
                )}

                {visibleWithdraw && requestDetail.status !== "withdrawn" && (
                  <Button
                    onClick={() =>
                      brokerIsAllowedToEdit
                        ? triggerAction(REQUEST_WITHDRAWN)
                        : this.showNotification()
                    }
                    type="button"
                    variant="text"
                    color="danger"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("withdrawRequest"),
                      }}
                    />
                  </Button>
                )}
              </div>
            ) : (
              ""
            )}

            <Button
              onClick={
                brokerIsAllowedToEdit
                  ? this.openRequest.bind(this, requestDetail)
                  : this.showNotification
              }
              type="button"
              variant="text"
              color="dark"
              className="gh-quote-details-manage-buttons"
            >
              {staticService.findByAlias("fullRequestDetailsLabel")}
              <img className="gh-link-icon" src={link} alt="external-link" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
BrokerRequestNav.contextTypes = {
  store: PropTypes.object,
};

BrokerRequestNav.defaultProps = {
  brokerIsAllowedToEdit: true,
};

BrokerRequestNav.propTypes = {
  requestDetail: PropTypes.object,
  brokerIsAllowedToEdit: PropTypes.bool,
  isSendToEdit: PropTypes.bool,
  backPath: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  triggerAction: PropTypes.func,
  closeRequestDialog: PropTypes.bool,
  isAllowed: PropTypes.bool,
  visibleWithdraw: PropTypes.bool,
  backButton: PropTypes.string,
  teamMembersList: PropTypes.array,
};

export { BrokerRequestNav };
