import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { config } from "../../../../configs";
import {
  getBrokerRequestDetail,
  getDeclineReasons,
  sendDeclineReasons,
  sendRequestDeclineReasons,
} from "../../../../actions";
import { BlockComponent } from "../../../common";
import { ButtonWithBadgeComponent, RequestDetailComponent } from "../common";
import { fireTracking, ModalComponent, redirectTo } from "../../../../shared";
import Auth from "../../../../services/auth";
import { ComponentAuthorization, IsVerifiedAuthorization, WrapperComponent } from "../../../";
import staticService from "../../../../services/static.service";
import { DeclineReasons } from "../common";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";

class OperatorRequestDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountVerified: Auth.isAuthenticated() ? Auth.isVerified() : false,
      isRestricted: false,
      form: {},
      redirectToMarket: false,
    };
    this.loadData = this.loadData.bind(this);
    fireTracking(props.location.pathname);
  }

  componentDidMount() {
    this.props.onRequest(this.props.match.params.id);
    this.loadData(this.props.match.params.id);
    this.props.getDeclineReasons("booking_request");
    this.closeDeclineModal();
    if (!this.props.requestDetail.id) {
      this.loadData(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ form: nextProps.form });
  }

  loadData(id) {
    this.context.store.dispatch(
      getBrokerRequestDetail(id, () => {
        this.setState({
          isRestricted: true,
        });
      }),
    );
  }

  openDeclineModal() {
    this.declineModal.open();
  }

  closeDeclineModal() {
    this.declineModal.hide();
  }
  submitDecline() {
    const { form } = this.state;
    if (form.reason.value === "Other info") {
      this.props
        .sendDeclineReasons(this.request.id, {
          reason: this.state.form.other_info.value,
        })
        .then(() => this.closeDeclineModal());
    } else {
      this.props
        .sendDeclineReasons(this.request.id, {
          reason: this.state.form.reason.value,
        })
        .then(() => this.closeDeclineModal());
    }
    setTimeout(() => {
      this.setState({ redirectToMarket: true });
      window.location.reload();
    }, 1000);
  }

  get componentPropsQuote() {
    return {
      isButton: false,
      path: "/new-quote/" + (this.request.id || ""),
      actionTitle: staticService.findByAlias("startQuote"),
      classNames: ["uk-button uk-button-primary gh-padding-remove gh-request-btn-margin"],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  get componentDeclinePropsQuote() {
    return {
      isButton: true,
      actionTitle: staticService.findByAlias("declineRequestButton"),
      action: this.openDeclineModal.bind(this),
      classNames: ["uk-button uk-button-danger gh-margin-small-left gh-padding-remove"],
      tooltipMessage: staticService.findByAlias("accountUnverified"),
      verified: Auth.isAuthenticated() ? Auth.isVerified() : false,
    };
  }

  componentWillUnmount() {
    this.props.onRequest(null);
    this.props.resetReasons({});
    this.context.store.dispatch(actions.reset("declineRequestModel"));
  }

  get request() {
    return this.props.requestDetail || {};
  }
  get marketButtonProps() {
    return {
      componentProps: {
        route: "/marketplace",
        label: staticService.findByAlias("operatorMarketPlaceButton"),
        classes: ["btn-pulse"],
        visible: Auth.isAuthenticated() ? Auth.isVerified() : 0,
      },
      roles: ["operator_admin", "operator_team"],
      component: ButtonWithBadgeComponent,
    };
  }
  render() {
    if (this.state.isRestricted) {
      return redirectTo("/restricted");
    }
    if (this.state.redirectToMarket) {
      return redirectTo("/marketplace");
    }

    return (
      <WrapperComponent>
        <BlockComponent visible={true} parentProps={{ className: "uk-margin" }}>
          {this.modal}
          <ComponentAuthorization
            component={this.marketButtonProps.component}
            componentProps={this.marketButtonProps.componentProps}
            roles={this.marketButtonProps.roles}
          />
          <a className="uk-link-text clear-paddings" onClick={this.props.history.goBack}>
            <b className={"font-weight-500"}>
              <span className="uk-margin-small-right" data-uk-icon="icon: arrow-left" />
              <span
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("backToRequestList"),
                }}
              />
            </b>
          </a>
          <div className="gh-padding-small-left gh-padding-small-right uk-align-center">
            <ModalComponent
              title={staticService.findByAlias("operatorDeclineModalTitle")}
              ref={(declineModal) => (this.declineModal = declineModal)}
              id={"declineRequest"}
              onClose={this.closeDeclineModal.bind(this)}
              modalClass="gh-decline-modal"
            >
              <DeclineReasons
                reasons={this.props.reasons}
                form={this.state.form}
                submitDecline={this.submitDecline.bind(this)}
              />
            </ModalComponent>
            <RequestDetailComponent
              request={this.request}
              showHelicopImage={this.props.screenW > config.minDeviceWidth}
            />

            <div className="uk-padding-remove-top uk-margin-bottom uk-margin-top uk-text-center uk-text-left@s">
              <ComponentAuthorization
                component={IsVerifiedAuthorization}
                componentProps={this.componentPropsQuote}
                roles={["operator_admin", "operator_team"]}
              />
              {this.request &&
              !this.request.is_declined &&
              this.request.quotes_per_company === 0 ? (
                <ComponentAuthorization
                  component={IsVerifiedAuthorization}
                  componentProps={this.componentDeclinePropsQuote}
                  roles={["operator_admin", "operator_team"]}
                />
              ) : (
                ""
              )}
              {/*{this.request &&*/}
              {/*!this.request.is_declined &&*/}
              {/*this.request.quotes_per_company === 0 ? (*/}
              {/*  <a*/}
              {/*    className="uk-button uk-button-danger gh-margin-small-left gh-padding-remove"*/}
              {/*    onClick={this.openDeclineModal.bind(this)}*/}
              {/*    dangerouslySetInnerHTML={{*/}
              {/*      __html: staticService.findByAlias("declineRequestButton"),*/}
              {/*    }}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  ""*/}
              {/*)}*/}
            </div>
          </div>
        </BlockComponent>
      </WrapperComponent>
    );
  }
}

OperatorRequestDetailComponent.contextTypes = {
  store: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    requestDetail: state.requestDetail,
    formVisible: true,
    company: state.company,
    ...state.screenDimensions,
    reasons: state.requests.reasons,
    form: state.forms.declineRequestModel,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDeclineReasons: getDeclineReasons,
      sendDeclineReasons: sendDeclineReasons,
      resetReasons: sendRequestDeclineReasons,
      resetModel: (model) => dispatch(actions.reset(model)),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(OperatorRequestDetailComponent);
export { COMPONENT as OperatorRequestDetailComponent };
