import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { config } from "../../../../configs";
import { formatID } from "../../../../shared";
import staticService from "../../../../services/static.service";
import capitalizeString from "../../../../utils/capitalizeString";

class EmptyLegRequestComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { visible, info } = this.props;
    return visible ? (
      <div className="uk-background-default uk-padding-small uk-border-rounded uk-margin-top gh-notification gh-elr-row">
        <div data-uk-grid className="uk-grid uk-margin-remove-left">
          <div className="uk-width-1-1@s uk-width-3-5@m uk-padding-remove-left">
            <b className="font-weight-500 gh-color-primary uk-margin-small-right gh-display-block-mob uk-text-left uk-text-right@m">
              {formatID(info.id, config.prefixes.request)}
            </b>
            <span className="gh-color-primary gh-display-block-mob uk-text-left uk-text-right@m">
              {info.from}
              <span className="uk-margin-small-left uk-margin-small-right uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <polyline fill="none" stroke="#242f42" points="10 5 15 9.5 10 14"></polyline>
                  <line fill="none" stroke="#242f42" x1="4" y1="9.5" x2="15" y2="9.5"></line>
                </svg>
              </span>
              {info.to}
            </span>
          </div>
          <div className="uk-width-1-1@s uk-width-1-5@m uk-padding-remove">
            <span
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias("status").toUpperCase(),
              }}
              className="gh-label uk-margin-right gh-display-block-mob uk-text-left uk-text-right@m"
            />
            <span className="gh-color-primary gh-display-block-mob uk-text-left uk-text-right@m">
              {capitalizeString(info.status)}
            </span>
          </div>
          <div className="uk-width-1-1@s uk-width-1-5@m uk-text-right@m uk-padding-remove">
            <Link
              to={{
                pathname: `/marketplace/trip/request/${info.id}`,
                state: { originPath: `/flights/empty-legs/filter/requests/${info.empty_leg_id}` },
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: capitalizeString(staticService.findByAlias("addQuote")),
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    ) : null;
  }
}

EmptyLegRequestComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  info: PropTypes.object.isRequired,
};

EmptyLegRequestComponent.contextTypes = {
  store: PropTypes.object,
};

const COMPONENT = connect(() => {
  return {};
})(EmptyLegRequestComponent);
export { COMPONENT as EmptyLegRequestComponent };
