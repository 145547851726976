import React, { useState } from "react";
import PropTypes from "prop-types";
import auth from "../../../../../../../services/auth";
import staticService from "../../../../../../../services/static.service";
import { connect } from "react-redux";
import calculatePercentage from "../../../../../../../utils/calculatePercentage";
import { updateDepositAmount } from "../../../../../../../actions";
import { showNotification } from "../../../../../../../utils/showNotification";

const InfoPaidDeposit = (props) => {
  const { quotePrice, bookingDetail, updateDepositAmount, bookingId, status } = props;
  const [showInput, setShowInput] = useState(false);
  const [percent, setPercent] = useState(quotePrice.deposit_amount.percent || 0);
  const [amount, setAmount] = useState(quotePrice.deposit_amount.amount || 0);
  const isAllowToEdit =
    auth.isOperator() && quotePrice.deposit_amount.percent < 100 && status === "confirmed";

  const handleChangePercent = ({ target }) => {
    const value = parseInt(target.value) || "";
    const calculatedPercent = calculatePercentage(
      value > 100 ? 100 : value,
      +quotePrice.total.replaceAll(",", ""),
    );

    setAmount(calculatedPercent);
    setPercent(value > 100 ? 100 : value);
  };

  const handleClose = () => {
    setAmount(quotePrice.deposit_amount.amount || 0);
    setPercent(quotePrice.deposit_amount.percent || 0);
    setShowInput(false);
  };

  const handleSave = () => {
    if (!isAllowToEdit) return;
    updateDepositAmount(bookingId, { deposit_percent: percent }).then(() => {
      showNotification(staticService.findByAlias("depositWasUpdated"), "success");
      setShowInput(false);
    });
  };

  return (
    <div className="gh-booking-info-bar-col deposit">
      <div className="gh-booking-info-bar-key">
        {staticService.findByAlias("gridBookingsHeadePayed")}
      </div>

      <div className="gh-booking-info-bar-value w-500">
        <span className={`${showInput ? "--hide" : ""}`}>{quotePrice.deposit_amount.percent}%</span>

        <div className={`gh-booking-info-bar-identifier-form ${!showInput ? "--hide" : ""}`}>
          <div className="gh-booking-info-wrapper-input">
            <input
              type="number"
              className="gh-booking-info-bar-identifier-input"
              value={percent}
              onChange={handleChangePercent}
            />
            <span className="uk-input-percent">%</span>
          </div>
          <button
            uk-icon="icon: check"
            className="gh-booking-info-bar-identifier-btn btn-style"
            style={{ color: "#1abc9c" }}
            onClick={handleSave}
          />

          <button
            uk-icon="icon: close"
            className="gh-booking-info-bar-identifier-btn btn-style"
            style={{ color: "#e74c3c" }}
            onClick={handleClose}
          />
        </div>

        <span className="uk-margin-small-left">
          [{bookingDetail?.operator_currency?.currency || ""} {amount}]
        </span>
      </div>

      <div className="gh-booking-info-bar-value">
        {isAllowToEdit && !showInput ? (
          <div
            className="gh-booking-info-bar-edit-btn btn-style"
            onClick={() => setShowInput(true)}
          >
            Edit
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

InfoPaidDeposit.defaultProps = {};

InfoPaidDeposit.propTypes = {
  quotePrice: PropTypes.object,
  updateDepositAmount: PropTypes.func,
  bookingId: PropTypes.number,
  status: PropTypes.string,
  bookingDetail: PropTypes.object,
};

const mapStateToProps = (store) => ({
  bookingDetail: store.bookingDetail,
});

export default connect(mapStateToProps, { updateDepositAmount })(InfoPaidDeposit);
