import React from "react";
import PropTypes from "prop-types";

class RadioComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || false,
      disabled: props.disabled || true,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.checked !== nextProps.checked) {
      this.setState((prevState) => ({
        checked: nextProps.checked,
      }));
    }
  }
  onChange() {
    if (this.props.disabled) {
      this.setState((prevState) => ({
        checked: true,
      }));
    } else {
      const isChecked = this.state.checked;
      this.setState((prevState) => ({
        checked: true,
      }));
      this.props.onClick(!isChecked, this.props.value, this.props.model || this.props.name);
    }
  }

  render() {
    return (
      <div onClick={this.onChange} className={this.props.wrapperClassName}>
        <input
          type="radio"
          value={this.props.value}
          checked={this.state.checked}
          className={this.props.className}
          onChange={() => {}}
          disabled={this.props.disabled}
        />
        <label className={"label"}>{this.props.label}</label>
      </div>
    );
  }
}

RadioComponent.propTypes = {
  label: PropTypes.any.isRequired,
  value: () => {
    PropTypes.string || PropTypes.number;
  },
  disabled: PropTypes.bool,
  model: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  checked: PropTypes.bool,
};

export { RadioComponent };
