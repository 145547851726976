import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions, Form } from "react-redux-form";
import { getBookingsByFiltering, getBookingSuccess } from "../../../../../actions";
import { FilterBookingFieldset } from "../../../../forms/fieldsets/";
import { filterMapStateToProps, setActiveItem } from "../../../../../shared/";
import { allModels } from "../../../../../reducers";
import { BookingFilters, config } from "../../../../../configs/";
import { FilterTabsComponent } from "../../filters";
import BaseFilterComponent from "../../filters/base-filter.component";
import googleApi from "../../../../../services/google.service";
import generateLocationAdditionalInfo from "../../../../../utils/generateLocationAdditionalInfo";
import auth from "../../../../../services/auth";

const modelName = "bookingsConfirmedFiltersModel";

class BookingFiltersComponent extends BaseFilterComponent {
  constructor(props) {
    super(props, modelName);
    this.state = {
      modelName: modelName,
      filterTabs: [],
    };
    this.updateTabList = this.updateTabList.bind(this);
    this.onSelectLocation = this.onSelectLocation.bind(this);
    this.updateOnSelect = this.updateOnSelect.bind(this);
    this.updateGeocodeModel = this.updateGeocodeModel.bind(this);
    this.updateDepartureTime = this.updateDepartureTime.bind(this);
  }

  get Model() {
    return allModels.bookingsConfirmedFiltersModel;
  }

  componentDidMount() {
    if (auth.getRole() === "broker_team") {
      this.props.updateModel(modelName, { activity: "own" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (auth.isBroker() && !Object.keys(nextProps.dashboardDetails.bookings).length) return;
    this.setState({
      filterTabs: BookingFilters.filter(
        (item) =>
          item.filter !== "provisional" ||
          (item.filter === "provisional" &&
            auth.isBroker() &&
            nextProps.dashboardDetails.bookings.provisional_bookings > 0) ||
          (item.filter === "provisional" &&
            auth.isBroker() &&
            auth.hasFeature("manual_bank_transfer")) ||
          (item.filter === "provisional" && auth.isOperator()),
      ),
    });
    if (this.state.filterTabs.length <= 0) return;

    const { filterTabs } = this.state;
    const activeTab = filterTabs.find((item) => item.active)?.filter;
    if (nextProps.path !== activeTab) {
      setActiveItem(filterTabs, nextProps.path);
    }
  }

  fillCoordinates(modelKey, address) {
    if (address.place_id !== null || address.place_id !== "") {
      googleApi.getCoordinatesByPlaceId(address.place_id).then((result) => {
        address.latitude = result.lat;
        address.longitude = result.lng;
        this.updateOnSelect(modelKey, address);
      });
    }
  }

  updateOnSelect(modelKey, address) {
    if (address) {
      this.props.updateModel(`${modelName}.${modelKey}`, address.location);
      this.props.updateModel(
        `${modelName}.${modelKey}_info`,
        generateLocationAdditionalInfo(address),
      );
      this.props.updateModel(`${modelName}.${modelKey}_coverage_status`, address.coverage_status);
    }
  }

  updateGeocodeModel(modelKey, value) {
    const address = {
      location: value,
      lat: null,
      lng: null,
      source: config.locationSource[2],
    };
    this.updateOnSelect(modelKey, address);
  }

  onSelectLocation(handleClickOutside, modelKey, address) {
    handleClickOutside();
    if (address.source === "google" && !address.longitude) {
      this.fillCoordinates(modelKey, address);
    } else {
      this.updateOnSelect(modelKey, address);
    }
  }

  reset() {
    if (this.Model) {
      this.props.resetModel(modelName);

      this.handleFormSubmit(this.props.path, {
        bookingOrder: "id",
        bookingSorted: "desc",
        activity: auth.getRole() === "broker_team" ? "own" : "all",
      });
    }
  }

  updateTabList(list, status) {
    this.props.history.push(`/flights/my-bookings/filter/${status}`);
    this.context.store.dispatch(actions.change("isConcat", false));
    this.props.loadDataFunc(status, 1);
    setActiveItem(this.state.filterTabs, status);
  }

  updateDepartureTime(model, value) {
    this.props.updateModel(model, value);
  }

  render() {
    const { isLoading } = this.props;
    if (!this.state.filterTabs.length > 0) return "";

    return (
      <div>
        <FilterTabsComponent
          tabs={this.state.filterTabs}
          changeFunc={this.updateTabList}
          isLoading={isLoading}
        />
        {!this.props.visible ? null : (
          <div className="uk-card uk-card-default uk-card-body">
            <div>
              <Form model={modelName} onSubmit={this.handleFormSubmit.bind(this, this.props.path)}>
                <FilterBookingFieldset
                  modelName={modelName}
                  updateModelFn={this.props.updateModel}
                  activity={this.props.activity}
                  disablePastDate={this.props.disablePastDate}
                  disableFutureDate={this.props.disableFutureDate}
                  updateLocation={this.updateLocation.bind(this)}
                  radioLabels={{
                    label1: "MyBookings",
                    label2: "AllBookings",
                  }}
                  resetModel={this.reset.bind(this)}
                  isSubmitEnabled={false}
                  updateGeocodeModel={this.updateGeocodeModel}
                  onSelectLocation={this.onSelectLocation}
                  from={this.props.from}
                  to={this.props.to}
                  from_info={this.props.from_info}
                  to_info={this.props.to_info}
                  from_coverage_status={this.props.from_coverage_status}
                  to_coverage_status={this.props.to_coverage_status}
                  updateDepartureTime={this.updateDepartureTime}
                />
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    setActiveItem([], "");
    this.props.resetModel(modelName);
  }
}

BookingFiltersComponent.contextTypes = {
  store: PropTypes.object,
};

BookingFiltersComponent.defaultTypes = {
  disablePastDate: true,
  disableFutureDate: false,
};

BookingFiltersComponent.propTypes = {
  visible: PropTypes.bool,
  path: PropTypes.string,
  pageFilters: PropTypes.array,
  disablePastDate: PropTypes.bool,
  disableFutureDate: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetModel: (model) => dispatch(actions.reset(model)),
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      get: (page, filters, type = "confirmed") => getBookingsByFiltering(type, page, filters),
      update: getBookingSuccess,
    },
    dispatch,
  );
};
const mapStateToProps = (state) => ({
  dashboardDetails: state.dashboard,
  ...filterMapStateToProps(modelName, state),
});
const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(BookingFiltersComponent);
export { COMPONENT as BookingFiltersComponent };
