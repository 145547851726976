import React from "react";
import PropTypes from "prop-types";

const CustomValidateError = (props) => {
  const firstRule = props.children.shift();
  const className = ["ul validation-errors disabled-list gh-input-errors"];

  if (firstRule && firstRule.props) {
    return (
      <ul className={className.concat(props.containerClass).join(" ")} style={props.styles || {}}>
        <li dangerouslySetInnerHTML={{ __html: firstRule.props.children }} />
      </ul>
    );
  }
  return null;
};

CustomValidateError.defaultProps = {
  containerClass: [],
};

CustomValidateError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  containerClass: PropTypes.array,
};

export { CustomValidateError };
