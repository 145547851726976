import React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteWithSubRoutes, returnRouteUrl } from "../../../../shared";
import { BrokerBookingRequestsComponent } from "../";

const BrokerBookingComponent = ({ match, routes }) => {
  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <RouteWithSubRoutes key={i} title={route.title} {...returnRouteUrl(route, match.url)} />
        );
      })}
      <Route path="/" component={BrokerBookingRequestsComponent} />
    </Switch>
  );
};

export { BrokerBookingComponent };
