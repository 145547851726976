import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ConfirmComponent } from "../../../../common";
import { CardFormComponent } from "../../../../forms";
import {
  cardDeletedSuccess,
  deleteCard,
  getCards,
  getPaymentQuoteDetails,
  newCardSuccess,
  paymentDetailsSuccess,
  RESET_CARD_UPDATE,
  setDefaultCard,
} from "../../../../../actions";
import { StripeCardComponent } from "./stripe-card.component";
import { ManualBankTransfer } from "./manual-bank-tranfer";
import staticService from "../../../../../services/static.service";
import {
  isObjectEmpty,
  SpinnerComponent,
  spinnerInstance,
  SpinnerService,
} from "../../../../../shared";
import auth from "../../../../../services/auth";
import moment from "moment";
import stripeImg from "../../../../../assets/img/powered_by_stripe.png";
import CardsListingOverlay from "./CardsListingOverlay";

class CardsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      card: { card_id: "" },
      isCardDefaultChanged: false,
      showConfirm: false,
      cards: [],
      hasLoaded: false,
      service: new SpinnerService(),
      isUpdated: false,
      cardToRemove: null,
    };

    this.removeCard = this.removeCard.bind(this);
    this.updatePricesOnInit = this.updatePricesOnInit.bind(this);
    this.radioChange = this.radioChange.bind(this);
    this.loadCards = this.loadCards.bind(this);
  }

  renderLoadingSpinner() {
    return (
      <div className={this.props.loaderPosition}>
        <SpinnerComponent service={this.state.service} />
      </div>
    );
  }

  get hidden() {
    return this.state.editMode ? "uk-hidden" : "";
  }

  // eslint-disable-next-line getter-return
  get cards() {
    if (this.props.quoteDetail.departure_time === undefined) this.renderLoadingSpinner();

    if (this.state.hasLoaded) {
      const cards = this.props.cards ? this.props.cards : [];
      const departureDaysCount = moment(this.props.quoteDetail.departure_time).diff(
        new Date(),
        "days",
      );

      const manualIsEnabled = auth.hasFeature("manual_bank_transfer");

      const isBookingPageAndCardsExists =
        !window.location.pathname.includes("banking/cards") && cards.length;
      const stripePaymentIsNotAllowed = departureDaysCount < 9 && manualIsEnabled;
      const operatorStripeIsNotVerified =
        this.props.quoteDetail?.operator_stripe_verification_status === false;

      const showCardsListingOverlay =
        isBookingPageAndCardsExists && (stripePaymentIsNotAllowed || operatorStripeIsNotVerified);
      const cardsOverlayMessage = operatorStripeIsNotVerified
        ? staticService.findByAlias("stripeOnboardingOperatorNotVerified")
        : staticService.findByAlias("stripeNotAvailable");

      const ruleToDisplayAddButton =
        auth.hasFeature("store_payment_cards") ||
        (auth.hasFeature("store_payment_card") && this.props.cards.length < 1) ||
        !auth.hasFeature("store_payment_card");

      const disableAddCard =
        departureDaysCount < 9 &&
        manualIsEnabled &&
        !window.location.href.includes("banking/cards");

      return this.state.editMode ? (
        <CardFormComponent
          visible={true}
          changeCard={this.changeCard.bind(this)}
          card={this.state.card}
          newCardAction={this.props.newCardAction}
          onSuccess={this.onSuccessCard.bind(this)}
          updateOnAddNewCard={this.radioChange}
          quoteDetail={this.props.quoteDetail}
        />
      ) : (
        <div>
          <div className="uk-position-relative">
            <CardsListingOverlay show={showCardsListingOverlay} message={cardsOverlayMessage} />
            {cards.map((card, key) => (
              <StripeCardComponent
                key={key}
                cardInfo={card}
                changeCard={this.editCard.bind(this)}
                updateModelFunc={this.radioChange}
                selectedCard={this.state.card}
                deleteCard={this.openConfirmDialog.bind(this)}
                setDefaultCard={this.props.setDefaultCard.bind(this, card)}
              />
            ))}
          </div>

          <div data-uk-grid className="gh-add-card-btns">
            {ruleToDisplayAddButton && !showCardsListingOverlay && (
              <div>
                <a
                  onClick={disableAddCard ? null : this.changeCard.bind(this)}
                  dangerouslySetInnerHTML={{
                    __html: staticService.findByAlias("AddNewCard"),
                  }}
                  className={`gh-cords-component-add-new-btn ${
                    disableAddCard ? "btn-style-disabled" : ""
                  }`}
                />
              </div>
            )}

            <div className="uk-text-left gh-powered-stripe">
              <a href={"http://www.stripe.com"} target="_blank" rel="noreferrer">
                <img src={stripeImg} alt="Powered by Stripe" />
              </a>
            </div>
          </div>

          {!window.location.pathname.includes("banking/cards") ? this.manualBankTransfer : ""}
        </div>
      );
    }

    this.renderLoadingSpinner();
  }

  get manualBankTransfer() {
    const isEnabled = auth.hasFeature("manual_bank_transfer");

    if (this.state.hasLoaded) {
      if (isEnabled)
        return (
          <ManualBankTransfer updateModelFunc={this.radioChange} selectedCard={this.state.card} />
        );
    }

    return null;
  }

  get defaultCard() {
    const cards = this.props.cards ? this.props.cards : [];
    return cards.reduce((prev, card) => {
      if (card.selected) {
        prev = card;
      }
      return prev;
    }, {});
  }

  updatePricesOnInit(cards) {
    const { quoteDetail } = this.props;
    const departureDaysCount = moment(quoteDetail.departure_time).diff(new Date(), "days");
    const isEnabled = auth.hasFeature("manual_bank_transfer");
    const currentCard = cards.find((item) => item.selected);
    this.setState({ isUpdated: true });

    if (currentCard && departureDaysCount >= 9) {
      return this.radioChange(currentCard);
    } else if (isEnabled) {
      return this.radioChange({ card_id: "manual" });
    }
  }

  componentDidMount() {
    this.loadCards();
  }

  componentDidUpdate() {
    if (!isObjectEmpty(this.props.quoteDetail) && !this.state.isUpdated && this.state.hasLoaded) {
      this.updatePricesOnInit(this.props.cards);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.cards !== nextProps.cards) {
      this.setState({ editMode: false });
    }

    if (nextProps.cardUpdated.action === "delete") {
      this.setState({ card: null });
      nextProps.updateReduxAuthModelAfterDelete(nextProps.cardUpdated.data);
      nextProps.resetCardActions();
    }

    if (nextProps.cardUpdated.action === "default-card") {
      this.setState({ editMode: false, isCardDefaultChanged: false });
      nextProps.resetCardActions();
    }
  }

  loadCards() {
    const search = new URLSearchParams(window.location.search);

    let payment_method = "";
    if (search.has("payment_method")) {
      payment_method = search.get("payment_method");
    }

    this.props
      .getCustomerDetails(null, { params: { payment_method } })
      .then(() => {
        this.setState({ hasLoaded: true });
        this.state.service.hide();
      })
      .catch(() => {
        this.setState({ hasLoaded: true });
        this.state.service.hide();
      });
    this.state.service.setProp("type", "default center").show();
  }

  onSuccessCard(card) {
    this.setState({ editMode: false });
    if (this.props.triggerInitMode) {
      this.props.triggerInitMode(false);
    }
    this.props.selectedCard(card);
    if (this.props.paymentView) {
      this.props.getPaymentQuoteDetails(this.props.quoteDetail.id, card.country);
    }
  }

  changeCard(status) {
    if (status === "cancel") {
      this.setState({ editMode: false });
      this.props.triggerInitMode(false);
      return this.updatePricesOnInit(this.props.cards);
    }

    this.setState({
      editMode: !this.state.editMode,
      card: null,
      isCardDefaultChanged: false,
    });

    if (this.props.triggerInitMode) {
      this.props.triggerInitMode(!this.state.editMode);
    }
    const selectedCard = this.props.cards.find((card) => card.selected);

    if (this.props.paymentView) {
      this.props
        .getPaymentQuoteDetails(this.props.quoteDetail.id, selectedCard.country)
        .then(() => this.props.selectedCard(selectedCard));
    }
  }

  editCard(card) {
    this.setState({
      editMode: !this.state.editMode,
      card: card,
      isCardDefaultChanged: false,
    });
  }

  radioChange(card) {
    const { paymentView, getPaymentQuoteDetails, selectedCard, setPaymentDetails } = this.props;
    spinnerInstance.show();

    if (paymentView) {
      getPaymentQuoteDetails(
        this.props.quoteDetail.id,
        card.card_id !== "manual" ? card.country : "manual_bank_transfer",
      )
        .then((details) => {
          setPaymentDetails(details);
          spinnerInstance.hide();
        })
        .catch(() => {
          spinnerInstance.hide();
        });
    }

    this.setState({
      isCardDefaultChanged: !card.default,
      card: card,
    });
    selectedCard(card);
  }

  removeCard() {
    const requestResponse = this.props.deleteCard(this.state.cardToRemove);
    if (this.props.paymentView) {
      requestResponse.finally(() => {
        if (!this.props.cards.length) {
          this.radioChange({ card_id: "manual" });
        } else {
          const defaultCard = this.props.cards.find((card) => card.default === true);

          if (defaultCard) {
            this.radioChange(defaultCard);
          } else {
            this.radioChange(this.props.cards[0]);
          }
        }
        this.closeDialog();
      });
      // should update cards list after removing even default card
      this.loadCards();
    }
  }

  openConfirmDialog(cardObj) {
    const card = cardObj.card_id ? { ...cardObj } : { ...cardObj, card_id: cardObj.id };
    this.setState({ cardToRemove: card });
    this.confirmComponent.openModal();
  }

  closeDialog() {
    this.setState({ cardToRemove: null });
    this.confirmComponent.closeModal();
  }

  render() {
    const classNames = ["uk-accordion-content", "gh-accordion-content", "uk-margin-remove"];

    if (!this.props.hasPadding) classNames.push("uk-padding-remove");

    return (
      <div>
        <ConfirmComponent
          ref={(confirmComponent) => (this.confirmComponent = confirmComponent)}
          info={staticService.findByAlias("deleteCardConfirmMessage")}
          onSelect={this.removeCard}
          onReject={this.closeDialog.bind(this)}
          buttonsProps={{
            continue: {
              title: staticService.findByAlias("continue"),
              css: "uk-button-danger",
            },
            cancel: {
              title: staticService.findByAlias("cancel"),
              css: "uk-button-default",
            },
          }}
          showCloseBtn={true}
          modalProps={{
            id: "delete-card",
            title: staticService.findByAlias("removeCardModalTitle"),
            onClose: this.closeDialog.bind(this),
          }}
        />
        <div
          className={
            window.innerWidth > 576
              ? "uk-background-default uk-padding uk-padding-small-bottom"
              : this.state.editMode
              ? "uk-background-default uk-padding-small"
              : ""
          }
        >
          {window.innerWidth > 576 ? (
            <h4 className={this.hidden}>{staticService.findByAlias("wallet")}</h4>
          ) : (
            ""
          )}
          {this.cards}
        </div>
        {/*</SectionBlockComponent>*/}
      </div>
    );
  }
}

CardsComponent.contextTypes = {
  store: PropTypes.object,
};

CardsComponent.propTypes = {
  hasPadding: PropTypes.bool,
  paymentView: PropTypes.bool,
  loaderPosition: PropTypes.string,
  triggerInitMode: PropTypes.func,
  selectedCard: PropTypes.func,
  newCardAction: PropTypes.string,
  getPaymentQuoteDetails: PropTypes.func,
  quoteDetail: PropTypes.object,
  getCustomerDetails: PropTypes.func,
  setPaymentDetails: PropTypes.func,
  cards: PropTypes.array,
  deleteCard: PropTypes.func,
  setDefaultCard: PropTypes.func,
  updateReduxAuthModelAfterDelete: PropTypes.func,
  cardUpdated: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  resetCardActions: PropTypes.func,
};

function mapStateToProps({ auth, cardUpdated, confirm, cards, quoteDetail }) {
  const response = {
    ...auth,
    ...cards,
    dialogVisibility: cardUpdated.action === "delete" ? false : confirm,
    cardUpdated,
    quoteDetail,
  };

  return response;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomerDetails: getCards,
      setDefaultCard: setDefaultCard,
      deleteCard: deleteCard,
      updateReduxAuthModelAfterDelete: (data) => dispatch(cardDeletedSuccess(data)),
      updateReduxAuthModel: (data) => dispatch(newCardSuccess(data)),
      resetCardActions: () => dispatch({ type: RESET_CARD_UPDATE }),
      getPaymentQuoteDetails,
      paymentDetailsSuccess,
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(CardsComponent);
export { COMPONENT as CardsComponent };
