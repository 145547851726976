import React from "react";
import { BlockComponent } from "../../../../common";
import staticService from "../../../../../services/static.service";
import { NEW_CARD_AND_DEFAULT } from "../../../../../actions";
import { CardsComponent } from "./";

class BrokerManageCardsComponent extends React.PureComponent {
  render() {
    return (
      <BlockComponent
        visible={true}
        parentProps={{
          className: `uk-margin uk-position-relative ${
            window.innerWidth < 576 ? "uk-margin-large-bottom" : ""
          }`,
        }}
      >
        <h2
          className="gh-text-slim uk-margin-remove-top"
          dangerouslySetInnerHTML={{ __html: staticService.findByAlias("cardManagement") }}
        />
        <CardsComponent
          hasPadding={true}
          paymentView={false}
          newCardAction={NEW_CARD_AND_DEFAULT}
          selectedCard={() => {}}
          loaderPosition={"card-loader"}
        />
      </BlockComponent>
    );
  }
}

export { BrokerManageCardsComponent };
