import React, { Component } from "react";
import PropTypes from "prop-types";
import { ModalComponent } from "../../../../shared";
import { config } from "../../../../configs";
import auth from "../../../../services/auth";

class GalleryModalComponent extends Component {
  render() {
    return (
      <div>
        <ModalComponent
          title={this.props.modalTitle}
          ref={(modal) => (this.modal = modal)}
          options={{ bgClose: false, escClose: false }}
          id={"premiumGalleryModalTitle"}
          onClose={this.props.onClose}
        >
          <p dangerouslySetInnerHTML={{ __html: this.props.modalContent }} />
          <div className="uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow>
            <ul className="disabled-list uk-slideshow-items">
              {this.props.items.map((image, index) => (
                <li key={index}>
                  <img
                    src={config.apiDomain + `/aircraft-image/${image.id}?token=${auth.getToken()}`}
                    alt=""
                    data-uk-cover
                  />
                </li>
              ))}
            </ul>

            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover"
              href="#"
              data-uk-slidenav-previous
              data-uk-slideshow-item="previous"
            >
              Prev
            </a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover"
              href="#"
              data-uk-slidenav-next
              data-uk-slideshow-item="next"
            >
              Next
            </a>
          </div>
        </ModalComponent>
      </div>
    );
  }
}

GalleryModalComponent.contextTypes = {
  store: PropTypes.object,
};

GalleryModalComponent.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalContent: PropTypes.string,
  items: PropTypes.array,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GalleryModalComponent.defaultProps = {
  visible: false,
  items: [],
};

export { GalleryModalComponent };
