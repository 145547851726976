import React from "react";
import staticService from "../../../../../services/static.service";
import PadSelectionMap from "../../../../common/PadSelectionMap/PadSelectionMap";
import InfoBox from "../../../../common/InfoBox";

const ReviewLegsSummaryComponent = (props) => {
  const flyDirCoords = [...props.legAddresses].map((itm) => ({
    from: itm.from_coords,
    to: itm.to_coords,
    pad: itm,
    key: itm.id,
  }));

  return (
    <div className="gh-simple-section gh-review-summary">
      <h4
        dangerouslySetInnerHTML={{
          __html: staticService.findByAlias("legsSummary"),
        }}
      />

      <PadSelectionMap
        classNames="uk-margin gh-pad-selection"
        flyDirCoords={flyDirCoords}
        screenDimensions={props.screenDimensions}
        withPads={false}
        review
        updateChosenSitesByRole
      />

      <InfoBox alias="pinsOnMapAreOnlySuggestions" />
    </div>
  );
};

export { ReviewLegsSummaryComponent };
