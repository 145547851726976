import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./customProgressBar.css";

const CustomProgressBar = (props) => {
  const { percent, additionalClass, animation } = props;
  const [style, setStyle] = React.useState({});

  useEffect(() => {
    const newStyle = {
      opacity: 1,
      width: `${percent}%`,
    };

    if (!animation) {
      return setStyle(newStyle);
    }

    const timer = () =>
      setTimeout(() => {
        setStyle(newStyle);
      }, 100);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, [percent]);

  useEffect(() => {
    return function cleanup() {
      setStyle({});
    };
  }, []);

  return (
    <div className={`gh-custom-progress-bar ${additionalClass}`}>
      <div className={`gh-custom-progress-bar-done ${animation ? "animated" : ""}`} style={style} />
    </div>
  );
};

CustomProgressBar.defaultProps = {
  additionalClass: "",
  animation: true,
};

CustomProgressBar.propTypes = {
  percent: PropTypes.number,
  additionalClass: PropTypes.string,
  animation: PropTypes.bool,
};

export default CustomProgressBar;
