/**
 * handleHookFormErrors - "Handle server side errors for react-hook-form"
 *
 * @param {Function} setError Function from hook-form to set errors
 * @param {Object} errors Server side errors
 * @param {Boolean} shouldFocus Should to focus on the input
 */

const handleHookFormErrors = (setError, errors, shouldFocus = true) => {
  if (!setError || !errors) return;

  Object.keys(errors).forEach((key) => {
    setError(key, { type: "server", message: errors[key] }, { shouldFocus: shouldFocus });
  });
};

export default handleHookFormErrors;
