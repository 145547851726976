import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AircraftComponent from "../AircraftComponent";
import staticService from "../../../services/static.service";
import Tooltip from "../Tooltip";
import uid from "../../../utils/uid";
import CheckboxFuncComponent from "../CheckboxFuncComponent";

const QuoteAircrafts = (props) => {
  const {
    editingQuote,
    editingQuote: { helicopters, extra_helicopters },
    leg,
    leg: { selected_aircrafts, selected_additional_aircrafts },
    legIndex,
    trigger,
    unregister,
    readOnly,
    updateQuoteDetail,
    aircraftKey,
    originalQuote,
    setError,
    setValue,
    clearErrors,
  } = props;
  const titleItem = useRef();

  const [hideAddBtn, setHideAddBtn] = useState(false);

  useEffect(() => {
    let additionalSelectedAircrafts =
      selected_additional_aircrafts.length > 1 || selected_additional_aircrafts[0].id
        ? selected_additional_aircrafts
        : [];

    if (
      [...selected_aircrafts, ...additionalSelectedAircrafts].length >=
        helicoptersToDisplay.length ||
      readOnly
    ) {
      setHideAddBtn(true);
    } else {
      setHideAddBtn(false);
    }
  }, [selected_aircrafts, selected_additional_aircrafts, readOnly]);

  const updateAircraftLegs = (updatedSelectedHelicopters, sameAsPrevious) => {
    let skip = false;
    return editingQuote.legs.map((item, index) => {
      if (index < legIndex) {
        return item;
      } else if (legIndex === index) {
        return {
          ...item,
          [aircraftKey]: updatedSelectedHelicopters,
          helicopter_same_as_previous: sameAsPrevious ? sameAsPrevious : false,
        };
      } else if (legIndex < index && item.helicopter_same_as_previous) {
        if (skip) {
          return item;
        } else {
          return {
            ...item,
            [aircraftKey]: updatedSelectedHelicopters.map((item) => ({ ...item, ...uid() })),
            helicopter_same_as_previous: true,
          };
        }
      } else {
        skip = true;
        return item;
      }
    });
  };

  const handleChangeHelicopter = (e, indexKey) => {
    const crew_size_default = editingQuote?.crew_size || 1;
    const value = e.target.value;

    const baseHelicopter = helicopters.find((item) => item.id === +value);
    const extraHelicopter = extra_helicopters.find((item) => item.id === +value);
    const selectedHelicopter = baseHelicopter || extraHelicopter;

    setValue(`crew_size${indexKey}`, crew_size_default);
    setValue(`owner_approval_${indexKey}`, 0);
    clearErrors([`crew_size${indexKey}`, `owner_approval_${indexKey}`]);

    //crew_size: crew_size_default => sets crew_size based on request requirements
    const updatedSelectedHelicopters = leg[aircraftKey].map((item) => {
      if (indexKey === item.uid || indexKey === item.id) {
        return {
          ...selectedHelicopter,
          uid: indexKey,
          owner_approval: 0,
          crew_size: crew_size_default,
        };
      } else {
        return item;
      }
    });

    const updatedLegs = updateAircraftLegs(updatedSelectedHelicopters);

    updateQuoteDetail({ ...editingQuote, legs: updatedLegs }, true);
  };

  const handleChangeAircraftOption = (key, e, indexKey) => {
    const value = e.target.value;

    const updatedSelectedHelicopters = leg[aircraftKey].map((item) => {
      if (indexKey === item.uid || indexKey === item.id) {
        return { ...item, [key]: +value };
      } else {
        return item;
      }
    });

    const updatedLegs = updateAircraftLegs(updatedSelectedHelicopters);

    updateQuoteDetail({ ...editingQuote, legs: updatedLegs, skip_price_calculator: true }, true);
  };

  const handleDeleteRow = (aircraftId, indexKey) => () => {
    if (
      aircraftKey === "selected_additional_aircrafts" &&
      selected_additional_aircrafts.length === 1
    ) {
      const updatedLegs = updateAircraftLegs([uid()]);

      return updateQuoteDetail({ ...editingQuote, legs: updatedLegs }, true);
    }

    const updatedAircrafts = leg[aircraftKey].filter(({ id }) => id !== aircraftId);

    const updatedLegs = updateAircraftLegs(updatedAircrafts);

    updateQuoteDetail({ ...editingQuote, legs: updatedLegs }, true);

    unregister(`aircraft_model${indexKey}`);
  };

  const handleAddAircraft = async () => {
    const indexRow = leg[aircraftKey].length - 1;

    if (aircraftKey === "selected_additional_aircrafts") {
      if (Object.keys(leg[aircraftKey][indexRow]).length === 1) {
        return setError(
          `alternative_aircraft_model${leg[aircraftKey][indexRow].uid}`,
          { type: "manual", message: staticService.findByAlias("helicopterSelectionError") },
          { shouldFocus: true },
        );
      } else if (!leg[aircraftKey][indexRow].crew_size) {
        return setError(
          `crew_size${leg[aircraftKey][indexRow].uid}`,
          { type: "manual", message: staticService.findByAlias("crewError") },
          { shouldFocus: true },
        );
      }
    }

    const updatedLegs = editingQuote.legs.map((item, index) => {
      if (index === legIndex) {
        return {
          ...item,
          [aircraftKey]: [...leg[aircraftKey], uid()],
          helicopter_same_as_previous: false,
        };
      } else {
        return item;
      }
    });

    if (leg[aircraftKey].length) {
      let modelFieldName = `${
        aircraftKey === "selected_additional_aircrafts" ? "alternative_" : ""
      }aircraft_model${leg[aircraftKey][indexRow].uid}`;
      let crewSizeFieldName = `crew_size${leg[aircraftKey][indexRow].uid}`;

      const fieldsIsValid = await trigger([modelFieldName, crewSizeFieldName], {
        shouldFocus: true,
      });

      if (fieldsIsValid) {
        updateQuoteDetail({ ...editingQuote, legs: updatedLegs }, false);
      }
    } else {
      updateQuoteDetail({ ...editingQuote, legs: updatedLegs }, false);
    }
  };

  const helicoptersToDisplay = helicopters.filter(
    (item) =>
      (item.hasOwnProperty("is_verified") && !!item.is_verified) ||
      !item.hasOwnProperty("is_verified"),
  );

  const updateSameSelectedHelicopters = (value) => {
    let alternativeKey =
      aircraftKey === "selected_aircrafts" ? "selected_additional_aircrafts" : "selected_aircrafts";
    let skip = false;

    const updatedLegs = editingQuote.legs.map((item, index) => {
      if (index < legIndex) {
        return item;
      } else if (legIndex === index) {
        return {
          ...item,
          [aircraftKey]: value
            ? editingQuote.legs[legIndex - 1][aircraftKey]
            : originalQuote.legs[legIndex][aircraftKey],
          [alternativeKey]: value
            ? editingQuote.legs[legIndex - 1][alternativeKey]
            : originalQuote.legs[legIndex][alternativeKey],
          helicopter_same_as_previous: value,
        };
      } else if (legIndex < index && item.helicopter_same_as_previous) {
        if (skip) {
          return item;
        } else {
          return {
            ...item,
            [aircraftKey]: value
              ? editingQuote.legs[legIndex - 1][aircraftKey]
              : originalQuote.legs[legIndex][aircraftKey],
            [alternativeKey]: value
              ? editingQuote.legs[legIndex - 1][alternativeKey]
              : originalQuote.legs[legIndex][alternativeKey],
            helicopter_same_as_previous: true,
          };
        }
      } else {
        skip = true;
        return item;
      }
    });

    updateQuoteDetail({ ...editingQuote, legs: updatedLegs }, true);
  };

  return (
    <div
      className={`gh-aircraft-wrapper ${readOnly ? "read-only" : ""} ${
        aircraftKey === "selected_additional_aircrafts" ? "alternative-aircrafts" : ""
      }`}
    >
      {aircraftKey === "selected_aircrafts" ? (
        <div className="gh-aircraft-wrapper-title-line">
          <h4 className="title-item" ref={titleItem}>
            {staticService.findByAlias("aircraft")}
          </h4>
        </div>
      ) : (
        ""
      )}

      {leg[aircraftKey] &&
        leg[aircraftKey].map((item, idx) => (
          <AircraftComponent
            {...props}
            handleChangeHelicopter={handleChangeHelicopter}
            handleDeleteRow={handleDeleteRow(item.id, item.uid)}
            handleChangeAircraftOption={handleChangeAircraftOption}
            indexKey={item.uid || item.id}
            helicopter={item}
            index={idx}
            key={idx}
            selectedHeliList={leg[aircraftKey]}
            additionalClassName={`${idx === 0 ? "first-row" : ""} ${
              leg[aircraftKey].length - 1 === idx ? "last-row" : ""
            }`}
          />
        ))}

      {aircraftKey === "selected_additional_aircrafts" &&
      !leg["selected_additional_aircrafts"].length &&
      !readOnly ? (
        <h4 className="title-item" style={{ marginTop: 30, display: "flex", alignItems: "center" }}>
          {staticService.findByAlias("alternativeTitleQuote")}
          <div style={{ marginLeft: 15 }}>
            <Tooltip
              pos="top-left"
              refEl="primaryAircraftTooltip"
              wrapperClassName="gh-alternative-helicopters-tooltip"
            >
              {staticService.findByAlias("primaryAircraftTooltip")}
            </Tooltip>
          </div>
        </h4>
      ) : (
        ""
      )}

      {!hideAddBtn ? (
        <div className="gh-aircraft-bottom-row">
          <div className="gh-add-aircraft-btn btn-style" onClick={handleAddAircraft}>
            + {staticService.findByAlias("addAircraft")}
          </div>
        </div>
      ) : (
        ""
      )}

      {legIndex > 0 && aircraftKey === "selected_additional_aircrafts" ? (
        <div className="gh-aircraft-bottom-row --flex-end">
          <CheckboxFuncComponent
            label={staticService.findByAlias("sameAsPrevious")}
            checked={leg.helicopter_same_as_previous}
            onClick={() => {
              updateSameSelectedHelicopters(!leg.helicopter_same_as_previous);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

QuoteAircrafts.propTypes = {
  editingQuote: PropTypes.object,
  leg: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
  index: PropTypes.number,
  unregister: PropTypes.func,
  trigger: PropTypes.func,
  readOnly: PropTypes.bool,
  legIndex: PropTypes.number,
  aircraftKey: PropTypes.string,
  originalQuote: PropTypes.object,
  setError: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
};

QuoteAircrafts.defaultProps = {};

export default QuoteAircrafts;
