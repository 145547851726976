import React from "react";
import PropTypes from "prop-types";

const Item = (props) => {
  const tagProps = props.attrProps || {};
  return (
    <li
      className={`${
        window.location.pathname !== "/account/register"
          ? ""
          : "uk-card uk-card-default uk-card-body uk-margin-small-bottom"
      }`}
      {...tagProps}
    >
      {props.children}
    </li>
  );
};

Item.propTypes = {
  attrProps: PropTypes.object,
};

export { Item };
