import React from "react";

export default function PricingPlanAlert() {
  return (
    <div className="pricing-plan-alert alert-warn uk-margin-medium-top">
      If you have any questions about your pricing plan or will like to learn about others plans available on GetHeli
      more please contact <a href="mailto:contact@getheli.com">contact@getheli.com</a>.
    </div>
  );
}
