import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../../services/static.service";
import Tooltip from "../../../../common/Tooltip";

const QuoteDepositDetails = ({ bookingDetail, calculatedPrice, currency }) => {
  const isBookingConfirmed = bookingDetail?.status === "confirmed";
  const isBookingProvisional = bookingDetail?.status === "provisional";
  // const isOperatorAndBookingInit = bookingStatus === "booking_init" && Auth.isOperator();
  const isSecuredWithDeposit = calculatedPrice?.secure_with_deposit !== 0;

  const hideComponent = isBookingConfirmed || isBookingProvisional ? "--hide" : "";

  return (
    <div className={`gh-quote-details-deposit-row ${hideComponent}`}>
      {isSecuredWithDeposit ? (
        <React.Fragment>
          <div className="gh-quote-details-deposit-value">
            {staticService.findByAlias("depositPaymentLabel")}
          </div>
          <div className="gh-quote-details-deposit-key">
            <span>
              {currency} {calculatedPrice.deposit_amount?.amount}
            </span>
          </div>
        </React.Fragment>
      ) : (
        <div className="gh-quote-details-deposit-value --no-deposit">
          {staticService.findByAlias("noDepositPaymentLabel")}
          <Tooltip
            pos="top-left"
            refEl="self"
            wrapperClassName="uk-margin-small-left gh-bigger-icon"
          >
            {staticService.findByAlias("noDepositPaymentTooltip")}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

QuoteDepositDetails.propTypes = {
  bookingDetail: PropTypes.object,
  calculatedPrice: PropTypes.object,
  currency: PropTypes.string,
};
QuoteDepositDetails.defaultProps = {};
export default QuoteDepositDetails;
