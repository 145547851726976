import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import UIkit from "uikit";
import logo from "../../assets/img/svg/logoWhite.svg";
import staticService from "../../services/static.service";
import { Nav } from "./nav.component";
import Auth from "../../services/auth";
import closeIcon from "../../assets/img/home/closeMenu.svg";
import PublicMobileMenu from "./PublicMobileMenu";

class NavWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extraProps: props.extraProps,
      extraOptions: {},
      logoPath: Auth.isAuthenticated() ? "/dashboard" : "/",
      mobileNavVisible: false,
    };
    this.beforeNav = this.beforeNav.bind(this);
    this.afterNav = this.afterNav.bind(this);
    this.componentProps = this.componentProps.bind(this);
    this.filteredNav = this.filteredNav.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.toggleMobileNavigationHandler = this.toggleMobileNavigationHandler.bind(this);
  }

  componentProps() {
    return {
      to: "/",
      className: "uk-text-uppercase gh-req-quotes",
    };
  }

  componentDidMount() {
    UIkit.toggle("#offcanvas");
  }

  handleRedirect(url, anchor = "") {
    localStorage.setItem("scroll", anchor);
    window.location.href = url;
  }

  beforeNav(options = {}) {
    return (
      <div className={options.className || "uk-navbar-left"} style={{ zIndex: 1 }}>
        <a href={this.state.logoPath} className={"uk-navbar-item uk-logo gh-logo navbar-logo-item"}>
          <img
            src={logo}
            className="uk-responsive-height"
            alt={staticService.findByAlias("appName")}
          />
        </a>
      </div>
    );
  }

  path(page) {
    if (page.paths) {
      return page.paths[Auth.getMainRole()];
    }
    return page.path;
  }

  filteredNav() {
    if (Auth.getMainRole() === "broker") {
      return this.pages.filter((page) => {
        if (!Auth.hasFeature("empty_leg_markeplace") && page.paths)
          return page.paths.broker !== "/marketplace/empty-legs";
        return page.path !== "/account/fleet" && page.path !== "/price-calculator";
      });
    } else {
      return this.pages.filter(
        (page) => page.path !== "/marketplace" && page.path !== "/marketplace/pipelines",
      );
    }
  }

  toggleMobileNavigationHandler(status) {
    return () => {
      if (status) {
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("html").style.overflowY = "unset";
      }
      this.setState({ mobileNavVisible: status });
    };
  }

  afterNav() {
    const pathList = [];
    const currentPathName = window.location.pathname.split("/")[1];

    if (this.props.showNav) {
      return [
        <div
          key={0}
          className={
            Auth.isAuthenticated() ? "uk-navbar-right uk-hidden@l" : "uk-navbar-right uk-hidden@m"
          }
        >
          <button
            className="uk-navbar-toggle"
            data-uk-icon
            onClick={this.toggleMobileNavigationHandler(true)}
          >
            <svg
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9.99993H26.7143"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1.42852H26.7143"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 18.5713H26.7143"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>,
        <div key="mobile-nav">
          <div
            role="banner"
            className="gh-side-menu-box"
            style={{
              display: this.state.mobileNavVisible ? "block" : "none",
            }}
            onClick={this.toggleMobileNavigationHandler(false)}
          />
          <div
            className={`gh-side-menu-inner ${
              Auth.isAuthenticated() ? "gh-offcanvas-bar-logged" : "gh-offcanvas-bar"
            }`}
            style={{
              transform: `translateX(${this.state.mobileNavVisible ? 0 : "100%"})translateY(86px)`,
            }}
          >
            {pathList.includes(currentPathName) ? (
              <>
                <button
                  className="uk-offcanvas-close"
                  type="button"
                  onClick={this.toggleMobileNavigationHandler(false)}
                >
                  <img src={closeIcon} alt="close" />
                </button>
                <ul className="disabled-list uk-nav uk-nav-primary uk-nav-center uk-margin-medium-top">
                  {this.filteredNav().map((page, index) => {
                    return (
                      <li key={index} {...page.props.listProp}>
                        {page.isDropdown ? (
                          Nav.getDropdownTemplate("gh-dropdown-mobile")
                        ) : (
                          <NavLink
                            {...page.props.linkProp}
                            to={this.path(page)}
                            activeClassName="uk-active"
                          >
                            {page.paths &&
                            page.paths[Auth.getMainRole()] === "/marketplace/empty-legs" &&
                            Auth.isAuthenticated() &&
                            Auth.getMainRole() === "broker" ? (
                              <span
                                style={{ color: "#1abc9c" }}
                                dangerouslySetInnerHTML={{
                                  __html: staticService.findByAlias("brokerMarketplaceEmptyLegTab"),
                                }}
                              />
                            ) : (
                              <span
                                // style={{ color: "#1abc9c" }}
                                dangerouslySetInnerHTML={{
                                  __html: staticService.findByAlias(page.title),
                                }}
                              />
                            )}
                          </NavLink>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <PublicMobileMenu onCloseMenu={this.toggleMobileNavigationHandler} />
            )}
          </div>
        </div>,
      ];
    }
  }

  get pages() {
    return this.props.pages.filter((page) => {
      if ((Auth.isAuthenticated() && page.onlineUser === "online") || page.onlineUser === "both") {
        return true;
      }

      return !Auth.isAuthenticated() && page.onlineUser === "offline";
    });
  }

  render() {
    const listClasses = [
      "disabled-list",
      "uk-grid-small",
      "uk-flex-inline",
      "uk-flex-middle",
      "uk-flex-nowrap",
    ];

    return (
      <Nav
        pages={this.filteredNav()}
        showNavigation={this.props.showNav}
        navProps={{ className: this.state.extraProps.navClassName }}
        before={this.beforeNav}
        extraProps={this.state.extraOptions}
        after={this.afterNav}
        ulProps={{ className: listClasses.join(" ") }}
        dashboardModel={this.props.dashboardModel}
        handleRedirect={this.handleRedirect}
        activePath={this.props?.activeRoute?.path}
      />
    );
  }
}

NavWrapper.propTypes = {
  activeRoute: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  extraProps: PropTypes.object,
  showNav: PropTypes.bool,
};

NavWrapper.defaultProps = {
  extraProps: {
    navClassName: "uk-navbar",
  },
  showNav: true,
};

export { NavWrapper };
