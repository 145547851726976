import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./QuoteDetailsManageButtons.css";
import staticService from "../../../../../services/static.service";
import { ConfirmComponent } from "../../../../common";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getDeclineReasons } from "../../../../../actions";
import WithdrawComponent from "../WithdrawComponent";
import Button from "../../../../common/Button";
import QuoteDetailsBookingModalContent from "../QuoteDetailsBookingModalContent";
import { ModalComponent } from "../../../../../shared";
import QuoteGeneralInfoWithChat from "../QuoteGeneralInfoWithChat";
import { QUOTE_OPEN, QUOTE_REJECTED, QUOTE_SELECTED } from "../../../../../configs";

const QuoteDetailsManageButtons = (props) => {
  if (props.visibility === false) return "";
  const {
    requestID,
    quoteID,
    originPath,
    getDeclineReasons,
    reasons = [],
    quoteStatus,
    bookingRequestStatus,
    quoteDepartureTime,
    quotePrice,
    parentUrl,
    history,
    payment,
    hasQuoteToExpired,
  } = props;

  const withdrawModalRef = useRef(null);
  const bookingModalRef = useRef(null);
  const amendModalRef = useRef(null);
  const [quoteDetailsBookingModalTitle, setQuoteDetailsBookingModalTitle] = useState(
    staticService.findByAlias(
      payment && payment.payment_method === 2
        ? "confirmThisProvisionalBooking"
        : "confirmBookingModalTitle",
    ),
  );
  const closeModal = (ref) => {
    ref.current.closeModal();
  };
  const openModal = (ref) => {
    ref.current.open();
  };
  const openWithdrawModal = () => {
    getDeclineReasons().then(() => withdrawModalRef.current.openModal());
  };

  const showButtons = quoteStatus === QUOTE_OPEN || quoteStatus === QUOTE_SELECTED;
  const isRejected = quoteStatus === QUOTE_REJECTED;

  const handleAmendQuoteClick = () => {
    if (quoteStatus === "booking_init") {
      amendModalRef.current.open();
    } else {
      history.push({
        pathname: `/marketplace/trip/quote/${requestID}`,
        search: `?quote_id=${quoteID}`,
        state: { originPath },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="gh-quote-details-manage-buttons-wrapper uk-flex uk-flex-between uk-flex-middle">
        {showButtons && (
          <div className="gh-quote-details-manage-buttons-left-wrapper">
            <Button
              onClick={handleAmendQuoteClick}
              type="button"
              variant="text"
              color="dark"
              className="gh-quote-details-manage-buttons"
              disabled={hasQuoteToExpired}
              tooltipProps={{
                message: hasQuoteToExpired
                  ? staticService.findByAlias("requestIsExpiredTooltip")
                  : "",
              }}
            >
              Amend Quote
            </Button>
            <Button
              onClick={openWithdrawModal}
              type="button"
              color="danger"
              variant="text"
              className="gh-quote-details-manage-buttons"
            >
              Withdraw Quote
            </Button>
          </div>
        )}
        <div
          className={`gh-quote-details-manage-buttons-right-wrapper ${
            isRejected ? "uk-margin-auto-left" : ""
          }`}
        >
          {quoteStatus === QUOTE_SELECTED ? (
            <div>
              <Button
                onClick={() => openModal(bookingModalRef)}
                type="button"
                variant="text"
                color="success"
                className="gh-quote-details-manage-buttons"
                disabled={hasQuoteToExpired}
                tooltipProps={{
                  message: hasQuoteToExpired
                    ? staticService.findByAlias("requestIsExpiredTooltip")
                    : "",
                }}
              >
                {staticService.findByAlias(
                  payment && payment.payment_method === 2
                    ? "confirmProvisionalBooking"
                    : "bookingAgreeBtn",
                )}
              </Button>
            </div>
          ) : quoteStatus === QUOTE_OPEN || (isRejected && bookingRequestStatus === "open") ? (
            <Button
              onClick={{
                pathname: `/marketplace/trip/request/${requestID}`,
                state: { originPath },
              }}
              type="link"
              variant="text"
              color="success"
              className={`gh-quote-details-manage-buttons`}
              disabled={hasQuoteToExpired}
              tooltipProps={{
                message: hasQuoteToExpired
                  ? staticService.findByAlias("requestIsExpiredTooltip")
                  : "",
              }}
            >
              {isRejected ? "Quote Again" : "Submit a New Quote"}
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      {showButtons && (
        <div>
          <ConfirmComponent
            ref={withdrawModalRef}
            info={
              <WithdrawComponent
                reasons={[...reasons, "Other info"]}
                quoteID={Number(quoteID)}
                closeModal={() => closeModal(withdrawModalRef)}
              />
            }
            modalProps={{
              id: `widthdraw-modal-${quoteID}`,
              title: staticService.findByAlias("declineReasonsTitle"),
              onClose: () => closeModal(withdrawModalRef),
              options: {
                clsPage: "",
                bgClose: false,
                escClose: false,
              },
            }}
            onSelect={() => {}}
            showCloseBtn
            onReject={() => closeModal(withdrawModalRef)}
          />
          <ModalComponent
            title={quoteDetailsBookingModalTitle}
            ref={bookingModalRef}
            id={"QuoteDetailsBookingModalContent"}
          >
            <QuoteDetailsBookingModalContent
              quoteDepartureTime={quoteDepartureTime}
              quoteID={quoteID}
              requestID={requestID}
              quotePrice={quotePrice}
              parentUrl={parentUrl}
              closeModal={() => closeModal(bookingModalRef)}
              paymentMethod={payment ? payment.payment_method : null}
              changeModalTitle={setQuoteDetailsBookingModalTitle}
              history={history}
            />
          </ModalComponent>
          <ModalComponent
            title={staticService.findByAlias("amendQuote")}
            ref={amendModalRef}
            id={"QuoteDetailsAmendModalContent"}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: staticService.findByAlias(
                  payment && payment.payment_method === 1
                    ? "amendQuoteConfirmation"
                    : "amendQuoteConfirmationManualTransfer",
                ),
              }}
            />
            <div className="uk-flex uk-flex-center uk-margin-medium-top gh-quote-amend-modal-wrapper">
              <Button
                onClick={() => amendModalRef.current.hide()}
                type="button"
                variant="outlined"
                color="light"
                className="uk-margin-small-right gh-quote-amend-modal-btn uk-text-normal"
              >
                Close
              </Button>
              <Button
                onClick={{
                  pathname: `/marketplace/trip/quote/${requestID}`,
                  search: `?quote_id=${quoteID}`,
                  state: { originPath },
                }}
                type="link"
                className="gh-quote-amend-modal-btn"
              >
                Proceed
              </Button>
            </div>
          </ModalComponent>
        </div>
      )}
    </React.Fragment>
  );
};
QuoteDetailsManageButtons.propTypes = {
  quoteID: PropTypes.number.isRequired,
  requestID: PropTypes.number.isRequired,
  originPath: PropTypes.string.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.string),
  getDeclineReasons: PropTypes.func.isRequired,
  quoteStatus: PropTypes.string.isRequired,
  quoteDepartureTime: PropTypes.string,
  quotePrice: PropTypes.string.isRequired,
  parentUrl: PropTypes.string.isRequired,
  visibility: PropTypes.bool,
  history: PropTypes.object,
  bookingRequestStatus: PropTypes.string,
  payment: PropTypes.object,
  hasQuoteToExpired: PropTypes.bool,
};
QuoteGeneralInfoWithChat.defaultProps = {
  visibility: true,
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getDeclineReasons: () => getDeclineReasons("quote") }, dispatch);
};
const mapStateToProps = (state) => ({ reasons: state.requests.reasons });
export default connect(mapStateToProps, mapDispatchToProps)(QuoteDetailsManageButtons);
