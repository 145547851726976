import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";
import { bindActionCreators } from "redux";
import { Control, actions, Form, Errors } from "react-redux-form";
import { submitContact } from "../../actions";
import { validationRules, validationMessages, config } from "../../configs";
import { CustomValidateError, updateModel, BasicMapComponent, MarkerComponent } from "../../shared";
import staticService from "../../services/static.service";
import PhoneInput from "../common/phoneInput";
import { AfterSubmitComponent } from "./after-submit.component";

const { validEmail, required, requiredObject, phoneInputValidation } = validationRules;

class ContactFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleMarkers: [],
      onMapLoad: false,
      submitted: false,
    };
    this.submitForm = this.submitForm.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.action && parseInt(nextProps.action) === 1) {
      this.props.resetModel(this.props.model);
    }
  }

  componentWillUnmount() {
    this.props.resetModel(this.props.model);
  }

  onMarkerCreated(marker) {
    this.setState((prev) => {
      prev.googleMarkers.push(marker);
      return { googleMarkers: prev.googleMarkers };
    });
  }

  get Marker() {
    if (this.state.onMapLoad) {
      return (
        <MarkerComponent
          onClick={() => {}}
          infoWindow={{}}
          onLoad={this.onMarkerCreated.bind(this)}
          position={config.contactMapPosition}
        />
      );
    }
    return null;
  }

  submitForm(form) {
    this.props.submitContact(form).then(() => this.setState({ submitted: true }));
  }
  render() {
    if (!this.props.visible) return null;
    const mapContainerHeight = this.props.screenInnerW < 640 ? "300px" : "auto";
    const containerStyles = { overflow: "hidden", height: mapContainerHeight };
    const containerClasses = ["uk-position-relative"];
    if (this.props.screenInnerW < 640) containerClasses.push("uk-padding-remove-left");

    return (
      <div>
        <div
          data-uk-grid
          className={
            "uk-padding uk-padding-remove-horizontal uk-padding-remove-top uk-child-width-1-2@s uk-margin-remove"
          }
          data-uk-height-match="target: > div > .gh-exact-height"
        >
          <div className="uk-first-column uk-padding-remove-left">
            {this.state.submitted ? (
              <AfterSubmitComponent />
            ) : (
              <Form model={this.props.model} onSubmit={this.submitForm}>
                <div className="gh-exact-height">
                  <div className="uk-width-1-1 uk-align-center">
                    <label
                      className={"required-contact uk-form-label"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("contactFormName"),
                      }}
                    />

                    <Control.text
                      className={"uk-input"}
                      model={".name"}
                      changeAction={updateModel}
                      updateOn="change"
                      validators={{ required }}
                    />
                    <Errors
                      model=".name"
                      show="touched"
                      wrapper={CustomValidateError}
                      messages={{
                        required: validationMessages().requiredMessage.bind(
                          null,
                          staticService.findByAlias("contactFormName"),
                        ),
                      }}
                    />
                  </div>
                  <div className="uk-width-1-1 uk-align-center">
                    <label
                      className={"required-contact uk-form-label"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("contactFormEmail"),
                      }}
                    />

                    <Control.text
                      className={"uk-input"}
                      model={".email"}
                      changeAction={updateModel}
                      updateOn="change"
                      validators={{ required, validEmail }}
                    />
                    <Errors
                      model=".email"
                      show="touched"
                      wrapper={CustomValidateError}
                      messages={{
                        required: validationMessages().requiredMessage.bind(
                          null,
                          staticService.findByAlias("contactFormEmail"),
                        ),
                        validEmail: validationMessages().validEmailMessage,
                      }}
                    />
                  </div>

                  <div className="uk-width-1-1 uk-align-center">
                    <label
                      className={"required-contact uk-form-label"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("phoneNumber"),
                      }}
                    />
                    <div className="uk-inline uk-width-1-1">
                      <Control.text
                        model={".phone"}
                        changeAction={updateModel}
                        updateOn="change"
                        validators={{
                          required: requiredObject("value", true),
                          phoneInputValidation,
                        }}
                        component={PhoneInput}
                      />
                    </div>
                    <Errors
                      model=".phone"
                      show="touched"
                      wrapper={CustomValidateError}
                      messages={{
                        required: validationMessages().requiredMessage.bind(
                          null,
                          staticService.findByAlias("phoneNumber"),
                        ),
                        phoneInputValidation: validationMessages().phoneValidationMessage,
                      }}
                    />
                  </div>

                  <div className="uk-width-1-1 uk-align-center">
                    <label
                      className={"required-contact uk-form-label"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("contactFormSubject"),
                      }}
                    />

                    <Control.select
                      className={"uk-select uk-input gh-bg-white"}
                      model={".subject"}
                      changeAction={updateModel}
                      updateOn="change"
                    >
                      {config.contactSubjects.map((subject) => {
                        return (
                          <option key={subject.id} value={subject.id}>
                            {subject.title}
                          </option>
                        );
                      })}
                    </Control.select>
                  </div>
                  <div className="uk-width-1-1 uk-align-center uk-margin-remove-bottom">
                    <label
                      className={"required-contact uk-form-label"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("contactFormMessage"),
                      }}
                    />

                    <Control.textarea
                      className={"uk-textarea"}
                      model={".message"}
                      changeAction={updateModel}
                      updateOn="change"
                      validators={{ required }}
                    />
                    <Errors
                      model=".message"
                      show="touched"
                      wrapper={CustomValidateError}
                      messages={{
                        required: validationMessages().requiredMessage.bind(
                          null,
                          staticService.findByAlias("contactFormMessage"),
                        ),
                      }}
                    />
                  </div>

                  <div className="uk-hidden@s">
                    <div className="uk-margin-medium-top">
                      <Control.button
                        model={this.props.model}
                        disabled={{ valid: false }}
                        className={"uk-button uk-button-primary uk-align-center"}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: staticService.findByAlias("send"),
                          }}
                        />
                      </Control.button>
                    </div>
                  </div>
                </div>
                <div className="uk-visible@s">
                  <div className="uk-margin-medium-top uk-flex uk-flex-center">
                    <Control.button
                      model={this.props.model}
                      disabled={{ valid: false }}
                      className={"uk-button uk-button-primary uk-align-center"}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: staticService.findByAlias("send"),
                        }}
                      />
                    </Control.button>
                  </div>
                </div>
              </Form>
            )}
          </div>
          <div
            style={{
              height: this.props.screenInnerW > 640 ? 522 : "100%",
              paddingLeft: this.props.screenInnerW < 640 ? 0 : 25,
            }}
          >
            <div className="uk-hidden@s uk-padding-remove-left">
              <div className={"gh-reduce-paragraph-pad"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>
                    <h2
                      className={"gh-text-slim"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("FindUs"),
                      }}
                    />
                    <address
                      className={"gh-address"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("FindUsAddress"),
                      }}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <h2
                      className={"gh-text-slim"}
                      dangerouslySetInnerHTML={{
                        __html: staticService.findByAlias("FollowUs"),
                      }}
                    />
                    <a href="https://www.instagram.com/getheli/" rel="noreferrer" target="_blank">
                      <span
                        className="gh-social-icon"
                        style={{ cursor: "pointer", color: "#1abc9c" }}
                        data-uk-icon={"icon: instagram; ratio: 1.75"}
                      />
                    </a>
                    <a href="https://twitter.com/gethelico" rel="noreferrer" target="_blank">
                      <span
                        className="gh-social-icon"
                        style={{
                          cursor: "pointer",
                          color: "#1abc9c",
                          marginLeft: "20px",
                        }}
                        data-uk-icon={"icon: twitter; ratio: 0.75"}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/getheli/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span
                        className="gh-social-icon"
                        style={{
                          cursor: "pointer",
                          color: "#1abc9c",
                          marginLeft: "20px",
                        }}
                        data-uk-icon={"icon: linkedin; ratio: 1.75"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: this.props.screenInnerW > 640 ? "100%" : "400px",
                position: "relative",
              }}
            >
              <LazyLoad debounce={false} className="gh-exact-height">
                <div
                  style={{ width: "100%", height: "100%", position: "absolute" }}
                  className={"google-map gh-exact-height"}
                >
                  <BasicMapComponent
                    markers={this.state.googleMarkers}
                    totalMarkers={this.state.googleMarkers.length}
                    zoom={15}
                    maxZoom={20}
                    isFitBoundEnabled={false}
                    onLoad={() => {
                      this.setState({ onMapLoad: true });
                    }}
                    onClick={() => {}}
                    centerAroundCurrentLocation={false}
                    position={config.contactMapPosition}
                  >
                    {this.Marker}
                  </BasicMapComponent>
                </div>
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactFormComponent.contextTypes = {
  store: PropTypes.object,
};

ContactFormComponent.propTypes = {
  visible: PropTypes.bool,
  model: PropTypes.string.isRequired,
  screenInnerW: PropTypes.number,
  resetModel: PropTypes.func,
  submitContact: PropTypes.func,
  action: PropTypes.number,
};

const mapStateToProps = ({ contactForm, screenDimensions }) => {
  return {
    ...contactForm,
    ...screenDimensions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      submitContact: submitContact,
      resetModel: (model) => dispatch(actions.reset(model)),
    },
    dispatch,
  );
};

const COMPONENT = connect(mapStateToProps, mapDispatchToProps)(ContactFormComponent);
export { COMPONENT as ContactFormComponent };
