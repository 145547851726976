import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./ViewFullQuoteDetails.css";
import Auth from "../../../services/auth";
import { getQuote, REMOVE_QUOTE_ITEM } from "../../../actions";
import staticService from "../../../services/static.service";
import { isObjectEmpty } from "../../../shared";
import BackLinkStyled from "../../common/BackLinkStyled";
import OperatorCompanyBanner from "../../common/OperatorCompanyBanner";
import QuoteVisualsLayout from "./components/QuoteVisualsLayout";
import QuoteManageButtons from "./components/QuoteManageButtons";
import { EUROPEAN_FEE } from "../../../configs";
import { FullQuoteContext } from "./ViewFullQuoteContext";
import moment from "moment";

const ViewFullQuoteDetails = (props) => {
  /*===========Destructuring===========*/
  const { match, parentRoute, quoteDetail, getQuote, history, removeQuotes, path } = props;
  const [feeSelected, setFeeSelected] = useState(EUROPEAN_FEE);

  const { params } = parentRoute || {};
  const { id: requestId = null } = params || {};
  const { quoteID = null } = match.params || {};

  /*===========Ref===========*/

  /*===========Lifecycles===========*/
  useEffect(() => {
    document.body.classList.add("gh-quote-details-body");

    return function cleanup() {
      document.body.classList.remove("gh-quote-details-body");
    };
  }, []);

  /*===========Get Quote Details===========*/
  useEffect(() => {
    if (!match.params.quoteID) return;

    getQuote(match.params.quoteID)
      .then(() => {
        window.scrollTo(0, 0);
      })
      .catch(() => {
        history.push(`/flights/requests/${requestId}`);
      });

    return () => {
      if (!window.location.href.includes("payment/")) return removeQuotes();
    };
  }, []);

  useEffect(() => {
    if (quoteDetail.expiration_time && moment().isAfter(moment(quoteDetail.expiration_time))) {
      if (parentRoute.url) {
        history.push(parentRoute.url);
      } else {
        history.goBack();
      }
    }
  }, [quoteDetail]);

  /*===========Render Component===========*/
  if (!quoteDetail || isObjectEmpty(quoteDetail)) return "";

  if (
    quoteDetail.status &&
    quoteDetail.status !== "open" &&
    quoteDetail.status !== "booking_init" &&
    quoteDetail.status !== "disabled"
  )
    return <Redirect to={`/flights/requests/filter/review/${requestId}`} />;

  return (
    <React.Fragment>
      <BackLinkStyled
        linkName={staticService.findByAlias("backToRequest")}
        title={`Request GHR${requestId} - Full Details of Quote GHQ${quoteID}`}
        to={`/flights/requests/filter/review/${requestId}`}
      />
      <div className="uk-container gh-view-full-quote-wrapper">
        <FullQuoteContext.Provider value={{ feeSelected, setFeeSelected }}>
          {Auth.hasFeature("remove_anonymity_for_brokers") && !!quoteDetail.operator_company && (
            <OperatorCompanyBanner
              operatorCompany={quoteDetail.operator_company}
              expirationTime={quoteDetail.expiration_time}
              wrapperClassName={quoteDetail.expiration_time ? "gh-company-banner-with-date" : ""}
            />
          )}

          <QuoteVisualsLayout
            feeSelected={feeSelected}
            setFeeSelected={setFeeSelected}
            quoteDetail={quoteDetail}
            history={history}
          />
          <QuoteManageButtons
            quoteDetail={quoteDetail}
            quoteID={quoteID}
            requestId={requestId}
            history={history}
            path={path}
          />
        </FullQuoteContext.Provider>
      </div>
    </React.Fragment>
  );
};

ViewFullQuoteDetails.propTypes = {
  match: PropTypes.object,
  parentRoute: PropTypes.object,
  quoteDetail: PropTypes.object,
  getQuote: PropTypes.func,
  removeQuotes: PropTypes.func,
  history: PropTypes.object,
  path: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getQuote,
      removeQuotes: () => dispatch({ type: REMOVE_QUOTE_ITEM }),
    },
    dispatch,
  );
};

function mapStateToProps(state) {
  return {
    quoteDetail: state.quoteDetail,
  };
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ViewFullQuoteDetails));
