import React from "react";
import PropTypes from "prop-types";
import transformAdditionalInfo from "../../../utils/transformAdditionalInfo";
import "./SiteComponent.css";

import greenMarker from "../../../assets/img/svg/greenMarker.svg";
import yellowMarker from "../../../assets/img/svg/yellowMarker.svg";
import oval from "../../../assets/img/svg/oval.svg";

const locationIcons = {
  gh_event: yellowMarker,
  hellipaddy: greenMarker,
  google: oval,
};

const SiteComponent = ({ icon = oval, name, secondaryText, className, ...rest }) => {
  return (
    <div className={`gh-site-wrapper ${className}`} {...rest}>
      <div className="gh-site-img-wrapper">
        <img src={locationIcons[icon]} alt="icon" />
      </div>
      <div className="gh-site-info-wrapper">
        <h6 className="gh-site-title">{name}</h6>
        <div className="gh-site-info">
          <p className="gh-site-name">{transformAdditionalInfo(secondaryText)}</p>
        </div>
      </div>
    </div>
  );
};

SiteComponent.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  secondaryText: PropTypes.string,
  className: PropTypes.string,
};

export default SiteComponent;
