import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import * as yup from "yup";
import useYupResolver from "../../../../hooks/useYupResolver";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "../../../../components";
import InputNumber from "../../../../components/Form/components/InputNumber";
import Radio from "../../../../components/Form/components/RadioButton";
import { Modal } from "../Modal";
import apiHandler from "../../../../services/api-handler";
import { useUnmount } from "react-use";
import { OperatorMarginContext } from "./OperatorMargin";
import Tooltip from "../../../../components/common/Tooltip";
import staticService from "../../../../services/static.service";
import { connect } from "react-redux";
import Button from "../../../../components/common/Button";
import { showNotification } from "../../../../utils/showNotification";
import { parseThousands } from "../../../../components/Form/components/InputNumber/InputNumber.utils";
import { useCalculatorContext } from "../../CalculatorContext";

const companyDefaultsSchema = yup.object().shape({
  company_margin: yup
    .string()
    .test("max", "Margin must be less than or equal to 100%", (val) => parseThousands(val) <= 100)
    .required("This field is required"),
  pilot: yup.object().shape({
    is_pilot_included: yup.string().required("This field is required"),
    pilot_rate_period: yup.string().when("is_pilot_included", {
      is: "2",
      then: yup.string().required("This field is required"),
    }),
    pilot_rate_amount: yup.string().when("is_pilot_included", {
      is: "2",
      then: yup
        .string()
        .test(
          "max",
          "Amount must be no more than 999,999.99",
          (val) => parseThousands(val) <= 999999.99,
        )
        .test("min", "This field is required", (val) => parseThousands(val) > 0)
        .required("This field is required"),
    }),
    pilot_duty: yup.string().when("is_pilot_included", {
      is: "2",
      then: (schema) =>
        schema
          .max(2, "Must be 2 characters or less")
          .test("min", "This field is required", (val) => parseThousands(val) > 0)
          .required("This field is required"),
    }),
    pilot_tax: yup.string().when("is_pilot_included", {
      is: "2",
      then: (schema) =>
        schema.test(
          "max",
          "Tax rate must be less than or equal to 100%",
          (val) => parseThousands(val) <= 100 || val === "",
        ),
    }),
  }),
});

const formStaticValues = {
  is_automatic_quoting_enabled: "no",
  is_one_way_enabled: "no",
  is_return_enabled: "no",
  is_multi_leg_enabled: "no",
};

const OperatorMarginSettingsModal = ({ visible, setVisible, banking }) => {
  const { updateForm, activeAircraftIndex } = useCalculatorContext();
  const { getValues } = useFormContext();

  const form = useForm({
    resolver: useYupResolver(companyDefaultsSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const { companyDefaultSettings, setCompanyDefaultSettings } =
    React.useContext(OperatorMarginContext);

  const [loading, setLoading] = React.useState(false);

  // const watchFields = form.watch(["is_automatic_quoting_enabled"]);

  const fieldsToUpdate = (
    pilot_rate_amount,
    pilot_duty,
    pilot_tax,
    company_margin,
    is_pilot_included,
    pilot_rate_period,
    activeAircraftIndex,
  ) => {
    const aircraftsItems = getValues(`aircrafts`);

    const fieldsToUpdate = aircraftsItems.map((item) => {
      const legs = item.legs.map((leg, legIndex) => {
        const pilots = leg.pilots.map((pilot, pilotIndex) => {
          const fieldsToUpdate = {
            [`aircrafts.${activeAircraftIndex}.legs.${legIndex}.pilots.${pilotIndex}.payment_amount`]:
              Number(pilot_rate_amount),
            [`aircrafts.${activeAircraftIndex}.legs.${legIndex}.pilots.${pilotIndex}.pilot_shift_duration`]:
              Number(pilot_duty),

            [`aircrafts.${activeAircraftIndex}.legs.${legIndex}.pilots.${pilotIndex}.payment_tax`]:
              {
                sum: "",
                percent: pilot_tax,
              },

            [`aircrafts.${activeAircraftIndex}.legs.${legIndex}.pilots.${pilotIndex}.is_included`]:
              is_pilot_included,

            [`aircrafts.${activeAircraftIndex}.legs.${legIndex}.pilots.${pilotIndex}.payment_type`]:
              pilot_rate_period,
            operator_margin: { sum: "", percent: company_margin },
          };
          const convertedObjectToArr = Object.entries(fieldsToUpdate);

          return convertedObjectToArr;
        });

        return pilots;
      });

      return legs;
    });

    const fieldsConvertedDeepLevel = fieldsToUpdate.flat(3);

    return fieldsConvertedDeepLevel;
  };

  const onSubmitHandler = React.useCallback(
    async (values) => {
      setLoading(true);

      const parsedValues = parseValuesBeforeSubmit(values);

      apiHandler
        .setProperty("skipErrorAlert", true)
        .setPath("quote/defaults")
        .post(parsedValues)
        .then((res) => {
          const { pilot_rate_amount, pilot_duty, pilot_tax, is_pilot_included, pilot_rate_period } =
            res?.data?.pilot;
          const { company_margin } = res?.data;

          updateForm([
            ...fieldsToUpdate(
              pilot_rate_amount,
              pilot_duty,
              pilot_tax,
              company_margin,
              is_pilot_included,
              pilot_rate_period,
              activeAircraftIndex,
            ),
          ]);

          setCompanyDefaultSettings(res.data);

          if (res.message) {
            showNotification(res.message, "success");
          }

          setLoading(false);
          setVisible(false);
        })
        .catch((err) => {
          setLoading(false);
          return err;
        });
    },
    [activeAircraftIndex],
  );

  useUnmount(() => {
    window.sessionStorage.removeItem("company-quote-defaults");
  });
  // this one is fixing input error when user is typing
  React.useEffect(() => {
    const subscription = form.watch(() => {
      form.trigger();
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  if (!visible) return null;

  return ReactDOM.createPortal(
    <Modal
      title="Price calculator default values"
      visible={visible}
      onVisibilityChange={setVisible}
    >
      <Form
        form={form}
        initialValues={{
          ...companyDefaultSettings,
          pilot: {
            ...companyDefaultSettings?.pilot,
            is_pilot_included: companyDefaultSettings?.pilot.is_pilot_included || "1",
            pilot_rate_period: companyDefaultSettings?.pilot.pilot_rate_period || "1",
          },
          ...formStaticValues,
        }}
        onSubmit={onSubmitHandler}
      >
        <Form.Item
          name="company_vat"
          label="OPERATOR DEFAULT TAX RATE (%)"
          className="uk-margin-small-bottom"
          disabled={true}
        >
          <InputNumber className="uk-input" placeholder="[0.00]" />
        </Form.Item>
        <Form.Item
          name="company_margin"
          label="Operator Default Margin (%)"
          className="uk-margin-small-bottom"
        >
          <InputNumber className="uk-input" placeholder="[0.00]" />
        </Form.Item>
        <Form.Item
          label="Pilot Cost Included in Cost/H of Aircraft"
          name="pilot.is_pilot_included"
          className="uk-margin-small-bottom"
        >
          <Radio
            values={[
              {
                label: "Yes",
                value: "1",
              },
              {
                label: "No",
                value: "2",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Default Pilot Rate Period"
          name="pilot.pilot_rate_period"
          className="uk-margin-small-bottom"
        >
          <Radio
            values={[
              {
                label: "Hourly",
                value: "1",
              },
              {
                label: "Daily",
                value: "2",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="pilot.pilot_rate_amount"
          label={`Default Pilot Rate Amount (${banking.currency || "GBP"})`}
          className="uk-margin-small-bottom"
        >
          <InputNumber className="uk-input" placeholder="[0.00]" />
        </Form.Item>
        <Form.Item
          label="Default Pilot Duty (hours or days)"
          name="pilot.pilot_duty"
          className="uk-margin-small-bottom"
        >
          <InputNumber className="uk-input" placeholder="[0.00]" />
        </Form.Item>
        <Form.Item
          name="pilot.pilot_tax"
          label="Default Pilot Tax Rate(%)"
          className="uk-margin-small-bottom"
        >
          <InputNumber className="uk-input" placeholder="[0.00]" />
        </Form.Item>
        <div className="uk-flex">
          <Tooltip
            pos="top-right"
            refEl="self"
            show={true}
            trigger={
              <Form.Item
                label="Enable Automatic Quoting"
                name="is_automatic_quoting_enabled"
                className="uk-margin-small-bottom"
                disabled
              >
                <Radio
                  required={true}
                  values={[
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ]}
                />
              </Form.Item>
            }
          >
            {staticService.findByAlias("featureWillBeAvailableSoon")}
          </Tooltip>
          <Tooltip pos="top-center" wrapperClassName="uk-margin-small-left" refEl="self">
            {staticService.findByAlias("enableAutomaticQuotingTooltip")}
          </Tooltip>
        </div>
        <div className="calculator__modal-bottom-radio-buttons">
          <Tooltip
            pos="top-right"
            refEl="self"
            show={true}
            trigger={
              <Form.Item
                label="Enable One Way"
                name="is_one_way_enabled"
                className="uk-margin-small-bottom"
                disabled
              >
                <Radio
                  required={true}
                  values={[
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ]}
                />
              </Form.Item>
            }
          >
            {staticService.findByAlias("featureWillBeAvailableSoon")}
          </Tooltip>

          <Tooltip
            pos="top-center"
            refEl="self"
            show={true}
            trigger={
              <Form.Item
                label="Enable Return"
                name="is_return_enabled"
                className="uk-margin-small-bottom"
                disabled
              >
                <Radio
                  values={[
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ]}
                />
              </Form.Item>
            }
          >
            {staticService.findByAlias("featureWillBeAvailableSoon")}
          </Tooltip>

          <Tooltip
            pos="top-left"
            refEl="self"
            show={true}
            trigger={
              <Form.Item
                label="Enable Multi Leg"
                name="is_multi_leg_enabled"
                className="uk-margin-small-bottom"
                disabled
              >
                <Radio
                  values={[
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ]}
                />
              </Form.Item>
            }
          >
            {staticService.findByAlias("featureWillBeAvailableSoon")}
          </Tooltip>
        </div>
        <Button
          loading={loading}
          className="calculator-reset-btn calculator-submit-btn"
          onClick={form.handleSubmit(onSubmitHandler)}
        >
          Save as Default
        </Button>
        <span className="aircraft-modal-note">
          {staticService.findByAlias("priceCalculatorModalNote")}
        </span>
        <span className="aircraft-modal-note">
          {staticService.findByAlias("priceCalculatorModalSubNote")}
        </span>
      </Form>
    </Modal>,
    document.getElementById("app"),
  );
};

OperatorMarginSettingsModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  banking: PropTypes.object,
};

const mapStateToProps = (store) => ({
  banking: store.dashboard.banking,
});

export default connect(mapStateToProps)(OperatorMarginSettingsModal);

const parseValuesBeforeSubmit = (values) => {
  const parsedPilot = {
    ...values.pilot,
    pilot_rate_amount: parseThousands(values.pilot.pilot_rate_amount),
    pilot_tax: parseThousands(values.pilot.pilot_tax),
    pilot_duty: parseThousands(values.pilot.pilot_duty),
  };

  const parsedValues = {
    ...values,
    pilot: parsedPilot,
    company_margin: parseThousands(values.company_margin),
  };
  return parsedValues;
};
