import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import ImageSlider from "../ImageSlider";
import staticService from "../../../services/static.service";
import { BrokerQuoteInfo } from "../../pages/user-online/broker/request/broker-quote-info.component";
import Button from "../Button";
import LiveChatComponent from "../LiveChatComponent/liveChatComponent";
import moment from "moment/moment";
import ExpirationBanner from "../ExpirationBanner";
import Auth from "../../../services/auth";
import withTooltip from "../Button/hocButton";

import "./QuoteItem.css";
import on_hold from "../../../assets/img/on_hold_badge.png";
import withdrawn from "../../../assets/img/withdrawn-badge.png";
import { QUOTE_OPEN } from "../../../configs";

const QuoteItem = (props) => {
  const {
    requestID,
    quote,
    brokerIsAllowedToEdit,
    hideChat,
    history,
    showPad,
    hasQuoteToExpired,
    status,
  } = props;
  const [selectedHeli, setSelectedHeli] = useState(0);
  const isQuoteExpired = quote.expiration_time && moment().isAfter(quote.expiration_time);
  const isQuoteOnHold = quote.status === "on_hold";
  const isQuoteWithdraw = quote.status === "withdrawn" && Auth.isBroker();

  const changeSliderHeli = (index) => {
    setSelectedHeli(index);
  };

  const heliDescription = useMemo(
    () =>
      quote.aircrafts[selectedHeli]?.aircraft_images[0].is_operator_image
        ? "isOperatorImage"
        : "isNotOperatorImage",
    [quote.aircrafts, selectedHeli],
  );

  const handleViewFullQuote = () => {
    // const path = viewFullQuoteOnClick ? viewFullQuoteOnClick : `${onSelectPath}/quote/${quote.id}`;
    const path = `/flights/requests/${requestID}/quote/${quote.id}`;
    history.push(path);
  };

  const disableLiveChat = (brokerIsAllowedToEdit, isQuoteExpired, hasQuoteToExpired, status) => {
    switch (status) {
      case QUOTE_OPEN:
        return false;

      default:
        return !brokerIsAllowedToEdit || isQuoteExpired || hasQuoteToExpired;
    }
  };

  return (
    <div className="gh-quote-item">
      {!!quote.is_amend_notified && !isQuoteWithdraw ? (
        <div className="gh-quote-card_amend-alert uk-alert-warning uk-text-uppercase">
          Amended Quote
        </div>
      ) : (
        ""
      )}

      {quote.expiration_time && !isQuoteWithdraw ? (
        <ExpirationBanner date={quote.expiration_time} />
      ) : (
        ""
      )}

      {isQuoteOnHold || isQuoteWithdraw ? (
        <div className="gh-quote-card-overlay">
          <div className="gh-quote-withdrawn">
            <div className="info">
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias(
                    isQuoteOnHold ? "quoteOnHoldStatus" : "quoteWasWithdrawn",
                  ),
                }}
              />
              <hr className="line" />
              <span
                className="reason-label"
                dangerouslySetInnerHTML={{
                  __html: staticService.findByAlias("reasonLabel"),
                }}
              />

              {isQuoteOnHold ? (
                <p className="reason-text">
                  {!Auth.isVerified()
                    ? staticService.findByAlias("missingInfoBrokerReason")
                    : staticService.findByAlias("missingInfoOperReason")}
                </p>
              ) : (
                <p className="reason-text">{quote.reason}</p>
              )}
            </div>
            <span className="badge">
              <img src={isQuoteOnHold ? on_hold : withdrawn} alt="badge" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        style={{ display: "flex", flexWrap: "wrap" }}
        className="uk-grid-small gh-quote-detail uk-padding-remove"
      >
        <div className="uk-width-1-1 uk-width-1-3@l">
          <div className="gh-quote-slider">
            <ImageSlider
              imagesUrl={quote.aircrafts.map((item) => item.aircraft_images[0].file_url)}
              selectedImg={selectedHeli}
              getSelectedImg={changeSliderHeli}
            />

            <p className="gh-description-small-gray" style={{ padding: "0 10px" }}>
              {staticService.findByAlias(heliDescription)}
            </p>
          </div>
        </div>

        <div className="uk-width-1-1 uk-width-2-3@s uk-width-1-2@l uk-margin-small-top">
          <BrokerQuoteInfo
            quote={quote}
            selectedHeli={selectedHeli}
            changeSliderHeli={changeSliderHeli}
            showPad={showPad}
            price={showPrice(quote)}
          />
        </div>
        <div className="uk-width-1-1 uk-width-1-3@s uk-width-1-6@l uk-margin-remove-top">
          <div className="/*uk-text-center uk-text-right@m*/ gh-quote-buttons-box">
            <div className="gh-quote-buttons">
              <div className="gh-bok-dec-buttons">
                <Button
                  disabled={isQuoteExpired || hasQuoteToExpired}
                  onClick={handleViewFullQuote}
                  type="button"
                  variant="outlined"
                  style={{ whiteSpace: "nowrap" }}
                  className="gh-quote-button"
                  tooltipProps={{
                    message: hasQuoteToExpired
                      ? staticService.findByAlias("requestIsExpiredTooltip")
                      : "",
                  }}
                >
                  {staticService.findByAlias("viewFullQuoteButton")}
                </Button>
              </div>

              {!hideChat && (
                <LiveChatComponentWithTooltip
                  quoteId={quote.id}
                  titleAlias="quoteDiscussionBroker"
                  disabled={disableLiveChat(
                    brokerIsAllowedToEdit,
                    isQuoteExpired,
                    hasQuoteToExpired,
                    status,
                  )}
                  commentsCount={
                    quote.comments_count && !quote.seen_by_broker ? quote.comments_count : 0
                  }
                  tooltipProps={
                    status !== QUOTE_OPEN
                      ? {
                          message: hasQuoteToExpired
                            ? staticService.findByAlias("requestIsExpiredTooltip")
                            : "",
                        }
                      : ""
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuoteItem.propTypes = {
  requestID: PropTypes.number.isRequired,
  quote: PropTypes.object,
  hideChat: PropTypes.bool,
  history: PropTypes.object,
  brokerIsAllowedToEdit: PropTypes.bool,
  showPad: PropTypes.func,
  hasQuoteToExpired: PropTypes.bool,
  status: PropTypes.string,
};

export default QuoteItem;

// ===================================================================================

const showPrice = (quote) => {
  return (
    <span>
      <span>{quote.user_currency.currency} </span>
      {quote.user_price_formatted + " "}
      incl. <span>{quote.user_currency.currency} </span>
      {quote.user_vat_formatted} VAT
    </span>
  );
};

const LiveChatComponentWithTooltip = withTooltip((props) => {
  return <LiveChatComponent {...props} />;
});
