import Api from "../services/api-handler";
import handlerError from "./error";
import * as types from "./actionTypes";
import { staticText } from "../configs/";
import GetHeliEvents from "../services/event.service";

const teamEntity = "team";
const invitationsEntity = "invitations";

export function getTeamMembersSuccess(members) {
  return { type: types.SET_TEAM_MEMEBERS, members };
}

export function getTeamMembers() {
  return function (dispatch) {
    return Api.setPath("company", teamEntity)
      .getAll()
      .then((list) => {
        dispatch(getTeamMembersSuccess(list));
        GetHeliEvents.trigger("members");
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function getInvitationsSuccess(invitations) {
  return { type: types.GET_INVITATIONS, invitations };
}

export function getInvitations() {
  return function (dispatch) {
    return Api.setPath("invitations")
      .getAll()
      .then((list) => {
        dispatch(getInvitationsSuccess(list));
        GetHeliEvents.trigger("invitations");
      })
      .catch((err) => handlerError(err, dispatch));
  };
}

export function inviteMembers(payload, onSuccess, onError) {
  return function (dispatch) {
    Api.setProperty("skipErrorAlert", true)
      .setDispatchAndValidationModel(dispatch, "inviteMembersModel")
      .setProperty("responseMessages", staticText.globalMessages.invitation);
    return Api.setPath(invitationsEntity, "add")
      .post(payload)
      .then((response) => {
        Api.setProperty("skipErrorAlert", false);
        if (onSuccess) onSuccess(response);
        return dispatch(getInvitationsSuccess(response));
      })
      .catch((err) => {
        if (err.status === 422 || err.status === 432) {
          onError(err);
        }
        return handlerError(err, dispatch);
      });
  };
}
