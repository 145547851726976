import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  clearQuoteDetail,
  getHelicopterServiceTerms,
  loadUserDashboardStatistic,
  previewQuote,
  updateQuoteDetail,
  updateQuoteLeg,
} from "../../../actions";
import staticService from "../../../services/static.service";
import QuoteLegs from "../../common/QuoteLegs";
import QuotePrice from "../../common/QuotePrice/quotePrice";
import TermsAndConditions from "../../common/TermsAndConditions/termsAndConditions";
import AdditionalInformation from "../../common/AdditionalInformation";
import { validationRules } from "../../../configs";
import { convertToNumber } from "../../../shared";
import quoteFormToOriginal from "../../../utils/quoteFormToOriginal";
import auth from "../../../services/auth";
import tranformDateTimeToISO from "../../../utils/tranformDateTimeToISO";
import UploadFiles from "../../forms/register/upload-files.component";
import { bindActionCreators } from "redux";
import { actions } from "react-redux-form";
import QuoteExpirationDate from "./components/QuoteExpirationDate";
const { isPrice, min } = validationRules;
import { showNotification } from "../../../utils/showNotification";
import ReviewGeneralInformationComponent from "../user-online/broker/review/review-general-information.component";
import moment from "moment";

import "./quoteForm.css";

const QuoteForm = (props) => {
  const {
    editingQuote,
    originalQuote,
    flightPurposes,
    loadUserDashboardStatistic,
    previewQuote,
    history,
    updateQuoteDetail,
    clearQuoteDetail,
    quoteFormModel,
    updateModel,
    getHelicopterServiceTerms,
    updateQuoteLeg,
    handleChangeTab,
  } = props;

  const [isEmptyLeg, setIsEmptyLeg] = useState(false);
  const [departureTimeError, setDepartureTimeError] = useState(null);
  const [passengerAmountError, setPassengerAmountError] = useState(null);

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    clearErrors,
    unregister,
    setError,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    getHelicopterServiceTerms().then((res) => {
      updateModel(
        "quoteFormModel.helicopterServicesTerms",
        editingQuote.helicopter_services_terms || res || [],
      );
    });
  }, []);

  useEffect(() => {
    if (editingQuote.quote_price.total && !getValues("total"))
      setValue("total", editingQuote.quote_price.total);
    if (editingQuote.quote_price.val && !getValues("vat"))
      setValue("vat", editingQuote.quote_price.vat);
  }, [editingQuote, getValues, setValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadUserDashboardStatistic();
  }, []);

  useEffect(() => {
    if (editingQuote.emptyLegAircraft) {
      updateQuoteDetail({
        ...editingQuote,
        legs: editingQuote.legs.map((l) => ({
          ...l,
          selected_aircrafts: [editingQuote.emptyLegAircraft],
        })),
      });
    }

    if (originalQuote.empty_leg_id) {
      setIsEmptyLeg(true);
    }
  }, []);

  const openTabsBeforeSubmit = () => {
    document
      .querySelectorAll(".gh-accordion-wrapper .gh-accordion-section")
      .forEach(
        (tab) =>
          !tab.classList.contains("uk-open") && tab.querySelector(".uk-accordion-title").click(),
      );
  };

  const handlerError = (err) => {
    for (let key in err) {
      setError(key, { type: "manual", message: err[key][0] }, { shouldFocus: true });
    }
  };

  const onSubmit = () => {
    const locationErrors = [];

    if (departureTimeError) {
      let errElement = document.getElementById(`departure-time-error-${departureTimeError}`);
      const yOffset = -100;
      const y = errElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

      return window.scrollTo({ top: y, behavior: "smooth" });
    }

    let errors = false;

    const passengersAmountValidation = editingQuote.legs.map(
      (leg) => leg.passengers.children + leg.passengers.men + leg.passengers.women <= 0 && true,
    );

    if (passengersAmountValidation.includes(true)) {
      setPassengerAmountError(passengersAmountValidation.indexOf(true));
      const errorContainer = document.querySelectorAll(".gh-quote-form-passengers-error-total")[0];
      if (errorContainer) errorContainer.scrollIntoView();
      errors = true;
    }

    editingQuote.legs.forEach((item, index) => {
      if (!item.from_coords) {
        locationErrors.push(`from${index}`);
      }
    });

    editingQuote.legs.forEach((item, index) => {
      if (!item.to_coords) {
        locationErrors.push(`to${index}`);
      }
    });
    // fix for empty leg(buttons are hid), when payload locked is null, we convert it to bool (false)
    editingQuote.legs.forEach((item, index) => {
      if (typeof item.is_payload_locked === "object" && item.is_payload_locked === null) {
        editingQuote.legs[index].is_payload_locked = true;
      }
    });

    if (locationErrors.length) {
      locationErrors.forEach((item) =>
        setError(
          item,
          { type: "manual", message: staticService.findByAlias("pleaseSelect") },
          { shouldFocus: true },
        ),
      );
      errors = true;
    }

    editingQuote.legs.forEach((item) => {
      item.selected_additional_aircrafts.forEach((item) => {
        if (Object.keys(item).length > 1 && !item.crew_size) {
          setError(
            `crew_size${item.uid}`,
            { type: "manual", message: staticService.findByAlias("crewError") },
            { shouldFocus: true },
          );
          errors = true;
        }
      });
    });

    if (editingQuote.quote_price.total === "0.00") {
      return setError(
        "total",
        { type: "manual", message: staticService.findByAlias("totalError") },
        { shouldFocus: true },
      );
    }

    if (
      !isPrice(editingQuote.quote_price.total) ||
      !min("1", true)(editingQuote.quote_price.total)
    ) {
      return setError(
        "total",
        { type: "manual", message: staticService.findByAlias("validPriceError") },
        { shouldFocus: true },
      );
    }

    if (editingQuote.description && editingQuote.description.length > 500) {
      return setError(
        "description",
        { type: "manual", message: "Must be 500 or fewer characters" },
        { shouldFocus: true },
      );
    }

    // if (!isPrice(editingQuote.quote_price.vat) || !min("1", true)(editingQuote.quote_price.vat)) {
    //   setError("vat", { type: "manual", message: `Enter a valid price` }, { shouldFocus: true });
    //   errors = true;
    // }

    if (
      convertToNumber(editingQuote.quote_price.total) <
      convertToNumber(editingQuote.quote_price.vat) +
        convertToNumber(editingQuote.quote_price.landing_fees)
    ) {
      setError(
        "total",
        { type: "manual", message: staticService.findByAlias("totalShouldBeBiggerError") },
        { shouldFocus: true },
      );
      errors = true;
    }

    if (errors) return;

    previewQuote({
      ...editingQuote,
      broker: originalQuote.broker,
      legs: editingQuote.legs.map((leg) => {
        leg.date = tranformDateTimeToISO(moment(leg.date).format(), leg.departure_time);
        leg.departure_time = tranformDateTimeToISO(moment(leg.date).format(), leg.departure_time);
        return leg;
      }),
      booking_request_id: editingQuote.booking_request_id || editingQuote.id,
      booking_request: editingQuote.booking_request,
      helicopter_services_terms: quoteFormModel.helicopterServicesTerms,
      quote_price: {
        ...editingQuote.quote_price,
        vat: editingQuote.quote_price.vat || 0,
      },
    })
      .then((res) => {
        const updatedLegs = res.legs.map((item) => ({
          ...item,
          special_items: {
            ...item.special_items,
            other: item.special_items.other ? item.special_items.other : "",
          },
        }));
        clearQuoteDetail();
        history.push({
          pathname: `/marketplace/preview-quote/${res.id}`,
          state: {
            ...history.location.state,
            editingQuote: { ...res, legs: updatedLegs },
            originalQuote,
            formPath: {
              pathname: history.location.pathname,
              search: history.location.search || "",
            },
          },
        });
      })
      .catch((err) => {
        const { data } = err;

        if (data?.message) {
          showNotification(
            data.message === "Action Unauthorized" ? "isRequestNotAllowed" : data.message,
            "danger",
          );
        }

        if (err) {
          handlerError(err.message || err.data.message);
          return err;
        }
      });
  };

  const handleUpdateQuoteLeg = (newData, updateOnServerSide = false) => {
    if (updateOnServerSide && newData.quote) {
      const quoteId = newData.quote.id;

      updateQuoteLeg(quoteId, newData).then((res) => {
        updateQuoteDetail({ ...newData, quote: res.quote, quote_price: res.quote.quote_price });
      });
    } else {
      updateQuoteDetail(newData);
    }
  };

  const resetQuoteVal = quoteFormToOriginal(originalQuote);

  if (!auth.isVerified() || !editingQuote.quote) {
    return <Redirect to={`/marketplace/trip/request/${editingQuote.id}`} />;
  }

  return (
    <div className="gh-quote-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/*General Information*/}
        <ReviewGeneralInformationComponent
          requestForm={{ ...editingQuote, broker: originalQuote.broker }}
          purposes={flightPurposes}
          quoteForm={true}
          brokerCompany={originalQuote.broker_company}
        />

        {/*LEGS*/}
        <QuoteLegs
          setError={setError}
          control={control}
          reset={reset}
          trigger={trigger}
          clearErrors={clearErrors}
          unregister={unregister}
          isEmptyLeg={isEmptyLeg}
          setValue={setValue}
          resetQuoteVal={resetQuoteVal}
          departureTimeError={departureTimeError}
          setDepartureTimeError={setDepartureTimeError}
          passengerAmountError={passengerAmountError}
          setPassengerAmountError={setPassengerAmountError}
          updateQuoteDetail={handleUpdateQuoteLeg}
        />

        {/*QUOTE PRICE*/}
        <QuotePrice
          control={control}
          editingQuote={editingQuote}
          originalQuote={originalQuote}
          flightPurposes={flightPurposes}
          setError={setError}
          clearErrors={clearErrors}
          setValue={setValue}
          unregister={unregister}
          handleChangeTab={handleChangeTab}
        />

        {/*QUOTE EXPIRATION DATE*/}
        <QuoteExpirationDate updateQuoteDetail={updateQuoteDetail} editingQuote={editingQuote} />

        {/*ADDITIONAL INFORMATION*/}
        <AdditionalInformation
          editingQuote={editingQuote}
          control={control}
          clearErrors={clearErrors}
          setError={setError}
        />

        {/*TERMS AND CONDITIONS*/}
        {auth.getHeliServiceTerms() ? (
          <TermsAndConditions request={originalQuote} />
        ) : (
          <div className="gh-simple-section gh-terms-conditions-wrapper">
            <div className="uk-margin-top">
              <UploadFiles
                model={quoteFormModel.helicopterServicesTerms}
                modelName="quoteFormModel.helicopterServicesTerms"
                uploadTitle="flightContract"
                docType={1}
                docs={quoteFormModel.helicopterServicesTerms}
                entity="company_document"
                customRemoveFileAction={() => {
                  updateModel("quoteFormModel.helicopterServicesTerms", []);
                }}
                multiple={false}
                onlyOneFile={true}
              />
            </div>
          </div>
        )}

        <div className="gh-quote-form-buttons">
          <button
            type="button"
            className="uk-button uk-button-primary gh-request-gen-btns"
            onClick={() => {
              openTabsBeforeSubmit();
              setTimeout(handleSubmit(onSubmit), 180);
            }}
          >
            <span>Preview Quote</span>
          </button>
        </div>
      </form>
    </div>
  );
};

QuoteForm.propTypes = {
  editingQuote: PropTypes.object,
  originalQuote: PropTypes.object,
  flightPurposes: PropTypes.array,
  loadUserDashboardStatistic: PropTypes.func,
  previewQuote: PropTypes.func,
  history: PropTypes.object,
  updateQuoteDetail: PropTypes.func,
  clearQuoteDetail: PropTypes.func,
  quoteFormModel: PropTypes.object,
  updateModel: PropTypes.func,
  getHelicopterServiceTerms: PropTypes.func,
  updateQuoteLeg: PropTypes.func,
  handleChangeTab: PropTypes.func,
};
const mapStateToProps = ({ quoteToAmend, quoteFormModel }) => ({
  quoteToAmend,
  quoteFormModel,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateModel: (model, value) => dispatch(actions.change(model, value)),
      loadUserDashboardStatistic,
      previewQuote,
      updateQuoteDetail,
      clearQuoteDetail,
      getHelicopterServiceTerms,
      updateQuoteLeg,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteForm);
