import PropTypes from "prop-types";
import React from "react";
import condStrings from "../../../../utils/condString";
import {
  parseNumber,
  prepareNumber,
  separateThousands,
  tranformToNumber,
  validateInputNumber,
} from "./InputNumber.utils";

import "./InputNumber.styles.css";

const decimalSeparator = ".";
const thousandsSeparator = ",";

export const InputNumber = React.forwardRef((props, ref) => {
  const {
    name,
    onChange,
    onBlur,
    value,
    maximumFractionDigits,
    className,
    prefix,
    onEnterValidate,
    ...rest
  } = props;

  const changeHander = (event) => {
    let value = event.target.value || "";

    if (!value) return onChange("");

    if (value.split(decimalSeparator).length - 1 > 1) {
      if (value[value.length - 1] === decimalSeparator) {
        value = value.slice(0, -1);
      }
    }

    let [left, right] = prepareNumber(value, { decimalSeparator }).split(decimalSeparator);

    if (left) left = separateThousands(left, { thousandsSeparator });
    else return onChange("");

    if (!right && value[value.length - 1] === decimalSeparator) left += decimalSeparator;

    if (right) right = `${decimalSeparator}${right.substring(0, maximumFractionDigits)}`;
    else right = "";

    onChange(`${left}${right}`);
  };

  const blurHandler = (event) => {
    if (onBlur) onBlur(event);

    let value = event.target.value || "";

    if (value && value[value.length - 1] === decimalSeparator) {
      onChange(value.slice(0, -1));
    }
  };

  const handleOnKeyDown = (e) => {
    const keyValue = e.code;

    if ((keyValue === "Enter" || keyValue === "NumpadEnter") && onEnterValidate) {
      e.target.blur();
    }
  };

  return (
    <div className="gh-input-number">
      {prefix && <span className="gh-input-number__prefix">{prefix}</span>}
      <input
        className={condStrings(className, prefix && "with-prefix")}
        ref={ref}
        id={name}
        name={name}
        value={value || ""}
        autoComplete="off"
        onChange={changeHander}
        onBlur={blurHandler}
        placeholder={`0${decimalSeparator}00`}
        onKeyDown={handleOnKeyDown}
        {...rest}
      />
    </div>
  );
});

InputNumber.displayName = "InputNumber";

InputNumber.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maximumFractionDigits: PropTypes.number,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  prefix: PropTypes.any,
  onEnterValidate: PropTypes.bool,
};

InputNumber.defaultProps = {
  maximumFractionDigits: 2,
  value: "",
  onEnterValidate: false,
};

InputNumber.validate = validateInputNumber({ decimalSeparator });
InputNumber.toNumber = tranformToNumber({ decimalSeparator });
InputNumber.parse = parseNumber({ decimalSeparator, thousandsSeparator });
