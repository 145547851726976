import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../services/static.service";
import Tooltip from "../Tooltip";

// eslint-disable-next-line react/display-name
const withTooltip = (Component) => {
  const Sub = (props) => {
    const { tooltipProps } = props;
    if (!tooltipProps?.message) return <Component {...props} />;

    return (
      <Tooltip
        pos={tooltipProps.position || "top-center"}
        refEl="self"
        trigger={<Component {...props} />}
      >
        {staticService.findByAlias(tooltipProps.message) || tooltipProps.message || ""}
      </Tooltip>
    );
  };

  Sub.propTypes = {
    tooltipProps: PropTypes.object,
  };

  return Sub;
};

export default withTooltip;
